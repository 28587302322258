import React, {useEffect, useRef, useState} from 'react';
import '../style/Letter.css'; // 스타일을 별도의 CSS 파일로 분리할 수 있습니다.

const pagesContent = [
    "안녕, 세원아?",
    "우리가 벌써 만난지 100일이 되었어!",
    "시간이 정말 빠르다 ㅎㅎ 그치?",
    "만나면서 처음 이렇게 편지를 써 보네!",
    "우리 처음에 만난 날 기억해?",
    "맑은 날 오후 서면 블랙업 커피앞에서 처음 만난 세원이 모습은",
    "세상에서 아름다운 꽃 한송이 같았어.",
    "내 앞에 나타나줘서 정말 고마워!",
    "우리 만나면서 바다도 가고 별보러도 가고",
    "경주 한옥도 가고 귀신체험도 하고 정말 재미있었어!",
    "우리 서로 비슷한 면도 많고",
    "색다르게 다른 점도 있었는데",
    "우리 잘 맞는거 같아 ㅎㅎ",
    "나는 일주일에 평일에는 일하다가",
    "힘들어서 기운 빠지곤 했는데",
    "항상 세원이를 만나는 주말이면 너무 행복해!",
    "같이 맛있는 음식 먹고, 멋진 뷰도 같이 구경하고",
    "사진도 많이 찍어서 오빠는 행복하고 너무 좋아!",
    "같이 있으면 세원이의 밝고 신나는 에너지 뿜뿜도 좋고,",
    "트리트먼트 머리향도 좋고, 세상에서 가장 예쁜 세원이가",
    "내 여자친구라는 사실이 세상에서 가장 행복해!",
    "특히 운전할 때나 같이 있을 때",
    "잡은 손은 언제나 설렌 마음이 들어!",
    "앞으로도 내가 곁에 지켜줄게!",
    "여보야도 내 곁에 항상 있어줘.",
    "사랑해 여보야!",
    "알러뷰 뿅뿅!!",
    "From JM"
];
let QnA = [
    {quiz:'처음 만난 장소는? (5글자)',answer:'블랙업커피',inAnswer:''}
    ,{quiz:'처음 산 커플티 브랜드 이름은? (3글자)',answer:'커버낫', inAnswer: ''}
    ,{quiz:'고백한 장소는? (지하철역,3글자)',answer:'전포역', inAnswer: ''}
    ,{quiz:'물고기를 잡은 장소는? (3글자)',answer:'철구소', inAnswer: ''}
    ,{quiz:'창원 카페에서 찍은 사진 포즈 글자는? (4자)',answer:'love', inAnswer: ''}
    ,{quiz:'MBTI 검사한 카페이름은? (4자)',answer:'웨이브온', inAnswer: ''}
]

const Letter = () => {
    const audioRef = useRef(null);
    const [activePage, setActivePage] = useState(null);
    const [isPass, setIsPass] = useState(false);
    useEffect(()=> {
        if(!isPass){
            QnA.forEach( qna => {
                qna.inAnswer = prompt(qna.quiz).toLowerCase();
            });
            const collectQnA = QnA.filter( v => { return v.answer === v.inAnswer;});
            if(collectQnA.length === QnA.length){
                setIsPass(true);
            }
        }
    },[isPass])
    const handleTouchStart = (index) => {
        if( audioRef.current){
            audioRef.current.play().catch(error =>{
                console.log(error);
            })
        }
        setActivePage(index);
    }


    return (
        <div>
            <audio ref={audioRef} src="/bgm.mp3" preload="metadata" />
            {isPass ? (
                <div>
                <h3>To SW</h3>
                <p>아래 빈 화면을 터치!</p>
                <div className={'pages'}>
            {pagesContent.map((page,index)=>(
                <div
                key={index}
                className={`page ${activePage === index ? ' show':''}`}
                onClick={() => handleTouchStart(index)}
                >{page}</div>
                ))}
                </div>
                <h3>끝.</h3>
                </div>
            ) : (
                <div>
                   문제를 풀어봐.
                    <p>새로고침하면 문제를 풀 수 있음.</p>
                </div>
            )}
        </div>
    );
};

export default Letter;
