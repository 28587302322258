import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

function C1() {
    return (
        <Box
            sx={(theme) => ({
                width: '100%',
                backgroundImage: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <Typography component="h2" variant="h4" color="warning.light">
                    클레이사격
                </Typography>

                    <div>
                    <Accordion>
                        <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header">
                            언제?
                        </AccordionSummary>
                        <AccordionDetails>
                            1
                        </AccordionDetails>
                    </Accordion>

                <Accordion>
                    <AccordionSummary
                        aria-controls="panel2-content"
                        id="panel2-header">
                        어디서?
                    </AccordionSummary>
                    <AccordionDetails>
                        1
                        <br/>

                        <Button variant="contained"
                                onClick={()=> {
                                    window.open('https://place.map.kakao.com/')
                                }}
                        >위치찾기</Button>

                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        aria-controls="panel2-content"
                        id="panel2-header">
                        1
                    </AccordionSummary>
                    <AccordionDetails>
                        1
                    </AccordionDetails>
                </Accordion>

                    </div>
            </Container>
        </Box>
    );
}

export default C1