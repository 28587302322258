import React from 'react';
import Cookies from 'js-cookie';
import {useLocation} from 'react-router-dom';
import Box from '@mui/material/Box';
import {DataGrid, GridColDef} from '@mui/x-data-grid';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const columns: GridColDef<(typeof rows)[number]>[]= [
    { field: 'distance', headerName: '거리', width: 90},
    {
        field: 'nameKR',
        headerName: '이름',
        width: 110,
        editable: true,
    },
    {
        field: 'foodTypes',
        headerName: '음식',
        width: 110,
    },
    {
        field: 'ribbonType',
        headerName: '리본',
        width: 110,
    },
    {
        field: 'websiteInstagram',
        headerName: '인스타그램',
        width: 150,
        editable: true,
    },
    {
        field: 'zone2_1',
        headerName: '지역',
        width: 110,
    },
    {
        field: 'zone2_2',
        headerName: '상세지역',
        width: 110,
    },
    {
        field: 'bookStatus',
        headerName: '북상태',
        width: 150,
    },
    {
        field: 'bookYear',
        headerName: '연도',
        width: 110,
    },
];

function BLUE(){
    
    let query = useQuery();
    let x = query.get('x');
    let y = query.get('y');
    
    const key = Cookies.get('key');
    if(key !== 'jong'){
        return (
            <div>
                <h1>BLUE</h1>
            </div>
        );
    }
    
    const data_array =[

        {"id":909,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shillahotels/","foodTypes":"중식","latitude":37.5563,"longitude":127.0063,"bookYear":"2024","nameKR":"팔선","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":401,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.50997,"longitude":127.1262,"bookYear":"2024","nameKR":"벽제갈비","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":472,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sg_dinehill/","foodTypes":"한식(육류)","latitude":37.52494,"longitude":127.0326,"bookYear":"2024","nameKR":"삼원가든","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":4627,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hongbogak_official/","foodTypes":"중식","latitude":37.5052805568865,"longitude":127.028919391781,"bookYear":"2024","nameKR":"홍보각","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":382,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.51982,"longitude":126.9399,"bookYear":"2024","nameKR":"백리향","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":1534,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mayfield_hotel/","foodTypes":"한식(육류)","latitude":37.54683,"longitude":126.8186,"bookYear":"2024","nameKR":"낙원","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":859,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.556297,"longitude":127.006307,"bookYear":"2024","nameKR":"콘티넨탈","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":571,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5563,"longitude":127.0063,"bookYear":"2024","nameKR":"아리아께","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":415,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.54683,"longitude":126.8186,"bookYear":"2024","nameKR":"봉래헌","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":254,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/restaurant_lamitie/","foodTypes":"프랑스식","latitude":37.52596462384521,"longitude":127.04457274798145,"bookYear":"2024","nameKR":"라미띠에","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":343,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/restaurant_mipiace/","foodTypes":"이탈리아식","latitude":37.5214688685552,"longitude":127.044872674794,"bookYear":"2024","nameKR":"미피아체","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":198,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.56466,"longitude":126.9782,"bookYear":"2024","nameKR":"도원","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":1521,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lottehotel_seoul/","foodTypes":"중식","latitude":37.56461,"longitude":126.9809,"bookYear":"2024","nameKR":"도림","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":4649,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.564406,"longitude":126.979697,"bookYear":"2024","nameKR":"스시조","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":3998,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lespoirduhibou/","foodTypes":"프랑스식","latitude":37.52579,"longitude":127.0404,"bookYear":"2024","nameKR":"레스쁘아","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":4667,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jungsik_inc/","foodTypes":"한식(일반한식)","latitude":37.52562,"longitude":127.0411,"bookYear":"2024","nameKR":"정식당","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":4945,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lottehotel_seoul/","foodTypes":"한식(일반한식)","latitude":37.56461,"longitude":126.9809,"bookYear":"2024","nameKR":"무궁화","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":4953,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yountvillebistro/","foodTypes":"프랑스식","latitude":37.526,"longitude":127.0412,"bookYear":"2024","nameKR":"비스트로드욘트빌","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":15931,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.52542,"longitude":127.0367,"bookYear":"2024","nameKR":"파씨오네","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":20038,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/table_for_four_/)","foodTypes":"이탈리아식","latitude":37.49769,"longitude":126.9944,"bookYear":"2024","nameKR":"테이블포포","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":19650,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/toctoc_seoul/","foodTypes":"프랑스식","latitude":37.522410597151854,"longitude":127.05431282319448,"bookYear":"2024","nameKR":"톡톡","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":26017,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/zerocomplex_seoul","foodTypes":"컨템포러리","latitude":37.5208433209866,"longitude":126.992854368108,"bookYear":"2024","nameKR":"제로컴플렉스","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":26944,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/soigneseoul/","foodTypes":"컨템포러리","latitude":37.5195101291255,"longitude":127.019106035326,"bookYear":"2024","nameKR":"스와니예","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":27144,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mingles_restaurant/","foodTypes":"한식(일반한식)","latitude":37.52537011726149,"longitude":127.04416060872083,"bookYear":"2024","nameKR":"밍글스","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28167,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kwonsooksoo/","foodTypes":"한식(일반한식)","latitude":37.524328,"longitude":127.043571,"bookYear":"2024","nameKR":"권숙수","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28236,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bornandbredkorea","foodTypes":"한식(육류)","latitude":37.566790715500474,"longitude":127.04450198300889,"bookYear":"2024","nameKR":"본앤브레드","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":28229,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/restaurant_allaprima/","foodTypes":"컨템포러리","latitude":37.513525,"longitude":127.026005,"bookYear":"2024","nameKR":"알라프리마","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":28054,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.525742,"longitude":127.041981,"bookYear":"2024","nameKR":"코지마","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28946,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/restaurant_thegreentable/","foodTypes":"프랑스식","latitude":37.5777621971432,"longitude":126.988357552948,"bookYear":"2024","nameKR":"더그린테이블","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":29206,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.52300901428518,"longitude":127.03480609700759,"bookYear":"2024","nameKR":"고료리켄","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29083,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/choidot_official/","foodTypes":"컨템포러리","latitude":37.524456,"longitude":127.046528,"bookYear":"2024","nameKR":"쵸이닷","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29567,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lamant_secret/","foodTypes":"컨템포러리","latitude":37.559733515077966,"longitude":126.97952350317435,"bookYear":"2024","nameKR":"라망시크레","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":29243,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/muoki.seoul/","foodTypes":"컨템포러리","latitude":37.518997,"longitude":127.042238,"bookYear":"2024","nameKR":"무오키","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29464,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/onjium_restaurant/","foodTypes":"한식(일반한식)","latitude":37.5804948296674,"longitude":126.9733908253081,"bookYear":"2024","nameKR":"온지음레스토랑","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":30341,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.578806133812215,"longitude":126.98162396312134,"bookYear":"2024","nameKR":"키즈나","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":30639,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.52249740233286,"longitude":127.03426739117289,"bookYear":"2024","nameKR":"다이닝마","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30988,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5714966636936,"longitude":126.983659015157,"bookYear":"2024","nameKR":"수운","ribbonType":"3","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":31137,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5257986944472,"longitude":127.045659927706,"bookYear":"2024","nameKR":"스시마츠모토","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31431,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/7thdoor_official/","foodTypes":"컨템포러리","latitude":37.5224089749768,"longitude":127.054313613882,"bookYear":"2024","nameKR":"세븐스도어","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31432,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kojacha.official/","foodTypes":"컨템포러리","latitude":37.5208683828468,"longitude":127.055215316615,"bookYear":"2024","nameKR":"코자차","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31886,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/eatanicgarden/","foodTypes":"한식(일반한식)","latitude":37.5028813541774,"longitude":127.041356540268,"bookYear":"2024","nameKR":"이타닉가든","ribbonType":"3","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":29470,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seogwanmyeonog/","foodTypes":"한식(면류)","latitude":37.49398971324013,"longitude":127.01726966280745,"bookYear":"2024","nameKR":"서관면옥","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":27626,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lawrysseoul/","foodTypes":"기타","latitude":37.49778,"longitude":127.0262,"bookYear":"2024","nameKR":"로리스더프라임립","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":5752,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/paolodemariafinetrattoria","foodTypes":"이탈리아식","latitude":37.5727556539163,"longitude":126.936890577525,"bookYear":"2024","nameKR":"파올로데마리아","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":172,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.493389,"longitude":127.032278,"bookYear":"2024","nameKR":"대려도","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":12482,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.52101,"longitude":127.0361,"bookYear":"2024","nameKR":"투뿔등심","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":27906,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wolfgangssteakhousekorea","foodTypes":"기타","latitude":37.52447,"longitude":127.0415,"bookYear":"2024","nameKR":"울프강스테이크하우스","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":3065,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.67699,"longitude":127.0442,"bookYear":"2024","nameKR":"무수옥","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":6705,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.54153,"longitude":126.9421,"bookYear":"2024","nameKR":"성우서서갈비","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":28257,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.549353,"longitude":126.918942,"bookYear":"2024","nameKR":"빠넬로","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":1078,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.502187,"longitude":127.056394,"bookYear":"2024","nameKR":"대치정육식당","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":1230,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.51076,"longitude":127.0239,"bookYear":"2024","nameKR":"원강","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":1401,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.56834,"longitude":126.9305,"bookYear":"2024","nameKR":"목란","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":6869,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/spaccanapoliseoul","foodTypes":"이탈리아식","latitude":37.54888,"longitude":126.9156,"bookYear":"2024","nameKR":"스파카나폴리","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":7927,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.55062,"longitude":126.9191,"bookYear":"2024","nameKR":"이치류","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27567,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/imperato_/","foodTypes":"이탈리아식","latitude":37.521061,"longitude":127.104461,"bookYear":"2024","nameKR":"임뻬라또","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":29305,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/moltobene.seoul/","foodTypes":"이탈리아식","latitude":37.546217,"longitude":126.91664,"bookYear":"2024","nameKR":"몰토베네","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":29227,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.553806747709,"longitude":126.918422364162,"bookYear":"2024","nameKR":"진진가연","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":4800,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4803175879078,"longitude":126.95018578679264,"bookYear":"2024","nameKR":"황소곱창","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":12425,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.50186,"longitude":127.0105,"bookYear":"2024","nameKR":"마루심","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":6814,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tuktuknoodle/","foodTypes":"아시아식","latitude":37.565147,"longitude":126.924014,"bookYear":"2024","nameKR":"툭툭누들타이","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28318,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.542049,"longitude":126.950949,"bookYear":"2024","nameKR":"여명","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":9164,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중남미식","latitude":37.53457,"longitude":126.9902,"bookYear":"2024","nameKR":"바토스","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31069,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5338250009562,"longitude":126.992128689959,"bookYear":"2024","nameKR":"쟈니덤플링","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31949,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5263661402159,"longitude":127.030258157623,"bookYear":"2024","nameKR":"가담","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31855,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/namyeong_don","foodTypes":"한식(육류)","latitude":37.5427493719708,"longitude":126.973775844291,"bookYear":"2024","nameKR":"남영돈","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31007,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wolhwa.boramae/","foodTypes":"한식(육류)","latitude":37.4916283438844,"longitude":126.926302976249,"bookYear":"2024","nameKR":"월화고기(보라매점)","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":31050,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5250892597473,"longitude":127.032226965053,"bookYear":"2024","nameKR":"오스테리아꼬또","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32220,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5252513808129,"longitude":127.032770047864,"bookYear":"2024","nameKR":"붓처스컷(도산점)","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31295,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ristorante_bottarga/","foodTypes":"이탈리아식","latitude":37.5267388362975,"longitude":127.034999358912,"bookYear":"2024","nameKR":"보타르가","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33632,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/miu_84/","foodTypes":"한식(육류)","latitude":37.5247411726121,"longitude":127.019644043561,"bookYear":"2024","nameKR":"미우","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31899,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/uovopastabar/","foodTypes":"이탈리아식","latitude":37.4819218756209,"longitude":127.043796290477,"bookYear":"2024","nameKR":"우오보","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":29716,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/godosik92/","foodTypes":"한식(육류)","latitude":37.510353720640786,"longitude":127.10865792523055,"bookYear":"2024","nameKR":"고도식","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":29569,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gold_pig1982/","foodTypes":"한식(육류)","latitude":37.55709299058367,"longitude":127.01166616636434,"bookYear":"2024","nameKR":"금돼지식당","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":30027,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mongtan_official/","foodTypes":"한식(육류)","latitude":37.53599611626749,"longitude":126.97224352471865,"bookYear":"2024","nameKR":"몽탄","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31285,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4887950669684,"longitude":127.013660090025,"bookYear":"2024","nameKR":"육통령 ","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":30427,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.5203166598331,"longitude":127.03533877402701,"bookYear":"2024","nameKR":"영동장어","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":1602,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.67558052201009,"longitude":128.70070969505048,"bookYear":"2024","nameKR":"납작식당","ribbonType":"2","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1668,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.659554789284634,"longitude":127.83129507020915,"bookYear":"2024","nameKR":"양지말화로구이","ribbonType":"2","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":2048,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pnixresto/","foodTypes":"한식(일반한식)","latitude":35.196068,"longitude":128.084463,"bookYear":"2024","nameKR":"천황식당","ribbonType":"2","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":2131,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1440360460934,"longitude":126.839136628554,"bookYear":"2024","nameKR":"대광식당","ribbonType":"2","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":2996,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.679559,"longitude":126.850237,"bookYear":"2024","nameKR":"소복갈비","ribbonType":"2","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":2280,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.148042,"longitude":129.11369,"bookYear":"2024","nameKR":"언양불고기부산집","ribbonType":"2","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":2294,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yetnalomakjib.official/","foodTypes":"한식(육류)","latitude":35.108997,"longitude":129.019853,"bookYear":"2024","nameKR":"옛날오막집","ribbonType":"2","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":2449,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sovely_2007/","foodTypes":"한식(육류)","latitude":35.32174720440017,"longitude":126.98199922058063,"bookYear":"2024","nameKR":"신식당","ribbonType":"2","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":4523,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.565334,"longitude":129.122291,"bookYear":"2024","nameKR":"언양기와집불고기","ribbonType":"2","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":11131,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.071622,"longitude":129.057311,"bookYear":"2024","nameKR":"목장원","ribbonType":"2","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":6149,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.274159,"longitude":127.028696,"bookYear":"2024","nameKR":"가보정","ribbonType":"2","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":28185,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.830197,"longitude":128.615152,"bookYear":"2024","nameKR":"투웰브키친","ribbonType":"2","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":29088,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.161575,"longitude":129.166947,"bookYear":"2024","nameKR":"거대갈비","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":29388,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/goryukdang/ ","foodTypes":"한식(육류)","latitude":34.970555,"longitude":127.526148,"bookYear":"2024","nameKR":"고력당","ribbonType":"2","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":17765,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.07085479161655,"longitude":126.99935094414914,"bookYear":"2024","nameKR":"오케이목장가든","ribbonType":"2","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":28198,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.7925688416434,"longitude":126.382296383204,"bookYear":"2024","nameKR":"영암식당","ribbonType":"2","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":33208,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.1601348208773,"longitude":129.154492375182,"bookYear":"2024","nameKR":"부우사안","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":33582,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.3451359023538,"longitude":126.202325769963,"bookYear":"2024","nameKR":"연리지가든","ribbonType":"2","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":32493,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.8873110205234,"longitude":127.763485854877,"bookYear":"2024","nameKR":"우성닭갈비","ribbonType":"2","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":34797,"bookStatus":"승인","websiteInstagram":"http://www.instagram.com/baohaus__","foodTypes":"중식","latitude":35.1548859834433,"longitude":129.063342492016,"bookYear":"2024","nameKR":"바오하우스","ribbonType":"2","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":891,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5776,"longitude":126.9717,"bookYear":"2024","nameKR":"토속촌","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":950,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hakatabunko_official","foodTypes":"일식","latitude":37.54886,"longitude":126.9237,"bookYear":"2024","nameKR":"하카다분코","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":954,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hansung.kal/","foodTypes":"한식(일반한식)","latitude":37.5206301397046,"longitude":127.036162554013,"bookYear":"2024","nameKR":"한성칼국수","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":1000,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/koraebul_official/","foodTypes":"한식(어패류)","latitude":37.49624,"longitude":127.0339,"bookYear":"2024","nameKR":"고래불","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":1104,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.53988,"longitude":126.9433,"bookYear":"2024","nameKR":"마포옥","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":710,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5971,"longitude":126.9843,"bookYear":"2024","nameKR":"삼청각한식당","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":871,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5787470168012,"longitude":126.985411258625,"bookYear":"2024","nameKR":"큰기와집","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":57,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gombawie/","foodTypes":"한식(육류)","latitude":37.51488,"longitude":127.0588,"bookYear":"2024","nameKR":"곰바위","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":178,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.57269,"longitude":126.960904,"bookYear":"2024","nameKR":"대성집","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":338,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.52189,"longitude":127.04,"bookYear":"2024","nameKR":"무등산","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":378,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.473052,"longitude":127.039139,"bookYear":"2024","nameKR":"배나무골오리집","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":3805,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.564483681788396,"longitude":126.98018321530917,"bookYear":"2024","nameKR":"홍연","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":4652,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.52779,"longitude":127.0325,"bookYear":"2024","nameKR":"한일관","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":1533,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/romantic_owner_chef/","foodTypes":"이탈리아식","latitude":37.58179,"longitude":126.9714,"bookYear":"2024","nameKR":"까델루뽀","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":3978,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.56677,"longitude":126.976,"bookYear":"2024","nameKR":"컨퍼런스하우스달개비","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":3648,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tteurak_jk/","foodTypes":"한식(육류)","latitude":37.5244,"longitude":127.0555,"bookYear":"2024","nameKR":"뜨락","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":4626,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.49322,"longitude":126.9893,"bookYear":"2024","nameKR":"주","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":5076,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.51892,"longitude":127.0291,"bookYear":"2024","nameKR":"평양면옥(논현점)","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":25265,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.50926,"longitude":127.0241,"bookYear":"2024","nameKR":"늘푸른정육식당","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":5185,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.51017,"longitude":127.126,"bookYear":"2024","nameKR":"봉피양(방이점)","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":4855,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.48083,"longitude":126.9491,"bookYear":"2024","nameKR":"갯바위","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":4861,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.50964,"longitude":127.0579,"bookYear":"2024","nameKR":"시추안하우스","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":29466,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hyunudon/","foodTypes":"일식","latitude":37.517039793687886,"longitude":127.02430622206757,"bookYear":"2024","nameKR":"현우동","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30176,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.49768509612491,"longitude":126.99870629466835,"bookYear":"2024","nameKR":"라씨에트","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":29709,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/haevichidining/","foodTypes":"미국식","latitude":37.57149756466348,"longitude":126.98365878856444,"bookYear":"2024","nameKR":"마이클바이해비치","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":28514,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.516107,"longitude":127.03603,"bookYear":"2024","nameKR":"진미평양냉면","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32021,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.545626109663,"longitude":127.066210285087,"bookYear":"2024","nameKR":"남한강민물매운탕","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":29600,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.53666611513179,"longitude":126.99909484024475,"bookYear":"2024","nameKR":"휴135","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30251,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/madamthai_/","foodTypes":"아시아식","latitude":37.5195011942199,"longitude":127.023667106026,"bookYear":"2024","nameKR":"마담타이","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30509,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yoojungnakji_1966/","foodTypes":"한식(어패류)","latitude":37.56762919252033,"longitude":126.97604409036988,"bookYear":"2024","nameKR":"무교동유정낙지","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":32870,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/essence.seoul/","foodTypes":"프랑스식","latitude":37.4818351712773,"longitude":127.01336700925,"bookYear":"2024","nameKR":"에쌍스","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":32142,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5138710747422,"longitude":127.106364392884,"bookYear":"2024","nameKR":"모던눌랑라이브","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33410,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ka.ky.suk/","foodTypes":"일식","latitude":37.5582802556099,"longitude":126.924747511429,"bookYear":"2024","nameKR":"락희돈","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32502,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mott32seoul/","foodTypes":"중식","latitude":37.5039351769908,"longitude":127.002413006799,"bookYear":"2024","nameKR":"모트32서울","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":32915,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/the_bjgalbi/","foodTypes":"한식(육류)","latitude":37.5260149160433,"longitude":127.047084917624,"bookYear":"2024","nameKR":"벽제갈비더청담","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32796,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pao_pao_chang/","foodTypes":"바/주점","latitude":37.527861899218,"longitude":127.036312210926,"bookYear":"2024","nameKR":"보보식당","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33369,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5250678429106,"longitude":127.040349845762,"bookYear":"2024","nameKR":"우정","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":388,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4891,"longitude":127.0312,"bookYear":"2024","nameKR":"버드나무집","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":946,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hadongkwan_official/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.56441,"longitude":126.9851,"bookYear":"2024","nameKR":"하동관","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":7173,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5366074435475,"longitude":126.997831352071,"bookYear":"2024","nameKR":"오월의종","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":707,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.54745,"longitude":126.9455,"bookYear":"2024","nameKR":"을밀대","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":186,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.58069,"longitude":126.9805,"bookYear":"2024","nameKR":"더레스토랑","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":1284,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.567332,"longitude":126.981994,"bookYear":"2024","nameKR":"충무집","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":1345,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.57275,"longitude":126.9733,"bookYear":"2024","nameKR":"평안도만두집","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":1392,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.58939,"longitude":126.9989,"bookYear":"2024","nameKR":"명륜손칼국수","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":1015,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lasaveur_seoul","foodTypes":"프랑스식","latitude":37.49705,"longitude":126.9985,"bookYear":"2024","nameKR":"라싸브어","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":1051,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bistecca_kr","foodTypes":"이탈리아식","latitude":37.540037,"longitude":126.992072,"bookYear":"2024","nameKR":"비스떼까","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":1089,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.577089,"longitude":126.980272,"bookYear":"2024","nameKR":"두가헌","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":1160,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.54538,"longitude":127.0853,"bookYear":"2024","nameKR":"서북면옥","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":813,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.571716,"longitude":126.979423,"bookYear":"2024","nameKR":"청진옥","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":855,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.508579,"longitude":127.064168,"bookYear":"2024","nameKR":"코너스톤","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":914,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.56487,"longitude":126.9904,"bookYear":"2024","nameKR":"평래옥","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":915,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56302915750704,"longitude":127.0064696447095,"bookYear":"2024","nameKR":"평양면옥","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":931,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/prosoycrab/","foodTypes":"한식(어패류)","latitude":37.5145780257989,"longitude":127.019168572867,"bookYear":"2024","nameKR":"프로간장게장","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":941,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.48484,"longitude":127.0952,"bookYear":"2024","nameKR":"필경재","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":942,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56034,"longitude":126.9969,"bookYear":"2024","nameKR":"필동면옥","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":952,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/koreahouse_official/","foodTypes":"한식(일반한식)","latitude":37.56022530490597,"longitude":126.99449942354792,"bookYear":"2024","nameKR":"한국의집","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":565,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.5833552066204,"longitude":126.98134071176,"bookYear":"2024","nameKR":"아따블르","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":636,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.57132,"longitude":126.991513,"bookYear":"2024","nameKR":"영춘옥","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":684,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56817,"longitude":126.9988,"bookYear":"2024","nameKR":"우래옥","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":772,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.58706,"longitude":126.969378,"bookYear":"2024","nameKR":"지화자","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":511,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.473216,"longitude":127.048669,"bookYear":"2024","nameKR":"소호정","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":514,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.59307,"longitude":126.966,"bookYear":"2024","nameKR":"자하손만두","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":530,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.57648,"longitude":126.9915,"bookYear":"2024","nameKR":"순라길","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":305,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.52789,"longitude":127.0389,"bookYear":"2024","nameKR":"만두집","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":336,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5677,"longitude":126.9798,"bookYear":"2024","nameKR":"무교동북어국집","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":97,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.497127,"longitude":126.985225,"bookYear":"2024","nameKR":"기꾸","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":125,"bookStatus":"승인","websiteInstagram":"","foodTypes":"미국식","latitude":37.564372,"longitude":126.980016,"bookYear":"2024","nameKR":"나인스게이트","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":212,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5786280284849,"longitude":126.980087994867,"bookYear":"2024","nameKR":"두레","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":246,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.540522,"longitude":126.996446,"bookYear":"2024","nameKR":"라쿠치나","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":1431,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5251913154781,"longitude":126.929112756574,"bookYear":"2024","nameKR":"리스토란테에오","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":1505,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.55055,"longitude":126.9558,"bookYear":"2024","nameKR":"진미식당","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":3506,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lottehotel_seoul/","foodTypes":"일식","latitude":37.56461,"longitude":126.9809,"bookYear":"2024","nameKR":"모모야마","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":3686,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.51101489356036,"longitude":127.05390190668514,"bookYear":"2024","nameKR":"외고집설렁탕","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":1587,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.50503,"longitude":127.055,"bookYear":"2024","nameKR":"몰타참치","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":3824,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.52476,"longitude":127.0446,"bookYear":"2024","nameKR":"비금도","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":4692,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.47968,"longitude":126.9547,"bookYear":"2024","nameKR":"성민양꼬치","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":4670,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.57811,"longitude":126.9861,"bookYear":"2024","nameKR":"깡통만두","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":4785,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.57386,"longitude":126.9832,"bookYear":"2024","nameKR":"발우공양","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":5050,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/goostk/","foodTypes":"기타","latitude":37.52288,"longitude":127.0206,"bookYear":"2024","nameKR":"구스테이크528","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":4963,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.55565,"longitude":127.1113,"bookYear":"2024","nameKR":"명월관","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":4872,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.48186,"longitude":127.0045,"bookYear":"2024","nameKR":"송강","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":4875,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.55863008236035,"longitude":127.1117068379481,"bookYear":"2024","nameKR":"금룡","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":4902,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.489557,"longitude":126.995052,"bookYear":"2024","nameKR":"만발","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":4856,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/subaru_soba/","foodTypes":"일식","latitude":37.4923799714446,"longitude":126.986130514897,"bookYear":"2024","nameKR":"스바루","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":5865,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.503003,"longitude":127.038134,"bookYear":"2024","nameKR":"봉우리","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":6364,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.552014,"longitude":126.927861,"bookYear":"2024","nameKR":"미로식당","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6030,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5198,"longitude":126.9401,"bookYear":"2024","nameKR":"슈치쿠","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":6783,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushi_sunsoo/","foodTypes":"일식","latitude":37.52283,"longitude":127.0364,"bookYear":"2024","nameKR":"스시선수","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":6949,"bookStatus":"승인","websiteInstagram":"","foodTypes":"컨템포러리","latitude":37.522953871348,"longitude":127.036013446545,"bookYear":"2024","nameKR":"류니끄","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":6896,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cuisson82.seoul/","foodTypes":"프랑스식","latitude":37.48158,"longitude":127.0447,"bookYear":"2024","nameKR":"뀌쏭82","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":6858,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.560088148067926,"longitude":126.9911266583733,"bookYear":"2024","nameKR":"그릴데미그라스","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":6465,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4849946106957,"longitude":127.029112829918,"bookYear":"2024","nameKR":"신가예촌","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":6438,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.56534,"longitude":126.981,"bookYear":"2024","nameKR":"라세느","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":7275,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yongsusan_biwon/","foodTypes":"한식(일반한식)","latitude":37.579407,"longitude":126.989116,"bookYear":"2024","nameKR":"용수산비원","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":12410,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5257986944472,"longitude":127.045659927706,"bookYear":"2024","nameKR":"쥬안","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":9379,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.55698,"longitude":127.0059,"bookYear":"2024","nameKR":"더파크뷰","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":9273,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.524733243328555,"longitude":127.00170595433845,"bookYear":"2024","nameKR":"댓잎갈비","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":9433,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.509132,"longitude":127.060851,"bookYear":"2024","nameKR":"하코네","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":9130,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/miuya_yangjae/","foodTypes":"일식","latitude":37.47399,"longitude":127.0354,"bookYear":"2024","nameKR":"미우야","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":13602,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5263441835022,"longitude":126.99610834939314,"bookYear":"2024","nameKR":"레스토랑오늘","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":12679,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.54682,"longitude":126.9132,"bookYear":"2024","nameKR":"교다이야","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":18362,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.56052,"longitude":126.9929,"bookYear":"2024","nameKR":"수엔190","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":20475,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kappo_akii/","foodTypes":"일식","latitude":37.522981,"longitude":127.036214,"bookYear":"2024","nameKR":"갓포아키","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":26930,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.53828,"longitude":127.0935,"bookYear":"2024","nameKR":"어반나이프","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":23751,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.50594,"longitude":127.0525,"bookYear":"2024","nameKR":"경천애인2237","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":26983,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.57044961338216,"longitude":127.00874326329739,"bookYear":"2024","nameKR":"BLT스테이크","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":27344,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.55165,"longitude":126.915,"bookYear":"2024","nameKR":"우동카덴","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26655,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shillahotels/","foodTypes":"한식(일반한식)","latitude":37.55698,"longitude":127.0059,"bookYear":"2024","nameKR":"라연","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":26954,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.53071,"longitude":126.9217,"bookYear":"2024","nameKR":"정인면옥","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":27562,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.51713,"longitude":127.0232,"bookYear":"2024","nameKR":"김수사","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":27853,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hwahaedang/","foodTypes":"한식(어패류)","latitude":37.52708629137116,"longitude":126.9169305449114,"bookYear":"2024","nameKR":"화해당","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":28116,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/superpan_seoul","foodTypes":"한식(일반한식)","latitude":37.5244390021596,"longitude":127.026839035907,"bookYear":"2024","nameKR":"수퍼판","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":28151,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5224466309117,"longitude":127.042700950101,"bookYear":"2024","nameKR":"세야스시","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28182,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.490921,"longitude":127.037987,"bookYear":"2024","nameKR":"이수부","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":27424,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/maison_loupblanc/","foodTypes":"바/주점","latitude":37.5256854191743,"longitude":126.96275666622,"bookYear":"2024","nameKR":"메종루블랑","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":27541,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5218417619336,"longitude":127.036895154956,"bookYear":"2024","nameKR":"스시코우지","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":28125,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cucciologroup/","foodTypes":"이탈리아식","latitude":37.5287911785354,"longitude":126.968673565462,"bookYear":"2024","nameKR":"쿠촐로서울","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":28245,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4801,"longitude":126.9827,"bookYear":"2024","nameKR":"흑산도홍어","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":28471,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5820958419415,"longitude":127.004326234581,"bookYear":"2024","nameKR":"핏제리아오","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":28470,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5832949237454,"longitude":127.00305727141,"bookYear":"2024","nameKR":"순대실록","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":28531,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.508245,"longitude":127.065467,"bookYear":"2024","nameKR":"중앙해장","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":28532,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.522977,"longitude":127.042515,"bookYear":"2024","nameKR":"더라운드","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28515,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.515834,"longitude":127.042281,"bookYear":"2024","nameKR":"봉밀가","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28456,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.509786,"longitude":127.057913,"bookYear":"2024","nameKR":"투뿔등심고담","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":28584,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.525231,"longitude":127.039135,"bookYear":"2024","nameKR":"몽고네","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":28957,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.55922,"longitude":127.003614,"bookYear":"2024","nameKR":"서울다이닝","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":28984,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/exquisine_seoul","foodTypes":"프랑스식","latitude":37.520999,"longitude":127.049653,"bookYear":"2024","nameKR":"익스퀴진","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28492,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.527044,"longitude":127.045747,"bookYear":"2024","nameKR":"파불라","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28958,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.573264,"longitude":126.989129,"bookYear":"2024","nameKR":"호반","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":28281,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.586207,"longitude":126.982048,"bookYear":"2024","nameKR":"만정","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":29178,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/butchelihouse/","foodTypes":"기타","latitude":37.536814,"longitude":127.012713,"bookYear":"2024","nameKR":"부첼리하우스","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":29057,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sunthebud_official/","foodTypes":"기타","latitude":37.52615,"longitude":127.041687,"bookYear":"2024","nameKR":"썬더버드","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29052,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.51375250260386,"longitude":127.10444609981751,"bookYear":"2024","nameKR":"스테이","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":29012,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.6065673142822,"longitude":126.970504064967,"bookYear":"2024","nameKR":"두레유","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":29186,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.540424,"longitude":126.946225,"bookYear":"2024","nameKR":"핑하오","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29132,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hulotte_seoul/","foodTypes":"프랑스식","latitude":37.5227679418531,"longitude":127.043405669306,"bookYear":"2024","nameKR":"윌로뜨","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29209,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.570539,"longitude":126.975391,"bookYear":"2024","nameKR":"유유안","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29210,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.570539,"longitude":126.975391,"bookYear":"2024","nameKR":"보칼리노","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29045,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/okdongsik/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.55262,"longitude":126.914492,"bookYear":"2024","nameKR":"옥동식","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":29602,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.509191510745374,"longitude":127.0608089555861,"bookYear":"2024","nameKR":"웨이루","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":29476,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/akirabackseoul/","foodTypes":"일식","latitude":37.57069965284965,"longitude":126.97540015123504,"bookYear":"2024","nameKR":"아키라백서울","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29256,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.49773542876355,"longitude":126.99444068210096,"bookYear":"2024","nameKR":"파스타포포","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":29427,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5221099525237,"longitude":127.045055412969,"bookYear":"2024","nameKR":"미토우","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29542,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.504147,"longitude":127.004603,"bookYear":"2024","nameKR":"더마고그릴","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":29346,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/restaurant_on/","foodTypes":"프랑스식","latitude":37.521946,"longitude":127.049384,"bookYear":"2024","nameKR":"레스토랑온","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29543,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.52294062837627,"longitude":127.03449836732372,"bookYear":"2024","nameKR":"스시시미즈","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29452,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/soseoul_hannam/","foodTypes":"한식(일반한식)","latitude":37.535220562541056,"longitude":127.00910573136069,"bookYear":"2024","nameKR":"소설한남","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":29471,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.56534516953803,"longitude":126.98100699814147,"bookYear":"2024","nameKR":"피에르가니에르서울","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":30072,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/flavorsofficial_/","foodTypes":"기타","latitude":37.503934456230404,"longitude":127.00241119724721,"bookYear":"2024","nameKR":"플레이버즈","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":29770,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.49949430360649,"longitude":127.02926287275582,"bookYear":"2024","nameKR":"강남진해장","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":29892,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.55863008236035,"longitude":127.1117068379481,"bookYear":"2024","nameKR":"모에기","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":29880,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.52542350241616,"longitude":127.03547241127296,"bookYear":"2024","nameKR":"임프레션","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30071,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/xescmenzl/","foodTypes":"기타","latitude":37.54106033675408,"longitude":127.05641286396366,"bookYear":"2024","nameKR":"세스크멘슬","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29827,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jue_seoul","foodTypes":"중식","latitude":37.53748713839805,"longitude":127.01279004754028,"bookYear":"2024","nameKR":"쥬에","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":29734,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/minu.c_dining","foodTypes":"이탈리아식","latitude":37.48170400540631,"longitude":127.04356958699984,"bookYear":"2024","nameKR":"미누씨","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":29916,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.556823649468,"longitude":126.90823736311545,"bookYear":"2024","nameKR":"소금집델리","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":30073,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tamayuraofficial/","foodTypes":"일식","latitude":37.503934456230404,"longitude":127.00241119724721,"bookYear":"2024","nameKR":"타마유라","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":30105,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/restaurantevett/","foodTypes":"컨템포러리","latitude":37.5234326965405,"longitude":127.036202029788,"bookYear":"2024","nameKR":"레스토랑에빗","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30195,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/souldining_seoul/","foodTypes":"한식(일반한식)","latitude":37.54536153663566,"longitude":126.98438418086488,"bookYear":"2024","nameKR":"소울다이닝","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30198,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":37.55006938601234,"longitude":127.00049792699866,"bookYear":"2024","nameKR":"페스타바이민구","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":30254,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5241263043193,"longitude":127.038982210697,"bookYear":"2024","nameKR":"윤서울","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30468,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.51999431896053,"longitude":127.05766846672014,"bookYear":"2024","nameKR":"더원","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":30459,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.522410597151854,"longitude":127.05431282319448,"bookYear":"2024","nameKR":"텐지몽","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30987,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5714966636936,"longitude":126.983659015157,"bookYear":"2024","nameKR":"중심","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":30638,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.517187824504305,"longitude":127.02297603119361,"bookYear":"2024","nameKR":"왕스덕","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30777,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4933167175702,"longitude":127.125036887944,"bookYear":"2024","nameKR":"명인밥상","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":31130,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.4822286276945,"longitude":127.045573477903,"bookYear":"2024","nameKR":"마누테라스","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":31482,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5709958592808,"longitude":126.978914477333,"bookYear":"2024","nameKR":"모던샤브하우스","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":31446,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5229398817454,"longitude":127.045484539744,"bookYear":"2024","nameKR":"무니","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31365,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5597323437561,"longitude":126.979523390315,"bookYear":"2024","nameKR":"팔레드신","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":31668,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.5222303672862,"longitude":127.043259315881,"bookYear":"2024","nameKR":"오와이","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31484,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.526074227507,"longitude":127.041233246229,"bookYear":"2024","nameKR":"오네뜨장","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31917,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/perigee_seoul/","foodTypes":"이탈리아식","latitude":37.5112491980008,"longitude":127.049479155493,"bookYear":"2024","nameKR":"페리지","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":32014,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.527629770888,"longitude":127.034918660011,"bookYear":"2024","nameKR":"스시하네","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32250,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5366661151313,"longitude":126.99909563226,"bookYear":"2024","nameKR":"스시상남","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":32136,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/iledecheongdam/","foodTypes":"한식(육류)","latitude":37.5246842905146,"longitude":127.041890313948,"bookYear":"2024","nameKR":"일드청담","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32059,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5714966636936,"longitude":126.983659015157,"bookYear":"2024","nameKR":"스시메르","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":32062,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cesta.seoul/","foodTypes":"미국식","latitude":37.5352206526869,"longitude":127.009105052515,"bookYear":"2024","nameKR":"세스타","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":32212,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yangchulseoul/","foodTypes":"기타","latitude":37.5166188678736,"longitude":127.032459279407,"bookYear":"2024","nameKR":"양출서울","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32353,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/minchul_kang_/","foodTypes":"프랑스식","latitude":37.5224534383828,"longitude":127.044517830428,"bookYear":"2024","nameKR":"강민철레스토랑","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32299,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gigas_seoul/","foodTypes":"유럽식","latitude":37.5569763923852,"longitude":126.978121332046,"bookYear":"2024","nameKR":"기가스","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":32285,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/allen_hyunmin_suh/","foodTypes":"프랑스식","latitude":37.5028813541774,"longitude":127.041356540268,"bookYear":"2024","nameKR":"알렌","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":32477,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sugimoto_omakase","foodTypes":"일식","latitude":37.57974107056,"longitude":126.982048950552,"bookYear":"2024","nameKR":"스기모토","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":32427,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shinkyesook/","foodTypes":"중식","latitude":37.5820958419415,"longitude":127.004326234581,"bookYear":"2024","nameKR":"계향각","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":32598,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/crab52_official/","foodTypes":"기타","latitude":37.510356849269,"longitude":127.061023782931,"bookYear":"2024","nameKR":"크랩52","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":32634,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5614211308706,"longitude":126.978778867088,"bookYear":"2024","nameKR":"양미옥(남대문점)","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":32685,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5354656162861,"longitude":126.998534343562,"bookYear":"2024","nameKR":"구찌오스테리아서울","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":32666,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/aruhi_specialday/","foodTypes":"일식","latitude":37.521470230225,"longitude":126.924971147893,"bookYear":"2024","nameKR":"아루히니와","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":32664,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/el__pic/","foodTypes":"기타","latitude":37.5223327912275,"longitude":127.046626942892,"bookYear":"2024","nameKR":"엘픽","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32608,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/solbam_restaurant/","foodTypes":"한식(일반한식)","latitude":37.5154927557092,"longitude":127.0341900991,"bookYear":"2024","nameKR":"솔밤","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32772,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jueun_restaurant","foodTypes":"한식(일반한식)","latitude":37.5729199136655,"longitude":126.970063182815,"bookYear":"2024","nameKR":"주은","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":33039,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/l0_dining/","foodTypes":"컨템포러리","latitude":37.5260334010541,"longitude":126.962235987145,"bookYear":"2024","nameKR":"레벨제로","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32976,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/matthew.seoul/","foodTypes":"컨템포러리","latitude":37.5392021126229,"longitude":127.061988341323,"bookYear":"2024","nameKR":"매튜","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32924,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/filleter.fish/","foodTypes":"기타","latitude":37.5204615260968,"longitude":127.039643755702,"bookYear":"2024","nameKR":"블루바이필레터","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"","status":"ACTIVE"},
        {"id":33366,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/restaurant.vinho/","foodTypes":"기타","latitude":37.518458048048,"longitude":127.038022514319,"bookYear":"2024","nameKR":"빈호","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":33482,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/buvetteseoul/","foodTypes":"기타","latitude":37.5253215967063,"longitude":127.028865132152,"bookYear":"2024","nameKR":"부베트","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33224,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5028813541774,"longitude":127.041356540268,"bookYear":"2024","nameKR":"스시아라미즈","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":33220,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gonichef/","foodTypes":"일식","latitude":37.5213277654859,"longitude":127.037670238659,"bookYear":"2024","nameKR":"고니","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":33693,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sicili_liheungju/","foodTypes":"이탈리아식","latitude":37.5376679399283,"longitude":127.000499654869,"bookYear":"2024","nameKR":"시칠리","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":3552,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.700602,"longitude":126.665046,"bookYear":"2024","nameKR":"고덕갈비","ribbonType":"2","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":19520,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gogiri.gram/","foodTypes":"한식(면류)","latitude":37.35999570353232,"longitude":127.04009017799066,"bookYear":"2024","nameKR":"고기리막국수","ribbonType":"2","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":32584,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.160201591128,"longitude":129.166579258799,"bookYear":"2024","nameKR":"규우정","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":2499,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.032238,"longitude":126.717055,"bookYear":"2024","nameKR":"하얀집","ribbonType":"2","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":2477,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.207976,"longitude":126.986722,"bookYear":"2024","nameKR":"전통식당","ribbonType":"2","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":5916,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.563714,"longitude":128.724094,"bookYear":"2024","nameKR":"옥야식당","ribbonType":"2","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":1682,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.673591,"longitude":128.709078,"bookYear":"2024","nameKR":"용평회관","ribbonType":"2","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":2543,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.818302,"longitude":127.149197,"bookYear":"2024","nameKR":"왱이콩나물국밥","ribbonType":"2","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":6412,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.817098392419595,"longitude":127.09331266871277,"bookYear":"2024","nameKR":"궁","ribbonType":"2","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":1848,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sambuja1983/","foodTypes":"한식(육류)","latitude":37.2736041648046,"longitude":127.052603103031,"bookYear":"2024","nameKR":"삼부자갈비","ribbonType":"2","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":27454,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushi.hoshikai/ ","foodTypes":"일식","latitude":33.487826,"longitude":126.51764,"bookYear":"2024","nameKR":"스시호시카이","ribbonType":"2","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32621,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.2799601701037,"longitude":127.443704459258,"bookYear":"2024","nameKR":"우동선","ribbonType":"2","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":2407,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.950087,"longitude":127.490202,"bookYear":"2024","nameKR":"대원식당","ribbonType":"2","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":7100,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.395169,"longitude":127.077575,"bookYear":"2024","nameKR":"능라도","ribbonType":"2","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":31236,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.153006221653,"longitude":129.059606320437,"bookYear":"2024","nameKR":"거대곰탕","ribbonType":"2","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":31849,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5249785082314,"longitude":127.456202066521,"bookYear":"2024","nameKR":"다안토니오","ribbonType":"2","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":32463,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lab24_by_kumuda/","foodTypes":"프랑스식","latitude":35.177434696318,"longitude":129.197177089995,"bookYear":"2024","nameKR":"랩24바이쿠무다","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":32587,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.1600663074705,"longitude":129.163127599383,"bookYear":"2024","nameKR":"팔레드신","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":32932,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/doban_fnb/","foodTypes":"한식(일반한식)","latitude":34.639145158068,"longitude":126.773555426022,"bookYear":"2024","nameKR":"도반","ribbonType":"2","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":34003,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bawipastabar/","foodTypes":"이탈리아식","latitude":37.5391327781277,"longitude":127.061562618594,"bookYear":"2024","nameKR":"바위파스타바성수","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34659,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/palate.busan/ ","foodTypes":"프랑스식","latitude":35.1316369588506,"longitude":129.119770650931,"bookYear":"2024","nameKR":"팔레트","ribbonType":"2","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":36407,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lee_sookil/","foodTypes":"일식","latitude":35.8599603978581,"longitude":128.638355828115,"bookYear":"2024","nameKR":"오공공구일일","ribbonType":"2","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":5035,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mamangateau_/","foodTypes":"디저트/차/베이커리","latitude":37.52223,"longitude":127.0215,"bookYear":"2024","nameKR":"마망갸또","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":26729,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffeeplace.go/","foodTypes":"디저트/차/베이커리","latitude":35.841439,"longitude":129.211818,"bookYear":"2024","nameKR":"커피플레이스","ribbonType":"2","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":28955,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.510092,"longitude":127.024074,"bookYear":"2024","nameKR":"배꼽집","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":8977,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.841192,"longitude":128.420307,"bookYear":"2024","nameKR":"부일식당","ribbonType":"2","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":2560,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.81242,"longitude":127.146598,"bookYear":"2024","nameKR":"현대옥남부시장점","ribbonType":"2","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":3390,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.84062,"longitude":128.421698,"bookYear":"2024","nameKR":"수정식당","ribbonType":"2","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":1687,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.8787,"longitude":127.725366,"bookYear":"2024","nameKR":"원조숯불닭불고기집","ribbonType":"2","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":31660,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.8920600132365,"longitude":128.824370835597,"bookYear":"2024","nameKR":"철뚝소머리국밥","ribbonType":"2","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":6216,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.49556096426278,"longitude":128.7494525258688,"bookYear":"2024","nameKR":"설봉돼지국밥","ribbonType":"2","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":4983,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.868526,"longitude":128.606687,"bookYear":"2024","nameKR":"왕거미식당","ribbonType":"2","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":29658,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.82140686452656,"longitude":127.10165265023547,"bookYear":"2024","nameKR":"스시요헤이","ribbonType":"2","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2568,"bookStatus":"승인","websiteInstagram":"http://instargram.com/jeju.daewoojung/","foodTypes":"한식(어패류)","latitude":33.4988947828616,"longitude":126.519578083071,"bookYear":"2024","nameKR":"대우정","ribbonType":"2","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":31602,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_____brillant/","foodTypes":"디저트/차/베이커리","latitude":35.2217566034894,"longitude":129.083561526107,"bookYear":"2024","nameKR":"브리앙","ribbonType":"2","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":26730,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.3527262476506,"longitude":127.372458280442,"bookYear":"2024","nameKR":"톨드어스토리","ribbonType":"2","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":16004,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.219232,"longitude":129.086487,"bookYear":"2024","nameKR":"모모스커피","ribbonType":"2","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":1684,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.6958027617453,"longitude":128.46288084444,"bookYear":"2024","nameKR":"운두령","ribbonType":"2","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":35267,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1572036560166,"longitude":129.144817207267,"bookYear":"2024","nameKR":"하레마","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":32532,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yulling_official/","foodTypes":"한식(육류)","latitude":35.1588665231625,"longitude":129.170591418833,"bookYear":"2024","nameKR":"율링","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":28567,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6720891098678,"longitude":126.80704892127,"bookYear":"2024","nameKR":"동무밥상","ribbonType":"2","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":3416,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.670973,"longitude":126.747625,"bookYear":"2024","nameKR":"서동관","ribbonType":"2","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":28169,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/terreno_jeju/","foodTypes":"유럽식","latitude":33.2441693950141,"longitude":126.405574367231,"bookYear":"2024","nameKR":"떼레노","ribbonType":"2","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":2104,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yosukgung.kr/","foodTypes":"한식(일반한식)","latitude":35.830282,"longitude":129.216803,"bookYear":"2024","nameKR":"요석궁1779","ribbonType":"2","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2566,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.230103,"longitude":126.308753,"bookYear":"2024","nameKR":"남경미락","ribbonType":"2","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":6854,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.70402108029292,"longitude":126.21219213159618,"bookYear":"2024","nameKR":"화해당","ribbonType":"2","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":1670,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.1224785517472,"longitude":128.576128999955,"bookYear":"2024","nameKR":"영광정메밀국수","ribbonType":"2","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":3239,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.260506,"longitude":128.261105,"bookYear":"2024","nameKR":"주천묵집","ribbonType":"2","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":6784,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.983407,"longitude":126.742245,"bookYear":"2024","nameKR":"게눈감추듯","ribbonType":"2","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":4378,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.23475960865856,"longitude":127.70886974015433,"bookYear":"2024","nameKR":"강천매운탕","ribbonType":"2","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":29365,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffeetemple_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.47816384943728,"longitude":126.57024606687517,"bookYear":"2024","nameKR":"커피템플","ribbonType":"2","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":22071,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/skpinx_restaurant/","foodTypes":"한식(일반한식)","latitude":33.3014096689889,"longitude":126.38810751849,"bookYear":"2024","nameKR":"비오토피아레스토랑","ribbonType":"2","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":19598,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8580913894178,"longitude":128.632793463062,"bookYear":"2024","nameKR":"정이품","ribbonType":"2","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":1973,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.73097391295375,"longitude":127.04753134428825,"bookYear":"2024","nameKR":"평양면옥","ribbonType":"2","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":2411,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.78918,"longitude":126.384937,"bookYear":"2024","nameKR":"덕인집","ribbonType":"2","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":30139,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/whitechristmas_restaurant/ ","foodTypes":"프랑스식","latitude":36.63083110629392,"longitude":127.4248595383393,"bookYear":"2024","nameKR":"화이트크리스마스","ribbonType":"2","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":5924,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.502458,"longitude":129.376894,"bookYear":"2024","nameKR":"고래명가","ribbonType":"2","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":2622,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.228352,"longitude":126.307705,"bookYear":"2024","nameKR":"진미명가","ribbonType":"2","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":2267,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.204619,"longitude":129.083825,"bookYear":"2024","nameKR":"동래할매파전","ribbonType":"2","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":1634,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.864588,"longitude":128.842447,"bookYear":"2024","nameKR":"보헤미안박이추커피","ribbonType":"2","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":28430,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chez_roll/","foodTypes":"디저트/차/베이커리","latitude":37.5975600326635,"longitude":127.372518055161,"bookYear":"2024","nameKR":"쉐즈롤","ribbonType":"2","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":28164,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":33.323322,"longitude":126.844501,"bookYear":"2024","nameKR":"밀리우","ribbonType":"2","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":5503,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.154195,"longitude":129.146306,"bookYear":"2024","nameKR":"이레옥","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":22069,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.957126,"longitude":126.688526,"bookYear":"2024","nameKR":"지미원","ribbonType":"2","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":9445,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.156853,"longitude":129.056647,"bookYear":"2024","nameKR":"무궁화","ribbonType":"2","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":3199,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.160192,"longitude":129.165253,"bookYear":"2024","nameKR":"사까에","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":31023,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.1613327099223,"longitude":129.168013841974,"bookYear":"2024","nameKR":"차오란","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":29385,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.438028,"longitude":126.456713,"bookYear":"2024","nameKR":"임페리얼트레져","ribbonType":"2","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":7963,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.156854,"longitude":129.05579,"bookYear":"2024","nameKR":"도림","ribbonType":"2","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":6955,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.160415,"longitude":129.154677,"bookYear":"2024","nameKR":"동백섬횟집","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":4081,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.160192,"longitude":129.165253,"bookYear":"2024","nameKR":"남풍","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":2392,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.927533,"longitude":126.390205,"bookYear":"2024","nameKR":"구로횟집","ribbonType":"2","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":31667,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/begoing22","foodTypes":"일식","latitude":35.1483944403014,"longitude":129.11385120473,"bookYear":"2024","nameKR":"동경밥상","ribbonType":"2","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":2412,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1580995963383,"longitude":126.346630107925,"bookYear":"2024","nameKR":"도리포횟집","ribbonType":"2","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":2511,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/crab.gunsan/","foodTypes":"한식(어패류)","latitude":35.983019,"longitude":126.771916,"bookYear":"2024","nameKR":"계곡가든","ribbonType":"2","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":2389,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.721717,"longitude":127.721883,"bookYear":"2024","nameKR":"경도회관","ribbonType":"2","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":32449,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1570058452744,"longitude":129.125908288209,"bookYear":"2024","nameKR":"은해갈치","ribbonType":"2","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":14944,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bibibidang/","foodTypes":"디저트/차/베이커리","latitude":35.162483,"longitude":129.185075,"bookYear":"2024","nameKR":"비비비당","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":2396,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.81355166600711,"longitude":126.44763035873788,"bookYear":"2024","nameKR":"금메달식당","ribbonType":"2","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":22714,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.156058,"longitude":129.060001,"bookYear":"2024","nameKR":"블랙업커피","ribbonType":"2","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":2459,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7861166241455,"longitude":126.384070759305,"bookYear":"2024","nameKR":"영란횟집","ribbonType":"2","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":2705,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.982977,"longitude":128.370689,"bookYear":"2024","nameKR":"박쏘가리","ribbonType":"2","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":1686,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.9295173983054,"longitude":127.781207476335,"bookYear":"2024","nameKR":"샘밭막국수","ribbonType":"2","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":1709,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.146044,"longitude":127.308652,"bookYear":"2024","nameKR":"철원막국수","ribbonType":"2","zone2_1":"강원","zone2_2":"철원군","status":"ACTIVE"},
        {"id":3378,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.022667,"longitude":128.175344,"bookYear":"2024","nameKR":"청풍떡갈비","ribbonType":"2","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":2108,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.658422,"longitude":128.12788,"bookYear":"2024","nameKR":"원조진남매운탕","ribbonType":"2","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":2059,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.860876,"longitude":128.425707,"bookYear":"2024","nameKR":"굴향토집","ribbonType":"2","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":2605,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/osulloc_official/","foodTypes":"디저트/차/베이커리","latitude":33.3059189405945,"longitude":126.289374359219,"bookYear":"2024","nameKR":"오설록","ribbonType":"2","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":5310,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.327735,"longitude":127.427271,"bookYear":"2024","nameKR":"성심당","ribbonType":"2","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":2890,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.831605,"longitude":128.000753,"bookYear":"2024","nameKR":"우리식당","ribbonType":"2","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":1679,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.198991993291386,"longitude":128.5829082861216,"bookYear":"2024","nameKR":"춘선네","ribbonType":"2","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":5468,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.80501482578532,"longitude":127.8194281233136,"bookYear":"2024","nameKR":"오십년할머니집","ribbonType":"2","zone2_1":"충북/세종","zone2_2":"괴산군","status":"ACTIVE"},
        {"id":1642,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.639295,"longitude":128.560994,"bookYear":"2024","nameKR":"부일식당","ribbonType":"2","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":9309,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":33.247558,"longitude":126.408116,"bookYear":"2024","nameKR":"더파크뷰","ribbonType":"2","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":30063,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/podohotel_official/ ","foodTypes":"한식(일반한식)","latitude":33.317944,"longitude":126.387504,"bookYear":"2024","nameKR":"포도호텔레스토랑","ribbonType":"2","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":5428,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.009937,"longitude":127.867427,"bookYear":"2024","nameKR":"중앙탑초가집","ribbonType":"2","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":2984,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.651966,"longitude":126.942001,"bookYear":"2024","nameKR":"백학정","ribbonType":"2","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":1631,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.293026,"longitude":128.541766,"bookYear":"2024","nameKR":"백촌막국수","ribbonType":"2","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":5469,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.80595,"longitude":127.81752,"bookYear":"2024","nameKR":"괴산매운탕","ribbonType":"2","zone2_1":"충북/세종","zone2_2":"괴산군","status":"ACTIVE"},
        {"id":1750,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.495207,"longitude":127.301134,"bookYear":"2024","nameKR":"원조분원붕어찜","ribbonType":"2","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":2089,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.87285234473955,"longitude":128.5257645695902,"bookYear":"2024","nameKR":"서부냉면","ribbonType":"2","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":3604,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.209208,"longitude":127.463339,"bookYear":"2024","nameKR":"목화식당","ribbonType":"2","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":12457,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.167807,"longitude":129.132831,"bookYear":"2024","nameKR":"아오모리","ribbonType":"2","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":2546,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.800584,"longitude":127.132166,"bookYear":"2024","nameKR":"전라회관","ribbonType":"2","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":3359,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.25878303148087,"longitude":127.03218609766743,"bookYear":"2024","nameKR":"유치회관","ribbonType":"2","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":1972,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.385209,"longitude":127.13449,"bookYear":"2024","nameKR":"평양면옥","ribbonType":"2","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":2487,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.647106,"longitude":126.784046,"bookYear":"2024","nameKR":"청자골종가집","ribbonType":"2","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":2980,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.86504217758519,"longitude":127.11365735179528,"bookYear":"2024","nameKR":"호남각","ribbonType":"2","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":1640,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.88166,"longitude":127.749641,"bookYear":"2024","nameKR":"부안막국수","ribbonType":"2","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":1768,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.537252,"longitude":127.302404,"bookYear":"2024","nameKR":"기와집순두부조안본점","ribbonType":"2","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":2971,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.373402,"longitude":127.141188,"bookYear":"2024","nameKR":"남원집","ribbonType":"2","zone2_1":"전북","zone2_2":"순창군","status":"ACTIVE"},
        {"id":1681,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.223973,"longitude":128.367382,"bookYear":"2024","nameKR":"용바위식당","ribbonType":"2","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":2191,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.69458471340069,"longitude":128.4457832805499,"bookYear":"2024","nameKR":"원조현풍박소선할매집곰탕","ribbonType":"2","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":12445,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.754579,"longitude":126.293964,"bookYear":"2024","nameKR":"바다꽃게장","ribbonType":"2","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":2395,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.843944,"longitude":127.343676,"bookYear":"2024","nameKR":"국일식당","ribbonType":"2","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":2606,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.469658,"longitude":126.92239,"bookYear":"2024","nameKR":"오조해녀의집","ribbonType":"2","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":3202,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.140327,"longitude":129.109995,"bookYear":"2024","nameKR":"영남식육식당","ribbonType":"2","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":1691,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.946162,"longitude":127.778341,"bookYear":"2024","nameKR":"유포리막국수","ribbonType":"2","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":6352,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.898329,"longitude":126.642484,"bookYear":"2024","nameKR":"장수꽃게장","ribbonType":"2","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":8921,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.299005,"longitude":127.918199,"bookYear":"2024","nameKR":"흥업묵집","ribbonType":"2","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":7086,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":36.365491,"longitude":127.380015,"bookYear":"2024","nameKR":"스시호산","ribbonType":"2","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":3243,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.724744,"longitude":126.567074,"bookYear":"2024","nameKR":"독천식당","ribbonType":"2","zone2_1":"전남","zone2_2":"영암군","status":"ACTIVE"},
        {"id":25575,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sona_seoul","foodTypes":"디저트/차/베이커리","latitude":37.52066,"longitude":127.0216,"bookYear":"2024","nameKR":"소나","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":31115,"bookStatus":"보류","websiteInstagram":"https://www.instagram.com/the_local_eater","foodTypes":"이탈리아식","latitude":37.5409414348017,"longitude":127.014746912628,"bookYear":"2024","nameKR":"로컬릿","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":28324,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lepain_seoul","foodTypes":"디저트/차/베이커리","latitude":37.5072078494456,"longitude":127.10818286753653,"bookYear":"2024","nameKR":"르빵","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":27849,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/commemoa.gn/","foodTypes":"바/주점","latitude":37.5181990350542,"longitude":127.038222826352,"bookYear":"2024","nameKR":"꼼모아","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32591,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4758044816939,"longitude":127.039151097456,"bookYear":"2024","nameKR":"크레미엘","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":4382,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_michaya/","foodTypes":"디저트/차/베이커리","latitude":37.55547,"longitude":126.9182,"bookYear":"2024","nameKR":"미카야","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5750,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hoteldouce_seorae","foodTypes":"디저트/차/베이커리","latitude":37.49953,"longitude":126.9989,"bookYear":"2024","nameKR":"오뗄두스","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":20987,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/haap2010/","foodTypes":"디저트/차/베이커리","latitude":37.5223173108567,"longitude":127.020612195958,"bookYear":"2024","nameKR":"합","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":32570,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/identity_coffeelab/","foodTypes":"디저트/차/베이커리","latitude":37.5554112242628,"longitude":126.917486143443,"bookYear":"2024","nameKR":"아이덴티티커피랩","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":7011,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.49826,"longitude":127.0542,"bookYear":"2024","nameKR":"스위트에삐","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":6971,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mille_gateaux_/","foodTypes":"디저트/차/베이커리","latitude":37.493104,"longitude":127.018143,"bookYear":"2024","nameKR":"밀갸또","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":13483,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/biwon1949/","foodTypes":"디저트/차/베이커리","latitude":37.5797848928672,"longitude":126.984863118822,"bookYear":"2024","nameKR":"비원떡집","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":22712,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/elcafecoffeeroasters","foodTypes":"디저트/차/베이커리","latitude":37.5519690862067,"longitude":126.977112544264,"bookYear":"2024","nameKR":"엘카페커피로스터스","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":29130,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.51375250260386,"longitude":127.10444609981751,"bookYear":"2024","nameKR":"바81","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33418,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gelateria_pietra/","foodTypes":"디저트/차/베이커리","latitude":37.5661498802795,"longitude":126.920029213649,"bookYear":"2024","nameKR":"피에트라","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":6964,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffeelibre_yeonnam","foodTypes":"디저트/차/베이커리","latitude":37.5636435058861,"longitude":126.923730236588,"bookYear":"2024","nameKR":"커피리브레","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":6963,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/fellncole/","foodTypes":"디저트/차/베이커리","latitude":37.54813,"longitude":126.9222,"bookYear":"2024","nameKR":"펠앤콜","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":41,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.57451,"longitude":126.9856,"bookYear":"2024","nameKR":"경인미술관전통다원","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":9177,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ours_blanc__/","foodTypes":"디저트/차/베이커리","latitude":37.54138,"longitude":126.9624,"bookYear":"2024","nameKR":"우스블랑","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":28479,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.572533,"longitude":126.935079,"bookYear":"2024","nameKR":"카덴","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":32706,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ongo_patisserie/","foodTypes":"디저트/차/베이커리","latitude":37.5694208162675,"longitude":126.928242163795,"bookYear":"2024","nameKR":"온고파티스리","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":4937,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/paul.and.paulina/  ","foodTypes":"디저트/차/베이커리","latitude":37.568844,"longitude":126.929055,"bookYear":"2024","nameKR":"폴앤폴리나","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":28190,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/meshcoffee","foodTypes":"디저트/차/베이커리","latitude":37.54808,"longitude":127.04244,"bookYear":"2024","nameKR":"메쉬커피","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":30336,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bluebottlekorea/","foodTypes":"디저트/차/베이커리","latitude":37.548107201529675,"longitude":127.04564060165043,"bookYear":"2024","nameKR":"블루보틀(성수점)","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":6402,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/richemont_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.56141,"longitude":126.9156,"bookYear":"2024","nameKR":"리치몬드과자점","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":1552,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bakery_napoleon","foodTypes":"디저트/차/베이커리","latitude":37.58889,"longitude":127.0051,"bookYear":"2024","nameKR":"나폴레옹과자점","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":28387,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/deepbluelake_mangwon/","foodTypes":"디저트/차/베이커리","latitude":37.555775,"longitude":126.905635,"bookYear":"2024","nameKR":"딥블루레이크","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":27255,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.540976,"longitude":126.949047,"bookYear":"2024","nameKR":"프릳츠","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29211,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.570539,"longitude":126.975391,"bookYear":"2024","nameKR":"찰스H","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":4747,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/namusairocoffee","foodTypes":"디저트/차/베이커리","latitude":37.5746,"longitude":126.971,"bookYear":"2024","nameKR":"나무사이로","ribbonType":"2","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":4658,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/guillaume_seoul/?igshid=1wyqia5da7r8c","foodTypes":"디저트/차/베이커리","latitude":37.52405,"longitude":127.0411,"bookYear":"2024","nameKR":"기욤","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32198,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/zest.seoul/","foodTypes":"바/주점","latitude":37.525227889754,"longitude":127.040790451167,"bookYear":"2024","nameKR":"제스트","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":1542,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/casadelvino2002/","foodTypes":"바/주점","latitude":37.526291,"longitude":127.044077,"bookYear":"2024","nameKR":"까사델비노","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":22725,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.51717,"longitude":127.0391,"bookYear":"2024","nameKR":"스위츠플래닛","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":6710,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5199,"longitude":126.9318,"bookYear":"2024","nameKR":"브레드피트","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":7017,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.52534,"longitude":127.0377,"bookYear":"2024","nameKR":"이치에","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":5205,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.526336,"longitude":127.030513,"bookYear":"2024","nameKR":"호원당","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":28191,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/maillet_patisseriefrancaise/","foodTypes":"디저트/차/베이커리","latitude":37.4989604535524,"longitude":126.99760965781,"bookYear":"2024","nameKR":"마얘","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":28144,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/maison_m.o/","foodTypes":"디저트/차/베이커리","latitude":37.489277,"longitude":126.994082,"bookYear":"2024","nameKR":"메종엠오","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":33205,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4889155854443,"longitude":127.027749222342,"bookYear":"2024","nameKR":"김영모과자점","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":27113,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffeemontage","foodTypes":"디저트/차/베이커리","latitude":37.52946,"longitude":127.1219,"bookYear":"2024","nameKR":"커피몽타주","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":5722,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/deuxamis_patisserie_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.51933,"longitude":127.0226,"bookYear":"2024","nameKR":"듀자미","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":6876,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/piaf_artisan_chocolatier/","foodTypes":"디저트/차/베이커리","latitude":37.5223173108567,"longitude":127.020612195958,"bookYear":"2024","nameKR":"삐아프","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":4697,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/waraku_sinsa","foodTypes":"바/주점","latitude":37.5221,"longitude":127.0207,"bookYear":"2024","nameKR":"와라쿠","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":29411,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dangok_sinsa/","foodTypes":"디저트/차/베이커리","latitude":37.520066,"longitude":127.020428,"bookYear":"2024","nameKR":"당옥","ribbonType":"2","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":8951,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.517018,"longitude":127.726176,"bookYear":"2024","nameKR":"대성식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"함양군","status":"ACTIVE"},
        {"id":31240,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tianmimi_0719","foodTypes":"중식","latitude":37.4918506404669,"longitude":127.025236485997,"bookYear":"2024","nameKR":"티엔미미","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":9217,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.877436,"longitude":129.231063,"bookYear":"2024","nameKR":"용강국밥","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30634,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.49988967209787,"longitude":127.05303895241715,"bookYear":"2024","nameKR":"마초야","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":29480,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/iyeo1950/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.49183887029659,"longitude":127.01086899157218,"bookYear":"2024","nameKR":"이여곰탕","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":3088,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.55551,"longitude":126.925,"bookYear":"2024","nameKR":"산띠","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":4996,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.51579,"longitude":127.0209,"bookYear":"2024","nameKR":"첨벙아구찜","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":4724,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5211553001199,"longitude":127.038549558779,"bookYear":"2024","nameKR":"대가방","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":4995,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.563215,"longitude":126.976002,"bookYear":"2024","nameKR":"만족오향족발","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":244,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.566262,"longitude":126.979954,"bookYear":"2024","nameKR":"라칸티나","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":302,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/choidaepo_official/","foodTypes":"한식(육류)","latitude":37.5444618385571,"longitude":126.952057296928,"bookYear":"2024","nameKR":"마포진짜원조최대포","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":316,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.563981,"longitude":126.983573,"bookYear":"2024","nameKR":"명동돈가스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":402,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5685,"longitude":126.9988,"bookYear":"2024","nameKR":"보건옥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":426,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.55657,"longitude":126.922,"bookYear":"2024","nameKR":"불이아","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":460,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5621320478482,"longitude":126.982238910434,"bookYear":"2024","nameKR":"산동교자","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":483,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.52020494927552,"longitude":126.93180215312694,"bookYear":"2024","nameKR":"서궁","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":5753,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.499069,"longitude":126.996889,"bookYear":"2024","nameKR":"볼라레","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":5002,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.509538,"longitude":127.082624,"bookYear":"2024","nameKR":"늘푸른목장","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":3454,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.54946,"longitude":126.8763,"bookYear":"2024","nameKR":"임진강민물장어","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":29089,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/leblanseu/","foodTypes":"프랑스식","latitude":37.5728975356749,"longitude":126.990217389936,"bookYear":"2024","nameKR":"르블란서","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":615,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.57257,"longitude":127.0125,"bookYear":"2024","nameKR":"에베레스트","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":634,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.56107,"longitude":126.9831,"bookYear":"2024","nameKR":"영양센타","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":655,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.50907,"longitude":127.1334,"bookYear":"2024","nameKR":"오발탄","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":4783,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.52394,"longitude":127.0496,"bookYear":"2024","nameKR":"영천영화청담점","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":3932,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.59285,"longitude":126.9656,"bookYear":"2024","nameKR":"계열사","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":29168,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/italyjae/","foodTypes":"이탈리아식","latitude":37.578765,"longitude":126.981726,"bookYear":"2024","nameKR":"이태리재","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":687,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.55320433277618,"longitude":127.01116761232842,"bookYear":"2024","nameKR":"우성갈비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":29251,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/motorcitykorea","foodTypes":"미국식","latitude":37.533944,"longitude":126.989441,"bookYear":"2024","nameKR":"모터시티","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":6458,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.569732651542004,"longitude":126.90313681206744,"bookYear":"2024","nameKR":"성산왕갈비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":765,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5667960482466,"longitude":126.993638013279,"bookYear":"2024","nameKR":"조선옥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":6818,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gianisnapoli/","foodTypes":"이탈리아식","latitude":37.502626,"longitude":127.03754,"bookYear":"2024","nameKR":"지아니스나폴리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":27817,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.56179,"longitude":126.9275,"bookYear":"2024","nameKR":"편의방","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":880,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/welcometo_taj/","foodTypes":"아시아식","latitude":37.5644105705291,"longitude":126.986233754442,"bookYear":"2024","nameKR":"타지","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":5918,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.479197,"longitude":126.95068,"bookYear":"2024","nameKR":"신기루황소곱창구이","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":28279,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.493798,"longitude":126.993333,"bookYear":"2024","nameKR":"도우룸","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":4228,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.50472,"longitude":127.0426,"bookYear":"2024","nameKR":"부산양곱창","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":998,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.52771,"longitude":127.0386,"bookYear":"2024","nameKR":"개화옥","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":1134,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.53437,"longitude":126.975,"bookYear":"2024","nameKR":"봉산집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":1152,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.52083,"longitude":127.0576,"bookYear":"2024","nameKR":"삼성원조양곱창","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":18799,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.547556,"longitude":126.920844,"bookYear":"2024","nameKR":"맛이차이나","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5607,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56275,"longitude":126.9978,"bookYear":"2024","nameKR":"부산갈비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":1268,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.56172,"longitude":126.9922,"bookYear":"2024","nameKR":"충무로쭈꾸미불고기","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":27437,"bookStatus":"승인","websiteInstagram":"","foodTypes":"미국식","latitude":37.55007,"longitude":126.9197,"bookYear":"2024","nameKR":"오리지널시카고피자","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":1270,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4697,"longitude":127.0447,"bookYear":"2024","nameKR":"참나무숯불에닭이만나는순간","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":4281,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5328479505042,"longitude":126.99081275746,"bookYear":"2024","nameKR":"이태원숯불구이","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":1383,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.562858,"longitude":126.926699,"bookYear":"2024","nameKR":"향미","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":25771,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.548136,"longitude":127.071506,"bookYear":"2024","nameKR":"빠오즈푸","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":6407,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/esang1982/","foodTypes":"한식(육류)","latitude":37.61123,"longitude":126.9777,"bookYear":"2024","nameKR":"북악정","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":1473,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.66209,"longitude":127.0329,"bookYear":"2024","nameKR":"수정궁","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":29170,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dotz_hannam","foodTypes":"아시아식","latitude":37.536611,"longitude":126.99913,"bookYear":"2024","nameKR":"다츠","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":4913,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.55788,"longitude":126.937344,"bookYear":"2024","nameKR":"형제갈비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":1568,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.53463,"longitude":126.9877,"bookYear":"2024","nameKR":"페트라","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":22482,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.55496,"longitude":127.129,"bookYear":"2024","nameKR":"암사민물장어","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":13385,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5147588693048,"longitude":127.019094304612,"bookYear":"2024","nameKR":"한성돈까스","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":4768,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.50805,"longitude":126.9404,"bookYear":"2024","nameKR":"운봉산장","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":7896,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5861124622414,"longitude":127.029518544594,"bookYear":"2024","nameKR":"삼통치킨","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":4720,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/blacksmith_pizza.korea/","foodTypes":"이탈리아식","latitude":37.580529,"longitude":126.985338,"bookYear":"2024","nameKR":"대장장이화덕피자집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":28413,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.542516,"longitude":127.00246,"bookYear":"2024","nameKR":"윤세영식당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":5937,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.563221,"longitude":126.925134,"bookYear":"2024","nameKR":"하하","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":7193,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/buddhasbelly_official/","foodTypes":"아시아식","latitude":37.53436,"longitude":126.98835,"bookYear":"2024","nameKR":"부다스벨리","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":27574,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.571343815041786,"longitude":126.97119805167881,"bookYear":"2024","nameKR":"조우","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":6530,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/unknown_diner","foodTypes":"이탈리아식","latitude":37.48414,"longitude":127.0455,"bookYear":"2024","nameKR":"언노운다이너","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":29348,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/camillo_lasagneria/","foodTypes":"이탈리아식","latitude":37.552551,"longitude":126.912878,"bookYear":"2024","nameKR":"카밀로라자네리아","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":29347,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/fagp_inst/","foodTypes":"이탈리아식","latitude":37.549626,"longitude":127.044633,"bookYear":"2024","nameKR":"팩피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":28949,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/vgtacos/","foodTypes":"중남미식","latitude":37.511924,"longitude":127.052772,"bookYear":"2024","nameKR":"비야게레로","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":29076,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seochoncheflee","foodTypes":"이탈리아식","latitude":37.579544,"longitude":126.970834,"bookYear":"2024","nameKR":"서촌김씨뜨라또리아","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":15850,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.540082,"longitude":126.942878,"bookYear":"2024","nameKR":"청춘구락부","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29229,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.560133,"longitude":126.962236,"bookYear":"2024","nameKR":"옐로우보울","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":13212,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5044034043625,"longitude":127.043661150546,"bookYear":"2024","nameKR":"별양집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":18601,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5669,"longitude":126.9301,"bookYear":"2024","nameKR":"오향만두","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":6789,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.53723,"longitude":126.9998,"bookYear":"2024","nameKR":"부자피자","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":21046,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bistro_mohing/","foodTypes":"이탈리아식","latitude":37.479028,"longitude":126.953504,"bookYear":"2024","nameKR":"모힝","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":29137,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/102mozza/","foodTypes":"이탈리아식","latitude":37.593025,"longitude":126.997544,"bookYear":"2024","nameKR":"모짜","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":7002,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5870507663948,"longitude":127.018932734098,"bookYear":"2024","nameKR":"안동반점","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":29047,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.546194350143,"longitude":127.048432321547,"bookYear":"2024","nameKR":"대성갈비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29173,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.528286,"longitude":127.005425,"bookYear":"2024","nameKR":"소꿉","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":3544,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5383565084538,"longitude":127.067914081307,"bookYear":"2024","nameKR":"매화반점","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":12441,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56903,"longitude":127.0573,"bookYear":"2024","nameKR":"일광쪽갈비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":31202,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hieutu_seoul/","foodTypes":"아시아식","latitude":37.5303509410215,"longitude":126.9713078372,"bookYear":"2024","nameKR":"효뜨","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32466,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5137519612953,"longitude":127.104446890835,"bookYear":"2024","nameKR":"고든램지버거","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32203,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/matteo_travelog/","foodTypes":"이탈리아식","latitude":37.5468826236179,"longitude":127.024072726848,"bookYear":"2024","nameKR":"마테오견문록","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":32151,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/the_italianclub/","foodTypes":"이탈리아식","latitude":37.5138710747422,"longitude":127.106364392884,"bookYear":"2024","nameKR":"더이탈리안클럽","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36332,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5709958592808,"longitude":126.978914477333,"bookYear":"2024","nameKR":"차알(광화문디타워점)","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":33845,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/growers_official/","foodTypes":"기타","latitude":37.5722825693755,"longitude":126.933334949292,"bookYear":"2024","nameKR":"그로어스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":29831,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.53448172851247,"longitude":126.99072238313396,"bookYear":"2024","nameKR":"왕타이","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30677,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cuisine_la_cle","foodTypes":"프랑스식","latitude":37.579211074520316,"longitude":126.97028934695817,"bookYear":"2024","nameKR":"퀴진라끌레","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31617,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5250144574642,"longitude":127.036909727014,"bookYear":"2024","nameKR":"휴고도산","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29915,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thecharmandre/","foodTypes":"유럽식","latitude":37.5112193646564,"longitude":127.108309794125,"bookYear":"2024","nameKR":"차만다","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34440,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lala_sichuanlab","foodTypes":"중식","latitude":37.5397320046683,"longitude":127.063320550603,"bookYear":"2024","nameKR":"라라관","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":30362,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kunsomchai","foodTypes":"아시아식","latitude":37.4961957717521,"longitude":127.0143254664647,"bookYear":"2024","nameKR":"쿤쏨차이","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":31552,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5166413661632,"longitude":127.018909049863,"bookYear":"2024","nameKR":"꿉당","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":32420,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.545406152402,"longitude":126.972992424883,"bookYear":"2024","nameKR":"구복만두","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31099,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gop_official_/","foodTypes":"한식(육류)","latitude":37.5174631210953,"longitude":126.896493699106,"bookYear":"2024","nameKR":"곱(문래점)","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":30739,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5455735247076,"longitude":127.051086163784,"bookYear":"2024","nameKR":"중앙감속기","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":31836,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/flavourtownseoul/","foodTypes":"중식","latitude":37.5469844940099,"longitude":127.042925648075,"bookYear":"2024","nameKR":"플레이버타운","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33182,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/soulhanu/","foodTypes":"한식(육류)","latitude":37.5392825670042,"longitude":126.947276903557,"bookYear":"2024","nameKR":"소울한우","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":32028,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5075121874388,"longitude":127.008065342049,"bookYear":"2024","nameKR":"마틸다바베큐치킨","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":30056,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.51843321067631,"longitude":127.03010733587352,"bookYear":"2024","nameKR":"우정양곱창","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":29839,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chickpeace.kr/","foodTypes":"기타","latitude":37.51912282497437,"longitude":127.02473222317606,"bookYear":"2024","nameKR":"칙피스 ","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":33028,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lejiuseorae/","foodTypes":"이탈리아식","latitude":37.498314991796,"longitude":126.998912893897,"bookYear":"2024","nameKR":"르지우","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":33087,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/namyeongchicken_n.y.c/","foodTypes":"한식(가금류)","latitude":37.5425431587763,"longitude":126.973487034748,"bookYear":"2024","nameKR":"남영탉","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32636,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_onedegreenorth_/","foodTypes":"아시아식","latitude":37.5166294605324,"longitude":127.037462121204,"bookYear":"2024","nameKR":"원디그리노스","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":30013,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hyang.yeon_/","foodTypes":"중식","latitude":37.540974299543926,"longitude":127.05544422259797,"bookYear":"2024","nameKR":"성수속향연","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":30171,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.494649871484306,"longitude":127.0123077962813,"bookYear":"2024","nameKR":"우진가","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":31267,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lambsya/","foodTypes":"한식(육류)","latitude":37.5480826850688,"longitude":127.108572799417,"bookYear":"2024","nameKR":"램스야","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":31031,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/roramen_mullae/","foodTypes":"일식","latitude":37.5139946050255,"longitude":126.893738701859,"bookYear":"2024","nameKR":"로라멘","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":32029,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5174902364919,"longitude":127.036153686218,"bookYear":"2024","nameKR":"대삼식당","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":29349,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pizzeria_darobe","foodTypes":"이탈리아식","latitude":37.548519716506,"longitude":127.043389364604,"bookYear":"2024","nameKR":"다로베","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29703,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hongshinae_salt/","foodTypes":"이탈리아식","latitude":37.51519954278996,"longitude":127.03333653116074,"bookYear":"2024","nameKR":"솔트","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":30165,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.562055413512205,"longitude":126.98587471746742,"bookYear":"2024","nameKR":"명동피자","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":33212,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hyodochicken/","foodTypes":"한식(가금류)","latitude":37.5746362867009,"longitude":126.971058563556,"bookYear":"2024","nameKR":"효도치킨","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":30829,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/viamerengue/","foodTypes":"중남미식","latitude":37.5586280532636,"longitude":126.93811157695,"bookYear":"2024","nameKR":"비아메렝게","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":33433,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gogi_namboo/","foodTypes":"한식(육류)","latitude":37.4839440321864,"longitude":127.012917836855,"bookYear":"2024","nameKR":"남부","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":31020,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5373391492786,"longitude":127.001983901724,"bookYear":"2024","nameKR":"은마양대창","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":32174,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/manimalsmokehouse/","foodTypes":"기타","latitude":37.5345209941094,"longitude":126.988471989299,"bookYear":"2024","nameKR":"매니멀스모크하우스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31658,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4877387829749,"longitude":127.120685887596,"bookYear":"2024","nameKR":"팔화당","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":31998,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gary_hong_/","foodTypes":"한식(가금류)","latitude":37.5181618896329,"longitude":127.025230865206,"bookYear":"2024","nameKR":"은화계","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32646,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/al.choc/","foodTypes":"이탈리아식","latitude":37.5620840572499,"longitude":126.923584348403,"bookYear":"2024","nameKR":"알척","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":30830,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5427605404136,"longitude":126.973355698925,"bookYear":"2024","nameKR":"초원","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":30773,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5254169018151,"longitude":126.872955497947,"bookYear":"2024","nameKR":"일미락","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":32762,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5314787376006,"longitude":126.971736081763,"bookYear":"2024","nameKR":"굿손","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":33809,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gooey_brunch_bar/","foodTypes":"기타","latitude":37.5401156018448,"longitude":126.962252873816,"bookYear":"2024","nameKR":"구위브런치바","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":30143,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/golden_gate1940/ ","foodTypes":"중식","latitude":37.585413647250576,"longitude":127.00062496728204,"bookYear":"2024","nameKR":"1940금문","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":32009,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mutandining/","foodTypes":"중식","latitude":37.5272989341349,"longitude":127.030310574565,"bookYear":"2024","nameKR":"무탄","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31077,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ryumokmin/","foodTypes":"한식(가금류)","latitude":37.5143957753291,"longitude":127.028815905333,"bookYear":"2024","nameKR":"류몽민","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32821,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/odol0707/","foodTypes":"한식(육류)","latitude":37.5275625288744,"longitude":126.899817341967,"bookYear":"2024","nameKR":"당산오돌","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":32431,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5306543048487,"longitude":126.971294031553,"bookYear":"2024","nameKR":"로스트인홍콩","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31918,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/focacciadellastrada/","foodTypes":"이탈리아식","latitude":37.5312966447796,"longitude":126.970991031155,"bookYear":"2024","nameKR":"포카치아델라스트라다","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":30606,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.51746186029412,"longitude":126.89649437954259,"bookYear":"2024","nameKR":"동경화로(문래점)","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":32025,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5589266608979,"longitude":126.964320892997,"bookYear":"2024","nameKR":"두툼","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":31136,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kukbingwan_official/","foodTypes":"한식(육류)","latitude":37.5766282218463,"longitude":126.973830985417,"bookYear":"2024","nameKR":"국빈관","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31499,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5514096192093,"longitude":126.915191676236,"bookYear":"2024","nameKR":"천이오겹살","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":29871,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/brera.kr_/","foodTypes":"이탈리아식","latitude":37.54837530553894,"longitude":127.00694366116952,"bookYear":"2024","nameKR":"브레라","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":32917,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lexian1945_official/","foodTypes":"중식","latitude":37.5760936402375,"longitude":126.924152924933,"bookYear":"2024","nameKR":"락희안(가좌본점)","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":31133,"bookStatus":"승인","websiteInstagram":"","foodTypes":"미국식","latitude":37.5027658420773,"longitude":127.026657450782,"bookYear":"2024","nameKR":"녘","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":29867,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.53790518192312,"longitude":127.06651676132604,"bookYear":"2024","nameKR":"송화산시도삭면","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":31594,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wearegege/","foodTypes":"중식","latitude":37.5315505801618,"longitude":126.971126584354,"bookYear":"2024","nameKR":"꺼거","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31364,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/palsikdang_official/","foodTypes":"한식(육류)","latitude":37.5237373375472,"longitude":127.049353121756,"bookYear":"2024","nameKR":"팔식당","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30992,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ikseon_dimibang/","foodTypes":"이탈리아식","latitude":37.5740691956402,"longitude":126.990292742097,"bookYear":"2024","nameKR":"익선디미방","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":31256,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5821290013901,"longitude":126.972773908135,"bookYear":"2024","nameKR":"비스트로친친","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":33156,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.479727563019,"longitude":126.999133741459,"bookYear":"2024","nameKR":"일도씨닭갈비","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":30827,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5459839648361,"longitude":126.945564274909,"bookYear":"2024","nameKR":"계고기집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":31988,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/annam_dogok/","foodTypes":"아시아식","latitude":37.4869159664401,"longitude":127.051855548139,"bookYear":"2024","nameKR":"안남","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":30172,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.53625971396709,"longitude":126.96124127038736,"bookYear":"2024","nameKR":"용문갈비집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":2918,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.869783085852255,"longitude":128.53600717307626,"bookYear":"2024","nameKR":"풍기인삼갈비","ribbonType":"1","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":2948,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.096938,"longitude":129.027025,"bookYear":"2024","nameKR":"백화양곱창","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":2730,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.634609,"longitude":127.487107,"bookYear":"2024","nameKR":"조선면옥","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":2994,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.81611771512828,"longitude":126.97260874536448,"bookYear":"2024","nameKR":"염치정육점식당","ribbonType":"1","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":2943,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.119363,"longitude":128.96136,"bookYear":"2024","nameKR":"청송집","ribbonType":"1","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":2781,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/official_shinfayuen/","foodTypes":"중식","latitude":35.114808,"longitude":129.038722,"bookYear":"2024","nameKR":"신발원","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":3834,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/eunices_garden/","foodTypes":"이탈리아식","latitude":37.311484,"longitude":126.889064,"bookYear":"2024","nameKR":"유니스의정원","ribbonType":"1","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":2655,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.147227,"longitude":126.505858,"bookYear":"2024","nameKR":"서산회관","ribbonType":"1","zone2_1":"충남","zone2_2":"서천군","status":"ACTIVE"},
        {"id":2995,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.815642,"longitude":126.972392,"bookYear":"2024","nameKR":"큰고개정육점식당","ribbonType":"1","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":2936,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/naruter_jang.eo_2404/","foodTypes":"한식(민물어패류)","latitude":36.44838662314152,"longitude":127.45387658341932,"bookYear":"2024","nameKR":"나루터장어","ribbonType":"1","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":1005,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/giwajibyangdaechangcenta/","foodTypes":"한식(육류)","latitude":37.5063066630473,"longitude":127.141441061135,"bookYear":"2024","nameKR":"기와집양대창센타","ribbonType":"1","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":3082,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.926377,"longitude":129.287883,"bookYear":"2024","nameKR":"옛날경주숯불","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":3360,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.831454,"longitude":128.551366,"bookYear":"2024","nameKR":"가야성","ribbonType":"1","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":2774,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.8678145743535,"longitude":128.592124302127,"bookYear":"2024","nameKR":"복해반점","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":2788,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.11308070565428,"longitude":129.03788213915826,"bookYear":"2024","nameKR":"일품향","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":1606,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.871695,"longitude":127.702646,"bookYear":"2024","nameKR":"다우등심","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":1611,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.672572465214614,"longitude":128.7088739065627,"bookYear":"2024","nameKR":"대관령숯불회관","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1617,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.483724,"longitude":129.125014,"bookYear":"2024","nameKR":"덕취원","ribbonType":"1","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":1625,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.685431079655295,"longitude":128.56172999911794,"bookYear":"2024","nameKR":"두일막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1641,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4671146751918,"longitude":129.163805575001,"bookYear":"2024","nameKR":"부일막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":3587,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.399939,"longitude":127.397451,"bookYear":"2024","nameKR":"트레비","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":3055,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.965355,"longitude":126.518907,"bookYear":"2024","nameKR":"두암식당","ribbonType":"1","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":1834,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bonsuwon/","foodTypes":"한식(육류)","latitude":37.2780143260641,"longitude":127.04065858878,"bookYear":"2024","nameKR":"본수원갈비","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":2552,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.839098,"longitude":127.129626,"bookYear":"2024","nameKR":"한국관","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":1859,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.9010199093981,"longitude":127.055046489727,"bookYear":"2024","nameKR":"송월관","ribbonType":"1","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":1861,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.346317,"longitude":127.154021,"bookYear":"2024","nameKR":"수레실가든","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":1868,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.273741,"longitude":127.049227,"bookYear":"2024","nameKR":"신라갈비","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":1893,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.082699,"longitude":127.056478,"bookYear":"2024","nameKR":"영빈루","ribbonType":"1","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":2582,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":33.416576,"longitude":126.265228,"bookYear":"2024","nameKR":"보영반점","ribbonType":"1","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":1924,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.0353702681041,"longitude":127.366895678769,"bookYear":"2024","nameKR":"김근자할머니집","ribbonType":"1","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":3246,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2731660886751,"longitude":127.00990075381,"bookYear":"2024","nameKR":"팔미옥","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":2553,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8164,"longitude":127.148277,"bookYear":"2024","nameKR":"한국집","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":3394,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.927073,"longitude":129.289235,"bookYear":"2024","nameKR":"화산숯불","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2097,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.5647096883379,"longitude":128.732447818784,"bookYear":"2024","nameKR":"문화갈비","ribbonType":"1","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":2114,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.197043,"longitude":129.34051,"bookYear":"2024","nameKR":"시장식육식당","ribbonType":"1","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":2137,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.138939,"longitude":126.794842,"bookYear":"2024","nameKR":"송정떡갈비","ribbonType":"1","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":2167,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.825617,"longitude":128.622434,"bookYear":"2024","nameKR":"마루막창","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":2172,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.870992,"longitude":128.6051,"bookYear":"2024","nameKR":"벙글벙글찜갈비","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":2173,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.871234,"longitude":128.605089,"bookYear":"2024","nameKR":"봉산찜갈비","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":2186,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.869388,"longitude":128.591401,"bookYear":"2024","nameKR":"영생덕","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":2604,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.496546,"longitude":126.526161,"bookYear":"2024","nameKR":"도남오거리식당","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":2328,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.565202,"longitude":129.125484,"bookYear":"2024","nameKR":"공원불고기","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":3261,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chuncheon_santorini","foodTypes":"이탈리아식","latitude":37.891373,"longitude":127.776463,"bookYear":"2024","nameKR":"산토리니","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":2359,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.475428,"longitude":126.617798,"bookYear":"2024","nameKR":"신승반점","ribbonType":"1","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":2408,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.9761829598815,"longitude":127.585575367383,"bookYear":"2024","nameKR":"대한식당","ribbonType":"1","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":2421,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.9905890411263,"longitude":126.476892085335,"bookYear":"2024","nameKR":"무안식당","ribbonType":"1","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":3366,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3949684831333,"longitude":127.352933366659,"bookYear":"2024","nameKR":"한우김삿갓","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":2439,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.132314,"longitude":127.253845,"bookYear":"2024","nameKR":"석곡식당","ribbonType":"1","zone2_1":"전남","zone2_2":"곡성군","status":"ACTIVE"},
        {"id":2444,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.050739,"longitude":126.721722,"bookYear":"2024","nameKR":"송현불고기","ribbonType":"1","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":2536,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.81733,"longitude":127.145299,"bookYear":"2024","nameKR":"성미당","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2537,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.5081147866567,"longitude":126.599729863453,"bookYear":"2024","nameKR":"신덕식당","ribbonType":"1","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":3326,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.471662,"longitude":126.623914,"bookYear":"2024","nameKR":"진흥각","ribbonType":"1","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":2540,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.837042,"longitude":127.107347,"bookYear":"2024","nameKR":"에루화","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":8190,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.201803,"longitude":129.090884,"bookYear":"2024","nameKR":"태백관","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":6191,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.1505617573345,"longitude":126.913291884113,"bookYear":"2024","nameKR":"신락원","ribbonType":"1","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":6181,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/boksoungwon/","foodTypes":"중식","latitude":37.4949812740021,"longitude":126.786010776513,"bookYear":"2024","nameKR":"복성원","ribbonType":"1","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":5343,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/vini_e_olii","foodTypes":"이탈리아식","latitude":37.321336,"longitude":127.110211,"bookYear":"2024","nameKR":"뜨라또리아비니에올리","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":4442,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/byeolnanjip.daejeon/","foodTypes":"한식(일반한식)","latitude":36.330913,"longitude":127.430711,"bookYear":"2024","nameKR":"별난집","ribbonType":"1","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":5364,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.657128,"longitude":126.771756,"bookYear":"2024","nameKR":"어랑생선구이","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":5920,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chun_hyun_han_woo/","foodTypes":"한식(육류)","latitude":37.422119,"longitude":127.314877,"bookYear":"2024","nameKR":"천현한우집","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":9014,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.785604,"longitude":126.386549,"bookYear":"2024","nameKR":"성식당","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":5103,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.471968,"longitude":126.635388,"bookYear":"2024","nameKR":"용화반점","ribbonType":"1","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":4106,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.453284,"longitude":126.731974,"bookYear":"2024","nameKR":"전가복","ribbonType":"1","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":5271,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.287697,"longitude":127.307121,"bookYear":"2024","nameKR":"방동가든","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":6312,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/addang2010_suwon","foodTypes":"이탈리아식","latitude":37.250232,"longitude":127.073516,"bookYear":"2024","nameKR":"아름다운땅","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":5247,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.221969,"longitude":129.081156,"bookYear":"2024","nameKR":"대길숯불갈비","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":5231,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.128407,"longitude":129.046835,"bookYear":"2024","nameKR":"명당만두","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":4311,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.597186,"longitude":127.231081,"bookYear":"2024","nameKR":"덕소숯불고기","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":4073,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.113557,"longitude":129.037714,"bookYear":"2024","nameKR":"장성향","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":4003,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.151073,"longitude":126.836814,"bookYear":"2024","nameKR":"나정상회","ribbonType":"1","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":4981,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.507448,"longitude":126.599391,"bookYear":"2024","nameKR":"명가풍천장어","ribbonType":"1","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":6097,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.863182,"longitude":128.612237,"bookYear":"2024","nameKR":"삼수장어","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":4418,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.976221,"longitude":127.585408,"bookYear":"2024","nameKR":"대중식당","ribbonType":"1","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":6179,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.077823,"longitude":127.055133,"bookYear":"2024","nameKR":"인화루","ribbonType":"1","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":6178,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.081192,"longitude":127.055342,"bookYear":"2024","nameKR":"홍태루","ribbonType":"1","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":6185,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.649185,"longitude":127.456415,"bookYear":"2024","nameKR":"대명도리탕","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":6067,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.993038,"longitude":126.71024,"bookYear":"2024","nameKR":"쌍용반점","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":6428,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1470414225913,"longitude":126.926553400152,"bookYear":"2024","nameKR":"미미원","ribbonType":"1","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":6152,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ibjujib","foodTypes":"한식(육류)","latitude":37.27928,"longitude":127.017622,"bookYear":"2024","nameKR":"입주집","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":4230,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gardengallery1","foodTypes":"이탈리아식","latitude":37.587843,"longitude":127.174029,"bookYear":"2024","nameKR":"가든갤러리","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":4412,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/namjihyuns2o61/","foodTypes":"한식(민물어패류)","latitude":34.733211,"longitude":127.723041,"bookYear":"2024","nameKR":"산골산장어","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":4544,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.858136,"longitude":128.622747,"bookYear":"2024","nameKR":"동봉숯불구이","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":4102,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.97823326330339,"longitude":126.71574669803049,"bookYear":"2024","nameKR":"복성루","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":6297,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/semprebene2007","foodTypes":"이탈리아식","latitude":37.2653436061759,"longitude":127.02832874822782,"bookYear":"2024","nameKR":"셈프레베네","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":4079,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.162691,"longitude":129.162967,"bookYear":"2024","nameKR":"신흥관","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":4072,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.099569262169815,"longitude":129.0274138029116,"bookYear":"2024","nameKR":"개화","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":6349,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.279589,"longitude":127.01813,"bookYear":"2024","nameKR":"진미통닭","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":5520,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.874611,"longitude":128.59646,"bookYear":"2024","nameKR":"국일불갈비","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":9253,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.244479,"longitude":126.522625,"bookYear":"2024","nameKR":"신우가촌","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":8162,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3266011675506,"longitude":127.430806399788,"bookYear":"2024","nameKR":"대전갈비집","ribbonType":"1","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":4604,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.500359,"longitude":126.472964,"bookYear":"2024","nameKR":"해오름식당","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":4097,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9855692318719,"longitude":126.717319847466,"bookYear":"2024","nameKR":"완주옥","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":4034,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.16005,"longitude":126.900384,"bookYear":"2024","nameKR":"영발원","ribbonType":"1","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":6426,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.147845,"longitude":126.853111,"bookYear":"2024","nameKR":"연화","ribbonType":"1","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":4107,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.462954,"longitude":126.714296,"bookYear":"2024","nameKR":"부암갈비","ribbonType":"1","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":5964,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.851456,"longitude":127.110923,"bookYear":"2024","nameKR":"가운데집","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":4554,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.875983,"longitude":128.603725,"bookYear":"2024","nameKR":"단골식당","ribbonType":"1","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":8117,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.28166168639556,"longitude":127.01790662330005,"bookYear":"2024","nameKR":"매향통닭","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":4453,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8033676650484,"longitude":127.156147465167,"bookYear":"2024","nameKR":"나정식당","ribbonType":"1","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":7986,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.158081,"longitude":129.058029,"bookYear":"2024","nameKR":"급행장","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":4754,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.977433,"longitude":127.587056,"bookYear":"2024","nameKR":"광양불고기금목서","ribbonType":"1","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":4419,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.970142,"longitude":127.578833,"bookYear":"2024","nameKR":"삼대광양불고기집","ribbonType":"1","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":4599,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.250158,"longitude":126.433252,"bookYear":"2024","nameKR":"오르막가든","ribbonType":"1","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":4457,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.322418,"longitude":127.437239,"bookYear":"2024","nameKR":"인동왕만두","ribbonType":"1","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":21733,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.160026,"longitude":129.114907,"bookYear":"2024","nameKR":"삼오불고기","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":29124,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.350803,"longitude":127.339851,"bookYear":"2024","nameKR":"백마강","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":18600,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.279586,"longitude":127.017623,"bookYear":"2024","nameKR":"용성통닭","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":29940,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nuovo_napoli/","foodTypes":"이탈리아식","latitude":36.361973967432924,"longitude":127.35299824967332,"bookYear":"2024","nameKR":"누오보나폴리","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":12932,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.661657,"longitude":126.766876,"bookYear":"2024","nameKR":"다루마야","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":29982,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.59638857053523,"longitude":127.51002380704577,"bookYear":"2024","nameKR":"산골농원","ribbonType":"1","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":12544,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.143608,"longitude":129.109723,"bookYear":"2024","nameKR":"고옥","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":29203,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/osteria308/ ","foodTypes":"이탈리아식","latitude":37.5588264366331,"longitude":127.204686431376,"bookYear":"2024","nameKR":"오스테리아308","ribbonType":"1","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":18732,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.494263,"longitude":126.723941,"bookYear":"2024","nameKR":"복화루","ribbonType":"1","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":28613,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.2194947299668,"longitude":129.082953641258,"bookYear":"2024","nameKR":"미미루","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":9792,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.2712210032043,"longitude":126.64788191817,"bookYear":"2024","nameKR":"대유가든","ribbonType":"1","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":14927,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.611703,"longitude":126.510658,"bookYear":"2024","nameKR":"꼭대기집약수터","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":29545,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/meltingroom/","foodTypes":"기타","latitude":37.40451962512449,"longitude":126.725848798049,"bookYear":"2024","nameKR":"멜팅룸","ribbonType":"1","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":20924,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.496684,"longitude":126.742578,"bookYear":"2024","nameKR":"삼도갈비","ribbonType":"1","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":27902,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.917041946064046,"longitude":127.12979711013118,"bookYear":"2024","nameKR":"동순원","ribbonType":"1","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":19480,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.3632732359855,"longitude":127.435317572544,"bookYear":"2024","nameKR":"향미각","ribbonType":"1","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":26502,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.163905,"longitude":129.163292,"bookYear":"2024","nameKR":"해성막창집","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":29856,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/attico_restaurant/ ","foodTypes":"이탈리아식","latitude":35.8270461694833,"longitude":128.6268601457501,"bookYear":"2024","nameKR":"아티코","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":28094,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.387654,"longitude":127.31681,"bookYear":"2024","nameKR":"피제리아다알리","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":27106,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pangyo_seren/","foodTypes":"이탈리아식","latitude":37.389857,"longitude":127.083679,"bookYear":"2024","nameKR":"세렌","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":10023,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.9894365985048,"longitude":127.355025891728,"bookYear":"2024","nameKR":"금성가든","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":24358,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.2010429017684,"longitude":127.073465269243,"bookYear":"2024","nameKR":"상해루","ribbonType":"1","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":28489,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.520594,"longitude":127.14635,"bookYear":"2024","nameKR":"드까르멜릿","ribbonType":"1","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":25444,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.936495,"longitude":126.952716,"bookYear":"2024","nameKR":"신동양","ribbonType":"1","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":28484,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.359691,"longitude":127.373091,"bookYear":"2024","nameKR":"나래한옥레스토랑","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":29766,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/aroithaibusan/","foodTypes":"아시아식","latitude":35.162518920484544,"longitude":129.12901519148312,"bookYear":"2024","nameKR":"알로이타이레스토랑 ","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":28551,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/songdo_toulon","foodTypes":"프랑스식","latitude":37.38599150946484,"longitude":126.6435586122863,"bookYear":"2024","nameKR":"툴롱","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":29281,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":36.625397,"longitude":127.428077,"bookYear":"2024","nameKR":"보테가","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":12903,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.483772,"longitude":126.486825,"bookYear":"2024","nameKR":"연정식당","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":29747,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.712254211998385,"longitude":126.97136588453453,"bookYear":"2024","nameKR":"데니스스모크하우스","ribbonType":"1","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":27895,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.868402,"longitude":128.58911,"bookYear":"2024","nameKR":"삐에뜨라","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":12788,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.824867,"longitude":127.104042,"bookYear":"2024","nameKR":"고궁담","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":21373,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.09955021929684,"longitude":129.02747473681845,"bookYear":"2024","nameKR":"홍소족발","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":29390,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/davidnrules/","foodTypes":"기타","latitude":37.391545,"longitude":127.099619,"bookYear":"2024","nameKR":"데이빗앤룰스","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":12192,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.84399,"longitude":127.062494,"bookYear":"2024","nameKR":"덕화원","ribbonType":"1","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12478,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.09705328557333,"longitude":129.02783833604138,"bookYear":"2024","nameKR":"일광양곱창","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":28091,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cubersgrill/ ","foodTypes":"이탈리아식","latitude":35.2301402963327,"longitude":128.68151791969,"bookYear":"2024","nameKR":"쿠버스그릴","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":12379,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.879486,"longitude":127.727927,"bookYear":"2024","nameKR":"우미닭갈비","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":10193,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ggeomeoggomusin/","foodTypes":"한식(민물어패류)","latitude":35.50336,"longitude":126.597066,"bookYear":"2024","nameKR":"꺼먹고무신","ribbonType":"1","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":17768,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.842308,"longitude":129.212952,"bookYear":"2024","nameKR":"반도불갈비식당","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":12380,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.158717,"longitude":128.982483,"bookYear":"2024","nameKR":"태백한우골실비식당","ribbonType":"1","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":14866,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":0,"longitude":0,"bookYear":"2024","nameKR":"은하갈비","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":29535,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/superju.lee","foodTypes":"일식","latitude":35.24088265732257,"longitude":129.01470439051528,"bookYear":"2024","nameKR":"슌사이쿠보","ribbonType":"1","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":19010,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.125626,"longitude":126.931824,"bookYear":"2024","nameKR":"막동이회관","ribbonType":"1","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":26977,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":34.97551215334561,"longitude":127.57647797937287,"bookYear":"2024","nameKR":"지곡산장","ribbonType":"1","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":31095,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":35.1841885048834,"longitude":129.079498634797,"bookYear":"2024","nameKR":"이영식옛날돈까스","ribbonType":"1","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":31664,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.756523031181,"longitude":128.865654678745,"bookYear":"2024","nameKR":"쯩라이","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":30607,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.154608216345835,"longitude":126.85388761204993,"bookYear":"2024","nameKR":"동경화로","ribbonType":"1","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":30499,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sung_yup_kim/","foodTypes":"일식","latitude":37.49010124126421,"longitude":126.75536987358142,"bookYear":"2024","nameKR":"돈가스온기","ribbonType":"1","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":30856,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7851184304017,"longitude":128.919305639067,"bookYear":"2024","nameKR":"풍년갈비","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":31653,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/taverna_seo/","foodTypes":"프랑스식","latitude":35.8348227209437,"longitude":129.211291682957,"bookYear":"2024","nameKR":"타베르나","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31330,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.3268002893287,"longitude":129.011151832235,"bookYear":"2024","nameKR":"육동면","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":31455,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_poongro_","foodTypes":"한식(육류)","latitude":33.3100748801007,"longitude":126.332538342542,"bookYear":"2024","nameKR":"풍로","ribbonType":"1","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":31332,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/menyagokoro_official/","foodTypes":"일식","latitude":37.2779516081955,"longitude":127.045384408359,"bookYear":"2024","nameKR":"멘야고코로","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":30382,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thaimarsil/ ","foodTypes":"아시아식","latitude":37.4003304236136,"longitude":126.918805979795,"bookYear":"2024","nameKR":"타이마실","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":31408,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.7869128967341,"longitude":126.144144797081,"bookYear":"2024","nameKR":"파스타포포","ribbonType":"1","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":31157,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.8823321773353,"longitude":128.61768521709,"bookYear":"2024","nameKR":"신산홍","ribbonType":"1","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":30285,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yangbawe_official/","foodTypes":"한식(육류)","latitude":37.397322467386175,"longitude":127.1135630623978,"bookYear":"2024","nameKR":"양바위","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":31271,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lala___table/","foodTypes":"이탈리아식","latitude":37.3635093517969,"longitude":127.127976868476,"bookYear":"2024","nameKR":"라라테이블","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":30852,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chef_myonghwan","foodTypes":"이탈리아식","latitude":37.5518130290607,"longitude":127.184490015618,"bookYear":"2024","nameKR":"언피니쉬드","ribbonType":"1","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":31717,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chef._hyun","foodTypes":"중식","latitude":37.7397766771195,"longitude":126.490406706965,"bookYear":"2024","nameKR":"금문도","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":31304,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/rione_busan/","foodTypes":"이탈리아식","latitude":35.1715965724359,"longitude":129.120035876905,"bookYear":"2024","nameKR":"리오네","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":31273,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.8444374659612,"longitude":129.209149020438,"bookYear":"2024","nameKR":"어향원","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30106,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/helloaudrey.official/","foodTypes":"이탈리아식","latitude":37.379959882597795,"longitude":127.0470289058939,"bookYear":"2024","nameKR":"헬로오드리","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":30175,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/fromitaly0414/","foodTypes":"이탈리아식","latitude":37.7758102311379,"longitude":127.165241103361,"bookYear":"2024","nameKR":"프롬이태리","ribbonType":"1","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":30086,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.92232433799451,"longitude":128.54760696353944,"bookYear":"2024","nameKR":"뜨라또리아델리카르도","ribbonType":"1","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":30361,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/moderngogi/","foodTypes":"한식(육류)","latitude":37.6388401439198,"longitude":126.62700888618926,"bookYear":"2024","nameKR":"인생화로","ribbonType":"1","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":31428,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tonshou__tonkatsu","foodTypes":"일식","latitude":35.1564423185533,"longitude":129.124785928587,"bookYear":"2024","nameKR":"톤쇼우","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":31399,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pizzeria_la_cena/","foodTypes":"이탈리아식","latitude":37.4717477607087,"longitude":127.312256892534,"bookYear":"2024","nameKR":"라체나","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":31287,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chopilsal_official/","foodTypes":"한식(육류)","latitude":35.1566471472902,"longitude":129.146930632686,"bookYear":"2024","nameKR":"초필살돼지구이","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":30792,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oakhill_official/","foodTypes":"기타","latitude":37.7053075158769,"longitude":127.059504591205,"bookYear":"2024","nameKR":"오크힐스테이크","ribbonType":"1","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":31526,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.4011208765501,"longitude":127.222314340289,"bookYear":"2024","nameKR":"송유향","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":30575,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/katshokudou","foodTypes":"일식","latitude":37.39426202230159,"longitude":127.11036477013562,"bookYear":"2024","nameKR":"카츠쇼쿠도우","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":30596,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lebouiboui.jeju/ ","foodTypes":"프랑스식","latitude":33.51541197569133,"longitude":126.5395325929007,"bookYear":"2024","nameKR":"르부이부이","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34773,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":35.1583076744908,"longitude":129.18232691377,"bookYear":"2024","nameKR":"달타이","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":32744,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ristorante_da_lino/","foodTypes":"이탈리아식","latitude":34.9562130146845,"longitude":127.481918385845,"bookYear":"2024","nameKR":"리노 ","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":34722,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.1021001982297,"longitude":129.030579760489,"bookYear":"2024","nameKR":"이재모피자","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":34740,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1600053936274,"longitude":129.155415309483,"bookYear":"2024","nameKR":"영남돼지","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":33398,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9864534103514,"longitude":126.710060162883,"bookYear":"2024","nameKR":"명월갈비","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":33158,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_cuocere/","foodTypes":"이탈리아식","latitude":37.6528350492817,"longitude":126.68443022381,"bookYear":"2024","nameKR":"쿠오체레","ribbonType":"1","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":34160,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.9623471705886,"longitude":126.979945021558,"bookYear":"2024","nameKR":"이탈","ribbonType":"1","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":34509,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.161820360403,"longitude":129.159655134617,"bookYear":"2024","nameKR":"해목","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":36478,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.5077890744115,"longitude":126.520299856681,"bookYear":"2024","nameKR":"탐라가든","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34768,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1390447688674,"longitude":129.107641594484,"bookYear":"2024","nameKR":"감성양고기","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":32814,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.4799951290492,"longitude":127.286781727618,"bookYear":"2024","nameKR":"피제리아지알로","ribbonType":"1","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":34792,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.2462489639252,"longitude":129.216230389223,"bookYear":"2024","nameKR":"명가양꼬치","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":34517,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lecoin_bistro/","foodTypes":"프랑스식","latitude":34.9303573495796,"longitude":127.496560067369,"bookYear":"2024","nameKR":"르꼬앙","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":34770,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1668023507704,"longitude":129.113717276496,"bookYear":"2024","nameKR":"고기형","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":33338,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":34.7897627716091,"longitude":126.384818976195,"bookYear":"2024","nameKR":"중화루","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":35829,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":33.4694460541333,"longitude":126.487858537103,"bookYear":"2024","nameKR":"리보스코화덕피자 ","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":32322,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/damyang_apzip/","foodTypes":"한식(육류)","latitude":35.3104223224495,"longitude":126.979475176504,"bookYear":"2024","nameKR":"담양앞집","ribbonType":"1","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":33781,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/fishterie/","foodTypes":"기타","latitude":34.7858229686263,"longitude":126.385305034373,"bookYear":"2024","nameKR":"피시테리안","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":34861,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":35.2105190530281,"longitude":129.075318426884,"bookYear":"2024","nameKR":"헤아릴","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":34857,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.3029912141532,"longitude":129.112725712338,"bookYear":"2024","nameKR":"포구나무집","ribbonType":"1","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":34820,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yunj.mong","foodTypes":"이탈리아식","latitude":37.6696734609311,"longitude":126.773486166319,"bookYear":"2024","nameKR":"윤몽","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":34817,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chef_lee01/","foodTypes":"이탈리아식","latitude":35.1596158504847,"longitude":129.153828860927,"bookYear":"2024","nameKR":"쉐프리","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":34827,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1551646233162,"longitude":129.121164349434,"bookYear":"2024","nameKR":"우토피아","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36842,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9182940424853,"longitude":128.550451206094,"bookYear":"2024","nameKR":"짚탄","ribbonType":"1","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":36539,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/malsoon_house/","foodTypes":"이탈리아식","latitude":35.2631229344939,"longitude":128.62266831399,"bookYear":"2024","nameKR":"박말순레스토랑","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":36523,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.8368574943909,"longitude":127.895216803374,"bookYear":"2024","nameKR":"화랑갈비","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":893,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.49882,"longitude":126.9975,"bookYear":"2024","nameKR":"톰볼라","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":951,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.53572,"longitude":126.973,"bookYear":"2024","nameKR":"한강집생태","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":979,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hyehwanoodlehouse/","foodTypes":"한식(면류)","latitude":37.58651,"longitude":127.0015,"bookYear":"2024","nameKR":"혜화칼국수","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":1040,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5217938336297,"longitude":127.055341903929,"bookYear":"2024","nameKR":"파파호","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":1068,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5062314417537,"longitude":127.025972171991,"bookYear":"2024","nameKR":"노들강","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":1086,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4910060488883,"longitude":127.010080956911,"bookYear":"2024","nameKR":"동신명가","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":1150,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.52174,"longitude":126.9265,"bookYear":"2024","nameKR":"대동문","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":1166,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.622489,"longitude":126.932763,"bookYear":"2024","nameKR":"성너머집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":1239,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.55898,"longitude":126.977,"bookYear":"2024","nameKR":"은호식당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":1362,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5743642341595,"longitude":126.98051726008,"bookYear":"2024","nameKR":"베이징코야","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":1405,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.51009,"longitude":127.0354,"bookYear":"2024","nameKR":"목포자매집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":474,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.58460857932275,"longitude":126.98192595977827,"bookYear":"2024","nameKR":"삼청동수제비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":478,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.52548,"longitude":127.0529,"bookYear":"2024","nameKR":"새벽집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":503,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5945850956093,"longitude":126.990464229478,"bookYear":"2024","nameKR":"성북동누룽지백숙","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":604,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5296057693694,"longitude":126.921553019468,"bookYear":"2024","nameKR":"양마니","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":662,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56425,"longitude":127.0005,"bookYear":"2024","nameKR":"오장동흥남집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":682,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5713825413725,"longitude":127.040519485291,"bookYear":"2024","nameKR":"용문집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":712,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yining_official/","foodTypes":"중식","latitude":37.522365,"longitude":127.041275,"bookYear":"2024","nameKR":"이닝","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":763,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.585493,"longitude":126.981752,"bookYear":"2024","nameKR":"소선재","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":779,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5607,"longitude":126.9207,"bookYear":"2024","nameKR":"진어참치","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":800,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.567293,"longitude":126.979532,"bookYear":"2024","nameKR":"참숯골","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":3524,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.54295,"longitude":127.0718,"bookYear":"2024","nameKR":"우마이도","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":43,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.56294,"longitude":126.9729,"bookYear":"2024","nameKR":"고려삼계탕","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":108,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.52078,"longitude":126.9311,"bookYear":"2024","nameKR":"가양칼국수버섯매운탕","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":171,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56741500765416,"longitude":127.03065677544468,"bookYear":"2024","nameKR":"대도식당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":282,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.52796,"longitude":127.0359,"bookYear":"2024","nameKR":"리틀사이공","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":384,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/whitegarden1998/","foodTypes":"중식","latitude":37.529196,"longitude":126.924881,"bookYear":"2024","nameKR":"백원","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":4767,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4758882227788,"longitude":127.045346573447,"bookYear":"2024","nameKR":"삼일정육식당","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":1464,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/butterfingerpancakes/","foodTypes":"기타","latitude":37.52428,"longitude":127.0404,"bookYear":"2024","nameKR":"버터핑거팬케익스","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":1493,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chewoon7/","foodTypes":"중식","latitude":37.52241,"longitude":127.0208,"bookYear":"2024","nameKR":"채운","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":4686,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.51465,"longitude":127.0619,"bookYear":"2024","nameKR":"꽃지","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":3770,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.526878,"longitude":127.042406,"bookYear":"2024","nameKR":"10꼬르소꼬모카페","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":4769,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.51008,"longitude":127.0837,"bookYear":"2024","nameKR":"알라딘의양고기","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":5635,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5600090440077,"longitude":126.977093560851,"bookYear":"2024","nameKR":"진주집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":5913,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.51175,"longitude":127.0547,"bookYear":"2024","nameKR":"아야진생태찌개","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":5782,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.548721,"longitude":126.920283,"bookYear":"2024","nameKR":"겐로쿠우동","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":21885,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mimiholic1206","foodTypes":"일식","latitude":37.520036,"longitude":127.021089,"bookYear":"2024","nameKR":"미미면가","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":9071,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.54192,"longitude":126.9505,"bookYear":"2024","nameKR":"마포양지설렁탕","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":6399,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.47789,"longitude":126.8897,"bookYear":"2024","nameKR":"춘천옥","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":5931,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.54248,"longitude":126.9473,"bookYear":"2024","nameKR":"남해바다","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":5061,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.52554,"longitude":127.0323,"bookYear":"2024","nameKR":"장사랑","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":5046,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.560967,"longitude":126.983114,"bookYear":"2024","nameKR":"장수갈비집본가","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":5125,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.50144,"longitude":127.0244,"bookYear":"2024","nameKR":"강남교자","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":21510,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.53746,"longitude":126.9997,"bookYear":"2024","nameKR":"빠르크","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":6899,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tavern38seoul","foodTypes":"미국식","latitude":37.5196034778722,"longitude":127.019076195659,"bookYear":"2024","nameKR":"태번38","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":8001,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.491696,"longitude":126.90722,"bookYear":"2024","nameKR":"삼거리먼지막순대국","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":6409,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.55839,"longitude":126.977598,"bookYear":"2024","nameKR":"부원집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":6394,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.54987,"longitude":127.1288,"bookYear":"2024","nameKR":"초롱이고모부대찌개","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":7102,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.514777,"longitude":127.018792,"bookYear":"2024","nameKR":"오복꼬리곰탕","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":4792,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5679,"longitude":127.0451,"bookYear":"2024","nameKR":"군산아구산꽃게","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":6212,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.60884,"longitude":126.9556,"bookYear":"2024","nameKR":"장모님해장국","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":27447,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/soi_yeonnam/","foodTypes":"아시아식","latitude":37.56349,"longitude":126.9254,"bookYear":"2024","nameKR":"소이연남","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":6377,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yongmunsoup_night/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.53791,"longitude":126.9608,"bookYear":"2024","nameKR":"용문해장국","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":27439,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/polpo_bangbae/","foodTypes":"이탈리아식","latitude":37.494716,"longitude":126.990082,"bookYear":"2024","nameKR":"비스트로뽈뽀","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":26723,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중남미식","latitude":37.5481,"longitude":126.9228,"bookYear":"2024","nameKR":"구스토타코","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6849,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.56661113515573,"longitude":126.98849534336023,"bookYear":"2024","nameKR":"예전명가","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":5687,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.51892,"longitude":126.8715,"bookYear":"2024","nameKR":"평미가","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":13330,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.56156,"longitude":126.9241,"bookYear":"2024","nameKR":"월강부산돼지국밥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":6452,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.556,"longitude":126.8567,"bookYear":"2024","nameKR":"등촌최월선칼국수","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":6988,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5284,"longitude":126.9924,"bookYear":"2024","nameKR":"유진막국수","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":8965,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.56087,"longitude":127.0329,"bookYear":"2024","nameKR":"높은집종가산꼼장어","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":30407,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.53943141378686,"longitude":126.9437648759179,"bookYear":"2024","nameKR":"모연","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":28996,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/manpo.official","foodTypes":"한식(면류)","latitude":37.614102,"longitude":126.917447,"bookYear":"2024","nameKR":"만포면옥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":29157,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.551304,"longitude":126.910887,"bookYear":"2024","nameKR":"오스테리아샘킴","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":32013,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5034603568189,"longitude":127.022680491549,"bookYear":"2024","nameKR":"혜장국","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":30500,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/huampyeonbaek/","foodTypes":"일식","latitude":37.55101137211389,"longitude":126.976891369992,"bookYear":"2024","nameKR":"후암편백","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":32027,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5037329376349,"longitude":127.052982069884,"bookYear":"2024","nameKR":"농민백암순대","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":32049,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/terraceroom.seoul","foodTypes":"기타","latitude":37.524644994825,"longitude":127.042678785802,"bookYear":"2024","nameKR":"테라스룸","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31808,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bbq.lab","foodTypes":"한식(육류)","latitude":37.4759862858024,"longitude":127.043337447354,"bookYear":"2024","nameKR":"바베큐랩","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":32007,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jowu_official/","foodTypes":"한식(육류)","latitude":37.5225808095194,"longitude":127.027855578503,"bookYear":"2024","nameKR":"조우","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31360,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5669741682164,"longitude":127.06256224752,"bookYear":"2024","nameKR":"대흥설농탕","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":29134,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.552697,"longitude":127.088769,"bookYear":"2024","nameKR":"소바쿠","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":30708,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/itsumo_ramen/","foodTypes":"일식","latitude":37.5540010720065,"longitude":126.924626873497,"bookYear":"2024","nameKR":"이츠모라멘","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":30085,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5628428242687,"longitude":126.926049298178,"bookYear":"2024","nameKR":"오복수산","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":29935,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/the_charcuteria","foodTypes":"기타","latitude":37.53428344113129,"longitude":127.01009673581584,"bookYear":"2024","nameKR":"더샤퀴테리아","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":28336,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5096776765406,"longitude":127.055536318832,"bookYear":"2024","nameKR":"능라도강남점","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":27455,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seonghwaramen/","foodTypes":"일식","latitude":37.5558555943525,"longitude":126.926194388848,"bookYear":"2024","nameKR":"성화라멘","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31521,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/takemoto.miyako/","foodTypes":"일식","latitude":37.5461687337223,"longitude":126.979089400658,"bookYear":"2024","nameKR":"타케모토","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":29448,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.51085907051559,"longitude":127.05573411165798,"bookYear":"2024","nameKR":"경평면옥","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":29407,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.526362,"longitude":127.001948,"bookYear":"2024","nameKR":"종점숯불갈비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30201,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/crown_pig5109/","foodTypes":"한식(육류)","latitude":37.51852177044802,"longitude":127.02046975493539,"bookYear":"2024","nameKR":"크라운돼지","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30443,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.48654040068214,"longitude":126.85617585284692,"bookYear":"2024","nameKR":"태천옥","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":31138,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mememi.official/","foodTypes":"이탈리아식","latitude":37.523811310561,"longitude":127.041117630865,"bookYear":"2024","nameKR":"미미미가든","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":27580,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.55803,"longitude":126.9432,"bookYear":"2024","nameKR":"미스터서왕만두","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":31246,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5279203642126,"longitude":127.037497633767,"bookYear":"2024","nameKR":"압구정진주","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30412,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/woomadam_omakase","foodTypes":"한식(육류)","latitude":37.549575099755,"longitude":126.917355874781,"bookYear":"2024","nameKR":"우마담","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":30124,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/k.y_porkcutlet_yeonnam/","foodTypes":"일식","latitude":37.56115634873273,"longitude":126.92449856120587,"bookYear":"2024","nameKR":"경양카츠","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31337,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5537098162821,"longitude":126.918315070824,"bookYear":"2024","nameKR":"진향","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33051,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5424762304318,"longitude":127.093616159333,"bookYear":"2024","nameKR":"팀스쿡","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":33863,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hwachoonok_official/","foodTypes":"한식(육류)","latitude":37.5214834460024,"longitude":127.037710249005,"bookYear":"2024","nameKR":"화춘옥청담","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32607,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4948527051808,"longitude":127.038027166994,"bookYear":"2024","nameKR":"류경회관","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":33058,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5065396188048,"longitude":127.06280753409,"bookYear":"2024","nameKR":"이봉","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":33550,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/domaine_cheongdam/","foodTypes":"기타","latitude":37.5241378103505,"longitude":127.04144259491,"bookYear":"2024","nameKR":"도멘청담","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32759,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5028813541774,"longitude":127.041356540268,"bookYear":"2024","nameKR":"덴푸라랩","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":33592,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lenfance.bistro/","foodTypes":"프랑스식","latitude":37.5729199136655,"longitude":126.970063182815,"bookYear":"2024","nameKR":"렁팡스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":32224,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oncheonjip_dosan","foodTypes":"일식","latitude":37.5260718190467,"longitude":127.034712491629,"bookYear":"2024","nameKR":"온천집스키야키","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33364,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gwang_pyung/","foodTypes":"한식(면류)","latitude":37.5143594876356,"longitude":127.064878819823,"bookYear":"2024","nameKR":"광평","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":32566,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mamalee_dining/","foodTypes":"한식(일반한식)","latitude":37.5251775245928,"longitude":126.924876706923,"bookYear":"2024","nameKR":"마마리다이닝","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":32541,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5605790552175,"longitude":127.002123277822,"bookYear":"2024","nameKR":"호빈","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":33129,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5211495474917,"longitude":127.034729328677,"bookYear":"2024","nameKR":"한우리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":33483,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5596113961056,"longitude":126.837242277605,"bookYear":"2024","nameKR":"게장게장게장","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":32434,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dochwi_hanok","foodTypes":"한식(일반한식)","latitude":37.5768472187949,"longitude":126.968862082641,"bookYear":"2024","nameKR":"도취하녹식당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":33204,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.4876033476735,"longitude":127.049755052514,"bookYear":"2024","nameKR":"일일향","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":33146,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mangione_iksang/","foodTypes":"이탈리아식","latitude":37.5288173852679,"longitude":126.966653789116,"bookYear":"2024","nameKR":"만지오네","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32149,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/confier.seoul/","foodTypes":"컨템포러리","latitude":37.5578385325826,"longitude":126.9744151,"bookYear":"2024","nameKR":"콘피에르","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":33538,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4921758625358,"longitude":126.924271334879,"bookYear":"2024","nameKR":"서평면옥","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":32978,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5863108382758,"longitude":126.982009783933,"bookYear":"2024","nameKR":"개성철렵","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":32312,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.594539002298,"longitude":127.102280443169,"bookYear":"2024","nameKR":"용마해장국","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":32758,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.579026178285,"longitude":126.972084802501,"bookYear":"2024","nameKR":"태진복집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":32219,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5408991543251,"longitude":126.949156453911,"bookYear":"2024","nameKR":"월화식당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":32975,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5251775245928,"longitude":126.924876706923,"bookYear":"2024","nameKR":"렌위치","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":32690,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/barun_jo/","foodTypes":"한식(육류)","latitude":37.5054276033769,"longitude":127.024527634543,"bookYear":"2024","nameKR":"더바른정육식당","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":33597,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kushikawa_omacase/","foodTypes":"일식","latitude":37.4827269349012,"longitude":127.045678929685,"bookYear":"2024","nameKR":"쿠시카와","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":33054,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5109823973192,"longitude":127.058205573267,"bookYear":"2024","nameKR":"오크레스토랑","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":35263,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sarukame_ramen/","foodTypes":"일식","latitude":37.5610703891437,"longitude":126.922681739908,"bookYear":"2024","nameKR":"사루카메","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":36084,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5589936056287,"longitude":126.922291269024,"bookYear":"2024","nameKR":"키움참치(홍대점)","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":36127,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.4885591040326,"longitude":127.104470715166,"bookYear":"2024","nameKR":"지리산약초장어(서울본점)","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":34306,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5060995475667,"longitude":127.003407328203,"bookYear":"2024","nameKR":"온유월식당","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":34883,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5509656114371,"longitude":126.956732003277,"bookYear":"2024","nameKR":"황금콩밭","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":35840,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/haechen_obu/","foodTypes":"기타","latitude":37.5729358261699,"longitude":126.989165079423,"bookYear":"2024","nameKR":"해천어부","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":33300,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bonanzacoffee_korea/","foodTypes":"디저트/차/베이커리","latitude":37.5516413660904,"longitude":127.076203776649,"bookYear":"2024","nameKR":"보난자커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36358,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cscr.official/","foodTypes":"디저트/차/베이커리","latitude":36.3358826709238,"longitude":127.437080341283,"bookYear":"2024","nameKR":"챔프스페이스","ribbonType":"1","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":34787,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ledorer_official/","foodTypes":"바/주점","latitude":35.1554490471091,"longitude":129.146606834695,"bookYear":"2024","nameKR":"르도헤","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":35578,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":36.4779099639115,"longitude":127.290238012216,"bookYear":"2024","nameKR":"르비엣","ribbonType":"1","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":3840,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56585,"longitude":126.9287,"bookYear":"2024","nameKR":"청송함흥냉면","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":33119,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/25tuna_/","foodTypes":"일식","latitude":37.5066772102609,"longitude":126.756466806807,"bookYear":"2024","nameKR":"이오참치","ribbonType":"1","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":31221,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/stone.and.water.kr/","foodTypes":"디저트/차/베이커리","latitude":37.5131208985041,"longitude":127.119821871507,"bookYear":"2024","nameKR":"스톤앤워터","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":30238,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hohosikdang","foodTypes":"일식","latitude":37.58190337889023,"longitude":127.00003622828625,"bookYear":"2024","nameKR":"호호식당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":32464,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.4772854081322,"longitude":126.489890239425,"bookYear":"2024","nameKR":"우수미회센타","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":4158,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ccot_famille/","foodTypes":"디저트/차/베이커리","latitude":37.5044921229104,"longitude":127.007845270854,"bookYear":"2024","nameKR":"담장옆에국화꽃","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":4099,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/leesungdang_1945/","foodTypes":"디저트/차/베이커리","latitude":35.986947,"longitude":126.711164,"bookYear":"2024","nameKR":"이성당","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":2531,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.817827,"longitude":127.141016,"bookYear":"2024","nameKR":"삼백집","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":1968,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.369541,"longitude":127.110077,"bookYear":"2024","nameKR":"평가옥","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":4590,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/samdnoodle","foodTypes":"한식(면류)","latitude":33.506925,"longitude":126.53011,"bookYear":"2024","nameKR":"삼대국수회관","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":1236,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56639,"longitude":127.0316,"bookYear":"2024","nameKR":"유래회관","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":1264,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.582813,"longitude":127.000472,"bookYear":"2024","nameKR":"진아춘","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":1403,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.525422,"longitude":127.027559,"bookYear":"2024","nameKR":"목로","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":984,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/homesukki_0/","foodTypes":"기타","latitude":37.512006,"longitude":127.126226,"bookYear":"2024","nameKR":"홈수끼","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":995,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.52141,"longitude":127.0309,"bookYear":"2024","nameKR":"강남면옥","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":996,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.54043,"longitude":126.9678,"bookYear":"2024","nameKR":"강원정","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":1001,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.577407,"longitude":126.816112,"bookYear":"2024","nameKR":"고성막국수","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":1105,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.51188,"longitude":126.9144,"bookYear":"2024","nameKR":"막내회센타","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":1216,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.502091,"longitude":127.0583,"bookYear":"2024","nameKR":"여수오동도","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":773,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.56299,"longitude":126.9928,"bookYear":"2024","nameKR":"진고개","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":775,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.51216,"longitude":127.0196,"bookYear":"2024","nameKR":"진동둔횟집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":780,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.52134,"longitude":126.9274,"bookYear":"2024","nameKR":"진주집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":781,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56235,"longitude":126.9745,"bookYear":"2024","nameKR":"진주회관","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":782,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.52018,"longitude":126.9273,"bookYear":"2024","nameKR":"진진만두","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":798,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.634825,"longitude":127.070523,"bookYear":"2024","nameKR":"참누렁소가든","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":815,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.556791,"longitude":126.985334,"bookYear":"2024","nameKR":"촛불1978","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":957,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.52147,"longitude":127.0352,"bookYear":"2024","nameKR":"한우리한정식","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":962,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5210980098112,"longitude":126.929780312735,"bookYear":"2024","nameKR":"해동복국","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":975,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.521478,"longitude":127.037326,"bookYear":"2024","nameKR":"현복집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":576,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/intercontinental_coex/","foodTypes":"아시아식","latitude":37.5129436953442,"longitude":127.057114235894,"bookYear":"2024","nameKR":"아시안라이브","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":606,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.54896,"longitude":126.9697,"bookYear":"2024","nameKR":"양평매운탕","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":608,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/daesung_1967/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5774742408426,"longitude":127.028564198078,"bookYear":"2024","nameKR":"어머니대성집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":632,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.51616,"longitude":127.0174,"bookYear":"2024","nameKR":"영동설렁탕","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":661,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56425,"longitude":126.9999,"bookYear":"2024","nameKR":"오장동함흥냉면","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":681,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.57973,"longitude":126.9709,"bookYear":"2024","nameKR":"용금옥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":686,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.59267,"longitude":126.9984,"bookYear":"2024","nameKR":"우리밀국시","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":691,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":37.5198,"longitude":126.9401,"bookYear":"2024","nameKR":"워킹온더클라우드","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":692,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.534653,"longitude":126.974207,"bookYear":"2024","nameKR":"원대구탕","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":695,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.504,"longitude":127.0254,"bookYear":"2024","nameKR":"원주추어탕","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":711,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.566606,"longitude":126.988638,"bookYear":"2024","nameKR":"이남장","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":734,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.485118,"longitude":127.032125,"bookYear":"2024","nameKR":"자인뭉티기","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":419,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.567519,"longitude":126.980269,"bookYear":"2024","nameKR":"부민옥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":422,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.57389,"longitude":126.984,"bookYear":"2024","nameKR":"부산식당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":425,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.58006,"longitude":126.9806,"bookYear":"2024","nameKR":"황생가칼국수","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":479,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.495414,"longitude":127.016403,"bookYear":"2024","nameKR":"샘밭막국수","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":484,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.51942,"longitude":126.933,"bookYear":"2024","nameKR":"서글렁탕","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":491,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.48177,"longitude":127.0129,"bookYear":"2024","nameKR":"서초갈비","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":512,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.562029012273435,"longitude":126.97816920253568,"bookYear":"2024","nameKR":"속초생태집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":267,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.57838,"longitude":126.9859,"bookYear":"2024","nameKR":"로씨니","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":279,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56825,"longitude":126.9784,"bookYear":"2024","nameKR":"이북만두","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":280,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.49661,"longitude":127.0269,"bookYear":"2024","nameKR":"리샨","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":315,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56255,"longitude":126.9857,"bookYear":"2024","nameKR":"명동교자","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":387,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.52681,"longitude":127.038,"bookYear":"2024","nameKR":"뱃고동","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":82,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.50193,"longitude":127.0596,"bookYear":"2024","nameKR":"그란구스또","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":128,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.572194,"longitude":126.979423,"bookYear":"2024","nameKR":"남도식당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":131,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.50627,"longitude":127.0295,"bookYear":"2024","nameKR":"남서울민물장어","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":147,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.57508,"longitude":126.9852,"bookYear":"2024","nameKR":"뉘조","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":183,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.502007409189,"longitude":126.987786107024,"bookYear":"2024","nameKR":"대전집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":230,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.53583,"longitude":126.8954,"bookYear":"2024","nameKR":"또순이네","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":48,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.52963,"longitude":127.1359,"bookYear":"2024","nameKR":"고모네원조콩탕황태탕","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":56,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56806,"longitude":126.9795,"bookYear":"2024","nameKR":"곰국시집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":70,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.52142,"longitude":126.9307,"bookYear":"2024","nameKR":"구마산","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":75,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.58839,"longitude":127.0043,"bookYear":"2024","nameKR":"국시집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":76,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/______chrysanthemum/","foodTypes":"한식(일반한식)","latitude":37.59455,"longitude":126.9927,"bookYear":"2024","nameKR":"국화정원","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":79,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.57423,"longitude":126.9854,"bookYear":"2024","nameKR":"개성만두궁","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":1411,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.55193,"longitude":126.9884,"bookYear":"2024","nameKR":"엔그릴","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":1414,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.5659,"longitude":126.9726,"bookYear":"2024","nameKR":"남도식당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":1437,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5026522880225,"longitude":126.904472543578,"bookYear":"2024","nameKR":"원조호수삼계탕","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":1448,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56324,"longitude":126.9914,"bookYear":"2024","nameKR":"사랑방칼국수","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":1449,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.56728,"longitude":126.9815,"bookYear":"2024","nameKR":"철철복집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":1480,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.555174,"longitude":126.914191,"bookYear":"2024","nameKR":"쉐시몽","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":2838,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.5706158369189,"longitude":126.930706658777,"bookYear":"2024","nameKR":"쁠라뒤쥬르","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":3090,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5233085543086,"longitude":126.933882068409,"bookYear":"2024","nameKR":"수정","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":3108,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4833,"longitude":127.0124,"bookYear":"2024","nameKR":"봉산옥","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":3465,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.48816,"longitude":126.9079,"bookYear":"2024","nameKR":"등나무집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":3466,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.49289,"longitude":127.0131,"bookYear":"2024","nameKR":"서초장어타운","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":3521,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.52125,"longitude":127.0366,"bookYear":"2024","nameKR":"신사면옥","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":3739,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yukjeon/","foodTypes":"한식(육류)","latitude":37.54288,"longitude":126.947,"bookYear":"2024","nameKR":"역전회관","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":3889,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.51674,"longitude":127.0341,"bookYear":"2024","nameKR":"가람국시","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":3893,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.563425,"longitude":126.943674,"bookYear":"2024","nameKR":"효동각","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":3900,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.52143,"longitude":126.9302,"bookYear":"2024","nameKR":"여의도따로국밥","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":134,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56725,"longitude":126.9816,"bookYear":"2024","nameKR":"남포면옥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":3933,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hyojadongduomo/","foodTypes":"이탈리아식","latitude":37.58162,"longitude":126.9715,"bookYear":"2024","nameKR":"두오모","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":3949,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.56246,"longitude":126.9737,"bookYear":"2024","nameKR":"잼배옥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":3997,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.51681,"longitude":127.1131,"bookYear":"2024","nameKR":"산들해","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":4126,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.50039,"longitude":127.0007,"bookYear":"2024","nameKR":"남도미락","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":4150,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4986606964681,"longitude":127.001972587035,"bookYear":"2024","nameKR":"해남천일관","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":4196,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.52225,"longitude":126.912,"bookYear":"2024","nameKR":"덕원","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":4203,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6195,"longitude":127.0776,"bookYear":"2024","nameKR":"제일콩집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":4703,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.57393,"longitude":126.9713,"bookYear":"2024","nameKR":"사발","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":4745,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5206,"longitude":127.0328,"bookYear":"2024","nameKR":"마포숯불갈비","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":4793,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.55341,"longitude":127.0101,"bookYear":"2024","nameKR":"진남포면옥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8739,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.578944,"longitude":126.985564,"bookYear":"2024","nameKR":"한뫼촌","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8484,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5715235111942,"longitude":126.976222600985,"bookYear":"2024","nameKR":"가봉루","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":5092,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.49664,"longitude":127.0392,"bookYear":"2024","nameKR":"레드마블하우스","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":4907,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sinanchon3/","foodTypes":"한식(어패류)","latitude":37.57567,"longitude":126.971,"bookYear":"2024","nameKR":"신안촌","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":4926,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.53287,"longitude":126.8691,"bookYear":"2024","nameKR":"개성집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":6379,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.58995,"longitude":127.005,"bookYear":"2024","nameKR":"하단","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":5905,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.54599,"longitude":127.0542,"bookYear":"2024","nameKR":"성수족발","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":5804,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.548999,"longitude":126.922382,"bookYear":"2024","nameKR":"웃사브","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6368,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.52106,"longitude":126.9309,"bookYear":"2024","nameKR":"영의정","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":6398,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.55473,"longitude":127.0881,"bookYear":"2024","nameKR":"장군갈비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":6513,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.614682,"longitude":127.07843,"bookYear":"2024","nameKR":"청평매운탕","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":6462,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oasisbrunch/","foodTypes":"호주식","latitude":37.52475,"longitude":127.0406,"bookYear":"2024","nameKR":"오아시스","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":6433,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5146,"longitude":127.0533,"bookYear":"2024","nameKR":"차이린","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":6864,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.53493,"longitude":126.9031,"bookYear":"2024","nameKR":"이조보쌈","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":6704,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.51853,"longitude":127.0181,"bookYear":"2024","nameKR":"따뚱","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":6928,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tokyosaikabo/","foodTypes":"일식","latitude":37.525707,"longitude":127.052421,"bookYear":"2024","nameKR":"도쿄사이카보","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":6881,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.51051668092548,"longitude":127.02353498939178,"bookYear":"2024","nameKR":"구루메스시","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":6885,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.53472,"longitude":127.0087,"bookYear":"2024","nameKR":"마리또에몰리에","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":6502,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.60266,"longitude":127.0108,"bookYear":"2024","nameKR":"봉화묵집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":6497,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.57374,"longitude":126.9861,"bookYear":"2024","nameKR":"산촌","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":6840,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.54599,"longitude":126.9223,"bookYear":"2024","nameKR":"쿠시무라","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":7332,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.501898,"longitude":127.037291,"bookYear":"2024","nameKR":"싱카이","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":9095,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.57013,"longitude":126.9945,"bookYear":"2024","nameKR":"계림","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":12475,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56068572701359,"longitude":127.03303082484841,"bookYear":"2024","nameKR":"땅코참숯구이","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":7034,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.523035646485845,"longitude":126.97033878731338,"bookYear":"2024","nameKR":"갯마을","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":12471,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.52795,"longitude":127.0307,"bookYear":"2024","nameKR":"산동교자관","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":12443,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.562912,"longitude":126.926541,"bookYear":"2024","nameKR":"아필립","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":7857,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.602260750611,"longitude":126.926933124828,"bookYear":"2024","nameKR":"서부감자국","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":9283,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.519465,"longitude":126.939833,"bookYear":"2024","nameKR":"63뷔페파빌리온","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":9128,"bookStatus":"승인","websiteInstagram":"","foodTypes":"미국식","latitude":37.50305,"longitude":127.0277,"bookYear":"2024","nameKR":"브릭오븐","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":13352,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.504473,"longitude":127.055795,"bookYear":"2024","nameKR":"반룡산","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":16248,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.57262064091815,"longitude":126.97572100551191,"bookYear":"2024","nameKR":"설가온","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":12989,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.50335158488171,"longitude":127.03843409410236,"bookYear":"2024","nameKR":"이도곰탕","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":13329,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.51375250260386,"longitude":127.10444609981751,"bookYear":"2024","nameKR":"비채나","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":17751,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/minami_seocho/","foodTypes":"일식","latitude":37.4933,"longitude":127.0181,"bookYear":"2024","nameKR":"미나미","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":12749,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.517373,"longitude":126.865309,"bookYear":"2024","nameKR":"히노야마","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":15228,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.523315,"longitude":127.032782,"bookYear":"2024","nameKR":"하모","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":16919,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5166413661632,"longitude":127.018909049863,"bookYear":"2024","nameKR":"양평신내서울해장국큰아들집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":17884,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kiriyama_official/","foodTypes":"일식","latitude":37.49716,"longitude":127.0304,"bookYear":"2024","nameKR":"우동명가기리야마본진","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":17491,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5776100466268,"longitude":126.970805300872,"bookYear":"2024","nameKR":"마지","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":12958,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4949,"longitude":127.0274,"bookYear":"2024","nameKR":"조양관","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":19805,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5624,"longitude":126.9789,"bookYear":"2024","nameKR":"애성회관","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":19699,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.491243,"longitude":126.924082,"bookYear":"2024","nameKR":"가네끼스시","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":19804,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.509286,"longitude":127.022812,"bookYear":"2024","nameKR":"아나고야","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":22700,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.55499,"longitude":126.9237,"bookYear":"2024","nameKR":"하카타나카","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":19639,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.53867,"longitude":127.0021,"bookYear":"2024","nameKR":"베라피자","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":19426,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushigo_oh_chef/","foodTypes":"일식","latitude":37.49889,"longitude":126.9979,"bookYear":"2024","nameKR":"스시고","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":19132,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.458346,"longitude":127.011579,"bookYear":"2024","nameKR":"소나무집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":21437,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.556728,"longitude":127.042893,"bookYear":"2024","nameKR":"우동가조쿠","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":23577,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4796683841344,"longitude":127.124913859704,"bookYear":"2024","nameKR":"툇마루밥상","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":26653,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lecochonchef/","foodTypes":"프랑스식","latitude":37.5777601305254,"longitude":126.988415175695,"bookYear":"2024","nameKR":"르꼬숑","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":26434,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.560343,"longitude":126.923626,"bookYear":"2024","nameKR":"최사장네닭","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":26931,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.56966,"longitude":126.97489,"bookYear":"2024","nameKR":"구마스시","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":26428,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/moulin_official/","foodTypes":"프랑스식","latitude":37.58145,"longitude":126.9719,"bookYear":"2024","nameKR":"물랑","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":27400,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jirouramen/","foodTypes":"일식","latitude":37.553654,"longitude":126.925138,"bookYear":"2024","nameKR":"지로우라멘","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26596,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lmsno1/","foodTypes":"한식(어패류)","latitude":37.61351,"longitude":127.065,"bookYear":"2024","nameKR":"남해바다마차","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":23549,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.52098,"longitude":127.0216,"bookYear":"2024","nameKR":"오리지널팬케이크하우스","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":26593,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5102187627967,"longitude":127.05527375054,"bookYear":"2024","nameKR":"서백자간장게장","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":24445,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.634719,"longitude":127.027637,"bookYear":"2024","nameKR":"수유갈비산성골","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":23384,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5729405662598,"longitude":126.964003814558,"bookYear":"2024","nameKR":"능라밥상","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":27860,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oty_300/","foodTypes":"한식(일반한식)","latitude":37.517844350546795,"longitude":126.98032224591155,"bookYear":"2024","nameKR":"오통영","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":27731,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.49414,"longitude":126.9948,"bookYear":"2024","nameKR":"스시만","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":27453,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gallinadaisy/","foodTypes":"이탈리아식","latitude":37.57984,"longitude":126.9709,"bookYear":"2024","nameKR":"갈리나데이지","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":27449,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.58107,"longitude":126.9666,"bookYear":"2024","nameKR":"에노테카친친","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":28152,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.4821054065899,"longitude":126.950487720839,"bookYear":"2024","nameKR":"려","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":28197,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.55446,"longitude":126.9333,"bookYear":"2024","nameKR":"히노키공방","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":28215,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/scopa_the_chef/","foodTypes":"이탈리아식","latitude":37.52552,"longitude":127.0507,"bookYear":"2024","nameKR":"스코파더쉐프","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":27643,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.54837,"longitude":126.9213,"bookYear":"2024","nameKR":"츄리츄리","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28186,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.525439,"longitude":127.032238,"bookYear":"2024","nameKR":"스시코마츠","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":28544,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/olheum_seoul/","foodTypes":"한식(일반한식)","latitude":37.524644994825,"longitude":127.042678785802,"bookYear":"2024","nameKR":"옳음","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28600,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.560107,"longitude":127.033277,"bookYear":"2024","nameKR":"소나무","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":28414,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.535202,"longitude":127.008799,"bookYear":"2024","nameKR":"단비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":28612,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cucciologroup","foodTypes":"이탈리아식","latitude":37.523453,"longitude":127.036399,"bookYear":"2024","nameKR":"볼피노","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":28255,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중남미식","latitude":37.504425,"longitude":127.00776,"bookYear":"2024","nameKR":"텍사스데브라질","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":28575,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lamaindechef/","foodTypes":"이탈리아식","latitude":37.490398,"longitude":127.033044,"bookYear":"2024","nameKR":"라망드셰프","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":28486,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중남미식","latitude":37.482007,"longitude":126.996644,"bookYear":"2024","nameKR":"까르니브라질그릴","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":28358,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.560222,"longitude":126.925129,"bookYear":"2024","nameKR":"랑빠스81","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28260,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.52994,"longitude":126.922215,"bookYear":"2024","nameKR":"스시아이","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":28254,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.524327,"longitude":127.037348,"bookYear":"2024","nameKR":"우가","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":28564,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.53836393117944,"longitude":127.07092087005326,"bookYear":"2024","nameKR":"라구뜨","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":28455,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.568369,"longitude":126.97779,"bookYear":"2024","nameKR":"메이징에이","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":28334,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.567745,"longitude":126.982291,"bookYear":"2024","nameKR":"스시미토","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":29202,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.565347288841,"longitude":126.983735515555,"bookYear":"2024","nameKR":"하이디라오","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":29179,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.525289,"longitude":127.035494,"bookYear":"2024","nameKR":"수린","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29182,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wootender/","foodTypes":"한식(육류)","latitude":37.526957,"longitude":127.03578,"bookYear":"2024","nameKR":"우텐더","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29151,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.51449,"longitude":127.029443,"bookYear":"2024","nameKR":"홍명","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":29028,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/flowerrice_official/","foodTypes":"한식(일반한식)","latitude":37.575236,"longitude":126.984001,"bookYear":"2024","nameKR":"꽃,밥에피다","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":29102,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.568908,"longitude":126.975418,"bookYear":"2024","nameKR":"광화문국밥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29197,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeine.dining/","foodTypes":"이탈리아식","latitude":37.578689,"longitude":126.981832,"bookYear":"2024","nameKR":"제이네","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":29234,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chinabokchun/","foodTypes":"중식","latitude":37.559528,"longitude":126.924244,"bookYear":"2024","nameKR":"중화복춘","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":29276,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.565321,"longitude":126.979066,"bookYear":"2024","nameKR":"이나니와요스케","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":29642,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.49878070777355,"longitude":126.99786942617622,"bookYear":"2024","nameKR":"스시쇼우","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":29303,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5245580941157,"longitude":127.042300440704,"bookYear":"2024","nameKR":"비플리끄","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29428,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/the_xef/","foodTypes":"유럽식","latitude":37.5399721712719,"longitude":126.965214759448,"bookYear":"2024","nameKR":"더셰프","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":29459,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chef.zzo/","foodTypes":"기타","latitude":37.577607529639074,"longitude":126.97119563919911,"bookYear":"2024","nameKR":"오베르쥬 ","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":29259,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/menyamiko2020/","foodTypes":"일식","latitude":37.517356,"longitude":127.051313,"bookYear":"2024","nameKR":"멘야미코","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29609,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/latelier_comte.official/","foodTypes":"프랑스식","latitude":37.59139981236814,"longitude":126.9972780042131,"bookYear":"2024","nameKR":"라뜰리에꼼때","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":29420,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/myswitzerland_seoul/","foodTypes":"유럽식","latitude":37.5784729001341,"longitude":126.972564219356,"bookYear":"2024","nameKR":"라스위스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":29544,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5408011604849,"longitude":126.95248554210742,"bookYear":"2024","nameKR":"대물상회","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29576,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.557334550269836,"longitude":126.9167973827794,"bookYear":"2024","nameKR":"쿠시카츠쿠시엔","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":29481,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dining_juyeon","foodTypes":"이탈리아식","latitude":37.59349331688812,"longitude":127.088084611645,"bookYear":"2024","nameKR":"다이닝주연","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":29788,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.50199919273351,"longitude":126.88335175195856,"bookYear":"2024","nameKR":"진사향","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":29781,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/monol0gue_/","foodTypes":"일식","latitude":37.517745812005636,"longitude":127.02603486955721,"bookYear":"2024","nameKR":"모노로그","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":29782,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/takumi.gon/","foodTypes":"일식","latitude":37.4983905097385,"longitude":126.995611990694,"bookYear":"2024","nameKR":"타쿠미곤","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":29758,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.578040900912804,"longitude":127.05631395943723,"bookYear":"2024","nameKR":"은하곱창","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":30145,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.490841370015886,"longitude":126.89928112630038,"bookYear":"2024","nameKR":"린궁즈멘관","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":30180,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yosoojung/","foodTypes":"한식(일반한식)","latitude":37.547119300873,"longitude":126.941578380249,"bookYear":"2024","nameKR":"요수정","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":30260,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.515315163777615,"longitude":127.11752862698826,"bookYear":"2024","nameKR":"청와옥","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":30224,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sulnoon1977","foodTypes":"한식(면류)","latitude":37.491233489324316,"longitude":127.00976980121769,"bookYear":"2024","nameKR":"설눈","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":30531,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/venez_kitchen/","foodTypes":"이탈리아식","latitude":37.54903901328583,"longitude":127.07084950531714,"bookYear":"2024","nameKR":"브네","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":30334,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hongbok1958","foodTypes":"중식","latitude":37.56098778646127,"longitude":126.9800121050206,"bookYear":"2024","nameKR":"홍복","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":30304,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4936455498871,"longitude":126.993857605338,"bookYear":"2024","nameKR":"우주","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":30301,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/eo.sung/","foodTypes":"일식","latitude":37.52573778252187,"longitude":127.03294753291887,"bookYear":"2024","nameKR":"어성일식","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30432,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.51280919580697,"longitude":127.02165357641486,"bookYear":"2024","nameKR":"봉산평양냉면","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":30388,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56962955276772,"longitude":127.02320493673872,"bookYear":"2024","nameKR":"원할머니보쌈족발본점","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":30449,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.57274922285186,"longitude":126.93467481560019,"bookYear":"2024","nameKR":"비스트로카덴","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":30424,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/apgujeong_myeon_ok/","foodTypes":"한식(면류)","latitude":37.52646320855546,"longitude":127.02906072050142,"bookYear":"2024","nameKR":"압구정면옥","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30430,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.481620693698794,"longitude":127.04469111273866,"bookYear":"2024","nameKR":"르82","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":30394,"bookStatus":"커버","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.552187608984575,"longitude":127.14368312591535,"bookYear":"2024","nameKR":"앙상블","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":30558,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.52059572557547,"longitude":127.02968287095139,"bookYear":"2024","nameKR":"명인등심","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30532,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chef__tainer/","foodTypes":"이탈리아식","latitude":37.537703961537986,"longitude":126.99491293167496,"bookYear":"2024","nameKR":"셰프테이너","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30823,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tastycube_","foodTypes":"중식","latitude":37.5568390268236,"longitude":126.934589678597,"bookYear":"2024","nameKR":"정육면체","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":30637,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.46905469096468,"longitude":127.0411612115922,"bookYear":"2024","nameKR":"식영정","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":30636,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.504150929638236,"longitude":127.06487179442593,"bookYear":"2024","nameKR":"아선재","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":31015,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/taechunmyeonok/","foodTypes":"한식(면류)","latitude":37.5471245269241,"longitude":127.10267053054,"bookYear":"2024","nameKR":"태천면옥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":30771,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5608213723708,"longitude":126.993849717992,"bookYear":"2024","nameKR":"필동칼국수","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":30731,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5187672551656,"longitude":127.020691646267,"bookYear":"2024","nameKR":"돈불리제담","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30685,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shirakawa_tempura/","foodTypes":"일식","latitude":37.526968302491305,"longitude":127.03962276267629,"bookYear":"2024","nameKR":"시라카와","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30679,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5743269631718,"longitude":127.057330885456,"bookYear":"2024","nameKR":"리진","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":30659,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hajung0125/","foodTypes":"일식","latitude":37.550696993229145,"longitude":127.17224337890242,"bookYear":"2024","nameKR":"송하정스시","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":31121,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oncheonjip_ikseon/","foodTypes":"일식","latitude":37.5740999680943,"longitude":126.989799745222,"bookYear":"2024","nameKR":"온천집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":31306,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/baton_mealcafe","foodTypes":"기타","latitude":37.5264866412544,"longitude":126.962632956649,"bookYear":"2024","nameKR":"바통밀카페","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31241,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mongjoongsik/","foodTypes":"중식","latitude":37.5628310413654,"longitude":126.924656121785,"bookYear":"2024","nameKR":"몽중식","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31118,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/91hannampiece","foodTypes":"중식","latitude":37.5365636555465,"longitude":127.002134363783,"bookYear":"2024","nameKR":"피에세","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":31035,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/surak25noodles/","foodTypes":"한식(면류)","latitude":37.6739162617427,"longitude":127.052583116043,"bookYear":"2024","nameKR":"수락이오냉면","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":31252,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5246288889829,"longitude":127.040570885332,"bookYear":"2024","nameKR":"스시스미레","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31098,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5184094093552,"longitude":126.908454213015,"bookYear":"2024","nameKR":"청기와타운","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":31344,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5739029320481,"longitude":126.978983502911,"bookYear":"2024","nameKR":"미치루스시","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":31230,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kappo_akii/","foodTypes":"일식","latitude":37.5285655568387,"longitude":126.993107501389,"bookYear":"2024","nameKR":"타카","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31341,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/arouz_seoul","foodTypes":"기타","latitude":37.5471667734011,"longitude":127.005677166122,"bookYear":"2024","nameKR":"어라우즈","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":31396,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/aubouillon","foodTypes":"프랑스식","latitude":37.5506171094749,"longitude":127.026068932213,"bookYear":"2024","nameKR":"오부이용","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":31557,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushi_yz","foodTypes":"일식","latitude":37.5089551867803,"longitude":127.056808306719,"bookYear":"2024","nameKR":"스시욘즈","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":31646,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wgana_/","foodTypes":"한식(육류)","latitude":37.5241891623529,"longitude":127.037409337867,"bookYear":"2024","nameKR":"더블유가나","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31467,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5156966686084,"longitude":127.039886464663,"bookYear":"2024","nameKR":"옥면가","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31576,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.557010458319,"longitude":126.977683897926,"bookYear":"2024","nameKR":"회현식당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":31367,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5366048791746,"longitude":127.133115595362,"bookYear":"2024","nameKR":"스시모루","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":31511,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.5586686785768,"longitude":126.968671473835,"bookYear":"2024","nameKR":"르셰프블루","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":31579,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/epanoui_seoul/","foodTypes":"프랑스식","latitude":37.5218581526673,"longitude":127.042543826804,"bookYear":"2024","nameKR":"에빠뉘","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31644,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4957720180583,"longitude":127.134972963035,"bookYear":"2024","nameKR":"소우리정육점식당","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":31961,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/trattoriasamkim/","foodTypes":"이탈리아식","latitude":37.5238090344097,"longitude":127.037677142475,"bookYear":"2024","nameKR":"뜨라또리아샘킴","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31839,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yakitorimook","foodTypes":"일식","latitude":37.5646633414656,"longitude":126.92456870688,"bookYear":"2024","nameKR":"야키도리묵","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31824,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5474482522073,"longitude":127.143698245033,"bookYear":"2024","nameKR":"황푸차이나","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":31822,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/monksbutcher","foodTypes":"기타","latitude":37.5354565227693,"longitude":126.999230852921,"bookYear":"2024","nameKR":"몽크스부처","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31986,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/woojoo.ok/","foodTypes":"한식(면류)","latitude":37.5636331027913,"longitude":126.926795769119,"bookYear":"2024","nameKR":"우주옥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32023,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/siio_official/","foodTypes":"이탈리아식","latitude":37.5205778099808,"longitude":127.03921056508,"bookYear":"2024","nameKR":"시오","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32144,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cement_seoul/","foodTypes":"이탈리아식","latitude":37.535842687869,"longitude":127.001219236784,"bookYear":"2024","nameKR":"시멘트","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":32073,"bookStatus":"승인","websiteInstagram":"","foodTypes":"컨템포러리","latitude":37.5095752689071,"longitude":127.0446101651,"bookYear":"2024","nameKR":"비언유주얼 강남점","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":32249,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5221099525237,"longitude":127.045055412969,"bookYear":"2024","nameKR":"스시상현","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32208,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.526282574268,"longitude":126.962950612322,"bookYear":"2024","nameKR":"미미옥(신용산점)","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32248,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tempura_kiiro/","foodTypes":"일식","latitude":37.5195101291255,"longitude":127.019106035326,"bookYear":"2024","nameKR":"키이로","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":32105,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ikik_official","foodTypes":"일식","latitude":37.5241845160433,"longitude":126.87245802316,"bookYear":"2024","nameKR":"이키이키","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":32262,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gentil_seoul/","foodTypes":"기타","latitude":37.5081533736736,"longitude":127.086278321079,"bookYear":"2024","nameKR":"정띠","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32280,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hansang_the_table/","foodTypes":"한식(일반한식)","latitude":37.5226041599715,"longitude":127.041851367598,"bookYear":"2024","nameKR":"한상더테이블","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32496,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5330890858597,"longitude":126.963775427136,"bookYear":"2024","nameKR":"더보일러스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32331,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lestiuhannam/","foodTypes":"유럽식","latitude":37.5331395404854,"longitude":127.003796513813,"bookYear":"2024","nameKR":"레에스티우","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":32638,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gyuban_official/","foodTypes":"한식(일반한식)","latitude":37.5664884462972,"longitude":126.981025044659,"bookYear":"2024","nameKR":"규반","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":32726,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5750118075378,"longitude":126.981249627719,"bookYear":"2024","nameKR":"스시와","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":32625,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/becca_premium_deli/","foodTypes":"기타","latitude":37.5037840530077,"longitude":127.06025436091,"bookYear":"2024","nameKR":"베카프리미엄델리샵","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":32597,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5137519612953,"longitude":127.104446890835,"bookYear":"2024","nameKR":"바이킹스워프","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32972,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/elpino323/","foodTypes":"중남미식","latitude":37.0630110710332,"longitude":127.051216029694,"bookYear":"2024","nameKR":"엘피노323","ribbonType":"1","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":32864,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.555621576538,"longitude":126.919928084467,"bookYear":"2024","nameKR":"안동한우마을","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33009,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/eggnflour_pasta/","foodTypes":"이탈리아식","latitude":37.5453628880082,"longitude":126.984383275318,"bookYear":"2024","nameKR":"에그앤플라워","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":32728,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.633802248678,"longitude":126.917738964075,"bookYear":"2024","nameKR":"포옹싸","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":33095,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5166377998582,"longitude":127.051864456444,"bookYear":"2024","nameKR":"청담나인","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33069,"bookStatus":"승인","websiteInstagram":"","foodTypes":"컨템포러리","latitude":37.5259032192094,"longitude":127.035092978477,"bookYear":"2024","nameKR":"스패출러바이해비치","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33537,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/contedetulear/","foodTypes":"기타","latitude":37.5255631741936,"longitude":127.036889294894,"bookYear":"2024","nameKR":"꽁티드툴레아","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33391,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/isokwoohwa/","foodTypes":"한식(육류)","latitude":37.5349423314645,"longitude":127.010231124633,"bookYear":"2024","nameKR":"이속우화","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33472,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.4846790484395,"longitude":127.045475354701,"bookYear":"2024","nameKR":"음이터리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":33362,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/onyvaseoul/","foodTypes":"프랑스식","latitude":37.5251927478858,"longitude":127.045718384766,"bookYear":"2024","nameKR":"오니바","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33250,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cheilburgo/","foodTypes":"기타","latitude":37.5263872750909,"longitude":127.051257025733,"bookYear":"2024","nameKR":"제일버거 ","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33426,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/neulbokjip/ ","foodTypes":"한식(어패류)","latitude":37.479473537579,"longitude":127.102804582731,"bookYear":"2024","nameKR":"늘복집","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":33710,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gyemoim.co.kr/","foodTypes":"한식(가금류)","latitude":37.5578835476535,"longitude":126.967661584299,"bookYear":"2024","nameKR":"계모임","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":33549,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cavistry_caviardining/","foodTypes":"기타","latitude":37.5224791634001,"longitude":127.031153770592,"bookYear":"2024","nameKR":"캐비스트리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33577,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/forestkitchen.official/","foodTypes":"기타","latitude":37.5137519612953,"longitude":127.104446890835,"bookYear":"2024","nameKR":"포리스트키친","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34495,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushikase_lotte/","foodTypes":"일식","latitude":37.5647299033135,"longitude":126.981730421825,"bookYear":"2024","nameKR":"스시카세","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":34613,"bookStatus":"승인","websiteInstagram":"http://instagram.com/_kiwajip_","foodTypes":"한식(육류)","latitude":37.609449827136,"longitude":127.052207218213,"bookYear":"2024","nameKR":"고센기와집갈비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":34462,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5744668688197,"longitude":126.983552182559,"bookYear":"2024","nameKR":"스페이스오","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":36500,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5709958592808,"longitude":126.978914477333,"bookYear":"2024","nameKR":"만리지화","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":36254,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.4982007062265,"longitude":126.923839026725,"bookYear":"2024","nameKR":"상남스시","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":36541,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/borgo_hannam","foodTypes":"이탈리아식","latitude":37.5370310977791,"longitude":127.001950855071,"bookYear":"2024","nameKR":"보르고한남","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":12793,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.198672,"longitude":128.573088,"bookYear":"2024","nameKR":"노렌스시","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":32402,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sway_ap","foodTypes":"이탈리아식","latitude":36.3478400269344,"longitude":127.397129469285,"bookYear":"2024","nameKR":"스웨이","ribbonType":"1","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":4631,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.44174160085888,"longitude":126.67180231454223,"bookYear":"2024","nameKR":"학익궁중삼계탕","ribbonType":"1","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":30957,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/perlen_official/","foodTypes":"디저트/차/베이커리","latitude":37.5620527838076,"longitude":126.920970411501,"bookYear":"2024","nameKR":"펠른","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":29506,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/suijae1928/","foodTypes":"한식(육류)","latitude":36.62280589422268,"longitude":127.42528949994461,"bookYear":"2024","nameKR":"수이재1928","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":30887,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seogawon_guksoo/?hl=ko","foodTypes":"한식(면류)","latitude":35.18993,"longitude":129.08733,"bookYear":"2024","nameKR":"서가원국수","ribbonType":"1","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":4002,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.147418,"longitude":126.912695,"bookYear":"2024","nameKR":"예향식당","ribbonType":"1","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":5233,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.158988,"longitude":129.056675,"bookYear":"2024","nameKR":"사미헌","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":527,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.59505415381474,"longitude":126.99483656083896,"bookYear":"2024","nameKR":"수연산방","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":31683,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.4940281450966,"longitude":127.248659282177,"bookYear":"2024","nameKR":"테이블레이","ribbonType":"1","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":33240,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gwehdo/","foodTypes":"디저트/차/베이커리","latitude":37.5771335165691,"longitude":126.969059394981,"bookYear":"2024","nameKR":"궤도","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":34518,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mangetsu_kor","foodTypes":"일식","latitude":35.1666896767145,"longitude":129.157451291448,"bookYear":"2024","nameKR":"나가하마만게츠","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":27683,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.50122,"longitude":127.0355,"bookYear":"2024","nameKR":"루프탑클라우드","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":1591,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.756447,"longitude":128.894858,"bookYear":"2024","nameKR":"강릉감자옹심","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":34579,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":35.1986613756611,"longitude":129.221107738456,"bookYear":"2024","nameKR":"아쁘앙","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":31262,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/italy_bb20","foodTypes":"이탈리아식","latitude":37.4888768572114,"longitude":126.781865195693,"bookYear":"2024","nameKR":"이태리백반","ribbonType":"1","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":31851,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6775848797429,"longitude":126.75588635188,"bookYear":"2024","nameKR":"꿀양집","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":4529,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8725612844738,"longitude":128.594105919501,"bookYear":"2024","nameKR":"녹양","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":8959,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.00717924203169,"longitude":127.78699679181844,"bookYear":"2024","nameKR":"원조나루터재첩식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":23103,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.15565900037182,"longitude":129.0581417389067,"bookYear":"2024","nameKR":"기장손칼국수","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":35366,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4947910560605,"longitude":126.724853680131,"bookYear":"2024","nameKR":"카페뮤게트","ribbonType":"1","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":9208,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.930502,"longitude":127.788979,"bookYear":"2024","nameKR":"참나무숯불닭갈비","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":5679,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.677258,"longitude":127.480745,"bookYear":"2024","nameKR":"대추나무집","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":8125,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3971211074072,"longitude":126.925135034907,"bookYear":"2024","nameKR":"남부정육점","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":7776,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gijanggomjangeo/ ","foodTypes":"한식(어패류)","latitude":35.185384,"longitude":129.211399,"bookYear":"2024","nameKR":"기장곰장어","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":6994,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sokcho_ilculbong/","foodTypes":"한식(어패류)","latitude":38.181821113784,"longitude":128.607873036875,"bookYear":"2024","nameKR":"일출봉횟집","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":6487,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hanbuk_co/","foodTypes":"바/주점","latitude":37.53395563305231,"longitude":127.00806468152406,"bookYear":"2024","nameKR":"한남북엇국","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":6033,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.563534,"longitude":128.731965,"bookYear":"2024","nameKR":"거창갈비","ribbonType":"1","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":5969,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.838394,"longitude":127.132877,"bookYear":"2024","nameKR":"금암피순대","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":25573,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chaconn_e/","foodTypes":"바/주점","latitude":37.576407472395424,"longitude":126.97147232183104,"bookYear":"2024","nameKR":"서촌계단집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":29577,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chef.haku/","foodTypes":"바/주점","latitude":37.73769071651059,"longitude":127.04006607679563,"bookYear":"2024","nameKR":"흥선","ribbonType":"1","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":30888,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sogop_friend/","foodTypes":"한식(육류)","latitude":37.2591165009604,"longitude":127.044694930826,"bookYear":"2024","nameKR":"소곱친구","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":3301,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.161374,"longitude":126.905559,"bookYear":"2024","nameKR":"영미오리탕","ribbonType":"1","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":3262,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sariwon52/","foodTypes":"한식(면류)","latitude":36.326123,"longitude":127.426548,"bookYear":"2024","nameKR":"사리원면옥","ribbonType":"1","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":3169,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/suncheon_myeonggunggwan/","foodTypes":"한식(일반한식)","latitude":34.949763,"longitude":127.486257,"bookYear":"2024","nameKR":"순천한정식명궁관","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":31711,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/enaka1248/","foodTypes":"일식","latitude":37.4754603774713,"longitude":126.869641862784,"bookYear":"2024","nameKR":"이나까","ribbonType":"1","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":2413,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.793936,"longitude":126.385641,"bookYear":"2024","nameKR":"독천식당","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":2044,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.193976,"longitude":128.060696,"bookYear":"2024","nameKR":"하연옥","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":2038,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.190806,"longitude":128.07976,"bookYear":"2024","nameKR":"유정장어","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":37429,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kappomatsu/","foodTypes":"일식","latitude":35.8175868760044,"longitude":127.104693118573,"bookYear":"2024","nameKR":"갓포마츠","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38711,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.0018360359029,"longitude":127.066333846004,"bookYear":"2024","nameKR":"신라가든","ribbonType":"1","zone2_1":"경기","zone2_2":"연천군","status":"ACTIVE"},
        {"id":1773,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2767236812888,"longitude":127.116612515025,"bookYear":"2024","nameKR":"신갈나주집","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":4067,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.15891,"longitude":128.986561,"bookYear":"2024","nameKR":"태성실비식당","ribbonType":"1","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":4415,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.739559,"longitude":127.734111,"bookYear":"2024","nameKR":"복춘식당","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":27880,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7576687037693,"longitude":127.656714108966,"bookYear":"2024","nameKR":"미로횟집","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":2042,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1951084738721,"longitude":128.084751615748,"bookYear":"2024","nameKR":"제일식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":2229,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/solbat_mj/","foodTypes":"한식(일반한식)","latitude":36.41505294773959,"longitude":127.3822495116106,"bookYear":"2024","nameKR":"솔밭묵집","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":6207,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.385463,"longitude":127.119615,"bookYear":"2024","nameKR":"서현실비","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":30785,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/parc__prive","foodTypes":"이탈리아식","latitude":37.7062313364754,"longitude":127.060990898967,"bookYear":"2024","nameKR":"파크프리베","ribbonType":"1","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":4854,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.219245,"longitude":126.251344,"bookYear":"2024","nameKR":"덕승식당","ribbonType":"1","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":36470,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/rhinebeck.co","foodTypes":"미국식","latitude":35.188455719102,"longitude":126.830969839581,"bookYear":"2024","nameKR":"라인벡","ribbonType":"1","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":36496,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1640800813671,"longitude":129.170077834284,"bookYear":"2024","nameKR":"토라후구가","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":17972,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1688468877476,"longitude":129.166335995964,"bookYear":"2024","nameKR":"해운대가야밀면","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":29217,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeontongcrab","foodTypes":"한식(어패류)","latitude":36.644421093104,"longitude":127.457923744541,"bookYear":"2024","nameKR":"전통꽃게장","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":30271,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.85971485931228,"longitude":126.77732163659992,"bookYear":"2024","nameKR":"모아냉면","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":30640,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bar_sonamu","foodTypes":"바/주점","latitude":35.859978753110774,"longitude":128.60479518200793,"bookYear":"2024","nameKR":"소나무","ribbonType":"1","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":37930,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1633016982795,"longitude":129.161339790841,"bookYear":"2024","nameKR":"해운대원조할매국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":37007,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/haewol_coffee_official","foodTypes":"디저트/차/베이커리","latitude":37.389004286781,"longitude":126.635327347312,"bookYear":"2024","nameKR":"해월커피(송도점)","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":33839,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/doable_8/","foodTypes":"중식","latitude":36.3235796585697,"longitude":127.425205918343,"bookYear":"2024","nameKR":"도어블","ribbonType":"1","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":12530,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.7707491901144,"longitude":128.950108871906,"bookYear":"2024","nameKR":"산토리니","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33214,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/monert_cake/","foodTypes":"디저트/차/베이커리","latitude":37.4910941147932,"longitude":127.074163955752,"bookYear":"2024","nameKR":"모너트","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":37643,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gatchi_arim","foodTypes":"한식(일반한식)","latitude":35.6902615981601,"longitude":127.925744229977,"bookYear":"2024","nameKR":"가치","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"거창군","status":"ACTIVE"},
        {"id":35166,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffee_hwa_roasters","foodTypes":"디저트/차/베이커리","latitude":37.3879630465081,"longitude":126.667249614783,"bookYear":"2024","nameKR":"커피화로스터스본점","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":2210,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.448022,"longitude":127.411042,"bookYear":"2024","nameKR":"청주남주동해장국","ribbonType":"1","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":2344,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.4185279909319,"longitude":126.655151731132,"bookYear":"2024","nameKR":"긴자(송도유원지점)","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":30797,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kkaebiok/ ","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3934747558952,"longitude":126.646717372459,"bookYear":"2024","nameKR":"깨비옥(월드마크점)","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":29131,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chae_yoon_hee/","foodTypes":"바/주점","latitude":37.515397466383,"longitude":126.894642746351,"bookYear":"2024","nameKR":"채윤희","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":31794,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mogwolbread/","foodTypes":"디저트/차/베이커리","latitude":35.214832272915,"longitude":127.465910915213,"bookYear":"2024","nameKR":"목월빵집","ribbonType":"1","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":36505,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/travertine_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.5248745716605,"longitude":126.962078419523,"bookYear":"2024","nameKR":"트래버틴","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":27756,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/17dossi_chocolatier","foodTypes":"디저트/차/베이커리","latitude":37.5587445543563,"longitude":126.924488771563,"bookYear":"2024","nameKR":"17도씨","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28598,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.417262,"longitude":127.124761,"bookYear":"2024","nameKR":"면통단","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":3141,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/saesoli_mulsoli/","foodTypes":"디저트/차/베이커리","latitude":37.454467,"longitude":127.101943,"bookYear":"2024","nameKR":"새소리물소리","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":3283,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yoo__jeong/","foodTypes":"한식(가금류)","latitude":37.46169350447387,"longitude":127.15572174393746,"bookYear":"2024","nameKR":"유정집","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":3728,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.49728,"longitude":126.9985,"bookYear":"2024","nameKR":"시실리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":16950,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/butanchu_seoul/","foodTypes":"일식","latitude":37.55636,"longitude":126.9267,"bookYear":"2024","nameKR":"부탄츄","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26595,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/1688mama_shuijiao/","foodTypes":"중식","latitude":37.59176,"longitude":126.9135,"bookYear":"2024","nameKR":"마마수제만두","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":29410,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/basakmacha_cutlets_shop/","foodTypes":"기타","latitude":37.555286,"longitude":126.906548,"bookYear":"2024","nameKR":"바삭마차","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":36483,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.8218985993036,"longitude":128.608464997373,"bookYear":"2024","nameKR":"명화식당 ","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":36458,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chi_rori/","foodTypes":"바/주점","latitude":33.505887396405,"longitude":126.540701882785,"bookYear":"2024","nameKR":"치로리","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":35091,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thehan_dining/","foodTypes":"한식(일반한식)","latitude":36.338013170451,"longitude":127.344402483639,"bookYear":"2024","nameKR":"더한다이닝","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":35271,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/strutcoffee/","foodTypes":"디저트/차/베이커리","latitude":35.1540996887899,"longitude":129.066832410219,"bookYear":"2024","nameKR":"스트럿커피 ","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":2825,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.346002,"longitude":127.792407,"bookYear":"2024","nameKR":"선광집","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":36482,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/royandmay","foodTypes":"중식","latitude":33.4094889585963,"longitude":126.900889549879,"bookYear":"2024","nameKR":"로이앤메이","ribbonType":"1","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":36822,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/fairfield_songdobeach","foodTypes":"기타","latitude":35.0776929951861,"longitude":129.019261113815,"bookYear":"2024","nameKR":"송도키친","ribbonType":"1","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":34808,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.1226525525869,"longitude":129.045269995971,"bookYear":"2024","nameKR":"산동완탕교자관","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":34800,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/busanjung0878/","foodTypes":"일식","latitude":35.1433406570452,"longitude":129.055805544287,"bookYear":"2024","nameKR":"부산정","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":32738,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/la_ronde_carree/","foodTypes":"프랑스식","latitude":35.1320139793883,"longitude":126.945467761557,"bookYear":"2024","nameKR":"라롱드꺄레","ribbonType":"1","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":13192,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7471090383513,"longitude":126.486294894912,"bookYear":"2024","nameKR":"강화국수","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":35261,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seatown_bakery/ ","foodTypes":"디저트/차/베이커리","latitude":35.1460920097159,"longitude":129.114118806297,"bookYear":"2024","nameKR":"바닷마을과자점","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36459,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/papero.jeju","foodTypes":"이탈리아식","latitude":33.4848872998055,"longitude":126.482738634657,"bookYear":"2024","nameKR":"파페로 ","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":32737,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/finedining_official/","foodTypes":"한식(일반한식)","latitude":35.8151597527019,"longitude":127.104828246858,"bookYear":"2024","nameKR":"파인","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2895,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.844898,"longitude":128.425034,"bookYear":"2024","nameKR":"동광식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":3281,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.080863,"longitude":127.052711,"bookYear":"2024","nameKR":"김네집","ribbonType":"1","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":33031,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/restaurant.autrement/","foodTypes":"프랑스식","latitude":34.9476908950119,"longitude":127.502436585885,"bookYear":"2024","nameKR":"오트르망","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":36487,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.5671049839985,"longitude":128.160328779692,"bookYear":"2024","nameKR":"적사부","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"합천군","status":"ACTIVE"},
        {"id":35291,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yakitori__ken/","foodTypes":"일식","latitude":35.8173526105163,"longitude":127.103580841516,"bookYear":"2024","nameKR":"야키토리켄","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":35228,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/d.ramsey_r/","foodTypes":"프랑스식","latitude":35.1569650933316,"longitude":129.120821242473,"bookYear":"2024","nameKR":"램지","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36442,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.8472524804914,"longitude":126.494113916572,"bookYear":"2024","nameKR":"옛날시골밥상","ribbonType":"1","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":33000,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_kiye0n_/","foodTypes":"일식","latitude":35.9866114767312,"longitude":126.70764217807,"bookYear":"2024","nameKR":"기연","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":35276,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1562465441926,"longitude":129.11822455579,"bookYear":"2024","nameKR":"누아쥬앤모찌꼬","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":31226,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/museebakery/","foodTypes":"디저트/차/베이커리","latitude":36.3253924157264,"longitude":127.424941579092,"bookYear":"2024","nameKR":"뮤제","ribbonType":"1","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":2937,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.33259,"longitude":127.430975,"bookYear":"2024","nameKR":"신도칼국수","ribbonType":"1","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":3278,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.554684,"longitude":129.319874,"bookYear":"2024","nameKR":"궁중삼계탕","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":26986,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.3170817325281,"longitude":127.37406476312563,"bookYear":"2024","nameKR":"홍운장","ribbonType":"1","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":8161,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.3285622363718,"longitude":127.431600699161,"bookYear":"2024","nameKR":"개천식당","ribbonType":"1","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":1608,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.07549679485068,"longitude":128.62195130248037,"bookYear":"2024","nameKR":"단양면옥","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":1772,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4876858884669,"longitude":127.162164076795,"bookYear":"2024","nameKR":"나주개미집","ribbonType":"1","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":34684,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1952680699079,"longitude":129.078939381831,"bookYear":"2024","nameKR":"보느파티쓰리","ribbonType":"1","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":2724,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.972802,"longitude":127.922059,"bookYear":"2024","nameKR":"운정식당","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":35009,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/blacksoybean_noodles/","foodTypes":"한식(면류)","latitude":35.1485810625633,"longitude":126.908810918137,"bookYear":"2024","nameKR":"김명화서리태콩국수","ribbonType":"1","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":34685,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/boulangerie_monsieur_vincent/","foodTypes":"디저트/차/베이커리","latitude":35.1463730323694,"longitude":129.112923396395,"bookYear":"2024","nameKR":"무슈뱅상","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35199,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.0106518367381,"longitude":126.69701855442,"bookYear":"2024","nameKR":"유정식당","ribbonType":"1","zone2_1":"충남","zone2_2":"서천군","status":"ACTIVE"},
        {"id":34767,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":35.1477112784785,"longitude":129.108888435311,"bookYear":"2024","nameKR":"611우드파이어","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":6351,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.273409,"longitude":127.04632,"bookYear":"2024","nameKR":"군포해물탕","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34712,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/haegong_soseoldam_official/","foodTypes":"일식","latitude":35.1582366420697,"longitude":129.127113877846,"bookYear":"2024","nameKR":"야키토리해공","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36449,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seaowall","foodTypes":"이탈리아식","latitude":34.827113495811,"longitude":128.420035363005,"bookYear":"2024","nameKR":"오월","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":32429,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.6318084788914,"longitude":127.491280602384,"bookYear":"2024","nameKR":"당조","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":2517,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gyodong_tea/","foodTypes":"디저트/차/베이커리","latitude":35.814081,"longitude":127.152937,"bookYear":"2024","nameKR":"교동다원","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":5308,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.321983,"longitude":127.40663,"bookYear":"2024","nameKR":"오류옥천가","ribbonType":"1","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":2945,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1737,"longitude":129.10882,"bookYear":"2024","nameKR":"옥미아구찜","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":3193,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.472478,"longitude":126.628214,"bookYear":"2024","nameKR":"경인면옥","ribbonType":"1","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":3204,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.26657906950981,"longitude":129.08473900435,"bookYear":"2024","nameKR":"구포촌국수","ribbonType":"1","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":6037,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.31924258099379,"longitude":128.2603468220629,"bookYear":"2024","nameKR":"다시식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"의령군","status":"ACTIVE"},
        {"id":35797,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushi.kaito.gn","foodTypes":"일식","latitude":37.7834491282873,"longitude":128.916863885661,"bookYear":"2024","nameKR":"스시카이토","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":4476,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.171876,"longitude":128.992863,"bookYear":"2024","nameKR":"김서방네닭갈비","ribbonType":"1","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":7033,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.105793,"longitude":129.023468,"bookYear":"2024","nameKR":"동화반점","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":30177,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/creamilk/ ","foodTypes":"디저트/차/베이커리","latitude":37.5992044446472,"longitude":127.13817040116882,"bookYear":"2024","nameKR":"크리밀크","ribbonType":"1","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":5999,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.205451,"longitude":128.996694,"bookYear":"2024","nameKR":"금용","ribbonType":"1","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":34793,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.228923670893,"longitude":129.084924015682,"bookYear":"2024","nameKR":"미각반점","ribbonType":"1","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":2047,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":34.867141,"longitude":128.73031,"bookYear":"2024","nameKR":"천화원","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":35331,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/month.coffee/","foodTypes":"디저트/차/베이커리","latitude":35.1581324184655,"longitude":129.067912588795,"bookYear":"2024","nameKR":"먼스커피바","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":21941,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.816119,"longitude":127.151217,"bookYear":"2024","nameKR":"양반가","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":31201,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kurumi_sweets/?igshid=hf1abzhmkvdu","foodTypes":"디저트/차/베이커리","latitude":35.2127551847224,"longitude":129.080466522883,"bookYear":"2024","nameKR":"쿠루미과자점","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":1660,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.203376,"longitude":128.591867,"bookYear":"2024","nameKR":"송도물회","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":31449,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.2123357196656,"longitude":129.063469116458,"bookYear":"2024","nameKR":"백객도","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":2120,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.826771,"longitude":128.626493,"bookYear":"2024","nameKR":"풍기삼계탕","ribbonType":"1","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":32500,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffee_space_bar/","foodTypes":"디저트/차/베이커리","latitude":35.145626965806,"longitude":129.067534638427,"bookYear":"2024","nameKR":"커피스페이스바","ribbonType":"1","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":7793,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.820875,"longitude":128.631877,"bookYear":"2024","nameKR":"원조제주도백록담통도야지","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":6115,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.394086,"longitude":126.964462,"bookYear":"2024","nameKR":"흘수선","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":8160,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.3270065537574,"longitude":127.430571079004,"bookYear":"2024","nameKR":"소나무집","ribbonType":"1","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":32448,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3020001561347,"longitude":127.56687826551,"bookYear":"2024","nameKR":"옥천초량순대","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":2715,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.48578970062195,"longitude":127.71725163999983,"bookYear":"2024","nameKR":"신라식당","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":20349,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1019367993296,"longitude":129.029343284082,"bookYear":"2024","nameKR":"서진섭돼지국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":34812,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2056976186142,"longitude":129.124478849782,"bookYear":"2024","nameKR":"새총횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":6565,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.843103,"longitude":128.431527,"bookYear":"2024","nameKR":"한려곰장어","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":18746,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":0,"longitude":0,"bookYear":"2024","nameKR":"맛있게먹는날","ribbonType":"1","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":34159,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.9343466079439,"longitude":126.962682280029,"bookYear":"2024","nameKR":"풍성제과","ribbonType":"1","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":2217,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.33548,"longitude":127.428877,"bookYear":"2024","nameKR":"명랑식당","ribbonType":"1","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":2941,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.114282,"longitude":129.037131,"bookYear":"2024","nameKR":"평산옥","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":30091,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ble_boulangerie_jeju","foodTypes":"디저트/차/베이커리","latitude":33.4747511448544,"longitude":126.543715482949,"bookYear":"2024","nameKR":"블레블랑제리","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":5087,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6617,"longitude":126.769113,"bookYear":"2024","nameKR":"속초어시장","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":4525,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.850248,"longitude":129.2059,"bookYear":"2024","nameKR":"국시집","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":28332,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.5140367610096,"longitude":126.52021339359,"bookYear":"2024","nameKR":"정성듬뿍제주국","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":30502,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.3130512282884,"longitude":127.079016703977,"bookYear":"2024","nameKR":"야키도리켄지 ","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":34045,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pyo.sick/","foodTypes":"일식","latitude":35.5384804761965,"longitude":129.329966124898,"bookYear":"2024","nameKR":"표식","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":2573,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.51005,"longitude":126.538796,"bookYear":"2024","nameKR":"돌하르방식당","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34772,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1382099077343,"longitude":129.103942170065,"bookYear":"2024","nameKR":"깡통집","ribbonType":"1","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":35277,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1265644969754,"longitude":129.039337916419,"bookYear":"2024","nameKR":"모티","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":31523,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8562429747982,"longitude":128.634118643294,"bookYear":"2024","nameKR":"아소다이닝","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":6040,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.2482242957169,"longitude":126.561215225298,"bookYear":"2024","nameKR":"천짓골식당","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":32560,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/siciliapastabar/","foodTypes":"이탈리아식","latitude":35.8596023276928,"longitude":128.638240415548,"bookYear":"2024","nameKR":"시칠리아파스타바","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":5897,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.395855,"longitude":126.919818,"bookYear":"2024","nameKR":"흑산도홍어","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":2452,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.593822,"longitude":127.766629,"bookYear":"2024","nameKR":"아와비","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":3073,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.544168,"longitude":129.329771,"bookYear":"2024","nameKR":"본여우&본정","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":22853,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.155111,"longitude":129.05712,"bookYear":"2024","nameKR":"마라톤집","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":4094,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.187585,"longitude":129.079643,"bookYear":"2024","nameKR":"포항회관","ribbonType":"1","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":27864,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.158415822629586,"longitude":128.10682235302147,"bookYear":"2024","nameKR":"시마다","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":30959,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/werk.roasters/ ","foodTypes":"디저트/차/베이커리","latitude":35.1526155103264,"longitude":129.066281137311,"bookYear":"2024","nameKR":"베르크로스터스","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34801,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1358161193851,"longitude":129.086334064016,"bookYear":"2024","nameKR":"브레드365","ribbonType":"1","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":32682,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bangasayu_coffee_bar","foodTypes":"디저트/차/베이커리","latitude":35.1435667885526,"longitude":126.921977820545,"bookYear":"2024","nameKR":"반가사유","ribbonType":"1","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":35252,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1476949894647,"longitude":129.067558877244,"bookYear":"2024","nameKR":"곤국","ribbonType":"1","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":2215,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.36232692798339,"longitude":127.34818647050909,"bookYear":"2024","nameKR":"동해원","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":5238,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.206554,"longitude":129.101193,"bookYear":"2024","nameKR":"태산손만두칼국수","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":2826,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.443574,"longitude":129.163465,"bookYear":"2024","nameKR":"정라횟집","ribbonType":"1","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":3095,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.00586112420113,"longitude":126.9449050112385,"bookYear":"2024","nameKR":"진미식당","ribbonType":"1","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":30927,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1750912324192,"longitude":129.054956262122,"bookYear":"2024","nameKR":"화남정돼지국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":35270,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.864511777147,"longitude":128.589267527921,"bookYear":"2024","nameKR":"롤러커피","ribbonType":"1","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":6838,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.80708116313834,"longitude":127.14022132458027,"bookYear":"2024","nameKR":"온달네식당","ribbonType":"1","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":6353,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lavolta_jeonju/","foodTypes":"이탈리아식","latitude":35.821073,"longitude":127.115484,"bookYear":"2024","nameKR":"라볼타","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":23401,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.16646,"longitude":129.158315,"bookYear":"2024","nameKR":"의령식당","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":28552,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6537984863297,"longitude":126.887108412484,"bookYear":"2024","nameKR":"티나의식빵","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":32946,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mattina1135/","foodTypes":"이탈리아식","latitude":35.9598299254439,"longitude":126.977932120439,"bookYear":"2024","nameKR":"마띠나","ribbonType":"1","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":35218,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/donquixote_gunsan/","foodTypes":"바/주점","latitude":35.9887276791947,"longitude":126.710811016301,"bookYear":"2024","nameKR":"돈키호테","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":6798,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.104822,"longitude":129.035765,"bookYear":"2024","nameKR":"백구당","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":35790,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.5358172901256,"longitude":129.334870238703,"bookYear":"2024","nameKR":"갓포이찌","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":5235,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.100912,"longitude":129.022553,"bookYear":"2024","nameKR":"신창국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":35292,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_pair_ing/","foodTypes":"바/주점","latitude":35.8445262616062,"longitude":127.143566948503,"bookYear":"2024","nameKR":"페어링","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":4390,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.153688,"longitude":127.069121,"bookYear":"2024","nameKR":"오산할머니집","ribbonType":"1","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":30570,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lalune_croissants","foodTypes":"디저트/차/베이커리","latitude":35.22762078536069,"longitude":128.6791080417862,"bookYear":"2024","nameKR":"라룬크루아상","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":1996,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.488516,"longitude":127.492483,"bookYear":"2024","nameKR":"홍춘관","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":33617,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cospir_official/","foodTypes":"디저트/차/베이커리","latitude":35.2321573599801,"longitude":129.088575703601,"bookYear":"2024","nameKR":"코스피어","ribbonType":"1","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":11194,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.241416,"longitude":129.094242,"bookYear":"2024","nameKR":"기장방우횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":2686,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.632332,"longitude":127.486728,"bookYear":"2024","nameKR":"버섯찌개경주집","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":3147,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.740165,"longitude":127.04824,"bookYear":"2024","nameKR":"신래향","ribbonType":"1","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":5952,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.710323,"longitude":126.546661,"bookYear":"2024","nameKR":"영성각","ribbonType":"1","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":8115,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.279325,"longitude":127.0172,"bookYear":"2024","nameKR":"대원옥","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":4982,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.8692604253307,"longitude":128.585621469476,"bookYear":"2024","nameKR":"대동면옥","ribbonType":"1","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":32471,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/less_better.busan/","foodTypes":"디저트/차/베이커리","latitude":35.2041320833556,"longitude":129.079154377407,"bookYear":"2024","nameKR":"레스베러","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":1740,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/Hwangtodak/","foodTypes":"한식(가금류)","latitude":37.876549,"longitude":127.753473,"bookYear":"2024","nameKR":"송&정황토숯불닭갈비","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":34856,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/taka_ra_men/","foodTypes":"일식","latitude":35.1554009645619,"longitude":129.055156037034,"bookYear":"2024","nameKR":"타카라멘","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":2248,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.323415,"longitude":127.41078,"bookYear":"2024","nameKR":"한영식당","ribbonType":"1","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":26929,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.482906,"longitude":126.856677,"bookYear":"2024","nameKR":"정인면옥평양냉면","ribbonType":"1","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":3327,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.470842,"longitude":126.625026,"bookYear":"2024","nameKR":"청실홍실(신포본점)","ribbonType":"1","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":27881,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.00139,"longitude":126.944763,"bookYear":"2024","nameKR":"한일식당","ribbonType":"1","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":14879,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.405237,"longitude":127.382336,"bookYear":"2024","nameKR":"산들다헌","ribbonType":"1","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":7418,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.10571260795181,"longitude":129.02045116768213,"bookYear":"2024","nameKR":"원조18번완당발국수","ribbonType":"1","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":30138,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jei.kitchen/","foodTypes":"아시아식","latitude":37.6707366132961,"longitude":126.783659544436,"bookYear":"2024","nameKR":"재이식당","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":11231,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.2067468230822,"longitude":129.07616994053,"bookYear":"2024","nameKR":"대관원","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":30750,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.801986182453,"longitude":126.414014488203,"bookYear":"2024","nameKR":"미락식당","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":34826,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1607762403214,"longitude":129.192693453494,"bookYear":"2024","nameKR":"우봉샤브","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":3297,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.996986,"longitude":129.3451,"bookYear":"2024","nameKR":"연일물회","ribbonType":"1","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":8913,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.858448,"longitude":126.78604,"bookYear":"2024","nameKR":"은하장","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":34158,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.9379483824097,"longitude":126.94781572593,"bookYear":"2024","nameKR":"야래향","ribbonType":"1","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":34779,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":35.1382755065968,"longitude":129.104077367087,"bookYear":"2024","nameKR":"라이옥","ribbonType":"1","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":31180,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sammoondang_coffee_company/","foodTypes":"디저트/차/베이커리","latitude":34.8465785748135,"longitude":128.424234774115,"bookYear":"2024","nameKR":"삼문당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":30102,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dessert_cinema","foodTypes":"디저트/차/베이커리","latitude":35.17626406917965,"longitude":129.09049003992527,"bookYear":"2024","nameKR":"디저트시네마","ribbonType":"1","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":33573,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ingle_mangsang/","foodTypes":"기타","latitude":37.5926105756326,"longitude":129.090818968519,"bookYear":"2024","nameKR":"잉걸","ribbonType":"1","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":31464,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.744067234891,"longitude":127.055799145579,"bookYear":"2024","nameKR":"부흥국수","ribbonType":"1","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":31174,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bakery_446/","foodTypes":"디저트/차/베이커리","latitude":36.6291995415094,"longitude":127.518041195488,"bookYear":"2024","nameKR":"베이커리446","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":31720,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/letang_restaurant","foodTypes":"프랑스식","latitude":35.1725158544022,"longitude":129.055165697438,"bookYear":"2024","nameKR":"레땅","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":27432,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/maitre_artisan/","foodTypes":"디저트/차/베이커리","latitude":35.141435,"longitude":129.11026,"bookYear":"2024","nameKR":"메트르아티정","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":2765,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.84169,"longitude":128.419575,"bookYear":"2024","nameKR":"옛날충무꼬지김밥","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":27619,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pateachoux_busan/","foodTypes":"디저트/차/베이커리","latitude":35.16838202402967,"longitude":129.1557916049448,"bookYear":"2024","nameKR":"빠따슈","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":34777,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tete_o_ne_/","foodTypes":"바/주점","latitude":35.1526203304441,"longitude":129.06641588951,"bookYear":"2024","nameKR":"떼떼오네","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":23067,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.0358763261738,"longitude":129.36555361589745,"bookYear":"2024","nameKR":"장기식당","ribbonType":"1","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":28535,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.815699,"longitude":127.103193,"bookYear":"2024","nameKR":"오래옥","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":30947,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.0984667361187,"longitude":129.032043057012,"bookYear":"2024","nameKR":"남포설렁탕","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":34822,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/baektan_yakitori","foodTypes":"일식","latitude":35.1560144578186,"longitude":129.122478226524,"bookYear":"2024","nameKR":"야키토리백탄","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":3293,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.871506300401215,"longitude":128.5928709969318,"bookYear":"2024","nameKR":"교동따로식당","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":30911,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/layers_bakehouse","foodTypes":"디저트/차/베이커리","latitude":33.5149314383408,"longitude":126.520780096661,"bookYear":"2024","nameKR":"레이어스베이크하우스","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":6786,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.486833,"longitude":126.489554,"bookYear":"2024","nameKR":"마라도횟집","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":1639,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.672030102011334,"longitude":128.70850331960247,"bookYear":"2024","nameKR":"부산식육식당","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":32635,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/elkote_jeju/","foodTypes":"기타","latitude":33.4943580533312,"longitude":126.544402614639,"bookYear":"2024","nameKR":"엘코테","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32681,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/whiteshirtcoffee_juicy/","foodTypes":"디저트/차/베이커리","latitude":35.1525421939005,"longitude":126.871959311877,"bookYear":"2024","nameKR":"화이트셔츠커피","ribbonType":"1","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":34810,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.0934902383255,"longitude":129.039277450895,"bookYear":"2024","nameKR":"삼형제오리","ribbonType":"1","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":29065,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ho33ho/","foodTypes":"디저트/차/베이커리","latitude":36.637445,"longitude":127.488634,"bookYear":"2024","nameKR":"흥흥제과","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":28392,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.853532,"longitude":128.613735,"bookYear":"2024","nameKR":"형제수산","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":5557,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.79114,"longitude":128.087897,"bookYear":"2024","nameKR":"고바우식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"합천군","status":"ACTIVE"},
        {"id":31122,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oncheonjip_soje/","foodTypes":"일식","latitude":36.336835126743,"longitude":127.436832820805,"bookYear":"2024","nameKR":"온천집","ribbonType":"1","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":30787,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushikudoku/","foodTypes":"일식","latitude":35.2085957616265,"longitude":129.008304541422,"bookYear":"2024","nameKR":"스시쿠도쿠","ribbonType":"1","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":2197,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/into._.1992/","foodTypes":"유럽식","latitude":35.869075,"longitude":128.599468,"bookYear":"2024","nameKR":"인투","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":34776,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1639816443536,"longitude":129.157214149098,"bookYear":"2024","nameKR":"디젤앤카멜리아스 ","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":2692,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.622804,"longitude":127.487522,"bookYear":"2024","nameKR":"남들갈비","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":34968,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.3591723418063,"longitude":127.377257266653,"bookYear":"2024","nameKR":"월광박속낙지탕","ribbonType":"1","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":31625,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/inthetrip__/","foodTypes":"이탈리아식","latitude":37.2343229202265,"longitude":127.197647496922,"bookYear":"2024","nameKR":"인더트립","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":7184,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.81273407284626,"longitude":127.15201312928349,"bookYear":"2024","nameKR":"외할머니솜씨","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":28980,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.4769396444974,"longitude":126.61929074473487,"bookYear":"2024","nameKR":"신","ribbonType":"1","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":30622,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.525131026973135,"longitude":126.54672950780683,"bookYear":"2024","nameKR":"인발루","ribbonType":"1","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":8994,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.844842,"longitude":129.20755,"bookYear":"2024","nameKR":"현대밀면","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":16073,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.840182,"longitude":128.42189,"bookYear":"2024","nameKR":"명촌식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":2254,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gaemizib_bonjeom/","foodTypes":"한식(어패류)","latitude":35.1007617197893,"longitude":129.030120329694,"bookYear":"2024","nameKR":"개미집","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":32376,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_gelateria/","foodTypes":"디저트/차/베이커리","latitude":35.1551346198254,"longitude":129.130845493475,"bookYear":"2024","nameKR":"카페젤라떼리아","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":2785,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.098971,"longitude":129.031071,"bookYear":"2024","nameKR":"원산면옥","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":5349,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.330183,"longitude":126.79105,"bookYear":"2024","nameKR":"칸티푸르","ribbonType":"1","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36559,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/zappnhenry/","foodTypes":"디저트/차/베이커리","latitude":35.8680791397631,"longitude":128.590206864098,"bookYear":"2024","nameKR":"잽엔헨리","ribbonType":"1","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":1777,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.256643604615256,"longitude":127.02630997050555,"bookYear":"2024","nameKR":"다래식당","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34061,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/flat_relationship/","foodTypes":"디저트/차/베이커리","latitude":35.8644566351085,"longitude":128.609642971812,"bookYear":"2024","nameKR":"수평적관계","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":2807,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.007611,"longitude":127.662157,"bookYear":"2024","nameKR":"금강식당","ribbonType":"1","zone2_1":"전북","zone2_2":"무주군","status":"ACTIVE"},
        {"id":26811,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gomespresso/","foodTypes":"디저트/차/베이커리","latitude":36.343632,"longitude":127.341334,"bookYear":"2024","nameKR":"곰에스프레소","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":4556,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.0995614535287,"longitude":129.02664680754,"bookYear":"2024","nameKR":"부산족발","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":6785,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sweet__lab/ ","foodTypes":"디저트/차/베이커리","latitude":35.14116862882947,"longitude":128.6712476165314,"bookYear":"2024","nameKR":"스위트랩","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":31362,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1963396084977,"longitude":128.082721979917,"bookYear":"2024","nameKR":"밀레다임커피","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":31175,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3808648665764,"longitude":126.959011352007,"bookYear":"2024","nameKR":"시그니쳐로스터스","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":11185,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1112444649127,"longitude":129.111258148054,"bookYear":"2024","nameKR":"합천국밥집","ribbonType":"1","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":1627,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.8795107241627,"longitude":127.72786659630235,"bookYear":"2024","nameKR":"명물닭갈비","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":32373,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffeefinder_korea/","foodTypes":"디저트/차/베이커리","latitude":33.498577662748,"longitude":126.528678753614,"bookYear":"2024","nameKR":"커피파인더","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":35268,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nodoubt_coffee/","foodTypes":"디저트/차/베이커리","latitude":35.8543722060853,"longitude":128.492489743631,"bookYear":"2024","nameKR":"노다웃","ribbonType":"1","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":2483,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.570537,"longitude":126.599319,"bookYear":"2024","nameKR":"천일식당","ribbonType":"1","zone2_1":"전남","zone2_2":"해남군","status":"ACTIVE"},
        {"id":30960,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/fmcoffee__/","foodTypes":"디저트/차/베이커리","latitude":35.1546246237968,"longitude":129.063756360117,"bookYear":"2024","nameKR":"에프엠커피","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":29768,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/au_bonbeurre/","foodTypes":"디저트/차/베이커리","latitude":37.2938971146561,"longitude":127.05513606578843,"bookYear":"2024","nameKR":"오봉베르","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34835,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2051119844538,"longitude":129.081839221506,"bookYear":"2024","nameKR":"제일횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":4479,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.173902,"longitude":128.992792,"bookYear":"2024","nameKR":"시장실비식당","ribbonType":"1","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":6801,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.818437,"longitude":127.147193,"bookYear":"2024","nameKR":"PNB풍년제과본점","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":5955,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yong_gung3/ ","foodTypes":"한식(육류)","latitude":36.607026994906185,"longitude":128.27757959704786,"bookYear":"2024","nameKR":"단골식당","ribbonType":"1","zone2_1":"경북","zone2_2":"예천군","status":"ACTIVE"},
        {"id":12533,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.86628,"longitude":129.213992,"bookYear":"2024","nameKR":"랑콩뜨레","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2398,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.004472,"longitude":127.26581,"bookYear":"2024","nameKR":"송광사길상식당","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":18847,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.509554,"longitude":127.766935,"bookYear":"2024","nameKR":"상록수식당","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":30669,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/messimessichan43_/","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.3580801952478,"longitude":127.351186444993,"bookYear":"2024","nameKR":"태평소국밥","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":7971,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8415722858687,"longitude":129.215349506473,"bookYear":"2024","nameKR":"최영화빵","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":6193,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1482353456848,"longitude":128.659238460867,"bookYear":"2024","nameKR":"진해제과","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":28969,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bonjour_madame_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.252782,"longitude":126.503451,"bookYear":"2024","nameKR":"봉주르마담","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":27883,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.478241,"longitude":126.49136,"bookYear":"2024","nameKR":"컴플리트커피","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":30446,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.60476670752635,"longitude":127.28101368882578,"bookYear":"2024","nameKR":"대흥식당","ribbonType":"1","zone2_1":"전남","zone2_2":"고흥군","status":"ACTIVE"},
        {"id":8129,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sinposundae/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.471484,"longitude":126.62625,"bookYear":"2024","nameKR":"신포순대","ribbonType":"1","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":2283,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.0985447819427,"longitude":129.032402896207,"bookYear":"2024","nameKR":"서울깍두기","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":8950,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.5180253713296,"longitude":127.729095779555,"bookYear":"2024","nameKR":"병곡식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"함양군","status":"ACTIVE"},
        {"id":4445,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.808917,"longitude":127.147714,"bookYear":"2024","nameKR":"학화할머니호도과자","ribbonType":"1","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":4593,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.489305,"longitude":126.523301,"bookYear":"2024","nameKR":"곰해장국","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":33468,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.8008470565233,"longitude":126.430855275189,"bookYear":"2024","nameKR":"별스넥","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":32501,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/treasurescoffee/","foodTypes":"디저트/차/베이커리","latitude":35.1557195610927,"longitude":129.067104920194,"bookYear":"2024","nameKR":"트레져스커피","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33499,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/freres.lumiere/","foodTypes":"프랑스식","latitude":33.5147681928676,"longitude":126.519647833239,"bookYear":"2024","nameKR":"뤼미에흐","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":7161,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.868847,"longitude":128.599669,"bookYear":"2024","nameKR":"루시드","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":1618,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.672501,"longitude":128.708085,"bookYear":"2024","nameKR":"도암식당","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":16065,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.8424105708126,"longitude":128.423387099505,"bookYear":"2024","nameKR":"대추나무","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":30423,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.796148337757934,"longitude":128.04923535533624,"bookYear":"2024","nameKR":"르뱅스타독일빵집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":2276,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.098772,"longitude":129.031499,"bookYear":"2024","nameKR":"백광상회","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":30437,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.9876703161005,"longitude":126.708783450148,"bookYear":"2024","nameKR":"째보식당","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":34864,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hororotku","foodTypes":"일식","latitude":35.1592815106648,"longitude":129.062050273793,"bookYear":"2024","nameKR":"호로롯쿠","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":3058,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.673225,"longitude":128.708272,"bookYear":"2024","nameKR":"진태원","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":4334,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.164593,"longitude":127.373623,"bookYear":"2024","nameKR":"제일식당","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":35333,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/green_ruska/","foodTypes":"디저트/차/베이커리","latitude":33.5053408704763,"longitude":126.515221031512,"bookYear":"2024","nameKR":"그린루스카","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":8879,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dongmyeong_okononomiyaki/","foodTypes":"일식","latitude":35.86509125837802,"longitude":128.5984860953748,"bookYear":"2024","nameKR":"동명오코노미야끼","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":21867,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffeeawake/","foodTypes":"디저트/차/베이커리","latitude":35.229839,"longitude":129.083827,"bookYear":"2024","nameKR":"커피어웨이크","ribbonType":"1","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":32512,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushi_kou_/","foodTypes":"일식","latitude":35.1589242224756,"longitude":129.125964146081,"bookYear":"2024","nameKR":"스시코우","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":3040,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.483933,"longitude":126.762629,"bookYear":"2024","nameKR":"털보해물탕","ribbonType":"1","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":4515,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5120138879489,"longitude":128.217889723796,"bookYear":"2024","nameKR":"자매식당","ribbonType":"1","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":6845,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.527794,"longitude":126.725752,"bookYear":"2024","nameKR":"금문도","ribbonType":"1","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":6923,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/59_dangdang/","foodTypes":"한식(일반한식)","latitude":37.493513,"longitude":126.722037,"bookYear":"2024","nameKR":"오구당당","ribbonType":"1","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":2772,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.987783,"longitude":128.029593,"bookYear":"2024","nameKR":"장영선지례원조삼거리불고기","ribbonType":"1","zone2_1":"경북","zone2_2":"김천시","status":"ACTIVE"},
        {"id":2949,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0994165807824,"longitude":129.037469319734,"bookYear":"2024","nameKR":"성일집","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":8120,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.399857,"longitude":126.921234,"bookYear":"2024","nameKR":"석기정","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":8946,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.75442,"longitude":128.896264,"bookYear":"2024","nameKR":"금학칼국수","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":3300,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.8427154988965,"longitude":129.212731133788,"bookYear":"2024","nameKR":"평양냉면집","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":6460,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.380375714836,"longitude":128.662361445216,"bookYear":"2024","nameKR":"정선면옥","ribbonType":"1","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":8138,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.475057,"longitude":126.644915,"bookYear":"2024","nameKR":"해장국","ribbonType":"1","zone2_1":"인천","zone2_2":"동구","status":"ACTIVE"},
        {"id":12430,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.791756,"longitude":128.088458,"bookYear":"2024","nameKR":"감로식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"합천군","status":"ACTIVE"},
        {"id":2070,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.841428,"longitude":129.215133,"bookYear":"2024","nameKR":"대구갈비","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2947,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1015516915783,"longitude":129.029598334626,"bookYear":"2024","nameKR":"돌고래순두부","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":3150,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.155566110011,"longitude":129.058538257152,"bookYear":"2024","nameKR":"송정3대국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":7998,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.319702,"longitude":127.39521,"bookYear":"2024","nameKR":"대들보함흥면옥","ribbonType":"1","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":6004,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.152412080565,"longitude":129.025073233379,"bookYear":"2024","nameKR":"개금밀면","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":28093,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.4438,"longitude":126.703225,"bookYear":"2024","nameKR":"파니노구스토","ribbonType":"1","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":12465,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.116595,"longitude":129.041411,"bookYear":"2024","nameKR":"본전돼지국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":7000,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gunbong1987/","foodTypes":"한식(탕/국/찌개/전골)","latitude":34.945648,"longitude":127.493977,"bookYear":"2024","nameKR":"건봉국밥","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":6020,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.147698,"longitude":126.917662,"bookYear":"2024","nameKR":"궁전제과","ribbonType":"1","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":6796,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.868612024897,"longitude":128.593447218741,"bookYear":"2024","nameKR":"삼송빵집","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":1688,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.338525,"longitude":127.956376,"bookYear":"2024","nameKR":"원주복추어탕","ribbonType":"1","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":2310,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.098849098939425,"longitude":129.03145746857538,"bookYear":"2024","nameKR":"할매집","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":2490,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7867917928283,"longitude":126.383434226731,"bookYear":"2024","nameKR":"초원음식점","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":11150,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.161473,"longitude":129.055536,"bookYear":"2024","nameKR":"춘하추동밀면","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":35195,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7580687553018,"longitude":127.659896675869,"bookYear":"2024","nameKR":"꽃게살비빔밥꽃게탕(시청점)","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":3368,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.356376,"longitude":127.340043,"bookYear":"2024","nameKR":"유성복집","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":7987,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.964175,"longitude":128.938509,"bookYear":"2024","nameKR":"포항할매집","ribbonType":"1","zone2_1":"경북","zone2_2":"영천시","status":"ACTIVE"},
        {"id":29375,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.2445422641819,"longitude":126.563792111796,"bookYear":"2024","nameKR":"유동커피","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":2069,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.022687,"longitude":129.356196,"bookYear":"2024","nameKR":"다락방","ribbonType":"1","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":26068,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.95368,"longitude":127.484182,"bookYear":"2024","nameKR":"화월당","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":6150,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.826838,"longitude":128.624528,"bookYear":"2024","nameKR":"우리복어식당","ribbonType":"1","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":30984,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hytte_roastery/","foodTypes":"디저트/차/베이커리","latitude":35.1557529453563,"longitude":129.06820567163,"bookYear":"2024","nameKR":"히떼로스터리","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33328,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oksan_t_haus/","foodTypes":"디저트/차/베이커리","latitude":35.1524676736722,"longitude":129.066848944886,"bookYear":"2024","nameKR":"옥산티하우스","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":3759,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1421407221157,"longitude":129.060146286184,"bookYear":"2024","nameKR":"원조할매낙지","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":3781,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.792294,"longitude":126.384441,"bookYear":"2024","nameKR":"유달콩물","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":32417,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.4870322671012,"longitude":126.492835864473,"bookYear":"2024","nameKR":"오래옥식당","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":36780,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.3790480318957,"longitude":127.137485931104,"bookYear":"2024","nameKR":"콘비노","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":1665,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.88368539888931,"longitude":127.72586159767161,"bookYear":"2024","nameKR":"실비막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":1748,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.408773,"longitude":127.122856,"bookYear":"2024","nameKR":"감미옥","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":29505,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sung_ku_jung/","foodTypes":"기타","latitude":37.390743244853894,"longitude":127.08058799846955,"bookYear":"2024","nameKR":"제로투나인","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":1874,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.659136,"longitude":126.782642,"bookYear":"2024","nameKR":"아소산","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":1884,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ilsanmasjib/","foodTypes":"한식(육류)","latitude":37.670701,"longitude":126.791662,"bookYear":"2024","nameKR":"양수면옥","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":36481,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":33.3049726791712,"longitude":126.31635202613,"bookYear":"2024","nameKR":"르쉬느아 ","ribbonType":"1","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":34592,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.2558546223222,"longitude":126.415472334394,"bookYear":"2024","nameKR":"호텔리어스커피","ribbonType":"1","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":2791,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1129086078974,"longitude":129.01302646429394,"bookYear":"2024","nameKR":"청죽","ribbonType":"1","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":12751,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.374307,"longitude":127.006192,"bookYear":"2024","nameKR":"올라!백운호수점","ribbonType":"1","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":34711,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1633069847366,"longitude":129.159614124407,"bookYear":"2024","nameKR":"쇼진","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":6993,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.488361,"longitude":126.869521,"bookYear":"2024","nameKR":"철산명가가마솥밥상","ribbonType":"1","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":4167,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.870625,"longitude":128.598955,"bookYear":"2024","nameKR":"부산안면옥","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":28511,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.46429467763424,"longitude":126.64049697523481,"bookYear":"2024","nameKR":"이화찹쌀순대","ribbonType":"1","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":35194,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.4804682928955,"longitude":126.265393798498,"bookYear":"2024","nameKR":"신호등회관","ribbonType":"1","zone2_1":"전남","zone2_2":"진도군","status":"ACTIVE"},
        {"id":35221,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/casa_del_coffe/?igshid=YmMyMTA2M2Y%3D","foodTypes":"디저트/차/베이커리","latitude":35.1437086514286,"longitude":126.910364828774,"bookYear":"2024","nameKR":"까사델커피","ribbonType":"1","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":35269,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/1LL_official/","foodTypes":"디저트/차/베이커리","latitude":35.8543713204355,"longitude":128.478642739054,"bookYear":"2024","nameKR":"1LL커피","ribbonType":"1","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":5268,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.331175,"longitude":127.42355,"bookYear":"2024","nameKR":"한밭칼국수","ribbonType":"1","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":2343,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.417657,"longitude":126.673635,"bookYear":"2024","nameKR":"금산식당","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":35275,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/guau_chocolat/","foodTypes":"디저트/차/베이커리","latitude":33.5562535400722,"longitude":126.746135885389,"bookYear":"2024","nameKR":"구아우쇼콜라","ribbonType":"1","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":34721,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1623835504262,"longitude":129.162973570497,"bookYear":"2024","nameKR":"부다면옥","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":29976,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.646721317027456,"longitude":126.78027967720392,"bookYear":"2024","nameKR":"비스트로쎄봉","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":3472,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.03782,"longitude":129.366538,"bookYear":"2024","nameKR":"아라비카커피숍","ribbonType":"1","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":8188,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.205262,"longitude":129.084765,"bookYear":"2024","nameKR":"배종관동래삼계탕","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":33139,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.9606330720678,"longitude":126.690613918746,"bookYear":"2024","nameKR":"사계절꽃게무침","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":32795,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gaehwadang_restaurant/","foodTypes":"프랑스식","latitude":35.9588123147053,"longitude":126.713148223411,"bookYear":"2024","nameKR":"개화당","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":2179,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.864457591592796,"longitude":128.5946340613605,"bookYear":"2024","nameKR":"동성로생고기","ribbonType":"1","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":8144,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.630294,"longitude":127.490606,"bookYear":"2024","nameKR":"청송통닭","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":2578,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.5112339525471,"longitude":126.522842093978,"bookYear":"2024","nameKR":"미풍해장국","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32747,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.4810999182995,"longitude":126.264328318863,"bookYear":"2024","nameKR":"이화식당","ribbonType":"1","zone2_1":"전남","zone2_2":"진도군","status":"ACTIVE"},
        {"id":34742,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1634173704986,"longitude":129.165623847105,"bookYear":"2024","nameKR":"해운대밀면","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":2033,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.206683,"longitude":128.578271,"bookYear":"2024","nameKR":"오동동진짜초가집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":36444,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bullanseo.bistro/","foodTypes":"프랑스식","latitude":34.8074518726895,"longitude":126.466429583606,"bookYear":"2024","nameKR":"불란서식당","ribbonType":"1","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":2554,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.811885,"longitude":127.159702,"bookYear":"2024","nameKR":"남양집","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":33040,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hermitage_jeju/","foodTypes":"프랑스식","latitude":33.2550685511515,"longitude":126.505899154154,"bookYear":"2024","nameKR":"에르미타주","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":12789,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.174115,"longitude":129.173588,"bookYear":"2024","nameKR":"상황삼계탕오리불고기","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":4000,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.332117,"longitude":127.428763,"bookYear":"2024","nameKR":"금성삼계탕","ribbonType":"1","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":34602,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.5361273272657,"longitude":129.335466461812,"bookYear":"2024","nameKR":"라피콜라이탈리아","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":37551,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.6398616033567,"longitude":126.848000705818,"bookYear":"2024","nameKR":"민쿡앞바당","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":36239,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.280217502236,"longitude":127.438871199731,"bookYear":"2024","nameKR":"장흥회관","ribbonType":"1","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":30101,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/monocle_jeju","foodTypes":"디저트/차/베이커리","latitude":33.2728990726722,"longitude":126.684536983306,"bookYear":"2024","nameKR":"모노클제주","ribbonType":"1","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":35293,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mano.jeonju/?igsh=MWdocHYyZjl2NGcxcg%3D%3D","foodTypes":"이탈리아식","latitude":35.8163824587637,"longitude":127.105486139384,"bookYear":"2024","nameKR":"마노","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2159,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.870349,"longitude":128.584484,"bookYear":"2024","nameKR":"국일생갈비","ribbonType":"1","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":34799,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1387281351032,"longitude":129.06345343296,"bookYear":"2024","nameKR":"백산키친","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":34741,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1518415819748,"longitude":129.116897372933,"bookYear":"2024","nameKR":"이타쇼","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":34710,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.160201591128,"longitude":129.166579258799,"bookYear":"2024","nameKR":"모리","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":34660,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1574108535091,"longitude":129.120957248578,"bookYear":"2024","nameKR":"제로베이스","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":32974,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chauffer233/","foodTypes":"프랑스식","latitude":36.5180968787654,"longitude":127.231759471609,"bookYear":"2024","nameKR":"쇼페","ribbonType":"1","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":33459,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/finedining_q1","foodTypes":"한식(육류)","latitude":37.6468895968911,"longitude":126.680521997391,"bookYear":"2024","nameKR":"규원","ribbonType":"1","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":34785,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.1560041848855,"longitude":129.146859199299,"bookYear":"2024","nameKR":"라호짬뽕","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":6808,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.0997204203411,"longitude":129.029587140355,"bookYear":"2024","nameKR":"비엔씨","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":31066,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/woobulli","foodTypes":"한식(육류)","latitude":37.6380494625681,"longitude":126.789225760985,"bookYear":"2024","nameKR":"우블리","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":1589,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.204314617459715,"longitude":128.5908595193918,"bookYear":"2024","nameKR":"감나무집감자옹심이","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":2036,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":34.832972,"longitude":128.414505,"bookYear":"2024","nameKR":"울산다찌","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":2039,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.185784,"longitude":128.086289,"bookYear":"2024","nameKR":"진주육거리곰탕","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":36438,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8330389246839,"longitude":127.135656237413,"bookYear":"2024","nameKR":"금암우족탕","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2512,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.729086,"longitude":126.710707,"bookYear":"2024","nameKR":"계화회관","ribbonType":"1","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":36448,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":34.7072390693264,"longitude":128.04937751274,"bookYear":"2024","nameKR":"지산식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":33804,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/newly_steakhouse/","foodTypes":"바/주점","latitude":35.1551342050388,"longitude":129.124005331974,"bookYear":"2024","nameKR":"뉼리","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35546,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.7242095140887,"longitude":126.507107185454,"bookYear":"2024","nameKR":"봄날의정원한식당","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":31194,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/betterperson_in/","foodTypes":"디저트/차/베이커리","latitude":37.3914225476128,"longitude":127.098199126463,"bookYear":"2024","nameKR":"베러펄슨","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":33030,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tofu_sundubu/","foodTypes":"한식(일반한식)","latitude":37.4767690276423,"longitude":127.18889697251,"bookYear":"2024","nameKR":"옛날순두부집","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":36193,"bookStatus":"승인","websiteInstagram":"https://instagram.com/losier_official","foodTypes":"디저트/차/베이커리","latitude":35.166880612775,"longitude":126.864708940673,"bookYear":"2024","nameKR":"로지에","ribbonType":"1","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":5970,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.826331,"longitude":127.14285,"bookYear":"2024","nameKR":"펄펄닭내장","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":12697,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.563722,"longitude":128.731971,"bookYear":"2024","nameKR":"구서울갈비","ribbonType":"1","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":5552,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.207767,"longitude":128.576041,"bookYear":"2024","nameKR":"화성갈비","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":1858,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.826659,"longitude":127.515231,"bookYear":"2024","nameKR":"송원막국수","ribbonType":"1","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":2435,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/samhak_yeosu/","foodTypes":"한식(어패류)","latitude":34.7390860242063,"longitude":127.745243842152,"bookYear":"2024","nameKR":"삼학집","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":30934,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/damihong1996/ ","foodTypes":"중식","latitude":37.4526501741667,"longitude":126.818181446297,"bookYear":"2024","nameKR":"다미홍","ribbonType":"1","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":29881,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yanncouvreur_kr/ ","foodTypes":"디저트/차/베이커리","latitude":37.39803937685553,"longitude":126.63394268920703,"bookYear":"2024","nameKR":"카페꼼마","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":4315,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.622356,"longitude":127.495098,"bookYear":"2024","nameKR":"유명산가마솥할머니해장국","ribbonType":"1","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":5278,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.366351,"longitude":127.380504,"bookYear":"2024","nameKR":"귀빈돌솥밥","ribbonType":"1","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":6905,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.56465961710473,"longitude":128.7309948726289,"bookYear":"2024","nameKR":"맘모스제과","ribbonType":"1","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":18375,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.39488,"longitude":126.651559,"bookYear":"2024","nameKR":"열두바구니","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":2256,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.158866,"longitude":129.170634,"bookYear":"2024","nameKR":"경북횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":2580,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.4990775638137,"longitude":126.52489319987,"bookYear":"2024","nameKR":"백선횟집","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":3168,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.951518,"longitude":127.48808,"bookYear":"2024","nameKR":"금빈회관","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":32894,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0898974307863,"longitude":129.037841709307,"bookYear":"2024","nameKR":"다미복국","ribbonType":"1","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":34903,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3437288292061,"longitude":128.002777550974,"bookYear":"2024","nameKR":"가평잣두부","ribbonType":"1","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":28988,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/franlokal","foodTypes":"이탈리아식","latitude":37.610328,"longitude":127.349205,"bookYear":"2024","nameKR":"프란로칼","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":4078,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mingju_haeundae/","foodTypes":"중식","latitude":35.16366537449376,"longitude":129.16941915438136,"bookYear":"2024","nameKR":"밍주","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":33228,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/soontofugelato/","foodTypes":"디저트/차/베이커리","latitude":37.7713682470682,"longitude":128.947102574156,"bookYear":"2024","nameKR":"순두부젤라또2호점","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":4743,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.386559,"longitude":127.122112,"bookYear":"2024","nameKR":"유타로","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":34974,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.7885865020935,"longitude":127.008943449924,"bookYear":"2024","nameKR":"고려옥","ribbonType":"1","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":12520,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.832654,"longitude":128.617265,"bookYear":"2024","nameKR":"만반","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":6457,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.862276,"longitude":127.725698,"bookYear":"2024","nameKR":"퇴계막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":30488,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.15393617856878,"longitude":129.06257950408977,"bookYear":"2024","nameKR":"음주양식당오스테리아어부","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":11494,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gnsonamu/","foodTypes":"한식(일반한식)","latitude":37.791711,"longitude":128.91543,"bookYear":"2024","nameKR":"초당소나무집","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":12594,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.743028,"longitude":127.199285,"bookYear":"2024","nameKR":"광릉한옥집","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":1663,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.06502158635752,"longitude":128.6209314732062,"bookYear":"2024","nameKR":"송이버섯마을","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":1943,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.4905,"longitude":127.488329,"bookYear":"2024","nameKR":"진영관","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":2094,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.839676,"longitude":129.213856,"bookYear":"2024","nameKR":"숙영식당","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2263,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/no1namhang/","foodTypes":"한식(어패류)","latitude":35.224209,"longitude":129.226997,"bookYear":"2024","nameKR":"남항횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":2409,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.06498435172595,"longitude":126.52294491417383,"bookYear":"2024","nameKR":"대흥식당","ribbonType":"1","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":2417,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.205585,"longitude":126.986361,"bookYear":"2024","nameKR":"들풀","ribbonType":"1","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":32034,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5722288955159,"longitude":126.681230713614,"bookYear":"2024","nameKR":"정서진메밀면옥","ribbonType":"1","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":34791,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mug_dessert_lab/","foodTypes":"디저트/차/베이커리","latitude":35.157037795554,"longitude":129.143328681551,"bookYear":"2024","nameKR":"머그디저트랩","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":3069,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.88088582978792,"longitude":127.72636153551409,"bookYear":"2024","nameKR":"회영루","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":3240,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.645093,"longitude":127.812259,"bookYear":"2024","nameKR":"장원막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":3375,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.93980727858952,"longitude":128.78787483688134,"bookYear":"2024","nameKR":"돌바우횟집","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":3776,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.33994,"longitude":127.122346,"bookYear":"2024","nameKR":"야마다야","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":4110,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4544609539309,"longitude":126.669851615814,"bookYear":"2024","nameKR":"풍전식당","ribbonType":"1","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":4303,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.659986,"longitude":126.66587,"bookYear":"2024","nameKR":"남강메기","ribbonType":"1","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":4491,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.837157,"longitude":127.582968,"bookYear":"2024","nameKR":"다윤네집","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":4495,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.790561,"longitude":128.914886,"bookYear":"2024","nameKR":"원조초당순두부","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":5403,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/manbokjeong7337651/","foodTypes":"한식(일반한식)","latitude":36.29443,"longitude":127.164823,"bookYear":"2024","nameKR":"만복정","ribbonType":"1","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":26978,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.314093,"longitude":127.486971,"bookYear":"2024","nameKR":"당골식당","ribbonType":"1","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":30605,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/amare_dining/ ","foodTypes":"이탈리아식","latitude":36.37615183447152,"longitude":127.39538287342457,"bookYear":"2024","nameKR":"아마레","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":9204,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sokcho_carrot/","foodTypes":"바/주점","latitude":38.21282354562133,"longitude":128.60107377150567,"bookYear":"2024","nameKR":"당근마차","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1669,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/1989ysg/","foodTypes":"한식(면류)","latitude":37.89745,"longitude":127.790179,"bookYear":"2024","nameKR":"연산골막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":1749,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4031963748567,"longitude":127.548956896926,"bookYear":"2024","nameKR":"강계봉진막국수","ribbonType":"1","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":28079,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.162568,"longitude":129.188998,"bookYear":"2024","nameKR":"라꽁띠","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":28435,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/leconvive_/","foodTypes":"프랑스식","latitude":35.176675,"longitude":129.12544,"bookYear":"2024","nameKR":"르꽁비브","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":3611,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":35.259879,"longitude":129.092248,"bookYear":"2024","nameKR":"카파도키아","ribbonType":"1","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":6510,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.833598,"longitude":129.20846,"bookYear":"2024","nameKR":"놋전국수","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":1727,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.498942,"longitude":127.989251,"bookYear":"2024","nameKR":"함밭식당","ribbonType":"1","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":30866,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.8582760438596,"longitude":127.052313702152,"bookYear":"2024","nameKR":"용암리막국수","ribbonType":"1","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":30791,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_toenmaru","foodTypes":"디저트/차/베이커리","latitude":37.7928910724179,"longitude":128.914475618377,"bookYear":"2024","nameKR":"툇마루","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":2596,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jungmunshinlawon/","foodTypes":"한식(육류)","latitude":33.2343665667281,"longitude":126.511981496403,"bookYear":"2024","nameKR":"신라원","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":31491,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_aewol_matcha","foodTypes":"디저트/차/베이커리","latitude":33.4713998561988,"longitude":126.418695773401,"bookYear":"2024","nameKR":"애월맛차","ribbonType":"1","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":3388,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.394453,"longitude":127.423294,"bookYear":"2024","nameKR":"초계탕막국수","ribbonType":"1","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":33073,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/eyst.kr/","foodTypes":"디저트/차/베이커리","latitude":35.829995383936,"longitude":129.216898226204,"bookYear":"2024","nameKR":"이스트1779","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":6837,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.244614654136484,"longitude":129.21524366029482,"bookYear":"2024","nameKR":"못난이식당","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":2827,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.210876,"longitude":128.586208,"bookYear":"2024","nameKR":"동명항생선숯불구이","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":32467,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.2178729065457,"longitude":126.249794723487,"bookYear":"2024","nameKR":"만선식당","ribbonType":"1","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":3321,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.170114,"longitude":127.179911,"bookYear":"2024","nameKR":"평매매운탕","ribbonType":"1","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":3796,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.370221,"longitude":128.508051,"bookYear":"2024","nameKR":"부부횟집","ribbonType":"1","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":8220,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.615402,"longitude":126.686621,"bookYear":"2024","nameKR":"권오길손국수","ribbonType":"1","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":5464,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.604895,"longitude":127.350947,"bookYear":"2024","nameKR":"서종가든","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":26482,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.490452,"longitude":127.472459,"bookYear":"2024","nameKR":"양평축협한우프라자","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":1607,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.51177157274205,"longitude":128.21829634151237,"bookYear":"2024","nameKR":"단골식당","ribbonType":"1","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":1620,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":38.25489048763,"longitude":128.509561445543,"bookYear":"2024","nameKR":"동루골막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":1661,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.087537,"longitude":128.653513,"bookYear":"2024","nameKR":"송월메밀국수","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":1702,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.44563,"longitude":128.453393,"bookYear":"2024","nameKR":"제비호식당","ribbonType":"1","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":12442,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.21468,"longitude":128.596143,"bookYear":"2024","nameKR":"속초생대구","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":31635,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.312800682694,"longitude":126.59667132986,"bookYear":"2024","nameKR":"서귀다원","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":2572,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.492778,"longitude":126.505963,"bookYear":"2024","nameKR":"도라지식당","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":2720,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.847398,"longitude":127.990431,"bookYear":"2024","nameKR":"영화식당","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":32561,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.1604087736533,"longitude":128.109864247247,"bookYear":"2024","nameKR":"사또가든","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":9206,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.919961,"longitude":128.804061,"bookYear":"2024","nameKR":"동해막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":4514,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.788639,"longitude":128.924291,"bookYear":"2024","nameKR":"송정해변막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":29743,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lilot_jeju","foodTypes":"프랑스식","latitude":33.5108584972183,"longitude":126.892907684722,"bookYear":"2024","nameKR":"릴로제주","ribbonType":"1","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":1598,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.205995,"longitude":128.528025,"bookYear":"2024","nameKR":"김영애할머니순두부","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1713,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.785335850533215,"longitude":128.90368814477551,"bookYear":"2024","nameKR":"옛태광식당","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":31233,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/doosoogobang/","foodTypes":"한식(일반한식)","latitude":37.2730876685805,"longitude":127.061262679777,"bookYear":"2024","nameKR":"두수고방","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":2050,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.149951222604244,"longitude":128.65647786557295,"bookYear":"2024","nameKR":"촌돼지보쌈","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":3331,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.350299,"longitude":127.339923,"bookYear":"2024","nameKR":"골목집소머리국밥","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":31932,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/haenyeokitchen_official/","foodTypes":"한식(일반한식)","latitude":33.4977915669711,"longitude":126.912298334864,"bookYear":"2024","nameKR":"해녀의부엌(종달점)","ribbonType":"1","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":32382,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":33.4358380573487,"longitude":126.67929567455,"bookYear":"2024","nameKR":"우동카덴","ribbonType":"1","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":2102,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.559228937163674,"longitude":128.71974057473122,"bookYear":"2024","nameKR":"옥동손국수","ribbonType":"1","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":2279,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.09711367503743,"longitude":129.03112054346374,"bookYear":"2024","nameKR":"부산명물횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":2472,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.784855,"longitude":126.383446,"bookYear":"2024","nameKR":"장터식당","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":2478,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.013385,"longitude":126.391683,"bookYear":"2024","nameKR":"제일회식당","ribbonType":"1","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":2528,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.40097908130732,"longitude":127.37677774683848,"bookYear":"2024","nameKR":"부산집","ribbonType":"1","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":2652,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.783254,"longitude":126.453204,"bookYear":"2024","nameKR":"삼기꽃게장","ribbonType":"1","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":2786,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.1147489234496,"longitude":129.038671552195,"bookYear":"2024","nameKR":"원향재","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":31928,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.4055706217181,"longitude":127.379553633783,"bookYear":"2024","nameKR":"경방루","ribbonType":"1","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":5013,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.47304,"longitude":128.726286,"bookYear":"2024","nameKR":"옥산장","ribbonType":"1","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":4304,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.646234,"longitude":126.548289,"bookYear":"2024","nameKR":"벌말매운탕","ribbonType":"1","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":27527,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.5568777191667,"longitude":126.720522540524,"bookYear":"2024","nameKR":"곰막","ribbonType":"1","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":27546,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.1071151949351,"longitude":128.187502614243,"bookYear":"2024","nameKR":"전씨네막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":6522,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.20153,"longitude":127.297725,"bookYear":"2024","nameKR":"내대막국수 ","ribbonType":"1","zone2_1":"강원","zone2_2":"철원군","status":"ACTIVE"},
        {"id":23113,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.741527,"longitude":126.492891,"bookYear":"2024","nameKR":"만복정","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":6358,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.552098,"longitude":129.11795,"bookYear":"2024","nameKR":"부흥횟집","ribbonType":"1","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":2621,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.2466971443416,"longitude":126.334929405504,"bookYear":"2024","nameKR":"중앙식당","ribbonType":"1","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":9202,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.552462,"longitude":129.113522,"bookYear":"2024","nameKR":"물곰식당","ribbonType":"1","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":30848,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/goodtaste_coffee/ ","foodTypes":"디저트/차/베이커리","latitude":35.8605972845882,"longitude":128.622138112787,"bookYear":"2024","nameKR":"커피맛을조금아는남자본점","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":31290,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_hansohun_/","foodTypes":"한식(육류)","latitude":37.3653315106286,"longitude":127.107704982095,"bookYear":"2024","nameKR":"한소헌","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":6208,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.378101,"longitude":127.112294,"bookYear":"2024","nameKR":"페삭","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":25007,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.79191965477539,"longitude":127.12851910371452,"bookYear":"2024","nameKR":"생생이두부보쌈","ribbonType":"1","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":2258,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.101223,"longitude":129.024659,"bookYear":"2024","nameKR":"구포집","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":8195,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.141016,"longitude":129.056196,"bookYear":"2024","nameKR":"60년전통할매국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":2337,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.539935,"longitude":129.31423,"bookYear":"2024","nameKR":"함양집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":2607,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.4914993524063,"longitude":126.497164033968,"bookYear":"2024","nameKR":"올래국수","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":2850,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bellarosa_2007/","foodTypes":"이탈리아식","latitude":37.3625062165384,"longitude":127.10754584296,"bookYear":"2024","nameKR":"벨라로사","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":3294,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":35.859466,"longitude":128.627661,"bookYear":"2024","nameKR":"에스파냐","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":3377,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.603902,"longitude":126.663342,"bookYear":"2024","nameKR":"삼거리갈비","ribbonType":"1","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":3780,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.157488,"longitude":129.126615,"bookYear":"2024","nameKR":"마라도","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":7997,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.83343,"longitude":129.214757,"bookYear":"2024","nameKR":"경주원조콩국","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":12558,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.348534,"longitude":127.110605,"bookYear":"2024","nameKR":"행하령수제비","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":13126,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.152499,"longitude":126.883834,"bookYear":"2024","nameKR":"가매일식","ribbonType":"1","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":4643,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.481053,"longitude":126.504988,"bookYear":"2024","nameKR":"어우늘","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":6184,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.167188,"longitude":129.131221,"bookYear":"2024","nameKR":"가미","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":34789,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1564423185533,"longitude":129.124785928587,"bookYear":"2024","nameKR":"만다꼬","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":30342,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.530802862996296,"longitude":126.66116238443087,"bookYear":"2024","nameKR":"숙성명작","ribbonType":"1","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":5496,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.188133,"longitude":129.082464,"bookYear":"2024","nameKR":"화수목","ribbonType":"1","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":2204,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.847824,"longitude":128.591446,"bookYear":"2024","nameKR":"청학식당","ribbonType":"1","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":2216,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.351977,"longitude":127.339874,"bookYear":"2024","nameKR":"만나한우복수집","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":2351,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.411992,"longitude":126.678551,"bookYear":"2024","nameKR":"부원집","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":3043,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.35547,"longitude":126.947264,"bookYear":"2024","nameKR":"군포식당","ribbonType":"1","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":6121,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8728408205024,"longitude":128.587765583825,"bookYear":"2024","nameKR":"성주숯불갈비식당","ribbonType":"1","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":3286,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.146626,"longitude":126.852622,"bookYear":"2024","nameKR":"홍아네","ribbonType":"1","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":36198,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mimong_cj","foodTypes":"일식","latitude":36.7140498123745,"longitude":127.431102324448,"bookYear":"2024","nameKR":"미몽","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":7155,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.157204455063585,"longitude":129.1448178866254,"bookYear":"2024","nameKR":"우미","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":31445,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushi_sian","foodTypes":"일식","latitude":35.1678450471667,"longitude":129.130962834729,"bookYear":"2024","nameKR":"스시시안","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":5332,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.367345,"longitude":127.106285,"bookYear":"2024","nameKR":"센다이","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":5232,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.133834,"longitude":129.091777,"bookYear":"2024","nameKR":"미소오뎅","ribbonType":"1","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":30438,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/buonobuonopangyo/?igshid=1180ikfj532nz","foodTypes":"디저트/차/베이커리","latitude":37.396716696657684,"longitude":127.11005215357467,"bookYear":"2024","nameKR":"보노보노","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":11153,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.156286,"longitude":129.054516,"bookYear":"2024","nameKR":"마당집","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":6984,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.413459,"longitude":127.125506,"bookYear":"2024","nameKR":"슌","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":9025,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.8036719502714,"longitude":126.902173829711,"bookYear":"2024","nameKR":"대흥각","ribbonType":"1","zone2_1":"전북","zone2_2":"김제시","status":"ACTIVE"},
        {"id":30670,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.34845717259386,"longitude":127.32473941546232,"bookYear":"2024","nameKR":"용진식당","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":7988,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.205322,"longitude":128.576538,"bookYear":"2024","nameKR":"불로식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":30053,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":36.3550923934892,"longitude":127.34064964438413,"bookYear":"2024","nameKR":"스시아이","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":2967,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.033747,"longitude":126.714279,"bookYear":"2024","nameKR":"사랑채","ribbonType":"1","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":2988,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.1668745704882,"longitude":127.10649373675801,"bookYear":"2024","nameKR":"은진손칼국수","ribbonType":"1","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":30858,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.3916255624152,"longitude":126.97293973413,"bookYear":"2024","nameKR":"스시미우","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":28361,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":35.150735,"longitude":126.925645,"bookYear":"2024","nameKR":"알랭","ribbonType":"1","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":14695,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.803061,"longitude":127.109687,"bookYear":"2024","nameKR":"클라시코","ribbonType":"1","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":4611,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/namhaesoban/","foodTypes":"한식(일반한식)","latitude":37.3766608532197,"longitude":127.11623934422,"bookYear":"2024","nameKR":"남해소반","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":30374,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pungrook/","foodTypes":"한식(면류)","latitude":35.5436617004613,"longitude":129.33103563813228,"bookYear":"2024","nameKR":"풍로옥","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":34790,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.211131130309,"longitude":129.00615094998,"bookYear":"2024","nameKR":"만선참가자미회전문점","ribbonType":"1","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":2327,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.56309333360177,"longitude":129.31132563001626,"bookYear":"2024","nameKR":"수림복국","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":2340,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.538211,"longitude":126.662052,"bookYear":"2024","nameKR":"갯마을산낙지전문점","ribbonType":"1","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":29453,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6592384391903,"longitude":126.7673440822,"bookYear":"2024","nameKR":"평양냉면양각도","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":29855,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yorokobu80/","foodTypes":"일식","latitude":37.393785630906656,"longitude":126.64187294130727,"bookYear":"2024","nameKR":"스시요로코부","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":15441,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.832185,"longitude":127.140099,"bookYear":"2024","nameKR":"취팔선객잔","ribbonType":"1","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":6745,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.487026,"longitude":126.480553,"bookYear":"2024","nameKR":"밥이보약","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":29989,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffeemoms/","foodTypes":"디저트/차/베이커리","latitude":35.25903511749771,"longitude":129.08387772583802,"bookYear":"2024","nameKR":"커피맘스","ribbonType":"1","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":18254,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.005913,"longitude":126.945664,"bookYear":"2024","nameKR":"황등시장비빔밥","ribbonType":"1","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":2128,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1428617238044,"longitude":126.921112360948,"bookYear":"2024","nameKR":"김가원","ribbonType":"1","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":2207,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":36.385513,"longitude":127.378942,"bookYear":"2024","nameKR":"가남지","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":31047,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.8037089442127,"longitude":127.086580567137,"bookYear":"2024","nameKR":"모밥가","ribbonType":"1","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":28061,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.508121,"longitude":127.260135,"bookYear":"2024","nameKR":"바이핸커피","ribbonType":"1","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":6142,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.4722,"longitude":127.130136,"bookYear":"2024","nameKR":"동해원","ribbonType":"1","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":31178,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cygnus_coffee_bar/","foodTypes":"디저트/차/베이커리","latitude":35.5358801934194,"longitude":129.328194341734,"bookYear":"2024","nameKR":"시그너스커피바","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":27257,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.017404,"longitude":129.360159,"bookYear":"2024","nameKR":"안동소머리곰탕","ribbonType":"1","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":1673,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.8679635599916,"longitude":128.847456786614,"bookYear":"2024","nameKR":"영진횟집","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":32389,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_the_other_side/","foodTypes":"디저트/차/베이커리","latitude":33.3885916865167,"longitude":126.22655462747,"bookYear":"2024","nameKR":"카페이면","ribbonType":"1","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":2739,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.18303617163373,"longitude":128.47958555677866,"bookYear":"2024","nameKR":"덕포식당","ribbonType":"1","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":30064,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kkot_dang2/","foodTypes":"한식(일반한식)","latitude":37.172628598332395,"longitude":127.9898476518207,"bookYear":"2024","nameKR":"꽃댕이묵마을","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":3099,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.784393,"longitude":126.458059,"bookYear":"2024","nameKR":"산해별미","ribbonType":"1","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":3238,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.20508175962453,"longitude":128.98313407293884,"bookYear":"2024","nameKR":"초막고갈두","ribbonType":"1","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":4352,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.86060193035685,"longitude":126.78736003461722,"bookYear":"2024","nameKR":"원조삼거리부대찌개","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":17538,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.096635,"longitude":128.957881,"bookYear":"2024","nameKR":"영진돼지국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":5315,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.830775,"longitude":128.475854,"bookYear":"2024","nameKR":"오대산내고향","ribbonType":"1","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":9815,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.2447309579674,"longitude":126.564687784883,"bookYear":"2024","nameKR":"벌집식당","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":6296,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.321861,"longitude":127.11201,"bookYear":"2024","nameKR":"수담","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":28377,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nonsilcoffeeroasters/ ","foodTypes":"디저트/차/베이커리","latitude":36.022958,"longitude":129.334266,"bookYear":"2024","nameKR":"논실커피로스터스","ribbonType":"1","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":1785,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/don.pasta.pizza/","foodTypes":"이탈리아식","latitude":37.386134,"longitude":127.123581,"bookYear":"2024","nameKR":"돈파스타","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":1998,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.492131419966,"longitude":127.488486784076,"bookYear":"2024","nameKR":"화천갈비","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":37134,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5048322159039,"longitude":126.752430839268,"bookYear":"2024","nameKR":"마므레크로와상","ribbonType":"1","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":29497,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/leehanbakery/","foodTypes":"디저트/차/베이커리","latitude":36.51971773084169,"longitude":127.23587768890583,"bookYear":"2024","nameKR":"이한빵집","ribbonType":"1","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":27442,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.14476633932806,"longitude":129.05980269865856,"bookYear":"2024","nameKR":"가가와","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":28539,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.81514,"longitude":127.105029,"bookYear":"2024","nameKR":"만성한정식","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":3277,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.56297892259319,"longitude":129.31071362635663,"bookYear":"2024","nameKR":"무주골","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":24013,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.392261,"longitude":126.956503,"bookYear":"2024","nameKR":"스시소우","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":31434,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushiuni_omakase_izakaya/?igshid=MzRlODBiNWFlZA%3D%3D","foodTypes":"일식","latitude":35.8369598215877,"longitude":127.057717349996,"bookYear":"2024","nameKR":"스시우니&어스테판","ribbonType":"1","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":28968,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.848621,"longitude":128.635668,"bookYear":"2024","nameKR":"더키친노이","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":33304,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.3630091848763,"longitude":127.106533316945,"bookYear":"2024","nameKR":"스시야","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":29463,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.94073586051068,"longitude":126.5292859115853,"bookYear":"2024","nameKR":"등대로","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":1811,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.971974,"longitude":127.33082,"bookYear":"2024","nameKR":"명지원","ribbonType":"1","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":1819,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.8708822368302,"longitude":126.756015769178,"bookYear":"2024","nameKR":"반구정어부집","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":1951,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.34610511811508,"longitude":127.33547336747236,"bookYear":"2024","nameKR":"최미자소머리국밥","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":2105,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.912151,"longitude":128.790136,"bookYear":"2024","nameKR":"용두식당","ribbonType":"1","zone2_1":"경북","zone2_2":"봉화군","status":"ACTIVE"},
        {"id":6034,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.678567,"longitude":126.75352,"bookYear":"2024","nameKR":"미도향","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":31564,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thebangpaje/","foodTypes":"한식(어패류)","latitude":0,"longitude":0,"bookYear":"2024","nameKR":"방파제","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":2484,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":34.985734,"longitude":127.772431,"bookYear":"2024","nameKR":"청룡식당","ribbonType":"1","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":2979,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.843586,"longitude":127.144595,"bookYear":"2024","nameKR":"이연국수","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":3184,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.37719,"longitude":127.11212,"bookYear":"2024","nameKR":"커피의정원","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":3234,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.71285825856377,"longitude":126.81707306835246,"bookYear":"2024","nameKR":"설성식당","ribbonType":"1","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":12824,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7923580661195,"longitude":128.04728411282,"bookYear":"2024","nameKR":"햇살복집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":6213,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.05824220611173,"longitude":129.4213048230894,"bookYear":"2024","nameKR":"죽변우성식당","ribbonType":"1","zone2_1":"경북","zone2_2":"울진군","status":"ACTIVE"},
        {"id":4592,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.499915,"longitude":126.532681,"bookYear":"2024","nameKR":"광양해장국","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":31572,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4807094380316,"longitude":127.182921474571,"bookYear":"2024","nameKR":"용마루","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":5400,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.439001,"longitude":126.522007,"bookYear":"2024","nameKR":"오양손칼국수","ribbonType":"1","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":31316,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":36.5798270699703,"longitude":128.573884407335,"bookYear":"2024","nameKR":"풍전브런치카페","ribbonType":"1","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":7472,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.793616,"longitude":128.836229,"bookYear":"2024","nameKR":"남산식육식당","ribbonType":"1","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":22522,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.965777,"longitude":129.417899,"bookYear":"2024","nameKR":"40년전통할매손칼국수","ribbonType":"1","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":2424,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.632574,"longitude":127.03111,"bookYear":"2024","nameKR":"바다하우스","ribbonType":"1","zone2_1":"전남","zone2_2":"장흥군","status":"ACTIVE"},
        {"id":2643,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.3110268606103,"longitude":126.513730635327,"bookYear":"2024","nameKR":"두발횟집","ribbonType":"1","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":3014,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.778018,"longitude":127.568678,"bookYear":"2024","nameKR":"송원칼국수","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":3264,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.6281448626734,"longitude":127.811410719088,"bookYear":"2024","nameKR":"안의원조갈비집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"함양군","status":"ACTIVE"},
        {"id":31771,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.3205006360447,"longitude":128.99781107582,"bookYear":"2024","nameKR":"설야멱","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":28425,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.354481,"longitude":127.045699,"bookYear":"2024","nameKR":"산의아침","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":37833,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.6011212853991,"longitude":126.490610764062,"bookYear":"2024","nameKR":"정광수의돈까스가게","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":1855,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.699208,"longitude":126.661054,"bookYear":"2024","nameKR":"소쇄원","ribbonType":"1","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":33715,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lesmains_official/","foodTypes":"디저트/차/베이커리","latitude":35.1923142397145,"longitude":129.102939621975,"bookYear":"2024","nameKR":"레망파티쓰리","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":2185,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.6732244986268,"longitude":128.690590483022,"bookYear":"2024","nameKR":"안압정","ribbonType":"1","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":2660,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.7756365286089,"longitude":126.976298305789,"bookYear":"2024","nameKR":"연춘식당","ribbonType":"1","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":4251,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dodongsanbang_official/","foodTypes":"한식(일반한식)","latitude":35.604137,"longitude":129.096417,"bookYear":"2024","nameKR":"도동산방","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":4253,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.22084097945897,"longitude":129.07926346795668,"bookYear":"2024","nameKR":"동래별장","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":27035,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.975304,"longitude":127.207601,"bookYear":"2024","nameKR":"골목집","ribbonType":"1","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":37537,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/238queens_lounge/","foodTypes":"이탈리아식","latitude":35.2570403453581,"longitude":128.633627626479,"bookYear":"2024","nameKR":"퀸즈라운지","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":32736,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/la_violetta_official/","foodTypes":"이탈리아식","latitude":35.105316059605,"longitude":126.869922783393,"bookYear":"2024","nameKR":"라비올레따","ribbonType":"1","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":4878,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/organicshilla/","foodTypes":"이탈리아식","latitude":35.8564395477729,"longitude":128.603726467771,"bookYear":"2024","nameKR":"올가닉신라","ribbonType":"1","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":31016,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/esquisse__official/","foodTypes":"유럽식","latitude":35.185530064354,"longitude":128.788677532075,"bookYear":"2024","nameKR":"에디스키친","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":30279,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ansan_gukbo_mandu/ ","foodTypes":"기타","latitude":37.310215415104416,"longitude":126.82741783962383,"bookYear":"2024","nameKR":"안산국보만두","ribbonType":"1","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":32112,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.368241209595,"longitude":127.101050800176,"bookYear":"2024","nameKR":"수래옥","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":2639,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.285636,"longitude":126.909186,"bookYear":"2024","nameKR":"나루터식당","ribbonType":"1","zone2_1":"충남","zone2_2":"부여군","status":"ACTIVE"},
        {"id":4974,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.33750561467525,"longitude":127.29637266297199,"bookYear":"2024","nameKR":"라그로타","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":21957,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.158122,"longitude":129.148538,"bookYear":"2024","nameKR":"다온한정식","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":28390,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.764596,"longitude":128.8739,"bookYear":"2024","nameKR":"기사문","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":2385,"bookStatus":"승인","websiteInstagram":" ","foodTypes":"한식(어패류)","latitude":37.47137550092973,"longitude":126.62734974373366,"bookYear":"2024","nameKR":"화선횟집","ribbonType":"1","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":33268,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.8490675782043,"longitude":127.735380409934,"bookYear":"2024","nameKR":"신흥막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":2684,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.839068,"longitude":128.043136,"bookYear":"2024","nameKR":"감나무집","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":30006,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.2409257912557,"longitude":128.587741943938,"bookYear":"2024","nameKR":"베이스워터커피","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":29662,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8329192883911,"longitude":127.17289860219806,"bookYear":"2024","nameKR":"전라도음식이야기","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":8105,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pynoodles/","foodTypes":"한식(면류)","latitude":36.783104,"longitude":126.995733,"bookYear":"2024","nameKR":"평양면옥","ribbonType":"1","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":34348,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nongga_house/","foodTypes":"한식(일반한식)","latitude":37.7081838842712,"longitude":126.514820267929,"bookYear":"2024","nameKR":"농가의식탁","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":2474,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.169418,"longitude":127.448494,"bookYear":"2024","nameKR":"전원가든","ribbonType":"1","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":6989,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.982034,"longitude":126.771926,"bookYear":"2024","nameKR":"대가꽃게장","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":2637,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.278788,"longitude":126.885846,"bookYear":"2024","nameKR":"구드래황토정","ribbonType":"1","zone2_1":"충남","zone2_2":"부여군","status":"ACTIVE"},
        {"id":30463,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.465637916517345,"longitude":127.12286416506588,"bookYear":"2024","nameKR":"원진노기순청국장","ribbonType":"1","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":29414,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_choryang","foodTypes":"디저트/차/베이커리","latitude":35.119818,"longitude":129.029512,"bookYear":"2024","nameKR":"초량1941","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":4298,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.742977,"longitude":127.199332,"bookYear":"2024","nameKR":"광릉불고기","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":6072,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.410674,"longitude":127.407519,"bookYear":"2024","nameKR":"심원첫집","ribbonType":"1","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":7183,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.735616,"longitude":126.412596,"bookYear":"2024","nameKR":"소박한밥상","ribbonType":"1","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":30151,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kitchendongbaek/","foodTypes":"이탈리아식","latitude":35.1577879564722,"longitude":129.17876543266965,"bookYear":"2024","nameKR":"키친동백","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":30581,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.41854690998507,"longitude":127.87099111427078,"bookYear":"2024","nameKR":"지리산약초장어","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":2665,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.819746,"longitude":126.256617,"bookYear":"2024","nameKR":"태안명품박속낙지탕","ribbonType":"1","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":2727,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.989566,"longitude":127.368148,"bookYear":"2024","nameKR":"장다리식당","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":12506,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/baelys_kitchen/","foodTypes":"디저트/차/베이커리","latitude":35.188632,"longitude":126.823815,"bookYear":"2024","nameKR":"배리스키친","ribbonType":"1","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":5347,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.595516,"longitude":127.35024,"bookYear":"2024","nameKR":"문호리팥죽","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":9240,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.780769,"longitude":126.370844,"bookYear":"2024","nameKR":"선경준치회집","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":4022,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.20889596829513,"longitude":127.46362730079208,"bookYear":"2024","nameKR":"부부식당","ribbonType":"1","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":5974,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.819626,"longitude":127.142923,"bookYear":"2024","nameKR":"효자문","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":1696,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.924630492686106,"longitude":128.77714473398387,"bookYear":"2024","nameKR":"입암메밀타운","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":1719,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.9673060734759,"longitude":127.665256049796,"bookYear":"2024","nameKR":"평남횟집","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":2319,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.501737,"longitude":129.369561,"bookYear":"2024","nameKR":"고래고기원조할매집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":2662,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.012444,"longitude":126.697424,"bookYear":"2024","nameKR":"할매온정집","ribbonType":"1","zone2_1":"충남","zone2_2":"서천군","status":"ACTIVE"},
        {"id":2894,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.827282309311435,"longitude":128.43259532875427,"bookYear":"2024","nameKR":"도남식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":5015,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.498468,"longitude":128.745744,"bookYear":"2024","nameKR":"밀양돼지국밥","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":32407,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kuwoom.official/","foodTypes":"디저트/차/베이커리","latitude":37.4153135803913,"longitude":126.814831818348,"bookYear":"2024","nameKR":"쿠:움","ribbonType":"1","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":17334,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.417637,"longitude":126.652544,"bookYear":"2024","nameKR":"충남서산꽃게탕집","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":4482,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.160252,"longitude":128.99009,"bookYear":"2024","nameKR":"배달식육실비식당","ribbonType":"1","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":6782,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56392,"longitude":127.114868,"bookYear":"2024","nameKR":"묘향만두","ribbonType":"1","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":5968,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.84371049880932,"longitude":127.12531148654585,"bookYear":"2024","nameKR":"해이루","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2112,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.415716,"longitude":129.430341,"bookYear":"2024","nameKR":"진일대게회","ribbonType":"1","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":12589,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.396853,"longitude":127.233867,"bookYear":"2024","nameKR":"장지리가마솥해장국","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":2693,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.629238,"longitude":127.48518,"bookYear":"2024","nameKR":"남주동해장국","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":32565,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2160395437485,"longitude":129.080942043105,"bookYear":"2024","nameKR":"원조꼬리곰집","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":2801,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.984675,"longitude":126.708832,"bookYear":"2024","nameKR":"군산복집","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":35101,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.2349083288511,"longitude":126.863157153268,"bookYear":"2024","nameKR":"다람쥐할머니","ribbonType":"1","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":5333,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.477512,"longitude":127.186722,"bookYear":"2024","nameKR":"오복손두부","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":4399,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.117283,"longitude":127.667785,"bookYear":"2024","nameKR":"외할머니집","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"음성군","status":"ACTIVE"},
        {"id":4432,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.03144,"longitude":126.71658,"bookYear":"2024","nameKR":"노안집","ribbonType":"1","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":4520,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.712992,"longitude":129.198184,"bookYear":"2024","nameKR":"기와집숯불고기","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":12548,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.600509,"longitude":126.828232,"bookYear":"2024","nameKR":"지리산어탕국수","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":33675,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.2828405392621,"longitude":129.25761706663,"bookYear":"2024","nameKR":"탐복","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":31589,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ce_lieu_439/","foodTypes":"프랑스식","latitude":35.8724682114633,"longitude":128.598166401698,"bookYear":"2024","nameKR":"셀리우","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":10273,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.523224,"longitude":126.549738,"bookYear":"2024","nameKR":"우정회관","ribbonType":"1","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":32391,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/a.tout.de.sweet/","foodTypes":"디저트/차/베이커리","latitude":37.7484806470159,"longitude":126.481559688009,"bookYear":"2024","nameKR":"아뚜드스윗","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":2067,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.577677,"longitude":128.758144,"bookYear":"2024","nameKR":"까치구멍집","ribbonType":"1","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":32524,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mamarron_official","foodTypes":"디저트/차/베이커리","latitude":33.4521567767768,"longitude":126.439180967033,"bookYear":"2024","nameKR":"마마롱","ribbonType":"1","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":34510,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lessence_busan/?igshid=YmMyMTA2M2Y%3D","foodTypes":"프랑스식","latitude":35.1446033601617,"longitude":129.111351664341,"bookYear":"2024","nameKR":"레썽스","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":3077,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.619222,"longitude":127.357633,"bookYear":"2024","nameKR":"사각하늘","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":3200,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.170806,"longitude":129.171487,"bookYear":"2024","nameKR":"젠스시","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":6294,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/le_reve_de_bebe","foodTypes":"디저트/차/베이커리","latitude":37.326108,"longitude":127.125589,"bookYear":"2024","nameKR":"르헤브드베베","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":29485,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":35.161507412932416,"longitude":129.17638470864551,"bookYear":"2024","nameKR":"머스트루","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":28577,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thespoonjeju/","foodTypes":"이탈리아식","latitude":33.476097,"longitude":126.554301,"bookYear":"2024","nameKR":"더스푼","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":31048,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.9553505621381,"longitude":127.025782666434,"bookYear":"2024","nameKR":"예지원","ribbonType":"1","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":1515,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.716905329933,"longitude":126.971463676,"bookYear":"2024","nameKR":"만포면옥","ribbonType":"1","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":1649,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.8809909779402,"longitude":128.75493191171,"bookYear":"2024","nameKR":"삼교리원조동치미막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33136,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oakwoodpremierincheon/","foodTypes":"기타","latitude":37.3891408885668,"longitude":126.644442676851,"bookYear":"2024","nameKR":"오크레스토랑(오크우드프리미어인천점)","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":34804,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2735579293132,"longitude":126.500679795691,"bookYear":"2024","nameKR":"종가집굴비정식설궁","ribbonType":"1","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":1810,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.876633,"longitude":127.529706,"bookYear":"2024","nameKR":"명지쉼터가든","ribbonType":"1","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":36322,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1767493318838,"longitude":127.019758937419,"bookYear":"2024","nameKR":"명가은","ribbonType":"1","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":30884,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yoyo.bakeshop","foodTypes":"디저트/차/베이커리","latitude":37.3586035473385,"longitude":126.926394640697,"bookYear":"2024","nameKR":"요요연연","ribbonType":"1","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":3778,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":38.184424,"longitude":128.565853,"bookYear":"2024","nameKR":"원조털보네토종닭","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":28474,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/11chesterfieldway ","foodTypes":"기타","latitude":35.847431,"longitude":129.205046,"bookYear":"2024","nameKR":"일레븐체스터필드웨이","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":28398,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.531851,"longitude":129.329298,"bookYear":"2024","nameKR":"고래향","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":2160,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.871099,"longitude":128.593168,"bookYear":"2024","nameKR":"국일따로국밥","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":2311,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.158818,"longitude":129.170661,"bookYear":"2024","nameKR":"박옥희할매집원조복국","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":27129,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/luvmoon9145 ","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.573371,"longitude":127.122357,"bookYear":"2024","nameKR":"양평해장국","ribbonType":"1","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":9429,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":33.247558,"longitude":126.408116,"bookYear":"2024","nameKR":"히노데","ribbonType":"1","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":9197,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.247415,"longitude":126.408113,"bookYear":"2024","nameKR":"천지","ribbonType":"1","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":31009,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.2217566034894,"longitude":129.083561526107,"bookYear":"2024","nameKR":"봉식당","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":5995,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.155716,"longitude":129.122599,"bookYear":"2024","nameKR":"칠성횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":16416,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gajokhwegwan/","foodTypes":"한식(일반한식)","latitude":35.15121,"longitude":126.925987,"bookYear":"2024","nameKR":"가족회관","ribbonType":"1","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":5482,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.219469,"longitude":129.081898,"bookYear":"2024","nameKR":"내당","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":9427,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.156853,"longitude":129.056647,"bookYear":"2024","nameKR":"모모야마","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":2620,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.242504,"longitude":126.419062,"bookYear":"2024","nameKR":"중문해녀의집","ribbonType":"1","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":29372,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chromitecoffee/ ","foodTypes":"디저트/차/베이커리","latitude":37.421254,"longitude":126.650581,"bookYear":"2024","nameKR":"크로마이트커피","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":6953,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.8582172242343,"longitude":127.233466897681,"bookYear":"2024","nameKR":"다다미일식","ribbonType":"1","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":4226,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/phoenixhnr/","foodTypes":"이탈리아식","latitude":33.432707,"longitude":126.930303,"bookYear":"2024","nameKR":"민트레스토랑","ribbonType":"1","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":4316,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/goya_namyangju/","foodTypes":"한식(가금류)","latitude":37.646013472457035,"longitude":127.36442901686172,"bookYear":"2024","nameKR":"고야","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":4562,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.154386,"longitude":129.145144,"bookYear":"2024","nameKR":"클라우드32","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":32508,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/r_justeune/","foodTypes":"프랑스식","latitude":35.8455256165351,"longitude":129.212925095775,"bookYear":"2024","nameKR":"주스트윤","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":6944,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.831535,"longitude":128.616235,"bookYear":"2024","nameKR":"민수사","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":34305,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1551800271003,"longitude":129.131030467408,"bookYear":"2024","nameKR":"금랑횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":1721,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.34383,"longitude":127.860856,"bookYear":"2024","nameKR":"하얀집가든","ribbonType":"1","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":1969,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.9039038939775,"longitude":127.051113879958,"bookYear":"2024","nameKR":"평남면옥","ribbonType":"1","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":32404,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hwasarang_asado/","foodTypes":"중남미식","latitude":37.7332067042515,"longitude":127.042313809623,"bookYear":"2024","nameKR":"화사랑아사도","ribbonType":"1","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":22002,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.322246,"longitude":129.26957,"bookYear":"2024","nameKR":"고스락","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":2290,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.22115334711958,"longitude":129.08268888480987,"bookYear":"2024","nameKR":"어가초밥","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":10368,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chabatgol","foodTypes":"한식(일반한식)","latitude":35.9878582410264,"longitude":128.620579134271,"bookYear":"2024","nameKR":"고향차밭골","ribbonType":"1","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":3094,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.66141,"longitude":126.765133,"bookYear":"2024","nameKR":"옥류담","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":3367,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.365348,"longitude":127.380401,"bookYear":"2024","nameKR":"여자만장어구이","ribbonType":"1","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":3682,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.830615,"longitude":128.625386,"bookYear":"2024","nameKR":"라벨라쿠치나","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":30399,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bonplatbonvin/","foodTypes":"프랑스식","latitude":33.4880113659418,"longitude":126.497898657075,"bookYear":"2024","nameKR":"봉플라봉뱅","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":37161,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.4004659332759,"longitude":127.106812648944,"bookYear":"2024","nameKR":"스시쿤","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":9297,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.15596,"longitude":129.153982,"bookYear":"2024","nameKR":"셔블","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":9958,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.94629,"longitude":127.513477,"bookYear":"2024","nameKR":"신화정","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":30654,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.63822371507417,"longitude":126.66414659944488,"bookYear":"2024","nameKR":"수갈비","ribbonType":"1","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":29895,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushiryoko/","foodTypes":"일식","latitude":37.38114070975842,"longitude":127.11664144633698,"bookYear":"2024","nameKR":"스시료코","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32446,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chago145_pangyo/","foodTypes":"한식(육류)","latitude":37.3944039022277,"longitude":127.108352326702,"bookYear":"2024","nameKR":"차고145","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":3162,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.37402,"longitude":127.143429,"bookYear":"2024","nameKR":"민속집","ribbonType":"1","zone2_1":"전북","zone2_2":"순창군","status":"ACTIVE"},
        {"id":3817,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.349941,"longitude":127.388282,"bookYear":"2024","nameKR":"대선칼국수","ribbonType":"1","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":4505,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.195610112746934,"longitude":128.45632407892722,"bookYear":"2024","nameKR":"장릉보리밥집","ribbonType":"1","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":4616,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hancdang/","foodTypes":"한식(일반한식)","latitude":37.570818,"longitude":127.201816,"bookYear":"2024","nameKR":"한채당","ribbonType":"1","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":1635,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.883584,"longitude":127.72555,"bookYear":"2024","nameKR":"봉운장","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":1769,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.38339,"longitude":127.138726,"bookYear":"2024","nameKR":"긴자","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":3078,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.680556,"longitude":126.848758,"bookYear":"2024","nameKR":"삼우갈비","ribbonType":"1","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":30081,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bistro.perlei/","foodTypes":"이탈리아식","latitude":37.53386631106574,"longitude":126.63692747385267,"bookYear":"2024","nameKR":"비스트로페르레이","ribbonType":"1","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":27949,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.2009711098924,"longitude":128.588341430946,"bookYear":"2024","nameKR":"후포식당","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":33066,"bookStatus":"승인","websiteInstagram":"","foodTypes":"미국식","latitude":35.1762346398621,"longitude":129.115567097586,"bookYear":"2024","nameKR":"마이클어반팜테이블","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":8957,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.083507,"longitude":128.086648,"bookYear":"2024","nameKR":"덕합반점","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":4233,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.064774,"longitude":126.987535,"bookYear":"2024","nameKR":"수림정","ribbonType":"1","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":4409,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.150417,"longitude":126.930509,"bookYear":"2024","nameKR":"광양숯불구이","ribbonType":"1","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":4537,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.812147,"longitude":129.361972,"bookYear":"2024","nameKR":"백년찻집(경주점)","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":4603,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_heukdonga/","foodTypes":"한식(육류)","latitude":33.4800554245897,"longitude":126.473495778004,"bookYear":"2024","nameKR":"흑돈가","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":24345,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.405374023183406,"longitude":126.96907052648785,"bookYear":"2024","nameKR":"비소원","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":5164,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.422415,"longitude":126.266399,"bookYear":"2024","nameKR":"사형제횟집","ribbonType":"1","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":23757,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.001118,"longitude":129.195583,"bookYear":"2024","nameKR":"외바우","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2597,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.2466001158367,"longitude":126.562910154724,"bookYear":"2024","nameKR":"쌍둥이횟집","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":2864,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/songjeongrib/","foodTypes":"한식(육류)","latitude":37.3337346296283,"longitude":127.054186056472,"bookYear":"2024","nameKR":"송정갈비","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":28083,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.15551931105345,"longitude":129.1265241125179,"bookYear":"2024","nameKR":"제일장횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":33940,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sinkicoffee/","foodTypes":"디저트/차/베이커리","latitude":35.0880587481424,"longitude":129.06093733221,"bookYear":"2024","nameKR":"신기산업카페","ribbonType":"1","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":13171,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gyeongpo_busan/","foodTypes":"한식(어패류)","latitude":37.803631,"longitude":128.908911,"bookYear":"2024","nameKR":"부산처녀횟집","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":2977,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.849946,"longitude":127.117305,"bookYear":"2024","nameKR":"무궁화","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":3255,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7069389880508,"longitude":127.112037360701,"bookYear":"2024","nameKR":"태능숯불갈비","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":32349,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3859850702138,"longitude":127.405484021358,"bookYear":"2024","nameKR":"마당넓은집","ribbonType":"1","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":4050,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.77139945696539,"longitude":126.75896343405259,"bookYear":"2024","nameKR":"오두산막국수","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":27870,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.48382397284803,"longitude":127.98139819282888,"bookYear":"2024","nameKR":"횡성축협한우프라자","ribbonType":"1","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":1612,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.67212457284142,"longitude":128.71762687833714,"bookYear":"2024","nameKR":"대관령추어탕토종닭","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1745,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.83559,"longitude":126.745579,"bookYear":"2024","nameKR":"갈릴리농원","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":1825,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.478665,"longitude":127.183903,"bookYear":"2024","nameKR":"백제장","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":1911,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/waltzndrmahn/","foodTypes":"이탈리아식","latitude":37.5919663107173,"longitude":127.3384565303,"bookYear":"2024","nameKR":"왈츠와닥터만","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":2636,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.285298,"longitude":126.909629,"bookYear":"2024","nameKR":"구드래돌쌈밥","ribbonType":"1","zone2_1":"충남","zone2_2":"부여군","status":"ACTIVE"},
        {"id":32577,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":37.7164020556989,"longitude":126.980321567814,"bookYear":"2024","nameKR":"그릴휘바","ribbonType":"1","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":4455,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.686564,"longitude":126.741216,"bookYear":"2024","nameKR":"신창집","ribbonType":"1","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":6266,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1828881155471,"longitude":129.205520295074,"bookYear":"2024","nameKR":"영변횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":6164,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.962043,"longitude":126.846998,"bookYear":"2024","nameKR":"호성식당","ribbonType":"1","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":2098,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.577524,"longitude":128.758064,"bookYear":"2024","nameKR":"맛50년헛제사밥","ribbonType":"1","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":2480,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.3367455579809,"longitude":126.948182543041,"bookYear":"2024","nameKR":"죽림원가든","ribbonType":"1","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":28081,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.216991,"longitude":129.22612,"bookYear":"2024","nameKR":"무진장횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":7994,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.104775,"longitude":129.024922,"bookYear":"2024","nameKR":"새진주식당","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":6320,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.391459,"longitude":126.953979,"bookYear":"2024","nameKR":"파스타까사","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":9238,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.6199458977054,"longitude":126.286950817423,"bookYear":"2024","nameKR":"선창횟집","ribbonType":"1","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":6336,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.384299,"longitude":126.960797,"bookYear":"2024","nameKR":"함흥곰보냉면","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":27850,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/souffle_cake_/","foodTypes":"디저트/차/베이커리","latitude":35.809693122114,"longitude":127.106704999473,"bookYear":"2024","nameKR":"수플레","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":9238,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.6199458977054,"longitude":126.286950817423,"bookYear":"2024","nameKR":"선창횟집","ribbonType":"1","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":37166,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/maisonforet/","foodTypes":"기타","latitude":37.2918080435448,"longitude":127.262507869518,"bookYear":"2024","nameKR":"메종포레","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":2008,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.83280215127413,"longitude":127.99196423404888,"bookYear":"2024","nameKR":"달반늘","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":32351,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mukri_459/","foodTypes":"디저트/차/베이커리","latitude":37.1691204904098,"longitude":127.238441675057,"bookYear":"2024","nameKR":"묵리459","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":37619,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sangango_official/","foodTypes":"한식(어패류)","latitude":37.3476484812844,"longitude":127.090379644014,"bookYear":"2024","nameKR":"산으로간고등어","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":2410,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.321206,"longitude":126.981633,"bookYear":"2024","nameKR":"덕인갈비","ribbonType":"1","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":3133,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.49286663174872,"longitude":127.28287778831813,"bookYear":"2024","nameKR":"강마을다람쥐","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":6305,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.259286,"longitude":127.033171,"bookYear":"2024","nameKR":"어구미","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6431,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.388827,"longitude":126.971333,"bookYear":"2024","nameKR":"진성장어","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":12466,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.81742109957106,"longitude":127.14581211755369,"bookYear":"2024","nameKR":"하숙영가마솥비빔밥","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2233,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sutgolwon_nengmeun/","foodTypes":"한식(면류)","latitude":36.390146,"longitude":127.350209,"bookYear":"2024","nameKR":"숯골원냉면","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":31872,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1524902757948,"longitude":126.88366875296,"bookYear":"2024","nameKR":"광주옥1947 ","ribbonType":"1","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":3185,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.332087,"longitude":127.057705,"bookYear":"2024","nameKR":"연지","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":3479,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.664576,"longitude":127.292126,"bookYear":"2024","nameKR":"평창갈비","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":32393,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.9698476849125,"longitude":127.579220059671,"bookYear":"2024","nameKR":"매실한우광양불고기","ribbonType":"1","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":32243,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/owol_2009/","foodTypes":"한식(일반한식)","latitude":37.3433762101927,"longitude":127.061009835122,"bookYear":"2024","nameKR":"오월식당","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":5273,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.36043,"longitude":127.344547,"bookYear":"2024","nameKR":"연래춘대반점","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":5515,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.843902,"longitude":128.603895,"bookYear":"2024","nameKR":"대동강","ribbonType":"1","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":30630,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yangwoojung_","foodTypes":"한식(육류)","latitude":37.395800819414,"longitude":127.107592379777,"bookYear":"2024","nameKR":"양우정(판교점)","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":13214,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.409542,"longitude":127.126811,"bookYear":"2024","nameKR":"겐","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":23188,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.465561,"longitude":127.121645,"bookYear":"2024","nameKR":"매향막국수","ribbonType":"1","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":31728,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.2341381844005,"longitude":129.012625665188,"bookYear":"2024","nameKR":"취밍","ribbonType":"1","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":2259,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.16243,"longitude":129.16456,"bookYear":"2024","nameKR":"금수복국","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":2547,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.81477855930242,"longitude":127.14879043990344,"bookYear":"2024","nameKR":"종로회관","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":2845,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.6834408119262,"longitude":127.101599294224,"bookYear":"2024","nameKR":"목향원","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":30969,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/singlefin_aleworks/","foodTypes":"이탈리아식","latitude":38.0234337961978,"longitude":128.722750198151,"bookYear":"2024","nameKR":"싱글핀에일웍스","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":25154,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.0116818709442,"longitude":126.761736015862,"bookYear":"2024","nameKR":"유성꽃게장","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":7781,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.00734854888814,"longitude":128.59138083882385,"bookYear":"2024","nameKR":"백년찻집","ribbonType":"1","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":1716,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.933024,"longitude":127.793282,"bookYear":"2024","nameKR":"통나무집닭갈비","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":2182,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/xinchao_korea/","foodTypes":"아시아식","latitude":35.827009,"longitude":128.624359,"bookYear":"2024","nameKR":"신짜오","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":2249,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.446146,"longitude":127.383824,"bookYear":"2024","nameKR":"할머니묵집","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":2681,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.156438,"longitude":127.00927,"bookYear":"2024","nameKR":"황산옥","ribbonType":"1","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":3325,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.65486,"longitude":126.670118,"bookYear":"2024","nameKR":"김포한탄강","ribbonType":"1","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":4052,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.941586,"longitude":128.718625,"bookYear":"2024","nameKR":"양지바위횟집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":11317,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.102328,"longitude":129.029084,"bookYear":"2024","nameKR":"산해갈비","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":5419,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.741373,"longitude":127.424787,"bookYear":"2024","nameKR":"반암막국수","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":23886,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.561663,"longitude":127.113871,"bookYear":"2024","nameKR":"모던기와","ribbonType":"1","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":2093,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.0349826664718,"longitude":129.369045878316,"bookYear":"2024","nameKR":"수향회식당","ribbonType":"1","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":2349,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.578647,"longitude":126.778243,"bookYear":"2024","nameKR":"벌말매운탕","ribbonType":"1","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":2542,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.016701,"longitude":126.800916,"bookYear":"2024","nameKR":"옹고집쌈밥","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":35235,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seoji.chogaddle/","foodTypes":"디저트/차/베이커리","latitude":37.7911030002888,"longitude":128.880935949637,"bookYear":"2024","nameKR":"서지초가뜰","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":3563,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.190295,"longitude":128.586311,"bookYear":"2024","nameKR":"소야삼교리동치미막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":29495,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/waveoncoffee_official/ ","foodTypes":"디저트/차/베이커리","latitude":35.322270040272926,"longitude":129.269779558861,"bookYear":"2024","nameKR":"웨이브온","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":34022,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3706538972531,"longitude":127.796477772392,"bookYear":"2024","nameKR":"스톤크릭스테이션","ribbonType":"1","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":6977,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.509734,"longitude":126.813308,"bookYear":"2024","nameKR":"봉순게장","ribbonType":"1","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":28625,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oakhill_official ","foodTypes":"디저트/차/베이커리","latitude":37.705339,"longitude":127.058894,"bookYear":"2024","nameKR":"오크힐커피","ribbonType":"1","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":32412,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/munsterdam_pub/","foodTypes":"바/주점","latitude":37.7267740899755,"longitude":126.784125289613,"bookYear":"2024","nameKR":"뮌스터담","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":2760,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.070208,"longitude":127.064079,"bookYear":"2024","nameKR":"최네집","ribbonType":"1","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":31101,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/forestoutings_ilsan/","foodTypes":"디저트/차/베이커리","latitude":37.665621799875,"longitude":126.813495004411,"bookYear":"2024","nameKR":"포레스트아웃팅스","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":34387,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/abyssinia_coffee/","foodTypes":"디저트/차/베이커리","latitude":36.8100664501967,"longitude":127.128165039987,"bookYear":"2024","nameKR":"아비시니아커피","ribbonType":"1","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":32499,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hocuspocus.roasters/","foodTypes":"디저트/차/베이커리","latitude":37.0634561436965,"longitude":127.076301876617,"bookYear":"2024","nameKR":"호커스포커스로스터스","ribbonType":"1","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":2156,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/si______ung/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.872395,"longitude":128.586973,"bookYear":"2024","nameKR":"8번식당","ribbonType":"1","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":2271,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.104482,"longitude":129.024399,"bookYear":"2024","nameKR":"물꽁식당","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":34923,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.2788114675288,"longitude":127.442615024054,"bookYear":"2024","nameKR":"아지매","ribbonType":"1","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":2997,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.8149746159382,"longitude":127.133731184041,"bookYear":"2024","nameKR":"남해전복","ribbonType":"1","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":29393,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.98660171082413,"longitude":126.74525784841119,"bookYear":"2024","nameKR":"미곡창고","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":4533,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.803909,"longitude":126.436743,"bookYear":"2024","nameKR":"인동주마을","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":34831,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2020308646942,"longitude":129.086914613782,"bookYear":"2024","nameKR":"의령돼지국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":16312,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.96393284246067,"longitude":126.89385434085042,"bookYear":"2024","nameKR":"원조두지리매운탕1호점","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":12698,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.563545,"longitude":128.731933,"bookYear":"2024","nameKR":"뉴서울갈비","ribbonType":"1","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":1771,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.034008,"longitude":127.366108,"bookYear":"2024","nameKR":"김미자할머니집","ribbonType":"1","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":1899,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.301206,"longitude":127.407957,"bookYear":"2024","nameKR":"어향미가","ribbonType":"1","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":2032,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2060952071556,"longitude":128.578347605662,"bookYear":"2024","nameKR":"오동동아구할매집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":36905,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/el16.52_/","foodTypes":"디저트/차/베이커리","latitude":35.0631174044683,"longitude":129.019438172979,"bookYear":"2024","nameKR":"이엘십육점오이","ribbonType":"1","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":2583,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_boodoo","foodTypes":"한식(일반한식)","latitude":33.2188214636735,"longitude":126.251031679557,"bookYear":"2024","nameKR":"부두식당","ribbonType":"1","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":31420,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ramos_burger/","foodTypes":"기타","latitude":37.8718434789193,"longitude":127.708019667729,"bookYear":"2024","nameKR":"라모스버거","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":4258,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.300325,"longitude":127.408381,"bookYear":"2024","nameKR":"나랏님이천쌀밥","ribbonType":"1","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":37621,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_de_chelsea_official/","foodTypes":"디저트/차/베이커리","latitude":37.6915838336282,"longitude":126.576836567671,"bookYear":"2024","nameKR":"카페드첼시","ribbonType":"1","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":32602,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ledetour_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.3121197599694,"longitude":127.054940225552,"bookYear":"2024","nameKR":"르디투어","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":30786,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.7044693124774,"longitude":127.057929822036,"bookYear":"2024","nameKR":"일월담","ribbonType":"1","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":33498,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8559516434581,"longitude":128.750312103976,"bookYear":"2024","nameKR":"커피명가본","ribbonType":"1","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":31179,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe304/","foodTypes":"디저트/차/베이커리","latitude":35.1443297071938,"longitude":126.840088340695,"bookYear":"2024","nameKR":"카페304","ribbonType":"1","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":13061,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.218796,"longitude":128.593221,"bookYear":"2024","nameKR":"봉포머구리집","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":29929,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.19819262814359,"longitude":129.22858278039445,"bookYear":"2024","nameKR":"맥퀸즈라운지","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":1600,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.872299717130396,"longitude":127.72435419760912,"bookYear":"2024","nameKR":"남부막국수본관","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":10792,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.941238,"longitude":127.874782,"bookYear":"2024","nameKR":"유진횟집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":2908,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.656245,"longitude":128.129027,"bookYear":"2024","nameKR":"초곡관","ribbonType":"1","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":12897,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.4880042514984,"longitude":126.499706378954,"bookYear":"2024","nameKR":"삼성혈해물탕","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":3260,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.932927948712276,"longitude":127.79210503853827,"bookYear":"2024","nameKR":"명가막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":4613,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.4717592753964,"longitude":127.309386573615,"bookYear":"2024","nameKR":"엄지매운탕","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":4813,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.56057,"longitude":129.117658,"bookYear":"2024","nameKR":"갈비구락부","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":32399,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chilamsagye/","foodTypes":"디저트/차/베이커리","latitude":35.2998246855164,"longitude":129.259159523105,"bookYear":"2024","nameKR":"칠암사계","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":29149,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/badajungwon/","foodTypes":"디저트/차/베이커리","latitude":38.2314307691712,"longitude":128.582883041835,"bookYear":"2024","nameKR":"바다정원","ribbonType":"1","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":2602,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.2410076748705,"longitude":126.610914213519,"bookYear":"2024","nameKR":"어진이네횟집","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":2676,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.7554,"longitude":126.291387,"bookYear":"2024","nameKR":"토담집","ribbonType":"1","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":3003,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.814358,"longitude":128.03729,"bookYear":"2024","nameKR":"조령산묵밥청국장","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"괴산군","status":"ACTIVE"},
        {"id":3642,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.109924,"longitude":128.378428,"bookYear":"2024","nameKR":"동광알탕","ribbonType":"1","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":32309,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3797476315094,"longitude":127.046035966335,"bookYear":"2024","nameKR":"피크닉엣더나무사이로","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":1733,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.616238,"longitude":128.377227,"bookYear":"2024","nameKR":"현대막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1751,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.55939,"longitude":127.234976,"bookYear":"2024","nameKR":"개성집","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":1902,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.744091224976486,"longitude":127.04949124304684,"bookYear":"2024","nameKR":"오뎅식당","ribbonType":"1","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":1907,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.518043,"longitude":127.455453,"bookYear":"2024","nameKR":"옥천면옥","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":31842,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.6952127333096,"longitude":126.76201157783,"bookYear":"2024","nameKR":"사적인접시","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":31038,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mybottle.cafe/","foodTypes":"디저트/차/베이커리","latitude":37.3811733499784,"longitude":127.130339166306,"bookYear":"2024","nameKR":"마이보틀","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":2645,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.606667,"longitude":126.415856,"bookYear":"2024","nameKR":"맛동산","ribbonType":"1","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":3001,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.824135,"longitude":126.258063,"bookYear":"2024","nameKR":"원풍식당","ribbonType":"1","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":36440,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cottonclub1224","foodTypes":"프랑스식","latitude":36.6308328191872,"longitude":127.424859324128,"bookYear":"2024","nameKR":"제이슨굿맨코튼클럽&화이트크리스마스","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":3245,"bookStatus":"승인","websiteInstagram":"http://instagram.com/island_village.muju","foodTypes":"한식(민물어패류)","latitude":36.01668,"longitude":127.645059,"bookYear":"2024","nameKR":"섬마을","ribbonType":"1","zone2_1":"전북","zone2_2":"무주군","status":"ACTIVE"},
        {"id":28422,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.85019,"longitude":127.771585,"bookYear":"2024","nameKR":"대룡산막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":5338,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/day_under_the_tree/","foodTypes":"디저트/차/베이커리","latitude":37.7606561183014,"longitude":127.361496085833,"bookYear":"2024","nameKR":"나무아래오후N","ribbonType":"1","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":5456,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.383039,"longitude":126.961896,"bookYear":"2024","nameKR":"부산복칼국수","ribbonType":"1","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":32583,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.4918433108774,"longitude":126.472336779634,"bookYear":"2024","nameKR":"동귀리갈칫집","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":33428,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.2418117185555,"longitude":126.386244199585,"bookYear":"2024","nameKR":"색달식당중문갈치조림구이(본점)","ribbonType":"1","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":11425,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.167854,"longitude":129.172718,"bookYear":"2024","nameKR":"양산국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":29978,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffeeradio/","foodTypes":"디저트/차/베이커리","latitude":37.315935436740695,"longitude":127.95971319611964,"bookYear":"2024","nameKR":"커피라디오","ribbonType":"1","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":2139,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.162062,"longitude":126.906179,"bookYear":"2024","nameKR":"영광오리탕","ribbonType":"1","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":2883,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.875152,"longitude":128.626848,"bookYear":"2024","nameKR":"백만석","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":29679,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jejugukdam","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.4885898164723,"longitude":126.494701728453,"bookYear":"2024","nameKR":"제주국담","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":4570,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/twinsporksoup/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1317702724979,"longitude":129.092306249124,"bookYear":"2024","nameKR":"쌍둥이돼지국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":31753,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_sann/","foodTypes":"디저트/차/베이커리","latitude":36.9946676701953,"longitude":128.394377599647,"bookYear":"2024","nameKR":"카페산","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":6935,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.36839,"longitude":127.380774,"bookYear":"2024","nameKR":"일정","ribbonType":"1","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":12825,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.808333,"longitude":128.034624,"bookYear":"2024","nameKR":"대청마루","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":1698,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.438973,"longitude":128.095649,"bookYear":"2024","nameKR":"장미산장","ribbonType":"1","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":1714,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/terarosacoffee/","foodTypes":"기타","latitude":37.6964984768682,"longitude":128.892026509635,"bookYear":"2024","nameKR":"테라로사커피공장","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":2372,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.711918,"longitude":126.353835,"bookYear":"2024","nameKR":"진두강참장어","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":2534,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.4004101546192,"longitude":127.375335015311,"bookYear":"2024","nameKR":"새집추어탕","ribbonType":"1","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":32684,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.2524890526693,"longitude":127.075715233126,"bookYear":"2024","nameKR":"크레마노","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":2667,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.532616,"longitude":126.349559,"bookYear":"2024","nameKR":"일송꽃게장백반","ribbonType":"1","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":36594,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.674081062353,"longitude":128.70875040364,"bookYear":"2024","nameKR":"오대산식당","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":3242,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.869986,"longitude":126.279364,"bookYear":"2024","nameKR":"이원식당","ribbonType":"1","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":3891,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.199415,"longitude":128.550024,"bookYear":"2024","nameKR":"점봉산산채식당","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":9026,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.758052,"longitude":127.396283,"bookYear":"2024","nameKR":"초가정담","ribbonType":"1","zone2_1":"전북","zone2_2":"진안군","status":"ACTIVE"},
        {"id":5971,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.820148,"longitude":127.147709,"bookYear":"2024","nameKR":"연지본관","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":30875,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/painsetgateaux/","foodTypes":"디저트/차/베이커리","latitude":37.6580852251838,"longitude":127.127937957506,"bookYear":"2024","nameKR":"빵에갸또","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":2060,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.19237,"longitude":127.624656,"bookYear":"2024","nameKR":"혜성식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":2432,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.739779,"longitude":127.734271,"bookYear":"2024","nameKR":"사시사철삼치회","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":2516,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.595441,"longitude":126.61902,"bookYear":"2024","nameKR":"곰소쉼터","ribbonType":"1","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":2610,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_yurine","foodTypes":"한식(일반한식)","latitude":33.481447,"longitude":126.497123,"bookYear":"2024","nameKR":"향토음식유리네","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":2675,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.609581,"longitude":126.416466,"bookYear":"2024","nameKR":"큰마을영양굴밥","ribbonType":"1","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":2689,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.532336,"longitude":127.821578,"bookYear":"2024","nameKR":"경희식당","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":29777,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/que_de_pain/","foodTypes":"디저트/차/베이커리","latitude":36.38676342801562,"longitude":127.30874648673381,"bookYear":"2024","nameKR":"꾸드뱅","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":2886,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.707315,"longitude":128.037128,"bookYear":"2024","nameKR":"해사랑전복마을","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":2956,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.632894,"longitude":126.484912,"bookYear":"2024","nameKR":"죽림다원","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":5102,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.471487,"longitude":126.625066,"bookYear":"2024","nameKR":"프렌치빌","ribbonType":"1","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":33142,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe.rafik_official/","foodTypes":"디저트/차/베이커리","latitude":34.6951459330053,"longitude":127.777275070026,"bookYear":"2024","nameKR":"라피끄","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":5591,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.538944382285,"longitude":126.47032220586,"bookYear":"2024","nameKR":"이슬회수산","ribbonType":"1","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":5967,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.81341,"longitude":127.151444,"bookYear":"2024","nameKR":"베테랑칼국수","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":9214,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.151585,"longitude":128.659711,"bookYear":"2024","nameKR":"신생원","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":8118,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4561479304308,"longitude":126.602780672066,"bookYear":"2024","nameKR":"송원식당","ribbonType":"1","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":12902,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.462882,"longitude":126.938914,"bookYear":"2024","nameKR":"해녀의집","ribbonType":"1","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":1610,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.202632,"longitude":128.593632,"bookYear":"2024","nameKR":"단천식당","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":30585,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/snugroastery","foodTypes":"디저트/차/베이커리","latitude":37.4807350060176,"longitude":126.865332670084,"bookYear":"2024","nameKR":"스너그로스터리","ribbonType":"1","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":1887,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/eorang_mandu/","foodTypes":"한식(면류)","latitude":37.636282,"longitude":127.224156,"bookYear":"2024","nameKR":"어랑손만두국","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":34312,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/HALO_ROASTERS/","foodTypes":"디저트/차/베이커리","latitude":35.0215907762251,"longitude":126.793712219899,"bookYear":"2024","nameKR":"헤일로로스터스","ribbonType":"1","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":2126,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hwangnambbang/","foodTypes":"디저트/차/베이커리","latitude":35.840808,"longitude":129.213724,"bookYear":"2024","nameKR":"황남빵","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2913,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.995272,"longitude":128.681968,"bookYear":"2024","nameKR":"종점식당","ribbonType":"1","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":3100,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.794693,"longitude":129.205589,"bookYear":"2024","nameKR":"삼릉고향칼국수","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":3247,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.991892,"longitude":127.342966,"bookYear":"2024","nameKR":"장원식당","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":8187,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1639567195984,"longitude":128.984242057254,"bookYear":"2024","nameKR":"밀양국밥","ribbonType":"1","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":3654,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.027707,"longitude":127.278716,"bookYear":"2024","nameKR":"원조파주골손두부","ribbonType":"1","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":5932,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/daewondang_official","foodTypes":"디저트/차/베이커리","latitude":37.8573915985867,"longitude":127.740078455692,"bookYear":"2024","nameKR":"대원당","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":16899,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.939494,"longitude":128.717674,"bookYear":"2024","nameKR":"효진수산횟집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":1675,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/odaesan_gamasot/","foodTypes":"한식(일반한식)","latitude":37.7166585675027,"longitude":128.601879126683,"bookYear":"2024","nameKR":"오대산가마솥식당","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1677,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.057475,"longitude":128.682354,"bookYear":"2024","nameKR":"오산횟집","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":2164,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.83107,"longitude":128.573593,"bookYear":"2024","nameKR":"대덕식당","ribbonType":"1","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":2428,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.004711,"longitude":127.265605,"bookYear":"2024","nameKR":"벌교식당","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":2527,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.688648,"longitude":126.55875,"bookYear":"2024","nameKR":"변산온천산장","ribbonType":"1","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":2565,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.2430676050881,"longitude":126.567575944579,"bookYear":"2024","nameKR":"서귀포괸당네","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":2623,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.246601,"longitude":126.559163,"bookYear":"2024","nameKR":"진주식당","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":30973,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hautebakery_/ ","foodTypes":"디저트/차/베이커리","latitude":36.7220494955779,"longitude":127.542105655972,"bookYear":"2024","nameKR":"하우트커피컴퍼니","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":2771,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.786991,"longitude":128.893808,"bookYear":"2024","nameKR":"오시오숯불식육식당","ribbonType":"1","zone2_1":"경북","zone2_2":"봉화군","status":"ACTIVE"},
        {"id":31010,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1659324519479,"longitude":129.132411521392,"bookYear":"2024","nameKR":"커피프론트","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":27111,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.842083,"longitude":128.423513,"bookYear":"2024","nameKR":"원조밀물식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":4449,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.79512944189744,"longitude":127.16501522344355,"bookYear":"2024","nameKR":"광덕산호두과자전문점","ribbonType":"1","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":4701,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sgbgbok.gumi/","foodTypes":"한식(어패류)","latitude":36.129573,"longitude":128.331686,"bookYear":"2024","nameKR":"싱글벙글복어","ribbonType":"1","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":24938,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.998772,"longitude":127.18726,"bookYear":"2024","nameKR":"일일캠프닉타운","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":34769,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gentz_bakery/ ","foodTypes":"디저트/차/베이커리","latitude":35.1268954263812,"longitude":129.109627235385,"bookYear":"2024","nameKR":"겐츠베이커리본점","ribbonType":"1","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":10985,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.399273,"longitude":129.419327,"bookYear":"2024","nameKR":"태보회대게","ribbonType":"1","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":2584,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.5162850086573,"longitude":126.527472387247,"bookYear":"2024","nameKR":"산지물식당","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":27114,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/monsterroasters/","foodTypes":"디저트/차/베이커리","latitude":35.180326,"longitude":128.563695,"bookYear":"2024","nameKR":"몬스터로스터스","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":18728,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.174092,"longitude":128.068647,"bookYear":"2024","nameKR":"이동우커피","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":29656,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.83276516567481,"longitude":127.13569782835667,"bookYear":"2024","nameKR":"김판쇠전주우족탕","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":28501,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.215186,"longitude":127.217277,"bookYear":"2024","nameKR":"원조연산할머니순대","ribbonType":"1","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":6792,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.449175,"longitude":126.710864,"bookYear":"2024","nameKR":"안스베이커리","ribbonType":"1","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":17510,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.733055,"longitude":127.722048,"bookYear":"2024","nameKR":"청정게장촌","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":9370,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thewestinjosunbusan/","foodTypes":"기타","latitude":35.15596,"longitude":129.153982,"bookYear":"2024","nameKR":"까밀리아","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":1678,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.07764247636883,"longitude":128.44863288371988,"bookYear":"2024","nameKR":"오색식당","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":1700,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.206078,"longitude":128.519341,"bookYear":"2024","nameKR":"초당순두부집","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1707,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":38.06887761235847,"longitude":128.6240293827849,"bookYear":"2024","nameKR":"천선식당","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":1739,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.669874,"longitude":128.709862,"bookYear":"2024","nameKR":"황태회관","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":2055,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.843302,"longitude":128.423317,"bookYear":"2024","nameKR":"한일김밥","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":2302,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.160131,"longitude":129.166595,"bookYear":"2024","nameKR":"원조전복죽집","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":2338,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.440445,"longitude":126.457435,"bookYear":"2024","nameKR":"레스토랑8","ribbonType":"1","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":2585,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.5180229179837,"longitude":126.518645716694,"bookYear":"2024","nameKR":"산호전복","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":2732,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.8473111251175,"longitude":127.991064189566,"bookYear":"2024","nameKR":"투가리식당","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":2794,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.193357,"longitude":128.986144,"bookYear":"2024","nameKR":"할매재첩국","ribbonType":"1","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":29295,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/narnia.jeju/  ","foodTypes":"기타","latitude":33.236906874789,"longitude":126.372666735433,"bookYear":"2024","nameKR":"나니아레스토랑","ribbonType":"1","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":10679,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.693185,"longitude":128.889978,"bookYear":"2024","nameKR":"산동관","ribbonType":"1","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":9307,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.388092,"longitude":126.640783,"bookYear":"2024","nameKR":"레벨19","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":17647,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.343379,"longitude":126.985011,"bookYear":"2024","nameKR":"명가","ribbonType":"1","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":1630,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4392530146403,"longitude":129.191117081312,"bookYear":"2024","nameKR":"바다횟집","ribbonType":"1","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":1657,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.683963,"longitude":128.562668,"bookYear":"2024","nameKR":"성주식당","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":38767,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/songdocoffee/","foodTypes":"디저트/차/베이커리","latitude":37.3975857583899,"longitude":126.650666499516,"bookYear":"2024","nameKR":"송도커피","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":2378,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.608793,"longitude":126.417985,"bookYear":"2024","nameKR":"토가","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":2576,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.481795,"longitude":126.505228,"bookYear":"2024","nameKR":"모이세해장국","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":2714,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.53143887421377,"longitude":127.82206649804458,"bookYear":"2024","nameKR":"속리토속음식점","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":2868,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.335601239552645,"longitude":126.9656506849818,"bookYear":"2024","nameKR":"봉덕칼국수","ribbonType":"1","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":4307,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.46456,"longitude":126.709028,"bookYear":"2024","nameKR":"삼화정","ribbonType":"1","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":4606,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.2234752539049,"longitude":126.254186719523,"bookYear":"2024","nameKR":"산방식당","ribbonType":"1","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":12449,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.215129,"longitude":128.596129,"bookYear":"2024","nameKR":"이모네식당","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":27540,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.624662,"longitude":127.465825,"bookYear":"2024","nameKR":"개신동해장국","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":12750,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5110670495195,"longitude":127.449715164662,"bookYear":"2024","nameKR":"옥천냉면황해식당","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":1762,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.749548,"longitude":127.208623,"bookYear":"2024","nameKR":"광릉돌솥밥","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":1827,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.851081,"longitude":126.889391,"bookYear":"2024","nameKR":"초계탕","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":29092,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/animachef/ ","foodTypes":"이탈리아식","latitude":35.539776,"longitude":129.332509,"bookYear":"2024","nameKR":"아니마","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":2339,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/daesunjung40/","foodTypes":"한식(일반한식)","latitude":37.63973240343648,"longitude":126.49070426015393,"bookYear":"2024","nameKR":"대선정","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":2488,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/greenleafworld_official/","foodTypes":"디저트/차/베이커리","latitude":34.70662,"longitude":127.090509,"bookYear":"2024","nameKR":"초록잎이펼치는세상","ribbonType":"1","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":2752,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":38.031813,"longitude":127.366263,"bookYear":"2024","nameKR":"미미향","ribbonType":"1","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":34670,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.157037795554,"longitude":129.143328681551,"bookYear":"2024","nameKR":"스시이루카","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":4502,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.130321,"longitude":128.536395,"bookYear":"2024","nameKR":"강원토속식당","ribbonType":"1","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":32256,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lastspring_jeju/","foodTypes":"한식(일반한식)","latitude":33.5579832476993,"longitude":126.75128950923,"bookYear":"2024","nameKR":"넘은봄","ribbonType":"1","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":30704,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/Centro_Yangsan","foodTypes":"이탈리아식","latitude":35.3273990916233,"longitude":129.014274700329,"bookYear":"2024","nameKR":"첸트로","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":8915,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":38.024138598070145,"longitude":127.0697328494338,"bookYear":"2024","nameKR":"명신반점","ribbonType":"1","zone2_1":"경기","zone2_2":"연천군","status":"ACTIVE"},
        {"id":29954,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/vadada.jeju","foodTypes":"디저트/차/베이커리","latitude":33.2369212940733,"longitude":126.436719215087,"bookYear":"2024","nameKR":"바다다","ribbonType":"1","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":32963,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_yuja/","foodTypes":"디저트/차/베이커리","latitude":34.8193534482731,"longitude":128.036354840851,"bookYear":"2024","nameKR":"카페유자","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":13201,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.47789,"longitude":127.371932,"bookYear":"2024","nameKR":"흙토담골","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":30878,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chefmac.9/ ","foodTypes":"이탈리아식","latitude":37.6635816881275,"longitude":127.247449080772,"bookYear":"2024","nameKR":"쉐프맥나인","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":1629,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/migayeon_pyeongchang/","foodTypes":"한식(일반한식)","latitude":37.611995,"longitude":128.378317,"bookYear":"2024","nameKR":"봉평메밀미가연","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1664,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.137222,"longitude":128.59002,"bookYear":"2024","nameKR":"실로암메밀국수","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":29829,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.4386303395708,"longitude":126.918079816339,"bookYear":"2024","nameKR":"가시아방국수","ribbonType":"1","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":2109,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.841563,"longitude":129.250897,"bookYear":"2024","nameKR":"이조한정식","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":25883,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.703854,"longitude":129.016088,"bookYear":"2024","nameKR":"바다마을횟집","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":3657,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.945657,"longitude":128.328986,"bookYear":"2024","nameKR":"고향집","ribbonType":"1","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":13757,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.163874,"longitude":127.373618,"bookYear":"2024","nameKR":"백암식당","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":24292,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pg_4sisters","foodTypes":"한식(면류)","latitude":37.67419426196459,"longitude":127.5022358391344,"bookYear":"2024","nameKR":"네자매평강막국수","ribbonType":"1","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":28407,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.4985789993217,"longitude":126.459142607002,"bookYear":"2024","nameKR":"자매국수","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":35278,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.2684151426294,"longitude":129.148538005304,"bookYear":"2024","nameKR":"밤나무집","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":29336,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":33.4602975523449,"longitude":126.929448034221,"bookYear":"2024","nameKR":"샤오츠","ribbonType":"1","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":1624,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.79118686250141,"longitude":128.91463141780676,"bookYear":"2024","nameKR":"강릉짬뽕순두부동화가든본점","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":1711,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.79055465489145,"longitude":128.91539608215248,"bookYear":"2024","nameKR":"초당할머니순두부","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":1818,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.868249,"longitude":126.751795,"bookYear":"2024","nameKR":"반구정나루터집","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":2014,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.16746,"longitude":128.163221,"bookYear":"2024","nameKR":"문산제일염소불고기","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":3619,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.507156,"longitude":127.629957,"bookYear":"2024","nameKR":"고바우설렁탕","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":10773,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.093089,"longitude":128.819129,"bookYear":"2024","nameKR":"도선장횟집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":4100,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/il_heung_ok/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.98511,"longitude":126.709157,"bookYear":"2024","nameKR":"일흥옥","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":4467,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.206278,"longitude":128.527343,"bookYear":"2024","nameKR":"원조재래식할머니순두부","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":4498,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.612663,"longitude":128.371399,"bookYear":"2024","nameKR":"고향막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":4587,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":34.841325,"longitude":128.422666,"bookYear":"2024","nameKR":"수향","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":26069,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.679929802055796,"longitude":129.46444013638737,"bookYear":"2024","nameKR":"동심식당","ribbonType":"1","zone2_1":"경북","zone2_2":"울진군","status":"ACTIVE"},
        {"id":26646,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3478512753013,"longitude":127.348481197263,"bookYear":"2024","nameKR":"구일가든","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":34597,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4494535127144,"longitude":128.077562799449,"bookYear":"2024","nameKR":"새말토종순대","ribbonType":"1","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":37598,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.1371003837242,"longitude":128.234070609758,"bookYear":"2024","nameKR":"청정골산채전문식당","ribbonType":"1","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":29355,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/love_tesoro719","foodTypes":"디저트/차/베이커리","latitude":37.403941,"longitude":127.116242,"bookYear":"2024","nameKR":"데조로의집","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":2420,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.638901,"longitude":126.767311,"bookYear":"2024","nameKR":"명동식당","ribbonType":"1","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":2503,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.639241780994915,"longitude":126.76718208715526,"bookYear":"2024","nameKR":"해태식당","ribbonType":"1","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":2914,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8727053579291,"longitude":128.525423186212,"bookYear":"2024","nameKR":"서부불고기","ribbonType":"1","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":3207,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.113474,"longitude":129.037627,"bookYear":"2024","nameKR":"중남해","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":32962,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ain_river/","foodTypes":"디저트/차/베이커리","latitude":34.771136304703,"longitude":127.88742823603,"bookYear":"2024","nameKR":"앵강마켓","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":4013,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.604264,"longitude":126.468712,"bookYear":"2024","nameKR":"신용횟집","ribbonType":"1","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":33500,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bistroswoo/","foodTypes":"프랑스식","latitude":33.5077235198634,"longitude":126.542123053771,"bookYear":"2024","nameKR":"가스트로관수","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":19827,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8452553556477,"longitude":128.617305191539,"bookYear":"2024","nameKR":"후포회수산","ribbonType":"1","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":14170,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.6349099443474,"longitude":126.767611566751,"bookYear":"2024","nameKR":"강진만한정식","ribbonType":"1","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":28364,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_namyang","foodTypes":"한식(어패류)","latitude":33.450105,"longitude":126.914186,"bookYear":"2024","nameKR":"남양수산","ribbonType":"1","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":1690,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yucheonmakguksu/","foodTypes":"한식(면류)","latitude":37.670621794404454,"longitude":128.60059332821967,"bookYear":"2024","nameKR":"유천막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":29612,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/junchul_chef/","foodTypes":"프랑스식","latitude":37.3937818483729,"longitude":126.633289319826,"bookYear":"2024","nameKR":"제이라운지","ribbonType":"1","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":2437,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.19139,"longitude":127.37511,"bookYear":"2024","nameKR":"새수궁가든","ribbonType":"1","zone2_1":"전남","zone2_2":"곡성군","status":"ACTIVE"},
        {"id":3595,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.73912120370822,"longitude":127.92479222065566,"bookYear":"2024","nameKR":"삼산이수","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"거창군","status":"ACTIVE"},
        {"id":25512,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.512449,"longitude":128.214003,"bookYear":"2024","nameKR":"둔내막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":16142,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":33.5118229358576,"longitude":126.525017285831,"bookYear":"2024","nameKR":"골목식당","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":6246,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jinudong/","foodTypes":"일식","latitude":37.386559,"longitude":127.122112,"bookYear":"2024","nameKR":"진우동","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":2063,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.483426,"longitude":130.906659,"bookYear":"2024","nameKR":"99식당","ribbonType":"1","zone2_1":"경북","zone2_2":"울릉군","status":"ACTIVE"},
        {"id":31883,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.9190060911906,"longitude":128.717778541051,"bookYear":"2024","nameKR":"새밭골농장","ribbonType":"1","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":2611,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.5180494497425,"longitude":126.518977092821,"bookYear":"2024","nameKR":"유빈","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":2743,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":38.093941,"longitude":127.760084,"bookYear":"2024","nameKR":"화천어죽탕","ribbonType":"1","zone2_1":"강원","zone2_2":"화천군","status":"ACTIVE"},
        {"id":8933,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.426637608829054,"longitude":128.3925949162694,"bookYear":"2024","nameKR":"방림메밀막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":28963,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/eatalykorea ","foodTypes":"이탈리아식","latitude":37.392793537537784,"longitude":127.11208816175997,"bookYear":"2024","nameKR":"이탈리","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":5328,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.19193,"longitude":128.59599,"bookYear":"2024","nameKR":"송원면옥","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":30455,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.552089283506376,"longitude":128.74157845557022,"bookYear":"2024","nameKR":"예미정","ribbonType":"1","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":1645,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.214267,"longitude":128.596916,"bookYear":"2024","nameKR":"사돈집","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1703,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.619067735065755,"longitude":128.37680016371826,"bookYear":"2024","nameKR":"봉평메밀진미식당","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1728,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.20195,"longitude":128.589812,"bookYear":"2024","nameKR":"함흥냉면옥","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1734,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.755007,"longitude":128.865492,"bookYear":"2024","nameKR":"형제막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":1929,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/king_imgeum/","foodTypes":"한식(일반한식)","latitude":37.306306,"longitude":127.403477,"bookYear":"2024","nameKR":"임금님쌀밥집","ribbonType":"1","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":2078,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.851561,"longitude":129.262836,"bookYear":"2024","nameKR":"맷돌순두부","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2564,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.266381,"longitude":126.6427,"bookYear":"2024","nameKR":"공천포식당","ribbonType":"1","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":27543,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.201665,"longitude":128.534899,"bookYear":"2024","nameKR":"미가","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":4121,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.4049604567374,"longitude":126.257820958071,"bookYear":"2024","nameKR":"옹포별장가든","ribbonType":"1","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":16145,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.5085320014821,"longitude":126.540741186743,"bookYear":"2024","nameKR":"은희네해장국","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":5421,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.151156,"longitude":128.188519,"bookYear":"2024","nameKR":"대보명가","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":5477,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.589295,"longitude":127.507566,"bookYear":"2024","nameKR":"효자촌묵집","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":30069,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.07058487253968,"longitude":128.62798706444605,"bookYear":"2024","nameKR":"감나무식당","ribbonType":"1","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":18621,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yyfetofo/","foodTypes":"한식(일반한식)","latitude":36.5585627673662,"longitude":129.12685828614738,"bookYear":"2024","nameKR":"음식디미방","ribbonType":"1","zone2_1":"경북","zone2_2":"영양군","status":"ACTIVE"},
        {"id":29695,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/masimi_roasters/","foodTypes":"디저트/차/베이커리","latitude":35.55410683108133,"longitude":129.2598833189175,"bookYear":"2024","nameKR":"마시미로스터스 ","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":29741,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.75118466230538,"longitude":128.89600071390228,"bookYear":"2024","nameKR":"통일집","ribbonType":"1","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":2090,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.840676,"longitude":129.195747,"bookYear":"2024","nameKR":"석하한정식","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2272,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1604045038317,"longitude":129.158921337521,"bookYear":"2024","nameKR":"미나미","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":2775,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.845276,"longitude":128.613156,"bookYear":"2024","nameKR":"연경반점","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":2809,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.373148,"longitude":127.143075,"bookYear":"2024","nameKR":"새집","ribbonType":"1","zone2_1":"전북","zone2_2":"순창군","status":"ACTIVE"},
        {"id":8185,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.220611,"longitude":129.081507,"bookYear":"2024","nameKR":"원조소문난산곰장어","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":3113,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.141584,"longitude":129.061979,"bookYear":"2024","nameKR":"마산식당","ribbonType":"1","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":10973,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.179113,"longitude":128.109509,"bookYear":"2024","nameKR":"송화한정식","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":4255,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.160881,"longitude":129.167009,"bookYear":"2024","nameKR":"예이제","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":4605,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.5072242643657,"longitude":126.525812998066,"bookYear":"2024","nameKR":"대광식당","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":6130,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/daegu_jongro/","foodTypes":"한식(육류)","latitude":35.868188,"longitude":128.592225,"bookYear":"2024","nameKR":"종로숯불갈비","ribbonType":"1","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":13118,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.317132,"longitude":127.970143,"bookYear":"2024","nameKR":"엄나무집삼계탕","ribbonType":"1","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":2751,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.205778,"longitude":127.543573,"bookYear":"2024","nameKR":"만우정육점생고기식당","ribbonType":"1","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":3042,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.381807,"longitude":126.830523,"bookYear":"2024","nameKR":"들꽃향","ribbonType":"1","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":3364,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.368353,"longitude":127.381064,"bookYear":"2024","nameKR":"천년의정원","ribbonType":"1","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":4141,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.483483856649286,"longitude":127.41103004721222,"bookYear":"2024","nameKR":"참좋은생각","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":4366,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.281487,"longitude":127.017367,"bookYear":"2024","nameKR":"수원만두","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6962,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":37.453321,"longitude":127.002682,"bookYear":"2024","nameKR":"엘올리보","ribbonType":"1","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":2222,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.365596,"longitude":127.336028,"bookYear":"2024","nameKR":"바질리코","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":2670,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.891904,"longitude":126.625338,"bookYear":"2024","nameKR":"제일꽃게장","ribbonType":"1","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":2923,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.027175,"longitude":129.362453,"bookYear":"2024","nameKR":"만포갈비","ribbonType":"1","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":4588,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.833589,"longitude":128.410849,"bookYear":"2024","nameKR":"해원횟집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":11606,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.489423,"longitude":126.756409,"bookYear":"2024","nameKR":"강원한방토종삼계탕","ribbonType":"1","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":11202,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":0,"longitude":0,"bookYear":"2024","nameKR":"거북선횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":10968,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8256980608763,"longitude":129.237305556377,"bookYear":"2024","nameKR":"수석정","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":1800,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.532074,"longitude":127.217195,"bookYear":"2024","nameKR":"마방집","ribbonType":"1","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":1945,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.794532,"longitude":127.233365,"bookYear":"2024","nameKR":"참나무쟁이","ribbonType":"1","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":2513,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.850056,"longitude":127.119427,"bookYear":"2024","nameKR":"고궁","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2844,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3138071989344,"longitude":126.915006165321,"bookYear":"2024","nameKR":"둥근상시골집","ribbonType":"1","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":2892,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.26129939646334,"longitude":128.6178576033322,"bookYear":"2024","nameKR":"임진각식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":2963,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.6413434221397,"longitude":126.770148238551,"bookYear":"2024","nameKR":"둥지식당","ribbonType":"1","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":4045,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.187168,"longitude":126.920503,"bookYear":"2024","nameKR":"석암돌솥밥","ribbonType":"1","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":5330,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yaejeon_story/","foodTypes":"한식(일반한식)","latitude":37.464302,"longitude":127.325932,"bookYear":"2024","nameKR":"예전한정식","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":13054,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.3200304725997,"longitude":126.981680019187,"bookYear":"2024","nameKR":"한우명가","ribbonType":"1","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":1603,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.1585405895507,"longitude":128.987939447956,"bookYear":"2024","nameKR":"너와집","ribbonType":"1","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":1846,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3531829701441,"longitude":127.045723006646,"bookYear":"2024","nameKR":"산사랑","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":2058,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.04161,"longitude":128.039986,"bookYear":"2024","nameKR":"해원장횟집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":27034,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.793853,"longitude":127.137665,"bookYear":"2024","nameKR":"꽃밭정이","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2641,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.600298,"longitude":126.663738,"bookYear":"2024","nameKR":"내당한우","ribbonType":"1","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":2808,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.81486,"longitude":127.108359,"bookYear":"2024","nameKR":"반야돌솥밥","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":4331,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.22151451328498,"longitude":127.36793816178398,"bookYear":"2024","nameKR":"강민주의 들밥","ribbonType":"1","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":4393,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.930495,"longitude":127.05697,"bookYear":"2024","nameKR":"유정부대찌개","ribbonType":"1","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":4439,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.811907,"longitude":127.160007,"bookYear":"2024","nameKR":"한벽집","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":5549,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.2091962077771,"longitude":128.574366313759,"bookYear":"2024","nameKR":"백제령","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":25168,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.889885,"longitude":127.095333,"bookYear":"2024","nameKR":"매초약선","ribbonType":"1","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":8997,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.958491,"longitude":129.40343,"bookYear":"2024","nameKR":"삼육식당","ribbonType":"1","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":1646,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.619013,"longitude":128.725956,"bookYear":"2024","nameKR":"산골식당","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1789,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.963275,"longitude":126.893778,"bookYear":"2024","nameKR":"두지리강촌매운탕","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":1944,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.718258,"longitude":126.975108,"bookYear":"2024","nameKR":"진흥관","ribbonType":"1","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":1967,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.991943,"longitude":127.087491,"bookYear":"2024","nameKR":"파주옥","ribbonType":"1","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":2501,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.333683,"longitude":126.964616,"bookYear":"2024","nameKR":"한상근대통밥집","ribbonType":"1","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":2510,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.816999,"longitude":127.145958,"bookYear":"2024","nameKR":"가족회관","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2981,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.823430224109224,"longitude":127.14913857398763,"bookYear":"2024","nameKR":"백송회관","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":4119,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.685262,"longitude":128.741122,"bookYear":"2024","nameKR":"남경식당","ribbonType":"1","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":4297,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.681182,"longitude":127.209479,"bookYear":"2024","nameKR":"삼대째손두부","ribbonType":"1","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":12592,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7203298610875,"longitude":126.982111943242,"bookYear":"2024","nameKR":"평양면옥","ribbonType":"1","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":1832,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.329752,"longitude":127.648997,"bookYear":"2024","nameKR":"보배네집","ribbonType":"1","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":2805,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.977259,"longitude":127.5855,"bookYear":"2024","nameKR":"한국식당","ribbonType":"1","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":2872,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.29449,"longitude":127.418517,"bookYear":"2024","nameKR":"관촌순두부","ribbonType":"1","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":3372,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.926404,"longitude":129.289098,"bookYear":"2024","nameKR":"고향숯불갈비","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":4392,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.860271,"longitude":127.276886,"bookYear":"2024","nameKR":"명덕잣나무집","ribbonType":"1","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":4427,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.064398,"longitude":126.523356,"bookYear":"2024","nameKR":"화랑식당","ribbonType":"1","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":10164,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":34.746333,"longitude":126.709255,"bookYear":"2024","nameKR":"경포대산장","ribbonType":"1","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":5366,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.250822,"longitude":127.075445,"bookYear":"2024","nameKR":"두꺼비집부대찌개","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":26970,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.49245173928852,"longitude":129.30614419299621,"bookYear":"2024","nameKR":"효정밥상","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":6196,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.236114,"longitude":128.687226,"bookYear":"2024","nameKR":"언양각식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":2386,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.355515,"longitude":126.449917,"bookYear":"2024","nameKR":"007식당","ribbonType":"1","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":2447,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.321977,"longitude":126.982369,"bookYear":"2024","nameKR":"승일식당","ribbonType":"1","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":2492,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":34.73913,"longitude":127.730709,"bookYear":"2024","nameKR":"칠공주장어탕","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":2601,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.490943605233,"longitude":126.496185900658,"bookYear":"2024","nameKR":"어장군","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":2899,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.841001,"longitude":128.421885,"bookYear":"2024","nameKR":"터미널회식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":4016,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.324087,"longitude":126.984274,"bookYear":"2024","nameKR":"진우네집국수","ribbonType":"1","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":4383,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.24512,"longitude":127.651201,"bookYear":"2024","nameKR":"시골맛집","ribbonType":"1","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":4756,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.029546,"longitude":127.048777,"bookYear":"2024","nameKR":"달맞이흑두부","ribbonType":"1","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":5410,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.513718,"longitude":126.341976,"bookYear":"2024","nameKR":"송정꽃게집","ribbonType":"1","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":8147,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.481032,"longitude":126.773107,"bookYear":"2024","nameKR":"인하찹쌀순대","ribbonType":"1","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":12887,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.508064,"longitude":126.532253,"bookYear":"2024","nameKR":"국수마당","ribbonType":"1","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":2003,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.007283,"longitude":127.787028,"bookYear":"2024","nameKR":"원조강변할매재첩식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":2010,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.1897149322196,"longitude":127.623007231241,"bookYear":"2024","nameKR":"동백식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":2561,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.843059,"longitude":127.270404,"bookYear":"2024","nameKR":"원조화심두부","ribbonType":"1","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":2861,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.545725,"longitude":127.583343,"bookYear":"2024","nameKR":"용문산중앙식당","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":2973,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.733785,"longitude":127.720768,"bookYear":"2024","nameKR":"두꺼비게장","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":3036,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.4306035699418,"longitude":126.73902156484,"bookYear":"2024","nameKR":"본가","ribbonType":"1","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":3085,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.137776,"longitude":129.105916,"bookYear":"2024","nameKR":"초원복국","ribbonType":"1","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":35220,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7808378255289,"longitude":126.377119387269,"bookYear":"2024","nameKR":"조선쫄복탕","ribbonType":"1","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":27121,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/180coffeeroasters_official/ ","foodTypes":"디저트/차/베이커리","latitude":37.382513,"longitude":127.151627,"bookYear":"2024","nameKR":"180커피로스터스","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":9489,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.590425,"longitude":126.60485,"bookYear":"2024","nameKR":"칠산꽃게장","ribbonType":"1","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":6078,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.139607,"longitude":129.108783,"bookYear":"2024","nameKR":"옵스(남천점)","ribbonType":"1","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":12709,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.401239,"longitude":127.110502,"bookYear":"2024","nameKR":"알레그리아(판교점)","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":13093,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.260716,"longitude":129.091198,"bookYear":"2024","nameKR":"이랴이랴(남산점)","ribbonType":"1","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":1880,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.0076740444248,"longitude":127.272894839101,"bookYear":"2024","nameKR":"안일옥","ribbonType":"1","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":2590,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.4345196505906,"longitude":126.926028514975,"bookYear":"2024","nameKR":"섭지해녀의집","ribbonType":"1","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":2595,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.477962,"longitude":126.90844,"bookYear":"2024","nameKR":"시흥해녀의집","ribbonType":"1","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":2834,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.837999,"longitude":128.013975,"bookYear":"2024","nameKR":"가리산막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":2985,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.245413,"longitude":126.561332,"bookYear":"2024","nameKR":"수희식당","ribbonType":"1","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":3803,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.4853183836848,"longitude":126.499924103522,"bookYear":"2024","nameKR":"앞뱅디식당","ribbonType":"1","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":30518,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.83055372265005,"longitude":128.61615356287723,"bookYear":"2024","nameKR":"용지봉","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":4026,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.011071,"longitude":127.383946,"bookYear":"2024","nameKR":"진일기사식당","ribbonType":"1","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":4101,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.984826,"longitude":126.708948,"bookYear":"2024","nameKR":"일해옥","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":4955,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.78301,"longitude":126.688811,"bookYear":"2024","nameKR":"통일동산두부마을","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":6125,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.158744,"longitude":129.170767,"bookYear":"2024","nameKR":"속씨원한대구탕","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":13024,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3787674159352,"longitude":126.985154040533,"bookYear":"2024","nameKR":"일출보리밥","ribbonType":"1","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":27900,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bigtreehouse_official/ ","foodTypes":"한식(가금류)","latitude":35.733597,"longitude":128.659536,"bookYear":"2024","nameKR":"큰나무집","ribbonType":"1","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":17906,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yangposea/","foodTypes":"한식(어패류)","latitude":35.842761,"longitude":128.616258,"bookYear":"2024","nameKR":"양포수산","ribbonType":"1","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":1754,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.440992,"longitude":127.010345,"bookYear":"2024","nameKR":"경마장오리집","ribbonType":"1","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":1757,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.275018,"longitude":127.002971,"bookYear":"2024","nameKR":"고등반점","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":1802,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.386237,"longitude":127.122389,"bookYear":"2024","nameKR":"만강홍","ribbonType":"1","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":1829,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.667363,"longitude":126.767046,"bookYear":"2024","nameKR":"베이징코야","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":29677,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.833035134097635,"longitude":127.10350470989484,"bookYear":"2024","nameKR":"흑두부이야기","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":28396,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.197209,"longitude":128.083709,"bookYear":"2024","nameKR":"천수식당","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":4075,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/amisanhaeundae/","foodTypes":"중식","latitude":35.157964,"longitude":129.148518,"bookYear":"2024","nameKR":"아미산","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":6058,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.4977,"longitude":126.778192,"bookYear":"2024","nameKR":"나리스키친","ribbonType":"1","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":6860,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.10677,"longitude":129.1115,"bookYear":"2024","nameKR":"오륙도가원","ribbonType":"1","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":1860,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.306218,"longitude":127.000671,"bookYear":"2024","nameKR":"송풍가든","ribbonType":"1","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":1917,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/uprone_1999/","foodTypes":"한식(육류)","latitude":37.3367561018155,"longitude":127.1730289056,"bookYear":"2024","nameKR":"유프로네","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":31536,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/god_100_sook_official/ ","foodTypes":"한식(가금류)","latitude":35.8531192251442,"longitude":128.505159362715,"bookYear":"2024","nameKR":"성서제1능이버섯능이백숙","ribbonType":"1","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":31571,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.8666618742878,"longitude":126.753266235102,"bookYear":"2024","nameKR":"반구정임진강나루","ribbonType":"1","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":4456,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.761888646239,"longitude":126.453579124299,"bookYear":"2024","nameKR":"안흥일품꽃게장","ribbonType":"1","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":1839,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.226102,"longitude":127.107453,"bookYear":"2024","nameKR":"사또가든","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":1850,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.903484,"longitude":127.051357,"bookYear":"2024","nameKR":"생연칼국수삼계탕","ribbonType":"1","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":2646,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.672217,"longitude":126.274077,"bookYear":"2024","nameKR":"몽대횟집","ribbonType":"1","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":2650,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.7632772553223,"longitude":126.496126315668,"bookYear":"2024","nameKR":"반도회관","ribbonType":"1","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":3132,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.387316,"longitude":127.406447,"bookYear":"2024","nameKR":"건업리보리밥","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":3320,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.713603,"longitude":126.391927,"bookYear":"2024","nameKR":"원조부석냉면","ribbonType":"1","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":4979,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.192007967164905,"longitude":128.540451738685,"bookYear":"2024","nameKR":"섭죽마을","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":6805,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/toujours_bakery/","foodTypes":"디저트/차/베이커리","latitude":36.818342,"longitude":127.139607,"bookYear":"2024","nameKR":"뚜쥬루과자점","ribbonType":"1","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":12537,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.6394576027749,"longitude":127.431411478437,"bookYear":"2024","nameKR":"바누아투과자점","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":13364,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4257773062508,"longitude":127.537834125285,"bookYear":"2024","nameKR":"개군할머니토종순대국","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":1633,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.872076,"longitude":127.724133,"bookYear":"2024","nameKR":"별당막국수","ribbonType":"1","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":3181,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.958034,"longitude":126.740416,"bookYear":"2024","nameKR":"압강옥","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":12039,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.289818,"longitude":127.506064,"bookYear":"2024","nameKR":"쌍용해장국","ribbonType":"1","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":4271,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.34643,"longitude":127.290973,"bookYear":"2024","nameKR":"흑룡산촌두부","ribbonType":"1","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":4320,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.465024,"longitude":127.527817,"bookYear":"2024","nameKR":"양평신내서울해장국본점","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":4480,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.171951,"longitude":128.991605,"bookYear":"2024","nameKR":"태백한우마을","ribbonType":"1","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":27950,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.987413,"longitude":126.708202,"bookYear":"2024","nameKR":"한일옥","ribbonType":"1","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":34744,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1844997475583,"longitude":129.211331443199,"bookYear":"2024","nameKR":"동남횟집","ribbonType":"1","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":12587,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.391651,"longitude":127.285134,"bookYear":"2024","nameKR":"쌍령해장국","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":32388,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.2007214353398,"longitude":128.53323455988,"bookYear":"2024","nameKR":"두메산골","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1826,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ilovemegi/","foodTypes":"한식(민물어패류)","latitude":37.659803,"longitude":126.72505,"bookYear":"2024","nameKR":"메기일번지","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":1989,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.385934,"longitude":127.406676,"bookYear":"2024","nameKR":"향촌건업리묵집","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":2393,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.738732,"longitude":127.732013,"bookYear":"2024","nameKR":"구백식당","ribbonType":"1","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":2821,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.553665230496,"longitude":126.70988954878,"bookYear":"2024","nameKR":"해녀촌","ribbonType":"1","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":3274,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.570673,"longitude":129.349518,"bookYear":"2024","nameKR":"금강장어","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":3593,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.709846,"longitude":129.202463,"bookYear":"2024","nameKR":"만복래숯불구이","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":4629,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.681578,"longitude":127.877364,"bookYear":"2024","nameKR":"늘푸름한우사랑","ribbonType":"1","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":5512,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.100637,"longitude":128.981795,"bookYear":"2024","nameKR":"해주냉면","ribbonType":"1","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":5558,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.086619,"longitude":128.086097,"bookYear":"2024","nameKR":"재건냉면집","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":5923,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6773776561521,"longitude":127.48724539953,"bookYear":"2024","nameKR":"방일해장국","ribbonType":"1","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":7127,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.823041,"longitude":129.186373,"bookYear":"2024","nameKR":"연화바루","ribbonType":"1","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":1712,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/grandma_okran/","foodTypes":"한식(일반한식)","latitude":38.196857,"longitude":128.536586,"bookYear":"2024","nameKR":"최옥란할머니순두부","ribbonType":"1","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":2353,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.721607,"longitude":126.513266,"bookYear":"2024","nameKR":"원조선창집장어구이","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":2374,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.7046601088169,"longitude":126.391045725067,"bookYear":"2024","nameKR":"충남서산집","ribbonType":"1","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":2591,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":33.437705,"longitude":126.676869,"bookYear":"2024","nameKR":"성미가든","ribbonType":"1","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":3607,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.3590448257953,"longitude":126.444407537798,"bookYear":"2024","nameKR":"동원정","ribbonType":"1","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":11181,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.221872,"longitude":129.081798,"bookYear":"2024","nameKR":"금문","ribbonType":"1","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":4639,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.158777,"longitude":129.171829,"bookYear":"2024","nameKR":"아저씨대구탕","ribbonType":"1","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":6356,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.4013567084675,"longitude":127.37707452536658,"bookYear":"2024","nameKR":"현식당","ribbonType":"1","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":8907,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.465696,"longitude":127.528188,"bookYear":"2024","nameKR":"신내강호해장국","ribbonType":"1","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":28578,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":34.540709,"longitude":126.61265,"bookYear":"2024","nameKR":"원조장수통닭","ribbonType":"1","zone2_1":"전남","zone2_2":"해남군","status":"ACTIVE"},
        {"id":33114,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/midang_myunok/","foodTypes":"한식(면류)","latitude":36.8061559177559,"longitude":126.739989170003,"bookYear":"2024","nameKR":"미당면옥","ribbonType":"1","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":9028,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.5856031723623,"longitude":126.858329509657,"bookYear":"2024","nameKR":"신가네정읍국밥","ribbonType":"1","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":34807,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.0709802454456,"longitude":129.016122498804,"bookYear":"2024","nameKR":"빈스톡","ribbonType":"1","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":34778,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1006950852985,"longitude":129.036117527715,"bookYear":"2024","nameKR":"뚱보집","ribbonType":"1","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":1783,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.78644,"longitude":127.30017,"bookYear":"2024","nameKR":"산장대통령","ribbonType":"1","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":1928,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.670477,"longitude":126.786049,"bookYear":"2024","nameKR":"일산칼국수","ribbonType":"1","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":2711,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.042765,"longitude":128.502564,"bookYear":"2024","nameKR":"성골촌","ribbonType":"1","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":2882,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.850112,"longitude":128.585605,"bookYear":"2024","nameKR":"거제도굴구이","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":5027,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.03149812975411,"longitude":126.71670066190049,"bookYear":"2024","nameKR":"60년전통남평할매집","ribbonType":"1","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":6171,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.009903,"longitude":127.269488,"bookYear":"2024","nameKR":"장안면옥","ribbonType":"1","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":11330,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.13878,"longitude":129.062,"bookYear":"2024","nameKR":"석화한정식","ribbonType":"1","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":10739,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.16488237454452,"longitude":128.12728082498666,"bookYear":"2024","nameKR":"설야진주전통비빔밥전문점","ribbonType":"1","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":32670,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/padelladipapa","foodTypes":"이탈리아식","latitude":37.2908630029837,"longitude":127.14054437491,"bookYear":"2024","nameKR":"빠델라디파파","ribbonType":"1","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":32221,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/patisserie.aube/","foodTypes":"디저트/차/베이커리","latitude":35.5416106558647,"longitude":129.333217865841,"bookYear":"2024","nameKR":"파티세리오브","ribbonType":"1","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":28382,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gwang_coffeeroastery/","foodTypes":"디저트/차/베이커리","latitude":35.809641,"longitude":127.152675,"bookYear":"2024","nameKR":"광커피로스터리","ribbonType":"1","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":33399,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/caffeine_sinhyeonri/","foodTypes":"기타","latitude":37.3783105090253,"longitude":127.164280017606,"bookYear":"2024","nameKR":"카페인신현리포레스트","ribbonType":"1","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":30720,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kimssibooin_korean_dessert/","foodTypes":"디저트/차/베이커리","latitude":37.4962577972563,"longitude":126.997750985843,"bookYear":"2024","nameKR":"김씨부인","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":32730,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/london.bagel.museum/","foodTypes":"디저트/차/베이커리","latitude":37.579170128664,"longitude":126.986202735182,"bookYear":"2024","nameKR":"런던베이글뮤지엄","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":9142,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.53941,"longitude":126.9875,"bookYear":"2024","nameKR":"더베이커스테이블","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":36348,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gobangchae/","foodTypes":"바/주점","latitude":37.5202190104628,"longitude":126.929509648217,"bookYear":"2024","nameKR":"고방채","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":32623,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nothincoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5610596604605,"longitude":127.001145287116,"bookYear":"2024","nameKR":"노띵커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":36071,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/saamann.coffee.roasters","foodTypes":"디저트/차/베이커리","latitude":37.589502138993,"longitude":127.005429926687,"bookYear":"2024","nameKR":"사만커피로스터스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":30039,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe.onion","foodTypes":"디저트/차/베이커리","latitude":37.57738681090379,"longitude":126.98637445896094,"bookYear":"2024","nameKR":"어니언(안국점)","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":19547,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5627141090474,"longitude":126.926397127655,"bookYear":"2024","nameKR":"시실리","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31039,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5458116617778,"longitude":126.945299948138,"bookYear":"2024","nameKR":"오롯","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29689,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/leesarcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.552472790258584,"longitude":127.01042511544821,"bookYear":"2024","nameKR":"리사르커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":31959,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kiheicoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5118320645968,"longitude":127.053194780969,"bookYear":"2024","nameKR":"키헤이커피","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":29715,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/adoreofficial","foodTypes":"디저트/차/베이커리","latitude":37.512809780779634,"longitude":127.0450942735762,"bookYear":"2024","nameKR":"아도르","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":34619,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5389676740777,"longitude":126.989128810378,"bookYear":"2024","nameKR":"남산와이너리","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":28986,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafedeweet_patisserie/","foodTypes":"디저트/차/베이커리","latitude":37.5003637668422,"longitude":126.92671225982,"bookYear":"2024","nameKR":"듀윗","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":34275,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/atelier.pond/","foodTypes":"디저트/차/베이커리","latitude":37.5368027102701,"longitude":127.000015614034,"bookYear":"2024","nameKR":"아뜰리에폰드","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33623,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nyang_jima/","foodTypes":"바/주점","latitude":37.5146817750863,"longitude":126.890650682526,"bookYear":"2024","nameKR":"냐옹지마","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":5041,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.50203,"longitude":127.0262,"bookYear":"2024","nameKR":"아이리스펍더블린","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":31895,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/poongryu_official/","foodTypes":"디저트/차/베이커리","latitude":37.5802745815836,"longitude":126.967456726676,"bookYear":"2024","nameKR":"풍류","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31166,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/patisserie_sona/","foodTypes":"디저트/차/베이커리","latitude":37.5497060138632,"longitude":126.866308526225,"bookYear":"2024","nameKR":"파티세리소나","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":29988,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5764581852171,"longitude":126.9721721367,"bookYear":"2024","nameKR":"안주마을","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":28520,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/enterheartscoffee/","foodTypes":"디저트/차/베이커리","latitude":37.490984,"longitude":126.984195,"bookYear":"2024","nameKR":"엔터하츠","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":31148,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gray_gristmill/","foodTypes":"디저트/차/베이커리","latitude":37.5207186841862,"longitude":127.019955768192,"bookYear":"2024","nameKR":"그레이그리스트밀","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":671,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5346871460213,"longitude":126.998136551597,"bookYear":"2024","nameKR":"올댓재즈","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30496,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/patisserie.jaein","foodTypes":"디저트/차/베이커리","latitude":37.5363402198792,"longitude":127.001269028454,"bookYear":"2024","nameKR":"재인","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33110,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bbb_hannam","foodTypes":"디저트/차/베이커리","latitude":37.5364064454549,"longitude":127.001062993302,"bookYear":"2024","nameKR":"베베베","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31186,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/le.peche.mignon_","foodTypes":"디저트/차/베이커리","latitude":37.5352850570059,"longitude":126.987719358376,"bookYear":"2024","nameKR":"르페셰미뇽","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":3617,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.53875,"longitude":127.002,"bookYear":"2024","nameKR":"패션5","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":22439,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.53031,"longitude":126.997,"bookYear":"2024","nameKR":"헬카페로스터즈","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":24893,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thebooth_gyeonglidan/","foodTypes":"바/주점","latitude":37.53936,"longitude":126.9877,"bookYear":"2024","nameKR":"더부스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":9118,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.53941,"longitude":126.9875,"bookYear":"2024","nameKR":"맥파이브루샵","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33501,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mokdangaok/","foodTypes":"디저트/차/베이커리","latitude":37.5360217198748,"longitude":127.000857518344,"bookYear":"2024","nameKR":"목단가옥","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":199,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5621320478482,"longitude":126.982238910434,"bookYear":"2024","nameKR":"도향촌","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":32261,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/the_spot_fabulous_/","foodTypes":"디저트/차/베이커리","latitude":37.5623328436628,"longitude":126.982600032183,"bookYear":"2024","nameKR":"더스팟패뷸러스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":5689,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.51796359991885,"longitude":126.98232664293936,"bookYear":"2024","nameKR":"동빙고","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":22364,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.52018,"longitude":126.96768,"bookYear":"2024","nameKR":"브레드05","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":31150,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5462699792267,"longitude":126.912617576909,"bookYear":"2024","nameKR":"파스텔커피웍스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32720,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5460993018096,"longitude":126.91257941517,"bookYear":"2024","nameKR":"커넥츠커피(합정점)","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":30565,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/eqbmcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5403541616654,"longitude":127.067833632739,"bookYear":"2024","nameKR":"더이퀼리브리엄커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":33447,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/barista_dash/","foodTypes":"디저트/차/베이커리","latitude":37.5582513703731,"longitude":127.073477495613,"bookYear":"2024","nameKR":"로프커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":33620,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/biscuit_floor/","foodTypes":"디저트/차/베이커리","latitude":37.6251019326524,"longitude":127.073724834712,"bookYear":"2024","nameKR":"비스킷플로어","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":32162,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bar_pourpourpour","foodTypes":"바/주점","latitude":37.5541781812656,"longitude":126.966321990807,"bookYear":"2024","nameKR":"포어포어포어","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":32929,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tigre.seoul/","foodTypes":"디저트/차/베이커리","latitude":37.6200041126766,"longitude":126.916869408565,"bookYear":"2024","nameKR":"티그레서울","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":22710,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.488809874587,"longitude":126.994502515981,"bookYear":"2024","nameKR":"파이브브루잉","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":32804,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/knickerbockerbagel_official/","foodTypes":"디저트/차/베이커리","latitude":37.5097092982799,"longitude":127.10570564968,"bookYear":"2024","nameKR":"니커버커베이글","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34707,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5040191892562,"longitude":127.114496353632,"bookYear":"2024","nameKR":"선호커피","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32277,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pas_a_pas_baking_studio0272/","foodTypes":"디저트/차/베이커리","latitude":37.509982006836,"longitude":127.109492211647,"bookYear":"2024","nameKR":"빠아빠","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":24126,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.511007,"longitude":127.094003,"bookYear":"2024","nameKR":"떡함지","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31195,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dessertist_/","foodTypes":"디저트/차/베이커리","latitude":37.5106818395712,"longitude":127.107604482119,"bookYear":"2024","nameKR":"디저티스트","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31152,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5096796932223,"longitude":127.11000582851,"bookYear":"2024","nameKR":"이월로스터스 ","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31197,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hows_seoul","foodTypes":"디저트/차/베이커리","latitude":37.5093823098353,"longitude":127.079656267219,"bookYear":"2024","nameKR":"하우스서울","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32298,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5004371186721,"longitude":127.124419978657,"bookYear":"2024","nameKR":"자이소","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":29779,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.49974503859954,"longitude":127.03495362076806,"bookYear":"2024","nameKR":"502커피로스터스(역삼점)","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":35301,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_towners_/","foodTypes":"디저트/차/베이커리","latitude":37.4984648733779,"longitude":127.039841335692,"bookYear":"2024","nameKR":"타우너스에스프레소바","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":5128,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffee_bar_k/","foodTypes":"바/주점","latitude":37.504696,"longitude":127.041237,"bookYear":"2024","nameKR":"커피바K","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":32611,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4971791313312,"longitude":127.039858859358,"bookYear":"2024","nameKR":"하트카페","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":22366,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.507901,"longitude":126.964262,"bookYear":"2024","nameKR":"프랑세즈","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":29746,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mysterlee_brewing_company/","foodTypes":"바/주점","latitude":37.54384100225424,"longitude":126.94790306612319,"bookYear":"2024","nameKR":"미스터리브루잉컴퍼니","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":31151,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/reissue.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5626957260034,"longitude":126.921362029118,"bookYear":"2024","nameKR":"리이슈커피로스터스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27503,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pakito_taberna_espanola/","foodTypes":"바/주점","latitude":37.561736,"longitude":126.918344,"bookYear":"2024","nameKR":"빠끼또","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28350,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.560867,"longitude":126.924926,"bookYear":"2024","nameKR":"진가","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":33413,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lenuage_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5617663577595,"longitude":126.926697768319,"bookYear":"2024","nameKR":"르누아즈","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":9009,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.56255,"longitude":126.927,"bookYear":"2024","nameKR":"송가","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":6942,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafeimi/","foodTypes":"디저트/차/베이커리","latitude":37.55744,"longitude":126.9219,"bookYear":"2024","nameKR":"이미커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32121,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yanncouvreur_kr/","foodTypes":"디저트/차/베이커리","latitude":37.5593202128277,"longitude":126.924647886467,"bookYear":"2024","nameKR":"얀쿠브레","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28566,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/glt_gelato","foodTypes":"디저트/차/베이커리","latitude":37.559162,"longitude":126.925678,"bookYear":"2024","nameKR":"glt젤라또","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":29025,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.554603,"longitude":126.930511,"bookYear":"2024","nameKR":"산울림1992","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32342,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lake_coffeebar/","foodTypes":"디저트/차/베이커리","latitude":37.5543144698367,"longitude":126.917916725803,"bookYear":"2024","nameKR":"레이크커피바","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5816,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5500851944722,"longitude":126.919764712297,"bookYear":"2024","nameKR":"피오니","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28562,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.553063,"longitude":126.916664,"bookYear":"2024","nameKR":"커피상점이심","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27566,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.55701,"longitude":126.92659,"bookYear":"2024","nameKR":"밀로커피로스터스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":29810,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/woolfsocialclub","foodTypes":"디저트/차/베이커리","latitude":37.54245411206785,"longitude":127.00270663687861,"bookYear":"2024","nameKR":"울프소셜클럽","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":18221,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/artisanbakerm","foodTypes":"디저트/차/베이커리","latitude":37.53454304398228,"longitude":127.00865308193832,"bookYear":"2024","nameKR":"아티장베이커스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":29632,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tartinebakeryseoul/","foodTypes":"디저트/차/베이커리","latitude":37.53439168374897,"longitude":127.00854444857862,"bookYear":"2024","nameKR":"타르틴베이커리서울","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":32046,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5536649100854,"longitude":127.020108389723,"bookYear":"2024","nameKR":"신금호숯불갈비","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":31292,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jadeandwater.seoul/","foodTypes":"바/주점","latitude":37.5436483262883,"longitude":127.015311526062,"bookYear":"2024","nameKR":"제이드앤워터","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33198,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thegriffinbar/","foodTypes":"바/주점","latitude":37.5704485321397,"longitude":127.008743829151,"bookYear":"2024","nameKR":"더그리핀바","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":32987,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coheeby_ryu/","foodTypes":"디저트/차/베이커리","latitude":37.5703269097751,"longitude":127.00313020538,"bookYear":"2024","nameKR":"류강현커피집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":6819,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.556978,"longitude":127.005914,"bookYear":"2024","nameKR":"패스트리부띠끄","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":7027,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.55706,"longitude":127.0058,"bookYear":"2024","nameKR":"더라이브러리","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":32955,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bohey_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.555447268885,"longitude":127.007920663857,"bookYear":"2024","nameKR":"보헤이커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":5640,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.559389,"longitude":127.002667,"bookYear":"2024","nameKR":"태극당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":33278,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cheongsudang_/","foodTypes":"디저트/차/베이커리","latitude":37.5738814755255,"longitude":126.989784379653,"bookYear":"2024","nameKR":"청수당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":32545,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/secondcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5767802454922,"longitude":126.990885361301,"bookYear":"2024","nameKR":"세컨드커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":5634,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/g.u_puerhtea_jongro/","foodTypes":"디저트/차/베이커리","latitude":37.575528,"longitude":126.99047,"bookYear":"2024","nameKR":"지유명차","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7059,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.57233,"longitude":126.9859,"bookYear":"2024","nameKR":"아름다운차박물관","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":33012,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shabba_i/","foodTypes":"바/주점","latitude":37.5650958071738,"longitude":126.992583246148,"bookYear":"2024","nameKR":"금토일샴페인빠","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":30507,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/euljiro_boseok/","foodTypes":"바/주점","latitude":37.56514798844486,"longitude":126.98924713690984,"bookYear":"2024","nameKR":"을지로보석","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29549,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/euljiro_redstar/","foodTypes":"바/주점","latitude":37.56549767665184,"longitude":126.99042877522649,"bookYear":"2024","nameKR":"레드스타","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":33020,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wui.yongsan/","foodTypes":"중식","latitude":37.5270237235917,"longitude":126.963773835076,"bookYear":"2024","nameKR":"웨이티하우스앤레스토랑","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31963,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5245391729878,"longitude":126.964843291404,"bookYear":"2024","nameKR":"롱브르378","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32679,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pont_official_/","foodTypes":"디저트/차/베이커리","latitude":37.5257905975656,"longitude":126.96285650643,"bookYear":"2024","nameKR":"폰트커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31833,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sailer_korea/","foodTypes":"디저트/차/베이커리","latitude":37.5269056148241,"longitude":126.966566172294,"bookYear":"2024","nameKR":"싸일러용산","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32546,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/______quartz/","foodTypes":"디저트/차/베이커리","latitude":37.5329621717645,"longitude":126.9718442489,"bookYear":"2024","nameKR":"쿼츠","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":28156,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/commcomm_bakery_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.53078,"longitude":126.97057,"bookYear":"2024","nameKR":"컴컴베이커리카페","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":30440,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bamaself_coffee","foodTypes":"디저트/차/베이커리","latitude":37.54072007005738,"longitude":126.96867977728446,"bookYear":"2024","nameKR":"바마셀","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":34439,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5391104230158,"longitude":126.965722178909,"bookYear":"2024","nameKR":"베이커리합","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":30697,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.56854787150042,"longitude":127.05662375259054,"bookYear":"2024","nameKR":"커피나인","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":26745,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.56939,"longitude":126.9317,"bookYear":"2024","nameKR":"피터팬1978","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":18234,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.57061814217744,"longitude":126.9326663108036,"bookYear":"2024","nameKR":"떡의미학","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":31146,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5661857885447,"longitude":126.927353106836,"bookYear":"2024","nameKR":"디폴트밸류","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":36360,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lookbackcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5682422653995,"longitude":126.92920588507,"bookYear":"2024","nameKR":"룩백커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":33450,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/protokoll.roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5677931372994,"longitude":126.931372918465,"bookYear":"2024","nameKR":"프로토콜","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":29484,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.568021529380786,"longitude":126.92967131457455,"bookYear":"2024","nameKR":"금옥당","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":27976,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.56776,"longitude":126.9296,"bookYear":"2024","nameKR":"매뉴팩트커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":3464,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/labonnetarte_official/","foodTypes":"디저트/차/베이커리","latitude":37.5639171508019,"longitude":126.943832497098,"bookYear":"2024","nameKR":"라본느타르트","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":31956,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5673657681152,"longitude":126.969516767128,"bookYear":"2024","nameKR":"동아리","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":30653,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.56842249719518,"longitude":126.9782578910947,"bookYear":"2024","nameKR":"다만프레르","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":31153,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.592510379455,"longitude":127.020261166362,"bookYear":"2024","nameKR":"리이케커피 ","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":32181,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5870992240896,"longitude":127.029898451923,"bookYear":"2024","nameKR":"라플루마앤보헤미안","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":6416,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/atelieretmamie/","foodTypes":"디저트/차/베이커리","latitude":37.5915728402712,"longitude":127.020590405787,"bookYear":"2024","nameKR":"아뜰리에마미","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":36189,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bonjak_tteok/","foodTypes":"디저트/차/베이커리","latitude":37.548479397281,"longitude":127.063912435314,"bookYear":"2024","nameKR":"본작","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34370,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/brewingceremony/","foodTypes":"디저트/차/베이커리","latitude":37.5432727118261,"longitude":127.055139727387,"bookYear":"2024","nameKR":"브루잉세레모니","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":30512,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lowkey_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.544198024387065,"longitude":127.051581189409,"bookYear":"2024","nameKR":"로우키","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":28321,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5489241684925,"longitude":127.054880332448,"bookYear":"2024","nameKR":"피어커피로스터스","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33293,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gimisa_seongsu/","foodTypes":"디저트/차/베이커리","latitude":37.545134419964,"longitude":127.062772457313,"bookYear":"2024","nameKR":"기미사","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":31502,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seongsu_baking_studio","foodTypes":"디저트/차/베이커리","latitude":37.5461965391103,"longitude":127.043747525348,"bookYear":"2024","nameKR":"성수베이킹스튜디오","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":28115,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.546834,"longitude":127.044095,"bookYear":"2024","nameKR":"보난자베이커리","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":28237,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.54623,"longitude":127.0449,"bookYear":"2024","nameKR":"밀도","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36506,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/re커버y_coffee_bar/","foodTypes":"디저트/차/베이커리","latitude":37.5503309855629,"longitude":127.0448580594,"bookYear":"2024","nameKR":"리커버리커피바","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":28947,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/amazingbrewing","foodTypes":"바/주점","latitude":37.542863,"longitude":127.049402,"bookYear":"2024","nameKR":"어메이징브루잉컴퍼니","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29236,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sb_bread_factory/","foodTypes":"디저트/차/베이커리","latitude":37.595858,"longitude":126.988618,"bookYear":"2024","nameKR":"성북동빵공장","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":28528,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.582877,"longitude":126.971122,"bookYear":"2024","nameKR":"베어카페","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":29479,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.57917145468498,"longitude":126.97038445847463,"bookYear":"2024","nameKR":"바참","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":28285,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/scoffbakehouse","foodTypes":"디저트/차/베이커리","latitude":37.593107,"longitude":126.963583,"bookYear":"2024","nameKR":"스코프","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31109,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_layered/","foodTypes":"디저트/차/베이커리","latitude":37.5777414803804,"longitude":126.985931528189,"bookYear":"2024","nameKR":"레이어드","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":490,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5862,"longitude":126.9818,"bookYear":"2024","nameKR":"서울서둘째로잘하는집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":29434,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.582351,"longitude":126.985222,"bookYear":"2024","nameKR":"그린마일커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":31154,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/haeryecoffee/","foodTypes":"디저트/차/베이커리","latitude":37.6206715464357,"longitude":126.917614902171,"bookYear":"2024","nameKR":"해례커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":31155,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6191336121159,"longitude":126.917559511221,"bookYear":"2024","nameKR":"와이엠커피프로젝트","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":32097,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafecomma__official/","foodTypes":"디저트/차/베이커리","latitude":37.551946564453,"longitude":126.906760728614,"bookYear":"2024","nameKR":"카페꼼마(합정점)","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":32869,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/monsieurbubu.coffeestand/","foodTypes":"디저트/차/베이커리","latitude":37.5561283507016,"longitude":126.898338402889,"bookYear":"2024","nameKR":"무슈부부커피스탠드","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":36024,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/doraeknot_mangwon/","foodTypes":"디저트/차/베이커리","latitude":37.5601500310484,"longitude":126.903480384734,"bookYear":"2024","nameKR":"도래노트","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":18337,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.555,"longitude":126.9103,"bookYear":"2024","nameKR":"경기떡집","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":30871,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bakery_creek/","foodTypes":"디저트/차/베이커리","latitude":37.5483976966577,"longitude":126.939900311541,"bookYear":"2024","nameKR":"크리크","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":32368,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/birosocoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5481234358738,"longitude":126.939202546866,"bookYear":"2024","nameKR":"비로소커피","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36169,"bookStatus":"승인","websiteInstagram":"http://instagram.com/liebhaber.seoul","foodTypes":"디저트/차/베이커리","latitude":37.5459540326884,"longitude":126.944940337991,"bookYear":"2024","nameKR":"립하버","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29835,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.544749366441955,"longitude":126.94826001068026,"bookYear":"2024","nameKR":"타쿠미야","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":28573,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.544749366441955,"longitude":126.94826001068026,"bookYear":"2024","nameKR":"팩토리엠","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":32130,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/felt_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5687892140435,"longitude":126.978817526558,"bookYear":"2024","nameKR":"펠트커피(청계천점)","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":28992,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.575341,"longitude":126.970203,"bookYear":"2024","nameKR":"커피투어(광화문점)","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":111,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.57275,"longitude":126.9694,"bookYear":"2024","nameKR":"커피스트","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":8510,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.570994,"longitude":126.980451,"bookYear":"2024","nameKR":"호미관","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":12638,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.648898,"longitude":127.034519,"bookYear":"2024","nameKR":"함스브로트과자점","ribbonType":"1","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":12991,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.52433,"longitude":127.0436,"bookYear":"2024","nameKR":"마코토","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":27556,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.52497,"longitude":127.045,"bookYear":"2024","nameKR":"볼트스테이크하우스","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30998,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.524644994825,"longitude":127.042678785802,"bookYear":"2024","nameKR":"스케줄청담","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28488,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/alice_cheongdam/","foodTypes":"바/주점","latitude":37.5266775490869,"longitude":127.040943739564,"bookYear":"2024","nameKR":"앨리스청담","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33600,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/anseoli.bakinglab/","foodTypes":"디저트/차/베이커리","latitude":37.5222713073295,"longitude":127.048064135117,"bookYear":"2024","nameKR":"안서리베이킹랩","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":27559,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.52622,"longitude":127.0411,"bookYear":"2024","nameKR":"르챔버","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30188,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jungsikcafe_","foodTypes":"디저트/차/베이커리","latitude":37.52565954993628,"longitude":127.04107916345522,"bookYear":"2024","nameKR":"정식카페","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28973,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/devastate.kr/","foodTypes":"디저트/차/베이커리","latitude":37.526127,"longitude":127.044787,"bookYear":"2024","nameKR":"데바스테이트","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":854,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.519933,"longitude":127.049232,"bookYear":"2024","nameKR":"케익하우스윈","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":26788,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_treeanon/","foodTypes":"디저트/차/베이커리","latitude":37.520284,"longitude":127.044556,"bookYear":"2024","nameKR":"트리아농","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":6890,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/maison_de_zoe","foodTypes":"디저트/차/베이커리","latitude":37.52331,"longitude":127.049,"bookYear":"2024","nameKR":"메종드조에","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30476,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/restaurant.dexter","foodTypes":"바/주점","latitude":37.52319959376904,"longitude":127.04653042140113,"bookYear":"2024","nameKR":"덱스터","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":34139,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/roda_wine","foodTypes":"바/주점","latitude":37.517540239927,"longitude":127.051670182016,"bookYear":"2024","nameKR":"로다","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33382,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sailer_korea/","foodTypes":"디저트/차/베이커리","latitude":37.5271468521262,"longitude":127.049491124593,"bookYear":"2024","nameKR":"싸일러청담","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32536,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gute_leute_/","foodTypes":"디저트/차/베이커리","latitude":37.5176953608723,"longitude":127.03963018885,"bookYear":"2024","nameKR":"구테로이테","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32875,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/a_n_other_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5162618583468,"longitude":127.037439201501,"bookYear":"2024","nameKR":"에이엔아더","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31172,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kokkilibagel/","foodTypes":"디저트/차/베이커리","latitude":37.5286104254461,"longitude":126.893270984521,"bookYear":"2024","nameKR":"코끼리베이글","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":28437,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.519724,"longitude":126.931242,"bookYear":"2024","nameKR":"쿠마","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":31788,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/iambagel_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5202061193308,"longitude":126.931800002781,"bookYear":"2024","nameKR":"아이엠베이글","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":6804,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/elie.s.pie/","foodTypes":"디저트/차/베이커리","latitude":37.52083,"longitude":126.9313,"bookYear":"2024","nameKR":"엘리스파이","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":32916,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dmdrkrha/","foodTypes":"바/주점","latitude":37.484029950182,"longitude":127.037560260553,"bookYear":"2024","nameKR":"자맛풍","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":27738,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.48475,"longitude":127.0449,"bookYear":"2024","nameKR":"젠틀커피","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":32939,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/delibag.kr/","foodTypes":"디저트/차/베이커리","latitude":37.4917134802222,"longitude":127.039748870748,"bookYear":"2024","nameKR":"델리백","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":32017,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kota_dyun","foodTypes":"바/주점","latitude":37.5253756052581,"longitude":127.037683021073,"bookYear":"2024","nameKR":"코타바이뎐","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32127,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nu_dake","foodTypes":"디저트/차/베이커리","latitude":37.5253703709931,"longitude":127.035679523304,"bookYear":"2024","nameKR":"누데이크","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29016,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ourbakerycafe","foodTypes":"디저트/차/베이커리","latitude":37.52354898537393,"longitude":127.03688332564025,"bookYear":"2024","nameKR":"아우어베이커리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30594,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.52483929944529,"longitude":127.03663055616519,"bookYear":"2024","nameKR":"코메","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33449,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/smallbatch.seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5259982940752,"longitude":127.034104955634,"bookYear":"2024","nameKR":"스몰배치서울","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":5296,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.525031,"longitude":127.025307,"bookYear":"2024","nameKR":"길손","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31996,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cupandcutcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5065177158838,"longitude":127.054280157476,"bookYear":"2024","nameKR":"컵앤드컷","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":6268,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/retrooven2008/","foodTypes":"디저트/차/베이커리","latitude":37.51215,"longitude":127.0439,"bookYear":"2024","nameKR":"레트로오븐","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":4882,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.48116,"longitude":126.9459,"bookYear":"2024","nameKR":"남도포장마차","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":30012,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeanboulangerie_kr","foodTypes":"디저트/차/베이커리","latitude":37.47720087747186,"longitude":126.9619863012828,"bookYear":"2024","nameKR":"쟝블랑제리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":31134,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4992525794113,"longitude":126.998962639243,"bookYear":"2024","nameKR":"서래오뎅","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":7177,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.493937,"longitude":127.00103,"bookYear":"2024","nameKR":"줄리에뜨","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":34761,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lefourdore_korea","foodTypes":"디저트/차/베이커리","latitude":37.5109823973192,"longitude":127.058205573267,"bookYear":"2024","nameKR":"르푸도레","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":32874,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/a.cup.of.newwave/","foodTypes":"디저트/차/베이커리","latitude":37.5209328477412,"longitude":126.876746663862,"bookYear":"2024","nameKR":"뉴웨이브카페","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":30712,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5508932986207,"longitude":126.836942560718,"bookYear":"2024","nameKR":"타르데마","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":27549,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.50742,"longitude":127.0235,"bookYear":"2024","nameKR":"히까리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":32217,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dihirang/","foodTypes":"바/주점","latitude":37.5118740693026,"longitude":127.035057905947,"bookYear":"2024","nameKR":"디히랑","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":34628,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/leoiseau_/","foodTypes":"디저트/차/베이커리","latitude":37.5594526887597,"longitude":126.833912548403,"bookYear":"2024","nameKR":"르와조","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":22368,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5713698504776,"longitude":126.80296035927,"bookYear":"2024","nameKR":"안스베이커리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":19491,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ipari_magok/","foodTypes":"바/주점","latitude":37.560835635621,"longitude":126.829618546018,"bookYear":"2024","nameKR":"이파리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":28504,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.49345568764088,"longitude":127.02114139054106,"bookYear":"2024","nameKR":"장티크베이커리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":27960,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ruelle_de_paris_official/","foodTypes":"디저트/차/베이커리","latitude":37.484014,"longitude":127.017341,"bookYear":"2024","nameKR":"루엘드파리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":28603,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.503518,"longitude":127.009698,"bookYear":"2024","nameKR":"가이센","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":31483,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5039351769908,"longitude":127.002413006799,"bookYear":"2024","nameKR":"호라이즌","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":30843,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.536433399041,"longitude":127.126157127477,"bookYear":"2024","nameKR":"브랜뉴하이몬드","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":21820,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yul_bakery","foodTypes":"디저트/차/베이커리","latitude":37.5333584448635,"longitude":127.135449331754,"bookYear":"2024","nameKR":"율베이커리","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":33128,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/waynesbagels_kor/","foodTypes":"디저트/차/베이커리","latitude":37.4919941549832,"longitude":127.028848796506,"bookYear":"2024","nameKR":"웨인스베이글스","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":30599,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shoto_patisserie","foodTypes":"디저트/차/베이커리","latitude":37.52384777173811,"longitude":127.02430843056398,"bookYear":"2024","nameKR":"쇼토","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":6973,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sanho_gaengdu/","foodTypes":"바/주점","latitude":37.5223,"longitude":127.0204,"bookYear":"2024","nameKR":"산호","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":22131,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.51697,"longitude":127.0236,"bookYear":"2024","nameKR":"커피휘엘","ribbonType":"1","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":36429,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1548994504362,"longitude":129.119247481947,"bookYear":"2024","nameKR":"라운지아카네","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":2324,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.5693947377572,"longitude":129.343835317711,"bookYear":"2024","nameKR":"원조대구막창1번지","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":5646,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.571359,"longitude":126.98026,"bookYear":"2024","nameKR":"흥진옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":5642,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.57244,"longitude":126.9794,"bookYear":"2024","nameKR":"한성옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":120,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.57168,"longitude":127.016,"bookYear":"2024","nameKR":"나마스테","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":614,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":0,"longitude":0,"bookYear":"2024","nameKR":"뉴욕스테이크하우스","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":1144,"bookStatus":"승인","websiteInstagram":"http://instagram.com/starsamarkand_","foodTypes":"아시아식","latitude":37.5656961493958,"longitude":127.005632504153,"bookYear":"2024","nameKR":"스타사마르칸트","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":1237,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.544346,"longitude":126.973233,"bookYear":"2024","nameKR":"은성집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":5174,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dubai_itaewon/","foodTypes":"아시아식","latitude":37.53432,"longitude":126.9953,"bookYear":"2024","nameKR":"두바이","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":6445,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5593399785272,"longitude":126.943602557565,"bookYear":"2024","nameKR":"오리지날분식","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":34385,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4843108450591,"longitude":127.03796596878,"bookYear":"2024","nameKR":"양재닭집","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36275,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pizzeriadalforno/","foodTypes":"이탈리아식","latitude":37.561541939142,"longitude":127.040824841501,"bookYear":"2024","nameKR":"핏제리아달포르노","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":32281,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kdh_mangata/","foodTypes":"유럽식","latitude":37.5801813093084,"longitude":126.980982059626,"bookYear":"2024","nameKR":"만가타","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":36168,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jungjung_20","foodTypes":"중식","latitude":37.5462286708302,"longitude":126.944201657706,"bookYear":"2024","nameKR":"정정","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":35249,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wheat.n.meat/","foodTypes":"기타","latitude":37.5036785514807,"longitude":127.027484274765,"bookYear":"2024","nameKR":"위트앤미트","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":32018,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yasunoya","foodTypes":"일식","latitude":37.546726600027,"longitude":126.978409378518,"bookYear":"2024","nameKR":"야스노야","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":36311,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5404187841405,"longitude":127.093974084703,"bookYear":"2024","nameKR":"고고기","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36307,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5593835750015,"longitude":126.837861297457,"bookYear":"2024","nameKR":"광주똑순이아구찜","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":31783,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/food_sylvain/","foodTypes":"프랑스식","latitude":37.5528119921071,"longitude":126.906329720168,"bookYear":"2024","nameKR":"푸드실방","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":33431,"bookStatus":"승인","websiteInstagram":"http://instagram.com/krap_pom","foodTypes":"아시아식","latitude":37.5248762621515,"longitude":127.037988213227,"bookYear":"2024","nameKR":"까폼","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31493,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5431753629727,"longitude":127.055419600647,"bookYear":"2024","nameKR":"탐광","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33335,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeje_modernchinese/?igshid=1fbvyah1bwohz","foodTypes":"중식","latitude":37.5478966119798,"longitude":127.042574916474,"bookYear":"2024","nameKR":"제제","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":31990,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kungthale/","foodTypes":"아시아식","latitude":37.5227822943748,"longitude":127.021750577543,"bookYear":"2024","nameKR":"꿍탈레","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30577,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/timhowan_korea","foodTypes":"중식","latitude":37.5114495900297,"longitude":127.0568656195432,"bookYear":"2024","nameKR":"팀호완","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":2356,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.419622,"longitude":126.646941,"bookYear":"2024","nameKR":"송도갈매기","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":2366,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.475235,"longitude":126.619,"bookYear":"2024","nameKR":"원보만두","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":4105,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.662015,"longitude":126.767338,"bookYear":"2024","nameKR":"로얄인디아","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":4237,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/byelmijeong/","foodTypes":"한식(민물어패류)","latitude":37.723069,"longitude":126.512483,"bookYear":"2024","nameKR":"별미정","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":4642,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.399039,"longitude":127.422754,"bookYear":"2024","nameKR":"띠울석갈비","ribbonType":"X","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":5269,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.333824,"longitude":127.429368,"bookYear":"2024","nameKR":"태화장","ribbonType":"X","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":5360,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.654732,"longitude":126.772628,"bookYear":"2024","nameKR":"아시아아시아","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":5459,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.488105,"longitude":126.784517,"bookYear":"2024","nameKR":"안나푸르나레스토랑","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":5544,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":35.553394,"longitude":129.31883,"bookYear":"2024","nameKR":"나마스까르","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":8004,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.139236,"longitude":126.794979,"bookYear":"2024","nameKR":"화정떡갈비","ribbonType":"X","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":6169,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중남미식","latitude":37.080044,"longitude":127.050496,"bookYear":"2024","nameKR":"리오그릴","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":6261,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.198094,"longitude":127.077822,"bookYear":"2024","nameKR":"수엠부","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":6303,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.263915,"longitude":127.03174,"bookYear":"2024","nameKR":"중평떡볶이","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6327,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.390518,"longitude":126.954781,"bookYear":"2024","nameKR":"긴자인도레스토랑","ribbonType":"X","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":6733,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.266846304981705,"longitude":127.00238557875734,"bookYear":"2024","nameKR":"델리다바","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6753,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.266915,"longitude":127.003605,"bookYear":"2024","nameKR":"수엠부","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":8250,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8864072366511,"longitude":128.649242661182,"bookYear":"2024","nameKR":"원조돼지갈비찜","ribbonType":"X","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":23586,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.2060524722481,"longitude":129.071900561307,"bookYear":"2024","nameKR":"부부냉면","ribbonType":"X","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":18045,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.275154,"longitude":127.041464,"bookYear":"2024","nameKR":"운봉농장","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":29668,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.82128124885351,"longitude":127.14352187208769,"bookYear":"2024","nameKR":"다이닝스푼","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":16577,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.2862322919066,"longitude":127.208710584815,"bookYear":"2024","nameKR":"타이씨암레스토랑","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":12382,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.683847,"longitude":127.871729,"bookYear":"2024","nameKR":"늘푸름임꺽정","ribbonType":"X","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":31346,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dayjust591/","foodTypes":"한식(육류)","latitude":37.155804693605,"longitude":128.988525228897,"bookYear":"2024","nameKR":"기와집갈비","ribbonType":"X","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":30966,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_alohawave_/","foodTypes":"기타","latitude":37.9721367966862,"longitude":128.760741739085,"bookYear":"2024","nameKR":"알로하웨이브","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":30695,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hanwoolover/","foodTypes":"한식(육류)","latitude":37.65849219752267,"longitude":127.83019528671404,"bookYear":"2024","nameKR":"홍천한우애","ribbonType":"X","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":30681,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.3761518348165,"longitude":127.395382761984,"bookYear":"2024","nameKR":"랑골로","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":31710,"bookStatus":"승인","websiteInstagram":"https://ilpiore.cityfood.co.kr/","foodTypes":"이탈리아식","latitude":35.8183011301566,"longitude":127.109739751076,"bookYear":"2024","nameKR":"일피오레","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":31045,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_dongbaek_kitchen/","foodTypes":"이탈리아식","latitude":33.4284139538887,"longitude":126.266960238165,"bookYear":"2024","nameKR":"동백키친","ribbonType":"X","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":30900,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/__seasoning/","foodTypes":"이탈리아식","latitude":35.8354640121509,"longitude":129.212435831564,"bookYear":"2024","nameKR":"시즈닝","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31726,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.6333600113314,"longitude":127.480996970648,"bookYear":"2024","nameKR":"타볼라","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":32084,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5637612084486,"longitude":127.189620532746,"bookYear":"2024","nameKR":"미미곱창","ribbonType":"X","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":32145,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mata_ashita_2020/","foodTypes":"일식","latitude":36.1406056763446,"longitude":128.115260461911,"bookYear":"2024","nameKR":"마타아시타","ribbonType":"X","zone2_1":"경북","zone2_2":"김천시","status":"ACTIVE"},
        {"id":31729,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/odett_she","foodTypes":"이탈리아식","latitude":33.4108751922188,"longitude":126.295578088098,"bookYear":"2024","nameKR":"오데뜨제주","ribbonType":"X","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":32100,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_burningcow","foodTypes":"한식(육류)","latitude":33.4886058315439,"longitude":126.494892626807,"bookYear":"2024","nameKR":"명호마농갈비","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":31761,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/blacktaco_daegu","foodTypes":"중남미식","latitude":35.866578853136,"longitude":128.599522658112,"bookYear":"2024","nameKR":"블랙타코앤그릴","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":30910,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.9804184253442,"longitude":128.762110298953,"bookYear":"2024","nameKR":"파머스키친","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":31856,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.4850469004095,"longitude":126.485037987096,"bookYear":"2024","nameKR":"숙성도","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":31994,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pungmi_chicken/","foodTypes":"한식(가금류)","latitude":34.9464318917125,"longitude":127.490962746295,"bookYear":"2024","nameKR":"풍미통닭","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":31703,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jungjaejib","foodTypes":"이탈리아식","latitude":35.1375739262404,"longitude":129.105437932465,"bookYear":"2024","nameKR":"비스트로정재집","ribbonType":"X","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":32269,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seasoner_/","foodTypes":"이탈리아식","latitude":35.8496166520276,"longitude":127.074395109939,"bookYear":"2024","nameKR":"시즈너","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":32827,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/trinity_bistro/","foodTypes":"이탈리아식","latitude":36.3437061272589,"longitude":127.390437872642,"bookYear":"2024","nameKR":"트리니티비스트로","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":33042,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tate_modern__/ ","foodTypes":"기타","latitude":35.1439808889848,"longitude":126.838125962119,"bookYear":"2024","nameKR":"테이트모던","ribbonType":"X","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":33081,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pastology_/?hl=ko","foodTypes":"이탈리아식","latitude":35.8215014121616,"longitude":127.141251515993,"bookYear":"2024","nameKR":"파스톨로지","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":34296,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":35.2566260149864,"longitude":128.624998278346,"bookYear":"2024","nameKR":"브라바스","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":33918,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/inbs.kitchen/","foodTypes":"이탈리아식","latitude":37.4950765434973,"longitude":126.722218164855,"bookYear":"2024","nameKR":"인브스키친","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":33610,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/simdon_official/","foodTypes":"한식(육류)","latitude":35.5633156546155,"longitude":129.310647538756,"bookYear":"2024","nameKR":"심돈","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":33386,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yajasu_thai","foodTypes":"아시아식","latitude":35.8716369095844,"longitude":128.599926040531,"bookYear":"2024","nameKR":"야자수지붕","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":32790,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/imthai_thai_cuisine/","foodTypes":"아시아식","latitude":35.1546146320587,"longitude":129.064448368204,"bookYear":"2024","nameKR":"아임타이","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":32750,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":35.1681333894814,"longitude":129.156151467948,"bookYear":"2024","nameKR":"오르","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":34059,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kits.chs/","foodTypes":"기타","latitude":37.5080614046585,"longitude":126.724395552824,"bookYear":"2024","nameKR":"키치키치다이닝","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":33025,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/baannmai/","foodTypes":"아시아식","latitude":37.7059079583638,"longitude":126.717569488124,"bookYear":"2024","nameKR":"반마이","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32789,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/patpong1258","foodTypes":"아시아식","latitude":37.3235860043263,"longitude":127.124166403559,"bookYear":"2024","nameKR":"태국식당팟퐁","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":33352,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/official_fo_m/","foodTypes":"이탈리아식","latitude":35.1893447394618,"longitude":126.83195210524,"bookYear":"2024","nameKR":"수완지구폼","ribbonType":"X","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":34150,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thai_road/","foodTypes":"아시아식","latitude":37.7106586602791,"longitude":126.759350612558,"bookYear":"2024","nameKR":"타이로드","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":36091,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.2171017283125,"longitude":128.586695715581,"bookYear":"2024","nameKR":"엘리","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":36286,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.8274989723399,"longitude":128.62280990904,"bookYear":"2024","nameKR":"만수통닭(수성못본점)","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":34484,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/meat_culture/","foodTypes":"유럽식","latitude":37.7721403195664,"longitude":128.946793831304,"bookYear":"2024","nameKR":"미트컬쳐","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":32835,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.7530600719358,"longitude":127.433793841158,"bookYear":"2024","nameKR":"델씨엘로","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":32444,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hanuvino","foodTypes":"한식(육류)","latitude":37.3621749058887,"longitude":127.106553698118,"bookYear":"2024","nameKR":"하누비노","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":33108,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.1821548757565,"longitude":128.073302037258,"bookYear":"2024","nameKR":"보리키친","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":36159,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/midam1974_official","foodTypes":"한식(육류)","latitude":35.0927867753126,"longitude":128.908847401726,"bookYear":"2024","nameKR":"미담1974","ribbonType":"X","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":33475,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.785800960683,"longitude":126.273876849587,"bookYear":"2024","nameKR":"진미각","ribbonType":"X","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":33291,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":37.791449758958,"longitude":128.914612776422,"bookYear":"2024","nameKR":"라꼬시나","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":32253,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lastortas_/","foodTypes":"중남미식","latitude":33.499187641786,"longitude":126.53111624291,"bookYear":"2024","nameKR":"라스또르따스","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":33179,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/stillroom.gyeongju/","foodTypes":"이탈리아식","latitude":35.8421877960572,"longitude":129.210404495561,"bookYear":"2024","nameKR":"스틸룸","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":36250,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/quivi__/","foodTypes":"이탈리아식","latitude":35.150801069729,"longitude":126.923748783162,"bookYear":"2024","nameKR":"퀴비","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":33491,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":36.3547355070224,"longitude":127.379985783043,"bookYear":"2024","nameKR":"인디","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":32889,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8009905044663,"longitude":127.107067740803,"bookYear":"2024","nameKR":"기린더매운갈비찜 ","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":33968,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mizinenae_","foodTypes":"한식(육류)","latitude":35.8668230995472,"longitude":128.601467536574,"bookYear":"2024","nameKR":"미진삼겹살","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":33904,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pasta_dope/","foodTypes":"이탈리아식","latitude":36.8090849956031,"longitude":127.105571509388,"bookYear":"2024","nameKR":"도프","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":34265,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cowboy_grill/","foodTypes":"기타","latitude":37.0132487293328,"longitude":128.226070712833,"bookYear":"2024","nameKR":"카우보이그릴","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":32734,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/danke.schnitzel","foodTypes":"유럽식","latitude":34.7971136080592,"longitude":128.043353462764,"bookYear":"2024","nameKR":"당케슈니첼","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":35776,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/taberna_fuegonegro/","foodTypes":"유럽식","latitude":36.6561955411291,"longitude":127.492382358889,"bookYear":"2024","nameKR":"푸에고네그로","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":34169,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sunnys_pizza_market/","foodTypes":"미국식","latitude":37.3243166971603,"longitude":127.098555481467,"bookYear":"2024","nameKR":"써니스피자마켓","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":33936,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/likburger","foodTypes":"기타","latitude":35.8339513729891,"longitude":129.209329981623,"bookYear":"2024","nameKR":"릭버거","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":33082,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.1390772796424,"longitude":128.415517512858,"bookYear":"2024","nameKR":"곱곱","ribbonType":"X","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":36032,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.3352842344125,"longitude":126.9620409299,"bookYear":"2024","nameKR":"남도예담","ribbonType":"X","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":33529,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.5912417463854,"longitude":127.477811788592,"bookYear":"2024","nameKR":"더스프링","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33259,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/leone_ilsan/","foodTypes":"이탈리아식","latitude":37.6700054375993,"longitude":126.78636079657,"bookYear":"2024","nameKR":"레오네","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":32542,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/unome.incheon","foodTypes":"이탈리아식","latitude":37.4452656406021,"longitude":126.705530849084,"bookYear":"2024","nameKR":"유노미","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":33668,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mansion_de_bkkk/","foodTypes":"아시아식","latitude":35.8677827381148,"longitude":128.590350954774,"bookYear":"2024","nameKR":"맨션드방콕","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":32197,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/taste.good913","foodTypes":"한식(가금류)","latitude":35.5532845272506,"longitude":129.319792255863,"bookYear":"2024","nameKR":"가미닭갈비913","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":35404,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.6392060697998,"longitude":127.487935697554,"bookYear":"2024","nameKR":"무심천드래곤","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35095,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/1324_dining","foodTypes":"이탈리아식","latitude":37.7377820876921,"longitude":127.049730620553,"bookYear":"2024","nameKR":"다이닝1324","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":35216,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.8319280521553,"longitude":127.188250843931,"bookYear":"2024","nameKR":"비스트로오마주","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":34916,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/black_ranch9289/","foodTypes":"한식(육류)","latitude":33.2429503789996,"longitude":126.454851753683,"bookYear":"2024","nameKR":"꺼멍목장","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":34915,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6258160809824,"longitude":126.89716650271,"bookYear":"2024","nameKR":"신호등장작구이","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":35577,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lebeef_official/","foodTypes":"한식(육류)","latitude":36.4813104143387,"longitude":127.289895723001,"bookYear":"2024","nameKR":"르비프","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":35727,"bookStatus":"승인","websiteInstagram":"https://instagram.com/bistro_archive","foodTypes":"유럽식","latitude":33.4822123388974,"longitude":126.69573232546,"bookYear":"2024","nameKR":"아카이브","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":35743,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/elrato_ulsan/","foodTypes":"중남미식","latitude":35.541023682508,"longitude":129.336028836675,"bookYear":"2024","nameKR":"엘라토","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":35732,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.6009911150225,"longitude":126.817819357562,"bookYear":"2024","nameKR":"행주만리","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":35287,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tori_kokoro/","foodTypes":"일식","latitude":37.64388077797,"longitude":127.110083097611,"bookYear":"2024","nameKR":"토리코코로 ","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":36938,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1465983082992,"longitude":129.112546978234,"bookYear":"2024","nameKR":"다이도코로","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36622,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/neung_so.hwa","foodTypes":"기타","latitude":34.7870435261571,"longitude":126.381119893653,"bookYear":"2024","nameKR":"능소화","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":36626,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/our.daily.meal/","foodTypes":"기타","latitude":37.4482316481038,"longitude":126.695246841006,"bookYear":"2024","nameKR":"일용할양식","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":36613,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.0377255349591,"longitude":127.032371194351,"bookYear":"2024","nameKR":"이탈리안테이블","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":36969,"bookStatus":"승인","websiteInstagram":"https://instagram.com/simpson_the_spice","foodTypes":"아시아식","latitude":37.7271718679568,"longitude":126.770530777752,"bookYear":"2024","nameKR":"심슨더스파이스","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":37022,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.3083489841266,"longitude":126.828265186376,"bookYear":"2024","nameKR":"라쪼","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":37944,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.8763504245625,"longitude":127.753135457632,"bookYear":"2024","nameKR":"1.5닭갈비","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":36819,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tonsum_story/","foodTypes":"일식","latitude":35.0913239887317,"longitude":129.0386028191,"bookYear":"2024","nameKR":"톤섬","ribbonType":"X","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":36614,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nakedkitchen620","foodTypes":"기타","latitude":37.3846027421473,"longitude":126.962189372884,"bookYear":"2024","nameKR":"네이키드키친","ribbonType":"X","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":36768,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.4717237578997,"longitude":127.136290416523,"bookYear":"2024","nameKR":"까우","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":37610,"bookStatus":"승인","websiteInstagram":"","foodTypes":"미국식","latitude":37.0783777894064,"longitude":127.050949711478,"bookYear":"2024","nameKR":"페페피자","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":37252,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/allapala_pizza/","foodTypes":"이탈리아식","latitude":36.8999266108399,"longitude":127.536277965329,"bookYear":"2024","nameKR":"알라팔라","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"진천군","status":"ACTIVE"},
        {"id":37074,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.5505318719969,"longitude":129.29617288569,"bookYear":"2024","nameKR":"덕클","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":938,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5041098746658,"longitude":127.055240972615,"bookYear":"2024","nameKR":"피양콩할마니","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":997,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.562072,"longitude":126.981821,"bookYear":"2024","nameKR":"개화","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":1064,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5708105464699,"longitude":127.021463886656,"bookYear":"2024","nameKR":"깃대봉냉면","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":1107,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.55579567980368,"longitude":127.01031693140858,"bookYear":"2024","nameKR":"만포막국수","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":1228,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.481674097012295,"longitude":126.9536038806978,"bookYear":"2024","nameKR":"완산정","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":1250,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.5521,"longitude":126.9744,"bookYear":"2024","nameKR":"일미장어","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":1261,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5399716114492,"longitude":126.943866192022,"bookYear":"2024","nameKR":"원조조박집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":1325,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.481239329407565,"longitude":126.94811389134087,"bookYear":"2024","nameKR":"해태식당","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":1327,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5621198047309,"longitude":126.981717024453,"bookYear":"2024","nameKR":"행화촌","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":1338,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.559913,"longitude":126.977072,"bookYear":"2024","nameKR":"희락갈치","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":412,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.542211,"longitude":126.952016,"bookYear":"2024","nameKR":"본점최대포","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":470,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.51469,"longitude":127.0594,"bookYear":"2024","nameKR":"삼성국수","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":488,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5708444878121,"longitude":126.979923668151,"bookYear":"2024","nameKR":"서린낙지","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":494,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.48914,"longitude":127.0334,"bookYear":"2024","nameKR":"남순남순대국","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":600,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.481262,"longitude":127.013393,"bookYear":"2024","nameKR":"앵콜칼국수","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":714,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5727,"longitude":126.9839,"bookYear":"2024","nameKR":"이문설농탕","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":783,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.57033,"longitude":127.0056,"bookYear":"2024","nameKR":"진옥화할매닭한마리","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":23,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5155681940207,"longitude":127.018825754067,"bookYear":"2024","nameKR":"강남따로국밥","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":58,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56976,"longitude":126.9973,"bookYear":"2024","nameKR":"함흥곰보냉면","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":90,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5279678162173,"longitude":127.035856001621,"bookYear":"2024","nameKR":"금성스테이크부대찌개","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":170,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.532225,"longitude":126.901019,"bookYear":"2024","nameKR":"당산원조곱창","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":180,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.50272,"longitude":127.0353,"bookYear":"2024","nameKR":"대우부대찌개강남본점","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":318,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56274,"longitude":126.9857,"bookYear":"2024","nameKR":"명동함흥면옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":342,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.568388,"longitude":126.997805,"bookYear":"2024","nameKR":"문화옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":349,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.56413,"longitude":126.984,"bookYear":"2024","nameKR":"미성옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":364,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5369083075217,"longitude":127.000040053449,"bookYear":"2024","nameKR":"바다식당","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":370,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.508658,"longitude":127.055941,"bookYear":"2024","nameKR":"박서방순대국밥","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":381,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.481133,"longitude":127.013754,"bookYear":"2024","nameKR":"백년옥","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":3835,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/urbangarden2005/","foodTypes":"이탈리아식","latitude":37.567337,"longitude":126.969177,"bookYear":"2024","nameKR":"어반가든","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":12909,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아프리카식","latitude":37.53351,"longitude":126.9908,"bookYear":"2024","nameKR":"브라이리퍼블릭","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":13013,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.546568,"longitude":126.946358,"bookYear":"2024","nameKR":"아소정","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":4900,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.536878,"longitude":127.000121,"bookYear":"2024","nameKR":"나리의집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":5138,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sanmoori/","foodTypes":"한식(어패류)","latitude":37.50631,"longitude":127.097,"bookYear":"2024","nameKR":"군산오징어","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":5215,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.483864,"longitude":127.019691,"bookYear":"2024","nameKR":"부일갈비","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":5605,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.571957,"longitude":126.982737,"bookYear":"2024","nameKR":"백부장집닭한마리","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":5619,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.56654,"longitude":126.9898,"bookYear":"2024","nameKR":"오구반점","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":6477,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.55811637198653,"longitude":126.98688953052782,"bookYear":"2024","nameKR":"목멱산방","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":6957,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.549391,"longitude":126.911976,"bookYear":"2024","nameKR":"양화정","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":26701,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.64083,"longitude":127.0291,"bookYear":"2024","nameKR":"오늘통닭(수유본점)","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":7621,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5510079695241,"longitude":126.91164899787,"bookYear":"2024","nameKR":"뉴욕아파트먼트","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":31951,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/maison.de.paris","foodTypes":"프랑스식","latitude":37.5733168601026,"longitude":126.990234088745,"bookYear":"2024","nameKR":"빠리가옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":31989,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/alfonso_pasta/","foodTypes":"이탈리아식","latitude":37.575371838965,"longitude":126.897012559179,"bookYear":"2024","nameKR":"파스타공작소","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":31880,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5205101179976,"longitude":126.974928635952,"bookYear":"2024","nameKR":"르번미","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":31962,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5269462575567,"longitude":127.039802628162,"bookYear":"2024","nameKR":"호화반점","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30417,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/phongon_korea/","foodTypes":"아시아식","latitude":37.5661151011377,"longitude":127.078223556542,"bookYear":"2024","nameKR":"포응온","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":31954,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5796409879546,"longitude":126.971587241588,"bookYear":"2024","nameKR":"창성갈비","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31550,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/togarashiseoul","foodTypes":"일식","latitude":37.5146337771289,"longitude":127.02899335598,"bookYear":"2024","nameKR":"토가라시","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":30022,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.486386678699056,"longitude":126.94994205082227,"bookYear":"2024","nameKR":"만양순대국","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":31725,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.557083987471,"longitude":126.937376871643,"bookYear":"2024","nameKR":"유닭스토리 ","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":28357,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.48081,"longitude":127.011661,"bookYear":"2024","nameKR":"비노인빌라","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":29041,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chungsoojang/","foodTypes":"한식(육류)","latitude":37.605297,"longitude":127.01077,"bookYear":"2024","nameKR":"청수장","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":32031,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5679425897426,"longitude":126.931024715211,"bookYear":"2024","nameKR":"진미","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":32326,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5955083392501,"longitude":127.100331994394,"bookYear":"2024","nameKR":"용마갈비","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":33436,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bubuyoridahn/","foodTypes":"한식(어패류)","latitude":37.5428111679777,"longitude":127.017539927889,"bookYear":"2024","nameKR":"부부요리단","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33432,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5480612095083,"longitude":126.954720753238,"bookYear":"2024","nameKR":"그레이스국밥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":33541,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4826273644389,"longitude":127.126626946849,"bookYear":"2024","nameKR":"푼주","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":33265,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/phosome_by_chanu/","foodTypes":"아시아식","latitude":37.5941562838985,"longitude":127.01821856847,"bookYear":"2024","nameKR":"포썸","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":33901,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.567146723828,"longitude":126.978457591358,"bookYear":"2024","nameKR":"남촌회관","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":32519,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bistro_floc.","foodTypes":"프랑스식","latitude":37.5392766260308,"longitude":127.082721466205,"bookYear":"2024","nameKR":"플록","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":32709,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5704978639194,"longitude":127.001207236708,"bookYear":"2024","nameKR":"진주육회","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":33360,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/soba_mae/","foodTypes":"일식","latitude":37.546808145881,"longitude":127.047007351689,"bookYear":"2024","nameKR":"소바마에","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33044,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chinalnnsoo/","foodTypes":"중식","latitude":37.5315605721866,"longitude":126.971835159116,"bookYear":"2024","nameKR":"중화객잔수","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32328,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.507246947003,"longitude":127.032257860617,"bookYear":"2024","nameKR":"육개옥","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":32717,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jinyeongmyeonog","foodTypes":"한식(면류)","latitude":37.4715699238483,"longitude":126.90039992595,"bookYear":"2024","nameKR":"진영면옥","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":32227,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5353741059467,"longitude":126.902277256782,"bookYear":"2024","nameKR":"당산옛날곱창","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":33067,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6154408274917,"longitude":127.075710783856,"bookYear":"2024","nameKR":"묵동부대찌개","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":32115,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/freshville_since2002","foodTypes":"기타","latitude":37.5082024036362,"longitude":127.066305587885,"bookYear":"2024","nameKR":"후레쉬빌","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":33422,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5764202233299,"longitude":126.971667142925,"bookYear":"2024","nameKR":"뼈탄집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":33260,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wumok_beef/","foodTypes":"한식(육류)","latitude":37.5266090561205,"longitude":127.035421951109,"bookYear":"2024","nameKR":"우모크","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33813,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mokpo.nakji/","foodTypes":"한식(어패류)","latitude":37.5775527985075,"longitude":126.969595934945,"bookYear":"2024","nameKR":"목포세발낙지","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":33994,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sanghoon_kwon84","foodTypes":"한식(면류)","latitude":37.5994008243308,"longitude":126.948635967562,"bookYear":"2024","nameKR":"어머니와아들","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34136,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sowana_hannamdong/","foodTypes":"한식(육류)","latitude":37.5354860739455,"longitude":127.000990229169,"bookYear":"2024","nameKR":"소와나","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":34836,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lehans_/","foodTypes":"이탈리아식","latitude":37.6041320110277,"longitude":126.998523144915,"bookYear":"2024","nameKR":"르한스","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":34137,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/artspace_woowol?igsh=NG0zMjRvMGlma2Vm","foodTypes":"한식(육류)","latitude":37.5538350029778,"longitude":126.924885862587,"bookYear":"2024","nameKR":"아트스페이스우월","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":34135,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/beflique_grill/","foodTypes":"한식(육류)","latitude":37.5210587932382,"longitude":127.036886398434,"bookYear":"2024","nameKR":"비플리끄그릴","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":34228,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5109164436053,"longitude":127.107722449185,"bookYear":"2024","nameKR":"멘야하나비","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36149,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/parkgane1966_official/","foodTypes":"한식(일반한식)","latitude":37.5705393128128,"longitude":127.000850669375,"bookYear":"2024","nameKR":"박가네빈대떡","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":35233,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5174631210953,"longitude":126.896493699106,"bookYear":"2024","nameKR":"느루집","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":34248,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hojibobo_restaurant/","foodTypes":"아시아식","latitude":37.5339654370555,"longitude":126.989436261467,"bookYear":"2024","nameKR":"호지보보","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":34534,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5364188945323,"longitude":127.12221435572,"bookYear":"2024","nameKR":"풍년집","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":34279,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/noi_since_2012","foodTypes":"아시아식","latitude":37.5141649803156,"longitude":127.063851151366,"bookYear":"2024","nameKR":"더하노이","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":35223,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/giwajip_ssambap/","foodTypes":"한식(일반한식)","latitude":38.1859319752741,"longitude":127.306487006752,"bookYear":"2024","nameKR":"기와집","ribbonType":"X","zone2_1":"강원","zone2_2":"철원군","status":"ACTIVE"},
        {"id":36364,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/aus.bymark/","foodTypes":"바/주점","latitude":33.4723830360693,"longitude":126.546868507561,"bookYear":"2024","nameKR":"아우스","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":14085,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.809985,"longitude":126.377414,"bookYear":"2024","nameKR":"원조탕탕이맛집","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":3206,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1636662184916,"longitude":129.169417311289,"bookYear":"2024","nameKR":"황금어장","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":31245,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/locanda__ora/","foodTypes":"이탈리아식","latitude":36.1161081456388,"longitude":128.344477760611,"bookYear":"2024","nameKR":"로칸다오라","ribbonType":"X","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":3729,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.0725211881687,"longitude":129.016203053201,"bookYear":"2024","nameKR":"아미치","ribbonType":"X","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":5082,"bookStatus":"커버","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.662989,"longitude":126.763629,"bookYear":null,"nameKR":"파스타스토리","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":13293,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sw____09/","foodTypes":"한식(일반한식)","latitude":35.867701,"longitude":128.597993,"bookYear":"2024","nameKR":"개정","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":33302,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/au_beurre117/","foodTypes":"디저트/차/베이커리","latitude":37.6518833009949,"longitude":127.128118486856,"bookYear":"2024","nameKR":"오뵈르","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":30525,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.83529430313871,"longitude":128.61718374206788,"bookYear":"2024","nameKR":"금수강산해물탕","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":5149,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.509053,"longitude":127.058313,"bookYear":"2024","nameKR":"마노디셰프","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":25,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56197,"longitude":126.974,"bookYear":"2024","nameKR":"강서면옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":9089,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.536622745972274,"longitude":126.97032435495488,"bookYear":"2024","nameKR":"문배동육칼","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31276,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/restaurantfuego","foodTypes":"기타","latitude":37.533972347996,"longitude":127.009830247058,"bookYear":"2024","nameKR":"푸에고","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":16017,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.341742,"longitude":127.961572,"bookYear":null,"nameKR":"장군화로구이","ribbonType":"X","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":38780,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.3239382583487,"longitude":126.747893168908,"bookYear":"2024","nameKR":"신지횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"완도군","status":"ACTIVE"},
        {"id":38781,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.3166114228499,"longitude":126.751886569399,"bookYear":"2024","nameKR":"유일정식당","ribbonType":"X","zone2_1":"전남","zone2_2":"완도군","status":"ACTIVE"},
        {"id":38785,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chontofu/","foodTypes":"한식(일반한식)","latitude":35.9918976173054,"longitude":128.299853490741,"bookYear":"2024","nameKR":"촌두부집","ribbonType":"X","zone2_1":"경북","zone2_2":"성주군","status":"ACTIVE"},
        {"id":956,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5672716927969,"longitude":126.964330722367,"bookYear":"2024","nameKR":"한옥집김치찜","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":1251,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.57146,"longitude":126.9751,"bookYear":"2024","nameKR":"일품당","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":1252,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.56213060631099,"longitude":126.9822392503262,"bookYear":"2024","nameKR":"일품향","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":1273,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.55307483069939,"longitude":127.00822517896421,"bookYear":"2024","nameKR":"처가집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":1276,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5227,"longitude":127.0434,"bookYear":"2024","nameKR":"청담골","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":1396,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.536291,"longitude":126.974574,"bookYear":"2024","nameKR":"명화원","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":8054,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/koreansoup_bh/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.59308,"longitude":126.9138,"bookYear":"2024","nameKR":"봉희설렁탕","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":1087,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.53085,"longitude":126.9966,"bookYear":"2024","nameKR":"동아냉면","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":1096,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.498963,"longitude":127.038343,"bookYear":"2024","nameKR":"파크루안","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":1170,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5182520985876,"longitude":126.904700613521,"bookYear":"2024","nameKR":"송죽장","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":1179,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5129436953442,"longitude":127.057114235894,"bookYear":"2024","nameKR":"스카이라운지","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":1189,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.54468,"longitude":126.9577,"bookYear":"2024","nameKR":"신성각","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":1205,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.56605,"longitude":126.9916,"bookYear":"2024","nameKR":"안동장","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":1215,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.50333,"longitude":127.0575,"bookYear":"2024","nameKR":"여수동촌","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":814,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.567432,"longitude":126.980473,"bookYear":"2024","nameKR":"초류향","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":888,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.523307,"longitude":127.048241,"bookYear":"2024","nameKR":"토담골","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":625,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.57149,"longitude":127.0003,"bookYear":"2024","nameKR":"연지얼큰한동태국","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":629,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.562918,"longitude":126.997997,"bookYear":"2024","nameKR":"영덕회식당","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":704,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.564207,"longitude":126.976068,"bookYear":"2024","nameKR":"유림면","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":746,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.52113,"longitude":126.9299,"bookYear":"2024","nameKR":"장원북어국","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":747,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5644375648557,"longitude":126.967286479534,"bookYear":"2024","nameKR":"장호왕곱창","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":758,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.562096678179984,"longitude":126.97415796260648,"bookYear":"2024","nameKR":"정원순두부","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":764,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5752792170686,"longitude":126.983634642346,"bookYear":"2024","nameKR":"조금","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":408,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.54225880774428,"longitude":126.94781364433918,"bookYear":"2024","nameKR":"복성각","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":423,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.542487,"longitude":126.939493,"bookYear":"2024","nameKR":"부영각","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":437,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.59456387645758,"longitude":126.99230471440727,"bookYear":"2024","nameKR":"비양도","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":449,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.497673,"longitude":126.997758,"bookYear":"2024","nameKR":"포폴라리타","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":504,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.59324,"longitude":126.996,"bookYear":"2024","nameKR":"성북동돼지갈비본점","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":563,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.59345,"longitude":126.9957,"bookYear":"2024","nameKR":"쌍다리돼지불백","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":177,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5633305026485,"longitude":126.991506236821,"bookYear":"2024","nameKR":"대성닭한마리","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":232,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.560373,"longitude":127.005882,"bookYear":"2024","nameKR":"뚱뚱이할머니집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":65,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.57131,"longitude":126.975,"bookYear":"2024","nameKR":"광화문집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":1439,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.590734,"longitude":127.01834,"bookYear":"2024","nameKR":"강풍돼지갈비","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":1504,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.512913,"longitude":127.018912,"bookYear":"2024","nameKR":"의정부평양면옥","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":1571,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아프리카식","latitude":37.5341720738554,"longitude":126.992239645,"bookYear":"2024","nameKR":"마라케쉬나이트","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":3110,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.52849,"longitude":127.1156,"bookYear":"2024","nameKR":"유천냉면","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":3742,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5378376344984,"longitude":126.894643881557,"bookYear":"2024","nameKR":"길풍식당","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":3779,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.57199,"longitude":126.9883,"bookYear":"2024","nameKR":"유진식당","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":3826,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.569856,"longitude":127.057795,"bookYear":"2024","nameKR":"성천막국수","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":3963,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.58681,"longitude":126.982,"bookYear":"2024","nameKR":"단풍나무집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":4146,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.568642,"longitude":126.999731,"bookYear":"2024","nameKR":"은주정","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":4625,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.495612,"longitude":127.01539,"bookYear":"2024","nameKR":"명동곰돌이","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":4782,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.49857,"longitude":127.0367,"bookYear":"2024","nameKR":"역삼동북어집","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":8731,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.571651,"longitude":126.982582,"bookYear":"2024","nameKR":"안래홍","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":8705,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.568875,"longitude":126.999392,"bookYear":"2024","nameKR":"강산옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":5100,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5066550675471,"longitude":127.04838618187,"bookYear":"2024","nameKR":"맛자랑","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":5148,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.484547,"longitude":127.037988,"bookYear":"2024","nameKR":"영동족발","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":8489,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.56254,"longitude":126.97786,"bookYear":"2024","nameKR":"동원민물장어","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":5926,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.57202928626468,"longitude":126.97429489709765,"bookYear":"2024","nameKR":"오가와","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":6060,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.53638918177256,"longitude":126.96040845953524,"bookYear":"2024","nameKR":"창성옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":6137,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5007217293612,"longitude":127.030740316375,"bookYear":"2024","nameKR":"동경전통육개장설곰탕","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":6200,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.54322935056174,"longitude":127.05764109707678,"bookYear":"2024","nameKR":"이북집찹쌀순대","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":6403,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.54841,"longitude":127.0223,"bookYear":"2024","nameKR":"은성보쌈","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":6408,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.55952,"longitude":126.977,"bookYear":"2024","nameKR":"닭진미","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":6690,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5273787104138,"longitude":127.038528777361,"bookYear":"2024","nameKR":"심양","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":6846,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ah_gejang/","foodTypes":"한식(어패류)","latitude":37.55527962147538,"longitude":126.955604455409,"bookYear":"2024","nameKR":"아현동간장게장","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":6891,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.553017026808,"longitude":127.01003588754638,"bookYear":"2024","nameKR":"약수순대국","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":6914,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.57244,"longitude":127.027,"bookYear":"2024","nameKR":"옥천옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":9065,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.59654,"longitude":127.0609,"bookYear":"2024","nameKR":"영화장","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":7945,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5542486631229,"longitude":126.911148349271,"bookYear":"2024","nameKR":"망원동즉석우동","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":7978,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5903945486139,"longitude":127.060947878604,"bookYear":"2024","nameKR":"봉이만두","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":17670,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.546242262980655,"longitude":127.04978109979747,"bookYear":"2024","nameKR":"전주콩나물국밥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":16866,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.59364,"longitude":126.966,"bookYear":"2024","nameKR":"소소한풍경","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":18090,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.55016,"longitude":126.9529,"bookYear":"2024","nameKR":"이요이요스시","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":18316,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.519504,"longitude":127.050675,"bookYear":"2024","nameKR":"진성칼국수","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":13347,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.564021,"longitude":126.996107,"bookYear":"2024","nameKR":"황평집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":18633,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.538344,"longitude":127.06775,"bookYear":"2024","nameKR":"홍매반점","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":19123,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5147607136931,"longitude":127.119120258928,"bookYear":"2024","nameKR":"비스트로베네토","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":22709,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.484,"longitude":127.0453,"bookYear":"2024","nameKR":"포브라더스","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":21407,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5633404921113,"longitude":126.991346531232,"bookYear":"2024","nameKR":"동원집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":26594,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.567822,"longitude":126.981712,"bookYear":"2024","nameKR":"원흥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":26801,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5799900420491,"longitude":127.004253318384,"bookYear":"2024","nameKR":"도도야","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":23424,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.570649,"longitude":127.003333,"bookYear":"2024","nameKR":"이모네설렁탕","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":23821,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5625153279648,"longitude":126.978928412409,"bookYear":"2024","nameKR":"한가람","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":27553,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.627962,"longitude":127.043454,"bookYear":"2024","nameKR":"할머니해장국","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":27656,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/koreasudam/","foodTypes":"한식(일반한식)","latitude":37.51047,"longitude":127.046,"bookYear":"2024","nameKR":"수담한정식","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":27512,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cubawang_cubaking/","foodTypes":"중남미식","latitude":37.555957,"longitude":126.926453,"bookYear":"2024","nameKR":"쿠바왕","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":29156,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.549595746738795,"longitude":126.91045519529965,"bookYear":"2024","nameKR":"합정옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":29274,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.570344,"longitude":126.999911,"bookYear":"2024","nameKR":"부촌육회","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":29413,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.521872,"longitude":127.042542,"bookYear":"2024","nameKR":"스시쿠루미","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29293,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tapamadre2016/","foodTypes":"유럽식","latitude":37.573018,"longitude":126.969103,"bookYear":"2024","nameKR":"따빠마드레","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29995,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.538037975165935,"longitude":126.94658554632935,"bookYear":"2024","nameKR":"도하정","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29885,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.56254191943842,"longitude":126.96747089301188,"bookYear":"2024","nameKR":"땡땡거리형제옥","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":29803,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.50992516737628,"longitude":127.02571546018511,"bookYear":"2024","nameKR":"논현양꼬치","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":29710,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/crazyforkatsu/","foodTypes":"일식","latitude":37.550452930919654,"longitude":126.90971180237112,"bookYear":"2024","nameKR":"크레이지카츠","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":30098,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gomtangban","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.544749366441955,"longitude":126.94826001068026,"bookYear":"2024","nameKR":"곰탕반","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":30118,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/redbeanhouse","foodTypes":"한식(일반한식)","latitude":37.5248243999828,"longitude":127.027837746706,"bookYear":"2024","nameKR":"파머스마켓팥집","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30084,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/phoforyoukr","foodTypes":"아시아식","latitude":37.5251775245928,"longitude":126.924876706923,"bookYear":"2024","nameKR":"포포유","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":30272,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.53314486656054,"longitude":126.99422987786963,"bookYear":"2024","nameKR":"플랜트","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30493,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5685910027871,"longitude":126.999672984722,"bookYear":"2024","nameKR":"이원손칼국수","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":30504,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.5664299904795,"longitude":126.988973372471,"bookYear":"2024","nameKR":"동강나루터","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":30489,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.57367794033718,"longitude":126.9834273822331,"bookYear":"2024","nameKR":"아지오","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":30473,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.503934456230404,"longitude":127.00241119724721,"bookYear":"2024","nameKR":"달가마","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":30560,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.50449284378187,"longitude":127.00784436615872,"bookYear":"2024","nameKR":"에토레","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":30764,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5398789692901,"longitude":127.128195554633,"bookYear":"2024","nameKR":"안녕식당","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":30556,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/junjabang.sungsu","foodTypes":"중식","latitude":37.54830923383195,"longitude":127.05371147824512,"bookYear":"2024","nameKR":"전자방","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":30986,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hannam_kitchen/","foodTypes":"중식","latitude":37.5341308199483,"longitude":127.006010283589,"bookYear":"2024","nameKR":"한남소관","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":31553,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seokhwan.yun/","foodTypes":"일식","latitude":37.527861899218,"longitude":127.036312210926,"bookYear":"2024","nameKR":"스시츠바키","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31744,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cangweon9366/","foodTypes":"한식(가금류)","latitude":37.491302882416,"longitude":127.011591359711,"bookYear":"2024","nameKR":"3대삼계장인","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":31593,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/food_does_matter/","foodTypes":"기타","latitude":37.4962659038648,"longitude":126.997625688538,"bookYear":"2024","nameKR":"푸드더즈매터","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":31690,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yeonheegomtang/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5697957773223,"longitude":126.930905170249,"bookYear":"2024","nameKR":"연희곰탕","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":31567,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushi_nuha/","foodTypes":"일식","latitude":37.5791247862862,"longitude":126.968651810412,"bookYear":"2024","nameKR":"스시누하","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31444,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5221662900104,"longitude":127.038350529847,"bookYear":"2024","nameKR":"키겐","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":31575,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5365636555465,"longitude":127.002134363783,"bookYear":"2024","nameKR":"일상담미","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":31832,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/palma_seochon/","foodTypes":"중남미식","latitude":37.5777056259239,"longitude":126.969669004548,"bookYear":"2024","nameKR":"팔마서촌","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31984,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/k_house_official/","foodTypes":"한식(어패류)","latitude":37.5235467773742,"longitude":127.0489155413,"bookYear":"2024","nameKR":"케이하우스","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32026,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5675622624001,"longitude":127.007747857693,"bookYear":"2024","nameKR":"대화정","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":31947,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/woobling_chungdam","foodTypes":"한식(육류)","latitude":37.5228274339838,"longitude":127.040015488815,"bookYear":"2024","nameKR":"우블링","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32033,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5365636555465,"longitude":127.002134363783,"bookYear":"2024","nameKR":"스시노아야","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":32166,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/6.jahamunro.6.gil/","foodTypes":"중식","latitude":37.5779680592302,"longitude":126.972504517859,"bookYear":"2024","nameKR":"도량","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":32274,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5047428849251,"longitude":127.053159553826,"bookYear":"2024","nameKR":"명정루(선릉본점)","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":32177,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/eulji_canhcanh/","foodTypes":"아시아식","latitude":37.5657437884452,"longitude":126.991063733657,"bookYear":"2024","nameKR":"을지깐깐","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":32352,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shawnino_trattoria/","foodTypes":"이탈리아식","latitude":37.526325161342,"longitude":126.963431504099,"bookYear":"2024","nameKR":"쇼니노","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32701,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kyewol_seoul","foodTypes":"한식(가금류)","latitude":37.5395599858903,"longitude":127.047631685043,"bookYear":"2024","nameKR":"계월곰탕","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32799,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cumulus.shop","foodTypes":"기타","latitude":37.4897066749488,"longitude":127.030992140879,"bookYear":"2024","nameKR":"큐뮬러스","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":33011,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lafinebouche.seoul/","foodTypes":"기타","latitude":37.5375418904115,"longitude":127.000383113347,"bookYear":"2024","nameKR":"라핀부쉬","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33055,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5062203134857,"longitude":127.005051680109,"bookYear":"2024","nameKR":"스시마이우","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":33047,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gyeokjung_mullae/","foodTypes":"한식(가금류)","latitude":37.5132835373582,"longitude":126.893343713248,"bookYear":"2024","nameKR":"계옥정","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":33111,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cheznous.privatekitchen/","foodTypes":"프랑스식","latitude":37.5363433391664,"longitude":126.987404190597,"bookYear":"2024","nameKR":"셰누프라이빗키친","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33080,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/racinee_seoul/","foodTypes":"컨템포러리","latitude":37.5180983015937,"longitude":127.043439274897,"bookYear":"2024","nameKR":"라시네","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33102,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/okisoba77/","foodTypes":"일식","latitude":37.4772365189989,"longitude":126.883695161966,"bookYear":"2024","nameKR":"오키소바","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":33063,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pecorino_trattoria/","foodTypes":"이탈리아식","latitude":37.5224534383828,"longitude":127.044517830428,"bookYear":"2024","nameKR":"페코리노","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33424,"bookStatus":"승인","websiteInstagram":"http://www.instagram.com/OSTERIA_SOTTI_official","foodTypes":"이탈리아식","latitude":37.5789590954951,"longitude":126.972259848556,"bookYear":"2024","nameKR":"오스테리아소띠","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":33434,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sasim.official/","foodTypes":"일식","latitude":37.5628428242687,"longitude":126.926049298178,"bookYear":"2024","nameKR":"사심스키야키","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":33412,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5637577243541,"longitude":126.851844528395,"bookYear":"2024","nameKR":"제일식당","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":33531,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/salsarica_hj/","foodTypes":"중남미식","latitude":37.5522382688712,"longitude":126.915571436023,"bookYear":"2024","nameKR":"살사리까","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33406,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sunhwaui_gogi/","foodTypes":"한식(육류)","latitude":37.4869862662895,"longitude":126.993367652945,"bookYear":"2024","nameKR":"선화의고기","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":33420,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5561130617043,"longitude":126.923446601456,"bookYear":"2024","nameKR":"평안도상원냉면","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33380,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/vegan_littlegangster/","foodTypes":"기타","latitude":37.5391665336977,"longitude":126.957858428878,"bookYear":"2024","nameKR":"리틀갱스터","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":33416,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bloodsundaezzang/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5647585252426,"longitude":127.020115332779,"bookYear":"2024","nameKR":"전주순대국","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":33480,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6300758720915,"longitude":127.024026578559,"bookYear":"2024","nameKR":"수유골목냉면","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":33351,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/onblack94_/","foodTypes":"기타","latitude":37.5482787310534,"longitude":126.922383191782,"bookYear":"2024","nameKR":"온블랙94","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":34141,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/deepin_oksu/","foodTypes":"이탈리아식","latitude":37.5426912380213,"longitude":127.012662054013,"bookYear":"2024","nameKR":"디핀옥수","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":34147,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5236566514026,"longitude":127.023048491895,"bookYear":"2024","nameKR":"마히나비건테이블","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":33602,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/millennial_dining/","foodTypes":"기타","latitude":37.4854201490875,"longitude":127.018966531325,"bookYear":"2024","nameKR":"천년식향","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":33678,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/koyama7988/","foodTypes":"일식","latitude":37.5709617749066,"longitude":126.981437983842,"bookYear":"2024","nameKR":"코야마","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":34143,"bookStatus":"승인","websiteInstagram":"http://www.instagram.com/naturalhigh_seoul","foodTypes":"기타","latitude":37.5396939051504,"longitude":126.98755649203,"bookYear":"2024","nameKR":"내추럴하이","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33729,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4983080339873,"longitude":127.151155298044,"bookYear":"2024","nameKR":"내일도두부","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":34026,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sumookdang.official/","foodTypes":"한식(일반한식)","latitude":37.4944247834549,"longitude":127.038685534981,"bookYear":"2024","nameKR":"수묵당","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":33616,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6022872455731,"longitude":126.915220019727,"bookYear":"2024","nameKR":"은평닭곰탕 ","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":33587,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sukidang.official/","foodTypes":"일식","latitude":37.5464259604891,"longitude":126.943949956629,"bookYear":"2024","nameKR":"스키당","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":34307,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gwangjangdak/","foodTypes":"한식(가금류)","latitude":37.5704827232826,"longitude":127.0015433157,"bookYear":"2024","nameKR":"광장누룽지닭강정","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":35604,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.502541427323,"longitude":127.108899027292,"bookYear":"2024","nameKR":"디베르티티","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34554,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5228295670581,"longitude":127.027894925038,"bookYear":"2024","nameKR":"오미가한정식","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":34738,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oden.dongyang","foodTypes":"일식","latitude":37.5092546717859,"longitude":127.12882845771,"bookYear":"2024","nameKR":"동양","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34726,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5383362888084,"longitude":127.001831971058,"bookYear":"2024","nameKR":"그릴도하","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":34752,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dadami_gunja","foodTypes":"일식","latitude":37.5554421378695,"longitude":127.081982994595,"bookYear":"2024","nameKR":"다다미","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":34404,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5008847132213,"longitude":127.027482685232,"bookYear":"2024","nameKR":"사유의서재(강남점)","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":34300,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5382334285342,"longitude":126.893922350178,"bookYear":"2024","nameKR":"너도나도식당","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":34607,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dimdimsumkr/","foodTypes":"중식","latitude":37.5634116488196,"longitude":126.98288772495,"bookYear":"2024","nameKR":"딤딤섬(명동점)","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":34332,"bookStatus":"승인","websiteInstagram":"http://www.instagram.com/danjung_gongdeok","foodTypes":"기타","latitude":37.5447477438441,"longitude":126.948258201287,"bookYear":"2024","nameKR":"단정","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":34594,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushijihyun/","foodTypes":"일식","latitude":37.5606674318154,"longitude":126.923601980153,"bookYear":"2024","nameKR":"스시지현","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":36544,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/baseisnice_seoul/","foodTypes":"기타","latitude":37.539624938496,"longitude":126.949105836145,"bookYear":"2024","nameKR":"베이스이즈나이스","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36138,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chunmimi1000/","foodTypes":"중식","latitude":37.517382475711,"longitude":127.020330650586,"bookYear":"2024","nameKR":"천미미","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":36170,"bookStatus":"승인","websiteInstagram":"http://instagram.com/sushi_moriji","foodTypes":"일식","latitude":37.5477060679608,"longitude":126.939680201636,"bookYear":"2024","nameKR":"스시모리지","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36005,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.5585656034033,"longitude":127.039448556777,"bookYear":"2024","nameKR":"히토리우동","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":36255,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chalim2014/","foodTypes":"한식(일반한식)","latitude":37.5776633052701,"longitude":126.893823873558,"bookYear":"2024","nameKR":"맛있는밥상차림","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":36366,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5780255567709,"longitude":126.89559947123,"bookYear":"2024","nameKR":"동신화로","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":32395,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe.redfife/","foodTypes":"디저트/차/베이커리","latitude":37.7259088838674,"longitude":126.692823825906,"bookYear":"2024","nameKR":"레드파이프","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32455,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/farm.in.seaside/","foodTypes":"디저트/차/베이커리","latitude":37.4920748543607,"longitude":126.581257283455,"bookYear":"2024","nameKR":"바다앞농장","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":3313,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.792764,"longitude":127.433463,"bookYear":"2024","nameKR":"진안관","ribbonType":"X","zone2_1":"전북","zone2_2":"진안군","status":"ACTIVE"},
        {"id":35846,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4669135040489,"longitude":127.138193562163,"bookYear":"2024","nameKR":"위례와인바주연","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":35584,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ogu__official/","foodTypes":"디저트/차/베이커리","latitude":35.0855833761443,"longitude":129.034834546647,"bookYear":"2024","nameKR":"오구카페","ribbonType":"X","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":5551,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cavehouse.kr/","foodTypes":"한식(가금류)","latitude":35.169263,"longitude":128.572479,"bookYear":"2024","nameKR":"동굴집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":3758,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.10202,"longitude":129.027068,"bookYear":"2024","nameKR":"깡통골목할매유부전골","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":35922,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/complexity_coffee","foodTypes":"디저트/차/베이커리","latitude":35.138215055907,"longitude":129.091567252747,"bookYear":"2024","nameKR":"복합성","ribbonType":"X","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":29845,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bopster_onetrackmind/","foodTypes":"디저트/차/베이커리","latitude":35.82206816140163,"longitude":127.10402607135318,"bookYear":"2024","nameKR":"밥스터","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":37775,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oj_jokbal/","foodTypes":"한식(육류)","latitude":37.4745904793418,"longitude":126.869176585068,"bookYear":"2024","nameKR":"조선식탁오정족발","ribbonType":"X","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":32421,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4003793746832,"longitude":127.10208724198,"bookYear":"2024","nameKR":"하림닭요리","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":8982,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wisaenggweon/","foodTypes":"한식(가금류)","latitude":36.565411,"longitude":128.72784,"bookYear":"2024","nameKR":"위생찜닭","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":8903,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.74007345332978,"longitude":127.04816342716612,"bookYear":"2024","nameKR":"지동관","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":21095,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7510408577713,"longitude":127.701370010372,"bookYear":"2024","nameKR":"조일식당","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":28204,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.820182,"longitude":127.14998,"bookYear":"2024","nameKR":"전일갑오","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":5331,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.2836119964831,"longitude":127.102645248432,"bookYear":"2024","nameKR":"만수정","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":4163,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.490491,"longitude":126.494539,"bookYear":"2024","nameKR":"두루두루식당","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":3112,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.161952,"longitude":129.194013,"bookYear":"2024","nameKR":"하진이네","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":31772,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jecheon_sanare","foodTypes":"한식(일반한식)","latitude":37.1200176686129,"longitude":128.127012079295,"bookYear":"2024","nameKR":"산아래","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":2241,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.325849,"longitude":127.424708,"bookYear":"2024","nameKR":"진로집","ribbonType":"X","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":2209,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.328545,"longitude":127.423595,"bookYear":"2024","nameKR":"광천식당","ribbonType":"X","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":31906,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.3564678160179,"longitude":127.349833786492,"bookYear":"2024","nameKR":"온천칼국수","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":32125,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2191991091321,"longitude":128.682615836653,"bookYear":"2024","nameKR":"성산명가","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":31920,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.4756312965383,"longitude":126.619850659295,"bookYear":"2024","nameKR":"연경","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":33298,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/Deluxe_pocha","foodTypes":"바/주점","latitude":37.5134640907308,"longitude":126.892837402698,"bookYear":"2024","nameKR":"디럭스포차","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":33134,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seafood_oido/","foodTypes":"한식(어패류)","latitude":37.3430440576378,"longitude":126.687114304828,"bookYear":"2024","nameKR":"청춘조개","ribbonType":"X","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":32773,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.7720449256901,"longitude":128.947839827855,"bookYear":"2024","nameKR":"보사노바커피로스터스","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33006,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":35.5389231633275,"longitude":129.338750272732,"bookYear":"2024","nameKR":"사이먼스테이크","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":35954,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/noodlekorea/","foodTypes":"한식(면류)","latitude":37.4927644799871,"longitude":126.759120028286,"bookYear":"2024","nameKR":"청정칼국수수제비전문점(본점)","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":30917,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/eunrin_coffee/","foodTypes":"디저트/차/베이커리","latitude":35.5803216893774,"longitude":129.451197096196,"bookYear":"2024","nameKR":"카페은린","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":35458,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2334628811405,"longitude":128.903023554574,"bookYear":"2024","nameKR":"삼대부대찌개","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":30970,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/beacheers.kr/","foodTypes":"디저트/차/베이커리","latitude":38.022348422206,"longitude":128.7229631196,"bookYear":"2024","nameKR":"비치얼스","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":31972,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_grettel","foodTypes":"디저트/차/베이커리","latitude":37.2368926816389,"longitude":127.191405706603,"bookYear":"2024","nameKR":"그레텔","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":34117,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/so_good_official/","foodTypes":"컨템포러리","latitude":37.3109265442891,"longitude":126.82849429791,"bookYear":"2024","nameKR":"소굿","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":25314,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.879113,"longitude":128.596916,"bookYear":"2024","nameKR":"광명반점","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":35107,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mori.japanese/","foodTypes":"일식","latitude":34.9318214247585,"longitude":127.698973914709,"bookYear":"2024","nameKR":"모리","ribbonType":"X","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":24164,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/panisvitae/","foodTypes":"디저트/차/베이커리","latitude":37.657622,"longitude":126.787738,"bookYear":"2024","nameKR":"빠니스비떼","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":22965,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.234959,"longitude":127.206575,"bookYear":"2024","nameKR":"대성부대고기전문","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":4531,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.829958,"longitude":128.616434,"bookYear":"2024","nameKR":"극동구이","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":10335,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/neulbom.jeju/","foodTypes":"한식(육류)","latitude":33.47929,"longitude":126.472878,"bookYear":"2024","nameKR":"늘봄흑돼지","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":4239,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.73265459690177,"longitude":127.72584848119612,"bookYear":"2024","nameKR":"한일관","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":35110,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4666676990313,"longitude":129.170555129247,"bookYear":"2024","nameKR":"일미어담","ribbonType":"X","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":35035,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7604809972956,"longitude":127.660194061737,"bookYear":"2024","nameKR":"진미꽃게탕","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":7991,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1940730755118,"longitude":128.085514331129,"bookYear":"2024","nameKR":"하동복집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":25982,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.166194,"longitude":129.040049,"bookYear":"2024","nameKR":"팔복통닭","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":5539,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.138682,"longitude":126.796398,"bookYear":"2024","nameKR":"서울곱창","ribbonType":"X","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":30874,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.3541353709037,"longitude":127.380594278252,"bookYear":"2024","nameKR":"설천순대국밥","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":31560,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/moklotjip","foodTypes":"바/주점","latitude":37.7449816431985,"longitude":127.097374440948,"bookYear":"2024","nameKR":"목롯집","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":35168,"bookStatus":"승인","websiteInstagram":"http://www.instagram.com/cafe.inmarch","foodTypes":"디저트/차/베이커리","latitude":35.0611599999833,"longitude":127.736691215187,"bookYear":"2024","nameKR":"인마치","ribbonType":"X","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":37812,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/brickbean_coffee/","foodTypes":"디저트/차/베이커리","latitude":36.7967686810377,"longitude":127.06072303108,"bookYear":"2024","nameKR":"브릭빈커피로스터스","ribbonType":"X","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":32425,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_munjiri535","foodTypes":"디저트/차/베이커리","latitude":37.8300524673987,"longitude":126.717986365934,"bookYear":"2024","nameKR":"문지리535","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":26062,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gangnarusta_","foodTypes":"한식(민물어패류)","latitude":37.620652,"longitude":126.534344,"bookYear":"2024","nameKR":"강나루숯불장어","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":35749,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cho_daechang_baekseok/","foodTypes":"한식(육류)","latitude":37.6460955031928,"longitude":126.787364563004,"bookYear":"2024","nameKR":"초대창(백석본점)","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":31376,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6555683973189,"longitude":126.684044231246,"bookYear":"2024","nameKR":"구스타프커피","ribbonType":"X","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":36486,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/saloneitalia/","foodTypes":"이탈리아식","latitude":34.8067504070005,"longitude":128.033616375773,"bookYear":"2024","nameKR":"이태리회관","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":35410,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/anode_jeju/","foodTypes":"바/주점","latitude":33.4723830360693,"longitude":126.546868507561,"bookYear":"2024","nameKR":"아노드","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":37101,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gentle_bread/","foodTypes":"디저트/차/베이커리","latitude":37.6691414364439,"longitude":126.74955804785,"bookYear":"2024","nameKR":"젠틀비","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35319,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.7026387086666,"longitude":126.570698767341,"bookYear":"2024","nameKR":"송만두","ribbonType":"X","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":37018,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.6968487534699,"longitude":129.434844009953,"bookYear":"2024","nameKR":"텀즈","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":35823,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/meta45cafe/","foodTypes":"디저트/차/베이커리","latitude":36.4923112410361,"longitude":127.260577313847,"bookYear":"2024","nameKR":"메타45카페","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":36639,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/since1995_sashimi/","foodTypes":"한식(어패류)","latitude":35.1653875882885,"longitude":129.042007569135,"bookYear":"2024","nameKR":"양지횟집","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37272,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/keystonespeciescoffee","foodTypes":"디저트/차/베이커리","latitude":37.6365609696014,"longitude":127.225480055151,"bookYear":"2024","nameKR":"키스톤스피시즈","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":37377,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yakitori.senppo?igsh=MTJqMXhqOGJzN2p4NA==","foodTypes":"일식","latitude":36.9927187980066,"longitude":127.091627550091,"bookYear":"2024","nameKR":"센뽀","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":33945,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ondang.official","foodTypes":"기타","latitude":35.8814323209175,"longitude":129.226099010887,"bookYear":"2024","nameKR":"온당","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31056,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hyang_ok_jeong/","foodTypes":"한식(민물어패류)","latitude":35.2226612016782,"longitude":128.928527776166,"bookYear":"2024","nameKR":"향옥정","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":30942,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ryomi.official","foodTypes":"일식","latitude":35.8356369904009,"longitude":129.209754509291,"bookYear":"2024","nameKR":"료미","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":37634,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nakwongjgj/","foodTypes":"한식(어패류)","latitude":37.0013224015189,"longitude":127.276991542521,"bookYear":"2024","nameKR":"낙원간장게장","ribbonType":"X","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":2442,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.310606193599,"longitude":126.980556150292,"bookYear":"2024","nameKR":"송죽정","ribbonType":"X","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":33909,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/a.nook_/","foodTypes":"디저트/차/베이커리","latitude":35.8769064309736,"longitude":128.629675290758,"bookYear":"2024","nameKR":"아눅","ribbonType":"X","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":35861,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/developingroom/","foodTypes":"디저트/차/베이커리","latitude":37.4176352451493,"longitude":126.675810259257,"bookYear":"2024","nameKR":"디벨로핑룸","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":37595,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/salone_ristorante/","foodTypes":"이탈리아식","latitude":37.278604632643,"longitude":127.446968136001,"bookYear":"2024","nameKR":"살로네","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":32251,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/no1dot/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3491516586711,"longitude":127.087027385206,"bookYear":"2024","nameKR":"일호점미역","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":33586,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/song__ho__sung/","foodTypes":"일식","latitude":37.3132899396398,"longitude":126.83263647222,"bookYear":"2024","nameKR":"송호성돈카츠","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":35981,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/la_fassona","foodTypes":"이탈리아식","latitude":36.658495214756,"longitude":126.686243095065,"bookYear":"2024","nameKR":"라파쏘나","ribbonType":"X","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":27909,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.435158,"longitude":127.820054,"bookYear":"2024","nameKR":"태화루","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":35717,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/attentionplzzz","foodTypes":"디저트/차/베이커리","latitude":35.3708705285619,"longitude":127.019616642949,"bookYear":"2024","nameKR":"어텐션플리즈","ribbonType":"X","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":35004,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_the_container","foodTypes":"디저트/차/베이커리","latitude":33.4980845354554,"longitude":126.678960128696,"bookYear":"2024","nameKR":"카페더콘테나","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":33507,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sangsun_sunsang","foodTypes":"한식(어패류)","latitude":37.3711932154318,"longitude":127.114616947467,"bookYear":"2024","nameKR":"생선선생","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35702,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dlforud2623","foodTypes":"한식(면류)","latitude":37.7256171568415,"longitude":128.97796978901,"bookYear":"2024","nameKR":"꼭지네식당","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":4389,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.135610900449585,"longitude":127.20196414661103,"bookYear":"2024","nameKR":"송전매운탕","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":31778,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.829333025358,"longitude":127.134084495215,"bookYear":"2024","nameKR":"슈엔","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":33888,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.3943527597949,"longitude":126.961778602993,"bookYear":"2024","nameKR":"모리코무","ribbonType":"X","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":31845,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.3053987556391,"longitude":126.17910003961,"bookYear":"2024","nameKR":"안녕구움","ribbonType":"X","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":36585,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/feeke.coffee.cake","foodTypes":"디저트/차/베이커리","latitude":35.2560299920734,"longitude":128.635788078709,"bookYear":"2024","nameKR":"피케","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":35511,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.8733790801522,"longitude":128.524444525711,"bookYear":"2024","nameKR":"한결청국장","ribbonType":"X","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":3594,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.492353,"longitude":127.835069,"bookYear":"2024","nameKR":"늘비식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":32135,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":36.3579297992182,"longitude":127.348999261533,"bookYear":"2024","nameKR":"코이누르","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":34751,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5027792645918,"longitude":126.773716849978,"bookYear":"2024","nameKR":"진리면관","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":31907,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bake___off/","foodTypes":"디저트/차/베이커리","latitude":36.2955092318621,"longitude":127.339963255213,"bookYear":"2024","nameKR":"베이크오프","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":35533,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":35.1583076744908,"longitude":129.18232691377,"bookYear":"2024","nameKR":"샤브야","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":35916,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/reallazy_patisserie/","foodTypes":"디저트/차/베이커리","latitude":35.1521105560894,"longitude":129.063190759889,"bookYear":"2024","nameKR":"릴레이지","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":6755,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":37.4739496932831,"longitude":126.630454535915,"bookYear":"2024","nameKR":"아라베스크(동인천점)","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":37445,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.7197621344521,"longitude":127.047078314537,"bookYear":"2024","nameKR":"일미만두1981의정부","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":36443,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.781092079721,"longitude":126.379337124435,"bookYear":"2024","nameKR":"만선식당 ","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":34746,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4477162229187,"longitude":126.697156435278,"bookYear":"2024","nameKR":"돈불1971","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":36430,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ouef_haus/","foodTypes":"기타","latitude":37.6080967868212,"longitude":127.376747237303,"bookYear":"2024","nameKR":"우프하우스","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":31478,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.4392567733968,"longitude":127.142043268878,"bookYear":"2024","nameKR":"의천각","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":8899,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.430317,"longitude":127.071353,"bookYear":"2024","nameKR":"청계산장","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":33109,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/izakayamiki_/","foodTypes":"일식","latitude":37.3940760380464,"longitude":126.962567900335,"bookYear":"2024","nameKR":"미키","ribbonType":"X","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":30909,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ongojae__paldang/","foodTypes":"이탈리아식","latitude":37.5471470089019,"longitude":127.248103061582,"bookYear":"2024","nameKR":"온고재","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":32843,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ilsangand.official/","foodTypes":"디저트/차/베이커리","latitude":37.2703543406802,"longitude":127.008191505926,"bookYear":"2024","nameKR":"일상엔베이커리카페","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":32088,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1750614644809,"longitude":128.148111482554,"bookYear":"2024","nameKR":"금산골","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":13156,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/koggiri_mapo_line5","foodTypes":"한식(일반한식)","latitude":37.54028,"longitude":126.9488,"bookYear":"2024","nameKR":"코끼리분식","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":31774,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/monil2_house","foodTypes":"디저트/차/베이커리","latitude":37.5634283536668,"longitude":126.922493792198,"bookYear":"2024","nameKR":"모닐이네하우스","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":1731,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.754025117657804,"longitude":128.8986237900098,"bookYear":"2024","nameKR":"해성횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":34762,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.2887649094641,"longitude":127.014846752926,"bookYear":"2024","nameKR":"그레이락","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34953,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.5085243001739,"longitude":126.542192560054,"bookYear":"2024","nameKR":"뽕이네각재기","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":2481,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.799355629919525,"longitude":126.69660780819846,"bookYear":"2024","nameKR":"중원회관","ribbonType":"X","zone2_1":"전남","zone2_2":"영암군","status":"ACTIVE"},
        {"id":36098,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/payseondong_official/","foodTypes":"일식","latitude":35.15744403347,"longitude":129.064716513065,"bookYear":"2024","nameKR":"페이센동(전포본점)","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":36476,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.5142114384906,"longitude":126.525937732194,"bookYear":"2024","nameKR":"신해바라기분식","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":30616,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.12891753192106,"longitude":128.2055280595679,"bookYear":"2024","nameKR":"해동반점","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":6055,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.467376,"longitude":126.635816,"bookYear":"2024","nameKR":"신일반점","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":6961,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.5290690209425,"longitude":127.161037998447,"bookYear":"2024","nameKR":"행운집","ribbonType":"X","zone2_1":"전북","zone2_2":"임실군","status":"ACTIVE"},
        {"id":34814,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1584975140978,"longitude":129.063165868686,"bookYear":"2024","nameKR":"소인수분해","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":8157,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.332486,"longitude":127.430875,"bookYear":"2024","nameKR":"삼대째전통칼국수","ribbonType":"X","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":33402,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4765018878755,"longitude":127.187590412871,"bookYear":"2024","nameKR":"두레","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":35190,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8570367835297,"longitude":128.589124150105,"bookYear":"2024","nameKR":"세연전통콩국","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":30492,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lyrical.kr","foodTypes":"디저트/차/베이커리","latitude":36.3431295445134,"longitude":127.386281476903,"bookYear":"2024","nameKR":"리리컬디저트","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":32459,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/esquep99_63/","foodTypes":"이탈리아식","latitude":37.3024841671484,"longitude":126.972047816747,"bookYear":"2024","nameKR":"에스쿱","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34264,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1607572387712,"longitude":129.17144402728,"bookYear":"2024","nameKR":"미포집","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":36320,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ty_youngbingwan/","foodTypes":"한식(어패류)","latitude":34.8270632104261,"longitude":128.432517973776,"bookYear":"2024","nameKR":"영빈관","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":34961,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.480941635064,"longitude":126.474608934429,"bookYear":"2024","nameKR":"코시롱","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":36479,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.4976550783368,"longitude":126.529180813791,"bookYear":"2024","nameKR":"아카우마","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":8112,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.600879,"longitude":127.300454,"bookYear":"2024","nameKR":"왕천파닭","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":36453,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1562671759912,"longitude":126.931897178028,"bookYear":"2024","nameKR":"충장빈대떡집","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":32167,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hoxy_jeju/","foodTypes":"기타","latitude":33.4868195539038,"longitude":126.481801586092,"bookYear":"2024","nameKR":"혹시","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":34701,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1567572881633,"longitude":129.134260506525,"bookYear":"2024","nameKR":"수변최고돼지국밥","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":34991,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8681941913545,"longitude":128.588615243079,"bookYear":"2024","nameKR":"서영홍합밥","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":35282,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_oh.tt/","foodTypes":"디저트/차/베이커리","latitude":37.2634659541288,"longitude":127.034032772779,"bookYear":"2024","nameKR":"오티티","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37413,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/luaz_bakerycoffee","foodTypes":"디저트/차/베이커리","latitude":37.2776225347006,"longitude":127.015801412399,"bookYear":"2024","nameKR":"루아즈 블랑제리","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":18615,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.35763,"longitude":127.334473,"bookYear":"2024","nameKR":"부산식당","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":34992,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.8369007652307,"longitude":128.540359771016,"bookYear":"2024","nameKR":"연화정삼계탕","ribbonType":"X","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":36305,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.314249247468,"longitude":126.828281208668,"bookYear":"2024","nameKR":"더수플레","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":3007,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.530783314472544,"longitude":127.82042933748808,"bookYear":"2024","nameKR":"동아리식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":36548,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3901576089752,"longitude":127.091797600613,"bookYear":"2024","nameKR":"판교집","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":33841,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1547241491262,"longitude":129.119923556336,"bookYear":"2024","nameKR":"차선책","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35484,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/vinculo_cucu/","foodTypes":"바/주점","latitude":35.1637666061429,"longitude":129.162818057002,"bookYear":"2024","nameKR":"빈끌로","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":6373,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.301999,"longitude":126.861147,"bookYear":"2024","nameKR":"시골순대","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":8137,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.475491,"longitude":126.62878,"bookYear":"2024","nameKR":"인천집","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":1830,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.328796,"longitude":126.788736,"bookYear":"2024","nameKR":"베트남고향식당","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":2140,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.150798,"longitude":126.91354,"bookYear":"2024","nameKR":"영안반점","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":37356,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yongkang_zzie/","foodTypes":"중식","latitude":35.1564007582815,"longitude":129.124226996244,"bookYear":"2024","nameKR":"융캉찌에(광안리점) ","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":33048,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/onewwal/","foodTypes":"디저트/차/베이커리","latitude":35.8117166700283,"longitude":127.152558006972,"bookYear":"2024","nameKR":"오뉴월","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":34998,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.8713182152585,"longitude":128.602302464347,"bookYear":"2024","nameKR":"진성식당","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":34283,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/waffle_rang/","foodTypes":"디저트/차/베이커리","latitude":37.3850358596408,"longitude":127.110998145724,"bookYear":"2024","nameKR":"와플랑","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":36107,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffeelight_roasters","foodTypes":"디저트/차/베이커리","latitude":33.5115249273697,"longitude":126.519189280587,"bookYear":"2024","nameKR":"커피라이트","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":8143,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":36.633069,"longitude":127.488619,"bookYear":"2024","nameKR":"공원당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33711,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mel._u/","foodTypes":"디저트/차/베이커리","latitude":34.9672043783605,"longitude":128.704712634239,"bookYear":"2024","nameKR":"멜유","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":8975,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.84398,"longitude":128.423644,"bookYear":"2024","nameKR":"원조3대충무할매김밥","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":35240,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1558589610766,"longitude":129.064109704721,"bookYear":"2024","nameKR":"야키토리규전포","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":35567,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/woogalivegogi_official/","foodTypes":"한식(육류)","latitude":35.8418046381792,"longitude":128.707576629109,"bookYear":"2024","nameKR":"우가생고기","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":32423,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bistro.on","foodTypes":"바/주점","latitude":37.6772775407263,"longitude":126.812503761924,"bookYear":"2024","nameKR":"비스트로온","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35264,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/grandmabuttumak/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3262972764931,"longitude":127.101516099029,"bookYear":"2024","nameKR":"할머니의 부뚜막","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":33533,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/robatayozi/","foodTypes":"일식","latitude":35.554990103257,"longitude":129.319040575075,"bookYear":"2024","nameKR":"로바타요지 ","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":35967,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.9660067151317,"longitude":127.976576620112,"bookYear":"2024","nameKR":"남촌횟집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":33462,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":34.7899992439615,"longitude":126.383418768384,"bookYear":"2024","nameKR":"태동반점","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":35671,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mkr_coffee/","foodTypes":"디저트/차/베이커리","latitude":34.5256702381235,"longitude":127.135748595358,"bookYear":"2024","nameKR":"mkr커피","ribbonType":"X","zone2_1":"전남","zone2_2":"고흥군","status":"ACTIVE"},
        {"id":36886,"bookStatus":"승인","websiteInstagram":"https://instagram.com/butterandcup_","foodTypes":"기타","latitude":34.8951432248789,"longitude":128.625607944893,"bookYear":"2024","nameKR":"버터앤컵","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":4068,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.102851,"longitude":129.02703,"bookYear":"2024","nameKR":"옥생관","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":32406,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.1364492944565,"longitude":128.207442906028,"bookYear":"2024","nameKR":"송학반장","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":4542,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.896634,"longitude":128.614907,"bookYear":"2024","nameKR":"싱글벙글막창전문점","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":4547,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.882661,"longitude":128.61962,"bookYear":"2024","nameKR":"삼아통닭","ribbonType":"X","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":31469,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thepaparagu","foodTypes":"이탈리아식","latitude":37.3857153001799,"longitude":127.11197622411,"bookYear":"2024","nameKR":"파파라구","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":6048,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4286793306214,"longitude":126.990958708463,"bookYear":"2024","nameKR":"토정","ribbonType":"X","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":36662,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.5761950770131,"longitude":126.594533875173,"bookYear":"2024","nameKR":"소망식당","ribbonType":"X","zone2_1":"전남","zone2_2":"해남군","status":"ACTIVE"},
        {"id":35040,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9723300803282,"longitude":126.734998534186,"bookYear":"2024","nameKR":"군산옥","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":30511,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/limyo_/","foodTypes":"디저트/차/베이커리","latitude":34.8391868224981,"longitude":128.50716785293565,"bookYear":"2024","nameKR":"리묘","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":8141,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.477546,"longitude":126.644718,"bookYear":"2024","nameKR":"창석원조닭알탕","ribbonType":"X","zone2_1":"인천","zone2_2":"동구","status":"ACTIVE"},
        {"id":35311,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4508451518181,"longitude":126.634650103041,"bookYear":"2024","nameKR":"용현장어구이","ribbonType":"X","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":30671,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dongwonkal1995/","foodTypes":"한식(면류)","latitude":36.36276110601318,"longitude":127.37733620407465,"bookYear":"2024","nameKR":"동원칼국수","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":31132,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7574784769327,"longitude":128.892667922832,"bookYear":"2024","nameKR":"현대장칼국수","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":31419,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dal.pasta_/","foodTypes":"이탈리아식","latitude":37.3583199121192,"longitude":126.932291369357,"bookYear":"2024","nameKR":"달보드레파스타","ribbonType":"X","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":21776,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lavite_viva","foodTypes":"이탈리아식","latitude":37.3915531198039,"longitude":127.089555679163,"bookYear":"2024","nameKR":"라비떼","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":22836,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.201269,"longitude":129.089655,"bookYear":"2024","nameKR":"부광반점","ribbonType":"X","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":21623,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0970739269777,"longitude":129.02405890084,"bookYear":"2024","nameKR":"달뜨네","ribbonType":"X","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":1601,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.066392,"longitude":128.171736,"bookYear":"2024","nameKR":"남북면옥","ribbonType":"X","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":36573,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bucksu_dazzi/","foodTypes":"한식(어패류)","latitude":34.8409040729642,"longitude":128.423753182503,"bookYear":"2024","nameKR":"벅수다찌","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":3138,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.827582,"longitude":128.54596,"bookYear":"2024","nameKR":"당인가차이나타운","ribbonType":"X","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":33608,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3376759578669,"longitude":127.927375691123,"bookYear":"2024","nameKR":"수미감자탕","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":5426,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.141513,"longitude":128.214816,"bookYear":"2024","nameKR":"두꺼비식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":5587,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.651757,"longitude":126.942998,"bookYear":"2024","nameKR":"대일정","ribbonType":"X","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":31025,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/__noru","foodTypes":"디저트/차/베이커리","latitude":35.1033825566958,"longitude":129.026488877115,"bookYear":"2024","nameKR":"인앤빈","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":21857,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.081224,"longitude":127.053926,"bookYear":"2024","nameKR":"숯고개부대찌개","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":34182,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/house_planb/","foodTypes":"이탈리아식","latitude":37.2839831053039,"longitude":127.01153648218,"bookYear":"2024","nameKR":"하우스플랜비","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":18876,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.340159,"longitude":127.932584,"bookYear":"2024","nameKR":"동승루","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":29447,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/swlove1008/","foodTypes":"한식(육류)","latitude":35.21048159567992,"longitude":129.0742074963779,"bookYear":"2024","nameKR":"석정갈비","ribbonType":"X","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":8192,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.103642,"longitude":128.962469,"bookYear":"2024","nameKR":"복성반점","ribbonType":"X","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":30903,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hwangnam_maegju/","foodTypes":"바/주점","latitude":35.8359793704024,"longitude":129.210078709672,"bookYear":"2024","nameKR":"황남맥주","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":18243,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":34.739777,"longitude":127.73447,"bookYear":"2024","nameKR":"순심원","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":19642,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/redish_brown_/","foodTypes":"디저트/차/베이커리","latitude":37.386215,"longitude":127.110985,"bookYear":"2024","nameKR":"레디쉬브라운","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":13209,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.470784,"longitude":126.624014,"bookYear":"2024","nameKR":"향원","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":21099,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.642478384075446,"longitude":128.7467726441706,"bookYear":"2024","nameKR":"할매김밥","ribbonType":"X","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":29852,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/rothy__________/","foodTypes":"디저트/차/베이커리","latitude":33.4856887401381,"longitude":126.698872152394,"bookYear":"2024","nameKR":"구좌상회","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":24640,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.822377914611,"longitude":127.146129153689,"bookYear":"2024","nameKR":"수정관","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":32585,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":34.841524317771,"longitude":128.42054993514,"bookYear":"2024","nameKR":"심가네해물짬뽕","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":33379,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/celebmandoo/","foodTypes":"한식(면류)","latitude":36.6286196654614,"longitude":127.49062174721,"bookYear":"2024","nameKR":"육거리소문난만두","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":12477,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.152326,"longitude":129.024672,"bookYear":"2024","nameKR":"양가손만두","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":30813,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.2017753830849,"longitude":128.590242372179,"bookYear":"2024","nameKR":"양반댁함흥냉면","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":34963,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.3724633261417,"longitude":127.339830318037,"bookYear":"2024","nameKR":"오한순손수제비","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":3028,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":38.20452,"longitude":128.588847,"bookYear":"2024","nameKR":"동해순대국집","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":3253,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.153796,"longitude":127.116551,"bookYear":"2024","nameKR":"관악장","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":23052,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.860567,"longitude":128.645394,"bookYear":"2024","nameKR":"명동생고기","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":33476,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jayu.bakeshop/","foodTypes":"디저트/차/베이커리","latitude":37.8858353771698,"longitude":127.752166583277,"bookYear":"2024","nameKR":"자유빵집","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":34209,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.109170691938,"longitude":128.421427074412,"bookYear":"2024","nameKR":"외할머니가","ribbonType":"X","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":4689,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.428956,"longitude":126.991428,"bookYear":"2024","nameKR":"통나무집","ribbonType":"X","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":31531,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chadam_jung/","foodTypes":"디저트/차/베이커리","latitude":37.444577786539,"longitude":126.704943993861,"bookYear":"2024","nameKR":"차담정","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":25510,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.754505,"longitude":128.892756,"bookYear":"2024","nameKR":"벌집","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":17769,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.830618,"longitude":129.216385,"bookYear":"2024","nameKR":"박용자경주명동쫄면","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":37259,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8416165098695,"longitude":129.214431797187,"bookYear":"2024","nameKR":"경주콩나물국밥","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":15807,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.47156,"longitude":126.624978,"bookYear":"2024","nameKR":"마냥집","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":27866,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.880083,"longitude":128.586895,"bookYear":"2024","nameKR":"진미생고기","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":25535,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.302364,"longitude":126.852555,"bookYear":"2024","nameKR":"연안생태찌개","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":8253,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.844815,"longitude":128.603786,"bookYear":"2024","nameKR":"김천식당","ribbonType":"X","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":30908,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ninbe_patbingsu/","foodTypes":"디저트/차/베이커리","latitude":37.9700818854581,"longitude":128.761501603163,"bookYear":"2024","nameKR":"닌베","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":14923,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.5656182491623,"longitude":128.727925370228,"bookYear":"2024","nameKR":"중앙찜닭","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":30619,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.31444361148266,"longitude":127.58065009657045,"bookYear":"2024","nameKR":"문정식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":23194,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.870029,"longitude":128.580542,"bookYear":"2024","nameKR":"합천할매손칼국수","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":2111,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8253,"longitude":128.625526,"bookYear":"2024","nameKR":"중앙식육식당","ribbonType":"X","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":32559,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.1522240016999,"longitude":127.069880176558,"bookYear":"2024","nameKR":"대흥식당","ribbonType":"X","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":2925,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.871628,"longitude":128.579636,"bookYear":"2024","nameKR":"대풍반점","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":18261,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":38.35482643902393,"longitude":128.51064762713085,"bookYear":"2024","nameKR":"수성반점","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":4070,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.102567,"longitude":129.034251,"bookYear":"2024","nameKR":"화국반점","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":16068,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":34.84209138899284,"longitude":128.42348961351627,"bookYear":"2024","nameKR":"강변실비","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":5525,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":35.892668,"longitude":128.609183,"bookYear":"2024","nameKR":"인도방랑기","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":31450,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chef_hsl/","foodTypes":"이탈리아식","latitude":35.0691748976658,"longitude":129.078172065646,"bookYear":"2024","nameKR":"토로","ribbonType":"X","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":6132,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.251103293481,"longitude":126.560475427576,"bookYear":"2024","nameKR":"용이식당","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":19843,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.87630977058431,"longitude":128.60543016659992,"bookYear":"2024","nameKR":"보문손칼국수","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":6812,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.4812755845228,"longitude":126.484940446305,"bookYear":"2024","nameKR":"명당양과","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":31527,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.1144924399674,"longitude":129.038527521843,"bookYear":"2024","nameKR":"마가만두","ribbonType":"X","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":22314,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.09596420520529,"longitude":129.02463156843092,"bookYear":"2024","nameKR":"신흥반점","ribbonType":"X","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":32433,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.144173386034,"longitude":129.110490077736,"bookYear":"2024","nameKR":"남천낙지","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":2155,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.15800101908962,"longitude":126.91952792853603,"bookYear":"2024","nameKR":"할매추어탕","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":2161,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8715351879169,"longitude":128.596972626153,"bookYear":"2024","nameKR":"낙원식당","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":3153,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.139877,"longitude":126.882346,"bookYear":"2024","nameKR":"광명식당","ribbonType":"X","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":3358,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.872044,"longitude":128.581749,"bookYear":"2024","nameKR":"옛집식당","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":4053,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.503376,"longitude":126.52778,"bookYear":"2024","nameKR":"감초식당","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":4518,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.84275312778289,"longitude":129.21757969902583,"bookYear":"2024","nameKR":"서라벌찰보리빵","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":4591,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.499657,"longitude":126.537568,"bookYear":"2024","nameKR":"남춘식당","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":6014,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.8614544674523,"longitude":128.591540421698,"bookYear":"2024","nameKR":"남문납짝만두","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":6068,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.258423,"longitude":127.041625,"bookYear":"2024","nameKR":"진미회관","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":27136,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.857182,"longitude":128.61611,"bookYear":"2024","nameKR":"본전식당","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":7410,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.821069,"longitude":128.641368,"bookYear":"2024","nameKR":"벙글벙글식당","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":8145,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.449803,"longitude":127.431586,"bookYear":"2024","nameKR":"영화반점","ribbonType":"X","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":8922,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3514673762597,"longitude":127.946089410227,"bookYear":"2024","nameKR":"박순례손말이고기산정집","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":9116,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.855288,"longitude":128.589796,"bookYear":"2024","nameKR":"프라리네","ribbonType":"X","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":10815,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.563478,"longitude":128.729493,"bookYear":"2024","nameKR":"골목안손국수","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":20471,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.0281431977222,"longitude":129.360006122844,"bookYear":"2024","nameKR":"형제통닭","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":2029,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.188981,"longitude":128.56161,"bookYear":"2024","nameKR":"양지통술","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":32213,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hasul9096/","foodTypes":"바/주점","latitude":37.6237024658783,"longitude":127.152464010121,"bookYear":"2024","nameKR":"하술","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":30067,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/analog_society","foodTypes":"바/주점","latitude":37.77057538703522,"longitude":128.87748944826856,"bookYear":"2024","nameKR":"아날로그소사이어티","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":34386,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1513844653032,"longitude":126.924379825371,"bookYear":"2024","nameKR":"황톳길","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":32514,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/__euddem_bs/","foodTypes":"일식","latitude":35.1418930768257,"longitude":129.109187982387,"bookYear":"2024","nameKR":"으뜸이로리바타","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":5541,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.55338281824518,"longitude":129.33324453039165,"bookYear":"2024","nameKR":"엄지식육식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":23065,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/izakaya_inha/","foodTypes":"바/주점","latitude":37.765044,"longitude":128.878828,"bookYear":"2024","nameKR":"인하선생","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33354,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/orot_izakaya/","foodTypes":"바/주점","latitude":37.6148032731199,"longitude":126.834456864566,"bookYear":"2024","nameKR":"오롯","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":7167,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.155617,"longitude":129.056739,"bookYear":"2024","nameKR":"산성목살","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":32513,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/busan_sosuin/","foodTypes":"바/주점","latitude":35.1566786108333,"longitude":129.053834676446,"bookYear":"2024","nameKR":"소수인 ","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":18711,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4585699553006,"longitude":126.647317282461,"bookYear":"2024","nameKR":"부영선지국","ribbonType":"X","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":26980,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2089774430763,"longitude":127.46866255061,"bookYear":"2024","nameKR":"봉성식당","ribbonType":"X","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":29901,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushi_taroo/","foodTypes":"일식","latitude":35.25437248968075,"longitude":128.62015462840264,"bookYear":"2024","nameKR":"스시타로","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":30034,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bubu0325_","foodTypes":"바/주점","latitude":37.667262879074514,"longitude":126.77226247751234,"bookYear":"2024","nameKR":"부부0325","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":30892,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.6460073269165,"longitude":126.787781456786,"bookYear":"2024","nameKR":"이노루","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":34058,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/midang_2815/","foodTypes":"한식(일반한식)","latitude":37.4906796202749,"longitude":126.7559912584,"bookYear":"2024","nameKR":"미당맷돌순두부","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":25948,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8093457240916,"longitude":128.54785860776622,"bookYear":"2024","nameKR":"석봉포차","ribbonType":"X","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":32472,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1788738276068,"longitude":128.10421582591,"bookYear":"2024","nameKR":"뱅해이네","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":22399,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.232592,"longitude":128.690833,"bookYear":"2024","nameKR":"원조판문점","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":2127,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.139964,"longitude":126.860993,"bookYear":"2024","nameKR":"갯마을산장어숯불구이","ribbonType":"X","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":2237,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.33360303852199,"longitude":127.4275435511829,"bookYear":"2024","nameKR":"왕관식당","ribbonType":"X","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":36437,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8141871877546,"longitude":127.119614501199,"bookYear":"2024","nameKR":"원조치마살숯불구이","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":32400,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":33.5010097979914,"longitude":126.53072136746,"bookYear":"2024","nameKR":"유일반점","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":6141,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.991127,"longitude":127.106293,"bookYear":"2024","nameKR":"석일식당","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":25026,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.866873,"longitude":128.59238,"bookYear":"2024","nameKR":"뉴욕통닭","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":31111,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.4711436177277,"longitude":126.626419399964,"bookYear":"2024","nameKR":"제이콥스핏제리아","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":25330,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.470892,"longitude":126.626985,"bookYear":"2024","nameKR":"다복집","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":31436,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chaguncoffee/","foodTypes":"디저트/차/베이커리","latitude":35.4764926352065,"longitude":128.765103790098,"bookYear":"2024","nameKR":"차군커피로스터스","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":31841,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seomoon_noodle/","foodTypes":"한식(면류)","latitude":35.2291085575241,"longitude":129.088301385596,"bookYear":"2024","nameKR":"서문국수","ribbonType":"X","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":7815,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/psk1670/","foodTypes":"한식(일반한식)","latitude":35.869545134849595,"longitude":128.59696357366542,"bookYear":"2024","nameKR":"중앙떡볶이","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":8204,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.1061560853273,"longitude":129.034868802202,"bookYear":"2024","nameKR":"석기시대","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":36787,"bookStatus":"승인","websiteInstagram":"http://www.Instagram.com/dabo_ong","foodTypes":"일식","latitude":34.8743211672184,"longitude":128.689748628979,"bookYear":"2024","nameKR":"룡소","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":33514,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.140635537107,"longitude":129.061945282144,"bookYear":"2024","nameKR":"황금고래촌","ribbonType":"X","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":36928,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ramen_myojo/","foodTypes":"일식","latitude":36.0815392723019,"longitude":129.395541310826,"bookYear":"2024","nameKR":"라멘묘조","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":31260,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.0063776837604,"longitude":127.662039609207,"bookYear":"2024","nameKR":"카페로뎀","ribbonType":"X","zone2_1":"전북","zone2_2":"무주군","status":"ACTIVE"},
        {"id":2594,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.483766,"longitude":126.484138,"bookYear":"2024","nameKR":"순화국수","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":2832,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.068531,"longitude":128.171603,"bookYear":"2024","nameKR":"일미장","ribbonType":"X","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":29830,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.85500919816055,"longitude":128.55370944245067,"bookYear":"2024","nameKR":"진양식당","ribbonType":"X","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":37592,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":36.3591447687972,"longitude":127.376885115414,"bookYear":"2024","nameKR":"토미야","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":34986,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.8655442723904,"longitude":128.588623122239,"bookYear":"2024","nameKR":"미꾸리추어탕","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":34975,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.3606148997181,"longitude":128.152282614824,"bookYear":"2024","nameKR":"지천통나무집","ribbonType":"X","zone2_1":"경북","zone2_2":"상주시","status":"ACTIVE"},
        {"id":30627,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.003151553915444,"longitude":128.41185273462673,"bookYear":"2024","nameKR":"만리궁","ribbonType":"X","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":31145,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.0890038263757,"longitude":129.390226799571,"bookYear":"2024","nameKR":"온정가","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":8108,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.064722,"longitude":127.065978,"bookYear":"2024","nameKR":"쌍흥원","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":8132,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.47303129307372,"longitude":126.63128917176078,"bookYear":"2024","nameKR":"새집칼국수","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":8236,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.87622349258235,"longitude":128.60379415549093,"bookYear":"2024","nameKR":"정화네하우스","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":31452,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/changwon_macaron/","foodTypes":"디저트/차/베이커리","latitude":35.2230017613157,"longitude":128.677106503449,"bookYear":"2024","nameKR":"그해창원","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":33171,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/flower.garam7","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1099991150563,"longitude":129.038548379857,"bookYear":"2024","nameKR":"꽃가람 ","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":20295,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.845386,"longitude":128.599255,"bookYear":"2024","nameKR":"금성반점","ribbonType":"X","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":34832,"bookStatus":"승인","websiteInstagram":"http://instagram.com/italy_samchon","foodTypes":"일식","latitude":35.2295020851607,"longitude":129.092828733477,"bookYear":"2024","nameKR":"이태리삼촌","ribbonType":"X","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":31684,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1896888304025,"longitude":129.077958753952,"bookYear":"2024","nameKR":"부산밀면거제옥","ribbonType":"X","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":27544,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":38.200897,"longitude":128.581927,"bookYear":"2024","nameKR":"금성각","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":30760,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/breadmonster_gyeongju/","foodTypes":"디저트/차/베이커리","latitude":35.8679589861561,"longitude":129.214273020484,"bookYear":"2024","nameKR":"브래드몬스터","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":21838,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.219156,"longitude":128.703524,"bookYear":"2024","nameKR":"프랑스베이커리","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":19378,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.48121,"longitude":126.489157,"bookYear":"2024","nameKR":"커피코알라","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":35231,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.7948061518864,"longitude":127.134390625178,"bookYear":"2024","nameKR":"토방","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":30303,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/juan.augustinus","foodTypes":"기타","latitude":37.3898706874902,"longitude":127.09195974832,"bookYear":"2024","nameKR":"후안즈","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":29972,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_syong/","foodTypes":"디저트/차/베이커리","latitude":33.2663484357197,"longitude":126.642771183007,"bookYear":"2024","nameKR":"공천포카페숑","ribbonType":"X","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":5365,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.296644,"longitude":127.012349,"bookYear":"2024","nameKR":"그집해장국","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":30961,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1965101697623,"longitude":126.840986151718,"bookYear":"2024","nameKR":"구름곶","ribbonType":"X","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38768,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gilboat_club/","foodTypes":"디저트/차/베이커리","latitude":33.5089889087524,"longitude":126.521651586172,"bookYear":"2024","nameKR":"길보트","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":6054,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.471533,"longitude":126.622836,"bookYear":"2024","nameKR":"중앙옥","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":12613,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.56217256259864,"longitude":126.8550311188752,"bookYear":"2024","nameKR":"화순옥","ribbonType":"X","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":6807,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.824941,"longitude":128.625081,"bookYear":"2024","nameKR":"태극당","ribbonType":"X","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":6811,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.206801,"longitude":128.576765,"bookYear":"2024","nameKR":"코아양과","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":26936,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.4630046258352,"longitude":126.933340547749,"bookYear":"2024","nameKR":"해뜨는식당","ribbonType":"X","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":8003,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.472042,"longitude":126.622707,"bookYear":"2024","nameKR":"명월집","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":8224,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.889028,"longitude":128.574629,"bookYear":"2024","nameKR":"자갈마당복어","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":30882,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/breadandbutter_croissant?utm_source=ig_profile_share&igshid=x6xlnrdvwww","foodTypes":"디저트/차/베이커리","latitude":35.5465114976441,"longitude":129.311709079169,"bookYear":"2024","nameKR":"브레드앤버터크로아상","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":27127,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/boheme_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.479644,"longitude":126.484628,"bookYear":"2024","nameKR":"보엠","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":1840,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":37.32898,"longitude":126.789622,"bookYear":"2024","nameKR":"사마르칸트(안산점)","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":32914,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/plus33__/","foodTypes":"프랑스식","latitude":35.2407549254859,"longitude":128.68121965963,"bookYear":"2024","nameKR":"플러스33","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":2556,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.9389289658307,"longitude":127.167232027463,"bookYear":"2024","nameKR":"할머니국수집","ribbonType":"X","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":2929,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.85745590155795,"longitude":128.5808470605009,"bookYear":"2024","nameKR":"미성당납작만두","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":4044,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.149626,"longitude":126.944742,"bookYear":"2024","nameKR":"온천할머니집","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":4308,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.471428,"longitude":126.6265,"bookYear":"2024","nameKR":"산동만두","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":25902,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.232584,"longitude":129.084914,"bookYear":"2024","nameKR":"칠칠켄터키","ribbonType":"X","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":5532,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.843532,"longitude":128.600662,"bookYear":"2024","nameKR":"낙산가든","ribbonType":"X","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":6384,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.064975,"longitude":127.066444,"bookYear":"2024","nameKR":"평양면옥","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":30150,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/vivaracho12/","foodTypes":"바/주점","latitude":35.168758635973724,"longitude":129.17845238670395,"bookYear":"2024","nameKR":"비바라쵸","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":8989,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.0402566352537,"longitude":129.366855740444,"bookYear":"2024","nameKR":"로타리냉면","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":26255,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.64952742417464,"longitude":126.64268361763533,"bookYear":"2024","nameKR":"한성치킨","ribbonType":"X","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":10604,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.151019,"longitude":128.660497,"bookYear":"2024","nameKR":"고려갈비","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":31349,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dodoplate/","foodTypes":"디저트/차/베이커리","latitude":35.1765978304649,"longitude":128.807540189871,"bookYear":"2024","nameKR":"도도플레이트","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":36732,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.2122894607674,"longitude":128.710246047596,"bookYear":"2024","nameKR":"달콤비니하우스","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":31619,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.4994075109362,"longitude":126.72161530643,"bookYear":"2024","nameKR":"뉴욕반점","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":31537,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bistro_karratha/","foodTypes":"이탈리아식","latitude":35.9523967022123,"longitude":128.567430188311,"bookYear":"2024","nameKR":"비스트로카라사","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":36336,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4926649571862,"longitude":126.726444913942,"bookYear":"2024","nameKR":"오잠봉","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":32401,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dalbich_angye/","foodTypes":"기타","latitude":36.3842938807273,"longitude":128.43575778255,"bookYear":"2024","nameKR":"달빛레스토랑","ribbonType":"X","zone2_1":"경북","zone2_2":"의성군","status":"ACTIVE"},
        {"id":30503,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seito_udon/","foodTypes":"일식","latitude":35.82346754138147,"longitude":127.1033019464559,"bookYear":"2024","nameKR":"세이토","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29492,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/achaga_official/","foodTypes":"디저트/차/베이커리","latitude":36.564904263131844,"longitude":128.7312728723915,"bookYear":"2024","nameKR":"아차가","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":8237,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.869298,"longitude":128.594421,"bookYear":"2024","nameKR":"중화반점","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":31614,"bookStatus":"승인","websiteInstagram":"http://www.instagram.com/hwayanggaock","foodTypes":"이탈리아식","latitude":37.2695484247883,"longitude":127.007182472159,"bookYear":"2024","nameKR":"화양가옥수원역점","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":30620,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.98445601812994,"longitude":128.36960409772306,"bookYear":"2024","nameKR":"복생반점","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":34320,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/season.storage/","foodTypes":"한식(일반한식)","latitude":37.2822455156544,"longitude":127.017601061177,"bookYear":"2024","nameKR":"계절곳간","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":32372,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.179148802848,"longitude":126.806924599572,"bookYear":"2024","nameKR":"유향","ribbonType":"X","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":2783,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.100375,"longitude":128.98203,"bookYear":"2024","nameKR":"오사카","ribbonType":"X","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":3068,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.005900745394435,"longitude":127.27202102158691,"bookYear":"2024","nameKR":"영흥루","ribbonType":"X","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":31081,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.4803249508461,"longitude":126.620511682489,"bookYear":"2024","nameKR":"혜빈장","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":5478,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.565233,"longitude":127.509411,"bookYear":"2024","nameKR":"고은정육점삼흥집 ","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":6455,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.758348,"longitude":128.893039,"bookYear":"2024","nameKR":"원조강릉교동반점본점","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":7169,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.988806,"longitude":126.713991,"bookYear":"2024","nameKR":"빈해원","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":8107,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.995628,"longitude":127.085183,"bookYear":"2024","nameKR":"개화식당","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":8245,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.866735,"longitude":128.594127,"bookYear":"2024","nameKR":"태산만두","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":34704,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.3907470164198,"longitude":127.076538502335,"bookYear":"2024","nameKR":"안안","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":28534,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.27740597717071,"longitude":127.45456681238676,"bookYear":"2024","nameKR":"온정손만두","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":30853,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1231064484569,"longitude":129.113322859182,"bookYear":"2024","nameKR":"약콩밀면","ribbonType":"X","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":26037,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.348634,"longitude":126.593944,"bookYear":"2024","nameKR":"한울타리","ribbonType":"X","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":32354,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.5517266785325,"longitude":129.300062089848,"bookYear":"2024","nameKR":"만당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":31618,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.2997121213949,"longitude":127.567760590943,"bookYear":"2024","nameKR":"풍미당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":30810,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gastro___rock/","foodTypes":"유럽식","latitude":35.8596440830779,"longitude":128.606317752257,"bookYear":"2024","nameKR":"가스트로락","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":30816,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/biscuit_table","foodTypes":"디저트/차/베이커리","latitude":37.7373676374438,"longitude":127.048002050424,"bookYear":"2024","nameKR":"비스킷테이블","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":4468,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.8918384389087,"longitude":128.828165080865,"bookYear":"2024","nameKR":"월성식당","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":4550,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.854875,"longitude":128.545662,"bookYear":"2024","nameKR":"김주연왕족발","ribbonType":"X","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":37423,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.5456526321256,"longitude":129.329882404359,"bookYear":"2024","nameKR":"부조화","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":31823,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bar_di.lan","foodTypes":"바/주점","latitude":35.1487533393925,"longitude":129.114023309705,"bookYear":"2024","nameKR":"바딜란","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38264,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1518665091161,"longitude":126.916416294973,"bookYear":"2024","nameKR":"박순자녹두집 ","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":31328,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jootopia_daegu","foodTypes":"이탈리아식","latitude":35.8658929023221,"longitude":128.611515870775,"bookYear":"2024","nameKR":"주토피아","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":29725,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cookie_moonlight","foodTypes":"디저트/차/베이커리","latitude":37.6706530214042,"longitude":126.782934247352,"bookYear":"2024","nameKR":"달빛과자상점","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":27036,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.562936,"longitude":128.731948,"bookYear":"2024","nameKR":"일직식당","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":22907,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.136465,"longitude":128.025659,"bookYear":"2024","nameKR":"대흥식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":33956,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/unagi_yoon","foodTypes":"일식","latitude":35.5304859680361,"longitude":129.30647036557,"bookYear":"2024","nameKR":"윤우나기","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":29775,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yangin_bakeshop/","foodTypes":"디저트/차/베이커리","latitude":35.138643006235355,"longitude":126.91373136812179,"bookYear":"2024","nameKR":"양인제과","ribbonType":"X","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":27639,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.667212,"longitude":127.248391,"bookYear":"2024","nameKR":"바타르","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":28506,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6907725678782,"longitude":127.886421025546,"bookYear":"2024","nameKR":"풍년식당","ribbonType":"X","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":22722,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4457987636476,"longitude":129.163016340373,"bookYear":"2024","nameKR":"맛과향이있는집","ribbonType":"X","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":32383,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.2746022216878,"longitude":127.019038893742,"bookYear":"2024","nameKR":"평장원","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":29902,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_sushi_ann/","foodTypes":"일식","latitude":33.4965502751994,"longitude":126.534600587297,"bookYear":"2024","nameKR":"스시앤","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":35415,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/buhne_coffeebar/","foodTypes":"바/주점","latitude":36.8095814698202,"longitude":128.609784364058,"bookYear":"2024","nameKR":"뷔네커피바","ribbonType":"X","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":4128,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.0814079574376,"longitude":127.053096893116,"bookYear":"2024","nameKR":"미스리햄버거","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":31828,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/rose_soju/","foodTypes":"한식(육류)","latitude":35.8718680276123,"longitude":128.598851973168,"bookYear":"2024","nameKR":"장미","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":4464,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.2031016607351,"longitude":128.593781824102,"bookYear":"2024","nameKR":"아바이식당","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":5383,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.267167,"longitude":127.001695,"bookYear":"2024","nameKR":"명산식당","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35465,"bookStatus":"승인","websiteInstagram":"https://instagram.com/le.minyong_","foodTypes":"디저트/차/베이커리","latitude":36.6235518182649,"longitude":127.517873270795,"bookYear":"2024","nameKR":"르미뇽","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36632,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bakeryyulkyo_p3120","foodTypes":"디저트/차/베이커리","latitude":37.4003659710472,"longitude":126.65076958299,"bookYear":"2024","nameKR":"베이커리율교P3120","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":12536,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.0587734932269,"longitude":129.374622662076,"bookYear":"2024","nameKR":"한스드림베이커리","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":31746,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ahneu.official","foodTypes":"디저트/차/베이커리","latitude":35.8125247432672,"longitude":127.149325865432,"bookYear":"2024","nameKR":"아느양과","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":9000,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.985378,"longitude":127.305759,"bookYear":"2024","nameKR":"조계산보리밥집","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":30026,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.93830391233327,"longitude":126.95234161947066,"bookYear":"2024","nameKR":"고려당","ribbonType":"X","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":30965,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.9686361739517,"longitude":128.761890467767,"bookYear":"2024","nameKR":"플리즈웨잇","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":31829,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/brewersbrothers/","foodTypes":"바/주점","latitude":35.8685105357459,"longitude":128.591715748706,"bookYear":"2024","nameKR":"브루어스브라더스","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":28472,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.826332,"longitude":127.114096,"bookYear":"2024","nameKR":"옛촌막걸리","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2300,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.099456,"longitude":129.03203,"bookYear":"2024","nameKR":"고갈비남마담","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":33342,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ohorijoon_official/","foodTypes":"바/주점","latitude":35.8696716772914,"longitude":128.593223207554,"bookYear":"2024","nameKR":"오호리준","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":29846,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/maraedang","foodTypes":"디저트/차/베이커리","latitude":35.819324759708785,"longitude":127.14264194365298,"bookYear":"2024","nameKR":"마래당","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":34086,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pizzaplz_1","foodTypes":"미국식","latitude":37.4932414747495,"longitude":126.726390794891,"bookYear":"2024","nameKR":"피플즈","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":4564,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"바/주점","latitude":34.859589,"longitude":128.426527,"bookYear":"2024","nameKR":"토담실비","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":5965,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8260456406814,"longitude":127.140800521711,"bookYear":"2024","nameKR":"오원집","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":5975,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.797172,"longitude":127.122787,"bookYear":"2024","nameKR":"용진집막걸리 ","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":8124,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4629495610559,"longitude":126.643858354501,"bookYear":"2024","nameKR":"마산집","ribbonType":"X","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":37380,"bookStatus":"승인","websiteInstagram":"http://www.instagram.com/sinpochoigozip","foodTypes":"한식(육류)","latitude":37.471904214057,"longitude":126.623513594139,"bookYear":"2024","nameKR":"최고집(신포본점)","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":31814,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/all_knowing.official","foodTypes":"디저트/차/베이커리","latitude":35.8713784404074,"longitude":128.599629991522,"bookYear":"2024","nameKR":"어노잉","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":32490,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7614500391767,"longitude":127.6711022792,"bookYear":"2024","nameKR":"돌섬선어","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":30885,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.5370398407719,"longitude":129.329785430959,"bookYear":"2024","nameKR":"세비로","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":2199,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.830817610663,"longitude":128.705367336552,"bookYear":"2024","nameKR":"전라도꽃게장","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":32596,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_first_kitchen_?igsh=c3NlZmJpZmd2Mzd4&utm_source=qr","foodTypes":"이탈리아식","latitude":37.4952997649006,"longitude":126.723966154118,"bookYear":"2024","nameKR":"첫번째부엌","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":33315,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.8821974033704,"longitude":127.726580500034,"bookYear":"2024","nameKR":"육림닭강정","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":17761,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.209028,"longitude":127.468359,"bookYear":"2024","nameKR":"동아식당","ribbonType":"X","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":6006,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.124252,"longitude":129.111101,"bookYear":"2024","nameKR":"할매팥빙수단팥죽","ribbonType":"X","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":6056,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.4708388009468,"longitude":126.623097732013,"bookYear":"2024","nameKR":"등대경양식","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":6135,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.475104,"longitude":126.618803,"bookYear":"2024","nameKR":"십리향","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":29475,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.15548229516693,"longitude":129.05687322949333,"bookYear":"2024","nameKR":"오스테리아라치베타","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":29898,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/moonsushi_official/","foodTypes":"일식","latitude":35.17028789735641,"longitude":129.1661447578186,"bookYear":"2024","nameKR":"문스시","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":29499,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/budnamu_brewery/","foodTypes":"바/주점","latitude":37.748143,"longitude":128.884472,"bookYear":"2024","nameKR":"버드나무브루어리","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":14000,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.15937,"longitude":129.15481,"bookYear":"2024","nameKR":"청춘식당","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":31480,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pigbanggu/","foodTypes":"일식","latitude":35.1508750261637,"longitude":129.113939961626,"bookYear":"2024","nameKR":"고향카츠","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":27897,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.866785,"longitude":128.586735,"bookYear":"2024","nameKR":"금와식당","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":30617,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.67331173607224,"longitude":127.4892822740791,"bookYear":"2024","nameKR":"율량반점","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":30613,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.17526132838543,"longitude":129.33465466292833,"bookYear":"2024","nameKR":"영화춘","ribbonType":"X","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":36730,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.5157682944667,"longitude":126.513999673793,"bookYear":"2024","nameKR":"휴즐리제주","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":2317,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.11339,"longitude":129.038547,"bookYear":"2024","nameKR":"홍성방","ribbonType":"X","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":33569,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shinminhoi_1907/","foodTypes":"디저트/차/베이커리","latitude":35.9862710000215,"longitude":126.709985203459,"bookYear":"2024","nameKR":"카페신민회","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":2504,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.6686204195499,"longitude":127.084186535127,"bookYear":"2024","nameKR":"행낭횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":34406,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/puul__/","foodTypes":"디저트/차/베이커리","latitude":35.1399577224631,"longitude":126.915377801687,"bookYear":"2024","nameKR":"풀","ribbonType":"X","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":2795,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.089414,"longitude":129.040037,"bookYear":"2024","nameKR":"홍복작은면가게","ribbonType":"X","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":33001,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeongdenbab/","foodTypes":"한식(일반한식)","latitude":36.354868667745,"longitude":127.377807808839,"bookYear":"2024","nameKR":"정든밥","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":35050,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.7443385552907,"longitude":127.12154256013,"bookYear":"2024","nameKR":"목향밥상","ribbonType":"X","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":5475,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.653476,"longitude":127.470648,"bookYear":"2024","nameKR":"백로식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":26735,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.1192501757211,"longitude":128.1276432342931,"bookYear":"2024","nameKR":"중국만두","ribbonType":"X","zone2_1":"경북","zone2_2":"김천시","status":"ACTIVE"},
        {"id":31094,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/uhzzu_seafood/","foodTypes":"한식(어패류)","latitude":35.153122801605,"longitude":129.058123816228,"bookYear":"2024","nameKR":"어쭈 ","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":27117,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ladcoffee/","foodTypes":"디저트/차/베이커리","latitude":36.353713,"longitude":127.377275,"bookYear":"2024","nameKR":"라드커피","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":30944,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sulwoldessert/","foodTypes":"디저트/차/베이커리","latitude":35.8350780216531,"longitude":129.21254251317,"bookYear":"2024","nameKR":"설월","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30938,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_1894/","foodTypes":"디저트/차/베이커리","latitude":35.8361881565132,"longitude":129.211918606674,"bookYear":"2024","nameKR":"1894사랑채","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":22156,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.613827,"longitude":127.28972,"bookYear":"2024","nameKR":"홍카페","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":30794,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/miga_sujebi/","foodTypes":"한식(면류)","latitude":37.3867307725774,"longitude":127.121976391707,"bookYear":"2024","nameKR":"미가들깨수제비충무김밥","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":2251,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.098974921728434,"longitude":129.02846490014426,"bookYear":"2024","nameKR":"18번완당집","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":25625,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.977302813597,"longitude":128.652527183443,"bookYear":"2024","nameKR":"일월식당","ribbonType":"X","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":2599,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":33.513455,"longitude":126.526115,"bookYear":"2024","nameKR":"아주반점","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":2880,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.791846,"longitude":127.525411,"bookYear":"2024","nameKR":"섬향기","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":4129,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.080407299815285,"longitude":127.05003269392468,"bookYear":"2024","nameKR":"미스진햄버거","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":36820,"bookStatus":"승인","websiteInstagram":"http://instagram.com/dia_feliz____","foodTypes":"디저트/차/베이커리","latitude":35.0725211881687,"longitude":129.016203053201,"bookYear":"2024","nameKR":"디아펠리즈","ribbonType":"X","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":29673,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.81397071354361,"longitude":127.1490456407199,"bookYear":"2024","nameKR":"풍남정","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":6574,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.99108,"longitude":129.55621,"bookYear":"2024","nameKR":"까꾸네모리국수","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":29850,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.513221408059,"longitude":126.706033593835,"bookYear":"2024","nameKR":"카페세바","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":30625,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.451801186024504,"longitude":126.8029641881872,"bookYear":"2024","nameKR":"태풍루","ribbonType":"X","zone2_1":"충남","zone2_2":"청양군","status":"ACTIVE"},
        {"id":29956,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/udo_blancrocher","foodTypes":"디저트/차/베이커리","latitude":33.5156836104419,"longitude":126.957910049012,"bookYear":"2024","nameKR":"블랑로쉐","ribbonType":"X","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":8251,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.863897,"longitude":128.618249,"bookYear":"2024","nameKR":"허대구대구통닭","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":31406,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_breadman__","foodTypes":"디저트/차/베이커리","latitude":37.75516798062,"longitude":128.899497837662,"bookYear":"2024","nameKR":"만동제과","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":28384,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.566535,"longitude":126.977969,"bookYear":"2024","nameKR":"안젤라분식","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":27851,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.813773,"longitude":127.151036,"bookYear":"2024","nameKR":"교동고로케","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":27908,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.64821,"longitude":127.477134,"bookYear":"2024","nameKR":"아성청국장","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":30949,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.0989350141599,"longitude":129.031224287784,"bookYear":"2024","nameKR":"할매가야밀면","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":30902,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kodocoffeebar/","foodTypes":"디저트/차/베이커리","latitude":35.8379151577705,"longitude":129.210427438113,"bookYear":"2024","nameKR":"고도커피","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30901,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hyanghwajung_gj","foodTypes":"한식(일반한식)","latitude":35.8380366087077,"longitude":129.209183921076,"bookYear":"2024","nameKR":"향화정","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31425,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.328978815662,"longitude":126.789325290286,"bookYear":"2024","nameKR":"디유히엔콴","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":28383,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.82123187418822,"longitude":127.14260109140058,"bookYear":"2024","nameKR":"동영커피","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":36812,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_hail_le","foodTypes":"디저트/차/베이커리","latitude":35.0789290758782,"longitude":129.044545567586,"bookYear":"2024","nameKR":"헤일르","ribbonType":"X","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":2194,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/imosigdang/","foodTypes":"한식(육류)","latitude":35.872228,"longitude":128.586906,"bookYear":"2024","nameKR":"이모식당","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":34858,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/froasters/","foodTypes":"디저트/차/베이커리","latitude":35.1529654593889,"longitude":129.068259307226,"bookYear":"2024","nameKR":"프랭클린커피로스터스","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":25623,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.549312122986095,"longitude":128.5266430570897,"bookYear":"2024","nameKR":"하회민속식당","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":3334,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.738892,"longitude":127.049141,"bookYear":"2024","nameKR":"수흥부대찌개","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":12510,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.869918,"longitude":128.59707,"bookYear":"2024","nameKR":"T클래스커피","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":5236,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.099883,"longitude":129.030524,"bookYear":"2024","nameKR":"남포수제비","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":30951,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wolsung.dessert/","foodTypes":"디저트/차/베이커리","latitude":35.8364476840361,"longitude":129.211941637759,"bookYear":"2024","nameKR":"월성과자점","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":12662,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.718893,"longitude":128.272138,"bookYear":"2024","nameKR":"고령모듬추어탕","ribbonType":"X","zone2_1":"경북","zone2_2":"고령군","status":"ACTIVE"},
        {"id":27952,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.064354,"longitude":129.384066,"bookYear":"2024","nameKR":"환여횟집","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":36811,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yeoulbook","foodTypes":"디저트/차/베이커리","latitude":35.0770491277301,"longitude":129.046602081071,"bookYear":"2024","nameKR":"여울책장","ribbonType":"X","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":29847,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hyojadongcoffeezip_jeonju/","foodTypes":"디저트/차/베이커리","latitude":35.820501597247215,"longitude":127.09779170613321,"bookYear":"2024","nameKR":"효자동커피집","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":7005,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":38.1903417263615,"longitude":128.586870278933,"bookYear":"2024","nameKR":"만석닭강정","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":8146,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.9824810893898,"longitude":126.72242445039994,"bookYear":"2024","nameKR":"중동호떡","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":28199,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.793123,"longitude":126.38132,"bookYear":"2024","nameKR":"가락지","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":13365,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9416411115934,"longitude":126.94944734325,"bookYear":"2024","nameKR":"정순순대","ribbonType":"X","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":31889,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/rollingbrewing/","foodTypes":"디저트/차/베이커리","latitude":33.5131031404698,"longitude":126.529112441801,"bookYear":"2024","nameKR":"롤링브루잉","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":1938,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.16457714008792,"longitude":127.3731742539236,"bookYear":"2024","nameKR":"중앙식당","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":32507,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/naeso_sikdang","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.6114068351609,"longitude":126.588737920253,"bookYear":"2024","nameKR":"내소식당","ribbonType":"X","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":2860,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5493029817463,"longitude":127.57216863809491,"bookYear":"2024","nameKR":"미르","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":29062,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.634417,"longitude":127.45689,"bookYear":"2024","nameKR":"본정","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":3341,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.269812,"longitude":128.266538,"bookYear":"2024","nameKR":"주천찐빵","ribbonType":"X","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":18094,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.035326,"longitude":129.368662,"bookYear":"2024","nameKR":"죽도회대게타운","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":6806,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.873447,"longitude":128.580732,"bookYear":"2024","nameKR":"적두병","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":6809,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.099775,"longitude":128.991045,"bookYear":"2024","nameKR":"쉐라미과자점","ribbonType":"X","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":6810,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.206021,"longitude":128.575427,"bookYear":"2024","nameKR":"고려당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":6999,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.732742,"longitude":127.71998,"bookYear":"2024","nameKR":"등가게장","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":30996,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4741299021105,"longitude":126.632218585494,"bookYear":"2024","nameKR":"인천당","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":7981,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.916892,"longitude":127.129625,"bookYear":"2024","nameKR":"진주회관","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":8153,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.295761,"longitude":127.337392,"bookYear":"2024","nameKR":"천복순대국밥","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":28429,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.843091,"longitude":128.54191,"bookYear":"2024","nameKR":"데일리베이킹스튜디오","ribbonType":"X","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":8233,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wheat_field.bakery/","foodTypes":"디저트/차/베이커리","latitude":35.870458,"longitude":128.596599,"bookYear":"2024","nameKR":"밀밭베이커리","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":8885,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.184372,"longitude":126.832984,"bookYear":"2024","nameKR":"내고향찐빵손만두","ribbonType":"X","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":12495,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.870425,"longitude":128.633773,"bookYear":"2024","nameKR":"르배","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36660,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/rooftop0_","foodTypes":"디저트/차/베이커리","latitude":37.201986645093,"longitude":127.029588738015,"bookYear":"2024","nameKR":"옥탑빵","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":2768,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.48289,"longitude":130.907674,"bookYear":"2024","nameKR":"다애식당","ribbonType":"X","zone2_1":"경북","zone2_2":"울릉군","status":"ACTIVE"},
        {"id":2901,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.8405188077328,"longitude":128.421530308839,"bookYear":"2024","nameKR":"풍화김밥","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":2921,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.6408324349272,"longitude":128.746045302232,"bookYear":"2024","nameKR":"의성식당","ribbonType":"X","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":26694,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/geobuksun_kkulppang/","foodTypes":"디저트/차/베이커리","latitude":0,"longitude":0,"bookYear":"2024","nameKR":"거북선꿀빵","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":3072,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.839666,"longitude":128.420475,"bookYear":"2024","nameKR":"만성복집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":4166,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.5115403957912,"longitude":126.52001621862,"bookYear":"2024","nameKR":"우진해장국","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":4448,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hodoonara.comm/","foodTypes":"디저트/차/베이커리","latitude":36.809090762872586,"longitude":127.14756749891292,"bookYear":"2024","nameKR":"원조천안옛날호두과자","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":12681,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.336032,"longitude":127.392085,"bookYear":"2024","nameKR":"공주떡집","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":5020,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.640393,"longitude":128.746924,"bookYear":"2024","nameKR":"원조청도추어탕고디탕","ribbonType":"X","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":12500,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/may__morning","foodTypes":"디저트/차/베이커리","latitude":35.812251,"longitude":128.547114,"bookYear":"2024","nameKR":"오월의아침(1호점)","ribbonType":"X","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":22413,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.978549,"longitude":126.704941,"bookYear":"2024","nameKR":"영국빵집","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":6173,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.08021,"longitude":127.051347,"bookYear":"2024","nameKR":"정운숯불갈비","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":22401,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.033224,"longitude":129.366621,"bookYear":"2024","nameKR":"오거리곰탕","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":23918,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/naked_bakery_official/","foodTypes":"디저트/차/베이커리","latitude":37.3261834913973,"longitude":127.939938585787,"bookYear":"2024","nameKR":"네이키드베이커리","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":34809,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1013614984089,"longitude":129.034840833733,"bookYear":"2024","nameKR":"산청돼지국밥","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":9013,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.768222,"longitude":127.081553,"bookYear":"2024","nameKR":"보성양탕","ribbonType":"X","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":31004,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yeosudang/","foodTypes":"디저트/차/베이커리","latitude":34.7399016307552,"longitude":127.735318517113,"bookYear":"2024","nameKR":"여수당","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":30611,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.08635002711198,"longitude":126.82669851520683,"bookYear":"2024","nameKR":"수원소갈비해장국","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":30920,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kjw_ksh_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.5442312173592,"longitude":127.316970349647,"bookYear":"2024","nameKR":"곽지원빵공방","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":26355,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.984103,"longitude":128.370972,"bookYear":"2024","nameKR":"그집쏘가리","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":31074,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jinhae.mijin_bakery/","foodTypes":"디저트/차/베이커리","latitude":35.151815090165,"longitude":128.66605694706,"bookYear":"2024","nameKR":"미진과자점","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":27550,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.036943,"longitude":129.364442,"bookYear":"2024","nameKR":"명승원만두","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":23303,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.2315375199814,"longitude":126.296142564681,"bookYear":"2024","nameKR":"춘미향","ribbonType":"X","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":31005,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7563714832078,"longitude":127.716413380851,"bookYear":"2024","nameKR":"동서식당","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":2176,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sayaka2002/","foodTypes":"일식","latitude":35.8706465991564,"longitude":128.598833185885,"bookYear":"2024","nameKR":"사야까","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":2305,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.101868,"longitude":129.035305,"bookYear":"2024","nameKR":"중앙모밀","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":32926,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.4801653453993,"longitude":126.620640246403,"bookYear":"2024","nameKR":"미광","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":20308,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.084588674485,"longitude":127.018830527587,"bookYear":"2024","nameKR":"군남면옥","ribbonType":"X","zone2_1":"경기","zone2_2":"연천군","status":"ACTIVE"},
        {"id":3047,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.519741,"longitude":130.872959,"bookYear":"2024","nameKR":"나리촌식당","ribbonType":"X","zone2_1":"경북","zone2_2":"울릉군","status":"ACTIVE"},
        {"id":4493,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.672014,"longitude":128.709107,"bookYear":"2024","nameKR":"동양식당","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":31838,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nangpoonbabsang","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.4835004392029,"longitude":126.488946467315,"bookYear":"2024","nameKR":"낭푼밥상","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":29374,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.25164,"longitude":126.424616,"bookYear":"2024","nameKR":"나성수두리보말톳칼국수","ribbonType":"X","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":6898,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.442485,"longitude":129.163492,"bookYear":"2024","nameKR":"덕성루","ribbonType":"X","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":27896,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8693584383697,"longitude":128.59422841428,"bookYear":"2024","nameKR":"신라식당","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":8114,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.276822,"longitude":127.015562,"bookYear":"2024","nameKR":"코끼리만두","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":31970,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.1412763503857,"longitude":128.21274606942,"bookYear":"2024","nameKR":"대영식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":8954,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.493528,"longitude":128.753463,"bookYear":"2024","nameKR":"단골집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":18400,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gyodong899/","foodTypes":"디저트/차/베이커리","latitude":37.7611719772364,"longitude":128.89195835293,"bookYear":"2024","nameKR":"교동899","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":10446,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.474746,"longitude":126.597695,"bookYear":"2024","nameKR":"미투커피","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":30939,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/julies_kitchenette/","foodTypes":"이탈리아식","latitude":35.8377469517356,"longitude":129.210509635476,"bookYear":"2024","nameKR":"줄리스","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2284,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.099755,"longitude":129.034785,"bookYear":"2024","nameKR":"섬진강","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":3067,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.007032,"longitude":127.269155,"bookYear":"2024","nameKR":"북경반점","ribbonType":"X","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":3139,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.4747096049514,"longitude":126.618296808744,"bookYear":"2024","nameKR":"풍미","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":4416,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.739889,"longitude":127.733445,"bookYear":"2024","nameKR":"길손식당","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":30623,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.450735526322084,"longitude":126.8059697397609,"bookYear":"2024","nameKR":"영순각","ribbonType":"X","zone2_1":"충남","zone2_2":"청양군","status":"ACTIVE"},
        {"id":4488,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.347232030387424,"longitude":127.94905154739114,"bookYear":"2024","nameKR":"오학닭갈비","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":5327,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.48794061441903,"longitude":128.0724888182896,"bookYear":"2024","nameKR":"광암막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":5381,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2674005491516,"longitude":127.001584347069,"bookYear":"2024","nameKR":"일미순대국","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":29917,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/londontea_ulsan/","foodTypes":"기타","latitude":35.530406074275994,"longitude":129.2958765959802,"bookYear":"2024","nameKR":"런던티","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":21327,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.831102,"longitude":128.572858,"bookYear":"2024","nameKR":"앞산할매손칼국수","ribbonType":"X","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":16147,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.484766,"longitude":126.494767,"bookYear":"2024","nameKR":"모슬포해안도로식당","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":7990,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.801966,"longitude":127.149689,"bookYear":"2024","nameKR":"평양냉면","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":34927,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5071735406897,"longitude":126.751476572166,"bookYear":"2024","nameKR":"약초와산이야기","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":27258,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.9959698284941,"longitude":127.087305842939,"bookYear":"2024","nameKR":"해주탕집","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":30893,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/matsu_yakitori/","foodTypes":"일식","latitude":37.6474587422116,"longitude":126.784365937355,"bookYear":"2024","nameKR":"마쓰야키토리","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":22629,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gimcangnam70/","foodTypes":"중식","latitude":37.485342,"longitude":126.707769,"bookYear":"2024","nameKR":"산동포자","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":18705,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.9898060126325,"longitude":129.553016862057,"bookYear":"2024","nameKR":"철규분식","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":2021,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.187752,"longitude":128.561011,"bookYear":"2024","nameKR":"서호통술","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":2099,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.59513,"longitude":128.19838,"bookYear":"2024","nameKR":"영흥반점","ribbonType":"X","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":2121,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.036509,"longitude":129.363965,"bookYear":"2024","nameKR":"해구식당","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":2425,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.6849459051191,"longitude":125.444685666001,"bookYear":"2024","nameKR":"바다횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"신안군","status":"ACTIVE"},
        {"id":3203,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.16194,"longitude":129.166372,"bookYear":"2024","nameKR":"평안도족발","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":34187,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/trafford_coffeecentre/","foodTypes":"기타","latitude":35.158956477922,"longitude":129.066506063326,"bookYear":"2024","nameKR":"트래포드","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":4484,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.445234,"longitude":129.189194,"bookYear":"2024","nameKR":"항구식당","ribbonType":"X","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":4508,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.1763384490222,"longitude":128.986868601552,"bookYear":"2024","nameKR":"이모네식당","ribbonType":"X","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":4569,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.840794,"longitude":128.419419,"bookYear":"2024","nameKR":"통영식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":32578,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_arch.bakery/","foodTypes":"디저트/차/베이커리","latitude":35.1019130832091,"longitude":129.029979138833,"bookYear":"2024","nameKR":"아치베이커리","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":5382,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.267261,"longitude":127.001742,"bookYear":"2024","nameKR":"아다미식당","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6007,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.124144,"longitude":129.113425,"bookYear":"2024","nameKR":"소문난팥빙수","ribbonType":"X","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":6359,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.876265,"longitude":128.640465,"bookYear":"2024","nameKR":"은예문왕소금구이","ribbonType":"X","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":6990,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.977328,"longitude":126.733398,"bookYear":"2024","nameKR":"금강꽃게장","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":8200,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.129055,"longitude":129.048699,"bookYear":"2024","nameKR":"루반도르","ribbonType":"X","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":8979,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.595736,"longitude":128.202057,"bookYear":"2024","nameKR":"서울만두","ribbonType":"X","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":8993,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.841708,"longitude":129.214455,"bookYear":"2024","nameKR":"밀면식당","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31520,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":33.4868429561724,"longitude":126.48507997337,"bookYear":"2024","nameKR":"십원향","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":31762,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.8782547470076,"longitude":128.606403945976,"bookYear":"2024","nameKR":"수봉반점","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":35900,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/painfamille_official/","foodTypes":"디저트/차/베이커리","latitude":37.8844364402021,"longitude":128.825027405505,"bookYear":"2024","nameKR":"팡파미유","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":26799,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.276116,"longitude":127.042669,"bookYear":"2024","nameKR":"키와마루아지","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":25975,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/1983meat/","foodTypes":"한식(육류)","latitude":35.2180391597713,"longitude":129.0793976572,"bookYear":"2024","nameKR":"1983암돼지갈비","ribbonType":"X","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":2195,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.839635780031,"longitude":128.593128235436,"bookYear":"2024","nameKR":"이태리앤이태리","ribbonType":"X","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":34794,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1501077151386,"longitude":129.114651163228,"bookYear":"2024","nameKR":"바로해장","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":2586,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.490928601764,"longitude":126.499603479789,"bookYear":"2024","nameKR":"삼다가","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":2609,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":33.2449084333718,"longitude":126.563385765954,"bookYear":"2024","nameKR":"덕성원","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":3018,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.607888,"longitude":127.50402,"bookYear":"2024","nameKR":"활력추어탕","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":4574,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.667081477607,"longitude":129.463595607961,"bookYear":"2024","nameKR":"골목횟집","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":27877,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.863638,"longitude":128.612835,"bookYear":"2024","nameKR":"우각정","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":5322,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4722669877635,"longitude":128.722671220118,"bookYear":"2024","nameKR":"청원식당","ribbonType":"X","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":5380,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.283116,"longitude":127.020279,"bookYear":"2024","nameKR":"화청갈비","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":5405,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.82069469573267,"longitude":127.13435512144828,"bookYear":"2024","nameKR":"대안식당","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":5487,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.149144,"longitude":126.914459,"bookYear":"2024","nameKR":"1960청원모밀","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":19573,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.5987713315525,"longitude":127.137404671718,"bookYear":"2024","nameKR":"충북추어탕","ribbonType":"X","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":6071,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.215965,"longitude":127.215974,"bookYear":"2024","nameKR":"연산시장도토리묵","ribbonType":"X","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":6174,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.080443,"longitude":127.05213,"bookYear":"2024","nameKR":"파스타비노","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":6920,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pnjb2611/","foodTypes":"한식(육류)","latitude":37.46382,"longitude":126.715064,"bookYear":"2024","nameKR":"풍년족발","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":27874,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.417338,"longitude":126.672933,"bookYear":"2024","nameKR":"백란","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":7999,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.1305383498779,"longitude":128.97054327193524,"bookYear":"2024","nameKR":"양산도집","ribbonType":"X","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":8974,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":34.844079,"longitude":128.423445,"bookYear":"2024","nameKR":"산양식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":13476,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.79177754615347,"longitude":126.38308625976377,"bookYear":"2024","nameKR":"쑥꿀레","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":16154,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.858851,"longitude":126.786431,"bookYear":"2024","nameKR":"밀밭식당","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":28499,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.102536249007166,"longitude":127.42845342987738,"bookYear":"2024","nameKR":"할머니옛날순대국밥집","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":1621,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.378799,"longitude":128.659879,"bookYear":"2024","nameKR":"동박골식당","ribbonType":"X","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":2350,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.474129,"longitude":126.61942,"bookYear":"2024","nameKR":"복래춘","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":2721,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.977697,"longitude":128.356119,"bookYear":"2024","nameKR":"오학식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":2849,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.195816,"longitude":127.07292,"bookYear":"2024","nameKR":"마리에뜨","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":4555,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.09747970844967,"longitude":129.02992786190097,"bookYear":"2024","nameKR":"김해식당","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":5485,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.100141,"longitude":129.034904,"bookYear":"2024","nameKR":"영빈관","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":5528,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.8644929105412,"longitude":128.588670552093,"bookYear":"2024","nameKR":"유창반점","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":5533,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.869574,"longitude":128.595163,"bookYear":"2024","nameKR":"삼락식당","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":5545,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.2394120709179,"longitude":128.582100746798,"bookYear":"2024","nameKR":"둥지식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":6052,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.429093,"longitude":126.99119,"bookYear":"2024","nameKR":"동성회관","ribbonType":"X","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":6136,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/restaurant_unmelo/","foodTypes":"이탈리아식","latitude":37.284727,"longitude":127.011474,"bookYear":"2024","nameKR":"운멜로","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6197,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.31934,"longitude":128.260544,"bookYear":"2024","nameKR":"의령망개떡","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"의령군","status":"ACTIVE"},
        {"id":6909,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.80266,"longitude":127.127851,"bookYear":"2024","nameKR":"한일한정식","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":6922,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.511420375813344,"longitude":126.7264264716101,"bookYear":"2024","nameKR":"취선","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":8109,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bujadduck2687/","foodTypes":"디저트/차/베이커리","latitude":36.458518,"longitude":127.123809,"bookYear":"2024","nameKR":"부자떡집","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":9015,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.739632,"longitude":127.733117,"bookYear":"2024","nameKR":"원앙식당","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":31997,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/phorest_daegu/","foodTypes":"아시아식","latitude":35.8608009568726,"longitude":128.606049179404,"bookYear":"2024","nameKR":"포레스트","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":25742,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.21015099807278,"longitude":127.47007887907546,"bookYear":"2024","nameKR":"수구레국밥","ribbonType":"X","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":2524,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.46013,"longitude":127.60174,"bookYear":"2024","nameKR":"두꺼비집","ribbonType":"X","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":2766,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.843239,"longitude":128.431237,"bookYear":"2024","nameKR":"한산섬식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":5409,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.78259867717676,"longitude":126.45197680995933,"bookYear":"2024","nameKR":"진국집","ribbonType":"X","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":32465,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1173216815489,"longitude":129.040598629185,"bookYear":"2024","nameKR":"초량밀면","ribbonType":"X","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":6813,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.491583,"longitude":126.488621,"bookYear":"2024","nameKR":"어머니빵집","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":18943,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.868069,"longitude":128.592691,"bookYear":"2024","nameKR":"미도다방","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":19447,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/imhomecafe/","foodTypes":"기타","latitude":37.383983,"longitude":127.111371,"bookYear":"2024","nameKR":"아임홈","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":27953,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.740744,"longitude":127.736033,"bookYear":"2024","nameKR":"진남식당","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":19011,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.223424,"longitude":128.588924,"bookYear":"2024","nameKR":"오봉식당","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":29138,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.49554,"longitude":126.777186,"bookYear":"2024","nameKR":"땅차커피드립바","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":30918,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.8139404189964,"longitude":127.147300634435,"bookYear":"2024","nameKR":"원제과점","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29085,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.373895,"longitude":127.136679,"bookYear":"2024","nameKR":"앙토낭카렘","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":28410,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.516039,"longitude":126.527543,"bookYear":"2024","nameKR":"앞돈지","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":1590,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.2000884002604,"longitude":128.58396169532,"bookYear":"2024","nameKR":"감자바우","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1971,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.73903574282178,"longitude":127.0377024565972,"bookYear":"2024","nameKR":"평양초계탕막국수","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":23159,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.8027379237982,"longitude":127.141264602377,"bookYear":"2024","nameKR":"화강반점","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":2077,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.565416,"longitude":128.727817,"bookYear":"2024","nameKR":"안동매일찜닭","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":35230,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0959722841611,"longitude":129.025970899438,"bookYear":"2024","nameKR":"다도서귀포","ribbonType":"X","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":2388,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":34.892607,"longitude":127.508957,"bookYear":"2024","nameKR":"강변장어","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":2769,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.540583,"longitude":130.873635,"bookYear":"2024","nameKR":"신애분식","ribbonType":"X","zone2_1":"경북","zone2_2":"울릉군","status":"ACTIVE"},
        {"id":3237,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.679957,"longitude":126.906889,"bookYear":"2024","nameKR":"취락식당","ribbonType":"X","zone2_1":"전남","zone2_2":"장흥군","status":"ACTIVE"},
        {"id":25473,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.93641,"longitude":126.968586,"bookYear":"2024","nameKR":"마동국수","ribbonType":"X","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":25083,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hwajeong1979s/","foodTypes":"한식(면류)","latitude":35.31890853980689,"longitude":128.2605747590698,"bookYear":"2024","nameKR":"의령화정소바국수","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"의령군","status":"ACTIVE"},
        {"id":5489,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.910329985322086,"longitude":127.07457542801662,"bookYear":"2024","nameKR":"유성식당","ribbonType":"X","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":31058,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.257320263933,"longitude":128.867169572905,"bookYear":"2024","nameKR":"한일뒷고기","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":5951,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.565433295852,"longitude":128.72784886971306,"bookYear":"2024","nameKR":"유진찜닭","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":8010,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.909279,"longitude":127.055106,"bookYear":"2024","nameKR":"호수식당","ribbonType":"X","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":20307,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.835201,"longitude":128.616044,"bookYear":"2024","nameKR":"산꼼파","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":22533,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.89118362076606,"longitude":128.82776512983492,"bookYear":"2024","nameKR":"동진네횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":10076,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":34.9528375138179,"longitude":127.52062594451,"bookYear":"2024","nameKR":"청강다슬기","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":21297,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.135517,"longitude":128.211919,"bookYear":"2024","nameKR":"빨간오뎅보금자리","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":31853,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4315957772898,"longitude":127.131147622072,"bookYear":"2024","nameKR":"호돌이문방구","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":12593,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.128575,"longitude":128.216278,"bookYear":"2024","nameKR":"노다지맛집","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":1744,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.462012,"longitude":127.155515,"bookYear":"2024","nameKR":"가야","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":15942,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.743986,"longitude":127.049687,"bookYear":"2024","nameKR":"진미식당","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":2178,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.870377,"longitude":128.595972,"bookYear":"2024","nameKR":"상주식당","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":36756,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.7572016584046,"longitude":127.657988819827,"bookYear":"2024","nameKR":"지노한우노블","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":2940,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":35.262475,"longitude":129.084176,"bookYear":"2024","nameKR":"경희궁","ribbonType":"X","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":4972,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafeora_official/","foodTypes":"디저트/차/베이커리","latitude":37.45199,"longitude":126.373753,"bookYear":"2024","nameKR":"카페오라","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":28075,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.390512,"longitude":127.080551,"bookYear":"2024","nameKR":"베네쿠치","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":12672,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.359042,"longitude":129.385064,"bookYear":"2024","nameKR":"죽도산","ribbonType":"X","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":22142,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lskking7301/","foodTypes":"한식(일반한식)","latitude":36.518529,"longitude":127.498478,"bookYear":"2024","nameKR":"청풍미가","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":30472,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.385788349614465,"longitude":127.13511296226831,"bookYear":"2024","nameKR":"가비양","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":30413,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.63248502195165,"longitude":126.83253012533471,"bookYear":"2024","nameKR":"화정맛집민쿡다시마","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":13111,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.360356,"longitude":127.149067,"bookYear":"2024","nameKR":"와궁","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":33667,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/monimoni_official/","foodTypes":"디저트/차/베이커리","latitude":37.5076227247155,"longitude":126.726847041634,"bookYear":"2024","nameKR":"모니모니","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":1724,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.202293,"longitude":128.567681,"bookYear":"2024","nameKR":"한성면옥","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":2823,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.762114,"longitude":127.298937,"bookYear":"2024","nameKR":"충남집순대","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":35585,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gohyangok_lafesta/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6598054476523,"longitude":126.766807615454,"bookYear":"2024","nameKR":"고향옥얼큰순대국본점","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":34709,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1590105586735,"longitude":129.143314832388,"bookYear":"2024","nameKR":"야가와","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":4267,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.321977885952705,"longitude":127.41526575883474,"bookYear":"2024","nameKR":"예지원","ribbonType":"X","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":29899,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":36.344751112322555,"longitude":127.33916536496392,"bookYear":"2024","nameKR":"스시정수","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":16155,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.40347,"longitude":126.908729,"bookYear":"2024","nameKR":"태원정육식당","ribbonType":"X","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":36657,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chark___6/","foodTypes":"한식(가금류)","latitude":37.6139925532056,"longitude":127.166192141111,"bookYear":"2024","nameKR":"착륙","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":5281,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.301258,"longitude":127.456685,"bookYear":"2024","nameKR":"평양숨두부","ribbonType":"X","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":5471,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.22546222291432,"longitude":127.9159457377763,"bookYear":"2024","nameKR":"덕승관","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"영동군","status":"ACTIVE"},
        {"id":36297,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8550412265679,"longitude":128.611881083419,"bookYear":"2024","nameKR":"효탄","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":6159,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.290928,"longitude":127.014647,"bookYear":"2024","nameKR":"골목집설렁탕","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6295,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.321471,"longitude":127.109975,"bookYear":"2024","nameKR":"에코의서재","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":31628,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.2461172281763,"longitude":127.04122753343,"bookYear":"2024","nameKR":"아장아장(아빠가만든전복장)","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":31865,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":36.6665919581841,"longitude":127.498369735096,"bookYear":"2024","nameKR":"타베르나","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":8904,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.743338,"longitude":127.028706,"bookYear":"2024","nameKR":"실비식당","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":36030,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":35.1846122046886,"longitude":129.211425374497,"bookYear":"2024","nameKR":"모닝베어","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":35515,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.275482048649,"longitude":128.402491865011,"bookYear":"2024","nameKR":"카페두루고","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"함안군","status":"ACTIVE"},
        {"id":1705,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.2022265838819,"longitude":128.591798786901,"bookYear":"2024","nameKR":"진양횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":32911,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/vietnam_noodlebar/","foodTypes":"아시아식","latitude":37.7229368094568,"longitude":126.738651335904,"bookYear":"2024","nameKR":"비엣남","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32474,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1358445327458,"longitude":129.101066942189,"bookYear":"2024","nameKR":"양화옥","ribbonType":"X","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":35695,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5932276255965,"longitude":127.338386038796,"bookYear":"2024","nameKR":"로이테","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":37776,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cheolsan_bukhaedomokjang/","foodTypes":"일식","latitude":37.4745904793418,"longitude":126.869176585068,"bookYear":"2024","nameKR":"북해도목장","ribbonType":"X","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":2719,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.971875,"longitude":127.933624,"bookYear":"2024","nameKR":"아주식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":37030,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jsicoffeecompany","foodTypes":"디저트/차/베이커리","latitude":37.3468997480954,"longitude":126.951592713951,"bookYear":"2024","nameKR":"조상일커피","ribbonType":"X","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":32845,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/grazie_yeongdo/","foodTypes":"이탈리아식","latitude":35.0890251834784,"longitude":129.061561483318,"bookYear":"2024","nameKR":"그라치에","ribbonType":"X","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":35509,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.6046281683729,"longitude":127.48703234989,"bookYear":"2024","nameKR":"에이커","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":34672,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.1737847596914,"longitude":129.175137543763,"bookYear":"2024","nameKR":"오스테리아오로","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":36394,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4755089754091,"longitude":127.18911719291,"bookYear":"2024","nameKR":"함지박","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":32724,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kamechanjeonju","foodTypes":"바/주점","latitude":35.8164100496566,"longitude":127.109997405542,"bookYear":"2024","nameKR":"카메짱","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":36048,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/foredi_doan/","foodTypes":"바/주점","latitude":36.3058077144073,"longitude":127.349825598834,"bookYear":"2024","nameKR":"포레디","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":36960,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/uuchajeok","foodTypes":"디저트/차/베이커리","latitude":37.0218043247646,"longitude":127.202836054065,"bookYear":"2024","nameKR":"유유차적","ribbonType":"X","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":35122,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/room816_/","foodTypes":"디저트/차/베이커리","latitude":36.8196329132767,"longitude":127.158440492646,"bookYear":"2024","nameKR":"룸816","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":34426,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.3839945538029,"longitude":126.828318987031,"bookYear":"2024","nameKR":"참소예","ribbonType":"X","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":38721,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.9918844691423,"longitude":127.342954676098,"bookYear":"2024","nameKR":"수정식당","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":35555,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.8498477590094,"longitude":127.749035333961,"bookYear":"2024","nameKR":"비와별닭갈비본점","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":4394,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.94648844599477,"longitude":127.07083806116182,"bookYear":"2024","nameKR":"소담골","ribbonType":"X","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":36981,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4433899641325,"longitude":126.705442146582,"bookYear":"2024","nameKR":"시나몬하우스","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":35803,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/millimeter_2915/","foodTypes":"디저트/차/베이커리","latitude":36.6025219604612,"longitude":127.461114078951,"bookYear":"2024","nameKR":"밀리미터베이커리카페&키친","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":6160,"bookStatus":"승인","websiteInstagram":"","latitude":37.291113,"longitude":127.013503,"bookYear":"2024","nameKR":"풍년설렁탕해장국","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35551,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nousmonde917","foodTypes":"디저트/차/베이커리","latitude":35.4348504077344,"longitude":126.801791804324,"bookYear":"2024","nameKR":"루몽드917","ribbonType":"X","zone2_1":"전남","zone2_2":"장성군","status":"ACTIVE"},
        {"id":37355,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2435255339258,"longitude":129.214634828286,"bookYear":"2024","nameKR":"해송대게","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":37088,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"기타","latitude":36.4891809464414,"longitude":127.255425977253,"bookYear":"2024","nameKR":"힛유어텅","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":8184,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.212128,"longitude":129.005881,"bookYear":"2024","nameKR":"평양집","ribbonType":"X","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":8244,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.839343,"longitude":128.704815,"bookYear":"2024","nameKR":"금곡삼계탕","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":33860,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.2369516576458,"longitude":128.581863251171,"bookYear":"2024","nameKR":"뜨라또리아다젠나","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":17068,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.942855,"longitude":128.562441,"bookYear":"2024","nameKR":"련","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":30851,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.8748856092856,"longitude":127.128211314668,"bookYear":"2024","nameKR":"엄스키야키","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":32854,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sanmarpizza/","foodTypes":"미국식","latitude":37.7547910296994,"longitude":128.894873754048,"bookYear":"2024","nameKR":"샌마르","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":36534,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":34.8663479121827,"longitude":128.689173334165,"bookYear":"2024","nameKR":"파평옥","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":35150,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yukhwami_official/","foodTypes":"한식(육류)","latitude":36.8168930735222,"longitude":127.107031605939,"bookYear":"2024","nameKR":"육화미(본점)","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38705,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.6010901044203,"longitude":126.816985086539,"bookYear":"2024","nameKR":"프로메나드","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":37334,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/itsbagel_official/","foodTypes":"디저트/차/베이커리","latitude":36.7162106249114,"longitude":127.421611598208,"bookYear":"2024","nameKR":"잇츠베이글","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35520,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafedaconejeju","foodTypes":"디저트/차/베이커리","latitude":33.5291832266884,"longitude":126.836774879854,"bookYear":"2024","nameKR":"다코네","ribbonType":"X","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":4549,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.882719,"longitude":128.619857,"bookYear":"2024","nameKR":"꼬꼬하우스","ribbonType":"X","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":32068,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.7403639602762,"longitude":127.060060431292,"bookYear":"2024","nameKR":"메종키친","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":37306,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yet_gol_2003/","foodTypes":"한식(육류)","latitude":35.1546252758895,"longitude":129.056253094633,"bookYear":"2024","nameKR":"옛골","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":8226,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8617652729192,"longitude":128.564203053583,"bookYear":"2024","nameKR":"복들어온날","ribbonType":"X","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":24182,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.6697809485561,"longitude":127.489459705129,"bookYear":"2024","nameKR":"조선칡냉면","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":30714,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe.demian","foodTypes":"디저트/차/베이커리","latitude":35.5557517017245,"longitude":129.320649560873,"bookYear":"2024","nameKR":"데미안","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":34203,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeongdam213/","foodTypes":"디저트/차/베이커리","latitude":35.7581032452776,"longitude":127.396289236407,"bookYear":"2024","nameKR":"정담213","ribbonType":"X","zone2_1":"전북","zone2_2":"진안군","status":"ACTIVE"},
        {"id":2247,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.330999,"longitude":127.430961,"bookYear":"2024","nameKR":"한밭식당","ribbonType":"X","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":35960,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4406868122934,"longitude":126.7068450145,"bookYear":"2024","nameKR":"산향한정식","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":32187,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/riverhead.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.2712134279757,"longitude":127.007673979687,"bookYear":"2024","nameKR":"리버헤드","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33497,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":33.4760757847189,"longitude":126.482743771781,"bookYear":"2024","nameKR":"바토라브와르","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":5966,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.81119312175415,"longitude":127.15041688813014,"bookYear":"2024","nameKR":"메르밀진미집","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":33473,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gluttony_coffee/","foodTypes":"디저트/차/베이커리","latitude":33.4865744240922,"longitude":126.534892820294,"bookYear":"2024","nameKR":"글러트니커피","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":35645,"bookStatus":"승인","websiteInstagram":"","foodTypes":"유럽식","latitude":37.7919850495766,"longitude":128.913553175117,"bookYear":"2024","nameKR":"더블티다이닝","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":34253,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/subito.subito.subito/","foodTypes":"이탈리아식","latitude":37.6131347683792,"longitude":126.834481403206,"bookYear":"2024","nameKR":"수비토","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":36447,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.8569366592575,"longitude":128.586131468689,"bookYear":"2024","nameKR":"제일콩국","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":36446,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.0867278855272,"longitude":128.262911239664,"bookYear":"2024","nameKR":"옥천식당 ","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"고성군","status":"ACTIVE"},
        {"id":36477,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.4923249707188,"longitude":126.47257683069,"bookYear":"2024","nameKR":"솔지식당","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":34714,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.6690229582856,"longitude":126.775136185219,"bookYear":"2024","nameKR":"떼네룸","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":32850,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_fine?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr","foodTypes":"디저트/차/베이커리","latitude":33.5551543850457,"longitude":126.794966166154,"bookYear":"2024","nameKR":"오늘도화창","ribbonType":"X","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":36272,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sunnycupcake_/","foodTypes":"디저트/차/베이커리","latitude":37.395800819414,"longitude":127.107592379777,"bookYear":"2024","nameKR":"오디너리벗써니컵케이크","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":36577,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe.regouter/","foodTypes":"디저트/차/베이커리","latitude":37.4476452216679,"longitude":126.884972577884,"bookYear":"2024","nameKR":"르꾸떼","ribbonType":"X","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":36445,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.6822761517179,"longitude":128.228480273625,"bookYear":"2024","nameKR":"원조소풍","ribbonType":"X","zone2_1":"경북","zone2_2":"고령군","status":"ACTIVE"},
        {"id":32258,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shurazcake/","foodTypes":"디저트/차/베이커리","latitude":37.6717982875005,"longitude":126.748944739411,"bookYear":"2024","nameKR":"슈라즈케이크","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":36558,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/fidelity_coffee","foodTypes":"디저트/차/베이커리","latitude":35.8429998604647,"longitude":128.613594117848,"bookYear":"2024","nameKR":"피델리티커피","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36990,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mongyeon9991","foodTypes":"중식","latitude":37.2053842522352,"longitude":127.07433912353,"bookYear":"2024","nameKR":"몽연","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":36514,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.4385713931346,"longitude":129.083519432611,"bookYear":"2024","nameKR":"청송닭백숙 ","ribbonType":"X","zone2_1":"경북","zone2_2":"청송군","status":"ACTIVE"},
        {"id":33515,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bumyong_choi/","foodTypes":"이탈리아식","latitude":37.646736863068,"longitude":126.789194642141,"bookYear":"2024","nameKR":"파스타가든","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":33013,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gulp_deli_market/","foodTypes":"기타","latitude":35.8220119351354,"longitude":127.143114515738,"bookYear":"2024","nameKR":"걸프","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":1588,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.203721,"longitude":128.591908,"bookYear":"2024","nameKR":"88생선구이","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":35603,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pretkorea/","foodTypes":"기타","latitude":35.1017165268215,"longitude":129.021466735408,"bookYear":"2024","nameKR":"쁘렛","ribbonType":"X","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":34214,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/osteriamills/","foodTypes":"이탈리아식","latitude":35.8598391640114,"longitude":128.638642147713,"bookYear":"2024","nameKR":"밀즈","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":35429,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pro_kimgajang/","foodTypes":"한식(육류)","latitude":35.1621854272246,"longitude":129.177337267349,"bookYear":"2024","nameKR":"하선집숯불돼지갈비전문점","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":31159,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/es.pizza/","foodTypes":"미국식","latitude":37.6660301215934,"longitude":126.771357817446,"bookYear":"2024","nameKR":"엘리수피자","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":5023,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.204972,"longitude":128.579276,"bookYear":"2024","nameKR":"남성식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":5476,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.646534,"longitude":127.488458,"bookYear":"2024","nameKR":"봉용불고기","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36987,"bookStatus":"승인","websiteInstagram":"https://instagram.com/ba.ma.ro_jeju","foodTypes":"기타","latitude":33.249698457597,"longitude":126.411556504447,"bookYear":"2024","nameKR":"베메로","ribbonType":"X","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":36853,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8331848184481,"longitude":129.210002943018,"bookYear":"2024","nameKR":"복길","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30877,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1445334652882,"longitude":129.059898861466,"bookYear":"2024","nameKR":"칼질천번","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":27898,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.22024,"longitude":128.582701,"bookYear":"2024","nameKR":"동문설렁탕","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":32590,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/le.sentier/","foodTypes":"프랑스식","latitude":37.1127172516087,"longitude":127.063723141391,"bookYear":"2024","nameKR":"르샹띠에","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":30548,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/han_madang/","foodTypes":"한식(일반한식)","latitude":37.28985528511303,"longitude":126.99953988069885,"bookYear":"2024","nameKR":"한마당생고기","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":1941,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/khk5292/","foodTypes":"한식(가금류)","latitude":37.613912,"longitude":127.216631,"bookYear":"2024","nameKR":"진미오리구이","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":32456,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3856616167904,"longitude":126.645166645438,"bookYear":"2024","nameKR":"밥상편지","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":32346,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bakery_kioku","foodTypes":"디저트/차/베이커리","latitude":37.6658241156432,"longitude":126.786527038246,"bookYear":"2024","nameKR":"키오쿠","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":31708,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jejuegamyeon","foodTypes":"한식(일반한식)","latitude":33.5147353182838,"longitude":126.518008437497,"bookYear":"2024","nameKR":"제주에가면","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32511,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1490092879515,"longitude":129.111806074669,"bookYear":"2024","nameKR":"스시하루","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":31156,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/396coffeecompany","foodTypes":"디저트/차/베이커리","latitude":36.5600469121104,"longitude":128.699927931198,"bookYear":"2024","nameKR":"396커피컴퍼니","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":5397,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.181233,"longitude":127.177841,"bookYear":"2024","nameKR":"신풍매운탕","ribbonType":"X","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":7964,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.191205,"longitude":128.084106,"bookYear":"2024","nameKR":"북경장","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":8194,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.173473,"longitude":129.109987,"bookYear":"2024","nameKR":"홍순덕전포양곱창","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":33060,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.2379141128451,"longitude":126.60225861921,"bookYear":"2024","nameKR":"섶섬할망카페","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":30854,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.8007949538143,"longitude":126.792290791543,"bookYear":"2024","nameKR":"일루션커피","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":1732,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.635758,"longitude":128.342804,"bookYear":"2024","nameKR":"허브나라농원","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1791,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.671123,"longitude":127.503253,"bookYear":"2024","nameKR":"들풀","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":32104,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7343088942172,"longitude":127.417268804586,"bookYear":"2024","nameKR":"청평호반닭갈비막국수","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":32242,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mr.avvio/","foodTypes":"이탈리아식","latitude":37.7465730111216,"longitude":127.096402780207,"bookYear":"2024","nameKR":"비스트로아비오","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":3122,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.651026,"longitude":128.455242,"bookYear":"2024","nameKR":"백수식당","ribbonType":"X","zone2_1":"경북","zone2_2":"예천군","status":"ACTIVE"},
        {"id":3158,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.20216,"longitude":128.586687,"bookYear":"2024","nameKR":"원산면옥","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":3263,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5199584680356,"longitude":127.464491840023,"bookYear":"2024","nameKR":"옥천고읍냉면","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":32803,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.5937745892918,"longitude":128.713037334494,"bookYear":"2024","nameKR":"제비원삼겹","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":4492,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6648216866968,"longitude":129.0504075353639,"bookYear":"2024","nameKR":"정동진심곡쉼터","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":6982,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shellyscoffee_roastery/","foodTypes":"디저트/차/베이커리","latitude":37.840201,"longitude":128.876654,"bookYear":"2024","nameKR":"쉘리스커피","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":29967,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/matilda_jeju/","foodTypes":"바/주점","latitude":33.4656961653475,"longitude":126.334277144658,"bookYear":"2024","nameKR":"마틸다","ribbonType":"X","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":11889,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.51520235273921,"longitude":127.29831365800919,"bookYear":"2024","nameKR":"황토마당","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":32293,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.8107940137327,"longitude":127.09570226032,"bookYear":"2024","nameKR":"메밀집","ribbonType":"X","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":33518,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/il_tempo_official/","foodTypes":"이탈리아식","latitude":37.3267234415968,"longitude":127.10854616741,"bookYear":"2024","nameKR":"일템포 ","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":31515,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":33.556688667726,"longitude":126.803497234638,"bookYear":"2024","nameKR":"밭담","ribbonType":"X","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":27876,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5021826692157,"longitude":128.207705912571,"bookYear":"2024","nameKR":"삼정","ribbonType":"X","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":4423,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":34.793291,"longitude":126.38455,"bookYear":"2024","nameKR":"곰집갈비","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":5387,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/blume_garden_cafe","foodTypes":"디저트/차/베이커리","latitude":37.789254,"longitude":126.696533,"bookYear":"2024","nameKR":"블루메","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":25082,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.7523353006341,"longitude":129.208118394717,"bookYear":"2024","nameKR":"수리뫼","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":34084,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/diningforest/","foodTypes":"이탈리아식","latitude":35.3897993058408,"longitude":129.046300532215,"bookYear":"2024","nameKR":"다이닝숲","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":7979,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.7937610371893,"longitude":129.206134281118,"bookYear":"2024","nameKR":"부성식당","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31382,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hauyo_coffee/","foodTypes":"디저트/차/베이커리","latitude":35.1542340554273,"longitude":128.610607380089,"bookYear":"2024","nameKR":"하우요커피","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":35370,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hillon-dining","foodTypes":"이탈리아식","latitude":34.7501591340169,"longitude":127.650721946674,"bookYear":"2024","nameKR":"힐론카페&힐론다이닝","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":30191,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.59840918163189,"longitude":127.33268940460381,"bookYear":"2024","nameKR":"설하식당","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":12560,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1467237050672,"longitude":129.110730062746,"bookYear":"2024","nameKR":"다리집","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":11496,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5173411559086,"longitude":129.118726972373,"bookYear":"2024","nameKR":"신라1993","ribbonType":"X","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":32769,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":33.3989565025173,"longitude":126.247193915704,"bookYear":"2024","nameKR":"함올레","ribbonType":"X","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":35318,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6780565234562,"longitude":127.472726370192,"bookYear":"2024","nameKR":"가평냉면부손설악본점","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":2679,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.605026,"longitude":126.793432,"bookYear":"2024","nameKR":"호반식당","ribbonType":"X","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":2704,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.133822,"longitude":128.112617,"bookYear":"2024","nameKR":"묵마을","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":33005,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ascend_coffee/","foodTypes":"디저트/차/베이커리","latitude":35.1898064162437,"longitude":128.557292617019,"bookYear":"2024","nameKR":"어센드커피","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":36561,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.0834874138077,"longitude":127.075580447981,"bookYear":"2024","nameKR":"들뫼약산흑염소전문점","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":12387,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":38.210336,"longitude":127.265453,"bookYear":"2024","nameKR":"폭포가든","ribbonType":"X","zone2_1":"강원","zone2_2":"철원군","status":"ACTIVE"},
        {"id":5309,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.206301,"longitude":128.003489,"bookYear":"2024","nameKR":"추풍령할매갈비","ribbonType":"X","zone2_1":"경북","zone2_2":"김천시","status":"ACTIVE"},
        {"id":6199,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.229927,"longitude":127.301203,"bookYear":"2024","nameKR":"정마루호박꽃","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":37552,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pskseafood_seojong/","foodTypes":"한식(면류)","latitude":37.6227458501237,"longitude":127.353250045987,"bookYear":"2024","nameKR":"박승광해물손칼국수","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":31930,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.6235022552194,"longitude":126.833974728892,"bookYear":"2024","nameKR":"옥루","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":8905,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.7981200375645,"longitude":127.087774869486,"bookYear":"2024","nameKR":"다인막국수","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":33519,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/samcheok_boss/","foodTypes":"한식(어패류)","latitude":37.4669709124289,"longitude":129.169668227535,"bookYear":"2024","nameKR":"삼척보스대게","ribbonType":"X","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":30862,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.5082478584042,"longitude":126.724411153969,"bookYear":"2024","nameKR":"장어한판","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":34990,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.4778426050998,"longitude":127.13936663069,"bookYear":"2024","nameKR":"청양어죽","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":29853,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_azulejo/","foodTypes":"디저트/차/베이커리","latitude":33.366986353012,"longitude":126.839173835129,"bookYear":"2024","nameKR":"아줄레주","ribbonType":"X","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":31375,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.1706553456414,"longitude":126.617326961215,"bookYear":"2024","nameKR":"카페제부리","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":20329,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.1364131567348,"longitude":128.590390693019,"bookYear":"2024","nameKR":"진솔메밀국수","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":37379,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.3535612642552,"longitude":127.573153648314,"bookYear":"2024","nameKR":"남한강송어횟집","ribbonType":"X","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":30047,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.70036166987865,"longitude":128.22433817921376,"bookYear":"2024","nameKR":"원조생곡막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":1593,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.669102,"longitude":128.709912,"bookYear":"2024","nameKR":"고향이야기","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":34142,"bookStatus":"승인","websiteInstagram":"http://instagram.com/aurore_official_site","foodTypes":"기타","latitude":37.652137711416,"longitude":126.660708725929,"bookYear":"2024","nameKR":"오로르","ribbonType":"X","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":37477,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.7969334972413,"longitude":126.749686995953,"bookYear":"2024","nameKR":"길손가든","ribbonType":"X","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":4469,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":38.069564,"longitude":128.624717,"bookYear":"2024","nameKR":"월웅식당","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":35238,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.7253167093835,"longitude":126.699725481223,"bookYear":"2024","nameKR":"꼿그이","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":12454,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.253117,"longitude":128.56397,"bookYear":"2024","nameKR":"봉포선영이네물회전문점","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":6180,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.0868887226697,"longitude":127.25768423309957,"bookYear":"2024","nameKR":"고삼묵밥","ribbonType":"X","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":29546,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":33.3966234065148,"longitude":126.242444054558,"bookYear":"2024","nameKR":"협재수우동","ribbonType":"X","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":35222,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yeonl_ove/","foodTypes":"한식(일반한식)","latitude":38.2179262730934,"longitude":127.208596129285,"bookYear":"2024","nameKR":"연사랑","ribbonType":"X","zone2_1":"강원","zone2_2":"철원군","status":"ACTIVE"},
        {"id":22501,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.7600849570496,"longitude":128.919141212406,"bookYear":"2024","nameKR":"고성생선찜","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":8189,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.204058,"longitude":129.083941,"bookYear":"2024","nameKR":"구조방낙지","ribbonType":"X","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":9021,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.8177739036398,"longitude":127.143220994883,"bookYear":"2024","nameKR":"일품향","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":36402,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1819544645506,"longitude":128.10301972697,"bookYear":"2024","nameKR":"송기원진주냉면","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":30769,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pyeongchang_gamjane/","foodTypes":"한식(일반한식)","latitude":37.687809063224,"longitude":128.558878563129,"bookYear":"2024","nameKR":"감자네","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":33359,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hyangtojeong_suncheon/","foodTypes":"한식(일반한식)","latitude":34.9574861340511,"longitude":127.520343746368,"bookYear":"2024","nameKR":"향토정","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":1662,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.0730010692547,"longitude":128.6353702189692,"bookYear":"2024","nameKR":"송이골","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":1685,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":38.002302,"longitude":128.231706,"bookYear":"2024","nameKR":"옛날원대막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":35128,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/morningsmile_dairy_farm/","foodTypes":"디저트/차/베이커리","latitude":33.4547876226385,"longitude":126.586252039479,"bookYear":"2024","nameKR":"아침미소목장 카페","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":2456,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.594862,"longitude":127.755735,"bookYear":"2024","nameKR":"언덕에바람","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":4466,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":38.202787,"longitude":128.593784,"bookYear":"2024","nameKR":"신다신","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":5024,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.674885,"longitude":126.902615,"bookYear":"2024","nameKR":"명희네음식점","ribbonType":"X","zone2_1":"전남","zone2_2":"장흥군","status":"ACTIVE"},
        {"id":5326,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.130852,"longitude":128.536473,"bookYear":"2024","nameKR":"고향원조칡칼국수","ribbonType":"X","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":33140,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.5274546238264,"longitude":127.378904434963,"bookYear":"2024","nameKR":"부강옥","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":12376,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":38.1866046791691,"longitude":127.289042330847,"bookYear":"2024","nameKR":"임꺽정가든","ribbonType":"X","zone2_1":"강원","zone2_2":"철원군","status":"ACTIVE"},
        {"id":31063,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":34.8862580868084,"longitude":128.623654543878,"bookYear":"2024","nameKR":"충남식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":6575,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.681918,"longitude":128.59717,"bookYear":"2024","nameKR":"오대산채나라","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":12345,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.724784,"longitude":126.537423,"bookYear":"2024","nameKR":"항만식당","ribbonType":"X","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":31595,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yetgol/","foodTypes":"한식(면류)","latitude":37.6148057217731,"longitude":128.371612071189,"bookYear":"2024","nameKR":"초가집옛골","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":28385,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.979699,"longitude":126.713976,"bookYear":"2024","nameKR":"지린성","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":8938,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.076799,"longitude":128.493574,"bookYear":"2024","nameKR":"삼십년할머니순두부","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":35899,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sinjuokmi/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3950821426674,"longitude":127.24450291246,"bookYear":"2024","nameKR":"수제순대신주옥미","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":13227,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.089561,"longitude":128.654872,"bookYear":"2024","nameKR":"옛뜰","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":15799,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/m_comme_macaron/","foodTypes":"디저트/차/베이커리","latitude":37.7568980543064,"longitude":128.896785597947,"bookYear":"2024","nameKR":"엠꼼마카롱","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":2505,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.98789,"longitude":126.477485,"bookYear":"2024","nameKR":"향림낙지한마당","ribbonType":"X","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":3342,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.569077,"longitude":126.60097,"bookYear":"2024","nameKR":"용궁해물탕","ribbonType":"X","zone2_1":"전남","zone2_2":"해남군","status":"ACTIVE"},
        {"id":35064,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.3519574152186,"longitude":128.850465535465,"bookYear":"2024","nameKR":"가야농원","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":31418,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/muto_rp_sokcho/","foodTypes":"디저트/차/베이커리","latitude":38.1943224448768,"longitude":128.559771046138,"bookYear":"2024","nameKR":"뮤토로스팅플랜트","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":4752,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.045248,"longitude":126.204028,"bookYear":"2024","nameKR":"지도횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"신안군","status":"ACTIVE"},
        {"id":5321,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.753287,"longitude":127.962209,"bookYear":"2024","nameKR":"길매식당","ribbonType":"X","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":5576,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.83705,"longitude":129.211252,"bookYear":"2024","nameKR":"도솔마을","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":32510,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushi_yasdoku/","foodTypes":"일식","latitude":35.1608763696878,"longitude":129.172544292406,"bookYear":"2024","nameKR":"안덕스시","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":6841,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.130527209195,"longitude":128.184542075798,"bookYear":"2024","nameKR":"금왕식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":26972,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2993695906913,"longitude":127.556073818925,"bookYear":"2024","nameKR":"능서돼지국밥","ribbonType":"X","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":20941,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.191582,"longitude":129.079794,"bookYear":"2024","nameKR":"용문각","ribbonType":"X","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":8106,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.320403,"longitude":126.518325,"bookYear":"2024","nameKR":"김가네사골수제비","ribbonType":"X","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":9017,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.739531,"longitude":127.734776,"bookYear":"2024","nameKR":"돌산식당","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":27183,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.258329,"longitude":126.408082,"bookYear":"2024","nameKR":"미향해장국","ribbonType":"X","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":26518,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.15716325049214,"longitude":128.98508610692366,"bookYear":"2024","nameKR":"77돌곱창","ribbonType":"X","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":2341,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4713374505179,"longitude":126.626741407116,"bookYear":"2024","nameKR":"경남횟집","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":2445,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sook__octopus/","foodTypes":"한식(어패류)","latitude":34.987315257204,"longitude":126.477164051801,"bookYear":"2024","nameKR":"숙이네식당","ribbonType":"X","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":2952,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ljs8025995/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.159275,"longitude":129.056989,"bookYear":"2024","nameKR":"태화육개장","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":2962,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4441959696871,"longitude":126.411479284322,"bookYear":"2024","nameKR":"한옹가","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":26984,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.843899,"longitude":128.585902,"bookYear":"2024","nameKR":"대해복어","ribbonType":"X","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":4082,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.113415,"longitude":129.037978,"bookYear":"2024","nameKR":"장춘방","ribbonType":"X","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":5506,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.14154992275924,"longitude":129.0624072939891,"bookYear":"2024","nameKR":"합천식당","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":5547,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.182884,"longitude":128.155881,"bookYear":"2024","nameKR":"진주헛제사밥","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":8154,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.328966,"longitude":127.423504,"bookYear":"2024","nameKR":"희락반점","ribbonType":"X","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":8249,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.88167848981788,"longitude":128.62940311796828,"bookYear":"2024","nameKR":"해금강","ribbonType":"X","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":9247,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8268521576547,"longitude":127.142423673307,"bookYear":"2024","nameKR":"진미집","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":9767,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.21691,"longitude":126.252206,"bookYear":"2024","nameKR":"최남단식당","ribbonType":"X","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":30886,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/2ri_2ri/","foodTypes":"한식(어패류)","latitude":36.349891064587,"longitude":127.382189183305,"bookYear":"2024","nameKR":"참숯향생선구이","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":34944,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.1991501701666,"longitude":128.575932900172,"bookYear":"2024","nameKR":"속초강동호식당","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":2043,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.132283,"longitude":127.976491,"bookYear":"2024","nameKR":"진남식육식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":2292,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0973544535171,"longitude":128.923778340385,"bookYear":"2024","nameKR":"영주동삼대복국집","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":2455,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":34.5255289794736,"longitude":126.556108096614,"bookYear":"2024","nameKR":"어성장어센타","ribbonType":"X","zone2_1":"전남","zone2_2":"해남군","status":"ACTIVE"},
        {"id":2475,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.052188,"longitude":126.981235,"bookYear":"2024","nameKR":"사평다슬기수제비","ribbonType":"X","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":2496,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.209924,"longitude":127.463506,"bookYear":"2024","nameKR":"평화식당","ribbonType":"X","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":32910,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3098257362266,"longitude":127.086075656397,"bookYear":"2024","nameKR":"신분당선숯불구이","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":2683,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.657015,"longitude":127.483406,"bookYear":"2024","nameKR":"가화한정식","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":2713,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.64384,"longitude":127.490616,"bookYear":"2024","nameKR":"소영칼국수","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":2847,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.9090507279755,"longitude":127.052920243398,"bookYear":"2024","nameKR":"진미옥","ribbonType":"X","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":4473,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5583796050561,"longitude":129.120660578914,"bookYear":"2024","nameKR":"동해바다곰치국","ribbonType":"X","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":37831,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1757258323682,"longitude":129.127268717113,"bookYear":"2024","nameKR":"유명한횟집","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":5978,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.353545,"longitude":126.77179,"bookYear":"2024","nameKR":"나목도식당","ribbonType":"X","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":6757,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.7563725251266,"longitude":128.865135319523,"bookYear":"2024","nameKR":"두부마을초당순두부","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":17451,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.985871,"longitude":126.706922,"bookYear":"2024","nameKR":"락원","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":8222,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.8779291293611,"longitude":128.417675074348,"bookYear":"2024","nameKR":"동곡원조할매손칼국수","ribbonType":"X","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":31876,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/224_coffee/","foodTypes":"디저트/차/베이커리","latitude":35.1558052248973,"longitude":128.609424662506,"bookYear":"2024","nameKR":"224커피","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":8961,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.205233,"longitude":128.575745,"bookYear":"2024","nameKR":"반달집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":9476,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.2247803042594,"longitude":127.90495631972,"bookYear":"2024","nameKR":"안성식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"영동군","status":"ACTIVE"},
        {"id":10127,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.274941,"longitude":126.51367,"bookYear":"2024","nameKR":"문정한정식","ribbonType":"X","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":10256,"bookStatus":"승인","websiteInstagram":"http://instagram.com/asangarden","foodTypes":"한식(민물어패류)","latitude":35.504479,"longitude":126.590774,"bookYear":"2024","nameKR":"아산가든","ribbonType":"X","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":33306,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe.always.pine/","foodTypes":"디저트/차/베이커리","latitude":35.420992285374,"longitude":127.524331761436,"bookYear":"2024","nameKR":"늘,파인","ribbonType":"X","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":31249,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.5036648564977,"longitude":126.912640565732,"bookYear":"2024","nameKR":"소금바치순이네","ribbonType":"X","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":11502,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.556119,"longitude":129.120787,"bookYear":"2024","nameKR":"충북횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":18415,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/the.chef__/","foodTypes":"이탈리아식","latitude":35.144223,"longitude":129.108684,"bookYear":"2024","nameKR":"더셰프","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":1886,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.671987,"longitude":126.736277,"bookYear":"2024","nameKR":"양촌리아구","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":1900,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.387177,"longitude":126.931869,"bookYear":"2024","nameKR":"옛집","ribbonType":"X","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":2124,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.485328,"longitude":130.902399,"bookYear":"2024","nameKR":"향우촌","ribbonType":"X","zone2_1":"경북","zone2_2":"울릉군","status":"ACTIVE"},
        {"id":2125,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.421854,"longitude":129.357932,"bookYear":"2024","nameKR":"화림산가든","ribbonType":"X","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":2133,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.109896,"longitude":126.876616,"bookYear":"2024","nameKR":"돌매순두부","ribbonType":"X","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":2787,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.151424,"longitude":129.057616,"bookYear":"2024","nameKR":"월강","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":3602,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.751375,"longitude":126.411321,"bookYear":"2024","nameKR":"수궁한정식","ribbonType":"X","zone2_1":"전남","zone2_2":"영암군","status":"ACTIVE"},
        {"id":5987,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.870828,"longitude":128.581111,"bookYear":"2024","nameKR":"미림","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":6019,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":35.865807,"longitude":128.626331,"bookYear":"2024","nameKR":"바우만스테이크하우스","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":7180,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.5322194983004,"longitude":126.618608890294,"bookYear":"2024","nameKR":"신촌덕인당","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":30309,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dkstarpork/","foodTypes":"한식(육류)","latitude":37.44391888989587,"longitude":126.70912956589233,"bookYear":"2024","nameKR":"별삼겹","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":9745,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.292777,"longitude":127.243588,"bookYear":"2024","nameKR":"장원복집","ribbonType":"X","zone2_1":"충남","zone2_2":"계룡시","status":"ACTIVE"},
        {"id":9796,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.2788608378028,"longitude":126.704647540795,"bookYear":"2024","nameKR":"제남가든","ribbonType":"X","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":32184,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/soso_omakase/","foodTypes":"일식","latitude":37.2758569444677,"longitude":127.151277088807,"bookYear":"2024","nameKR":"소소","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":2434,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.98596008425404,"longitude":126.96052298487959,"bookYear":"2024","nameKR":"능주삼거리식당","ribbonType":"X","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":12450,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.89988,"longitude":128.038017,"bookYear":"2024","nameKR":"장남원조보리밥","ribbonType":"X","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":36857,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ga.eum_makchang","foodTypes":"한식(육류)","latitude":35.3592085630983,"longitude":129.042351667839,"bookYear":"2024","nameKR":"가음막창","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":29825,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/barstro37","foodTypes":"중남미식","latitude":37.3628633162975,"longitude":127.164165738895,"bookYear":"2024","nameKR":"바스트로37","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":5535,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/__in_vino/","foodTypes":"기타","latitude":35.86469024368968,"longitude":128.63417871474678,"bookYear":"2024","nameKR":"인비노","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":6260,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.368135,"longitude":127.106274,"bookYear":"2024","nameKR":"오또코","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":12392,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":38.106061,"longitude":127.704766,"bookYear":"2024","nameKR":"명가","ribbonType":"X","zone2_1":"강원","zone2_2":"화천군","status":"ACTIVE"},
        {"id":30521,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/samhabga/","foodTypes":"한식(어패류)","latitude":35.83361758653045,"longitude":128.61642375653454,"bookYear":"2024","nameKR":"삼합가","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":31059,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2281561473707,"longitude":128.871557937536,"bookYear":"2024","nameKR":"삼일뒷고기","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":35393,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yakitori_yatai/","foodTypes":"일식","latitude":37.6643934379853,"longitude":126.765773331567,"bookYear":"2024","nameKR":"야키토리야타이","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":13183,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.960073,"longitude":126.731335,"bookYear":"2024","nameKR":"장어골숯불구이","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":11076,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/samsongsushi","foodTypes":"일식","latitude":35.099686,"longitude":129.029879,"bookYear":"2024","nameKR":"삼송초밥","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":31119,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.5402361555976,"longitude":129.333005064926,"bookYear":"2024","nameKR":"오사카멘치","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":30746,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/blackpig_2","foodTypes":"한식(육류)","latitude":33.2549504182081,"longitude":126.417150290098,"bookYear":"2024","nameKR":"중문흑돼지천국","ribbonType":"X","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":1896,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.256090701315,"longitude":127.467264326586,"bookYear":"2024","nameKR":"옛날맛손두부","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":4035,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.170799,"longitude":126.905995,"bookYear":"2024","nameKR":"해송","ribbonType":"X","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":4037,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.16291,"longitude":126.916974,"bookYear":"2024","nameKR":"주정숙청국장","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":4753,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.31938,"longitude":126.749063,"bookYear":"2024","nameKR":"미원횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"완도군","status":"ACTIVE"},
        {"id":12501,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/parisienne__jinju/","foodTypes":"프랑스식","latitude":35.173868,"longitude":128.068537,"bookYear":"2024","nameKR":"빠리지엔느","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":5465,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.338817,"longitude":127.427011,"bookYear":"2024","nameKR":"신미식당","ribbonType":"X","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":5989,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.783178,"longitude":126.477059,"bookYear":"2024","nameKR":"돌기와집","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":6057,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.394587,"longitude":126.640927,"bookYear":"2024","nameKR":"송도국제경양식","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":6947,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.541161,"longitude":129.348584,"bookYear":"2024","nameKR":"도쿄참치","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":30564,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.831255710118285,"longitude":128.61622091779958,"bookYear":"2024","nameKR":"남강회초밥","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":23329,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/illiana._.r/","foodTypes":"이탈리아식","latitude":35.860319,"longitude":128.621814,"bookYear":"2024","nameKR":"일리아나레스토랑","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":33213,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5398176379992,"longitude":127.209319527184,"bookYear":"2024","nameKR":"하남장터곱창","ribbonType":"X","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":9754,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.245235,"longitude":126.522353,"bookYear":"2024","nameKR":"하영횟집","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":17921,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.406283,"longitude":127.116422,"bookYear":"2024","nameKR":"베이징스토리","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":26975,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.839406,"longitude":127.132845,"bookYear":"2024","nameKR":"감로헌","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":34027,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/songdosulsang/","foodTypes":"바/주점","latitude":37.3960687593126,"longitude":126.649038274476,"bookYear":"2024","nameKR":"송도술상","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":29368,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lasperanza_changwon/","foodTypes":"이탈리아식","latitude":35.2286912789379,"longitude":128.679710134939,"bookYear":"2024","nameKR":"라스페란자","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":32482,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.5807028174702,"longitude":126.854685834949,"bookYear":"2024","nameKR":"청해횟집수산","ribbonType":"X","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":2275,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.13838045320367,"longitude":129.1126636697171,"bookYear":"2024","nameKR":"백경","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":2811,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.9798224885702,"longitude":126.720344767289,"bookYear":"2024","nameKR":"송정식당","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":37568,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4389466732572,"longitude":126.718515045903,"bookYear":"2024","nameKR":"영월애곤드레(인천본점)","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":34983,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.9868955986838,"longitude":126.59074341263,"bookYear":"2024","nameKR":"석문명가","ribbonType":"X","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":5356,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.646905,"longitude":126.786539,"bookYear":"2024","nameKR":"장충동한양할머니족발","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":6123,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.870002,"longitude":128.58474,"bookYear":"2024","nameKR":"부창생갈비","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":35224,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.2077791026613,"longitude":127.210327301951,"bookYear":"2024","nameKR":"마당예쁜집","ribbonType":"X","zone2_1":"강원","zone2_2":"철원군","status":"ACTIVE"},
        {"id":31675,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/henrys__garden","foodTypes":"이탈리아식","latitude":35.1620784685036,"longitude":129.17493498904,"bookYear":"2024","nameKR":"헨리스가든","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":8123,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.4575472897245,"longitude":126.690879385155,"bookYear":"2024","nameKR":"연중반점","ribbonType":"X","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":8252,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.860224,"longitude":128.627424,"bookYear":"2024","nameKR":"범어만두","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36080,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3646927269071,"longitude":127.106762033824,"bookYear":"2024","nameKR":"미담휴","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":24663,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.545031,"longitude":126.682388,"bookYear":"2024","nameKR":"오가네냉면","ribbonType":"X","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":29033,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_ainos/","foodTypes":"이탈리아식","latitude":37.71195,"longitude":126.691803,"bookYear":"2024","nameKR":"아이노스","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":8891,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.149469,"longitude":126.911918,"bookYear":"2024","nameKR":"쌍학","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":8894,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.12300030057456,"longitude":126.72244881677213,"bookYear":"2024","nameKR":"명화식육식당","ribbonType":"X","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":31741,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/samgeorisigdang/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2574559282907,"longitude":128.623838869746,"bookYear":"2024","nameKR":"창원삼거리식당(중동본점)","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":34960,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.3592786936314,"longitude":127.377680938776,"bookYear":"2024","nameKR":"박선희황태어글탕","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":36016,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.6262968112092,"longitude":127.423664783629,"bookYear":"2024","nameKR":"강서추어탕","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":31704,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/fine.waffle/","foodTypes":"디저트/차/베이커리","latitude":34.9324351589841,"longitude":127.552546422683,"bookYear":"2024","nameKR":"파인와플","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":5401,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.49904206520492,"longitude":127.139324581817,"bookYear":"2024","nameKR":"청운식당","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":9239,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.784137,"longitude":126.381371,"bookYear":"2024","nameKR":"선창횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":2414,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.011855,"longitude":126.392016,"bookYear":"2024","nameKR":"동원식당","ribbonType":"X","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":2462,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.000705,"longitude":126.71218,"bookYear":"2024","nameKR":"영일복집","ribbonType":"X","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":2519,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.3979945691989,"longitude":127.367733274264,"bookYear":"2024","nameKR":"금생춘","ribbonType":"X","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":3400,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.40887,"longitude":127.395352,"bookYear":"2024","nameKR":"대연각","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":27118,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.513806,"longitude":127.603889,"bookYear":"2024","nameKR":"커피하우스제로제","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":5401,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.49904206520492,"longitude":127.139324581817,"bookYear":"2024","nameKR":"청운식당","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":5919,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.888564,"longitude":128.738139,"bookYear":"2024","nameKR":"봉화본가","ribbonType":"X","zone2_1":"경북","zone2_2":"봉화군","status":"ACTIVE"},
        {"id":5925,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.180903,"longitude":129.203613,"bookYear":"2024","nameKR":"강남횟집","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":5981,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.179348,"longitude":128.458133,"bookYear":"2024","nameKR":"사랑방식당","ribbonType":"X","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":8181,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.512604,"longitude":126.518865,"bookYear":"2024","nameKR":"태광식당","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":8223,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.941404,"longitude":128.570342,"bookYear":"2024","nameKR":"왕근이칼국수","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":37565,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.0315267041985,"longitude":129.364247797129,"bookYear":"2024","nameKR":"강산식당","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":9037,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/blackpork.house_jeju/?igshid=k327jhbdxath","foodTypes":"한식(육류)","latitude":33.24397,"longitude":126.536393,"bookYear":"2024","nameKR":"서귀포흑돼지명가","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":35000,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.3148932237595,"longitude":127.583016432464,"bookYear":"2024","nameKR":"옥천묵집","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":9239,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.784137,"longitude":126.381371,"bookYear":"2024","nameKR":"선창횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":30433,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ain53.77255200/","foodTypes":"디저트/차/베이커리","latitude":38.13496807166692,"longitude":127.98761429858854,"bookYear":"2024","nameKR":"아인53카페","ribbonType":"X","zone2_1":"강원","zone2_2":"양구군","status":"ACTIVE"},
        {"id":33832,"bookStatus":"승인","websiteInstagram":"http://www.instagram.com/mkh_1981","foodTypes":"중식","latitude":37.5259038205765,"longitude":129.11622713311,"bookYear":"2024","nameKR":"만강홍","ribbonType":"X","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":30183,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.002323567413434,"longitude":127.11310319573755,"bookYear":"2024","nameKR":"브릭스키친","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":34894,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.0167761459251,"longitude":127.321190132769,"bookYear":"2024","nameKR":"두향","ribbonType":"X","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":16160,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.76425030207408,"longitude":126.88781186992625,"bookYear":"2024","nameKR":"길조식당","ribbonType":"X","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":17950,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.21576004059353,"longitude":127.19483193019525,"bookYear":"2024","nameKR":"고향식당","ribbonType":"X","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":1939,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.213758,"longitude":127.36009,"bookYear":"2024","nameKR":"지산가든","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":2555,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.817622,"longitude":127.127988,"bookYear":"2024","nameKR":"한일관","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":2664,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.439022,"longitude":126.521236,"bookYear":"2024","nameKR":"우리횟집","ribbonType":"X","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":3343,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.797484,"longitude":126.887119,"bookYear":"2024","nameKR":"매일회관","ribbonType":"X","zone2_1":"전북","zone2_2":"김제시","status":"ACTIVE"},
        {"id":31926,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4944466184207,"longitude":127.497513333446,"bookYear":"2024","nameKR":"커피리얼리스트","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":29985,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8404279313478,"longitude":127.129235260538,"bookYear":"2024","nameKR":"덕천식당","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":37834,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.1304999747133,"longitude":128.254403256627,"bookYear":"2024","nameKR":"시골순두부","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":6918,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.65451,"longitude":127.486628,"bookYear":"2024","nameKR":"리정식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":25329,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.3676825411476,"longitude":127.382002622866,"bookYear":"2024","nameKR":"만촌","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":35258,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":38.2869562392294,"longitude":127.213824904134,"bookYear":"2024","nameKR":"철원샘통고추냉이","ribbonType":"X","zone2_1":"강원","zone2_2":"철원군","status":"ACTIVE"},
        {"id":29970,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffee_melo/","foodTypes":"디저트/차/베이커리","latitude":33.3122855061511,"longitude":126.366264313279,"bookYear":"2024","nameKR":"커피맛이멜로","ribbonType":"X","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":8900,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.550651,"longitude":127.185139,"bookYear":"2024","nameKR":"강영감네닭내장수구레","ribbonType":"X","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":9016,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.740078,"longitude":127.73665,"bookYear":"2024","nameKR":"노래미식당","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":30436,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3899591966301,"longitude":126.741757006715,"bookYear":"2024","nameKR":"마데몽","ribbonType":"X","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":13318,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.806937,"longitude":126.399332,"bookYear":"2024","nameKR":"석심횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":16149,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.4845835121617,"longitude":126.700229760691,"bookYear":"2024","nameKR":"선흘방주할머니식당","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":22299,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.158299,"longitude":129.125699,"bookYear":"2024","nameKR":"자매국밥","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":17550,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3502285628167,"longitude":127.948624848521,"bookYear":"2024","nameKR":"신혼부부","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":35342,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7458208207652,"longitude":127.652534564089,"bookYear":"2024","nameKR":"대풍마차","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":32103,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9226859384384,"longitude":128.59904145527,"bookYear":"2024","nameKR":"까치산갈비","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":4173,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.171243,"longitude":129.166211,"bookYear":"2024","nameKR":"이튼밸리","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":35234,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1823203548958,"longitude":129.106907013778,"bookYear":"2024","nameKR":"연산낙지해물탕","ribbonType":"X","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":29951,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/salondelavant/","foodTypes":"디저트/차/베이커리","latitude":33.4578617802591,"longitude":126.348952499258,"bookYear":"2024","nameKR":"살롱드라방","ribbonType":"X","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":29192,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/leesdeli_00/","foodTypes":"기타","latitude":37.361034,"longitude":127.105185,"bookYear":"2024","nameKR":"리즈델리","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":9188,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.180134,"longitude":128.072222,"bookYear":"2024","nameKR":"한정식아리랑","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":12336,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.939574406888774,"longitude":126.83659541575447,"bookYear":"2024","nameKR":"장단콩두부집","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":29093,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":35.841779,"longitude":128.704143,"bookYear":"2024","nameKR":"트리니떼","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":14330,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.50153908465229,"longitude":126.7746395759715,"bookYear":"2024","nameKR":"묵향","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":31827,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ti_vaillant_creperie/","foodTypes":"프랑스식","latitude":37.7801153181292,"longitude":126.709199797669,"bookYear":"2024","nameKR":"티바이양크레프리","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":31522,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gastropace_official/","foodTypes":"바/주점","latitude":35.8596122720718,"longitude":128.637649929357,"bookYear":"2024","nameKR":"가스트로파체","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":31943,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.6914111531274,"longitude":128.453738546575,"bookYear":"2024","nameKR":"스시전","ribbonType":"X","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":34956,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.4698865361909,"longitude":126.545914052162,"bookYear":"2024","nameKR":"상춘재","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":1994,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.40258362825161,"longitude":127.54722046598195,"bookYear":"2024","nameKR":"홍원막국수","ribbonType":"X","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":2347,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.640144,"longitude":126.391834,"bookYear":"2024","nameKR":"미락횟집","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":2429,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":34.851167,"longitude":127.335948,"bookYear":"2024","nameKR":"벌교우렁집","ribbonType":"X","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":2673,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.762312,"longitude":127.299266,"bookYear":"2024","nameKR":"청화집","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":3080,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.639995,"longitude":126.81352,"bookYear":"2024","nameKR":"산마루가든","ribbonType":"X","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":4252,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.391038,"longitude":129.068101,"bookYear":"2024","nameKR":"죽림산방","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":4385,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.322465,"longitude":127.677336,"bookYear":"2024","nameKR":"예닮골","ribbonType":"X","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":4751,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.999702,"longitude":126.710503,"bookYear":"2024","nameKR":"홍어1번지","ribbonType":"X","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":5462,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.238745,"longitude":126.613843,"bookYear":"2024","nameKR":"진짜원조소나무집","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":5511,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.099161,"longitude":129.02972,"bookYear":"2024","nameKR":"까사오로","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":6198,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.23688,"longitude":128.993889,"bookYear":"2024","nameKR":"대동할매국수","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":20369,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.8694788164144,"longitude":127.72047703505,"bookYear":"2024","nameKR":"왕만두","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":7989,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.46649,"longitude":127.121949,"bookYear":"2024","nameKR":"새이학가든","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":8217,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.194033,"longitude":129.22073,"bookYear":"2024","nameKR":"용궁해물야채쟁반짜장","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":8901,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.744058,"longitude":127.200335,"bookYear":"2024","nameKR":"광천정육점식당","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":9624,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.773855,"longitude":126.604112,"bookYear":"2024","nameKR":"용현집","ribbonType":"X","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":30844,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dining_oknam","foodTypes":"이탈리아식","latitude":38.1889380021958,"longitude":128.587375114518,"bookYear":"2024","nameKR":"다이닝옥남","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":14982,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.713858,"longitude":126.694912,"bookYear":"2024","nameKR":"원조할머니묵집","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":30865,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mummum_official","foodTypes":"일식","latitude":35.8143077381378,"longitude":128.522889903797,"bookYear":"2024","nameKR":"뭄뭄","ribbonType":"X","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":32169,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/flexdiner","foodTypes":"바/주점","latitude":36.3613071536354,"longitude":127.351420438984,"bookYear":"2024","nameKR":"플랙스다이너","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":1879,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.036711,"longitude":127.371225,"bookYear":"2024","nameKR":"안성마춤한우촌","ribbonType":"X","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":2358,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.469752,"longitude":126.626447,"bookYear":"2024","nameKR":"신성루","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":31770,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":33.4796265020162,"longitude":126.397680873053,"bookYear":"2024","nameKR":"제주이와이","ribbonType":"X","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":4018,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.2306504665571,"longitude":127.00869316684,"bookYear":"2024","nameKR":"창평전통안두부","ribbonType":"X","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":28109,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.858551,"longitude":128.560435,"bookYear":"2024","nameKR":"신신반점","ribbonType":"X","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":5021,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.607707,"longitude":128.273593,"bookYear":"2024","nameKR":"박달식당","ribbonType":"X","zone2_1":"경북","zone2_2":"예천군","status":"ACTIVE"},
        {"id":5259,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.751894,"longitude":126.484182,"bookYear":"2024","nameKR":"왕자정묵밥","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":5334,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5047015214017,"longitude":126.80448166881,"bookYear":"2024","nameKR":"김정수할머니메밀옹심이보쌈","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":6310,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중남미식","latitude":37.322039,"longitude":127.109709,"bookYear":"2024","nameKR":"라스마가리타스","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":6400,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7122189476294,"longitude":127.105511570607,"bookYear":"2024","nameKR":"당고개냉면","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":33043,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.1426350442913,"longitude":126.835233299661,"bookYear":"2024","nameKR":"맥문동","ribbonType":"X","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":31819,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dimolto293/","foodTypes":"이탈리아식","latitude":37.5251202773986,"longitude":126.808976678374,"bookYear":"2024","nameKR":"디몰토","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":30481,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1813267308598,"longitude":129.076220783681,"bookYear":"2024","nameKR":"스시류","ribbonType":"X","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":31592,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/zanzan_yakitori/","foodTypes":"바/주점","latitude":37.2654257600945,"longitude":127.032283173405,"bookYear":"2024","nameKR":"야키토리잔잔인계본점","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33186,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6586701971311,"longitude":126.767621359759,"bookYear":"2024","nameKR":"성심어린","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35719,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.8634223909307,"longitude":127.733087153528,"bookYear":"2024","nameKR":"애식주","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":31424,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shushi_ra/","foodTypes":"일식","latitude":37.3952040049379,"longitude":126.631287119931,"bookYear":"2024","nameKR":"스시라","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":2201,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8808880687756,"longitude":128.579500676077,"bookYear":"2024","nameKR":"제비원식당","ribbonType":"X","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":4329,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.99959627609727,"longitude":127.32259371600077,"bookYear":"2024","nameKR":"홍익정육점식당","ribbonType":"X","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":32553,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.4162974483761,"longitude":128.164824021671,"bookYear":"2024","nameKR":"안압정","ribbonType":"X","zone2_1":"경북","zone2_2":"상주시","status":"ACTIVE"},
        {"id":33588,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/umteun_delicatessen/","foodTypes":"기타","latitude":33.5243148704692,"longitude":126.860942907621,"bookYear":"2024","nameKR":"움튼델리카테슨","ribbonType":"X","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":32851,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5032053014414,"longitude":126.768162751102,"bookYear":"2024","nameKR":"델레니오","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":34662,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lesplanchesbusan/","foodTypes":"프랑스식","latitude":35.1465492971022,"longitude":129.113186878968,"bookYear":"2024","nameKR":"레플랑시","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":8902,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.543277,"longitude":127.326496,"bookYear":"2024","nameKR":"연밭","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":9456,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.666548,"longitude":127.494375,"bookYear":"2024","nameKR":"베이징","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":24692,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.743521,"longitude":127.133423,"bookYear":"2024","nameKR":"백련","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":31627,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.5559182589208,"longitude":129.321873010743,"bookYear":"2024","nameKR":"손가","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":19126,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.301092,"longitude":126.864336,"bookYear":"2024","nameKR":"조순금닭도리탕","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36296,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.8605807967742,"longitude":128.640306189169,"bookYear":"2024","nameKR":"스시노토시","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":22696,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.80884197664358,"longitude":127.17426533456585,"bookYear":"2024","nameKR":"녹원","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":30821,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/all_the_ugly_cookie/","foodTypes":"디저트/차/베이커리","latitude":35.1567521971997,"longitude":129.064960508804,"bookYear":"2024","nameKR":"올더어글리쿠키","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":31415,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gyoha0319578989","foodTypes":"한식(면류)","latitude":37.7792989644861,"longitude":126.706911500686,"bookYear":"2024","nameKR":"교하제면소","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32683,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bistro_aube","foodTypes":"유럽식","latitude":35.171849434882,"longitude":128.066857513299,"bookYear":"2024","nameKR":"양식당오브","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":4171,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.08135,"longitude":129.02521,"bookYear":"2024","nameKR":"미성하모샤브샤브","ribbonType":"X","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":6148,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.672821,"longitude":126.753925,"bookYear":"2024","nameKR":"오향선","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":6907,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.815269,"longitude":127.185557,"bookYear":"2024","nameKR":"송연","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":30043,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pizzeria_popolo/","foodTypes":"이탈리아식","latitude":37.6584011318816,"longitude":126.773393488954,"bookYear":"2024","nameKR":"포폴로피자","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":8227,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.880439,"longitude":128.614867,"bookYear":"2024","nameKR":"신성가든","ribbonType":"X","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":29661,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.81463972081593,"longitude":127.11796282914288,"bookYear":"2024","nameKR":"일송정","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":8956,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1846814721252,"longitude":128.073975848454,"bookYear":"2024","nameKR":"더하우스갑을","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":32270,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.1691617993167,"longitude":128.57278277376,"bookYear":"2024","nameKR":"가포옛날영도집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":24450,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.259314,"longitude":127.143178,"bookYear":"2024","nameKR":"호박촌","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":35090,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yaho_1869","foodTypes":"한식(민물어패류)","latitude":36.4424486540194,"longitude":127.466491925871,"bookYear":"2024","nameKR":"야호정","ribbonType":"X","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":12699,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.65482699078136,"longitude":128.45124799954613,"bookYear":"2024","nameKR":"새대구숯불구이","ribbonType":"X","zone2_1":"경북","zone2_2":"예천군","status":"ACTIVE"},
        {"id":33952,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mimong_kitchen/","foodTypes":"이탈리아식","latitude":35.337860152939,"longitude":129.014983064663,"bookYear":"2024","nameKR":"미몽","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":31773,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.8287331285737,"longitude":127.175384697146,"bookYear":"2024","nameKR":"기찻길옆오막살이","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":33785,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeongdongjin_seafood/","foodTypes":"한식(어패류)","latitude":35.1604429383303,"longitude":129.0553650934,"bookYear":"2024","nameKR":"정동진해물탕해물찜","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34973,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.6731800145969,"longitude":128.265614412809,"bookYear":"2024","nameKR":"아천교횟집 ","ribbonType":"X","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":2680,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.248828,"longitude":127.222945,"bookYear":"2024","nameKR":"지산농원","ribbonType":"X","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":34431,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3027233227865,"longitude":127.402030633215,"bookYear":"2024","nameKR":"카페호야","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":3144,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.283619,"longitude":127.277137,"bookYear":"2024","nameKR":"자작나무이야기","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":27552,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.042812,"longitude":129.297576,"bookYear":"2024","nameKR":"송학할매손두부","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":3571,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.642884,"longitude":127.460413,"bookYear":"2024","nameKR":"양자강","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":5104,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.413254,"longitude":126.725492,"bookYear":"2024","nameKR":"사곶냉면","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":5275,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.342041,"longitude":127.425097,"bookYear":"2024","nameKR":"오씨칼국수","ribbonType":"X","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":25446,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.974696,"longitude":127.033657,"bookYear":"2024","nameKR":"한솔가든","ribbonType":"X","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":5505,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.169174,"longitude":129.120726,"bookYear":"2024","nameKR":"나룻터국수","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":6172,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.080375,"longitude":127.076242,"bookYear":"2024","nameKR":"태화루","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":33307,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.0132457830447,"longitude":127.664395248071,"bookYear":"2024","nameKR":"달달숲","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":34281,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.1505276574955,"longitude":128.594770392221,"bookYear":"2024","nameKR":"효령매운탕","ribbonType":"X","zone2_1":"대구","zone2_2":"군위군","status":"ACTIVE"},
        {"id":31466,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7937065038056,"longitude":126.954414349751,"bookYear":"2024","nameKR":"온달면가","ribbonType":"X","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":8973,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.284813,"longitude":129.014428,"bookYear":"2024","nameKR":"호포옛날할매집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":28710,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.467914,"longitude":126.699558,"bookYear":"2024","nameKR":"백령도메밀청풍면옥","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":30453,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lionel_louis_house/","foodTypes":"이탈리아식","latitude":35.665823584332756,"longitude":129.40769121349632,"bookYear":"2024","nameKR":"리루하","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":26123,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.207676,"longitude":127.064683,"bookYear":"2024","nameKR":"눈솜","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":27951,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.98771432488653,"longitude":126.72038789006149,"bookYear":"2024","nameKR":"유락식당","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":30642,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.391747615129944,"longitude":127.0579811329386,"bookYear":"2024","nameKR":"치화","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":36293,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8389572228343,"longitude":128.617267950763,"bookYear":"2024","nameKR":"정아칼치","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":28374,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.712681,"longitude":126.692825,"bookYear":"2024","nameKR":"카페나라","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":27251,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.989396,"longitude":126.709042,"bookYear":"2024","nameKR":"진갈비","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":17167,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.414315,"longitude":128.158133,"bookYear":"2024","nameKR":"수라간","ribbonType":"X","zone2_1":"경북","zone2_2":"상주시","status":"ACTIVE"},
        {"id":2053,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.150033,"longitude":128.659301,"bookYear":"2024","nameKR":"푸름각","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":2688,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.300246,"longitude":127.569778,"bookYear":"2024","nameKR":"경진각","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":32440,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.7615439596503,"longitude":127.01915143737,"bookYear":"2024","nameKR":"목화반점","ribbonType":"X","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":5325,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.1305027275877,"longitude":128.047743627634,"bookYear":"2024","nameKR":"광치막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"양구군","status":"ACTIVE"},
        {"id":5985,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.359231,"longitude":127.142956,"bookYear":"2024","nameKR":"장순루","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":32610,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hongnoodles/","foodTypes":"한식(면류)","latitude":37.286639113092,"longitude":126.989601971927,"bookYear":"2024","nameKR":"홍밀면옥","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34934,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.195635378458,"longitude":126.956107190853,"bookYear":"2024","nameKR":"장수촌","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":31208,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_ruscello_/ ","foodTypes":"이탈리아식","latitude":34.8167854776271,"longitude":128.673273433785,"bookYear":"2024","nameKR":"키친루셀로","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":37622,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.7995225011227,"longitude":126.981229014698,"bookYear":"2024","nameKR":"차우림","ribbonType":"X","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12547,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.175684,"longitude":129.195933,"bookYear":"2024","nameKR":"벨라루나","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":27139,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.507339,"longitude":128.777009,"bookYear":"2024","nameKR":"밀양할매메기탕","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":31931,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.1913878107844,"longitude":127.473324449838,"bookYear":"2024","nameKR":"명성각","ribbonType":"X","zone2_1":"충남","zone2_2":"금산군","status":"ACTIVE"},
        {"id":4332,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/masanagu.icheon_official/","foodTypes":"한식(일반한식)","latitude":37.236475,"longitude":127.365545,"bookYear":"2024","nameKR":"마산아구이천쌀밥","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":4336,"bookStatus":"승인","websiteInstagram":"http://instagram.com/wanteat_rice","foodTypes":"한식(일반한식)","latitude":37.2364418191812,"longitude":127.495454372032,"bookYear":"2024","nameKR":"원이쌀밥","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":28404,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.57003,"longitude":129.120288,"bookYear":"2024","nameKR":"언양불고기","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":5398,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.964984,"longitude":126.690081,"bookYear":"2024","nameKR":"주희네칼국수","ribbonType":"X","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":5953,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.442641,"longitude":126.430415,"bookYear":"2024","nameKR":"해송쌈밥","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":5957,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.038487,"longitude":129.356611,"bookYear":"2024","nameKR":"할매식당","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":6175,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.0819949824561,"longitude":127.051304262123,"bookYear":"2024","nameKR":"호아마이","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":34459,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":36.32380218954,"longitude":127.427420962185,"bookYear":"2024","nameKR":"만나(대흥본점)","ribbonType":"X","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":31061,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.4186120364846,"longitude":129.063178591978,"bookYear":"2024","nameKR":"다다물회집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":29503,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.28573313508838,"longitude":128.54396246018564,"bookYear":"2024","nameKR":"금화정막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":10775,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.092426,"longitude":128.819084,"bookYear":"2024","nameKR":"김해횟집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":13213,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jangan_restaurant/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.06090204127097,"longitude":126.52454285193468,"bookYear":"2024","nameKR":"장안식당","ribbonType":"X","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":31036,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5219647129089,"longitude":129.115070638699,"bookYear":"2024","nameKR":"냉면권가","ribbonType":"X","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":25738,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.560986,"longitude":126.853054,"bookYear":"2024","nameKR":"풍성한숯불갈비","ribbonType":"X","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":33955,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.3059486221156,"longitude":127.549729615127,"bookYear":"2024","nameKR":"맥우백년식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":34668,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/uglymood_cafe","foodTypes":"디저트/차/베이커리","latitude":37.3934747558952,"longitude":126.646717372459,"bookYear":"2024","nameKR":"어글리무드","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":3020,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.846062,"longitude":127.989719,"bookYear":"2024","nameKR":"대봉식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":3232,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.788064,"longitude":126.380557,"bookYear":"2024","nameKR":"한미르","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":25508,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.7964092694953,"longitude":128.91681353551,"bookYear":"2024","nameKR":"은파횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":34314,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lunamarket_yd/","foodTypes":"디저트/차/베이커리","latitude":36.261757569597,"longitude":127.898618361495,"bookYear":"2024","nameKR":"루나마켓","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"영동군","status":"ACTIVE"},
        {"id":22335,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.172269,"longitude":129.071685,"bookYear":"2024","nameKR":"늘해랑","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":5016,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.469525682618865,"longitude":128.6511188291295,"bookYear":"2024","nameKR":"동부식육식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":31018,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.8433518429245,"longitude":127.148382648533,"bookYear":"2024","nameKR":"진미반점","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":6523,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.077808,"longitude":128.022018,"bookYear":"2024","nameKR":"도촌막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"양구군","status":"ACTIVE"},
        {"id":8947,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.518078,"longitude":129.111575,"bookYear":"2024","nameKR":"참맛골","ribbonType":"X","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":9019,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":34.59133629556561,"longitude":127.41260524853672,"bookYear":"2024","nameKR":"일성식당","ribbonType":"X","zone2_1":"전남","zone2_2":"고흥군","status":"ACTIVE"},
        {"id":36424,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/songjukwon_/","foodTypes":"한식(민물어패류)","latitude":37.7841162307014,"longitude":128.904037773857,"bookYear":"2024","nameKR":"송죽원","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":11501,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seon_chaang/?hl=ko","foodTypes":"한식(어패류)","latitude":37.56291,"longitude":129.119555,"bookYear":"2024","nameKR":"선창횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":34912,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3721111776699,"longitude":126.92812277365,"bookYear":"2024","nameKR":"숨두부촌 ","ribbonType":"X","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":12664,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.0311235119141,"longitude":128.636574350561,"bookYear":"2024","nameKR":"산너머남촌","ribbonType":"X","zone2_1":"대구","zone2_2":"군위군","status":"ACTIVE"},
        {"id":35225,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3697232098627,"longitude":127.998489474515,"bookYear":"2024","nameKR":"몽그리즈치즈카페","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":13756,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gwangbeomene/","foodTypes":"한식(어패류)","latitude":38.3687144941201,"longitude":128.509595472138,"bookYear":"2024","nameKR":"광범이네횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":18258,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jung_deun1994/","foodTypes":"한식(면류)","latitude":38.211796,"longitude":128.587883,"bookYear":"2024","nameKR":"정든식당","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":25266,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":33.545797,"longitude":126.660752,"bookYear":"2024","nameKR":"다래향","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":2061,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.8416103281049,"longitude":128.419297170985,"bookYear":"2024","nameKR":"호동식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":2252,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/no.1_bokguk/","foodTypes":"한식(어패류)","latitude":35.24694,"longitude":129.213436,"bookYear":"2024","nameKR":"가마솥생복집","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":2691,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.309615,"longitude":127.561491,"bookYear":"2024","nameKR":"금강올갱이","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":4065,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.186371,"longitude":128.962926,"bookYear":"2024","nameKR":"태백순두부","ribbonType":"X","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":4379,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.290498,"longitude":127.640403,"bookYear":"2024","nameKR":"마을식당","ribbonType":"X","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":4471,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.439113,"longitude":129.19071,"bookYear":"2024","nameKR":"만남의식당","ribbonType":"X","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":36925,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thealicesejong/","foodTypes":"기타","latitude":36.4872910328777,"longitude":127.265380488081,"bookYear":"2024","nameKR":"디앨리스","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":4494,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.784751,"longitude":128.919197,"bookYear":"2024","nameKR":"초당고부순두부","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":27133,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/songdo_kong/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.417451,"longitude":126.654105,"bookYear":"2024","nameKR":"송도콩나물해장국밥","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":34540,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.242222441692,"longitude":127.318977702939,"bookYear":"2024","nameKR":"외할머니집","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":5562,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.49341062256603,"longitude":129.0814305475648,"bookYear":"2024","nameKR":"경기식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":27140,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.101191,"longitude":128.976124,"bookYear":"2024","nameKR":"할매복집","ribbonType":"X","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":11193,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2669709895718,"longitude":129.23351097849,"bookYear":"2024","nameKR":"일광대복집","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":27141,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.367383,"longitude":128.509983,"bookYear":"2024","nameKR":"자매해녀횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":35099,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/painpainpain_busan/","foodTypes":"디저트/차/베이커리","latitude":35.154532464031,"longitude":129.14454331149,"bookYear":"2024","nameKR":"아티성블렁제리빵빵빵","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":26657,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.24208374914679,"longitude":128.42287909756718,"bookYear":"2024","nameKR":"대구식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"함안군","status":"ACTIVE"},
        {"id":32335,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/habaek.cafe/","foodTypes":"디저트/차/베이커리","latitude":37.6152126044709,"longitude":127.343247005659,"bookYear":"2024","nameKR":"하백","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":3652,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.712132,"longitude":126.813111,"bookYear":"2024","nameKR":"두리원 손두부","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":30761,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yasojuban","foodTypes":"한식(일반한식)","latitude":34.8064207404621,"longitude":128.399980320749,"bookYear":"2024","nameKR":"야소주반","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":6239,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/chefcantante/","foodTypes":"이탈리아식","latitude":37.394471049824794,"longitude":127.24302707005315,"bookYear":"2024","nameKR":"쉐프깐딴떼","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":32872,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pizzeria_biny_s","foodTypes":"이탈리아식","latitude":37.3908091831812,"longitude":127.091157317881,"bookYear":"2024","nameKR":"피제리아비니스","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":31426,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/planet1020_official/","foodTypes":"디저트/차/베이커리","latitude":35.2094990252079,"longitude":127.640752845963,"bookYear":"2024","nameKR":"플래닛1020","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":29848,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":33.5032053972907,"longitude":126.617177861619,"bookYear":"2024","nameKR":"맥파이브루어리","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":35227,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/daeryong_official/","foodTypes":"중식","latitude":35.1726510696047,"longitude":128.05925668107,"bookYear":"2024","nameKR":"대룡중식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":34596,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dotlineespressobar","foodTypes":"디저트/차/베이커리","latitude":36.6257216451429,"longitude":127.429271676176,"bookYear":"2024","nameKR":"점선에스프레소바","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":34545,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.3762277609286,"longitude":126.765035926852,"bookYear":"2024","nameKR":"마음한끼","ribbonType":"X","zone2_1":"전남","zone2_2":"장성군","status":"ACTIVE"},
        {"id":23854,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dal_6_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.76849,"longitude":127.463999,"bookYear":"2024","nameKR":"달과6펜스 베이커리 카페","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":33584,"bookStatus":"승인","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.7392520595464,"longitude":127.431869207474,"bookYear":"2024","nameKR":"쉐누","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":32858,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/daebak_gak/","foodTypes":"중식","latitude":37.6703408611995,"longitude":126.762784599418,"bookYear":"2024","nameKR":"대박각","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":31394,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.1580744527928,"longitude":128.224874108903,"bookYear":"2024","nameKR":"순수해","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":31721,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/osteriavivi","foodTypes":"이탈리아식","latitude":35.125273376129,"longitude":129.039060349358,"bookYear":"2024","nameKR":"오스테리아비비","ribbonType":"X","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":35972,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1617668654949,"longitude":129.163192394835,"bookYear":"2024","nameKR":"진우린해장","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":31976,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.9656430273888,"longitude":128.043010920758,"bookYear":"2024","nameKR":"씨맨스카페","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":2466,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.4841815984293,"longitude":126.265413934466,"bookYear":"2024","nameKR":"옥천횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"진도군","status":"ACTIVE"},
        {"id":2717,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.531551931209044,"longitude":127.82292077827792,"bookYear":"2024","nameKR":"신토불이약초식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":30915,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.3860632906962,"longitude":127.496752891831,"bookYear":"2024","nameKR":"은골할먼네","ribbonType":"X","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":5501,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2207991112241,"longitude":129.081971087676,"bookYear":"2024","nameKR":"부산자갈치왕곰장어","ribbonType":"X","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":25945,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.641877,"longitude":128.748152,"bookYear":"2024","nameKR":"시골집","ribbonType":"X","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":8186,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.21212220496056,"longitude":129.0057447961472,"bookYear":"2024","nameKR":"호호면옥","ribbonType":"X","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":8917,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.089288,"longitude":127.206909,"bookYear":"2024","nameKR":"지장산막국수","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":34897,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6372656867627,"longitude":126.395755285976,"bookYear":"2024","nameKR":"꽃게연가","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":23061,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.1482764641751,"longitude":127.074318422287,"bookYear":"2024","nameKR":"운암회관","ribbonType":"X","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":12644,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.740199,"longitude":128.06876,"bookYear":"2024","nameKR":"문경새재오는길","ribbonType":"X","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":27349,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.384991,"longitude":127.114069,"bookYear":"2024","nameKR":"루프엑스","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":33104,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wolyeongdang/","foodTypes":"디저트/차/베이커리","latitude":36.5788511386475,"longitude":128.764588904743,"bookYear":"2024","nameKR":"월영당","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":13473,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.6039190840701,"longitude":127.113012231194,"bookYear":"2024","nameKR":"하루","ribbonType":"X","zone2_1":"전북","zone2_2":"임실군","status":"ACTIVE"},
        {"id":16767,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.0178757290828,"longitude":129.351241378607,"bookYear":"2024","nameKR":"수가성","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":34038,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3949423122559,"longitude":126.650165044813,"bookYear":"2024","nameKR":"시드니양갈비","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":2331,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.536475,"longitude":129.335203,"bookYear":"2024","nameKR":"울산돼지국밥","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":4274,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.35545,"longitude":127.344992,"bookYear":"2024","nameKR":"청주해장국","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":4483,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.173535,"longitude":128.992806,"bookYear":"2024","nameKR":"부래실비식당","ribbonType":"X","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":4541,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.825697,"longitude":128.62496,"bookYear":"2024","nameKR":"아리조나막창","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":5976,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.818257,"longitude":127.149974,"bookYear":"2024","nameKR":"풍전콩나물국밥","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":30846,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8575059999915,"longitude":128.545761698565,"bookYear":"2024","nameKR":"내당동섬","ribbonType":"X","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":31084,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":36.4901153255575,"longitude":126.336651806813,"bookYear":"2024","nameKR":"아일랜드57","ribbonType":"X","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":8203,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.15579937139768,"longitude":129.12288849951688,"bookYear":"2024","nameKR":"새벽집","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":9272,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.627541409669014,"longitude":126.89205531009365,"bookYear":"2024","nameKR":"서오릉메카다슬기","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":28389,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.86628870188552,"longitude":127.72318163076427,"bookYear":"2024","nameKR":"남부해장국","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":30529,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.83704317779539,"longitude":128.6171351355747,"bookYear":"2024","nameKR":"양곱화","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":12417,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.87103,"longitude":128.592669,"bookYear":"2024","nameKR":"한우장","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":13339,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.146107,"longitude":126.915316,"bookYear":"2024","nameKR":"평화식당","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":26679,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/press__b/","foodTypes":"바/주점","latitude":37.2074774156432,"longitude":127.061577748401,"bookYear":"2024","nameKR":"프레스비","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":32004,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/montbeer_official/","foodTypes":"바/주점","latitude":38.2043564539355,"longitude":128.52809343011,"bookYear":"2024","nameKR":"몽트비어","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":30527,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dongiok7722/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.835537973887966,"longitude":128.6162192535236,"bookYear":"2024","nameKR":"동이옥","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":37705,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1644269548256,"longitude":128.984538919088,"bookYear":"2024","nameKR":"램바란스","ribbonType":"X","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":20781,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.797159,"longitude":126.427051,"bookYear":"2024","nameKR":"옥정한정식","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":2363,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.474374,"longitude":126.597567,"bookYear":"2024","nameKR":"예전","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":36096,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/busan_alley_house/","foodTypes":"한식(육류)","latitude":35.1139846812025,"longitude":129.11131646801,"bookYear":"2024","nameKR":"용호동골목집","ribbonType":"X","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":11398,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.1671970117476,"longitude":129.132039053517,"bookYear":"2024","nameKR":"뜰아래채","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":30626,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.637827091610916,"longitude":128.1700981400651,"bookYear":"2024","nameKR":"동성반점","ribbonType":"X","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":14048,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.349762,"longitude":129.329171,"bookYear":"2024","nameKR":"베테랑바베큐","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":33803,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.1571054220807,"longitude":129.061603494057,"bookYear":"2024","nameKR":"몫몫","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":18245,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.15314,"longitude":126.913507,"bookYear":"2024","nameKR":"새반도정","ribbonType":"X","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":16161,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.011647,"longitude":128.224146,"bookYear":"2024","nameKR":"학현식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":1758,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.796848,"longitude":127.160743,"bookYear":"2024","nameKR":"고모리691","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":1959,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.387732,"longitude":127.1244,"bookYear":"2024","nameKR":"탈리","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":2273,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.154801,"longitude":129.131826,"bookYear":"2024","nameKR":"밀레니엄횟집","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":2626,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_seogwipo25/","foodTypes":"한식(어패류)","latitude":33.239466,"longitude":126.438257,"bookYear":"2024","nameKR":"큰갯물횟집","ribbonType":"X","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":3050,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.600935413386,"longitude":126.814623801957,"bookYear":"2024","nameKR":"일미정","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":10707,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.709341,"longitude":128.015732,"bookYear":"2024","nameKR":"가남보리밥전문식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거창군","status":"ACTIVE"},
        {"id":25380,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.069738,"longitude":127.02172,"bookYear":"2024","nameKR":"하늘아래","ribbonType":"X","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":30528,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.83016227553433,"longitude":128.61792058735452,"bookYear":"2024","nameKR":"내안에","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":32783,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.3919331303769,"longitude":126.954812908919,"bookYear":"2024","nameKR":"호랑이굴","ribbonType":"X","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":36791,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.6450543770892,"longitude":126.784560867709,"bookYear":"2024","nameKR":"해오름한정식","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":37559,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nakbuljip/","foodTypes":"한식(어패류)","latitude":35.1754316611564,"longitude":129.196461811216,"bookYear":"2024","nameKR":"낙불집(송정본점)","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":29633,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/woodam2014_official/","foodTypes":"한식(육류)","latitude":37.3343611913347,"longitude":127.929200017682,"bookYear":"2024","nameKR":"우담","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":4096,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.752176,"longitude":126.911992,"bookYear":"2024","nameKR":"보리고개","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":33861,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeomungang/","foodTypes":"기타","latitude":37.4993760594158,"longitude":127.992211169132,"bookYear":"2024","nameKR":"저문강에삽을씻고","ribbonType":"X","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":31967,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ins_mill/","foodTypes":"디저트/차/베이커리","latitude":33.2384787715141,"longitude":126.229230662407,"bookYear":"2024","nameKR":"인스밀","ribbonType":"X","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":29248,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.370303,"longitude":127.10679,"bookYear":"2024","nameKR":"레스토랑마고","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":30702,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.3973207561987,"longitude":127.11356226927,"bookYear":"2024","nameKR":"스시혼","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":33908,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.5463056024436,"longitude":129.327388782432,"bookYear":"2024","nameKR":"울산언양불고기","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":32355,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/khunwon_official/","foodTypes":"중식","latitude":37.6509174810735,"longitude":126.778893109192,"bookYear":"2024","nameKR":"군원","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":12357,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.7416322346961,"longitude":126.651562507811,"bookYear":"2024","nameKR":"외갓집","ribbonType":"X","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":31715,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ricoj_restaurant","foodTypes":"이탈리아식","latitude":36.3864809428662,"longitude":127.379011156499,"bookYear":"2024","nameKR":"리코제이","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":37561,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pigbanggu/","foodTypes":"기타","latitude":35.2174432458898,"longitude":129.224815003551,"bookYear":"2024","nameKR":"고향연화","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":18233,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.417116,"longitude":127.132468,"bookYear":"2024","nameKR":"사계진미","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":28592,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.1266999135301,"longitude":128.10493822324818,"bookYear":"2024","nameKR":"강성면옥","ribbonType":"X","zone2_1":"경북","zone2_2":"김천시","status":"ACTIVE"},
        {"id":1619,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.49192131692736,"longitude":129.10949779148663,"bookYear":"2024","nameKR":"동그라미해물집","ribbonType":"X","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":34480,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1645986324747,"longitude":129.175028120511,"bookYear":"2024","nameKR":"스무고개","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":2440,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.064478,"longitude":126.984058,"bookYear":"2024","nameKR":"석란","ribbonType":"X","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":27854,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sookumjae_official/","foodTypes":"한식(일반한식)","latitude":35.223849,"longitude":128.685018,"bookYear":"2024","nameKR":"수금재","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":31055,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":35.0716400952136,"longitude":129.057362356798,"bookYear":"2024","nameKR":"오채담","ribbonType":"X","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":6975,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.370244,"longitude":127.107506,"bookYear":"2024","nameKR":"풍천장어전문","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":29896,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushiewa_official/","foodTypes":"일식","latitude":37.393785630906656,"longitude":126.64187294130727,"bookYear":"2024","nameKR":"스시이와","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":30667,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.32108298441544,"longitude":127.37750046420177,"bookYear":"2024","nameKR":"대성콩국수","ribbonType":"X","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":36817,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/redlighthouse_/","foodTypes":"한식(어패류)","latitude":35.0849553647222,"longitude":129.035724402913,"bookYear":"2024","nameKR":"빨간등대영도본점","ribbonType":"X","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":31489,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.4668629585736,"longitude":126.337619221079,"bookYear":"2024","nameKR":"고내횟집","ribbonType":"X","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":2716,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.007643,"longitude":127.916945,"bookYear":"2024","nameKR":"신라정","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":3645,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.490211,"longitude":126.725232,"bookYear":"2024","nameKR":"비하니","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":35968,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":34.9302092046607,"longitude":128.072189223093,"bookYear":"2024","nameKR":"삼천포한옥집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":4325,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.330351,"longitude":126.862813,"bookYear":"2024","nameKR":"구이동","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":4365,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.263471998622,"longitude":127.030007009288,"bookYear":"2024","nameKR":"바다예찬","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":4507,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.200456632421684,"longitude":128.85423416652245,"bookYear":"2024","nameKR":"낙원회관","ribbonType":"X","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":4849,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.382475,"longitude":126.961744,"bookYear":"2024","nameKR":"연평도아구찜꽃게찜","ribbonType":"X","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":5389,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.64694,"longitude":127.10918,"bookYear":"2024","nameKR":"태릉허참갈비","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":5458,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.511855,"longitude":126.774606,"bookYear":"2024","nameKR":"손가면옥","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":36183,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/la.tarta__/","foodTypes":"디저트/차/베이커리","latitude":37.8875844320993,"longitude":127.776340574548,"bookYear":"2024","nameKR":"라타르타","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":7007,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.831559,"longitude":128.714876,"bookYear":"2024","nameKR":"강성횟집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":31351,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/arirang_foodville","foodTypes":"한식(육류)","latitude":37.8908020858711,"longitude":127.056612278066,"bookYear":"2024","nameKR":"아리랑갈비","ribbonType":"X","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":32170,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/foremost_cafe/","foodTypes":"디저트/차/베이커리","latitude":36.5032242277783,"longitude":128.676127730076,"bookYear":"2024","nameKR":"폴모스트","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":25796,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.856427,"longitude":128.589096,"bookYear":"2024","nameKR":"그저모이기","ribbonType":"X","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":19173,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.13236,"longitude":129.093523,"bookYear":"2024","nameKR":"나들목","ribbonType":"X","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":31117,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.5165302156963,"longitude":126.515170039058,"bookYear":"2024","nameKR":"용연횟집","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":26287,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.158971,"longitude":128.987126,"bookYear":"2024","nameKR":"태백실비식당","ribbonType":"X","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":31864,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/odongchuya","foodTypes":"한식(육류)","latitude":37.2902126872204,"longitude":127.452146808163,"bookYear":"2024","nameKR":"오동추야","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":33820,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.8916165828093,"longitude":128.615398678147,"bookYear":"2024","nameKR":"마이블루발렌타인","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":32228,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yangwadang_sc/","foodTypes":"디저트/차/베이커리","latitude":34.9472505740057,"longitude":127.51357919503,"bookYear":"2024","nameKR":"양와당","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":1747,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.777978,"longitude":127.116132,"bookYear":"2024","nameKR":"갈비생각","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":1974,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.501403,"longitude":127.419594,"bookYear":"2024","nameKR":"평양초계탕막국수","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":2198,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.866654,"longitude":128.630573,"bookYear":"2024","nameKR":"인화반점","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":2471,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.359779,"longitude":126.447167,"bookYear":"2024","nameKR":"일번지식당","ribbonType":"X","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":2672,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.778297,"longitude":126.466467,"bookYear":"2024","nameKR":"청원꽃게장","ribbonType":"X","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":36944,"bookStatus":"승인","websiteInstagram":"http://www.instagram.com/jeongon251_","foodTypes":"중식","latitude":37.5483379803093,"longitude":127.216582349876,"bookYear":"2024","nameKR":"정온","ribbonType":"X","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":27253,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.805095,"longitude":127.108325,"bookYear":"2024","nameKR":"한양불고기","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":31616,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lotus.hill_in_yangsu/","foodTypes":"한식(일반한식)","latitude":37.5498463041723,"longitude":127.325680288522,"bookYear":"2024","nameKR":"연꽃언덕","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":30923,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/merdecour_offical/","foodTypes":"디저트/차/베이커리","latitude":35.2330732998624,"longitude":129.244129676673,"bookYear":"2024","nameKR":"메르데쿠르","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":9474,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.476081,"longitude":126.619266,"bookYear":"2024","nameKR":"만다복","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":30418,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.41084231859582,"longitude":127.12681732185294,"bookYear":"2024","nameKR":"해올","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37612,"bookStatus":"승인","websiteInstagram":"http://Instagram.com/maejuri_coffee","foodTypes":"디저트/차/베이커리","latitude":36.9236072240647,"longitude":127.129633716207,"bookYear":"2024","nameKR":"매주리커피","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":13050,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.028942,"longitude":127.067412,"bookYear":"2024","nameKR":"아씨마늘보쌈","ribbonType":"X","zone2_1":"경기","zone2_2":"연천군","status":"ACTIVE"},
        {"id":25962,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.88219,"longitude":128.650158,"bookYear":"2024","nameKR":"만나식당","ribbonType":"X","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":1923,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.026084,"longitude":127.369043,"bookYear":"2024","nameKR":"소문난이동갈비","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":2958,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4809906084254,"longitude":126.62073488891,"bookYear":"2024","nameKR":"우순임할머니쭈꾸미집","ribbonType":"X","zone2_1":"인천","zone2_2":"동구","status":"ACTIVE"},
        {"id":2990,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.881705856571045,"longitude":126.38306128424112,"bookYear":"2024","nameKR":"낙지한마당","ribbonType":"X","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":34918,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.1938294967862,"longitude":127.028910104326,"bookYear":"2024","nameKR":"행복한콩박사","ribbonType":"X","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":4405,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.852546,"longitude":127.104313,"bookYear":"2024","nameKR":"갑기회관","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":5415,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.504052,"longitude":126.483116,"bookYear":"2024","nameKR":"시월애","ribbonType":"X","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":8131,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.457954,"longitude":127.428218,"bookYear":"2024","nameKR":"장수정","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":34336,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.4003793746832,"longitude":127.10208724198,"bookYear":"2024","nameKR":"교소바","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":9765,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.270087,"longitude":127.258531,"bookYear":"2024","nameKR":"예사랑막국수","ribbonType":"X","zone2_1":"충남","zone2_2":"계룡시","status":"ACTIVE"},
        {"id":33613,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/fruiterie_forest/","foodTypes":"디저트/차/베이커리","latitude":35.1795955814326,"longitude":129.192819878955,"bookYear":"2024","nameKR":"프루터리포레스트","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":11205,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.288184,"longitude":129.170847,"bookYear":"2024","nameKR":"미동암소정","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":30576,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.522671295194066,"longitude":127.1893827799818,"bookYear":"2024","nameKR":"최부자집","ribbonType":"X","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":36711,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2687045173268,"longitude":126.941256581248,"bookYear":"2024","nameKR":"달빛뜨락","ribbonType":"X","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":29843,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/changbawi/","foodTypes":"한식(가금류)","latitude":38.20705017843263,"longitude":128.5111862692824,"bookYear":"2024","nameKR":"창바위식당","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":3015,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.813398,"longitude":127.517741,"bookYear":"2024","nameKR":"송애집","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"진천군","status":"ACTIVE"},
        {"id":3603,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.004493,"longitude":126.709018,"bookYear":"2024","nameKR":"대지회관","ribbonType":"X","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":5375,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":38.057779,"longitude":127.029518,"bookYear":"2024","nameKR":"황지참게매운탕","ribbonType":"X","zone2_1":"경기","zone2_2":"연천군","status":"ACTIVE"},
        {"id":5448,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.599468105125,"longitude":126.826198744418,"bookYear":"2024","nameKR":"화정가든","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":6205,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.384538,"longitude":127.131216,"bookYear":"2024","nameKR":"민수라간장게장","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":29784,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/joyang_bangjik/","foodTypes":"디저트/차/베이커리","latitude":37.74675367474736,"longitude":126.48096278048874,"bookYear":"2024","nameKR":"조양방직카페","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":8936,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.304283,"longitude":128.540016,"bookYear":"2024","nameKR":"기화양어장횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":8986,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.982587,"longitude":128.579513,"bookYear":"2024","nameKR":"안심식당","ribbonType":"X","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":30931,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/vajara_korea","foodTypes":"일식","latitude":35.2515923748461,"longitude":128.996218551237,"bookYear":"2024","nameKR":"바자라","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":24029,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.393411837581,"longitude":127.351975086998,"bookYear":"2024","nameKR":"숯골원조냉면","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":19659,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.241482,"longitude":128.301701,"bookYear":"2024","nameKR":"대한곱창","ribbonType":"X","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":33310,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/soulroastery/","foodTypes":"디저트/차/베이커리","latitude":37.9243652323758,"longitude":127.767251617335,"bookYear":"2024","nameKR":"소울로스터리","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":32265,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.9380189457564,"longitude":127.777244940929,"bookYear":"2024","nameKR":"오수물막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":1595,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.3806200845628,"longitude":128.443203393037,"bookYear":"2024","nameKR":"교동막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":1708,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.1031541293993,"longitude":127.707402399113,"bookYear":"2024","nameKR":"천일막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"화천군","status":"ACTIVE"},
        {"id":2223,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.416023,"longitude":127.381119,"bookYear":"2024","nameKR":"산밑할머니묵집","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":2377,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.473982,"longitude":126.618571,"bookYear":"2024","nameKR":"태화원","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":33981,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/only_yoo1/","foodTypes":"한식(가금류)","latitude":37.8305618661562,"longitude":127.513662956144,"bookYear":"2024","nameKR":"유일닭강정","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":5416,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.1102653847221,"longitude":127.565886647017,"bookYear":"2024","nameKR":"인삼골식당","ribbonType":"X","zone2_1":"충남","zone2_2":"금산군","status":"ACTIVE"},
        {"id":5556,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/doriwon365.co.kr/","foodTypes":"한식(일반한식)","latitude":35.446173,"longitude":128.538955,"bookYear":"2024","nameKR":"도리원","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창녕군","status":"ACTIVE"},
        {"id":29697,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/planet_coffee_sancheong/","foodTypes":"디저트/차/베이커리","latitude":35.46419674410944,"longitude":127.79610360873674,"bookYear":"2024","nameKR":"플래닛커피산청점","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":31388,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.5774114603257,"longitude":128.757951484028,"bookYear":"2024","nameKR":"월영교달빵","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":6987,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.9889510752764,"longitude":126.711626709765,"bookYear":"2024","nameKR":"송강식당","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":32301,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hui_hui_official/","foodTypes":"중식","latitude":37.2451294220493,"longitude":127.061898704684,"bookYear":"2024","nameKR":"후이후이","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":31114,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/forkner_official/","foodTypes":"이탈리아식","latitude":37.3181157520239,"longitude":126.836545917769,"bookYear":"2024","nameKR":"포크너","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":32468,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/milkstorehouse/","foodTypes":"디저트/차/베이커리","latitude":36.4881546093341,"longitude":126.527057771048,"bookYear":"2024","nameKR":"우유창고","ribbonType":"X","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":31755,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oedonursery","foodTypes":"디저트/차/베이커리","latitude":34.8067178557759,"longitude":128.693401363008,"bookYear":"2024","nameKR":"외도널서리","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":8948,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.792063,"longitude":128.087039,"bookYear":"2024","nameKR":"삼성식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"합천군","status":"ACTIVE"},
        {"id":30699,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/happynudi","foodTypes":"디저트/차/베이커리","latitude":37.29090528471981,"longitude":127.04772676181096,"bookYear":"2024","nameKR":"해피누디","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":31220,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.3882125742472,"longitude":126.65670715787,"bookYear":"2024","nameKR":"삼대냉면","ribbonType":"X","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":32562,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gyeongju_allbareut/","foodTypes":"한식(어패류)","latitude":35.8472388275632,"longitude":129.280212090982,"bookYear":"2024","nameKR":"올바릇식당","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":34979,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.4480035928756,"longitude":126.524334015026,"bookYear":"2024","nameKR":"먹쇠네굴집","ribbonType":"X","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":36235,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":34.8215252471671,"longitude":127.889000699507,"bookYear":"2024","nameKR":"바래온","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":33135,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.3891408885668,"longitude":126.644442676851,"bookYear":"2024","nameKR":"파노라믹65","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":30249,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.162385634362074,"longitude":127.7567608694106,"bookYear":"2024","nameKR":"아리랑가든","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"영동군","status":"ACTIVE"},
        {"id":32807,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.9806291539857,"longitude":128.062349590629,"bookYear":"2024","nameKR":"삼천포맛집정서방","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":32842,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.0601674702656,"longitude":127.175617855715,"bookYear":"2024","nameKR":"싼타나레스토랑","ribbonType":"X","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":3241,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.790202,"longitude":127.005664,"bookYear":"2024","nameKR":"소나무집","ribbonType":"X","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":4459,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.42496,"longitude":127.382619,"bookYear":"2024","nameKR":"구즉묵집","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":37597,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.4890057749203,"longitude":127.489181044195,"bookYear":"2024","nameKR":"비원매운탕","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":37617,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jabango_biz/","foodTypes":"한식(어패류)","latitude":37.7845486977172,"longitude":127.085045192157,"bookYear":"2024","nameKR":"자반고","ribbonType":"X","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":24782,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.757781,"longitude":126.908437,"bookYear":"2024","nameKR":"평양초계탕막국수","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":19486,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.346382,"longitude":127.325226,"bookYear":"2024","nameKR":"강경옥","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":31073,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.798874839353,"longitude":126.422236137681,"bookYear":"2024","nameKR":"명인집","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":31663,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6800494066693,"longitude":126.849619975781,"bookYear":"2024","nameKR":"더더간장게장","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":31775,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.2915676707273,"longitude":127.050470929016,"bookYear":"2024","nameKR":"오이탈리안","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":31429,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/js_pot/","foodTypes":"중식","latitude":37.394245407468,"longitude":127.110306812433,"bookYear":"2024","nameKR":"제이스팟","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":16904,"bookStatus":"승인","websiteInstagram":"http://instagram.com/trouts_house","foodTypes":"한식(민물어패류)","latitude":37.363779,"longitude":128.415408,"bookYear":"2024","nameKR":"송어의집","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":852,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.397233,"longitude":127.113519,"bookYear":"2024","nameKR":"커피미학","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":31398,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.4913538491328,"longitude":127.467010820575,"bookYear":"2024","nameKR":"핏제리아루카","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":2281,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.115226,"longitude":129.038612,"bookYear":"2024","nameKR":"사해방","ribbonType":"X","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":3373,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.85361098059707,"longitude":129.20317069628425,"bookYear":"2024","nameKR":"슈만과클라라","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30891,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3885615213589,"longitude":127.53890401471,"bookYear":"2024","nameKR":"방아실돼지집","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":4535,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.338008,"longitude":127.188184,"bookYear":"2024","nameKR":"향가산장","ribbonType":"X","zone2_1":"전북","zone2_2":"순창군","status":"ACTIVE"},
        {"id":32211,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6507958617244,"longitude":126.648031557932,"bookYear":"2024","nameKR":"갈비본질프리미엄","ribbonType":"X","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":34323,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/merheure_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.1349707922065,"longitude":127.039577579582,"bookYear":"2024","nameKR":"메르오르","ribbonType":"X","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":28060,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.51430696188853,"longitude":127.25860425575911,"bookYear":"2024","nameKR":"카페더코너","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":8159,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.343024,"longitude":127.441241,"bookYear":"2024","nameKR":"적덕식당","ribbonType":"X","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":8201,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.135778,"longitude":129.068331,"bookYear":"2024","nameKR":"칠성식당","ribbonType":"X","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":31558,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pyeongyangilmi/","foodTypes":"한식(면류)","latitude":37.2730876685805,"longitude":127.061262679777,"bookYear":"2024","nameKR":"평양일미","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":29918,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hey_mer_official/","foodTypes":"디저트/차/베이커리","latitude":35.3683728554488,"longitude":129.348636093127,"bookYear":"2024","nameKR":"헤이메르","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":8892,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1555729538212,"longitude":126.83848998676393,"bookYear":"2024","nameKR":"삼희불낙","ribbonType":"X","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":26705,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.305872,"longitude":126.516509,"bookYear":"2024","nameKR":"조개까는남자","ribbonType":"X","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":30520,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.834701020412126,"longitude":128.6170743143053,"bookYear":"2024","nameKR":"미남장어","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":12889,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.518214,"longitude":126.52855,"bookYear":"2024","nameKR":"대진횟집","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":33881,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.0632992990447,"longitude":129.389455395717,"bookYear":"2024","nameKR":"설머리횟집","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":30526,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.8342044684755,"longitude":128.616211900208,"bookYear":"2024","nameKR":"금산삼계탕(본점)","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":17467,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.8017724357011,"longitude":128.91097345761,"bookYear":"2024","nameKR":"경포팔도강산","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":35995,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.3288399165026,"longitude":128.229340921969,"bookYear":"2024","nameKR":"초가산장","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"의령군","status":"ACTIVE"},
        {"id":31053,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1857648319727,"longitude":129.211775919248,"bookYear":"2024","nameKR":"원조짚불곰장어기장외가집","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":33115,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafepiora/","foodTypes":"디저트/차/베이커리","latitude":36.8061309776846,"longitude":126.740328573905,"bookYear":"2024","nameKR":"카페피어라","ribbonType":"X","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":30883,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pervade_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.7632275825168,"longitude":128.886859823307,"bookYear":"2024","nameKR":"퍼베이드","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":4092,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.102312973656105,"longitude":129.02888975092546,"bookYear":"2024","nameKR":"부산숯불갈비","ribbonType":"X","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":27914,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":35.222833,"longitude":128.682596,"bookYear":"2024","nameKR":"브런치팩토리","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":32106,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.8922482486066,"longitude":127.723876377649,"bookYear":"2024","nameKR":"메바우명가춘천막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":30921,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hayden__coffee/","foodTypes":"디저트/차/베이커리","latitude":35.2823815761636,"longitude":129.25668703928,"bookYear":"2024","nameKR":"헤이든","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":6456,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.402590512590365,"longitude":127.54651124213107,"bookYear":"2024","nameKR":"천서리막국수","ribbonType":"X","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":8207,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.146039,"longitude":129.111905,"bookYear":"2024","nameKR":"본가제일면가","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":8984,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.14608,"longitude":128.311197,"bookYear":"2024","nameKR":"천안문","ribbonType":"X","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":36748,"bookStatus":"승인","websiteInstagram":"http://instagram.com/gtsburger_zen","foodTypes":"기타","latitude":37.250664904498,"longitude":127.064089695338,"bookYear":"2024","nameKR":"GTS버거 ","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":13082,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bagdadcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.702439,"longitude":126.510022,"bookYear":"2024","nameKR":"바그다드커피","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":18650,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.772309,"longitude":127.37141,"bookYear":"2024","nameKR":"금강막국수닭갈비","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":30913,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/glever_cafe/","foodTypes":"디저트/차/베이커리","latitude":36.4509208625614,"longitude":127.470310143254,"bookYear":"2024","nameKR":"글레버","ribbonType":"X","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":32110,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/road_1950_official/","foodTypes":"기타","latitude":36.917444061188,"longitude":126.812597333026,"bookYear":"2024","nameKR":"로드1950","ribbonType":"X","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":1890,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.645083,"longitude":127.383202,"bookYear":"2024","nameKR":"엔로제","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":3596,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.18800092914892,"longitude":127.3938904721671,"bookYear":"2024","nameKR":"하한산장","ribbonType":"X","zone2_1":"전남","zone2_2":"곡성군","status":"ACTIVE"},
        {"id":4460,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.416007,"longitude":127.384185,"bookYear":"2024","nameKR":"산골묵집","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":4819,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.126498,"longitude":129.070873,"bookYear":"2024","nameKR":"내호냉면","ribbonType":"X","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":30227,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/haus_bakery","foodTypes":"디저트/차/베이커리","latitude":37.5984974209966,"longitude":127.35299416525,"bookYear":"2024","nameKR":"하우스베이커리","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":31966,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.4629252295724,"longitude":126.309244734174,"bookYear":"2024","nameKR":"몽상드애월","ribbonType":"X","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":22387,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.270044,"longitude":126.654102,"bookYear":"2024","nameKR":"무주향","ribbonType":"X","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":29849,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pung_lim_dabang/","foodTypes":"디저트/차/베이커리","latitude":33.4718737008442,"longitude":126.785710834013,"bookYear":"2024","nameKR":"풍림다방","ribbonType":"X","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":31308,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mimiok_official/","foodTypes":"한식(면류)","latitude":35.5846244686024,"longitude":129.345933620638,"bookYear":"2024","nameKR":"미미옥(울산점)","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":33317,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/j___forest/","foodTypes":"디저트/차/베이커리","latitude":35.5387382401505,"longitude":126.857169212473,"bookYear":"2024","nameKR":"제이포렛","ribbonType":"X","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":9033,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.10994613978944,"longitude":127.56633349202367,"bookYear":"2024","nameKR":"강변가든","ribbonType":"X","zone2_1":"충남","zone2_2":"금산군","status":"ACTIVE"},
        {"id":30972,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/karuna_in_yangyang/","foodTypes":"디저트/차/베이커리","latitude":37.9674952649956,"longitude":128.762053337078,"bookYear":"2024","nameKR":"카루나","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":10571,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.478825,"longitude":128.421884,"bookYear":"2024","nameKR":"화진포박포수가든","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":32787,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dnucoffee/","foodTypes":"디저트/차/베이커리","latitude":35.5170034088586,"longitude":129.307816852032,"bookYear":"2024","nameKR":"디앤유커피팩토리","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":36818,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.0860351248094,"longitude":129.034926461517,"bookYear":"2024","nameKR":"영도빨간등대밀면","ribbonType":"X","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":35215,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.4050371178808,"longitude":127.302073674526,"bookYear":"2024","nameKR":"예이제448","ribbonType":"X","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":31395,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.1831177082292,"longitude":128.204642366243,"bookYear":"2024","nameKR":"카페피노","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":34468,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafeview66/","foodTypes":"디저트/차/베이커리","latitude":37.5873233180689,"longitude":127.176092468371,"bookYear":"2024","nameKR":"뷰66","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":1695,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.570347,"longitude":128.344825,"bookYear":"2024","nameKR":"평창축협대관령한우타운 면온점","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1871,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4680478222731,"longitude":127.202748358515,"bookYear":"2024","nameKR":"아라비카","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":2303,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.113467,"longitude":129.037685,"bookYear":"2024","nameKR":"장춘향","ribbonType":"X","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":33337,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/big_reaction201/","foodTypes":"기타","latitude":34.787729818929,"longitude":126.367350144258,"bookYear":"2024","nameKR":"대반동201","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":2877,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.924719,"longitude":126.90545,"bookYear":"2024","nameKR":"은영이네","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":37614,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/anarkh.official/","foodTypes":"디저트/차/베이커리","latitude":37.728517826312,"longitude":127.113721038619,"bookYear":"2024","nameKR":"아나키아","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":4090,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.916237,"longitude":127.712757,"bookYear":"2024","nameKR":"미스타페오","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":5417,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.996999855295144,"longitude":128.1667270075069,"bookYear":"2024","nameKR":"느티나무횟집","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":32377,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/adcafe_dj/","foodTypes":"디저트/차/베이커리","latitude":36.4483238051193,"longitude":127.453380519867,"bookYear":"2024","nameKR":"에이디카페","ribbonType":"X","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":6126,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.914874,"longitude":128.640605,"bookYear":"2024","nameKR":"구불로식당","ribbonType":"X","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":6230,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.208464,"longitude":127.066571,"bookYear":"2024","nameKR":"일블루","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":34295,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/positivespace566/","foodTypes":"디저트/차/베이커리","latitude":37.6202730987382,"longitude":126.69791747672,"bookYear":"2024","nameKR":"포지티브스페이스566","ribbonType":"X","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":8918,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.873572,"longitude":127.711286,"bookYear":"2024","nameKR":"이디오피아","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":32398,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_treebring/","foodTypes":"디저트/차/베이커리","latitude":36.5671042827675,"longitude":127.453152995285,"bookYear":"2024","nameKR":"트리브링","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33886,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lasantecafe2017/","foodTypes":"디저트/차/베이커리","latitude":35.2505176390774,"longitude":128.577845240707,"bookYear":"2024","nameKR":"라쌍떼","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":31494,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.85674632521,"longitude":128.679720205089,"bookYear":"2024","nameKR":"룰리커피","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":29508,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.3593850185529,"longitude":127.244362455028,"bookYear":"2024","nameKR":"어썸845 ","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":30641,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_debeige/","foodTypes":"디저트/차/베이커리","latitude":34.93348339677413,"longitude":128.05154601190287,"bookYear":"2024","nameKR":"드베이지","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":12514,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafeethiopia_official/","foodTypes":"디저트/차/베이커리","latitude":37.800251,"longitude":128.864478,"bookYear":"2024","nameKR":"에티오피아","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33309,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/baenamu_forest/","foodTypes":"디저트/차/베이커리","latitude":36.8795789962305,"longitude":127.120571006432,"bookYear":"2024","nameKR":"배나무숲","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":17253,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.857901,"longitude":127.756501,"bookYear":"2024","nameKR":"만강","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":31103,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bread._.ssong/","foodTypes":"디저트/차/베이커리","latitude":37.5547684273285,"longitude":127.236521285686,"bookYear":"2024","nameKR":"브레드쏭","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":32336,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6161705846195,"longitude":127.343909364542,"bookYear":"2024","nameKR":"라온숨","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":1614,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.212729,"longitude":128.601019,"bookYear":"2024","nameKR":"대선횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1723,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.223843,"longitude":128.589095,"bookYear":"2024","nameKR":"한산횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1915,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.09338539870358,"longitude":127.2732662409357,"bookYear":"2024","nameKR":"운천막국수","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":3098,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6177453221276,"longitude":126.823732396488,"bookYear":"2024","nameKR":"자유로민물장어웅어회","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":3315,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.425582,"longitude":126.879224,"bookYear":"2024","nameKR":"정읍식당","ribbonType":"X","zone2_1":"전남","zone2_2":"장성군","status":"ACTIVE"},
        {"id":31060,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2954587777356,"longitude":128.762805026045,"bookYear":"2024","nameKR":"신라가든","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":32297,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hokkirin.coffeeroasters/","foodTypes":"디저트/차/베이커리","latitude":35.0999206658747,"longitude":128.802180343881,"bookYear":"2024","nameKR":"호끼린커피로스터스","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":4425,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.803361,"longitude":126.36883,"bookYear":"2024","nameKR":"먼바다전복","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":5922,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sjsj_fish","foodTypes":"한식(어패류)","latitude":36.505302,"longitude":126.33656,"bookYear":"2024","nameKR":"승진횟집","ribbonType":"X","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":6850,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.786318,"longitude":126.384161,"bookYear":"2024","nameKR":"포도원횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":33964,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.9042941190717,"longitude":127.042432507502,"bookYear":"2024","nameKR":"도너츠윤본점","ribbonType":"X","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":23949,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.629316,"longitude":127.356547,"bookYear":"2024","nameKR":"하버커피","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":10804,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.941053,"longitude":127.874328,"bookYear":"2024","nameKR":"제일횟집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":35232,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.7918251928969,"longitude":127.431173872706,"bookYear":"2024","nameKR":"진안제일순대","ribbonType":"X","zone2_1":"전북","zone2_2":"진안군","status":"ACTIVE"},
        {"id":31501,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/awesome__bro","foodTypes":"디저트/차/베이커리","latitude":37.346716834357,"longitude":127.33683332902,"bookYear":"2024","nameKR":"어썸브로","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":12696,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.563668,"longitude":128.732317,"bookYear":"2024","nameKR":"동부갈비","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":31611,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dominiccoffee_official_/","foodTypes":"디저트/차/베이커리","latitude":37.2948029209528,"longitude":127.053977556449,"bookYear":"2024","nameKR":"도미닉커피랩","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":17625,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.242904,"longitude":126.420435,"bookYear":"2024","nameKR":"카페 카노푸스","ribbonType":"X","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":34899,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5063599742164,"longitude":126.718331678694,"bookYear":"2024","nameKR":"정가네굴마당","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":1865,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.261298,"longitude":127.280359,"bookYear":"2024","nameKR":"한터시골농장","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":1931,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.233837,"longitude":127.136181,"bookYear":"2024","nameKR":"물레방아","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":1947,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.213147,"longitude":126.735644,"bookYear":"2024","nameKR":"청미횟집","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":2418,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.78794,"longitude":126.390526,"bookYear":"2024","nameKR":"뚱보횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":4302,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3878279896615,"longitude":127.406172935335,"bookYear":"2024","nameKR":"란이네가든","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":36810,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/aether_cafe","foodTypes":"기타","latitude":35.0772567895242,"longitude":129.046493480617,"bookYear":"2024","nameKR":"에테르","ribbonType":"X","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":31613,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ssanghwa153","foodTypes":"디저트/차/베이커리","latitude":33.4670457292435,"longitude":126.519983761102,"bookYear":"2024","nameKR":"쌍화153","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":23766,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.745432,"longitude":126.950005,"bookYear":"2024","nameKR":"장흥폭포수식당","ribbonType":"X","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":31350,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6718344853241,"longitude":127.489842586264,"bookYear":"2024","nameKR":"유명숯불닭갈비","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":30643,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.86764313934061,"longitude":128.63491690267355,"bookYear":"2024","nameKR":"커피명가라핀카","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":31211,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.5437862980272,"longitude":129.352074277712,"bookYear":"2024","nameKR":"제1능이버섯삼산점","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":16187,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.962982,"longitude":128.924393,"bookYear":"2024","nameKR":"화평대군","ribbonType":"X","zone2_1":"경북","zone2_2":"영천시","status":"ACTIVE"},
        {"id":31319,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sanidacafe/","foodTypes":"기타","latitude":37.4069677627641,"longitude":127.91438200049,"bookYear":"2024","nameKR":"사니다카페","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":2056,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":34.867761,"longitude":128.730194,"bookYear":"2024","nameKR":"할매함흥냉면","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":2081,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.991661,"longitude":129.556934,"bookYear":"2024","nameKR":"모모식당","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":2485,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.481163,"longitude":126.939678,"bookYear":"2024","nameKR":"청송횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"장흥군","status":"ACTIVE"},
        {"id":2726,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.983862,"longitude":128.370483,"bookYear":"2024","nameKR":"자연식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":2946,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.196848,"longitude":129.077898,"bookYear":"2024","nameKR":"국제밀면","ribbonType":"X","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":3005,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.986304,"longitude":128.370681,"bookYear":"2024","nameKR":"돌집식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":3121,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.975895,"longitude":128.362377,"bookYear":"2024","nameKR":"비원쏘가리","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":3651,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.16168038395858,"longitude":127.33946367052724,"bookYear":"2024","nameKR":"광주가든","ribbonType":"X","zone2_1":"전남","zone2_2":"곡성군","status":"ACTIVE"},
        {"id":4540,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.843029,"longitude":129.253003,"bookYear":"2024","nameKR":"토함","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":6066,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.066173,"longitude":126.987643,"bookYear":"2024","nameKR":"엄지빈","ribbonType":"X","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":8221,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.724109,"longitude":126.51258,"bookYear":"2024","nameKR":"더리미집","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":37325,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.3852816573301,"longitude":126.838681153217,"bookYear":"2024","nameKR":"목감동설악추어탕화덕생선구이","ribbonType":"X","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":31551,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5025416520547,"longitude":126.775312407757,"bookYear":"2024","nameKR":"유림회관","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":10247,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.524009,"longitude":126.551921,"bookYear":"2024","nameKR":"수궁회관","ribbonType":"X","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":13079,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.506708,"longitude":129.120725,"bookYear":"2024","nameKR":"신촌식당","ribbonType":"X","zone2_1":"경북","zone2_2":"청송군","status":"ACTIVE"},
        {"id":13317,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.78615,"longitude":126.384512,"bookYear":"2024","nameKR":"만호유달횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":14139,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.713811,"longitude":126.687788,"bookYear":"2024","nameKR":"완이네작은밥상","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":37326,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5088186667468,"longitude":127.424925876749,"bookYear":"2024","nameKR":"구벼울","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":16647,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.4941489837602,"longitude":126.927824550692,"bookYear":"2024","nameKR":"종가집","ribbonType":"X","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":17008,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.283794,"longitude":129.156151,"bookYear":"2024","nameKR":"우남정","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":31457,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.2701554183928,"longitude":128.266952718485,"bookYear":"2024","nameKR":"제천식당","ribbonType":"X","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":2087,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.99515,"longitude":128.681734,"bookYear":"2024","nameKR":"부석사식당","ribbonType":"X","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":31969,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kleinblue_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.3448726520792,"longitude":126.177314651522,"bookYear":"2024","nameKR":"클랭블루","ribbonType":"X","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":29401,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/barbaracoco_cafe/","foodTypes":"디저트/차/베이커리","latitude":36.671925,"longitude":127.499731,"bookYear":"2024","nameKR":"바바라코코","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":3659,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.507029,"longitude":128.104731,"bookYear":"2024","nameKR":"큰터손두부","ribbonType":"X","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":37480,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yusinsigdang/","foodTypes":"한식(민물어패류)","latitude":35.5060805092745,"longitude":126.599798708128,"bookYear":"2024","nameKR":"유신식당","ribbonType":"X","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":4744,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.755715,"longitude":128.078457,"bookYear":"2024","nameKR":"깊은산속화로구이","ribbonType":"X","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":26940,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.236421,"longitude":126.312948,"bookYear":"2024","nameKR":"레이지박스","ribbonType":"X","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":5423,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.076798,"longitude":127.884531,"bookYear":"2024","nameKR":"강변횟집","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":5577,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.43886,"longitude":129.087092,"bookYear":"2024","nameKR":"달기약수촌","ribbonType":"X","zone2_1":"경북","zone2_2":"청송군","status":"ACTIVE"},
        {"id":5588,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.49377128089902,"longitude":126.9275836402603,"bookYear":"2024","nameKR":"삼일회관","ribbonType":"X","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":6844,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sobasoba5858/","foodTypes":"일식","latitude":35.319184,"longitude":128.260805,"bookYear":"2024","nameKR":"의령소바","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"의령군","status":"ACTIVE"},
        {"id":31410,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.5493133197784,"longitude":128.526641070429,"bookYear":"2024","nameKR":"솔밭식당","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":31086,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.4901153255575,"longitude":126.336651806813,"bookYear":"2024","nameKR":"르스튜디오블루","ribbonType":"X","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":31244,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/natural_garden_529/","foodTypes":"디저트/차/베이커리","latitude":37.6371489347801,"longitude":127.380908885024,"bookYear":"2024","nameKR":"내추럴가든529","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":8011,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.0508,"longitude":127.38055,"bookYear":"2024","nameKR":"이동폭포갈비","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":32437,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/squareroot_cafe/","foodTypes":"디저트/차/베이커리","latitude":38.3752628155047,"longitude":128.505683953453,"bookYear":"2024","nameKR":"스퀘어루트","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":8916,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.034272,"longitude":127.366179,"bookYear":"2024","nameKR":"느티나무갈비","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":29478,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.36780329131399,"longitude":126.65458375697632,"bookYear":"2024","nameKR":"갱스커피","ribbonType":"X","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":31219,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.007512294905,"longitude":127.663559460382,"bookYear":"2024","nameKR":"창복평양냉면","ribbonType":"X","zone2_1":"전북","zone2_2":"무주군","status":"ACTIVE"},
        {"id":31142,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mosobamboo_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.3604833653476,"longitude":127.057501262869,"bookYear":"2024","nameKR":"모소밤부","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":32002,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/danjangmyun_coffee/","foodTypes":"디저트/차/베이커리","latitude":35.52979429678,"longitude":128.892736416305,"bookYear":"2024","nameKR":"단장면커피로스터스","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":31393,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.048830978174,"longitude":128.170879588142,"bookYear":"2024","nameKR":"커피라끄","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":31311,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.4611696789499,"longitude":126.311418894475,"bookYear":"2024","nameKR":"랜디스도넛","ribbonType":"X","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":30963,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_moonlit/","foodTypes":"디저트/차/베이커리","latitude":37.5037389484964,"longitude":127.596779609115,"bookYear":"2024","nameKR":"카페문릿","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":30580,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.2324131656503,"longitude":126.36625743425407,"bookYear":"2024","nameKR":"휴일로","ribbonType":"X","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":17600,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/roccia_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.501869,"longitude":126.466726,"bookYear":"2024","nameKR":"로치아커피로스터스","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34702,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.2346842641796,"longitude":129.243311246848,"bookYear":"2024","nameKR":"기장끝집","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":1604,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.668512,"longitude":128.708885,"bookYear":"2024","nameKR":"노다지","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":2287,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.070518,"longitude":129.018204,"bookYear":"2024","nameKR":"송도공원","ribbonType":"X","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":4338,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.28254,"longitude":127.742344,"bookYear":"2024","nameKR":"두메산골","ribbonType":"X","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":5270,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.449542,"longitude":127.431656,"bookYear":"2024","nameKR":"맛집부추해물칼국수","ribbonType":"X","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":5491,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.482376,"longitude":127.72137,"bookYear":"2024","nameKR":"김천식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":32334,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ijinsanghoe/","foodTypes":"디저트/차/베이커리","latitude":37.2790228554903,"longitude":127.400005934045,"bookYear":"2024","nameKR":"이진상회","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":32786,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7941950415587,"longitude":127.230032867539,"bookYear":"2024","nameKR":"곰터먹촌","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":33088,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/forbidden_place_in_dmz/","foodTypes":"디저트/차/베이커리","latitude":37.8954467020524,"longitude":126.732268893385,"bookYear":"2024","nameKR":"포비든플레이스","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":10554,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4402678649015,"longitude":129.191230854164,"bookYear":"2024","nameKR":"펠리스횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":31676,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_so.you","foodTypes":"디저트/차/베이커리","latitude":37.688414993185,"longitude":127.107025407517,"bookYear":"2024","nameKR":"카페소유","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":36744,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5211633096625,"longitude":126.625891759251,"bookYear":"2024","nameKR":"림","ribbonType":"X","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":34412,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hg4183388/","foodTypes":"한식(면류)","latitude":36.8106181388812,"longitude":127.108680123789,"bookYear":"2024","nameKR":"한결가치칼국수","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":31742,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_carmel","foodTypes":"디저트/차/베이커리","latitude":35.8500125117921,"longitude":128.446987172614,"bookYear":"2024","nameKR":"카르멜","ribbonType":"X","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":31456,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.2740033163797,"longitude":128.269224721697,"bookYear":"2024","nameKR":"카페달","ribbonType":"X","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":28402,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.4675956215277,"longitude":126.915586925886,"bookYear":"2024","nameKR":"성산갯마을식당","ribbonType":"X","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":31606,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4479134480636,"longitude":126.884045024072,"bookYear":"2024","nameKR":"이학순베이커리","ribbonType":"X","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":33698,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/grigio__cafe/","foodTypes":"디저트/차/베이커리","latitude":35.1432074942454,"longitude":126.848491488178,"bookYear":"2024","nameKR":"그리지오","ribbonType":"X","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":34519,"bookStatus":"승인","websiteInstagram":"http://www.instagram.com/garookangtable","foodTypes":"기타","latitude":35.1729210126791,"longitude":129.197440254607,"bookYear":"2024","nameKR":"거루캥테이블","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":2467,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.189688,"longitude":127.373092,"bookYear":"2024","nameKR":"용궁산장","ribbonType":"X","zone2_1":"전남","zone2_2":"곡성군","status":"ACTIVE"},
        {"id":2678,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.1463504249067,"longitude":126.504600734721,"bookYear":"2024","nameKR":"해돋이회센타","ribbonType":"X","zone2_1":"충남","zone2_2":"서천군","status":"ACTIVE"},
        {"id":3131,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.945630103155146,"longitude":127.06574948032265,"bookYear":"2024","nameKR":"넓은공간","ribbonType":"X","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":31577,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8182312361145,"longitude":127.14883229195,"bookYear":"2024","nameKR":"삼양다방","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":4063,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.124915,"longitude":127.601114,"bookYear":"2024","nameKR":"가선식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"영동군","status":"ACTIVE"},
        {"id":5420,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.84529102725961,"longitude":127.99191697183645,"bookYear":"2024","nameKR":"향나무집","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":5470,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.18393,"longitude":128.205868,"bookYear":"2024","nameKR":"꼬네","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":29395,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/boon.the_bread/","foodTypes":"디저트/차/베이커리","latitude":35.859135,"longitude":128.622068,"bookYear":"2024","nameKR":"분더브레드","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":29302,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe__lumiere/","foodTypes":"디저트/차/베이커리","latitude":37.7727283699348,"longitude":128.947154370314,"bookYear":"2024","nameKR":"카페뤼미에르","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":29300,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.868426,"longitude":128.845891,"bookYear":"2024","nameKR":"카르페디엠","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":30083,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/aplace_official/","foodTypes":"디저트/차/베이커리","latitude":38.19285877879227,"longitude":128.601800720543,"bookYear":"2024","nameKR":"에이플레이스","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":11920,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.702332,"longitude":127.396574,"bookYear":"2024","nameKR":"이경숙할머니음식점","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":12361,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.2392565993576,"longitude":126.60583120479,"bookYear":"2024","nameKR":"26호까치할머니손칼국수","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":21683,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.5310721276233,"longitude":127.502836508286,"bookYear":"2024","nameKR":"나루터가마솥소머리곰탕","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33469,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/theirthere_official/","foodTypes":"디저트/차/베이커리","latitude":36.6123657363633,"longitude":127.498151347625,"bookYear":"2024","nameKR":"데어데어","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":1752,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.264552,"longitude":127.718884,"bookYear":"2024","nameKR":"걸구쟁이네","ribbonType":"X","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":2110,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.835893,"longitude":129.217951,"bookYear":"2024","nameKR":"이풍녀구로쌈밥","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31213,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.2552764918427,"longitude":126.41566183609,"bookYear":"2024","nameKR":"이드레국수","ribbonType":"X","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":2902,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.861691,"longitude":128.425137,"bookYear":"2024","nameKR":"항남뚝배기","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":3597,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.158272,"longitude":126.847815,"bookYear":"2024","nameKR":"명송생복집","ribbonType":"X","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":20607,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.020821,"longitude":128.172584,"bookYear":"2024","nameKR":"청풍황금떡갈비","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":4444,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.8231628011528,"longitude":126.258093501528,"bookYear":"2024","nameKR":"두메식당","ribbonType":"X","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":34298,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/terrace.in.seaside/","foodTypes":"디저트/차/베이커리","latitude":37.4920748543607,"longitude":126.581257283455,"bookYear":"2024","nameKR":"바다앞테라스","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":5351,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.849957456905386,"longitude":127.3555636180345,"bookYear":"2024","nameKR":"소희네묵집","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":8981,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.114352,"longitude":128.01653,"bookYear":"2024","nameKR":"서울식당","ribbonType":"X","zone2_1":"경북","zone2_2":"김천시","status":"ACTIVE"},
        {"id":9029,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.508592,"longitude":126.599735,"bookYear":"2024","nameKR":"연기식당","ribbonType":"X","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":9035,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.8031769394815,"longitude":127.82042598493831,"bookYear":"2024","nameKR":"팔도강산민물매운탕","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"괴산군","status":"ACTIVE"},
        {"id":9268,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.683009,"longitude":126.850103,"bookYear":"2024","nameKR":"원당헌","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":30650,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/vincent_van_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.201043590175736,"longitude":126.98388317165512,"bookYear":"2024","nameKR":"빈센트반커피","ribbonType":"X","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":25864,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/geumil_hongeo/","foodTypes":"한식(어패류)","latitude":34.999340242123814,"longitude":126.71044058493779,"bookYear":"2024","nameKR":"금일홍어","ribbonType":"X","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":33247,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/promenade_yeosu/","foodTypes":"디저트/차/베이커리","latitude":34.718852411375,"longitude":127.745016291104,"bookYear":"2024","nameKR":"프롬나드","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":31732,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1564105450054,"longitude":129.175132215367,"bookYear":"2024","nameKR":"엣지993","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":30953,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/siminbakery_1949/","foodTypes":"디저트/차/베이커리","latitude":36.0383112291928,"longitude":129.364682762578,"bookYear":"2024","nameKR":"시민제과","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":1666,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.407905,"longitude":128.14606,"bookYear":"2024","nameKR":"심순녀안흥찐빵","ribbonType":"X","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":1697,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.447221363082555,"longitude":128.4639370755443,"bookYear":"2024","nameKR":"자매활어횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":37830,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/abalounge_official","foodTypes":"기타","latitude":38.268414597184,"longitude":128.555902159838,"bookYear":"2024","nameKR":"아바라운지","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":3034,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.189158,"longitude":127.622664,"bookYear":"2024","nameKR":"설송","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":3591,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.245492,"longitude":129.319442,"bookYear":"2024","nameKR":"보경식당","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":3660,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.410424,"longitude":126.852131,"bookYear":"2024","nameKR":"칠갑산맛집","ribbonType":"X","zone2_1":"충남","zone2_2":"청양군","status":"ACTIVE"},
        {"id":31975,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jejuoneandonly","foodTypes":"디저트/차/베이커리","latitude":33.2392235100208,"longitude":126.319190550487,"bookYear":"2024","nameKR":"원앤온리","ribbonType":"X","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":35027,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.720399701256,"longitude":127.758318759106,"bookYear":"2024","nameKR":"돌산황금게장","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":28234,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.635964,"longitude":126.81574,"bookYear":"2024","nameKR":"동가룰가든","ribbonType":"X","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":6131,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.9467401534161,"longitude":128.31673754784,"bookYear":"2024","nameKR":"왜관식당","ribbonType":"X","zone2_1":"경북","zone2_2":"성주군","status":"ACTIVE"},
        {"id":36712,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.2351178178847,"longitude":126.313645335479,"bookYear":"2024","nameKR":"소색채본","ribbonType":"X","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":8945,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.837612,"longitude":128.87533,"bookYear":"2024","nameKR":"장안횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":26720,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.370525,"longitude":127.14564,"bookYear":"2024","nameKR":"순창전통유과","ribbonType":"X","zone2_1":"전북","zone2_2":"순창군","status":"ACTIVE"},
        {"id":31237,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.0628217153379,"longitude":126.984910710671,"bookYear":"2024","nameKR":"화성식육식당","ribbonType":"X","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":30974,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffee_aruna/","foodTypes":"디저트/차/베이커리","latitude":38.1967006652993,"longitude":128.598099975182,"bookYear":"2024","nameKR":"아루나","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":27252,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.98529499269312,"longitude":126.70810112251296,"bookYear":"2024","nameKR":"고우당","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":1671,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.217473,"longitude":128.594333,"bookYear":"2024","nameKR":"영금정생선조림","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1833,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.74434338722778,"longitude":127.04975010056846,"bookYear":"2024","nameKR":"보영식당","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":1921,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.773199,"longitude":126.902733,"bookYear":"2024","nameKR":"전주식백반은진식당","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":2011,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.064489,"longitude":127.746793,"bookYear":"2024","nameKR":"동흥재첩국","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":3008,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.299999,"longitude":127.566288,"bookYear":"2024","nameKR":"별미올갱이해장국","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":4517,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.838741,"longitude":129.206861,"bookYear":"2024","nameKR":"단석명가찰보리빵","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":4600,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.5179163183103,"longitude":126.519055328461,"bookYear":"2024","nameKR":"화진전복","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":5433,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.979067,"longitude":128.353723,"bookYear":"2024","nameKR":"삼정정육점식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":32418,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1835901193388,"longitude":129.210844586315,"bookYear":"2024","nameKR":"기장혼국보미역","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":9308,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":35.15596,"longitude":129.153982,"bookYear":"2024","nameKR":"파노라마라운지","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":9358,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.247558,"longitude":126.408116,"bookYear":"2024","nameKR":"바당","ribbonType":"X","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":31674,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/melbuzz_slowcity/","foodTypes":"디저트/차/베이커리","latitude":33.4760757847189,"longitude":126.482743771781,"bookYear":"2024","nameKR":"멜버즈 ","ribbonType":"X","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":30566,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/will_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.6202660418252,"longitude":126.83139763832155,"bookYear":"2024","nameKR":"윌커피로스터스","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":1729,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.561467,"longitude":129.119458,"bookYear":"2024","nameKR":"해변으로","ribbonType":"X","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":3071,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.492882,"longitude":127.836507,"bookYear":"2024","nameKR":"원조우정식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":3661,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.761267,"longitude":128.077138,"bookYear":"2024","nameKR":"목련가든","ribbonType":"X","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":4374,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.950826,"longitude":127.399251,"bookYear":"2024","nameKR":"송백가든","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"진천군","status":"ACTIVE"},
        {"id":4504,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.412123,"longitude":128.156618,"bookYear":"2024","nameKR":"면사무소앞안흥찐빵","ribbonType":"X","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":32702,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ondajung","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.3969660098774,"longitude":126.243404582873,"bookYear":"2024","nameKR":"협재온다정","ribbonType":"X","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":6800,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/colombang_official/","foodTypes":"디저트/차/베이커리","latitude":34.789851685791895,"longitude":126.38441612105967,"bookYear":"2024","nameKR":"코롬방제과","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":31317,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.569722248893,"longitude":128.769177895588,"bookYear":"2024","nameKR":"구름에오프","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":8134,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/samgangok/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.473028,"longitude":126.634066,"bookYear":"2024","nameKR":"삼강옥","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":26982,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.241437,"longitude":127.486371,"bookYear":"2024","nameKR":"만남가든","ribbonType":"X","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":8937,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.07737201035171,"longitude":128.44689529179598,"bookYear":"2024","nameKR":"산골식당","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":9522,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.691628,"longitude":126.564786,"bookYear":"2024","nameKR":"김인경원조바지락죽","ribbonType":"X","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":35955,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/darangyitasty/","foodTypes":"한식(어패류)","latitude":34.7268388386472,"longitude":127.893459622034,"bookYear":"2024","nameKR":"다랭이맛집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":27878,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.875265,"longitude":128.590838,"bookYear":"2024","nameKR":"원조할매곰탕집","ribbonType":"X","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":28507,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.2023687432796,"longitude":128.593670063877,"bookYear":"2024","nameKR":"명천명태순대","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":29841,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":38.2043642434727,"longitude":128.588938255172,"bookYear":"2024","nameKR":"88순대국","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1704,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.223973,"longitude":128.368171,"bookYear":"2024","nameKR":"용대진부령식당","ribbonType":"X","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":2663,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.0294012764615,"longitude":126.561043934877,"bookYear":"2024","nameKR":"용왕횟집","ribbonType":"X","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":2690,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.04271595927857,"longitude":128.48400586229778,"bookYear":"2024","nameKR":"금강식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":2710,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.63271,"longitude":127.485645,"bookYear":"2024","nameKR":"상주집","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":3032,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.243439,"longitude":127.488374,"bookYear":"2024","nameKR":"지리각식당","ribbonType":"X","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":4472,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.467517885023845,"longitude":129.16905124696794,"bookYear":"2024","nameKR":"바다마을","ribbonType":"X","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":6793,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/whitewindmill1993/","foodTypes":"디저트/차/베이커리","latitude":37.2417399462448,"longitude":127.034165232558,"bookYear":"2024","nameKR":"하얀풍차","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":28401,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.248521,"longitude":126.559362,"bookYear":"2024","nameKR":"네거리식당","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":31335,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.5437022656737,"longitude":126.668709879591,"bookYear":"2024","nameKR":"카페델문도","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":29070,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.557645,"longitude":129.255871,"bookYear":"2024","nameKR":"남도돼지국밥","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":36363,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jeju_soomdo/","foodTypes":"디저트/차/베이커리","latitude":33.2502906728106,"longitude":126.536006813907,"bookYear":"2024","nameKR":"숨도카페","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":8939,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.669105,"longitude":128.711362,"bookYear":"2024","nameKR":"원조맷돌순두부","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":9218,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.56386823284641,"longitude":128.7211837759666,"bookYear":"2024","nameKR":"버버리찰떡","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":32378,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/k.hemingway.official/","foodTypes":"기타","latitude":37.3484540479686,"longitude":127.102481434926,"bookYear":"2024","nameKR":"K.헤밍웨이베이커리카페&다이닝","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":21567,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.212519,"longitude":128.493859,"bookYear":"2024","nameKR":"송원","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":21579,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":38.2043916873864,"longitude":128.589619133089,"bookYear":"2024","nameKR":"형제닭집","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":10935,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.49232,"longitude":127.836079,"bookYear":"2024","nameKR":"일신식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":32003,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kevincoffee_official/","foodTypes":"디저트/차/베이커리","latitude":35.522876947902,"longitude":127.722795317064,"bookYear":"2024","nameKR":"케빈커피로스터스","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"함양군","status":"ACTIVE"},
        {"id":12507,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.125079,"longitude":126.878497,"bookYear":"2024","nameKR":"베비에르과자점","ribbonType":"X","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":2012,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.843854,"longitude":128.423696,"bookYear":"2024","nameKR":"뚱보할매김밥집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":2651,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.761422,"longitude":127.298843,"bookYear":"2024","nameKR":"아우내큰엄니순대국","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":2831,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.26204116136164,"longitude":128.1920726441631,"bookYear":"2024","nameKR":"이인숙황둔찐빵","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":2989,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.028949,"longitude":126.560998,"bookYear":"2024","nameKR":"등대횟집","ribbonType":"X","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":3406,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.808674,"longitude":127.793969,"bookYear":"2024","nameKR":"서울식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"괴산군","status":"ACTIVE"},
        {"id":27132,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4586511837387,"longitude":126.639805143154,"bookYear":"2024","nameKR":"송도골","ribbonType":"X","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":4516,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.670082,"longitude":128.709811,"bookYear":"2024","nameKR":"황태덕장","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":5019,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.640902,"longitude":128.746035,"bookYear":"2024","nameKR":"역전추어탕","ribbonType":"X","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":6281,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.277686,"longitude":127.032426,"bookYear":"2024","nameKR":"쉐푸스","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":30757,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6764766165122,"longitude":126.799388811552,"bookYear":"2024","nameKR":"제주은희네해장국(일산본점)","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":28616,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7252391470533,"longitude":127.71281349136417,"bookYear":"2024","nameKR":"자매식당","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":9207,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.196124,"longitude":128.345086,"bookYear":"2024","nameKR":"백담황태구이","ribbonType":"X","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":32554,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.512747072119,"longitude":127.810104186053,"bookYear":"2024","nameKR":"화풍정","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":32564,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7329194004045,"longitude":127.721924666936,"bookYear":"2024","nameKR":"명동게장","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":4339,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2263158091697,"longitude":127.10503646463,"bookYear":"2024","nameKR":"양평해장국","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":4346,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.901965504931674,"longitude":127.30615344761831,"bookYear":"2024","nameKR":"초가집순두부보리밥","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":32397,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6648909552707,"longitude":128.709289978036,"bookYear":"2024","nameKR":"대관령황태촌","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":5369,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.66305,"longitude":126.860054,"bookYear":"2024","nameKR":"서삼릉보리밥","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":7186,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":33.372255,"longitude":126.85604,"bookYear":"2024","nameKR":"카페오름","ribbonType":"X","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":7985,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2381068074411,"longitude":127.014906791871,"bookYear":"2024","nameKR":"원조창평시장국밥","ribbonType":"X","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":8972,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/samdado1991/","foodTypes":"한식(일반한식)","latitude":34.9263174233483,"longitude":128.075154824097,"bookYear":"2024","nameKR":"삼다도전복죽","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":9032,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.696205,"longitude":126.66279,"bookYear":"2024","nameKR":"뜨끈이집","ribbonType":"X","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":30355,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jejuhanmyeonga/","foodTypes":"한식(육류)","latitude":33.5120527982259,"longitude":126.699958554717,"bookYear":"2024","nameKR":"제주한면가","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":33662,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nine_u_official/","foodTypes":"디저트/차/베이커리","latitude":35.6093246483208,"longitude":129.455518384773,"bookYear":"2024","nameKR":"나인유","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":18824,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.155796339296295,"longitude":129.0585446076955,"bookYear":"2024","nameKR":"포항돼지국밥","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":29574,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gangneung_terracej/","foodTypes":"한식(어패류)","latitude":37.870317313951915,"longitude":128.84297696559884,"bookYear":"2024","nameKR":"주문진횟집테라스제이","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":25621,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.393191,"longitude":128.480811,"bookYear":"2024","nameKR":"초우","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창녕군","status":"ACTIVE"},
        {"id":36566,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/playball.tongyeong/","foodTypes":"한식(어패류)","latitude":34.8339634459184,"longitude":128.40887644564,"bookYear":"2024","nameKR":"플레이볼인통영","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":30610,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.73184182787061,"longitude":127.4099895470949,"bookYear":"2024","nameKR":"본가장작불곰탕해장국","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":23059,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.99731600616009,"longitude":127.08411634050975,"bookYear":"2024","nameKR":"진미식당","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":31647,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.194815284934,"longitude":127.099267611882,"bookYear":"2024","nameKR":"소나무한정식","ribbonType":"X","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":17767,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8216931681819,"longitude":129.210789672094,"bookYear":"2024","nameKR":"교리김밥","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":1632,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.064179,"longitude":128.589935,"bookYear":"2024","nameKR":"범부메밀국수","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":3116,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.110054,"longitude":127.565988,"bookYear":"2024","nameKR":"금산관광농원","ribbonType":"X","zone2_1":"충남","zone2_2":"금산군","status":"ACTIVE"},
        {"id":3235,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.7143,"longitude":126.815759,"bookYear":"2024","nameKR":"수인관","ribbonType":"X","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":35176,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/willys.sandwich/","foodTypes":"기타","latitude":33.5218766114956,"longitude":126.586223736231,"bookYear":"2024","nameKR":"윌리스","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34028,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ganghwa_ggagga/","foodTypes":"디저트/차/베이커리","latitude":37.6528637982605,"longitude":126.510639128179,"bookYear":"2024","nameKR":"강화까까","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":8911,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/56.house/","foodTypes":"기타","latitude":37.9172957461617,"longitude":127.05556844865,"bookYear":"2024","nameKR":"오륙하우스","ribbonType":"X","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":34349,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_1929/","foodTypes":"디저트/차/베이커리","latitude":37.1173034278098,"longitude":128.235977096886,"bookYear":"2024","nameKR":"카페1929","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":36670,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/avec_vegan/","foodTypes":"기타","latitude":33.4824341372855,"longitude":126.396846966785,"bookYear":"2024","nameKR":"아베끄","ribbonType":"X","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":29376,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.217731,"longitude":126.249871,"bookYear":"2024","nameKR":"미영이네식당","ribbonType":"X","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":33775,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.9760806736095,"longitude":126.705583586907,"bookYear":"2024","nameKR":"우리떡갈비","ribbonType":"X","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":37553,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.6423374175194,"longitude":127.426913157763,"bookYear":"2024","nameKR":"수암골연어곳간","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33046,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lowamansion/","foodTypes":"디저트/차/베이커리","latitude":35.2591366304508,"longitude":129.243495658398,"bookYear":"2024","nameKR":"로와맨션","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":1622,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.9029350425534,"longitude":128.822062485132,"bookYear":"2024","nameKR":"소돌막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":38011,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.3011060163823,"longitude":127.117815393909,"bookYear":"2024","nameKR":"수타우동모루","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":34631,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5966418318946,"longitude":127.50583778153,"bookYear":"2024","nameKR":"솥뚜껑닭볶음탕","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":5266,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.705209,"longitude":126.390048,"bookYear":"2024","nameKR":"외포리꽃게집","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":6003,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.223765,"longitude":129.230881,"bookYear":"2024","nameKR":"용암할매횟집","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":35082,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.0791597373074,"longitude":128.672204469321,"bookYear":"2024","nameKR":"수라상","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":31217,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.1331806781815,"longitude":128.618961419054,"bookYear":"2024","nameKR":"범바우막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":35867,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.7059662542756,"longitude":126.762927162241,"bookYear":"2024","nameKR":"하우스오브컨티뉴","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":8920,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.879025,"longitude":127.727458,"bookYear":"2024","nameKR":"원조중앙닭갈비","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":31447,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.3241786196066,"longitude":126.983716879334,"bookYear":"2024","nameKR":"옛날진미국수","ribbonType":"X","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":27433,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.524478,"longitude":126.861867,"bookYear":"2024","nameKR":"미엘드세화","ribbonType":"X","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":36831,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.8848920530556,"longitude":127.442451434864,"bookYear":"2024","nameKR":"폴린커피로스팅룸","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"진천군","status":"ACTIVE"},
        {"id":1715,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.791102,"longitude":128.910454,"bookYear":"2024","nameKR":"토담순두부","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":2618,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.24051082068603,"longitude":126.56557565528797,"bookYear":"2024","nameKR":"제주할망뚝배기","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":4812,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.713997,"longitude":129.199117,"bookYear":"2024","nameKR":"유통불고기","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":5022,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.60779874624225,"longitude":128.27955492538453,"bookYear":"2024","nameKR":"흥부네토종한방순대","ribbonType":"X","zone2_1":"경북","zone2_2":"예천군","status":"ACTIVE"},
        {"id":5418,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.921459,"longitude":128.356918,"bookYear":"2024","nameKR":"장림산방","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":8935,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/koreasofttofu/","foodTypes":"한식(일반한식)","latitude":37.185102,"longitude":128.470669,"bookYear":"2024","nameKR":"김인수할머니순두부","ribbonType":"X","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":8940,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.516181,"longitude":128.73065,"bookYear":"2024","nameKR":"대운식당","ribbonType":"X","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":9242,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.842186,"longitude":127.343078,"bookYear":"2024","nameKR":"원조꼬막식당","ribbonType":"X","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9249,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.408618,"longitude":126.263581,"bookYear":"2024","nameKR":"삼일식당","ribbonType":"X","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":10199,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.432418,"longitude":126.709448,"bookYear":"2024","nameKR":"다은회관","ribbonType":"X","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":34637,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yeonhwa.tea/","foodTypes":"디저트/차/베이커리","latitude":33.4875699681633,"longitude":126.714780305705,"bookYear":"2024","nameKR":"연화차","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":12473,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.026786,"longitude":129.338865,"bookYear":"2024","nameKR":"고래사냥","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":13210,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":36.3362504495122,"longitude":126.650994109092,"bookYear":"2024","nameKR":"황해원","ribbonType":"X","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":31612,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.8445687704171,"longitude":128.428497600079,"bookYear":"2024","nameKR":"통영해물가","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":29753,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.0774854837986,"longitude":128.637129960653,"bookYear":"2024","nameKR":"해촌","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":1925,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":38.06873627406506,"longitude":127.32302939177569,"bookYear":"2024","nameKR":"이모네","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":2071,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.85037619816443,"longitude":129.22399865006273,"bookYear":"2024","nameKR":"대구막창","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2648,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.50881211675434,"longitude":126.33309923826583,"bookYear":"2024","nameKR":"물새집","ribbonType":"X","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":5323,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":38.108171,"longitude":127.98576,"bookYear":"2024","nameKR":"석장골오골계숯불구이","ribbonType":"X","zone2_1":"강원","zone2_2":"양구군","status":"ACTIVE"},
        {"id":30159,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bamgasiburger","foodTypes":"기타","latitude":37.66908084209575,"longitude":126.7846206467847,"bookYear":"2024","nameKR":"밤가시버거","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":31402,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/forrestpicnic_seooreung/","foodTypes":"디저트/차/베이커리","latitude":37.620737006622,"longitude":126.902000367685,"bookYear":"2024","nameKR":"포레스트피크닉","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":37596,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.8767622983613,"longitude":128.290700051694,"bookYear":"2024","nameKR":"미산민박식당","ribbonType":"X","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":8953,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.320287,"longitude":128.262968,"bookYear":"2024","nameKR":"수정식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"의령군","status":"ACTIVE"},
        {"id":29952,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/paradise_gili_in_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.5566858972833,"longitude":126.793702508338,"bookYear":"2024","nameKR":"너는파라다이스길리","ribbonType":"X","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":9510,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.273622,"longitude":126.910316,"bookYear":"2024","nameKR":"산장식당","ribbonType":"X","zone2_1":"충남","zone2_2":"부여군","status":"ACTIVE"},
        {"id":12027,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.269586,"longitude":127.435242,"bookYear":"2024","nameKR":"보승루","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":34932,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5470772898263,"longitude":127.250186906067,"bookYear":"2024","nameKR":"예봉산더덕집","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":14986,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.475781,"longitude":127.188082,"bookYear":"2024","nameKR":"건강한밥상","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":2422,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.721883,"longitude":127.72244,"bookYear":"2024","nameKR":"미림횟집","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":3031,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.188026,"longitude":127.394301,"bookYear":"2024","nameKR":"나루터","ribbonType":"X","zone2_1":"전남","zone2_2":"곡성군","status":"ACTIVE"},
        {"id":3165,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":34.740104,"longitude":127.736521,"bookYear":"2024","nameKR":"함남면옥","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":3307,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.19191,"longitude":127.376,"bookYear":"2024","nameKR":"가든산장","ribbonType":"X","zone2_1":"전남","zone2_2":"곡성군","status":"ACTIVE"},
        {"id":5017,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.470271,"longitude":128.651463,"bookYear":"2024","nameKR":"제일식육식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":36304,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/llow_official/","foodTypes":"디저트/차/베이커리","latitude":35.8451050176228,"longitude":129.27183356899,"bookYear":"2024","nameKR":"엘로우","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31884,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.606933343616,"longitude":128.894193262328,"bookYear":"2024","nameKR":"인골산장","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":8909,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.789509,"longitude":126.846157,"bookYear":"2024","nameKR":"광탄은행나무집","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":8944,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.852711,"longitude":128.838984,"bookYear":"2024","nameKR":"초시막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":10184,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.500099,"longitude":126.61227,"bookYear":"2024","nameKR":"강촌숯불장어구이","ribbonType":"X","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":29854,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.2676978014372,"longitude":126.589225321842,"bookYear":"2024","nameKR":"동성식당","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":18859,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.717774,"longitude":128.56066,"bookYear":"2024","nameKR":"소나무식당","ribbonType":"X","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":12452,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.058947,"longitude":129.4218,"bookYear":"2024","nameKR":"진해식당","ribbonType":"X","zone2_1":"경북","zone2_2":"울진군","status":"ACTIVE"},
        {"id":12674,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.600447,"longitude":129.414314,"bookYear":"2024","nameKR":"정직한바다횟집","ribbonType":"X","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":28500,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/2daejje","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.3715771686319,"longitude":127.14615960014,"bookYear":"2024","nameKR":"2대째순대","ribbonType":"X","zone2_1":"전북","zone2_2":"순창군","status":"ACTIVE"},
        {"id":1693,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/leejomyeonok_official/","foodTypes":"한식(면류)","latitude":38.182144763807,"longitude":128.600708743175,"bookYear":"2024","nameKR":"이조면옥","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":2034,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.842638,"longitude":128.420318,"bookYear":"2024","nameKR":"오미사꿀빵","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":2457,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.84272686403975,"longitude":127.34431965070299,"bookYear":"2024","nameKR":"역전식당","ribbonType":"X","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":2548,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.399193,"longitude":127.600025,"bookYear":"2024","nameKR":"지리산천왕봉식당","ribbonType":"X","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":2657,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.761736,"longitude":127.299163,"bookYear":"2024","nameKR":"박순자아우내순대","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":2725,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.53174474172678,"longitude":127.82318633969453,"bookYear":"2024","nameKR":"석정","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":2922,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.841074,"longitude":128.420491,"bookYear":"2024","nameKR":"남옥식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":5435,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.84631,"longitude":127.9899,"bookYear":"2024","nameKR":"만리식당","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":6959,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.522587,"longitude":126.345659,"bookYear":"2024","nameKR":"솔밭가든","ribbonType":"X","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":34803,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1974896365586,"longitude":127.627693464273,"bookYear":"2024","nameKR":"법향다원","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":31389,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.5494443156281,"longitude":128.528175641088,"bookYear":"2024","nameKR":"시월애단팥빵","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":9024,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.906923,"longitude":127.77194,"bookYear":"2024","nameKR":"별미가든","ribbonType":"X","zone2_1":"전북","zone2_2":"무주군","status":"ACTIVE"},
        {"id":9187,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.4936508033281,"longitude":129.069284171526,"bookYear":"2024","nameKR":"원조손두부","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":9255,"bookStatus":"승인","websiteInstagram":"http://instagram.com/soonduck_e_ne/","foodTypes":"한식(어패류)","latitude":33.411974,"longitude":126.896577,"bookYear":"2024","nameKR":"성산갈치조림순덕이네","ribbonType":"X","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":9523,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.700135,"longitude":126.580146,"bookYear":"2024","nameKR":"풍차식당","ribbonType":"X","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":10984,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.445903,"longitude":129.434328,"bookYear":"2024","nameKR":"선미횟집","ribbonType":"X","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":26042,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.662401,"longitude":127.020651,"bookYear":"2024","nameKR":"갈재산장","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":19399,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.218146,"longitude":128.043704,"bookYear":"2024","nameKR":"월운막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"양구군","status":"ACTIVE"},
        {"id":11503,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.436512,"longitude":129.186046,"bookYear":"2024","nameKR":"삼정식당","ribbonType":"X","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":12689,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8044682137075,"longitude":129.502892080347,"bookYear":"2024","nameKR":"은정횟집","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":22927,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.3240102154559,"longitude":126.983460883151,"bookYear":"2024","nameKR":"원조대나무국수","ribbonType":"X","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":1637,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6107287636293,"longitude":128.359977649457,"bookYear":"2024","nameKR":"메밀꽃필무렵","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":1953,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.790014,"longitude":126.699611,"bookYear":"2024","nameKR":"카메라타","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":2373,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6405321446873,"longitude":126.391876947108,"bookYear":"2024","nameKR":"청강호","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":2538,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.4101448835933,"longitude":127.395566974717,"bookYear":"2024","nameKR":"신촌매운탕","ribbonType":"X","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":4349,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.070288,"longitude":127.304672,"bookYear":"2024","nameKR":"산비탈","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":6002,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.223862,"longitude":129.227029,"bookYear":"2024","nameKR":"일번횟집","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":29860,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.15303070254262,"longitude":129.06198607915832,"bookYear":"2024","nameKR":"라라관","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":31634,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/enough.jeju/","foodTypes":"디저트/차/베이커리","latitude":33.4560241058215,"longitude":126.418981894194,"bookYear":"2024","nameKR":"이너프","ribbonType":"X","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":9252,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.434345,"longitude":127.209657,"bookYear":"2024","nameKR":"옛날배씨네집","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":33583,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gnb_jeju/","foodTypes":"바/주점","latitude":33.3910733394765,"longitude":126.22863126772,"bookYear":"2024","nameKR":"금능반지하","ribbonType":"X","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":31609,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.4031935477087,"longitude":126.722275801146,"bookYear":"2024","nameKR":"심스키친","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":31416,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lapland_official/","foodTypes":"디저트/차/베이커리","latitude":37.7120838064194,"longitude":126.6916603793,"bookYear":"2024","nameKR":"라플란드","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":11495,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.79110565419484,"longitude":128.9165227664985,"bookYear":"2024","nameKR":"차현희순두부청국장","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":1930,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.26221375039432,"longitude":127.03380640492422,"bookYear":"2024","nameKR":"장수생고기","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":2005,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.149195,"longitude":128.658803,"bookYear":"2024","nameKR":"곱돌이","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":2352,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.497371,"longitude":126.721775,"bookYear":"2024","nameKR":"부평막국수","ribbonType":"X","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":3586,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.228526,"longitude":129.223883,"bookYear":"2024","nameKR":"팔각정","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":4259,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.5385327972547,"longitude":129.345257892967,"bookYear":"2024","nameKR":"차일품","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":32668,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5964862048586,"longitude":126.644712590511,"bookYear":"2024","nameKR":"단풍나무","ribbonType":"X","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":4503,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.346592,"longitude":127.84682,"bookYear":"2024","nameKR":"만낭포감자떡","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":4536,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.847974,"longitude":128.566829,"bookYear":"2024","nameKR":"버들식당","ribbonType":"X","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":4579,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.553689,"longitude":129.291589,"bookYear":"2024","nameKR":"대일함흥냉면","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":4595,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.247730542475,"longitude":126.566454178905,"bookYear":"2024","nameKR":"삼다숯불갈비","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":5928,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.258416,"longitude":126.407129,"bookYear":"2024","nameKR":"목포고을","ribbonType":"X","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":6069,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.989654,"longitude":127.099759,"bookYear":"2024","nameKR":"고복수평양냉면","ribbonType":"X","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":9018,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":34.633795,"longitude":126.780792,"bookYear":"2024","nameKR":"목리장어센터","ribbonType":"X","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":23709,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.877712,"longitude":127.170729,"bookYear":"2024","nameKR":"후연","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":22434,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/korou_korean_restaurant/","foodTypes":"한식(일반한식)","latitude":37.556066,"longitude":126.673385,"bookYear":"2024","nameKR":"고루","ribbonType":"X","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":1949,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.677573,"longitude":126.793614,"bookYear":"2024","nameKR":"명가누룽지","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":2129,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.152003947053,"longitude":126.864459862647,"bookYear":"2024","nameKR":"낙지한마당","ribbonType":"X","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":4005,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.826323,"longitude":127.177045,"bookYear":"2024","nameKR":"아중도토리묵촌","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":4059,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.344031,"longitude":127.110469,"bookYear":"2024","nameKR":"이수사","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":4109,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.783723,"longitude":126.447502,"bookYear":"2024","nameKR":"가야관","ribbonType":"X","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":6427,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.151275,"longitude":126.853305,"bookYear":"2024","nameKR":"육전명가","ribbonType":"X","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":30523,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.83875705557876,"longitude":128.6162048804827,"bookYear":"2024","nameKR":"대륙회초밥","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":11115,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.27505,"longitude":129.0857,"bookYear":"2024","nameKR":"부산압구정한우갈비","ribbonType":"X","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":2438,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.0336417258347,"longitude":126.907815278796,"bookYear":"2024","nameKR":"색동두부","ribbonType":"X","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":3254,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.905037,"longitude":127.051935,"bookYear":"2024","nameKR":"황주생고기","ribbonType":"X","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":3383,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oci8673/","foodTypes":"한식(육류)","latitude":35.314468,"longitude":127.486859,"bookYear":"2024","nameKR":"양미한옥가든","ribbonType":"X","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":3565,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.585551,"longitude":127.171538,"bookYear":"2024","nameKR":"초대","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":4596,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.5419070759865,"longitude":126.667723063722,"bookYear":"2024","nameKR":"서울식당","ribbonType":"X","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":5085,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.646157,"longitude":126.613163,"bookYear":"2024","nameKR":"그집","ribbonType":"X","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":5571,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":35.8346337436194,"longitude":128.731012615388,"bookYear":"2024","nameKR":"부천성","ribbonType":"X","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":20121,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.807212,"longitude":128.521041,"bookYear":"2024","nameKR":"남강장어","ribbonType":"X","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":8121,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.45601347117161,"longitude":126.64917608022276,"bookYear":"2024","nameKR":"성진물텀벙","ribbonType":"X","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":29034,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.706976,"longitude":126.686368,"bookYear":"2024","nameKR":"밥상마루","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":9530,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.720618,"longitude":126.538241,"bookYear":"2024","nameKR":"향수가든","ribbonType":"X","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":34567,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":36.9869949760901,"longitude":127.737733386382,"bookYear":"2024","nameKR":"열명의농부","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":10897,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.600173,"longitude":128.710815,"bookYear":"2024","nameKR":"석송가든","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":11992,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.0103492512401,"longitude":127.208363939737,"bookYear":"2024","nameKR":"중앙가든","ribbonType":"X","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":30522,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sook8750/","foodTypes":"한식(육류)","latitude":35.8455617302033,"longitude":128.592196413088,"bookYear":"2024","nameKR":"소마구참숯갈비","ribbonType":"X","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":13134,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.866830741906654,"longitude":127.7211776729748,"bookYear":"2024","nameKR":"곰배령","ribbonType":"X","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":18604,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.195948,"longitude":129.059576,"bookYear":"2024","nameKR":"주문진막국수","ribbonType":"X","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":17216,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.07962,"longitude":128.353551,"bookYear":"2024","nameKR":"농우마실","ribbonType":"X","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":2376,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.47396329811918,"longitude":126.6194686339322,"bookYear":"2024","nameKR":"태림봉","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":2522,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.730736,"longitude":126.731372,"bookYear":"2024","nameKR":"당산마루","ribbonType":"X","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":2529,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.67273163009273,"longitude":127.11150634183493,"bookYear":"2024","nameKR":"산에는꽃이피네","ribbonType":"X","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":2671,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.115123,"longitude":127.591157,"bookYear":"2024","nameKR":"원골식당","ribbonType":"X","zone2_1":"충남","zone2_2":"금산군","status":"ACTIVE"},
        {"id":2813,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.815408,"longitude":127.144514,"bookYear":"2024","nameKR":"한국식당","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":3120,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.314816,"longitude":127.580409,"bookYear":"2024","nameKR":"구읍할매묵집","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":3311,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.00336,"longitude":127.683504,"bookYear":"2024","nameKR":"천지가든","ribbonType":"X","zone2_1":"전북","zone2_2":"무주군","status":"ACTIVE"},
        {"id":3374,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.841224,"longitude":129.250768,"bookYear":"2024","nameKR":"산해","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30442,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.48288811515947,"longitude":126.68748747070909,"bookYear":"2024","nameKR":"산나래간장게장","ribbonType":"X","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":4343,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.17870579986039,"longitude":127.23560917367021,"bookYear":"2024","nameKR":"동강민물매운탕","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":4368,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.316225,"longitude":127.003496,"bookYear":"2024","nameKR":"항아리집","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":4410,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.099186,"longitude":126.775136,"bookYear":"2024","nameKR":"원조동곡식당","ribbonType":"X","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":4601,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.290493,"longitude":126.318372,"bookYear":"2024","nameKR":"축협축산물플라자한우식당","ribbonType":"X","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":4891,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.450258,"longitude":127.426548,"bookYear":"2024","nameKR":"보성식당","ribbonType":"X","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":5573,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.760886,"longitude":128.077029,"bookYear":"2024","nameKR":"새재할매집","ribbonType":"X","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":8130,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.471721,"longitude":126.623946,"bookYear":"2024","nameKR":"중화루","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":10593,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.717989,"longitude":128.557736,"bookYear":"2024","nameKR":"다사랑","ribbonType":"X","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":37538,"bookStatus":"승인","websiteInstagram":"http://instagram.com/mulggobang","foodTypes":"디저트/차/베이커리","latitude":37.7957004163072,"longitude":127.159406586238,"bookYear":"2024","nameKR":"물꼬방","ribbonType":"X","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":28606,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.833256,"longitude":129.210745,"bookYear":"2024","nameKR":"별채반교동쌈밥","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":3079,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.937352,"longitude":128.914339,"bookYear":"2024","nameKR":"동궁회관","ribbonType":"X","zone2_1":"경북","zone2_2":"봉화군","status":"ACTIVE"},
        {"id":4443,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.139789,"longitude":127.235452,"bookYear":"2024","nameKR":"대둔산양촌한우타운","ribbonType":"X","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":4576,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.743112,"longitude":127.75012,"bookYear":"2024","nameKR":"동백회관","ribbonType":"X","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":6147,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.729816,"longitude":127.104107,"bookYear":"2024","nameKR":"해금강","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":8208,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.134225,"longitude":129.092192,"bookYear":"2024","nameKR":"다다우동","ribbonType":"X","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":8240,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sun_noodle/","foodTypes":"한식(면류)","latitude":35.880441,"longitude":128.613367,"bookYear":"2024","nameKR":"원조신암태양칼국수","ribbonType":"X","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":9607,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.282979,"longitude":126.915479,"bookYear":"2024","nameKR":"서동한우","ribbonType":"X","zone2_1":"충남","zone2_2":"부여군","status":"ACTIVE"},
        {"id":33467,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/blumetz.official/","foodTypes":"기타","latitude":37.3639411805735,"longitude":127.127506984099,"bookYear":"2024","nameKR":"블루메쯔(분당수내점)","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":30524,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.83035859263258,"longitude":128.61618944243605,"bookYear":"2024","nameKR":"성림복어","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":29934,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hyo_seiromushi/","foodTypes":"일식","latitude":37.36825247994039,"longitude":127.10507494664158,"bookYear":"2024","nameKR":"효","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37514,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_bistrodoma_","foodTypes":"기타","latitude":37.3922684316568,"longitude":127.061843205041,"bookYear":"2024","nameKR":"비스트로도마","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":22790,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.715736,"longitude":126.980949,"bookYear":"2024","nameKR":"카페휘바","ribbonType":"X","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":14096,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.451265,"longitude":127.134333,"bookYear":"2024","nameKR":"황해도전통손만두국","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":17351,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.502841,"longitude":129.428816,"bookYear":"2024","nameKR":"나고야일식","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":3096,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.011567108005124,"longitude":127.02428383110625,"bookYear":"2024","nameKR":"미륵산순두부","ribbonType":"X","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":4017,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.320950352140855,"longitude":126.98152430796627,"bookYear":"2024","nameKR":"청운식당","ribbonType":"X","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":4327,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.330214,"longitude":126.861589,"bookYear":"2024","nameKR":"제일생고기","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":5254,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.44568,"longitude":126.698383,"bookYear":"2024","nameKR":"큰나루밴댕이회무침","ribbonType":"X","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":7995,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.968775,"longitude":127.580148,"bookYear":"2024","nameKR":"시내식당","ribbonType":"X","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":24206,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.218966,"longitude":128.924849,"bookYear":"2024","nameKR":"달카페","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":29750,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.782295221486706,"longitude":127.02969186120137,"bookYear":"2024","nameKR":"시실리","ribbonType":"X","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":27131,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.394901,"longitude":126.650156,"bookYear":"2024","nameKR":"송도동산호","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":26985,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.232715,"longitude":128.991269,"bookYear":"2024","nameKR":"강변매운탕","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":2095,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.47686,"longitude":127.71512,"bookYear":"2024","nameKR":"순흥전통묵집","ribbonType":"X","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":2169,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/misungbokbul/","foodTypes":"한식(어패류)","latitude":35.837807,"longitude":128.616471,"bookYear":"2024","nameKR":"미성복어불고기","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":2873,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.281086,"longitude":127.464235,"bookYear":"2024","nameKR":"원조주막칡냉면","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":2912,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.414162,"longitude":128.160088,"bookYear":"2024","nameKR":"홍성식육식당","ribbonType":"X","zone2_1":"경북","zone2_2":"상주시","status":"ACTIVE"},
        {"id":4261,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.299598,"longitude":127.408593,"bookYear":"2024","nameKR":"청목","ribbonType":"X","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":5399,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.434906,"longitude":126.880484,"bookYear":"2024","nameKR":"바닷물손두부","ribbonType":"X","zone2_1":"충남","zone2_2":"청양군","status":"ACTIVE"},
        {"id":5566,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.124779,"longitude":128.343077,"bookYear":"2024","nameKR":"이조곰탕","ribbonType":"X","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":6746,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.516424,"longitude":126.505594,"bookYear":"2024","nameKR":"용두골","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34240,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.1399908531024,"longitude":126.794226294194,"bookYear":"2024","nameKR":"동성떡갈비","ribbonType":"X","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":8100,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.603116,"longitude":126.551347,"bookYear":"2024","nameKR":"삼삼복집","ribbonType":"X","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":8247,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.87069,"longitude":128.604584,"bookYear":"2024","nameKR":"낙영찜갈비","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":9737,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.286891,"longitude":127.233316,"bookYear":"2024","nameKR":"신도리한우촌","ribbonType":"X","zone2_1":"충남","zone2_2":"계룡시","status":"ACTIVE"},
        {"id":11167,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.203026,"longitude":129.085193,"bookYear":"2024","nameKR":"정림전통한식","ribbonType":"X","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":32967,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.3016913386604,"longitude":126.851087861155,"bookYear":"2024","nameKR":"궁중삼계탕","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":17905,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1974101800571,"longitude":129.223212498997,"bookYear":"2024","nameKR":"풍원장시골밥상집","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":1978,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.1629310507097,"longitude":127.376181633755,"bookYear":"2024","nameKR":"풍성식당","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":2401,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.1643551990656,"longitude":127.4520499131,"bookYear":"2024","nameKR":"남창식당","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":2476,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.495746,"longitude":126.617202,"bookYear":"2024","nameKR":"전주식당","ribbonType":"X","zone2_1":"전남","zone2_2":"해남군","status":"ACTIVE"},
        {"id":2592,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.516396,"longitude":126.528819,"bookYear":"2024","nameKR":"가본식당","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":2653,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.53932,"longitude":126.472704,"bookYear":"2024","nameKR":"삼천리수산횟집","ribbonType":"X","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":2668,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.672271,"longitude":126.643713,"bookYear":"2024","nameKR":"입질네어죽","ribbonType":"X","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":3149,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.38294728468969,"longitude":128.6716409905278,"bookYear":"2024","nameKR":"동광식당","ribbonType":"X","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":4602,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.420056,"longitude":126.338884,"bookYear":"2024","nameKR":"장촌한우마을","ribbonType":"X","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":5411,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.754536,"longitude":126.294154,"bookYear":"2024","nameKR":"향토꽃게장","ribbonType":"X","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":5977,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.016313,"longitude":127.644689,"bookYear":"2024","nameKR":"강나루","ribbonType":"X","zone2_1":"전북","zone2_2":"무주군","status":"ACTIVE"},
        {"id":6000,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.2442214828468,"longitude":129.0555755130261,"bookYear":"2024","nameKR":"산성집","ribbonType":"X","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":6215,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.802955,"longitude":126.151041,"bookYear":"2024","nameKR":"천리포횟집슈퍼","ribbonType":"X","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":24990,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.381483,"longitude":127.128082,"bookYear":"2024","nameKR":"망캄","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":29981,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.7089697108985,"longitude":127.446717722669,"bookYear":"2024","nameKR":"서호식당","ribbonType":"X","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":8254,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.838418,"longitude":128.627252,"bookYear":"2024","nameKR":"소머리곰탕","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":10587,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.6858,"longitude":127.90801,"bookYear":"2024","nameKR":"풍전복집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거창군","status":"ACTIVE"},
        {"id":13170,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.299316,"longitude":126.527391,"bookYear":"2024","nameKR":"바다동산","ribbonType":"X","zone2_1":"전남","zone2_2":"해남군","status":"ACTIVE"},
        {"id":28080,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.2864537125445,"longitude":129.258200533011,"bookYear":"2024","nameKR":"아데초이","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":2006,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.710712,"longitude":128.047818,"bookYear":"2024","nameKR":"공주식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":2030,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.064434,"longitude":127.746593,"bookYear":"2024","nameKR":"여여식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":2057,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.865787,"longitude":128.730636,"bookYear":"2024","nameKR":"항만식당해물뚝배기","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":2082,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.55184822229163,"longitude":128.53067772853205,"bookYear":"2024","nameKR":"목석원가든","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":2470,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":34.77972818743272,"longitude":127.3330976811831,"bookYear":"2024","nameKR":"소문난갈비탕","ribbonType":"X","zone2_1":"전남","zone2_2":"고흥군","status":"ACTIVE"},
        {"id":22301,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.158127,"longitude":129.174569,"bookYear":"2024","nameKR":"기와집대구탕","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":27687,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.137973,"longitude":129.065094,"bookYear":"2024","nameKR":"커피텍로스터스","ribbonType":"X","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":3152,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.077189602988064,"longitude":128.44766209310816,"bookYear":"2024","nameKR":"통나무집식당","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":34190,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bong_gung/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2984950703762,"longitude":126.924970214205,"bookYear":"2024","nameKR":"봉궁순대국본점","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":4454,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.79253,"longitude":127.129618,"bookYear":"2024","nameKR":"투가리해장국","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":5412,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.657659,"longitude":126.620565,"bookYear":"2024","nameKR":"중앙산채명가","ribbonType":"X","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":5935,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.664465,"longitude":126.513397,"bookYear":"2024","nameKR":"변산명인바지락죽","ribbonType":"X","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":6461,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.37660521873304,"longitude":128.3843761427687,"bookYear":"2024","nameKR":"청성애원","ribbonType":"X","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":9563,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.624250903459384,"longitude":126.47115061361717,"bookYear":"2024","nameKR":"군산식당","ribbonType":"X","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9652,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.730409,"longitude":126.733285,"bookYear":"2024","nameKR":"오뚜기회관","ribbonType":"X","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9970,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.907734,"longitude":127.343582,"bookYear":"2024","nameKR":"고향정가","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":2037,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":34.840743,"longitude":128.418329,"bookYear":"2024","nameKR":"원조시락국","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":2309,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.159164,"longitude":129.170716,"bookYear":"2024","nameKR":"미포할매복국","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":2970,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.77042,"longitude":127.080435,"bookYear":"2024","nameKR":"수복식당","ribbonType":"X","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":3328,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4642,"longitude":126.639685,"bookYear":"2024","nameKR":"평양옥","ribbonType":"X","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":3348,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.064135,"longitude":126.522817,"bookYear":"2024","nameKR":"목포식당","ribbonType":"X","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":3757,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.320228,"longitude":128.261571,"bookYear":"2024","nameKR":"종로식당","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"의령군","status":"ACTIVE"},
        {"id":4511,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.22685941116675,"longitude":128.81666923708158,"bookYear":"2024","nameKR":"혜원가든","ribbonType":"X","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":4589,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.502456,"longitude":126.538479,"bookYear":"2024","nameKR":"골막식당","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":31748,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dambit.art.space/","foodTypes":"디저트/차/베이커리","latitude":35.3233346109027,"longitude":126.99315928415,"bookYear":"2024","nameKR":"담빛예술창고","ribbonType":"X","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":7996,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.66542,"longitude":127.373265,"bookYear":"2024","nameKR":"옥산장날순대","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":9023,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.81258149814976,"longitude":127.14715317570085,"bookYear":"2024","nameKR":"조점례남문피순대","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":9219,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.66424326333898,"longitude":129.11504646752724,"bookYear":"2024","nameKR":"맘포식당숯불갈비","ribbonType":"X","zone2_1":"경북","zone2_2":"영양군","status":"ACTIVE"},
        {"id":31891,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dcw.mall/","foodTypes":"한식(육류)","latitude":37.4417104235279,"longitude":126.670764759323,"bookYear":"2024","nameKR":"동추원불고기","ribbonType":"X","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":28423,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.653551,"longitude":126.776297,"bookYear":"2024","nameKR":"대동관","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35409,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/riripie.busan","foodTypes":"디저트/차/베이커리","latitude":35.1611826061236,"longitude":129.173697603189,"bookYear":"2024","nameKR":"리리파이","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":17540,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.8310439816317,"longitude":127.124448354975,"bookYear":"2024","nameKR":"쁘띠빠리","ribbonType":"X","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":17561,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.476636,"longitude":127.181909,"bookYear":"2024","nameKR":"산성대가","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":32060,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8263960630723,"longitude":128.624193760503,"bookYear":"2024","nameKR":"마당숯불갈비","ribbonType":"X","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":1626,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.1036292141337,"longitude":128.6304439497802,"bookYear":"2024","nameKR":"등불","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":1717,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.511942,"longitude":128.225373,"bookYear":"2024","nameKR":"통나무집","ribbonType":"X","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":2079,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.171669,"longitude":129.37325,"bookYear":"2024","nameKR":"명가정통민물장어","ribbonType":"X","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":2184,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.861613,"longitude":128.633907,"bookYear":"2024","nameKR":"아트리움","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":3041,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.507556,"longitude":126.804999,"bookYear":"2024","nameKR":"산마루들녘에","ribbonType":"X","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":4367,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.293938,"longitude":127.111653,"bookYear":"2024","nameKR":"암소제비추리","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":4702,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.97580955309495,"longitude":127.5851575607965,"bookYear":"2024","nameKR":"장원회관","ribbonType":"X","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":26956,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.823969,"longitude":129.210983,"bookYear":"2024","nameKR":"하연지","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":4803,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.536383,"longitude":127.302336,"bookYear":"2024","nameKR":"고당","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":5339,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.358887,"longitude":127.122986,"bookYear":"2024","nameKR":"두향","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":6005,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.17572,"longitude":129.117964,"bookYear":"2024","nameKR":"뱅델올리브","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":6214,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.401453,"longitude":127.228597,"bookYear":"2024","nameKR":"싸리골","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":9813,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/damijin.jeju/","foodTypes":"한식(어패류)","latitude":33.325074,"longitude":126.844119,"bookYear":"2024","nameKR":"다미진","ribbonType":"X","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":30540,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.83341876157897,"longitude":128.61619839656814,"bookYear":"2024","nameKR":"아라한참치","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":30841,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/daewang__official/","foodTypes":"한식(어패류)","latitude":37.4759058754402,"longitude":126.598215746152,"bookYear":"2024","nameKR":"전라도대왕조개","ribbonType":"X","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":30553,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":35.833127113164224,"longitude":128.61724598962692,"bookYear":"2024","nameKR":"센도리","ribbonType":"X","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":16857,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.370363,"longitude":127.106522,"bookYear":"2024","nameKR":"카리","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37414,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sanjanggarden_sejong/","foodTypes":"한식(육류)","latitude":36.6003071026536,"longitude":127.261948698477,"bookYear":"2024","nameKR":"산장가든","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":30838,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/talbingo_","foodTypes":"디저트/차/베이커리","latitude":36.5489628322659,"longitude":128.527490403276,"bookYear":"2024","nameKR":"탈빙고","ribbonType":"X","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":1816,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.250176,"longitude":127.075253,"bookYear":"2024","nameKR":"무안낙지골","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":2268,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.273911,"longitude":129.25447,"bookYear":"2024","nameKR":"마레","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":2369,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.723894,"longitude":126.51277,"bookYear":"2024","nameKR":"일미산장숯불장어","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":2642,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.438298,"longitude":126.518453,"bookYear":"2024","nameKR":"대전횟집","ribbonType":"X","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":25387,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.163476218359,"longitude":129.060341233639,"bookYear":"2024","nameKR":"주차장산꼼장어","ribbonType":"X","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":2842,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.497891,"longitude":127.302064,"bookYear":"2024","nameKR":"남강집","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":26962,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_the_lounge","foodTypes":"기타","latitude":37.400367,"longitude":127.094641,"bookYear":"2024","nameKR":"더라운지","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":20462,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.874016,"longitude":128.596272,"bookYear":"2024","nameKR":"대호불갈비","ribbonType":"X","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":4049,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.744172,"longitude":127.049408,"bookYear":"2024","nameKR":"형네식당","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":4235,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.891208,"longitude":128.824663,"bookYear":"2024","nameKR":"대동면옥","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":4522,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.566797,"longitude":129.125072,"bookYear":"2024","nameKR":"진미불고기","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":4580,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.846602061352854,"longitude":129.2099710381196,"bookYear":"2024","nameKR":"영양숯불갈비","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":4727,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.074601,"longitude":128.622473,"bookYear":"2024","nameKR":"그린생칼국수","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":5091,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.234277,"longitude":127.892191,"bookYear":"2024","nameKR":"한천가든","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"영동군","status":"ACTIVE"},
        {"id":5516,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/apsanvilla00","foodTypes":"이탈리아식","latitude":35.833629,"longitude":128.577677,"bookYear":"2024","nameKR":"앞산주택","ribbonType":"X","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":5565,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.126021,"longitude":128.339133,"bookYear":"2024","nameKR":"한촌설렁탕","ribbonType":"X","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":6282,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.659743,"longitude":126.768772,"bookYear":"2024","nameKR":"오오마참치","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":25070,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.381949,"longitude":127.1516,"bookYear":"2024","nameKR":"쿠치나디까사","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32057,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.2825214965929,"longitude":127.613118494687,"bookYear":"2024","nameKR":"여내울","ribbonType":"X","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":29249,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3823034862019,"longitude":127.093855681171,"bookYear":"2024","nameKR":"사계절한정식","ribbonType":"X","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":30002,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/steezecoffee/","foodTypes":"디저트/차/베이커리","latitude":36.6777605169974,"longitude":127.471675625544,"bookYear":"2024","nameKR":"스티즈커피로스터스","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":9483,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.775178,"longitude":126.461951,"bookYear":"2024","nameKR":"삽교신창집","ribbonType":"X","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":26173,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.796726,"longitude":128.916812,"bookYear":"2024","nameKR":"카페폴앤메리","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":26572,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.828823,"longitude":129.209183,"bookYear":"2024","nameKR":"진미식당","ribbonType":"X","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":17276,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.491586,"longitude":126.549199,"bookYear":"2024","nameKR":"송죽원","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":1823,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.331860514636745,"longitude":127.01556700587442,"bookYear":"2024","nameKR":"백운농장","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":2819,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.52561,"longitude":126.857532,"bookYear":"2024","nameKR":"은성식당","ribbonType":"X","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":2830,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.228555,"longitude":128.081968,"bookYear":"2024","nameKR":"향온","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":3091,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.1608778541688,"longitude":127.07946519088402,"bookYear":"2024","nameKR":"새말해장국","ribbonType":"X","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":3615,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.214807,"longitude":128.91453,"bookYear":"2024","nameKR":"원조할매추어탕","ribbonType":"X","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":8218,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.158822,"longitude":129.170621,"bookYear":"2024","nameKR":"새아침식당","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":10513,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.444044,"longitude":128.453276,"bookYear":"2024","nameKR":"성진회관","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":3615,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.214807,"longitude":128.91453,"bookYear":"2024","nameKR":"원조할매추어탕","ribbonType":"X","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":4168,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.885891,"longitude":128.485532,"bookYear":"2024","nameKR":"박곡칼국수","ribbonType":"X","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":4440,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.811937961172184,"longitude":127.15988526362128,"bookYear":"2024","nameKR":"화순집","ribbonType":"X","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":4506,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.1816387211917,"longitude":128.978049084415,"bookYear":"2024","nameKR":"고사리식당","ribbonType":"X","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":5361,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jaseon.farm","foodTypes":"한식(일반한식)","latitude":37.3153796655905,"longitude":127.019014765343,"bookYear":"2024","nameKR":"자선농원","ribbonType":"X","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":8218,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.158822,"longitude":129.170621,"bookYear":"2024","nameKR":"새아침식당","ribbonType":"X","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":9538,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.3147622492051,"longitude":126.69517669715037,"bookYear":"2024","nameKR":"삼호식당","ribbonType":"X","zone2_1":"충남","zone2_2":"부여군","status":"ACTIVE"},
        {"id":27333,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.428219,"longitude":127.24024,"bookYear":"2024","nameKR":"진짜무릎도가니탕&설농탕","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":10513,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.444044,"longitude":128.453276,"bookYear":"2024","nameKR":"성진회관","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":11951,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.654949,"longitude":127.255217,"bookYear":"2024","nameKR":"원조천마산곰탕","ribbonType":"X","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":12363,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.275527,"longitude":126.569098,"bookYear":"2024","nameKR":"삐쭉이백합칼국수","ribbonType":"X","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":27889,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.476097,"longitude":126.554301,"bookYear":"2024","nameKR":"메종드쁘띠푸르","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":21401,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.742845,"longitude":127.026183,"bookYear":"2024","nameKR":"정통부대고기","ribbonType":"X","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":32416,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.6101323485696,"longitude":126.629737725409,"bookYear":"2024","nameKR":"다해꽃게장","ribbonType":"X","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":1735,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.865209,"longitude":128.841474,"bookYear":"2024","nameKR":"홍질목","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":4027,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.799126480346956,"longitude":126.69580874517143,"bookYear":"2024","nameKR":"동락식당","ribbonType":"X","zone2_1":"전남","zone2_2":"영암군","status":"ACTIVE"},
        {"id":4350,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/paju_boribap_house/","foodTypes":"한식(일반한식)","latitude":37.752553,"longitude":126.910803,"bookYear":"2024","nameKR":"시골보리밥집","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":4578,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.559721,"longitude":129.457546,"bookYear":"2024","nameKR":"남일횟집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":4586,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1965938328424,"longitude":128.083763291625,"bookYear":"2024","nameKR":"수복빵집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":30817,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.0365881423485,"longitude":127.261978627337,"bookYear":"2024","nameKR":"도토리네칼국수","ribbonType":"X","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":5540,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.563151,"longitude":129.125921,"bookYear":"2024","nameKR":"원조옛날곰탕","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":5555,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pungnyeonbogjib/","foodTypes":"한식(어패류)","latitude":34.928506,"longitude":128.069668,"bookYear":"2024","nameKR":"풍년복집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":8887,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.16074,"longitude":126.907667,"bookYear":"2024","nameKR":"창억떡집","ribbonType":"X","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":8943,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.453985,"longitude":128.397233,"bookYear":"2024","nameKR":"산북막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":8999,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.006693,"longitude":126.672693,"bookYear":"2024","nameKR":"신흥장어","ribbonType":"X","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":32454,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/fillmoodcafe/","foodTypes":"디저트/차/베이커리","latitude":37.7764834274328,"longitude":126.911969101416,"bookYear":"2024","nameKR":"필무드","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":31235,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.1760092661427,"longitude":128.602117218122,"bookYear":"2024","nameKR":"대포면옥","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":34399,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.2277357065848,"longitude":127.526248215706,"bookYear":"2024","nameKR":"은성손두부","ribbonType":"X","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":29617,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/shortcut.roasters","foodTypes":"디저트/차/베이커리","latitude":37.69617855033165,"longitude":126.79886983237493,"bookYear":"2024","nameKR":"숏컷로스터스","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":25363,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.18390900435032,"longitude":129.08110121451458,"bookYear":"2024","nameKR":"녹산횟집","ribbonType":"X","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":17762,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.059139,"longitude":126.983294,"bookYear":"2024","nameKR":"금성대중음식점","ribbonType":"X","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":18249,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.466064,"longitude":128.167324,"bookYear":"2024","nameKR":"부흥식육식당","ribbonType":"X","zone2_1":"경북","zone2_2":"상주시","status":"ACTIVE"},
        {"id":27135,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.417721,"longitude":126.654306,"bookYear":"2024","nameKR":"인천콩나물국밥","ribbonType":"X","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":32173,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pizzeria.ceppo","foodTypes":"이탈리아식","latitude":37.2302220144557,"longitude":127.187719923273,"bookYear":"2024","nameKR":"핏제리아체뽀","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":30776,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.1518607931522,"longitude":127.069865996337,"bookYear":"2024","nameKR":"부용식당","ribbonType":"X","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":30025,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.94004725197094,"longitude":126.94878085592498,"bookYear":"2024","nameKR":"태백칼국수","ribbonType":"X","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":3256,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.32899454379219,"longitude":127.39015248823621,"bookYear":"2024","nameKR":"광현가든","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":32408,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/_cafesori_/","foodTypes":"디저트/차/베이커리","latitude":38.1986484437097,"longitude":128.535110773132,"bookYear":"2024","nameKR":"카페소리","ribbonType":"X","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":4015,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.505554,"longitude":126.599589,"bookYear":"2024","nameKR":"할매집풍천장어숯불구이","ribbonType":"X","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":5324,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":38.107200489614556,"longitude":127.98619593182465,"bookYear":"2024","nameKR":"장수오골계숯불구이","ribbonType":"X","zone2_1":"강원","zone2_2":"양구군","status":"ACTIVE"},
        {"id":5553,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.512443,"longitude":128.841384,"bookYear":"2024","nameKR":"행랑채","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":8987,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.972617157443075,"longitude":128.93462083759647,"bookYear":"2024","nameKR":"삼송꾼만두","ribbonType":"X","zone2_1":"경북","zone2_2":"영천시","status":"ACTIVE"},
        {"id":9610,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.779891016930726,"longitude":126.49158444461149,"bookYear":"2024","nameKR":"수석가든","ribbonType":"X","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":29268,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gosranhee/","foodTypes":"디저트/차/베이커리","latitude":33.488843,"longitude":126.534655,"bookYear":"2024","nameKR":"고스란히","ribbonType":"X","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":27182,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.3529857030654,"longitude":126.771350295873,"bookYear":"2024","nameKR":"가시식당","ribbonType":"X","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":31487,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.4863033240683,"longitude":127.69508024178,"bookYear":"2024","nameKR":"청학산","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"함양군","status":"ACTIVE"},
        {"id":31573,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.2189479435164,"longitude":128.367845066203,"bookYear":"2024","nameKR":"더덕식당","ribbonType":"X","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":13172,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.93355190296433,"longitude":128.78953396462967,"bookYear":"2024","nameKR":"창횟집","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":13181,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.708786,"longitude":126.878039,"bookYear":"2024","nameKR":"대자골토속음식","ribbonType":"X","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":27891,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4879,"longitude":128.072078,"bookYear":"2024","nameKR":"용둔막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":19895,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.751901,"longitude":128.896553,"bookYear":"2024","nameKR":"원조춘하추동","ribbonType":"X","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":19991,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.528144,"longitude":127.385893,"bookYear":"2024","nameKR":"원두막매운탕","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":1799,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.517814,"longitude":127.600802,"bookYear":"2024","nameKR":"마당곤드레밥","ribbonType":"X","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":2307,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0766965102662,"longitude":129.022502310846,"bookYear":"2024","nameKR":"원조태성하모횟집","ribbonType":"X","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":2332,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.552405,"longitude":129.272909,"bookYear":"2024","nameKR":"원조집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":2333,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.715243,"longitude":129.201104,"bookYear":"2024","nameKR":"종점숯불구이","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":2509,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.944686,"longitude":127.500973,"bookYear":"2024","nameKR":"흥덕식당","ribbonType":"X","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":2587,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.2476972084968,"longitude":126.559024545325,"bookYear":"2024","nameKR":"삼보식당","ribbonType":"X","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":2891,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.9270480038647,"longitude":128.076015137813,"bookYear":"2024","nameKR":"삼천포원조물회","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":3216,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.298518643361724,"longitude":129.25949603957014,"bookYear":"2024","nameKR":"꺼먹동네","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":4985,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.825976,"longitude":128.712663,"bookYear":"2024","nameKR":"성암골가마솥국밥","ribbonType":"X","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":5509,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.144084,"longitude":129.110415,"bookYear":"2024","nameKR":"향나무집","ribbonType":"X","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":6001,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.260266,"longitude":129.233223,"bookYear":"2024","nameKR":"미청식당","ribbonType":"X","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":6188,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.713599,"longitude":129.199828,"bookYear":"2024","nameKR":"봉계전통숯불구이","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":6802,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.3195499845436,"longitude":128.260884366104,"bookYear":"2024","nameKR":"제일소바","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"의령군","status":"ACTIVE"},
        {"id":8228,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.880999,"longitude":128.581701,"bookYear":"2024","nameKR":"대창숯불갈비","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":8949,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.519534,"longitude":127.724693,"bookYear":"2024","nameKR":"조샌집","ribbonType":"X","zone2_1":"경남/울산","zone2_2":"함양군","status":"ACTIVE"},
        {"id":9237,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":34.7860313308185,"longitude":126.383052022388,"bookYear":"2024","nameKR":"명신식당","ribbonType":"X","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":33722,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.5928942914673,"longitude":128.20989955802,"bookYear":"2024","nameKR":"카페라밀","ribbonType":"X","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":33453,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.5062549422206,"longitude":129.119620961174,"bookYear":"2024","nameKR":"명궁약수가든","ribbonType":"X","zone2_1":"경북","zone2_2":"청송군","status":"ACTIVE"},
        {"id":32558,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.2289131437365,"longitude":127.103529207168,"bookYear":"2024","nameKR":"콩게미","ribbonType":"X","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":16159,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.315496,"longitude":127.576859,"bookYear":"2024","nameKR":"대박집","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":28063,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.360236,"longitude":127.248398,"bookYear":"2024","nameKR":"삼부자손두부집","ribbonType":"X","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":1738,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.202376,"longitude":128.347817,"bookYear":"2024","nameKR":"황태령","ribbonType":"X","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":2669,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.11027989339403,"longitude":127.56512308490575,"bookYear":"2024","nameKR":"저곡식당","ribbonType":"X","zone2_1":"충남","zone2_2":"금산군","status":"ACTIVE"},
        {"id":3382,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.953986,"longitude":124.719664,"bookYear":"2024","nameKR":"사곶냉면","ribbonType":"X","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":4478,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3778,"longitude":129.227299,"bookYear":"2024","nameKR":"춘도식당","ribbonType":"X","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":5402,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.771856,"longitude":126.935444,"bookYear":"2024","nameKR":"밀터칼국수","ribbonType":"X","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":5492,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.48239102307592,"longitude":127.72255879430382,"bookYear":"2024","nameKR":"강서면옥","ribbonType":"X","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":6027,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.46797,"longitude":127.193529,"bookYear":"2024","nameKR":"메종드포레","ribbonType":"X","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":6932,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.520927,"longitude":130.869023,"bookYear":"2024","nameKR":"산마을식당","ribbonType":"X","zone2_1":"경북","zone2_2":"울릉군","status":"ACTIVE"},
        {"id":8229,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.88875507886933,"longitude":128.5805255861061,"bookYear":"2024","nameKR":"칠성동할매콩국수","ribbonType":"X","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":33819,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mn__spot/","foodTypes":"중식","latitude":37.8493212211671,"longitude":126.79118555326,"bookYear":"2024","nameKR":"맛나반점","ribbonType":"X","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":8930,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.261763,"longitude":128.19226,"bookYear":"2024","nameKR":"황둔막국수","ribbonType":"X","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":31075,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.5648156219123,"longitude":129.121786254894,"bookYear":"2024","nameKR":"언양한우불고기","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":31433,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ahojung__/","foodTypes":"중식","latitude":36.4956272428769,"longitude":127.264907568615,"bookYear":"2024","nameKR":"아호정","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":37016,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gnocchi_on_thursday","foodTypes":"이탈리아식","latitude":36.4923112410356,"longitude":127.260577313847,"bookYear":"2024","nameKR":"뇨끼온떨스데이","ribbonType":"X","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":28503,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.380643,"longitude":128.136108,"bookYear":"2024","nameKR":"강림순대","ribbonType":"X","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":4463,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.0777980297195,"longitude":128.448729745946,"bookYear":"2024","nameKR":"남설악식당","ribbonType":"X","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":37466,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":35.6327003385787,"longitude":129.359469672795,"bookYear":"2024","nameKR":"히츠지","ribbonType":"X","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":30305,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.526262166373165,"longitude":127.12642417750322,"bookYear":"2024","nameKR":"애크로매틱커피파트너스","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":33122,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/anyoccasion.official/","foodTypes":"디저트/차/베이커리","latitude":37.5240565925233,"longitude":127.038074565192,"bookYear":"2024","nameKR":"애니오케이션 ","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":35813,"bookStatus":"승인","websiteInstagram":"https://instagram.com/monamona_macaron","foodTypes":"디저트/차/베이커리","latitude":37.5069006300365,"longitude":127.087485239662,"bookYear":"2024","nameKR":"모나모나","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33388,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mellower_kr/","foodTypes":"디저트/차/베이커리","latitude":37.5425331453144,"longitude":127.053233219739,"bookYear":"2024","nameKR":"베통성수더플래그십","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33636,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5142589272797,"longitude":126.897682058175,"bookYear":"2024","nameKR":"비닐하우스","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":32503,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5633674241059,"longitude":127.037643745197,"bookYear":"2024","nameKR":"주052","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":30345,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffee_verviers/","foodTypes":"디저트/차/베이커리","latitude":37.52817822371467,"longitude":126.87577187643134,"bookYear":"2024","nameKR":"커피베르비에","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":33552,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/barseulona/","foodTypes":"디저트/차/베이커리","latitude":37.5131245864065,"longitude":127.122897207665,"bookYear":"2024","nameKR":"바르세울로나","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32529,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/younganddaughters/","foodTypes":"디저트/차/베이커리","latitude":37.5479383583415,"longitude":126.954369685876,"bookYear":"2024","nameKR":"영앤도터스","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36542,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/paulsdiner_","foodTypes":"바/주점","latitude":37.5392786344347,"longitude":126.98755859772,"bookYear":"2024","nameKR":"폴스다이너","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":28057,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5345405311963,"longitude":126.997056164597,"bookYear":"2024","nameKR":"케르반베이커리","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31373,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mitasu_seoul/","foodTypes":"바/주점","latitude":37.5207393924073,"longitude":126.974783764469,"bookYear":"2024","nameKR":"미타스","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":32619,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/a.d.seoul_","foodTypes":"디저트/차/베이커리","latitude":37.547693524246,"longitude":126.917713619072,"bookYear":"2024","nameKR":"어썸도넛서울","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33155,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pincoffee_official/","foodTypes":"디저트/차/베이커리","latitude":37.5381476766317,"longitude":127.088676060054,"bookYear":"2024","nameKR":"핀커피","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":32538,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/orso_espressobar/","foodTypes":"디저트/차/베이커리","latitude":37.5488646464681,"longitude":126.975955379255,"bookYear":"2024","nameKR":"오르소에스프레소바","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":32659,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_stairs_/","foodTypes":"디저트/차/베이커리","latitude":37.556949565722,"longitude":126.979247211045,"bookYear":"2024","nameKR":"계단집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":27630,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.513859279255,"longitude":127.101857941447,"bookYear":"2024","nameKR":"8b돌체","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31167,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.508017011539,"longitude":127.106929511608,"bookYear":"2024","nameKR":"우프베이커리","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32528,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/withincoffee","foodTypes":"디저트/차/베이커리","latitude":37.5027670147241,"longitude":127.110766419496,"bookYear":"2024","nameKR":"위딘커피","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32259,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dd.gelateria/","foodTypes":"디저트/차/베이커리","latitude":37.5073118031018,"longitude":127.106021212701,"bookYear":"2024","nameKR":"젤라떼리아도도","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32544,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffee_ambience/","foodTypes":"디저트/차/베이커리","latitude":37.5015695882044,"longitude":127.124850928522,"bookYear":"2024","nameKR":"커피앰비언스 ","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":34129,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bar_manta","foodTypes":"바/주점","latitude":37.5031028684087,"longitude":127.04017584493,"bookYear":"2024","nameKR":"만타","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":36214,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/zenzero.seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5152700382785,"longitude":127.043229960702,"bookYear":"2024","nameKR":"젠제로","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29490,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/baram_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.561625387809116,"longitude":126.92531753047939,"bookYear":"2024","nameKR":"바람커피","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35858,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/heebee_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.5654254249388,"longitude":126.917205031446,"bookYear":"2024","nameKR":"히비","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":34698,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/koike.official/","foodTypes":"디저트/차/베이커리","latitude":37.5622521083425,"longitude":126.923886942959,"bookYear":"2024","nameKR":"코이크","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31916,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/acont_seoul/ ","foodTypes":"디저트/차/베이커리","latitude":37.5644476859182,"longitude":126.926349128647,"bookYear":"2024","nameKR":"아꽁뜨","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35869,"bookStatus":"승인","websiteInstagram":"https://instagram.com/gelateria.eta","foodTypes":"디저트/차/베이커리","latitude":37.5580495404784,"longitude":126.921880850072,"bookYear":"2024","nameKR":"젤라떼리아에따","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35943,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5512291674425,"longitude":126.915342505913,"bookYear":"2024","nameKR":"레드플랜트","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6780,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.555685,"longitude":126.924264,"bookYear":"2024","nameKR":"츄로101","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32921,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/thinkfruit/","foodTypes":"디저트/차/베이커리","latitude":37.5553166571615,"longitude":126.929201591124,"bookYear":"2024","nameKR":"띵크프룻","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31757,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/paddington.seoul","foodTypes":"바/주점","latitude":37.5482667556138,"longitude":127.024425786313,"bookYear":"2024","nameKR":"패딩턴","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":36145,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hidden_hour","foodTypes":"바/주점","latitude":37.5700913336734,"longitude":127.001313407537,"bookYear":"2024","nameKR":"히든아워","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":30732,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dongbaek_official/","foodTypes":"디저트/차/베이커리","latitude":37.573403931926,"longitude":126.989597437615,"bookYear":"2024","nameKR":"동백양과점","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":29604,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.56132796753287,"longitude":126.99178749569548,"bookYear":"2024","nameKR":"디올드","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":34308,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/stoked_seoul","foodTypes":"디저트/차/베이커리","latitude":37.525085019703,"longitude":126.962278322367,"bookYear":"2024","nameKR":"스톡드","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32506,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nakhasan.coffee","foodTypes":"디저트/차/베이커리","latitude":37.5309686382096,"longitude":126.971558876438,"bookYear":"2024","nameKR":"낙하산커피","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31992,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bardoucement_seoul/","foodTypes":"바/주점","latitude":37.5372473510359,"longitude":126.965778473193,"bookYear":"2024","nameKR":"두쓰멍","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36140,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/caphecozy/","foodTypes":"디저트/차/베이커리","latitude":37.5594925028349,"longitude":126.943950582631,"bookYear":"2024","nameKR":"카페코지","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":31945,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/___lilly.wood","foodTypes":"디저트/차/베이커리","latitude":37.5537203977791,"longitude":126.932655147861,"bookYear":"2024","nameKR":"릴리우드","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":32527,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/summit.culture/","foodTypes":"디저트/차/베이커리","latitude":37.5543646847543,"longitude":126.933740790083,"bookYear":"2024","nameKR":"써밋컬쳐","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":33064,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_spatz/","foodTypes":"디저트/차/베이커리","latitude":37.5907066139965,"longitude":127.020140090908,"bookYear":"2024","nameKR":"카페스파츠","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":31168,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kimdongil01/","foodTypes":"디저트/차/베이커리","latitude":37.5427191811958,"longitude":127.054071597609,"bookYear":"2024","nameKR":"뺑드에코","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":30439,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/rawcoffeestand_official/","foodTypes":"디저트/차/베이커리","latitude":37.547119326953506,"longitude":127.04661318185674,"bookYear":"2024","nameKR":"로우커피스탠드","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33971,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5464716847409,"longitude":127.044042241047,"bookYear":"2024","nameKR":"스웨덴피크닉서울숲","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33563,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5468294033531,"longitude":127.043004658207,"bookYear":"2024","nameKR":"샌드카베","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":31147,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5489909714797,"longitude":127.045691271981,"bookYear":"2024","nameKR":"카모플라쥬커피","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32971,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/vegandaum/","foodTypes":"디저트/차/베이커리","latitude":37.5691535548616,"longitude":126.913300690329,"bookYear":"2024","nameKR":"비건다움","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":31610,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5628324702465,"longitude":126.915330283401,"bookYear":"2024","nameKR":"칼라스커피","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":22489,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tongindong.coffee?igshid=YmMyMTA2M2Y=","foodTypes":"디저트/차/베이커리","latitude":37.5763032229312,"longitude":126.97000069775,"bookYear":"2024","nameKR":"통인동커피공방","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":32099,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sorry_espressobar","foodTypes":"디저트/차/베이커리","latitude":37.5764842250867,"longitude":126.9733484421,"bookYear":"2024","nameKR":"쏘리에스프레소바","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":26781,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ice_boobing/","foodTypes":"디저트/차/베이커리","latitude":37.592721,"longitude":126.965393,"bookYear":"2024","nameKR":"부암동빙수집","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":8713,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5770806892654,"longitude":126.967937657713,"bookYear":"2024","nameKR":"커피한잔","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31993,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/dokdo.16.celsius/","foodTypes":"바/주점","latitude":37.579470207527,"longitude":126.968577173605,"bookYear":"2024","nameKR":"독도16도","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31427,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.576289623047,"longitude":126.979764398735,"bookYear":"2024","nameKR":"더그릭","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":33105,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wolyeongdang/","foodTypes":"디저트/차/베이커리","latitude":37.5799395860304,"longitude":126.98163557165,"bookYear":"2024","nameKR":"월영당서울","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":33452,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/this.is.cuppacity/","foodTypes":"디저트/차/베이커리","latitude":37.5527729319837,"longitude":126.90752755267,"bookYear":"2024","nameKR":"커퍼시티","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":36504,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/portrait_coffeebar","foodTypes":"디저트/차/베이커리","latitude":37.556534693543,"longitude":126.906506819532,"bookYear":"2024","nameKR":"포트레이트커피바","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":36503,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/alwaysau8ust/","foodTypes":"디저트/차/베이커리","latitude":37.5561117959526,"longitude":126.90352079066,"bookYear":"2024","nameKR":"올웨이즈어거스트로스터스","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":29011,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffeeshopdongkyung/","foodTypes":"디저트/차/베이커리","latitude":37.55591,"longitude":126.903538,"bookYear":"2024","nameKR":"커피가게동경","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":33451,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/huelgocoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5490468747023,"longitude":126.947882129056,"bookYear":"2024","nameKR":"후엘고","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":1103,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.53936,"longitude":126.9477,"bookYear":"2024","nameKR":"마포나루","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36167,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/soolsoo_cuisine/","foodTypes":"바/주점","latitude":37.5463681672215,"longitude":126.943489553417,"bookYear":"2024","nameKR":"술수","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":33880,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/brew_niche/","foodTypes":"디저트/차/베이커리","latitude":37.5447477438441,"longitude":126.948258201287,"bookYear":"2024","nameKR":"브루니치","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36171,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/antidote.seoul/","foodTypes":"바/주점","latitude":37.5470233195855,"longitude":126.942076474986,"bookYear":"2024","nameKR":"앤티도트","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":4908,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.581895,"longitude":127.001663,"bookYear":"2024","nameKR":"학림","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":32498,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/official_matiere_cafe","foodTypes":"디저트/차/베이커리","latitude":37.5823153109913,"longitude":126.999286412043,"bookYear":"2024","nameKR":"마띠에르","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":112,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coffee_friend_/","foodTypes":"디저트/차/베이커리","latitude":37.57253,"longitude":126.9818,"bookYear":"2024","nameKR":"커피친구","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":843,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.569723,"longitude":126.977718,"bookYear":"2024","nameKR":"카페이마","ribbonType":"X","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":4669,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5261092686954,"longitude":127.053596230339,"bookYear":"2024","nameKR":"우랑","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31680,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mattcafeofficial","foodTypes":"디저트/차/베이커리","latitude":37.5138900858308,"longitude":127.040621061238,"bookYear":"2024","nameKR":"맷카페","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":7179,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.51836,"longitude":126.9339,"bookYear":"2024","nameKR":"빠뜨릭스와플 ","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":29922,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.48615039398465,"longitude":127.03578399466674,"bookYear":"2024","nameKR":"커피플랜트","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":6708,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.48387,"longitude":127.0444,"bookYear":"2024","nameKR":"프레스카29","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":19620,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.53196,"longitude":127.0269,"bookYear":"2024","nameKR":"커피트리","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32016,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/goodle_bang","foodTypes":"바/주점","latitude":37.5257683834006,"longitude":127.037584796844,"bookYear":"2024","nameKR":"구들","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32548,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/seewhypatisserie/","foodTypes":"디저트/차/베이커리","latitude":37.5237141786447,"longitude":127.037896897426,"bookYear":"2024","nameKR":"씨와이파티세리 ","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31173,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafe_baleine/","foodTypes":"디저트/차/베이커리","latitude":37.4858173465196,"longitude":126.927993853485,"bookYear":"2024","nameKR":"카페드발렌느","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":33367,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/life_bread__/","foodTypes":"디저트/차/베이커리","latitude":37.4886249229289,"longitude":126.998278019339,"bookYear":"2024","nameKR":"라이프브레드","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":32830,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/rive____gauche/","foodTypes":"디저트/차/베이커리","latitude":37.5216245595859,"longitude":126.870444168108,"bookYear":"2024","nameKR":"리브고쉬","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":30491,"bookStatus":"승인","websiteInstagram":"","foodTypes":"바/주점","latitude":37.506940760217255,"longitude":127.02696758540216,"bookYear":"2024","nameKR":"송씨해물점","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":33249,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/hotplace_junghwa","foodTypes":"바/주점","latitude":37.5673140391214,"longitude":126.827554917518,"bookYear":"2024","nameKR":"일본요리집정화","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":31149,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4820788271549,"longitude":127.011429837981,"bookYear":"2024","nameKR":"프리퍼","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":32569,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/lullcoffee_/","foodTypes":"디저트/차/베이커리","latitude":37.4824271909759,"longitude":127.011074872818,"bookYear":"2024","nameKR":"룰커피 ","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":33199,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/mood.seoul_/","foodTypes":"바/주점","latitude":37.5124362037118,"longitude":126.995962256954,"bookYear":"2024","nameKR":"무드서울","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":34616,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cafej.the.matcha.house/","foodTypes":"디저트/차/베이커리","latitude":37.5606685127751,"longitude":127.157998151763,"bookYear":"2024","nameKR":"카페제이","ribbonType":"X","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36194,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/trattoria.rialto/","foodTypes":"이탈리아식","latitude":37.5231984225484,"longitude":127.046530194424,"bookYear":"2024","nameKR":"리알토","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":36372,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5210863385776,"longitude":127.019138224359,"bookYear":"2024","nameKR":"이목스모크다이닝","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":35306,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5343180384265,"longitude":127.006194266565,"bookYear":"2024","nameKR":"로스옥(한남점)","ribbonType":"주의","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":36677,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/trattoria_romagna/","foodTypes":"이탈리아식","latitude":37.2980252815321,"longitude":127.045674146628,"bookYear":"2024","nameKR":"뜨라또리아로마냐","ribbonType":"주의","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37981,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sedeukoh/","foodTypes":"유럽식","latitude":33.4269432075484,"longitude":126.673684691736,"bookYear":"2024","nameKR":"오팬파이어","ribbonType":"주의","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":36540,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yd.pochana/","foodTypes":"아시아식","latitude":37.5206301397046,"longitude":127.036162554013,"bookYear":"2024","nameKR":"영동포차나","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":35795,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5067805185055,"longitude":127.031169865611,"bookYear":"2024","nameKR":"부르","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":34683,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/the_hyeongje/","foodTypes":"한식(어패류)","latitude":37.5428436189268,"longitude":126.95133595616,"bookYear":"2024","nameKR":"더형제","ribbonType":"주의","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36126,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/andon_songdo1/","foodTypes":"한식(육류)","latitude":37.3929791823293,"longitude":126.645688890785,"bookYear":"2024","nameKR":"안돈","ribbonType":"주의","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":39249,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.2570693005612,"longitude":129.084330999955,"bookYear":null,"nameKR":"금정사계","ribbonType":"주의","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":37248,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pizzeriasuppli_official/","foodTypes":"이탈리아식","latitude":37.0453058369773,"longitude":127.153711162014,"bookYear":"2024","nameKR":"핏제리아수플리 ","ribbonType":"주의","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":37402,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/tripodmokpo/","foodTypes":"이탈리아식","latitude":34.8062287776547,"longitude":126.437392280495,"bookYear":"2024","nameKR":"트라이팟","ribbonType":"주의","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":36880,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/onsimjae/","foodTypes":"한식(일반한식)","latitude":37.4884727521487,"longitude":126.780307107525,"bookYear":"2024","nameKR":"온심재","ribbonType":"주의","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":34268,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/soha_dining_bar","foodTypes":"기타","latitude":37.4979609979171,"longitude":126.725369823179,"bookYear":"2024","nameKR":"소하다이닝바 ","ribbonType":"주의","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":37148,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":35.1601706860183,"longitude":126.847956473666,"bookYear":"2024","nameKR":"브우디엔","ribbonType":"주의","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":36640,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/koeru_/","foodTypes":"일식","latitude":35.2143517254971,"longitude":126.852021819493,"bookYear":"2024","nameKR":"덴푸라코에루","ribbonType":"주의","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":34335,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/bourque.kr","foodTypes":"한식(일반한식)","latitude":37.5262942863702,"longitude":127.037052334832,"bookYear":"2024","nameKR":"부어끄","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":35272,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/blgre.sinsa/","foodTypes":"한식(일반한식)","latitude":37.5224844642666,"longitude":127.021024577276,"bookYear":"2024","nameKR":"블그레","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":34374,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jungdaeseoul/","foodTypes":"일식","latitude":37.5729199136655,"longitude":126.970063182815,"bookYear":"2024","nameKR":"정대","ribbonType":"주의","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":34257,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/daonbansang/","foodTypes":"한식(일반한식)","latitude":37.5273661232786,"longitude":127.027468526838,"bookYear":"2024","nameKR":"휴135다온반상","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":36230,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/legume.seoul/","foodTypes":"기타","latitude":37.5195101291255,"longitude":127.019106035326,"bookYear":"2024","nameKR":"레귬","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":36269,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sushiseongbuk/","foodTypes":"일식","latitude":37.5936027665025,"longitude":127.000787409179,"bookYear":"2024","nameKR":"스시성북","ribbonType":"주의","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":36172,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yeastseoul/","foodTypes":"컨템포러리","latitude":37.526364756231,"longitude":127.035549219304,"bookYear":"2024","nameKR":"이스트","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":36495,"bookStatus":"승인","websiteInstagram":"","foodTypes":"미국식","latitude":37.5137519612953,"longitude":127.104446890835,"bookYear":"2024","nameKR":"캐롤스","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36550,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gaggen_seoul","foodTypes":"일식","latitude":37.5245020417067,"longitude":127.045244867883,"bookYear":"2024","nameKR":"가겐","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33708,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/salon_du_bouquet/","foodTypes":"바/주점","latitude":37.5255834964246,"longitude":127.048029153978,"bookYear":"2024","nameKR":"살롱뒤부케","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":39346,"bookStatus":"커버","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.5162242501301,"longitude":127.031037264311,"bookYear":null,"nameKR":"꼴라쥬","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":37567,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/sanro_seoul/","foodTypes":"일식","latitude":37.5241684694236,"longitude":127.049777176061,"bookYear":null,"nameKR":"산로","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":37657,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/kirameki_seoul","foodTypes":"일식","latitude":37.5180657989333,"longitude":127.028212028125,"bookYear":null,"nameKR":"키라메키","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37970,"bookStatus":"커버","websiteInstagram":"","foodTypes":"일식","latitude":37.5251906223084,"longitude":127.04095242964,"bookYear":null,"nameKR":"미가키","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37882,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5529553805687,"longitude":126.963407179378,"bookYear":null,"nameKR":"소수헌","ribbonType":"주의","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":37646,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/dahliadining_/","foodTypes":"기타","latitude":37.5232258722082,"longitude":127.035755766179,"bookYear":null,"nameKR":"달리아다이닝","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":36834,"bookStatus":"커버","websiteInstagram":"","foodTypes":"일식","latitude":37.5275867130132,"longitude":127.047760394029,"bookYear":null,"nameKR":"스시사이토쥬욘","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":35849,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/darkeditioncoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5690018837641,"longitude":126.927996029512,"bookYear":"2024","nameKR":"다크에디션커피","ribbonType":"주의","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":34368,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/roexcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5499597470726,"longitude":126.936606294055,"bookYear":"2024","nameKR":"로익스커피","ribbonType":"주의","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":34369,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/birchcoffeekorea/","foodTypes":"디저트/차/베이커리","latitude":37.5405440831839,"longitude":127.059673142014,"bookYear":"2024","nameKR":"버치커피","ribbonType":"주의","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34063,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/terresdecafe_korea/","foodTypes":"디저트/차/베이커리","latitude":37.4772935648142,"longitude":127.040795021293,"bookYear":"2024","nameKR":"떼르드카페","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36196,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/swmsweetskorea","foodTypes":"디저트/차/베이커리","latitude":37.528391947359,"longitude":127.036640212616,"bookYear":"2024","nameKR":"스틱윗미","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33913,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/arabica.kr/","foodTypes":"디저트/차/베이커리","latitude":37.5118239121138,"longitude":127.059159043842,"bookYear":"2024","nameKR":"퍼센트아라비카커피","ribbonType":"주의","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":37316,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/simeun_dining/","foodTypes":"바/주점","latitude":37.5918586065082,"longitude":127.014025610945,"bookYear":null,"nameKR":"심은","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":37361,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/suomian_official/","foodTypes":"중식","latitude":35.86061117355,"longitude":128.604221880763,"bookYear":"2024","nameKR":"수오미엔","ribbonType":"신규","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":37846,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hamdeok_galchi","foodTypes":"한식(어패류)","latitude":33.5406229953067,"longitude":126.6739859636,"bookYear":null,"nameKR":"제주갈치명가","ribbonType":"신규","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":39473,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tatsujin_udon","foodTypes":"일식","latitude":36.3547708878685,"longitude":127.378264339346,"bookYear":null,"nameKR":"타츠진우동","ribbonType":"신규","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":39430,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eochon_cafe/","foodTypes":"디저트/차/베이커리","latitude":34.7260403486513,"longitude":127.715037219676,"bookYear":null,"nameKR":"로스터리카페어촌","ribbonType":"신규","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":39434,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sweetpotato.bear.house","foodTypes":"디저트/차/베이커리","latitude":35.309809318293,"longitude":128.983442811477,"bookYear":null,"nameKR":"고구마베어하우스","ribbonType":"신규","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":39661,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/uki_no_hana_","foodTypes":"바/주점","latitude":35.1585628380847,"longitude":129.064430305268,"bookYear":null,"nameKR":"유키노하나","ribbonType":"신규","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":39341,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hiya_atsu2/?hl=ko","foodTypes":"일식","latitude":37.2039744437243,"longitude":127.072192727906,"bookYear":null,"nameKR":"히야아츠 ","ribbonType":"신규","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":35856,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/horapa_seoul/","foodTypes":"아시아식","latitude":37.5793929163302,"longitude":126.971100761039,"bookYear":"2024","nameKR":"호라파","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":36257,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5513250013994,"longitude":127.076101717325,"bookYear":"2024","nameKR":"오스테리아현","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36488,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/ssoisamduck","foodTypes":"아시아식","latitude":35.8644935936166,"longitude":128.607468668907,"bookYear":"2024","nameKR":"쏘이삼덕","ribbonType":"신규","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":36512,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/trattoria_ilphenomenon","foodTypes":"이탈리아식","latitude":36.4863361688308,"longitude":127.303415930056,"bookYear":"2024","nameKR":"뜨라또리아 일 페노메논","ribbonType":"신규","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":36398,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cave_pasta.bar/","foodTypes":"이탈리아식","latitude":36.3523777454039,"longitude":127.373618700623,"bookYear":"2024","nameKR":"케이브파스타바","ribbonType":"신규","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":35449,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gion0415/","foodTypes":"한식(육류)","latitude":35.1651418390172,"longitude":129.158621350485,"bookYear":"2024","nameKR":"기온","ribbonType":"신규","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":35368,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/cenacolo_artforet","foodTypes":"이탈리아식","latitude":37.3937818483729,"longitude":126.633289319826,"bookYear":"2024","nameKR":"체나콜로아트포레","ribbonType":"신규","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":36565,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/stone_seogwipo","foodTypes":"이탈리아식","latitude":33.2515479103078,"longitude":126.505544137268,"bookYear":"2024","nameKR":"스톤","ribbonType":"신규","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":38049,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/stinkybacontruck","foodTypes":"미국식","latitude":37.2874453391147,"longitude":126.991574559571,"bookYear":"2024","nameKR":"스팅키베이컨트럭","ribbonType":"신규","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39410,"bookStatus":"커버","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5265016994693,"longitude":127.040868751654,"bookYear":null,"nameKR":"뻬를라","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":35882,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wolhwa_grilled/","foodTypes":"한식(육류)","latitude":37.5133146983831,"longitude":126.893528263865,"bookYear":"2024","nameKR":"월화갈비","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":35814,"bookStatus":"승인","websiteInstagram":"","foodTypes":"아시아식","latitude":37.4981354086584,"longitude":126.997962854215,"bookYear":"2024","nameKR":"퍼부어(서래마을점)","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":34737,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/a.troistemps.seoul/","foodTypes":"프랑스식","latitude":37.5633377534164,"longitude":126.9273747744,"bookYear":"2024","nameKR":"아트와떵","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":36166,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/wdj_gogizip","foodTypes":"한식(육류)","latitude":37.4510686603601,"longitude":126.902994835469,"bookYear":"2024","nameKR":"원다정왕소금구이","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":36510,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5221803249649,"longitude":126.919916550827,"bookYear":"2024","nameKR":"고우가","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":35939,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5666164557397,"longitude":126.838422664924,"bookYear":"2024","nameKR":"계시","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":34646,"bookStatus":"승인","websiteInstagram":"","foodTypes":"기타","latitude":37.5032910260927,"longitude":127.065495897888,"bookYear":"2024","nameKR":"르쏠","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":34502,"bookStatus":"승인","websiteInstagram":"","foodTypes":"중식","latitude":37.5128698613973,"longitude":127.124285768124,"bookYear":"2024","nameKR":"그랑차오","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":39046,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5713864751051,"longitude":126.973698680555,"bookYear":null,"nameKR":"포옹싸반미앤카페","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":39569,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5423089059261,"longitude":127.053190175134,"bookYear":null,"nameKR":"커피냅로스터스(성수점)","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":39292,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cuddle_coffee","foodTypes":"디저트/차/베이커리","latitude":35.8626638686429,"longitude":128.607802017869,"bookYear":null,"nameKR":"커들커피","ribbonType":"신규","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":39553,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hoon403","foodTypes":"일식","latitude":36.0951788771357,"longitude":128.428157060652,"bookYear":null,"nameKR":"심야식당구미호","ribbonType":"신규","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":39549,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lee_nak_san","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1122107275437,"longitude":129.039290121818,"bookYear":null,"nameKR":"이낙에산다(부산역직영점)","ribbonType":"신규","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":39542,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gogot_gwanganri/","foodTypes":"한식(육류)","latitude":35.1552472702648,"longitude":129.118702940985,"bookYear":null,"nameKR":"고갓","ribbonType":"신규","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":39494,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/blackgoat_dasan","foodTypes":"한식(육류)","latitude":37.6254940730134,"longitude":127.156119003301,"bookYear":null,"nameKR":"흑미담(다산점)","ribbonType":"신규","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38827,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rubyscafe.seoul","foodTypes":"기타","latitude":37.5460041478872,"longitude":127.047772141632,"bookYear":null,"nameKR":"리틀루비스카페(SM성수점)","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":39461,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mildf_?igsh=Z25xcjd1a3o4ZDJr","foodTypes":"기타","latitude":35.5389231633275,"longitude":129.338750272732,"bookYear":null,"nameKR":"밀디에프","ribbonType":"신규","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":36714,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.4357844766957,"longitude":126.700918972106,"bookYear":"2024","nameKR":"베이크샵워너비","ribbonType":"신규","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":39267,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/linconnu_seoul","foodTypes":"디저트/차/베이커리","latitude":37.5234695242392,"longitude":127.037997228703,"bookYear":null,"nameKR":"랑꼬뉴","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":34493,"bookStatus":"승인","websiteInstagram":null,"foodTypes":"기타","latitude":37.4637733062696,"longitude":127.106712517556,"bookYear":"2024","nameKR":"바이탈바이브","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":35958,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nubase_official","foodTypes":"기타","latitude":37.5182277112271,"longitude":127.020823504967,"bookYear":"2024","nameKR":"누베이스","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":36242,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/debbies_seoul/","foodTypes":"기타","latitude":37.5795532687287,"longitude":126.989150422174,"bookYear":"2024","nameKR":"데비스","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":36192,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/veggieweekend/","foodTypes":"기타","latitude":37.5344826645458,"longitude":127.007517811442,"bookYear":"2024","nameKR":"베지위켄드","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":39417,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sungsuk_2021","foodTypes":"바/주점","latitude":37.6485750124376,"longitude":126.876556771514,"bookYear":null,"nameKR":"고텐","ribbonType":"신규","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":37213,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/guuinongwon/","foodTypes":"한식(육류)","latitude":37.5395242756404,"longitude":127.092485388889,"bookYear":null,"nameKR":"구의농원","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":39480,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gobstardang_/reels/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6514808312455,"longitude":127.113609240336,"bookYear":null,"nameKR":"곱별당본점 ","ribbonType":"신규","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":39420,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_sugyeok","foodTypes":"한식(가금류)","latitude":37.3471045902731,"longitude":127.111148478986,"bookYear":null,"nameKR":"수계옥","ribbonType":"신규","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37530,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1864522022461,"longitude":128.162942081054,"bookYear":null,"nameKR":"백와","ribbonType":"신규","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":39207,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dokkaebi.house/","foodTypes":"한식(육류)","latitude":35.1848605876758,"longitude":129.075497000106,"bookYear":null,"nameKR":"도깨비집","ribbonType":"신규","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":36210,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/sakabar_sinkawa/","foodTypes":"바/주점","latitude":35.8655807245917,"longitude":128.617838719703,"bookYear":"2024","nameKR":"사카바신카와","ribbonType":"신규","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":38729,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.386697716642,"longitude":127.115411556939,"bookYear":null,"nameKR":"체르또","ribbonType":"신규","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":39245,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/happilyeverafter.pangyo","foodTypes":"이탈리아식","latitude":37.3905241939899,"longitude":127.091385307003,"bookYear":null,"nameKR":"HEA","ribbonType":"신규","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":39312,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5046375961419,"longitude":126.77675342106,"bookYear":null,"nameKR":"토담청국장","ribbonType":"신규","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":39095,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joogiyul.official/","foodTypes":"디저트/차/베이커리","latitude":37.5108031408009,"longitude":127.043201596217,"bookYear":null,"nameKR":"주기율","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":38762,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.2753399169713,"longitude":126.953617832615,"bookYear":null,"nameKR":"초밥본연","ribbonType":"신규","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37407,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/victoria_bakery_garden/","foodTypes":"디저트/차/베이커리","latitude":35.1287204100142,"longitude":129.008631525115,"bookYear":"2024","nameKR":"빅토리아베이커리가든","ribbonType":"신규","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":39297,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.3781291523733,"longitude":127.392988720623,"bookYear":null,"nameKR":"폭스카페X베이크샵수하","ribbonType":"신규","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":38852,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1897399036378,"longitude":128.80167598748,"bookYear":null,"nameKR":"양식당아치","ribbonType":"신규","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":38261,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/maison_joychoy/","foodTypes":"프랑스식","latitude":35.152118006969,"longitude":126.915532796582,"bookYear":"2024","nameKR":"메종조이초이","ribbonType":"신규","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":38262,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/pitt_restaurant/","foodTypes":"프랑스식","latitude":35.1373653175148,"longitude":126.916422231237,"bookYear":"2024","nameKR":"피트","ribbonType":"신규","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":38744,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/inccoffee__/","foodTypes":"디저트/차/베이커리","latitude":35.1694249627668,"longitude":126.888805470329,"bookYear":null,"nameKR":"인크커피(광주-기아챔피언스필드점)","ribbonType":"신규","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":39176,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeoui_hwaro/","foodTypes":"한식(육류)","latitude":37.5192120330535,"longitude":126.928582231249,"bookYear":null,"nameKR":"여의화로직영점","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":39175,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeoui_hwaro/ ","foodTypes":"한식(육류)","latitude":37.5207615513454,"longitude":126.929136630611,"bookYear":null,"nameKR":"여의화로","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":37665,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oncloudnine_jj?igsh=aTN2ZXc3cWFhemtw&utm_source=qr","foodTypes":"디저트/차/베이커리","latitude":37.4789725620181,"longitude":126.978093783426,"bookYear":null,"nameKR":"온클라우드나인","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":38997,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.0290416307028,"longitude":127.856613853686,"bookYear":null,"nameKR":"카페레이크249","ribbonType":"신규","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":37481,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4852878303815,"longitude":127.115119595215,"bookYear":null,"nameKR":"오마카세정준","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":37251,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.3105298169509,"longitude":128.985922251927,"bookYear":"2024","nameKR":"서리단","ribbonType":"신규","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":39033,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daehwa_corp/","foodTypes":"한식(육류)","latitude":35.8824229785031,"longitude":129.228543738141,"bookYear":null,"nameKR":"대화로(경주용황점)","ribbonType":"신규","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":38989,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7608988993878,"longitude":127.221960883537,"bookYear":null,"nameKR":"장원막국수","ribbonType":"신규","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":38724,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2638843015003,"longitude":127.03310232868,"bookYear":null,"nameKR":"달구운바람스타 (인계나혜석점)","ribbonType":"신규","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37955,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5034429519643,"longitude":127.053872034148,"bookYear":null,"nameKR":"선주가(선릉점)","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":37909,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5737878650638,"longitude":126.987907857685,"bookYear":null,"nameKR":"우심당","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":38813,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/danhh.gj","foodTypes":"한식(육류)","latitude":35.8355699421169,"longitude":129.211263186515,"bookYear":null,"nameKR":"단향회","ribbonType":"신규","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":35966,"bookStatus":"승인","websiteInstagram":"","foodTypes":"일식","latitude":37.2651381728083,"longitude":127.028685485358,"bookYear":"2024","nameKR":"카야마구로","ribbonType":"신규","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37209,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yakitori.jun","foodTypes":"일식","latitude":35.1684716902803,"longitude":128.062089991002,"bookYear":"2024","nameKR":"야키토리준","ribbonType":"신규","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":37307,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/kkwarere","foodTypes":"디저트/차/베이커리","latitude":35.1882733804562,"longitude":126.831296857721,"bookYear":"2024","nameKR":"꽈르르","ribbonType":"신규","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":36197,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/heominsu/","foodTypes":"디저트/차/베이커리","latitude":37.6117467554368,"longitude":126.72649225465,"bookYear":"2024","nameKR":"빵집우상향","ribbonType":"신규","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":36093,"bookStatus":"승인","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.3238878241858,"longitude":127.346304035277,"bookYear":"2024","nameKR":"향옥찻집","ribbonType":"신규","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":35705,"bookStatus":"승인","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5020701156006,"longitude":126.76792763595,"bookYear":"2024","nameKR":"반코","ribbonType":"신규","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":37574,"bookStatus":"승인","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2652473947203,"longitude":127.034993309844,"bookYear":"2024","nameKR":"인계해장","ribbonType":"신규","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36672,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/allday_udon","foodTypes":"일식","latitude":35.2142794999019,"longitude":126.846426652188,"bookYear":"2024","nameKR":"올데이우동","ribbonType":"신규","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":37644,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/poilde__carotte/","foodTypes":"디저트/차/베이커리","latitude":36.6199892958647,"longitude":127.406208561766,"bookYear":"2024","nameKR":"포이드캐롯","ribbonType":"신규","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":37070,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/yong_dune/","foodTypes":"한식(일반한식)","latitude":33.518214972194,"longitude":126.488634787753,"bookYear":"2024","nameKR":"용두네해장국","ribbonType":"신규","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":37411,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/oseme_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.2564505675538,"longitude":127.034817560952,"bookYear":"2024","nameKR":"오젬므","ribbonType":"신규","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38787,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5082883689579,"longitude":126.735148437087,"bookYear":null,"nameKR":"봉란옥","ribbonType":"신규","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":38691,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5190030559475,"longitude":127.022612686215,"bookYear":null,"nameKR":"도산회동(신사본점)","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":38723,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/unikcoffee_official","foodTypes":"디저트/차/베이커리","latitude":37.5663487192763,"longitude":126.927549672526,"bookYear":null,"nameKR":"유닉커피로스터스","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":37925,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.2631667914276,"longitude":127.035320291924,"bookYear":null,"nameKR":"이츠모라멘(수원점)","ribbonType":"신규","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38756,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/keungol_official","foodTypes":"한식(면류)","latitude":37.636102809629,"longitude":127.365440255276,"bookYear":null,"nameKR":"큰골칼국수","ribbonType":"신규","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":38765,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.8271658877998,"longitude":127.119705197268,"bookYear":null,"nameKR":"화덕으로간고등어","ribbonType":"신규","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":37960,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1801103404771,"longitude":126.816949787953,"bookYear":null,"nameKR":"오대한천냉면","ribbonType":"신규","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":37408,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/victoria_bakery_garden/","foodTypes":"디저트/차/베이커리","latitude":35.1201712234814,"longitude":129.042804968476,"bookYear":null,"nameKR":"빅토리아베이커리가든(캐슬점)","ribbonType":"신규","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":38717,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.385183475728,"longitude":126.644007327864,"bookYear":null,"nameKR":"우아시스(송도본점)","ribbonType":"신규","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":38013,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"유럽식","latitude":37.484573287516,"longitude":126.983403101465,"bookYear":null,"nameKR":"타파델디아","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":37324,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.266050727758,"longitude":127.03259369744,"bookYear":null,"nameKR":"정초손만두","ribbonType":"신규","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37958,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/esoope_buldang/","foodTypes":"기타","latitude":36.8197610766889,"longitude":127.10778351439,"bookYear":null,"nameKR":"카페이수페(불당점)","ribbonType":"신규","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38162,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dangchadang/","foodTypes":"디저트/차/베이커리","latitude":36.3311234848961,"longitude":126.608801405061,"bookYear":null,"nameKR":"당차당","ribbonType":"신규","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":38150,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pyg_kor","foodTypes":"디저트/차/베이커리","latitude":35.8646082890799,"longitude":128.60566458461,"bookYear":null,"nameKR":"피와이지","ribbonType":"신규","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":37632,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5481634403528,"longitude":126.92216581335,"bookYear":null,"nameKR":"찜이로닭","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":37372,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.556074489938,"longitude":126.91462968561,"bookYear":null,"nameKR":"카페공명(합정점서울작업실)","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":36726,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4865511753391,"longitude":126.929719265446,"bookYear":null,"nameKR":"이츠모라멘(신림점)","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":37824,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.6247341438957,"longitude":127.079560002845,"bookYear":null,"nameKR":"코타텍사스바베큐","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":37601,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.3192504477489,"longitude":128.998117529601,"bookYear":null,"nameKR":"바로덮밥&파스타","ribbonType":"신규","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":37870,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.8980502910213,"longitude":127.73871427797,"bookYear":null,"nameKR":"가우숲","ribbonType":"신규","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":37889,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3663779952744,"longitude":127.12734945093,"bookYear":null,"nameKR":"육일돈","ribbonType":"신규","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":36769,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5671458986322,"longitude":126.995451191045,"bookYear":null,"nameKR":"이프프커피","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":37706,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5820926424763,"longitude":126.888984720113,"bookYear":null,"nameKR":"룽탕성젠바오","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":37426,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.4824491667873,"longitude":129.418881999206,"bookYear":null,"nameKR":"삼복면옥","ribbonType":"신규","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":37521,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.6005519701322,"longitude":126.921519128693,"bookYear":null,"nameKR":"은평한마리닭","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":32860,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/haute.seoul/","foodTypes":"프랑스식","latitude":37.5562300858769,"longitude":126.911681198973,"bookYear":null,"nameKR":"오뜨서울","ribbonType":"신규","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":37541,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coldbutter_tanbang_official","foodTypes":"디저트/차/베이커리","latitude":36.3443269014723,"longitude":127.399719717709,"bookYear":null,"nameKR":"콜드버터베이크샵(탄방점)","ribbonType":"신규","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":37509,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kingpork_offcial","foodTypes":"한식(육류)","latitude":37.5080394023993,"longitude":127.104053141156,"bookYear":null,"nameKR":"임금돼지(잠실점)","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":37496,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.4957978625958,"longitude":127.062741656284,"bookYear":null,"nameKR":"피자느반","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":37492,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5837344040736,"longitude":126.891670032714,"bookYear":null,"nameKR":"늘품육회","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":37487,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jungjeom2024/","foodTypes":"한식(육류)","latitude":37.6004342552927,"longitude":126.889886731456,"bookYear":null,"nameKR":"정점","ribbonType":"신규","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":37447,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.477879276806,"longitude":126.987327408715,"bookYear":null,"nameKR":"더뱅어핫도그","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":37425,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wangcho_bbq/","foodTypes":"한식(가금류)","latitude":37.5655020961448,"longitude":127.035803581,"bookYear":null,"nameKR":"왕초바베큐","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":37122,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5231170407774,"longitude":126.864634231827,"bookYear":null,"nameKR":"오가네돼지국밥(목동법원점)","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":37404,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.0738452498414,"longitude":129.015820946436,"bookYear":null,"nameKR":"우연지","ribbonType":"신규","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":37203,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gentlekitchen_/","foodTypes":"기타","latitude":37.5663316572964,"longitude":126.919964387949,"bookYear":null,"nameKR":"젠틀키친","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37376,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/namsansirloin/","foodTypes":"한식(육류)","latitude":37.5597720025011,"longitude":126.985023469804,"bookYear":null,"nameKR":"남산등심","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":37298,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5104218436877,"longitude":127.055669203417,"bookYear":null,"nameKR":"남천1번국밥","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":37303,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5008080109033,"longitude":126.950570031068,"bookYear":null,"nameKR":"라메","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":37315,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/snugyongsan","foodTypes":"디저트/차/베이커리","latitude":37.538962823278,"longitude":126.959156808632,"bookYear":null,"nameKR":"스너그(용산점)","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37163,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tealroasters","foodTypes":"디저트/차/베이커리","latitude":37.5576844654565,"longitude":126.928136734708,"bookYear":null,"nameKR":"틸로스터스","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":37294,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/therealtownbusiness/","foodTypes":"중남미식","latitude":37.595210898234,"longitude":127.085606482423,"bookYear":null,"nameKR":"리얼타운비즈니스타코","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":37291,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/___muguriya","foodTypes":"한식(어패류)","latitude":37.5380767541768,"longitude":126.969865080417,"bookYear":null,"nameKR":"머구리야","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37236,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5223503946319,"longitude":127.02431292218,"bookYear":null,"nameKR":"그라도스","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37246,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5791685656575,"longitude":126.923893047343,"bookYear":null,"nameKR":"마닐로우","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":37234,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":37.5257239181178,"longitude":127.038009684938,"bookYear":null,"nameKR":"코너피자조인트 압구정","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37199,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4667454838323,"longitude":126.886718830722,"bookYear":null,"nameKR":"빈스커피컴퍼니","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":35854,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/masamadre.bakeshop/","foodTypes":"디저트/차/베이커리","latitude":37.5804439635136,"longitude":126.969115309952,"bookYear":"2024","nameKR":"마사마드레베이크샵","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":36930,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.526993635766,"longitude":126.894135680365,"bookYear":null,"nameKR":"이치돈부리","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37136,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4842856391064,"longitude":126.899282125859,"bookYear":null,"nameKR":"제일관(구로디지털점)","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":36776,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/brevillekorea/","foodTypes":"디저트/차/베이커리","latitude":37.522194471999,"longitude":127.024980297931,"bookYear":null,"nameKR":"브레빌카페&스토어","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37024,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.493110486689,"longitude":127.084232260259,"bookYear":null,"nameKR":"솔트플러스39","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":37068,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5706253564255,"longitude":126.976011435792,"bookYear":null,"nameKR":"칠돈가(광화문점)","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":37069,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5257032589667,"longitude":126.961908986221,"bookYear":null,"nameKR":"칠돈가(용산점)","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36958,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5057520040906,"longitude":127.025199318349,"bookYear":null,"nameKR":"유케집(논현점)","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":36849,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5104486647966,"longitude":127.080562243241,"bookYear":null,"nameKR":"정담옥","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36583,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/mitbord.seoul/","foodTypes":"기타","latitude":37.5408654463888,"longitude":127.047850218608,"bookYear":null,"nameKR":"밋보어서울","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36044,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/2do_aging_namseong/","foodTypes":"한식(육류)","latitude":37.4857870455174,"longitude":126.969451801121,"bookYear":null,"nameKR":"2도에이징(남성역점)","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":36199,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/honeybeeseoul","foodTypes":"디저트/차/베이커리","latitude":37.5350929677353,"longitude":127.000940328088,"bookYear":"2024","nameKR":"허니비서울","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":36617,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5744668688197,"longitude":126.983552182559,"bookYear":null,"nameKR":"안녕브릭샌드","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":36345,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/focal.point.official/","foodTypes":"디저트/차/베이커리","latitude":37.5542457145629,"longitude":126.968794192644,"bookYear":"2024","nameKR":"포컬포인트","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":36452,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5523787788854,"longitude":126.920569825328,"bookYear":null,"nameKR":"만평우동(홍대점)","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36643,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/kushibar_seoul","foodTypes":"일식","latitude":37.5563424349889,"longitude":127.010984511239,"bookYear":null,"nameKR":"쿠시바","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":36201,"bookStatus":"승인","websiteInstagram":"https://instagram.com/kotton.seoul","foodTypes":"디저트/차/베이커리","latitude":37.5747420354664,"longitude":126.984923231338,"bookYear":"2024","nameKR":"코튼서울","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":36073,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/scooper_gelato/","foodTypes":"디저트/차/베이커리","latitude":37.5332380352776,"longitude":126.973698393164,"bookYear":"2024","nameKR":"스쿠퍼젤라또(삼각지점)","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36387,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/die_synthese/","foodTypes":"디저트/차/베이커리","latitude":37.5479201849315,"longitude":127.044137696679,"bookYear":"2024","nameKR":"디진테제하이카페","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36615,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/waa.seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5462046110775,"longitude":127.049328210947,"bookYear":null,"nameKR":"와아","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35200,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/jaha_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5728686910564,"longitude":126.97096076384,"bookYear":"2024","nameKR":"자하","ribbonType":"신규","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":34258,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/baguettine.seoul/","foodTypes":"디저트/차/베이커리","latitude":37.479965879262,"longitude":126.999043969527,"bookYear":"2024","nameKR":"바게틴","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":35875,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/nokorika_sadang/","foodTypes":"바/주점","latitude":37.4774279924988,"longitude":126.977777113343,"bookYear":"2024","nameKR":"노코리카","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":34331,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/coeur.coffeestand/","foodTypes":"디저트/차/베이커리","latitude":37.4932227490529,"longitude":127.016187316348,"bookYear":"2024","nameKR":"코어커피스탠드","ribbonType":"신규","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":29500,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/passageseoul/","foodTypes":"프랑스식","latitude":37.5459144876248,"longitude":126.943811380412,"bookYear":null,"nameKR":"파사주","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":39278,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.deffee/","foodTypes":"디저트/차/베이커리","latitude":37.4447476303686,"longitude":127.140320131654,"bookYear":null,"nameKR":"디피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":39258,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/asiatogo7851?igsh=cmttZ2xscnA2dHM0","foodTypes":"중식","latitude":35.1426212906637,"longitude":129.062059451906,"bookYear":null,"nameKR":"아시아투고","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":39151,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gn_goldcow/","foodTypes":"한식(육류)","latitude":37.7665701801174,"longitude":128.904448063087,"bookYear":null,"nameKR":"강릉한우금송아지","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":37468,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.560441309643,"longitude":127.035736080166,"bookYear":null,"nameKR":"스시도쿠카미동(왕십리본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":37470,"bookStatus":"커버","websiteInstagram":"","foodTypes":"일식","latitude":37.560441309643,"longitude":127.035736080166,"bookYear":null,"nameKR":"스시도쿠겐(왕십리본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":39646,"bookStatus":"비활성","websiteInstagram":"ddhleejh","foodTypes":"일식","latitude":37.4784470841365,"longitude":126.956556553762,"bookYear":null,"nameKR":"텐동요츠야","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":35477,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_dturtle/","foodTypes":"디저트/차/베이커리","latitude":37.5815293759116,"longitude":126.999648247743,"bookYear":null,"nameKR":"디터틀","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":5072,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.50178,"longitude":127.0274,"bookYear":null,"nameKR":"봉우화로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":35363,"bookStatus":"커버","websiteInstagram":"https://instagram.com/swgh_official","foodTypes":"디저트/차/베이커리","latitude":35.1761007071334,"longitude":129.196568604772,"bookYear":null,"nameKR":"수월경화","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":36868,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/whyden.coffee","foodTypes":"디저트/차/베이커리","latitude":37.5475565627229,"longitude":127.054447732155,"bookYear":null,"nameKR":"와이덴성수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":38815,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/yukmokwon","foodTypes":"한식(육류)","latitude":37.4924272855457,"longitude":127.030921234166,"bookYear":null,"nameKR":"육목원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":36702,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9813668129256,"longitude":128.556820069835,"bookYear":null,"nameKR":"우인정","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":39660,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bar_saol/","foodTypes":"바/주점","latitude":37.5531336368108,"longitude":126.96657709302,"bookYear":null,"nameKR":"세올","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":39204,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oven_brothers/","foodTypes":"디저트/차/베이커리","latitude":36.3323506696544,"longitude":127.409241360074,"bookYear":null,"nameKR":"오븐브라더스","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":38772,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pimfy_itaewon?igsh=MTJyenRmNzR4dm1xcQ==","foodTypes":"기타","latitude":37.5343987025279,"longitude":126.988210990583,"bookYear":null,"nameKR":"핌피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":39564,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myungingalbi/","foodTypes":"한식(육류)","latitude":37.617439353054,"longitude":127.028455452166,"bookYear":null,"nameKR":"명인갈비(미아점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":39711,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/katsu_maison/","foodTypes":"일식","latitude":37.5302768374452,"longitude":126.899336959694,"bookYear":null,"nameKR":"카츠메종","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":39761,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/frenchie_jinju","foodTypes":"프랑스식","latitude":35.172419907534,"longitude":128.06742620746,"bookYear":null,"nameKR":"프랜치","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":39760,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1950526016954,"longitude":128.072558701874,"bookYear":null,"nameKR":"산청흑돼지","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":39557,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/elabore_seoul","foodTypes":"디저트/차/베이커리","latitude":37.5224063791966,"longitude":127.043133284887,"bookYear":null,"nameKR":"엘라보레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":9288,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5287308923987,"longitude":127.035797311464,"bookYear":null,"nameKR":"장스테이크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39649,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/neulsomdang/","foodTypes":"디저트/차/베이커리","latitude":37.4709229218852,"longitude":126.784412861105,"bookYear":null,"nameKR":"늘솜당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":39650,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/neulsomdang_2nd/","foodTypes":"디저트/차/베이커리","latitude":37.4115076838609,"longitude":126.722466503581,"bookYear":null,"nameKR":"늘솜당(인천점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":812,"bookStatus":"커버","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.61931880270452,"longitude":126.9214449234175,"bookYear":null,"nameKR":"청일집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":39721,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5238640247561,"longitude":126.924142368161,"bookYear":null,"nameKR":"우상화로구이(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":39722,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3239146437067,"longitude":127.096272503176,"bookYear":null,"nameKR":"우상화로구이(수지구청점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":39723,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woosang_official/","foodTypes":"한식(육류)","latitude":37.3903256677075,"longitude":127.092353055925,"bookYear":null,"nameKR":"우상화로구이(서판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":39719,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woosang_official/","foodTypes":"한식(육류)","latitude":37.5568874407015,"longitude":126.837392387854,"bookYear":null,"nameKR":"우상화로구이(발산역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":39720,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woosang_121/","foodTypes":"한식(육류)","latitude":37.3613376767451,"longitude":127.105543131014,"bookYear":null,"nameKR":"우상화로구이(분당정자점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":39718,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woosang_bojeong/","foodTypes":"한식(육류)","latitude":37.3212931303188,"longitude":127.112657234897,"bookYear":null,"nameKR":"우상화로구이(죽전보정점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":39717,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gery_prince104/","foodTypes":"디저트/차/베이커리","latitude":37.3890111897813,"longitude":126.634032775089,"bookYear":null,"nameKR":"게리프린스","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":39713,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/272nszib/?igsh=MWNiZ3F2bzJ3b2puMw%3D%3D","foodTypes":"한식(육류)","latitude":37.6006338787016,"longitude":126.65328006267,"bookYear":null,"nameKR":"272냉삼집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":39712,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chosim_apgujeong/","foodTypes":"바/주점","latitude":37.5269843828037,"longitude":127.038403113006,"bookYear":null,"nameKR":"초심","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39710,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ok2_noodle/","foodTypes":"한식(면류)","latitude":36.6079128845523,"longitude":126.416083395455,"bookYear":null,"nameKR":"옥경이네","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":39709,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/local.takoyaki","foodTypes":"바/주점","latitude":37.5555240682551,"longitude":127.077262619893,"bookYear":null,"nameKR":"로컬타코야끼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":39160,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.1091590363668,"longitude":128.420434502533,"bookYear":null,"nameKR":"펭귄요리공장","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":39209,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/zanzan_mr/","foodTypes":"바/주점","latitude":37.4327879518931,"longitude":127.132082307847,"bookYear":null,"nameKR":"이자카야잔잔(모란점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":39248,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/atelier.smida/","foodTypes":"디저트/차/베이커리","latitude":35.1752187014038,"longitude":129.106402225233,"bookYear":null,"nameKR":"아틀리에스미다","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":39261,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yakitori.konomi","foodTypes":"일식","latitude":37.2327819579037,"longitude":127.189034026946,"bookYear":null,"nameKR":"야키토리&오뎅코노미","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":39273,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jejusongaktong/","foodTypes":"한식(어패류)","latitude":33.2067772259681,"longitude":126.289973082566,"bookYear":null,"nameKR":"송악통갈치","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":39310,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/outro_coffee/","foodTypes":"디저트/차/베이커리","latitude":36.6505811057411,"longitude":127.546299947156,"bookYear":null,"nameKR":"아우트로커피","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":39325,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/underhill.cafe/","foodTypes":"디저트/차/베이커리","latitude":36.7995695066904,"longitude":127.014019870554,"bookYear":null,"nameKR":"언더힐","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":39340,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/neoh_jeju","foodTypes":"디저트/차/베이커리","latitude":33.2780754943062,"longitude":126.246398530469,"bookYear":null,"nameKR":"너흐","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":39402,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/better_sweet_cake/","foodTypes":"디저트/차/베이커리","latitude":37.5086530376404,"longitude":126.72892731107,"bookYear":null,"nameKR":"베러스윗","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":39506,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gontrancherrier.official_kr/","foodTypes":"디저트/차/베이커리","latitude":37.4297945568098,"longitude":126.990295584667,"bookYear":null,"nameKR":"곤트란쉐리에(과천점) ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":39627,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pickles.busan/","foodTypes":"기타","latitude":35.1550518686378,"longitude":129.064157999549,"bookYear":null,"nameKR":"피클스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":39705,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/inson_seoul?igsh=OWh1cWVyNGNvamo4&utm_source=qr","foodTypes":"한식(어패류)","latitude":37.5498633152298,"longitude":126.977806096934,"bookYear":null,"nameKR":"인손서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31596,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/seoryung_seoul","foodTypes":"한식(면류)","latitude":37.5585411616418,"longitude":126.975411166003,"bookYear":null,"nameKR":"서령","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":39707,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/around_bagel/","foodTypes":"디저트/차/베이커리","latitude":35.7548208308473,"longitude":128.64870437254,"bookYear":null,"nameKR":"어라운드베이글","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":27575,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/couscous_tunisian_home_kitchen/","foodTypes":"아프리카식","latitude":37.57782,"longitude":126.9711,"bookYear":null,"nameKR":"꾸스꾸스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":39587,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gurae.ok/","foodTypes":"한식(육류)","latitude":37.6436066755883,"longitude":126.623431880202,"bookYear":null,"nameKR":"구래옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":37796,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.4818924398904,"longitude":126.949921758362,"bookYear":null,"nameKR":"피자파쪼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":35708,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/mrs.pberry","foodTypes":"디저트/차/베이커리","latitude":36.4736395773064,"longitude":127.13712102727,"bookYear":null,"nameKR":"미세스피베리","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":39619,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistro_enso/","foodTypes":"바/주점","latitude":35.8161473377231,"longitude":127.142817952298,"bookYear":null,"nameKR":"엔소","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":39667,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5608155702969,"longitude":126.924747112012,"bookYear":null,"nameKR":"영지인","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":39192,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dodam_pasta/ ","foodTypes":"이탈리아식","latitude":35.2569384530326,"longitude":128.635685915728,"bookYear":null,"nameKR":"도담파스타","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":39620,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/someonesbreadntable/","foodTypes":"디저트/차/베이커리","latitude":37.6385245933423,"longitude":126.664414216306,"bookYear":null,"nameKR":"썸원스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":39634,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yakitori_dongjak?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==","foodTypes":"일식","latitude":36.6157215750452,"longitude":127.516391860137,"bookYear":null,"nameKR":"야키토리동작(청주동남지구점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":38295,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2196428825594,"longitude":128.675495049949,"bookYear":null,"nameKR":"제일횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":39668,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/samsoon.official?igsh=MTRpbTYzNDZhcW45ag%3D%3D&utm_source=qr","foodTypes":"이탈리아식","latitude":37.255574628955,"longitude":127.073356770213,"bookYear":null,"nameKR":"쌤순","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39672,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dottgan/","foodTypes":"한식(육류)","latitude":35.1048967171145,"longitude":128.924539994878,"bookYear":null,"nameKR":"돗간","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":38195,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1817613857786,"longitude":128.560946028421,"bookYear":null,"nameKR":"오리천하(마산본점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":39685,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ubakeshop?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==","foodTypes":"디저트/차/베이커리","latitude":37.2376624265408,"longitude":127.189230133476,"bookYear":null,"nameKR":"유베이크샵","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":39690,"bookStatus":"비활성","websiteInstagram":"http://www.instargram.com/thanks_to_tea","foodTypes":"디저트/차/베이커리","latitude":37.4923522908694,"longitude":126.58100832145,"bookYear":null,"nameKR":"차덕분","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":39700,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mushroomhouse_127/","foodTypes":"한식(육류)","latitude":37.5467163859063,"longitude":127.044069430288,"bookYear":null,"nameKR":"버섯집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":38358,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8758918637691,"longitude":128.639327172412,"bookYear":null,"nameKR":"효목골막창","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":38342,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8598232340002,"longitude":128.646278750073,"bookYear":null,"nameKR":"천일석쇠구이전문점(본점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":38742,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7506342832673,"longitude":127.701080333573,"bookYear":null,"nameKR":"참치마","ribbonType":"정보없음","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":38658,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8285446188087,"longitude":127.173997474462,"bookYear":null,"nameKR":"추억의광장","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38363,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2156452657001,"longitude":126.846529079331,"bookYear":null,"nameKR":"노란간판창평국밥","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38565,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3526107594909,"longitude":127.388658829358,"bookYear":null,"nameKR":"우두령","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":38391,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4740127796263,"longitude":126.869364351226,"bookYear":null,"nameKR":"철산곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":38920,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/orai_anyang/ ","foodTypes":"바/주점","latitude":37.3906208865489,"longitude":126.953893695784,"bookYear":null,"nameKR":"오라이 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":18753,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"노천드럼통","ribbonType":"정보없음","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":39608,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4960392633805,"longitude":126.776727565817,"bookYear":null,"nameKR":"굴타령조개타령","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":39101,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6240554066051,"longitude":126.835350722488,"bookYear":null,"nameKR":"군산항회직판장(행신점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":38892,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/stroll_inthenight/ ","foodTypes":"바/주점","latitude":37.5341822052637,"longitude":126.736713954549,"bookYear":null,"nameKR":"저녘산책","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":38289,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5326231015567,"longitude":126.651181684661,"bookYear":null,"nameKR":"곱창남","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":38353,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5725778840771,"longitude":126.974212073076,"bookYear":null,"nameKR":"황금정 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":39687,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dongnamhall/","foodTypes":"한식(육류)","latitude":37.5034670296556,"longitude":127.039984570493,"bookYear":null,"nameKR":"동남회관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":34346,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/miraehall/","foodTypes":"한식(육류)","latitude":37.53463223588,"longitude":127.005831785916,"bookYear":null,"nameKR":"미래회관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":39706,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/inson_jeju","foodTypes":"한식(어패류)","latitude":33.5225471869147,"longitude":126.855656633921,"bookYear":null,"nameKR":"인손","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":39704,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ola_cucina/","foodTypes":"이탈리아식","latitude":37.2190314716414,"longitude":126.955490860538,"bookYear":null,"nameKR":"올라쿠치나in봉담","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39703,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/steady.coffee/","foodTypes":"디저트/차/베이커리","latitude":35.8348571147048,"longitude":128.680830559152,"bookYear":null,"nameKR":"스테디커피","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":39701,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woong_ine/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.8164430886775,"longitude":127.096109473108,"bookYear":null,"nameKR":"웅이네","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":39702,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5222764916286,"longitude":127.13323018124,"bookYear":null,"nameKR":"리아네춘천닭갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":39698,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nicetime.kr/","foodTypes":"기타","latitude":37.3952040049379,"longitude":126.631287119931,"bookYear":null,"nameKR":"나이스타임","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":39697,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/rafre_fruit","foodTypes":"디저트/차/베이커리","latitude":37.5472524943165,"longitude":127.039443386345,"bookYear":null,"nameKR":"라프레플루트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":39694,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oshi_ilsan/?igsh=MXNoNjJoejAzbXRobA%3D%3D&utm_source=qr","foodTypes":"일식","latitude":37.6575928102258,"longitude":126.772944560294,"bookYear":null,"nameKR":"오시(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":31871,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushidoku2014/","foodTypes":"일식","latitude":37.560950003452,"longitude":127.036061946811,"bookYear":null,"nameKR":"스시도쿠왕십리본점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":39693,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oshi_official__/","foodTypes":"일식","latitude":37.5552281238649,"longitude":126.907724042936,"bookYear":null,"nameKR":"오시(망원본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":39692,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8367250687987,"longitude":127.169708273609,"bookYear":null,"nameKR":"감자탕나라","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":39691,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/freshvox_sandwich/","foodTypes":"기타","latitude":37.479966429878,"longitude":126.88263620024,"bookYear":null,"nameKR":"프레쉬복스(가산점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":39689,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sky_bean_","foodTypes":"디저트/차/베이커리","latitude":37.647089935891,"longitude":126.671195011593,"bookYear":null,"nameKR":"하늘콩","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":39688,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.2797503435573,"longitude":127.443000404725,"bookYear":null,"nameKR":"랑토스레","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":39684,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rimi_room/","foodTypes":"기타","latitude":35.1553790199662,"longitude":129.118079495814,"bookYear":null,"nameKR":"유어타입","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":39605,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baekbae_fb/?igshid=OGQ5ZDc2ODk2ZA%3D%3D","foodTypes":"한식(면류)","latitude":35.1911852719691,"longitude":128.802026049648,"bookYear":null,"nameKR":"한식교(김해장유점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":39683,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/busansik/","foodTypes":"한식(육류)","latitude":35.1554713854325,"longitude":129.118254818597,"bookYear":null,"nameKR":"부산식","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":39643,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakal_sinchang","foodTypes":"한식(면류)","latitude":35.1964796686864,"longitude":126.840547974619,"bookYear":null,"nameKR":"바칼(본점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":5835,"bookStatus":"커버","websiteInstagram":"","foodTypes":"중식","latitude":37.47977,"longitude":127.0429,"bookYear":null,"nameKR":"브루스리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":49,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.55837,"longitude":126.9373,"bookYear":null,"nameKR":"고바우","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":223,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.579976,"longitude":127.003922,"bookYear":null,"nameKR":"디마떼오","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":29467,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":37.573866239384124,"longitude":126.9919989373204,"bookYear":null,"nameKR":"니코키친","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":3899,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.526201,"longitude":126.931078,"bookYear":null,"nameKR":"희정식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":34128,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5552233141176,"longitude":127.075594139455,"bookYear":null,"nameKR":"온미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":5745,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.49607,"longitude":126.999731,"bookYear":null,"nameKR":"브루클린더버거조인트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":32236,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5073694628793,"longitude":126.885387464785,"bookYear":null,"nameKR":"우월소곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":6573,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"미국식","latitude":37.509304,"longitude":127.060909,"bookYear":null,"nameKR":"알로하테이블","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":31225,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/marione_ss/","foodTypes":"이탈리아식","latitude":37.5397433768049,"longitude":127.056910522064,"bookYear":null,"nameKR":"마리오네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32019,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yasunoya_jiro","foodTypes":"일식","latitude":37.5384919166036,"longitude":126.968080121147,"bookYear":null,"nameKR":"야스노야지로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31126,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tomate_woodoven/","foodTypes":"이탈리아식","latitude":37.5069876334496,"longitude":126.963691455768,"bookYear":null,"nameKR":"토마트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":9067,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.54211,"longitude":126.9522,"bookYear":null,"nameKR":"원조갈매기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29257,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.504395,"longitude":126.8917,"bookYear":null,"nameKR":"강촌숯불닭갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":29262,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_song_chef/","foodTypes":"중식","latitude":37.519298,"longitude":127.019605,"bookYear":null,"nameKR":"송쉐프","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":31786,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4962758238801,"longitude":127.033957316936,"bookYear":null,"nameKR":"국제수입식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":29000,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.525009,"longitude":126.89481,"bookYear":null,"nameKR":"더함한우골","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":30236,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dongbangmisik/","foodTypes":"중식","latitude":37.5062631802423,"longitude":127.035866428428,"bookYear":null,"nameKR":"동방미식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":13461,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oliveave.co.kr/","foodTypes":"이탈리아식","latitude":37.477655,"longitude":126.982223,"bookYear":null,"nameKR":"올리브에비뉴","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":38288,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/sowolpot_hbc","foodTypes":"한식(일반한식)","latitude":37.5450553697544,"longitude":126.985023019641,"bookYear":null,"nameKR":"소월솥밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":39272,"bookStatus":"커버","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5261483522748,"longitude":126.962751005883,"bookYear":null,"nameKR":"파브리키친","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":34270,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/trago_bistro/","foodTypes":"이탈리아식","latitude":37.5447477438441,"longitude":126.948258201287,"bookYear":null,"nameKR":"트라고","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29866,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.53914149590237,"longitude":127.06626440648117,"bookYear":null,"nameKR":"매운향솥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":22047,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.497028,"longitude":126.864534,"bookYear":null,"nameKR":"실크로드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":28262,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/laplanque_seoul/","foodTypes":"프랑스식","latitude":37.533309,"longitude":126.993509,"bookYear":null,"nameKR":"라플랑끄","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":29990,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.539718348076555,"longitude":126.96940435358498,"bookYear":null,"nameKR":"열정도고깃집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36152,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/ilvecchio_seoul","foodTypes":"이탈리아식","latitude":37.5763802659537,"longitude":126.894702467922,"bookYear":null,"nameKR":"일베키오","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":37476,"bookStatus":"커버","websiteInstagram":null,"foodTypes":"일식","latitude":37.5003393446141,"longitude":127.040054488697,"bookYear":null,"nameKR":"마구로젠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":34365,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5190567060549,"longitude":127.023783138208,"bookYear":null,"nameKR":"소양탐정","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37651,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/generous_duckoo/","foodTypes":"중식","latitude":37.5252591861539,"longitude":127.040172451405,"bookYear":null,"nameKR":"덕후선생","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31597,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/euljidarak/","foodTypes":"이탈리아식","latitude":37.5637445047995,"longitude":126.991427412426,"bookYear":null,"nameKR":"을지다락","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":34282,"bookStatus":"커버","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5466198167851,"longitude":127.043290153654,"bookYear":null,"nameKR":"꾸아(성수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32056,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5191210876079,"longitude":127.020121061287,"bookYear":null,"nameKR":"신비갈비살","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37151,"bookStatus":"커버","websiteInstagram":"http://instagram.com/100years_noodles","foodTypes":"한식(면류)","latitude":37.5242126602779,"longitude":126.859391234407,"bookYear":null,"nameKR":"목동백년국수 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34478,"bookStatus":"커버","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5602455642568,"longitude":126.836884719112,"bookYear":null,"nameKR":"오적회관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":35443,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5601356822454,"longitude":126.837697932979,"bookYear":null,"nameKR":"일편등심(마곡발산점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":34035,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/jungmyeon__/","foodTypes":"한식(면류)","latitude":37.5455037246349,"longitude":127.068628822449,"bookYear":null,"nameKR":"정면","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36835,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/magenta__bar","foodTypes":"이탈리아식","latitude":37.5463287648769,"longitude":126.941216094498,"bookYear":null,"nameKR":"마젠타","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":38690,"bookStatus":"커버","websiteInstagram":"http://www.instagram.com/radici_seoul","foodTypes":"이탈리아식","latitude":37.4984765462412,"longitude":126.999514176857,"bookYear":null,"nameKR":"라디치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":37397,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/aroga_kr/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4660352420573,"longitude":126.896264072547,"bookYear":null,"nameKR":"아로가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":32953,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/the_eeleel/","foodTypes":"한식(민물어패류)","latitude":37.5205955456524,"longitude":127.029681739673,"bookYear":null,"nameKR":"더일","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31586,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/restaurant_bawi/","foodTypes":"이탈리아식","latitude":37.5372288776059,"longitude":127.001178979441,"bookYear":null,"nameKR":"레스토랑바위","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":37123,"bookStatus":"커버","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.4862262142568,"longitude":126.992503987151,"bookYear":null,"nameKR":"볼레티","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":31904,"bookStatus":"커버","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5791598068788,"longitude":126.986546213919,"bookYear":null,"nameKR":"애호락","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":39462,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/jincheongsoo/","foodTypes":"한식(면류)","latitude":37.5230539498747,"longitude":127.045246821503,"bookYear":null,"nameKR":"진청수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":29284,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/eltapeo0708/","foodTypes":"유럽식","latitude":37.476844,"longitude":126.961775,"bookYear":null,"nameKR":"엘따뻬오","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":37217,"bookStatus":"커버","websiteInstagram":"","foodTypes":"중식","latitude":37.5109823973192,"longitude":127.058205573267,"bookYear":null,"nameKR":"이화원오크우드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":36584,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/sageandbutter.seoul/","foodTypes":"이탈리아식","latitude":37.5239720541697,"longitude":127.032419816463,"bookYear":null,"nameKR":"세이지앤버터","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37149,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/josunpalace/","foodTypes":"중식","latitude":37.5028813541774,"longitude":127.041356540268,"bookYear":null,"nameKR":"더그레이트홍연","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":39253,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/kigen_soba/","foodTypes":"일식","latitude":37.5247769123574,"longitude":127.040603772323,"bookYear":null,"nameKR":"소바키겐","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":36396,"bookStatus":"커버","websiteInstagram":"","foodTypes":"일식","latitude":37.5647073799978,"longitude":126.994037176372,"bookYear":null,"nameKR":"도우또","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":33209,"bookStatus":"커버","websiteInstagram":"","foodTypes":"중식","latitude":37.5021549058648,"longitude":127.125168223705,"bookYear":null,"nameKR":"에핑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":37786,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/suin_seoul/","foodTypes":"한식(가금류)","latitude":37.5747847174107,"longitude":126.986190073207,"bookYear":null,"nameKR":"수인","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":38850,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5104309589729,"longitude":127.022801926675,"bookYear":null,"nameKR":"강남설렁탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":37419,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/shin.gwangdong/","foodTypes":"중식","latitude":37.564632570577,"longitude":127.018999725364,"bookYear":null,"nameKR":"신광동","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":39271,"bookStatus":"커버","websiteInstagram":"","foodTypes":"기타","latitude":37.5254955370963,"longitude":127.037938072114,"bookYear":null,"nameKR":" 히키니쿠토고메도산","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39274,"bookStatus":"커버","websiteInstagram":"","foodTypes":"기타","latitude":37.5319396161547,"longitude":126.97296062481,"bookYear":null,"nameKR":"아치서울(용산점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37049,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/youngdong_gomtang/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5089469835135,"longitude":127.023820647442,"bookYear":null,"nameKR":"영동곰탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":30108,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oouchi__/","foodTypes":"일식","latitude":37.484172850929646,"longitude":127.01325582980871,"bookYear":null,"nameKR":"오우치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":39155,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/truffle_di_alba/","foodTypes":"이탈리아식","latitude":37.5341665277593,"longitude":127.002861453913,"bookYear":null,"nameKR":"트러플디알바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":38809,"bookStatus":"커버","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5997123185996,"longitude":126.919882555261,"bookYear":null,"nameKR":"아로이타이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":39269,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5220163808659,"longitude":126.930048684376,"bookYear":null,"nameKR":"여운관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":37111,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5715419143307,"longitude":127.080725014456,"bookYear":null,"nameKR":"장수닭한마리손칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":27990,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.56734,"longitude":126.92888,"bookYear":null,"nameKR":"에노테카오토","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":37395,"bookStatus":"커버","websiteInstagram":"","foodTypes":"중식","latitude":37.5107067799275,"longitude":127.094231575378,"bookYear":null,"nameKR":"심포니오브차이나","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":37322,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5646598422505,"longitude":126.989396953863,"bookYear":null,"nameKR":"이조식탁","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":1069,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.498269,"longitude":127.025213,"bookYear":null,"nameKR":"맛있는이야기노랑저고리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":5111,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.56694,"longitude":126.9974,"bookYear":null,"nameKR":"산골면옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"","status":"ACTIVE"},
        {"id":5940,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5018487681441,"longitude":127.066014410681,"bookYear":null,"nameKR":"광양불고기본가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":29212,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5280561383743,"longitude":127.031675252376,"bookYear":null,"nameKR":"피양옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":28541,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.581254,"longitude":126.888871,"bookYear":null,"nameKR":"배꼽집(상암점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":28461,"bookStatus":"커버","websiteInstagram":"","foodTypes":"아시아식","latitude":37.509337,"longitude":127.024496,"bookYear":null,"nameKR":"반피차이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":39682,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/spring_221120/","foodTypes":"기타","latitude":37.5494338978392,"longitude":126.920348772086,"bookYear":null,"nameKR":"서교동의봄","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":39459,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.2102656623158,"longitude":129.075380711275,"bookYear":null,"nameKR":"마루한","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":39679,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/37.5signature_seongsu_/","foodTypes":"기타","latitude":37.5433806911887,"longitude":127.054079883066,"bookYear":null,"nameKR":"37.5시그니처성수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":39681,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kyoomo.ramen/","foodTypes":"일식","latitude":37.5527409401053,"longitude":127.010278824939,"bookYear":null,"nameKR":"쿄오모라멘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":39680,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chefjms/","foodTypes":"일식","latitude":37.5063601603768,"longitude":127.09065432655,"bookYear":null,"nameKR":"도요스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":39676,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pepenero.seoul/","foodTypes":"이탈리아식","latitude":37.5597527538278,"longitude":126.830889417254,"bookYear":null,"nameKR":"페페네로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":39675,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.2249129124883,"longitude":127.073269145919,"bookYear":null,"nameKR":"성진라멘","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39674,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.2458173874113,"longitude":129.21507097775,"bookYear":null,"nameKR":"브레이크인커피(기장본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":37474,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushidoku2014/","foodTypes":"일식","latitude":37.5477165045093,"longitude":127.043065822487,"bookYear":null,"nameKR":"스시도쿠더성수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":39673,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.3221646868433,"longitude":129.177527989118,"bookYear":null,"nameKR":"족발신선생(부산정관점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":39670,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6887455690617,"longitude":127.894848221894,"bookYear":null,"nameKR":"방앗간막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":39666,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jib_bab_jib/","foodTypes":"한식(일반한식)","latitude":35.8825051086058,"longitude":128.631022333835,"bookYear":null,"nameKR":"집밥집","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":39665,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.8403858809065,"longitude":127.128731325147,"bookYear":null,"nameKR":"퀸상아꼬마김밥","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":39664,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5339612696752,"longitude":126.989184636732,"bookYear":null,"nameKR":"산해장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":39662,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/liege_waffle_/","foodTypes":"디저트/차/베이커리","latitude":37.5647231818473,"longitude":126.976350513728,"bookYear":null,"nameKR":"리에제와플더뷰","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":39659,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_konomiya_","foodTypes":"일식","latitude":37.8601377142976,"longitude":127.728116252689,"bookYear":null,"nameKR":"코노미야","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":39479,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.9935362559609,"longitude":129.4029895381,"bookYear":null,"nameKR":"열이네식육식당(울진점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"울진군","status":"ACTIVE"},
        {"id":39478,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5159214173024,"longitude":129.123315616896,"bookYear":null,"nameKR":"열이네식육식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":39658,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/regular_house/","foodTypes":"디저트/차/베이커리","latitude":35.1013439435054,"longitude":129.03010033826,"bookYear":null,"nameKR":"레귤러하우스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":39653,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6514452431588,"longitude":127.045912956978,"bookYear":null,"nameKR":"베어커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":39652,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chungdo_chinese","foodTypes":"중식","latitude":37.7572656275118,"longitude":128.87003946222,"bookYear":null,"nameKR":"청도회관","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":39651,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/stroll_garden?igsh=OGZhazNnaGU4OWt4&utm_source=qr","foodTypes":"디저트/차/베이커리","latitude":35.8519537282746,"longitude":127.694130077278,"bookYear":null,"nameKR":"정원산책 ","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"무주군","status":"ACTIVE"},
        {"id":37546,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/midoga_official","latitude":35.5425703376701,"longitude":129.337962557758,"bookYear":null,"nameKR":"미도가","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":39190,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yunnan_guanzi","foodTypes":"중식","latitude":35.1651593268796,"longitude":129.158403654058,"bookYear":null,"nameKR":"운남관즈","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":39164,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cors_coffee/ ","foodTypes":"디저트/차/베이커리","latitude":37.2494976769229,"longitude":127.079563005653,"bookYear":null,"nameKR":"콜스커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37342,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.778417919777,"longitude":128.429784381979,"bookYear":null,"nameKR":"본팔백(달성군청점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":37341,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8526463713493,"longitude":128.502597983011,"bookYear":null,"nameKR":"본팔백(이곡점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":37493,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4120167282349,"longitude":127.097765703141,"bookYear":null,"nameKR":"더차이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":39360,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5641995695565,"longitude":126.92623358305,"bookYear":null,"nameKR":"산볼(연남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":39359,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sanbowl_official/","foodTypes":"일식","latitude":37.5538766545763,"longitude":126.90638474677,"bookYear":null,"nameKR":"산볼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":39405,"bookStatus":"커버","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.529029935862,"longitude":126.89734126539,"bookYear":null,"nameKR":"스몰톡","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":39516,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4881050744391,"longitude":126.781242041563,"bookYear":null,"nameKR":"셀렉커피랩(부천점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":39517,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/selectcoffeelab/","foodTypes":"디저트/차/베이커리","latitude":37.5089598337091,"longitude":127.025702913887,"bookYear":null,"nameKR":"셀렉커피랩(논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":39492,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5017915051523,"longitude":127.027398311446,"bookYear":null,"nameKR":"봉우이층집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":39384,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/_hitato_/","foodTypes":"일식","latitude":37.5806221664786,"longitude":126.96738966742,"bookYear":null,"nameKR":"히타토제면소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":39049,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/oilje_official/","foodTypes":"한식(일반한식)","latitude":37.5325403467892,"longitude":126.974737930578,"bookYear":null,"nameKR":"오일제","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":39607,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5954323135034,"longitude":127.061546917955,"bookYear":null,"nameKR":"도란도란곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":39610,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6449317432879,"longitude":126.788187556236,"bookYear":null,"nameKR":"통마루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":39609,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5994116666493,"longitude":127.138175313969,"bookYear":null,"nameKR":"족발구역(구리본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":39640,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6449262056432,"longitude":126.789170299012,"bookYear":null,"nameKR":"독불장군","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":39606,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5544498556697,"longitude":126.853839614814,"bookYear":null,"nameKR":"해성식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":39611,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cheongchun_sea_food2/","foodTypes":"한식(어패류)","latitude":35.1688928816111,"longitude":129.11218109826,"bookYear":null,"nameKR":"청춘횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":39612,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4861760973716,"longitude":126.724544037978,"bookYear":null,"nameKR":"곱창에빠지다","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":39614,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.1999842552092,"longitude":127.072589815256,"bookYear":null,"nameKR":"채돈이(본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39613,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/onie_izakaya/","foodTypes":"바/주점","latitude":34.7971590543505,"longitude":126.431081572889,"bookYear":null,"nameKR":"오니","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":39616,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.3865365092985,"longitude":127.124999029229,"bookYear":null,"nameKR":"콩쥐팥쥐","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":39615,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/avekcheri/","foodTypes":"디저트/차/베이커리","latitude":37.5620566617297,"longitude":126.923159825714,"bookYear":null,"nameKR":"아벡쉐리더파크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":39626,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3775813436,"longitude":126.72696325273,"bookYear":null,"nameKR":"화로상회(배곧점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":39629,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/unagi_yuuka?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr","foodTypes":"일식","latitude":37.430409444559,"longitude":127.072395941315,"bookYear":null,"nameKR":"우나기유카","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":39631,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/twofold_coffee/","foodTypes":"디저트/차/베이커리","latitude":35.3816251675243,"longitude":128.489097384523,"bookYear":null,"nameKR":"투폴드","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"함안군","status":"ACTIVE"},
        {"id":39630,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rinny_bake?igsh=M3JybXhoazI4dHZh","foodTypes":"디저트/차/베이커리","latitude":37.6710197592352,"longitude":126.782076264998,"bookYear":null,"nameKR":"리니베이크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":39639,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ltd_coffee_roasters?igsh=OGZ4Z3A0bWM2cm1u&utm_source=qr","foodTypes":"디저트/차/베이커리","latitude":35.1512243796957,"longitude":126.854526782498,"bookYear":null,"nameKR":"엘티디커피로스터스","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":39644,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jan.january_official?igsh=MTVycnd1Nmw0cnlibw%3D%3D&utm_source=qr","foodTypes":"바/주점","latitude":36.81260178841,"longitude":127.105641451034,"bookYear":null,"nameKR":"잔","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":39648,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/biggalbi_hyoja/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6722204150192,"longitude":126.955696744539,"bookYear":null,"nameKR":"효자동왕갈비탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":39647,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dalbread_official/","foodTypes":"디저트/차/베이커리","latitude":37.5646343728536,"longitude":126.989722597206,"bookYear":null,"nameKR":"해운대달맞이빵","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":39645,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jan.bada_cheonanasan?igsh=azhzOGJqb3hybTFy&utm_source=qr","foodTypes":"바/주점","latitude":36.7888255559128,"longitude":127.10444542232,"bookYear":null,"nameKR":"잔(천안아산점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":39642,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/filmcinema_coffeebar/","foodTypes":"디저트/차/베이커리","latitude":37.3185731848194,"longitude":126.838037384741,"bookYear":null,"nameKR":"필름시네마","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":39641,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gyeongjudong_/","foodTypes":"일식","latitude":35.8384424593983,"longitude":129.209388609085,"bookYear":null,"nameKR":"경주동","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":39638,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.24787337171,"longitude":126.582210932211,"bookYear":null,"nameKR":"서귀포흑돈","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":39637,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/boyakjung/","foodTypes":"한식(민물어패류)","latitude":37.0391482615487,"longitude":127.048898107444,"bookYear":null,"nameKR":"보약정추어명가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":39636,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1113999957044,"longitude":128.965799535269,"bookYear":null,"nameKR":"온나파스타(하단본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":39633,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bingsu.__.gorae?igsh=cDEyM2k3Y3QxOHEz","foodTypes":"디저트/차/베이커리","latitude":37.277479676599,"longitude":127.050634827285,"bookYear":null,"nameKR":"빙수고래","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39632,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/boram_fishhouse","foodTypes":"한식(어패류)","latitude":37.2543032634268,"longitude":126.496923997786,"bookYear":null,"nameKR":"보람이네","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":39625,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/freewill_pizza/","foodTypes":"미국식","latitude":35.1564223291034,"longitude":129.066917622312,"bookYear":null,"nameKR":"프리윌피자","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":39624,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1326695529587,"longitude":129.10968594313,"bookYear":null,"nameKR":"용호만감자탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":39623,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.6233895188643,"longitude":127.433105496554,"bookYear":null,"nameKR":"어도횟집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":39622,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cscr.official/","foodTypes":"디저트/차/베이커리","latitude":37.5043163132049,"longitude":126.76208267382,"bookYear":null,"nameKR":"챔프스페이스커피로스터스(현대백화점중동점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":39618,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sinsa_hwaro","foodTypes":"한식(육류)","latitude":37.5180561407121,"longitude":127.022876980815,"bookYear":null,"nameKR":"신사화로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":39617,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4758384289304,"longitude":126.619735992309,"bookYear":null,"nameKR":"중국제과담","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":39603,"bookStatus":"커버","websiteInstagram":"","foodTypes":"중식","latitude":33.4852623358443,"longitude":126.481459449471,"bookYear":null,"nameKR":"차이나하우스 ","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":39602,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/jejudreamtower/","foodTypes":"기타","latitude":33.4852623358443,"longitude":126.481459449471,"bookYear":null,"nameKR":"스테이크하우스 ","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":39601,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1036510311215,"longitude":127.423636823376,"bookYear":null,"nameKR":"수련산방","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":39474,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rider_yisupapa/","foodTypes":"기타","latitude":37.6219704228009,"longitude":126.913971706642,"bookYear":null,"nameKR":"양식당브런치","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":30847,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mola_mangwon/","foodTypes":"이탈리아식","latitude":37.5570383206015,"longitude":126.902787575534,"bookYear":null,"nameKR":"몰라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":39583,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/dear_mycookie","foodTypes":"디저트/차/베이커리","latitude":37.0808225478131,"longitude":127.051008366661,"bookYear":null,"nameKR":"디어마이쿠키","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":39489,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cielo__kr/","foodTypes":"기타","latitude":35.1540570459646,"longitude":129.145834012644,"bookYear":null,"nameKR":"해운대씨엘로","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":39604,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jinlyok_/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.1314009168726,"longitude":126.916693746732,"bookYear":null,"nameKR":"진리옥(본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39533,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/flourartisanbakery","foodTypes":"디저트/차/베이커리","latitude":37.4860552797801,"longitude":127.12100938212,"bookYear":null,"nameKR":"플라워아티장베이커리(문정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":39525,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myeongju_garden/","foodTypes":"디저트/차/베이커리","latitude":36.576779563349,"longitude":128.165816650934,"bookYear":null,"nameKR":"명주정원","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"상주시","status":"ACTIVE"},
        {"id":39593,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/eppy_coffee","foodTypes":"디저트/차/베이커리","latitude":36.695408886255,"longitude":127.557780958214,"bookYear":null,"nameKR":"에피","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":31603,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":37.4894264951483,"longitude":126.993907816314,"bookYear":null,"nameKR":"와일드플라워","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":39600,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.2000161303874,"longitude":127.603645084999,"bookYear":null,"nameKR":"섬진강재첩국수","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":39599,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.765598510911,"longitude":127.415829459216,"bookYear":null,"nameKR":"마이산옛터","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"진안군","status":"ACTIVE"},
        {"id":39584,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sokcho.hama/","foodTypes":"일식","latitude":38.2165371821388,"longitude":128.594560514525,"bookYear":null,"nameKR":"하마식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":39391,"bookStatus":"커버","websiteInstagram":"https://instagram.com/flourartisanbakery","foodTypes":"디저트/차/베이커리","latitude":37.5189214322204,"longitude":127.021113732728,"bookYear":null,"nameKR":"플라워아티장베이커리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":39596,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cookiehanip","foodTypes":"디저트/차/베이커리","latitude":37.4817177152857,"longitude":127.148159647989,"bookYear":null,"nameKR":"쿠키한입","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":39591,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/blank_vibe_/","foodTypes":"디저트/차/베이커리","latitude":37.2887418709812,"longitude":127.01223854195,"bookYear":null,"nameKR":"블랭크바이브","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39588,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/namdo_softufu/","foodTypes":"한식(일반한식)","latitude":34.7514306671618,"longitude":126.411948401024,"bookYear":null,"nameKR":"남도손두부","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영암군","status":"ACTIVE"},
        {"id":39522,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_thegreennook","foodTypes":"기타","latitude":37.8650678233262,"longitude":127.708785336105,"bookYear":null,"nameKR":"더그린눅","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":39586,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/beginning_bread","foodTypes":"디저트/차/베이커리","latitude":37.6260636933654,"longitude":127.071747683747,"bookYear":null,"nameKR":"비기닝브레드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":39585,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pancone_official","foodTypes":"이탈리아식","latitude":37.5304540525105,"longitude":126.971089785438,"bookYear":null,"nameKR":"판코네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":39580,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ddasuon/","foodTypes":"한식(일반한식)","latitude":37.4835695693308,"longitude":126.895502765348,"bookYear":null,"nameKR":"따수온","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":39576,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/noonflower_official/","foodTypes":"한식(육류)","latitude":37.5199221939163,"longitude":126.892125694525,"bookYear":null,"nameKR":"눈꽃을나누다(문래직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":39574,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.8791664712317,"longitude":127.725254833179,"bookYear":null,"nameKR":"대영옥돼지곰탕","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":39573,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/y__k_myeon/","foodTypes":"한식(면류)","latitude":37.8813791197435,"longitude":127.732984584914,"bookYear":null,"nameKR":"육면","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":39572,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5036274972267,"longitude":127.022660298088,"bookYear":null,"nameKR":"이가해장국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":39571,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.382340086168,"longitude":126.641368803184,"bookYear":null,"nameKR":"목향","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":39469,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/earth__us","foodTypes":"디저트/차/베이커리","latitude":37.5853427511264,"longitude":126.971240550399,"bookYear":null,"nameKR":"얼스어스(서촌점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":39468,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/earth__us","foodTypes":"디저트/차/베이커리","latitude":37.5641298365593,"longitude":126.923271335595,"bookYear":null,"nameKR":"얼스어스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":39554,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/serendypiknic/","foodTypes":"기타","latitude":37.5360041943117,"longitude":126.963226287175,"bookYear":null,"nameKR":"세렌디피크닉","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37872,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5742707942486,"longitude":126.990870271708,"bookYear":null,"nameKR":"문화제빵","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":39320,"bookStatus":"커버","websiteInstagram":"http://instagram.com/wood_mellow","foodTypes":"기타","latitude":37.5630737818447,"longitude":127.155098343711,"bookYear":null,"nameKR":"우드멜로우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":34731,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/morub_official/","foodTypes":"디저트/차/베이커리","latitude":35.1655799839409,"longitude":129.158014118813,"bookYear":null,"nameKR":"모루비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":39200,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/grilldburger_official/ ","foodTypes":"기타","latitude":37.4941306123133,"longitude":126.500366936226,"bookYear":null,"nameKR":"그릴드버거","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":39397,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/ojjok_e_rak","foodTypes":"한식(육류)","latitude":35.1558903785557,"longitude":129.1223902938,"bookYear":null,"nameKR":"오쪽이락","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":39442,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5541767070081,"longitude":126.912156565553,"bookYear":null,"nameKR":"강화통통생고기본점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":36823,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.0988337930557,"longitude":127.090132055775,"bookYear":null,"nameKR":"커피냅로스터스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":36824,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5664284125218,"longitude":126.920213302301,"bookYear":null,"nameKR":"커피냅로스터스(연남동)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":39570,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.4891898600088,"longitude":126.413707243168,"bookYear":null,"nameKR":"커피냅로스터스(제주점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":39568,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.6282723078111,"longitude":129.351176123363,"bookYear":null,"nameKR":"수정방","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":39562,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.921660742161,"longitude":127.058397419104,"bookYear":null,"nameKR":"꽃돼지국밥","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":39561,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/downt_espresso/","foodTypes":"디저트/차/베이커리","latitude":35.1556512493243,"longitude":129.064119927643,"bookYear":null,"nameKR":"다운트","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":39559,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5048440320759,"longitude":127.042824150655,"bookYear":null,"nameKR":"두레국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":39558,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5729765145796,"longitude":126.974351284671,"bookYear":null,"nameKR":"송추가마골인어반(광화문점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":39375,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cheongchunjogae.official/","foodTypes":"한식(어패류)","latitude":37.4568301222517,"longitude":126.369053328922,"bookYear":null,"nameKR":"진주조개(을왕본점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":39376,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cheongchunjogae.official/","foodTypes":"한식(어패류)","latitude":37.4555248831936,"longitude":126.370733272858,"bookYear":null,"nameKR":"청춘조개포차(을왕본점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":39370,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gogae_view/","foodTypes":"한식(어패류)","latitude":37.4764929251161,"longitude":126.598494219491,"bookYear":null,"nameKR":"조개뷰(월미본점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":38492,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.3976390888538,"longitude":127.112523629313,"bookYear":null,"nameKR":"이가네양꼬치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":38494,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3968925296743,"longitude":127.111925428711,"bookYear":null,"nameKR":"통통곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":9276,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.717548,"longitude":126.97217,"bookYear":null,"nameKR":"송추가마골본관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":35340,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6731110812513,"longitude":126.775527494435,"bookYear":null,"nameKR":"슬로우드링크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":39556,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1516041319697,"longitude":126.922392122319,"bookYear":null,"nameKR":"포카포카","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":39548,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lee_nak_san","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1470219103438,"longitude":129.110899923962,"bookYear":null,"nameKR":"이낙에산다(광안리본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":39540,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/urban_foodoffice/","foodTypes":"이탈리아식","latitude":37.505988710756,"longitude":126.7571899325,"bookYear":null,"nameKR":"어반푸드오피스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":3993,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5871292464272,"longitude":127.029463467677,"bookYear":null,"nameKR":"무아국수집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":38713,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/rumicake/","foodTypes":"디저트/차/베이커리","latitude":37.7362385027312,"longitude":126.753142001113,"bookYear":null,"nameKR":"루미케익","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":39500,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vienna227_cafe","foodTypes":"디저트/차/베이커리","latitude":37.5389997704376,"longitude":127.071214413664,"bookYear":null,"nameKR":"비엔나227(건대스타시티직영점) ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":35581,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jangduk_official/","foodTypes":"한식(민물어패류)","latitude":35.1568124461,"longitude":129.134474818443,"bookYear":null,"nameKR":"장덕풍천산삼민물장어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35582,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jangduk_official/","foodTypes":"한식(민물어패류)","latitude":35.1769192419104,"longitude":129.068255862773,"bookYear":null,"nameKR":"장덕풍천산삼민물장어(거제리직영점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":35914,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jangduk_official/","foodTypes":"한식(민물어패류)","latitude":35.1535346413664,"longitude":129.057539923574,"bookYear":null,"nameKR":"장덕장어(서면직영점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":39283,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_nampo","foodTypes":"한식(어패류)","latitude":35.8712594746887,"longitude":128.600266984969,"bookYear":null,"nameKR":"남포","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":39415,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5456773286722,"longitude":127.042914714267,"bookYear":null,"nameKR":"서우룸다이닝(서울성수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36525,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fritti_uomo/","foodTypes":"이탈리아식","latitude":37.5572145871483,"longitude":127.07839001386,"bookYear":null,"nameKR":"프리티워모","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":39501,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.3026924973304,"longitude":127.409028243922,"bookYear":null,"nameKR":"한내송어회","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":39418,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tealimes_official","foodTypes":"디저트/차/베이커리","latitude":35.0978586755014,"longitude":128.821077563697,"bookYear":null,"nameKR":"티라임스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":39511,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/halawonsh/","foodTypes":"한식(육류)","latitude":33.3049726791712,"longitude":126.31635202613,"bookYear":null,"nameKR":"한라원","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":39520,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myfavoritebottlebar_busan/","foodTypes":"바/주점","latitude":35.1569269756392,"longitude":129.122729403448,"bookYear":null,"nameKR":"마이페이보릿보틀(광안점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":39513,"bookStatus":"커버","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.1986613756611,"longitude":129.221107738456,"bookYear":null,"nameKR":"아난티앳부산빌라쥬버지니아","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":38808,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.5793008885255,"longitude":128.500387546477,"bookYear":null,"nameKR":"안동양반모던한정식","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":39022,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.237644585764,"longitude":129.082566084405,"bookYear":null,"nameKR":"라이크댓커피","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":39519,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rufruf_museum/","foodTypes":"디저트/차/베이커리","latitude":37.5416962727725,"longitude":127.058247206574,"bookYear":null,"nameKR":"러프러프","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":39396,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/youngkcal__official?igsh=a3dmaWIybGZvMW9j&utm_source=qr","foodTypes":"기타","latitude":36.3952089166511,"longitude":127.408329331897,"bookYear":null,"nameKR":"영칼로리포케본점","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":38761,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yakitori_gokko_yn","foodTypes":"일식","latitude":37.56219904426,"longitude":126.923475914307,"bookYear":null,"nameKR":"야키토리고꼬연남","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":39361,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moremore_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.5291465566297,"longitude":126.8757129546,"bookYear":null,"nameKR":"모어모어","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":39455,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5127249935295,"longitude":127.05585415027,"bookYear":null,"nameKR":"민규(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":39174,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.1287171115548,"longitude":128.219039299025,"bookYear":null,"nameKR":"원보쌈","ribbonType":"정보없음","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":39598,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/ciaciaroom/","foodTypes":"기타","latitude":35.2101091844153,"longitude":126.891039653196,"bookYear":null,"nameKR":"차차룸 ","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":39021,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4852570404812,"longitude":127.035196748823,"bookYear":null,"nameKR":"난지당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":39537,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_madeljen/","foodTypes":"디저트/차/베이커리","latitude":37.563389233811,"longitude":126.926700026567,"bookYear":null,"nameKR":"마들젠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35607,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yanjing.official/","foodTypes":"중식","latitude":37.4786869592588,"longitude":126.619401720652,"bookYear":null,"nameKR":"차이나타운동화마을연경","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":31921,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5705757964955,"longitude":126.933622857037,"bookYear":null,"nameKR":"연경(연희점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":39528,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.3590077744486,"longitude":129.04175545528,"bookYear":null,"nameKR":"잡앗닭","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":39425,"bookStatus":"커버","websiteInstagram":"","foodTypes":"일식","latitude":37.4937404364019,"longitude":126.992480394027,"bookYear":null,"nameKR":"스시우토","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":39424,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.536265801091,"longitude":126.99916397629,"bookYear":null,"nameKR":"베베르카","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":39423,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/___hasira/","foodTypes":"일식","latitude":37.5229861757958,"longitude":127.048910310952,"bookYear":null,"nameKR":"하시라","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":39421,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ranjuku.warayaki/","foodTypes":"바/주점","latitude":37.5112123940707,"longitude":127.055649770696,"bookYear":null,"nameKR":"란주쿠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":39422,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/cafe_hrnn/","foodTypes":"디저트/차/베이커리","latitude":35.2043969584414,"longitude":128.906092010381,"bookYear":null,"nameKR":"흐른","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":7331,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.491115,"longitude":127.029859,"bookYear":null,"nameKR":"삼호복집(서초점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":1153,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.556485,"longitude":126.935005,"bookYear":null,"nameKR":"삼호복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":7110,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.56341,"longitude":126.983737,"bookYear":null,"nameKR":"마노디셰프(명동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":30239,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hohosikdang/","foodTypes":"일식","latitude":37.57295431891117,"longitude":126.99046857296638,"bookYear":null,"nameKR":"호호식당(익선동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":30774,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5476275236329,"longitude":127.049626650234,"bookYear":null,"nameKR":"일미락(성수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":7236,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.487393,"longitude":127.052727,"bookYear":null,"nameKR":"벽제갈비(타워팰리스점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":7238,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.557713,"longitude":126.938039,"bookYear":null,"nameKR":"벽제갈비(신촌점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":29631,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.52448082359342,"longitude":127.04169392560684,"bookYear":null,"nameKR":"나리의집(청담점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":5204,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.525361,"longitude":127.035166,"bookYear":null,"nameKR":"강서면옥(압구정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32480,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5217860808427,"longitude":127.03092995237,"bookYear":null,"nameKR":"우수미회센타(신사점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30426,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.57446722947599,"longitude":126.9835539937142,"bookYear":null,"nameKR":"담장옆에국화꽃CCOT(안녕인사동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":29142,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.571153,"longitude":126.974709,"bookYear":null,"nameKR":"화목순대국(광화문점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29140,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.519303,"longitude":126.93149,"bookYear":null,"nameKR":"화목순대국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":6821,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.482864,"longitude":127.019524,"bookYear":null,"nameKR":"버드나무집(우면산점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":30798,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5713698504776,"longitude":126.80296035927,"bookYear":null,"nameKR":"이성당과자점(롯데백화점김포공항점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":7308,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.52083,"longitude":126.9313,"bookYear":null,"nameKR":"파파호(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":27910,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5118239121138,"longitude":127.059159043842,"bookYear":null,"nameKR":"하동관(코엑스점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":26988,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hadongkwan_official/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.527477,"longitude":126.919652,"bookYear":null,"nameKR":"하동관(직영1호점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":28153,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maybell_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.5172332566615,"longitude":126.903715993779,"bookYear":null,"nameKR":"오월의종(타임스퀘어점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":6925,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.507896,"longitude":127.103372,"bookYear":null,"nameKR":"삼백집(잠실직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":5304,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.476131,"longitude":127.045711,"bookYear":null,"nameKR":"평가옥(양재점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":30203,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.523282965587505,"longitude":127.0346478496235,"bookYear":null,"nameKR":"동남방앗간(도산점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30202,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.56176902018169,"longitude":126.92448888831335,"bookYear":null,"nameKR":"동남방앗간","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27673,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.508677,"longitude":127.056157,"bookYear":null,"nameKR":"문배동육칼(삼성직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":19443,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.669509,"longitude":126.75088,"bookYear":null,"nameKR":"을밀대(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":7082,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.495332,"longitude":127.030372,"bookYear":null,"nameKR":"을밀대(강남역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":32055,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fourb.hours/","foodTypes":"디저트/차/베이커리","latitude":37.5697975427046,"longitude":126.973177410043,"bookYear":null,"nameKR":"포비브라이트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29357,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fourb.hours/","foodTypes":"디저트/차/베이커리","latitude":37.551916,"longitude":126.911029,"bookYear":null,"nameKR":"포비베이직","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":29356,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.570995859145405,"longitude":126.97891368495493,"bookYear":null,"nameKR":"포비광화문","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29702,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/anthracite_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.49877410719873,"longitude":127.02669323759949,"bookYear":null,"nameKR":"앤트러사이트(강남점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":28444,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/anthracite_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5361092100338,"longitude":127.000163832697,"bookYear":null,"nameKR":"앤트러사이트(한남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":28231,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/anthracitecoffeejeju","foodTypes":"디저트/차/베이커리","latitude":33.407646,"longitude":126.258172,"bookYear":null,"nameKR":"앤트러사이트(한림점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":6118,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.54577,"longitude":126.9184,"bookYear":null,"nameKR":"앤트러사이트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":17889,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.51258,"longitude":127.0556,"bookYear":null,"nameKR":"브루클린더버거조인트(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":31064,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/noransangsogalbi/","foodTypes":"한식(육류)","latitude":37.517518193485,"longitude":127.039180345759,"bookYear":null,"nameKR":"노란상소갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29456,"bookStatus":"커버","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5776259780911,"longitude":126.8967400223,"bookYear":null,"nameKR":"최다이닝","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":36463,"bookStatus":"커버","websiteInstagram":null,"foodTypes":"프랑스식","latitude":37.5373071864777,"longitude":126.944273138606,"bookYear":null,"nameKR":"호텔나루서울레스토랑부아쟁","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":39536,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.2312665577594,"longitude":128.840627498339,"bookYear":null,"nameKR":"고구려","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":39535,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ggotnak_mokpo","foodTypes":"한식(어패류)","latitude":34.7825279026224,"longitude":126.383058333128,"bookYear":null,"nameKR":"꽃낙","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":39534,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vinter_coffee_bar/","foodTypes":"디저트/차/베이커리","latitude":37.5216657103043,"longitude":127.020062016023,"bookYear":null,"nameKR":"빈터커피바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":39532,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soil_and_people/","foodTypes":"한식(일반한식)","latitude":37.4439539902781,"longitude":126.792180933475,"bookYear":null,"nameKR":"흙과사람들","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":39531,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakingduck_cakehouse/","foodTypes":"디저트/차/베이커리","latitude":37.6272223096072,"longitude":127.077253399908,"bookYear":null,"nameKR":"베이킹덕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":39530,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.3924922264887,"longitude":127.231118670867,"bookYear":null,"nameKR":"신백옥양꼬치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":39529,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.3139203480214,"longitude":129.023714938612,"bookYear":null,"nameKR":"잡앗닭(석산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":23824,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.487283,"longitude":127.103109,"bookYear":null,"nameKR":"삼대국수회관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":39527,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/zenzen_co/","foodTypes":"기타","latitude":37.3187021053486,"longitude":126.838552231917,"bookYear":null,"nameKR":"젠젠(안산중앙점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":39526,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/zenzen_co/","foodTypes":"기타","latitude":37.3809410558608,"longitude":126.656664161119,"bookYear":null,"nameKR":"젠젠(본점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":39524,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bezout.restaurant/","foodTypes":"기타","latitude":35.0863873977364,"longitude":128.909480180028,"bookYear":null,"nameKR":"브주레스토랑","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":39523,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dongbing_iceflakes/","foodTypes":"디저트/차/베이커리","latitude":35.8667724316079,"longitude":128.594268826997,"bookYear":null,"nameKR":"동빙","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":39518,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/flower_ydpork/","foodTypes":"한식(육류)","latitude":37.5170722986908,"longitude":126.907041678448,"bookYear":null,"nameKR":"영등포꽃삼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":39515,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/selectcoffeelab/","foodTypes":"디저트/차/베이커리","latitude":37.5056930255122,"longitude":127.040595017678,"bookYear":null,"nameKR":"셀렉커피랩","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":39388,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/hongmc2020/","foodTypes":"한식(어패류)","latitude":37.5105541159183,"longitude":126.911550456858,"bookYear":null,"nameKR":"홍어명가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":39387,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5814899815783,"longitude":126.969672903572,"bookYear":null,"nameKR":"안덕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":39382,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafegrasse/","foodTypes":"기타","latitude":37.5198724390762,"longitude":127.024397523446,"bookYear":null,"nameKR":"카페그라쎄","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":39383,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lastweek.jeonju/","foodTypes":"디저트/차/베이커리","latitude":35.8146733341504,"longitude":127.144844872731,"bookYear":null,"nameKR":"라스트위크","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":39514,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.499852933462,"longitude":127.029506833445,"bookYear":null,"nameKR":"마유유마라탕(강남역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":39381,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5230671928548,"longitude":127.036387716609,"bookYear":null,"nameKR":"포노부오노","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39380,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/chibo1973sinsa/","foodTypes":"일식","latitude":37.5180141725087,"longitude":127.020828762118,"bookYear":null,"nameKR":"치보","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":39512,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nova45_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.240964775824,"longitude":127.215230005845,"bookYear":null,"nameKR":"노바베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":39354,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dyddlsakwwlq/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2344567094984,"longitude":127.189104020198,"bookYear":null,"nameKR":"평안도찹쌀순대(용인명지대점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":39356,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6360963946037,"longitude":126.832562693397,"bookYear":null,"nameKR":"평안도찹쌀순대전문점(화정점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":39351,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6561821893907,"longitude":127.064022659398,"bookYear":null,"nameKR":"평안도순대국(노원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":39357,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6520876291657,"longitude":126.776668348232,"bookYear":null,"nameKR":"평안도찹쌀순대전문점(마두점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":39353,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4825827883002,"longitude":126.897045984079,"bookYear":null,"nameKR":"평안도찹쌀순대(구로디지털점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":39276,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/indica_seoul/","foodTypes":"아시아식","latitude":37.5353665378761,"longitude":127.010053434924,"bookYear":null,"nameKR":"인디카","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":39275,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lemmecoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5610932367871,"longitude":126.9638558141,"bookYear":null,"nameKR":"램미커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":39510,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":34.8036531383113,"longitude":126.429764597155,"bookYear":null,"nameKR":"국밥파는남자(목포점)","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":8501,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.572068,"longitude":126.97256,"bookYear":null,"nameKR":"광화문뚝감","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"","status":"ACTIVE"},
        {"id":14081,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.350802422229,"longitude":127.952166547083,"bookYear":null,"nameKR":"영화은마차","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":14561,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.2498541029398,"longitude":126.561042148732,"bookYear":null,"nameKR":"웅담식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":14924,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.549422,"longitude":126.920576,"bookYear":null,"nameKR":"산더미불고기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"","status":"ACTIVE"},
        {"id":17828,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3998981644883,"longitude":126.975215982347,"bookYear":null,"nameKR":"원조곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":19167,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.485884,"longitude":126.981572,"bookYear":null,"nameKR":"참오리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":19172,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.2030404129854,"longitude":128.573671677429,"bookYear":null,"nameKR":"삼대초밥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":19254,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/motnaniagu/ ","foodTypes":"한식(어패류)","latitude":37.3937496263108,"longitude":126.962944909378,"bookYear":null,"nameKR":"못난이아구나라","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":19380,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.2555510581229,"longitude":126.574613109375,"bookYear":null,"nameKR":"자리돔횟집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":19753,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1577976976376,"longitude":129.172238883003,"bookYear":null,"nameKR":"참새방앗간","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":38026,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3068486626495,"longitude":127.099690792824,"bookYear":null,"nameKR":"성남숯불갈매기살","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":38029,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2060614317713,"longitude":129.079291851147,"bookYear":null,"nameKR":"복천안주마을","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":38025,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4887486063504,"longitude":127.013385880372,"bookYear":null,"nameKR":"우리바다생태찌개 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":25999,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4884189352008,"longitude":127.014095010132,"bookYear":null,"nameKR":"해담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":38023,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.492714131604,"longitude":127.011577216814,"bookYear":null,"nameKR":"모악정","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":38027,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2060780609221,"longitude":129.079467397965,"bookYear":null,"nameKR":"삼바리(본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":36309,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gop.gangnam/","foodTypes":"한식(육류)","latitude":37.4943642838521,"longitude":127.031552686124,"bookYear":null,"nameKR":"강남곱","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38024,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.2061356853331,"longitude":129.079868850434,"bookYear":null,"nameKR":"참치가게","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":38032,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hoonhyukman/","foodTypes":"한식(어패류)","latitude":35.157572758388,"longitude":129.063963508884,"bookYear":null,"nameKR":"김씨주당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38033,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1601476835852,"longitude":129.064278550579,"bookYear":null,"nameKR":"상호미지수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38031,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1595588329591,"longitude":129.056839159647,"bookYear":null,"nameKR":"밤중에","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":38030,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.1473432570407,"longitude":127.074715979903,"bookYear":null,"nameKR":"우돈마실","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":38037,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.5093214666331,"longitude":126.539729307684,"bookYear":null,"nameKR":"사랑물","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":38038,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.5014172566441,"longitude":126.54447529699,"bookYear":null,"nameKR":"우도아지망","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":38052,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5006937956124,"longitude":127.02784371375,"bookYear":null,"nameKR":"알부자 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38050,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1567521971997,"longitude":129.064960508804,"bookYear":null,"nameKR":"전포아로하","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38057,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.156267949879,"longitude":129.060270337525,"bookYear":null,"nameKR":"댕강","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38048,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oldmansion_busan/","foodTypes":"한식(육류)","latitude":35.1551804292642,"longitude":129.064154774253,"bookYear":null,"nameKR":"올드맨션","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38055,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1574400806223,"longitude":129.061944202302,"bookYear":null,"nameKR":"산장1988","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38045,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/okdukkeobi_busan/","foodTypes":"한식(육류)","latitude":35.1546353684189,"longitude":129.066672267422,"bookYear":null,"nameKR":"옥두꺼비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38044,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":33.2475793538552,"longitude":126.564703173353,"bookYear":null,"nameKR":"마주앉아","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":38061,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/onjeongol_official/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1567244822472,"longitude":129.06129223508,"bookYear":null,"nameKR":"온전골","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38040,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ildochon","foodTypes":"한식(어패류)","latitude":33.5030685581689,"longitude":126.542844506293,"bookYear":null,"nameKR":"일도촌","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":38059,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4402983370673,"longitude":127.123265376081,"bookYear":null,"nameKR":"풍경돌판구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":38056,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4939069944418,"longitude":127.028843765138,"bookYear":null,"nameKR":"해우담 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38053,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ok.haesung/","foodTypes":"바/주점","latitude":35.1559600474479,"longitude":129.061598068083,"bookYear":null,"nameKR":"해성","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38043,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.2534795702774,"longitude":126.571673244109,"bookYear":null,"nameKR":"토담집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":38039,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":33.5056625286968,"longitude":126.544582311703,"bookYear":null,"nameKR":"율군짬뽕","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":38060,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.2492538373421,"longitude":126.976975162987,"bookYear":null,"nameKR":"수인선닭발집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38046,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/haemachon_jeju","foodTypes":"한식(어패류)","latitude":33.4949054391205,"longitude":126.527793124421,"bookYear":null,"nameKR":"해마촌","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":38058,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.4963930894124,"longitude":126.529481637199,"bookYear":null,"nameKR":"도남황금갈비","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":38054,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5004988522186,"longitude":127.028079434784,"bookYear":null,"nameKR":"떡도리탕 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38042,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.2406163010164,"longitude":126.565701743287,"bookYear":null,"nameKR":"부두회수산","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":38047,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.4953947694012,"longitude":126.528039153273,"bookYear":null,"nameKR":"노포갈비","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":38078,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5032426397499,"longitude":127.040101164357,"bookYear":null,"nameKR":"하몽하몽이베리코 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":38095,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.2594721427784,"longitude":127.044694351858,"bookYear":null,"nameKR":"만덕숯닭92","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38081,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hobo__restaurant/ ","foodTypes":"한식(육류)","latitude":37.4980324009469,"longitude":127.034664677708,"bookYear":null,"nameKR":"호보식당 ","ribbonType":"정보없음","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":38092,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4971192135865,"longitude":127.031509515761,"bookYear":null,"nameKR":"나주가 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38072,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cheom__beong/","foodTypes":"한식(어패류)","latitude":35.157639308202,"longitude":129.120817571047,"bookYear":null,"nameKR":"첨벙","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38079,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8051123335878,"longitude":128.608408745912,"bookYear":null,"nameKR":"영주축협한우프라자","ribbonType":"정보없음","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":38096,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chunsic_e/","foodTypes":"바/주점","latitude":35.1385677068133,"longitude":129.108039802619,"bookYear":null,"nameKR":"춘식이네","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38070,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.2679657301564,"longitude":127.003542456509,"bookYear":null,"nameKR":"하회마을찜닭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38087,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4973973244605,"longitude":127.030244769147,"bookYear":null,"nameKR":"뽕나무쟁이 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38076,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1577546301621,"longitude":129.125424561881,"bookYear":null,"nameKR":"칠월","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38086,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4981751373056,"longitude":127.034913082441,"bookYear":null,"nameKR":"명성갈비  ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":38062,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5012257817168,"longitude":127.024441102142,"bookYear":null,"nameKR":"공단갈비꼬치강남역점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38077,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chizik_official/","foodTypes":"일식","latitude":35.1569650933316,"longitude":129.120821242473,"bookYear":null,"nameKR":"치직","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38090,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.0644794157409,"longitude":129.384521308419,"bookYear":null,"nameKR":"청춘통닭","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":38083,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2464986586744,"longitude":127.055902880551,"bookYear":null,"nameKR":"땡이네소곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38071,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/okgyegwan_busan/","foodTypes":"한식(가금류)","latitude":35.1564431489078,"longitude":129.121361372753,"bookYear":null,"nameKR":"옥계관","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38091,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/foodmasta/","foodTypes":"한식(면류)","latitude":35.1485634371324,"longitude":129.111648737636,"bookYear":null,"nameKR":"백일평냉","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38064,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.298989164029,"longitude":126.969953622449,"bookYear":null,"nameKR":"봉수육","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38067,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1577546301621,"longitude":129.125424561881,"bookYear":null,"nameKR":"철마한우로스구이","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38068,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.7294416634388,"longitude":128.108098235601,"bookYear":null,"nameKR":"온천약돌한우돼지정육식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":38089,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chinesedrinkclub/","foodTypes":"바/주점","latitude":35.157991347653,"longitude":129.121565846921,"bookYear":null,"nameKR":"CDC","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38084,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.498785841354,"longitude":127.036105538193,"bookYear":null,"nameKR":"남호식당 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":38074,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/suwon_deaga","foodTypes":"한식(육류)","latitude":37.2674308198028,"longitude":127.001881419944,"bookYear":null,"nameKR":"대가주점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38065,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/somuger_themuger/","foodTypes":"한식(육류)","latitude":35.0483509589607,"longitude":128.966048422299,"bookYear":null,"nameKR":"소무거더무거","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":38082,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2597060246019,"longitude":127.044050129,"bookYear":null,"nameKR":"신씨고집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38066,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/borinepig","foodTypes":"한식(육류)","latitude":37.2976018730759,"longitude":126.969042751424,"bookYear":null,"nameKR":"보리네주먹고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38085,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1532353292792,"longitude":129.124656209034,"bookYear":null,"nameKR":"샤브남바완","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38094,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":36.0159833484297,"longitude":129.344084142101,"bookYear":null,"nameKR":"스시무라","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":38093,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.2598576114704,"longitude":127.044925562548,"bookYear":null,"nameKR":"잇다","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38063,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gyo__jak/","foodTypes":"바/주점","latitude":35.1634782716856,"longitude":128.984754329173,"bookYear":null,"nameKR":"교작(본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":38069,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5049885992418,"longitude":127.046743275216,"bookYear":null,"nameKR":"참치그라 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":38133,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bu_pyeong_official/","foodTypes":"한식(육류)","latitude":35.1002436692532,"longitude":129.026284186599,"bookYear":null,"nameKR":"부평양곱창","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":38104,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2645155673903,"longitude":127.035899598457,"bookYear":null,"nameKR":"고기랑","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38118,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1701427588097,"longitude":129.110673154066,"bookYear":null,"nameKR":"닭발의지존","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38123,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5182711939801,"longitude":127.019987128565,"bookYear":null,"nameKR":"구르메 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":38110,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/Chaihong_pohang","foodTypes":"중식","latitude":36.0592989788458,"longitude":129.378867702381,"bookYear":null,"nameKR":"차이홍","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":38129,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.2483495533718,"longitude":127.07629685766,"bookYear":null,"nameKR":"벌교집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38113,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5171579689591,"longitude":127.036373761803,"bookYear":null,"nameKR":"맛짱조개 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":38117,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2514587479802,"longitude":127.075935916587,"bookYear":null,"nameKR":"미소곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38121,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1662518011311,"longitude":129.117551616958,"bookYear":null,"nameKR":"백수농원","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38111,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1594058006292,"longitude":129.11446019551,"bookYear":null,"nameKR":"골목포차","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38135,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0990586074578,"longitude":129.026538412627,"bookYear":null,"nameKR":"청조횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":38125,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.1670313415658,"longitude":129.115847797489,"bookYear":null,"nameKR":"상하이탄","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38119,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.517488783,"longitude":127.021413312725,"bookYear":null,"nameKR":"목포집 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":38116,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.0575468683831,"longitude":129.377254500843,"bookYear":null,"nameKR":"포갈집","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":38134,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.8056969110374,"longitude":126.422166677253,"bookYear":null,"nameKR":"59년왕십리연탄곱창구이","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38099,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jungjin9244","foodTypes":"한식(육류)","latitude":37.2737583963957,"longitude":127.049960343048,"bookYear":null,"nameKR":"정진식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38130,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oktoppocha/","foodTypes":"바/주점","latitude":35.1845400635662,"longitude":129.081788965658,"bookYear":null,"nameKR":"옥탑포차","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":38100,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.014149977775,"longitude":129.358389510855,"bookYear":null,"nameKR":"마고닭","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":38108,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woosan4700","foodTypes":"한식(육류)","latitude":37.1175645977832,"longitude":126.91523413196,"bookYear":null,"nameKR":"우산식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38107,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2655090780864,"longitude":127.034306518185,"bookYear":null,"nameKR":"청화대","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38106,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2520935291006,"longitude":127.076362853139,"bookYear":null,"nameKR":"쏘삼돼지208","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38115,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5170765777751,"longitude":127.020372759636,"bookYear":null,"nameKR":"홍미닭발 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":38114,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1457735233523,"longitude":129.112017574929,"bookYear":null,"nameKR":"청송양곱창","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38109,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5155549500558,"longitude":127.039590937867,"bookYear":null,"nameKR":"신신양꼬치 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":38097,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2726842876647,"longitude":127.150078085749,"bookYear":null,"nameKR":"소근소근숯불갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":38124,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5238228613413,"longitude":127.052038439495,"bookYear":null,"nameKR":"백송 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":38120,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":34.7859385164968,"longitude":126.368503811314,"bookYear":null,"nameKR":"신비포차","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38132,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ummanae_samgyeopsal","foodTypes":"한식(육류)","latitude":37.499958365166,"longitude":126.776014630142,"bookYear":null,"nameKR":"엄마네삼겹살","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":38128,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5262424846195,"longitude":127.037319859812,"bookYear":null,"nameKR":"섭식당 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":38122,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2523268448601,"longitude":127.076726041705,"bookYear":null,"nameKR":"하누담","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38102,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/damoa__1993/ ","foodTypes":"한식(일반한식)","latitude":37.5201538342379,"longitude":127.031907653716,"bookYear":null,"nameKR":"다모아분식 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":38098,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5150690865663,"longitude":127.032658817035,"bookYear":null,"nameKR":"학동집 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":38103,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.1465692333024,"longitude":127.075871784959,"bookYear":null,"nameKR":"운암명가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":38101,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2630238820162,"longitude":127.035932823444,"bookYear":null,"nameKR":"이나경송탄부대찌개","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38165,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.9564470924087,"longitude":127.520244227521,"bookYear":null,"nameKR":"옛날삼겹살","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":38192,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.0175914141272,"longitude":127.864321447212,"bookYear":null,"nameKR":"중앙탑메밀마당","ribbonType":"정보없음","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":38171,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4838715283798,"longitude":127.012664897282,"bookYear":null,"nameKR":"곰포차 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":38138,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1773956856851,"longitude":129.124998931426,"bookYear":null,"nameKR":"냉삼스타","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":38188,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1605232884303,"longitude":129.190702071779,"bookYear":null,"nameKR":"청미가","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":38137,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1001069354083,"longitude":129.02703477955,"bookYear":null,"nameKR":"왕대박숯불갈비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":38179,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jinhae_official/ ","foodTypes":"한식(어패류)","latitude":37.5003535799397,"longitude":127.042879601261,"bookYear":null,"nameKR":"진짜해산물 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":38178,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.9529333006091,"longitude":127.520990239401,"bookYear":null,"nameKR":"명지원","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":38163,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gomi_bistro","foodTypes":"바/주점","latitude":34.9102792936118,"longitude":126.433528520785,"bookYear":null,"nameKR":"고미","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":38187,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.0458573527185,"longitude":127.060885004575,"bookYear":null,"nameKR":"차순옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":38176,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7330295252249,"longitude":127.72620519661,"bookYear":null,"nameKR":"꽃돌게장1번가","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":38177,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ysnw.ffff ","foodTypes":"한식(가금류)","latitude":37.4948991943308,"longitude":127.039410295564,"bookYear":null,"nameKR":"역삼농원 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38183,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5147783722949,"longitude":127.017671022342,"bookYear":null,"nameKR":"먹거리양곱창 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":38167,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sashimi_hwang/ ","foodTypes":"한식(어패류)","latitude":37.5262424846195,"longitude":127.037319859812,"bookYear":null,"nameKR":"황회장집 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":38172,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yesulsanghoe/ ","foodTypes":"한식(육류)","latitude":37.48417111057,"longitude":127.014273308255,"bookYear":null,"nameKR":"예술상회 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":38168,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5060393121226,"longitude":127.026132366328,"bookYear":null,"nameKR":"광개토정육식당 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":38174,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/themiju120/","foodTypes":"한식(가금류)","latitude":34.9209820589989,"longitude":127.473274214716,"bookYear":null,"nameKR":"The미주농원120","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":38184,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5017524163278,"longitude":126.77283839652,"bookYear":null,"nameKR":"미담참숯불구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":38169,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5074671381371,"longitude":127.02443318399,"bookYear":null,"nameKR":"영동그집 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":38136,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.8043091026473,"longitude":126.425532732947,"bookYear":null,"nameKR":"거북이회포차","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38193,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.50280070196,"longitude":127.050643196124,"bookYear":null,"nameKR":"돈그리아 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":38186,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5130662133216,"longitude":127.018140701044,"bookYear":null,"nameKR":"두배족발공원옆 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":38170,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":34.9585744969932,"longitude":127.517528391829,"bookYear":null,"nameKR":"텃밭","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":38139,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":34.8071729477604,"longitude":126.420953141721,"bookYear":null,"nameKR":"자작","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38181,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.477065197973,"longitude":126.984197843198,"bookYear":null,"nameKR":"어울림정육식당 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":38173,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4832944262439,"longitude":127.036215443199,"bookYear":null,"nameKR":"강촌쭈꾸미 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":38182,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4786145443079,"longitude":126.983326748977,"bookYear":null,"nameKR":"여진족 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":38189,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dongmyunghang ","foodTypes":"바/주점","latitude":37.5129517954437,"longitude":127.018077784658,"bookYear":null,"nameKR":"동명항","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":38196,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.042181760346,"longitude":127.050344032299,"bookYear":null,"nameKR":"형제고기야","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":38198,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5025758006896,"longitude":127.052116878637,"bookYear":null,"nameKR":"강정희간장새우 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":38175,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4821126327697,"longitude":127.037468850656,"bookYear":null,"nameKR":"꽃보다갈비살양재본점 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":38180,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.9398137051477,"longitude":127.694791597326,"bookYear":null,"nameKR":"금정광양불고기","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":38190,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.0415573292085,"longitude":127.052798454805,"bookYear":null,"nameKR":"동남상회","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":38226,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.2645852683694,"longitude":128.621168668152,"bookYear":null,"nameKR":"국일성","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38229,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.562873704136,"longitude":126.925152845884,"bookYear":null,"nameKR":"회뜨는총각 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":38225,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5716465634037,"longitude":126.979456058867,"bookYear":null,"nameKR":"무교동낙지 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":38224,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2568532083752,"longitude":128.638082941705,"bookYear":null,"nameKR":"공도포차","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38238,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2227440567635,"longitude":128.686849771468,"bookYear":null,"nameKR":"청사포횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38218,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4961403785684,"longitude":127.121148348753,"bookYear":null,"nameKR":"쇠돌이 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":38222,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/by__anak ","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5115127599737,"longitude":127.027993300902,"bookYear":null,"nameKR":"아낙 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":38216,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hoseokchon1986/ ","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5073210700263,"longitude":127.103393559212,"bookYear":null,"nameKR":"호석촌 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":38223,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dong.myeon","foodTypes":"한식(가금류)","latitude":36.6697085398409,"longitude":127.491156858905,"bookYear":null,"nameKR":"동면닭갈비","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":38205,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5144572160495,"longitude":127.108893037866,"bookYear":null,"nameKR":"한우한마리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":38207,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.2029077526872,"longitude":127.072383996667,"bookYear":null,"nameKR":"명가포차","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38228,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jandeungjae_yeonnam ","foodTypes":"한식(육류)","latitude":37.5607505874256,"longitude":126.924432080479,"bookYear":null,"nameKR":"잔등재차돌집 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":38220,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.6521576963652,"longitude":127.45717998628,"bookYear":null,"nameKR":"나릿집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":38227,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/6go._.zip","foodTypes":"한식(육류)","latitude":36.7164720859114,"longitude":127.428287731319,"bookYear":null,"nameKR":"육고집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":38208,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.8922584774719,"longitude":128.615574448073,"bookYear":null,"nameKR":"꽃분실비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":38239,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ddung_bo_90tan_","foodTypes":"한식(육류)","latitude":36.8206348981654,"longitude":127.159285862864,"bookYear":null,"nameKR":"뚱보네구공탄","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38217,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4967607576032,"longitude":127.121376311855,"bookYear":null,"nameKR":"오향가 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":38231,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5630779492645,"longitude":126.92599337881,"bookYear":null,"nameKR":"연남제비 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":38233,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5495221641519,"longitude":126.914505775455,"bookYear":null,"nameKR":"신김치생삼겹살 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":38209,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5363668946325,"longitude":127.126923795349,"bookYear":null,"nameKR":"풍년상회 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":38203,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.2039744437243,"longitude":127.072192727906,"bookYear":null,"nameKR":"동해수산","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38221,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2572147566489,"longitude":128.637139156631,"bookYear":null,"nameKR":"쉼터포차","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38204,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.6556641144774,"longitude":127.482481351935,"bookYear":null,"nameKR":"우천축산물불고기","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":38230,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/roasted_chicken_tongyoung/","foodTypes":"한식(가금류)","latitude":34.8781041507106,"longitude":128.421529183257,"bookYear":null,"nameKR":"참나무장작구이통닭","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":38212,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.8891132087449,"longitude":128.623939655704,"bookYear":null,"nameKR":"육갤러리","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":38213,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.2011338290011,"longitude":127.072275292664,"bookYear":null,"nameKR":"동호리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38215,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.0000228799452,"longitude":127.11511896083,"bookYear":null,"nameKR":"정착","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":38211,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.6276937898246,"longitude":127.425372255071,"bookYear":null,"nameKR":"정원보양촌","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":38219,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5322550549901,"longitude":127.138577078926,"bookYear":null,"nameKR":"강동해물찜해천탕 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":38199,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5152124851212,"longitude":127.110875301006,"bookYear":null,"nameKR":"마포소금구이 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":38201,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sam_do_jeong/","foodTypes":"한식(육류)","latitude":35.1793421811738,"longitude":128.561658822245,"bookYear":null,"nameKR":"삼도정한우한돈","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":38234,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.2316115800206,"longitude":128.859984810212,"bookYear":null,"nameKR":"현정이네황토오리","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":38232,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_haerusushi_/","foodTypes":"일식","latitude":35.2360237867854,"longitude":128.86617783945,"bookYear":null,"nameKR":"해루스시","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":38237,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1929432648905,"longitude":128.803302136934,"bookYear":null,"nameKR":"호동숯불한우한돈","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":38214,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bon_di.official ","foodTypes":"한식(육류)","latitude":37.5076671270066,"longitude":127.107749674369,"bookYear":null,"nameKR":"본디 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":38236,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8101777858113,"longitude":127.151710563323,"bookYear":null,"nameKR":"석산장","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38200,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.6559798353348,"longitude":127.48146522585,"bookYear":null,"nameKR":"대박생갈비","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":38282,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5046846189743,"longitude":126.751695069018,"bookYear":null,"nameKR":"상동테마곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":38246,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.8189740858322,"longitude":127.14099854532,"bookYear":null,"nameKR":"삼보어죽","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38242,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.8287786272058,"longitude":127.135468335662,"bookYear":null,"nameKR":"토정삼계탕","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38260,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5375787789066,"longitude":126.728355909275,"bookYear":null,"nameKR":"이학갈비","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":38240,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2197778544787,"longitude":128.681722304385,"bookYear":null,"nameKR":"동작그만조개구이","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38270,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.7670318463678,"longitude":128.879290738973,"bookYear":null,"nameKR":"회바라기","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":38275,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.356580661002,"longitude":128.009536368696,"bookYear":null,"nameKR":"치악산돌갱이","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":38273,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.9294135379478,"longitude":127.785346683871,"bookYear":null,"nameKR":"장호닭갈비","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":38250,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2464904168575,"longitude":128.508067755912,"bookYear":null,"nameKR":"거창횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":38254,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2573274115062,"longitude":128.60814601969,"bookYear":null,"nameKR":"소반","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38271,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.865003485517,"longitude":127.751033368138,"bookYear":null,"nameKR":"더담다","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":38244,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.0977705736242,"longitude":128.804613306444,"bookYear":null,"nameKR":"제주정돼지","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":38259,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2442564424436,"longitude":128.651344581447,"bookYear":null,"nameKR":"팔도오돌뼈","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38276,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nongga_wonju","foodTypes":"한식(육류)","latitude":37.3261559808965,"longitude":127.982017766969,"bookYear":null,"nameKR":"농가","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":38274,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3375708991249,"longitude":127.962413390888,"bookYear":null,"nameKR":"석탑본점","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":38267,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/umjinae_gangneung/","foodTypes":"한식(어패류)","latitude":37.766317802103,"longitude":128.907018449433,"bookYear":null,"nameKR":"엄지네포장마차","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":38252,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8253711340577,"longitude":127.13785822924,"bookYear":null,"nameKR":"산내들","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38279,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7984204184508,"longitude":126.433853931645,"bookYear":null,"nameKR":"바다조개전골","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38278,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5375101284322,"longitude":126.72777142023,"bookYear":null,"nameKR":"물꽁오리","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":38243,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2223668955368,"longitude":128.682149025096,"bookYear":null,"nameKR":"불꽃다찌","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38266,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/donghae_scallop","foodTypes":"한식(어패류)","latitude":37.5572484563136,"longitude":129.120969559709,"bookYear":null,"nameKR":"동해가리비본점","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":38265,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.1733252040701,"longitude":128.605788635416,"bookYear":null,"nameKR":"모녀가리비","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":38281,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5031091878246,"longitude":126.762102111299,"bookYear":null,"nameKR":"만남회","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":38247,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6436495707135,"longitude":126.624678600738,"bookYear":null,"nameKR":"순심이뚝배기동태탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":38245,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8304677308301,"longitude":127.136747530646,"bookYear":null,"nameKR":"광시한우곱창","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38280,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.7949621871956,"longitude":126.430623308105,"bookYear":null,"nameKR":"목포화연","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38258,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4880014341762,"longitude":126.780645666456,"bookYear":null,"nameKR":"행복한포차","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":38251,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.6426974392992,"longitude":126.62220189915,"bookYear":null,"nameKR":"청원숯불양다리구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":38269,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.769812316281,"longitude":128.913840964024,"bookYear":null,"nameKR":"병산닭발과부침개","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":38253,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6455086119591,"longitude":126.624411581511,"bookYear":null,"nameKR":"구래왕대포","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":38241,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8035358679346,"longitude":127.129127372222,"bookYear":null,"nameKR":"노을연탄구이","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38248,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1010191231914,"longitude":128.812969255197,"bookYear":null,"nameKR":"호호춘천닭갈비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":38257,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.212773292675,"longitude":128.70277581647,"bookYear":null,"nameKR":"무쇠곱창","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38256,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8044700230714,"longitude":127.139600242239,"bookYear":null,"nameKR":"정애식당","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38255,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/turtle.abalone","foodTypes":"한식(어패류)","latitude":36.8071729203536,"longitude":127.137421501273,"bookYear":null,"nameKR":"거북이전복","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38272,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.8741816765273,"longitude":127.713836736789,"bookYear":null,"nameKR":"봄내대가","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":38277,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":34.7953291998481,"longitude":126.429902990904,"bookYear":null,"nameKR":"유달제면소","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38286,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2217161211456,"longitude":128.685368820545,"bookYear":null,"nameKR":"촌놈고기집(본점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38291,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2052044038994,"longitude":128.810112578655,"bookYear":null,"nameKR":"청학동참숯불뒷통구이","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":38299,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.7982525388445,"longitude":126.435630997405,"bookYear":null,"nameKR":"원조목살집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38285,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2200217201697,"longitude":128.683770904905,"bookYear":null,"nameKR":"화돈화우(창원본점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38304,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2057889639617,"longitude":129.079740549451,"bookYear":null,"nameKR":"동래석쇠갈비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":38300,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4395082783991,"longitude":126.785312873123,"bookYear":null,"nameKR":"서경한우한우직판장정육식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":38294,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.2639078783824,"longitude":128.872640016141,"bookYear":null,"nameKR":"창성양꼬치마라탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":38283,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5333890115231,"longitude":126.652455914718,"bookYear":null,"nameKR":"청라꽃갈비","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":38284,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2435702057295,"longitude":128.650410085323,"bookYear":null,"nameKR":"해녀대합실식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38303,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/domodajji5492","foodTypes":"한식(어패류)","latitude":34.8083056678275,"longitude":126.375972919588,"bookYear":null,"nameKR":"도모다찌","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38302,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.206689944718,"longitude":129.079671852577,"bookYear":null,"nameKR":"두구동감나무집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":38290,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2210551823829,"longitude":128.682154678464,"bookYear":null,"nameKR":"목장회관","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38296,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2273493500874,"longitude":128.678184582098,"bookYear":null,"nameKR":"팔도안고을","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38298,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5333517509746,"longitude":126.651980223225,"bookYear":null,"nameKR":"청라전집왔전","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":38293,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2207482824392,"longitude":128.584764266911,"bookYear":null,"nameKR":"초특급","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":38307,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joeunzip__/","foodTypes":"한식(육류)","latitude":35.2326481554691,"longitude":129.08734676479,"bookYear":null,"nameKR":"좋은집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":38306,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3953921330862,"longitude":126.651885840366,"bookYear":null,"nameKR":"별미순대","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":38287,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":34.8076546190771,"longitude":126.374450885768,"bookYear":null,"nameKR":"양철지붕(중앙점)","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38292,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5335230185924,"longitude":126.653017257652,"bookYear":null,"nameKR":"뚜껑","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":38297,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7982125588921,"longitude":126.430955336588,"bookYear":null,"nameKR":"목포는항구다(평화광장점)","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38300,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4395082783991,"longitude":126.785312873123,"bookYear":null,"nameKR":"서경한우한우직판장정육식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":38311,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeongyukdo/","foodTypes":"한식(육류)","latitude":37.5491963881277,"longitude":126.914914555972,"bookYear":null,"nameKR":"정육도 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":38299,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.7982525388445,"longitude":126.435630997405,"bookYear":null,"nameKR":"원조목살집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38312,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.7529987555022,"longitude":127.702937718212,"bookYear":null,"nameKR":"이쁜돼지","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":38309,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.4952518153219,"longitude":129.430187079171,"bookYear":null,"nameKR":"조개명당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":38307,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joeunzip__/","foodTypes":"한식(육류)","latitude":35.2326481554691,"longitude":129.08734676479,"bookYear":null,"nameKR":"좋은집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":38316,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.7574804075487,"longitude":127.657147420524,"bookYear":null,"nameKR":"삼겹","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":38313,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4544683857355,"longitude":126.637637805683,"bookYear":null,"nameKR":"덕원대구탕","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":38315,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.5413145623024,"longitude":129.323824714087,"bookYear":null,"nameKR":"해운대대게횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":38314,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mongjubang/ ","foodTypes":"바/주점","latitude":37.5487045452801,"longitude":126.919163824865,"bookYear":null,"nameKR":"몽주방 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":38318,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chungdonok.seoul/ ","foodTypes":"한식(육류)","latitude":37.551960252738,"longitude":126.919619205143,"bookYear":null,"nameKR":"청돈옥 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":38317,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4556872148235,"longitude":126.649327471777,"bookYear":null,"nameKR":"본가물텀벙","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":38347,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3592229752379,"longitude":126.931756983661,"bookYear":null,"nameKR":"연탄사랑","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":38349,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.369804899546,"longitude":126.943734020793,"bookYear":null,"nameKR":"금정어시장1호점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":38350,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5730941591968,"longitude":126.97382543007,"bookYear":null,"nameKR":"광화문고기집 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":38344,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1450028244282,"longitude":126.917071310523,"bookYear":null,"nameKR":"아세안무역","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":38340,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.369909248717,"longitude":126.94026414448,"bookYear":null,"nameKR":"금자네순대국소머리곰탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":38341,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.5676710997533,"longitude":126.980510627285,"bookYear":null,"nameKR":"용금옥 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":38337,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dm_gobcangjib","foodTypes":"한식(육류)","latitude":35.149148342747,"longitude":126.927733435892,"bookYear":null,"nameKR":"한우곱창집","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":38352,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5710578458833,"longitude":126.974770997845,"bookYear":null,"nameKR":"동락돼지 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":38338,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.561805852361,"longitude":126.991732775863,"bookYear":null,"nameKR":"뚱보냉면 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":38343,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5823276564066,"longitude":127.000288695794,"bookYear":null,"nameKR":"달빛마루 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":38339,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.859214005327,"longitude":128.632881360148,"bookYear":null,"nameKR":"고미정","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":38346,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1454224677019,"longitude":126.918086397361,"bookYear":null,"nameKR":"소문난쭈꾸미","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":38345,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8121305717899,"longitude":128.525587094778,"bookYear":null,"nameKR":"식당이백도씨","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38336,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1494540465184,"longitude":126.928888211792,"bookYear":null,"nameKR":"끝집","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":38335,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5730063396492,"longitude":126.990890802055,"bookYear":null,"nameKR":"광주집 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":38351,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8477737236118,"longitude":128.539101828091,"bookYear":null,"nameKR":"성주막창","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38321,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5532227481537,"longitude":126.92071073159,"bookYear":null,"nameKR":"육몽 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":38320,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.758347301869,"longitude":127.661073886529,"bookYear":null,"nameKR":"오죽헌한정식","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":38319,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4534162471291,"longitude":126.635781122065,"bookYear":null,"nameKR":"하정식당","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":38331,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.3594855317163,"longitude":126.932695442879,"bookYear":null,"nameKR":"쭈꾸뱅이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":38330,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4131655326341,"longitude":126.677077375951,"bookYear":null,"nameKR":"진사부","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":38328,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/king_kong_pocha ","foodTypes":"바/주점","latitude":37.5551665159589,"longitude":126.923095488814,"bookYear":null,"nameKR":"킹콩포차 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":38327,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ducksday_daegu/","foodTypes":"한식(가금류)","latitude":35.8380250961728,"longitude":128.639203496971,"bookYear":null,"nameKR":"오리의하루","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":38326,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4445365058531,"longitude":126.701707672992,"bookYear":null,"nameKR":"구월동장군집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":38325,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.7580652935099,"longitude":127.66429647247,"bookYear":null,"nameKR":"녹차밭","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":38324,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7575229170939,"longitude":127.658672351758,"bookYear":null,"nameKR":"청양횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":38348,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5713864751051,"longitude":126.973698680555,"bookYear":null,"nameKR":"나무향기 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":38332,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.832032812382,"longitude":128.622222563506,"bookYear":null,"nameKR":"장군양꼬치","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":38323,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4447541025564,"longitude":126.702638317704,"bookYear":null,"nameKR":"유신불족발","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":38322,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.5360739092121,"longitude":129.337390205526,"bookYear":null,"nameKR":"송림생오리","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":38334,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8735722964474,"longitude":128.820367151057,"bookYear":null,"nameKR":"개봉참숯불구이","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":38333,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5569543745711,"longitude":126.933656784323,"bookYear":null,"nameKR":"돈불1971 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":38364,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.9361589758957,"longitude":128.566861934051,"bookYear":null,"nameKR":"삼거리회타운","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":38370,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.40018931714,"longitude":126.92200875561,"bookYear":null,"nameKR":"동해오징어보쌈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38387,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.3975909974973,"longitude":126.923179766267,"bookYear":null,"nameKR":"마루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38376,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5188979456571,"longitude":126.931943269754,"bookYear":null,"nameKR":"생돈구이촌 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":38361,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5630828214858,"longitude":126.977706647884,"bookYear":null,"nameKR":"풍년닭도리탕 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":38360,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5581064714844,"longitude":126.907521980502,"bookYear":null,"nameKR":"청어람망원점 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":38378,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3995417316785,"longitude":126.922799770084,"bookYear":null,"nameKR":"고탐갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38359,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2141177225913,"longitude":126.848441457921,"bookYear":null,"nameKR":"첨단돈","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38395,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8689463133843,"longitude":128.592709368352,"bookYear":null,"nameKR":"읍내","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":38394,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8554674446114,"longitude":128.554056890393,"bookYear":null,"nameKR":"봉그랑","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38365,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5661001157126,"longitude":127.019535484292,"bookYear":null,"nameKR":"옥경이네건생선 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":38377,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8583381052302,"longitude":128.530158765903,"bookYear":null,"nameKR":"두메산골한우마을","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38383,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5260558187275,"longitude":126.930769729764,"bookYear":null,"nameKR":"농부와닭동네 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":38384,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.398761865076,"longitude":126.923581757317,"bookYear":null,"nameKR":"꼬꼬바베큐","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38381,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.9282642697009,"longitude":128.073529195204,"bookYear":null,"nameKR":"비엔나실비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":38369,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rodeng_bar ","foodTypes":"바/주점","latitude":37.5332342860396,"longitude":126.900510317023,"bookYear":null,"nameKR":"로뎅바 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":38366,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ddakjoa_/","foodTypes":"한식(육류)","latitude":35.904521633782,"longitude":128.612423025785,"bookYear":null,"nameKR":"딱조아막창","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":38356,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5546544088203,"longitude":126.911063893831,"bookYear":null,"nameKR":"너랑나랑호프 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":38355,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2151932208461,"longitude":126.852460496695,"bookYear":null,"nameKR":"송정금호식육식당","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38374,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8233509642633,"longitude":128.519779436836,"bookYear":null,"nameKR":"골목","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38368,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/naruter_official","foodTypes":"바/주점","latitude":35.2149130275804,"longitude":126.848116527358,"bookYear":null,"nameKR":"나루터","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38390,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8792817855694,"longitude":128.641073436041,"bookYear":null,"nameKR":"청해회타운","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":38385,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5243933646946,"longitude":126.92714963712,"bookYear":null,"nameKR":"독도참치앤전복 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":38354,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9253562200021,"longitude":128.54651004351,"bookYear":null,"nameKR":"팔공산맥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":38380,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/80.000_official ","foodTypes":"한식(육류)","latitude":37.5299742441711,"longitude":126.920825855405,"bookYear":null,"nameKR":"팔만식당1970 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":38375,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.4134279233999,"longitude":128.122602917941,"bookYear":null,"nameKR":"상구한우","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"합천군","status":"ACTIVE"},
        {"id":38372,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.21409965559,"longitude":128.143385269211,"bookYear":null,"nameKR":"진주냉면산홍본점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":38379,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.8629840333151,"longitude":128.013997361612,"bookYear":null,"nameKR":"한국관","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":38386,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8511354229505,"longitude":128.623870074031,"bookYear":null,"nameKR":"명동돼지한마리","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":38382,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.841572868648,"longitude":128.52955540885,"bookYear":null,"nameKR":"수성산호아나고숯불곰장어","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38362,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9398194736181,"longitude":128.569903744611,"bookYear":null,"nameKR":"봉화생고기","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":38388,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bonhwaroo_yookhwajin_insaeng ","foodTypes":"한식(육류)","latitude":37.4835892550334,"longitude":126.927865628035,"bookYear":null,"nameKR":"본화로 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":38371,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jjunjju.kr ","foodTypes":"한식(어패류)","latitude":37.4792475435531,"longitude":126.953258518036,"bookYear":null,"nameKR":"쭈앤쭈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":38367,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sobaektan ","foodTypes":"한식(육류)","latitude":37.533363569248,"longitude":126.901141692408,"bookYear":null,"nameKR":"소백탄 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":38405,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5113085668197,"longitude":126.944810162717,"bookYear":null,"nameKR":"소야 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":38418,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5603955888014,"longitude":126.827508501448,"bookYear":null,"nameKR":"한우곱해장 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":38438,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5177764356504,"longitude":126.909599271835,"bookYear":null,"nameKR":"정솔닭한마리 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":38414,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.559915259194,"longitude":126.8343608462,"bookYear":null,"nameKR":"머슬장어 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":38416,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5605448342463,"longitude":126.838427628497,"bookYear":null,"nameKR":"머구리마곡점 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":38426,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.184167408629,"longitude":126.804591010885,"bookYear":null,"nameKR":"하남화로(하남3지구점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38406,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.9923129889162,"longitude":127.090873755031,"bookYear":null,"nameKR":"산꼼장어포차","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":38427,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3941905694417,"longitude":126.962890146906,"bookYear":null,"nameKR":"장군집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38401,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4741635050399,"longitude":126.870827302869,"bookYear":null,"nameKR":"김대포","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":38402,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5518695603647,"longitude":126.850869810561,"bookYear":null,"nameKR":"어부의딸 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":38419,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8664565986455,"longitude":128.598017431535,"bookYear":null,"nameKR":"고기굽는남자","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":38437,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1893652997635,"longitude":126.830213982601,"bookYear":null,"nameKR":"독에빠진돈막창(수완본점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38398,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4832118469713,"longitude":126.902649603614,"bookYear":null,"nameKR":"신림돼지뽈살 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":38440,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8614832457034,"longitude":128.607468091386,"bookYear":null,"nameKR":"방천소갈비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":38403,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8716504717252,"longitude":128.597864880491,"bookYear":null,"nameKR":"교동감나무집","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":38436,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3907303878149,"longitude":126.95419502625,"bookYear":null,"nameKR":"블랙시크릿가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38429,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.561058561996,"longitude":126.838610552611,"bookYear":null,"nameKR":"발산한우진곱창 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":38397,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8828143468514,"longitude":128.559866498484,"bookYear":null,"nameKR":"기찻길삼겹살","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":38404,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8697249384792,"longitude":128.591285670957,"bookYear":null,"nameKR":"녹향뭉티기","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":38441,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5066369329911,"longitude":126.962729487098,"bookYear":null,"nameKR":"진미순대 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":38413,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mirabeau__official/","foodTypes":"한식(육류)","latitude":35.866654425039,"longitude":128.597947782169,"bookYear":null,"nameKR":"미라보양대창","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":38424,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5726874871118,"longitude":126.822874302182,"bookYear":null,"nameKR":"마곡오리 ","ribbonType":"정보없음","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":38415,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2043558749869,"longitude":126.896719929463,"bookYear":null,"nameKR":"꼬부기","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":38400,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5432592790771,"longitude":126.843502478979,"bookYear":null,"nameKR":"화곡영양족발 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":38399,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4842384938602,"longitude":126.900104629882,"bookYear":null,"nameKR":"대한민족","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":38408,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.8666887322141,"longitude":128.600778543221,"bookYear":null,"nameKR":"온달포장&온달포차삼덕본점","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":38442,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.206125163643,"longitude":127.113186947887,"bookYear":null,"nameKR":"미나리고사리솥뚜껑삼겹살","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38434,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_pyeon.ji","foodTypes":"바/주점","latitude":36.9927263338995,"longitude":127.090850094976,"bookYear":null,"nameKR":"편지","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":38409,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/teuk_samgyeop ","foodTypes":"한식(육류)","latitude":37.559915259194,"longitude":126.8343608462,"bookYear":null,"nameKR":"특삼겹 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":38420,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8550991197087,"longitude":128.49423364912,"bookYear":null,"nameKR":"사이안","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38407,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.885531706055,"longitude":128.581455376842,"bookYear":null,"nameKR":"걸리버막창","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":38411,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4008806089718,"longitude":126.975077193751,"bookYear":null,"nameKR":"인덕신사또통족발보쌈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38412,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/manjae_samduk/","foodTypes":"한식(육류)","latitude":35.8669951876396,"longitude":128.6003610203,"bookYear":null,"nameKR":"만재네삼덕본점","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":38443,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.3913627249287,"longitude":126.954661026766,"bookYear":null,"nameKR":"강콩den","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38410,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.559915259194,"longitude":126.8343608462,"bookYear":null,"nameKR":"우직정 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":38422,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3939916674383,"longitude":126.960791889687,"bookYear":null,"nameKR":"양술이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38431,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1626785969897,"longitude":126.80914754826,"bookYear":null,"nameKR":"하남목장","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38439,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.1979517309026,"longitude":127.098239696428,"bookYear":null,"nameKR":"부촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38417,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2071672246077,"longitude":126.878857883732,"bookYear":null,"nameKR":"25시참숯구이","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":38430,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":36.9921840197014,"longitude":127.08995909635,"bookYear":null,"nameKR":"야키토리단감","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":38474,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.3674688788737,"longitude":126.724896082751,"bookYear":null,"nameKR":"팔당장어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":38467,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2538443192213,"longitude":127.489566787446,"bookYear":null,"nameKR":"가야축산","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":38472,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.3696602978227,"longitude":126.726545129528,"bookYear":null,"nameKR":"차고집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":38463,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.477245116591,"longitude":126.890330588284,"bookYear":null,"nameKR":"문정곱창 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":38445,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1891485725635,"longitude":126.82947857854,"bookYear":null,"nameKR":"한우리식육식당","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38476,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3698356601897,"longitude":126.728353838883,"bookYear":null,"nameKR":"탄돈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":38469,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4919585636183,"longitude":126.725432509645,"bookYear":null,"nameKR":"신매운양푼갈비찜(부평본점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":38473,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1313271506641,"longitude":126.78800400867,"bookYear":null,"nameKR":"성원세꼬시","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38466,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4113860610943,"longitude":127.131132939273,"bookYear":null,"nameKR":"매화양꼬치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38449,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4819870291587,"longitude":126.883088116031,"bookYear":null,"nameKR":"삼숙이갈비 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":38456,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/zip_bull/","foodTypes":"한식(육류)","latitude":37.2057729400022,"longitude":127.114129181734,"bookYear":null,"nameKR":"짚불태백","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38457,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4948720878104,"longitude":126.89946162017,"bookYear":null,"nameKR":"서탑할매 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":38479,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4122109417444,"longitude":127.13226734024,"bookYear":null,"nameKR":"미향양갈비양꼬치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38450,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.3908896841851,"longitude":126.954651035432,"bookYear":null,"nameKR":"이로리연","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38451,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1963823003722,"longitude":126.837211707636,"bookYear":null,"nameKR":"토모다찌","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38482,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wooore_","foodTypes":"한식(육류)","latitude":37.3643456065408,"longitude":127.106311805523,"bookYear":null,"nameKR":"우레","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38475,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1424094606986,"longitude":126.793900444137,"bookYear":null,"nameKR":"장흥횟집","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38452,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.479966429878,"longitude":126.88263620024,"bookYear":null,"nameKR":"인어나라 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":38465,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5385351578892,"longitude":127.087520375118,"bookYear":null,"nameKR":"한우짝갈비살 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38477,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2330066783304,"longitude":127.064069223763,"bookYear":null,"nameKR":"우직한정육식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38448,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8839129485892,"longitude":128.584657171937,"bookYear":null,"nameKR":"골목집숙성삼겹","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":38478,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1285835508308,"longitude":126.786188645288,"bookYear":null,"nameKR":"정순교낙원정갈비(광주본점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38480,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4135081428199,"longitude":127.130234432013,"bookYear":null,"nameKR":"시골양지곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38470,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5381848102594,"longitude":127.093222472755,"bookYear":null,"nameKR":"산골닭갈비 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38468,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.137449467314,"longitude":126.796844735297,"bookYear":null,"nameKR":"장수왕국밥","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38455,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.195207788168,"longitude":126.836305967672,"bookYear":null,"nameKR":"백탄돈구이(본점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38453,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4957877548093,"longitude":126.899219751579,"bookYear":null,"nameKR":"원조강뚝꼬치구이전문점대림본점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":38454,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8618335003493,"longitude":128.606000935796,"bookYear":null,"nameKR":"방천가족족발&보쌈","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":38460,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1239765861061,"longitude":126.761039772613,"bookYear":null,"nameKR":"송원식육식당","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38481,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.21213298948,"longitude":127.0553136608,"bookYear":null,"nameKR":"개성곱창능동본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38461,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4774978834519,"longitude":126.890076069807,"bookYear":null,"nameKR":"풍년갈비 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":38458,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/incheonsanggalbi_official/","foodTypes":"한식(육류)","latitude":37.197569425488,"longitude":127.096582650573,"bookYear":null,"nameKR":"인천생갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38444,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5466539031566,"longitude":126.874114547813,"bookYear":null,"nameKR":"목동우대갈비 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":38459,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ilmak_hwaro","foodTypes":"한식(육류)","latitude":35.9171437770109,"longitude":128.82484008739,"bookYear":null,"nameKR":"일막화로","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":38447,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5210390853913,"longitude":126.841413170986,"bookYear":null,"nameKR":"만석생고기 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":38471,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":36.9934291735513,"longitude":127.112048901804,"bookYear":null,"nameKR":"왕십리밥도먹고술도먹고","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":38462,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4122129779037,"longitude":127.132701665154,"bookYear":null,"nameKR":"문어천하","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38503,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3634117849993,"longitude":127.314968738297,"bookYear":null,"nameKR":"초월상회","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":38504,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1571621293765,"longitude":126.879715589677,"bookYear":null,"nameKR":"금수산방","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":38487,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1252490755638,"longitude":126.909971997132,"bookYear":null,"nameKR":"봉선골","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":38517,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4915000702063,"longitude":126.582469161841,"bookYear":null,"nameKR":"영종섬","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":38500,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lambro_boonjum/","foodTypes":"중식","latitude":37.4126291962301,"longitude":127.261559978086,"bookYear":null,"nameKR":"램브로광주본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":38499,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3850339201367,"longitude":127.120438766751,"bookYear":null,"nameKR":"방짜","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38496,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4317925812079,"longitude":127.131775946337,"bookYear":null,"nameKR":"빨강물고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":38511,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5637312032917,"longitude":127.191741670397,"bookYear":null,"nameKR":"골뱅이상회","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":38484,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3511648568046,"longitude":126.722015099099,"bookYear":null,"nameKR":"늘봄가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":38506,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3546040266611,"longitude":127.164186013598,"bookYear":null,"nameKR":"오포상회","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":38498,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1184366777858,"longitude":126.898055710363,"bookYear":null,"nameKR":"베스트","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":38493,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1320154365105,"longitude":126.908769483438,"bookYear":null,"nameKR":"저팔계","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":38485,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3643145088793,"longitude":127.107323103947,"bookYear":null,"nameKR":"강호식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38497,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6025377763773,"longitude":126.780312072933,"bookYear":null,"nameKR":"찬우물한우정육식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":38519,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kth_999/","foodTypes":"바/주점","latitude":35.1485407874273,"longitude":126.851355740968,"bookYear":null,"nameKR":"미식가이모카세(상무지구점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":38491,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6100621222714,"longitude":126.725651837392,"bookYear":null,"nameKR":"풍무동연탄집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":38507,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5614426436883,"longitude":127.192370418201,"bookYear":null,"nameKR":"마포집돼지갈비전문점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":38502,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.3860323369942,"longitude":127.120622274882,"bookYear":null,"nameKR":"웅성웅성","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38483,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1386841659873,"longitude":126.793864186692,"bookYear":null,"nameKR":"송극떡갈비","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38518,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1531015085093,"longitude":126.849836766818,"bookYear":null,"nameKR":"개념포차바다다","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":38512,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lsb_orthodox/","foodTypes":"바/주점","latitude":35.1783687449625,"longitude":126.874571046753,"bookYear":null,"nameKR":"미쯔","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":38486,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2127531481845,"longitude":127.054020557499,"bookYear":null,"nameKR":"경원궁","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38514,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.311198545455,"longitude":126.831933814193,"bookYear":null,"nameKR":"육술","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":38505,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.560577579823,"longitude":127.191383632928,"bookYear":null,"nameKR":"삼촌네조개찜","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":38488,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.379964955328,"longitude":127.116832474045,"bookYear":null,"nameKR":"수내곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38508,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3106448725268,"longitude":126.828426239338,"bookYear":null,"nameKR":"장인소곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":38490,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.2319728934885,"longitude":127.186829296537,"bookYear":null,"nameKR":"주해냄","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":38510,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6735209963852,"longitude":126.788729301289,"bookYear":null,"nameKR":"친구","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":38489,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.226592273868,"longitude":127.187413789007,"bookYear":null,"nameKR":"진주옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":38509,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bokdeongine/","foodTypes":"한식(육류)","latitude":35.157111511129,"longitude":126.861914334058,"bookYear":null,"nameKR":"복덩이네","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":38520,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.494157915435,"longitude":126.490160862363,"bookYear":null,"nameKR":"낙안","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":38495,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.1146512823282,"longitude":126.907233567049,"bookYear":null,"nameKR":"시골집추어탕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":38515,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.3316342461498,"longitude":126.792642717451,"bookYear":null,"nameKR":"연길왕꼬치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":38501,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4473675445436,"longitude":126.697196603214,"bookYear":null,"nameKR":"족장","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":38513,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.178414725468,"longitude":126.87423427487,"bookYear":null,"nameKR":"바다토끼","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":38516,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1790988279683,"longitude":126.893579161341,"bookYear":null,"nameKR":"고신락","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":38562,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5963008257111,"longitude":127.146316714644,"bookYear":null,"nameKR":"옥화식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":38563,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.3298330308729,"longitude":127.422406092296,"bookYear":null,"nameKR":"손이가어죽칼국수","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":38567,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thehome_rang3","foodTypes":"한식(육류)","latitude":37.6056126327816,"longitude":127.134593951395,"bookYear":null,"nameKR":"그집랭삼","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":38552,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/longfish_tasty/","foodTypes":"한식(민물어패류)","latitude":35.8648709116462,"longitude":127.119627058963,"bookYear":null,"nameKR":"사선대또랑장어","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38536,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1241407242667,"longitude":126.868673336163,"bookYear":null,"nameKR":"맛고을해남","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":38549,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.0537581840729,"longitude":126.904893456256,"bookYear":null,"nameKR":"별난곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":38554,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4019706551723,"longitude":126.721267226557,"bookYear":null,"nameKR":"사계절막회","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":38540,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1736934580045,"longitude":126.920024462446,"bookYear":null,"nameKR":"지업사횟집","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":38569,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.3191137343934,"longitude":127.431855505894,"bookYear":null,"nameKR":"농민순대","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":38521,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1479156870018,"longitude":126.850106160141,"bookYear":null,"nameKR":"원두막","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":38535,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6531459391532,"longitude":126.89454441833,"bookYear":null,"nameKR":"노적봉숯불갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":38561,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9576094896064,"longitude":126.998713030333,"bookYear":null,"nameKR":"오가네한우곱창","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38544,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.492888171318,"longitude":126.721906257445,"bookYear":null,"nameKR":"백오","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":38551,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4032997730972,"longitude":126.722965156775,"bookYear":null,"nameKR":"삼겹살집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":38566,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8160422567027,"longitude":127.110476136015,"bookYear":null,"nameKR":"푸른수산","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38548,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8477087282131,"longitude":127.157147326249,"bookYear":null,"nameKR":"도래지","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38538,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7616129862026,"longitude":126.767241918247,"bookYear":null,"nameKR":"금송촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":38553,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5024039280893,"longitude":126.722015274283,"bookYear":null,"nameKR":"아구그림","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":38542,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1702676574552,"longitude":126.910841626619,"bookYear":null,"nameKR":"강진식당","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":38568,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3511384907885,"longitude":127.418856286325,"bookYear":null,"nameKR":"연탄구이","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":38523,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1545563033028,"longitude":126.849474004526,"bookYear":null,"nameKR":"주작상회별채","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":38534,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6811239501457,"longitude":126.77389120548,"bookYear":null,"nameKR":"구일산장군집본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":38555,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wang.ttukkeong/","foodTypes":"한식(육류)","latitude":35.7914198453243,"longitude":127.135503278965,"bookYear":null,"nameKR":"왕뚜껑삼겹살","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38564,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.3267786018742,"longitude":127.425727914251,"bookYear":null,"nameKR":"정식당","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":38560,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5382364927306,"longitude":127.091306726945,"bookYear":null,"nameKR":"연스시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38570,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8199667401914,"longitude":127.104698235091,"bookYear":null,"nameKR":"도야","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38537,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nam_do0/","foodTypes":"한식(어패류)","latitude":35.1204770024834,"longitude":126.86567935615,"bookYear":null,"nameKR":"남도계절맛집","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":38522,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5111846372009,"longitude":126.671475645325,"bookYear":null,"nameKR":"온기족발","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":38547,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5199910567005,"longitude":126.733040838147,"bookYear":null,"nameKR":"돼지마당연탄소금구이","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":38556,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9302215355868,"longitude":126.948780988564,"bookYear":null,"nameKR":"우리집숯불구이","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38543,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_yeongojib","foodTypes":"한식(육류)","latitude":37.3066896913383,"longitude":126.815604302641,"bookYear":null,"nameKR":"제주연고집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":38524,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3179554420303,"longitude":126.839562631303,"bookYear":null,"nameKR":"왕곱","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":38533,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1343828355724,"longitude":126.858510824899,"bookYear":null,"nameKR":"아름다운사람들","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":38557,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4944147382622,"longitude":126.721942550206,"bookYear":null,"nameKR":"막걸리집개코네","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":38558,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5901476408667,"longitude":127.147512107842,"bookYear":null,"nameKR":"구리장군집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":38559,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.3362238821797,"longitude":126.810459252773,"bookYear":null,"nameKR":"춘참치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":38571,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.5146239374779,"longitude":127.257836453324,"bookYear":null,"nameKR":"저림숙성그릴","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":38572,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.9612389512791,"longitude":126.956816812311,"bookYear":null,"nameKR":"심야주방","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38579,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kumu_yakiton/","foodTypes":"바/주점","latitude":35.9518820464903,"longitude":126.940120995664,"bookYear":null,"nameKR":"쿠무","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38605,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.614044022101,"longitude":127.078202492525,"bookYear":null,"nameKR":"브라더야끼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":38608,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5648218822196,"longitude":127.03597450819,"bookYear":null,"nameKR":"제일곱창 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38578,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.3646124718786,"longitude":127.439304636904,"bookYear":null,"nameKR":"오리사랑","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":38609,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6407864624167,"longitude":127.027568243831,"bookYear":null,"nameKR":"조연탄","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":38604,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.3599313975327,"longitude":127.341987126686,"bookYear":null,"nameKR":"대전통닭","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":38593,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5926272807712,"longitude":127.053152028162,"bookYear":null,"nameKR":"여기가좋겠네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":38589,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bangmok135/ ","foodTypes":"한식(육류)","latitude":37.5879259086778,"longitude":127.007665956863,"bookYear":null,"nameKR":"방목 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":38606,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.57120477216,"longitude":127.038370793097,"bookYear":null,"nameKR":"손건호의한우일번지 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38607,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3627683363153,"longitude":127.378326996337,"bookYear":null,"nameKR":"강남회관","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":38586,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ddoddo_company","foodTypes":"바/주점","latitude":36.3607377576808,"longitude":127.42549498227,"bookYear":null,"nameKR":"또또포차","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":38585,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5419115057079,"longitude":127.068671696012,"bookYear":null,"nameKR":"환이네갈비살(본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38573,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.9523075415535,"longitude":126.941070374605,"bookYear":null,"nameKR":"배여사뼈다귀탕","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38602,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.4478849153353,"longitude":127.430906828975,"bookYear":null,"nameKR":"임단아갈비탕","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":38590,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5946129595778,"longitude":127.088231208808,"bookYear":null,"nameKR":"홍길동회수산","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":38594,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5954703227436,"longitude":127.089216903184,"bookYear":null,"nameKR":"함평국밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":38600,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5939509121813,"longitude":127.087988337978,"bookYear":null,"nameKR":"이주소곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":38611,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3620249997595,"longitude":127.377287970878,"bookYear":null,"nameKR":"제주돼지고맛","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":38581,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gojip132/","foodTypes":"한식(육류)","latitude":37.5465384293714,"longitude":127.045284549478,"bookYear":null,"nameKR":"고집132","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":38612,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.638573375179,"longitude":127.025141234309,"bookYear":null,"nameKR":"군산해물탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":38601,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hootari.seoul","foodTypes":"일식","latitude":37.5420106775817,"longitude":127.066035660944,"bookYear":null,"nameKR":"후타리 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38574,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.3058027072294,"longitude":127.334412190486,"bookYear":null,"nameKR":"정치망","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":38577,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yokuyamachi_official/","foodTypes":"일식","latitude":35.9525706881694,"longitude":126.939231514795,"bookYear":null,"nameKR":"요쿠야마치","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38582,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5608915756987,"longitude":127.039572245568,"bookYear":null,"nameKR":"양평회관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38580,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.959066669819,"longitude":126.990174177472,"bookYear":null,"nameKR":"술독","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38610,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jacob.joa/ ","foodTypes":"한식(육류)","latitude":37.564973660834,"longitude":127.036103049288,"bookYear":null,"nameKR":"왕십리맛나곱창 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38575,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.2973723400046,"longitude":127.337737426418,"bookYear":null,"nameKR":"인정쭈꾸미","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":38591,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.3738096945732,"longitude":127.419839762352,"bookYear":null,"nameKR":"이가촌","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":38588,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gyerim5252","foodTypes":"한식(가금류)","latitude":36.389400072237,"longitude":127.428475378335,"bookYear":null,"nameKR":"계림가든","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":38603,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5440415512187,"longitude":127.056627617429,"bookYear":null,"nameKR":"전통숯불꼼장어 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":38595,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5891758798869,"longitude":127.056010640964,"bookYear":null,"nameKR":"이모네파전 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":38599,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.3392674215975,"longitude":127.390261111179,"bookYear":null,"nameKR":"수정자갈치꼼장어","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":38598,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5834553888909,"longitude":127.02933612381,"bookYear":null,"nameKR":"삼성통닭","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":38583,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dobgamjatang/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5414795795911,"longitude":127.069266367393,"bookYear":null,"nameKR":"돕감자탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38576,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9525881953117,"longitude":126.939993879994,"bookYear":null,"nameKR":"공여사생갈비","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38587,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5927745404674,"longitude":127.018682247591,"bookYear":null,"nameKR":"응담말 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":38628,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6129853199329,"longitude":127.064730862556,"bookYear":null,"nameKR":"석계창신동매운족발1호점 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":38634,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.71403848368,"longitude":126.747507325706,"bookYear":null,"nameKR":"운정골감자탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":38629,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6457574095265,"longitude":126.786640909104,"bookYear":null,"nameKR":"순천만바다장어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":38653,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.898435585203,"longitude":127.057052331191,"bookYear":null,"nameKR":"풍천민물장어 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":38613,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/golbaengi9292/ ","foodTypes":"한식(어패류)","latitude":37.5632272785468,"longitude":127.033912640352,"bookYear":null,"nameKR":"왕십리통골뱅이 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38646,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5562465755289,"longitude":127.079304808205,"bookYear":null,"nameKR":"대명소곱창 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38617,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6773585479001,"longitude":126.755839709796,"bookYear":null,"nameKR":"너비아니","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":38640,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.6557494969152,"longitude":127.063095172733,"bookYear":null,"nameKR":"이자카야모리 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":38636,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6556680178593,"longitude":127.064812888402,"bookYear":null,"nameKR":"풍미연 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":38631,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6114809634041,"longitude":127.063525170982,"bookYear":null,"nameKR":"황가네소곱창 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":38627,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.640680612332,"longitude":126.789785972319,"bookYear":null,"nameKR":"워낭소리정육점식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":38649,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.8176524260841,"longitude":127.097989756315,"bookYear":null,"nameKR":"꼬리치는바다 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":38624,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gaepodongdak/ ","foodTypes":"한식(가금류)","latitude":37.5635845718427,"longitude":127.067082990511,"bookYear":null,"nameKR":"계포동 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38621,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5688108189618,"longitude":127.069843827191,"bookYear":null,"nameKR":"신원식당 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38641,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gsangmok","foodTypes":"한식(어패류)","latitude":37.6125267278698,"longitude":127.032579747918,"bookYear":null,"nameKR":"미아리곰장어아나고 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":38623,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.672270488681,"longitude":127.055433513999,"bookYear":null,"nameKR":"청와정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":38635,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9600087373501,"longitude":126.978968993872,"bookYear":null,"nameKR":"오가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38644,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.6384773023004,"longitude":126.832412554233,"bookYear":null,"nameKR":"꼬발","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":38614,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5604218763954,"longitude":127.033169015402,"bookYear":null,"nameKR":"푸줏간생고기점 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38638,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.6559085228327,"longitude":127.065568639538,"bookYear":null,"nameKR":"또와순두부 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":38650,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.632314138828,"longitude":126.832490404258,"bookYear":null,"nameKR":"제주통돼지","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":38645,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.9596664613364,"longitude":126.977558258838,"bookYear":null,"nameKR":"땡초화끈이","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38648,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jangyunchang_/","foodTypes":"한식(가금류)","latitude":35.8376698788572,"longitude":127.057244030431,"bookYear":null,"nameKR":"장군닭발","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":38637,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7148528266922,"longitude":126.761460859566,"bookYear":null,"nameKR":"똘이장군돼지부속","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":38643,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6121284151011,"longitude":127.032198910006,"bookYear":null,"nameKR":"조개일번지 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":38633,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6136412541298,"longitude":127.064374997257,"bookYear":null,"nameKR":"수양숯불갈비 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":38630,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.9620708103314,"longitude":126.989558885256,"bookYear":null,"nameKR":"고기바다","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38619,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6601982030916,"longitude":127.074209796325,"bookYear":null,"nameKR":"오늘은오징어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":38615,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5737918444142,"longitude":127.072150196589,"bookYear":null,"nameKR":"미식회 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38618,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/namdobossam/ ","foodTypes":"한식(육류)","latitude":37.5743253947157,"longitude":127.071291169328,"bookYear":null,"nameKR":"남도보쌈파전 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38620,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5704687024445,"longitude":127.071562106777,"bookYear":null,"nameKR":"BK볏짚우대갈비 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38625,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/himchan87/","foodTypes":"한식(어패류)","latitude":37.558190113832,"longitude":127.079576569907,"bookYear":null,"nameKR":"힘찬갯벌낙지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38652,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8430351151737,"longitude":127.13478872986,"bookYear":null,"nameKR":"통집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38654,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6357303034438,"longitude":126.831787671492,"bookYear":null,"nameKR":"주막집누룽지통닭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":38626,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.6824237571623,"longitude":126.803816283198,"bookYear":null,"nameKR":"털랭이매운탕고향집본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":38616,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":36.3511163670385,"longitude":127.375230491184,"bookYear":null,"nameKR":"벼","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":38655,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5992131179895,"longitude":127.138534369064,"bookYear":null,"nameKR":"덴마크마가린 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":38651,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8379385259391,"longitude":127.1329104461,"bookYear":null,"nameKR":"새움가맥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38639,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.7148528266922,"longitude":126.761460859566,"bookYear":null,"nameKR":"제주몬딱","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":38642,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.9596556869931,"longitude":126.977776087947,"bookYear":null,"nameKR":"두콩이네","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38666,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.2982111252396,"longitude":127.574363954449,"bookYear":null,"nameKR":"일미해장국","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":38670,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6464912327182,"longitude":127.23612233691,"bookYear":null,"nameKR":"두께","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38667,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.7274087399869,"longitude":127.191408991109,"bookYear":null,"nameKR":"본토박이장작구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38677,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6458176125245,"longitude":127.126953662679,"bookYear":null,"nameKR":"황소곱창곱돌이네","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38682,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7433862657765,"longitude":127.056402689509,"bookYear":null,"nameKR":"곱빼기 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":38659,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.896716635403,"longitude":127.194843315158,"bookYear":null,"nameKR":"부부막창,꼼장어 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":38673,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6244724937349,"longitude":127.151660064243,"bookYear":null,"nameKR":"은희네랭삼 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38681,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.7429307356182,"longitude":127.056591365198,"bookYear":null,"nameKR":"동오회마을 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":38680,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.6695820071007,"longitude":127.11880667062,"bookYear":null,"nameKR":"장어의꿈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38791,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8296528403929,"longitude":127.175090784672,"bookYear":null,"nameKR":"백김치새싹막회본점","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38671,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.623539489025,"longitude":127.153150811934,"bookYear":null,"nameKR":"총각네횟집 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38669,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3584027741264,"longitude":127.336513967503,"bookYear":null,"nameKR":"만수불고기","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":38663,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.8304763947637,"longitude":127.141851435608,"bookYear":null,"nameKR":"돼지네연탄구이 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":38664,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6568411144582,"longitude":127.304026504962,"bookYear":null,"nameKR":"마석고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38665,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7463829601483,"longitude":127.096777530357,"bookYear":null,"nameKR":"나주명품한우정육식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":38675,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.6214339584306,"longitude":127.160152666796,"bookYear":null,"nameKR":"십이월 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38656,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.8980490175918,"longitude":127.199483339475,"bookYear":null,"nameKR":"일미닭갈비 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":38789,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5373317780025,"longitude":127.126333225079,"bookYear":null,"nameKR":"쭈꾸쭈꾸쭈꾸미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":38684,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.7371676050615,"longitude":127.049993979378,"bookYear":null,"nameKR":"애경이네 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":38686,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7379091350301,"longitude":127.04884329544,"bookYear":null,"nameKR":"포탄 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":38678,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.746994484417,"longitude":127.041107135566,"bookYear":null,"nameKR":"지해림","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":38672,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.4048455472333,"longitude":127.403288739701,"bookYear":null,"nameKR":"전미원","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":38679,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.3565758457658,"longitude":127.340133806426,"bookYear":null,"nameKR":"장수진곰탕","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":38685,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.7411809215587,"longitude":127.048171065509,"bookYear":null,"nameKR":"규카","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":38657,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6610286903147,"longitude":126.76830760753,"bookYear":null,"nameKR":"동해안","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":38662,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.3467755123157,"longitude":127.291748403946,"bookYear":null,"nameKR":"수통골감나무집","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":38676,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3547812568731,"longitude":127.344845881385,"bookYear":null,"nameKR":"제주똥돼지오겹살","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":38790,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5571612041475,"longitude":126.90875552122,"bookYear":null,"nameKR":"윤수산","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":38683,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7373198658697,"longitude":127.050001229095,"bookYear":null,"nameKR":"서돈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":38661,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.0370118445405,"longitude":127.367151849925,"bookYear":null,"nameKR":"별미돼지 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":38674,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.397751343686,"longitude":127.402766516699,"bookYear":null,"nameKR":"한우곰탕","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":38660,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6651331847469,"longitude":126.766567670006,"bookYear":null,"nameKR":"우야우야대패삼겹살","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":38836,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4909396138947,"longitude":127.011409590508,"bookYear":null,"nameKR":"삼척포구","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":38861,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2465367207959,"longitude":127.055052400968,"bookYear":null,"nameKR":"팔팔정육식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38891,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.8066803370393,"longitude":126.373336223383,"bookYear":null,"nameKR":"팔금식육식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38878,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2061292804496,"longitude":129.079187409802,"bookYear":null,"nameKR":"동래이모","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":38886,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.855707716537,"longitude":128.553490614854,"bookYear":null,"nameKR":"박대포소금구이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38812,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5622964595541,"longitude":126.991942903816,"bookYear":null,"nameKR":"부산복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":38874,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/haeba_official/ ","foodTypes":"바/주점","latitude":37.5045392778392,"longitude":126.749896868595,"bookYear":null,"nameKR":"해바 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":38867,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.3468520528474,"longitude":127.110837968908,"bookYear":null,"nameKR":"전통춘천닭갈비 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38881,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8546183663274,"longitude":128.551366842446,"bookYear":null,"nameKR":"광코북성로","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38838,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2720843563038,"longitude":127.055032437508,"bookYear":null,"nameKR":"대가원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38883,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dongin2530407","foodTypes":"한식(어패류)","latitude":35.865294700674,"longitude":128.606836214279,"bookYear":null,"nameKR":"동인회식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":38877,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ddobomsae/ ","foodTypes":"바/주점","latitude":37.5045392778392,"longitude":126.749896868595,"bookYear":null,"nameKR":"또봄세 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":38889,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.9241011180067,"longitude":128.075491098437,"bookYear":null,"nameKR":"동강횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":38876,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.8385510230032,"longitude":128.753427773587,"bookYear":null,"nameKR":"영풍","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":38880,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5035811578707,"longitude":126.761622562168,"bookYear":null,"nameKR":"만수 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":38845,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4958057253368,"longitude":127.027547429997,"bookYear":null,"nameKR":"청간막국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38879,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8666889409727,"longitude":128.607799640799,"bookYear":null,"nameKR":"소나무향","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":38842,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mellowgarden2015/","foodTypes":"바/주점","latitude":37.5039900570348,"longitude":127.026171009331,"bookYear":null,"nameKR":"멜로우가든(강남점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":38872,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bbung_gurae/ ","foodTypes":"한식(육류)","latitude":37.6447241603272,"longitude":126.623460231274,"bookYear":null,"nameKR":"뻥쟁이네 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":38792,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8189095199691,"longitude":127.1174244922,"bookYear":null,"nameKR":"삐루봉가맥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38882,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jangsoohaneulso/","foodTypes":"한식(육류)","latitude":35.2138452275343,"longitude":126.851262498162,"bookYear":null,"nameKR":"장수하늘소","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38866,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3235527121221,"longitude":127.097247974527,"bookYear":null,"nameKR":"수지곱창 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":38888,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.3023517568194,"longitude":127.97069304945,"bookYear":null,"nameKR":"대박참숯장어","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":38859,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.5352926197364,"longitude":129.323383945412,"bookYear":null,"nameKR":"호박꽃본점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":38885,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.8697117709602,"longitude":128.591592528391,"bookYear":null,"nameKR":"대통조계찜","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":38839,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.2800190326622,"longitude":127.112632110682,"bookYear":null,"nameKR":"해삼 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":38865,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/osan_choshim/ ","foodTypes":"한식(육류)","latitude":37.1497516527238,"longitude":127.074789603467,"bookYear":null,"nameKR":"초심","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":38871,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.4876733915058,"longitude":126.479249297516,"bookYear":null,"nameKR":"무한조개야","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":38890,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lounge_jumin/ ","foodTypes":"바/주점","latitude":37.5345605849968,"longitude":126.736736610332,"bookYear":null,"nameKR":"주민회관","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":38863,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2683225050687,"longitude":126.956423555594,"bookYear":null,"nameKR":"향촌숯불갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38793,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.8085606773646,"longitude":128.520262983512,"bookYear":null,"nameKR":"취팔선(진천점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38844,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.005154811823,"longitude":127.085220187169,"bookYear":null,"nameKR":"최부자숯불갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":38846,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.49494258899,"longitude":127.027426341397,"bookYear":null,"nameKR":"청류벽","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38840,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4888282723171,"longitude":127.014017292973,"bookYear":null,"nameKR":"예향","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":38864,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2752511506869,"longitude":126.953353369617,"bookYear":null,"nameKR":"해태식육점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38870,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.999018325702,"longitude":127.080218890599,"bookYear":null,"nameKR":"1980연탄장수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":38847,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.8234670157741,"longitude":128.623327531306,"bookYear":null,"nameKR":"홍끼오","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":38794,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.099314542652,"longitude":129.02720112829,"bookYear":null,"nameKR":"부평서래갈매기","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":38843,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.49494258899,"longitude":127.027426341397,"bookYear":null,"nameKR":"놀란치킨(강남역본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38936,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/BLACKDOYAJI","foodTypes":"한식(육류)","latitude":37.5142207671959,"longitude":127.061618047272,"bookYear":null,"nameKR":"흑도야지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":38893,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":34.8075971449936,"longitude":126.374966383721,"bookYear":null,"nameKR":"달동네","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38901,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8110445939171,"longitude":127.110290360082,"bookYear":null,"nameKR":"족산","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38910,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2361593124812,"longitude":127.204770276755,"bookYear":null,"nameKR":"큰손주먹고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":38925,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.3908896841851,"longitude":126.954651035432,"bookYear":null,"nameKR":"범계갯바위횟집 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38906,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4122293517827,"longitude":126.678224633289,"bookYear":null,"nameKR":"소시민한우갈비살 ","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":38918,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8422289264545,"longitude":128.531032814861,"bookYear":null,"nameKR":"싱싱포차산오징어","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38919,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wooki_sikdang/","foodTypes":"바/주점","latitude":37.5090322024595,"longitude":127.023450826915,"bookYear":null,"nameKR":"우기식당(육지점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":38905,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":34.7868121083111,"longitude":126.387081994437,"bookYear":null,"nameKR":"건맥펍","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":38911,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5229050350666,"longitude":127.056494441884,"bookYear":null,"nameKR":"청담항","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":38897,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/duckdamhome_official/ ","foodTypes":"한식(가금류)","latitude":37.3975857583899,"longitude":126.650666499516,"bookYear":null,"nameKR":"덕담집 ","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":38931,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4120880740871,"longitude":127.131187930663,"bookYear":null,"nameKR":"서울감자탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38898,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2064999586634,"longitude":129.079410963655,"bookYear":null,"nameKR":"가련한부엌(동래점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":38923,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gobterang","foodTypes":"한식(육류)","latitude":35.1559885179593,"longitude":129.060475832432,"bookYear":null,"nameKR":"곱테랑","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":38930,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sanchonsutbul/ ","foodTypes":"한식(육류)","latitude":37.5022958880396,"longitude":126.771336799527,"bookYear":null,"nameKR":"산촌숯불정 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":38912,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.0053439998059,"longitude":129.336214753958,"bookYear":null,"nameKR":"강변육숫간","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":38900,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.385183475728,"longitude":126.644007327864,"bookYear":null,"nameKR":"바다를쌈하다","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":38929,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6574073840157,"longitude":126.770243392308,"bookYear":null,"nameKR":"강릉집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":38926,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4684653028745,"longitude":127.043035126315,"bookYear":null,"nameKR":"소백산","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":38904,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4126163559755,"longitude":126.677470679914,"bookYear":null,"nameKR":"손수갈비 ","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":38933,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4908556061437,"longitude":127.033428947382,"bookYear":null,"nameKR":"다슬음","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38908,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2068010812978,"longitude":129.079696646584,"bookYear":null,"nameKR":"불난불족","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":38935,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.9487231816037,"longitude":127.189517162805,"bookYear":null,"nameKR":"한옥생고기 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":38922,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3908896841851,"longitude":126.954651035432,"bookYear":null,"nameKR":"부추삼겹살","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38913,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2360237867854,"longitude":128.86617783945,"bookYear":null,"nameKR":"황금조개튼튼장어","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":38927,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.3977452704965,"longitude":127.11143339349,"bookYear":null,"nameKR":"제일호프","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":38899,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.1745374753438,"longitude":128.607891879232,"bookYear":null,"nameKR":"어부지리","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":38902,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joeunso09/ ","foodTypes":"한식(육류)","latitude":37.4447436024812,"longitude":126.703336171317,"bookYear":null,"nameKR":"와이리좋은소가 ","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":38915,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.398141374454,"longitude":126.923369502285,"bookYear":null,"nameKR":"원조안양감자탕 ","ribbonType":"정보없음","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":38924,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.4878823602855,"longitude":126.478472682903,"bookYear":null,"nameKR":"제주돔베고기집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":38907,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3691620094062,"longitude":126.943151747638,"bookYear":null,"nameKR":"우리한우소곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":38921,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.4877445657477,"longitude":126.478829555903,"bookYear":null,"nameKR":"이인자뼈다귀해장국","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":38909,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":34.8112277651701,"longitude":126.461239272054,"bookYear":null,"nameKR":"꼴통","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":38894,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1013500597004,"longitude":128.811214459681,"bookYear":null,"nameKR":"오징어먹물","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":38934,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2067630648818,"longitude":129.078987823711,"bookYear":null,"nameKR":"미소","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":38896,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.379305344604,"longitude":126.64614896978,"bookYear":null,"nameKR":"송도어멍 ","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":38939,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.3248699959622,"longitude":127.098043632826,"bookYear":null,"nameKR":"물고기자리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":38917,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4756904784083,"longitude":126.86930038251,"bookYear":null,"nameKR":"구름산곱창 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":38978,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5635280951081,"longitude":127.066737271164,"bookYear":null,"nameKR":"전국통일닭발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38955,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3131718370985,"longitude":127.435871876243,"bookYear":null,"nameKR":"강쇠네소곱창 ","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":38942,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6338125904837,"longitude":127.118322701737,"bookYear":null,"nameKR":"뚜삼 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":38975,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5375769086631,"longitude":127.083844149774,"bookYear":null,"nameKR":"풍성감자탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38968,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.1156588545021,"longitude":126.910583405813,"bookYear":null,"nameKR":"검은소새끼","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38962,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8665124199243,"longitude":127.122080490661,"bookYear":null,"nameKR":"바다횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38970,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4819900046278,"longitude":126.899546268465,"bookYear":null,"nameKR":"최소공배수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":38961,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5299948883491,"longitude":126.919907178495,"bookYear":null,"nameKR":"독토","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":38979,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5470776782067,"longitude":127.044332175795,"bookYear":null,"nameKR":"수원원조갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38944,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5999698102377,"longitude":127.138883081255,"bookYear":null,"nameKR":"품앗이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":38965,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.9523552300282,"longitude":126.989214112753,"bookYear":null,"nameKR":"백제가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":38964,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4836971479875,"longitude":126.900722120207,"bookYear":null,"nameKR":"이화참숯갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":38980,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.6135873699426,"longitude":127.031254372617,"bookYear":null,"nameKR":"판다","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":38971,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5598562142859,"longitude":126.834854214935,"bookYear":null,"nameKR":"천지의간","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":38943,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2327793868862,"longitude":127.187420199813,"bookYear":null,"nameKR":"역북집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":39074,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4815193451441,"longitude":126.929469476671,"bookYear":null,"nameKR":"박재성의메밀숯불닭갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":38941,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6566174316073,"longitude":127.304748048254,"bookYear":null,"nameKR":"마석호반닭갈비 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38957,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8448986721181,"longitude":127.125266532424,"bookYear":null,"nameKR":"슬기네가맥 ","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38960,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.817212056777,"longitude":127.116342115033,"bookYear":null,"nameKR":"소문난가맥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38967,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/janggun_gg","foodTypes":"바/주점","latitude":37.48397178331,"longitude":126.901809898362,"bookYear":null,"nameKR":"장군산꼼장어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":38948,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leess8961","foodTypes":"한식(육류)","latitude":37.6890123061415,"longitude":126.874142214025,"bookYear":null,"nameKR":"벽제농원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":38966,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4837384725133,"longitude":126.901006424159,"bookYear":null,"nameKR":"갯벌해물탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":38959,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.479846554903,"longitude":126.95493458723,"bookYear":null,"nameKR":"테이블서울","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":38947,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4784626402783,"longitude":126.983370422789,"bookYear":null,"nameKR":"남궁야","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":39030,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.283318767758,"longitude":127.439841610224,"bookYear":null,"nameKR":"연안부두조개구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":39051,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2765906848096,"longitude":127.116249127673,"bookYear":null,"nameKR":"소출구","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":38976,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5623797363841,"longitude":127.066005079294,"bookYear":null,"nameKR":"해물알탕전문","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":38958,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1534901089174,"longitude":126.811365291664,"bookYear":null,"nameKR":"청정바다회","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":38946,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/neulsang_/ ","foodTypes":"기타","latitude":37.6572869218154,"longitude":126.770984132251,"bookYear":null,"nameKR":"늘상웨돔 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":38956,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8871394630025,"longitude":128.634349928315,"bookYear":null,"nameKR":"연탄석쇠구이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":38973,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4969397553084,"longitude":126.953540835787,"bookYear":null,"nameKR":"상도곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":38951,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.2639381516252,"longitude":127.035394043077,"bookYear":null,"nameKR":"못난이세꼬시쭈꾸미","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38952,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.6533085269766,"longitude":126.84408432981,"bookYear":null,"nameKR":"원당골추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":38977,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mongryeon8525/","foodTypes":"한식(육류)","latitude":37.547142735723,"longitude":127.045032226992,"bookYear":null,"nameKR":"몽련","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":38972,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.131270347668,"longitude":126.915666750577,"bookYear":null,"nameKR":"어부화성점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38954,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4784677623485,"longitude":126.956434327005,"bookYear":null,"nameKR":"숯불호랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":38974,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5695531419965,"longitude":127.181903348564,"bookYear":null,"nameKR":"미사생고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":38963,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.6883330197894,"longitude":126.869382636012,"bookYear":null,"nameKR":"통일로장어촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":38940,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/honghong_deunggalbi","foodTypes":"한식(육류)","latitude":35.1723079610453,"longitude":128.824022845759,"bookYear":null,"nameKR":"홍홍식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":39090,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1687288911876,"longitude":129.113512436669,"bookYear":null,"nameKR":"수영냉삼","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":39102,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2532177913463,"longitude":127.490011691496,"bookYear":null,"nameKR":"청산참갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":39094,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4925029321777,"longitude":126.721957440081,"bookYear":null,"nameKR":"돼지코회조개구이","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":39111,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2465794618056,"longitude":127.057637318689,"bookYear":null,"nameKR":"냉삼집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39091,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1689710406661,"longitude":129.112231078423,"bookYear":null,"nameKR":"유명갈매기살","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":39119,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.5152816415039,"longitude":127.25848313191,"bookYear":null,"nameKR":"도담도담","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":39089,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8149117564381,"longitude":127.108150891627,"bookYear":null,"nameKR":"가람","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":39076,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4875743672386,"longitude":126.928084432906,"bookYear":null,"nameKR":"서울집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":39092,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/masan.yakiniku_1/","foodTypes":"일식","latitude":35.2198444742832,"longitude":128.583184232782,"bookYear":null,"nameKR":"마산가옥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":39077,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.487707824856,"longitude":126.929008660932,"bookYear":null,"nameKR":"미가할매집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":39120,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3583866985559,"longitude":127.345961118306,"bookYear":null,"nameKR":"박봉명생갈비","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":39079,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4991772389382,"longitude":126.776228325111,"bookYear":null,"nameKR":"하이타치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":39078,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5079080178341,"longitude":126.732055331217,"bookYear":null,"nameKR":"추동의정부부대찌개","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":39121,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goldenpork_sejong/","foodTypes":"한식(육류)","latitude":36.4903246735595,"longitude":127.257030692034,"bookYear":null,"nameKR":"금도새기","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":39080,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7406811183554,"longitude":127.048037545725,"bookYear":null,"nameKR":"양동이와주전자","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":39097,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7278742473648,"longitude":126.736643429474,"bookYear":null,"nameKR":"산내대장군집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":39110,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2138035714292,"longitude":127.040261451067,"bookYear":null,"nameKR":"두껍집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39081,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.7457627298836,"longitude":127.094898464587,"bookYear":null,"nameKR":"난닭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":39087,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8492611759302,"longitude":127.075726709264,"bookYear":null,"nameKR":"하면돼지","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":39125,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.847430437216,"longitude":127.154142365199,"bookYear":null,"nameKR":"새싹막회","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":39123,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.4901861961628,"longitude":127.258310218653,"bookYear":null,"nameKR":"탄영","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":39113,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4466461186089,"longitude":126.703959361598,"bookYear":null,"nameKR":"연탄구이포차","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":39112,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5008668612539,"longitude":126.773846342542,"bookYear":null,"nameKR":"화사랑갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":39109,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6131888904549,"longitude":127.031149099822,"bookYear":null,"nameKR":"오복닭한마리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":39100,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.7009625042974,"longitude":126.759052097916,"bookYear":null,"nameKR":"황금장어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":39115,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.3592540547346,"longitude":126.93237417283,"bookYear":null,"nameKR":"자갈치숯불꼼장어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":39114,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.412447823018,"longitude":126.677719231039,"bookYear":null,"nameKR":"아리산체","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":39088,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeonju_repeat/","foodTypes":"바/주점","latitude":35.8208460374366,"longitude":127.103521639678,"bookYear":null,"nameKR":"리피트","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":39118,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6912481783515,"longitude":126.762102593526,"bookYear":null,"nameKR":"신숯불닭갈비굽꼬","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":39116,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5901979342445,"longitude":127.145829897253,"bookYear":null,"nameKR":"키노야(토평본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":39108,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5500793711751,"longitude":126.915422795699,"bookYear":null,"nameKR":"마포오리농장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":39104,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.7441318472837,"longitude":128.863089004248,"bookYear":null,"nameKR":"부창오리","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":39103,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.8962160270988,"longitude":128.614690494413,"bookYear":null,"nameKR":"정동진아나고","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":39086,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8369646476301,"longitude":127.057237214935,"bookYear":null,"nameKR":"먹튀","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":39096,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7641381191094,"longitude":126.773297114564,"bookYear":null,"nameKR":"고조선","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":39126,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1601884357932,"longitude":126.846592375468,"bookYear":null,"nameKR":"상무암뽕순대국밥","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":39093,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4647798215972,"longitude":126.79204474292,"bookYear":null,"nameKR":"앞뜰장작구이마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":39124,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8190569068828,"longitude":127.150187832652,"bookYear":null,"nameKR":"임실슈퍼가맥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":39085,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8367304092323,"longitude":127.057240145773,"bookYear":null,"nameKR":"고모네막창","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":39336,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.6491689619108,"longitude":127.488081746365,"bookYear":null,"nameKR":"손복성돼지갈비 ","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":39331,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4956106417211,"longitude":126.72747192947,"bookYear":null,"nameKR":"부일안창살 ","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":39130,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2057004749825,"longitude":129.081009051352,"bookYear":null,"nameKR":"83비프","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":39329,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/g.t_sashimi_restaurant/ ","foodTypes":"한식(어패류)","latitude":37.1045634609504,"longitude":126.898875627517,"bookYear":null,"nameKR":"감탄회수산 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39326,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.2697565625078,"longitude":127.003096298245,"bookYear":null,"nameKR":"천지양꼬치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39335,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6077291377941,"longitude":127.137879481208,"bookYear":null,"nameKR":"오프로고깃집 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":39134,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2622356259935,"longitude":128.626095252754,"bookYear":null,"nameKR":"이가네가야밀면","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":39337,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4852327678424,"longitude":126.810112394852,"bookYear":null,"nameKR":"착한돈삼이네본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":39138,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hippie_hippy_","foodTypes":"한식(어패류)","latitude":35.1496757430153,"longitude":126.922787602828,"bookYear":null,"nameKR":"희피희피","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":39135,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.3070143885507,"longitude":128.732529700997,"bookYear":null,"nameKR":"조개대장(진영본점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":39334,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5514118073516,"longitude":126.921211604379,"bookYear":null,"nameKR":"작가 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":39129,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8570285732316,"longitude":128.574459460052,"bookYear":null,"nameKR":"진해숯불막창","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":39327,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.157572758388,"longitude":129.063963508884,"bookYear":null,"nameKR":"고하순 ","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":39330,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4134549251236,"longitude":127.130694874946,"bookYear":null,"nameKR":"황금어장 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":39136,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.2380886306142,"longitude":128.585012382193,"bookYear":null,"nameKR":"김나현토담참숯닭갈비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":39328,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.503948100333,"longitude":126.750607495796,"bookYear":null,"nameKR":"회진포낙지마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":39131,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sulnabang_bar/","foodTypes":"바/주점","latitude":35.155107894613,"longitude":129.066241695188,"bookYear":null,"nameKR":"술나방바","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":39332,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2244331432199,"longitude":127.062852835478,"bookYear":null,"nameKR":"진토김치생고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39509,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7316688869855,"longitude":126.736616718063,"bookYear":null,"nameKR":"이한돈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":39128,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1737046344346,"longitude":126.813787682623,"bookYear":null,"nameKR":"코끼리아구찜","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":39133,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1868239448987,"longitude":129.084993868562,"bookYear":null,"nameKR":"잔치가","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":39508,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.5035801828617,"longitude":126.49795471004,"bookYear":null,"nameKR":"먹돌제주본점","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":39270,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/tsukiyowa.dining/","foodTypes":"바/주점","latitude":37.5261419852792,"longitude":127.041483638144,"bookYear":null,"nameKR":"츠키요와","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39268,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/revedoux_seoul","foodTypes":"디저트/차/베이커리","latitude":37.5097691697002,"longitude":127.109319190485,"bookYear":null,"nameKR":"레브두","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":39254,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/monbrewcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.6437471002276,"longitude":127.003893348253,"bookYear":null,"nameKR":"몽브루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":39252,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/clement_coucou","foodTypes":"프랑스식","latitude":37.55384614365,"longitude":126.976694256314,"bookYear":null,"nameKR":"끌레망꾸꾸","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":39251,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/2bottle__/","foodTypes":"바/주점","latitude":35.216368244848,"longitude":126.848970953365,"bookYear":null,"nameKR":"투바틀","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":39223,"bookStatus":"커버","websiteInstagram":"","foodTypes":"기타","latitude":37.5338843799527,"longitude":126.988815922078,"bookYear":null,"nameKR":"봉비방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":39202,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tampa_sandwichbar/","foodTypes":"기타","latitude":37.5266163122588,"longitude":127.034032607667,"bookYear":null,"nameKR":"탬파(압구정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":39201,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/monpin_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5990094558034,"longitude":126.95906059355,"bookYear":null,"nameKR":"몽핀","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":39199,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/omakase_seiryu/","foodTypes":"일식","latitude":37.5174846188512,"longitude":127.103444795508,"bookYear":null,"nameKR":"세이류","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":39198,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/haewol_seoul/","foodTypes":"일식","latitude":37.5213133462612,"longitude":127.036240598407,"bookYear":null,"nameKR":"해월","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39196,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/toudou_chocolate/","foodTypes":"디저트/차/베이커리","latitude":37.4977006725584,"longitude":126.997934594486,"bookYear":null,"nameKR":"뚜두","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":39195,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/el.molino_sungsu/","foodTypes":"중남미식","latitude":37.5474335467852,"longitude":127.04116862477,"bookYear":null,"nameKR":"엘몰리노","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":39194,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/sushi_motoi/","foodTypes":"일식","latitude":37.5224116192579,"longitude":127.042346966928,"bookYear":null,"nameKR":"스시모토이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":39193,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/yidocheongdam/","foodTypes":"이탈리아식","latitude":37.5243352600843,"longitude":127.044788076146,"bookYear":null,"nameKR":"이도청담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":38916,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/wooki_sikdang/","foodTypes":"한식(어패류)","latitude":37.5086773753858,"longitude":127.023051010941,"bookYear":null,"nameKR":"우기식당(바다점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":38786,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/cafe.forest36","foodTypes":"디저트/차/베이커리","latitude":35.9910169859465,"longitude":128.297416029149,"bookYear":null,"nameKR":"포레스트36","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"성주군","status":"ACTIVE"},
        {"id":38988,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.3134192431136,"longitude":126.828673304277,"bookYear":null,"nameKR":"돈까스쿨","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":38784,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/noran_kor/","foodTypes":"디저트/차/베이커리","latitude":35.9523593802439,"longitude":128.211030148843,"bookYear":null,"nameKR":"카페노랑","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"성주군","status":"ACTIVE"},
        {"id":38782,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.7537205069651,"longitude":126.66143292923,"bookYear":null,"nameKR":"차담","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영암군","status":"ACTIVE"},
        {"id":38592,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ricky_cjy/ ","foodTypes":"한식(육류)","latitude":37.5956050754903,"longitude":127.061466437544,"bookYear":null,"nameKR":"도란도란곱창(별관)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":38778,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/","foodTypes":"디저트/차/베이커리","latitude":34.3189419575153,"longitude":126.749463111661,"bookYear":null,"nameKR":"카페더비치","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"완도군","status":"ACTIVE"},
        {"id":38841,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8813728817392,"longitude":128.652283857138,"bookYear":null,"nameKR":"마루막창(동촌점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":38718,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/thesiena_lounge/","foodTypes":"이탈리아식","latitude":37.5271137538004,"longitude":127.046692140286,"bookYear":null,"nameKR":"벨라비타","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":6254,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.346815,"longitude":127.111351,"bookYear":null,"nameKR":"생포탄","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":17321,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4752599020908,"longitude":126.96669613991,"bookYear":null,"nameKR":"소문난집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":25286,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.542334,"longitude":127.07013,"bookYear":null,"nameKR":"호호닭발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"","status":"ACTIVE"},
        {"id":37210,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sumizzim_?igsh=bXgybGNrN2J0eG9t&utm_source=qr","foodTypes":"한식(어패류)","latitude":35.0924393538552,"longitude":128.979456129076,"bookYear":null,"nameKR":"구포수미아구찜","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":39433,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daljilong","foodTypes":"디저트/차/베이커리","latitude":37.4780927958354,"longitude":126.85816906335,"bookYear":null,"nameKR":"달지롱","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":35121,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.6194660910821,"longitude":127.526674242766,"bookYear":null,"nameKR":"고깃집,용박골 청주동남지구점","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36421,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5250006298626,"longitude":127.03617269556,"bookYear":null,"nameKR":"리틀루비스카페(도산본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":27362,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.579143,"longitude":126.986218,"bookYear":null,"nameKR":"비엣콴","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8777,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.579214,"longitude":126.973669,"bookYear":null,"nameKR":"디미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":37987,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":34.8583760314645,"longitude":128.427603941728,"bookYear":null,"nameKR":"하원황칠오리(통영점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":37817,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/kookilsoup","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5570808780398,"longitude":126.9175086352,"bookYear":null,"nameKR":"국일순대국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":39466,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ho_sam_jung","foodTypes":"아시아식","latitude":35.2297952205418,"longitude":129.087779849671,"bookYear":null,"nameKR":"호삼정(부산대본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":39211,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/obbang_no3","foodTypes":"디저트/차/베이커리","latitude":36.5753507070191,"longitude":128.489889504041,"bookYear":null,"nameKR":"오빵(경북도청점)","ribbonType":"정보없음","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":39045,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1915091661137,"longitude":129.083672921588,"bookYear":null,"nameKR":"메가리국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":38731,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/oraund_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.3713368738573,"longitude":127.24637508751,"bookYear":null,"nameKR":"오라운트로스터리카페","ribbonType":"정보없음","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":37826,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/katsu_morizo/","foodTypes":"일식","latitude":35.8142524095602,"longitude":127.128464972492,"bookYear":null,"nameKR":"카츠모리조","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":30428,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.48714228876759,"longitude":127.01559464694301,"bookYear":null,"nameKR":"더진미평냉","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":37430,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/osaka.menchi.dd/","foodTypes":"일식","latitude":35.5322442102975,"longitude":129.319946817805,"bookYear":null,"nameKR":"오사카멘치(달동점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":39507,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gontran_pyeongchon/","foodTypes":"디저트/차/베이커리","latitude":37.3823056738098,"longitude":126.959474751631,"bookYear":null,"nameKR":"곤트란쉐리에(평촌점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":39504,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sonsubakery/","foodTypes":"디저트/차/베이커리","latitude":36.2965537797755,"longitude":127.34287197749,"bookYear":null,"nameKR":"손수베이커리","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":39503,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.2233047547854,"longitude":128.686826782131,"bookYear":null,"nameKR":"니재빵집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":39264,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1565695165869,"longitude":129.061560231883,"bookYear":null,"nameKR":"오성가든","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":39453,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/morgen_sillim/","foodTypes":"한식(육류)","latitude":37.4700997615221,"longitude":126.933657542377,"bookYear":null,"nameKR":"모르겐고깃집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":33869,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/here.coffee.roasters/","foodTypes":"디저트/차/베이커리","latitude":37.4681122261649,"longitude":127.041440246627,"bookYear":null,"nameKR":"히어커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36998,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5027247041978,"longitude":126.934757587446,"bookYear":null,"nameKR":"카페모닥불","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":39413,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/c.tytd/","foodTypes":"디저트/차/베이커리","latitude":37.5584928774283,"longitude":126.924163186253,"bookYear":null,"nameKR":"티와이티디","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":39440,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/dailyartstory_artcomplex","foodTypes":"디저트/차/베이커리","latitude":37.2604153561229,"longitude":127.140986275283,"bookYear":null,"nameKR":"데일리아트스토리(용인점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":39432,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5242915298626,"longitude":126.929338616058,"bookYear":null,"nameKR":"스시유(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":39281,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/line_number3","foodTypes":"중식","latitude":37.5878302208378,"longitude":126.946854326925,"bookYear":null,"nameKR":"3호선차이나","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":39303,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_mmotto_","foodTypes":"디저트/차/베이커리","latitude":37.4419835956588,"longitude":126.670630220364,"bookYear":null,"nameKR":"못또","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":39344,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwaro._.joong_hwa/","foodTypes":"한식(육류)","latitude":37.599688702066,"longitude":127.079466740593,"bookYear":null,"nameKR":"화로상회(중화점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":39502,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nongwoobone/","foodTypes":"한식(육류)","latitude":37.2731665904928,"longitude":127.08992263002,"bookYear":null,"nameKR":"농우본갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":39499,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5242915298626,"longitude":126.929338616058,"bookYear":null,"nameKR":"신홍러우(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":39498,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.5789256079723,"longitude":129.312901490461,"bookYear":null,"nameKR":"태양칼국수","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":39497,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/genghiskhan_shun/","foodTypes":"일식","latitude":37.5041813924961,"longitude":127.0515006041,"bookYear":null,"nameKR":"징기스칸슌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":39496,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bossanova_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5209544420636,"longitude":126.85200442947,"bookYear":null,"nameKR":"보사노바커피로스터스(신정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":39495,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwa_san__/","foodTypes":"한식(육류)","latitude":33.3049726791712,"longitude":126.31635202613,"bookYear":null,"nameKR":"화산(제주신화월드점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":39493,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.2866696524735,"longitude":127.055448351197,"bookYear":null,"nameKR":"알찬밥상","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39486,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/go.cheong.dam/","foodTypes":"한식(육류)","latitude":37.5709958592808,"longitude":126.978914477333,"bookYear":null,"nameKR":"고청담(광화문디타워점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":39483,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.771870968564,"longitude":127.161982765522,"bookYear":null,"nameKR":"이송불고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":39481,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jjori375/","foodTypes":"한식(가금류)","latitude":37.5823786497886,"longitude":127.00086982404,"bookYear":null,"nameKR":"쪼리닭(혜화대학로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":35980,"bookStatus":"비활성","websiteInstagram":"https://www.instragram.com/mimyunjung","foodTypes":"한식(면류)","latitude":37.4868646241789,"longitude":127.03945910421,"bookYear":null,"nameKR":"미면정양재본점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":39186,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seeomma/ ","foodTypes":"한식(면류)","latitude":35.9373572851709,"longitude":126.937671043682,"bookYear":null,"nameKR":"솝리칼국수","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":39476,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/panna_busan/","foodTypes":"기타","latitude":35.15487220509,"longitude":129.063759762022,"bookYear":null,"nameKR":"판나","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":39472,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5224466309117,"longitude":127.042700950101,"bookYear":null,"nameKR":"파에","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39467,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.845394383181,"longitude":129.287832728081,"bookYear":null,"nameKR":"소솜당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":39465,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/izakaya_honmi/","foodTypes":"바/주점","latitude":37.5111610686743,"longitude":127.0561536218,"bookYear":null,"nameKR":"혼미(삼성본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":39460,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.7745592715171,"longitude":127.13711795421,"bookYear":null,"nameKR":"수림정","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":39458,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_jok_garak/","foodTypes":"한식(육류)","latitude":37.496437116445,"longitude":127.120407561285,"bookYear":null,"nameKR":"더맛있는족발보쌈(가락점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":39457,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/re.fine_myeongdong/","foodTypes":"기타","latitude":37.5688363061533,"longitude":126.98510847461,"bookYear":null,"nameKR":"리파인(을지로명동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":39452,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/el_camino_wonju","foodTypes":"유럽식","latitude":37.3535301076418,"longitude":127.916020489409,"bookYear":null,"nameKR":"엘까미노","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":39450,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lunamond_2023","foodTypes":"디저트/차/베이커리","latitude":37.4489886322245,"longitude":127.139388465166,"bookYear":null,"nameKR":"루나몬드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":37711,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/son.riceee/","foodTypes":"한식(일반한식)","latitude":35.1530341459724,"longitude":129.068522078717,"bookYear":null,"nameKR":"손내향미","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":39552,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":38.0748823149655,"longitude":128.473150173334,"bookYear":null,"nameKR":"각두골","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":39547,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.8411297200979,"longitude":127.89657411624,"bookYear":null,"nameKR":"시장콩죽","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":39551,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0013265527551,"longitude":127.788250202714,"bookYear":null,"nameKR":"벚굴식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":34078,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gyeongju_gohyang/","foodTypes":"한식(면류)","latitude":35.8415469264312,"longitude":129.214442809677,"bookYear":null,"nameKR":"고향밀면(본점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":37662,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5301466215658,"longitude":126.969969527032,"bookYear":null,"nameKR":"양인환대","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37027,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lecork_bar","foodTypes":"기타","latitude":37.4661432246385,"longitude":127.139272679501,"bookYear":null,"nameKR":"르코르크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":39544,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2577616261514,"longitude":129.242902731211,"bookYear":null,"nameKR":"전산가든","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":39546,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.0840591089566,"longitude":128.089011063881,"bookYear":null,"nameKR":"예지분식","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":39545,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.5174031694518,"longitude":127.726369681636,"bookYear":null,"nameKR":"갑을식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"함양군","status":"ACTIVE"},
        {"id":39289,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/songdangpongdak/","foodTypes":"한식(가금류)","latitude":33.4717007193485,"longitude":126.786358898732,"bookYear":null,"nameKR":"송당퐁닭","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":39441,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/catches_pizza/","foodTypes":"미국식","latitude":35.149870799925,"longitude":126.922227616173,"bookYear":null,"nameKR":"캐치스피자","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":37010,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5631552255356,"longitude":127.034132639355,"bookYear":null,"nameKR":"고깃집왕고을","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":39016,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/notjeon.gyeongju","foodTypes":"한식(일반한식)","latitude":35.8323938233036,"longitude":129.2137447416,"bookYear":null,"nameKR":"놋전집","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":29774,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/deessebakery","foodTypes":"디저트/차/베이커리","latitude":37.6412701660367,"longitude":126.677601659359,"bookYear":null,"nameKR":"디쎄베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":4632,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.58838,"longitude":127.029,"bookYear":null,"nameKR":"동우설렁탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":6706,"bookStatus":"커버","websiteInstagram":"","foodTypes":"중식","latitude":37.58626,"longitude":126.9696,"bookYear":null,"nameKR":"중국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":39454,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/__danyeong/?utm_medium=copy_link","foodTypes":"기타","latitude":35.8347470067762,"longitude":128.56937083706,"bookYear":null,"nameKR":"단영","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":39449,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeong__dm?igsh=b2NyejY2Z2Q0cXhz","foodTypes":"한식(면류)","latitude":35.1446033601617,"longitude":129.111351664341,"bookYear":null,"nameKR":"정든면","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":39446,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/slyfox.in.bakerstreet/","foodTypes":"디저트/차/베이커리","latitude":35.143452391506,"longitude":126.83562647875,"bookYear":null,"nameKR":"슬라이폭스","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":39444,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/elderburger/","foodTypes":"기타","latitude":37.5463309801571,"longitude":127.042978797816,"bookYear":null,"nameKR":"엘더버거","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":39443,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.the_m/","foodTypes":"디저트/차/베이커리","latitude":37.2528956330613,"longitude":127.460107726116,"bookYear":null,"nameKR":"카페더엠","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":32122,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"컨템포러리","latitude":37.583902858397,"longitude":126.982103653747,"bookYear":null,"nameKR":"비언유주얼(삼청점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":36743,"bookStatus":"커버","websiteInstagram":"","foodTypes":"컨템포러리","latitude":37.5202736076902,"longitude":127.031119256871,"bookYear":null,"nameKR":"카니랩","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33241,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gwehdo/","foodTypes":"디저트/차/베이커리","latitude":37.561347713304,"longitude":126.922403138154,"bookYear":null,"nameKR":"궤도(연남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":39436,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kensington_yoido/","foodTypes":"기타","latitude":37.5302893644917,"longitude":126.921969207155,"bookYear":null,"nameKR":"켄싱턴호텔여의도뉴욕뉴욕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":34733,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/re.fresh.ment/","foodTypes":"디저트/차/베이커리","latitude":37.5508856180803,"longitude":127.047450016708,"bookYear":null,"nameKR":"리프레쉬먼트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":39539,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":34.4843190759403,"longitude":126.263737787524,"bookYear":null,"nameKR":"그냥경양식","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"진도군","status":"ACTIVE"},
        {"id":39543,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/trattoria_geoje","foodTypes":"이탈리아식","latitude":34.8890483310361,"longitude":128.694121035215,"bookYear":null,"nameKR":"뜨라또리아다파비오","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":39431,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushiu_official/","foodTypes":"일식","latitude":37.5056030103188,"longitude":127.064339793557,"bookYear":null,"nameKR":"스시유(삼성본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":39429,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woo__ramen","foodTypes":"일식","latitude":37.4327397656386,"longitude":127.129706305739,"bookYear":null,"nameKR":"우라멘본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":39428,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mr.bean_coffee_roasters7/","foodTypes":"디저트/차/베이커리","latitude":36.5054585085278,"longitude":127.234547154548,"bookYear":null,"nameKR":"미스터빈커피로스터스","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":37768,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jangan_lounge/","foodTypes":"바/주점","latitude":37.5624620903158,"longitude":127.064533081493,"bookYear":null,"nameKR":"장안라운지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":39426,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.8485782428627,"longitude":127.524549676047,"bookYear":null,"nameKR":"노드그라운즈","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":39477,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.9706657842377,"longitude":127.585663233576,"bookYear":null,"nameKR":"조선옥","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":38854,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/secondhillcoffee","foodTypes":"디저트/차/베이커리","latitude":36.8000451530001,"longitude":127.119368280675,"bookYear":null,"nameKR":"세컨드힐","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":36427,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1821859696921,"longitude":129.074539346581,"bookYear":null,"nameKR":"스페샬돼지","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":32317,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goo.d_official/","foodTypes":"기타","latitude":35.1486460542708,"longitude":126.922545935861,"bookYear":null,"nameKR":"구드","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":34088,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brewingeffect.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.4468307132621,"longitude":127.058402762647,"bookYear":null,"nameKR":"브루잉이펙트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":26659,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":0,"longitude":0,"bookYear":null,"nameKR":"크리스탈제이드레스토랑(소공점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":29837,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.53745124548869,"longitude":126.98864917768203,"bookYear":null,"nameKR":"누랑즈뉘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":4193,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.540234,"longitude":126.948623,"bookYear":null,"nameKR":"외백","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":35343,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5476479734779,"longitude":127.040154022998,"bookYear":null,"nameKR":"모리노키세츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36428,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jinri_koreanbbq","foodTypes":"한식(육류)","latitude":37.549180477958,"longitude":126.864897859605,"bookYear":null,"nameKR":"진리참숯구이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":29204,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/jldessertbar/","foodTypes":"디저트/차/베이커리","latitude":37.5339030611536,"longitude":127.005723679677,"bookYear":null,"nameKR":"JL디저트바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":39419,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafepicnic1104_dessert/","foodTypes":"디저트/차/베이커리","latitude":37.3246253937194,"longitude":126.678896185087,"bookYear":null,"nameKR":"카페피크닉","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":39456,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/myeonseoul/","foodTypes":"한식(면류)","latitude":37.5241263043193,"longitude":127.038982210697,"bookYear":null,"nameKR":"면서울by윤","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39416,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.8143661023026,"longitude":127.144163481944,"bookYear":null,"nameKR":"지복점","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":39411,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/danielsteakh/","foodTypes":"기타","latitude":37.2322338047907,"longitude":127.188837595008,"bookYear":null,"nameKR":"다니엘스테이크하우스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":39408,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5287911785354,"longitude":126.968673565462,"bookYear":null,"nameKR":"신류(용산점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":39407,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/minamok_seoul/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.522945604676,"longitude":127.032190748216,"bookYear":null,"nameKR":"미남옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39406,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/detroit_pizzzes.seoul/","foodTypes":"미국식","latitude":37.562181599861,"longitude":126.926509137292,"bookYear":null,"nameKR":"디트로이트피지스 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":39404,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/what_the_burger/","foodTypes":"기타","latitude":35.3190962186848,"longitude":128.998294491116,"bookYear":null,"nameKR":"왓더버거(양산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":39394,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5471115480347,"longitude":127.103604088577,"bookYear":null,"nameKR":"범가","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":39098,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5035681267212,"longitude":127.027318210827,"bookYear":null,"nameKR":"봉게짬뽕(강남역본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38855,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vnd_rop","foodTypes":"아시아식","latitude":37.5472823879252,"longitude":127.043842083555,"bookYear":null,"nameKR":"브이엔디(성수본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":39042,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gachi_coffee/ ","foodTypes":"디저트/차/베이커리","latitude":34.7532318199539,"longitude":127.655663620875,"bookYear":null,"nameKR":"가치커피","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":39403,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sagye_sotbap/","foodTypes":"한식(일반한식)","latitude":37.3109957339356,"longitude":126.829110398678,"bookYear":null,"nameKR":"사계솥밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":39401,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/flondon.coffee","foodTypes":"디저트/차/베이커리","latitude":37.3694477283379,"longitude":127.112574422858,"bookYear":null,"nameKR":"플런던커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":39399,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seungchun_food/","foodTypes":"한식(면류)","latitude":34.8857679453334,"longitude":128.619569873766,"bookYear":null,"nameKR":"승천냉면","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":39398,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.7611373449741,"longitude":128.077193623962,"bookYear":null,"nameKR":"라루올로","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":39395,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.8544088335552,"longitude":127.747076544582,"bookYear":null,"nameKR":"임가네","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":39393,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.0040482627771,"longitude":127.28116581157,"bookYear":null,"nameKR":"달빛장어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":39392,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yoon_hwi_official/","foodTypes":"기타","latitude":37.5910470741557,"longitude":127.019207756314,"bookYear":null,"nameKR":"윤휘식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":39390,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/banghwadong_chueotang","foodTypes":"한식(민물어패류)","latitude":37.57710906026,"longitude":126.81710832341,"bookYear":null,"nameKR":"원주추어탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":39389,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.santiago/","foodTypes":"디저트/차/베이커리","latitude":34.674211935082,"longitude":127.374579061578,"bookYear":null,"nameKR":"산티아고","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"고흥군","status":"ACTIVE"},
        {"id":39379,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5384728281281,"longitude":127.151931516117,"bookYear":null,"nameKR":"봉메밀","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":39378,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/poongjok_/","foodTypes":"한식(육류)","latitude":37.25891214965,"longitude":127.017629336241,"bookYear":null,"nameKR":"미식가의족발풍족본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39368,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.3434003321997,"longitude":126.687219812164,"bookYear":null,"nameKR":"청춘조개(3호점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":39367,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seafood_oido/","foodTypes":"한식(어패류)","latitude":37.3438446022003,"longitude":126.687380367231,"bookYear":null,"nameKR":"청춘조개(2호점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":39366,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tsf_ones_youth_star/","foodTypes":"한식(어패류)","latitude":37.342393820504,"longitude":126.686854620733,"bookYear":null,"nameKR":"청춘조개별관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":39369,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4764033747673,"longitude":126.598178940637,"bookYear":null,"nameKR":"청춘조개(월미도직영점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":39372,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cheongchunjogae.official/","foodTypes":"한식(어패류)","latitude":36.3111288150773,"longitude":126.513671878968,"bookYear":null,"nameKR":"청춘조개포차(대천점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":39373,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cheongchunjogae.official/","foodTypes":"한식(어패류)","latitude":36.8877867570533,"longitude":126.823735843917,"bookYear":null,"nameKR":"청춘조개포차(삽교직영점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":39374,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cheongchunjogae.official/","foodTypes":"한식(어패류)","latitude":37.4962050228747,"longitude":126.578970525732,"bookYear":null,"nameKR":"청춘조개포차(구읍뱃터직영점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":39377,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seafood_oido/","foodTypes":"한식(어패류)","latitude":37.4741322433377,"longitude":126.597552254849,"bookYear":null,"nameKR":"청춘조개회루프탑","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":39350,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pad_sillim/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4833370523321,"longitude":126.921083584848,"bookYear":null,"nameKR":"평안도식당(신림점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":39153,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/enjoythejuice_salad/","foodTypes":"기타","latitude":37.5265490012703,"longitude":127.134379483293,"bookYear":null,"nameKR":"인조이더주스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":34483,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/wolmyeong_chobab/","foodTypes":"일식","latitude":35.9856220644172,"longitude":126.707921941272,"bookYear":null,"nameKR":"월명초밥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":32229,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/mooaa.kr/","foodTypes":"일식","latitude":37.5196963204161,"longitude":127.02554619298,"bookYear":null,"nameKR":"무아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37857,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com//ojuri_sarangchae","foodTypes":"한식(육류)","latitude":37.5657161252516,"longitude":127.189378510112,"bookYear":null,"nameKR":"오쥬리사랑채","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":39343,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wolhaaa._.dat","foodTypes":"디저트/차/베이커리","latitude":37.594692239357,"longitude":126.916336619297,"bookYear":null,"nameKR":"월하:점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":39365,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/petillant_chuncheon/","foodTypes":"기타","latitude":37.8677259219718,"longitude":127.719835702813,"bookYear":null,"nameKR":"페티엉","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":39141,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/swch_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.4909627943056,"longitude":127.038004388906,"bookYear":null,"nameKR":"스위치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":39355,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4938812877118,"longitude":127.012543555859,"bookYear":null,"nameKR":"평안도순대국감자탕(서초교대점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":7855,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/taejo_gamjaguk/ ","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5908139536716,"longitude":127.017694871938,"bookYear":null,"nameKR":"태조감자국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":37910,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mongchon.dakgalbi/","foodTypes":"한식(가금류)","latitude":37.5115694532177,"longitude":127.10815851038,"bookYear":null,"nameKR":"몽촌닭갈비(잠실본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":37854,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jin_gooo/","foodTypes":"한식(육류)","latitude":37.5007411455809,"longitude":127.024801590532,"bookYear":null,"nameKR":"진구곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":37554,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nosanggrilled/","foodTypes":"중남미식","latitude":37.6572869218154,"longitude":126.770984132251,"bookYear":null,"nameKR":"노상그릴드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":37060,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8130864397689,"longitude":128.54670282765,"bookYear":null,"nameKR":"돼지만","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":37047,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/6boondang_official","foodTypes":"한식(육류)","latitude":37.3489404443443,"longitude":127.110466899341,"bookYear":null,"nameKR":"육분당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":36668,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4864663318419,"longitude":126.817717802861,"bookYear":null,"nameKR":"경인로570숯불구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":39364,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pureum_meat","foodTypes":"한식(육류)","latitude":37.4522347895411,"longitude":126.902602740624,"bookYear":null,"nameKR":"푸름상회","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":36151,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/piggym_okjeong/","foodTypes":"한식(육류)","latitude":37.8174962895288,"longitude":127.097990572071,"bookYear":null,"nameKR":"돼지체육관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":39363,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/kdo_seoul","foodTypes":"일식","latitude":37.5380624873521,"longitude":126.946397704715,"bookYear":null,"nameKR":"카도","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":39362,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5167635081981,"longitude":127.037812844426,"bookYear":null,"nameKR":"팔당족발","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":35986,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwawoo_west/","foodTypes":"한식(육류)","latitude":37.5659706997486,"longitude":126.965390115163,"bookYear":null,"nameKR":"화우(서대문본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":39358,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pizzeria_domanda","foodTypes":"이탈리아식","latitude":37.5411798903232,"longitude":126.96987265737,"bookYear":null,"nameKR":"도만다","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":35924,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wandosan_mulhoe/","foodTypes":"한식(어패류)","latitude":37.4966552528775,"longitude":127.118713226999,"bookYear":null,"nameKR":"완도산회특급포차(가락본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":39349,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5340035658779,"longitude":126.755676617371,"bookYear":null,"nameKR":"평안도식당(서운점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":39352,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leejin3020","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.557004893187,"longitude":126.937742721687,"bookYear":null,"nameKR":"평안도식당(신촌점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":39348,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5037156735737,"longitude":127.025885913594,"bookYear":null,"nameKR":"평안도식당(강남본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":39347,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/namwoocoffee_roastinghouse","foodTypes":"디저트/차/베이커리","latitude":37.5764455411159,"longitude":127.22452162512,"bookYear":null,"nameKR":"남우커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":39342,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5301466215658,"longitude":126.969969527032,"bookYear":null,"nameKR":"셰프정현","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":39311,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soonja_attic","foodTypes":"디저트/차/베이커리","latitude":37.6007793617733,"longitude":127.103512564723,"bookYear":null,"nameKR":"순자네다락방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":9092,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6016,"longitude":126.9296,"bookYear":null,"nameKR":"녹번꼼장어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":13391,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5930223660865,"longitude":127.018345336364,"bookYear":null,"nameKR":"온달왕돈까스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":14729,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5421491984204,"longitude":127.069884916683,"bookYear":null,"nameKR":"포항막회","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":14922,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.81817,"longitude":127.12459,"bookYear":null,"nameKR":"장수버섯마을","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":15136,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sansam_5252/","foodTypes":"한식(가금류)","latitude":37.520761,"longitude":126.926893,"bookYear":null,"nameKR":"산삼골오리집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":15543,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.519707,"longitude":126.929203,"bookYear":null,"nameKR":"여수해물낙지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":15692,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"오립스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":37336,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/cheerful_snack","foodTypes":"한식(일반한식)","latitude":33.4092985445972,"longitude":126.258772321923,"bookYear":null,"nameKR":"명랑스낵","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":16000,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.565381,"longitude":126.995302,"bookYear":null,"nameKR":"산수갑산","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":37875,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/udondan_0fficial/","foodTypes":"일식","latitude":37.4949570606479,"longitude":126.564338040847,"bookYear":null,"nameKR":"우동단","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":37640,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1602212408572,"longitude":129.165269043658,"bookYear":null,"nameKR":"본앤브레드(해운대파라다이스점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":35812,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1540570459646,"longitude":129.145834012644,"bookYear":null,"nameKR":"이가솜씨","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":16146,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":33.505459,"longitude":126.54163,"bookYear":null,"nameKR":"신설오름","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":36689,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.1572036560166,"longitude":129.144817207267,"bookYear":null,"nameKR":"프리미엄겟201해운대","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":35595,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/flyingscone_official/","foodTypes":"디저트/차/베이커리","latitude":35.1572036560166,"longitude":129.144817207267,"bookYear":null,"nameKR":"플라잉스콘","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":16571,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.565282,"longitude":126.965936,"bookYear":null,"nameKR":"삼호복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":17312,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5826378625249,"longitude":127.001219546667,"bookYear":null,"nameKR":"명륜동갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":8168,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.518566,"longitude":126.908564,"bookYear":null,"nameKR":"대문점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":7868,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5685569376048,"longitude":126.986094782493,"bookYear":null,"nameKR":"황소고집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":19468,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2242042779456,"longitude":126.837464385593,"bookYear":null,"nameKR":"꽃담","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":19605,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1660640671559,"longitude":129.117001559949,"bookYear":null,"nameKR":"간판없는집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":20371,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4855213188067,"longitude":127.035154472383,"bookYear":null,"nameKR":"잰부닥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":21493,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/shins_zzukkumi_suwon/ ","foodTypes":"한식(어패류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"신스쭈꾸미","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":21691,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4861292840185,"longitude":127.031442937827,"bookYear":null,"nameKR":"황재벌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":21718,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hannamdonghanbangchicken/","foodTypes":"한식(가금류)","latitude":37.531514,"longitude":127.00552,"bookYear":null,"nameKR":"한방통닭","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":21768,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6115123117462,"longitude":126.929483597487,"bookYear":null,"nameKR":"대로집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":22393,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.490473,"longitude":126.494514,"bookYear":null,"nameKR":"모살물","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":22421,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.731225,"longitude":127.187315,"bookYear":null,"nameKR":"약수촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":22678,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.0999588364497,"longitude":129.026093795456,"bookYear":null,"nameKR":"대정양곱창","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":23149,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5520984275925,"longitude":126.921843831702,"bookYear":null,"nameKR":"신미경홍대닭갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":23226,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lovejeju77/ ","foodTypes":"바/주점","latitude":37.5470109500703,"longitude":127.072784329147,"bookYear":null,"nameKR":"숨비소리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":23338,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.326494,"longitude":127.425601,"bookYear":null,"nameKR":"현대식당","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":23344,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.40103,"longitude":126.97556,"bookYear":null,"nameKR":"유성통닭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":23503,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1627132565715,"longitude":126.809349427046,"bookYear":null,"nameKR":"하남낙지마당","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":35626,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coccoc_official/","foodTypes":"아시아식","latitude":35.1579136940403,"longitude":129.182297074709,"bookYear":null,"nameKR":"콕콕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":36789,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3839107611132,"longitude":127.111371590863,"bookYear":null,"nameKR":"펄치커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":23760,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.474756,"longitude":126.86978,"bookYear":null,"nameKR":"카리브참치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":38849,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gi.dalim","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4021021626954,"longitude":127.110467524727,"bookYear":null,"nameKR":"기달임돼지곰탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":24953,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":34.806223,"longitude":126.401249,"bookYear":null,"nameKR":"88포장마차","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":5660,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.479609,"longitude":126.980645,"bookYear":null,"nameKR":"전주전집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":5657,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.47576,"longitude":126.9779,"bookYear":null,"nameKR":"청송산오징어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":28726,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"고향집김치삼겹살(합정역점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"","status":"ACTIVE"},
        {"id":28975,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5325221098815,"longitude":127.005448194526,"bookYear":null,"nameKR":"강가네맷돌빈대떡","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":5056,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5218378508926,"longitude":127.041357725173,"bookYear":null,"nameKR":"무돌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29371,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5689693213227,"longitude":126.988598326615,"bookYear":null,"nameKR":"효자동목고기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":4886,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.618089,"longitude":126.920993,"bookYear":null,"nameKR":"불오징어집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":29883,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.52014610327792,"longitude":127.02594973230234,"bookYear":null,"nameKR":"춘식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":29962,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.929619679244986,"longitude":127.7829656433325,"bookYear":null,"nameKR":"토담숯불닭갈비","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":37055,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dalbongbakeshop","foodTypes":"디저트/차/베이커리","latitude":37.4831824580276,"longitude":126.944957150404,"bookYear":null,"nameKR":"달봉제과점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":4127,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.541616,"longitude":126.950948,"bookYear":null,"nameKR":"마포갈매기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":3898,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.523737,"longitude":126.923921,"bookYear":null,"nameKR":"성민촌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":30563,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jin_ji_a","foodTypes":"중식","latitude":37.50757900481695,"longitude":127.1082467397109,"bookYear":null,"nameKR":"진지아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":30711,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5730833706346,"longitude":126.990838041453,"bookYear":null,"nameKR":"이경문순대곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":3485,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.55631,"longitude":126.9262,"bookYear":null,"nameKR":"홍익숯불갈비소금구이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31082,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5939936849695,"longitude":127.019713527052,"bookYear":null,"nameKR":"밀양손칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":31283,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5737804677873,"longitude":126.990804674931,"bookYear":null,"nameKR":"오죽이네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":31357,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5061762449161,"longitude":127.041344559745,"bookYear":null,"nameKR":"꽃새우영번지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":2632,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.484402,"longitude":126.495579,"bookYear":null,"nameKR":"황금어장","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":2624,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.490726,"longitude":126.493584,"bookYear":null,"nameKR":"차돌집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":39284,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sunaroi_cafe/","foodTypes":"기타","latitude":34.846640750062,"longitude":128.605148443666,"bookYear":null,"nameKR":"수나로이","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":32082,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5575785183332,"longitude":126.934993977939,"bookYear":null,"nameKR":"신촌부추곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":1154,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.519585,"longitude":127.031032,"bookYear":null,"nameKR":"삼호짱뚱이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32877,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/JJ.PORK","foodTypes":"한식(육류)","latitude":37.5215199676606,"longitude":127.031109141031,"bookYear":null,"nameKR":"압구정제주집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":797,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.57274,"longitude":126.990258,"bookYear":null,"nameKR":"찬양집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":485,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56429,"longitude":126.9671,"bookYear":null,"nameKR":"풍요람","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":259,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.54339,"longitude":126.9389,"bookYear":null,"nameKR":"램랜드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":33323,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.8166985126849,"longitude":127.124620705482,"bookYear":null,"nameKR":"본참치","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":33489,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.4765261400995,"longitude":126.479300826417,"bookYear":null,"nameKR":"도갈비","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":160,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.52016,"longitude":126.9273,"bookYear":null,"nameKR":"다미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":33528,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/woo.midang","foodTypes":"한식(육류)","latitude":37.6137779145211,"longitude":127.16556781867,"bookYear":null,"nameKR":"우미당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":34016,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/500don_official/","foodTypes":"한식(육류)","latitude":36.3498331847874,"longitude":127.389481323727,"bookYear":null,"nameKR":"오백돈","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":34951,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5163576153712,"longitude":127.021675414682,"bookYear":null,"nameKR":"우찬","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37667,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thepizzeriamarket","foodTypes":"미국식","latitude":37.5695686035849,"longitude":127.182975791553,"bookYear":null,"nameKR":"더피제리아마켓","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":35046,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.4896889583513,"longitude":126.490498648051,"bookYear":null,"nameKR":"도도름한우와흑돈","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":35165,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8375594931737,"longitude":128.57815696623,"bookYear":null,"nameKR":"앞산큰골집","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":39299,"bookStatus":"커버","websiteInstagram":"","foodTypes":"기타","latitude":36.4899317172292,"longitude":127.257096579017,"bookYear":null,"nameKR":"에이트","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":39203,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jollypiemarket/","foodTypes":"디저트/차/베이커리","latitude":37.5569705597732,"longitude":126.907031523039,"bookYear":null,"nameKR":"졸리파이마켓","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":32815,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/alldist_coffee","foodTypes":"디저트/차/베이커리","latitude":37.3620923289005,"longitude":126.922452317692,"bookYear":null,"nameKR":"올디스트커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":39338,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3793159725333,"longitude":126.657657591796,"bookYear":null,"nameKR":"안녕바다","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":39339,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5134071445935,"longitude":126.943957579056,"bookYear":null,"nameKR":"꽁칼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":39333,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/a.nook_/","foodTypes":"디저트/차/베이커리","latitude":35.8183059099163,"longitude":128.517269007519,"bookYear":null,"nameKR":"아눅월성","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":39324,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.0287388703541,"longitude":129.371006450581,"bookYear":null,"nameKR":"숯불이야기 ","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":39319,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eiffery_wine/","foodTypes":"바/주점","latitude":37.5039536820236,"longitude":126.753697241047,"bookYear":null,"nameKR":"에페리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":39317,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/blondeddoor/","foodTypes":"바/주점","latitude":37.5537666825878,"longitude":126.966913493477,"bookYear":null,"nameKR":"블론드도어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":39315,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/section_kr/","foodTypes":"디저트/차/베이커리","latitude":37.1912196389015,"longitude":126.820167553345,"bookYear":null,"nameKR":"섹션","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39313,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dam_daejeon","foodTypes":"한식(일반한식)","latitude":36.3083442055597,"longitude":127.354589639526,"bookYear":null,"nameKR":"한식파인다이닝담","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":39308,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3462209589654,"longitude":127.396781930599,"bookYear":null,"nameKR":"숯방한우","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":39305,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/in_my_poke","foodTypes":"기타","latitude":38.074046461321,"longitude":128.618978951541,"bookYear":null,"nameKR":"인마이포케","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":39304,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_giuk.niun.digd","foodTypes":"디저트/차/베이커리","latitude":35.3344286455991,"longitude":126.965506757019,"bookYear":null,"nameKR":"카페기역니은디귿","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":36782,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brewtiful_coffee_/","foodTypes":"디저트/차/베이커리","latitude":38.201246606225,"longitude":128.577612123336,"bookYear":null,"nameKR":"브루티풀","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":39228,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/blendy_busan/","foodTypes":"기타","latitude":35.1575683543763,"longitude":129.181968322573,"bookYear":null,"nameKR":"블렌디","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":708,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.573686660928,"longitude":126.988587758979,"bookYear":null,"nameKR":"을지면옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":36916,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/allday_phillip/","foodTypes":"기타","latitude":35.2091522294227,"longitude":128.109999083582,"bookYear":null,"nameKR":"필립","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":36078,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4750100992712,"longitude":127.141592563317,"bookYear":null,"nameKR":"우대포","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":36079,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woodaepo_bundang/","foodTypes":"한식(육류)","latitude":37.3860492558363,"longitude":127.121697508944,"bookYear":null,"nameKR":"우대포(분당직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":39302,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8079472051602,"longitude":128.546291288397,"bookYear":null,"nameKR":"동인동찜갈비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":37332,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4722163985438,"longitude":127.040723528732,"bookYear":null,"nameKR":"잔상","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":23258,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.525247,"longitude":127.038948,"bookYear":null,"nameKR":"핑가스존","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":34363,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yiandjung_bakery/","foodTypes":"디저트/차/베이커리","latitude":36.9831809866284,"longitude":127.94154598222,"bookYear":null,"nameKR":"베이커리이와정","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":39000,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1631050422073,"longitude":129.161230227894,"bookYear":null,"nameKR":"해운대원조할매국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":38751,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/goldkodari_official/","foodTypes":"한식(어패류)","latitude":35.3251046292601,"longitude":129.017076432869,"bookYear":null,"nameKR":"황금코다리(남양산역점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":39172,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/patisserie_eclore","foodTypes":"디저트/차/베이커리","latitude":36.1263857850444,"longitude":128.330293477979,"bookYear":null,"nameKR":"에끌로흐","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":39210,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/obbang_company","foodTypes":"디저트/차/베이커리","latitude":36.8172819150708,"longitude":128.603907156666,"bookYear":null,"nameKR":"오빵","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":39066,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chgaok/ ","foodTypes":"한식(일반한식)","latitude":35.8386569905707,"longitude":129.209315550524,"bookYear":null,"nameKR":"취향가옥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":38704,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_fuple","foodTypes":"디저트/차/베이커리","latitude":37.826452662442,"longitude":126.983506409071,"bookYear":null,"nameKR":"퍼플","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":39010,"bookStatus":"커버","websiteInstagram":"","foodTypes":"기타","latitude":37.4421653122498,"longitude":126.710549332736,"bookYear":null,"nameKR":"위드위치","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":37454,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coddpastabar","foodTypes":"바/주점","latitude":37.5551101560175,"longitude":126.905299913634,"bookYear":null,"nameKR":"코드파스타","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":35288,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gimpo_didi","foodTypes":"중식","latitude":37.6428068511903,"longitude":126.622775348817,"bookYear":null,"nameKR":"띠디","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":33197,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4813928010079,"longitude":127.012306525968,"bookYear":null,"nameKR":"커피라운지55","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":38895,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/salon_je.u","foodTypes":"기타","latitude":35.9815954104499,"longitude":129.40399182136,"bookYear":null,"nameKR":"제유","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":36942,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1678955285857,"longitude":129.176412756177,"bookYear":null,"nameKR":"보들우동","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":37753,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yido_yongsan_official/","foodTypes":"기타","latitude":37.5317374519705,"longitude":126.971514687147,"bookYear":null,"nameKR":"이도용산","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37223,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.4218250982142,"longitude":127.10843791737,"bookYear":null,"nameKR":"청담추어정","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":39257,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sangroksu_bakery_park","foodTypes":"디저트/차/베이커리","latitude":35.1675716105057,"longitude":129.172004179659,"bookYear":null,"nameKR":"상록수베이커리파크","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":36284,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.7308236218048,"longitude":127.048658158465,"bookYear":null,"nameKR":"동네식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":39227,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cheon.yeon_galbi/","foodTypes":"한식(육류)","latitude":35.781818817599,"longitude":128.44191556823,"bookYear":null,"nameKR":"다원천연갈비 ","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":39234,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5394018417027,"longitude":127.1269894743,"bookYear":null,"nameKR":"똥찌비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":39301,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daolfriend","foodTypes":"한식(육류)","latitude":37.5631800634805,"longitude":126.98396292275,"bookYear":null,"nameKR":"다올숯불구이(명동점) ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":39260,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/OH.SUBSAENG_LAB","foodTypes":"한식(어패류)","latitude":37.6514452431588,"longitude":127.045912956978,"bookYear":null,"nameKR":"섭생정식(창동본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":39298,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5165127869983,"longitude":127.022537731648,"bookYear":null,"nameKR":"인타운커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":34636,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nonfix_cheongdam","foodTypes":"이탈리아식","latitude":37.5226041599715,"longitude":127.041851367598,"bookYear":null,"nameKR":"논픽스","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":39149,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/362_kitchen/","foodTypes":"일식","latitude":37.5675303833963,"longitude":126.842458458849,"bookYear":null,"nameKR":"362키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":38005,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hihandam/","foodTypes":"한식(일반한식)","latitude":33.5105035117855,"longitude":126.541398281582,"bookYear":null,"nameKR":"하이한담","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":38714,"bookStatus":"비활성","websiteInstagram":"https://www.instragram.com/ilristourante_conte","foodTypes":"이탈리아식","latitude":37.5061702651501,"longitude":127.097509133661,"bookYear":null,"nameKR":"콘테","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":39229,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistro_iniya","foodTypes":"기타","latitude":37.6835319325396,"longitude":126.775748677563,"bookYear":null,"nameKR":"이니야","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":37015,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.alt","foodTypes":"기타","latitude":37.5684574168521,"longitude":126.932423872164,"bookYear":null,"nameKR":"에이엘티","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":39106,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kuiper_cheongdam/","foodTypes":"기타","latitude":37.5213277654859,"longitude":127.037670238659,"bookYear":null,"nameKR":"카이퍼청담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":36882,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5223003029771,"longitude":126.926225192863,"bookYear":null,"nameKR":"미스터향","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":36207,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_foret","foodTypes":"디저트/차/베이커리","latitude":37.291319858743,"longitude":127.140898214956,"bookYear":null,"nameKR":"카페포레","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":39178,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.6642954447437,"longitude":127.499037012886,"bookYear":null,"nameKR":"고부심","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":38034,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.6079958063261,"longitude":127.009463414932,"bookYear":null,"nameKR":"의정부사람","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":34675,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.8933194322583,"longitude":127.760361512218,"bookYear":null,"nameKR":"툇골오리본점","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":39145,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/patisserie__ffrihee__coffee","foodTypes":"디저트/차/베이커리","latitude":37.2857326454815,"longitude":127.014099406265,"bookYear":null,"nameKR":"프리히","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38993,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/altitude_suwon","foodTypes":"이탈리아식","latitude":37.2857001894495,"longitude":127.012670515148,"bookYear":null,"nameKR":"얼티튜드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38820,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5598582116655,"longitude":126.906976777883,"bookYear":null,"nameKR":"컨트리베이커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":37364,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jomoorak","foodTypes":"한식(가금류)","latitude":37.8079841735469,"longitude":127.52498980578,"bookYear":null,"nameKR":"조무락닭갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":39300,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.2596879616808,"longitude":127.058503033765,"bookYear":null,"nameKR":"다엔리코","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39140,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3518606872917,"longitude":126.723693682465,"bookYear":null,"nameKR":"고반식당(시흥정왕점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":39225,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1566103749545,"longitude":129.060758598204,"bookYear":null,"nameKR":"돈마초","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":32218,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5174631210953,"longitude":126.896493699106,"bookYear":null,"nameKR":"월화고기(문래점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":15420,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.529815,"longitude":126.921955,"bookYear":null,"nameKR":"곽수산","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"","status":"ACTIVE"},
        {"id":22839,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.177823,"longitude":129.124519,"bookYear":null,"nameKR":"올레","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":23533,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2083995719404,"longitude":128.578521013839,"bookYear":null,"nameKR":"강림통술","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":25656,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.156554,"longitude":129.174908,"bookYear":null,"nameKR":"미포끝집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":36474,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1553228098097,"longitude":129.065988834522,"bookYear":null,"nameKR":"푯","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33572,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1576453640189,"longitude":129.066411881854,"bookYear":null,"nameKR":"조장","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":37191,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeonju_solga/","foodTypes":"한식(육류)","latitude":35.8287880585973,"longitude":127.173054207745,"bookYear":null,"nameKR":"솔가숯불갈비","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38194,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.041927977346,"longitude":127.053323312861,"bookYear":null,"nameKR":"우와한돈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":30155,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.3227228798527,"longitude":127.098206919446,"bookYear":null,"nameKR":"미가훠궈양고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":36117,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2976222324033,"longitude":127.042573192301,"bookYear":null,"nameKR":"상원회관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39053,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.2468094454978,"longitude":127.059922353896,"bookYear":null,"nameKR":"갯벌스타일","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33694,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5438724527669,"longitude":127.066285680139,"bookYear":null,"nameKR":"육성회비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":15845,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.519809,"longitude":126.872941,"bookYear":null,"nameKR":"원두막","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":8478,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.565734436537,"longitude":126.965314953842,"bookYear":null,"nameKR":"어상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":33822,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5340942312917,"longitude":127.006165849642,"bookYear":null,"nameKR":"호박식당(한남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":17028,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.53322,"longitude":126.9735,"bookYear":null,"nameKR":"풍성집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":24848,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56963,"longitude":126.9848,"bookYear":null,"nameKR":"종로황소곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":6116,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.515742,"longitude":127.116007,"bookYear":null,"nameKR":"한아람","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":8317,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.556472,"longitude":126.9353,"bookYear":null,"nameKR":"죽해수산","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":17796,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.50801,"longitude":127.0246,"bookYear":null,"nameKR":"프로간장새우(논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":19808,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.496040053167,"longitude":126.985472935134,"bookYear":null,"nameKR":"스마일포장마차","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":34009,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dakhanmari_kamjatang","foodTypes":"한식(가금류)","latitude":37.5171506735807,"longitude":127.020699344217,"bookYear":null,"nameKR":"닭한마리감자탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":21717,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.505396,"longitude":127.042523,"bookYear":null,"nameKR":"문어랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":37906,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5021008334827,"longitude":127.024465815419,"bookYear":null,"nameKR":"활어회명인수산활명수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":39296,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.3894882745519,"longitude":127.307428551194,"bookYear":null,"nameKR":"베이크샵수하(지족동점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":39295,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakeshop_suha_","foodTypes":"디저트/차/베이커리","latitude":36.3488351268567,"longitude":127.432357576699,"bookYear":null,"nameKR":"베이크샵수하","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":39293,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yakitori_kino","foodTypes":"일식","latitude":37.5361796711226,"longitude":127.128668274141,"bookYear":null,"nameKR":"야키토리키노","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":39288,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.9927187980066,"longitude":127.091627550091,"bookYear":null,"nameKR":"쭈불리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":39287,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi_chosim/","foodTypes":"일식","latitude":37.6555512888913,"longitude":127.065070601549,"bookYear":null,"nameKR":"스시초심","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":39282,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/miso_sik","foodTypes":"한식(일반한식)","latitude":34.8815268086168,"longitude":128.62411608471,"bookYear":null,"nameKR":"미소식","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":39280,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/magazine_notes/","foodTypes":"바/주점","latitude":37.5376499933535,"longitude":126.959732421793,"bookYear":null,"nameKR":"매거진노츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":39277,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafelgo_bupyeong/","foodTypes":"디저트/차/베이커리","latitude":37.4953559291732,"longitude":126.724056865552,"bookYear":null,"nameKR":"카페르고부평","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":39266,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/near_and_dear_brunchcafe","foodTypes":"기타","latitude":38.2104243825615,"longitude":128.588086802763,"bookYear":null,"nameKR":"니어앤디어브런치카페","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":39265,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oss_boubou/","foodTypes":"기타","latitude":37.8925921138553,"longitude":127.194678852533,"bookYear":null,"nameKR":"오스스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":39235,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hyoeunok/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4981877603026,"longitude":126.934398299786,"bookYear":null,"nameKR":"효은옥이야기점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":39238,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fenz_cafe/ ","foodTypes":"디저트/차/베이커리","latitude":35.8765846712004,"longitude":128.625144003419,"bookYear":null,"nameKR":"팬즈 ","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":39255,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/ovrnghtcoffee","foodTypes":"디저트/차/베이커리","latitude":37.5666029325047,"longitude":127.024082467223,"bookYear":null,"nameKR":"오버나잇커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":39057,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5548638155738,"longitude":126.929956540282,"bookYear":null,"nameKR":"츄베릅","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":7957,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seoseo_galbi/","foodTypes":"한식(육류)","latitude":37.5666438426649,"longitude":126.929111752033,"bookYear":null,"nameKR":"연남서식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":39263,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1110536970167,"longitude":128.964541969052,"bookYear":null,"nameKR":"청춘곱도리탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":39262,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/apeoper_official","foodTypes":"디저트/차/베이커리","latitude":37.5998407487538,"longitude":127.090179230604,"bookYear":null,"nameKR":"어피펄","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":39256,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.327025002876,"longitude":127.984087690233,"bookYear":null,"nameKR":"루비스카페&바","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":39246,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_comme.a.paris/","foodTypes":"디저트/차/베이커리","latitude":37.8854166225304,"longitude":127.761806085778,"bookYear":null,"nameKR":"꼼아파리","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":39244,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_asara.cafe","foodTypes":"디저트/차/베이커리","latitude":33.5248563354878,"longitude":126.58954560972,"bookYear":null,"nameKR":"아사라","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":39243,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4860942781606,"longitude":126.957974303414,"bookYear":null,"nameKR":"청국장과보리밥(봉천점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":39242,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gabae5225","foodTypes":"디저트/차/베이커리","latitude":37.5338270667293,"longitude":126.905236092864,"bookYear":null,"nameKR":"가배5225","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":39232,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/labouchedulua_official","foodTypes":"디저트/차/베이커리","latitude":37.5552814575956,"longitude":127.069382806615,"bookYear":null,"nameKR":"라부슈뒤루아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":39226,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goldkodari_jinhae_?igsh=MXU5NjczOGV2djVlZQ%3D%3D&utm_source=qr","foodTypes":"한식(어패류)","latitude":35.155389594387,"longitude":128.713446312237,"bookYear":null,"nameKR":"황금코다리(진해자은점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":36763,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.0815392723019,"longitude":129.395541310826,"bookYear":null,"nameKR":"인브리즈","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":37972,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/upstairramen_official/","foodTypes":"일식","latitude":37.6668931388939,"longitude":126.783910986307,"bookYear":null,"nameKR":"계단라멘(일산본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":39167,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/o.u_bread/ ","foodTypes":"디저트/차/베이커리","latitude":37.6653867221792,"longitude":127.117418708681,"bookYear":null,"nameKR":"오유브레드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":36112,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5501583256716,"longitude":126.920519899336,"bookYear":null,"nameKR":"미티","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33929,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/all_the_ugly_cookie/","foodTypes":"디저트/차/베이커리","latitude":37.5397823826391,"longitude":127.056738790521,"bookYear":null,"nameKR":"올더어글리쿠키&사부아페어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33995,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/coffee_deniro","foodTypes":"디저트/차/베이커리","latitude":37.5496259419572,"longitude":126.963472292427,"bookYear":null,"nameKR":"커피드니로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":34591,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5654463222229,"longitude":126.903548142922,"bookYear":null,"nameKR":"스시린","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":36881,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chaleaf__/","foodTypes":"디저트/차/베이커리","latitude":37.7563505418171,"longitude":128.89427599505,"bookYear":null,"nameKR":"차리프","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":37991,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/keegoseoul/","foodTypes":"일식","latitude":37.5231984225484,"longitude":127.046530194424,"bookYear":null,"nameKR":"키고","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":37566,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/myungbodang/","foodTypes":"프랑스식","latitude":37.5261419852792,"longitude":127.041483638144,"bookYear":null,"nameKR":"명보당 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39224,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.3866043842462,"longitude":127.12212659509,"bookYear":null,"nameKR":"이자카야잔잔(서현역점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":39220,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/hanglooseburgers","foodTypes":"기타","latitude":35.5468127183969,"longitude":129.325059261636,"bookYear":null,"nameKR":"행루즈수제버거","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":39221,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hanglooseburgers_suwon/","foodTypes":"기타","latitude":37.2866992375232,"longitude":127.01289024512,"bookYear":null,"nameKR":"행루즈버거(수원점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39217,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yakiniku_komyo","foodTypes":"일식","latitude":37.480481146564,"longitude":126.879651059894,"bookYear":null,"nameKR":"코미오","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":39216,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/ghiwon22_bar","foodTypes":"바/주점","latitude":37.5670832981116,"longitude":126.981686268545,"bookYear":null,"nameKR":"기원22","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":39215,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bumga_galbi/","foodTypes":"한식(육류)","latitude":35.3293928931107,"longitude":129.010740372291,"bookYear":null,"nameKR":"범가숯불갈비(양산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":39213,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/bistro.goat","foodTypes":"기타","latitude":37.181376013869,"longitude":127.125307011731,"bookYear":null,"nameKR":"비스트로곧","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39212,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8369173293495,"longitude":128.540129124505,"bookYear":null,"nameKR":"양해장","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":39208,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_logout/","foodTypes":"디저트/차/베이커리","latitude":37.5195623496267,"longitude":127.030799399932,"bookYear":null,"nameKR":"로그아웃","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37694,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/demellocoffeekorea/","foodTypes":"디저트/차/베이커리","latitude":37.4830823343746,"longitude":126.897699487349,"bookYear":null,"nameKR":"드멜로커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":36827,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/misfits_burger/","foodTypes":"기타","latitude":37.4785330046588,"longitude":126.979866762653,"bookYear":null,"nameKR":"미스피츠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":39206,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/solsongyigui/","foodTypes":"한식(육류)","latitude":36.5504917245597,"longitude":128.734826870212,"bookYear":null,"nameKR":"솔송이구이","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":39070,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/calmild_home/","foodTypes":"기타","latitude":37.5496167775487,"longitude":126.937698169535,"bookYear":null,"nameKR":"카밀드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":39044,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sunniter.official/","foodTypes":"기타","latitude":37.5010350580513,"longitude":127.033222117635,"bookYear":null,"nameKR":"써니테르","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":38743,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/inccoffee__/","foodTypes":"디저트/차/베이커리","latitude":37.6125857877932,"longitude":127.151716222848,"bookYear":null,"nameKR":"인크커피(다산1호점) ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":39055,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/p.eatzza","foodTypes":"이탈리아식","latitude":37.5462406709864,"longitude":127.054410677206,"bookYear":null,"nameKR":"피잇짜","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36937,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/crdo_jeju","foodTypes":"디저트/차/베이커리","latitude":33.40373603124,"longitude":126.252238560534,"bookYear":null,"nameKR":"크도","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":37050,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.2912772180926,"longitude":127.004518952785,"bookYear":null,"nameKR":"라벤치레스토랑(정자점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37684,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.2876086076178,"longitude":127.057001896438,"bookYear":null,"nameKR":"모어댄커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36321,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5035436634397,"longitude":127.022399023384,"bookYear":null,"nameKR":"효은옥(본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":33349,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kwangyeon_ilsan","foodTypes":"이탈리아식","latitude":37.6693446459622,"longitude":126.785311067638,"bookYear":null,"nameKR":"광연","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35479,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_easily/","foodTypes":"디저트/차/베이커리","latitude":37.7620507744671,"longitude":128.892568075039,"bookYear":null,"nameKR":"이진리","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":36326,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/landfall_coffee","foodTypes":"디저트/차/베이커리","latitude":37.4985040877248,"longitude":126.917679077528,"bookYear":null,"nameKR":"랜드폴커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":35660,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/buffalospot.bangbae","foodTypes":"미국식","latitude":37.4852227126191,"longitude":126.990566791559,"bookYear":null,"nameKR":"버팔로스팟","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":39150,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/black._.bori_masim/","foodTypes":"한식(면류)","latitude":33.4819803675692,"longitude":126.405373327268,"bookYear":null,"nameKR":"제주검정보리마씸","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":32252,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5758776153526,"longitude":127.193496456174,"bookYear":null,"nameKR":"일호점미역(하남미사점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":32652,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dallaeja/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5187672551656,"longitude":127.020691646267,"bookYear":null,"nameKR":"달래해장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":39170,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5066186083695,"longitude":127.024254775566,"bookYear":null,"nameKR":"달래해장(논현영동시장점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":36588,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5067919626305,"longitude":127.091768429619,"bookYear":null,"nameKR":"안재식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35450,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wavycoffeeroasters/","foodTypes":"디저트/차/베이커리","latitude":35.8715816247425,"longitude":128.635018091024,"bookYear":null,"nameKR":"웨이비커피","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":39146,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/flame_coffee_official/","foodTypes":"디저트/차/베이커리","latitude":35.1909215914111,"longitude":129.124748881284,"bookYear":null,"nameKR":"플레임커피로스터스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":37752,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5184152342679,"longitude":126.914047973035,"bookYear":null,"nameKR":"서풍돼지에미친사람들","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":39205,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/suabean","foodTypes":"디저트/차/베이커리","latitude":36.5041417354194,"longitude":127.27175210069,"bookYear":null,"nameKR":"수아빈로스터리카페","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":39191,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lamer_panji/","foodTypes":"디저트/차/베이커리","latitude":35.6135018838139,"longitude":129.454957154487,"bookYear":null,"nameKR":"라메르판지","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":39189,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7573708326687,"longitude":126.769185278257,"bookYear":null,"nameKR":"해운대가야밀면(파주금촌직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":39188,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.536921366674,"longitude":129.335176743545,"bookYear":null,"nameKR":"칼삼겹살","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":39187,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/muro____o/","foodTypes":"이탈리아식","latitude":36.4779954049097,"longitude":127.302213541063,"bookYear":null,"nameKR":"피제리아무로","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":39185,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.8040798676819,"longitude":127.10667670119,"bookYear":null,"nameKR":"샤르베티에","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":39184,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chasi_hocha/","foodTypes":"디저트/차/베이커리","latitude":35.8342320590373,"longitude":128.615594289306,"bookYear":null,"nameKR":"차시호차","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":39183,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/u__me_98 ","foodTypes":"바/주점","latitude":37.2099373218309,"longitude":127.067145481171,"bookYear":null,"nameKR":"유메","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39182,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pizzeria_stay","foodTypes":"이탈리아식","latitude":37.5644187469965,"longitude":127.082530694891,"bookYear":null,"nameKR":"스테이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":39179,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1518871530091,"longitude":129.067334262098,"bookYear":null,"nameKR":"무채로스터리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":39177,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.2395114606125,"longitude":128.699993740511,"bookYear":null,"nameKR":"디텍트","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":39117,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5083630213933,"longitude":127.133787699141,"bookYear":null,"nameKR":"이낙근찹쌀떡카페","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":24070,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.511007,"longitude":127.094003,"bookYear":null,"nameKR":"이낙근찹쌀떡베이커리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":39173,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2139610943778,"longitude":126.846175412241,"bookYear":null,"nameKR":"태백김치삼겹살","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":39169,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maruok_official","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.18139659539,"longitude":127.125951528915,"bookYear":null,"nameKR":"마루옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39168,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/franklins_deli/ ","foodTypes":"기타","latitude":37.5623958490752,"longitude":126.925633449803,"bookYear":null,"nameKR":"프랭클린스델리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":38858,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/suvarnabhumi_busan/","foodTypes":"아시아식","latitude":35.1704236699477,"longitude":129.13885455228,"bookYear":null,"nameKR":"수완나폼","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":37820,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/container_bakery","foodTypes":"디저트/차/베이커리","latitude":37.5969523916725,"longitude":127.376921701109,"bookYear":null,"nameKR":"컨테이너베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":39083,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5244326592628,"longitude":126.873975644148,"bookYear":null,"nameKR":"락희안(목동점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":39084,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.654131720459,"longitude":126.775737399414,"bookYear":null,"nameKR":"락희안(일산직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35245,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":36.5859731513744,"longitude":128.182642983577,"bookYear":null,"nameKR":"리반점","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":35703,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":33.4824985385837,"longitude":126.478095234835,"bookYear":null,"nameKR":"히비키아일랜드","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":35720,"bookStatus":"커버","websiteInstagram":null,"foodTypes":"프랑스식","latitude":37.5436687207723,"longitude":126.970948330904,"bookYear":null,"nameKR":"라따뚜이인서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":39166,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafevinz_/ ","foodTypes":"디저트/차/베이커리","latitude":37.1833723393357,"longitude":127.108852416286,"bookYear":null,"nameKR":"카페바인즈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":39163,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/black_issue_cafe","foodTypes":"디저트/차/베이커리","latitude":37.7474397991279,"longitude":126.487458110158,"bookYear":null,"nameKR":"블랙이슈","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":39162,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi._.maker","foodTypes":"일식","latitude":37.4730513390026,"longitude":127.141262948182,"bookYear":null,"nameKR":"스시메이커","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":39158,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6231971923876,"longitude":127.077602655342,"bookYear":null,"nameKR":"어빌리티커피(공릉점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":39157,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5982757125499,"longitude":127.056583765753,"bookYear":null,"nameKR":"어빌리티커피(외대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":39156,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kartstantan_yangsan/","foodTypes":"일식","latitude":35.3153665913121,"longitude":128.990814217086,"bookYear":null,"nameKR":"카츠탄탄(물금점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":39154,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_sisimma/","foodTypes":"디저트/차/베이커리","latitude":35.3013569450519,"longitude":128.592667265869,"bookYear":null,"nameKR":"시심마","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":34201,"bookStatus":"커버","websiteInstagram":"","foodTypes":"기타","latitude":37.5346111494099,"longitude":127.008791575449,"bookYear":null,"nameKR":"불래","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":39152,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hito_soba","foodTypes":"일식","latitude":37.5158580433375,"longitude":126.726800852864,"bookYear":null,"nameKR":"히토소바","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":37165,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/tthbar/","foodTypes":"한식(일반한식)","latitude":37.5054026643012,"longitude":127.109283643946,"bookYear":null,"nameKR":"뜻한바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":29606,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tsubasa841","foodTypes":"일식","latitude":37.5212973163879,"longitude":126.928884767419,"bookYear":null,"nameKR":"스시아라타","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":25537,"bookStatus":"커버","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.53742,"longitude":126.8944,"bookYear":null,"nameKR":"원조북어국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":39148,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/danoc_gelato/","foodTypes":"디저트/차/베이커리","latitude":37.5447477438441,"longitude":126.948258201287,"bookYear":null,"nameKR":"다녹","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":39147,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/mattoita7","foodTypes":"기타","latitude":37.4763126069551,"longitude":126.870100850823,"bookYear":null,"nameKR":"마또이따 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":39143,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kitschplate_misa","foodTypes":"바/주점","latitude":37.5633033624167,"longitude":127.189707996219,"bookYear":null,"nameKR":"키치플레이트","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":39142,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sarrr_performance/","foodTypes":"미국식","latitude":37.3806957155085,"longitude":126.658783691215,"bookYear":null,"nameKR":"사르르(트리플스트리트점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":38715,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.8452895969151,"longitude":127.991915607994,"bookYear":null,"nameKR":"향나무식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":39028,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/stable_moment03","foodTypes":"디저트/차/베이커리","latitude":35.8044841078192,"longitude":128.502862670594,"bookYear":null,"nameKR":"스테이블모먼트(화원점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":39048,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dh_보류ing","foodTypes":"한식(민물어패류)","latitude":35.1619085351709,"longitude":129.167258911602,"bookYear":null,"nameKR":"풍천만민물장어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":21231,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5802603634986,"longitude":126.970979516515,"bookYear":null,"nameKR":"오쁘띠베르","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":18293,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"우물집(본점)","ribbonType":"정보없음","zone2_1":"","zone2_2":"","status":"ACTIVE"},
        {"id":28460,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/namkyeong_makguksu_/","foodTypes":"한식(면류)","latitude":37.50942,"longitude":127.085119,"bookYear":null,"nameKR":"남경막국수(잠실점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33324,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/namkyeong_makguksu_/","foodTypes":"한식(면류)","latitude":37.8983155987917,"longitude":128.833526505706,"bookYear":null,"nameKR":"남경막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33655,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/namkyeong_makguksu_/","foodTypes":"한식(면류)","latitude":38.1821331548355,"longitude":128.601625942964,"bookYear":null,"nameKR":"남경막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":39058,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/heyme_seoul/ ","foodTypes":"디저트/차/베이커리","latitude":37.5255639255313,"longitude":127.030462369391,"bookYear":null,"nameKR":"헤이미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39059,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4425062298688,"longitude":126.701518656221,"bookYear":null,"nameKR":"헤이미마카롱","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":33914,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.cumbeo","foodTypes":"디저트/차/베이커리","latitude":37.5427034108491,"longitude":127.14822016324,"bookYear":null,"nameKR":"카페쿰베오","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":38141,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eojugwan/","foodTypes":"바/주점","latitude":36.7974228064473,"longitude":127.098968299806,"bookYear":null,"nameKR":"어주관","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":37859,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/roasted_fish_cj/","foodTypes":"한식(어패류)","latitude":36.6462701039595,"longitude":127.477234499524,"bookYear":null,"nameKR":"생선구이전문점정가네","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":39105,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seek_specialty_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.478295565561,"longitude":126.955192496959,"bookYear":null,"nameKR":"씨크스페셜티커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":39072,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bae_ko.izakaya","foodTypes":"바/주점","latitude":37.252667089483,"longitude":127.07836103561,"bookYear":null,"nameKR":"배코","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35425,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_881122/","foodTypes":"일식","latitude":35.8699691145675,"longitude":128.605399983134,"bookYear":null,"nameKR":"무화식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":39071,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.2819798698923,"longitude":127.445350458113,"bookYear":null,"nameKR":"도깨스낵바","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":39069,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kyozabar/ ","foodTypes":"중식","latitude":35.8458522803728,"longitude":127.109302703674,"bookYear":null,"nameKR":"쿄","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":39065,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.2919963939424,"longitude":127.067612383165,"bookYear":null,"nameKR":"르페르베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":39064,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1244478448113,"longitude":126.897585905053,"bookYear":null,"nameKR":"수지화담","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":39063,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_sang_sang/ ","foodTypes":"바/주점","latitude":33.5432304162268,"longitude":126.662251627143,"bookYear":null,"nameKR":"상상(함덕점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":39062,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hanwoo2072/ ","foodTypes":"한식(육류)","latitude":35.2656783212331,"longitude":129.226915488972,"bookYear":null,"nameKR":"한우2072","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":39061,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/murik_kr/","foodTypes":"디저트/차/베이커리","latitude":37.560386517159,"longitude":126.828207752706,"bookYear":null,"nameKR":"무릭그릭요거트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":39060,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":36.0917150506992,"longitude":128.355594545965,"bookYear":null,"nameKR":"가가스시","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":39054,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rabbit_hole_yp/","foodTypes":"디저트/차/베이커리","latitude":37.5300279047792,"longitude":127.465381511608,"bookYear":null,"nameKR":"래빗홀","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":39056,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/blacksteak_chuncheon/ ","foodTypes":"기타","latitude":37.8441385110682,"longitude":127.714742794439,"bookYear":null,"nameKR":"블랙스테이크(춘천점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":39047,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kinship_yy/ ","foodTypes":"바/주점","latitude":38.1522915725967,"longitude":128.602790465093,"bookYear":null,"nameKR":"킨십","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":39052,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/alouette_bakery","foodTypes":"디저트/차/베이커리","latitude":36.605539979459,"longitude":127.516784133164,"bookYear":null,"nameKR":"종달새빵집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":38727,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/im__pie","foodTypes":"디저트/차/베이커리","latitude":37.5944795411923,"longitude":127.060274080156,"bookYear":null,"nameKR":"아임파이(외대본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":38728,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/im__pie","foodTypes":"디저트/차/베이커리","latitude":37.5913207967506,"longitude":127.04952849368,"bookYear":null,"nameKR":"아임파이(경희대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":39005,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/seonga1945","foodTypes":"한식(면류)","latitude":37.623274271103,"longitude":126.712780586966,"bookYear":null,"nameKR":"성가옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":35314,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tasty_doha/","foodTypes":"바/주점","latitude":37.5049955195503,"longitude":126.846227499702,"bookYear":null,"nameKR":"도하","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":36839,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hasirado_ver.2","foodTypes":"바/주점","latitude":37.5292585275277,"longitude":127.122064649067,"bookYear":null,"nameKR":"하시라도(강동구청점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36838,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/hasirado","foodTypes":"바/주점","latitude":37.4971711081955,"longitude":127.120021761888,"bookYear":null,"nameKR":"하시라도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":39014,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/butter_zip","foodTypes":"디저트/차/베이커리","latitude":36.798602191399,"longitude":127.119108998973,"bookYear":null,"nameKR":"버터집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":37764,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/reborn_pasta/","foodTypes":"이탈리아식","latitude":37.742379104044,"longitude":126.493857676537,"bookYear":null,"nameKR":"리본파스타","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":34453,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kiki.gelato","foodTypes":"디저트/차/베이커리","latitude":37.4772490250994,"longitude":126.979111332251,"bookYear":null,"nameKR":"키키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":35442,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/key__meal/","foodTypes":"바/주점","latitude":35.1764994415874,"longitude":126.917029895584,"bookYear":null,"nameKR":"기밀","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":38799,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/4season_kitchen_bundang","foodTypes":"이탈리아식","latitude":37.3603456172908,"longitude":127.102189666723,"bookYear":null,"nameKR":"포시즌키친(분당직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":38800,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/4season_kitchen_gwangyo","foodTypes":"이탈리아식","latitude":37.2839417579058,"longitude":127.056777997528,"bookYear":null,"nameKR":"포시즌키친(광교직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38807,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1479352023554,"longitude":126.853087566998,"bookYear":null,"nameKR":"더문","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":38823,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/concasse__seoul_forest/","foodTypes":"이탈리아식","latitude":37.5468294033531,"longitude":127.043004658207,"bookYear":null,"nameKR":"콩카세","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":38824,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5781896234291,"longitude":126.985835670099,"bookYear":null,"nameKR":"콩카세(안국점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":38860,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/890__official","foodTypes":"기타","latitude":37.517549573998,"longitude":127.021560267907,"bookYear":null,"nameKR":"신사890","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":39039,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":34.7954793631144,"longitude":128.046595654871,"bookYear":null,"nameKR":"램스하우스 ","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":33772,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5689990018634,"longitude":127.18314566024,"bookYear":null,"nameKR":"리꼬화덕피자(하남미사본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":37914,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pudini_official","foodTypes":"디저트/차/베이커리","latitude":34.97005757051,"longitude":127.521113200901,"bookYear":null,"nameKR":"푸디니","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":36719,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6766648904832,"longitude":126.798947685773,"bookYear":null,"nameKR":"청담특양평해장국일산본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":37638,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.7979125588967,"longitude":128.047813939607,"bookYear":null,"nameKR":"유즈노모레","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":38525,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.0899928888954,"longitude":128.355991752868,"bookYear":null,"nameKR":"라떼프랑스제과점","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":38986,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/idee.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5245200145201,"longitude":127.130786678023,"bookYear":null,"nameKR":"이드커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":38985,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.547325815685,"longitude":126.941955724387,"bookYear":null,"nameKR":"잼베이커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":37836,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1251125169371,"longitude":126.910090854081,"bookYear":null,"nameKR":"카엔","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":38826,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.8435438657323,"longitude":128.70061733086,"bookYear":null,"nameKR":"꽃숯갈비거제","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":38806,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/apgujeon_official","foodTypes":"바/주점","latitude":37.5255913428421,"longitude":127.038405227112,"bookYear":null,"nameKR":"압구전","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":38862,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1571755159474,"longitude":129.120079897488,"bookYear":null,"nameKR":"꿀꺽하우스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36872,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3089521131686,"longitude":126.831761827009,"bookYear":null,"nameKR":"고잔갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":37777,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8358575613426,"longitude":127.137183008516,"bookYear":null,"nameKR":"장군꼬들살전문점","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":37180,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kows_ansan","foodTypes":"한식(육류)","latitude":37.3090945924351,"longitude":126.828951250039,"bookYear":null,"nameKR":"코우즈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":38701,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5403086642877,"longitude":126.951794731604,"bookYear":null,"nameKR":"작은중식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":35146,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.6666791740219,"longitude":126.772843437673,"bookYear":null,"nameKR":"아누보","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":39017,"bookStatus":"비활성","websiteInstagram":"https://www.instragram.com/Manbouno_insta","foodTypes":"이탈리아식","latitude":36.3665933639288,"longitude":127.372071411632,"bookYear":null,"nameKR":"만보우노","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":37254,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/p/C6VCPcppt0r/?igsh=b3Q0dmJudW5nZnVk","foodTypes":"한식(어패류)","latitude":35.1591466194807,"longitude":129.058505814797,"bookYear":null,"nameKR":"명자네아구찜","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33734,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tokyogram80/","foodTypes":"바/주점","latitude":37.3985502429164,"longitude":126.975681726675,"bookYear":null,"nameKR":"동경에서온주방장 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":33798,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/dilettante.jeju","foodTypes":"디저트/차/베이커리","latitude":33.5350894387184,"longitude":126.628423406812,"bookYear":null,"nameKR":"딜레탕트","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":38856,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/roja_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.3319629746095,"longitude":127.121224870419,"bookYear":null,"nameKR":"로제커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":37674,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/monchoix_patisserie?igsh=bWN4dzF6dTh4eHhk","foodTypes":"디저트/차/베이커리","latitude":37.5487580173572,"longitude":126.922178095709,"bookYear":null,"nameKR":"몽슈와","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":34526,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7468963950623,"longitude":127.068553630025,"bookYear":null,"nameKR":"이유간백년칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":36710,"bookStatus":"커버","websiteInstagram":"","foodTypes":"바/주점","latitude":37.487175864841,"longitude":126.92960267014,"bookYear":null,"nameKR":"이모포차","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":36885,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/petitemullae","foodTypes":"바/주점","latitude":37.5211270318868,"longitude":126.887036322921,"bookYear":null,"nameKR":"쁘띠문래","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":32008,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/yakitori_pano/","foodTypes":"일식","latitude":37.525657218364,"longitude":127.038643848539,"bookYear":null,"nameKR":"야키토리파노","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":38164,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yukdamdo_2022/","foodTypes":"한식(육류)","latitude":35.1590365809587,"longitude":129.068180072582,"bookYear":null,"nameKR":"육담도","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":36331,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_cheesetabby","foodTypes":"디저트/차/베이커리","latitude":33.5551482122918,"longitude":126.807868302351,"bookYear":null,"nameKR":"카페치즈태비","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":36373,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.2197352064867,"longitude":128.680484502685,"bookYear":null,"nameKR":"성산옥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38142,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dining_hyohyo/","foodTypes":"바/주점","latitude":37.606231004929,"longitude":127.023773153729,"bookYear":null,"nameKR":"다이닝효효","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":38073,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.459661308769,"longitude":128.064274490144,"bookYear":null,"nameKR":"횡성한우마을","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":38041,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5581552132895,"longitude":127.080289211042,"bookYear":null,"nameKR":"마우로아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":5848,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5330219354655,"longitude":126.971210192897,"bookYear":null,"nameKR":"삼각정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":7217,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.49415,"longitude":127.012383,"bookYear":null,"nameKR":"램하우스(교대역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":633,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5657709109319,"longitude":126.989973041455,"bookYear":null,"nameKR":"영락골뱅이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":641,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.564103,"longitude":126.983752,"bookYear":null,"nameKR":"명동부대찌개황기족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":140,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafeknockone/","foodTypes":"디저트/차/베이커리","latitude":37.592726,"longitude":127.052754,"bookYear":null,"nameKR":"녹원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":293,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.573554,"longitude":126.98802,"bookYear":null,"nameKR":"마산해물아구찜통나무식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":39002,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baekje13","foodTypes":"한식(가금류)","latitude":35.259844731385,"longitude":128.631379691997,"bookYear":null,"nameKR":"백제13월삼계탕(창원중동점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":36408,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/74_eunjoo","foodTypes":"한식(면류)","latitude":35.5385169768322,"longitude":129.336026888951,"bookYear":null,"nameKR":"오리엔탈무거면옥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":35422,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.37979192876,"longitude":126.660954609983,"bookYear":null,"nameKR":"깨비옥(트리플스트리트본점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":34735,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwalo_samsan/","foodTypes":"한식(육류)","latitude":37.5071376647226,"longitude":126.735897832003,"bookYear":null,"nameKR":"조선화로집(삼산점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":33438,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4902182676908,"longitude":126.75104981614,"bookYear":null,"nameKR":"커피네이션","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":39041,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.2528362654807,"longitude":126.511618621911,"bookYear":null,"nameKR":"산석쇠불고기","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":39040,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.1596459079901,"longitude":128.222953293643,"bookYear":null,"nameKR":"셰프스노트","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":39035,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gomcoffeeroasters/","foodTypes":"디저트/차/베이커리","latitude":37.4990373394459,"longitude":127.052795096936,"bookYear":null,"nameKR":"커피볶는곰","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":39036,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cbum2990/ ","foodTypes":"바/주점","latitude":37.506435630024,"longitude":127.028238753213,"bookYear":null,"nameKR":"대가육회2990 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":39032,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/shabuhwa_haeridan/","foodTypes":"기타","latitude":35.1669900012414,"longitude":129.158292155762,"bookYear":null,"nameKR":"샤브화해리단길","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":39029,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/justkatsu_","foodTypes":"일식","latitude":37.5107652923856,"longitude":127.022953477124,"bookYear":null,"nameKR":"저스트카츠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":39027,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/stable_moment01","foodTypes":"디저트/차/베이커리","latitude":35.897347660411,"longitude":128.6257966624,"bookYear":null,"nameKR":"스테이블모먼트(반석점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":39026,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/stable_moment02","foodTypes":"디저트/차/베이커리","latitude":35.8188131425013,"longitude":128.516243250012,"bookYear":null,"nameKR":"스테이블모먼트(월배점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":35387,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5588248070734,"longitude":126.831007635455,"bookYear":null,"nameKR":"브런치나인","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":38984,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3587476373614,"longitude":127.344238670807,"bookYear":null,"nameKR":"맛찬들왕소금구이(봉명점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":39024,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8289791322999,"longitude":129.209863701473,"bookYear":null,"nameKR":"늘곰탕","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":39023,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.4771553634401,"longitude":126.980416580444,"bookYear":null,"nameKR":"벨아미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":39020,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hey_dining/","foodTypes":"이탈리아식","latitude":35.5428041664423,"longitude":129.338951532911,"bookYear":null,"nameKR":"헤이다이닝","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":39019,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.3502744065186,"longitude":127.378713265946,"bookYear":null,"nameKR":"도군함박","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":39018,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/duckking_hwang","foodTypes":"한식(가금류)","latitude":35.9035841745448,"longitude":128.612309525598,"bookYear":null,"nameKR":"황오리","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":39015,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_1322/","foodTypes":"디저트/차/베이커리","latitude":35.8120033289458,"longitude":127.154067341194,"bookYear":null,"nameKR":"일삼이이","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":34436,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4721353946075,"longitude":126.62617570865,"bookYear":null,"nameKR":"꼬알라파이(신포본점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":29923,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.47227150469785,"longitude":126.62576715648247,"bookYear":null,"nameKR":"스시사쿠(신포점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":35951,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4419095642788,"longitude":126.709064796775,"bookYear":null,"nameKR":"스시민(구월점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":35382,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5271100683154,"longitude":126.672642894273,"bookYear":null,"nameKR":"천월반점(가정루원시티점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":35381,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5333419696599,"longitude":126.645601017085,"bookYear":null,"nameKR":"천월반점(청라본점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":25348,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.545969,"longitude":126.67377,"bookYear":null,"nameKR":"태백산(서구점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":32663,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5245523504727,"longitude":126.630912113411,"bookYear":null,"nameKR":"픽스치킨(인천청라점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":4305,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ghmy1004/","foodTypes":"한식(어패류)","latitude":37.424372,"longitude":126.422627,"bookYear":null,"nameKR":"신공항마을","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":32381,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5037507751413,"longitude":127.02605715196,"bookYear":null,"nameKR":"삼겹살전문점대통령","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":36992,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/youngpohwaro_ingye/","foodTypes":"한식(육류)","latitude":37.2620750750271,"longitude":127.034448695002,"bookYear":null,"nameKR":"영포화로(인계점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36471,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_hoesang_","foodTypes":"한식(어패류)","latitude":37.5138389549701,"longitude":126.89479367748,"bookYear":null,"nameKR":"회상","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":7387,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"나주집횡성한우","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":39013,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coolkids_cookie","foodTypes":"디저트/차/베이커리","latitude":37.508741750011,"longitude":127.105256963898,"bookYear":null,"nameKR":"쿨키즈쿠키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":39012,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jinsoobabsang/","foodTypes":"한식(어패류)","latitude":35.1683371251336,"longitude":129.026977126452,"bookYear":null,"nameKR":"진수밥상","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":39009,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.2532120357276,"longitude":126.506154917179,"bookYear":null,"nameKR":"남매네흑돼지(서귀포신시가지점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":39008,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.2500546282277,"longitude":126.562988676862,"bookYear":null,"nameKR":"남매네흑돼지(서귀포점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":39004,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.1912990112669,"longitude":126.843908620454,"bookYear":null,"nameKR":"타오레스토랑 ","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":37542,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/vinology.winebar/","foodTypes":"기타","latitude":37.5224895714539,"longitude":127.040766561869,"bookYear":null,"nameKR":"비놀로지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":39007,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ikemens_suncheon/","foodTypes":"일식","latitude":34.9690435446486,"longitude":127.523706538296,"bookYear":null,"nameKR":"이케멘스","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":39006,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeowoo_katsu/","foodTypes":"일식","latitude":37.3091164539686,"longitude":126.815319978741,"bookYear":null,"nameKR":"여우카츠","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":39003,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4997484038191,"longitude":127.029196249265,"bookYear":null,"nameKR":"하우마라탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":39001,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dear._.moment/","foodTypes":"기타","latitude":37.546838498528,"longitude":126.94202309316,"bookYear":null,"nameKR":"디어모먼트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":38999,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.3302716307234,"longitude":129.00395160498,"bookYear":null,"nameKR":"오봉해물칼국수","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":38998,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7931932175295,"longitude":126.68610782869,"bookYear":null,"nameKR":"숑스바베큐(파주본점) ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":38995,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/banpin_busan/","foodTypes":"중식","latitude":35.1583914954415,"longitude":129.062792738905,"bookYear":null,"nameKR":"반핀","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38994,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ayheyaseoul","foodTypes":"디저트/차/베이커리","latitude":37.5252199778111,"longitude":126.961959913139,"bookYear":null,"nameKR":"에헤야서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":38990,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5315220220208,"longitude":127.074974205518,"bookYear":null,"nameKR":"옹수육","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38987,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.9752929990175,"longitude":128.952477035609,"bookYear":null,"nameKR":"신제주해장국","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영천시","status":"ACTIVE"},
        {"id":38983,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mansensushi70/","foodTypes":"일식","latitude":37.6305360744058,"longitude":127.117141360602,"bookYear":null,"nameKR":"만센","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":38982,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5302893644917,"longitude":126.921969207155,"bookYear":null,"nameKR":"티토커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":38981,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dnd_earth_dining_lab","foodTypes":"이탈리아식","latitude":37.5915670417169,"longitude":127.222486030557,"bookYear":null,"nameKR":"디엔디어스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38745,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gelatoment/","foodTypes":"디저트/차/베이커리","latitude":37.5063460974806,"longitude":127.021717872538,"bookYear":null,"nameKR":"젤라또먼트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":12365,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.271,"longitude":126.572184,"bookYear":null,"nameKR":"20호할머니칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":34649,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5015909531368,"longitude":126.77311799622,"bookYear":null,"nameKR":"청기와타운(부천중동점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":12116,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.518323898929,"longitude":126.804550086296,"bookYear":null,"nameKR":"심가면옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":30329,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5594526887597,"longitude":126.833912548403,"bookYear":null,"nameKR":"바삭하게(마곡직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":38775,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.6394126852324,"longitude":127.023626914694,"bookYear":null,"nameKR":"이씨부엌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":22681,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.167275,"longitude":129.037068,"bookYear":null,"nameKR":"화성왕족발","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":9573,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.006577,"longitude":126.442714,"bookYear":null,"nameKR":"바다나라회센타","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":11323,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.140279089316,"longitude":129.061402128032,"bookYear":null,"nameKR":"고메회초밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":36228,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3018128006666,"longitude":127.009992598525,"bookYear":null,"nameKR":"할머니손순대","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37986,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/JaJakMaEul/","foodTypes":"한식(육류)","latitude":37.164549401012,"longitude":127.376134148688,"bookYear":null,"nameKR":"자작마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":37985,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/royal__macaron/","foodTypes":"디저트/차/베이커리","latitude":37.5022684144176,"longitude":127.035495494875,"bookYear":null,"nameKR":"로얄마카롱","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":37936,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.3581103112345,"longitude":126.931939270521,"bookYear":null,"nameKR":"닭발퀸","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":37934,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/anmokbusan/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1449284249312,"longitude":129.110750833842,"bookYear":null,"nameKR":"안목","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37877,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/gong_gam__","latitude":37.8537254025597,"longitude":127.746107694909,"bookYear":null,"nameKR":"공감","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":37235,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.5102119921814,"longitude":126.889153625606,"bookYear":null,"nameKR":"저장고THE ESPRESSO ","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":36625,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/zucchero.caffe/","foodTypes":"디저트/차/베이커리","latitude":37.5444296352305,"longitude":126.96691671514,"bookYear":null,"nameKR":"쥬케로카페","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":35544,"bookStatus":"커버","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5078881923517,"longitude":126.732053818663,"bookYear":null,"nameKR":"비엣테이블","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":38720,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":38.2134745987189,"longitude":128.596660699395,"bookYear":null,"nameKR":"속초아이파크스위트호텔파인다이닝","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":38757,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/runit_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.5780246762684,"longitude":126.89420566206,"bookYear":null,"nameKR":"런이트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":36828,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5050370851613,"longitude":127.030781669704,"bookYear":null,"nameKR":"라운지희움","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":38804,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5380343644151,"longitude":127.049069492189,"bookYear":null,"nameKR":"한나","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34357,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5069000853819,"longitude":127.031496321997,"bookYear":null,"nameKR":"레스토랑핫이슈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":38726,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5463326811966,"longitude":126.922684063912,"bookYear":null,"nameKR":"후쿠란(상수본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36003,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cheongonchae","foodTypes":"한식(일반한식)","latitude":35.8376215586167,"longitude":129.208798388106,"bookYear":null,"nameKR":"청온채","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":37141,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.2108651707078,"longitude":127.089445559923,"bookYear":null,"nameKR":"그라츠커피랩(금강IX점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":37142,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.1720762292297,"longitude":127.104078001389,"bookYear":null,"nameKR":"그라츠커피랩 본점(동탄호수공원)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":37839,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2628524181995,"longitude":127.034400798874,"bookYear":null,"nameKR":"용범이네인계동껍데기(인계본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37557,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5652140056315,"longitude":126.992390927793,"bookYear":null,"nameKR":"까이9","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":37805,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8268651347631,"longitude":128.622313997802,"bookYear":null,"nameKR":"소노마마","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":38821,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7290153974816,"longitude":127.038337046987,"bookYear":null,"nameKR":"약초원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":38795,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.sobani","foodTypes":"디저트/차/베이커리","latitude":33.5125647740452,"longitude":126.525066832378,"bookYear":null,"nameKR":"소바니","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":35510,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.5499672104827,"longitude":129.295655437701,"bookYear":null,"nameKR":"225토마토스트릿(태화점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":38732,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8354230874599,"longitude":128.725314639095,"bookYear":null,"nameKR":"봉자막창(정평점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":33997,"bookStatus":"커버","websiteInstagram":"","foodTypes":"바/주점","latitude":37.475300231939,"longitude":126.890853923229,"bookYear":null,"nameKR":"엘티커피바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":38848,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.7756545056303,"longitude":128.941612166377,"bookYear":null,"nameKR":"번패티번강릉","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33782,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ou_topos_coffee","foodTypes":"디저트/차/베이커리","latitude":37.494471542072,"longitude":126.986676084564,"bookYear":null,"nameKR":"우토포스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":36808,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.2959646807164,"longitude":127.057315761435,"bookYear":null,"nameKR":"덴트로커피하우스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35745,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6708841216538,"longitude":126.781713380579,"bookYear":null,"nameKR":"앤디커피밤리단길","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":34739,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1750614644809,"longitude":128.148111482554,"bookYear":null,"nameKR":"금산골(진주혁신도시점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":34865,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.7188192534429,"longitude":127.185313746865,"bookYear":null,"nameKR":"나눔터오리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":34870,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.7991366812862,"longitude":127.102086601383,"bookYear":null,"nameKR":"엄나무닭곰탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":34885,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.3266931431465,"longitude":126.887853492772,"bookYear":null,"nameKR":"나의집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":34886,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.602800478674,"longitude":127.352740671391,"bookYear":null,"nameKR":"남촌집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":34887,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6732760274249,"longitude":126.789085580665,"bookYear":null,"nameKR":"다람쥐마을누룽지백숙","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":34888,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.1792079587232,"longitude":127.016431887146,"bookYear":null,"nameKR":"덕담오리초계탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":34890,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dodam_ly/","foodTypes":"한식(일반한식)","latitude":37.4402247180105,"longitude":126.794046810355,"bookYear":null,"nameKR":"도담","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":34892,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4620381929749,"longitude":127.155750829868,"bookYear":null,"nameKR":"동의궁 백숙 닭죽촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":34893,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7733846719007,"longitude":127.157736227841,"bookYear":null,"nameKR":"동이손만두","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":34896,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3845082077025,"longitude":127.254749996552,"bookYear":null,"nameKR":"들밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":34898,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4994071882238,"longitude":126.674009424285,"bookYear":null,"nameKR":"금호정 ","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":34901,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/miga_sujebi","foodTypes":"한식(일반한식)","latitude":37.3867307725774,"longitude":127.121976391707,"bookYear":null,"nameKR":"미가 들깨수제비 충무김밥 본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":34902,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gaetbada","foodTypes":"한식(어패류)","latitude":37.6855840694961,"longitude":126.31794179338,"bookYear":null,"nameKR":"갯바다 해물샤브칼국수","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":34906,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4171535104078,"longitude":127.132480611118,"bookYear":null,"nameKR":"사계진미 야탑본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":34907,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.6913908377895,"longitude":127.886152201206,"bookYear":null,"nameKR":"강희네","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":34908,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3581281496342,"longitude":126.916852084187,"bookYear":null,"nameKR":"산너머남촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":34909,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5469005493282,"longitude":127.249616893465,"bookYear":null,"nameKR":"산애로","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":34920,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3769053693663,"longitude":126.76531713087,"bookYear":null,"nameKR":"퓨전 굽는삼계탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":34921,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.1095556710523,"longitude":127.244828766698,"bookYear":null,"nameKR":"아라리곰탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":34924,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3145208524683,"longitude":126.936117404341,"bookYear":null,"nameKR":"초평골","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":34926,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3495129168864,"longitude":126.820828465574,"bookYear":null,"nameKR":"콩밭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":34931,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.7674318123495,"longitude":127.100622718633,"bookYear":null,"nameKR":"옛길우거지밥상","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":34933,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6429705830892,"longitude":127.2032540989,"bookYear":null,"nameKR":"옛날에손만두","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":34935,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":38.2068440300244,"longitude":128.352789461137,"bookYear":null,"nameKR":"다리골식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":34937,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.1825865651719,"longitude":128.480034409465,"bookYear":null,"nameKR":"다슬기향촌성호식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":34938,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4615907916562,"longitude":127.168337104315,"bookYear":null,"nameKR":"대관령감자옹심이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":34940,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.3573656860743,"longitude":126.987368918978,"bookYear":null,"nameKR":"천만애능이백숙","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":34941,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.8627607169771,"longitude":127.7279806914,"bookYear":null,"nameKR":"백일칼국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":34942,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.5117486007416,"longitude":126.531779199888,"bookYear":null,"nameKR":"가다오다","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34943,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.9313601707332,"longitude":127.790376588308,"bookYear":null,"nameKR":"샘토명물닭갈비본점","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":34952,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gozip_jeju/","foodTypes":"한식(어패류)","latitude":33.2579833781002,"longitude":126.416707323322,"bookYear":null,"nameKR":"고집돌우럭","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":34954,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.3742038762622,"longitude":127.320484283355,"bookYear":null,"nameKR":"거저울삼잎국화나물밥","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":34955,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.5102850304776,"longitude":126.482463365309,"bookYear":null,"nameKR":"삼번지횟집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34958,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.5122113539073,"longitude":126.527023650217,"bookYear":null,"nameKR":"일팔팔","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34959,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.3397049842968,"longitude":127.38964074496,"bookYear":null,"nameKR":"명옥헌삼대진곰탕","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":34964,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.0047408142857,"longitude":128.592960022859,"bookYear":null,"nameKR":"산마을곤드레산채밥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":34966,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.3153274641636,"longitude":127.438579965082,"bookYear":null,"nameKR":"옥이네칼국수보리밥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":34967,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.5980226793391,"longitude":128.199305417587,"bookYear":null,"nameKR":"문경상황버섯삼계탕","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":34969,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jangdong_acornjelly/","foodTypes":"한식(가금류)","latitude":36.3886738215691,"longitude":127.34711844626,"bookYear":null,"nameKR":"장동묵집","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":34970,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.1175845650611,"longitude":128.339983138371,"bookYear":null,"nameKR":"세정","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":34971,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.7973301400342,"longitude":129.312654843917,"bookYear":null,"nameKR":"쑥부쟁이","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":34972,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.6768167526523,"longitude":126.673327301841,"bookYear":null,"nameKR":"팔천구백냥","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":34976,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.7750130996317,"longitude":126.996393096211,"bookYear":null,"nameKR":"두향 본점","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":34977,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.7173117812208,"longitude":126.334014951392,"bookYear":null,"nameKR":"명량노을지는갯마을편","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":34978,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.2451705265128,"longitude":129.319754377118,"bookYear":null,"nameKR":"진주식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":34980,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.792203122362,"longitude":126.993872944104,"bookYear":null,"nameKR":"민들레밥상","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":34981,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8409229777396,"longitude":129.211800682255,"bookYear":null,"nameKR":"해가담 본점","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":34982,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8320573422781,"longitude":128.682809410741,"bookYear":null,"nameKR":"까꾸리웰빙손칼국수 ","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":34985,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seosan.sigolpoongkyoung/?igshid=1vjw6ahuf34e2","foodTypes":"한식(일반한식)","latitude":36.6853283066378,"longitude":126.387350206196,"bookYear":null,"nameKR":"시골풍경","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":34987,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8397364906539,"longitude":128.536455933078,"bookYear":null,"nameKR":"본동복어","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":34993,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.761552479847,"longitude":126.883258233954,"bookYear":null,"nameKR":"황금버섯","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":34994,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.797007086691,"longitude":128.547828475767,"bookYear":null,"nameKR":"유미정 ","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":34995,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.8166515623029,"longitude":127.15873448763,"bookYear":null,"nameKR":"명가곤드레추어탕","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":34996,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.9756061754191,"longitude":127.965057843286,"bookYear":null,"nameKR":"국수타령","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":34997,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.0226368052916,"longitude":127.96727804058,"bookYear":null,"nameKR":"그린가든","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":34999,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.026146767921,"longitude":128.384861897564,"bookYear":null,"nameKR":"보리곳간 ","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":35001,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.9395081680676,"longitude":128.606548033569,"bookYear":null,"nameKR":"토속정 식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":35002,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2662210477302,"longitude":129.235034641966,"bookYear":null,"nameKR":"갯마을식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":35003,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.2985179348271,"longitude":127.578018789908,"bookYear":null,"nameKR":"장수식당 ","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":35007,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.6139795592511,"longitude":127.48593332516,"bookYear":null,"nameKR":"정보한방삼계탕","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35008,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.9537897579723,"longitude":127.642726626963,"bookYear":null,"nameKR":"큰곰집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"음성군","status":"ACTIVE"},
        {"id":35010,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1927097555814,"longitude":126.829496172484,"bookYear":null,"nameKR":"김상궁","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":35012,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1504152224841,"longitude":126.943741060702,"bookYear":null,"nameKR":"대밭가든","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":35013,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1446559261187,"longitude":126.847800097343,"bookYear":null,"nameKR":"산일능이버섯백숙","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":35015,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1320462302245,"longitude":126.946642399757,"bookYear":null,"nameKR":"수자타","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":35017,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1979828048672,"longitude":126.874349396908,"bookYear":null,"nameKR":"연제장어","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":35018,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1312736738996,"longitude":126.924300333855,"bookYear":null,"nameKR":"윤가네 나주곰탕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":35019,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1272621313601,"longitude":126.852367460867,"bookYear":null,"nameKR":"정광석항아리보쌈 매월점","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":35020,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.2438238595842,"longitude":127.48165708072,"bookYear":null,"nameKR":"강남가든","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":35021,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1430882479054,"longitude":126.430849930764,"bookYear":null,"nameKR":"고향맛식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":35022,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2180788200142,"longitude":129.227278206317,"bookYear":null,"nameKR":"동해횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":35023,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.9856245105293,"longitude":126.748832237278,"bookYear":null,"nameKR":"꽃무지야생화촌","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":35024,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1451151088584,"longitude":129.067138460395,"bookYear":null,"nameKR":"외가집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":35025,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.2576899435881,"longitude":127.599970020242,"bookYear":null,"nameKR":"당치민박산장","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":35026,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.8040460916705,"longitude":126.393219314702,"bookYear":null,"nameKR":"나나네집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":35028,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0199738755564,"longitude":126.791387159842,"bookYear":null,"nameKR":"민주네 생선구이","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":35029,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.8412387256296,"longitude":126.352184600457,"bookYear":null,"nameKR":"꽃피는 무화가","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"신안군","status":"ACTIVE"},
        {"id":35030,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1394831673807,"longitude":129.107740772525,"bookYear":null,"nameKR":"고현곰장어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35031,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.9455502391673,"longitude":127.517142433598,"bookYear":null,"nameKR":"역전나그네","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":35033,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.2486619396778,"longitude":126.880347649301,"bookYear":null,"nameKR":"보자기 ","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":35034,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.9513334531999,"longitude":127.48638221869,"bookYear":null,"nameKR":"웰빙옛날팥죽","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":35036,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.5831274880102,"longitude":126.858094230023,"bookYear":null,"nameKR":"녹원전통찻집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":35038,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.9683568506325,"longitude":126.988447632621,"bookYear":null,"nameKR":"가리왕산약초백숙","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":35039,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.9634540576492,"longitude":126.694601000603,"bookYear":null,"nameKR":"감칠맛생선","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":35041,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2418049922533,"longitude":129.088771184714,"bookYear":null,"nameKR":"본가벌나무복집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":35042,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.7914210025801,"longitude":127.136392876834,"bookYear":null,"nameKR":"꽁당보리밥 청국장","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":35043,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8471968846859,"longitude":127.150402155566,"bookYear":null,"nameKR":"다담","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":35044,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.1862877508734,"longitude":129.070755418088,"bookYear":null,"nameKR":"한방장어구이","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":35047,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.7916430399048,"longitude":127.105823566312,"bookYear":null,"nameKR":"대장금","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":35048,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.9668616275467,"longitude":126.767879839974,"bookYear":null,"nameKR":"뜨락누룽지백숙","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":35051,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ssmc5288","foodTypes":"한식(어패류)","latitude":35.7279457174031,"longitude":127.040994513382,"bookYear":null,"nameKR":"밥도둑게장마을","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"김제시","status":"ACTIVE"},
        {"id":35054,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.713746086365,"longitude":126.982678995903,"bookYear":null,"nameKR":"아옛날","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"김제시","status":"ACTIVE"},
        {"id":35056,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.5037252162931,"longitude":126.597086695195,"bookYear":null,"nameKR":"오가네 장어","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":35058,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0961240014857,"longitude":129.025876299473,"bookYear":null,"nameKR":"부영집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":35059,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.6407250936008,"longitude":127.102921702276,"bookYear":null,"nameKR":"혜성가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":35063,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.4928398827749,"longitude":129.081470803273,"bookYear":null,"nameKR":"동심","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":35065,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.8924170955268,"longitude":128.62580701812,"bookYear":null,"nameKR":"도토리친구","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":35066,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeonboggugsu/","foodTypes":"한식(면류)","latitude":35.2024062486549,"longitude":128.565089960396,"bookYear":null,"nameKR":"전복국수","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":35068,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":34.863157921602,"longitude":128.723065908188,"bookYear":null,"nameKR":"보령청국장","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":35070,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.0984957289333,"longitude":128.098839877007,"bookYear":null,"nameKR":"우리집가든","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":35071,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1558782873551,"longitude":129.13232797908,"bookYear":null,"nameKR":"해저도시","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35072,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.4929114985809,"longitude":129.081216252263,"bookYear":null,"nameKR":"산들바람","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":35073,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.6108365247104,"longitude":129.077789751138,"bookYear":null,"nameKR":"가랑잎새","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":35074,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1500845489319,"longitude":128.696046624457,"bookYear":null,"nameKR":"우두골","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":35075,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.305102296963,"longitude":128.735904637894,"bookYear":null,"nameKR":"산야초한방백숙","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":35076,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ulsanwangkong/","foodTypes":"한식(일반한식)","latitude":35.5737563837892,"longitude":129.315019152478,"bookYear":null,"nameKR":"왕콩수제두부전문점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":35077,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/wish_bone_bean","foodTypes":"한식(가금류)","latitude":35.312728314378,"longitude":128.986928980012,"bookYear":null,"nameKR":"위시본삼계탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":35078,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8179562989093,"longitude":128.826497806577,"bookYear":null,"nameKR":"남광흑염소","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":35080,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.8943888242657,"longitude":128.661325160917,"bookYear":null,"nameKR":"청마루 대구뽈찜","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":35081,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":34.8789647770607,"longitude":128.656267405196,"bookYear":null,"nameKR":"초정가든 ","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":35084,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1692810686751,"longitude":128.131534684315,"bookYear":null,"nameKR":"호박오리샤브샤브","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":35085,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.2344241896084,"longitude":128.890651329999,"bookYear":null,"nameKR":"활천삼계탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":35086,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.8642630414299,"longitude":127.721464140356,"bookYear":null,"nameKR":"황태마을 ","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":35087,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.8876498712425,"longitude":127.76651999321,"bookYear":null,"nameKR":"양지뜨락","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":35089,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4642221085128,"longitude":126.709657703219,"bookYear":null,"nameKR":"녹수아구찜","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":35109,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3670752172197,"longitude":128.002821570679,"bookYear":null,"nameKR":"연잎밥마실","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":35111,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.8856856932545,"longitude":127.718156245096,"bookYear":null,"nameKR":"호남식당 닭갈비막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":35112,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pungnyeon_sigdang/","foodTypes":"한식(일반한식)","latitude":37.8541426662904,"longitude":127.747280316915,"bookYear":null,"nameKR":"풍년식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":35113,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.9062819773336,"longitude":127.753126163286,"bookYear":null,"nameKR":"해가빛","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":35116,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6822896440352,"longitude":126.779319124914,"bookYear":null,"nameKR":"장수관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35138,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.6072395276507,"longitude":127.250741600915,"bookYear":null,"nameKR":"산수정능이버섯백숙","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":35346,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(육류)","latitude":37.3942371804577,"longitude":126.645717977765,"bookYear":null,"nameKR":"백제원(송도점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":35347,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(육류)","latitude":37.5096867500485,"longitude":126.7752918972,"bookYear":null,"nameKR":"백제원(부천점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":35348,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(육류)","latitude":37.6774007256766,"longitude":126.778434109654,"bookYear":null,"nameKR":"백제원(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35353,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(어패류)","latitude":37.3942371804577,"longitude":126.645717977765,"bookYear":null,"nameKR":"도쿄하나(송도점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":35354,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(어패류)","latitude":37.5096867500485,"longitude":126.7752918972,"bookYear":null,"nameKR":"도쿄하나(부천점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":35356,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(어패류)","latitude":37.213090364253,"longitude":127.053727431352,"bookYear":null,"nameKR":"도쿄하나(동탄점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":35357,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(어패류)","latitude":37.6774007256766,"longitude":126.778434109654,"bookYear":null,"nameKR":"도쿄하나(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":36173,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/siku__official/","foodTypes":"바/주점","latitude":35.1569897466324,"longitude":129.121384237113,"bookYear":null,"nameKR":"시쿠","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":36359,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fritzcoffeecompany","foodTypes":"디저트/차/베이커리","latitude":33.4595987798601,"longitude":126.930361087152,"bookYear":null,"nameKR":"프릳츠(제주성산점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":37154,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mongtan_jeju/","foodTypes":"한식(육류)","latitude":33.5533920518974,"longitude":126.71509377031,"bookYear":null,"nameKR":"몽탄(제주점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":37323,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/502coffeeroasters/","foodTypes":"디저트/차/베이커리","latitude":37.2237776967099,"longitude":127.277826420739,"bookYear":null,"nameKR":"502커피로스터스(용인점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":37615,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nakta_nakta","foodTypes":"디저트/차/베이커리","latitude":37.7071198673734,"longitude":127.038341755583,"bookYear":null,"nameKR":"나크타","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":37618,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pinehillcoffeehouse2018/","foodTypes":"디저트/차/베이커리","latitude":37.9242055744495,"longitude":127.044165115766,"bookYear":null,"nameKR":"파인힐커피하우스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":37623,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.7832182519032,"longitude":127.005807671381,"bookYear":null,"nameKR":"어둔골손두부","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":37635,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.2496220559544,"longitude":126.567588735932,"bookYear":null,"nameKR":"오는정김밥","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":37636,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.0863911924487,"longitude":129.076725991167,"bookYear":null,"nameKR":"피아크카페&베이커리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":37639,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_byukdol/","foodTypes":"디저트/차/베이커리","latitude":35.1548199041731,"longitude":129.058042362966,"bookYear":null,"nameKR":"카페벽돌","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":37641,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_muryangsu","foodTypes":"디저트/차/베이커리","latitude":36.9944370564241,"longitude":128.682831223339,"bookYear":null,"nameKR":"카페무량수","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":37645,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/haejusoban","foodTypes":"한식(육류)","latitude":33.2807296466008,"longitude":126.2782437385,"bookYear":null,"nameKR":"해주소반","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":37983,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ppeoldabang","foodTypes":"디저트/차/베이커리","latitude":37.2341897303881,"longitude":126.531274839432,"bookYear":null,"nameKR":"뻘다방","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":34196,"bookStatus":"승인","websiteInstagram":"https://www.instagram.com/gaha_dining/","foodTypes":"기타","latitude":37.5625864791901,"longitude":127.189964124756,"bookYear":"2024","nameKR":"가하","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":38758,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.771127884242,"longitude":128.881407337635,"bookYear":null,"nameKR":"다다","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":37879,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yasai.seoul/","foodTypes":"바/주점","latitude":37.5602745231998,"longitude":126.925745456653,"bookYear":null,"nameKR":"야사이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37898,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중남미식","latitude":37.5098762606741,"longitude":127.110329595751,"bookYear":null,"nameKR":"꼰미고","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":37952,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5392955185658,"longitude":126.734206593381,"bookYear":null,"nameKR":"한계령식당","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":37966,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.7879771411652,"longitude":126.696081751774,"bookYear":null,"nameKR":"이그조틱(헤이리점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":37969,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/half__pound/","foodTypes":"디저트/차/베이커리","latitude":37.503454752187,"longitude":127.098886715511,"bookYear":null,"nameKR":"하프파운드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":38160,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7145087868747,"longitude":126.760060271416,"bookYear":null,"nameKR":"봉우리가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":38722,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jackjack_kr","foodTypes":"미국식","latitude":37.5380094370883,"longitude":126.988879234066,"bookYear":null,"nameKR":"잭잭","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":38797,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.4691169590312,"longitude":126.935143076579,"bookYear":null,"nameKR":"해일리","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":38835,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/golmok_since1997/","foodTypes":"한식(민물어패류)","latitude":37.3950395010975,"longitude":126.917713991138,"bookYear":null,"nameKR":"골목추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":37794,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/icecreamok_official","foodTypes":"디저트/차/베이커리","latitude":34.7393476025094,"longitude":127.7354280448,"bookYear":null,"nameKR":"아이스크림옥","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":35285,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bean_tie/","foodTypes":"디저트/차/베이커리","latitude":35.8597045810288,"longitude":127.153120324625,"bookYear":null,"nameKR":"빈타이","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":36667,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"호주식","latitude":37.5650584505079,"longitude":126.921696845394,"bookYear":null,"nameKR":"업투미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":38735,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/casa_di_chacha","foodTypes":"디저트/차/베이커리","latitude":37.2792275876541,"longitude":127.442897888785,"bookYear":null,"nameKR":"까사디차차","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":38857,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.2262346445035,"longitude":126.946184820377,"bookYear":null,"nameKR":"오늘도낙곱새","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38853,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vietsocialclub","foodTypes":"아시아식","latitude":35.1592229342299,"longitude":129.06635918021,"bookYear":null,"nameKR":"비엣소셜클럽","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38851,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.4987912712721,"longitude":126.656523273916,"bookYear":null,"nameKR":"포빈즈","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":38830,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.555760371591,"longitude":126.920389119209,"bookYear":null,"nameKR":"육전국밥(서교점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":38828,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5560946594281,"longitude":126.925668458382,"bookYear":null,"nameKR":"육전국밥(홍대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":38829,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.512600208515,"longitude":127.108249353562,"bookYear":null,"nameKR":"육전국밥(석촌호수점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":38798,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/4season_kitchen_dongtan","foodTypes":"기타","latitude":37.2043294369834,"longitude":127.123146194395,"bookYear":null,"nameKR":"포시즌키친","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38837,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dimdimduck","foodTypes":"중식","latitude":37.500722082344,"longitude":127.030768815378,"bookYear":null,"nameKR":"딤딤덕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":38834,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nakwonsoba","foodTypes":"일식","latitude":37.5618759569563,"longitude":126.998310289762,"bookYear":null,"nameKR":"낙원의소바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8248,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.870775,"longitude":128.604835,"bookYear":null,"nameKR":"월성찜갈비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":38825,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5533409412184,"longitude":126.920416363995,"bookYear":null,"nameKR":"서교독대","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31463,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jaibonso","foodTypes":"한식(육류)","latitude":37.3985205289857,"longitude":126.923515934254,"bookYear":null,"nameKR":"재본소","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38822,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/iyashi_official_/","foodTypes":"바/주점","latitude":37.5912437146641,"longitude":127.017776610743,"bookYear":null,"nameKR":"이자카야이야시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":38819,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakery_hoo","foodTypes":"디저트/차/베이커리","latitude":34.7777497983739,"longitude":127.649363499643,"bookYear":null,"nameKR":"베이커리후","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":38817,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5469398891445,"longitude":127.043433260445,"bookYear":null,"nameKR":"요쇼쿠(서울숲본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":38816,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1369984382123,"longitude":129.06305148161,"bookYear":null,"nameKR":"트로피","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":38814,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.0074876918224,"longitude":127.268354337129,"bookYear":null,"nameKR":"몽키필리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":38811,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.3553349456431,"longitude":129.03745104272,"bookYear":null,"nameKR":"빌리빌리","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":38810,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/becuziluvit/","foodTypes":"디저트/차/베이커리","latitude":35.3318238334227,"longitude":129.003632650738,"bookYear":null,"nameKR":"빌리커피바","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":38760,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mipo_driverkitchen_/","foodTypes":"한식(어패류)","latitude":35.8376386086705,"longitude":129.20913536855,"bookYear":null,"nameKR":"미포기사식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30872,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cenacolo2019/","foodTypes":"이탈리아식","latitude":37.4722721241489,"longitude":126.620958238913,"bookYear":null,"nameKR":"체나콜로","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":37033,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":34.4160315272218,"longitude":126.591730859262,"bookYear":null,"nameKR":"땅끝정인숙칼국수","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"해남군","status":"ACTIVE"},
        {"id":31163,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":33.5179873205674,"longitude":126.523359458825,"bookYear":null,"nameKR":"맥파이(탑동점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":6278,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kyusyuulteuraamen/","foodTypes":"일식","latitude":37.657549,"longitude":126.772949,"bookYear":null,"nameKR":"큐슈울트라아멘","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":38783,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4893098030803,"longitude":127.123539674809,"bookYear":null,"nameKR":"서울팥집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":36697,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.401674438259,"longitude":126.953650424508,"bookYear":null,"nameKR":"막스로스터스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":38796,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hefls_bake","foodTypes":"디저트/차/베이커리","latitude":37.507445341716,"longitude":126.73018992145,"bookYear":null,"nameKR":"히플스베이크","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":38805,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oneun_tea","foodTypes":"디저트/차/베이커리","latitude":35.2366744283722,"longitude":128.69704749108,"bookYear":null,"nameKR":"온은","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38763,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi_yammy/","foodTypes":"일식","latitude":37.5396742606328,"longitude":127.128030911171,"bookYear":null,"nameKR":"스시야미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":31442,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thebaegalbi/","foodTypes":"한식(육류)","latitude":37.6352789276758,"longitude":127.215172308195,"bookYear":null,"nameKR":"태능배갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":37935,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/je_gyoung92?igsh=MTNicXU1d3g1cmF5eA==","foodTypes":"기타","latitude":35.189015796373,"longitude":129.215803538787,"bookYear":null,"nameKR":"베이드노엘","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":38777,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ddoddo.seoul","foodTypes":"바/주점","latitude":37.5736261541552,"longitude":126.924821840823,"bookYear":null,"nameKR":"또또","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":38788,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sensi.ulsan","foodTypes":"이탈리아식","latitude":35.5397411698601,"longitude":129.329317006327,"bookYear":null,"nameKR":"센시","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":38776,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/poong.bao/","foodTypes":"바/주점","latitude":36.3267458265332,"longitude":127.424453221085,"bookYear":null,"nameKR":"풍바오","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":37999,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeonducksam_jeonpo","foodTypes":"바/주점","latitude":35.1564099843031,"longitude":129.066019733027,"bookYear":null,"nameKR":"전덕삼","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37938,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":33.4134916434073,"longitude":126.282885076812,"bookYear":null,"nameKR":"플로웨이브","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":38736,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.128935520071,"longitude":129.047051126259,"bookYear":null,"nameKR":"손즈램","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":38748,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.2364155134024,"longitude":128.993667200972,"bookYear":null,"nameKR":"365부산밀면(본점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":33760,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistrohome_official/","foodTypes":"바/주점","latitude":37.5918872485982,"longitude":127.01807115795,"bookYear":null,"nameKR":"비스트로홈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":38759,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/yeonnamdong_ytop","foodTypes":"기타","latitude":37.5649165980075,"longitude":126.924266240476,"bookYear":null,"nameKR":"연남동와이탑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":38770,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_unas/","foodTypes":"디저트/차/베이커리","latitude":37.5207630026542,"longitude":127.037714863742,"bookYear":null,"nameKR":"카페유나스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":38774,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.525844857378,"longitude":126.869548245323,"bookYear":null,"nameKR":"추억의한잔","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":38773,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/das_brot_dongtan","foodTypes":"디저트/차/베이커리","latitude":37.1992913914145,"longitude":127.088554328576,"bookYear":null,"nameKR":"다스브로트","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38771,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8141205792576,"longitude":127.108506555137,"bookYear":null,"nameKR":"효자동김밥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38769,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.605829337267,"longitude":126.769576313671,"bookYear":null,"nameKR":"모퉁이참숯갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":38764,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/outrigger_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.6057237896218,"longitude":127.032968397328,"bookYear":null,"nameKR":"아우트리거커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":38145,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hoxy__busan/","foodTypes":"디저트/차/베이커리","latitude":35.1854946136657,"longitude":129.048003967752,"bookYear":null,"nameKR":"혹시","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":16143,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":33.513919,"longitude":126.524048,"bookYear":null,"nameKR":"나주닭집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":30912,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/salon_de_stay/","foodTypes":"디저트/차/베이커리","latitude":36.4508710753608,"longitude":127.450723798226,"bookYear":null,"nameKR":"살롱드스테이","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":2734,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.52983506868713,"longitude":127.82099100953256,"bookYear":null,"nameKR":"평양식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":38754,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1648460457261,"longitude":129.113608693617,"bookYear":null,"nameKR":"도원","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38755,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/hayoon9177","foodTypes":"한식(육류)","latitude":36.0270294935193,"longitude":129.338321858235,"bookYear":null,"nameKR":"해시태그92","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":38752,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5464962386945,"longitude":127.073464598067,"bookYear":null,"nameKR":"애크로매틱커피(어린이대공원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38749,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.2137741919994,"longitude":128.980796077241,"bookYear":null,"nameKR":"365부산밀면(대저점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":38746,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafededaeng/","foodTypes":"디저트/차/베이커리","latitude":37.563714370989,"longitude":126.925639722621,"bookYear":null,"nameKR":"카페드댕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":38741,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/siol_don/","foodTypes":"일식","latitude":37.5889634926001,"longitude":127.004003264057,"bookYear":null,"nameKR":"시올돈(성북직영점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":38740,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/adef_coffee_roasters","foodTypes":"디저트/차/베이커리","latitude":37.5337684529184,"longitude":127.065324998001,"bookYear":null,"nameKR":"아드프커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38739,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5346264602161,"longitude":126.903708571568,"bookYear":null,"nameKR":"못골낙곱새(당산점) ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":38738,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5736827223723,"longitude":126.989857534615,"bookYear":null,"nameKR":"익선요록","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":38734,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffee_eternity/","foodTypes":"디저트/차/베이커리","latitude":37.5727668942167,"longitude":127.013421166492,"bookYear":null,"nameKR":"커피이터니티","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":38733,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8392223061542,"longitude":128.70526449548,"bookYear":null,"nameKR":"봉자막창(시지점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":37698,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tori_hana_/","foodTypes":"일식","latitude":37.3492831489257,"longitude":127.108275938415,"bookYear":null,"nameKR":"토리하나","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32824,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/connie._official","foodTypes":"디저트/차/베이커리","latitude":35.5416855148712,"longitude":129.332678842472,"bookYear":null,"nameKR":"코니","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":37424,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.5183014900124,"longitude":129.308494366725,"bookYear":null,"nameKR":"부조화숲","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":34236,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mummum_dasa/","foodTypes":"일식","latitude":35.8634224061519,"longitude":128.462499264732,"bookYear":null,"nameKR":"뭄뭄다사점","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":31752,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1163390608248,"longitude":129.03914165301,"bookYear":null,"nameKR":"브라운핸즈백제","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":36705,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/0p0p5892/","foodTypes":"한식(육류)","latitude":35.1174822721824,"longitude":129.039024718748,"bookYear":null,"nameKR":"골목갈비집 ","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":36915,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1162650613474,"longitude":129.039324501045,"bookYear":null,"nameKR":"영동밀면&돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":36941,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1178388077591,"longitude":129.040516329452,"bookYear":null,"nameKR":"원조부산밀면","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":37057,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1294198499329,"longitude":129.04907961683,"bookYear":null,"nameKR":"두놈닭칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":2776,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.112208,"longitude":129.039296,"bookYear":null,"nameKR":"경회루","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":8202,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.114518,"longitude":129.038543,"bookYear":null,"nameKR":"삼생원","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":11141,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.1385542718279,"longitude":129.060305027454,"bookYear":null,"nameKR":"참숯골","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":11320,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.138844,"longitude":129.061889,"bookYear":null,"nameKR":"조방낙지","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":11322,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.112492,"longitude":129.039678,"bookYear":null,"nameKR":"고궁삼계탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":11325,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.140826,"longitude":129.063016,"bookYear":null,"nameKR":"대수정","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":21735,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.139732,"longitude":129.060577,"bookYear":null,"nameKR":"신선식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":21745,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.117524,"longitude":129.038896,"bookYear":null,"nameKR":"덕미생선구이","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":21868,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/compagnon2012/","foodTypes":"디저트/차/베이커리","latitude":35.1411504333145,"longitude":129.055786216018,"bookYear":null,"nameKR":"꼼빠뇽","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":22177,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.139163,"longitude":129.059947,"bookYear":null,"nameKR":"맥켄양념치킨","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":23409,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.129207,"longitude":129.048001,"bookYear":null,"nameKR":"골목안국수집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":25521,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.129282,"longitude":129.047744,"bookYear":null,"nameKR":"오복통닭","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":25652,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.1286664811517,"longitude":129.046898830487,"bookYear":null,"nameKR":"콩나물비빔밥집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":26520,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.130658,"longitude":129.045676,"bookYear":null,"nameKR":"양지추어탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동구/차이나타운","status":"ACTIVE"},
        {"id":36480,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/haenyeokitchen_bukchon/","foodTypes":"한식(일반한식)","latitude":33.5498682802124,"longitude":126.6934323933,"bookYear":null,"nameKR":"해녀의부엌(북촌점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":27872,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.846836,"longitude":128.866895,"bookYear":null,"nameKR":"보헤미안로스터즈박이추커피공장","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":38143,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.517681361192,"longitude":127.021226273398,"bookYear":null,"nameKR":"영동소곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":32970,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.470761256083,"longitude":126.626912143981,"bookYear":null,"nameKR":"베르데","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":37558,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/osteria.renzo/","foodTypes":"이탈리아식","latitude":37.5696863023563,"longitude":127.181615025787,"bookYear":null,"nameKR":"오스테리아렌조 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":38725,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hattori_vinokaya/","foodTypes":"기타","latitude":37.2523815122422,"longitude":127.079497167074,"bookYear":null,"nameKR":"핫토리비노카야","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33801,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/237omucurry","foodTypes":"한식(일반한식)","latitude":37.525908123664,"longitude":126.904399908259,"bookYear":null,"nameKR":"237오므카레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":38687,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/london.bagel.museum/","foodTypes":"디저트/차/베이커리","latitude":37.5260808495833,"longitude":127.036439070226,"bookYear":null,"nameKR":"런던베이글뮤지엄(도산점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32810,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_manmeathouse","foodTypes":"한식(육류)","latitude":35.3297241123668,"longitude":129.009442649875,"bookYear":null,"nameKR":"그남자고깃집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":38531,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wooded_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.4088694740309,"longitude":126.972099491882,"bookYear":null,"nameKR":"우디드커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":37855,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1581596869009,"longitude":129.115280752701,"bookYear":null,"nameKR":"코닝크리머리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38730,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/libre_wine","foodTypes":"기타","latitude":35.2351683457116,"longitude":128.581576967077,"bookYear":null,"nameKR":"리브레","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":38719,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.143475263112,"longitude":129.110319332304,"bookYear":null,"nameKR":"수영쌈밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37081,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6028527980624,"longitude":127.159682380737,"bookYear":null,"nameKR":"어림","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38716,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/metdubu/","foodTypes":"한식(일반한식)","latitude":35.2748887971056,"longitude":128.889170263155,"bookYear":null,"nameKR":"봉화맷두부","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":38712,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/workerbeekorea/","foodTypes":"디저트/차/베이커리","latitude":35.814462371133,"longitude":127.148008324309,"bookYear":null,"nameKR":"워커비전주","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":37923,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goldenpiece_korea/","foodTypes":"디저트/차/베이커리","latitude":37.5378545835948,"longitude":127.003247877892,"bookYear":null,"nameKR":"골든피스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":37012,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3971879735886,"longitude":126.96738686836,"bookYear":null,"nameKR":"위스크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":37992,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/inthemass_/","foodTypes":"디저트/차/베이커리","latitude":35.866797492029,"longitude":128.60164437836,"bookYear":null,"nameKR":"인더매스(삼덕점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":33461,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/byway_bakers","foodTypes":"디저트/차/베이커리","latitude":37.7488107594075,"longitude":127.069650694541,"bookYear":null,"nameKR":"바이웨이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":38152,"bookStatus":"보류","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.5655131182464,"longitude":126.857055309981,"bookYear":null,"nameKR":"원조할매곰탕","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":38155,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4923566237229,"longitude":126.722116856672,"bookYear":null,"nameKR":"다해야","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":37261,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5663848991542,"longitude":127.017366274178,"bookYear":null,"nameKR":"발라닭(신당점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":37078,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5762076645137,"longitude":126.973148168228,"bookYear":null,"nameKR":"사직로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":36233,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.2505304248344,"longitude":127.07376227758,"bookYear":null,"nameKR":"포앤드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37827,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jakdo_ng/","foodTypes":"한식(면류)","latitude":37.5041640198029,"longitude":126.805104079171,"bookYear":null,"nameKR":"작동홍두깨칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":38131,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5009946247186,"longitude":127.025104306207,"bookYear":null,"nameKR":"더막창스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":38268,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.7982606505267,"longitude":128.79852735246,"bookYear":null,"nameKR":"찰스아노 ","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":34204,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5372755417992,"longitude":126.728882457258,"bookYear":null,"nameKR":"계산동수제돈까스","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":37994,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.9324668301753,"longitude":127.494510485325,"bookYear":null,"nameKR":"밥팜","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":36635,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/linchpin.kr","foodTypes":"디저트/차/베이커리","latitude":37.3824430719809,"longitude":126.66063408484,"bookYear":null,"nameKR":"린치핀","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":33966,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5461151893313,"longitude":127.057767115235,"bookYear":null,"nameKR":"성수칼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37835,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5124933826657,"longitude":127.111506834731,"bookYear":null,"nameKR":"우탄(방이본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34471,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.841609859529,"longitude":127.128797080658,"bookYear":null,"nameKR":"연다라순대","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38696,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/q.lounge_official/","foodTypes":"디저트/차/베이커리","latitude":35.153006221653,"longitude":129.059606320437,"bookYear":null,"nameKR":"큐라운지베이커리&카페","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":33732,"bookStatus":"커버","websiteInstagram":"http://www.instagram.com/the_dessertlab","foodTypes":"디저트/차/베이커리","latitude":37.5028684818551,"longitude":126.943452576133,"bookYear":null,"nameKR":"더디저트랩","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":37353,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":33.4836872265338,"longitude":126.378628442602,"bookYear":null,"nameKR":"로노브릿지","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":32989,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_wichita","foodTypes":"디저트/차/베이커리","latitude":37.3978588674954,"longitude":126.918464643284,"bookYear":null,"nameKR":"위치타","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":36113,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hansangminbk/","foodTypes":"디저트/차/베이커리","latitude":37.5939192277609,"longitude":126.712595876122,"bookYear":null,"nameKR":"한상민과자점(검단신도시점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":33643,"bookStatus":"커버","websiteInstagram":"","foodTypes":"기타","latitude":37.5396664092899,"longitude":127.050836598952,"bookYear":null,"nameKR":"리브나","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34678,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.5535189236161,"longitude":129.319439559442,"bookYear":null,"nameKR":"초원돼지갈비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":35475,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/factory_noodle/","foodTypes":"한식(면류)","latitude":36.1543758093815,"longitude":128.431162684473,"bookYear":null,"nameKR":"칼국수공장","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":36807,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/so___oul?igsh=MWF6c3FxM3NrNWUzaw==","foodTypes":"디저트/차/베이커리","latitude":37.5804589730862,"longitude":126.97147451202,"bookYear":null,"nameKR":"소울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":38710,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.8248201319003,"longitude":128.626459334653,"bookYear":null,"nameKR":"하망에스프레소바","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":38709,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5650782854459,"longitude":126.923781972137,"bookYear":null,"nameKR":"하이촨촨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":38706,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gombarogomtang/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4909880663275,"longitude":127.034017906367,"bookYear":null,"nameKR":"곰바로곰탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":38702,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kkot_dabang/","foodTypes":"디저트/차/베이커리","latitude":37.5630457652576,"longitude":126.967176393341,"bookYear":null,"nameKR":"꽃다방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":36521,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/comensip_coffee","foodTypes":"디저트/차/베이커리","latitude":37.5098655877274,"longitude":127.025821984185,"bookYear":null,"nameKR":"컴앤쉽","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":36773,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wootang.official","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5665764807804,"longitude":126.916868375013,"bookYear":null,"nameKR":"우탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":37822,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5621885878429,"longitude":126.977735323414,"bookYear":null,"nameKR":"누리옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":37801,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/india_island_","foodTypes":"아시아식","latitude":37.6361836574257,"longitude":126.373361175447,"bookYear":null,"nameKR":"인디아일랜드","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":38700,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6252126989301,"longitude":127.071322409288,"bookYear":null,"nameKR":"스마일닭발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":38699,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/crepeboyseoul","foodTypes":"프랑스식","latitude":37.5803730268393,"longitude":126.970772862576,"bookYear":null,"nameKR":"크레페보이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":38698,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/__leno______/","foodTypes":"한식(육류)","latitude":37.5162840583437,"longitude":128.212528945547,"bookYear":null,"nameKR":"화수목","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":31320,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/schedule_official/","foodTypes":"기타","latitude":35.1604439324117,"longitude":129.162247021768,"bookYear":null,"nameKR":"스케줄해운대","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":38695,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kino.iae","foodTypes":"바/주점","latitude":37.563518646146,"longitude":127.002161504784,"bookYear":null,"nameKR":"키노이에","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":38694,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soltaphouse/","foodTypes":"미국식","latitude":35.1485927053623,"longitude":129.114506185438,"bookYear":null,"nameKR":"솔탭하우스(광안본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38693,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/natsubi_goodday/","foodTypes":"바/주점","latitude":37.2971334286654,"longitude":126.971575986186,"bookYear":null,"nameKR":"나츠비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36725,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/homeboy_seoul/","foodTypes":"중식","latitude":37.5762076645137,"longitude":126.973148168228,"bookYear":null,"nameKR":"홈보이서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":38692,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/croiffle_village","foodTypes":"디저트/차/베이커리","latitude":37.4790129482572,"longitude":126.983813714259,"bookYear":null,"nameKR":"쎄봉크루아상","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":35280,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistroyuvino/","foodTypes":"이탈리아식","latitude":37.3149551732586,"longitude":127.077564891186,"bookYear":null,"nameKR":"유비노","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":38689,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1564419515859,"longitude":129.061383202597,"bookYear":null,"nameKR":"발빠닭(서면본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":38597,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":38.3170454252267,"longitude":128.531081651739,"bookYear":null,"nameKR":"다연뷔페레스토랑(오션투유리조트)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":38596,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":38.3170454252267,"longitude":128.531081651739,"bookYear":null,"nameKR":"오션숯불갈비(오션투유리조트)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":38546,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1551342050388,"longitude":129.124005331974,"bookYear":null,"nameKR":"산적화로구이(광안점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":38584,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.3158893066318,"longitude":127.073564931097,"bookYear":null,"nameKR":"코코진커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":38532,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wooded_coffee","foodTypes":"디저트/차/베이커리","latitude":37.2875559052836,"longitude":127.014032309445,"bookYear":null,"nameKR":"우디드커피(행궁점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34288,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistro_mohing/","foodTypes":"이탈리아식","latitude":37.4776382069726,"longitude":126.958962173384,"bookYear":null,"nameKR":"모힝(낙성대점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":33641,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8392043117213,"longitude":128.536346837982,"bookYear":null,"nameKR":"대통양대창","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":38530,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/saeatzip/","foodTypes":"바/주점","latitude":35.1555099785733,"longitude":129.067398951893,"bookYear":null,"nameKR":"사잇집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38529,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sos_coffee_/","foodTypes":"기타","latitude":37.5037964801515,"longitude":127.105677521154,"bookYear":null,"nameKR":"SOS커피브런치(석촌역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":38423,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.567481656175,"longitude":126.984388166805,"bookYear":null,"nameKR":"카페아이엠티(청계천을지로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":38392,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/longdrivers.kr","foodTypes":"기타","latitude":35.1579517850045,"longitude":129.066199076076,"bookYear":null,"nameKR":"롱드라이버스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":38329,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":35.1671631748574,"longitude":129.157661555388,"bookYear":null,"nameKR":"케빈스보일링크랩","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":38301,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mbssikdang_store/","foodTypes":"한식(가금류)","latitude":37.3321898741988,"longitude":127.930025278468,"bookYear":null,"nameKR":"민병선식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":38263,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_rabbitcastle","foodTypes":"이탈리아식","latitude":37.5094576887604,"longitude":127.085131818176,"bookYear":null,"nameKR":"래빗캐슬","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":38249,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5418974755732,"longitude":126.952209319163,"bookYear":null,"nameKR":"자인정육식당 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":38202,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8903426948095,"longitude":128.590899091007,"bookYear":null,"nameKR":"김선돼지국밥침산본관","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":33347,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.8431799185967,"longitude":127.064497894253,"bookYear":null,"nameKR":"다이닝스푼(혁신점)","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38161,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.2840677297347,"longitude":129.313082919409,"bookYear":null,"nameKR":"장호우리집갈비막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":38157,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/choiko_jip","foodTypes":"한식(육류)","latitude":36.8205854279534,"longitude":127.152409871569,"bookYear":null,"nameKR":"최고짚","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38156,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/darak_2f/","foodTypes":"기타","latitude":36.6167540933943,"longitude":127.516515784537,"bookYear":null,"nameKR":"다락","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":38151,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jyg2020/","foodTypes":"한식(민물어패류)","latitude":37.4432808874043,"longitude":126.709570829482,"bookYear":null,"nameKR":"내린천추어탕","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":36485,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.2372202984303,"longitude":126.954804106465,"bookYear":null,"nameKR":"베이커리설","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":38149,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pinktokyo_izakaya","foodTypes":"바/주점","latitude":37.5326231015567,"longitude":126.651181684661,"bookYear":null,"nameKR":"핑크도쿄","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":38146,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/horumonchichi/","foodTypes":"일식","latitude":37.2648538555268,"longitude":127.031664904813,"bookYear":null,"nameKR":"호르몬치치(수원인계본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":38144,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.9026278584046,"longitude":127.054393213324,"bookYear":null,"nameKR":"생연식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":37954,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8370477506336,"longitude":129.20921115633,"bookYear":null,"nameKR":"외가","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":37984,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4069926675798,"longitude":126.972563846266,"bookYear":null,"nameKR":"유니제과","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":32794,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.0988479365312,"longitude":129.029342915273,"bookYear":null,"nameKR":"갓포신","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":34361,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.0984667361187,"longitude":129.032043057012,"bookYear":null,"nameKR":"홍유단(남포본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":5513,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.098278255944784,"longitude":129.0326099887927,"bookYear":null,"nameKR":"서울삼계탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":7131,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.096938,"longitude":129.027025,"bookYear":null,"nameKR":"백화양곱창(2호점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":7423,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.098394,"longitude":129.032787,"bookYear":null,"nameKR":"미나미(남포점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":8209,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.09877275439449,"longitude":129.03042046250266,"bookYear":null,"nameKR":"남포삼계탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":8213,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.097387649815,"longitude":129.031054972076,"bookYear":null,"nameKR":"우리보리밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":22825,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.0966137768508,"longitude":129.030583690953,"bookYear":null,"nameKR":"상미상회","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":23585,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.097342,"longitude":129.030575,"bookYear":null,"nameKR":"고래1번지","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":25734,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.096639,"longitude":129.030593,"bookYear":null,"nameKR":"친구네집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":28110,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.0970547948845,"longitude":129.02783968921,"bookYear":null,"nameKR":"남해양곱창","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":2274,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"바다집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":2950,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.102028,"longitude":129.034209,"bookYear":null,"nameKR":"멸치쌈밥집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":3111,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.099573,"longitude":129.026493,"bookYear":null,"nameKR":"한양족발","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":5507,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.100621980423526,"longitude":129.03600605525398,"bookYear":null,"nameKR":"중앙식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":8206,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.102294,"longitude":129.026419,"bookYear":null,"nameKR":"깡통골목원조비빔당면","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":11318,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.100267,"longitude":129.029836,"bookYear":null,"nameKR":"큰집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":15398,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":0,"longitude":0,"bookYear":null,"nameKR":"옵스(롯데광복점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":19602,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":0,"longitude":0,"bookYear":null,"nameKR":"모티커리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":20348,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"언양불고기","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":21729,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.1007824609015,"longitude":129.025277299063,"bookYear":null,"nameKR":"소문난김밥집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":21747,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.110144,"longitude":129.038371,"bookYear":null,"nameKR":"그집곱도리탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":22189,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.101654,"longitude":129.031367,"bookYear":null,"nameKR":"올레","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":22195,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.1008155120753,"longitude":129.026335295866,"bookYear":null,"nameKR":"새부평한우갈비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":22673,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.099072288132,"longitude":129.026145977308,"bookYear":null,"nameKR":"멕코이치킨(부평점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":22683,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.104886,"longitude":129.035652,"bookYear":null,"nameKR":"커피산책","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":23611,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.101588,"longitude":129.037012,"bookYear":null,"nameKR":"원미돌솥밥숯불갈비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":25665,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.10218,"longitude":129.025815,"bookYear":null,"nameKR":"부평통닭","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":25972,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.100403,"longitude":129.02605,"bookYear":null,"nameKR":"이화양곱창","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":29446,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.1017486801928,"longitude":129.0358849493909,"bookYear":null,"nameKR":"명송초밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":30948,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.0998431113065,"longitude":129.030515435215,"bookYear":null,"nameKR":"종각집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":33041,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.10611139201,"longitude":129.037346476446,"bookYear":null,"nameKR":"중앙곰탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":33862,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1022235084999,"longitude":129.035160638079,"bookYear":null,"nameKR":"마구로테이블","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":35516,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1001681461177,"longitude":129.03074784905,"bookYear":null,"nameKR":"광복경양식남포본점","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":35580,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.1061477906186,"longitude":129.035282238906,"bookYear":null,"nameKR":"리콰이얼먼트커피브루얼스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":35970,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.1040432546171,"longitude":129.035272998352,"bookYear":null,"nameKR":"드메르(중앙점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":36628,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwahyundong","foodTypes":"바/주점","latitude":35.0990694774934,"longitude":129.025505429485,"bookYear":null,"nameKR":"화현동","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":21376,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.101327,"longitude":129.031372,"bookYear":null,"nameKR":"파머스버거","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":22178,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":35.09934,"longitude":129.031765,"bookYear":null,"nameKR":"꼬모도","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"중구/광복동/남포동","status":"ACTIVE"},
        {"id":14052,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.21817035213256,"longitude":126.84692006341677,"bookYear":null,"nameKR":"고려조삼계탕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":21835,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.191624,"longitude":126.825731,"bookYear":null,"nameKR":"디아망과자점","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":22450,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.2210193130946,"longitude":126.840820173537,"bookYear":null,"nameKR":"남부아구찜","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":23813,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.210944,"longitude":126.8378,"bookYear":null,"nameKR":"청석골 칡냉면","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":23814,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/banwol7942/","foodTypes":"한식(민물어패류)","latitude":35.1949899861778,"longitude":126.850133937441,"bookYear":null,"nameKR":"반월추어탕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":24924,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.138274,"longitude":126.793154,"bookYear":null,"nameKR":"장원정","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":25714,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.127293,"longitude":126.789566,"bookYear":null,"nameKR":"옛날에금잔디보리밥집","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":30447,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.13950334567472,"longitude":126.7932955880572,"bookYear":null,"nameKR":"이조송정떡갈비","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":33702,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/milgason__/","foodTypes":"한식(면류)","latitude":35.191499271214,"longitude":126.811054478762,"bookYear":null,"nameKR":"밀가손칼국수","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":34219,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nanabangkok_/","foodTypes":"아시아식","latitude":35.1916155062158,"longitude":126.827708505025,"bookYear":null,"nameKR":"나나방콕(수완점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":34547,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1720922509246,"longitude":126.806517009079,"bookYear":null,"nameKR":"하당먹거리","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":35170,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/repas_suwan/","foodTypes":"디저트/차/베이커리","latitude":35.192377641056,"longitude":126.82434842355,"bookYear":null,"nameKR":"레파스(수완점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":35183,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1920446728007,"longitude":126.830477748479,"bookYear":null,"nameKR":"파쿠야(수완점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":35653,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.1960412506558,"longitude":126.820273448948,"bookYear":null,"nameKR":"굽는당","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":36263,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2163759055157,"longitude":126.849476939811,"bookYear":null,"nameKR":"아스라이","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":37385,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.2186364053729,"longitude":126.845685729633,"bookYear":null,"nameKR":"청담카츠","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":37499,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/buukgan/","foodTypes":"기타","latitude":35.194037597196,"longitude":126.832248462584,"bookYear":null,"nameKR":"부엌간(수완점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":37715,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1936150067653,"longitude":126.814487380135,"bookYear":null,"nameKR":"양육점","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":37717,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yangyukjum/","foodTypes":"일식","latitude":35.2159999845315,"longitude":126.848923858338,"bookYear":null,"nameKR":"양육점(첨단점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"광산구/수완지구","status":"ACTIVE"},
        {"id":37170,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soba_n8253/","foodTypes":"일식","latitude":36.3548565038645,"longitude":127.363721186753,"bookYear":null,"nameKR":"소반","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":37902,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gomtang_gougo/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1269856823594,"longitude":126.913490116072,"bookYear":null,"nameKR":"곰탕고우고","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":36917,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gyemidak","foodTypes":"한식(면류)","latitude":36.6077608174731,"longitude":127.503863797581,"bookYear":null,"nameKR":"계미닭전문점","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":32186,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5647960477713,"longitude":126.976227682258,"bookYear":null,"nameKR":"카페돌담콩","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":34183,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8401983830377,"longitude":127.129043238799,"bookYear":null,"nameKR":"나무그늘통삼국","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":37721,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daiki_white_whale?igsh=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr","foodTypes":"일식","latitude":37.3124514130444,"longitude":126.838322160039,"bookYear":null,"nameKR":"다이키흰고래","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":37742,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.3102152340658,"longitude":126.827417276016,"bookYear":null,"nameKR":"카산도(고잔점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":33714,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.414082050781,"longitude":126.261743276402,"bookYear":null,"nameKR":"무호소반","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":37630,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4841921572999,"longitude":126.977405497956,"bookYear":null,"nameKR":"금목","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":37327,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1456620032545,"longitude":126.91841855377,"bookYear":null,"nameKR":"대금육회","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":36160,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/kamong.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.4912083268539,"longitude":127.008939478194,"bookYear":null,"nameKR":"카몽","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":35444,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ilpyeon__offical/","foodTypes":"한식(육류)","latitude":37.5563118078566,"longitude":126.924403968774,"bookYear":null,"nameKR":"일편등심(홍대본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":37703,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5233052345274,"longitude":127.132528261747,"bookYear":null,"nameKR":"백년화편","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":5242,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.16202403680393,"longitude":129.16324364910957,"bookYear":null,"nameKR":"해운대원조할매국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":2270,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.076837,"longitude":129.017474,"bookYear":null,"nameKR":"모아횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":8219,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.120979,"longitude":129.112807,"bookYear":null,"nameKR":"황산밀냉면","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":37990,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5811211914849,"longitude":127.087798097854,"bookYear":null,"nameKR":"미나리산장(사가정역점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":37933,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5665213872496,"longitude":127.015517232234,"bookYear":null,"nameKR":"크런치고고","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":38140,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oom_pook/","foodTypes":"바/주점","latitude":37.565169324397,"longitude":126.994109919477,"bookYear":null,"nameKR":"움푹","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":38127,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5617602798822,"longitude":126.985404271845,"bookYear":null,"nameKR":"무쇠김치삼겹(명동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":38126,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1548611680448,"longitude":129.060692744357,"bookYear":null,"nameKR":"무쇠김치삼겹(서면본점) ","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":38036,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5387928458995,"longitude":127.084800821084,"bookYear":null,"nameKR":"로바타니카이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38035,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tokki_dining/","foodTypes":"바/주점","latitude":37.5484482104764,"longitude":126.917857185128,"bookYear":null,"nameKR":"토끼다이닝","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":38019,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bonemeat_ilsan/","foodTypes":"한식(육류)","latitude":35.4948860635334,"longitude":129.43020139925,"bookYear":null,"nameKR":"본고깃집(일산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":38028,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/themalthouse_ss/","foodTypes":"기타","latitude":36.844276942264,"longitude":127.135465286371,"bookYear":null,"nameKR":"더몰트하우스(천안성성점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":38018,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.173247929974,"longitude":129.174014890989,"bookYear":null,"nameKR":"유유카츠(해운대본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":31368,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/itsumo_chodang","foodTypes":"일식","latitude":37.7848170244325,"longitude":128.916073583916,"bookYear":null,"nameKR":"이츠모라멘(강릉점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":37886,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3691438762557,"longitude":126.726058404858,"bookYear":null,"nameKR":"함돈(배곧직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":16776,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5579288349811,"longitude":126.934846769743,"bookYear":null,"nameKR":"소문난집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":37837,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_pigwing","foodTypes":"한식(육류)","latitude":33.5126847122847,"longitude":126.531132893384,"bookYear":null,"nameKR":"제주돈날개","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":31545,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4772970354565,"longitude":126.977563590473,"bookYear":null,"nameKR":"킷쵸","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":37460,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/somemore.deli/","foodTypes":"기타","latitude":37.5664528526799,"longitude":126.919848464933,"bookYear":null,"nameKR":"썸모어델리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37762,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5191170185113,"longitude":127.019672771719,"bookYear":null,"nameKR":"아로보서울","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37287,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"유럽식","latitude":37.6068235645264,"longitude":126.968112552501,"bookYear":null,"nameKR":"미트미츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":37415,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.3793159725333,"longitude":126.657657591796,"bookYear":null,"nameKR":"테라코타","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":37772,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.2293340051669,"longitude":129.087555735538,"bookYear":null,"nameKR":"브로넛","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":37828,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1531663017821,"longitude":129.068508856657,"bookYear":null,"nameKR":"페난","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37744,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.8089281576989,"longitude":127.153132885542,"bookYear":null,"nameKR":"구프오프","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":36570,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.3213932061118,"longitude":127.342886645485,"bookYear":null,"nameKR":"코너델리","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":37121,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5696931242599,"longitude":127.184709447519,"bookYear":null,"nameKR":"남종베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":37386,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8159847903713,"longitude":128.533655049727,"bookYear":null,"nameKR":"네모구이92","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":36774,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.516094431943,"longitude":127.111497713953,"bookYear":null,"nameKR":"미유키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":37649,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.3272415951579,"longitude":127.429615366416,"bookYear":null,"nameKR":"꾸미신닭발","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":37637,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":38.2104243825615,"longitude":128.588086802763,"bookYear":null,"nameKR":"딜리조이쌀국수 ","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":36701,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5416113618865,"longitude":127.070842363061,"bookYear":null,"nameKR":"서울낙업","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":34486,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.2059799762239,"longitude":128.57557049158,"bookYear":null,"nameKR":"창동복희집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":36832,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.1569983580107,"longitude":129.120285318103,"bookYear":null,"nameKR":"마노사포레(광안점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37273,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5325217110531,"longitude":127.077443439709,"bookYear":null,"nameKR":"뚝섬집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":37887,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.2071080616634,"longitude":129.082162483988,"bookYear":null,"nameKR":"오노고로(동래본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":37866,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.2624124408982,"longitude":128.344802557118,"bookYear":null,"nameKR":"아라분식","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"함안군","status":"ACTIVE"},
        {"id":31655,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moggumeong","foodTypes":"한식(육류)","latitude":34.8668590931857,"longitude":128.688876189171,"bookYear":null,"nameKR":"목구멍","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":37873,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafetrit/","foodTypes":"디저트/차/베이커리","latitude":37.5269056148241,"longitude":126.966566172294,"bookYear":null,"nameKR":"트릿","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":34945,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/heehee_official/","foodTypes":"일식","latitude":37.574702691756,"longitude":127.002748669723,"bookYear":null,"nameKR":"희희","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":37609,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/mondo.seoul_/","foodTypes":"바/주점","latitude":37.5135939293426,"longitude":127.016300866311,"bookYear":null,"nameKR":"몽도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37233,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/donar_bread_deli/","foodTypes":"기타","latitude":37.5323529435483,"longitude":126.992100673191,"bookYear":null,"nameKR":"도나르","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":36415,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5534034378215,"longitude":126.974303672318,"bookYear":null,"nameKR":"조선보울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":37924,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/song.gye.ok/","foodTypes":"한식(가금류)","latitude":37.3980298796617,"longitude":127.112030343708,"bookYear":null,"nameKR":"송계옥(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":37922,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/song.gye.ok/","foodTypes":"한식(가금류)","latitude":37.5128932525047,"longitude":127.110350889927,"bookYear":null,"nameKR":"송계옥(방이점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35286,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/godny_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.5678656599047,"longitude":126.827453357716,"bookYear":null,"nameKR":"고드니","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":30515,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sunnybreadkr/, https://smartstore.naver.com/sunnysweetshop","foodTypes":"디저트/차/베이커리","latitude":37.5468154178295,"longitude":127.041035434635,"bookYear":null,"nameKR":"써니브레드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":30735,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4779876310978,"longitude":126.957219669606,"bookYear":null,"nameKR":"라미스콘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":32267,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_pepper_/","foodTypes":"디저트/차/베이커리","latitude":37.5096256769585,"longitude":127.110151992824,"bookYear":null,"nameKR":"카페페퍼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36522,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5269056148241,"longitude":126.966566172294,"bookYear":null,"nameKR":"투리틀베이커스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31288,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5437080982902,"longitude":126.987265580732,"bookYear":null,"nameKR":"베제투스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":36762,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.5542653975533,"longitude":126.746336284699,"bookYear":null,"nameKR":"김녕에사는김영훈","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":37310,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_solea/","foodTypes":"디저트/차/베이커리","latitude":35.3231764336301,"longitude":128.994880976878,"bookYear":null,"nameKR":"카페솔레아","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":37904,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.7360908926602,"longitude":127.041800965445,"bookYear":null,"nameKR":"소문","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":33605,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.623600722079,"longitude":127.078318416392,"bookYear":null,"nameKR":"대디스파스타","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":37943,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.4394835506069,"longitude":126.784894398131,"bookYear":null,"nameKR":"남원참추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":37860,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.0183532720029,"longitude":127.864009490247,"bookYear":null,"nameKR":"남한강막국수","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":37297,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.2444909969815,"longitude":126.9738967886,"bookYear":null,"nameKR":"미담칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37962,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2537597664857,"longitude":127.030979654683,"bookYear":null,"nameKR":"소담화로","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35412,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.2419483705548,"longitude":129.213710462347,"bookYear":null,"nameKR":"레스토랑샵페스티노","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":36727,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4985392276194,"longitude":126.917939026858,"bookYear":null,"nameKR":"이넬","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":37988,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.265360305753,"longitude":129.236199578208,"bookYear":null,"nameKR":"기장가마솥추어탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":37878,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5007401488102,"longitude":127.140321254258,"bookYear":null,"nameKR":"카페디콤마","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":37747,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5575670810631,"longitude":126.907109326435,"bookYear":null,"nameKR":"단고당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":35740,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4937834824619,"longitude":126.722282094747,"bookYear":null,"nameKR":"명동칼국수","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":36455,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/changsim_1963/","foodTypes":"한식(육류)","latitude":37.2077244538009,"longitude":127.065497337074,"bookYear":null,"nameKR":"창심관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":7327,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.51191,"longitude":127.054338,"bookYear":null,"nameKR":"봉산집(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":36085,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2220768753205,"longitude":126.973599981357,"bookYear":null,"nameKR":"담양초벌소갈비전문점","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":36268,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/honakga/","foodTypes":"한식(육류)","latitude":37.5093717645665,"longitude":127.108898883074,"bookYear":null,"nameKR":"호낙가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":38016,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5196427263101,"longitude":126.871361484603,"bookYear":null,"nameKR":"온정손만두(목동점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":38014,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/last_chunsun/","foodTypes":"바/주점","latitude":37.2311131900405,"longitude":127.187181574795,"bookYear":null,"nameKR":"라스트춘선","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":38012,"bookStatus":"비활성","websiteInstagram":"Http://instagram.com/si_daek","foodTypes":"한식(육류)","latitude":35.2421148468694,"longitude":128.649410373337,"bookYear":null,"nameKR":"시댁","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":38010,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aube.brunch_/","foodTypes":"기타","latitude":35.8195559925562,"longitude":127.117333708916,"bookYear":null,"nameKR":"오브","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":38009,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5343407191274,"longitude":127.067021261006,"bookYear":null,"nameKR":"블랙스미스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":38008,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2286337965275,"longitude":129.092304416941,"bookYear":null,"nameKR":"청화백돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":35594,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oatning.official","foodTypes":"디저트/차/베이커리","latitude":37.3937818483729,"longitude":126.633289319826,"bookYear":null,"nameKR":"오트닝","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":38007,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6651139563171,"longitude":127.292130493891,"bookYear":null,"nameKR":"일호갈비탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":38004,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7409127264243,"longitude":127.061139492459,"bookYear":null,"nameKR":"먹고집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":38003,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hakko_bakery","foodTypes":"디저트/차/베이커리","latitude":37.5075879986399,"longitude":127.04065519567,"bookYear":null,"nameKR":"핫코베이커리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":38001,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hidden_roastery/","foodTypes":"디저트/차/베이커리","latitude":34.7600984981536,"longitude":127.666883316639,"bookYear":null,"nameKR":"히든로스터리&커피바","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":38000,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_kuroby/","foodTypes":"기타","latitude":34.9364565489904,"longitude":127.709730819926,"bookYear":null,"nameKR":"쿠로비","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":37998,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2059904430563,"longitude":128.576850032947,"bookYear":null,"nameKR":"창동초가집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":37997,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ramen_bold/","foodTypes":"일식","latitude":37.5756005919096,"longitude":126.99112787453,"bookYear":null,"nameKR":"라멘보루도","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":37996,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bomb.chungdam/","foodTypes":"한식(일반한식)","latitude":37.5263600018698,"longitude":127.046853219261,"bookYear":null,"nameKR":"보므청담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37993,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gongjunenoodle/","foodTypes":"한식(면류)","latitude":33.4913445688525,"longitude":126.59371518559,"bookYear":null,"nameKR":"공주네국수","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":37948,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5646277240836,"longitude":127.190162857833,"bookYear":null,"nameKR":"미나리산장(미사본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":37749,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ordinary.mood_daegu/","foodTypes":"이탈리아식","latitude":35.8717515769736,"longitude":128.59530270988,"bookYear":null,"nameKR":"오디너리무드","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":37982,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1517508521743,"longitude":129.020608015723,"bookYear":null,"nameKR":"백양숯불가든 ","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37980,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lab41_official/","foodTypes":"이탈리아식","latitude":37.5564535300615,"longitude":127.079445590694,"bookYear":null,"nameKR":"LAB41","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":37971,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.8247172439501,"longitude":128.626537850703,"bookYear":null,"nameKR":"카페하망주택","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":7511,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.290294,"longitude":127.011096,"bookYear":null,"nameKR":"유치회관(북문점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":27882,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/glomuro_coffee/","foodTypes":"디저트/차/베이커리","latitude":33.487231,"longitude":126.535371,"bookYear":null,"nameKR":"그러므로","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":5083,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chungchun__club","foodTypes":"한식(육류)","latitude":37.663837,"longitude":126.766269,"bookYear":null,"nameKR":"청춘구락부(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":5954,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.395914,"longitude":128.154733,"bookYear":null,"nameKR":"용궁단골식당(상주점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"상주시","status":"ACTIVE"},
        {"id":37968,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ta_ble_bakery/","foodTypes":"디저트/차/베이커리","latitude":35.2072015739543,"longitude":129.011278223377,"bookYear":null,"nameKR":"테이-블","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":37967,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/e__exotic","foodTypes":"기타","latitude":37.6470745187423,"longitude":126.670480875521,"bookYear":null,"nameKR":"이그조틱(장기점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":37965,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.6377978805026,"longitude":126.83568669389,"bookYear":null,"nameKR":"이그조틱(화정점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":37956,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/midme_official/","foodTypes":"디저트/차/베이커리","latitude":37.5792708106796,"longitude":126.971758668084,"bookYear":null,"nameKR":"미드메","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":37949,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5806561786855,"longitude":126.889839335259,"bookYear":null,"nameKR":"빅베어치즈케이크카페","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":34083,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4143340943985,"longitude":126.676991140837,"bookYear":null,"nameKR":"탐라담(연수점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":34610,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5406951340398,"longitude":126.942041881097,"bookYear":null,"nameKR":"청기와타운(마포점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":34641,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5436980583451,"longitude":126.972689331461,"bookYear":null,"nameKR":"청기와타운(남영점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36863,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ildu.sikdang/","foodTypes":"한식(육류)","latitude":37.509507238119,"longitude":127.036488016457,"bookYear":null,"nameKR":"일두한우정육식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":34212,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8226258044648,"longitude":127.145722150535,"bookYear":null,"nameKR":"브이엠에스커피","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":37892,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1646942445141,"longitude":128.038722268298,"bookYear":null,"nameKR":"커피플라워","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":37931,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/door.on_","foodTypes":"디저트/차/베이커리","latitude":37.2071365421238,"longitude":127.061762444569,"bookYear":null,"nameKR":"도어온","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":31701,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.8252090093432,"longitude":128.624904219749,"bookYear":null,"nameKR":"고릴라커피","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":37928,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/311.7330?igsh=MTJyYTR3ZzRzdjlqeA==","foodTypes":"한식(면류)","latitude":35.9460905970595,"longitude":128.553664855831,"bookYear":null,"nameKR":"허벌냉면","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":37961,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5429076278416,"longitude":127.056094142075,"bookYear":null,"nameKR":"골든센트리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37957,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/heungcoffeeroasters/","foodTypes":"디저트/차/베이커리","latitude":36.3919601243614,"longitude":127.350141998975,"bookYear":null,"nameKR":"흥커피로스터스","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":37953,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.838219687864,"longitude":129.209370810033,"bookYear":null,"nameKR":"테라도스(경주황남점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":37921,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8457222938615,"longitude":127.074441949345,"bookYear":null,"nameKR":"원식당부제일만만이룰성","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":37841,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5255995843403,"longitude":127.038826747185,"bookYear":null,"nameKR":"도산정육블랙","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37800,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/blumetz.official/","latitude":37.4995230812063,"longitude":127.063736788721,"bookYear":null,"nameKR":"블루메쯔(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":37799,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/blumetz.official/","foodTypes":"유럽식","latitude":37.5176638307111,"longitude":126.958036465507,"bookYear":null,"nameKR":"블루메쯔(노들섬점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":37748,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1560144578186,"longitude":129.122478226524,"bookYear":null,"nameKR":"페이센동(광안점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37628,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafeunjoongdong","foodTypes":"디저트/차/베이커리","latitude":37.3905609356605,"longitude":127.056371489385,"bookYear":null,"nameKR":"카페운중동","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37627,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dong.nyukk","foodTypes":"일식","latitude":35.8642146088446,"longitude":128.607542078079,"bookYear":null,"nameKR":"동녘","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":37670,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/monthlydeli","foodTypes":"기타","latitude":35.1552231186574,"longitude":129.067120337925,"bookYear":null,"nameKR":"먼슬리델리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37951,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yoongane153","foodTypes":"한식(육류)","latitude":36.474445837168,"longitude":127.275869284753,"bookYear":null,"nameKR":"윤가네들깨수제비&보쌈","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":34401,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.9192584622619,"longitude":127.05776745698,"bookYear":null,"nameKR":"특별한식탁","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":37232,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4476289713798,"longitude":126.647094601108,"bookYear":null,"nameKR":"순석닭강정","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":37452,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2272316623325,"longitude":128.881179699318,"bookYear":null,"nameKR":"명성고깃집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":37516,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.2037088564488,"longitude":128.814873689381,"bookYear":null,"nameKR":"덴하우스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":37950,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5336967726087,"longitude":126.972674084438,"bookYear":null,"nameKR":"아나바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37947,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/avekcheri/","foodTypes":"디저트/차/베이커리","latitude":37.5369189390512,"longitude":127.000258537128,"bookYear":null,"nameKR":"아벡쉐리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":37946,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.7874959244871,"longitude":126.697651969533,"bookYear":null,"nameKR":"흑사당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":37945,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6567152545134,"longitude":126.773131388502,"bookYear":null,"nameKR":"대종칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":37939,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/iksun.farm/","foodTypes":"한식(육류)","latitude":37.5731868289748,"longitude":126.991154310131,"bookYear":null,"nameKR":"익선동목장 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":37937,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/otb_coffee/","foodTypes":"디저트/차/베이커리","latitude":36.4785761962562,"longitude":127.289753419137,"bookYear":null,"nameKR":"오티비커피","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":37224,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dian_chungdam/","foodTypes":"한식(민물어패류)","latitude":37.4220718546559,"longitude":127.107387518129,"bookYear":null,"nameKR":"청담추어정(별관)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":37917,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2455421756521,"longitude":127.191734033139,"bookYear":null,"nameKR":"경자국밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":37929,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ramen_ya.korea/","foodTypes":"일식","latitude":35.1568727665082,"longitude":129.061167923363,"bookYear":null,"nameKR":"라멘야","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37883,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/comodo_table","foodTypes":"기타","latitude":35.1925217759427,"longitude":129.103053650428,"bookYear":null,"nameKR":"코모도테이블","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":37920,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ginger.bear_pie/","foodTypes":"디저트/차/베이커리","latitude":37.5090954536693,"longitude":127.105392392777,"bookYear":null,"nameKR":"진저베어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":37916,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/manse.sangam/","foodTypes":"한식(육류)","latitude":37.5785297541285,"longitude":126.894284759249,"bookYear":null,"nameKR":"만세회관(상암점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":37915,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mansehoegwan/","foodTypes":"한식(육류)","latitude":37.5434989075604,"longitude":127.058053868759,"bookYear":null,"nameKR":"만세회관(성수본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37913,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8262178396706,"longitude":128.624315369721,"bookYear":null,"nameKR":"조돌해녀(수성못점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":37912,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.9432195929593,"longitude":128.559623799484,"bookYear":null,"nameKR":"조돌해녀","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":3795,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goheung_house","foodTypes":"한식(육류)","latitude":37.527989628827,"longitude":126.992977452738,"bookYear":null,"nameKR":"고흥집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":37443,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/modern__duck/","foodTypes":"한식(가금류)","latitude":36.10731717239,"longitude":128.421881069501,"bookYear":null,"nameKR":"모던덕","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":37911,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chomidang_official/","foodTypes":"일식","latitude":36.3346887701113,"longitude":127.450172736698,"bookYear":null,"nameKR":"초미당세번째","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":37881,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/aeum.seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5540882155381,"longitude":126.931766648648,"bookYear":null,"nameKR":"에움","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":37880,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5790933151243,"longitude":126.971759754007,"bookYear":null,"nameKR":"서촌포인텔리젠시아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":37279,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.3821848709371,"longitude":126.959546177308,"bookYear":null,"nameKR":"더화룡","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":37908,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8350696735979,"longitude":129.212389137211,"bookYear":null,"nameKR":"신라제면","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":37907,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.8511496998376,"longitude":129.261750324885,"bookYear":null,"nameKR":"조돌칼국수","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":37905,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.3467335415714,"longitude":127.290888249797,"bookYear":null,"nameKR":"홍시반","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":37903,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/andover___/","foodTypes":"이탈리아식","latitude":36.615703829251,"longitude":127.514077041788,"bookYear":null,"nameKR":"앤도버","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":37901,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4730513390026,"longitude":127.141262948182,"bookYear":null,"nameKR":"우마당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":37900,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the.eddie.daegu/","foodTypes":"컨템포러리","latitude":35.856120796473,"longitude":128.60545474632,"bookYear":null,"nameKR":"디에디","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":37897,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/omeonggameongblackpork/","foodTypes":"한식(육류)","latitude":33.5092489519063,"longitude":126.474693862787,"bookYear":null,"nameKR":"오멍가멍흑돼지","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":37896,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.3434524727464,"longitude":126.735554311176,"bookYear":null,"nameKR":"참나무누룽닭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":37895,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1483695427626,"longitude":129.107813307954,"bookYear":null,"nameKR":"청산1954","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37891,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baristag_coffee_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.3454241991241,"longitude":126.763482442437,"bookYear":null,"nameKR":"택하다, 커피","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":37890,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dining_hyohyo","foodTypes":"바/주점","latitude":37.606231004929,"longitude":127.023773153729,"bookYear":null,"nameKR":"다이닝효효","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":37693,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5097212397913,"longitude":127.022790965877,"bookYear":null,"nameKR":"논현전","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":37653,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.547949900166,"longitude":126.917386412253,"bookYear":null,"nameKR":"베니베이크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":37716,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yangyukjum/","foodTypes":"일식","latitude":35.1327407819394,"longitude":126.930239363032,"bookYear":null,"nameKR":"양육점(학동점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":37888,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5586507509572,"longitude":127.039974427569,"bookYear":null,"nameKR":"끄트머리집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":37543,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ooohpoke/","foodTypes":"기타","latitude":37.5415995620032,"longitude":127.071136774445,"bookYear":null,"nameKR":"오포케","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36666,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":35.1607651948088,"longitude":129.192213522529,"bookYear":null,"nameKR":"청사포쌀국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":37850,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.510536343736,"longitude":126.892215775955,"bookYear":null,"nameKR":"고동경양(문래본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37885,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3434524727464,"longitude":126.735554311176,"bookYear":null,"nameKR":"함돈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":37884,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tangbanok","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8777310836924,"longitude":128.727098591439,"bookYear":null,"nameKR":"탕반옥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":37876,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/im_mariochef/","foodTypes":"유럽식","latitude":35.1381922001511,"longitude":126.914190396912,"bookYear":null,"nameKR":"까사델스페인","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":37874,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rollinikseon/","foodTypes":"기타","latitude":37.5743537755157,"longitude":126.989767334842,"bookYear":null,"nameKR":"롤인익선","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":37869,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.4878980334792,"longitude":126.992483354184,"bookYear":null,"nameKR":"포동본점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":37868,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":36.1377770275233,"longitude":128.419036338482,"bookYear":null,"nameKR":"달밤어묵본점","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":37867,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kkokkochicken","foodTypes":"한식(가금류)","latitude":37.9425019409564,"longitude":126.837330335084,"bookYear":null,"nameKR":"꼬꼬피치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":37865,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5732089134527,"longitude":127.013499127574,"bookYear":null,"nameKR":"도호","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":37863,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8278468386386,"longitude":127.172011873818,"bookYear":null,"nameKR":"대장간","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":37734,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mr.keungoal/","foodTypes":"한식(육류)","latitude":35.5448535654231,"longitude":129.330264996558,"bookYear":null,"nameKR":"앞산큰골집(달동점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":37063,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maison_de_four","foodTypes":"디저트/차/베이커리","latitude":35.1929854904443,"longitude":129.074855785753,"bookYear":null,"nameKR":"메종드푸","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":37064,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sgrbba","foodTypes":"바/주점","latitude":37.5685901508409,"longitude":126.930437098041,"bookYear":null,"nameKR":"신기루빠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":37604,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_awave","foodTypes":"디저트/차/베이커리","latitude":37.7579343468992,"longitude":128.891386261528,"bookYear":null,"nameKR":"어웨이브","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":37571,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/syoten_official","foodTypes":"바/주점","latitude":37.3109957339356,"longitude":126.829110398678,"bookYear":null,"nameKR":"쇼텐","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":210,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5733760424938,"longitude":126.986452732054,"bookYear":null,"nameKR":"툇마루집된장예술","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":37851,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/anjung.house/","foodTypes":"바/주점","latitude":35.5366530644815,"longitude":129.335123876051,"bookYear":null,"nameKR":"안정가옥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":29340,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hyodochicken, https://hdchk.modoo.at/","foodTypes":"한식(가금류)","latitude":37.520842307129705,"longitude":127.03709466086265,"bookYear":null,"nameKR":"효도치킨","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":9536,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.7821679988358,"longitude":126.46100054678,"bookYear":null,"nameKR":"맛수리감자탕","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":37858,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.8684826237761,"longitude":128.58821501908,"bookYear":null,"nameKR":"다금예전칼국수","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":37856,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_fantail","foodTypes":"디저트/차/베이커리","latitude":37.5516947946644,"longitude":126.979932314116,"bookYear":null,"nameKR":"팬테일","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":37853,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sambongbutchers/","foodTypes":"한식(육류)","latitude":37.5935096696346,"longitude":127.337429725842,"bookYear":null,"nameKR":"삼봉푸줏간 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":37848,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.8446207778172,"longitude":127.054922911134,"bookYear":null,"nameKR":"김여사능이버섯백숙","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":37807,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/uni_milmyeon/","foodTypes":"한식(면류)","latitude":35.1860386407419,"longitude":129.123033500874,"bookYear":null,"nameKR":"유니밀면","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":29014,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.onion","foodTypes":"디저트/차/베이커리","latitude":37.544644,"longitude":127.058323,"bookYear":null,"nameKR":"어니언","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37845,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/l_or_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5008535759541,"longitude":127.031986971465,"bookYear":null,"nameKR":"로흐","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":37843,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1830018064138,"longitude":129.075510861058,"bookYear":null,"nameKR":"믹스토랑(시청점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":37842,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4588902854241,"longitude":126.897681476166,"bookYear":null,"nameKR":"믹스토랑(금천구청점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":37844,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_mixtorang_changwon/","foodTypes":"기타","latitude":35.2426344519094,"longitude":128.689453349,"bookYear":null,"nameKR":"믹스토랑(창원대점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":27875,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.45020408272237,"longitude":126.63499960885346,"bookYear":null,"nameKR":"정든식당","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":31518,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5926253604882,"longitude":129.090808174525,"bookYear":null,"nameKR":"피아노","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":37281,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hooroop_roasteres","foodTypes":"디저트/차/베이커리","latitude":37.5606312206415,"longitude":126.832720625607,"bookYear":null,"nameKR":"후룹로스터스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":25717,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.963155,"longitude":126.718425,"bookYear":null,"nameKR":"청담횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":35841,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5616429635579,"longitude":126.977362888243,"bookYear":null,"nameKR":"북경원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":34783,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4188310217567,"longitude":127.116756698975,"bookYear":null,"nameKR":"구좌리얼크니손칼국수(성남사송직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":34780,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.3627233045556,"longitude":127.098942756414,"bookYear":null,"nameKR":"구좌리얼크니손칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":26597,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5814007689909,"longitude":127.046212150333,"bookYear":null,"nameKR":"경북손칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":33430,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/kakusita_izakaya","foodTypes":"바/주점","latitude":37.5618676427003,"longitude":126.926140242586,"bookYear":null,"nameKR":"카쿠시타","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":7630,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.49303,"longitude":127.028392,"bookYear":null,"nameKR":"유타로(강남역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":32074,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vinovine.gram/","foodTypes":"기타","latitude":37.4892104782646,"longitude":127.00831349217,"bookYear":null,"nameKR":"비노바인","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":32992,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lepainasser_seorae/","foodTypes":"디저트/차/베이커리","latitude":37.4966667687424,"longitude":126.998004848494,"bookYear":null,"nameKR":"르뺑아쎄르","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":37590,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8651742239642,"longitude":128.611450060066,"bookYear":null,"nameKR":"디오","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":37654,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.570617972762,"longitude":127.000043467383,"bookYear":null,"nameKR":"형제육회","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":37656,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/courtside.ss/","foodTypes":"디저트/차/베이커리","latitude":37.5434238915696,"longitude":127.06066826069,"bookYear":null,"nameKR":"코트사이드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37661,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5125043967149,"longitude":126.708909191911,"bookYear":null,"nameKR":"장터옛날통닭(인천본점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":37663,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5540750106135,"longitude":126.927303978336,"bookYear":null,"nameKR":"제로카페(홍대본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37679,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/annex_seoul/","foodTypes":"이탈리아식","latitude":37.5922615653835,"longitude":127.018935526044,"bookYear":null,"nameKR":"별관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":37680,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nokzi_kr","foodTypes":"바/주점","latitude":35.1503757789833,"longitude":126.925734235501,"bookYear":null,"nameKR":"녹지","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":37700,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6701436366568,"longitude":126.750215379827,"bookYear":null,"nameKR":"한알","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":37713,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/munhwabistro_sd/","foodTypes":"기타","latitude":37.5654939655511,"longitude":127.01983606799,"bookYear":null,"nameKR":"문화식당(신당점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":37714,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8696132631559,"longitude":128.591763493103,"bookYear":null,"nameKR":"교맥동(대구종로점) ","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":37726,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":34.8024841206915,"longitude":126.435780063867,"bookYear":null,"nameKR":"박병궁명인목포설렁탕 ","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":37840,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dosan_butchers/","foodTypes":"한식(육류)","latitude":37.5255995843403,"longitude":127.038826747185,"bookYear":null,"nameKR":"도산정육(청담본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37838,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/udon_be_completed","foodTypes":"일식","latitude":35.1486924046242,"longitude":126.923714754806,"bookYear":null,"nameKR":"우동이완성되다","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":9365,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.530234,"longitude":126.921678,"bookYear":null,"nameKR":"양스앤메츠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":37829,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi_asita/","foodTypes":"일식","latitude":35.8692558513333,"longitude":127.120546149137,"bookYear":null,"nameKR":"스시아시타(송천점)","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":37825,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/pibing_yangyang","foodTypes":"미국식","latitude":37.9715313839601,"longitude":128.759451393788,"bookYear":null,"nameKR":"피빙","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":37821,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/delicium_busan/","foodTypes":"디저트/차/베이커리","latitude":35.2371325762386,"longitude":129.242651268549,"bookYear":null,"nameKR":"델리시움","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":37816,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leemarco_pizza/","foodTypes":"이탈리아식","latitude":37.6636901789406,"longitude":127.011856348586,"bookYear":null,"nameKR":"리마르코","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":37815,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sandos_seongsu","foodTypes":"기타","latitude":37.5477009519645,"longitude":127.039621286419,"bookYear":null,"nameKR":"산도스성수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37813,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/happyhappy.cake/","foodTypes":"디저트/차/베이커리","latitude":37.5182883213674,"longitude":127.042201656569,"bookYear":null,"nameKR":"해피해피케이크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33389,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mellower_kr/","foodTypes":"디저트/차/베이커리","latitude":37.5023270151927,"longitude":127.044444694599,"bookYear":null,"nameKR":"베통역삼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":37791,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gomtanglab","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5188658448158,"longitude":127.025224654498,"bookYear":null,"nameKR":"곰탕랩","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37811,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2087086944096,"longitude":129.038045464984,"bookYear":null,"nameKR":"신부장숯불갈비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":37810,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sunsucoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5592565859994,"longitude":126.823388809057,"bookYear":null,"nameKR":"순수커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":37809,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/injucompany/","foodTypes":"디저트/차/베이커리","latitude":36.7558329487323,"longitude":127.076511482147,"bookYear":null,"nameKR":"인주(필드점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":37808,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/injucompany/","foodTypes":"디저트/차/베이커리","latitude":36.8674443338646,"longitude":126.874823992536,"bookYear":null,"nameKR":"인주(한옥점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":18945,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1625391557254,"longitude":129.163252246854,"bookYear":null,"nameKR":"구포국수(해운대본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":33907,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gomespresso/","foodTypes":"디저트/차/베이커리","latitude":36.3141015766298,"longitude":127.350036248552,"bookYear":null,"nameKR":"곰에스프레소.B","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":8007,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.828333,"longitude":127.097712,"bookYear":null,"nameKR":"미가옥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":37806,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/milmyeon.zip/","foodTypes":"한식(면류)","latitude":35.5707536570309,"longitude":129.124083608616,"bookYear":null,"nameKR":"언양밀면","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":37804,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daeryuk_huoguo","foodTypes":"중식","latitude":35.1562802749936,"longitude":129.124406777333,"bookYear":null,"nameKR":"대륙훠궈","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37803,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bojung_gnlg/","foodTypes":"한식(육류)","latitude":37.3207470055556,"longitude":127.109887693719,"bookYear":null,"nameKR":"강남랭겹(용인보정점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":37802,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rang_210401/","foodTypes":"한식(육류)","latitude":37.4999636489373,"longitude":127.028103322447,"bookYear":null,"nameKR":"강남랭겹","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":37797,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3777537592365,"longitude":127.132871482333,"bookYear":null,"nameKR":"카페키트","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37790,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yakitori_sinsets","foodTypes":"일식","latitude":35.1570044454528,"longitude":129.12448859567,"bookYear":null,"nameKR":"신세츠","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37793,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7509230868548,"longitude":127.74351171886,"bookYear":null,"nameKR":"일광","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":37792,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the.potato.park/","foodTypes":"한식(일반한식)","latitude":37.7563535549992,"longitude":128.897386093221,"bookYear":null,"nameKR":"감자유원지","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":7771,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.270135,"longitude":128.646247,"bookYear":null,"nameKR":"백년찻집(창원점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":37745,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/han_ggigalbitang","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3824430719809,"longitude":126.66063408484,"bookYear":null,"nameKR":"한끼,갈비탕","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":37789,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5039021645423,"longitude":126.751084684264,"bookYear":null,"nameKR":"붓두막","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":37782,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5166867855424,"longitude":127.02832745164,"bookYear":null,"nameKR":"모스가든","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":37779,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6233056206895,"longitude":126.836527841613,"bookYear":null,"nameKR":"참이맛감자탕(행신본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":37766,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/flow_itaewon","foodTypes":"기타","latitude":37.5326236293874,"longitude":126.991182183361,"bookYear":null,"nameKR":"플로우이태원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":37763,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/o.sho___","foodTypes":"바/주점","latitude":35.8697727464979,"longitude":128.591234480702,"bookYear":null,"nameKR":"오쇼","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":37788,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ssongthai_chiangmai/","foodTypes":"아시아식","latitude":37.5605537644679,"longitude":126.833890184435,"bookYear":null,"nameKR":"쏭타이치앙마이(마곡직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":37787,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ssongthai_chiangmai/","foodTypes":"아시아식","latitude":37.5303665102131,"longitude":126.971605603739,"bookYear":null,"nameKR":"쏭타이치앙마이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37781,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.3182995917297,"longitude":128.998175052316,"bookYear":null,"nameKR":"장인손만두","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":37778,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/elbethel_kitchen/","foodTypes":"중식","latitude":37.3952040049379,"longitude":126.631287119931,"bookYear":null,"nameKR":"엘벧엘","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":37704,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5417305569861,"longitude":126.952523537357,"bookYear":null,"nameKR":"오롯실비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":32319,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5447477438441,"longitude":126.948258201287,"bookYear":null,"nameKR":"오롯로바타","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":37769,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.6511027931211,"longitude":127.422488584933,"bookYear":null,"nameKR":"유생촌(청주점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":37771,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/standardsystem.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5225715544949,"longitude":127.043565625531,"bookYear":null,"nameKR":"스탠다드시스템","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37770,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/alexandercoffeestudio/","foodTypes":"디저트/차/베이커리","latitude":37.5051902735793,"longitude":127.045747691089,"bookYear":null,"nameKR":"알렉산더커피스튜디오","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":37735,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jjm._.pungam/","foodTypes":"바/주점","latitude":35.1258536793703,"longitude":126.878889762357,"bookYear":null,"nameKR":"정주막(풍암점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":33253,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.9073793270461,"longitude":127.05122453621,"bookYear":null,"nameKR":"올트커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":37761,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/munhwabistrot/","foodTypes":"기타","latitude":37.5423144759191,"longitude":127.053822480804,"bookYear":null,"nameKR":"문화식당(성수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37760,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5260187782865,"longitude":126.893563494772,"bookYear":null,"nameKR":"연탄불","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37759,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.2488532004121,"longitude":127.076632799875,"bookYear":null,"nameKR":"호식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37699,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/broome_brunch/","foodTypes":"기타","latitude":37.528490384394,"longitude":126.676990300262,"bookYear":null,"nameKR":"브룸","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":420,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.562064,"longitude":126.978328,"bookYear":null,"nameKR":"부산갈매기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":37510,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yetgoal_jecheon","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.1313449457786,"longitude":128.183822576974,"bookYear":null,"nameKR":"옛골식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":37756,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.9520668801659,"longitude":126.955910347879,"bookYear":null,"nameKR":"초미당두번째","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":29937,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.2784553284954,"longitude":126.716913203067,"bookYear":null,"nameKR":"범일분식","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":30881,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/liberte_dogok/","foodTypes":"프랑스식","latitude":37.4843847303118,"longitude":127.046296940516,"bookYear":null,"nameKR":"리베르떼 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":37755,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chomidang_official/","foodTypes":"일식","latitude":35.9521696599995,"longitude":126.95601492608,"bookYear":null,"nameKR":"초미당첫번째","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":37098,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/haanyang/","foodTypes":"중식","latitude":37.4846614982049,"longitude":127.120933640924,"bookYear":null,"nameKR":"한양중식(문정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":33807,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/haanyang/","foodTypes":"중식","latitude":37.4171034624346,"longitude":126.880947375247,"bookYear":null,"nameKR":"한양중식","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":37097,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/haanyang/","foodTypes":"중식","latitude":37.5707451369668,"longitude":126.983611410428,"bookYear":null,"nameKR":"한양중식(종로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":36991,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yphr_jmt/","foodTypes":"한식(육류)","latitude":37.2514587479802,"longitude":127.075935916587,"bookYear":null,"nameKR":"영포화로","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37525,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5209082467504,"longitude":127.023885082455,"bookYear":null,"nameKR":"샌드커피논탄토","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37682,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5927509576099,"longitude":126.998194890127,"bookYear":null,"nameKR":"문화식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":37709,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/muu_dining/","foodTypes":"한식(육류)","latitude":37.5092847609655,"longitude":127.027200856595,"bookYear":null,"nameKR":"MUU한우다이닝(논현본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":31264,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.4843235461066,"longitude":126.54065788782,"bookYear":null,"nameKR":"유스커피","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":37678,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5284754434309,"longitude":127.040052671773,"bookYear":null,"nameKR":"수아당(갤러리아백화점명품관WEST점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":35163,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/suadang_official/","foodTypes":"한식(일반한식)","latitude":37.5930702585042,"longitude":127.018022415284,"bookYear":null,"nameKR":"수아당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":37528,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nontanto.roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5273217750941,"longitude":126.874619136454,"bookYear":null,"nameKR":"샌드커피논탄토(목동점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":37529,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nontanto.ulsan/","foodTypes":"디저트/차/베이커리","latitude":35.6346884858172,"longitude":129.360632628965,"bookYear":null,"nameKR":"샌드커피논탄토(울산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":37523,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moru_changwon","foodTypes":"일식","latitude":35.2373432073938,"longitude":128.687323864594,"bookYear":null,"nameKR":"모루식당(창원점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":34515,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/moru_busan","foodTypes":"기타","latitude":35.1559851909963,"longitude":129.063994717674,"bookYear":null,"nameKR":"모루식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":35989,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/komfortabelcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5492487556727,"longitude":126.983354709318,"bookYear":null,"nameKR":"콤포타블남산","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":32323,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/creamchic_official/","foodTypes":"디저트/차/베이커리","latitude":37.5643525309057,"longitude":126.983969236251,"bookYear":null,"nameKR":"크림시크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":37387,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5297718014452,"longitude":126.964741503485,"bookYear":null,"nameKR":"오시오카페(아이파크몰용산점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37373,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leesarcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5706161018862,"longitude":126.980559892691,"bookYear":null,"nameKR":"리사르커피(종로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":31679,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5270852129791,"longitude":127.048430033039,"bookYear":null,"nameKR":"리사르커피(청담점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":34686,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4801329057386,"longitude":127.040298310882,"bookYear":null,"nameKR":"레드닷커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":32087,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/cafe.sadam","foodTypes":"기타","latitude":37.479887345745,"longitude":126.95558273167,"bookYear":null,"nameKR":"사담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":30691,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/momentsdemotions/","foodTypes":"디저트/차/베이커리","latitude":37.48123942875455,"longitude":127.01066394566186,"bookYear":null,"nameKR":"모멍데모시옹","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":30284,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/jungjakga/","foodTypes":"바/주점","latitude":37.48972598290362,"longitude":126.98164580719268,"bookYear":null,"nameKR":"정작가의막걸리집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":30422,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.49265687409588,"longitude":127.01201346501583,"bookYear":null,"nameKR":"락희옥(서초교대역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":28269,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/lakheeoak","foodTypes":"바/주점","latitude":37.544303,"longitude":126.948973,"bookYear":null,"nameKR":"락희옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":37685,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1870053457936,"longitude":126.897452031932,"bookYear":null,"nameKR":"이영미궁중한방삼계탕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":37754,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/neuling42_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.3613376767451,"longitude":127.105543131014,"bookYear":null,"nameKR":"노일링42","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":36367,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3567956500871,"longitude":127.324406247328,"bookYear":null,"nameKR":"블루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":37751,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4912751024086,"longitude":126.924094714271,"bookYear":null,"nameKR":"이태리로간고등어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":37750,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.daily_table/","foodTypes":"디저트/차/베이커리","latitude":37.2465642727006,"longitude":127.041382076664,"bookYear":null,"nameKR":"데일리테이블","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37746,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/RE.FINE_Jeonpo/","foodTypes":"기타","latitude":35.1555114408015,"longitude":129.067128511841,"bookYear":null,"nameKR":"리파인","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37226,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dian_chungdam/","foodTypes":"한식(민물어패류)","latitude":37.2660474710871,"longitude":127.135844388634,"bookYear":null,"nameKR":"청담추어정(기흥점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":37225,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dian_chungdam/","foodTypes":"한식(민물어패류)","latitude":37.4072104342353,"longitude":127.134451358779,"bookYear":null,"nameKR":"청담추어정(야탑점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35149,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/foodlove_namyangju/","foodTypes":"바/주점","latitude":37.7105136669138,"longitude":127.198420562012,"bookYear":null,"nameKR":"술로맨스 퓨전요리전문","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":37369,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5550687570722,"longitude":127.035424100115,"bookYear":null,"nameKR":"봉밀가(행당점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":10208,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.58267,"longitude":126.858514,"bookYear":null,"nameKR":"등촌회관","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":29031,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.714865,"longitude":126.688692,"bookYear":null,"nameKR":"블루박스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":29040,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fabrique_coffeeroasters/","foodTypes":"디저트/차/베이커리","latitude":37.401276,"longitude":127.110419,"bookYear":null,"nameKR":"파브리끄","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":29064,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/damxbb/","foodTypes":"디저트/차/베이커리","latitude":36.638451,"longitude":127.489033,"bookYear":null,"nameKR":"자매상회","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":29079,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.836757,"longitude":127.169368,"bookYear":null,"nameKR":"향토식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29199,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":38.19724342370608,"longitude":128.53771287788956,"bookYear":null,"nameKR":"크래프트루트(속초점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":29247,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.344129,"longitude":127.127817,"bookYear":null,"nameKR":"두마레","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":29674,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.79851470393243,"longitude":127.18375412313986,"bookYear":null,"nameKR":"호림이네","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":30631,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/haltavoca/","foodTypes":"디저트/차/베이커리","latitude":35.85419834101285,"longitude":128.65360000753907,"bookYear":null,"nameKR":"할타보카","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":30683,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.76049525072394,"longitude":128.8949131006497,"bookYear":null,"nameKR":"신성원식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":30964,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafestory_kr/","foodTypes":"디저트/차/베이커리","latitude":37.5548669403533,"longitude":127.241313309074,"bookYear":null,"nameKR":"카페스토리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":31065,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.3291099561211,"longitude":126.798255712689,"bookYear":null,"nameKR":"광동식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":31141,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.5365499082225,"longitude":126.554240197599,"bookYear":null,"nameKR":"금단양만","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":31661,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.1195443191268,"longitude":128.621218192654,"bookYear":null,"nameKR":"산촌생등심","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":32563,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chungchosoo_official/","foodTypes":"한식(어패류)","latitude":38.1921462347897,"longitude":128.590538447314,"bookYear":null,"nameKR":"청초수물회","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":34532,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6477644758004,"longitude":127.108223240285,"bookYear":null,"nameKR":"덕화배꽃마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":6921,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4516629220886,"longitude":126.671681781425,"bookYear":null,"nameKR":"고을순대국","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":28400,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.468208,"longitude":126.929243,"bookYear":null,"nameKR":"카페코지","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":37061,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.3087629318716,"longitude":128.984467366359,"bookYear":null,"nameKR":"명성가","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":37743,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bucfac/","foodTypes":"디저트/차/베이커리","latitude":37.5594578649978,"longitude":126.90602385246,"bookYear":null,"nameKR":"버터크림팩토리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":37741,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kasando_jeongja","foodTypes":"일식","latitude":37.3602089514741,"longitude":127.112588502843,"bookYear":null,"nameKR":"카산도(정자본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37740,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.2677327513882,"longitude":127.027423202883,"bookYear":null,"nameKR":"우루루(인계점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37739,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.3590826232786,"longitude":126.933707752313,"bookYear":null,"nameKR":"우루루(산본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":37738,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.391479298647,"longitude":126.953453547632,"bookYear":null,"nameKR":"우루루(범계점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":37736,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.4012813698831,"longitude":126.960750482661,"bookYear":null,"nameKR":"우루루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":37733,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/galbi__house/","foodTypes":"한식(육류)","latitude":37.8632574826468,"longitude":126.784882618073,"bookYear":null,"nameKR":"구워주는갈빗집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":37732,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/grillerz_2017/","foodTypes":"기타","latitude":37.515059104738,"longitude":126.894502736554,"bookYear":null,"nameKR":"그릴러즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37731,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jamsilgopchang/","foodTypes":"한식(육류)","latitude":37.5057215317162,"longitude":127.096451873452,"bookYear":null,"nameKR":"잠실황소곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":37730,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/taronga_trg/","foodTypes":"기타","latitude":37.6518833009949,"longitude":127.128118486856,"bookYear":null,"nameKR":"타롱가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":37729,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.2208620972449,"longitude":128.684535832807,"bookYear":null,"nameKR":"주목","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":37727,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7997639875566,"longitude":126.428692064991,"bookYear":null,"nameKR":"신화수산","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":37724,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/such.tori_/","foodTypes":"일식","latitude":35.8201747647596,"longitude":127.1383784601,"bookYear":null,"nameKR":"숯토리","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":36065,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5269056148241,"longitude":126.966566172294,"bookYear":null,"nameKR":"31월","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37689,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.493110486689,"longitude":127.084232260259,"bookYear":null,"nameKR":"썬플라워베이크샵","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":37723,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sintoburiongsimi/","foodTypes":"한식(면류)","latitude":38.2044998655463,"longitude":128.590205074366,"bookYear":null,"nameKR":"속초신토불이감자옹심이","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":37722,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/azae_ggomak/","foodTypes":"한식(어패류)","latitude":37.5338651470143,"longitude":126.956396918453,"bookYear":null,"nameKR":"아재꼬막","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37719,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/keepthat_coffee","foodTypes":"디저트/차/베이커리","latitude":37.2836072719405,"longitude":127.014122239346,"bookYear":null,"nameKR":"킵댓로스터리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37718,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/keepthat_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.2860495644338,"longitude":127.011367193573,"bookYear":null,"nameKR":"킵댓","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37712,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ngonhanoi/","foodTypes":"아시아식","latitude":35.1581507739397,"longitude":129.060777271264,"bookYear":null,"nameKR":"맛있는하노이","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":37489,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4037383177861,"longitude":127.109117752246,"bookYear":null,"nameKR":"데이빗앤룰스(디테라스점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":37394,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5163647511311,"longitude":126.889576695533,"bookYear":null,"nameKR":"양상블","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37659,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wumaka_official/","foodTypes":"한식(육류)","latitude":36.7719810878373,"longitude":126.448999452464,"bookYear":null,"nameKR":"우마카","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":37691,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.1903654947826,"longitude":128.588596225028,"bookYear":null,"nameKR":"돈우마을","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":37701,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mrleebeer/","foodTypes":"바/주점","latitude":37.4756133204062,"longitude":126.8690277205,"bookYear":null,"nameKR":"이선생맥주(철산점) ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":37692,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.6644926003472,"longitude":127.500542610838,"bookYear":null,"nameKR":"테이크잇슬로우","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":37707,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hyo_makase/","foodTypes":"일식","latitude":35.1552546068783,"longitude":129.134077394336,"bookYear":null,"nameKR":"램바란스(프리미엄광안)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37708,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1737089966411,"longitude":129.17714678273,"bookYear":null,"nameKR":"제일칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":32374,"bookStatus":"커버","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5598562142859,"longitude":126.834854214935,"bookYear":null,"nameKR":"화양연화 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":37293,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/jayu.bakeshop.2nd","foodTypes":"디저트/차/베이커리","latitude":37.8593270316839,"longitude":127.717405759333,"bookYear":null,"nameKR":"자유빵집(2호점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":37192,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sinsigaji_solga/","foodTypes":"한식(육류)","latitude":35.8203713781969,"longitude":127.104315665195,"bookYear":null,"nameKR":"솔가숯불갈비(신시가지점)","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":37668,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5688537151032,"longitude":126.965114852628,"bookYear":null,"nameKR":"교남김밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":10977,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.240201578822,"longitude":128.567964219694,"bookYear":null,"nameKR":"도남식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"군위군","status":"ACTIVE"},
        {"id":10710,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.0480173602419,"longitude":128.641869094665,"bookYear":null,"nameKR":"시골밥상","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"군위군","status":"ACTIVE"},
        {"id":10682,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.1487158640747,"longitude":128.597787317934,"bookYear":null,"nameKR":"삼장매운탕","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"군위군","status":"ACTIVE"},
        {"id":33874,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_shimso_gunwi/","foodTypes":"디저트/차/베이커리","latitude":36.1073799147543,"longitude":128.706828386349,"bookYear":null,"nameKR":"쉼소","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"군위군","status":"ACTIVE"},
        {"id":37690,"bookStatus":"비활성","websiteInstagram":null,"latitude":35.1526376232046,"longitude":129.067815626121,"bookYear":null,"nameKR":"치히로식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37710,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5242360099173,"longitude":127.040166698964,"bookYear":null,"nameKR":"MUU한우다이닝(청담점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":37702,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cinnamon_haus_/","foodTypes":"디저트/차/베이커리","latitude":37.4741322433377,"longitude":126.597552254849,"bookYear":null,"nameKR":"시나몬하우스(월미점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":37696,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5476475647755,"longitude":126.921269091913,"bookYear":null,"nameKR":"두옹즈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32158,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5138710747422,"longitude":127.106364392884,"bookYear":null,"nameKR":"지강한식당(잠실점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32159,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5263301884169,"longitude":127.029553686621,"bookYear":null,"nameKR":"지강한식당(압구정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37676,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5287565489862,"longitude":126.897107681069,"bookYear":null,"nameKR":"흘러","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":30602,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.79037861190712,"longitude":126.38451709800991,"bookYear":null,"nameKR":"씨엘비베이커리","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":37697,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mongfl_official/","foodTypes":"기타","latitude":36.4890737483121,"longitude":127.260245278663,"bookYear":null,"nameKR":"몽플(본점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":37094,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.754788070524,"longitude":128.892631128644,"bookYear":null,"nameKR":"임당동162","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":37491,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.368702977074,"longitude":127.050820589497,"bookYear":null,"nameKR":"프랭클린텍사스바베큐(석운점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37688,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pakepake_bakery","foodTypes":"디저트/차/베이커리","latitude":37.5423144759191,"longitude":127.053822480804,"bookYear":null,"nameKR":"파케파케","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37687,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.522057531502,"longitude":126.89528677963,"bookYear":null,"nameKR":"카츠쇼쿠도우(영등포구청점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37686,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.149379517754,"longitude":126.943074426269,"bookYear":null,"nameKR":"이영미궁중한방삼계탕(지산점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":37650,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/suage_daechi/","foodTypes":"일식","latitude":37.5001331106452,"longitude":127.053268696919,"bookYear":null,"nameKR":"스아게","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":37681,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/spillout_seoul/","foodTypes":"미국식","latitude":37.5617349291533,"longitude":126.924860275631,"bookYear":null,"nameKR":"스필아웃","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37588,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중남미식","latitude":34.8075357979272,"longitude":127.837157338443,"bookYear":null,"nameKR":"때깔로무역","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":37117,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중남미식","latitude":37.5676347080798,"longitude":126.978984811389,"bookYear":null,"nameKR":"브라주카그릴","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":37655,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kangbyul_sungsu/","foodTypes":"컨템포러리","latitude":37.5389394346462,"longitude":127.056868725032,"bookYear":null,"nameKR":"강별성수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37675,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":36.2985711712256,"longitude":127.33793374806,"bookYear":null,"nameKR":"하츠야","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":37673,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yi___liang","foodTypes":"중식","latitude":37.5383909303027,"longitude":127.093600528983,"bookYear":null,"nameKR":"예량","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":37671,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ilsan_top_","foodTypes":"바/주점","latitude":37.6684423383553,"longitude":126.773332132525,"bookYear":null,"nameKR":"최상위","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":37669,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.3197816166382,"longitude":129.000115316125,"bookYear":null,"nameKR":"팔당원조칼제비(양산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":37666,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wakegi.izakaya","foodTypes":"바/주점","latitude":37.197569425488,"longitude":127.096582650573,"bookYear":null,"nameKR":"와케기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":37664,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pastaseason","foodTypes":"이탈리아식","latitude":37.4905144417912,"longitude":126.758214512039,"bookYear":null,"nameKR":"파스타시즌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":37631,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5425995041559,"longitude":127.048746487536,"bookYear":null,"nameKR":"휘뚜루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37658,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5064466344777,"longitude":127.059502015213,"bookYear":null,"nameKR":"국민한우집(삼성역본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":37660,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/han1_do","foodTypes":"바/주점","latitude":37.385183475728,"longitude":126.644007327864,"bookYear":null,"nameKR":"한일도","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":37418,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1645302075522,"longitude":129.158061654434,"bookYear":null,"nameKR":"캐버린하우스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":36261,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5432606454948,"longitude":127.054737226446,"bookYear":null,"nameKR":"다이닝목로 성수점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32076,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5234794158554,"longitude":127.037776866131,"bookYear":null,"nameKR":"다이닝목로 압구정본점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":36467,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.6403317877667,"longitude":127.488412325697,"bookYear":null,"nameKR":"소로리","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":37652,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5280169945095,"longitude":126.870615708032,"bookYear":null,"nameKR":"하순옥황금안동국시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":36641,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.4673746469537,"longitude":126.338487533272,"bookYear":null,"nameKR":"돝밭","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":32137,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/konbu_i/","foodTypes":"바/주점","latitude":37.5650851033938,"longitude":126.990341333737,"bookYear":null,"nameKR":"콘부","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":37613,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_onyee/","foodTypes":"디저트/차/베이커리","latitude":37.8655136990181,"longitude":127.71931710452,"bookYear":null,"nameKR":"온이","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":37648,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/awluk/","foodTypes":"디저트/차/베이커리","latitude":35.1553423640147,"longitude":129.068127245643,"bookYear":null,"nameKR":"얼룩","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37647,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chalkfarmbusan","foodTypes":"디저트/차/베이커리","latitude":35.1532128253458,"longitude":129.067911161666,"bookYear":null,"nameKR":"촉팜","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37633,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wnch_seoul/","foodTypes":"기타","latitude":37.5880671533694,"longitude":127.020159306903,"bookYear":null,"nameKR":"운치","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":37626,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":37.0803686098437,"longitude":127.05095925348,"bookYear":null,"nameKR":"사스콰치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":37625,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lavatree_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5476939315842,"longitude":127.04512444582,"bookYear":null,"nameKR":"라바트리(서울숲)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37624,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/suni.onlysuni","foodTypes":"일식","latitude":36.0079529582591,"longitude":129.329425672387,"bookYear":null,"nameKR":"순이","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":37616,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bebes_uu","foodTypes":"디저트/차/베이커리","latitude":37.3905712700407,"longitude":126.979137100049,"bookYear":null,"nameKR":"베베슈베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":37370,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1619452635176,"longitude":129.110889889171,"bookYear":null,"nameKR":"우리포차","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35819,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.1488316626299,"longitude":128.70278544114,"bookYear":null,"nameKR":"돈미가옥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":37350,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5207908297398,"longitude":126.926911965929,"bookYear":null,"nameKR":"보글보글부대찌개","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36466,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.3277410838969,"longitude":127.983777279829,"bookYear":null,"nameKR":"마지쿠키","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":36357,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/phises_busan","foodTypes":"아시아식","latitude":35.158078935105,"longitude":129.067339868287,"bookYear":null,"nameKR":"피셋","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":36803,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5625864791901,"longitude":127.189964124756,"bookYear":null,"nameKR":"페어링하우스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":37527,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nontanto.roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5557509112143,"longitude":126.936271826482,"bookYear":null,"nameKR":"샌드커피논탄토(신촌점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":37526,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5592711660529,"longitude":126.924174847151,"bookYear":null,"nameKR":"샌드커피논탄토(연남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37375,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/timhortons.kr/","foodTypes":"디저트/차/베이커리","latitude":37.5049095607089,"longitude":127.024789737061,"bookYear":null,"nameKR":"팀홀튼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":37374,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tremorcoffeeworks/","foodTypes":"디저트/차/베이커리","latitude":37.5549252082881,"longitude":126.9279184716,"bookYear":null,"nameKR":"트레머커피웍스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":37321,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5428217449251,"longitude":127.058905961003,"bookYear":null,"nameKR":"킷사앤사보","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37442,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/milsishappy","foodTypes":"디저트/차/베이커리","latitude":37.5389686315866,"longitude":127.050937053628,"bookYear":null,"nameKR":"밀스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37611,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":37.5022812610386,"longitude":126.763405870968,"bookYear":null,"nameKR":"페페피자(부천점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":37608,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/diable_chocolat/","foodTypes":"디저트/차/베이커리","latitude":35.539942486417,"longitude":129.336294682771,"bookYear":null,"nameKR":"디아블","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":37607,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1629633489452,"longitude":129.158453369505,"bookYear":null,"nameKR":"승한막창","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":37606,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wadang_ny/","foodTypes":"바/주점","latitude":37.5403410293112,"longitude":126.96771295151,"bookYear":null,"nameKR":"와당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37605,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.788729150638,"longitude":127.578378335359,"bookYear":null,"nameKR":"황제시래기명태조림 ","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":37593,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5050896664825,"longitude":127.022568226432,"bookYear":null,"nameKR":"오도로키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":37545,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_berry__bliss_/","foodTypes":"디저트/차/베이커리","latitude":37.5542887811659,"longitude":126.927391591835,"bookYear":null,"nameKR":"베리블리스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37539,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5470013514751,"longitude":126.953756813538,"bookYear":null,"nameKR":"케이트미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":28943,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vanillacloud_kr/","foodTypes":"디저트/차/베이커리","latitude":37.5101921107854,"longitude":127.040309601915,"bookYear":null,"nameKR":"바닐라클라우드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":29742,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.185529139165375,"longitude":128.78867915840755,"bookYear":null,"nameKR":"엘리멘츠","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":31840,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.2557602362849,"longitude":127.547868316748,"bookYear":null,"nameKR":"금단가든","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":26144,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.083389,"longitude":127.571747,"bookYear":null,"nameKR":"황금산장","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":37053,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.206558266721,"longitude":127.112463591778,"bookYear":null,"nameKR":"달구운바람 돼지갈비(동탄영천점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":37052,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.2433020987325,"longitude":127.055738900634,"bookYear":null,"nameKR":"달구운바람 돼지갈비(영통망포점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37603,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/byewan_okjung","foodTypes":"중식","latitude":37.8198372639068,"longitude":127.094101424507,"bookYear":null,"nameKR":"바이완","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":37600,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.559084989016,"longitude":126.946291992341,"bookYear":null,"nameKR":"소오밥집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":37599,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/continuitycoffee","foodTypes":"디저트/차/베이커리","latitude":37.5638329928714,"longitude":126.99039016539,"bookYear":null,"nameKR":"콘티뉴이티","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":37575,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4820013511178,"longitude":127.010335155166,"bookYear":null,"nameKR":"폼나는BAB","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":37591,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_soruya_","foodTypes":"일식","latitude":36.8093066095445,"longitude":127.104516492564,"bookYear":null,"nameKR":"소루야","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":37589,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/smalltalkcoffee_official","foodTypes":"디저트/차/베이커리","latitude":37.5016487665451,"longitude":127.041646620207,"bookYear":null,"nameKR":"스몰톡커피(역삼점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":37573,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jjackpot_jeonju","foodTypes":"디저트/차/베이커리","latitude":35.8381638479646,"longitude":127.165302258968,"bookYear":null,"nameKR":"짹팥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":37587,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/steadyer.kr/","foodTypes":"기타","latitude":35.1526382462308,"longitude":129.067066452337,"bookYear":null,"nameKR":"스테디어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":35498,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":36.3162029398368,"longitude":127.400506007222,"bookYear":null,"nameKR":"카페익투스","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":36620,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5598714945494,"longitude":126.926300212385,"bookYear":null,"nameKR":"카페공명","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":36621,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.549904803701,"longitude":126.9220348814,"bookYear":null,"nameKR":"카페공명(홍대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":37586,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/gateaudrev","foodTypes":"디저트/차/베이커리","latitude":37.4943125825786,"longitude":126.724913785098,"bookYear":null,"nameKR":"갸또드레브","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":37585,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/alley_nine/","foodTypes":"바/주점","latitude":37.5095770619591,"longitude":127.106273471583,"bookYear":null,"nameKR":"앨리나인","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":37524,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.7460591811561,"longitude":127.052064339333,"bookYear":null,"nameKR":"카페비비글로우","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":37581,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/less_er_roasters/","foodTypes":"디저트/차/베이커리","latitude":36.3627444733067,"longitude":127.356125424525,"bookYear":null,"nameKR":"레서","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":37584,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafelodge","foodTypes":"디저트/차/베이커리","latitude":37.5330689592107,"longitude":126.952271862319,"bookYear":null,"nameKR":"카페로찌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37583,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/amy_quivala/","foodTypes":"바/주점","latitude":37.5421884422247,"longitude":127.046806319662,"bookYear":null,"nameKR":"퀴바라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37580,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1534505195433,"longitude":129.114182601828,"bookYear":null,"nameKR":"리볼리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37578,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/esoope_eojin/","foodTypes":"기타","latitude":36.5003828147236,"longitude":127.256120421125,"bookYear":null,"nameKR":"카페이수페(어진리저브점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":37579,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/esoope_dunsan/","foodTypes":"기타","latitude":36.3528670226557,"longitude":127.38711366189,"bookYear":null,"nameKR":"카페이수페(둔산법원시청점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":37577,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gnocchi.room/","foodTypes":"이탈리아식","latitude":37.5417129532735,"longitude":127.047784679124,"bookYear":null,"nameKR":"뇨끼룸","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37576,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/erip.bongmyeong/","foodTypes":"바/주점","latitude":36.3573823048401,"longitude":127.346657019477,"bookYear":null,"nameKR":"이립","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":34601,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.489480473506,"longitude":126.994064871383,"bookYear":null,"nameKR":"라바트리 방배","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":37512,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":38.2082819825448,"longitude":127.218418733143,"bookYear":null,"nameKR":"밀크스토리","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"철원군","status":"ACTIVE"},
        {"id":37572,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/todays_dream_","foodTypes":"디저트/차/베이커리","latitude":37.1960277680291,"longitude":126.822455695036,"bookYear":null,"nameKR":"오늘의꾸움","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":37531,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.4769246468113,"longitude":127.290456391507,"bookYear":null,"nameKR":"카페이수페(본점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":37569,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/namchoncenter","foodTypes":"디저트/차/베이커리","latitude":37.4386174261654,"longitude":126.719075952406,"bookYear":null,"nameKR":"남촌회관","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":37564,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/darlin_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.5226729282529,"longitude":127.030271259035,"bookYear":null,"nameKR":"달링베이커리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37563,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.2701267244493,"longitude":128.264116776632,"bookYear":null,"nameKR":"떡매매운탕","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":37560,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1539704411162,"longitude":129.124515022228,"bookYear":null,"nameKR":"낙불집(광안리점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37035,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/kissaten_/","foodTypes":"디저트/차/베이커리","latitude":37.5001683815758,"longitude":127.041482156956,"bookYear":null,"nameKR":"킷사텐(역삼점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":37036,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.566860235203,"longitude":126.842805327669,"bookYear":null,"nameKR":"킷사텐(양천향교점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":37202,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com//ko_.wooso","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2638600827784,"longitude":128.873503431707,"bookYear":null,"nameKR":"고우소한우곰탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":37189,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/crab_dongnae_/","foodTypes":"한식(어패류)","latitude":35.2184846469816,"longitude":129.083627981867,"bookYear":null,"nameKR":"동래대게","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":35396,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4666782475743,"longitude":126.895802209581,"bookYear":null,"nameKR":"실비순대국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":35139,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/msmoriofficial/","foodTypes":"한식(민물어패류)","latitude":35.1739016949905,"longitude":129.176758910781,"bookYear":null,"nameKR":"맛소문오리불고기","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":37522,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/so_melier/","foodTypes":"한식(육류)","latitude":35.1830215612451,"longitude":129.07501029157,"bookYear":null,"nameKR":"소믈리에","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":31476,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5227679418531,"longitude":127.043405669306,"bookYear":null,"nameKR":"산다이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":37158,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.8943119004595,"longitude":128.609833506,"bookYear":null,"nameKR":"도톤","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":36554,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/starmoondon","foodTypes":"기타","latitude":36.3278096995631,"longitude":127.427427813568,"bookYear":null,"nameKR":"대전은행동별리달리돈까스","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":37247,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":36.3251974320978,"longitude":127.345863690306,"bookYear":null,"nameKR":"카페듀플렉스 ","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":36954,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sooeulkwan","foodTypes":"바/주점","latitude":37.5620385899772,"longitude":126.92546833092,"bookYear":null,"nameKR":"수을관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37133,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joyh_house/","foodTypes":"이탈리아식","latitude":37.5436279279095,"longitude":127.066434715708,"bookYear":null,"nameKR":"조용한주택","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":37102,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/title_cafechujari/","foodTypes":"디저트/차/베이커리","latitude":37.3585511477688,"longitude":127.218500014137,"bookYear":null,"nameKR":"타이틀카페추자리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":35786,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5439965080669,"longitude":126.947481225372,"bookYear":null,"nameKR":"채담화","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36914,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5046055930956,"longitude":127.044137631,"bookYear":null,"nameKR":"스몰톡커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":37381,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":36.715526801866,"longitude":127.421092319501,"bookYear":null,"nameKR":"오늘밀키친","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":37459,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.3977781643798,"longitude":126.649765851027,"bookYear":null,"nameKR":"카페썸모어","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":37556,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.2328344921104,"longitude":128.877310633256,"bookYear":null,"nameKR":"모옌","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":37555,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tonzcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5618808238258,"longitude":127.04024213354,"bookYear":null,"nameKR":"톤즈커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":37359,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.44866669532,"longitude":126.388958415683,"bookYear":null,"nameKR":"고목정쌈밥","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":37548,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kitschclub.kr","foodTypes":"이탈리아식","latitude":37.4821098225099,"longitude":127.044767382161,"bookYear":null,"nameKR":"키치클럽","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":37547,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/star_zary/","foodTypes":"디저트/차/베이커리","latitude":35.926744610382,"longitude":128.600423680459,"bookYear":null,"nameKR":"별꽃자리","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":37540,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.3258437794837,"longitude":127.423428082815,"bookYear":null,"nameKR":"콜드버터베이크샵","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":37536,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/1994dining/","foodTypes":"기타","latitude":35.8357258896885,"longitude":129.209991451912,"bookYear":null,"nameKR":"1994다이닝","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":37535,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_byoly_official/","foodTypes":"디저트/차/베이커리","latitude":35.5532967301845,"longitude":129.457775389089,"bookYear":null,"nameKR":"카페벼리","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":37534,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5599809541925,"longitude":126.922620842942,"bookYear":null,"nameKR":"소바하우스멘야준","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37533,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/menyajoon","foodTypes":"일식","latitude":37.5548927378235,"longitude":126.916386904868,"bookYear":null,"nameKR":"멘야준","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":37532,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_bluehaus","foodTypes":"디저트/차/베이커리","latitude":33.4725803438611,"longitude":126.539976363197,"bookYear":null,"nameKR":"카페블루하우스","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":31581,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dear.mond/","foodTypes":"디저트/차/베이커리","latitude":37.5200390723498,"longitude":126.795310522012,"bookYear":null,"nameKR":"디어몬드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":33405,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/boulangerie115/","foodTypes":"디저트/차/베이커리","latitude":37.6523414192885,"longitude":126.661742861569,"bookYear":null,"nameKR":"블랑제리115","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":36019,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6522168336511,"longitude":126.639540620875,"bookYear":null,"nameKR":"들내음(김포점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":20207,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.4967465122125,"longitude":126.538979416681,"bookYear":null,"nameKR":"호두파이가맛있는집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":37520,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_gyesin_/","foodTypes":"한식(가금류)","latitude":37.5794353344594,"longitude":127.022814462849,"bookYear":null,"nameKR":"계신","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":37519,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chefs_aidul","foodTypes":"디저트/차/베이커리","latitude":37.5236867472414,"longitude":126.896437475848,"bookYear":null,"nameKR":"셰프의아이들","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37518,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seongsu_onsil/","foodTypes":"한식(일반한식)","latitude":37.5468549662722,"longitude":127.046860046304,"bookYear":null,"nameKR":"성수온실","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37506,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.1985000650294,"longitude":127.076282704154,"bookYear":null,"nameKR":"쉐진","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":37517,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.7913164655468,"longitude":128.920183093486,"bookYear":null,"nameKR":"찜대감","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":37515,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5224682843316,"longitude":127.02131462145,"bookYear":null,"nameKR":"카소미야(가로수길본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37513,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5414615295878,"longitude":127.237591640214,"bookYear":null,"nameKR":"팔당원조칼제비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":37511,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/compass_coffee_official/","foodTypes":"디저트/차/베이커리","latitude":37.4537728043866,"longitude":126.705820623497,"bookYear":null,"nameKR":"콤파스커피로스터스","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":34100,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.5210989114185,"longitude":126.929781329983,"bookYear":null,"nameKR":"설계도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":35371,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3714689708242,"longitude":127.129752700097,"bookYear":null,"nameKR":"휴고스커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35700,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.8726467292822,"longitude":127.70769343784,"bookYear":null,"nameKR":"화람한우","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":36770,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/uuuu_cafe","foodTypes":"디저트/차/베이커리","latitude":35.6136694479666,"longitude":129.450618933715,"bookYear":null,"nameKR":"uuuu","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":36000,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.2356694064218,"longitude":126.312482417814,"bookYear":null,"nameKR":"순천미향(제주산방산본점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":35811,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/songjung_no1/","foodTypes":"한식(면류)","latitude":35.1763321166419,"longitude":129.196628281737,"bookYear":null,"nameKR":"송정물총칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":12861,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.557626,"longitude":126.937917,"bookYear":null,"nameKR":"미네르바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":37505,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":33.2552105008869,"longitude":126.560045889923,"bookYear":null,"nameKR":"마시오름","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":37503,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_ovenus/","foodTypes":"디저트/차/베이커리","latitude":35.1555560269307,"longitude":129.132722588383,"bookYear":null,"nameKR":"오브너스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37501,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/buukgan","foodTypes":"기타","latitude":34.7273143778512,"longitude":127.748969371704,"bookYear":null,"nameKR":"부엌간(여수점)","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":37500,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/buukgan/","foodTypes":"기타","latitude":35.1144427469276,"longitude":126.896907340333,"bookYear":null,"nameKR":"부엌간(진월점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":36538,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.2637062771407,"longitude":128.622770489344,"bookYear":null,"nameKR":"오우가","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":33802,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thumbup.busan/","foodTypes":"한식(가금류)","latitude":35.2067461074039,"longitude":129.08120669181,"bookYear":null,"nameKR":"엄지척닭도리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":34096,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dowon_uk/","foodTypes":"한식(육류)","latitude":35.3085852082965,"longitude":128.735411767472,"bookYear":null,"nameKR":"진영도원갈비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":35765,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myung_baeck_house_osan/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.1607414331868,"longitude":127.077699871446,"bookYear":null,"nameKR":"명백집(오산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":35815,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/phovua.ilsan/","foodTypes":"아시아식","latitude":37.6706831492438,"longitude":126.781830150864,"bookYear":null,"nameKR":"퍼부어(밤리단길점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35889,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wolhwa_sikdang_songdo/","foodTypes":"한식(육류)","latitude":37.3940175669476,"longitude":126.646006981957,"bookYear":null,"nameKR":"월화식당(송도센트럴파크점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":35893,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wolhwasikdang_jeonju/","foodTypes":"한식(육류)","latitude":35.8372661121029,"longitude":127.057250602683,"bookYear":null,"nameKR":"월화식당(전주혁신도시점)","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":35938,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_____doyeony/","foodTypes":"한식(어패류)","latitude":37.5824673520379,"longitude":126.864940868128,"bookYear":null,"nameKR":"해담장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":36129,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lebien_wine/","foodTypes":"바/주점","latitude":35.1540963760343,"longitude":126.887547970071,"bookYear":null,"nameKR":"르비엔","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":36590,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5985342802709,"longitude":126.890213054012,"bookYear":null,"nameKR":"리비토","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":35792,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dj_woohwa","foodTypes":"한식(육류)","latitude":36.3519150688496,"longitude":127.3751474276,"bookYear":null,"nameKR":"우화곱창","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":35890,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wolhwa_sikdang_pyeongtaek/","foodTypes":"한식(육류)","latitude":37.0418311700926,"longitude":127.053193190939,"bookYear":null,"nameKR":"월화식당(평택고덕점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":35982,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/diggi_ty/","foodTypes":"이탈리아식","latitude":37.5075594169997,"longitude":126.728071813482,"bookYear":null,"nameKR":"디기티","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":35994,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.3294142603243,"longitude":129.172546808698,"bookYear":null,"nameKR":"흑송(2호점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":36009,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vinitus_kr/","foodTypes":"유럽식","latitude":37.4707175083074,"longitude":127.142268729817,"bookYear":null,"nameKR":"비니투스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":36021,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mirinae_pig","foodTypes":"한식(육류)","latitude":37.5025495906564,"longitude":126.771605866261,"bookYear":null,"nameKR":"미리내","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":36054,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_bavarder2/","foodTypes":"디저트/차/베이커리","latitude":36.6710813070025,"longitude":127.496978911736,"bookYear":null,"nameKR":"바바르디(율량점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36068,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dadam_boulangerie/","foodTypes":"디저트/차/베이커리","latitude":35.1952384059756,"longitude":129.090472779539,"bookYear":null,"nameKR":"다담블랑제리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":36121,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/butterful_cremorous_pohang/","foodTypes":"디저트/차/베이커리","latitude":36.064752178267,"longitude":129.38575954056,"bookYear":null,"nameKR":"버터풀앤크리멀러스(포항영일대점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":36122,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/iny_geoje/","foodTypes":"중식","latitude":34.8946475594655,"longitude":128.694427984913,"bookYear":null,"nameKR":"아이니(거제본점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":36251,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7651805316343,"longitude":127.092535395453,"bookYear":null,"nameKR":"솔가원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":36253,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3892363233087,"longitude":126.665127593356,"bookYear":null,"nameKR":"참피온삼겹살","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":36434,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/songdo_babo/","foodTypes":"한식(일반한식)","latitude":37.3793159725333,"longitude":126.657657591796,"bookYear":null,"nameKR":"송도춘향전","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":10334,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":33.487368,"longitude":126.482664,"bookYear":null,"nameKR":"우림일식","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":32839,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daeheung.hanu/","foodTypes":"한식(육류)","latitude":37.4894651292957,"longitude":127.987314969834,"bookYear":null,"nameKR":"대흥정육식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":36161,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.3475963965473,"longitude":127.951260898583,"bookYear":null,"nameKR":"원주장진우식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":37498,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/buukgan","foodTypes":"기타","latitude":35.149239919963,"longitude":126.927430025787,"bookYear":null,"nameKR":"부엌간(동명점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":37502,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/buukgan","foodTypes":"기타","latitude":35.1237810324252,"longitude":126.868629481525,"bookYear":null,"nameKR":"부엌간(풍암점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":37497,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/buukgan/","foodTypes":"기타","latitude":35.1294957897872,"longitude":126.847697198786,"bookYear":null,"nameKR":"부엌간 ","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":37494,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.2910333582533,"longitude":127.066670743351,"bookYear":null,"nameKR":"더차이(광교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37490,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dbb_kbox/","foodTypes":"한식(면류)","latitude":37.2716897982299,"longitude":126.563209091735,"bookYear":null,"nameKR":"디비비칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":37488,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3810206955032,"longitude":126.660813360073,"bookYear":null,"nameKR":"고집132","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":37486,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aewol_mong/","foodTypes":"한식(육류)","latitude":37.3810206955032,"longitude":126.660813360073,"bookYear":null,"nameKR":"애월몽(송도본점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":37485,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.2719450972031,"longitude":127.249147498059,"bookYear":null,"nameKR":"수미계닭갈비","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"계룡시","status":"ACTIVE"},
        {"id":37412,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.8097264672442,"longitude":127.101780520107,"bookYear":null,"nameKR":"헥스커피로스터스","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":33812,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hoyoon_since2020/","foodTypes":"바/주점","latitude":37.5016876100662,"longitude":126.772357839045,"bookYear":null,"nameKR":"호윤","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":34178,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwaroyangmi/","foodTypes":"한식(육류)","latitude":37.2959845408428,"longitude":127.069980570607,"bookYear":null,"nameKR":"화로양미","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":33799,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.4950184425127,"longitude":126.724648733337,"bookYear":null,"nameKR":"파스타바빌라","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":32066,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2313887204965,"longitude":129.009438763052,"bookYear":null,"nameKR":"화명옥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":34262,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/brownfield_roasters","foodTypes":"디저트/차/베이커리","latitude":34.8932481897335,"longitude":128.689740220799,"bookYear":null,"nameKR":"브라운필드","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":34562,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/duck4715292/","foodTypes":"한식(가금류)","latitude":36.0956297021854,"longitude":128.451626337527,"bookYear":null,"nameKR":"천생산오리사냥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":37483,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.6466117749406,"longitude":127.017099051043,"bookYear":null,"nameKR":"섬진강1984민물장어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":30367,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.278447405750285,"longitude":126.85047482281983,"bookYear":null,"nameKR":"농협안심한우나주곰탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":35851,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/grain_square","foodTypes":"디저트/차/베이커리","latitude":37.2307715119992,"longitude":127.186268085276,"bookYear":null,"nameKR":"그레인스퀘어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":35853,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jungang.dakgangjeong/","foodTypes":"한식(가금류)","latitude":38.1961651305965,"longitude":128.581010555104,"bookYear":null,"nameKR":"중앙닭강정(엑스포직영점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":35956,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5736063679023,"longitude":126.990455924572,"bookYear":null,"nameKR":"아트몬스터(익선동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":36144,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/miikflo/","foodTypes":"디저트/차/베이커리","latitude":37.5698707651112,"longitude":127.001696456151,"bookYear":null,"nameKR":"미크플로(광장시장점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":36157,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5281783139269,"longitude":126.875771989415,"bookYear":null,"nameKR":"쿠이대식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":36174,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5250909341112,"longitude":127.128032117228,"bookYear":null,"nameKR":"리애프리미엄(올림픽공원직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36177,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.294492236938,"longitude":127.021988962123,"bookYear":null,"nameKR":"얼큰명가찌개마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36238,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.7531898155774,"longitude":127.113773313956,"bookYear":null,"nameKR":"날라리식당(의정부민락직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":36378,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/eoneudal","foodTypes":"기타","latitude":37.8469274363599,"longitude":127.223895260211,"bookYear":null,"nameKR":"어느달","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":36393,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/spain_deliciosa","foodTypes":"유럽식","latitude":37.5589090959127,"longitude":126.824795871557,"bookYear":null,"nameKR":"맛있는스페인","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":36752,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.3081170712656,"longitude":126.830650449765,"bookYear":null,"nameKR":"서봉수참치앤스시","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36948,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/like_seongsu","foodTypes":"기타","latitude":37.5475441473972,"longitude":127.055376778184,"bookYear":null,"nameKR":"성수로운","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":6995,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4880695220981,"longitude":127.053483219997,"bookYear":null,"nameKR":"카페톨릭스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":29386,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.74214841440255,"longitude":127.02874093135836,"bookYear":null,"nameKR":"금미옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":37421,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4950254499168,"longitude":127.031861565371,"bookYear":null,"nameKR":"가가솥밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":37420,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5569492678443,"longitude":126.9857468875,"bookYear":null,"nameKR":"101번지남산돈까스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":37482,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5552996510825,"longitude":126.925433055758,"bookYear":null,"nameKR":"증증일상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37484,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4962144781686,"longitude":127.039427723148,"bookYear":null,"nameKR":"마포짚불곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":37479,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4852789881319,"longitude":127.015712528164,"bookYear":null,"nameKR":"달미옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":37478,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/arc__bakery","foodTypes":"디저트/차/베이커리","latitude":35.1313005627606,"longitude":128.706352028528,"bookYear":null,"nameKR":"아크","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":37475,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5219248457278,"longitude":127.021556323194,"bookYear":null,"nameKR":"록키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37473,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakeshop_ohpie","foodTypes":"디저트/차/베이커리","latitude":37.3197711389899,"longitude":127.108964880448,"bookYear":null,"nameKR":"오!파이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":34843,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.6138393830212,"longitude":127.077557139207,"bookYear":null,"nameKR":"임금돼지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":37455,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.1228213965729,"longitude":128.347329730289,"bookYear":null,"nameKR":"홍가추어탕","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":34868,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5003198777346,"longitude":126.9324385059,"bookYear":null,"nameKR":"옛골","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":4273,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.424723,"longitude":127.420286,"bookYear":null,"nameKR":"장수촌","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":37471,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baemal_tot/","foodTypes":"한식(면류)","latitude":35.5508497431831,"longitude":129.289567210697,"bookYear":null,"nameKR":"맨날국수(태화강직영점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":37458,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/4mazama/","foodTypes":"바/주점","latitude":35.1636179583467,"longitude":128.985160908082,"bookYear":null,"nameKR":"사마자마","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":37457,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/grsbulgogi/","foodTypes":"한식(육류)","latitude":34.9796055632754,"longitude":127.575239696599,"bookYear":null,"nameKR":"그러소","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":37318,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":37.6552881500789,"longitude":126.629585094546,"bookYear":null,"nameKR":"홀로피자","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":37444,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/olta_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.4024088142403,"longitude":126.908685421214,"bookYear":null,"nameKR":"올타커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":32849,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/no_morecoffee/","foodTypes":"디저트/차/베이커리","latitude":35.8628231059197,"longitude":128.596112427441,"bookYear":null,"nameKR":"노모어커피","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":37066,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4297945568098,"longitude":126.990295584667,"bookYear":null,"nameKR":"동양솥밥(과천점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":37351,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.2113646480068,"longitude":127.470776564946,"bookYear":null,"nameKR":"커피하우스렌토","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":35239,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.1534200225381,"longitude":129.055729765553,"bookYear":null,"nameKR":"야키토리 규","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":37453,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5203213728966,"longitude":126.928474727766,"bookYear":null,"nameKR":"천청돈감","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":37393,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4003659710472,"longitude":126.65076958299,"bookYear":null,"nameKR":"연두콩","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":25298,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yukjeon_sikdang.official/","foodTypes":"한식(육류)","latitude":37.574238,"longitude":127.024297,"bookYear":null,"nameKR":"육전식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":37435,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4926553975306,"longitude":127.009418764458,"bookYear":null,"nameKR":"마로우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":37451,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gtsburger_gg","foodTypes":"기타","latitude":37.2936273607612,"longitude":127.052726325172,"bookYear":null,"nameKR":"GTS버거(광교카페거리점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37450,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3604255934844,"longitude":127.114522908818,"bookYear":null,"nameKR":"GTS버거(분당정자점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37449,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.6601032953297,"longitude":127.073977711215,"bookYear":null,"nameKR":"미미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":37441,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.3283614945831,"longitude":126.844239273087,"bookYear":null,"nameKR":"라냐","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":37440,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/taly_juteak","foodTypes":"기타","latitude":37.2853059657191,"longitude":127.011990009663,"bookYear":null,"nameKR":"태리주택(행궁점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37330,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"컨템포러리","latitude":37.5629356638718,"longitude":127.189821606582,"bookYear":null,"nameKR":"유포리아","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":37138,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.403689022527,"longitude":127.38230081183,"bookYear":null,"nameKR":"은달래","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":37242,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/once.timeoff_official/","foodTypes":"디저트/차/베이커리","latitude":35.8213534091342,"longitude":127.141418572082,"bookYear":null,"nameKR":"원스타임오프쇼룸","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":35147,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bangujeoljeom/","foodTypes":"바/주점","latitude":35.8170484713042,"longitude":127.144882161691,"bookYear":null,"nameKR":"반구절점","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":35281,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/early_coffee0501/","foodTypes":"디저트/차/베이커리","latitude":35.9867490837825,"longitude":126.718836279313,"bookYear":null,"nameKR":"이른아침커피공장","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":35493,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/man_dong1/","foodTypes":"한식(일반한식)","latitude":35.848618502381,"longitude":127.075992770189,"bookYear":null,"nameKR":"만동","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":35750,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.8201406872552,"longitude":127.148068770937,"bookYear":null,"nameKR":"해율담","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":35852,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.5425504446865,"longitude":126.566099381612,"bookYear":null,"nameKR":"작은항구","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":35876,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/50end_pizza/","foodTypes":"이탈리아식","latitude":35.8431738618065,"longitude":127.125511767211,"bookYear":null,"nameKR":"피프티엔드피자","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":36204,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hausment.official/","foodTypes":"디저트/차/베이커리","latitude":35.8212292595498,"longitude":127.10267370608,"bookYear":null,"nameKR":"하우스먼트","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":36462,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/katsominu","foodTypes":"일식","latitude":35.8127145974721,"longitude":127.115768039125,"bookYear":null,"nameKR":"카츠소바미누","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":36864,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mymycake_","foodTypes":"디저트/차/베이커리","latitude":35.8400560461551,"longitude":127.124217803727,"bookYear":null,"nameKR":"마이마이케이크","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":36913,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ado_shelter","foodTypes":"디저트/차/베이커리","latitude":35.8218728584002,"longitude":127.130785084554,"bookYear":null,"nameKR":"아도","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":37014,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8203975819241,"longitude":127.146642854661,"bookYear":null,"nameKR":"여덟개의달","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":33567,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9583394373004,"longitude":126.705006621197,"bookYear":null,"nameKR":"뽀빠이갈비","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":33570,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.987067746448,"longitude":126.710606048672,"bookYear":null,"nameKR":"희락일식","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":33603,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/guum.shop/","foodTypes":"디저트/차/베이커리","latitude":35.8647876743434,"longitude":127.134583849292,"bookYear":null,"nameKR":"굼","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":33644,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/junghan_porklab/","foodTypes":"한식(육류)","latitude":35.9603310291793,"longitude":126.713429509444,"bookYear":null,"nameKR":"정한식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":33730,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.8737048157555,"longitude":127.127423761458,"bookYear":null,"nameKR":"류가중식","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":33774,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9605848192552,"longitude":126.68990131733,"bookYear":null,"nameKR":"내갈비","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":33876,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/official_seolwoo/","foodTypes":"한식(육류)","latitude":35.8217584134655,"longitude":127.102961663083,"bookYear":null,"nameKR":"프리미엄한우설우","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":33893,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ccino.restaurant/","foodTypes":"이탈리아식","latitude":35.8163636603573,"longitude":127.140968437518,"bookYear":null,"nameKR":"치노식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":33982,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didcoffee_/","foodTypes":"디저트/차/베이커리","latitude":35.8213977417551,"longitude":127.141273243072,"bookYear":null,"nameKR":"디드","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":34122,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.9559787025436,"longitude":126.698823466187,"bookYear":null,"nameKR":"파라디소페르두또","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":34186,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.774371510244,"longitude":127.100607681764,"bookYear":null,"nameKR":"뜰안","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":34237,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.9364890846597,"longitude":126.961386445169,"bookYear":null,"nameKR":"철낙","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":34477,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/us_myeon/","foodTypes":"일식","latitude":35.9457305386093,"longitude":126.957453899524,"bookYear":null,"nameKR":"우스면","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":34488,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.9604540406171,"longitude":126.936323538657,"bookYear":null,"nameKR":"아비토하우스","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":34566,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8171509022051,"longitude":127.124139954999,"bookYear":null,"nameKR":"동상집흑염소전문점","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":34611,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tight_coffee_company/","foodTypes":"디저트/차/베이커리","latitude":35.8195268101766,"longitude":127.116480716087,"bookYear":null,"nameKR":"타이트커피컴퍼니","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":34626,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8503733019422,"longitude":127.123749024126,"bookYear":null,"nameKR":"카페그곳","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":34766,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8288556564563,"longitude":127.174919596862,"bookYear":null,"nameKR":"우백갈비","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":32428,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8697504402954,"longitude":127.077484843526,"bookYear":null,"nameKR":"뽀빠이순대","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":32673,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8187504102413,"longitude":127.14284972528,"bookYear":null,"nameKR":"듀숑","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":32675,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kitchenhygge/","foodTypes":"기타","latitude":35.9698202068669,"longitude":126.736584113308,"bookYear":null,"nameKR":"키친후가","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":32788,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8050162042187,"longitude":127.101498000788,"bookYear":null,"nameKR":"라따블데뤼땡","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":32825,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kkotbunine59","foodTypes":"한식(육류)","latitude":35.8203124956088,"longitude":127.138362542943,"bookYear":null,"nameKR":"꽃분이네","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":32833,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.9642607651482,"longitude":126.71030848898,"bookYear":null,"nameKR":"쉐프테라스","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":32884,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8697810047072,"longitude":127.13568030126,"bookYear":null,"nameKR":"진가진설렁탕","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":32919,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8172220180912,"longitude":127.15227585741,"bookYear":null,"nameKR":"고궁수라간(한옥마을점)","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":33036,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeebrew.8th/","foodTypes":"디저트/차/베이커리","latitude":35.7927185940716,"longitude":127.431228256573,"bookYear":null,"nameKR":"커피브루","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"진안군","status":"ACTIVE"},
        {"id":33045,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.8448371953015,"longitude":127.07263251038,"bookYear":null,"nameKR":"오복스시","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":33141,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seulji_bakery1999/","foodTypes":"디저트/차/베이커리","latitude":35.5947132014892,"longitude":126.618264818688,"bookYear":null,"nameKR":"슬지네찐빵슬지제빵소","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":33181,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_cafe_villain/","foodTypes":"디저트/차/베이커리","latitude":35.8153317082521,"longitude":127.144092307862,"bookYear":null,"nameKR":"빌런브루잉&에스프레소바","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":33251,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.9837630132083,"longitude":126.850997201116,"bookYear":null,"nameKR":"명인 ","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":33566,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.9620696657275,"longitude":126.694741020833,"bookYear":null,"nameKR":"진호수산","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":31261,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.0078959394719,"longitude":127.661471424728,"bookYear":null,"nameKR":"아이빈커피","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"무주군","status":"ACTIVE"},
        {"id":31648,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/old_stuff_pd","foodTypes":"디저트/차/베이커리","latitude":35.823143637366,"longitude":127.102237504142,"bookYear":null,"nameKR":"올드스터프","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":31649,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/c.flatcafeteria","foodTypes":"디저트/차/베이커리","latitude":35.8215888353842,"longitude":127.104067497003,"bookYear":null,"nameKR":"씨플랫","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":31751,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.9901533122507,"longitude":126.712487760353,"bookYear":null,"nameKR":"미즈커피","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":31754,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dubhe_soyang","foodTypes":"디저트/차/베이커리","latitude":35.9036812149575,"longitude":127.243087529956,"bookYear":null,"nameKR":"두베카페","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":31847,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8378133267248,"longitude":127.100540623146,"bookYear":null,"nameKR":"임금님설렁탕","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":31867,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffee_groove/","foodTypes":"디저트/차/베이커리","latitude":35.9043195210871,"longitude":127.095283182645,"bookYear":null,"nameKR":"커피그루브","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":32152,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dalcom_couple","foodTypes":"디저트/차/베이커리","latitude":35.8350750832946,"longitude":127.102721314638,"bookYear":null,"nameKR":"달콤한부부","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29568,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.81241273199376,"longitude":127.14660405109117,"bookYear":null,"nameKR":"옛날피순대","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29655,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.823299896832516,"longitude":127.14366715451203,"bookYear":null,"nameKR":"그때산집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29657,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/buta_n_joodorack_n_track9/","foodTypes":"바/주점","latitude":35.821205613513484,"longitude":127.09651333889299,"bookYear":null,"nameKR":"부타","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29660,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ssunine_jib/","foodTypes":"이탈리아식","latitude":35.818317228492184,"longitude":127.10997447476328,"bookYear":null,"nameKR":"써니네집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29664,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":35.82089942177913,"longitude":127.14326445448619,"bookYear":null,"nameKR":"가마","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29667,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.846559648874,"longitude":127.12063577216834,"bookYear":null,"nameKR":"늘채움","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29672,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.8347434769056,"longitude":127.16253417064975,"bookYear":null,"nameKR":"착한밥상","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29675,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.79143879107894,"longitude":127.1307647349436,"bookYear":null,"nameKR":"황금옥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29676,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.817702597250864,"longitude":127.14850819219963,"bookYear":null,"nameKR":"이래면옥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29719,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.50873316916156,"longitude":126.59886160792897,"bookYear":null,"nameKR":"서해안식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":29424,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.818729,"longitude":127.139961,"bookYear":null,"nameKR":"초장집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":29443,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.821559200546346,"longitude":127.14321694936433,"bookYear":null,"nameKR":"리얼커피","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":25486,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.817745,"longitude":127.140816,"bookYear":null,"nameKR":"한미반점","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":25487,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.992216,"longitude":126.864444,"bookYear":null,"nameKR":"미소한우","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":25488,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.408717,"longitude":127.384189,"bookYear":null,"nameKR":"동춘원","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":25490,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.9238547913139,"longitude":126.644278847977,"bookYear":null,"nameKR":"제일원","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":25492,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.4161772733636,"longitude":127.398069252998,"bookYear":null,"nameKR":"취홍객잔","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":25494,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.886542,"longitude":126.895506,"bookYear":null,"nameKR":"명천식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"김제시","status":"ACTIVE"},
        {"id":25495,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.382212,"longitude":127.490907,"bookYear":null,"nameKR":"호랑골가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":25496,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.812679,"longitude":127.146449,"bookYear":null,"nameKR":"엄마손해장국","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":25721,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.6468925105167,"longitude":127.519881147814,"bookYear":null,"nameKR":"장수한우명품관","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"장수군","status":"ACTIVE"},
        {"id":26051,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ny_garden_/","foodTypes":"한식(가금류)","latitude":36.096317,"longitude":127.321417,"bookYear":null,"nameKR":"남양가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":26356,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.4332517624974,"longitude":126.696790891194,"bookYear":null,"nameKR":"나래궁","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":26391,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.985387,"longitude":126.716563,"bookYear":null,"nameKR":"장미칼국수","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":26700,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.893201,"longitude":127.311683,"bookYear":null,"nameKR":"한백상회","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":25450,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.952364,"longitude":126.939411,"bookYear":null,"nameKR":"이조참치","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":25452,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.958003,"longitude":126.977441,"bookYear":null,"nameKR":"청목","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":25455,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.959915,"longitude":126.982665,"bookYear":null,"nameKR":"토담","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":25475,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.9634165644522,"longitude":126.957429337773,"bookYear":null,"nameKR":"더플레이트","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":25481,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.9580525804485,"longitude":126.978373911566,"bookYear":null,"nameKR":"백제갈비","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":24874,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.96771,"longitude":126.69501,"bookYear":null,"nameKR":"온새미로","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":24934,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.56497481818378,"longitude":126.85588205162513,"bookYear":null,"nameKR":"솜씨만두","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":25147,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.797523,"longitude":126.886199,"bookYear":null,"nameKR":"섬진강","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"김제시","status":"ACTIVE"},
        {"id":25152,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.461538,"longitude":127.602613,"bookYear":null,"nameKR":"장터순대국","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":22423,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.837392,"longitude":127.170943,"bookYear":null,"nameKR":"성완각","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":23151,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.728924,"longitude":127.581733,"bookYear":null,"nameKR":"양지가마솥순대국밥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"장수군","status":"ACTIVE"},
        {"id":23177,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.79788,"longitude":126.892847,"bookYear":null,"nameKR":"옛날시골찐빵","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"김제시","status":"ACTIVE"},
        {"id":23205,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.614401,"longitude":127.283139,"bookYear":null,"nameKR":"초원식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"임실군","status":"ACTIVE"},
        {"id":23207,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.614401,"longitude":127.283139,"bookYear":null,"nameKR":"성수식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"임실군","status":"ACTIVE"},
        {"id":23372,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.939376,"longitude":126.948032,"bookYear":null,"nameKR":"소강불고기","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":23514,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.834729,"longitude":127.165845,"bookYear":null,"nameKR":"겐돈소바","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":23532,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.820008,"longitude":127.14812,"bookYear":null,"nameKR":"영동슈퍼","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":23590,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.587054,"longitude":126.608291,"bookYear":null,"nameKR":"자매식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":23804,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.819423,"longitude":127.122022,"bookYear":null,"nameKR":"예우랑","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":23817,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.818446,"longitude":127.117734,"bookYear":null,"nameKR":"누룩꽃피는날(중화산점)","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":23903,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.828901,"longitude":127.115327,"bookYear":null,"nameKR":"전복궁","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":24190,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.955938,"longitude":126.698463,"bookYear":null,"nameKR":"산타로사","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":24294,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.9686115751926,"longitude":126.704544798526,"bookYear":null,"nameKR":"중동호떡(2호점)","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":21221,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.822431,"longitude":127.149268,"bookYear":null,"nameKR":"백일홍","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":21331,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.77474454789719,"longitude":127.41696244993402,"bookYear":null,"nameKR":"국태가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"진안군","status":"ACTIVE"},
        {"id":21338,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.401902,"longitude":127.388294,"bookYear":null,"nameKR":"두부마을","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"남원시","status":"ACTIVE"},
        {"id":21982,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.939293,"longitude":126.532521,"bookYear":null,"nameKR":"새만금횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":22414,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.978935,"longitude":126.858841,"bookYear":null,"nameKR":"서수해장국","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":22415,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.963649,"longitude":126.695075,"bookYear":null,"nameKR":"비행장부대찌개","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":18196,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.808649233792,"longitude":127.103563564222,"bookYear":null,"nameKR":"맘스브레드","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":18365,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.809459,"longitude":127.152932,"bookYear":null,"nameKR":"맛자랑팥고향집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":18820,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.93298,"longitude":126.955988,"bookYear":null,"nameKR":"일해옥(익산점)","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":18890,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.982953,"longitude":126.752771,"bookYear":null,"nameKR":"논두렁쌈밥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":16137,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.812408,"longitude":127.146599,"bookYear":null,"nameKR":"운암콩나물국밥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":17425,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.807143,"longitude":127.119599,"bookYear":null,"nameKR":"완산한우곱창","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":17560,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.792092,"longitude":127.431862,"bookYear":null,"nameKR":"한일관","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"진안군","status":"ACTIVE"},
        {"id":13256,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.776839,"longitude":127.102264,"bookYear":null,"nameKR":"덕암청국장","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":13264,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.855203,"longitude":127.136289,"bookYear":null,"nameKR":"비빔소리","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":13275,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.866656,"longitude":127.115012,"bookYear":null,"nameKR":"족발타령","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":13279,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.819316,"longitude":127.152508,"bookYear":null,"nameKR":"장가네왕족발","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":13304,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.831856,"longitude":127.163921,"bookYear":null,"nameKR":"옛촌막걸리(인후동점)","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":13345,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.817364,"longitude":127.123421,"bookYear":null,"nameKR":"메밀방앗간","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":13346,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.7985887814487,"longitude":126.891812933878,"bookYear":null,"nameKR":"부부닭내장전문점","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"김제시","status":"ACTIVE"},
        {"id":13371,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.813224,"longitude":127.144889,"bookYear":null,"nameKR":"풍남피순대","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":13982,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.843793,"longitude":127.125861,"bookYear":null,"nameKR":"청양농장","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":16020,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.4405268384,"longitude":126.69369565932,"bookYear":null,"nameKR":"웰빙전복","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10315,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.3753493829178,"longitude":126.542907639974,"bookYear":null,"nameKR":"보리나라학원농장","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10316,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.4943217524505,"longitude":126.92690568493,"bookYear":null,"nameKR":"한국관","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":10320,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.4932300636165,"longitude":126.929133989332,"bookYear":null,"nameKR":"한일회관","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":10325,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.507723,"longitude":126.60561,"bookYear":null,"nameKR":"해주가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":12448,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.940264,"longitude":126.533798,"bookYear":null,"nameKR":"바지락명가장가네","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":13182,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.96614,"longitude":127.013208,"bookYear":null,"nameKR":"명장추어탕","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"익산시","status":"ACTIVE"},
        {"id":10283,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.462549,"longitude":126.627808,"bookYear":null,"nameKR":"인천가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10285,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.435024,"longitude":126.711271,"bookYear":null,"nameKR":"장수버섯마을","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10290,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.505021,"longitude":126.599125,"bookYear":null,"nameKR":"정자나무집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10297,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.503056,"longitude":126.585846,"bookYear":null,"nameKR":"진흥가","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10298,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.503685,"longitude":126.59711,"bookYear":null,"nameKR":"참조은집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10300,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.500073,"longitude":126.61189,"bookYear":null,"nameKR":"청림정금자할매집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10302,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.503416,"longitude":126.595905,"bookYear":null,"nameKR":"청원가든풍천장어","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10246,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.435976,"longitude":126.713823,"bookYear":null,"nameKR":"송원식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10265,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.5399,"longitude":126.741064,"bookYear":null,"nameKR":"오복식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10270,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.4456979034707,"longitude":126.436323334135,"bookYear":null,"nameKR":"용궁횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10271,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.503759,"longitude":126.595636,"bookYear":null,"nameKR":"우리회관","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10272,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.434712,"longitude":126.700719,"bookYear":null,"nameKR":"우성회관","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10276,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.5042187941066,"longitude":126.59537070742,"bookYear":null,"nameKR":"유달식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10212,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.446338,"longitude":126.436292,"bookYear":null,"nameKR":"만선횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10216,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.576706,"longitude":126.852522,"bookYear":null,"nameKR":"명성쌈밥","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":10220,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.504584,"longitude":126.591002,"bookYear":null,"nameKR":"물레방아","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10224,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.5158763374188,"longitude":126.483845441965,"bookYear":null,"nameKR":"바다마을","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10232,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.406571,"longitude":126.681704,"bookYear":null,"nameKR":"산수가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10236,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.5110660451033,"longitude":126.891330798761,"bookYear":null,"nameKR":"서래원","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":10237,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.447049,"longitude":126.442674,"bookYear":null,"nameKR":"서해바다","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10240,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.503229,"longitude":126.585607,"bookYear":null,"nameKR":"선운식당","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10245,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.504469,"longitude":126.592548,"bookYear":null,"nameKR":"초원풍천장어","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":9716,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.571115,"longitude":126.846415,"bookYear":null,"nameKR":"비원","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":9721,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.663426,"longitude":126.525241,"bookYear":null,"nameKR":"호수가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":10182,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.446868,"longitude":126.436605,"bookYear":null,"nameKR":"가막도횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10185,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.521968,"longitude":126.596502,"bookYear":null,"nameKR":"갯벌횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":10189,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.435982,"longitude":126.700281,"bookYear":null,"nameKR":"군산아구찜","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":9649,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.679328,"longitude":126.719534,"bookYear":null,"nameKR":"주산가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9655,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.724661,"longitude":126.735075,"bookYear":null,"nameKR":"맛동산화로구이","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9678,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.7118889275413,"longitude":126.728541670823,"bookYear":null,"nameKR":"칠산아구찜","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9680,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.727755,"longitude":126.737909,"bookYear":null,"nameKR":"아향","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9708,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.49328,"longitude":126.929602,"bookYear":null,"nameKR":"명인관","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":9710,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.56857,"longitude":126.851635,"bookYear":null,"nameKR":"다복정","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":9711,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.579425,"longitude":126.858079,"bookYear":null,"nameKR":"마포통갈비","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":9491,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.716876,"longitude":126.718402,"bookYear":null,"nameKR":"제일칡냉면","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9546,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.627437,"longitude":126.472072,"bookYear":null,"nameKR":"격포채석강횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9547,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.6234902526161,"longitude":126.470295277402,"bookYear":null,"nameKR":"격포항횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9551,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.6237565772182,"longitude":126.470725240177,"bookYear":null,"nameKR":"변산반도횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9554,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.628212,"longitude":126.4722,"bookYear":null,"nameKR":"새전주횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9556,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leodo_buan/","foodTypes":"한식(어패류)","latitude":35.623865874084,"longitude":126.470657294785,"bookYear":null,"nameKR":"이어도동충하초꽃게장","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9557,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.6292520579775,"longitude":126.471450955209,"bookYear":null,"nameKR":"전주그린횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9561,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.6234902526161,"longitude":126.470295277402,"bookYear":null,"nameKR":"현대횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9584,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.724494,"longitude":126.735052,"bookYear":null,"nameKR":"부안횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":9618,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.5800510803472,"longitude":126.855051754356,"bookYear":null,"nameKR":"마리서사","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"정읍시","status":"ACTIVE"},
        {"id":7448,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.8272010859993,"longitude":127.11827634606,"bookYear":null,"nameKR":"성미당(서신점)","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":6414,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.818189,"longitude":127.151741,"bookYear":null,"nameKR":"송정원","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시(한옥마을)","status":"ACTIVE"},
        {"id":4406,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.4363645844718,"longitude":126.71532036784214,"bookYear":null,"nameKR":"우진갯벌풍천장어","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":4407,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.528946,"longitude":126.556298,"bookYear":null,"nameKR":"우리수산풍천장어","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":3310,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.998841,"longitude":127.606689,"bookYear":null,"nameKR":"동춘강변가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"무주군","status":"ACTIVE"},
        {"id":3599,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.370629,"longitude":127.143424,"bookYear":null,"nameKR":"원조옥천골한정식","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"순창군","status":"ACTIVE"},
        {"id":4006,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.812969,"longitude":127.124892,"bookYear":null,"nameKR":"흙집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":4008,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.73017541015846,"longitude":127.59155739173698,"bookYear":null,"nameKR":"청기와집가든","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"장수군","status":"ACTIVE"},
        {"id":2551,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.72539,"longitude":127.112269,"bookYear":null,"nameKR":"풍경소리","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"완주군","status":"ACTIVE"},
        {"id":2558,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.638405,"longitude":126.488856,"bookYear":null,"nameKR":"해변촌탈아리궁","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"부안군","status":"ACTIVE"},
        {"id":2515,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.916995,"longitude":126.660487,"bookYear":null,"nameKR":"하제고향횟집","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"군산시","status":"ACTIVE"},
        {"id":2530,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.503883,"longitude":126.58553,"bookYear":null,"nameKR":"산장회관","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"고창군","status":"ACTIVE"},
        {"id":2532,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.817698,"longitude":127.141084,"bookYear":null,"nameKR":"삼일관","ribbonType":"정보없음","zone2_1":"전북","zone2_2":"전주시","status":"ACTIVE"},
        {"id":36472,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"미국식","latitude":37.5569164654944,"longitude":126.925392965,"bookYear":null,"nameKR":"레이몬드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":37417,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1643021689821,"longitude":129.157614376608,"bookYear":null,"nameKR":"듀플릿","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":37433,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.5080282989335,"longitude":126.487105160011,"bookYear":null,"nameKR":"천북댁수산2","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":37439,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3245785861802,"longitude":126.888505724091,"bookYear":null,"nameKR":"디마인커피로스터즈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":37438,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.8698246263777,"longitude":128.592881616846,"bookYear":null,"nameKR":"동명오코노미야끼(종로점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":37437,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/onnurigalbi/","foodTypes":"한식(육류)","latitude":37.7776452264131,"longitude":127.124320216703,"bookYear":null,"nameKR":"온누리석갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":37436,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wiir.philadelphia/","foodTypes":"미국식","latitude":37.5218877720325,"longitude":127.02227090829,"bookYear":null,"nameKR":"위알필라델피아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37431,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pat.dori/","foodTypes":"디저트/차/베이커리","latitude":34.9478891726348,"longitude":127.524554820075,"bookYear":null,"nameKR":"팥도리","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":37427,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/s_chosaeng/","foodTypes":"디저트/차/베이커리","latitude":37.3845985822573,"longitude":127.952927988173,"bookYear":null,"nameKR":"신라명과(태장점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":14142,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.56796,"longitude":126.9294,"bookYear":null,"nameKR":"연희김밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":37422,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/maison_de_cake","foodTypes":"디저트/차/베이커리","latitude":37.3092217440944,"longitude":126.831791006265,"bookYear":null,"nameKR":"메종드케이크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":37416,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1545242125349,"longitude":128.046056980516,"bookYear":null,"nameKR":"대게궁","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":37410,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pongthaii/","foodTypes":"아시아식","latitude":37.5540039439813,"longitude":126.936731657301,"bookYear":null,"nameKR":"퐁타이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":37409,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/slashwith/","foodTypes":"디저트/차/베이커리","latitude":37.5277815508235,"longitude":126.899591577968,"bookYear":null,"nameKR":"슬래시위드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36631,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/unagi__man","foodTypes":"일식","latitude":35.1823933169182,"longitude":129.074203254718,"bookYear":null,"nameKR":"우나기;만","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":36629,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yonghojung_1top","foodTypes":"일식","latitude":35.2378936849077,"longitude":128.684923377665,"bookYear":null,"nameKR":"용호정","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":33991,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.420657856999,"longitude":126.883173812408,"bookYear":null,"nameKR":"커피나인(광명역점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":33719,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.2613287245894,"longitude":127.063637995106,"bookYear":null,"nameKR":"박용정's커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37221,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5583800714359,"longitude":127.079802901097,"bookYear":null,"nameKR":"6까사","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":37238,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5016876100662,"longitude":126.772357839045,"bookYear":null,"nameKR":"줄라이베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":37243,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5546125474078,"longitude":127.009633208843,"bookYear":null,"nameKR":"콘웨이커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":37257,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5205708137639,"longitude":126.709140523179,"bookYear":null,"nameKR":"1982삼계정","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":37389,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1138642473688,"longitude":129.018880043429,"bookYear":null,"nameKR":"제이레브","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":37270,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bellas_garden/","foodTypes":"이탈리아식","latitude":37.5432175536482,"longitude":127.044977927876,"bookYear":null,"nameKR":"벨라스가든(서울숲점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37271,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bellas_garden/","foodTypes":"기타","latitude":37.5100817898343,"longitude":127.106289795622,"bookYear":null,"nameKR":"벨라스가든(석촌호수점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":37371,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1518050874233,"longitude":129.026638966764,"bookYear":null,"nameKR":"가야양곱창","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37406,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.3710196596328,"longitude":127.0679403022,"bookYear":null,"nameKR":"스시하루쿠","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37328,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.7053471875858,"longitude":126.611127993065,"bookYear":null,"nameKR":"어큠커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":37405,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.264784115237,"longitude":127.031998689687,"bookYear":null,"nameKR":"숙성회432","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37400,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5456635978843,"longitude":127.045884892221,"bookYear":null,"nameKR":"멘야코노하성수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37399,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeonhui_espresso","foodTypes":"디저트/차/베이커리","latitude":37.566975490304,"longitude":126.927906748329,"bookYear":null,"nameKR":"연희에스프레소바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":37398,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.6457187660677,"longitude":126.789807807056,"bookYear":null,"nameKR":"등대숯불꼬치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":37140,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/seoul_kyungyangsik","foodTypes":"기타","latitude":37.207507186162,"longitude":127.065050936064,"bookYear":null,"nameKR":"서울경양식","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":37360,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5150167297423,"longitude":126.894471577229,"bookYear":null,"nameKR":"철판희","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37382,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.8169716641493,"longitude":127.108606505071,"bookYear":null,"nameKR":"고기굽는철판집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":37378,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/siksun_","foodTypes":"일식","latitude":37.546792454197,"longitude":126.91419543921,"bookYear":null,"nameKR":"식선","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":37392,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4826771119431,"longitude":126.901794859232,"bookYear":null,"nameKR":"옛마을(구로디지털역점) ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":35654,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bring_me_coffee_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5253785170391,"longitude":126.891376058936,"bookYear":null,"nameKR":"브링미커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":32868,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4012945444973,"longitude":127.255389810917,"bookYear":null,"nameKR":"에인트커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":35569,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pachamama.bakery/","foodTypes":"디저트/차/베이커리","latitude":37.533329307004,"longitude":126.974107588221,"bookYear":null,"nameKR":"파차마마 베이커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37362,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.6225288367645,"longitude":127.353410219651,"bookYear":null,"nameKR":"콘벨트청주","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":34523,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.2625844725614,"longitude":127.03523716075,"bookYear":null,"nameKR":"행화촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37391,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_orbit_cafe_/","foodTypes":"디저트/차/베이커리","latitude":37.511882438661,"longitude":127.122399760198,"bookYear":null,"nameKR":"오르빗","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":37390,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/umi.mato/","foodTypes":"일식","latitude":37.5596348826715,"longitude":126.83201084043,"bookYear":null,"nameKR":"우미마토","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":37384,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kubjang9292/","foodTypes":"한식(민물어패류)","latitude":35.1737195163913,"longitude":129.176686996058,"bookYear":null,"nameKR":"꿉장풍천민물장어전문점","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":37383,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daily.fix.seoul","foodTypes":"기타","latitude":37.4977749894834,"longitude":127.032422058167,"bookYear":null,"nameKR":"데일리픽스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":36067,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.560835635621,"longitude":126.829618546018,"bookYear":null,"nameKR":"서쉐프퀴진","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":36965,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hauhau.official","foodTypes":"아시아식","latitude":37.3179179875751,"longitude":126.836098804436,"bookYear":null,"nameKR":"하우하우","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36933,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rimbaud_official","foodTypes":"디저트/차/베이커리","latitude":37.5383565084538,"longitude":127.067914081307,"bookYear":null,"nameKR":"랭보","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":37207,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/before.blooming/","foodTypes":"디저트/차/베이커리","latitude":37.559608616725,"longitude":126.925064436597,"bookYear":null,"nameKR":"비포블루밍","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":34666,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.7225666671623,"longitude":127.04408391175,"bookYear":null,"nameKR":"호야","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":37363,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1532353292792,"longitude":129.124656209034,"bookYear":null,"nameKR":"디에이블(광안점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37368,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5418866227463,"longitude":127.04491059446,"bookYear":null,"nameKR":"봉밀가(서울숲점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37366,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5125587415186,"longitude":126.930988395318,"bookYear":null,"nameKR":"수베이커리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":37358,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/o_gu2020/","latitude":37.6750573252283,"longitude":127.05653648872,"bookYear":null,"nameKR":"신오구반점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":37344,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5540773838651,"longitude":127.020126268441,"bookYear":null,"nameKR":"금호온","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":37357,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yongkangzzie_seomyeon","foodTypes":"중식","latitude":35.1601669813854,"longitude":129.062029723903,"bookYear":null,"nameKR":"융캉찌에(서면점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":36884,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":35.1589266349244,"longitude":129.174483899297,"bookYear":null,"nameKR":"생어거스틴(일루아호텔점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":37352,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bullkkeun.mapo/","foodTypes":"일식","latitude":37.5405044916054,"longitude":126.943926893937,"bookYear":null,"nameKR":"불끈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":37335,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.8502703351396,"longitude":128.486017820119,"bookYear":null,"nameKR":"단지막창(성서본점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":37278,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.3959438685764,"longitude":126.916829414193,"bookYear":null,"nameKR":"화룡짬뽕전문점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":37349,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.177921789586,"longitude":129.197835666593,"bookYear":null,"nameKR":"홀리라운지","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":37348,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.174195096816,"longitude":129.080975028855,"bookYear":null,"nameKR":"거대김밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":37347,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/unido2_official/","foodTypes":"일식","latitude":37.5051452818563,"longitude":127.047426933421,"bookYear":null,"nameKR":"마구로동우니도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":37346,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5207186841862,"longitude":127.019955768192,"bookYear":null,"nameKR":"카이센동우니도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":36608,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pasta_hh_pasta","foodTypes":"이탈리아식","latitude":36.3959646120893,"longitude":127.408336182876,"bookYear":null,"nameKR":"대전파스타에이치","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":37340,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pain_fermente/","foodTypes":"디저트/차/베이커리","latitude":37.5104527132174,"longitude":126.945805667761,"bookYear":null,"nameKR":"뺑빼르망떼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":37256,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/r7.recette","foodTypes":"디저트/차/베이커리","latitude":37.5569661389713,"longitude":126.9077130721,"bookYear":null,"nameKR":"흐쎄트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":37260,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brunchbean_siheung/","foodTypes":"기타","latitude":37.4500172530654,"longitude":126.792481843792,"bookYear":null,"nameKR":"브런치빈(시흥대야점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":37300,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.400957023907,"longitude":127.843070268376,"bookYear":null,"nameKR":"무튼(원주본점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":37314,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5615704188052,"longitude":126.988471331506,"bookYear":null,"nameKR":"명동정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":37331,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/musaek_official/","foodTypes":"바/주점","latitude":37.5462046110775,"longitude":127.049328210947,"bookYear":null,"nameKR":"무색","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37339,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jrem333","foodTypes":"이탈리아식","latitude":37.5174719524586,"longitude":126.927004790814,"bookYear":null,"nameKR":"제이렘333","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":37329,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":36.3265905371112,"longitude":127.427895254881,"bookYear":null,"nameKR":"어바웃타임","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":34445,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5239351177282,"longitude":127.038963671726,"bookYear":null,"nameKR":"포레스트청담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37338,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kosten_coffee","foodTypes":"디저트/차/베이커리","latitude":33.4984270215817,"longitude":126.528114170582,"bookYear":null,"nameKR":"코스튼커피","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34318,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5081110186832,"longitude":127.023882927127,"bookYear":null,"nameKR":"계승집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":34101,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/spring_bori/","foodTypes":"한식(일반한식)","latitude":37.4887341298546,"longitude":126.558998073671,"bookYear":null,"nameKR":"봄이보리밥(영종점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":36993,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.2519041253168,"longitude":127.075243713155,"bookYear":null,"nameKR":"즉석깡우동","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37320,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nuridal_dessert_","foodTypes":"디저트/차/베이커리","latitude":37.3290365060486,"longitude":126.916588644408,"bookYear":null,"nameKR":"누리달","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":37319,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafedadalab","foodTypes":"바/주점","latitude":37.5561101272013,"longitude":126.908789623365,"bookYear":null,"nameKR":"다다랩","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":37305,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.2102171206188,"longitude":129.007784162778,"bookYear":null,"nameKR":"하나호라","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":37080,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5032053014414,"longitude":126.768162751102,"bookYear":null,"nameKR":"스시도원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":36899,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moi_jung_?igsh=MXZvNHBtZGI3cDM3Zw==","foodTypes":"일식","latitude":37.5503037491009,"longitude":126.920256069177,"bookYear":null,"nameKR":"모이정(홍대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36898,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moi_jung_?igsh=MXZvNHBtZGI3cDM3Zw==","foodTypes":"일식","latitude":37.5625848184185,"longitude":126.920428263461,"bookYear":null,"nameKR":"모이정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37227,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5475004120123,"longitude":126.922602173821,"bookYear":null,"nameKR":"라멘트럭","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":37299,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5620005331406,"longitude":126.986563787577,"bookYear":null,"nameKR":"함초간장게장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":37282,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goatcoffeekorea/","foodTypes":"디저트/차/베이커리","latitude":37.3810206955032,"longitude":126.660813360073,"bookYear":null,"nameKR":"고트커피","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":37240,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yourhome.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.512762521754,"longitude":126.893682197611,"bookYear":null,"nameKR":"유어홈커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":34085,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.3115184349931,"longitude":129.007045527778,"bookYear":null,"nameKR":"솔방구리삼겹살(증산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":37317,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2100881887753,"longitude":129.00917283579,"bookYear":null,"nameKR":"솔방구리삼겹살","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":37313,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/poongkukmyeon/","foodTypes":"한식(면류)","latitude":35.8301882601278,"longitude":128.618814793819,"bookYear":null,"nameKR":"풍국면(들안길점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":32483,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.362937068153,"longitude":127.357990384161,"bookYear":null,"nameKR":"블랙데스크","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":1076,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5713825413725,"longitude":127.040519485291,"bookYear":null,"nameKR":"대구집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":37241,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.5746329465236,"longitude":129.316762273371,"bookYear":null,"nameKR":"협정","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":37309,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pizzeria_gotgan/","foodTypes":"이탈리아식","latitude":35.1123480043468,"longitude":128.964112425262,"bookYear":null,"nameKR":"핏제리아곳간","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":37308,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daichi_gwanganli/","foodTypes":"일식","latitude":35.1570778855136,"longitude":129.125218110478,"bookYear":null,"nameKR":"다이치","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37302,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.2658849967954,"longitude":127.031026221546,"bookYear":null,"nameKR":"그롤라","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37301,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.9608522145813,"longitude":128.685187248361,"bookYear":null,"nameKR":"팔공은가비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":35593,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.3680155148275,"longitude":126.92759744702,"bookYear":null,"nameKR":"쿠모쿠모베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":37239,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gogitsura","foodTypes":"한식(육류)","latitude":36.3922312798695,"longitude":127.312111343353,"bookYear":null,"nameKR":"고깃수라","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":37182,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/devils_vasinico/","foodTypes":"이탈리아식","latitude":37.5452128980791,"longitude":126.914298752246,"bookYear":null,"nameKR":"데빌스바지니코","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":37019,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ixchel.seoul/","foodTypes":"중남미식","latitude":37.545772266644,"longitude":126.914711367454,"bookYear":null,"nameKR":"익스첼(합정본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":37169,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5559075925988,"longitude":126.925494129838,"bookYear":null,"nameKR":"비틀버그","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36746,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5558534022974,"longitude":126.926725403177,"bookYear":null,"nameKR":"쓰나미라멘(홍대본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36924,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5087651331364,"longitude":127.023508765123,"bookYear":null,"nameKR":"미치코서울","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":37162,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ttufa.cheongdam/","foodTypes":"바/주점","latitude":37.5250720429425,"longitude":127.040713439647,"bookYear":null,"nameKR":"청담튜파","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":36963,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aseul_cheongdam","foodTypes":"기타","latitude":37.5241044115478,"longitude":127.041103537848,"bookYear":null,"nameKR":"어슬청담한식다이닝 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":36368,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/Camposeoul","foodTypes":"이탈리아식","latitude":37.5333132707774,"longitude":127.003213965254,"bookYear":null,"nameKR":"캄포","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":35917,"bookStatus":"커버","websiteInstagram":"https://instagram.com/usotsuke_robata","foodTypes":"바/주점","latitude":37.534305897775,"longitude":126.974502679788,"bookYear":null,"nameKR":"우소츠케","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":37045,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/ormond.coffee.store","foodTypes":"디저트/차/베이커리","latitude":35.1797300358366,"longitude":129.047291732804,"bookYear":null,"nameKR":"오몬드커피스토어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":34500,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.0929476534795,"longitude":129.040256650412,"bookYear":null,"nameKR":"드메르","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":37276,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.5157874307175,"longitude":126.704541361673,"bookYear":null,"nameKR":"황금풍천장어","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":35474,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":36.492070617908,"longitude":127.255836690696,"bookYear":null,"nameKR":"스페이스","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":36412,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"미국식","latitude":36.6385208491784,"longitude":127.487121712679,"bookYear":null,"nameKR":"스페이스피자(청주점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":37288,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4211854985339,"longitude":126.991242601845,"bookYear":null,"nameKR":"웨하커피로스터스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":37286,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ummd.seoul/","foodTypes":"기타","latitude":37.522058292923,"longitude":127.041195289247,"bookYear":null,"nameKR":"윰드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":37285,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.557338261969,"longitude":126.967984372159,"bookYear":null,"nameKR":"중림동물고기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":31866,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.6490641128528,"longitude":126.896975022417,"bookYear":null,"nameKR":"바램 고깃들","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":37304,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/johnyoven/","foodTypes":"기타","latitude":35.2291033348957,"longitude":128.68075381858,"bookYear":null,"nameKR":"조니오븐","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":32574,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5677107355183,"longitude":126.825645363269,"bookYear":null,"nameKR":"익스퍼2","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":37290,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"미국식","latitude":36.3900044965949,"longitude":127.308395272624,"bookYear":null,"nameKR":"봉다리크랩","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":36783,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/aquagarden_korea/","foodTypes":"디저트/차/베이커리","latitude":37.5137519612953,"longitude":127.104446890835,"bookYear":null,"nameKR":"아쿠아가든카페(롯데월드몰점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36777,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/titipainpain","foodTypes":"디저트/차/베이커리","latitude":37.5226914790563,"longitude":127.032675035392,"bookYear":null,"nameKR":"띠띠빵빵","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37296,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.5362542276123,"longitude":129.336548154207,"bookYear":null,"nameKR":"압구정한우국밥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":37295,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.0603549826939,"longitude":126.524261662832,"bookYear":null,"nameKR":"수미한","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":37289,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5401120120104,"longitude":126.948466319802,"bookYear":null,"nameKR":"늘잇","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":37284,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/classicoven.seoul","foodTypes":"디저트/차/베이커리","latitude":37.5252158945682,"longitude":126.893862726741,"bookYear":null,"nameKR":"클래식오븐","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37283,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ramen_o.ota","foodTypes":"일식","latitude":36.3524894986863,"longitude":127.37275059758,"bookYear":null,"nameKR":"라멘오오타","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":37280,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ddjokbal/","foodTypes":"한식(육류)","latitude":37.4831031497728,"longitude":126.900765644388,"bookYear":null,"nameKR":"대두족발","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":36989,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_mokkiofficial","foodTypes":"디저트/차/베이커리","latitude":37.5121397708761,"longitude":126.895017414686,"bookYear":null,"nameKR":"모키커피앤티하우스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37275,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.2170197946599,"longitude":128.5821250674,"bookYear":null,"nameKR":"폴앤루이스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":37277,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4939565941674,"longitude":127.03531662533,"bookYear":null,"nameKR":"세석정","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":37264,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.3497703680316,"longitude":126.604808045033,"bookYear":null,"nameKR":"보령우가","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":37268,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1632766402427,"longitude":129.116005864976,"bookYear":null,"nameKR":"양귀빈식당(3호점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37269,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1560657337018,"longitude":129.122822673441,"bookYear":null,"nameKR":"양귀빈식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37267,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/izakaya_oni2023/","foodTypes":"바/주점","latitude":37.3691438762557,"longitude":126.726058404858,"bookYear":null,"nameKR":"이자카야 오니","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":37266,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/flooat_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.4893655725166,"longitude":127.122764534607,"bookYear":null,"nameKR":"플루엣","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":37265,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oilh_seoul/","foodTypes":"바/주점","latitude":37.4893655725166,"longitude":127.122764534607,"bookYear":null,"nameKR":"이내서울","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":37263,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/qua_songdo/","foodTypes":"아시아식","latitude":37.3934747558952,"longitude":126.646717372459,"bookYear":null,"nameKR":"꾸아(송도점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":36530,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.2755271181009,"longitude":127.044357690238,"bookYear":null,"nameKR":"한식주점휘얼","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34625,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.4962687595557,"longitude":126.777019204125,"bookYear":null,"nameKR":"아리랑개성손만두","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":34024,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.546581916376,"longitude":126.914354783381,"bookYear":null,"nameKR":"델리카테슨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":35965,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.2637115611221,"longitude":127.036216454063,"bookYear":null,"nameKR":"미나미참치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36323,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hi_jeonbok/","foodTypes":"한식(일반한식)","latitude":33.5128573870185,"longitude":126.519607483216,"bookYear":null,"nameKR":"안녕전복","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32235,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/simyaramen/","foodTypes":"일식","latitude":35.1584906105941,"longitude":128.686522947724,"bookYear":null,"nameKR":"겐쇼심야라멘","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":36225,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jhallenge","foodTypes":"한식(일반한식)","latitude":37.5627193786384,"longitude":126.926178676706,"bookYear":null,"nameKR":"한술식당(프리미엄연남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35053,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.267868604837,"longitude":127.007319180836,"bookYear":null,"nameKR":"히피브루어스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37222,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.34432311934,"longitude":127.341546917635,"bookYear":null,"nameKR":"늘상상","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":37262,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/npoliwood/","foodTypes":"이탈리아식","latitude":33.543817050075,"longitude":126.663100369275,"bookYear":null,"nameKR":"엔폴리우드","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":37255,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_salted/","foodTypes":"디저트/차/베이커리","latitude":37.5553209546395,"longitude":127.079075330729,"bookYear":null,"nameKR":"솔티드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":37249,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rho_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.2318260599769,"longitude":127.287470219522,"bookYear":null,"nameKR":"로커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":37183,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1103383584735,"longitude":128.964023499309,"bookYear":null,"nameKR":"오늘김해뒷고기","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":37193,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/betasvc/","foodTypes":"바/주점","latitude":37.5657709109319,"longitude":126.989973041455,"bookYear":null,"nameKR":"베타서비스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":37216,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4670332563592,"longitude":127.042480861896,"bookYear":null,"nameKR":"아랑솥뚜껑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":37208,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.4919819723164,"longitude":126.543441876853,"bookYear":null,"nameKR":"데디드","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":37219,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.509407082837,"longitude":127.025330277087,"bookYear":null,"nameKR":"뱀파이어치킨","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":37244,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/benowa_wj","foodTypes":"기타","latitude":37.3529244803812,"longitude":127.916785011994,"bookYear":null,"nameKR":"베노아","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":37237,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.3933193690503,"longitude":126.963172221695,"bookYear":null,"nameKR":"신림춘천집(평촌점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":33665,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":37.5338461180202,"longitude":126.994278926911,"bookYear":null,"nameKR":"코너피자조인트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":34620,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistro_senno/","foodTypes":"이탈리아식","latitude":37.5555938739134,"longitude":126.926514249091,"bookYear":null,"nameKR":"비스트로세노","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36212,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.5158017102182,"longitude":126.520329045372,"bookYear":null,"nameKR":"북성로42베이커리카페","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":33543,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5820926424763,"longitude":126.888984720113,"bookYear":null,"nameKR":"온돈부리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":37153,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.3966015482842,"longitude":126.649590971336,"bookYear":null,"nameKR":"떼라깔다","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":37201,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5356297845795,"longitude":127.000859437294,"bookYear":null,"nameKR":"써스티써스데이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":37212,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.3712000751728,"longitude":127.114975966966,"bookYear":null,"nameKR":"유담솥뚜껑닭볶음탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37115,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/understandcoffee_?igsh=MXVibHhzdzczaWp0OA==","foodTypes":"디저트/차/베이커리","latitude":37.4990321852366,"longitude":127.302591323072,"bookYear":null,"nameKR":"언더스탠드커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":37214,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.2451891618766,"longitude":128.90590678436,"bookYear":null,"nameKR":"어방동스시","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":37211,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sentimet_seoul/","foodTypes":"기타","latitude":37.4839674222361,"longitude":127.046197185426,"bookYear":null,"nameKR":"센티맷","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":37200,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_jidong_/","foodTypes":"한식(가금류)","latitude":37.6261736800918,"longitude":127.076239587119,"bookYear":null,"nameKR":"지동닭갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":37205,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mirim_yangjang/","foodTypes":"중식","latitude":35.1562054016641,"longitude":129.067298814502,"bookYear":null,"nameKR":"미림양장","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37206,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maemmaem.official/","foodTypes":"일식","latitude":35.8718832315088,"longitude":128.597628192033,"bookYear":null,"nameKR":"맴맴","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":36830,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/anmin.kr","foodTypes":"한식(육류)","latitude":35.2274837823871,"longitude":128.84487797375,"bookYear":null,"nameKR":"안민김해","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":36841,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eer_coffee_roasters","foodTypes":"디저트/차/베이커리","latitude":37.5459656130831,"longitude":126.945236466602,"bookYear":null,"nameKR":"이어커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36848,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brownfactory_cafe","foodTypes":"디저트/차/베이커리","latitude":37.3152131053821,"longitude":127.072942564189,"bookYear":null,"nameKR":"브라운팩토리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":36850,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/official__y704","foodTypes":"디저트/차/베이커리","latitude":37.5433407736849,"longitude":127.053889866916,"bookYear":null,"nameKR":"와이(Y)704","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36861,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5265705340683,"longitude":126.963528110228,"bookYear":null,"nameKR":"직각식탁","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36876,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.7207395597398,"longitude":126.746047045628,"bookYear":null,"nameKR":"마편당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":36874,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.7409245394796,"longitude":127.047644694429,"bookYear":null,"nameKR":"요로시","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":36888,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/bebedonutshop","foodTypes":"디저트/차/베이커리","latitude":37.5343936424521,"longitude":126.974863231981,"bookYear":null,"nameKR":"베베도넛","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36889,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chohissatsu/","foodTypes":"바/주점","latitude":35.1486535914577,"longitude":129.113563210332,"bookYear":null,"nameKR":"초힛사츠","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36897,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woohee_12/","foodTypes":"한식(육류)","latitude":37.5603931542576,"longitude":127.01262588851,"bookYear":null,"nameKR":"우희","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":36902,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/propercoffeebar","foodTypes":"기타","latitude":37.5073379787265,"longitude":127.123156221506,"bookYear":null,"nameKR":"프로퍼커피바(2호점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36940,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.3400440675585,"longitude":127.374972861895,"bookYear":null,"nameKR":"논뚜렁추어탕","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":36943,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7196727836662,"longitude":126.737721737471,"bookYear":null,"nameKR":"소탄","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":36946,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gentle_kettle/","foodTypes":"디저트/차/베이커리","latitude":37.2876086076178,"longitude":127.057001896438,"bookYear":null,"nameKR":"젠틀케틀","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36949,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5605659711705,"longitude":126.885851899473,"bookYear":null,"nameKR":"물결한강","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36953,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/yeonhwa_dupbab","foodTypes":"한식(일반한식)","latitude":35.1542714950768,"longitude":129.057302821914,"bookYear":null,"nameKR":"연화덮밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":36959,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mongepatisserie/","foodTypes":"디저트/차/베이커리","latitude":37.5666029325047,"longitude":127.024082467223,"bookYear":null,"nameKR":"몽쥬빠티세리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":36968,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":36.6583596118008,"longitude":127.457695465888,"bookYear":null,"nameKR":"금용우암산짬뽕(청주본점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36970,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/meegack_1","foodTypes":"중식","latitude":36.8005893534834,"longitude":127.159881249558,"bookYear":null,"nameKR":"미객반점","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":36975,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4819130872905,"longitude":126.881581110867,"bookYear":null,"nameKR":"가츠나베1.186","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":36976,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gobujang_sungshin","foodTypes":"한식(육류)","latitude":37.5919829815487,"longitude":127.018917452104,"bookYear":null,"nameKR":"고부장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":36988,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4660858883142,"longitude":127.137453219601,"bookYear":null,"nameKR":"삼화식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":36995,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/boribab_official","foodTypes":"한식(일반한식)","latitude":37.5680066583233,"longitude":126.930576867248,"bookYear":null,"nameKR":"연희보리밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":36999,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.477596858823,"longitude":127.039426079869,"bookYear":null,"nameKR":"바이우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":37013,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.940122662188,"longitude":128.298174078267,"bookYear":null,"nameKR":"자란들","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"고성군","status":"ACTIVE"},
        {"id":37034,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.8804818562697,"longitude":127.726930220315,"bookYear":null,"nameKR":"낭만춘천숯불닭갈비","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":37087,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeenine_eonju","foodTypes":"디저트/차/베이커리","latitude":37.5066742472709,"longitude":127.032661945559,"bookYear":null,"nameKR":"커피나인(언주역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":37091,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/cafe_doan","foodTypes":"디저트/차/베이커리","latitude":37.2637115611221,"longitude":127.036216454063,"bookYear":null,"nameKR":"도안","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37116,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5480210316058,"longitude":126.97774372502,"bookYear":null,"nameKR":"월순철판동태찜","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":37144,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/de.f_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.6742448166223,"longitude":126.77816509826,"bookYear":null,"nameKR":"데프커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":37204,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.1326695529587,"longitude":129.10968594313,"bookYear":null,"nameKR":"볼로네제","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":37114,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/purereyeoul","foodTypes":"디저트/차/베이커리","latitude":37.4990321852366,"longitude":127.302591323072,"bookYear":null,"nameKR":"푸르레여울","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":36682,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3181157520239,"longitude":126.836545917769,"bookYear":null,"nameKR":"동양솥밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36847,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.5655602750813,"longitude":128.728151718326,"bookYear":null,"nameKR":"안동또또찜닭","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":7343,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5592821999279,"longitude":127.012858171038,"bookYear":null,"nameKR":"이화원(청구점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":37197,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dame_jeanne_y/","foodTypes":"기타","latitude":35.1806983706637,"longitude":129.107549271758,"bookYear":null,"nameKR":"담잔느","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":37196,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mipojip/","foodTypes":"한식(어패류)","latitude":35.148493760019,"longitude":129.114428688464,"bookYear":null,"nameKR":"미포집(광안리직영점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":37195,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hoheonjangdam/","foodTypes":"바/주점","latitude":37.5985318642041,"longitude":127.057144943613,"bookYear":null,"nameKR":"호헌장담","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":33663,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5337247389211,"longitude":126.992424902392,"bookYear":null,"nameKR":"아노브피자","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33664,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5635325649477,"longitude":126.925377878746,"bookYear":null,"nameKR":"아노브피자(연남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37190,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the.beigel.bakery/","foodTypes":"디저트/차/베이커리","latitude":37.5574065258621,"longitude":126.904292115373,"bookYear":null,"nameKR":"더바이글","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":30466,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1527487393055,"longitude":126.857928105657,"bookYear":null,"nameKR":"다연닭갈비","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":2974,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7341505888393,"longitude":127.72010254112,"bookYear":null,"nameKR":"황소식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":35761,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/unduk_dabang","foodTypes":"디저트/차/베이커리","latitude":37.5258105386776,"longitude":126.996726398505,"bookYear":null,"nameKR":"언덕다방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":36903,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/propercoffeebar/","foodTypes":"디저트/차/베이커리","latitude":37.5097086877695,"longitude":127.131121181323,"bookYear":null,"nameKR":"프로퍼커피바(1호점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36300,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.3586743242247,"longitude":127.376895214347,"bookYear":null,"nameKR":"대포조개전골(월평본점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":35737,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.6182495462232,"longitude":127.482212564213,"bookYear":null,"nameKR":"완면집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35440,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.9991806783585,"longitude":127.112540530139,"bookYear":null,"nameKR":"라이크미","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":37172,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5546232165761,"longitude":126.864184670431,"bookYear":null,"nameKR":"오프레스트로스터리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34658,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5589063282429,"longitude":126.943038576035,"bookYear":null,"nameKR":"푸푸딩","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":35872,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.4572192797322,"longitude":126.814403939622,"bookYear":null,"nameKR":"장심도한국본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":37178,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5084883334727,"longitude":126.961652170904,"bookYear":null,"nameKR":"액션커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":37054,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.6408602086349,"longitude":127.430290921962,"bookYear":null,"nameKR":"삼미화로","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35674,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.2651248641234,"longitude":127.04784013569,"bookYear":null,"nameKR":"윤희와망고","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37186,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.2361722945662,"longitude":127.286333637581,"bookYear":null,"nameKR":"능이향","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":37188,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.3389590621332,"longitude":127.951094958114,"bookYear":null,"nameKR":"버드랜드커피클럽","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":37112,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/spring_delight_/","foodTypes":"디저트/차/베이커리","latitude":37.6004080517042,"longitude":127.03510382314,"bookYear":null,"nameKR":"스프링딜라잇","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":37184,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4795130993704,"longitude":126.887511773421,"bookYear":null,"nameKR":"커피나인(가산디지털점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":37181,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6130690857226,"longitude":126.833201138811,"bookYear":null,"nameKR":"뚝방길생두루치기 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":37100,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/latecheckout_kor/","foodTypes":"바/주점","latitude":37.562830316373,"longitude":126.925644336395,"bookYear":null,"nameKR":"레이트체크아웃","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":37164,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5418257136081,"longitude":126.951006551177,"bookYear":null,"nameKR":"어부의딸쭈꾸미숯불구이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":37173,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moo.joo_/","foodTypes":"디저트/차/베이커리","latitude":36.0769659334986,"longitude":129.391129721397,"bookYear":null,"nameKR":"무주","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":28487,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중남미식","latitude":37.556883,"longitude":126.937342,"bookYear":null,"nameKR":"이빠네마그릴","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":37176,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6125029160227,"longitude":126.76139718784,"bookYear":null,"nameKR":"풍곡가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":37160,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1692617565668,"longitude":128.60006880912,"bookYear":null,"nameKR":"만푸푸네르","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":37171,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4989786738327,"longitude":127.000999698637,"bookYear":null,"nameKR":"서래이모네맛집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":37168,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":34.955118983407,"longitude":127.519216668148,"bookYear":null,"nameKR":"중경삼림","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":37145,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6706006777783,"longitude":126.785645565784,"bookYear":null,"nameKR":"에스프레소바코드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":36728,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5048981807486,"longitude":127.045010007318,"bookYear":null,"nameKR":"에이스크랩강남점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":37156,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.505395630065,"longitude":127.040254658606,"bookYear":null,"nameKR":"마시리벌교참꼬막","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":37155,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bgt_2023_3.31/","foodTypes":"한식(면류)","latitude":37.5086151302204,"longitude":127.059765116537,"bookYear":null,"nameKR":"봉계타","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":36745,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.6250872033066,"longitude":127.15161588836,"bookYear":null,"nameKR":"히마","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":36846,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wickerpark_seoul","foodTypes":"기타","latitude":37.5074198018341,"longitude":127.102484025924,"bookYear":null,"nameKR":"위커파크웨스트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35310,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.3377550363499,"longitude":127.116506163087,"bookYear":null,"nameKR":"그린오브 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":37159,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1768525694357,"longitude":129.089808715911,"bookYear":null,"nameKR":"카센미세","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":37157,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aht.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.2365163938833,"longitude":127.198473865768,"bookYear":null,"nameKR":"에이에이치티커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":37150,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/___beurre/","foodTypes":"디저트/차/베이커리","latitude":37.2101421642725,"longitude":127.051620596668,"bookYear":null,"nameKR":"뵈르아워","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":37073,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/frangerie_official","foodTypes":"디저트/차/베이커리","latitude":37.5496213115507,"longitude":126.931255709052,"bookYear":null,"nameKR":"프랑제리(피어신촌점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":37118,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dream_ricecake/","foodTypes":"디저트/차/베이커리","latitude":34.960217940948,"longitude":127.526159096374,"bookYear":null,"nameKR":"순천드림떡","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":37120,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5083543250525,"longitude":127.066764125492,"bookYear":null,"nameKR":"커피진심베이커리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":36580,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.8628626451183,"longitude":127.728295907398,"bookYear":null,"nameKR":"고기왕김육즙","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":36716,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pelican__cafe/","foodTypes":"디저트/차/베이커리","latitude":37.5475053666579,"longitude":126.922463546076,"bookYear":null,"nameKR":"펠리칸카페","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36939,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.noo","foodTypes":"디저트/차/베이커리","latitude":37.5808750287322,"longitude":126.966898217778,"bookYear":null,"nameKR":"누","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":37090,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.9879606926342,"longitude":127.941853576076,"bookYear":null,"nameKR":"채상궁","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":37139,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.757926941012,"longitude":128.89895875603,"bookYear":null,"nameKR":"은달래(강릉점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":37137,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.5021775517303,"longitude":129.429849271012,"bookYear":null,"nameKR":"도원","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":36244,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5436475647062,"longitude":127.056584886113,"bookYear":null,"nameKR":"네기라이브","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36220,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sancheongin/","foodTypes":"한식(육류)","latitude":37.5611255258819,"longitude":126.836985456641,"bookYear":null,"nameKR":"산청숯불가든","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":37132,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4919941549832,"longitude":127.028848796506,"bookYear":null,"nameKR":"돈꿈카츠앤소바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":37130,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8626681456352,"longitude":128.697307507776,"bookYear":null,"nameKR":"구일돼지","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":36528,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5441865873771,"longitude":127.086787844412,"bookYear":null,"nameKR":"원즈커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36610,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5216245595859,"longitude":126.870444168108,"bookYear":null,"nameKR":"르플로르","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":36663,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5574153066539,"longitude":126.90456553807,"bookYear":null,"nameKR":"카페네이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":36673,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":35.1392534882286,"longitude":126.916025933942,"bookYear":null,"nameKR":"미드나잇익스프레스","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":36753,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.295844997806,"longitude":127.33662426113,"bookYear":null,"nameKR":"오우","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":36806,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3995094826934,"longitude":126.924204192389,"bookYear":null,"nameKR":"시에나커피로스터스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":36814,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4730945210073,"longitude":127.039129900303,"bookYear":null,"nameKR":"양떡","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36836,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wnlgt/","foodTypes":"바/주점","latitude":37.2825861537357,"longitude":127.01666621841,"bookYear":null,"nameKR":"와인라이트","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36878,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/vaso_1011","foodTypes":"디저트/차/베이커리","latitude":37.5313904282915,"longitude":126.660857199423,"bookYear":null,"nameKR":"바소(청라점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":36907,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mizkiwa_woojung","foodTypes":"일식","latitude":35.5594212470866,"longitude":129.293716529084,"bookYear":null,"nameKR":"스시미즈기와(우정혁신도시점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":36964,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.2730866320671,"longitude":126.511835188608,"bookYear":null,"nameKR":"몽떼","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":37009,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.2764541640864,"longitude":126.996382953405,"bookYear":null,"nameKR":"기치브루랩","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":37044,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thu_pm4","foodTypes":"디저트/차/베이커리","latitude":35.1878769566458,"longitude":128.090014027778,"bookYear":null,"nameKR":"목요일오후네시","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":37085,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2326918826701,"longitude":129.088264640802,"bookYear":null,"nameKR":"낙양","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":37113,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.55825515906,"longitude":126.946068722683,"bookYear":null,"nameKR":"초식곳간","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":35545,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/god_mijori","foodTypes":"한식(어패류)","latitude":35.8698611607212,"longitude":128.732326606207,"bookYear":null,"nameKR":"미조리해물찜본점","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":37119,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_melange.co/","foodTypes":"디저트/차/베이커리","latitude":37.601084143098,"longitude":126.665088201111,"bookYear":null,"nameKR":"멜란지베이커리","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":37110,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hk_garden83/","foodTypes":"중식","latitude":35.1549982525993,"longitude":129.065669442159,"bookYear":null,"nameKR":"홍콩가든","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":37109,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6333643585248,"longitude":126.917852171711,"bookYear":null,"nameKR":"참나무본가(은평점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":37108,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/newoldcoffee_co/","foodTypes":"디저트/차/베이커리","latitude":36.4785110498806,"longitude":127.287327833491,"bookYear":null,"nameKR":"뉴올드커피","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":37107,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8359915289856,"longitude":128.61983209372,"bookYear":null,"nameKR":"연리희재(대구점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":37106,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.7684166754188,"longitude":126.713346253687,"bookYear":null,"nameKR":"연리희재","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":35558,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5061871117755,"longitude":127.097511756848,"bookYear":null,"nameKR":"비와별닭갈비(잠실직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35591,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.2006732594761,"longitude":127.097922758261,"bookYear":null,"nameKR":"메이플탑(동탄점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":36699,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1021607257782,"longitude":126.900911416043,"bookYear":null,"nameKR":"늘푸른흑염소가든(광주노대점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":37105,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5921650866509,"longitude":127.054111088129,"bookYear":null,"nameKR":"7카롱","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":37104,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4946203470469,"longitude":127.027606136235,"bookYear":null,"nameKR":"오늘통닭(강남역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":37103,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.3582007819722,"longitude":127.345401884465,"bookYear":null,"nameKR":"오늘통닭(봉명점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":36837,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.7767275518844,"longitude":128.874964341182,"bookYear":null,"nameKR":"데자뷰로스터리","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":36840,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4730513390026,"longitude":127.141262948182,"bookYear":null,"nameKR":"올드몬트","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":36852,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1501888488295,"longitude":126.922726539403,"bookYear":null,"nameKR":"테리킴스테이크","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":36833,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.8388485150443,"longitude":128.70844243464,"bookYear":null,"nameKR":"화계관","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36794,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8588293785723,"longitude":128.602247747375,"bookYear":null,"nameKR":"구공회","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":36798,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3209893962943,"longitude":126.829258245673,"bookYear":null,"nameKR":"더376","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36793,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5027584776087,"longitude":127.037798994712,"bookYear":null,"nameKR":"명정루(역삼점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":36869,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5006989321566,"longitude":127.034653018054,"bookYear":null,"nameKR":"보코네","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":36904,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5915745505929,"longitude":127.02007646418,"bookYear":null,"nameKR":"토쿠라멘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":36909,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8644899597669,"longitude":128.609967019407,"bookYear":null,"nameKR":"금주by카네바시라","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":36911,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4851444776342,"longitude":127.043891565106,"bookYear":null,"nameKR":"니꾸바키라","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36973,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5434565384669,"longitude":126.947277472141,"bookYear":null,"nameKR":"일야커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36977,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5690270688312,"longitude":126.827780298847,"bookYear":null,"nameKR":"봉이밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":37011,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/till.after_/","foodTypes":"이탈리아식","latitude":37.5589990583275,"longitude":126.924727661332,"bookYear":null,"nameKR":"틸애프터","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35367,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.60000888397,"longitude":126.890065259613,"bookYear":null,"nameKR":"도온정","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":37028,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5155320836663,"longitude":127.033722960146,"bookYear":null,"nameKR":"브루주아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":37048,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.323414446818,"longitude":126.785087914248,"bookYear":null,"nameKR":"당당초밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36786,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4976145695004,"longitude":127.102531804188,"bookYear":null,"nameKR":"빠다상점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":37043,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5590445216786,"longitude":126.936467197506,"bookYear":null,"nameKR":"오늘통닭(신촌직영점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":36875,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yoroshiiii_/","foodTypes":"바/주점","latitude":37.6000607564335,"longitude":127.141206385629,"bookYear":null,"nameKR":"요로시(구리점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":36747,"bookStatus":"커버","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.54310544254,"longitude":127.071814757048,"bookYear":null,"nameKR":"쑥띵","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36740,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.6182161017816,"longitude":127.07591204414,"bookYear":null,"nameKR":"미소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":36735,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3732182030325,"longitude":126.790712669353,"bookYear":null,"nameKR":"야미케이크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":36731,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중남미식","latitude":36.9999145785826,"longitude":127.114644478536,"bookYear":null,"nameKR":"고부기(평택본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":36718,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6412701660367,"longitude":126.677601659359,"bookYear":null,"nameKR":"육회바른연어(김포한강점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":37067,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5530409203888,"longitude":126.652864997453,"bookYear":null,"nameKR":"칠돈가(청라점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":36692,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.2268937872771,"longitude":129.011512192705,"bookYear":null,"nameKR":"만석꾼풍천산삼민물장어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":36606,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8304370886208,"longitude":128.618351540217,"bookYear":null,"nameKR":"파리네","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":37099,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jyojyo.kr/","foodTypes":"일식","latitude":37.5414819643497,"longitude":127.061023795592,"bookYear":null,"nameKR":"죠죠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37096,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/cremecrumble_cookie","foodTypes":"디저트/차/베이커리","latitude":37.5211908466829,"longitude":127.023659381837,"bookYear":null,"nameKR":"크렘크럼블","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":37095,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.533385119795,"longitude":126.651624357677,"bookYear":null,"nameKR":"굽도","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":36935,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5230740826298,"longitude":126.904586672939,"bookYear":null,"nameKR":"쉐프조부띠끄","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36936,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5269114271622,"longitude":126.894597707083,"bookYear":null,"nameKR":"브레드컴퍼니쉐프조","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37093,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7963158046398,"longitude":128.91715963786,"bookYear":null,"nameKR":"고씨네동해막국수(강문본점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":37092,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.7092150271575,"longitude":126.703785315596,"bookYear":null,"nameKR":"산뜨락곤드레","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":36389,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5451151551748,"longitude":126.940033304527,"bookYear":null,"nameKR":"구스카토커피 마포대흥점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":37089,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.2186916639885,"longitude":129.224888288741,"bookYear":null,"nameKR":"본본레스토랑","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":37037,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5547908528642,"longitude":126.964454371144,"bookYear":null,"nameKR":"킷사텐(서울역센트럴자이점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":35808,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5065230118214,"longitude":127.131602954536,"bookYear":null,"nameKR":"우카커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":849,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.495511,"longitude":127.014623,"bookYear":null,"nameKR":"칸지고고","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":4429,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.146795,"longitude":126.922616,"bookYear":null,"nameKR":"영성원","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":12391,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":38.09591545393744,"longitude":127.77603165291362,"bookYear":null,"nameKR":"파로호횟집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"화천군","status":"ACTIVE"},
        {"id":12791,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1150586536928,"longitude":128.700450576328,"bookYear":null,"nameKR":"대섬횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":13173,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.7454841458573,"longitude":127.654114386472,"bookYear":null,"nameKR":"동동해","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":37086,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/143ily___/","foodTypes":"바/주점","latitude":37.5871026073739,"longitude":126.982452741417,"bookYear":null,"nameKR":"일사삼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":29611,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5137519612953,"longitude":127.104446890835,"bookYear":null,"nameKR":"옥인피자","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36892,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6954250377903,"longitude":126.764587472166,"bookYear":null,"nameKR":"블레블레","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":36893,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6679852145335,"longitude":126.751647571454,"bookYear":null,"nameKR":"블레블레(현대백화점킨텍스점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":37084,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":34.7642300836808,"longitude":127.638132146469,"bookYear":null,"nameKR":"최선비","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":25848,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/bakerybongyo/","foodTypes":"디저트/차/베이커리","latitude":37.5587854480624,"longitude":126.911020390226,"bookYear":null,"nameKR":"베이커리봉교","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":37075,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baekseondang_gram/","foodTypes":"한식(가금류)","latitude":36.3480426228399,"longitude":127.388988706058,"bookYear":null,"nameKR":"백선당(탄방본점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":37042,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5332808940217,"longitude":126.992243018397,"bookYear":null,"nameKR":"벅벅(이태원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":37005,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nature_shabu/","foodTypes":"기타","latitude":33.2504129244334,"longitude":126.519548752313,"bookYear":null,"nameKR":"자연샤브(서귀포신시가지점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":37079,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/o_patisserie_/","foodTypes":"디저트/차/베이커리","latitude":37.6095536861858,"longitude":126.916680466459,"bookYear":null,"nameKR":"오파티세리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":37077,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8369910774607,"longitude":129.285349346667,"bookYear":null,"nameKR":"보문갈비","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":37071,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4935051209758,"longitude":126.722034689837,"bookYear":null,"nameKR":"불로만숯불닭갈비","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":36568,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5737946589409,"longitude":126.990249308503,"bookYear":null,"nameKR":"간판없는가게","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":36966,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/doduk_kyubum","foodTypes":"디저트/차/베이커리","latitude":37.5489724360164,"longitude":126.924508249611,"bookYear":null,"nameKR":"도덕과규범","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36492,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hon_geo.jeju/","foodTypes":"한식(육류)","latitude":33.3857358698314,"longitude":126.224843605727,"bookYear":null,"nameKR":"혼저","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":11112,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/toampark/","foodTypes":"한식(일반한식)","latitude":35.228898,"longitude":129.224998,"bookYear":null,"nameKR":"토암공원","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":31377,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lesoleil_official/","foodTypes":"디저트/차/베이커리","latitude":37.5371260651579,"longitude":127.001830244266,"bookYear":null,"nameKR":"르솔레이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":37062,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1742209532835,"longitude":129.173539469735,"bookYear":null,"nameKR":"우연","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":36392,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4668095536783,"longitude":127.097403955941,"bookYear":null,"nameKR":"오세안양갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":37058,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/masiondepain_bakery/","foodTypes":"디저트/차/베이커리","latitude":35.9394034579667,"longitude":128.559949389558,"bookYear":null,"nameKR":"메종드빵","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":34230,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5525171351569,"longitude":126.916851627507,"bookYear":null,"nameKR":"멘야하나비(합정점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36829,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/anmin.kr","foodTypes":"한식(육류)","latitude":35.1515705834896,"longitude":128.702150232993,"bookYear":null,"nameKR":"안민진해","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":37046,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.508381141658,"longitude":126.73309338626,"bookYear":null,"nameKR":"온도베이커리","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":35757,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.6194373178911,"longitude":127.495936468844,"bookYear":null,"nameKR":"청남삼겹살","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36685,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2288240982057,"longitude":127.071336917109,"bookYear":null,"nameKR":"양심소","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":34280,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/noi_since_2012","foodTypes":"아시아식","latitude":37.5398100400745,"longitude":127.055990552475,"bookYear":null,"nameKR":"노이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":37041,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/burg_burg_","foodTypes":"기타","latitude":37.5891858088375,"longitude":126.99475351986,"bookYear":null,"nameKR":"벅벅","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":37040,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.6085050534928,"longitude":127.485699686937,"bookYear":null,"nameKR":"샌드랑","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36927,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.3584017003507,"longitude":127.433801272937,"bookYear":null,"nameKR":"명품쉐프독도복국(용전점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":36047,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_oishikatsu_/","foodTypes":"일식","latitude":35.868876480982,"longitude":128.598068353064,"bookYear":null,"nameKR":"오이시카츠(동성로본점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":36596,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soon___official","foodTypes":"기타","latitude":36.631268435734,"longitude":127.464593079162,"bookYear":null,"nameKR":"순","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36804,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/inkimyung_mangpo","foodTypes":"한식(어패류)","latitude":37.2466489882292,"longitude":127.056208998995,"bookYear":null,"nameKR":"인기명수원망포","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36754,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.7401796381376,"longitude":127.649657754916,"bookYear":null,"nameKR":"단군신화","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":36805,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.0458278359148,"longitude":127.045056429299,"bookYear":null,"nameKR":"따따라멘","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":36825,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5280407556239,"longitude":127.036869472402,"bookYear":null,"nameKR":"크레스타운(압구정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":37004,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nature_shabu?igshid=w3t27kwpuqpg","foodTypes":"기타","latitude":33.265207773471,"longitude":126.568151292692,"bookYear":null,"nameKR":"자연샤브","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":36934,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chefcho77","foodTypes":"디저트/차/베이커리","latitude":37.5192524813198,"longitude":126.892722228176,"bookYear":null,"nameKR":"쉐프조","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":37008,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/haewol_coffee_official","foodTypes":"디저트/차/베이커리","latitude":37.368371329452,"longitude":126.727341176579,"bookYear":null,"nameKR":"해월커피(배곧점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":37000,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/breadboard2019/","foodTypes":"디저트/차/베이커리","latitude":36.7573144193841,"longitude":127.225091034806,"bookYear":null,"nameKR":"브레드보드","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":37020,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/always_anniversary","foodTypes":"디저트/차/베이커리","latitude":36.7999364583201,"longitude":127.122139938833,"bookYear":null,"nameKR":"오늘도기념일","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":36519,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeenine_deokeun_rw","foodTypes":"디저트/차/베이커리","latitude":37.5780164503878,"longitude":126.862525363288,"bookYear":null,"nameKR":"커피나인(덕은리버위크점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":37006,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.7828513793924,"longitude":127.081847740475,"bookYear":null,"nameKR":"웬유바이트","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":37003,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":37.5096143558743,"longitude":127.108952168206,"bookYear":null,"nameKR":"피자덕후피자힙(잠실송리단길점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":37002,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4832213787469,"longitude":127.038420614459,"bookYear":null,"nameKR":"삼산회관(양재역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":37001,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.63927632455,"longitude":129.288088540347,"bookYear":null,"nameKR":"베이커스바오밥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":36974,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/si_gan_eul_deul_i_da","foodTypes":"디저트/차/베이커리","latitude":37.4965094411461,"longitude":126.928028822574,"bookYear":null,"nameKR":"시간을들이다","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":16216,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.5080282989335,"longitude":126.487105160011,"bookYear":null,"nameKR":"갯마을","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":36675,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.2881339033892,"longitude":127.142609335737,"bookYear":null,"nameKR":"스톤커피바","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":34316,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5332088120956,"longitude":127.076200292098,"bookYear":null,"nameKR":"양양면옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36997,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_repaircoffee","foodTypes":"디저트/차/베이커리","latitude":37.4876383319824,"longitude":126.789563941811,"bookYear":null,"nameKR":"정비소","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":36996,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5071735406897,"longitude":126.751476572166,"bookYear":null,"nameKR":"상동백년갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":36994,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.7106503473415,"longitude":128.01570151894,"bookYear":null,"nameKR":"모든식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거창군","status":"ACTIVE"},
        {"id":36986,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.382650987288,"longitude":126.962894140534,"bookYear":null,"nameKR":"우산화로","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":33578,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.2722147478422,"longitude":127.151005407332,"bookYear":null,"nameKR":"로얄피그","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":36984,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chun_gye_ok","foodTypes":"한식(가금류)","latitude":37.2671339971731,"longitude":127.030070469552,"bookYear":null,"nameKR":"춘계옥본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36912,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jjumakchon","foodTypes":"한식(어패류)","latitude":37.2899085048256,"longitude":126.857846644822,"bookYear":null,"nameKR":"쭈막촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36920,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gyuum_cafe","foodTypes":"디저트/차/베이커리","latitude":38.0617895200892,"longitude":128.168043201183,"bookYear":null,"nameKR":"규움카페","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":34717,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.22785786646,"longitude":128.88656510452,"bookYear":null,"nameKR":"갈매기대도","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":36669,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/alice.project_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.4770034056652,"longitude":127.04036955084,"bookYear":null,"nameKR":"앨리스프로젝트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36967,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5606655023437,"longitude":127.157486453471,"bookYear":null,"nameKR":"브레드럽","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36962,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/onhwa_dosan","foodTypes":"기타","latitude":37.5248762621515,"longitude":127.037988213227,"bookYear":null,"nameKR":"온화다이닝 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":36961,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6305347144585,"longitude":126.657369619959,"bookYear":null,"nameKR":"가현산잔치국수","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":36957,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5036925446304,"longitude":127.053840999217,"bookYear":null,"nameKR":"유케집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":35904,"bookStatus":"비활성","websiteInstagram":"https://www.instagram/stonecreek_ae32","foodTypes":"디저트/차/베이커리","latitude":37.3251600854949,"longitude":127.980445258209,"bookYear":null,"nameKR":"스톤크릭10마일","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":36929,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bwok_boryeong/","foodTypes":"이탈리아식","latitude":36.3371359040261,"longitude":126.609115409412,"bookYear":null,"nameKR":"붴","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":36602,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cooky_mari","foodTypes":"디저트/차/베이커리","latitude":35.8386444857987,"longitude":128.625704196331,"bookYear":null,"nameKR":"쿠키마리케이크샵","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36952,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1432139376337,"longitude":129.059684140223,"bookYear":null,"nameKR":"정성담은","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":36947,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/aeguk_pork","foodTypes":"한식(육류)","latitude":35.0988923563594,"longitude":128.95770848313,"bookYear":null,"nameKR":"애국돼지","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":36945,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2623529069291,"longitude":128.87368628209,"bookYear":null,"nameKR":"한우육회비빔밥자연밥상","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":29254,"bookStatus":"커버","websiteInstagram":"","foodTypes":"중식","latitude":37.52941398328275,"longitude":126.89790787963236,"bookYear":null,"nameKR":"대관원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36515,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.568851450649,"longitude":126.827338682565,"bookYear":null,"nameKR":"닭장맥주(마곡나루본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":36654,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.6558964094856,"longitude":126.772038816453,"bookYear":null,"nameKR":"만돈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":36627,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_agarifighter_","foodTypes":"한식(육류)","latitude":37.5422475366416,"longitude":126.972291560606,"bookYear":null,"nameKR":"원동미나리삼겹살","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36918,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/saichou_dongtan","foodTypes":"바/주점","latitude":37.2062388115091,"longitude":127.074764426064,"bookYear":null,"nameKR":"사이쵸동탄","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":36919,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/club.goldenguy","foodTypes":"바/주점","latitude":37.3094302796125,"longitude":126.830679089431,"bookYear":null,"nameKR":"골덴가이하이볼클럽","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36910,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chamjsd/","foodTypes":"중식","latitude":37.4982260402942,"longitude":127.053668461581,"bookYear":null,"nameKR":"참바른중식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":33381,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5114638312604,"longitude":127.081212794079,"bookYear":null,"nameKR":"백석","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36790,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4854211148285,"longitude":127.03135523975,"bookYear":null,"nameKR":"명정루(양재점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36855,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/millbomsoup_bakery","foodTypes":"디저트/차/베이커리","latitude":37.7492708263313,"longitude":127.737238176149,"bookYear":null,"nameKR":"밀봄숲","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":36870,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.8412308724546,"longitude":127.129538378728,"bookYear":null,"nameKR":"베이커리브레도 ","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":36883,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ah_puh_kitchen","foodTypes":"이탈리아식","latitude":37.5442217804822,"longitude":126.672473261192,"bookYear":null,"nameKR":"어푸키친","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":36896,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2527690141039,"longitude":127.070900946094,"bookYear":null,"nameKR":"태백한우실비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36894,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1488601748871,"longitude":129.113819147771,"bookYear":null,"nameKR":"주옥같은인생","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36891,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/clean_your_liver/","foodTypes":"한식(육류)","latitude":35.1566471472902,"longitude":129.146930632686,"bookYear":null,"nameKR":"따귀집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":36890,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":33.5139398702552,"longitude":126.521176591559,"bookYear":null,"nameKR":"도주제","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":19682,"bookStatus":"커버","websiteInstagram":"","foodTypes":"바/주점","latitude":37.526998,"longitude":126.897608,"bookYear":null,"nameKR":"죽변항해물포차","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36879,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vaso_1005","foodTypes":"디저트/차/베이커리","latitude":37.3937618144703,"longitude":126.633308276601,"bookYear":null,"nameKR":"바소(송도점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":36877,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8676530872604,"longitude":128.578112993271,"bookYear":null,"nameKR":"마커커피 ","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":36867,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.443078384772,"longitude":127.13981075734,"bookYear":null,"nameKR":"참진짜장(신흥점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":36866,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joanne_5206","foodTypes":"한식(민물어패류)","latitude":37.5559967610014,"longitude":127.207785996679,"bookYear":null,"nameKR":"동인장어집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":36865,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5682179109709,"longitude":126.981614792564,"bookYear":null,"nameKR":"알돈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":36862,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_bakery_paradise","foodTypes":"디저트/차/베이커리","latitude":37.5914257165994,"longitude":127.141535980077,"bookYear":null,"nameKR":"낙원제과","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":36856,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/savorite_seoul/","foodTypes":"이탈리아식","latitude":37.5062773604789,"longitude":127.099387961215,"bookYear":null,"nameKR":"세이버릿","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32193,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6207410210947,"longitude":126.918015242848,"bookYear":null,"nameKR":"포항물회","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":36854,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5135927138159,"longitude":127.604368355057,"bookYear":null,"nameKR":"박금순특허청국장돌솥밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":36499,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sooeulkwan","foodTypes":"바/주점","latitude":37.5620385899772,"longitude":126.92546833092,"bookYear":null,"nameKR":"수을관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":36460,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5089249605329,"longitude":127.036644267689,"bookYear":null,"nameKR":"순정한우정육식당(논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":28443,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lepain_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.563253,"longitude":126.986832,"bookYear":null,"nameKR":"르빵(명동성당점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":36851,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4610422038019,"longitude":127.167613268628,"bookYear":null,"nameKR":"최박사돈까스냉면","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":36845,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bread_meet_paris","foodTypes":"디저트/차/베이커리","latitude":35.0923051642487,"longitude":129.058990829961,"bookYear":null,"nameKR":"브레드밋파리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":36844,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cisnetearoom","foodTypes":"디저트/차/베이커리","latitude":36.6467801400935,"longitude":127.474754269834,"bookYear":null,"nameKR":"씨스네티룸 ","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36826,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/laurasmill_cafe","foodTypes":"디저트/차/베이커리","latitude":36.3112054325594,"longitude":127.351914434746,"bookYear":null,"nameKR":"로라커피","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":36821,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jmonster_dessert_house/","foodTypes":"디저트/차/베이커리","latitude":34.9283108343677,"longitude":127.551628097481,"bookYear":null,"nameKR":"제이몬스터베이커리카페","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":33565,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.1432139376337,"longitude":129.059684140223,"bookYear":null,"nameKR":"정담칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":1737,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":38.195666,"longitude":128.538061,"bookYear":null,"nameKR":"황두막","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":1765,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.231751,"longitude":127.286713,"bookYear":null,"nameKR":"금성","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":6015,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8722904240528,"longitude":128.605125851812,"bookYear":null,"nameKR":"장원식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":9215,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.840838,"longitude":128.421052,"bookYear":null,"nameKR":"미주뚝배기","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":12678,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.51932,"longitude":126.9109,"bookYear":null,"nameKR":"대한옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":17115,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.6643565585227,"longitude":127.361445293758,"bookYear":null,"nameKR":"김삿갓밥집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":17738,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"원조옛날보리밥집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":21021,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.591894,"longitude":128.730842,"bookYear":null,"nameKR":"탐복 미나리가든","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":25231,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":33.467637,"longitude":126.610653,"bookYear":null,"nameKR":"명도암정식","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":33093,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/asill.1991/","foodTypes":"디저트/차/베이커리","latitude":37.5059323898242,"longitude":127.042731579122,"bookYear":null,"nameKR":"아실","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":34529,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.080539589669,"longitude":126.833437822643,"bookYear":null,"nameKR":"초원식당","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":34537,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.1060797983362,"longitude":128.351541433125,"bookYear":null,"nameKR":"청풍오리","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":34541,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5038237828612,"longitude":127.13876676249,"bookYear":null,"nameKR":"대밭골 우렁쌈밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":34546,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.7482239967819,"longitude":126.415333635575,"bookYear":null,"nameKR":"흙이야기","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영암군","status":"ACTIVE"},
        {"id":34548,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.3702083203817,"longitude":127.435208893512,"bookYear":null,"nameKR":"고기품은 두부전골 매봉식당","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":34550,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.0108709847025,"longitude":127.171786356617,"bookYear":null,"nameKR":"명선기운찬식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":34556,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.3558005487317,"longitude":127.335774246831,"bookYear":null,"nameKR":"예전손칼국시전문점","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":34565,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.597427830731,"longitude":127.509820508728,"bookYear":null,"nameKR":"유명산흥부네솥뚜껑닭볶음탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":34572,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.535733228762,"longitude":129.336650770881,"bookYear":null,"nameKR":"착한 물고기","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":34855,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4844899734409,"longitude":126.912742495634,"bookYear":null,"nameKR":"금동이네보쌈의명가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":34867,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5091915264878,"longitude":127.105565349903,"bookYear":null,"nameKR":"안준수보리밥보쌈 잠실본점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34869,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5029101502925,"longitude":127.050065020431,"bookYear":null,"nameKR":"우리집만두 선릉본점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":34871,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5724812434425,"longitude":126.934145957025,"bookYear":null,"nameKR":"녹원쌈밥 연희점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":34872,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/yurim_dakdori","foodTypes":"한식(가금류)","latitude":37.5478258698188,"longitude":126.871722147865,"bookYear":null,"nameKR":"유림닭도리탕 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34873,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5949649315205,"longitude":126.994311844449,"bookYear":null,"nameKR":"이향","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":34874,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/danback.haru/","foodTypes":"기타","latitude":37.5182569519621,"longitude":127.027230243102,"bookYear":null,"nameKR":"단백하루 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":34875,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5499605952374,"longitude":126.835973411049,"bookYear":null,"nameKR":"초가집부뚜막청국장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34876,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5731431789091,"longitude":127.055948144138,"bookYear":null,"nameKR":"엄나무집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":34877,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4922279155329,"longitude":127.029056271279,"bookYear":null,"nameKR":"들름집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":34878,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bodrijokbal/","foodTypes":"한식(육류)","latitude":37.6182876349063,"longitude":126.920924293549,"bookYear":null,"nameKR":"보들이족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34879,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/san_bapsang","foodTypes":"한식(일반한식)","latitude":37.6561351230062,"longitude":126.949238788923,"bookYear":null,"nameKR":"산들애건강밥상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34881,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.6530647634943,"longitude":126.942391284525,"bookYear":null,"nameKR":"산중다원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34882,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5225165988627,"longitude":126.891304981046,"bookYear":null,"nameKR":"시골쌈밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36784,"bookStatus":"커버","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4873967588158,"longitude":127.028496170379,"bookYear":null,"nameKR":"명선헌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":36236,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/mater_restaurant/","foodTypes":"컨템포러리","latitude":37.5232844070518,"longitude":127.034648302789,"bookYear":null,"nameKR":"마테르","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":36324,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.8041060106096,"longitude":127.108359399604,"bookYear":null,"nameKR":"춘천오리닭갈비","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":36813,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3732046962505,"longitude":127.068010463777,"bookYear":null,"nameKR":"세희네돈까스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":36809,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/damguri.official/","foodTypes":"기타","latitude":37.6390734809532,"longitude":126.673179663809,"bookYear":null,"nameKR":"담구리샤브칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":36593,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.6156118629923,"longitude":127.517378510536,"bookYear":null,"nameKR":"하누래(청주점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36592,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8294402582778,"longitude":127.133442715114,"bookYear":null,"nameKR":"하누래","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":36801,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/palgakjung_official/","foodTypes":"한식(육류)","latitude":36.3545109594013,"longitude":127.377818001059,"bookYear":null,"nameKR":"팔각정","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":7355,"bookStatus":"커버","websiteInstagram":"","foodTypes":"바/주점","latitude":37.50431,"longitude":127.0559,"bookYear":null,"nameKR":"고운님","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":36799,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5377363405254,"longitude":127.146230267567,"bookYear":null,"nameKR":"메이민","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36800,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cloud9.coffee/","foodTypes":"디저트/차/베이커리","latitude":33.4990357836808,"longitude":126.529277402113,"bookYear":null,"nameKR":"클라우드나인커피","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":36797,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pattypatty_burger/","foodTypes":"기타","latitude":37.5462361417851,"longitude":127.061612478688,"bookYear":null,"nameKR":"패티패티","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36796,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mercimadame_cafe/","foodTypes":"디저트/차/베이커리","latitude":35.1816079714725,"longitude":129.045884049597,"bookYear":null,"nameKR":"메르시마담","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":36795,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/imundong_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5954715513459,"longitude":127.057343593494,"bookYear":null,"nameKR":"이문동커피집크럼블로스터스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":36792,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yakitori_sooda/","foodTypes":"바/주점","latitude":37.5112868015958,"longitude":127.05719113004,"bookYear":null,"nameKR":"야키도리수다","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":36788,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.8148467849677,"longitude":128.605940675179,"bookYear":null,"nameKR":"두부마을택지2호점","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":35364,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.6599020883527,"longitude":127.301115378943,"bookYear":null,"nameKR":"삼거리다방","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":36785,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5688537151032,"longitude":126.965114852628,"bookYear":null,"nameKR":"합심","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":36779,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6400936562983,"longitude":127.025331073644,"bookYear":null,"nameKR":"칠복상회로스터스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":36778,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/meringue.patisserie","foodTypes":"디저트/차/베이커리","latitude":35.1661646215769,"longitude":129.15781821286,"bookYear":null,"nameKR":"머랭파티스리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":36775,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/kunshi_dong","foodTypes":"바/주점","latitude":37.5566974700972,"longitude":127.082668287716,"bookYear":null,"nameKR":"쿤시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":35763,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1799554812023,"longitude":129.072289317986,"bookYear":null,"nameKR":"마파람해물찜해물탕(시청직영점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":35762,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.2447595504152,"longitude":129.088584888713,"bookYear":null,"nameKR":"마파람해물찜해물탕(구서본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":36765,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5373857118299,"longitude":127.002909211408,"bookYear":null,"nameKR":"릴리언","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":36764,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5114567372249,"longitude":127.055477803419,"bookYear":null,"nameKR":"오더","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":36139,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5096776765406,"longitude":127.055536318832,"bookYear":null,"nameKR":"천미미(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":34910,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5693628646266,"longitude":126.985241260015,"bookYear":null,"nameKR":"죽마고돈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":31500,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/osteria_kochibi","foodTypes":"이탈리아식","latitude":37.579338125998,"longitude":126.968933386219,"bookYear":null,"nameKR":"고치비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":36760,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4859599519075,"longitude":127.035978381564,"bookYear":null,"nameKR":"국고집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36761,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5036380424601,"longitude":127.05221600974,"bookYear":null,"nameKR":"국고집(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":36758,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6552787315469,"longitude":127.061391730931,"bookYear":null,"nameKR":"한티옥(노원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":36757,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3899854320589,"longitude":126.950421554565,"bookYear":null,"nameKR":"한티옥(평촌점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35961,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4969569438366,"longitude":127.056248492743,"bookYear":null,"nameKR":"한티옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":36759,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5156680341198,"longitude":126.907665995469,"bookYear":null,"nameKR":"한티옥(영등포점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36751,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pumpkinowl_cafe","foodTypes":"디저트/차/베이커리","latitude":37.7578511571548,"longitude":128.893867445651,"bookYear":null,"nameKR":"펌킨오울","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":36750,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2782348046246,"longitude":127.043218629351,"bookYear":null,"nameKR":"우만동족발집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36186,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/izakaya_asoto/","foodTypes":"바/주점","latitude":37.5471374700642,"longitude":126.914227747204,"bookYear":null,"nameKR":"아소토","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36020,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coaster_coffee","foodTypes":"디저트/차/베이커리","latitude":35.0952062983669,"longitude":128.922868104764,"bookYear":null,"nameKR":"코스터커피","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":35385,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5101536926705,"longitude":127.020492126427,"bookYear":null,"nameKR":"파스타트리오","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":66,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.544849,"longitude":126.92581,"bookYear":null,"nameKR":"괴르츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":297,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.545739,"longitude":126.928298,"bookYear":null,"nameKR":"마차회집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":30573,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6189867339093,"longitude":127.046528885296,"bookYear":null,"nameKR":"16p","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":5277,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3473547141991,"longitude":127.369026885996,"bookYear":null,"nameKR":"영희네매운등갈비찜","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":21845,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.351647,"longitude":127.392687,"bookYear":null,"nameKR":"하레하레(둔산점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":35432,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4265533478018,"longitude":126.421227508077,"bookYear":null,"nameKR":"마시안제면소","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":35561,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_8.oli_/","foodTypes":"기타","latitude":37.5934613652406,"longitude":127.053071208373,"bookYear":null,"nameKR":"에잇올리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":32674,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/1st_espresso/","foodTypes":"디저트/차/베이커리","latitude":37.5860266549641,"longitude":127.060543519699,"bookYear":null,"nameKR":"퍼스트에스프레소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":36423,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.2074058862758,"longitude":127.062582541795,"bookYear":null,"nameKR":"홍익돈까스(동탄본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":36630,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.3119295387297,"longitude":126.831037062059,"bookYear":null,"nameKR":"인더비엣","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36604,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1001884856653,"longitude":129.017178050131,"bookYear":null,"nameKR":"몽실종가돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":36655,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jejumihyang_cheongnyangni/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5812760285375,"longitude":127.0432339069,"bookYear":null,"nameKR":"제주미향해장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":36694,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/royal___restaurant/","foodTypes":"기타","latitude":35.3094783092061,"longitude":128.984788819542,"bookYear":null,"nameKR":"로얄경양식&스테이크","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":36680,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":33.5169994762424,"longitude":126.523155739488,"bookYear":null,"nameKR":"미친부엌","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":36696,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.3100584244077,"longitude":128.984517377767,"bookYear":null,"nameKR":"라이온식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":36723,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6573936432322,"longitude":126.772404140408,"bookYear":null,"nameKR":"숯불에닭(웨스턴돔점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":36736,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/momiji_daejeon","foodTypes":"바/주점","latitude":36.3592619362646,"longitude":127.345737368344,"bookYear":null,"nameKR":"모미지","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":36749,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/cheffle_official","foodTypes":"디저트/차/베이커리","latitude":37.562830316373,"longitude":126.925644336395,"bookYear":null,"nameKR":"치플레","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":36087,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chilgok7/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4767616449233,"longitude":126.958532366945,"bookYear":null,"nameKR":"칠곡(낙성대본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":36733,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/slow_naju/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5614253772141,"longitude":126.97884383258,"bookYear":null,"nameKR":"금성관나주곰탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":36734,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/slow_naju/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4826840834852,"longitude":126.899902506754,"bookYear":null,"nameKR":"금성관나주곰탕(구로직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":36456,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/changsim_official/","foodTypes":"한식(육류)","latitude":36.6408211953852,"longitude":127.430713273272,"bookYear":null,"nameKR":"창심관(청주점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36043,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/2do_aging/","foodTypes":"한식(육류)","latitude":37.5016541917484,"longitude":126.934220313635,"bookYear":null,"nameKR":"2도에이징","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":35920,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_elsandwich/","foodTypes":"디저트/차/베이커리","latitude":37.5750546858003,"longitude":126.981766841584,"bookYear":null,"nameKR":"엘샌드위치(종로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":35915,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aewol_bakerycafe/","foodTypes":"디저트/차/베이커리","latitude":33.448306081357,"longitude":126.301560833469,"bookYear":null,"nameKR":"애월빵공장앤카페","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":35865,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/loveroom__official","foodTypes":"한식(어패류)","latitude":35.1611899140433,"longitude":129.193824920119,"bookYear":null,"nameKR":"사랑방(청사포점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":36289,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myeongin_deungsim/","foodTypes":"한식(육류)","latitude":37.5470013514751,"longitude":126.953756813538,"bookYear":null,"nameKR":"명인등심(마포직영점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36288,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myeongin_deungsim/","foodTypes":"한식(육류)","latitude":37.5114486889099,"longitude":127.056865845073,"bookYear":null,"nameKR":"명인등심(삼성직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":36287,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myeongin_deungsim/","foodTypes":"한식(육류)","latitude":37.5788467040194,"longitude":126.894766919894,"bookYear":null,"nameKR":"명인등심(상암직영점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":36045,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/2do_aging_sadang/","foodTypes":"한식(육류)","latitude":37.477113558418,"longitude":126.977874433857,"bookYear":null,"nameKR":"2도에이징(사당점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":35999,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/misotzeum/","foodTypes":"한식(일반한식)","latitude":37.1978203296795,"longitude":127.445811531535,"bookYear":null,"nameKR":"미솥지음","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":34417,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/me.meal_zip?igshid=MXByN3A5emoxNGI5ag%3D%3D&utm_source=qr","foodTypes":"한식(일반한식)","latitude":37.5101147039049,"longitude":127.108636541259,"bookYear":null,"nameKR":"메밀집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35855,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com.chaidle/","foodTypes":"중식","latitude":37.5109564586676,"longitude":127.018483467595,"bookYear":null,"nameKR":"차이들(논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":35798,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kinie_bakeshop/","foodTypes":"디저트/차/베이커리","latitude":37.6367256908892,"longitude":127.030274643553,"bookYear":null,"nameKR":"키니베이크샵","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":36742,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/olig_yeonnam/","foodTypes":"기타","latitude":37.5621675615769,"longitude":126.924819321443,"bookYear":null,"nameKR":"올리그","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":36741,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.4842856391064,"longitude":126.899282125859,"bookYear":null,"nameKR":"라마노","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":35794,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.570140174614,"longitude":127.006528555472,"bookYear":null,"nameKR":"서아당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":36739,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/januarypicnic/","foodTypes":"디저트/차/베이커리","latitude":37.5891411634006,"longitude":127.004145823037,"bookYear":null,"nameKR":"제뉴어리피크닉","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":36729,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rosniburger/","foodTypes":"기타","latitude":37.5353719029351,"longitude":126.98850987437,"bookYear":null,"nameKR":"로스니버거","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":35710,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/patisserie_ollo/","foodTypes":"디저트/차/베이커리","latitude":37.536748544981,"longitude":127.055580827958,"bookYear":null,"nameKR":"올로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35658,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/la.ficelle_","foodTypes":"디저트/차/베이커리","latitude":37.2887016141017,"longitude":127.061237557645,"bookYear":null,"nameKR":"라피셀","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35636,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/yangmi__official","foodTypes":"한식(육류)","latitude":37.3604029877478,"longitude":126.932274372096,"bookYear":null,"nameKR":"양미가옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":34679,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.3332572175667,"longitude":127.928600578278,"bookYear":null,"nameKR":"우동하루","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":36708,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1306007560264,"longitude":129.117841721094,"bookYear":null,"nameKR":"삼계명가 ","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":35092,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/kohinoor_hongdae?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr","foodTypes":"기타","latitude":37.5530939528403,"longitude":126.924353463531,"bookYear":null,"nameKR":"코이누르 홍대점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36722,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.3382167853439,"longitude":129.015174699989,"bookYear":null,"nameKR":"디저트카페이토","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":36717,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5300171522212,"longitude":126.997369499854,"bookYear":null,"nameKR":"이태원불꽃","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":36715,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.6117657328195,"longitude":127.466499802138,"bookYear":null,"nameKR":"산남동한우포차","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":34017,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi.blossom/","foodTypes":"일식","latitude":37.5282196781217,"longitude":127.123619088865,"bookYear":null,"nameKR":"스시꽃피다","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":34048,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vezza.official/","foodTypes":"이탈리아식","latitude":37.5717838064719,"longitude":126.92980791612,"bookYear":null,"nameKR":"베짜","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":34310,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/anuong_vietnamese/","foodTypes":"아시아식","latitude":37.5223503946319,"longitude":127.02431292218,"bookYear":null,"nameKR":"안웅","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":35541,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5030479410544,"longitude":126.76206803816,"bookYear":null,"nameKR":"이색어담","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":35602,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/centrale.de.facade_/","foodTypes":"기타","latitude":37.3143807255296,"longitude":126.839328587797,"bookYear":null,"nameKR":"센트랄드파사드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":35668,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/eoseureum_seoul?igshid=MzRlODBiNWFlZA==","foodTypes":"기타","latitude":37.5810058040942,"longitude":127.004735999074,"bookYear":null,"nameKR":"어스름","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":35842,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4457401365298,"longitude":127.057461463296,"bookYear":null,"nameKR":"부안애서","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":36099,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seop.sikdang","foodTypes":"일식","latitude":37.5493340295294,"longitude":126.914746464014,"bookYear":null,"nameKR":"스키야키201","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36115,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mariochef_yanglim/","foodTypes":"이탈리아식","latitude":35.1382723202655,"longitude":126.914174841179,"bookYear":null,"nameKR":"마리오셰프","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":36350,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.4835001713702,"longitude":126.928799854432,"bookYear":null,"nameKR":"꿈당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":36576,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2334843226529,"longitude":129.086197717792,"bookYear":null,"nameKR":"칠리앤버건디","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":36609,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4605145549358,"longitude":126.699036619044,"bookYear":null,"nameKR":"한우만","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":30991,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ikseon_jabbang/","foodTypes":"기타","latitude":37.5733847269877,"longitude":126.989442582006,"bookYear":null,"nameKR":"익선잡방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":9264,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5896722345334,"longitude":126.942486262935,"bookYear":null,"nameKR":"목포세발낙지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":11784,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.329574,"longitude":126.798266,"bookYear":null,"nameKR":"우성생고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11989,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.659897,"longitude":127.292614,"bookYear":null,"nameKR":"오리사냥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":13576,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.603181,"longitude":127.066938,"bookYear":null,"nameKR":"진도식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":14548,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.264697463315,"longitude":127.030873990619,"bookYear":null,"nameKR":"와인테라스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":15380,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5391348871096,"longitude":127.12605362186,"bookYear":null,"nameKR":"이모네","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":15937,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.560561,"longitude":126.92345,"bookYear":null,"nameKR":"나무그늘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":24583,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.264161,"longitude":127.030123,"bookYear":null,"nameKR":"별밤","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":25090,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.4626395207876,"longitude":128.135665200377,"bookYear":null,"nameKR":"횡성휴게소(강릉방향)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":26176,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.500362,"longitude":127.03433,"bookYear":null,"nameKR":"함초마을","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":29193,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5728587105399,"longitude":126.980753673658,"bookYear":null,"nameKR":"호가양꼬치","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29458,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ilsangdabansagalbijib/","foodTypes":"한식(육류)","latitude":35.1516657536145,"longitude":128.660958023235,"bookYear":null,"nameKR":"일상다반사갈비집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":29525,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/osteria_ora_","foodTypes":"이탈리아식","latitude":37.55637776249428,"longitude":126.91437650727966,"bookYear":null,"nameKR":"오스테리아오라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":35478,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.4003868266681,"longitude":126.919463454058,"bookYear":null,"nameKR":"일프리모","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":36713,"bookStatus":"커버","websiteInstagram":"https://www.instagram.com/room.tove","foodTypes":"디저트/차/베이커리","latitude":37.5753439185471,"longitude":126.983707418012,"bookYear":null,"nameKR":"토오베","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":9638,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.7734780244318,"longitude":126.602622241701,"bookYear":null,"nameKR":"수림가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":16060,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3899854320589,"longitude":126.950421554565,"bookYear":null,"nameKR":"옵스(롯데평촌점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":34091,"bookStatus":"커버","websiteInstagram":"","foodTypes":"컨템포러리","latitude":37.5246740736383,"longitude":127.041990425496,"bookYear":null,"nameKR":"그리에","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32313,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffee.love.sommelier/","foodTypes":"디저트/차/베이커리","latitude":37.553966335752,"longitude":126.976689126185,"bookYear":null,"nameKR":"커피를사랑한소믈리애","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":24278,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.554819,"longitude":126.976938,"bookYear":null,"nameKR":"엉클조 소시지 전문점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":29317,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.55063,"longitude":127.001105,"bookYear":null,"nameKR":"문바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":29318,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.550048,"longitude":127.000413,"bookYear":null,"nameKR":"그라넘다이닝라운지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":4866,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.545326,"longitude":126.984741,"bookYear":null,"nameKR":"해방촌노아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":9191,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.541773,"longitude":126.987402,"bookYear":null,"nameKR":"자코비버거","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":24896,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.54139745696838,"longitude":126.98701013679425,"bookYear":null,"nameKR":"필리스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":27459,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아프리카식","latitude":37.541642540800325,"longitude":126.98711872066315,"bookYear":null,"nameKR":"카사블랑카","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":28379,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.545062,"longitude":126.985011,"bookYear":null,"nameKR":"오랑오랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31187,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/le_montblanc/","foodTypes":"디저트/차/베이커리","latitude":37.5449087560253,"longitude":126.984857160188,"bookYear":null,"nameKR":"르몽블랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31941,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yunjudang","foodTypes":"바/주점","latitude":37.5453534474955,"longitude":126.98597835382,"bookYear":null,"nameKR":"윤주당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":32828,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/decalcomanie0815/","foodTypes":"유럽식","latitude":37.5452666383266,"longitude":126.984899408877,"bookYear":null,"nameKR":"데칼코마니","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30046,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.53378967346722,"longitude":126.98968157512934,"bookYear":null,"nameKR":"라이너스바베큐","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30065,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.534806233454404,"longitude":126.99279510729005,"bookYear":null,"nameKR":"야상해","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30066,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/analogsocietykitchen","foodTypes":"이탈리아식","latitude":37.53061858167045,"longitude":126.99791830066114,"bookYear":null,"nameKR":"아날로그소사이어티키친","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30208,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yakiniku_somun/","foodTypes":"일식","latitude":37.5261784599345,"longitude":126.99911759280263,"bookYear":null,"nameKR":"소문","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30267,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sulkkoma","foodTypes":"바/주점","latitude":37.5344770924284,"longitude":126.989181846842,"bookYear":null,"nameKR":"술꼬마","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30316,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ilhochic.hannam/","foodTypes":"한식(일반한식)","latitude":37.533750460636796,"longitude":127.00214967072546,"bookYear":null,"nameKR":"일호식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30385,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wegintixix/","foodTypes":"디저트/차/베이커리","latitude":37.53354829652798,"longitude":126.99078585730906,"bookYear":null,"nameKR":"위긴티식스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30549,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.53489272897758,"longitude":126.99278604758558,"bookYear":null,"nameKR":"야키도리고우이태원본점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30718,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gongi.seoul","foodTypes":"한식(일반한식)","latitude":37.5361993984782,"longitude":126.999342744969,"bookYear":null,"nameKR":"공기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":30719,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kyoyangsiksa","foodTypes":"한식(육류)","latitude":37.5359194593876,"longitude":126.999916273557,"bookYear":null,"nameKR":"교양식사","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31188,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baaa_bakeshop/","foodTypes":"디저트/차/베이커리","latitude":37.5355258112657,"longitude":127.000476672498,"bookYear":null,"nameKR":"바아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31243,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rocka_doodle","foodTypes":"기타","latitude":37.5348743233902,"longitude":126.988037242342,"bookYear":null,"nameKR":"롸카두들내쉬빌핫치킨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31250,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistro_fran","foodTypes":"이탈리아식","latitude":37.5357148391553,"longitude":127.000843824463,"bookYear":null,"nameKR":"비스트로프란","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31274,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woorahman","foodTypes":"한식(육류)","latitude":37.5432350979953,"longitude":126.990379231288,"bookYear":null,"nameKR":"우라만","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31294,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lounge_ciel/","foodTypes":"이탈리아식","latitude":37.5424239363147,"longitude":126.993067753562,"bookYear":null,"nameKR":"라운지씨엘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31378,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5363039460928,"longitude":126.987224071304,"bookYear":null,"nameKR":"커피나인(이태원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31508,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/conhascoffee_co.ltd/","foodTypes":"디저트/차/베이커리","latitude":37.5368522490066,"longitude":126.99803840079,"bookYear":null,"nameKR":"콘하스한남","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31621,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nimbus.coffee","foodTypes":"디저트/차/베이커리","latitude":37.5326394973629,"longitude":126.992649705982,"bookYear":null,"nameKR":"님부스커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31739,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.5334210600049,"longitude":127.001833208504,"bookYear":null,"nameKR":"세미계","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31784,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/summerlane_brunch","foodTypes":"호주식","latitude":37.5363596860333,"longitude":126.999266710454,"bookYear":null,"nameKR":"써머레인","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":31946,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/souslegui/","foodTypes":"디저트/차/베이커리","latitude":37.5360290203821,"longitude":127.000409807593,"bookYear":null,"nameKR":"수르기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":32078,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/otigelle/","foodTypes":"디저트/차/베이커리","latitude":37.5332553510698,"longitude":126.989357050611,"bookYear":null,"nameKR":"오띠젤리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":32601,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aufglet/","foodTypes":"디저트/차/베이커리","latitude":37.5368986582972,"longitude":127.001419068613,"bookYear":null,"nameKR":"아우프글렛","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":32906,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cleorestaurantseoul/","foodTypes":"유럽식","latitude":37.5285655568387,"longitude":126.993107501389,"bookYear":null,"nameKR":"클레오","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":32935,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maaddok/","foodTypes":"바/주점","latitude":37.533186066559,"longitude":126.990439241797,"bookYear":null,"nameKR":"이태원주식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33167,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mutin_bar/","foodTypes":"바/주점","latitude":37.5335266626566,"longitude":127.001449439441,"bookYear":null,"nameKR":"뮤땅","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33400,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5285655568387,"longitude":126.993107501389,"bookYear":null,"nameKR":"카페인신현리탭앤그릴","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33546,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5337184260957,"longitude":126.991053639979,"bookYear":null,"nameKR":"스탑사인","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33609,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5323221756006,"longitude":126.994505998962,"bookYear":null,"nameKR":"커피오브콜스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33639,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ssong_thai/","foodTypes":"아시아식","latitude":37.5337282690125,"longitude":126.992681617899,"bookYear":null,"nameKR":"쏭타이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33761,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5347412197711,"longitude":126.991995086031,"bookYear":null,"nameKR":"흥미식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33767,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5397629769285,"longitude":126.996240829219,"bookYear":null,"nameKR":"카우리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":33768,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5397629769285,"longitude":126.996240829219,"bookYear":null,"nameKR":"테판","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":34172,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.536480816948,"longitude":126.989063208046,"bookYear":null,"nameKR":"마메종394","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":34276,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/patisserie.pond/","foodTypes":"디저트/차/베이커리","latitude":37.5368027102701,"longitude":127.000015614034,"bookYear":null,"nameKR":"파티세리폰드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":34402,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":37.5338080297208,"longitude":126.98940867735,"bookYear":null,"nameKR":"네키드윙즈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":35308,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.534279444277,"longitude":126.99323505159,"bookYear":null,"nameKR":"알페도","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":35379,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dangol_house/","foodTypes":"바/주점","latitude":37.5340576174873,"longitude":126.993228622588,"bookYear":null,"nameKR":"이태원단골집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":35504,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/lavieencoco","foodTypes":"디저트/차/베이커리","latitude":37.5344587516142,"longitude":126.988643974163,"bookYear":null,"nameKR":"라비앙코코","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":36033,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/midnightmarket_itw/","foodTypes":"아시아식","latitude":37.5340841745651,"longitude":126.994534381258,"bookYear":null,"nameKR":"미드나잇마켓","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":435,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"프랑스식","latitude":37.537639,"longitude":126.994816,"bookYear":null,"nameKR":"비손","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":547,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5361335360386,"longitude":126.999484515185,"bookYear":null,"nameKR":"시골밥상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":1050,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.53548,"longitude":126.9993,"bookYear":null,"nameKR":"문타로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":1083,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.533267,"longitude":127.003036,"bookYear":null,"nameKR":"도이치하우스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":1303,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.539511,"longitude":126.997685,"bookYear":null,"nameKR":"텐카이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":1359,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중남미식","latitude":37.53367,"longitude":126.994,"bookYear":null,"nameKR":"타코아미고","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":1410,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.53951,"longitude":126.9977,"bookYear":null,"nameKR":"JJ마호니스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":3519,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.53368,"longitude":126.9954,"bookYear":null,"nameKR":"타지팰리스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":4682,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.53456,"longitude":126.991,"bookYear":null,"nameKR":"아그라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":4735,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5377376793044,"longitude":126.986960421442,"bookYear":null,"nameKR":"스탠딩커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":4916,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.535627,"longitude":126.99882,"bookYear":null,"nameKR":"천상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":5194,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5351061429632,"longitude":126.993789036287,"bookYear":null,"nameKR":"단풍나무집(이태원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":5283,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"유럽식","latitude":37.534001,"longitude":126.994207,"bookYear":null,"nameKR":"술탄케밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":5872,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.533631,"longitude":126.997058,"bookYear":null,"nameKR":"페르시안랜드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":6544,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.53379,"longitude":126.9894,"bookYear":null,"nameKR":"디엘리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":6557,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":37.53407458578776,"longitude":126.99224304933345,"bookYear":null,"nameKR":"트로이카","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":6727,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"유럽식","latitude":37.5343,"longitude":126.994271,"bookYear":null,"nameKR":"앙카라피크닉","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":6883,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.535071,"longitude":126.993765,"bookYear":null,"nameKR":"프로스트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":6884,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.535071,"longitude":126.993765,"bookYear":null,"nameKR":"글램","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":7025,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"유럽식","latitude":37.533266,"longitude":126.997554,"bookYear":null,"nameKR":"쌀람베이커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":7159,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5247001790381,"longitude":126.993300398993,"bookYear":null,"nameKR":"다채(동빙고점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":7316,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.533676,"longitude":127.00325,"bookYear":null,"nameKR":"동아냉면(이태원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":7647,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":37.534549,"longitude":126.998127,"bookYear":null,"nameKR":"스페인클럽(이태원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":8384,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.539442,"longitude":126.99732,"bookYear":null,"nameKR":"테라스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":12658,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.539741,"longitude":126.989567,"bookYear":null,"nameKR":"후렌드치킨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":12822,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.53767,"longitude":126.994902,"bookYear":null,"nameKR":"구스테이크733","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":12873,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중남미식","latitude":37.5339523956018,"longitude":126.989697279291,"bookYear":null,"nameKR":"코파카바나그릴","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":12874,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.54102,"longitude":126.989857,"bookYear":null,"nameKR":"워터스트리트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":12912,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.540798,"longitude":126.991794,"bookYear":null,"nameKR":"장진우식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":18384,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.53291,"longitude":126.995366,"bookYear":null,"nameKR":"한도니","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":19728,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chansbros/","foodTypes":"디저트/차/베이커리","latitude":37.53751,"longitude":126.987,"bookYear":null,"nameKR":"찬스브로스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":22475,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/harmonium.seoul/","foodTypes":"이탈리아식","latitude":37.533806,"longitude":127.001723,"bookYear":null,"nameKR":"아르모니움","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":22948,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5349726865538,"longitude":127.00055892351,"bookYear":null,"nameKR":"바바라스키친","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":23474,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.539807,"longitude":126.995599,"bookYear":null,"nameKR":"케냐 키암부","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":23558,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.539741,"longitude":126.989567,"bookYear":null,"nameKR":"살롱프라이드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":25412,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_roseandcrown/","foodTypes":"바/주점","latitude":37.534684,"longitude":126.991968,"bookYear":null,"nameKR":"로즈앤크라운","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":26563,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.540391,"longitude":126.994238,"bookYear":null,"nameKR":"서울루덴스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":26738,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":37.534891197166,"longitude":126.992784350599,"bookYear":null,"nameKR":"베이비기네스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":26870,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.535925,"longitude":126.999943,"bookYear":null,"nameKR":"어메이징타이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":26951,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5408,"longitude":126.9918,"bookYear":null,"nameKR":"그랑블루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":26968,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"유럽식","latitude":37.53436667407795,"longitude":126.99537025000907,"bookYear":null,"nameKR":"케르반레스토랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":28263,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cotejardin_/","foodTypes":"프랑스식","latitude":37.533406,"longitude":126.994589,"bookYear":null,"nameKR":"꾸띠자르당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":28346,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/changsmathilda/","foodTypes":"이탈리아식","latitude":37.540798,"longitude":126.991794,"bookYear":null,"nameKR":"마틸다","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":28441,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5345988793062,"longitude":126.990989609661,"bookYear":null,"nameKR":"오리지널팬케이크하우스(이태원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":28538,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ahn.mak/","foodTypes":"바/주점","latitude":37.53858637966524,"longitude":126.98740437904347,"bookYear":null,"nameKR":"안씨막걸리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":28620,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sohalounge/","foodTypes":"기타","latitude":37.533403,"longitude":127.001685,"bookYear":null,"nameKR":"소하","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":28945,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/villadelavino_official/","foodTypes":"이탈리아식","latitude":37.53363483407724,"longitude":126.99133344614759,"bookYear":null,"nameKR":"빌라드라비노","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":29060,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chflo_seoul/, https://www.cheeseflo.com/","foodTypes":"기타","latitude":37.536958,"longitude":126.999428,"bookYear":null,"nameKR":"치즈플로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":29096,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/casa684/","foodTypes":"바/주점","latitude":37.535199,"longitude":127.00096,"bookYear":null,"nameKR":"까사684","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":29175,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ilchiasso.seoul/","foodTypes":"이탈리아식","latitude":37.5346547281027,"longitude":126.988735928836,"bookYear":null,"nameKR":"일키아쏘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":29290,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":37.540971,"longitude":126.990627,"bookYear":null,"nameKR":"모멘토스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":29291,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tapasbar15/","foodTypes":"바/주점","latitude":37.534826,"longitude":126.992534,"bookYear":null,"nameKR":"타파스바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":29696,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rooteveryday","foodTypes":"기타","latitude":37.537354482947876,"longitude":126.99995474159623,"bookYear":null,"nameKR":"루트에브리데이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":29765,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kyeri_official","foodTypes":"디저트/차/베이커리","latitude":37.5333682588505,"longitude":126.99317992158008,"bookYear":null,"nameKR":"키에리(이태원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":29791,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.535163025039715,"longitude":126.99271813593,"bookYear":null,"nameKR":"마라토끼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":29861,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/janggang_itaewon/","foodTypes":"중식","latitude":37.5347110796635,"longitude":126.989152622402,"bookYear":null,"nameKR":"장강중류","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"19. 이태원/해방촌/동빙고","status":"ACTIVE"},
        {"id":130,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.55682,"longitude":126.9852,"bookYear":null,"nameKR":"남산골산채집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":226,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5643,"longitude":126.984116,"bookYear":null,"nameKR":"딘타이펑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":385,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.563345,"longitude":126.984085,"bookYear":null,"nameKR":"백제삼계탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":660,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5616935654071,"longitude":126.987397434068,"bookYear":null,"nameKR":"오우가","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":816,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5634197792853,"longitude":126.985035755931,"bookYear":null,"nameKR":"충무김밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":1391,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.563166,"longitude":126.985083,"bookYear":null,"nameKR":"명동해물탕어머니집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":3958,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.556741,"longitude":126.984914,"bookYear":null,"nameKR":"남산설렁탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":4721,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.563814,"longitude":126.985804,"bookYear":null,"nameKR":"레오니다스명동점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":4740,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.56096650359068,"longitude":126.98213992676158,"bookYear":null,"nameKR":"란주라미엔","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":4951,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.563246,"longitude":126.985013,"bookYear":null,"nameKR":"흑산도","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":4960,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nobizib_myeongdong/","foodTypes":"한식(육류)","latitude":37.565491,"longitude":126.983762,"bookYear":null,"nameKR":"너비집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":4990,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.562555,"longitude":126.983944,"bookYear":null,"nameKR":"명화당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":5119,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.562861,"longitude":126.983166,"bookYear":null,"nameKR":"가무","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":5612,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5629034813413,"longitude":126.982240538252,"bookYear":null,"nameKR":"소공분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":6974,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.56212,"longitude":126.9819,"bookYear":null,"nameKR":"향미명동점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":7032,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5646259701011,"longitude":126.984546206216,"bookYear":null,"nameKR":"명동할머니국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":7057,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.562802,"longitude":126.985016,"bookYear":null,"nameKR":"쯔루하시후게츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":7286,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.563422,"longitude":126.98408,"bookYear":null,"nameKR":"진사댁(명동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":7890,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.559769,"longitude":126.985776,"bookYear":null,"nameKR":"뚱뚱이족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":8435,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.5633439566333,"longitude":126.985364800771,"bookYear":null,"nameKR":"유가네(명동1호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":8437,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5633577545593,"longitude":126.98546745716,"bookYear":null,"nameKR":"다도해물집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":8440,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.562408,"longitude":126.984106,"bookYear":null,"nameKR":"아미소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":8469,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.557194,"longitude":126.986083,"bookYear":null,"nameKR":"원조남산왕돈까스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":8471,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5584172427962,"longitude":126.987142769841,"bookYear":null,"nameKR":"커피쉬","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":8472,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.557094,"longitude":126.985791,"bookYear":null,"nameKR":"커피앤틱","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":8683,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.563637,"longitude":126.985316,"bookYear":null,"nameKR":"안동찜닭","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":8858,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.565599,"longitude":126.983694,"bookYear":null,"nameKR":"할머니집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":12808,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.562638,"longitude":126.982436,"bookYear":null,"nameKR":"명동회관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":12810,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.56449,"longitude":126.9846,"bookYear":null,"nameKR":"원조한치","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":12811,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.564145,"longitude":126.98295,"bookYear":null,"nameKR":"라따블","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":12828,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.561393,"longitude":126.983444,"bookYear":null,"nameKR":"오다리집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":13216,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/minamiyama76/","foodTypes":"일식","latitude":37.557213,"longitude":126.985943,"bookYear":null,"nameKR":"미나미야마","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":14331,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.56566,"longitude":126.984,"bookYear":null,"nameKR":"묵호회집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":14407,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.559513,"longitude":126.984069,"bookYear":null,"nameKR":"삼호정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":16287,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.558936,"longitude":126.986102,"bookYear":null,"nameKR":"대나무집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":18618,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5651679495987,"longitude":126.984095832147,"bookYear":null,"nameKR":"세이슌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":24726,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.56308,"longitude":126.9837,"bookYear":null,"nameKR":"포엠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":29554,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.56341011693503,"longitude":126.98288636707568,"bookYear":null,"nameKR":"타이가든(명동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":29833,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hongmandang_myeongdong/","foodTypes":"디저트/차/베이커리","latitude":37.563161726011735,"longitude":126.98497355578199,"bookYear":null,"nameKR":"홍만당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":31528,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"컨템포러리","latitude":37.5606709890002,"longitude":126.986273829589,"bookYear":null,"nameKR":"룸201","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":32054,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5650216235093,"longitude":126.985460003108,"bookYear":null,"nameKR":"뱅커스클럽바이반얀트리 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":33989,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lotus_7_20/","foodTypes":"디저트/차/베이커리","latitude":37.5636568796353,"longitude":126.987804798513,"bookYear":null,"nameKR":"로투스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":34403,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5596783143009,"longitude":126.985880372497,"bookYear":null,"nameKR":"사유의서재(남산점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":36025,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bluebottlekorea/","foodTypes":"디저트/차/베이커리","latitude":37.5634116488196,"longitude":126.98288772495,"bookYear":null,"nameKR":"블루보틀(명동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":36418,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5605071691249,"longitude":126.985368298373,"bookYear":null,"nameKR":"아트몬스터(명동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"18. 명동/남산","status":"ACTIVE"},
        {"id":29272,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mishmashkorea/","foodTypes":"한식(일반한식)","latitude":37.5796581438292,"longitude":126.989143953986,"bookYear":null,"nameKR":"미쉬매쉬","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":200,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.52114,"longitude":126.9673,"bookYear":null,"nameKR":"동강","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":628,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.519651,"longitude":126.973888,"bookYear":null,"nameKR":"열해","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":943,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.52015,"longitude":126.967663,"bookYear":null,"nameKR":"하나스시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":1135,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.521138,"longitude":126.967331,"bookYear":null,"nameKR":"봉자네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":1226,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5180294947051,"longitude":126.978694898683,"bookYear":null,"nameKR":"와세다야","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":3847,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.520366,"longitude":126.974264,"bookYear":null,"nameKR":"한강치킨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":3848,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5207802377583,"longitude":126.974503436949,"bookYear":null,"nameKR":"니와","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":3849,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.520789,"longitude":126.974946,"bookYear":null,"nameKR":"스마일이촌떡볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":4122,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.524022,"longitude":126.968607,"bookYear":null,"nameKR":"옛집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":6032,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.518184,"longitude":126.977748,"bookYear":null,"nameKR":"차이옌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":6059,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.52065,"longitude":126.9677,"bookYear":null,"nameKR":"경일옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":6902,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.519983,"longitude":126.974159,"bookYear":null,"nameKR":"스즈란테이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":7942,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.518091,"longitude":126.978714,"bookYear":null,"nameKR":"동문우동전문점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":13480,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.520379,"longitude":126.974271,"bookYear":null,"nameKR":"나무와화덕피자집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":19515,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.523334,"longitude":126.969609,"bookYear":null,"nameKR":"풍미향","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":21770,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.519983,"longitude":126.974159,"bookYear":null,"nameKR":"카펜터스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":26652,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5200049152766,"longitude":126.969871897307,"bookYear":null,"nameKR":"쉐이드트리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":29418,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.520745,"longitude":126.975216,"bookYear":null,"nameKR":"솜씨이촌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":31898,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5205101179976,"longitude":126.974928635952,"bookYear":null,"nameKR":"르미야","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":31937,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sumzip_yongsan/","foodTypes":"한식(일반한식)","latitude":37.524406974427,"longitude":126.965380477811,"bookYear":null,"nameKR":"섬집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":34315,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yamytang.museum/","foodTypes":"한식(일반한식)","latitude":37.5247022357875,"longitude":126.977740246542,"bookYear":null,"nameKR":"야미당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":35651,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5208539475824,"longitude":126.974960986659,"bookYear":null,"nameKR":"스노우볼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"23. 이촌동/서빙고","status":"ACTIVE"},
        {"id":32937,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/codeinecoffee/","foodTypes":"디저트/차/베이커리","latitude":33.2350777693604,"longitude":126.296279745532,"bookYear":null,"nameKR":"코데인커피로스터스 ","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":34582,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeesketch_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.233371144923,"longitude":126.313291313646,"bookYear":null,"nameKR":"커피스케치","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":35161,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.2807928230905,"longitude":126.285370709694,"bookYear":null,"nameKR":"돈어길","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":35531,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/perlo_jeju/","foodTypes":"이탈리아식","latitude":33.2567330298804,"longitude":126.306135218185,"bookYear":null,"nameKR":"페를로","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":2629,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.219127,"longitude":126.251312,"bookYear":null,"nameKR":"항구식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":9746,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":33.225885,"longitude":126.255823,"bookYear":null,"nameKR":"하르방밀면","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":9766,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.2204010998315,"longitude":126.251542444489,"bookYear":null,"nameKR":"모슬포해녀식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":9788,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/olangjeju/","foodTypes":"한식(어패류)","latitude":33.221056,"longitude":126.252939,"bookYear":null,"nameKR":"올랭이와물꾸럭","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":9804,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.245869,"longitude":126.330793,"bookYear":null,"nameKR":"생원전복","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":21913,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ygim20209/","foodTypes":"기타","latitude":33.286732,"longitude":126.22275,"bookYear":null,"nameKR":"황금륭버거","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":24855,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.264789,"longitude":126.370777,"bookYear":null,"nameKR":"춘심이네","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"11. 서귀포시(대정/안덕)","status":"ACTIVE"},
        {"id":9293,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":33.323448,"longitude":126.84478,"bookYear":null,"nameKR":"바99","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":9794,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.273116,"longitude":126.654068,"bookYear":null,"nameKR":"한라흑돼지","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":9795,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":33.278605,"longitude":126.719585,"bookYear":null,"nameKR":"포항아구찜","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":9808,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.394031,"longitude":126.800642,"bookYear":null,"nameKR":"돌집식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":9812,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.325692,"longitude":126.829403,"bookYear":null,"nameKR":"우림가든","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":10351,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.3263223249256,"longitude":126.84404956955,"bookYear":null,"nameKR":"금데기횟집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":10353,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.324982,"longitude":126.844289,"bookYear":null,"nameKR":"해미원","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":21434,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.269437,"longitude":126.655663,"bookYear":null,"nameKR":"서연의집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":22687,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.272362,"longitude":126.676564,"bookYear":null,"nameKR":"와랑와랑","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":22724,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":33.413218,"longitude":126.770571,"bookYear":null,"nameKR":"고향길든솔","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":23306,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.325243,"longitude":126.844619,"bookYear":null,"nameKR":"표선어촌식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":26225,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.3247987513617,"longitude":126.843976351289,"bookYear":null,"nameKR":"흑돼지한마당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":26544,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.393328,"longitude":126.801232,"bookYear":null,"nameKR":"만덕이네","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":28233,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.323322,"longitude":126.844474,"bookYear":null,"nameKR":"마고","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":33394,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_waiting_dongas/","foodTypes":"일식","latitude":33.3179650530561,"longitude":126.82813842007,"bookYear":null,"nameKR":"더웨이팅","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":34232,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rubelin_lounge/","foodTypes":"디저트/차/베이커리","latitude":33.2723234007331,"longitude":126.683835038476,"bookYear":null,"nameKR":"루브린","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":35957,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/la__lune_bleue","foodTypes":"디저트/차/베이커리","latitude":33.2725925735685,"longitude":126.65076224575,"bookYear":null,"nameKR":"라룬블루","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":36125,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sundaisy_cafe","foodTypes":"기타","latitude":33.2754211305226,"longitude":126.709284064391,"bookYear":null,"nameKR":"썬데이지","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"10. 서귀포시(남원/표선)","status":"ACTIVE"},
        {"id":31633,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thecliffjeju/","foodTypes":"디저트/차/베이커리","latitude":33.2450403542925,"longitude":126.415599158242,"bookYear":null,"nameKR":"더클리프","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":32785,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/314mile","foodTypes":"기타","latitude":33.2497917330142,"longitude":126.430271762051,"bookYear":null,"nameKR":"314마일","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":33887,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/littleboracay.jeju/","foodTypes":"기타","latitude":33.2585732100881,"longitude":126.413963378095,"bookYear":null,"nameKR":"리틀보라카이","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":34121,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seafood_dining/","foodTypes":"일식","latitude":33.2450403542925,"longitude":126.415599158242,"bookYear":null,"nameKR":"항해진미","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":35620,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/peraeng__","foodTypes":"한식(어패류)","latitude":33.2574015225199,"longitude":126.415661855314,"bookYear":null,"nameKR":"제주횟집퍼랭","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":36636,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/mur_jeju","foodTypes":"디저트/차/베이커리","latitude":33.2449617417414,"longitude":126.387530606245,"bookYear":null,"nameKR":"뮈르","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":2570,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":33.2492408909928,"longitude":126.430046452651,"bookYear":null,"nameKR":"덕성원(중문점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":7452,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/deomjang/","foodTypes":"한식(일반한식)","latitude":33.257663,"longitude":126.40828,"bookYear":null,"nameKR":"덤장(중문점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":9338,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":33.249332,"longitude":126.408394,"bookYear":null,"nameKR":"라비타","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":9351,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":33.249332,"longitude":126.408394,"bookYear":null,"nameKR":"라테라스","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":9396,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":33.248535,"longitude":126.410394,"bookYear":null,"nameKR":"페닌슐라(롯데호텔제주)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":9755,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.253168,"longitude":126.420266,"bookYear":null,"nameKR":"일광회센타","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":9756,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.239079,"longitude":126.439547,"bookYear":null,"nameKR":"대포동산횟집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":9782,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.249678,"longitude":126.411363,"bookYear":null,"nameKR":"신우성흑돼지","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":9840,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.251901,"longitude":126.426607,"bookYear":null,"nameKR":"해심가든","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":9843,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.240309,"longitude":126.428668,"bookYear":null,"nameKR":"대기정","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":9847,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.255072,"longitude":126.416465,"bookYear":null,"nameKR":"제주미향","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":10347,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":33.2515291356409,"longitude":126.432692320092,"bookYear":null,"nameKR":"사해방흑돼지","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":10349,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.255094,"longitude":126.416633,"bookYear":null,"nameKR":"흑돼지구이집하영","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":14623,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.2557026953152,"longitude":126.414666453661,"bookYear":null,"nameKR":"제주오성","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":22111,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.255057,"longitude":126.416224,"bookYear":null,"nameKR":"이조은식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"09. 서귀포시(중문)","status":"ACTIVE"},
        {"id":35662,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/paulee_jeju/","foodTypes":"바/주점","latitude":33.4872450653419,"longitude":126.492268868212,"bookYear":null,"nameKR":"폴레","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":35755,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.3236342798791,"longitude":126.260639911558,"bookYear":null,"nameKR":"돈뜰","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":23741,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.393627,"longitude":126.239904,"bookYear":null,"nameKR":"한림해촌","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":9312,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":33.505845,"longitude":126.526596,"bookYear":null,"nameKR":"한라","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":14394,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":33.490195,"longitude":126.536061,"bookYear":null,"nameKR":"산방식당(제주점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":22640,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":33.486222,"longitude":126.699718,"bookYear":null,"nameKR":"하늘보리","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":25230,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":33.426226,"longitude":126.675173,"bookYear":null,"nameKR":"도리골토종닭","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":12892,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.486212,"longitude":126.492702,"bookYear":null,"nameKR":"털보산해횟집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":12896,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.490504,"longitude":126.494126,"bookYear":null,"nameKR":"자구내","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":12906,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":33.487483,"longitude":126.49784,"bookYear":null,"nameKR":"맛장터해장국","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":13678,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.488888,"longitude":126.429609,"bookYear":null,"nameKR":"사방팔방","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":15357,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.48381,"longitude":126.395618,"bookYear":null,"nameKR":"하르방","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":22391,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.486674,"longitude":126.488169,"bookYear":null,"nameKR":"엄마손횟집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":10332,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.472912,"longitude":126.484738,"bookYear":null,"nameKR":"광원","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":10333,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":33.479356,"longitude":126.481519,"bookYear":null,"nameKR":"양촌리마을","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":10338,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.486041,"longitude":126.542753,"bookYear":null,"nameKR":"제주축협한우프라자","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":21933,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.491524,"longitude":126.52702,"bookYear":null,"nameKR":"대원가","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":25225,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.491729,"longitude":126.543116,"bookYear":null,"nameKR":"태백산(3호점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":35753,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/complete_coffee/","foodTypes":"디저트/차/베이커리","latitude":33.480619352235,"longitude":126.478181494825,"bookYear":null,"nameKR":"컴플리트커피(노형점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":36206,"bookStatus":"비활성","websiteInstagram":"https://www./instagram.com/seeker_jeju","foodTypes":"디저트/차/베이커리","latitude":33.478519739375,"longitude":126.475153210682,"bookYear":null,"nameKR":"씨커","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":7755,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myungdangbakery/","foodTypes":"디저트/차/베이커리","latitude":33.485569,"longitude":126.472054,"bookYear":null,"nameKR":"명당양과(노형점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":9834,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":33.252898,"longitude":126.557946,"bookYear":null,"nameKR":"대일추어탕","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":9833,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":33.2544,"longitude":126.569438,"bookYear":null,"nameKR":"제주손칼국수냉면전문점","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":4597,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/millimwon/","foodTypes":"한식(육류)","latitude":33.482373,"longitude":126.653914,"bookYear":null,"nameKR":"밀림원","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":10341,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":33.545067,"longitude":126.686445,"bookYear":null,"nameKR":"정순화손두부","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":16412,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":33.436305,"longitude":126.676663,"bookYear":null,"nameKR":"안다미로식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":20640,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.5388510274446,"longitude":126.669302236558,"bookYear":null,"nameKR":"청진동해물탕","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":29851,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafedongbaek_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.5090143244165,"longitude":126.714416905275,"bookYear":null,"nameKR":"카페동백","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":30420,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.5394852368603,"longitude":126.670225953279,"bookYear":null,"nameKR":"곱들락","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":31813,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bone_blackpork/","foodTypes":"한식(육류)","latitude":33.5436381450771,"longitude":126.658710933927,"bookYear":null,"nameKR":"흑본오겹함덕점","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":32414,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/audrant333/","foodTypes":"디저트/차/베이커리","latitude":33.5421352880566,"longitude":126.671582949609,"bookYear":null,"nameKR":"오드랑베이커리","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":32739,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.5502160237072,"longitude":126.694782417941,"bookYear":null,"nameKR":"알마커피제작소","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":34174,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.4692414262647,"longitude":126.663830598494,"bookYear":null,"nameKR":"세미식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":35501,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.rollercoast","foodTypes":"디저트/차/베이커리","latitude":33.5435976653753,"longitude":126.674517450616,"bookYear":null,"nameKR":"롤러코스트","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":35517,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/okranok_jeju","foodTypes":"한식(면류)","latitude":33.5355652577303,"longitude":126.630821320602,"bookYear":null,"nameKR":"옥란면옥","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":35946,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.5377933635964,"longitude":126.638369215516,"bookYear":null,"nameKR":"무우수커피로스터스","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"05. 제주시(함덕/조천)","status":"ACTIVE"},
        {"id":32766,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mebakery.cafe/","foodTypes":"디저트/차/베이커리","latitude":33.2360012201748,"longitude":126.498828917545,"bookYear":null,"nameKR":"미베이커리카페","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":32848,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/donblack_/","foodTypes":"한식(육류)","latitude":33.2487127260843,"longitude":126.514996264479,"bookYear":null,"nameKR":"돈블랙서귀포본점","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":34839,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/playcoffee.lab/","foodTypes":"디저트/차/베이커리","latitude":33.250593390868,"longitude":126.558811586163,"bookYear":null,"nameKR":"플레이커피랩","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":35508,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/88burger/","foodTypes":"기타","latitude":33.2492475189286,"longitude":126.567122293048,"bookYear":null,"nameKR":"88버거","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":35934,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chaejeomseok_bakery_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.2484758517256,"longitude":126.514160374134,"bookYear":null,"nameKR":"채점석베이커리&카페","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":35991,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.2468268701551,"longitude":126.557857908504,"bookYear":null,"nameKR":"서귀샌드","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":36637,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/boncourage.jeju/","foodTypes":"디저트/차/베이커리","latitude":33.2542891736067,"longitude":126.505782814109,"bookYear":null,"nameKR":"봉꾸라주","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":2820,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.244191,"longitude":126.564557,"bookYear":null,"nameKR":"죽림횟집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":9399,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":33.246002,"longitude":126.581013,"bookYear":null,"nameKR":"살레","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":9735,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":33.252619,"longitude":126.51132,"bookYear":null,"nameKR":"오아시스","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":9744,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.2437144263977,"longitude":126.56837804746,"bookYear":null,"nameKR":"새만부정","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":9750,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.2425243024994,"longitude":126.565228241623,"bookYear":null,"nameKR":"남궁미락","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":9753,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.237318,"longitude":126.51527,"bookYear":null,"nameKR":"포구식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":9816,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.2443792573728,"longitude":126.568940792596,"bookYear":null,"nameKR":"멧돌뚝배기","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":9825,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":33.2477077001644,"longitude":126.557941678007,"bookYear":null,"nameKR":"이화삼계탕","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":9828,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":33.2513310190326,"longitude":126.560289500712,"bookYear":null,"nameKR":"진국옹기설렁탕","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":9832,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":33.2543299814447,"longitude":126.569504159315,"bookYear":null,"nameKR":"금강","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":12899,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.260322,"longitude":126.614855,"bookYear":null,"nameKR":"삼다뚝배기","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":13686,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.2416973090938,"longitude":126.564578999898,"bookYear":null,"nameKR":"부두식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":20674,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.243672,"longitude":126.568303,"bookYear":null,"nameKR":"동해미락","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":22271,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.24895941186437,"longitude":126.56411741288204,"bookYear":null,"nameKR":"버들집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":23304,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.2293042435205,"longitude":126.475933002517,"bookYear":null,"nameKR":"강정해녀의집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":23305,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.2457696318692,"longitude":126.562809882947,"bookYear":null,"nameKR":"돌아온천지연식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":27450,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.249439,"longitude":126.618933,"bookYear":null,"nameKR":"테라로사(제주서귀포점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":29364,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_be_brave","foodTypes":"디저트/차/베이커리","latitude":33.252468,"longitude":126.511852,"bookYear":null,"nameKR":"비브레이브","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"08. 서귀포시","status":"ACTIVE"},
        {"id":6044,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.319698,"longitude":126.267412,"bookYear":null,"nameKR":"오월의꽃","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":22225,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.3362806369928,"longitude":126.232706962925,"bookYear":null,"nameKR":"맛있는참세상","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":23078,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":33.304878,"longitude":126.255839,"bookYear":null,"nameKR":"피자굽는돌하르방","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":29957,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_cafe_cola/","foodTypes":"디저트/차/베이커리","latitude":33.443318566103,"longitude":126.291921158759,"bookYear":null,"nameKR":"카페콜라","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":29969,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/uyubudan/","foodTypes":"디저트/차/베이커리","latitude":33.3476224146646,"longitude":126.328061299164,"bookYear":null,"nameKR":"우유부단","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":31631,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.4073746848732,"longitude":126.26554032813,"bookYear":null,"nameKR":"이익새양과점","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":32912,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/little_waves.jeju/","foodTypes":"디저트/차/베이커리","latitude":33.388061470817,"longitude":126.229658028,"bookYear":null,"nameKR":"잔물결","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":34949,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jejumuvo/","foodTypes":"디저트/차/베이커리","latitude":33.3882281257178,"longitude":126.232312796198,"bookYear":null,"nameKR":"카페 무보 이탈리안 파인다이닝","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":35102,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_beejubeeju","foodTypes":"디저트/차/베이커리","latitude":33.4059126385835,"longitude":126.254890131579,"bookYear":null,"nameKR":"비주비주 한림점","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"04. 제주시(한림)","status":"ACTIVE"},
        {"id":29968,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ohoo4/","foodTypes":"디저트/차/베이커리","latitude":33.450756032064,"longitude":126.345976910291,"bookYear":null,"nameKR":"어떤오후","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":30655,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dangdang_official_","foodTypes":"기타","latitude":33.47444521036986,"longitude":126.37088286343312,"bookYear":null,"nameKR":"당당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":32415,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.4492669601044,"longitude":126.400128748713,"bookYear":null,"nameKR":"카이로스","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":33728,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moltz_jeju/","foodTypes":"이탈리아식","latitude":33.4241426594713,"longitude":126.406118358734,"bookYear":null,"nameKR":"몰츠","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":33811,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seaside_aewol/","foodTypes":"디저트/차/베이커리","latitude":33.485065884759,"longitude":126.407673083837,"bookYear":null,"nameKR":"씨사이드애월원두로스터리(제주애월하귀점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":36097,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.4500100666301,"longitude":126.367458072584,"bookYear":null,"nameKR":"로쿤커피","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":36411,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":33.4612601643908,"longitude":126.435802206511,"bookYear":null,"nameKR":"고추냉이식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":23301,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafeharuhana/","foodTypes":"디저트/차/베이커리","latitude":33.4520391236273,"longitude":126.393280680932,"bookYear":null,"nameKR":"하루하나","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"03. 제주시(애월)","status":"ACTIVE"},
        {"id":2613,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.491899,"longitude":126.498689,"bookYear":null,"nameKR":"장수물식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":5880,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.475069,"longitude":126.480548,"bookYear":null,"nameKR":"흑돼지가있는풍경","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":7054,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.483836,"longitude":126.49328,"bookYear":null,"nameKR":"삼대국수회관(신제주점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":7454,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":33.4854850223249,"longitude":126.49694497041,"bookYear":null,"nameKR":"미풍해장국(신제주점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":7455,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":33.486896,"longitude":126.495388,"bookYear":null,"nameKR":"삼보식당(신제주점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":8183,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":33.470053,"longitude":126.486196,"bookYear":null,"nameKR":"비원","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":9398,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":33.484758,"longitude":126.488706,"bookYear":null,"nameKR":"삼다정","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":12904,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.481679,"longitude":126.470795,"bookYear":null,"nameKR":"돈사돈(별관)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":12905,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.4832129201836,"longitude":126.490347416605,"bookYear":null,"nameKR":"풍전","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":13425,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_sammoogooksoo","foodTypes":"한식(육류)","latitude":33.490567,"longitude":126.491045,"bookYear":null,"nameKR":"삼무국수","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":21487,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":33.4876225657189,"longitude":126.484451820655,"bookYear":null,"nameKR":"대춘해장국","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":22777,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":33.489633859584,"longitude":126.493685496962,"bookYear":null,"nameKR":"원조오막집국수","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":27884,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.478447,"longitude":126.49099,"bookYear":null,"nameKR":"아라파파","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":28409,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":33.490511,"longitude":126.495764,"bookYear":null,"nameKR":"미나리","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":29267,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.4821097381984,"longitude":126.475077119087,"bookYear":null,"nameKR":"한량2010","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":29974,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/glomuro_coffee/","foodTypes":"디저트/차/베이커리","latitude":33.4684554530705,"longitude":126.485937143146,"bookYear":null,"nameKR":"그러므로part2","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":32072,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.4814359824131,"longitude":126.468667208494,"bookYear":null,"nameKR":"안빵","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":32876,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.4819159157363,"longitude":126.470398236263,"bookYear":null,"nameKR":"제주올레냉면","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":33554,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/heat_up21/","foodTypes":"기타","latitude":33.4869421280557,"longitude":126.478031891961,"bookYear":null,"nameKR":"힛업","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":33635,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/raonbakeryofficial/","foodTypes":"디저트/차/베이커리","latitude":33.4890199989422,"longitude":126.482130006763,"bookYear":null,"nameKR":"라온베이커리","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":34069,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sydneylm_jeju/","foodTypes":"한식(육류)","latitude":33.4834170379832,"longitude":126.478106511043,"bookYear":null,"nameKR":"시드니양갈비(제주노형직영점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":35131,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kitchengla.jeju/","foodTypes":"이탈리아식","latitude":33.4834173889444,"longitude":126.49333486687,"bookYear":null,"nameKR":"키친글라","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":36658,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.4700126643644,"longitude":126.49016672721,"bookYear":null,"nameKR":"포도원흑돼지","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"02. 제주시(신시가지)","status":"ACTIVE"},
        {"id":29949,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/he_season/","foodTypes":"디저트/차/베이커리","latitude":33.5318190555337,"longitude":126.818509470905,"bookYear":null,"nameKR":"그계절","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":30864,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gongbech.official/","foodTypes":"디저트/차/베이커리","latitude":33.5533920518974,"longitude":126.71509377031,"bookYear":null,"nameKR":"공백","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":34476,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/bagel__rimrim","foodTypes":"디저트/차/베이커리","latitude":33.554368981307,"longitude":126.790641294489,"bookYear":null,"nameKR":"베이글림림","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":35132,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gosari.coffee","foodTypes":"디저트/차/베이커리","latitude":33.4733772525897,"longitude":126.764083005021,"bookYear":null,"nameKR":"고사리커피","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":35547,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cacaofamily.official/","foodTypes":"디저트/차/베이커리","latitude":33.5225968970151,"longitude":126.85747498745,"bookYear":null,"nameKR":"카카오패밀리 (로이인더정글&카밀라스)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":18673,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":33.537265,"longitude":126.837793,"bookYear":null,"nameKR":"해맞이쉼터","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":23890,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.55549,"longitude":126.796253,"bookYear":null,"nameKR":"모래비카페","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"06. 제주시(세화/김녕/구좌)","status":"ACTIVE"},
        {"id":2617,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.5059939290281,"longitude":126.522637311624,"bookYear":null,"nameKR":"삼도제주보쌈","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":4147,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.495872,"longitude":126.536799,"bookYear":null,"nameKR":"온누리식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":4734,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":33.49141,"longitude":126.544724,"bookYear":null,"nameKR":"덕성원(이도점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":7122,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.516909,"longitude":126.529141,"bookYear":null,"nameKR":"물항식당(탑동점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":7754,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myungdangbakery/","foodTypes":"디저트/차/베이커리","latitude":33.493672,"longitude":126.444641,"bookYear":null,"nameKR":"명당양과(내도점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":9341,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":33.51880712831151,"longitude":126.51813762592138,"bookYear":null,"nameKR":"탐모라","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":9397,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":33.5188061398631,"longitude":126.518138492511,"bookYear":null,"nameKR":"더블루","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":10336,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.506082,"longitude":126.464621,"bookYear":null,"nameKR":"등대와바당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":10342,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":33.4596235354265,"longitude":126.51183928863,"bookYear":null,"nameKR":"제주오라성","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":12890,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.5166795322829,"longitude":126.516950468471,"bookYear":null,"nameKR":"명물식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":21983,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.518922,"longitude":126.493416,"bookYear":null,"nameKR":"오로섬","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":22166,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":33.4967724443598,"longitude":126.530042498672,"bookYear":null,"nameKR":"코코분식","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":22219,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":33.5125874068371,"longitude":126.527141612258,"bookYear":null,"nameKR":"한일식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":22641,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":33.5162218553848,"longitude":126.530829548404,"bookYear":null,"nameKR":"해광식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":22895,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.494740419269,"longitude":126.467202329112,"bookYear":null,"nameKR":"태백산(본점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":23924,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.500467,"longitude":126.531924,"bookYear":null,"nameKR":"한라식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":26935,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":33.493074,"longitude":126.463771,"bookYear":null,"nameKR":"웅스키친","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":26938,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.503027,"longitude":126.465498,"bookYear":null,"nameKR":"벨로인커피","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":27890,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.panettiere/","foodTypes":"디저트/차/베이커리","latitude":33.49483,"longitude":126.546352,"bookYear":null,"nameKR":"빠네띠에","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":28399,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":33.50132,"longitude":126.531345,"bookYear":null,"nameKR":"백성원해장국","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":28406,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":33.511295,"longitude":126.530582,"bookYear":null,"nameKR":"파도식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":28408,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.49408799463308,"longitude":126.51877820488961,"bookYear":null,"nameKR":"만세국수","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":28411,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":33.521372,"longitude":126.586047,"bookYear":null,"nameKR":"화성식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":28509,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.511684,"longitude":126.518346,"bookYear":null,"nameKR":"몽실할머니순대","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":28519,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":33.4976664288385,"longitude":126.537074646477,"bookYear":null,"nameKR":"만세국수(삼성혈점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":28615,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":33.509626,"longitude":126.543621,"bookYear":null,"nameKR":"하노이안브라더스","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":29971,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/by.seop/","foodTypes":"디저트/차/베이커리","latitude":33.506412115601,"longitude":126.513535495417,"bookYear":null,"nameKR":"스테이굿무드","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":29973,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/letoit_coffee/","foodTypes":"디저트/차/베이커리","latitude":33.4910780539456,"longitude":126.541981375264,"bookYear":null,"nameKR":"어반르토아","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":31981,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.5158850112082,"longitude":126.526726608136,"bookYear":null,"nameKR":"대돈","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32058,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/scone_ccllim/","foodTypes":"디저트/차/베이커리","latitude":33.5243870665283,"longitude":126.58277541112,"bookYear":null,"nameKR":"스콘가게끌림","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32075,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":33.4844289376836,"longitude":126.429196647648,"bookYear":null,"nameKR":"핏제리아마노","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32195,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/writersblock.jeju","foodTypes":"호주식","latitude":33.4935568284907,"longitude":126.544433067157,"bookYear":null,"nameKR":"라이터스블럭","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32238,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/go.yangok/","foodTypes":"한식(육류)","latitude":33.4945808645344,"longitude":126.548152786765,"bookYear":null,"nameKR":"고양옥","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32409,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pungryu__/","foodTypes":"디저트/차/베이커리","latitude":33.5105174891967,"longitude":126.523365331925,"bookYear":null,"nameKR":"아일랜드팩토리 풍류","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32495,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":33.5209446458277,"longitude":126.585741204485,"bookYear":null,"nameKR":"빵의정석","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32612,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seudong_jeju/","foodTypes":"일식","latitude":33.4987047800859,"longitude":126.530767272853,"bookYear":null,"nameKR":"새우동","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":32949,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":33.4723580424149,"longitude":126.550155345146,"bookYear":null,"nameKR":"온리커피스튜디오","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":33331,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_dogam","foodTypes":"한식(육류)","latitude":33.4817164874775,"longitude":126.50893553764,"bookYear":null,"nameKR":"제주도감","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":33658,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakers.b_jeju/","foodTypes":"디저트/차/베이커리","latitude":33.5019789745653,"longitude":126.530878747578,"bookYear":null,"nameKR":"베이커스비","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34202,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":33.516446324227,"longitude":126.515781230041,"bookYear":null,"nameKR":"가치","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34342,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_omen/","foodTypes":"디저트/차/베이커리","latitude":33.4754479010292,"longitude":126.543569173376,"bookYear":null,"nameKR":"오멘","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34382,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":33.4937795670783,"longitude":126.541049044792,"bookYear":null,"nameKR":"돈풍년","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":34383,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.5162206877035,"longitude":126.507601146737,"bookYear":null,"nameKR":"용출횟집","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":35247,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bomok_badanara2/","foodTypes":"한식(어패류)","latitude":33.4724471144528,"longitude":126.543299493728,"bookYear":null,"nameKR":"보목","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":35497,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goldenwattle_jeju","foodTypes":"호주식","latitude":33.5127949690575,"longitude":126.580969469249,"bookYear":null,"nameKR":"골든와틀","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":35526,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_chimsool","foodTypes":"일식","latitude":33.5011081716993,"longitude":126.5304860175,"bookYear":null,"nameKR":"제주침시술소","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":35774,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chsk.jeju","foodTypes":"디저트/차/베이커리","latitude":33.4893967147156,"longitude":126.536175628627,"bookYear":null,"nameKR":"채색","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"01. 제주시","status":"ACTIVE"},
        {"id":30044,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sandolongmandolong2/","foodTypes":"한식(면류)","latitude":33.496811235714645,"longitude":126.9103845738485,"bookYear":null,"nameKR":"산도롱맨도롱","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":33206,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/udorangjeju/","foodTypes":"디저트/차/베이커리","latitude":33.5070141829619,"longitude":126.942685176185,"bookYear":null,"nameKR":"우도랑","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":33229,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_costeno/","foodTypes":"디저트/차/베이커리","latitude":33.5098702104994,"longitude":126.905254340198,"bookYear":null,"nameKR":"꼬스뗀뇨","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":33245,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/orrrn_official/","foodTypes":"디저트/차/베이커리","latitude":33.4718015584104,"longitude":126.915754333279,"bookYear":null,"nameKR":"오른","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":33296,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seogwipean_bakery/","foodTypes":"디저트/차/베이커리","latitude":33.4352538752549,"longitude":126.921975056953,"bookYear":null,"nameKR":"서귀피안베이커리","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":33965,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/donutsyoon__jeju/","foodTypes":"디저트/차/베이커리","latitude":33.449445734535,"longitude":126.911162532937,"bookYear":null,"nameKR":"도너츠윤(제주점)","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":34099,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_sungsanhaena/","foodTypes":"디저트/차/베이커리","latitude":33.4625235857138,"longitude":126.93294806973,"bookYear":null,"nameKR":"성산해나","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":36314,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"유럽식","latitude":33.4628904334584,"longitude":126.932916269112,"bookYear":null,"nameKR":"윌라라","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":9801,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":33.461192,"longitude":126.933908,"bookYear":null,"nameKR":"돌하르방뚝배기식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":9818,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":33.4697934026635,"longitude":126.9299455324,"bookYear":null,"nameKR":"충남식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":19350,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.46317,"longitude":126.935018,"bookYear":null,"nameKR":"한성식당","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":21946,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":33.464712,"longitude":126.935856,"bookYear":null,"nameKR":"바다풍경","ribbonType":"정보없음","zone2_1":"제주","zone2_2":"07. 성산/우도","status":"ACTIVE"},
        {"id":29585,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.38162973762397,"longitude":126.65791896381225,"bookYear":null,"nameKR":"js가든(현대프리미엄아울렛송도점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":30790,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/samgyori_incheon","foodTypes":"한식(면류)","latitude":37.3881431004052,"longitude":126.645553109611,"bookYear":null,"nameKR":"삼교리동치미막국수(송도점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":32287,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/songjjuzip/","foodTypes":"한식(어패류)","latitude":37.3929791823293,"longitude":126.645688890785,"bookYear":null,"nameKR":"송쭈집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":33089,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4023784481142,"longitude":126.640151020131,"bookYear":null,"nameKR":"헤이트래블러","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":33273,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/newyorkbanana_/","foodTypes":"디저트/차/베이커리","latitude":37.3952040049379,"longitude":126.631287119931,"bookYear":null,"nameKR":"뉴욕바나나","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":33509,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mugung_songdo/","foodTypes":"한식(육류)","latitude":37.3849625996123,"longitude":126.642800057546,"bookYear":null,"nameKR":"무궁","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":33510,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/hooray_songdo","foodTypes":"바/주점","latitude":37.3937862653635,"longitude":126.641874180513,"bookYear":null,"nameKR":"후레이","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":33750,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/celebre_cafe_/","foodTypes":"디저트/차/베이커리","latitude":37.3993587408579,"longitude":126.636895802118,"bookYear":null,"nameKR":"셀레브르","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":33830,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3832947004154,"longitude":126.64225604799,"bookYear":null,"nameKR":"우아한밤","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":35248,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4116490153896,"longitude":126.654751097925,"bookYear":null,"nameKR":"청기와타운(송도점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":35553,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/dolphin_coffee_roasters","foodTypes":"디저트/차/베이커리","latitude":37.3934747558952,"longitude":126.646717372459,"bookYear":null,"nameKR":"돌핀커피로스터스","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":35554,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3937818483729,"longitude":126.633289319826,"bookYear":null,"nameKR":"돌핀커피오마카세","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":35601,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_orle","foodTypes":"기타","latitude":37.3937818483729,"longitude":126.633289319826,"bookYear":null,"nameKR":"카페오를","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":35628,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.379305344604,"longitude":126.64614896978,"bookYear":null,"nameKR":"송도어멍","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":36252,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/champion_triplestreet/","foodTypes":"한식(육류)","latitude":37.3804450761613,"longitude":126.660231759735,"bookYear":null,"nameKR":"참피온삼겹살(트리플스트리트점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":36382,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.3940175669476,"longitude":126.646006981957,"bookYear":null,"nameKR":"브런치빈","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":36383,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.3818594284068,"longitude":126.662694187386,"bookYear":null,"nameKR":"타파스빈","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":36384,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.3960687593126,"longitude":126.649038274476,"bookYear":null,"nameKR":"송도주물럭","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":36405,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.3832947004154,"longitude":126.64225604799,"bookYear":null,"nameKR":"송도씨","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":36600,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ji_tsugen","foodTypes":"일식","latitude":37.3840647142873,"longitude":126.643211374294,"bookYear":null,"nameKR":"지츠겐","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":22442,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.55048,"longitude":126.9223,"bookYear":null,"nameKR":"광동포차","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6956,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.54942,"longitude":126.91248,"bookYear":null,"nameKR":"퓨전선술집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":15672,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.54907,"longitude":126.90937,"bookYear":null,"nameKR":"참숯불풍천장어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":27396,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/simplehantable/","foodTypes":"한식(일반한식)","latitude":37.5515008030203,"longitude":126.91395024016,"bookYear":null,"nameKR":"더심플한테이블","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27781,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.5505685962739,"longitude":126.911226502198,"bookYear":null,"nameKR":"향남추어탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":29263,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ittengo1.5/","foodTypes":"일식","latitude":37.549208,"longitude":126.909219,"bookYear":null,"nameKR":"잇텐고","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":29705,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tb389_23","foodTypes":"바/주점","latitude":37.550554366727454,"longitude":126.91039972882957,"bookYear":null,"nameKR":"히게쯔라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":32380,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chef_kns/","foodTypes":"일식","latitude":37.5515008030203,"longitude":126.91395024016,"bookYear":null,"nameKR":"스시히카리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32665,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5500292272642,"longitude":126.909640793106,"bookYear":null,"nameKR":"은하루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":32721,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/connectscoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5549409780941,"longitude":126.910417677487,"bookYear":null,"nameKR":"커넥츠커피(망원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":32723,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/laopiak/","foodTypes":"아시아식","latitude":37.5527328101125,"longitude":126.90443785635,"bookYear":null,"nameKR":"라오삐약","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":32763,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/deepbluelake_roastery/","foodTypes":"디저트/차/베이커리","latitude":37.5551389609282,"longitude":126.900127651583,"bookYear":null,"nameKR":"딥블루레이크로스터리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":30190,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thepiano_official","foodTypes":"디저트/차/베이커리","latitude":37.617266382723905,"longitude":126.97480036004893,"bookYear":null,"nameKR":"더피아노","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":30265,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leeshokudo023793551/","foodTypes":"한식(육류)","latitude":37.606936694852244,"longitude":126.97150143674891,"bookYear":null,"nameKR":"이식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":31817,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/type_coffee","foodTypes":"디저트/차/베이커리","latitude":37.6087083156333,"longitude":127.056880012905,"bookYear":null,"nameKR":"타입커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":32651,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.611867505948,"longitude":127.054866978787,"bookYear":null,"nameKR":"태성집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":32698,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/barbecuegallery/","foodTypes":"한식(육류)","latitude":37.6066252857224,"longitude":126.970655684963,"bookYear":null,"nameKR":"바베큐갤러리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":32748,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daepyeonggalbi_official/","foodTypes":"한식(육류)","latitude":37.6069827683488,"longitude":126.970176151386,"bookYear":null,"nameKR":"대평갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":33222,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.6046691399298,"longitude":127.024646137611,"bookYear":null,"nameKR":"한술식당 길음본점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":33539,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6050587642973,"longitude":127.030990445726,"bookYear":null,"nameKR":"세이버스커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":34155,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/__cuins_coffee__","foodTypes":"디저트/차/베이커리","latitude":37.6005726967226,"longitude":127.033435171205,"bookYear":null,"nameKR":"커인스커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":35255,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6154557792406,"longitude":127.051061784991,"bookYear":null,"nameKR":"털보정육식당(장위2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":35428,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.6203849302826,"longitude":127.028173848583,"bookYear":null,"nameKR":"어촌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":35932,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.6146533152425,"longitude":127.029199887717,"bookYear":null,"nameKR":"로드샌드위치(미아사거리역점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":36642,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brownhaven_pie","foodTypes":"디저트/차/베이커리","latitude":37.6155321681138,"longitude":127.03187440283,"bookYear":null,"nameKR":"브라운헤이븐 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":540,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":37.611537,"longitude":126.978596,"bookYear":null,"nameKR":"스위스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":697,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.608188,"longitude":126.95761,"bookYear":null,"nameKR":"원조할머니두부집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":756,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.60909,"longitude":126.9745,"bookYear":null,"nameKR":"절벽","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":836,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.612901,"longitude":126.974304,"bookYear":null,"nameKR":"키미아트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":1517,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.60788,"longitude":126.9729,"bookYear":null,"nameKR":"섬진강민물장어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":3190,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6123,"longitude":126.9561,"bookYear":null,"nameKR":"한우향기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":3987,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.600581,"longitude":126.960132,"bookYear":null,"nameKR":"송스키친","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":4048,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.603574,"longitude":127.037584,"bookYear":null,"nameKR":"소양강민물매운탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":7777,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.610772,"longitude":126.97468,"bookYear":null,"nameKR":"강촌쌈밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":7784,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.60649,"longitude":126.9603,"bookYear":null,"nameKR":"옛날민속집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":9083,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.60341,"longitude":127.0371,"bookYear":null,"nameKR":"장원산꽃게","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":16024,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.602671,"longitude":127.023624,"bookYear":null,"nameKR":"내고향왕만두","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":23095,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.6066,"longitude":126.9666,"bookYear":null,"nameKR":"몽모랑시제과점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":29465,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bask_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.608603010858616,"longitude":127.02254657671214,"bookYear":null,"nameKR":"바스크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"27. 세검정/평창동/정릉/석계","status":"ACTIVE"},
        {"id":6832,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.542479,"longitude":127.065213,"bookYear":null,"nameKR":"예담밥상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":7714,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.532576,"longitude":127.067931,"bookYear":null,"nameKR":"커피더쏠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":13441,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.53278,"longitude":127.0799,"bookYear":null,"nameKR":"아낙네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":13941,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.537839,"longitude":127.087389,"bookYear":null,"nameKR":"전주밥상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":14918,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.557429,"longitude":127.076193,"bookYear":null,"nameKR":"북해도","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":15051,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.53784,"longitude":127.084265,"bookYear":null,"nameKR":"일송정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":20483,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.535703,"longitude":127.065744,"bookYear":null,"nameKR":"원조대구뽈찜","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":22521,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.558002,"longitude":127.088485,"bookYear":null,"nameKR":"옛날손칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":24499,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.55763,"longitude":127.0763,"bookYear":null,"nameKR":"영미오리탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":24842,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.542706,"longitude":127.069976,"bookYear":null,"nameKR":"신사소곱창(건대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":35857,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/on.the____","foodTypes":"디저트/차/베이커리","latitude":37.5483422189684,"longitude":127.064823842909,"bookYear":null,"nameKR":"온더","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34055,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5917668277371,"longitude":127.077842435464,"bookYear":null,"nameKR":"원조곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":35485,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/korea_sashimi_house/","foodTypes":"한식(어패류)","latitude":37.5970796029997,"longitude":127.076202532202,"bookYear":null,"nameKR":"한국횟집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":36061,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.59959942597,"longitude":127.07796258963,"bookYear":null,"nameKR":"오키나라(본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":5089,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.588118,"longitude":127.085616,"bookYear":null,"nameKR":"용쥐숯불갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":23479,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.613797,"longitude":127.077503,"bookYear":null,"nameKR":"포앤시드니(묵동자이점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":26599,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.58965,"longitude":127.0926,"bookYear":null,"nameKR":"진미각","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":6484,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.549119,"longitude":126.970142,"bookYear":null,"nameKR":"포대포","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":22911,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.671016,"longitude":127.056383,"bookYear":null,"nameKR":"달인보쌈집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":24793,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.626731,"longitude":127.07636,"bookYear":null,"nameKR":"리틀파스타(공릉직영점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":29704,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.60208748266139,"longitude":127.08492391311991,"bookYear":null,"nameKR":"부대닭","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":31191,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/de_soyoung/","foodTypes":"디저트/차/베이커리","latitude":37.5574269357971,"longitude":127.035129147107,"bookYear":null,"nameKR":"드소영","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":32081,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5985084427747,"longitude":127.095128523555,"bookYear":null,"nameKR":"동부고려제과","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":32288,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.6138393830212,"longitude":127.077557139207,"bookYear":null,"nameKR":"연스시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":32491,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5708963505639,"longitude":127.071502974477,"bookYear":null,"nameKR":"일미간장게장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":33581,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/palpalsikdang/","foodTypes":"일식","latitude":37.5611964141252,"longitude":127.005172419887,"bookYear":null,"nameKR":"팔팔식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":34551,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5997288955326,"longitude":127.095943709403,"bookYear":null,"nameKR":"예소랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":34618,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5998937071439,"longitude":127.102562041732,"bookYear":null,"nameKR":"산야초랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":36377,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_origin_coffeelab/","foodTypes":"디저트/차/베이커리","latitude":37.5935499047661,"longitude":127.076580861111,"bookYear":null,"nameKR":"디오리진","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":30849,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/segumjung_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5478040581648,"longitude":126.914765972923,"bookYear":null,"nameKR":"새검정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32244,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/udi.ne_/","foodTypes":"이탈리아식","latitude":37.5460455114159,"longitude":126.915802023145,"bookYear":null,"nameKR":"우디네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33061,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/silhouettecoffee","foodTypes":"디저트/차/베이커리","latitude":37.5463813420495,"longitude":126.918019121351,"bookYear":null,"nameKR":"실루엣커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33288,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joohaeng_/","foodTypes":"바/주점","latitude":37.5485286651181,"longitude":126.916949077994,"bookYear":null,"nameKR":"주행","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33619,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/perception.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5466993125055,"longitude":126.918420944135,"bookYear":null,"nameKR":"퍼셉션","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33788,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5459276815419,"longitude":126.915076127952,"bookYear":null,"nameKR":"믹스쳐","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":35818,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/muircoffee_/","foodTypes":"디저트/차/베이커리","latitude":37.5463208793059,"longitude":126.912974988438,"bookYear":null,"nameKR":"만유인력","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36059,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5460636770893,"longitude":126.915501452039,"bookYear":null,"nameKR":"오티디서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36266,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5502215219633,"longitude":126.916270244109,"bookYear":null,"nameKR":"쿠상다이닝","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":3882,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5462529082402,"longitude":126.916672664822,"bookYear":null,"nameKR":"백세식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6035,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5476019183732,"longitude":126.919581789268,"bookYear":null,"nameKR":"옛맛서울불고기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6628,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.54622,"longitude":126.9163,"bookYear":null,"nameKR":"쿡앤북","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6941,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.54829,"longitude":126.9188,"bookYear":null,"nameKR":"몽카페그레고리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":12637,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.54827,"longitude":126.917454,"bookYear":null,"nameKR":"비사벌전주콩나물국밥(합정점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":16299,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.545941,"longitude":126.918456,"bookYear":null,"nameKR":"무대륙","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":16949,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5456194890198,"longitude":126.916393642137,"bookYear":null,"nameKR":"버거4.5","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":23262,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.545575,"longitude":126.916367,"bookYear":null,"nameKR":"커피발전소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27312,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.548769,"longitude":126.91625,"bookYear":null,"nameKR":"프리바다727","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27379,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.548775348618,"longitude":126.916424292681,"bookYear":null,"nameKR":"럭키스트라이크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27651,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5488089440425,"longitude":126.916280424629,"bookYear":null,"nameKR":"쿠이신보","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27746,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bean_brothers/","foodTypes":"디저트/차/베이커리","latitude":37.5457206541178,"longitude":126.914981649041,"bookYear":null,"nameKR":"빈브라더스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27802,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.54772,"longitude":126.9188,"bookYear":null,"nameKR":"시간의공기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":85,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":37.54816,"longitude":126.91373,"bookYear":null,"nameKR":"그릭조이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":4818,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_dangojip/","foodTypes":"디저트/차/베이커리","latitude":37.54882,"longitude":126.9244,"bookYear":null,"nameKR":"당고집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":7498,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5569,"longitude":126.925244,"bookYear":null,"nameKR":"커피와사람들(홍대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27189,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.558284,"longitude":126.926206,"bookYear":null,"nameKR":"블랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28104,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.554747,"longitude":126.922321,"bookYear":null,"nameKR":"웨스턴철판볶음밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32829,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nezu_sub/","foodTypes":"바/주점","latitude":37.5470647343297,"longitude":126.913329561294,"bookYear":null,"nameKR":"네즈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33299,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/groni_official/","foodTypes":"디저트/차/베이커리","latitude":37.5471308393735,"longitude":126.919263634652,"bookYear":null,"nameKR":"그로니","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":35495,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.556949431971,"longitude":126.925519801986,"bookYear":null,"nameKR":"동교해물","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31102,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/algo_sausage_official/","foodTypes":"바/주점","latitude":37.5481167883366,"longitude":127.072861336247,"bookYear":null,"nameKR":"알고","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":31310,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5330984828917,"longitude":127.076551138742,"bookYear":null,"nameKR":"뺑드램","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":31331,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5448287303213,"longitude":127.071158853142,"bookYear":null,"nameKR":"스시호","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":31460,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5421491984204,"longitude":127.069884916683,"bookYear":null,"nameKR":"다원식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":31859,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lagranourse/","foodTypes":"디저트/차/베이커리","latitude":37.5375052411306,"longitude":127.08158295574,"bookYear":null,"nameKR":"라그랑우스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":31879,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/drawing__recipe","foodTypes":"미국식","latitude":37.5370096778475,"longitude":127.070099282847,"bookYear":null,"nameKR":"드로잉레시피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":32070,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rabbithole_burger","foodTypes":"기타","latitude":37.5460394694832,"longitude":127.076527027435,"bookYear":null,"nameKR":"래빗홀버거컴퍼니","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":32163,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/acre_kr/","foodTypes":"바/주점","latitude":37.5361536552074,"longitude":127.062696137025,"bookYear":null,"nameKR":"에이커","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":32595,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeolmae_bakery","foodTypes":"디저트/차/베이커리","latitude":37.5436345702004,"longitude":127.06695772623,"bookYear":null,"nameKR":"열매제과점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":32661,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.546055025643,"longitude":127.073018997047,"bookYear":null,"nameKR":"시홍쓰","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":33092,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5551942013519,"longitude":127.078915170247,"bookYear":null,"nameKR":"이이요","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":33357,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5405050771385,"longitude":127.070150304742,"bookYear":null,"nameKR":"백소정(건대본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":33522,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dawon2plus/","foodTypes":"바/주점","latitude":37.5425874108185,"longitude":127.070093868342,"bookYear":null,"nameKR":"다원투뿔육회","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":34036,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dodanzip/","foodTypes":"한식(육류)","latitude":37.542999379374,"longitude":127.068349185052,"bookYear":null,"nameKR":"도단집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":34037,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dodanzip/","foodTypes":"한식(육류)","latitude":37.5442666034627,"longitude":127.069789239165,"bookYear":null,"nameKR":"도단집별관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":34119,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5531123564063,"longitude":127.08844968063,"bookYear":null,"nameKR":"마시쏘부대찌개","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":34223,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5568732150225,"longitude":127.076442245622,"bookYear":null,"nameKR":"기와집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":34341,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/juhakj_official/","foodTypes":"중식","latitude":37.5479478384613,"longitude":127.108340848124,"bookYear":null,"nameKR":"주하객잔","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":34494,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chefnori_seoul/","foodTypes":"일식","latitude":37.5439712159622,"longitude":127.071809477098,"bookYear":null,"nameKR":"셰프노리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":34524,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oaoseoul_bakerycafe/","foodTypes":"디저트/차/베이커리","latitude":37.5381089502792,"longitude":127.075000713909,"bookYear":null,"nameKR":"오에이오서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":34657,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sosamyeonok","foodTypes":"한식(면류)","latitude":37.5540536261941,"longitude":127.078105698658,"bookYear":null,"nameKR":"소사면옥 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":34806,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5315220220208,"longitude":127.074974205518,"bookYear":null,"nameKR":"나린아토이자카야","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":35395,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/newradio.seoul/","foodTypes":"바/주점","latitude":37.5358601334787,"longitude":127.066761485044,"bookYear":null,"nameKR":"뉴라디오","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":35536,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cherrycoffee_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5551536063868,"longitude":127.078720243728,"bookYear":null,"nameKR":"체리커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":35656,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/bring_me_coffee_seoul","foodTypes":"디저트/차/베이커리","latitude":37.5313872650085,"longitude":127.074922593307,"bookYear":null,"nameKR":"브링미커피(자양점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36104,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/modami.yuk/","foodTypes":"한식(육류)","latitude":37.5478142685395,"longitude":127.067810065046,"bookYear":null,"nameKR":"모다미육","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36153,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/afternoonbread/","foodTypes":"디저트/차/베이커리","latitude":37.5333471777498,"longitude":127.092165720604,"bookYear":null,"nameKR":"오후의빵집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36279,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/o2._.bakery/","foodTypes":"디저트/차/베이커리","latitude":37.546698273475,"longitude":127.088885349625,"bookYear":null,"nameKR":"오투베이커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":36432,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leewu_chinesedining/","foodTypes":"중식","latitude":37.5425081932103,"longitude":127.070731415702,"bookYear":null,"nameKR":"이우","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":940,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.55562,"longitude":127.1114,"bookYear":null,"nameKR":"피자힐","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":3741,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.536699,"longitude":127.076432,"bookYear":null,"nameKR":"송림식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":4946,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.55863008236035,"longitude":127.1117068379481,"bookYear":null,"nameKR":"온달","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":13166,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5480981974837,"longitude":127.072324363402,"bookYear":null,"nameKR":"은혜떡볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":13326,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.547071,"longitude":127.106802,"bookYear":null,"nameKR":"옛집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":14156,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5515973987517,"longitude":127.110984517421,"bookYear":null,"nameKR":"가온","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":15338,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5639184235207,"longitude":127.086434498863,"bookYear":null,"nameKR":"대중관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":15504,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5366576372517,"longitude":127.081760914461,"bookYear":null,"nameKR":"복만루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":22794,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.54319,"longitude":127.069133,"bookYear":null,"nameKR":"아찌떡볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":27105,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.53554,"longitude":127.07901,"bookYear":null,"nameKR":"민정식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":29501,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kaleocoffee/","foodTypes":"디저트/차/베이커리","latitude":37.53874314724573,"longitude":127.07545167848532,"bookYear":null,"nameKR":"칼레오커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":29800,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.539665294333304,"longitude":127.06405619212867,"bookYear":null,"nameKR":"봉자마라탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":29802,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.53941981502316,"longitude":127.06480276626623,"bookYear":null,"nameKR":"해룡마라룽샤","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":29820,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.53488624282857,"longitude":127.07642081593596,"bookYear":null,"nameKR":"우성식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"33. 건대입구/광진구","status":"ACTIVE"},
        {"id":13193,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.621844,"longitude":127.072404,"bookYear":null,"nameKR":"소문난멸치국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":14313,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.659115,"longitude":127.070865,"bookYear":null,"nameKR":"만리향","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":15256,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.6413377369454,"longitude":127.075646867948,"bookYear":null,"nameKR":"향림","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":17211,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.6601294556367,"longitude":127.075209656625,"bookYear":null,"nameKR":"허파집냉면","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":20143,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.618964,"longitude":127.077933,"bookYear":null,"nameKR":"하노이별","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":22056,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.62167,"longitude":127.082056,"bookYear":null,"nameKR":"브로이하우스바네하임","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":24424,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.6531585938107,"longitude":127.058628794521,"bookYear":null,"nameKR":"영양센타","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":26752,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.6250348426368,"longitude":127.087400118828,"bookYear":null,"nameKR":"소라분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":32719,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/latt_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.6492607976891,"longitude":127.076361085876,"bookYear":null,"nameKR":"라트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":33696,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dietro_cheon/","foodTypes":"이탈리아식","latitude":37.6231213744439,"longitude":127.07615038016,"bookYear":null,"nameKR":"디에트로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":34586,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6566524275838,"longitude":127.062989409627,"bookYear":null,"nameKR":"쭈꾸미달인","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":34587,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6567882677746,"longitude":127.063879027884,"bookYear":null,"nameKR":"쭈꾸미달인 직영2호점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":35140,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bins_ring/","foodTypes":"디저트/차/베이커리","latitude":37.6581574674605,"longitude":127.061721168928,"bookYear":null,"nameKR":"빈스링","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":35206,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.6462913221846,"longitude":127.084698190316,"bookYear":null,"nameKR":"브런힐","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":35518,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bluemilescoffee/","foodTypes":"디저트/차/베이커리","latitude":37.6265608417801,"longitude":127.076644849096,"bookYear":null,"nameKR":"블루마일스커피로스터즈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":35839,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hogangbulbaek/","foodTypes":"한식(일반한식)","latitude":37.6176037664034,"longitude":127.104063484063,"bookYear":null,"nameKR":"호강불백","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"32. 노원구/중랑구","status":"ACTIVE"},
        {"id":30812,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5461484932303,"longitude":126.978268548767,"bookYear":null,"nameKR":"일미집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":31067,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5560659151376,"longitude":126.968138607192,"bookYear":null,"nameKR":"공일부엌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":31068,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5554204286425,"longitude":126.967434717654,"bookYear":null,"nameKR":"더하우스1932","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":33049,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sungwonkorea1/","foodTypes":"한식(육류)","latitude":37.5610682509201,"longitude":126.980044340355,"bookYear":null,"nameKR":"성원갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":33401,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5547278494914,"longitude":126.969526329341,"bookYear":null,"nameKR":"카페인신현리아티장가든","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":35152,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5533676516751,"longitude":126.97464398743,"bookYear":null,"nameKR":"죠우","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":35657,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_hooamdong","foodTypes":"디저트/차/베이커리","latitude":37.5487441293903,"longitude":126.977512657052,"bookYear":null,"nameKR":"후암동","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":36133,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jin1926_twincitynamsan/","foodTypes":"일식","latitude":37.5510861198751,"longitude":126.972911385084,"bookYear":null,"nameKR":"진1926(서울역트윈시티점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":36426,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bobby_seoul","foodTypes":"기타","latitude":37.5541781812656,"longitude":126.966321990807,"bookYear":null,"nameKR":"바비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":36623,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/happybricksand","foodTypes":"디저트/차/베이커리","latitude":37.5548375992165,"longitude":126.971732581232,"bookYear":null,"nameKR":"브릭샌드서울역점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":304,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.55934,"longitude":126.976723,"bookYear":null,"nameKR":"막내회집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":679,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.55997451357835,"longitude":126.97697914623569,"bookYear":null,"nameKR":"왕성식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":1067,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.55996,"longitude":126.9769,"bookYear":null,"nameKR":"내고향식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":1106,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.553853,"longitude":126.974741,"bookYear":null,"nameKR":"만복림","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":5655,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.56054060406895,"longitude":126.97837788996826,"bookYear":null,"nameKR":"남문토방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":7803,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5577646075635,"longitude":126.977760633286,"bookYear":null,"nameKR":"심원갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":7833,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.55837,"longitude":126.97733,"bookYear":null,"nameKR":"한순자손칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":7876,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.5602669008102,"longitude":126.972035325682,"bookYear":null,"nameKR":"신라삼계탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":7893,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.559687,"longitude":126.97718,"bookYear":null,"nameKR":"중앙왕족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":8284,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/junggam_official/","foodTypes":"한식(육류)","latitude":37.546209,"longitude":126.978657,"bookYear":null,"nameKR":"정감어린","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":8286,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.55469,"longitude":126.9688,"bookYear":null,"nameKR":"용산원조감자탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":8396,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.550502,"longitude":126.974118,"bookYear":null,"nameKR":"약초마을","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":8405,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.54965,"longitude":126.9771,"bookYear":null,"nameKR":"창익집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":8549,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.559525839913,"longitude":126.976636689855,"bookYear":null,"nameKR":"남대문생숯불갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":8558,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.557722096457,"longitude":126.980943177461,"bookYear":null,"nameKR":"두원식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":8574,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.56079,"longitude":126.978049,"bookYear":null,"nameKR":"칠백냥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":9109,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.559785,"longitude":126.976954,"bookYear":null,"nameKR":"호남식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":13101,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5583093867588,"longitude":126.9770091979,"bookYear":null,"nameKR":"남해식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":14143,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.55547,"longitude":126.9737,"bookYear":null,"nameKR":"루싱","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":14244,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.560051,"longitude":126.976224,"bookYear":null,"nameKR":"다흰정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":15184,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.549118478341,"longitude":126.970484281315,"bookYear":null,"nameKR":"아노","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":15341,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.548394,"longitude":126.978,"bookYear":null,"nameKR":"태화관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":18324,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.555454,"longitude":126.975434,"bookYear":null,"nameKR":"실란트로델리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":19446,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.560901,"longitude":126.980989,"bookYear":null,"nameKR":"호경전","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":19971,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.557942,"longitude":126.982799,"bookYear":null,"nameKR":"카페모리나리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":22017,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5570966597,"longitude":126.973633761228,"bookYear":null,"nameKR":"복성각(서울역점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":23426,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5580620434733,"longitude":126.972687824647,"bookYear":null,"nameKR":"명동집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":28165,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5597882067481,"longitude":126.977078575528,"bookYear":null,"nameKR":"중앙식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":28238,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.547043,"longitude":126.981166,"bookYear":null,"nameKR":"소월길밀영","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"21. 남대문/서울역/후암동","status":"ACTIVE"},
        {"id":28175,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5312,"longitude":127.00174,"bookYear":null,"nameKR":"챔프커피1작업실","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33248,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6002992571922,"longitude":126.925006431575,"bookYear":null,"nameKR":"럭키문","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":33800,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daon_katsu/","foodTypes":"일식","latitude":37.6189164157208,"longitude":126.917863426474,"bookYear":null,"nameKR":"다온카츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":35619,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pieceisland_seochon","foodTypes":"디저트/차/베이커리","latitude":37.5826812480983,"longitude":126.970999068655,"bookYear":null,"nameKR":"피스아일랜드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":35942,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/susindang","foodTypes":"디저트/차/베이커리","latitude":37.5713138623933,"longitude":126.915904667901,"bookYear":null,"nameKR":"수신당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":35969,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5842288929809,"longitude":126.909453082762,"bookYear":null,"nameKR":"도투리샤브칼국수불광천","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":36011,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tasteandtaste.kr","foodTypes":"기타","latitude":37.5404552699396,"longitude":127.061554653866,"bookYear":null,"nameKR":"테이스트앤드테이스트(성수플래그십)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36497,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5346599936902,"longitude":127.009808274639,"bookYear":null,"nameKR":"덴푸라혼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33846,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/choi_dining/","foodTypes":"일식","latitude":37.5612923637787,"longitude":126.924584102858,"bookYear":null,"nameKR":"초이다이닝","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35912,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ramen_menji","foodTypes":"일식","latitude":37.5562543045675,"longitude":126.926803666943,"bookYear":null,"nameKR":"멘지(홍대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":35918,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5542887811659,"longitude":126.927391591835,"bookYear":null,"nameKR":"마크스로프트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36010,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bogl.co.kr/","foodTypes":"기타","latitude":37.5482787310534,"longitude":126.922383191782,"bookYear":null,"nameKR":"보글","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36234,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sangsang_salon","foodTypes":"기타","latitude":37.5629634981756,"longitude":126.913007245624,"bookYear":null,"nameKR":"살롱드상상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":1062,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5269056148241,"longitude":126.966566172294,"bookYear":null,"nameKR":"금홍","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31207,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/conhascoffee_co.ltd/","foodTypes":"디저트/차/베이커리","latitude":37.5590125673642,"longitude":126.926144984931,"bookYear":null,"nameKR":"콘하스연남","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31498,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5253513996194,"longitude":127.12928567905,"bookYear":null,"nameKR":"간다이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":35638,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4840307542963,"longitude":127.120971066536,"bookYear":null,"nameKR":"문정커피본점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":34609,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4960790640201,"longitude":127.119779824539,"bookYear":null,"nameKR":"정우네한돈제육본점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":33551,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mayfield_hotel/","foodTypes":"이탈리아식","latitude":37.5478957237892,"longitude":126.819507121486,"bookYear":null,"nameKR":"더큐","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":9382,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.547525,"longitude":126.818789,"bookYear":null,"nameKR":"캐슬테라스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":12565,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.53,"longitude":126.8627,"bookYear":null,"nameKR":"사랑방칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":14555,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.530794,"longitude":126.853601,"bookYear":null,"nameKR":"용문각","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":17116,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.55185,"longitude":126.8523,"bookYear":null,"nameKR":"백두옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":33034,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5308694091405,"longitude":126.8496406609,"bookYear":null,"nameKR":"광선집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":33651,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5390663932795,"longitude":126.835418976281,"bookYear":null,"nameKR":"만배아리랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":35179,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5303651480517,"longitude":126.847206208517,"bookYear":null,"nameKR":"백순대본가새맛(까치산본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":35335,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chaili4447/","foodTypes":"중식","latitude":37.5393629216578,"longitude":126.832859801125,"bookYear":null,"nameKR":"맛있는중식차이리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":35345,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(육류)","latitude":37.5575962643159,"longitude":126.848578797153,"bookYear":null,"nameKR":"백제원(등촌점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":35351,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(어패류)","latitude":37.5575962643159,"longitude":126.848578797153,"bookYear":null,"nameKR":"오백년장어(등촌점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":35352,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(어패류)","latitude":37.5575962643159,"longitude":126.848578797153,"bookYear":null,"nameKR":"도쿄하나(등촌점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":13864,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5442964251603,"longitude":126.839016828602,"bookYear":null,"nameKR":"명궁","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":21897,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5517725994353,"longitude":126.848734189734,"bookYear":null,"nameKR":"만리장성","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":36709,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5679745986878,"longitude":126.826720979429,"bookYear":null,"nameKR":"양옥주점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":35901,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/palmsprings._.cafe","foodTypes":"기타","latitude":37.5099691587997,"longitude":126.917234541501,"bookYear":null,"nameKR":"카페팜스프링스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":35809,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tft.coffeeroasters","foodTypes":"디저트/차/베이커리","latitude":37.5197651591196,"longitude":126.930241165994,"bookYear":null,"nameKR":"티에프티커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":36349,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soul_hanu_go_bang_chae/","foodTypes":"한식(육류)","latitude":37.5195487940187,"longitude":126.930488645764,"bookYear":null,"nameKR":"소울한우(동여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":20479,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.480768,"longitude":126.904449,"bookYear":null,"nameKR":"조원녹두삼계탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":36245,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pinocchio_seorae/","foodTypes":"디저트/차/베이커리","latitude":37.4977801334555,"longitude":126.997524880728,"bookYear":null,"nameKR":"피노키오","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":35843,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/buanaes_seocho/","foodTypes":"한식(가금류)","latitude":37.4887143289998,"longitude":127.029849245333,"bookYear":null,"nameKR":"부안애서(서초점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":29945,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/viennboulang/","foodTypes":"디저트/차/베이커리","latitude":37.5062292217872,"longitude":127.09801611994726,"bookYear":null,"nameKR":"비엔블랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31353,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soryu_songpa/","foodTypes":"바/주점","latitude":37.511324183149,"longitude":127.111376153772,"bookYear":null,"nameKR":"소류","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31354,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sakabar_geragera","foodTypes":"바/주점","latitude":37.505331574335,"longitude":127.090181918074,"bookYear":null,"nameKR":"사카바게라게라","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31355,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nikubarshiya","foodTypes":"한식(육류)","latitude":37.5128541829574,"longitude":127.111179355525,"bookYear":null,"nameKR":"니꾸바시야","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31385,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5056889929139,"longitude":127.097455125368,"bookYear":null,"nameKR":"청와옥(석촌호수 직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31430,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5062773604789,"longitude":127.099387961215,"bookYear":null,"nameKR":"국민통닭","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31651,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bean_bang_official/","foodTypes":"디저트/차/베이커리","latitude":37.5088763944563,"longitude":127.115811694624,"bookYear":null,"nameKR":"빈방","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31940,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"프랑스식","latitude":37.5137519612953,"longitude":127.104446890835,"bookYear":null,"nameKR":"포숑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":31985,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/themakan_korea/","foodTypes":"아시아식","latitude":37.5132413002027,"longitude":127.110554320885,"bookYear":null,"nameKR":"더마칸","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32092,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5137519612953,"longitude":127.104446890835,"bookYear":null,"nameKR":"강가(잠실점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32141,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5138710747422,"longitude":127.106364392884,"bookYear":null,"nameKR":"모던샤브하우스(잠실점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32182,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yehwan1106/","foodTypes":"이탈리아식","latitude":37.5054826357644,"longitude":127.098896967779,"bookYear":null,"nameKR":"예환","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32332,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5138710747422,"longitude":127.106364392884,"bookYear":null,"nameKR":"레스파스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32379,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5066557779398,"longitude":127.089594261944,"bookYear":null,"nameKR":"리우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32539,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fuga.coffee","foodTypes":"디저트/차/베이커리","latitude":37.5038595744936,"longitude":127.111531540548,"bookYear":null,"nameKR":"푸가커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32733,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yunmunju/","foodTypes":"디저트/차/베이커리","latitude":37.5203140390086,"longitude":127.113265047998,"bookYear":null,"nameKR":"르봉마리아쥬","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32775,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bossanova_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5158226257174,"longitude":127.114669802108,"bookYear":null,"nameKR":"보사노바커피로스터스(잠실점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":32951,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eomuljeon_chung/","foodTypes":"바/주점","latitude":37.5138710747422,"longitude":127.106364392884,"bookYear":null,"nameKR":"어물전청(잠실점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33003,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yonampizza/","foodTypes":"이탈리아식","latitude":37.5108834930961,"longitude":127.081867388412,"bookYear":null,"nameKR":"요리하는남자","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33387,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/songpadon/","foodTypes":"한식(육류)","latitude":37.5127330893982,"longitude":127.120016251041,"bookYear":null,"nameKR":"송돈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33517,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.5148617378215,"longitude":127.107745425802,"bookYear":null,"nameKR":"샛집남원추어탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33544,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/onesrestaurant/","foodTypes":"프랑스식","latitude":37.5039022610292,"longitude":127.097502225593,"bookYear":null,"nameKR":"원스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33547,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5106146799736,"longitude":127.108234270692,"bookYear":null,"nameKR":"마운틴누크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33784,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/alternative.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5074539497873,"longitude":127.113802070905,"bookYear":null,"nameKR":"얼터너티브","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33850,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5097234363653,"longitude":127.083814002973,"bookYear":null,"nameKR":"영오이칠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33972,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5083601991062,"longitude":127.109589372688,"bookYear":null,"nameKR":"스웨덴피크닉송리단길","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":33974,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maro_patisserie/","foodTypes":"디저트/차/베이커리","latitude":37.5077669299288,"longitude":127.107583106525,"bookYear":null,"nameKR":"마로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34049,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/miplounge/","foodTypes":"바/주점","latitude":37.5099799418237,"longitude":127.105927037774,"bookYear":null,"nameKR":"밉","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34050,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/roofcottage/","foodTypes":"바/주점","latitude":37.5088377886634,"longitude":127.106372745781,"bookYear":null,"nameKR":"루프코티지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34052,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/matdaum_/","foodTypes":"바/주점","latitude":37.5106957875782,"longitude":127.108018582125,"bookYear":null,"nameKR":"맞닿음(잠실본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34098,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gobbun_songpa/","foodTypes":"한식(육류)","latitude":37.5007506928673,"longitude":127.106711740392,"bookYear":null,"nameKR":"곱분이곱창(송파중앙점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34109,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soomiyangga/","foodTypes":"일식","latitude":37.5082357728572,"longitude":127.109780785561,"bookYear":null,"nameKR":"수미양가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34123,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5173453447287,"longitude":127.100709917003,"bookYear":null,"nameKR":"커피나인(잠실장미상가점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34241,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5048857500852,"longitude":127.105122280273,"bookYear":null,"nameKR":"봉평발아메밀막국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34263,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woodgreen4717/","foodTypes":"이탈리아식","latitude":37.5119557370102,"longitude":127.12468103375,"bookYear":null,"nameKR":"우드그린4717","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34354,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/chitchat_songpa","foodTypes":"기타","latitude":37.5066902027774,"longitude":127.094781280361,"bookYear":null,"nameKR":"칫챗","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34507,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/claircle_patisserie/","foodTypes":"디저트/차/베이커리","latitude":37.5113188094349,"longitude":127.123832126995,"bookYear":null,"nameKR":"끌레르끌레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":34651,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5117930719582,"longitude":127.109246812748,"bookYear":null,"nameKR":"청기와타운(잠실점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35598,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5137519612953,"longitude":127.104446890835,"bookYear":null,"nameKR":"스시이세이(롯데백화점에비뉴엘월드타워점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35644,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5094038127388,"longitude":127.109998298112,"bookYear":null,"nameKR":"토나리우동","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35683,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5061875220992,"longitude":127.098968471356,"bookYear":null,"nameKR":"일일양(석촌호수점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35820,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5087190743487,"longitude":127.106530468724,"bookYear":null,"nameKR":"온나","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35923,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5071345100566,"longitude":127.108376841868,"bookYear":null,"nameKR":"완도산회포장마차(송파직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35929,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/manfromokinawa_highball/","foodTypes":"바/주점","latitude":37.5107807662347,"longitude":127.109471435885,"bookYear":null,"nameKR":"맨프롬오키나와(2호점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":35937,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5120136412368,"longitude":127.124538953723,"bookYear":null,"nameKR":"평양만두한상","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36281,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/backworry/","foodTypes":"디저트/차/베이커리","latitude":37.5096525962336,"longitude":127.111327410471,"bookYear":null,"nameKR":"백워리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36334,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5043387605142,"longitude":127.095674820603,"bookYear":null,"nameKR":"완도산회(삼전점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36335,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5069272566288,"longitude":127.109183850173,"bookYear":null,"nameKR":"완도산회(송파별관)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36347,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5113096112036,"longitude":127.098141751177,"bookYear":null,"nameKR":"청년다방(롯데월드점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36376,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/road_sandwich/","foodTypes":"기타","latitude":37.502841947835,"longitude":127.084156787858,"bookYear":null,"nameKR":"로드샌드위치(잠실점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36388,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/temiz_kr","foodTypes":"디저트/차/베이커리","latitude":37.5112935732469,"longitude":127.107854760373,"bookYear":null,"nameKR":"테미즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36409,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/machai_bangee","foodTypes":"중식","latitude":37.5120866023654,"longitude":127.112433147977,"bookYear":null,"nameKR":"마차이(방이점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36509,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/lebriller_songpa","foodTypes":"기타","latitude":37.5082357728572,"longitude":127.109780785561,"bookYear":null,"nameKR":"르브리에","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36531,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/giornoenotte_seoul","foodTypes":"이탈리아식","latitude":37.5070091848306,"longitude":127.12116919073,"bookYear":null,"nameKR":"조르노에노떼 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36581,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.5101952096632,"longitude":127.110817773732,"bookYear":null,"nameKR":"더빛남","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":36661,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/riff_coffee_bar/","foodTypes":"디저트/차/베이커리","latitude":37.5092421352568,"longitude":127.108283411661,"bookYear":null,"nameKR":"리프커피바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":133,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.50902,"longitude":127.1056,"bookYear":null,"nameKR":"깊은맛남원추어탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":609,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.51653,"longitude":127.1139,"bookYear":null,"nameKR":"어양","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":963,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/haejoo1983/","foodTypes":"한식(면류)","latitude":37.5096527123279,"longitude":127.079773391153,"bookYear":null,"nameKR":"해주냉면","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":1225,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.508536522806466,"longitude":127.08294936476561,"bookYear":null,"nameKR":"와라비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":3475,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.511368,"longitude":127.12689,"bookYear":null,"nameKR":"미우미우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":3995,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5131688791398,"longitude":127.122498795803,"bookYear":null,"nameKR":"우리강산","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":4718,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5126060876663,"longitude":127.124076192984,"bookYear":null,"nameKR":"마시마니","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":4758,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.511236,"longitude":127.098004,"bookYear":null,"nameKR":"도림(롯데호텔월드)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":5005,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5076453754084,"longitude":127.102735192556,"bookYear":null,"nameKR":"송파나루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":5008,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.510889,"longitude":127.082593,"bookYear":null,"nameKR":"전주설렁탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":5065,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.509154,"longitude":127.079687,"bookYear":null,"nameKR":"갯마을낙지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":5067,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.510194,"longitude":127.125125,"bookYear":null,"nameKR":"올림픽양곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":5068,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.5114786473939,"longitude":127.113331548837,"bookYear":null,"nameKR":"한방삼계탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":5592,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/golden_thai_restaurant/","foodTypes":"아시아식","latitude":37.51466,"longitude":127.1194,"bookYear":null,"nameKR":"골든타이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":7339,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.505156,"longitude":127.090057,"bookYear":null,"nameKR":"원광","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":7369,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/napoleon_bakery1968/","foodTypes":"디저트/차/베이커리","latitude":37.510752,"longitude":127.078933,"bookYear":null,"nameKR":"나폴레옹과자점(잠실점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":7594,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.50482,"longitude":127.0871,"bookYear":null,"nameKR":"비사벌전주콩나물국밥(송파점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":8969,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5104513955728,"longitude":127.084896556557,"bookYear":null,"nameKR":"동래파전","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":9084,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.50628,"longitude":127.0901,"bookYear":null,"nameKR":"유원설렁탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":9375,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.511235,"longitude":127.098027,"bookYear":null,"nameKR":"라세느(롯데호텔월드)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":13161,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moccozie/","foodTypes":"한식(일반한식)","latitude":37.500441,"longitude":127.11229,"bookYear":null,"nameKR":"모꼬지에","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":13338,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.509638,"longitude":127.084749,"bookYear":null,"nameKR":"탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":13389,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5069630013895,"longitude":127.090682086454,"bookYear":null,"nameKR":"돈까스의집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":14784,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.506335,"longitude":127.096294,"bookYear":null,"nameKR":"호림","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":15163,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.514567,"longitude":127.102493,"bookYear":null,"nameKR":"예솔","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":15167,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.511125,"longitude":127.107424,"bookYear":null,"nameKR":"석촌호민물장어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":15321,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.509617,"longitude":127.082874,"bookYear":null,"nameKR":"알루메","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":16625,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5154937300715,"longitude":127.113299840522,"bookYear":null,"nameKR":"가일","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":17591,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.52014,"longitude":127.0817,"bookYear":null,"nameKR":"하늘정원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":19250,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.504298,"longitude":127.095663,"bookYear":null,"nameKR":"완도산","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":19433,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.510909,"longitude":127.118439,"bookYear":null,"nameKR":"황산냉면","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":20549,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":0,"longitude":0,"bookYear":null,"nameKR":"몽중헌(방이점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":20917,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seojeong_salon","foodTypes":"디저트/차/베이커리","latitude":37.51019,"longitude":127.1062,"bookYear":null,"nameKR":"서정적살롱","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":21601,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.5058876761527,"longitude":127.090885267773,"bookYear":null,"nameKR":"갯돌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":21613,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.505804,"longitude":127.117619,"bookYear":null,"nameKR":"엄지손칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":21622,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.502981,"longitude":127.093717,"bookYear":null,"nameKR":"도나우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":21821,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.503107913259,"longitude":127.083919475308,"bookYear":null,"nameKR":"레빵뺑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":22172,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.507632,"longitude":127.107738,"bookYear":null,"nameKR":"도키도키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":23452,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.505507,"longitude":127.096847,"bookYear":null,"nameKR":"후포항","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":23455,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.511007,"longitude":127.094003,"bookYear":null,"nameKR":"한우리(롯데잠실점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":23462,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.508925,"longitude":127.086069,"bookYear":null,"nameKR":"대성정육식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":23576,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.500172,"longitude":127.105418,"bookYear":null,"nameKR":"송파감자국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":23578,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.501114,"longitude":127.109221,"bookYear":null,"nameKR":"진미옥설렁탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":25372,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.506989,"longitude":127.115202,"bookYear":null,"nameKR":"원산만두","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":25554,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.50942,"longitude":127.079319,"bookYear":null,"nameKR":"대호갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":26385,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.502321,"longitude":127.112276,"bookYear":null,"nameKR":"오떡","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":26613,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.532253,"longitude":127.119099,"bookYear":null,"nameKR":"어리랑횟집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":28529,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.51375250260386,"longitude":127.10444609981751,"bookYear":null,"nameKR":"피에프창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":29235,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/get.some.coffee","foodTypes":"디저트/차/베이커리","latitude":37.5135291,"longitude":127.1038651,"bookYear":null,"nameKR":"겟썸커피LMoA","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":29396,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.515759,"longitude":127.098878,"bookYear":null,"nameKR":"만포면옥소반","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":29583,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vatoskorea/","foodTypes":"중남미식","latitude":37.51131078364221,"longitude":127.09814039543262,"bookYear":null,"nameKR":"바토스(잠실점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":29593,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.51375250260386,"longitude":127.10444609981751,"bookYear":null,"nameKR":"김영모(잠실에비뉴엘점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":29828,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.514644435086836,"longitude":127.11045583437378,"bookYear":null,"nameKR":"양휘당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"25. 잠실/방이/올림픽공원","status":"ACTIVE"},
        {"id":819,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.492549,"longitude":127.124894,"bookYear":null,"nameKR":"취영루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":9266,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.508648,"longitude":127.130342,"bookYear":null,"nameKR":"춘향골남원추어탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":15373,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.503947,"longitude":127.127191,"bookYear":null,"nameKR":"래향","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":18540,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4776148442516,"longitude":127.124961768886,"bookYear":null,"nameKR":"하루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":18542,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4776148442516,"longitude":127.124961768886,"bookYear":null,"nameKR":"다온이네","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":18573,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.497519993190735,"longitude":127.14810273335581,"bookYear":null,"nameKR":"사랑방","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":19430,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.49854,"longitude":127.121843,"bookYear":null,"nameKR":"선복한우촌총각들","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":23464,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.503328,"longitude":127.129094,"bookYear":null,"nameKR":"윤화돈까스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":24460,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4890561526937,"longitude":127.123469604083,"bookYear":null,"nameKR":"골목떡볶이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":29733,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bk023049293/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.48535322415914,"longitude":127.11922743703323,"bookYear":null,"nameKR":"배꼽집(문정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":30824,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cho__kwang/","foodTypes":"중식","latitude":37.4815263519172,"longitude":127.126776704558,"bookYear":null,"nameKR":"조광201","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":30950,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.488125746629,"longitude":127.111059136873,"bookYear":null,"nameKR":"코른베르크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":31825,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kirinchina","foodTypes":"중식","latitude":37.5006501055225,"longitude":127.138286135038,"bookYear":null,"nameKR":"기린차이나","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":32284,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/weeklycoffee.official/","foodTypes":"디저트/차/베이커리","latitude":37.4786171055932,"longitude":127.143758763103,"bookYear":null,"nameKR":"위클리커피(송파위례점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":32922,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pubgoldfish/","foodTypes":"바/주점","latitude":37.4834511127693,"longitude":127.12463410359,"bookYear":null,"nameKR":"주점금붕어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":33230,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4947481770462,"longitude":127.145398715283,"bookYear":null,"nameKR":"유성닭갈비 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":33234,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4927672575467,"longitude":127.128089095334,"bookYear":null,"nameKR":"일도씨닭갈비(문정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":33726,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4983080339873,"longitude":127.151155298044,"bookYear":null,"nameKR":"일도불백","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":33885,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aller_a_paris","foodTypes":"디저트/차/베이커리","latitude":37.4970663126363,"longitude":127.134261838459,"bookYear":null,"nameKR":"알레아파리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":33999,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/holapoke_/","foodTypes":"기타","latitude":37.4846614982049,"longitude":127.120933640924,"bookYear":null,"nameKR":"올라포케","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":34012,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4951589857894,"longitude":127.117235587428,"bookYear":null,"nameKR":"가락김밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":34023,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/katsudan_gil/ ","foodTypes":"일식","latitude":37.4877387829749,"longitude":127.120685887596,"bookYear":null,"nameKR":"카츠단길","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":34242,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4822372129744,"longitude":127.127311561889,"bookYear":null,"nameKR":"조광어선","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":34647,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4860582505835,"longitude":127.117802423963,"bookYear":null,"nameKR":"코메벤또","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":34671,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4955097167446,"longitude":127.119870059952,"bookYear":null,"nameKR":"청기와타운(가락점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":35191,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/dartagnan_coffee","foodTypes":"디저트/차/베이커리","latitude":37.4935982824456,"longitude":127.133357888939,"bookYear":null,"nameKR":"달타냥의커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":35539,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woobool","foodTypes":"한식(면류)","latitude":37.4948868947464,"longitude":127.120093192726,"bookYear":null,"nameKR":"우불식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":35715,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4892847365294,"longitude":127.127875772051,"bookYear":null,"nameKR":"브런치하우스본점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":35905,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chunmimi_garak","foodTypes":"중식","latitude":37.4941449655427,"longitude":127.12022159525,"bookYear":null,"nameKR":"천미미(가락점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":35975,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bmkmoon/","foodTypes":"일식","latitude":37.4868314347245,"longitude":127.120453991896,"bookYear":null,"nameKR":"스시문정","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":36041,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nyamnyam_mulgogi/","foodTypes":"일식","latitude":37.4946908483153,"longitude":127.123833842595,"bookYear":null,"nameKR":"냠냠물고기(2호점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":36042,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4970069754843,"longitude":127.120699217866,"bookYear":null,"nameKR":"냠냠물고기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":36060,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5015343677051,"longitude":127.124072910932,"bookYear":null,"nameKR":"옥돌현옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":36124,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/make_brunch_house","foodTypes":"기타","latitude":37.4957980686962,"longitude":127.14997982818,"bookYear":null,"nameKR":"만들다","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"24. 가락/문정/오금/위례","status":"ACTIVE"},
        {"id":31910,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.4941313928275,"longitude":127.078783141187,"bookYear":null,"nameKR":"포나타이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":33257,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4873191631489,"longitude":127.103108072457,"bookYear":null,"nameKR":"스시야","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":33425,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4873191631489,"longitude":127.103108072457,"bookYear":null,"nameKR":"초원복집 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":35158,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/heavylayers.official/","foodTypes":"디저트/차/베이커리","latitude":37.4910310194905,"longitude":127.086890378738,"bookYear":null,"nameKR":"헤비레이어스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":35748,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joobaksa_gaepo/","foodTypes":"한식(가금류)","latitude":37.4892333041022,"longitude":127.067955632075,"bookYear":null,"nameKR":"주박사닭갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":36249,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.4932374890678,"longitude":127.085789845348,"bookYear":null,"nameKR":"하치타치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":36553,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/602_dessert/","foodTypes":"디저트/차/베이커리","latitude":37.4915685832333,"longitude":127.082716316269,"bookYear":null,"nameKR":"602DESSERT","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":3424,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.465651,"longitude":127.101117,"bookYear":null,"nameKR":"커피와쟁이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":3702,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.485829,"longitude":127.098689,"bookYear":null,"nameKR":"예소담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":13239,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.487986,"longitude":127.101777,"bookYear":null,"nameKR":"달팽이밥상","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":13328,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.46311,"longitude":127.107323,"bookYear":null,"nameKR":"초원식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":14158,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.485558,"longitude":127.098323,"bookYear":null,"nameKR":"가원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":15733,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.4737791164507,"longitude":127.108593084934,"bookYear":null,"nameKR":"목포명가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":19448,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.488334,"longitude":127.10248,"bookYear":null,"nameKR":"와후진","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":22087,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.479272,"longitude":127.102658,"bookYear":null,"nameKR":"단미그린비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"23. 개포/일원/수서","status":"ACTIVE"},
        {"id":35574,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/szi_ok","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4855747470468,"longitude":127.045459163565,"bookYear":null,"nameKR":"스지옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":30107,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dot506_official","foodTypes":"한식(육류)","latitude":37.49824899107963,"longitude":127.03620592712366,"bookYear":null,"nameKR":"돝고기506","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":31735,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"호주식","latitude":37.4997440471512,"longitude":127.034954751198,"bookYear":null,"nameKR":"빌즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":32605,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hakucho_han/","foodTypes":"일식","latitude":37.5019949322814,"longitude":127.037336400239,"bookYear":null,"nameKR":"스시하쿠초","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":33113,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeok3_dongbong/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4962830215716,"longitude":127.044800493496,"bookYear":null,"nameKR":"동봉관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":33378,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ssong_thai/","foodTypes":"아시아식","latitude":37.5056843062253,"longitude":127.040272004532,"bookYear":null,"nameKR":"쏭타이(역삼점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":33776,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5050548092998,"longitude":127.039902064615,"bookYear":null,"nameKR":"범표원두","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":34145,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.502589421595,"longitude":127.034962184701,"bookYear":null,"nameKR":"갈비다움(역삼본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":34629,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5024216299947,"longitude":127.035044325267,"bookYear":null,"nameKR":"르와조역삼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":35469,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fig_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5051503517396,"longitude":127.040062261359,"bookYear":null,"nameKR":"피그커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":35492,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4987459642102,"longitude":127.036859587938,"bookYear":null,"nameKR":"레이첼서울","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":35499,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.504331825662,"longitude":127.041519859513,"bookYear":null,"nameKR":"수라선역삼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":36229,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeenine_teheranro/","foodTypes":"디저트/차/베이커리","latitude":37.5009074585153,"longitude":127.034228797048,"bookYear":null,"nameKR":"커피나인(테헤란로점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":36237,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5026361264434,"longitude":127.039165329793,"bookYear":null,"nameKR":"오성식당(역삼점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":36365,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4954543973812,"longitude":127.038858291112,"bookYear":null,"nameKR":"fyi","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":36422,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/beomgol","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5003600931959,"longitude":127.043055799448,"bookYear":null,"nameKR":"범골","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":368,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.497946,"longitude":127.034375,"bookYear":null,"nameKR":"바코드플러스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":640,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.505912,"longitude":127.032554,"bookYear":null,"nameKR":"옛골","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":1281,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.499103,"longitude":127.037411,"bookYear":null,"nameKR":"최수사","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":1300,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.500866,"longitude":127.041048,"bookYear":null,"nameKR":"킹콩생고기전문점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":1335,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5051770065812,"longitude":127.042175840233,"bookYear":null,"nameKR":"황우양곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":3944,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.506298,"longitude":127.040316,"bookYear":null,"nameKR":"자르뎅페르뒤","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":4634,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.498861,"longitude":127.0345,"bookYear":null,"nameKR":"텀블러비어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":4869,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.50023,"longitude":127.0333,"bookYear":null,"nameKR":"그릴아지사이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":7090,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.501859,"longitude":127.037278,"bookYear":null,"nameKR":"강가(역삼점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":7552,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.503987,"longitude":127.040094,"bookYear":null,"nameKR":"리밍","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":9055,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4988193799338,"longitude":127.036622252659,"bookYear":null,"nameKR":"현대기사식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":12782,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.499927,"longitude":127.036553,"bookYear":null,"nameKR":"클로리스티앤커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":13368,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.50469,"longitude":127.043847,"bookYear":null,"nameKR":"선릉순대국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":14174,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.499363,"longitude":127.039406,"bookYear":null,"nameKR":"고갯마루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":14528,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5010094728706,"longitude":127.031902332706,"bookYear":null,"nameKR":"예담한정식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":14845,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.507339,"longitude":127.035168,"bookYear":null,"nameKR":"가야식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":15045,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.502748,"longitude":127.040097,"bookYear":null,"nameKR":"진수사","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":15479,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.495652,"longitude":127.040899,"bookYear":null,"nameKR":"만다린","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":15726,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5035644581254,"longitude":127.040162407776,"bookYear":null,"nameKR":"삼호복집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":15758,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5031028684087,"longitude":127.04017584493,"bookYear":null,"nameKR":"강구항","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":19813,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5027639048107,"longitude":127.038569731582,"bookYear":null,"nameKR":"삼다족발","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":19882,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.503332,"longitude":127.039601,"bookYear":null,"nameKR":"백운봉막국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":21569,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5031531623026,"longitude":127.040387923823,"bookYear":null,"nameKR":"연스시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":24351,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.494522,"longitude":127.039346,"bookYear":null,"nameKR":"아야진생태찌개(역삼직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":26358,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5035512468705,"longitude":127.03644677852,"bookYear":null,"nameKR":"농협한우금강정육식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":28356,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"유럽식","latitude":37.4975536471595,"longitude":127.033902370346,"bookYear":null,"nameKR":"헴라갓","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":28967,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/zac161017/, https://zac1001.modoo.at/","foodTypes":"바/주점","latitude":37.502337,"longitude":127.034633,"bookYear":null,"nameKR":"작","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":29056,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/minihansang/","foodTypes":"한식(일반한식)","latitude":37.501096,"longitude":127.032166,"bookYear":null,"nameKR":"민이한상","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":29107,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_an_sim_/","foodTypes":"바/주점","latitude":37.505087,"longitude":127.042586,"bookYear":null,"nameKR":"재패니즈다이닝안심","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":29637,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.502472682625246,"longitude":127.03693176396266,"bookYear":null,"nameKR":"해머스미스커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"08. 역삼동/언주역","status":"ACTIVE"},
        {"id":36706,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.screme/","foodTypes":"디저트/차/베이커리","latitude":37.5037271673496,"longitude":127.026468923524,"bookYear":null,"nameKR":"스크렘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":36656,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/marke_bakery","foodTypes":"디저트/차/베이커리","latitude":37.5088775618491,"longitude":127.023136917683,"bookYear":null,"nameKR":"마르케","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":35985,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.520520357927,"longitude":127.013749920164,"bookYear":null,"nameKR":"흐르르","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":36273,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5215311909818,"longitude":127.038295679925,"bookYear":null,"nameKR":"해목(논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":27815,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5619913938821,"longitude":126.921327116702,"bookYear":null,"nameKR":"유어로스팅파크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28041,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.56071,"longitude":126.922892,"bookYear":null,"nameKR":"배꼽시계","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":25772,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.559033,"longitude":126.922509,"bookYear":null,"nameKR":"마포곱창타운","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27341,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/180901start/","foodTypes":"한식(육류)","latitude":37.563164,"longitude":126.925818,"bookYear":null,"nameKR":"윤씨그릴방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":29264,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.56281,"longitude":126.926029,"bookYear":null,"nameKR":"포가","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":5144,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.554672,"longitude":126.913454,"bookYear":null,"nameKR":"명품잔치국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":16957,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.560315,"longitude":126.923182,"bookYear":null,"nameKR":"카페그랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":18906,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.558275,"longitude":126.924067,"bookYear":null,"nameKR":"족발예찬","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27236,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.556187,"longitude":126.912472,"bookYear":null,"nameKR":"그랭블레","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28248,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.55423,"longitude":126.91259,"bookYear":null,"nameKR":"홍어한마리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31539,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hasu0198/","foodTypes":"일식","latitude":37.5598711223182,"longitude":126.925420464417,"bookYear":null,"nameKR":"심야식당 하스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32780,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistro_goat","foodTypes":"이탈리아식","latitude":37.5584199276672,"longitude":126.923931245638,"bookYear":null,"nameKR":"비스트로고트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":5178,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.554085,"longitude":126.918774,"bookYear":null,"nameKR":"춘자대구탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":7747,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.550114,"longitude":126.920507,"bookYear":null,"nameKR":"심양(홍대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":12929,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.550369,"longitude":126.921355,"bookYear":null,"nameKR":"홍대조폭떡볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":15077,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.553081,"longitude":126.914024,"bookYear":null,"nameKR":"남경장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":15273,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중남미식","latitude":37.548804,"longitude":126.92124,"bookYear":null,"nameKR":"훌리오","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":21112,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.556408,"longitude":126.918879,"bookYear":null,"nameKR":"손탁커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26872,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thai_square_official/","foodTypes":"아시아식","latitude":37.549068,"longitude":126.92031,"bookYear":null,"nameKR":"타이스퀘어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27384,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.55003,"longitude":126.922885,"bookYear":null,"nameKR":"비닐","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27803,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5548421305601,"longitude":126.916810339406,"bookYear":null,"nameKR":"가츠시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28314,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.548434,"longitude":126.919565,"bookYear":null,"nameKR":"마녀커리크림치킨(상수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5143,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.554611,"longitude":126.924083,"bookYear":null,"nameKR":"가미우동","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5794,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hongchef_bistropub/","foodTypes":"이탈리아식","latitude":37.555717,"longitude":126.920158,"bookYear":null,"nameKR":"홍쉐프","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5917,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.55084,"longitude":126.9234,"bookYear":null,"nameKR":"초마","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5960,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.55364,"longitude":126.9214,"bookYear":null,"nameKR":"멘야산다이메","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":8417,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.553772,"longitude":126.920235,"bookYear":null,"nameKR":"백년토종삼계탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":13349,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.552686,"longitude":126.923543,"bookYear":null,"nameKR":"다락투","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26903,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.552139,"longitude":126.920609,"bookYear":null,"nameKR":"코노미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27194,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.552602,"longitude":126.922018,"bookYear":null,"nameKR":"혼가츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27407,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.554023,"longitude":126.920578,"bookYear":null,"nameKR":"옹다래찜닭","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27415,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lovely_damso/","foodTypes":"디저트/차/베이커리","latitude":37.554395,"longitude":126.927039,"bookYear":null,"nameKR":"담소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27487,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/monobloc_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.553663,"longitude":126.924485,"bookYear":null,"nameKR":"카페모노블록","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28002,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_ginger_pig/","foodTypes":"한식(육류)","latitude":37.5543,"longitude":126.921,"bookYear":null,"nameKR":"진저피그","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31588,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tonkun0910","foodTypes":"바/주점","latitude":37.5546698461682,"longitude":126.918862342051,"bookYear":null,"nameKR":"동꾼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31812,"bookStatus":"비활성","websiteInstagram":"https://www.instagram/cafe_saeroc","foodTypes":"디저트/차/베이커리","latitude":37.5529267490123,"longitude":126.923547299692,"bookYear":null,"nameKR":"카페,새록","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32364,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tsukeru_tsukemen/","foodTypes":"일식","latitude":37.5534328399931,"longitude":126.924387637935,"bookYear":null,"nameKR":"츠케루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":34598,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lucyd_seoul/","foodTypes":"기타","latitude":37.5473800413227,"longitude":126.922195031355,"bookYear":null,"nameKR":"루시드서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":35236,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/plate946","foodTypes":"이탈리아식","latitude":37.5485942331949,"longitude":126.92513136687,"bookYear":null,"nameKR":"플레이트946","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":8453,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.555915,"longitude":126.927259,"bookYear":null,"nameKR":"칼디커피서덕식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":19739,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.555552,"longitude":126.926482,"bookYear":null,"nameKR":"흥부네부대찌개","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":23222,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.545436,"longitude":126.92031,"bookYear":null,"nameKR":"그문화다방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":25674,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.54609,"longitude":126.9213,"bookYear":null,"nameKR":"김씨네심야식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26249,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.556712,"longitude":126.926474,"bookYear":null,"nameKR":"조개이야기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26859,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.54795,"longitude":126.920693,"bookYear":null,"nameKR":"시연","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26895,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.548514,"longitude":126.923409,"bookYear":null,"nameKR":"코다차야(홍대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26902,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.54864,"longitude":126.9213,"bookYear":null,"nameKR":"히메시야","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27089,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.555816,"longitude":126.926695,"bookYear":null,"nameKR":"커피101","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27372,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/austin_hongdae/","foodTypes":"기타","latitude":37.548547,"longitude":126.922123,"bookYear":null,"nameKR":"오스틴","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27390,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/starburger2014/","foodTypes":"기타","latitude":37.54795,"longitude":126.920693,"bookYear":null,"nameKR":"별버거","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":700,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.534161,"longitude":127.008614,"bookYear":null,"nameKR":"웨스턴차이나","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":4108,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.534249,"longitude":127.007918,"bookYear":null,"nameKR":"두에꼬제","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":24383,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.54837,"longitude":127.0202,"bookYear":null,"nameKR":"부원냉면","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":28619,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.534802,"longitude":127.008807,"bookYear":null,"nameKR":"한남리커","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33325,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/usualcookie/","foodTypes":"디저트/차/베이커리","latitude":37.5378545835948,"longitude":127.003247877892,"bookYear":null,"nameKR":"유주얼쿠키","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":34243,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_0ju/","foodTypes":"디저트/차/베이커리","latitude":37.5680943778524,"longitude":127.006460574612,"bookYear":null,"nameKR":"영주다방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":8820,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.57015,"longitude":127.00136,"bookYear":null,"nameKR":"오라이등심","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":12919,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.572748,"longitude":127.004569,"bookYear":null,"nameKR":"방아다리감자국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":16252,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.566836,"longitude":127.007374,"bookYear":null,"nameKR":"에베레스트(동대문역사문화공원역점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":3964,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.560176,"longitude":127.003111,"bookYear":null,"nameKR":"오래복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":5633,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.559722,"longitude":127.000056,"bookYear":null,"nameKR":"족회관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8796,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.570244,"longitude":127.019679,"bookYear":null,"nameKR":"꼭지네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8803,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.569963,"longitude":127.019691,"bookYear":null,"nameKR":"목포회관곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8815,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.571736,"longitude":127.016318,"bookYear":null,"nameKR":"서울회관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":8818,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.570017,"longitude":127.019686,"bookYear":null,"nameKR":"영광곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8819,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.570075,"longitude":127.01968,"bookYear":null,"nameKR":"영미네집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8837,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.570146,"longitude":127.019683,"bookYear":null,"nameKR":"행복한고향곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":12567,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.570345,"longitude":126.999838,"bookYear":null,"nameKR":"창신육회","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":8665,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.56247,"longitude":127.01471,"bookYear":null,"nameKR":"들국화","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8666,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.562616,"longitude":127.014916,"bookYear":null,"nameKR":"우리집떡볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8667,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.562751,"longitude":127.014905,"bookYear":null,"nameKR":"우정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8685,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.562616,"longitude":127.014916,"bookYear":null,"nameKR":"신당동원조종점떡볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8686,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.562541,"longitude":127.014999,"bookYear":null,"nameKR":"아이러브신당동","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8790,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.562842,"longitude":127.015052,"bookYear":null,"nameKR":"미니네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8798,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.566455,"longitude":127.012872,"bookYear":null,"nameKR":"느티나무설렁탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":15185,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.569962,"longitude":126.989863,"bookYear":null,"nameKR":"종로설렁탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":23867,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.551484,"longitude":127.007718,"bookYear":null,"nameKR":"약수한방삼계탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":635,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.57323,"longitude":126.9888,"bookYear":null,"nameKR":"영일식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":717,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.571955,"longitude":126.986299,"bookYear":null,"nameKR":"이조","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":3427,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.572822,"longitude":126.990816,"bookYear":null,"nameKR":"진주대박집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7830,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.572562,"longitude":126.985524,"bookYear":null,"nameKR":"얼큰한조벡이수제비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8592,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/365memil/","foodTypes":"바/주점","latitude":37.57309,"longitude":126.9869,"bookYear":null,"nameKR":"메밀꽃필무렵","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8605,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.574129,"longitude":126.989168,"bookYear":null,"nameKR":"연죽","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8625,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.572896,"longitude":126.986632,"bookYear":null,"nameKR":"천둥소리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":12927,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57584,"longitude":126.9897,"bookYear":null,"nameKR":"궁한정식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":13393,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.572988,"longitude":126.986051,"bookYear":null,"nameKR":"낙원떡집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":13567,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.572434,"longitude":126.989924,"bookYear":null,"nameKR":"파전집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":18224,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.572534,"longitude":126.98779,"bookYear":null,"nameKR":"일미식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":19453,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.573189,"longitude":126.990677,"bookYear":null,"nameKR":"통돼지집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":26475,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.57297,"longitude":126.990883,"bookYear":null,"nameKR":"호남선","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":31210,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tigertiger_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5743350300254,"longitude":126.990803927532,"bookYear":null,"nameKR":"타이거타이거","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":556,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.574216,"longitude":126.983895,"bookYear":null,"nameKR":"신옛찻집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":3973,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.574446,"longitude":126.985237,"bookYear":null,"nameKR":"잔치집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":4830,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.573437,"longitude":126.985465,"bookYear":null,"nameKR":"지대방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7736,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.569727,"longitude":126.984071,"bookYear":null,"nameKR":"두르가(종각점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7832,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.57428,"longitude":126.9859,"bookYear":null,"nameKR":"인사동수제비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7937,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.575433,"longitude":126.984046,"bookYear":null,"nameKR":"홍어가막걸리를만났을때","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8583,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57465,"longitude":126.984541,"bookYear":null,"nameKR":"가회","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8598,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57498,"longitude":126.984223,"bookYear":null,"nameKR":"시골밥상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8601,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5748,"longitude":126.985637,"bookYear":null,"nameKR":"수연","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8609,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.574774,"longitude":126.985051,"bookYear":null,"nameKR":"옥정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8614,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.573442,"longitude":126.98634,"bookYear":null,"nameKR":"인사동큰집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8627,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.574888,"longitude":126.985027,"bookYear":null,"nameKR":"촌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8628,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5743,"longitude":126.985152,"bookYear":null,"nameKR":"친절한현자씨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8630,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.575108,"longitude":126.984038,"bookYear":null,"nameKR":"태화","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8636,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.57075,"longitude":126.990333,"bookYear":null,"nameKR":"피맛골","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":9179,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.575101,"longitude":126.983394,"bookYear":null,"nameKR":"별다방미스리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":13011,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57431,"longitude":126.9845,"bookYear":null,"nameKR":"좋은씨앗","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":24455,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.575323,"longitude":126.98397,"bookYear":null,"nameKR":"음식이야기밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":29215,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.572716,"longitude":126.989723,"bookYear":null,"nameKR":"싱글벙글복어(종로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":3971,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.574017,"longitude":126.986107,"bookYear":null,"nameKR":"최대감네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":4804,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.568374,"longitude":126.988656,"bookYear":null,"nameKR":"포탈라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7950,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.56982,"longitude":126.990779,"bookYear":null,"nameKR":"흥부가","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8655,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.569676,"longitude":126.989956,"bookYear":null,"nameKR":"이대감고깃집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8737,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.568979,"longitude":126.985501,"bookYear":null,"nameKR":"만보성","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8844,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.568619,"longitude":126.988237,"bookYear":null,"nameKR":"돈가네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8868,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.569511,"longitude":126.989673,"bookYear":null,"nameKR":"한일장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":12928,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.56925,"longitude":126.9908,"bookYear":null,"nameKR":"한도삼겹살","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":421,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.563828,"longitude":126.992111,"bookYear":null,"nameKR":"부산복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":5637,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.564806,"longitude":126.990333,"bookYear":null,"nameKR":"춘천손칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8511,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.560971,"longitude":126.998294,"bookYear":null,"nameKR":"강서","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8512,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.562619,"longitude":126.99208,"bookYear":null,"nameKR":"고꼬로오뎅","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8521,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.56163,"longitude":126.991873,"bookYear":null,"nameKR":"동심","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8532,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.563513,"longitude":126.991995,"bookYear":null,"nameKR":"원조왕순대","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8533,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.561759,"longitude":126.991805,"bookYear":null,"nameKR":"장가안가","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8534,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.56403,"longitude":126.991044,"bookYear":null,"nameKR":"장독대","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8541,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.563184,"longitude":126.99144,"bookYear":null,"nameKR":"팔도보쌈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8839,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.568206,"longitude":126.999912,"bookYear":null,"nameKR":"호림정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":17580,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.563094,"longitude":126.99159,"bookYear":null,"nameKR":"어머니와고등어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":17979,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.563931,"longitude":126.990563,"bookYear":null,"nameKR":"정라진","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":23410,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.56508,"longitude":126.989741,"bookYear":null,"nameKR":"을지골뱅이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":32188,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5651799066544,"longitude":126.990569735043,"bookYear":null,"nameKR":"을지로차이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8697,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.565307,"longitude":126.989973,"bookYear":null,"nameKR":"나무한그루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8805,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5658,"longitude":126.995,"bookYear":null,"nameKR":"몽돌이네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8840,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.56591,"longitude":126.99497,"bookYear":null,"nameKR":"호반집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":18306,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.565924,"longitude":126.993053,"bookYear":null,"nameKR":"을지칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":19280,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.566461,"longitude":126.988496,"bookYear":null,"nameKR":"신라원삼계탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":22133,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.56705,"longitude":126.991567,"bookYear":null,"nameKR":"명동골뱅이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":22505,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.567358,"longitude":126.985136,"bookYear":null,"nameKR":"크리에이트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":785,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.541517,"longitude":126.973336,"bookYear":null,"nameKR":"쯔꾸시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":1002,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.534487,"longitude":126.974337,"bookYear":null,"nameKR":"골목식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":6610,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.544509,"longitude":126.966934,"bookYear":null,"nameKR":"이층집홍홍","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":6673,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5449,"longitude":126.96762,"bookYear":null,"nameKR":"더함","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":7891,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.536376,"longitude":126.961183,"bookYear":null,"nameKR":"원조영양족발회관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":8179,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.533926,"longitude":126.973323,"bookYear":null,"nameKR":"옛집국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":13386,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5075821755068,"longitude":126.959441961291,"bookYear":null,"nameKR":"모모치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":26987,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5063265822019,"longitude":126.962023563346,"bookYear":null,"nameKR":"삼목등심","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":28644,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.507504,"longitude":126.963631,"bookYear":null,"nameKR":"성민양꼬치(중앙대입구역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":29020,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/breadduck7/","foodTypes":"디저트/차/베이커리","latitude":37.5047956242888,"longitude":126.942297308681,"bookYear":null,"nameKR":"브레드덕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":32291,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/doyajib_","foodTypes":"한식(육류)","latitude":37.5318046607589,"longitude":126.972632800702,"bookYear":null,"nameKR":"도야집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":33932,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_curing/","foodTypes":"기타","latitude":37.5079352269786,"longitude":126.963713051621,"bookYear":null,"nameKR":"더큐어링","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":31503,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/paindemie_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.5613794906086,"longitude":127.040821470574,"bookYear":null,"nameKR":"뺑드미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":32895,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dalgddeogboggi/","foodTypes":"한식(가금류)","latitude":37.5024379063376,"longitude":126.950944652485,"bookYear":null,"nameKR":"은희네온집닭떡볶이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":33091,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5011794263125,"longitude":126.934336422614,"bookYear":null,"nameKR":"양씨네제빵소","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":34530,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5020554314725,"longitude":126.934097934607,"bookYear":null,"nameKR":"고향팥칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":35365,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.501587317933,"longitude":126.93716427007,"bookYear":null,"nameKR":"소문난곱창닭갈비전문점 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":35463,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5121656498804,"longitude":126.937662013492,"bookYear":null,"nameKR":"오코노미스토리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":36469,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.4944714903681,"longitude":126.958753828479,"bookYear":null,"nameKR":"고렝","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":36524,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/toms_kitchen_","foodTypes":"이탈리아식","latitude":37.5082107065725,"longitude":126.946118884633,"bookYear":null,"nameKR":"톰스키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":6371,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.511751,"longitude":126.945111,"bookYear":null,"nameKR":"와우신내떡","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":8167,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.504044,"longitude":126.949808,"bookYear":null,"nameKR":"오시오떡볶이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":9039,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5123857060582,"longitude":126.944401840416,"bookYear":null,"nameKR":"우정소금구이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":12635,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.565671,"longitude":127.06712,"bookYear":null,"nameKR":"비사벌전주콩나물국밥(장안점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":13259,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.506261930203834,"longitude":126.94773918513286,"bookYear":null,"nameKR":"다래식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":13584,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.512449,"longitude":126.93717,"bookYear":null,"nameKR":"남원골추어탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":14620,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.565387,"longitude":127.036059,"bookYear":null,"nameKR":"정은이네곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":15165,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5017090769881,"longitude":126.949224997137,"bookYear":null,"nameKR":"중국성","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":15289,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.499255839917,"longitude":126.951639401182,"bookYear":null,"nameKR":"다성반점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":15717,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.566937,"longitude":127.064202,"bookYear":null,"nameKR":"신용궁","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":15841,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5127084643805,"longitude":126.938271218334,"bookYear":null,"nameKR":"만리장성","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":17367,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.512208,"longitude":126.937826,"bookYear":null,"nameKR":"토속골","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":19807,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/t.p.y64/","foodTypes":"한식(어패류)","latitude":37.5148236866215,"longitude":126.937975950622,"bookYear":null,"nameKR":"태평양수산","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":19866,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.514969,"longitude":126.941385,"bookYear":null,"nameKR":"부안수산","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":22756,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5148236866215,"longitude":126.937975950622,"bookYear":null,"nameKR":"여수여천","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":22980,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.559468,"longitude":127.040463,"bookYear":null,"nameKR":"장어구이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":25536,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5118016078913,"longitude":126.954334348772,"bookYear":null,"nameKR":"소문난명품잔치국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":26246,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.509957,"longitude":126.939765,"bookYear":null,"nameKR":"오감만족","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":28431,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ohwall_bbo/","foodTypes":"디저트/차/베이커리","latitude":37.501523146270515,"longitude":126.93431169376034,"bookYear":null,"nameKR":"상도동카페오월","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":28447,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.501245214140845,"longitude":126.93355648615791,"bookYear":null,"nameKR":"나루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":29261,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.507091,"longitude":126.939606,"bookYear":null,"nameKR":"사이공리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":383,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.56932,"longitude":126.931049,"bookYear":null,"nameKR":"백암왕순대","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":776,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.566308,"longitude":126.928803,"bookYear":null,"nameKR":"진보","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":1248,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.566471,"longitude":126.92904,"bookYear":null,"nameKR":"이화원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":3725,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.557016,"longitude":126.936094,"bookYear":null,"nameKR":"춘천집닭갈비막국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":3896,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.558799,"longitude":126.938781,"bookYear":null,"nameKR":"신계치","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":8269,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.556483,"longitude":126.934027,"bookYear":null,"nameKR":"난향","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":8369,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.556074,"longitude":126.935798,"bookYear":null,"nameKR":"고향전주비빔밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":8447,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ajeossinenagjizzim/","foodTypes":"한식(어패류)","latitude":37.556793,"longitude":126.933362,"bookYear":null,"nameKR":"아저씨네낙지찜","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":12804,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.558505,"longitude":126.934078,"bookYear":null,"nameKR":"고향","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":12854,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.558558,"longitude":126.939399,"bookYear":null,"nameKR":"신미불닭발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":19299,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.566132,"longitude":126.929215,"bookYear":null,"nameKR":"선굼터","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":24984,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.568393,"longitude":126.931911,"bookYear":null,"nameKR":"오늘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":25300,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dokdabang/","foodTypes":"디저트/차/베이커리","latitude":37.558723,"longitude":126.937229,"bookYear":null,"nameKR":"독수리다방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":28299,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.566238,"longitude":126.930179,"bookYear":null,"nameKR":"홍대삭(연희점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":1331,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.586951,"longitude":127.042548,"bookYear":null,"nameKR":"홍능갈비집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":4224,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/spoon_race/","foodTypes":"이탈리아식","latitude":37.55793,"longitude":126.946081,"bookYear":null,"nameKR":"에그앤드스푼레이스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":8268,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.558276,"longitude":126.945464,"bookYear":null,"nameKR":"마농쇼콜라띠에","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":12856,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.557605,"longitude":126.938524,"bookYear":null,"nameKR":"신촌영양센터","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":15265,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.592773,"longitude":127.052292,"bookYear":null,"nameKR":"용성","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":27510,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/amenohicoffee/","foodTypes":"디저트/차/베이커리","latitude":37.555684,"longitude":126.931007,"bookYear":null,"nameKR":"아메노히커피점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":28485,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bookcafe_pao/","foodTypes":"디저트/차/베이커리","latitude":37.558382,"longitude":126.945952,"bookYear":null,"nameKR":"북카페파오","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":3722,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.563527,"longitude":126.977448,"bookYear":null,"nameKR":"서울삼계탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":6904,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lepul/","foodTypes":"기타","latitude":37.566195,"longitude":126.972175,"bookYear":null,"nameKR":"르풀","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":8853,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.572722,"longitude":127.023564,"bookYear":null,"nameKR":"양가네분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":9380,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.564628,"longitude":126.978062,"bookYear":null,"nameKR":"세븐스퀘어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":16508,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.564188,"longitude":126.97757,"bookYear":null,"nameKR":"오리마당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":19396,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.575605,"longitude":127.026376,"bookYear":null,"nameKR":"팔삼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":21497,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.563483,"longitude":126.977216,"bookYear":null,"nameKR":"잔치집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":21498,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.563394,"longitude":126.977374,"bookYear":null,"nameKR":"한국관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":34220,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/matte_factory/","latitude":37.5762703621118,"longitude":127.030252528343,"bookYear":null,"nameKR":"매트팩토리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":3947,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.561666,"longitude":126.975166,"bookYear":null,"nameKR":"서문회관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":5613,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.564103,"longitude":126.976196,"bookYear":null,"nameKR":"소공죽집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":7852,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/obokjung_/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.562303,"longitude":126.974186,"bookYear":null,"nameKR":"오복정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":8552,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.563341,"longitude":126.978424,"bookYear":null,"nameKR":"남양복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":8570,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.562743,"longitude":126.977722,"bookYear":null,"nameKR":"진동횟집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":8573,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.561916,"longitude":126.978163,"bookYear":null,"nameKR":"춘천닭갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":8797,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.563845,"longitude":126.980572,"bookYear":null,"nameKR":"아리랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":14414,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.562943,"longitude":126.972305,"bookYear":null,"nameKR":"서라벌한정식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":15766,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.564219,"longitude":126.968927,"bookYear":null,"nameKR":"유메","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":21697,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.563167,"longitude":126.975869,"bookYear":null,"nameKR":"하누락","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":25553,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.562311,"longitude":126.978066,"bookYear":null,"nameKR":"박씨화로구이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":6410,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.568698,"longitude":126.979562,"bookYear":null,"nameKR":"삼원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":7804,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.567388,"longitude":126.981482,"bookYear":null,"nameKR":"산불등심","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":9113,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.567195,"longitude":126.980656,"bookYear":null,"nameKR":"부추밭","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":15940,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.567371,"longitude":126.981638,"bookYear":null,"nameKR":"대원집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":19211,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.567314,"longitude":126.981517,"bookYear":null,"nameKR":"참복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":657,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.590559,"longitude":127.037177,"bookYear":null,"nameKR":"오소리순대","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":7888,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.591638,"longitude":127.016501,"bookYear":null,"nameKR":"오백집왕족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":13159,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.596092,"longitude":127.015734,"bookYear":null,"nameKR":"쌀떡볶이나라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":19237,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.581165,"longitude":127.026822,"bookYear":null,"nameKR":"두부촌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":25136,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.582523,"longitude":127.03123,"bookYear":null,"nameKR":"커피프로젝트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":26716,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.585934,"longitude":127.034259,"bookYear":null,"nameKR":"형제집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":32525,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eert_official/","foodTypes":"디저트/차/베이커리","latitude":37.5474545094044,"longitude":127.040997762786,"bookYear":null,"nameKR":"이이알티성수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32885,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/inches_seoul","foodTypes":"디저트/차/베이커리","latitude":37.5473610914344,"longitude":127.040952786657,"bookYear":null,"nameKR":"인치스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":7846,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.588909,"longitude":127.004637,"bookYear":null,"nameKR":"안동할매청국장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":14417,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.577092,"longitude":126.891376,"bookYear":null,"nameKR":"서룡","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":14429,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.580219,"longitude":126.882955,"bookYear":null,"nameKR":"셰프차이나","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":28008,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.56066,"longitude":126.917249,"bookYear":null,"nameKR":"포냐향","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":12561,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.582594,"longitude":126.970804,"bookYear":null,"nameKR":"진일정원조떡갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":12562,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.57872,"longitude":126.971254,"bookYear":null,"nameKR":"효자왕족발보쌈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":12986,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.57896,"longitude":126.971153,"bookYear":null,"nameKR":"샤또쇼콜라(경복궁점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":16009,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.592296,"longitude":127.002904,"bookYear":null,"nameKR":"올레국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":16303,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.576492,"longitude":126.969307,"bookYear":null,"nameKR":"봉평막국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":4935,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.579578,"longitude":126.972422,"bookYear":null,"nameKR":"MK2","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":5097,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_demitasse/","foodTypes":"디저트/차/베이커리","latitude":37.592352,"longitude":126.965711,"bookYear":null,"nameKR":"데미타스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":8483,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.592781,"longitude":126.963751,"bookYear":null,"nameKR":"가미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":13408,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.576603,"longitude":126.970999,"bookYear":null,"nameKR":"전대감댁","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":18321,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.592906,"longitude":126.964456,"bookYear":null,"nameKR":"미정당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":18829,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.57759,"longitude":126.9719,"bookYear":null,"nameKR":"미락치킨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":19375,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.594754,"longitude":126.96389,"bookYear":null,"nameKR":"파란대문","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":24348,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.578408,"longitude":126.970757,"bookYear":null,"nameKR":"도성","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":12995,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.560266,"longitude":126.964389,"bookYear":null,"nameKR":"남원할매추어탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":13006,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.55898,"longitude":126.9685,"bookYear":null,"nameKR":"호수집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":32295,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dining_hu","foodTypes":"중식","latitude":37.5628657665897,"longitude":126.964148671757,"bookYear":null,"nameKR":"다이닝후","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":4242,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.577378,"longitude":126.986081,"bookYear":null,"nameKR":"충청도집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":6633,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.581011,"longitude":126.981385,"bookYear":null,"nameKR":"만수의정원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8752,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/caferosso40_1/","foodTypes":"디저트/차/베이커리","latitude":37.582861,"longitude":126.981231,"bookYear":null,"nameKR":"카페로쏘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":12926,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57734,"longitude":126.9843,"bookYear":null,"nameKR":"다정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":13015,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5679,"longitude":126.9641,"bookYear":null,"nameKR":"서해안칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":21106,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.579916,"longitude":126.981261,"bookYear":null,"nameKR":"라디오엠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":27587,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_cflamingo/","foodTypes":"디저트/차/베이커리","latitude":37.579898,"longitude":126.981451,"bookYear":null,"nameKR":"씨플라밍고","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":6423,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.582192,"longitude":126.982072,"bookYear":null,"nameKR":"가화당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":6471,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/samchungdong_slowpark/","foodTypes":"이탈리아식","latitude":37.587871,"longitude":126.981476,"bookYear":null,"nameKR":"슬로우파크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":7847,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.584165,"longitude":126.982158,"bookYear":null,"nameKR":"향나무세그루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8641,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.58027,"longitude":126.981875,"bookYear":null,"nameKR":"커피팩토리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8651,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.580642,"longitude":126.986824,"bookYear":null,"nameKR":"소소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":15630,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.579407,"longitude":126.987351,"bookYear":null,"nameKR":"초정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":19637,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.583167,"longitude":126.982147,"bookYear":null,"nameKR":"파툼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":6824,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.616067,"longitude":126.914062,"bookYear":null,"nameKR":"짚터볏짚삼겹살","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":7807,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.578902,"longitude":126.924126,"bookYear":null,"nameKR":"엄마손떡볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":13353,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.616875,"longitude":126.918759,"bookYear":null,"nameKR":"미소복양평해장국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":31964,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yori_jori2020/","foodTypes":"바/주점","latitude":37.5706150914821,"longitude":126.914208301846,"bookYear":null,"nameKR":"요리조리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":32751,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe__merrypuriday/","foodTypes":"디저트/차/베이커리","latitude":37.6200956882776,"longitude":126.919255818638,"bookYear":null,"nameKR":"메리푸리데이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":33099,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5889743536353,"longitude":126.914063366415,"bookYear":null,"nameKR":"차이몬스터","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34134,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jung_seoul_/","foodTypes":"컨템포러리","latitude":37.6211715479602,"longitude":126.916778283254,"bookYear":null,"nameKR":"정서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":31472,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5511773786714,"longitude":126.907328086972,"bookYear":null,"nameKR":"베이커리나무","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":33779,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/604seoul/","foodTypes":"기타","latitude":37.5530879633685,"longitude":126.907581937588,"bookYear":null,"nameKR":"604서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":34309,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/cafe_mugyehoek","foodTypes":"디저트/차/베이커리","latitude":37.5558811486325,"longitude":126.905086963124,"bookYear":null,"nameKR":"무계획","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":301,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.53942,"longitude":126.9435,"bookYear":null,"nameKR":"마포원조주물럭집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":25335,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.555258,"longitude":126.899875,"bookYear":null,"nameKR":"만복기사식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":7908,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5401,"longitude":126.943552,"bookYear":null,"nameKR":"참식당생대구전문점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":7954,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.539664,"longitude":126.944191,"bookYear":null,"nameKR":"대감집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":9054,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.540132,"longitude":126.948253,"bookYear":null,"nameKR":"한양설농탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":13340,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.540204,"longitude":126.943627,"bookYear":null,"nameKR":"박달재","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":25381,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.541384,"longitude":126.941067,"bookYear":null,"nameKR":"모이세 해장국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29205,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.541624,"longitude":126.952919,"bookYear":null,"nameKR":"하하(공덕점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":31881,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5356515913359,"longitude":126.944258643662,"bookYear":null,"nameKR":"빠네돌체","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":34353,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jungsung.mapo/","foodTypes":"한식(육류)","latitude":37.5447477438441,"longitude":126.948258201287,"bookYear":null,"nameKR":"정성","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":990,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.548511,"longitude":126.953149,"bookYear":null,"nameKR":"황톳길","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":7816,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.583728,"longitude":126.997892,"bookYear":null,"nameKR":"나누미떡볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":13145,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.537753,"longitude":126.947046,"bookYear":null,"nameKR":"호남식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":21287,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.582343,"longitude":127.000869,"bookYear":null,"nameKR":"카페몽스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":22003,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.544891,"longitude":126.950607,"bookYear":null,"nameKR":"롯데나루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":23436,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.545185,"longitude":126.953434,"bookYear":null,"nameKR":"여수식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":8309,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/banquet_9304/","foodTypes":"디저트/차/베이커리","latitude":37.580796,"longitude":127.004434,"bookYear":null,"nameKR":"방켓","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":25583,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.580224,"longitude":127.003934,"bookYear":null,"nameKR":"라콜롬브","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":26251,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.581268,"longitude":127.004295,"bookYear":null,"nameKR":"코야코","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":8490,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.571738,"longitude":126.981354,"bookYear":null,"nameKR":"두림","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":16656,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.571992,"longitude":126.981457,"bookYear":null,"nameKR":"늘단비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":19260,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.570847,"longitude":126.971313,"bookYear":null,"nameKR":"아데쏘이탈리안레스토랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":447,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.571062,"longitude":126.975592,"bookYear":null,"nameKR":"뽐모도로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":8507,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.57142,"longitude":126.975,"bookYear":null,"nameKR":"풍년옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":8678,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.572718,"longitude":126.98072,"bookYear":null,"nameKR":"제주오겹살","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":13012,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.57133,"longitude":126.974401,"bookYear":null,"nameKR":"장수한방삼계탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":14439,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.569773,"longitude":126.978849,"bookYear":null,"nameKR":"송전일식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":14441,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.569773,"longitude":126.978849,"bookYear":null,"nameKR":"송학","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":15277,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.575324,"longitude":126.970664,"bookYear":null,"nameKR":"향연","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":16261,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.572997,"longitude":126.981334,"bookYear":null,"nameKR":"동감","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":19969,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.571116,"longitude":126.974489,"bookYear":null,"nameKR":"부산복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":23856,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.575728,"longitude":126.970806,"bookYear":null,"nameKR":"포도나무","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":25125,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.572229,"longitude":126.973977,"bookYear":null,"nameKR":"거성호프","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":9186,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.662491,"longitude":127.009908,"bookYear":null,"nameKR":"우리콩순두부","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":14440,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.646086,"longitude":127.019356,"bookYear":null,"nameKR":"송하","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":15857,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.671491,"longitude":127.005119,"bookYear":null,"nameKR":"백란","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":26540,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.675044,"longitude":127.0042,"bookYear":null,"nameKR":"대하정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":26947,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.644195,"longitude":127.004786,"bookYear":null,"nameKR":"곤드레이야기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":32172,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tebin_seoul/","foodTypes":"바/주점","latitude":37.6405270478745,"longitude":127.023502861681,"bookYear":null,"nameKR":"태빈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":33691,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.664946041388,"longitude":127.007686111659,"bookYear":null,"nameKR":"우디플레이트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":31401,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.525589387901,"longitude":127.045436032501,"bookYear":null,"nameKR":"고기준청담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":150,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.524233,"longitude":127.039991,"bookYear":null,"nameKR":"뉴욕바닷가재","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":21490,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.612647,"longitude":127.028929,"bookYear":null,"nameKR":"오징어마당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":28462,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/katsurou.kr/","foodTypes":"일식","latitude":37.522394,"longitude":127.047464,"bookYear":null,"nameKR":"카츠로우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":7973,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.523341,"longitude":127.044181,"bookYear":null,"nameKR":"호무랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":12990,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.52198,"longitude":127.0405,"bookYear":null,"nameKR":"광부","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":3395,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.525357,"longitude":127.051772,"bookYear":null,"nameKR":"코리아하우스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":26554,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.516466,"longitude":127.040419,"bookYear":null,"nameKR":"창신한우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":25768,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.535891,"longitude":126.895798,"bookYear":null,"nameKR":"양평옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":15360,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.528995,"longitude":126.897082,"bookYear":null,"nameKR":"삼성원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":23446,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.52668,"longitude":126.8975,"bookYear":null,"nameKR":"취향원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":24026,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.526122,"longitude":126.897808,"bookYear":null,"nameKR":"한성삼계탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":25442,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.526989,"longitude":126.897424,"bookYear":null,"nameKR":"강릉생태막회집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":32079,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/drmusicfood/","foodTypes":"이탈리아식","latitude":37.5286579800037,"longitude":126.89940927754,"bookYear":null,"nameKR":"오시게리따","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":1100,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.51915,"longitude":126.928551,"bookYear":null,"nameKR":"마도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":3867,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.520112,"longitude":126.931947,"bookYear":null,"nameKR":"란나타이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":3902,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.51913,"longitude":126.9305,"bookYear":null,"nameKR":"한양원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":6503,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.520167,"longitude":126.927251,"bookYear":null,"nameKR":"부흥동태탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":7228,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.520721,"longitude":126.929123,"bookYear":null,"nameKR":"밍1956(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":7341,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.51915,"longitude":126.93052,"bookYear":null,"nameKR":"은호식당(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":8174,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.520833,"longitude":126.931286,"bookYear":null,"nameKR":"열빈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":13265,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.521356,"longitude":126.92498,"bookYear":null,"nameKR":"삼보청국장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":14444,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.520833,"longitude":126.931286,"bookYear":null,"nameKR":"수라한정식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":15028,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.521345,"longitude":126.925982,"bookYear":null,"nameKR":"예성가든","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":15584,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.521683,"longitude":126.926591,"bookYear":null,"nameKR":"율도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":16207,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.521356,"longitude":126.92498,"bookYear":null,"nameKR":"차이홍","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":16388,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.520761,"longitude":126.926893,"bookYear":null,"nameKR":"제주항구","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":16439,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.521683,"longitude":126.926591,"bookYear":null,"nameKR":"본토박이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":17314,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.52004,"longitude":126.9308,"bookYear":null,"nameKR":"여의도생고기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":28242,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.520721,"longitude":126.929123,"bookYear":null,"nameKR":"신복촌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":1580,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.524267,"longitude":126.92482,"bookYear":null,"nameKR":"쥬빌리쇼콜라띠에","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":6682,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ginza_bairin","foodTypes":"일식","latitude":37.5251913154781,"longitude":126.929112756574,"bookYear":null,"nameKR":"긴자바이린 더현대서울점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":7842,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.443228,"longitude":127.055443,"bookYear":null,"nameKR":"소담채","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":7909,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/p/B2vnkhwj051/","foodTypes":"한식(어패류)","latitude":37.523511,"longitude":126.926529,"bookYear":null,"nameKR":"신송한식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":12413,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.52904,"longitude":126.9219,"bookYear":null,"nameKR":"정우칼국수보쌈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":13936,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.528565,"longitude":126.920609,"bookYear":null,"nameKR":"삼원정","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":14823,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.528229,"longitude":126.916424,"bookYear":null,"nameKR":"한류관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":15206,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.530294,"longitude":126.919904,"bookYear":null,"nameKR":"이즈미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":15757,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.52994,"longitude":126.922214,"bookYear":null,"nameKR":"오성해물탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":16463,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.52994,"longitude":126.922214,"bookYear":null,"nameKR":"다모아산채","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":17988,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.526016,"longitude":126.930757,"bookYear":null,"nameKR":"나주곰탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":23123,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.524353,"longitude":126.927137,"bookYear":null,"nameKR":"린네스가든","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":1279,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.484446,"longitude":127.036679,"bookYear":null,"nameKR":"첸수이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":1546,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.47752,"longitude":127.040989,"bookYear":null,"nameKR":"크로스비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":13313,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/alleystorycoffee/","foodTypes":"디저트/차/베이커리","latitude":37.470755,"longitude":127.039932,"bookYear":null,"nameKR":"앨리스토리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":13472,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.468726,"longitude":127.040363,"bookYear":null,"nameKR":"우촌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":16478,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.471295,"longitude":127.041031,"bookYear":null,"nameKR":"사랑채","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":23438,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.470529,"longitude":127.039578,"bookYear":null,"nameKR":"전주아구찜해물탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":26637,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.468117,"longitude":127.040956,"bookYear":null,"nameKR":"양재해장국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":31686,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sooinhong","foodTypes":"한식(육류)","latitude":37.4887512014819,"longitude":127.033148946277,"bookYear":null,"nameKR":"참족","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":32964,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eastable_seoul/","foodTypes":"아시아식","latitude":37.4835948322259,"longitude":127.046025210464,"bookYear":null,"nameKR":"이스터블","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":1161,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.526838,"longitude":127.037658,"bookYear":null,"nameKR":"압구정서서갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":3182,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.48557,"longitude":127.0441,"bookYear":null,"nameKR":"마포집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":7245,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.485368,"longitude":127.042633,"bookYear":null,"nameKR":"사리원(도곡점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":9199,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.485002,"longitude":127.042073,"bookYear":null,"nameKR":"초막집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":13321,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.48384,"longitude":127.045085,"bookYear":null,"nameKR":"하영호신촌설렁탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":19619,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.531957,"longitude":127.026942,"bookYear":null,"nameKR":"북촌김밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":3809,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.523715,"longitude":127.038047,"bookYear":null,"nameKR":"굴비마을","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":14329,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.526055,"longitude":127.035336,"bookYear":null,"nameKR":"묵전","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32118,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafedrowsy","foodTypes":"디저트/차/베이커리","latitude":37.5266144205262,"longitude":127.036737879251,"bookYear":null,"nameKR":"카페드로우지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33796,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eeguk_dosan/","foodTypes":"이탈리아식","latitude":37.5255940351736,"longitude":127.034958437297,"bookYear":null,"nameKR":"이국도산","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":207,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.52817,"longitude":127.0323,"bookYear":null,"nameKR":"동천홍","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":6817,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.524021,"longitude":127.027894,"bookYear":null,"nameKR":"피오렌티나","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":13147,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.522904,"longitude":127.032179,"bookYear":null,"nameKR":"옛날집(도산사거리점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":17471,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.527457,"longitude":127.030607,"bookYear":null,"nameKR":"소풍","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":15035,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.48495,"longitude":126.9338,"bookYear":null,"nameKR":"영빈관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":15153,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.484077,"longitude":126.9307,"bookYear":null,"nameKR":"북경반점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":15531,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.486035,"longitude":126.938876,"bookYear":null,"nameKR":"만다린","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":17370,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.506602,"longitude":127.049977,"bookYear":null,"nameKR":"선릉가든","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":26553,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.487166,"longitude":126.926097,"bookYear":null,"nameKR":"고려왕족발","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":26623,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.485945,"longitude":126.929934,"bookYear":null,"nameKR":"십원집(신림점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":31629,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/odo_sdb/","foodTypes":"일식","latitude":37.4824598621083,"longitude":126.913410233141,"bookYear":null,"nameKR":"카츠오도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":31714,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gourmet_kitchen111","foodTypes":"일식","latitude":37.4847073593542,"longitude":126.92720130472,"bookYear":null,"nameKR":"구루메키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":31470,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/toomutt","foodTypes":"이탈리아식","latitude":37.478764140474,"longitude":126.953457348277,"bookYear":null,"nameKR":"투머트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":32521,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sngsngmilktea/","foodTypes":"디저트/차/베이커리","latitude":37.475720029298,"longitude":126.962999554184,"bookYear":null,"nameKR":"승승밀크티","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":33065,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/unpeu_patisserie/","foodTypes":"디저트/차/베이커리","latitude":37.4782622357877,"longitude":126.955931343351,"bookYear":null,"nameKR":"앙뿌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":33872,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/namdobanju/","foodTypes":"바/주점","latitude":37.4782622357877,"longitude":126.955931343351,"bookYear":null,"nameKR":"남도반주","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":14490,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.476654,"longitude":126.952749,"bookYear":null,"nameKR":"안집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":15031,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.479828,"longitude":126.958044,"bookYear":null,"nameKR":"다빈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":15135,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.487893,"longitude":126.958255,"bookYear":null,"nameKR":"우성각","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":15915,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.482927,"longitude":126.942287,"bookYear":null,"nameKR":"수타원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":23230,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.481528,"longitude":126.942507,"bookYear":null,"nameKR":"봉천황소곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":25228,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.475532,"longitude":126.965128,"bookYear":null,"nameKR":"고향주물럭","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":4309,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.498094,"longitude":126.998123,"bookYear":null,"nameKR":"무샤","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":5743,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.498133,"longitude":126.998884,"bookYear":null,"nameKR":"맘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":5746,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.493911,"longitude":126.992919,"bookYear":null,"nameKR":"서래수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":16539,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.512748,"longitude":127.065102,"bookYear":null,"nameKR":"소담골","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":18599,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":37.493792,"longitude":126.992677,"bookYear":null,"nameKR":"퀸즈파크(서래점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":25106,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.498595,"longitude":126.997389,"bookYear":null,"nameKR":"산쪼메 라멘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":28333,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.499861,"longitude":126.998974,"bookYear":null,"nameKR":"차돌가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":5166,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.514213,"longitude":127.064412,"bookYear":null,"nameKR":"부옥당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":13351,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.509116,"longitude":127.05627,"bookYear":null,"nameKR":"한방정통삼계탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":33703,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5110897397563,"longitude":127.055708381822,"bookYear":null,"nameKR":"탐라도야지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":34064,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coun_boy/","foodTypes":"디저트/차/베이커리","latitude":37.4888353001286,"longitude":126.989448386648,"bookYear":null,"nameKR":"컨트리보이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":3763,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.492815,"longitude":126.986287,"bookYear":null,"nameKR":"장수원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":7137,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.478042,"longitude":126.983413,"bookYear":null,"nameKR":"남도명가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":12999,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.49164,"longitude":126.9867,"bookYear":null,"nameKR":"서호김밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":19698,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.480407,"longitude":126.982705,"bookYear":null,"nameKR":"흑산도홍탁","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":24201,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.477513,"longitude":126.98366,"bookYear":null,"nameKR":"남도술상","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":28201,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.486039,"longitude":126.983064,"bookYear":null,"nameKR":"싱싱오동도산아나고꼼장어숯불구이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":7745,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.478528,"longitude":126.980534,"bookYear":null,"nameKR":"권참치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":7919,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.478059,"longitude":126.979959,"bookYear":null,"nameKR":"영일만","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":13190,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.479918,"longitude":126.999577,"bookYear":null,"nameKR":"최고집칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":13974,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.547777,"longitude":126.864205,"bookYear":null,"nameKR":"소문장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":15459,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.512555,"longitude":126.838105,"bookYear":null,"nameKR":"가원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":17465,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.487857,"longitude":126.980701,"bookYear":null,"nameKR":"바다횟집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":17793,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.520716,"longitude":126.860068,"bookYear":null,"nameKR":"명가들깨칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":20061,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.477573,"longitude":126.980779,"bookYear":null,"nameKR":"삼향복집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":20440,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.529314,"longitude":126.875126,"bookYear":null,"nameKR":"오목집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":23820,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.528219,"longitude":126.854446,"bookYear":null,"nameKR":"황금오리농장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":26194,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.477475,"longitude":126.980965,"bookYear":null,"nameKR":"카페무","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":5669,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.551892,"longitude":126.875696,"bookYear":null,"nameKR":"대운정","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":13627,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.4914,"longitude":126.9234,"bookYear":null,"nameKR":"궁중삼계탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":15161,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.494245,"longitude":126.899539,"bookYear":null,"nameKR":"전가복","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":15712,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.504672,"longitude":126.921699,"bookYear":null,"nameKR":"산동성","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":16474,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.496881,"longitude":126.899292,"bookYear":null,"nameKR":"한우전문점 우의정","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":19692,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.499288,"longitude":126.910518,"bookYear":null,"nameKR":"순흥골","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":20378,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.513005,"longitude":126.921976,"bookYear":null,"nameKR":"금봉식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":22000,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.525319,"longitude":126.867915,"bookYear":null,"nameKR":"대복","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":12719,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.509583,"longitude":126.924635,"bookYear":null,"nameKR":"다채(대방점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":15199,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.505734,"longitude":127.057928,"bookYear":null,"nameKR":"용수사","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":24598,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hareizakaya/","foodTypes":"바/주점","latitude":37.506608,"longitude":127.060566,"bookYear":null,"nameKR":"하레이자카야","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":4991,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.503138,"longitude":127.054333,"bookYear":null,"nameKR":"사천옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":7904,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.500848,"longitude":127.052947,"bookYear":null,"nameKR":"광주식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":9201,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.502618,"longitude":127.056749,"bookYear":null,"nameKR":"남산희래등","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":18010,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.505591,"longitude":127.055221,"bookYear":null,"nameKR":"취홍","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":19390,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.502459,"longitude":127.05026,"bookYear":null,"nameKR":"서갈비청국장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":19584,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.493456,"longitude":127.059759,"bookYear":null,"nameKR":"흑다우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":33633,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4992986818287,"longitude":127.053682460616,"bookYear":null,"nameKR":"브레덴버거","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":31184,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.521967912845,"longitude":127.038277690372,"bookYear":null,"nameKR":"청사포산꼼장어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":31284,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5139958983743,"longitude":127.021211430073,"bookYear":null,"nameKR":"인량","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":6144,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.50913,"longitude":127.0233,"bookYear":null,"nameKR":"안동뭉티기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":13222,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.507791,"longitude":127.024333,"bookYear":null,"nameKR":"울트라멘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":13255,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.520295,"longitude":127.035946,"bookYear":null,"nameKR":"왕소금구이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":13281,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.50867,"longitude":127.023575,"bookYear":null,"nameKR":"리북집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":13373,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.509011,"longitude":127.024203,"bookYear":null,"nameKR":"현대순대국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":18176,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.51001,"longitude":127.022578,"bookYear":null,"nameKR":"토영자갈치곰장어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":21849,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.520295,"longitude":127.035946,"bookYear":null,"nameKR":"파이포유","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":26169,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.508283,"longitude":127.023403,"bookYear":null,"nameKR":"우제홍참치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":1235,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.51302,"longitude":127.0358,"bookYear":null,"nameKR":"유다까","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":5253,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.517318,"longitude":127.034089,"bookYear":null,"nameKR":"불이아(강남점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":15264,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.572345,"longitude":126.806848,"bookYear":null,"nameKR":"대원각","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":16569,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.516686,"longitude":127.03359,"bookYear":null,"nameKR":"물고기자리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":12715,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.495784,"longitude":127.013859,"bookYear":null,"nameKR":"기와집순두부(서초점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":12969,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.49305,"longitude":127.0087,"bookYear":null,"nameKR":"대나무골","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":15037,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.49424,"longitude":127.0129,"bookYear":null,"nameKR":"장원한정식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":21961,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.493748,"longitude":127.012373,"bookYear":null,"nameKR":"이남장(서초점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":32255,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fatchoy_official","foodTypes":"중식","latitude":37.5587286211731,"longitude":126.810555990074,"bookYear":null,"nameKR":"팟초이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":1122,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.489952,"longitude":127.008203,"bookYear":null,"nameKR":"미유끼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":3410,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baobabtreecoffee/","foodTypes":"디저트/차/베이커리","latitude":37.493856,"longitude":127.018624,"bookYear":null,"nameKR":"바오밥나무","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":7253,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.490261,"longitude":127.009904,"bookYear":null,"nameKR":"신강(교대점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":7915,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.485419,"longitude":127.014189,"bookYear":null,"nameKR":"서초아구해물찜탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":13148,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.494995,"longitude":127.017255,"bookYear":null,"nameKR":"토담(교대점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":13203,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.485174,"longitude":127.013142,"bookYear":null,"nameKR":"천지궁","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":18165,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.486922,"longitude":127.013996,"bookYear":null,"nameKR":"홍천한우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":19183,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.488251,"longitude":127.01506,"bookYear":null,"nameKR":"초막집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":21996,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.491786,"longitude":127.010328,"bookYear":null,"nameKR":"서귀포오분작뚝배기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":24593,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.493589,"longitude":127.016003,"bookYear":null,"nameKR":"스스루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":12963,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.48264,"longitude":127.0128,"bookYear":null,"nameKR":"선궁","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":15101,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.487361,"longitude":127.028481,"bookYear":null,"nameKR":"명선헌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":15296,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.48414,"longitude":127.019364,"bookYear":null,"nameKR":"만리향","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":18423,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.48435,"longitude":127.0144,"bookYear":null,"nameKR":"찜통","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":33859,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/antica.trattoria.seul/","foodTypes":"이탈리아식","latitude":37.4818154123508,"longitude":127.005998176299,"bookYear":null,"nameKR":"안티카트라토리아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":31807,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/Pierrotcoffee","foodTypes":"디저트/차/베이커리","latitude":37.5266097352574,"longitude":127.126627379363,"bookYear":null,"nameKR":"피에로커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":33026,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/labo_du_pain/","foodTypes":"디저트/차/베이커리","latitude":37.5021095310863,"longitude":127.010905425887,"bookYear":null,"nameKR":"라보뒤빵","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":4820,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.55766,"longitude":127.12675,"bookYear":null,"nameKR":"솔터","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":13208,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.530015,"longitude":127.122109,"bookYear":null,"nameKR":"평양만두집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":14213,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.523168,"longitude":127.131161,"bookYear":null,"nameKR":"긴자","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":15785,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.55166,"longitude":127.135254,"bookYear":null,"nameKR":"하나참치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":21816,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.551842,"longitude":127.132285,"bookYear":null,"nameKR":"하에레츠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":26093,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.536984,"longitude":127.130459,"bookYear":null,"nameKR":"피카피오","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":5699,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.501196,"longitude":127.024961,"bookYear":null,"nameKR":"악바리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":7806,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.501436,"longitude":127.024397,"bookYear":null,"nameKR":"시골야채된장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":13354,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.501598,"longitude":127.024836,"bookYear":null,"nameKR":"전주해장국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":18655,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.537303,"longitude":127.140722,"bookYear":null,"nameKR":"안가네등갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":18989,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.54997,"longitude":127.1443,"bookYear":null,"nameKR":"명일곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":20001,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.577335,"longitude":127.167842,"bookYear":null,"nameKR":"느티나무집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":21307,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.53561,"longitude":127.149203,"bookYear":null,"nameKR":"석촌오리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":26757,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.528649,"longitude":127.137549,"bookYear":null,"nameKR":"두남떡","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":34375,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lee.meat_/","foodTypes":"한식(육류)","latitude":37.5276512316436,"longitude":127.133075020589,"bookYear":null,"nameKR":"리밑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":1212,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.496836,"longitude":127.031817,"bookYear":null,"nameKR":"에도긴","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":9313,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.505795,"longitude":127.031298,"bookYear":null,"nameKR":"파라다이스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":9439,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.50582,"longitude":127.031161,"bookYear":null,"nameKR":"근천","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":12961,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.500984,"longitude":127.028861,"bookYear":null,"nameKR":"도치피자(강남점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":13220,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.501662,"longitude":127.026363,"bookYear":null,"nameKR":"오모야","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":13717,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.443117,"longitude":126.904134,"bookYear":null,"nameKR":"청진동해장국(유통상가점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":20372,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.503228,"longitude":127.026397,"bookYear":null,"nameKR":"대가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":24308,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/beansple/","foodTypes":"디저트/차/베이커리","latitude":37.502574,"longitude":127.027295,"bookYear":null,"nameKR":"빈스플","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":1257,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeonjusikdang/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.515341,"longitude":127.017888,"bookYear":null,"nameKR":"전주청국장집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":7836,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.489016,"longitude":126.824862,"bookYear":null,"nameKR":"이천성모메존칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":17035,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.514616,"longitude":127.017444,"bookYear":null,"nameKR":"진대감","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":19122,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.504286,"longitude":126.891142,"bookYear":null,"nameKR":"대왕곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":22630,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.515831,"longitude":127.018202,"bookYear":null,"nameKR":"양대명가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":24086,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.500986,"longitude":126.889608,"bookYear":null,"nameKR":"서울 안심 축산 정육식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":29258,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.504543,"longitude":126.892449,"bookYear":null,"nameKR":"신도림닭집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":31089,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4809454846807,"longitude":126.901495479172,"bookYear":null,"nameKR":"최고김밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":31165,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tongtonge_/","foodTypes":"디저트/차/베이커리","latitude":37.5219375834009,"longitude":127.025876370484,"bookYear":null,"nameKR":"이웃집통통이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":32318,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.4826840834852,"longitude":126.899902506754,"bookYear":null,"nameKR":"미식가양꼬치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":33873,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/caveterrace.sinsa","foodTypes":"이탈리아식","latitude":37.5187571437059,"longitude":127.021312095271,"bookYear":null,"nameKR":"까브테라스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":7291,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/taco7272boss/","foodTypes":"중남미식","latitude":37.519306,"longitude":127.022582,"bookYear":null,"nameKR":"타코칠리칠리(가로수길점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":12701,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.520977,"longitude":127.023005,"bookYear":null,"nameKR":"고디바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":14582,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/insadongchon/","foodTypes":"한식(일반한식)","latitude":37.517912,"longitude":127.021684,"bookYear":null,"nameKR":"인사동촌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":18326,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.52123,"longitude":127.0236,"bookYear":null,"nameKR":"카카오빈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":20243,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/able_cafe_official/","foodTypes":"기타","latitude":37.523501,"longitude":127.022982,"bookYear":null,"nameKR":"에이블","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":22897,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.523801,"longitude":127.022954,"bookYear":null,"nameKR":"코다차야(본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":28997,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.51882,"longitude":127.02408,"bookYear":null,"nameKR":"에머이(신사점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":22691,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.518368,"longitude":127.020518,"bookYear":null,"nameKR":"자주식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":32476,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/szimpatikus.seoul/","foodTypes":"바/주점","latitude":37.5176148937155,"longitude":127.020566787144,"bookYear":null,"nameKR":"심퍼티쿠시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":34398,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dangarugotgan/","foodTypes":"디저트/차/베이커리","latitude":35.1578757064495,"longitude":129.115462900245,"bookYear":null,"nameKR":"단가루곳간","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36564,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/spirit.train_official/","foodTypes":"바/주점","latitude":37.5716165496426,"longitude":127.014554296642,"bookYear":null,"nameKR":"증류주관차","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":1741,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.459912,"longitude":128.06373,"bookYear":null,"nameKR":"횡성축협한우프라자(우천점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":1725,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.614277095168745,"longitude":127.67533016618943,"bookYear":null,"nameKR":"한솔가든","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":1658,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.925654,"longitude":127.782966,"bookYear":null,"nameKR":"소양강양어장","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":2742,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4898113824207,"longitude":127.988186660343,"bookYear":null,"nameKR":"팔팔정육점식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":3029,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.495357,"longitude":127.98833,"bookYear":null,"nameKR":"금강산숯불구이","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":3157,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.201481891003,"longitude":128.582878645938,"bookYear":null,"nameKR":"낙천회관","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":4481,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.173905765668536,"longitude":128.98964107405138,"bookYear":null,"nameKR":"충남실비식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":4490,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.526505,"longitude":127.908949,"bookYear":null,"nameKR":"동가래한우셀프점","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":4501,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.883326,"longitude":127.756652,"bookYear":null,"nameKR":"하주골","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":5316,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.90295,"longitude":127.782987,"bookYear":null,"nameKR":"감자골집막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":5694,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.747756527167,"longitude":127.947819573665,"bookYear":null,"nameKR":"친절막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":6998,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.34997693487749,"longitude":127.95011538533755,"bookYear":null,"nameKR":"우리장터","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":7116,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.765597,"longitude":128.952542,"bookYear":null,"nameKR":"삼교리동치미막국수(남항진점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":7117,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.772582,"longitude":128.877905,"bookYear":null,"nameKR":"삼교리동치미막국수(교동점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":7118,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.911751,"longitude":127.752656,"bookYear":null,"nameKR":"삼교리동치미막국수(춘천점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":7119,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.7548336587405,"longitude":128.894407610439,"bookYear":null,"nameKR":"테라로사(임당점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":7375,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":38.2110430985462,"longitude":128.587351633194,"bookYear":null,"nameKR":"감자옹심이","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":7429,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.335638,"longitude":127.957583,"bookYear":null,"nameKR":"오막집(원주점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":7501,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.3466619847448,"longitude":127.941110429286,"bookYear":null,"nameKR":"황기족발콧등치기동광식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":7575,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.855276,"longitude":127.731891,"bookYear":null,"nameKR":"우성닭갈비(퇴계동점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":8919,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.8771159869517,"longitude":127.723814868169,"bookYear":null,"nameKR":"함지레스토랑","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":9294,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.212194,"longitude":128.820755,"bookYear":null,"nameKR":"하이랜드","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":9300,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":38.172754,"longitude":128.499252,"bookYear":null,"nameKR":"애비로드","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":9302,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":38.166943,"longitude":128.606341,"bookYear":null,"nameKR":"알바트로스","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":9329,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.2118740498975,"longitude":128.821453729557,"bookYear":null,"nameKR":"오리엔","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":9387,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.2118740498975,"longitude":128.821453729557,"bookYear":null,"nameKR":"월드퓨전","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":9411,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.645336,"longitude":128.682178,"bookYear":null,"nameKR":"모두랑용평셀프한우마을","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":9414,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.664221,"longitude":128.689884,"bookYear":null,"nameKR":"플레이버스레스토랑","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":10452,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":38.213991,"longitude":128.51039,"bookYear":null,"nameKR":"가조오리숯불구이","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":10455,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.437482,"longitude":129.153395,"bookYear":null,"nameKR":"강화가든","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":10459,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.350115,"longitude":128.942359,"bookYear":null,"nameKR":"광동민물매운탕","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":10460,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.17771,"longitude":128.457242,"bookYear":null,"nameKR":"광천숯불구이","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":10469,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.46461,"longitude":129.1619,"bookYear":null,"nameKR":"너울가지","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":10473,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.441608,"longitude":129.163294,"bookYear":null,"nameKR":"대승춘","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":10474,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.12979,"longitude":128.535965,"bookYear":null,"nameKR":"대영매운탕","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":10481,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.2287799891602,"longitude":129.043102922238,"bookYear":null,"nameKR":"뚱보냉면","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":10482,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.442614,"longitude":129.164243,"bookYear":null,"nameKR":"라스칼라","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":10488,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.381429,"longitude":129.233229,"bookYear":null,"nameKR":"만월정","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":10495,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":38.207098,"longitude":128.507693,"bookYear":null,"nameKR":"미시령본가막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":10561,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":38.44756,"longitude":128.4568,"bookYear":null,"nameKR":"함흥식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":10501,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4454631933803,"longitude":129.162952667658,"bookYear":null,"nameKR":"병천순대","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":10519,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.432975,"longitude":129.167096,"bookYear":null,"nameKR":"송죽","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":10526,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.242004,"longitude":128.513055,"bookYear":null,"nameKR":"어라연송어장횟집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":10530,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.231089369363,"longitude":129.044916785403,"bookYear":null,"nameKR":"오동나무식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":10546,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.4383857311652,"longitude":129.15718108016,"bookYear":null,"nameKR":"원조죽서뚜구리집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":10557,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.44573,"longitude":129.1628,"bookYear":null,"nameKR":"하동식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":11497,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.553341,"longitude":129.119454,"bookYear":null,"nameKR":"갈매기횟집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":11504,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.44833,"longitude":129.185303,"bookYear":null,"nameKR":"부림해물","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":11505,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.43712,"longitude":129.186035,"bookYear":null,"nameKR":"단골식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":11513,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.414944,"longitude":127.891365,"bookYear":null,"nameKR":"매호리느티나무집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":11900,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.791022,"longitude":127.525405,"bookYear":null,"nameKR":"남문","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":12369,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.321077,"longitude":127.964832,"bookYear":null,"nameKR":"대복추어탕","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":12371,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.38502,"longitude":127.977315,"bookYear":null,"nameKR":"토정추어탕","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":12374,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":38.219544,"longitude":128.370269,"bookYear":null,"nameKR":"부흥식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":12377,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.87911,"longitude":127.7272,"bookYear":null,"nameKR":"명동본가닭갈비","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":12381,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.178947,"longitude":128.987837,"bookYear":null,"nameKR":"태강식육실비","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":12388,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.8700222011856,"longitude":127.707382387396,"bookYear":null,"nameKR":"춘천명물닭갈비막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":12470,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.86562,"longitude":127.7223,"bookYear":null,"nameKR":"모래재순메밀꿩막국수닭갈비","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":12646,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.873411,"longitude":127.744946,"bookYear":null,"nameKR":"시실리아","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":12677,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":38.1589844243538,"longitude":127.311525425216,"bookYear":null,"nameKR":"한탄강매운탕","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"철원군","status":"ACTIVE"},
        {"id":12700,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dancheon1979/","foodTypes":"한식(육류)","latitude":38.189859,"longitude":128.573848,"bookYear":null,"nameKR":"단천면옥","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":13022,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.61274,"longitude":128.3717,"bookYear":null,"nameKR":"동이네막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":13060,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":38.205853,"longitude":128.528363,"bookYear":null,"nameKR":"김정옥할머니순두부(본점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":13063,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.80328,"longitude":127.6209,"bookYear":null,"nameKR":"검봉산칡국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":13065,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":38.06156,"longitude":127.982689,"bookYear":null,"nameKR":"양구재래식손두부","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양구군","status":"ACTIVE"},
        {"id":13246,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/namboo01/","foodTypes":"한식(면류)","latitude":37.869454,"longitude":127.724326,"bookYear":null,"nameKR":"남부막국수(별관)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":13289,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4366141727248,"longitude":129.184374733637,"bookYear":null,"nameKR":"삼거리식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":13294,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":38.20227,"longitude":128.5907,"bookYear":null,"nameKR":"부영각","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":13380,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.809216,"longitude":127.636054,"bookYear":null,"nameKR":"옛터숯불닭갈비","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":13382,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":38.199127,"longitude":128.536048,"bookYear":null,"nameKR":"초당본점","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":13703,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.90937,"longitude":127.781173,"bookYear":null,"nameKR":"양구재래식손두부","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":14040,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.333447,"longitude":127.951023,"bookYear":null,"nameKR":"흥업순대","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":14135,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":38.0757578700138,"longitude":128.624128452504,"bookYear":null,"nameKR":"동일식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":14681,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.3754584971886,"longitude":127.995492310322,"bookYear":null,"nameKR":"송어회랑 능이삼계탕","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":16844,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seorak_sketch/","foodTypes":"한식(일반한식)","latitude":38.182851,"longitude":128.529893,"bookYear":null,"nameKR":"설악스케치","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":16862,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.2620246216043,"longitude":128.191916708483,"bookYear":null,"nameKR":"본가황둔웰빙쌀찐빵","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":16906,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.55231,"longitude":129.11443,"bookYear":null,"nameKR":"동해안회대게도매센터","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":17629,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":38.1870219240855,"longitude":128.560048913655,"bookYear":null,"nameKR":"그린막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":17998,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.634995,"longitude":128.561417,"bookYear":null,"nameKR":"평창송어횟집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":18225,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":38.110006438188,"longitude":127.986316894603,"bookYear":null,"nameKR":"전주식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양구군","status":"ACTIVE"},
        {"id":18257,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":38.2256898860244,"longitude":128.586418847621,"bookYear":null,"nameKR":"왕박골식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":18875,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.317224,"longitude":127.935277,"bookYear":null,"nameKR":"황태해장국","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":19267,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.1193546761636,"longitude":128.949738688177,"bookYear":null,"nameKR":"명산식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":19398,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":38.075023986235,"longitude":128.02369215941,"bookYear":null,"nameKR":"시래원","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양구군","status":"ACTIVE"},
        {"id":21460,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.750385,"longitude":127.707284,"bookYear":null,"nameKR":"탑골가든","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":21467,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.8601996886099,"longitude":127.729442419853,"bookYear":null,"nameKR":"배나무골가마솥칼국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":22161,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.868137,"longitude":127.736693,"bookYear":null,"nameKR":"진미닭갈비(본점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":22347,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.76804,"longitude":128.876863,"bookYear":null,"nameKR":"스시심","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":22351,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.705208,"longitude":128.71397,"bookYear":null,"nameKR":"이촌쉼터","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":22509,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.755624,"longitude":128.893543,"bookYear":null,"nameKR":"손병욱베이커리","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":22496,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.183314,"longitude":128.46674,"bookYear":null,"nameKR":"미탄집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":22531,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5201673148542,"longitude":129.106855639344,"bookYear":null,"nameKR":"묵호물회","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":22534,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.788133,"longitude":128.913507,"bookYear":null,"nameKR":"토박이할머니순두부","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":22536,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":38.077018,"longitude":128.624081,"bookYear":null,"nameKR":"남문뚜거리","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":22540,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":38.104509,"longitude":127.988934,"bookYear":null,"nameKR":"초우식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양구군","status":"ACTIVE"},
        {"id":22628,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.518814,"longitude":129.121619,"bookYear":null,"nameKR":"수중횟집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":22634,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chuamsea/","foodTypes":"한식(어패류)","latitude":37.477807,"longitude":129.158981,"bookYear":null,"nameKR":"추암횟집대게","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":22635,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.55467,"longitude":129.120291,"bookYear":null,"nameKR":"칠형제곰치국","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":22873,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.7004751097054,"longitude":128.694262657339,"bookYear":null,"nameKR":"티팩토리","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":22976,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5019284846561,"longitude":128.453366896615,"bookYear":null,"nameKR":"토담막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":23136,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.875276,"longitude":127.777327,"bookYear":null,"nameKR":"메밀촌","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":23224,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.861964,"longitude":127.746312,"bookYear":null,"nameKR":"수누리감자탕(춘천점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":23374,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.173345,"longitude":128.993356,"bookYear":null,"nameKR":"부산감자옹심이","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":23632,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.8961781627439,"longitude":128.832337363877,"bookYear":null,"nameKR":"수원양희","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":24200,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.652274,"longitude":128.784505,"bookYear":null,"nameKR":"커피커퍼 커피박물관","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":24252,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.724216,"longitude":127.92755,"bookYear":null,"nameKR":"홍천강변애","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":24306,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":38.212457,"longitude":128.358938,"bookYear":null,"nameKR":"황태사랑","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":24314,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.86611,"longitude":127.75938,"bookYear":null,"nameKR":"예원","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":24486,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.8383397945593,"longitude":128.875204024119,"bookYear":null,"nameKR":"사천진리물회","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":24740,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.365685,"longitude":127.831671,"bookYear":null,"nameKR":"시골집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":24780,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.766168,"longitude":128.874827,"bookYear":null,"nameKR":"세실리아","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":23687,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4597622295094,"longitude":128.065273017155,"bookYear":null,"nameKR":"횡성한우이야기","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":24660,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.370353,"longitude":128.00106,"bookYear":null,"nameKR":"황골양지가든","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":24690,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"돌기와집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"철원군","status":"ACTIVE"},
        {"id":24694,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.968665,"longitude":128.416662,"bookYear":null,"nameKR":"두무대송어횟집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":24856,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.760867,"longitude":128.918129,"bookYear":null,"nameKR":"오직한우","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":24873,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":38.20445,"longitude":128.590153,"bookYear":null,"nameKR":"은혜횟집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":25099,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.3276467827112,"longitude":127.958760731065,"bookYear":null,"nameKR":"정가네해물찜","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":25260,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.707578,"longitude":127.694084,"bookYear":null,"nameKR":"명가춘천닭갈비막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":25509,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.765902,"longitude":128.923427,"bookYear":null,"nameKR":"동일장칼국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":25513,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":38.20445,"longitude":128.590153,"bookYear":null,"nameKR":"영동횟집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":25565,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":38.212068,"longitude":128.598932,"bookYear":null,"nameKR":"신가","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":25591,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":38.20445,"longitude":128.590153,"bookYear":null,"nameKR":"북청닭강정","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":26053,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yolkceo/","foodTypes":"기타","latitude":37.339589,"longitude":127.925297,"bookYear":null,"nameKR":"욜크","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":26158,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.593904,"longitude":129.088771,"bookYear":null,"nameKR":"해림횟집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":26181,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.1759952875545,"longitude":128.47765480454,"bookYear":null,"nameKR":"감자바우","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":26188,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coaltownbbq/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.227584,"longitude":128.813822,"bookYear":null,"nameKR":"석탄회관","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"정선군","status":"ACTIVE"},
        {"id":26233,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":38.210498,"longitude":128.601301,"bookYear":null,"nameKR":"동명활어센타66호","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":26404,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.176144,"longitude":128.993016,"bookYear":null,"nameKR":"태백산한우촌","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":26622,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":38.2128188476013,"longitude":128.598636296103,"bookYear":null,"nameKR":"팔도게찜","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":26696,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.411424,"longitude":128.155631,"bookYear":null,"nameKR":"안흥찐빵","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":26707,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.852467,"longitude":127.752299,"bookYear":null,"nameKR":"우리흑염소","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":26974,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.952427,"longitude":128.358475,"bookYear":null,"nameKR":"방동막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":27146,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.92971,"longitude":127.780545,"bookYear":null,"nameKR":"샘밭닭갈비","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":27155,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.9297377707444,"longitude":127.780609022649,"bookYear":null,"nameKR":"소양호어부의집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":29298,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.868591,"longitude":128.845491,"bookYear":null,"nameKR":"자바커피","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":29299,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/caffe_brazil/","foodTypes":"디저트/차/베이커리","latitude":37.86829,"longitude":128.846338,"bookYear":null,"nameKR":"카페브라질","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":29301,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_kamome/","foodTypes":"디저트/차/베이커리","latitude":37.7689076648819,"longitude":128.876018749361,"bookYear":null,"nameKR":"카페카모메","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":29423,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.75434,"longitude":128.895874,"bookYear":null,"nameKR":"바로방","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":29842,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":38.21491202777073,"longitude":128.59721013438084,"bookYear":null,"nameKR":"말자네","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":29932,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":38.201321367381,"longitude":128.58705463293097,"bookYear":null,"nameKR":"고메팔레스","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":30068,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.78692909364905,"longitude":128.9138813281272,"bookYear":null,"nameKR":"9남매두부집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":30090,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dearblackcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.3639649115042,"longitude":128.01170789381794,"bookYear":null,"nameKR":"디어블랙커피컴퍼니","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":30103,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe__pipe","foodTypes":"디저트/차/베이커리","latitude":37.34991925407203,"longitude":127.9465211960446,"bookYear":null,"nameKR":"카페파이프","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":30435,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe1001_8/","foodTypes":"디저트/차/베이커리","latitude":38.107683441454085,"longitude":128.03314956583273,"bookYear":null,"nameKR":"카페1001-8","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양구군","status":"ACTIVE"},
        {"id":30486,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.78786132820654,"longitude":128.90750335958188,"bookYear":null,"nameKR":"테라로사(경포호수점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":30811,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7737901587242,"longitude":128.891099556147,"bookYear":null,"nameKR":"대동면옥(강릉점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":30955,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/croft_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.8779013634044,"longitude":127.722371649823,"bookYear":null,"nameKR":"크로프트커피","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":31125,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.3443556164807,"longitude":128.009381359171,"bookYear":null,"nameKR":"토우커피","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":31218,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.2004313775811,"longitude":128.582422909246,"bookYear":null,"nameKR":"함흥막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":31421,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/salon_de_lim_/","foodTypes":"이탈리아식","latitude":37.1838900297539,"longitude":128.472796058116,"bookYear":null,"nameKR":"살롱드림","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":31459,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.9254745305905,"longitude":127.753434709757,"bookYear":null,"nameKR":"신세계춘천정통닭갈비","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":31568,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.1825865651719,"longitude":128.480034409465,"bookYear":null,"nameKR":"성호식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":31685,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.8536228034909,"longitude":127.754826827456,"bookYear":null,"nameKR":"종로1973","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":31698,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/official_cowabungapizza/","foodTypes":"미국식","latitude":38.0224801670315,"longitude":128.72450819143,"bookYear":null,"nameKR":"코와붕가","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":31750,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chilsungboatyard","foodTypes":"디저트/차/베이커리","latitude":38.1982448569158,"longitude":128.583369029166,"bookYear":null,"nameKR":"칠성조선소","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":32044,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":38.1893069175713,"longitude":128.594299237043,"bookYear":null,"nameKR":"솔밭가든막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":32050,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.2030101283988,"longitude":128.346900510055,"bookYear":null,"nameKR":"산마을","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"인제군","status":"ACTIVE"},
        {"id":32776,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bossanova_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":38.1885522085187,"longitude":128.603809095357,"bookYear":null,"nameKR":"보사노바커피로스터스(속초점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":32777,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bossanova_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.4657460742469,"longitude":129.171435882985,"bookYear":null,"nameKR":"보사노바커피로스터스(삼척점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":32802,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yuhwansicdang","foodTypes":"한식(어패류)","latitude":37.784387623574,"longitude":128.920264809932,"bookYear":null,"nameKR":"강릉유환식당","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33057,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cheonmae_satto/","foodTypes":"한식(육류)","latitude":37.3208803865341,"longitude":127.945445334115,"bookYear":null,"nameKR":"천매막국수&어사또왕족발보쌈","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":33097,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/todays_forest/","foodTypes":"디저트/차/베이커리","latitude":38.1888509613785,"longitude":128.588562065972,"bookYear":null,"nameKR":"오늘의숲","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":33154,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gdur_166/","foodTypes":"한식(어패류)","latitude":38.4483715526695,"longitude":128.458328226343,"bookYear":null,"nameKR":"고등어랑","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":33187,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yang_3day/","foodTypes":"한식(육류)","latitude":37.7681458675843,"longitude":128.878181176749,"bookYear":null,"nameKR":"성심어린(강릉점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33188,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/amsquare_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.3496970546059,"longitude":127.943353594722,"bookYear":null,"nameKR":"암스퀘어커피로스터스","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":33217,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.8815680174752,"longitude":128.831631169483,"bookYear":null,"nameKR":"강냉이소쿠리","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33227,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.7699598306712,"longitude":128.916099003935,"bookYear":null,"nameKR":"주문진항#20","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33287,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":38.2598078699028,"longitude":128.559296317137,"bookYear":null,"nameKR":"소울브릿지","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"고성군","status":"ACTIVE"},
        {"id":33311,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/yellowtree_cafe","foodTypes":"디저트/차/베이커리","latitude":37.3761877311125,"longitude":128.521745456144,"bookYear":null,"nameKR":"옐로우트리카페","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":33330,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":38.1979504456208,"longitude":128.569883772807,"bookYear":null,"nameKR":"메이트힐 더 로스터리카페","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":33441,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":38.2251214285408,"longitude":128.588226959498,"bookYear":null,"nameKR":"큰집횟집","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":33443,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5296406778276,"longitude":129.117438668673,"bookYear":null,"nameKR":"차이나웍","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":33444,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4800230906531,"longitude":129.121176258487,"bookYear":null,"nameKR":"차이나웍2 ","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":33460,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4654164643678,"longitude":129.16269572355,"bookYear":null,"nameKR":"뜰애홍합밥","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"삼척시","status":"ACTIVE"},
        {"id":33646,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5072623292481,"longitude":129.122459136783,"bookYear":null,"nameKR":"황정숙막국수","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"동해시","status":"ACTIVE"},
        {"id":33700,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_serengeti_coffee/","foodTypes":"디저트/차/베이커리","latitude":38.2050128910618,"longitude":128.578157261019,"bookYear":null,"nameKR":"세렝게티커피","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":33808,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kkilook_gn/","foodTypes":"디저트/차/베이커리","latitude":37.8703181279392,"longitude":128.842976758593,"bookYear":null,"nameKR":"갈매기다방","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":33824,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.1200824666253,"longitude":128.951247926904,"bookYear":null,"nameKR":"솔바람물소리","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"태백시","status":"ACTIVE"},
        {"id":33836,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/afternoontea_yw","foodTypes":"디저트/차/베이커리","latitude":37.1836666642528,"longitude":128.469474455027,"bookYear":null,"nameKR":"어느날디저트","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"영월군","status":"ACTIVE"},
        {"id":34125,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yangyang_favorite/","foodTypes":"디저트/차/베이커리","latitude":37.9675494160012,"longitude":128.761944369353,"bookYear":null,"nameKR":"페이보릿","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":34521,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/for_established_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.2785197965884,"longitude":127.909403507255,"bookYear":null,"nameKR":"엔킥바이포에스트","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":34589,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.7898284261825,"longitude":128.914641077556,"bookYear":null,"nameKR":"초당모꼬지","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":35155,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":38.1184520629514,"longitude":128.630466383864,"bookYear":null,"nameKR":"이태원왕갈비","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"양양군","status":"ACTIVE"},
        {"id":35273,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/love._.buckwheat_flower/","foodTypes":"한식(민물어패류)","latitude":37.6154287858689,"longitude":128.370434784437,"bookYear":null,"nameKR":"메밀꽃사랑","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"평창군","status":"ACTIVE"},
        {"id":35302,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hanwoowon/","foodTypes":"한식(육류)","latitude":37.7594737077429,"longitude":128.898538198554,"bookYear":null,"nameKR":"강릉 한우원","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":35377,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/shin.pd_fish/","foodTypes":"한식(어패류)","latitude":38.2027217413272,"longitude":128.592052726955,"bookYear":null,"nameKR":"신피디통큰생선구이","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":35462,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.8593635853866,"longitude":127.727905345476,"bookYear":null,"nameKR":"황금돼지저금통","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":35471,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5092700882077,"longitude":128.226998633433,"bookYear":null,"nameKR":"하누성(횡성본점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"횡성군","status":"ACTIVE"},
        {"id":35532,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.7922884465968,"longitude":128.917115241913,"bookYear":null,"nameKR":"초당110","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":35560,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.9299961214457,"longitude":127.788679961355,"bookYear":null,"nameKR":"비와별닭갈비(소양강점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":35625,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gn.gyungsungpocha/","foodTypes":"한식(어패류)","latitude":37.7905471968887,"longitude":128.920937237098,"bookYear":null,"nameKR":"경성포차","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":35673,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.7627245407524,"longitude":128.905308717024,"bookYear":null,"nameKR":"참순찰떡방(포남동본점)","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":35713,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7433561543034,"longitude":128.881783312308,"bookYear":null,"nameKR":"강릉홍가네옹심이","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"강릉시","status":"ACTIVE"},
        {"id":35766,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.9229090212663,"longitude":127.80680948061,"bookYear":null,"nameKR":"금옥","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":35767,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/simyahwaro.92/","foodTypes":"한식(육류)","latitude":37.8620977833607,"longitude":127.731241841082,"bookYear":null,"nameKR":"심야화로","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":31662,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jib.company","foodTypes":"바/주점","latitude":37.5582701252117,"longitude":126.919629517321,"bookYear":null,"nameKR":"목로집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":33377,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chinois_miro/","foodTypes":"아시아식","latitude":37.5616213054129,"longitude":126.927293704024,"bookYear":null,"nameKR":"시누아즈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35807,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chaeik_lim/","foodTypes":"디저트/차/베이커리","latitude":37.5590629077364,"longitude":126.920153815424,"bookYear":null,"nameKR":"세빠띠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":30282,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/park.ung/","foodTypes":"한식(가금류)","latitude":37.560912327826806,"longitude":126.92060536048022,"bookYear":null,"nameKR":"작가식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":30713,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/toma_wv?igshid=14c4p1mbmvacw","foodTypes":"일식","latitude":37.5595119480989,"longitude":126.921472669429,"bookYear":null,"nameKR":"토마","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31143,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kaffe_flam/","foodTypes":"디저트/차/베이커리","latitude":37.5615085236221,"longitude":126.925738233308,"bookYear":null,"nameKR":"카페플롬","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31952,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gaehwa.yeon","foodTypes":"바/주점","latitude":37.5620091934239,"longitude":126.925859408365,"bookYear":null,"nameKR":"개화연","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32526,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/566ramen","foodTypes":"일식","latitude":37.5605900486427,"longitude":126.920884693893,"bookYear":null,"nameKR":"566라멘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32982,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mysteriouskitchen_yeonnam/","foodTypes":"기타","latitude":37.5625353595712,"longitude":126.925834326013,"bookYear":null,"nameKR":"수상한키친","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":33083,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yangsikguok/","foodTypes":"이탈리아식","latitude":37.5617397985364,"longitude":126.925577850019,"bookYear":null,"nameKR":"양식구옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":33126,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5628980366499,"longitude":126.925732439897,"bookYear":null,"nameKR":"카페밀노 연남","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":33137,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daregelato/","foodTypes":"디저트/차/베이커리","latitude":37.5613569326774,"longitude":126.92581263134,"bookYear":null,"nameKR":"다레젤라또(연남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":33429,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/quafe_twisted","foodTypes":"디저트/차/베이커리","latitude":37.5621888604343,"longitude":126.925729295629,"bookYear":null,"nameKR":"꽈페","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":34795,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.562677427478,"longitude":126.925803738612,"bookYear":null,"nameKR":"오늘그대와","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35329,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5627163457846,"longitude":126.925367374427,"bookYear":null,"nameKR":"아디오도시 파리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":9123,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.56048,"longitude":126.9209,"bookYear":null,"nameKR":"오군수제고로케","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":15953,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.560371,"longitude":126.921028,"bookYear":null,"nameKR":"바다회사랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":25698,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"프랑스식","latitude":37.5613569326774,"longitude":126.92581263134,"bookYear":null,"nameKR":"쎄뿌뜨와","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27226,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dailyround_insta/","foodTypes":"기타","latitude":37.5619541212958,"longitude":126.925969137436,"bookYear":null,"nameKR":"데일리라운드(홍대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27292,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.561856,"longitude":126.92187,"bookYear":null,"nameKR":"감나무집기사식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27309,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5618266911747,"longitude":126.925492989532,"bookYear":null,"nameKR":"신군신양","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27702,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5614314159824,"longitude":126.918524630671,"bookYear":null,"nameKR":"연희동할머니네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28370,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.5621091724218,"longitude":126.924670770299,"bookYear":null,"nameKR":"리틀파파포(연남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28631,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.562602,"longitude":126.920467,"bookYear":null,"nameKR":"연남살롱","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":7856,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5618364940379,"longitude":126.924050688833,"bookYear":null,"nameKR":"송가네감자탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":23104,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.561959,"longitude":126.923358,"bookYear":null,"nameKR":"옥타","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27488,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5615316892488,"longitude":126.925468156781,"bookYear":null,"nameKR":"폴리스팬케이크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27633,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5606076166187,"longitude":126.9234798056,"bookYear":null,"nameKR":"나무그늘집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28132,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_lareine","foodTypes":"디저트/차/베이커리","latitude":37.562073,"longitude":126.926029,"bookYear":null,"nameKR":"라헨느","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28303,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5644315547941,"longitude":126.919975815306,"bookYear":null,"nameKR":"피오니(연남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":29370,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeongyo_yeonnam/","foodTypes":"중식","latitude":37.561566,"longitude":126.926648,"bookYear":null,"nameKR":"연교","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":30737,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lankwaijin","foodTypes":"중식","latitude":37.5657409707314,"longitude":126.923368723408,"bookYear":null,"nameKR":"란콰이진","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31312,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.562036049384,"longitude":126.924166951874,"bookYear":null,"nameKR":"랜디스도넛(연남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31797,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thanksoat/","foodTypes":"디저트/차/베이커리","latitude":37.5662739481646,"longitude":126.919897441386,"bookYear":null,"nameKR":"땡스오트연남","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32053,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/konro_yakiniku/","foodTypes":"일식","latitude":37.5620242723214,"longitude":126.923927241622,"bookYear":null,"nameKR":"곤로야끼니꾸","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32540,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pfiff.espresso.bar","foodTypes":"디저트/차/베이커리","latitude":37.5643000764386,"longitude":126.922644898853,"bookYear":null,"nameKR":"피프에스프레소바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32616,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5614698620877,"longitude":126.926443851035,"bookYear":null,"nameKR":"온실","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32700,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mijasea","foodTypes":"한식(어패류)","latitude":37.5616511196181,"longitude":126.924692396648,"bookYear":null,"nameKR":"미자씨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32941,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/okza_yeonnam","foodTypes":"아시아식","latitude":37.5654898301933,"longitude":126.922490071785,"bookYear":null,"nameKR":"옥자","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":34344,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5657222278679,"longitude":126.92350434288,"bookYear":null,"nameKR":"라쿤","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35266,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/9ukza/","foodTypes":"바/주점","latitude":37.5621110237609,"longitude":126.924044637797,"bookYear":null,"nameKR":"연남국자","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35691,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5634143168404,"longitude":126.922248194459,"bookYear":null,"nameKR":"마카롱롱롱","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":30030,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeonnamterrace/","foodTypes":"이탈리아식","latitude":37.56309252582777,"longitude":126.92581860690771,"bookYear":null,"nameKR":"연남테라스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32190,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/itdaproject","foodTypes":"이탈리아식","latitude":37.5644623885537,"longitude":126.925656523098,"bookYear":null,"nameKR":"잇다프로젝트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":33786,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seoul_pastry","foodTypes":"디저트/차/베이커리","latitude":37.5628792914086,"longitude":126.924166210355,"bookYear":null,"nameKR":"서울페이스트리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":34207,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/we_saladu/","foodTypes":"기타","latitude":37.5636891100996,"longitude":126.926885244285,"bookYear":null,"nameKR":"위샐러듀다이닝","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":5665,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.562563,"longitude":126.926994,"bookYear":null,"nameKR":"이노시시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27175,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/salondeceylon/","foodTypes":"디저트/차/베이커리","latitude":37.562375923911745,"longitude":126.92676553411971,"bookYear":null,"nameKR":"실론살롱","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27473,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moon_kitchen1/","foodTypes":"바/주점","latitude":37.562132,"longitude":126.926484,"bookYear":null,"nameKR":"달빛부엌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28096,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.562601,"longitude":126.926236,"bookYear":null,"nameKR":"폰테벨라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28122,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.565358,"longitude":126.923149,"bookYear":null,"nameKR":"산왕반점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28273,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.562964685536,"longitude":126.926556924313,"bookYear":null,"nameKR":"아필립(2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":29514,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5632513450692,"longitude":126.92620780630946,"bookYear":null,"nameKR":"테일러커피(연남2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":7061,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/la_lune_violette/","foodTypes":"프랑스식","latitude":37.55709,"longitude":126.9205,"bookYear":null,"nameKR":"라룬비올렛","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":7923,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.55653,"longitude":126.920266,"bookYear":null,"nameKR":"부산집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":12577,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.558179,"longitude":126.922943,"bookYear":null,"nameKR":"뽀르뚜아과자점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":22371,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.557503,"longitude":126.921279,"bookYear":null,"nameKR":"빵나무","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":25741,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/deepcoffee_official/","foodTypes":"디저트/차/베이커리","latitude":37.558683,"longitude":126.923327,"bookYear":null,"nameKR":"딥커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27230,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/laond_chocolate","foodTypes":"디저트/차/베이커리","latitude":37.5639474524567,"longitude":126.926493028022,"bookYear":null,"nameKR":"라온디","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27308,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.555832,"longitude":126.92109,"bookYear":null,"nameKR":"별천지설농탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":27825,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.55644,"longitude":126.9226,"bookYear":null,"nameKR":"로운","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28222,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bubu_de_comptoir/","foodTypes":"바/주점","latitude":37.55982,"longitude":126.92242,"bookYear":null,"nameKR":"프랑스포차부부드꼼뜨와","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28560,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.563058,"longitude":126.926365,"bookYear":null,"nameKR":"테일러커피(연남1호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":30648,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/burvana","foodTypes":"기타","latitude":37.560088444905425,"longitude":126.92276118859493,"bookYear":null,"nameKR":"버바나","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31110,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_layered/","foodTypes":"디저트/차/베이커리","latitude":37.5649902551936,"longitude":126.924197234558,"bookYear":null,"nameKR":"레이어드(연남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":31731,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tnjane_official","foodTypes":"이탈리아식","latitude":37.5647657861584,"longitude":126.924712125245,"bookYear":null,"nameKR":"티앤제인","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":33864,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ravoire_official/","foodTypes":"디저트/차/베이커리","latitude":37.5653277185427,"longitude":126.924119811394,"bookYear":null,"nameKR":"라보아르 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":34422,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nakgobsaemjw/","foodTypes":"한식(어패류)","latitude":37.5571277920898,"longitude":126.920488617343,"bookYear":null,"nameKR":"낙곱새미장원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":35944,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5583302651928,"longitude":126.921164029035,"bookYear":null,"nameKR":"레드플랜트(동교점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":30390,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.558907517289775,"longitude":126.92458684610322,"bookYear":null,"nameKR":"크레미뇽","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":32793,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bearsden_bakery","foodTypes":"디저트/차/베이커리","latitude":37.5593261331944,"longitude":126.92560107573,"bookYear":null,"nameKR":"베어스덴베이커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":34176,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/analogtable_yeonnam","foodTypes":"이탈리아식","latitude":37.5587712526005,"longitude":126.924816169617,"bookYear":null,"nameKR":"아날로그 테이블 연남","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":34734,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tae_guksu/","foodTypes":"아시아식","latitude":37.5614785192644,"longitude":126.926745926502,"bookYear":null,"nameKR":"태국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":7926,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5585,"longitude":126.9236,"bookYear":null,"nameKR":"교동집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":21509,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5596602534091,"longitude":126.925649637142,"bookYear":null,"nameKR":"해달밥술","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":26727,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/square_imi/","foodTypes":"디저트/차/베이커리","latitude":37.558831,"longitude":126.924248,"bookYear":null,"nameKR":"스퀘어이미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":26804,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/reggaechicken_yeonhui/","foodTypes":"한식(가금류)","latitude":37.559125,"longitude":126.925345,"bookYear":null,"nameKR":"레게치킨서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":28046,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nrparlour/","foodTypes":"디저트/차/베이커리","latitude":37.55994,"longitude":126.92532,"bookYear":null,"nameKR":"낙랑파라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":29745,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/butterbakery.official/","foodTypes":"디저트/차/베이커리","latitude":37.56003520759856,"longitude":126.92402886259394,"bookYear":null,"nameKR":"버터베이커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"02. 홍대입구역/연남동","status":"ACTIVE"},
        {"id":1063,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.554495,"longitude":126.931657,"bookYear":null,"nameKR":"철길왕갈비살","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":4880,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/parkkyesook/","foodTypes":"일식","latitude":37.554682,"longitude":126.916215,"bookYear":null,"nameKR":"마츠에라멘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5810,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.556342,"longitude":126.919682,"bookYear":null,"nameKR":"더페이머스램","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27019,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5562314918474,"longitude":126.914075064568,"bookYear":null,"nameKR":"길모퉁이칠리차차","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27753,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5531271613033,"longitude":126.925426279908,"bookYear":null,"nameKR":"풀잎향기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28001,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.555462,"longitude":126.916257,"bookYear":null,"nameKR":"제주정원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":29162,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seogyo_rotary_coffeebar/","foodTypes":"디저트/차/베이커리","latitude":37.554423,"longitude":126.917192,"bookYear":null,"nameKR":"서교로터리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":30097,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_kawakatsu_","foodTypes":"일식","latitude":37.55474286146928,"longitude":126.91620271470441,"bookYear":null,"nameKR":"카와카츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":30454,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/quench_coffee_nulimlee/","foodTypes":"디저트/차/베이커리","latitude":37.55400881376821,"longitude":126.91283556317251,"bookYear":null,"nameKR":"퀜치커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":30646,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lao_official_kr","foodTypes":"아시아식","latitude":37.55515355835388,"longitude":126.9159804359812,"bookYear":null,"nameKR":"라오","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32080,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kashiwa_dining/","foodTypes":"일식","latitude":37.5545377422357,"longitude":126.914371822209,"bookYear":null,"nameKR":"카시와","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32148,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brsr_seogyo/","foodTypes":"기타","latitude":37.5551058402556,"longitude":126.911706405241,"bookYear":null,"nameKR":"브뤼서리서교","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33855,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5559371523287,"longitude":126.914718910275,"bookYear":null,"nameKR":"아지트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":35157,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/projectharu.kr/","foodTypes":"디저트/차/베이커리","latitude":37.5542863522057,"longitude":126.915355231279,"bookYear":null,"nameKR":"연과점 하루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":35538,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5541117630052,"longitude":126.912423273376,"bookYear":null,"nameKR":"기요한","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32012,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5494262243681,"longitude":126.917312357764,"bookYear":null,"nameKR":"무판","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32155,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sarangbang_/","foodTypes":"이탈리아식","latitude":37.5490099022162,"longitude":126.921175543849,"bookYear":null,"nameKR":"비스트로사랑방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32707,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gongjoongdosie","foodTypes":"디저트/차/베이커리","latitude":37.5500019636091,"longitude":126.919267327754,"bookYear":null,"nameKR":"공중도시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":32945,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/july13_ristorante/","foodTypes":"이탈리아식","latitude":37.5489898367977,"longitude":126.921486312135,"bookYear":null,"nameKR":"줄라이13","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33724,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5497033519228,"longitude":126.920164253311,"bookYear":null,"nameKR":"카레시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":35940,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moritzplatz_oom/","foodTypes":"디저트/차/베이커리","latitude":37.5502727848665,"longitude":126.922878599624,"bookYear":null,"nameKR":"모리츠플라츠오오엠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36209,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yotsuya_hapjung/","foodTypes":"일식","latitude":37.5492314847664,"longitude":126.918690796393,"bookYear":null,"nameKR":"요츠야","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":1430,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.550271254063865,"longitude":126.9228799591869,"bookYear":null,"nameKR":"클럽에반스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5930,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.548864,"longitude":126.920006,"bookYear":null,"nameKR":"팩토리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6625,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/master_suaveshop/","foodTypes":"디저트/차/베이커리","latitude":37.556704,"longitude":126.918902,"bookYear":null,"nameKR":"슈아브","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":12945,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.54872,"longitude":126.9203,"bookYear":null,"nameKR":"별밤","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26826,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.550019,"longitude":126.922095,"bookYear":null,"nameKR":"샴락앤롤아이리쉬펍","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26861,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.548419,"longitude":126.91969,"bookYear":null,"nameKR":"옹달샘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27342,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.54962,"longitude":126.9224,"bookYear":null,"nameKR":"윤씨밀방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27464,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/young.sik.kim/","foodTypes":"바/주점","latitude":37.549191,"longitude":126.918653,"bookYear":null,"nameKR":"닥터마르","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27535,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/roro.eleven/","foodTypes":"이탈리아식","latitude":37.5498894301844,"longitude":126.918482310878,"bookYear":null,"nameKR":"로로11","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27782,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bar_dstill/","foodTypes":"바/주점","latitude":37.5499499718458,"longitude":126.92248817342,"bookYear":null,"nameKR":"디스틸","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28021,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/analog_garden","foodTypes":"기타","latitude":37.55543,"longitude":126.918,"bookYear":null,"nameKR":"아날로그가든","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28040,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.54876,"longitude":126.9193,"bookYear":null,"nameKR":"개화기요정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28315,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.548783,"longitude":126.919518,"bookYear":null,"nameKR":"바다포차시실리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":532,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.551586,"longitude":126.915604,"bookYear":null,"nameKR":"스시겐","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":4834,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.550987,"longitude":126.917368,"bookYear":null,"nameKR":"오자와","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5797,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/beliefcoffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5523422860741,"longitude":126.914006649192,"bookYear":null,"nameKR":"빌리프커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":8327,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mellow_cafe_/","foodTypes":"디저트/차/베이커리","latitude":37.551806,"longitude":126.919904,"bookYear":null,"nameKR":"멜로우","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":12933,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.552659,"longitude":126.912463,"bookYear":null,"nameKR":"서강껍데기소금구이소막창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":14527,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5528292174806,"longitude":126.919814054897,"bookYear":null,"nameKR":"영빈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":15315,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.553136,"longitude":126.920216,"bookYear":null,"nameKR":"두리반","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":21700,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.55075,"longitude":126.919709,"bookYear":null,"nameKR":"베이스캠프","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":23504,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yume_hongdae/","foodTypes":"바/주점","latitude":37.549192,"longitude":126.916216,"bookYear":null,"nameKR":"유메","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":24425,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ego_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.549368,"longitude":126.915584,"bookYear":null,"nameKR":"에고","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27296,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.55042420279193,"longitude":126.91984243325116,"bookYear":null,"nameKR":"미담진족","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27719,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.55423,"longitude":126.91915,"bookYear":null,"nameKR":"어메이징타일랜드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28009,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lpbar_cream/","foodTypes":"바/주점","latitude":37.5531755845749,"longitude":126.920247916685,"bookYear":null,"nameKR":"크림LP바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28278,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brewbrosroastery/","foodTypes":"디저트/차/베이커리","latitude":37.551913,"longitude":126.92022,"bookYear":null,"nameKR":"브루브로스커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28743,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.55042420279193,"longitude":126.91984243325116,"bookYear":null,"nameKR":"더캐스크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":30351,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sekai_no_hate_no_ramen/","foodTypes":"일식","latitude":37.55170037484883,"longitude":126.91519666565866,"bookYear":null,"nameKR":"세상끝의라멘","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33358,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5500851944722,"longitude":126.919764712297,"bookYear":null,"nameKR":"백소정(홍대합정점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":30822,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tendonhamada/","foodTypes":"일식","latitude":37.5544056031357,"longitude":126.921965798065,"bookYear":null,"nameKR":"에도마에텐동하마다","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31391,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5555650802746,"longitude":126.925711537641,"bookYear":null,"nameKR":"언더크레마","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33992,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ipanema_grill","foodTypes":"중남미식","latitude":37.5532482841278,"longitude":126.923499439833,"bookYear":null,"nameKR":"이빠네마그릴(홍대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":34645,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5543499095967,"longitude":126.920999483231,"bookYear":null,"nameKR":"청기와타운(홍대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36179,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/shinjoo0187/","foodTypes":"중식","latitude":37.5504130685142,"longitude":126.923290830966,"bookYear":null,"nameKR":"신주양꼬치(홍대본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":4205,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/SWBOKZIP/","foodTypes":"한식(어패류)","latitude":37.554546,"longitude":126.921701,"bookYear":null,"nameKR":"신원복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5288,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5506,"longitude":126.9233,"bookYear":null,"nameKR":"삼거리포차","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6563,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.55583,"longitude":126.92517,"bookYear":null,"nameKR":"달술집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6583,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/makssa_1/","foodTypes":"바/주점","latitude":37.552687,"longitude":126.923104,"bookYear":null,"nameKR":"막걸리싸롱","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":8424,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.550577,"longitude":126.923572,"bookYear":null,"nameKR":"예티","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":16285,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/doma_hongdae/","foodTypes":"한식(육류)","latitude":37.553046,"longitude":126.921402,"bookYear":null,"nameKR":"도마","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":22757,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5527268383311,"longitude":126.923858266579,"bookYear":null,"nameKR":"홍익수제비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26016,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yb_1945","foodTypes":"중식","latitude":37.55177,"longitude":126.9225,"bookYear":null,"nameKR":"송탄영빈루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26271,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.55454,"longitude":126.919384,"bookYear":null,"nameKR":"설참치(2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26893,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.552434,"longitude":126.922549,"bookYear":null,"nameKR":"쇼콜라윰","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26913,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5556350723573,"longitude":126.924833014539,"bookYear":null,"nameKR":"달의계단","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27062,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5555650802746,"longitude":126.925711537641,"bookYear":null,"nameKR":"누바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27147,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.552934,"longitude":126.922104,"bookYear":null,"nameKR":"강수곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27701,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5527935053551,"longitude":126.92342973971,"bookYear":null,"nameKR":"고집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27977,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/blot_coffee","foodTypes":"디저트/차/베이커리","latitude":37.55145,"longitude":126.9228,"bookYear":null,"nameKR":"블로트커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28010,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.552054,"longitude":126.921124,"bookYear":null,"nameKR":"문숙이미나리식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":6029,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.55541,"longitude":126.92434,"bookYear":null,"nameKR":"오비야","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":7016,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5557,"longitude":126.9278,"bookYear":null,"nameKR":"더담","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":12942,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.55493,"longitude":126.9292,"bookYear":null,"nameKR":"커피랩","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":16226,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.55487,"longitude":126.927892,"bookYear":null,"nameKR":"텟판타마고","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":25756,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.555733,"longitude":126.92569,"bookYear":null,"nameKR":"펍원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27164,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.555689,"longitude":126.92686,"bookYear":null,"nameKR":"타파스나인","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27237,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakerycafeaoitori/","foodTypes":"디저트/차/베이커리","latitude":37.55474,"longitude":126.9294,"bookYear":null,"nameKR":"아오이토리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27531,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.554838144866,"longitude":126.923995999619,"bookYear":null,"nameKR":"두레차","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27711,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5536399526623,"longitude":126.924205450362,"bookYear":null,"nameKR":"동강해물찜","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27720,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.55591,"longitude":126.9268,"bookYear":null,"nameKR":"이태원겐지스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27751,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5549933639148,"longitude":126.927542559587,"bookYear":null,"nameKR":"오너쉐프","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27894,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jcp_hongdae/","foodTypes":"미국식","latitude":37.5554,"longitude":126.9239,"bookYear":null,"nameKR":"제임스시카고피자","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28308,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5538328517557,"longitude":126.927645084291,"bookYear":null,"nameKR":"테일러커피(서교점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28359,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.555034,"longitude":126.929387,"bookYear":null,"nameKR":"림가기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31071,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tone_manner077","foodTypes":"디저트/차/베이커리","latitude":37.5542683101067,"longitude":126.928691952837,"bookYear":null,"nameKR":"톤앤매너","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31669,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/altdif","foodTypes":"디저트/차/베이커리","latitude":37.5558712475746,"longitude":126.929396291558,"bookYear":null,"nameKR":"알디프티바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":33689,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/torashun_official/","foodTypes":"일식","latitude":37.5557269262095,"longitude":126.926474054927,"bookYear":null,"nameKR":"토라슌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":29939,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/homefromgui/","foodTypes":"바/주점","latitude":37.54705988735309,"longitude":126.9225618914582,"bookYear":null,"nameKR":"홈프롬귀","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":30759,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5459855272478,"longitude":126.922136959082,"bookYear":null,"nameKR":"하꼬","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31254,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5473486968425,"longitude":126.922484643154,"bookYear":null,"nameKR":"브렛피자","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":31301,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myungsungkwan/","foodTypes":"바/주점","latitude":37.5459467147832,"longitude":126.921217130207,"bookYear":null,"nameKR":"명성관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":34539,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5484259464137,"longitude":126.921147528613,"bookYear":null,"nameKR":"경주식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":35006,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5475888171243,"longitude":126.922628335871,"bookYear":null,"nameKR":"버들골이야기(상수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":35330,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hansukwha/","foodTypes":"한식(일반한식)","latitude":37.5481882727443,"longitude":126.921839766783,"bookYear":null,"nameKR":"한석화","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":36038,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/katsnic_/","foodTypes":"일식","latitude":37.548247134132,"longitude":126.921336471931,"bookYear":null,"nameKR":"카츠닉","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":3487,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.54581,"longitude":126.921572,"bookYear":null,"nameKR":"이리카페","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":3828,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.54974867542175,"longitude":126.92288049761076,"bookYear":null,"nameKR":"스노브","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":3895,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.548796,"longitude":126.923203,"bookYear":null,"nameKR":"호호미욜","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":5815,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/decoa_balim/","foodTypes":"디저트/차/베이커리","latitude":37.5484231184581,"longitude":126.921772416079,"bookYear":null,"nameKR":"데코아발림","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":8325,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.549802297004,"longitude":126.92401107185,"bookYear":null,"nameKR":"카페더블루스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":14859,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.54581,"longitude":126.9208,"bookYear":null,"nameKR":"슬런치팩토리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":14970,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.54663,"longitude":126.923084,"bookYear":null,"nameKR":"제비다방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":24919,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.54587,"longitude":126.9218,"bookYear":null,"nameKR":"탐라식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":26943,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.54708,"longitude":126.9231,"bookYear":null,"nameKR":"무명집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":27304,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mum_aroy/","foodTypes":"아시아식","latitude":37.548276439825,"longitude":126.921778340653,"bookYear":null,"nameKR":"뭄알로이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28309,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5482,"longitude":126.920919,"bookYear":null,"nameKR":"홍대삭","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28831,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.548064,"longitude":126.921613,"bookYear":null,"nameKR":"유니크스위트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":28944,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/salotto_sangsu_official/","foodTypes":"이탈리아식","latitude":37.54941059606287,"longitude":126.92395364259423,"bookYear":null,"nameKR":"살로또상수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":29744,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/taster_house/","foodTypes":"바/주점","latitude":37.54595708041076,"longitude":126.92258566403736,"bookYear":null,"nameKR":"미식가주택","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"01. 홍대앞/서교동","status":"ACTIVE"},
        {"id":17582,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.53425,"longitude":127.0079,"bookYear":null,"nameKR":"스시아메","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":23117,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.54225,"longitude":127.0033,"bookYear":null,"nameKR":"익스페리멘털커피바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":29451,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maison_antibes/","foodTypes":"프랑스식","latitude":37.537386883114664,"longitude":127.00291011662243,"bookYear":null,"nameKR":"메종앙티브","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":29763,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/buto_hannam/","foodTypes":"한식(일반한식)","latitude":37.53810408737044,"longitude":127.0025661771826,"bookYear":null,"nameKR":"부토","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":31987,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/meatlokaal/","foodTypes":"기타","latitude":37.5315958889326,"longitude":127.007000144767,"bookYear":null,"nameKR":"미트로칼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":32432,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/littleneck.official/","foodTypes":"기타","latitude":37.539599647338,"longitude":127.003000269542,"bookYear":null,"nameKR":"리틀넥(한남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":32606,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oldferrydonut/","foodTypes":"디저트/차/베이커리","latitude":37.539599647338,"longitude":127.003000269542,"bookYear":null,"nameKR":"올드페리도넛","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33144,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5350413619239,"longitude":127.008995621906,"bookYear":null,"nameKR":"한남타츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33485,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/beastyburger_official/","foodTypes":"기타","latitude":37.532381186051,"longitude":127.007257834517,"bookYear":null,"nameKR":"비스티버거","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":34140,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5377482946181,"longitude":127.004996440907,"bookYear":null,"nameKR":"어물전청(한남점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":35246,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"프랑스식","latitude":37.5365636555465,"longitude":127.002134363783,"bookYear":null,"nameKR":"코르소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":29926,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"프랑스식","latitude":37.53536824993817,"longitude":127.0100516248657,"bookYear":null,"nameKR":"에피세리꼴라주","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":30015,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.534611509682044,"longitude":127.00879338576144,"bookYear":null,"nameKR":"고다이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":30031,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/omangia_chefjake/","foodTypes":"이탈리아식","latitude":37.53397351935086,"longitude":127.00982968150744,"bookYear":null,"nameKR":"오만지아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":30204,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tender_luca/","foodTypes":"바/주점","latitude":37.53016423757489,"longitude":127.00780179975787,"bookYear":null,"nameKR":"바로크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":30725,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5350126702013,"longitude":127.009504985864,"bookYear":null,"nameKR":"뇨끼바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":31782,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/camillo_hannam","foodTypes":"이탈리아식","latitude":37.5351686412066,"longitude":127.010478032935,"bookYear":null,"nameKR":"카밀로한남","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":32567,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/icecream__society","foodTypes":"디저트/차/베이커리","latitude":37.5349014887347,"longitude":127.009479741019,"bookYear":null,"nameKR":"아이스크림소사이어티","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33301,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/peer_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5322155400016,"longitude":127.009158880405,"bookYear":null,"nameKR":"피어커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":35454,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.5347647400939,"longitude":127.01027850691,"bookYear":null,"nameKR":"알레즈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":35817,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwangtaeilro/","foodTypes":"한식(어패류)","latitude":37.5332845884683,"longitude":127.004186627406,"bookYear":null,"nameKR":"황태일로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":36547,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soko_bar/","foodTypes":"바/주점","latitude":37.5349014887347,"longitude":127.009479741019,"bookYear":null,"nameKR":"소코","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":1246,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/itsumo2003/","foodTypes":"바/주점","latitude":37.533828,"longitude":127.008133,"bookYear":null,"nameKR":"이쯔모","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":5286,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bananagrill/","foodTypes":"기타","latitude":37.53379,"longitude":127.0081,"bookYear":null,"nameKR":"바나나그릴","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":5780,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pancakes_original_story/","foodTypes":"기타","latitude":37.53379,"longitude":127.008111,"bookYear":null,"nameKR":"팬케이크오리지널스토리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":13392,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hambagu_curry/","foodTypes":"기타","latitude":37.5341988807655,"longitude":127.008388065386,"bookYear":null,"nameKR":"함바그또카레야","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":27569,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.53524,"longitude":127.00998,"bookYear":null,"nameKR":"청","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":29306,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.534899776712614,"longitude":127.0094813247948,"bookYear":null,"nameKR":"오스테리아오르조","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":29377,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/redmoonseoul","foodTypes":"바/주점","latitude":37.534971,"longitude":127.009491,"bookYear":null,"nameKR":"레드문","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":7168,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.54196,"longitude":127.0165,"bookYear":null,"nameKR":"콩빠두","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":9078,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5485,"longitude":127.021976,"bookYear":null,"nameKR":"골목냉면","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":31623,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/detempore_neobistro/","foodTypes":"프랑스식","latitude":37.5322290415841,"longitude":127.008110316608,"bookYear":null,"nameKR":"디템포레","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33152,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/herendycoffee/","foodTypes":"디저트/차/베이커리","latitude":37.553725583492,"longitude":127.021929323445,"bookYear":null,"nameKR":"헤렌디커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":33162,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sweefi.seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5402350277221,"longitude":127.011739689498,"bookYear":null,"nameKR":"스위피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":34340,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/parco_musumak/","foodTypes":"바/주점","latitude":37.5505615906145,"longitude":127.026147450059,"bookYear":null,"nameKR":"파르코무수막","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":35984,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5484283131996,"longitude":127.018988142865,"bookYear":null,"nameKR":"베쌀집(금호본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":36520,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5477993802878,"longitude":127.021803448745,"bookYear":null,"nameKR":"소설옥(금남시장점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"20. 한남동/옥수동/금호동","status":"ACTIVE"},
        {"id":30023,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.57322086582709,"longitude":127.01121135270095,"bookYear":null,"nameKR":"수원성갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":34433,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/borntobe_blue","foodTypes":"디저트/차/베이커리","latitude":37.565900776642,"longitude":127.002800639675,"bookYear":null,"nameKR":"본투비블루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":1141,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.57272,"longitude":127.014,"bookYear":null,"nameKR":"뿌자","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":7285,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.572057,"longitude":127.011226,"bookYear":null,"nameKR":"진고개(동대문점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":7306,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.57099,"longitude":127.0108,"bookYear":null,"nameKR":"동북화과왕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":7328,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.5668979260337,"longitude":127.006807513903,"bookYear":null,"nameKR":"뿌자(2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":7889,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.572337,"longitude":127.010793,"bookYear":null,"nameKR":"와글와글족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":7938,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.574444,"longitude":127.015245,"bookYear":null,"nameKR":"낙산냉면","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":8723,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.572036,"longitude":127.010989,"bookYear":null,"nameKR":"동대문설렁탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":8727,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.571664,"longitude":127.011661,"bookYear":null,"nameKR":"동문식당(별관1호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":9107,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5693173401957,"longitude":127.012637355861,"bookYear":null,"nameKR":"유정식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":13005,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5726598289616,"longitude":127.004040878995,"bookYear":null,"nameKR":"백제정육점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":21855,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.568714,"longitude":127.005498,"bookYear":null,"nameKR":"경상도집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":22735,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.573132,"longitude":127.011546,"bookYear":null,"nameKR":"옥천매운족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":25678,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5719067124142,"longitude":127.012620813633,"bookYear":null,"nameKR":"동대문양육관점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":28624,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5704485321397,"longitude":127.008743829151,"bookYear":null,"nameKR":"타볼로24","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":29331,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mrs.fortuna/","foodTypes":"유럽식","latitude":37.564552,"longitude":127.004967,"bookYear":null,"nameKR":"파르투내","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":29822,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.56699713460257,"longitude":127.00665109320387,"bookYear":null,"nameKR":"해룡마라룽샤(동대문점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":7907,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.570067,"longitude":127.000113,"bookYear":null,"nameKR":"은성횟집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":7956,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.576827,"longitude":127.015857,"bookYear":null,"nameKR":"궁나라냉면묵밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":8742,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5714335534085,"longitude":126.999746324303,"bookYear":null,"nameKR":"삼성원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":8764,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5701125140082,"longitude":126.999707163125,"bookYear":null,"nameKR":"원조수원죽집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":8875,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57022,"longitude":127.006365,"bookYear":null,"nameKR":"호남집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":9093,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.571284,"longitude":127.00155,"bookYear":null,"nameKR":"삼보치킨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":12386,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5702826169787,"longitude":127.001120978032,"bookYear":null,"nameKR":"모녀꼬마마약김밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":12566,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.570536,"longitude":126.99987,"bookYear":null,"nameKR":"육회자매집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":24391,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.572237,"longitude":127.000606,"bookYear":null,"nameKR":"너섬가","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":31745,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5702146942862,"longitude":127.004428555358,"bookYear":null,"nameKR":"더우리곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":32699,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5706848236139,"longitude":127.000842860478,"bookYear":null,"nameKR":"광장맷돌집 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":32710,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5704986692802,"longitude":127.00166262475,"bookYear":null,"nameKR":"진주육회(3호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":32711,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5713209882191,"longitude":127.002712435476,"bookYear":null,"nameKR":"육즙당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":33621,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.onion/","foodTypes":"디저트/차/베이커리","latitude":37.5698284205096,"longitude":127.001536963273,"bookYear":null,"nameKR":"어니언(광장시장점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":34072,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kyosikdang_seoul/","foodTypes":"일식","latitude":37.5703893145214,"longitude":127.006257359689,"bookYear":null,"nameKR":"쿄식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":35322,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/changsin4/","foodTypes":"한식(육류)","latitude":37.5707050969985,"longitude":127.000690385099,"bookYear":null,"nameKR":"임현정창신육회빈대떡","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":36147,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/parkgane1966_official/","foodTypes":"한식(일반한식)","latitude":37.5704755177265,"longitude":127.001346693936,"bookYear":null,"nameKR":"박가네빈대떡(2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":36150,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/parkgane1966_official/","foodTypes":"한식(육류)","latitude":37.5704833608427,"longitude":127.000872515587,"bookYear":null,"nameKR":"박가네육회","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":36574,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5699757268247,"longitude":126.998029608751,"bookYear":null,"nameKR":"진주육회(4호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":36575,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5701826956741,"longitude":127.001213683773,"bookYear":null,"nameKR":"진주육회(2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":33305,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffee_at_market","foodTypes":"디저트/차/베이커리","latitude":37.5660936500343,"longitude":127.019945794403,"bookYear":null,"nameKR":"커피엣마켓","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":33990,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lotus_roasting_lab/","foodTypes":"디저트/차/베이커리","latitude":37.567012309015,"longitude":127.018826921399,"bookYear":null,"nameKR":"로투스랩","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":34180,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/onethird_hwanghak/","foodTypes":"디저트/차/베이커리","latitude":37.5687948259061,"longitude":127.022026785712,"bookYear":null,"nameKR":"원써드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":34384,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hatmuri_official/","foodTypes":"한식(육류)","latitude":37.5692408214603,"longitude":127.019446315132,"bookYear":null,"nameKR":"햇무리식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":35563,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/milders_official/","foodTypes":"디저트/차/베이커리","latitude":37.5703665287537,"longitude":127.022939607736,"bookYear":null,"nameKR":"마일더스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":6728,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.5618858207911,"longitude":127.005937924834,"bookYear":null,"nameKR":"아시아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8467,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.560731,"longitude":127.005311,"bookYear":null,"nameKR":"닭한마리돼지한근","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8720,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.570582,"longitude":127.000708,"bookYear":null,"nameKR":"통큰누이네육회빈대떡","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"14. 종로4,5가/동대문/동묘","status":"ACTIVE"},
        {"id":8804,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5683330981944,"longitude":127.023266224644,"bookYear":null,"nameKR":"못난이곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8809,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.570146,"longitude":127.019683,"bookYear":null,"nameKR":"보람이네곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8821,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5701490346484,"longitude":127.019386897178,"bookYear":null,"nameKR":"오복이네곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8831,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hanyang0766/","foodTypes":"한식(육류)","latitude":37.5666620270723,"longitude":127.019229224834,"bookYear":null,"nameKR":"한양갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8833,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.567943,"longitude":127.020813,"bookYear":null,"nameKR":"안경할머니곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":8834,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5701436621991,"longitude":127.019181672179,"bookYear":null,"nameKR":"할아버지손칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":9103,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.56621,"longitude":127.0205,"bookYear":null,"nameKR":"소문난국수집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":9104,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5668722969758,"longitude":127.023351229221,"bookYear":null,"nameKR":"삼호복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":20569,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5606730315574,"longitude":127.005022418532,"bookYear":null,"nameKR":"도치","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":1315,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.56022,"longitude":127.006,"bookYear":null,"nameKR":"평안도족발집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":3967,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.560891,"longitude":127.005118,"bookYear":null,"nameKR":"송원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":3968,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.560133,"longitude":127.005267,"bookYear":null,"nameKR":"함흥에겨울냉면","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":5629,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.560278,"longitude":127.000111,"bookYear":null,"nameKR":"장충동할머니집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":5878,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.56014,"longitude":127.0024,"bookYear":null,"nameKR":"더킹스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":7837,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.552952089851,"longitude":127.011720408113,"bookYear":null,"nameKR":"원조호남순대국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":19454,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.555105,"longitude":127.011379,"bookYear":null,"nameKR":"황토집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":22481,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.561946,"longitude":127.00608,"bookYear":null,"nameKR":"별내옥설농탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":25301,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5553641615604,"longitude":127.01066001316,"bookYear":null,"nameKR":"음악의숲","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":30300,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5562786474813,"longitude":127.00993220429952,"bookYear":null,"nameKR":"커피그래","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":30310,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/je61so/","foodTypes":"바/주점","latitude":37.56251382408706,"longitude":127.00576333169165,"bookYear":null,"nameKR":"제육원소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":31404,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5624898782753,"longitude":127.018640161533,"bookYear":null,"nameKR":"언더오챠드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":31948,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/Bonesteak.official","foodTypes":"이탈리아식","latitude":37.5578300175789,"longitude":127.011552425245,"bookYear":null,"nameKR":"본스테이크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":32370,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeezip1/","foodTypes":"디저트/차/베이커리","latitude":37.5583494269282,"longitude":127.008561783455,"bookYear":null,"nameKR":"커피집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":33894,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/paori_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5525793177354,"longitude":127.012011194121,"bookYear":null,"nameKR":"파오리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":33920,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/doughdaddy.official/","foodTypes":"미국식","latitude":37.5586731461314,"longitude":127.011801547535,"bookYear":null,"nameKR":"도우대디","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":34251,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5578191509301,"longitude":127.012098389152,"bookYear":null,"nameKR":"얌팩토리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":34358,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maaddok/","foodTypes":"바/주점","latitude":37.5567791421517,"longitude":127.01105214104,"bookYear":null,"nameKR":"신당동주식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":35316,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ysd_haemulteombeong/","foodTypes":"한식(어패류)","latitude":37.5535675597109,"longitude":127.01079488285,"bookYear":null,"nameKR":"약수동해물텀벙","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":30339,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/j1_1982/","foodTypes":"한식(육류)","latitude":37.56476568946827,"longitude":127.01345119703448,"bookYear":null,"nameKR":"백송","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":31713,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/akimitsu_korea","foodTypes":"일식","latitude":37.5707451369668,"longitude":126.983611410428,"bookYear":null,"nameKR":"시타마치텐동아키미츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":32633,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeonhaejang","foodTypes":"한식(육류)","latitude":37.5648358776941,"longitude":127.021877683863,"bookYear":null,"nameKR":"연해장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":33372,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/___kee.bar/","foodTypes":"기타","latitude":37.5575886885479,"longitude":127.014394252331,"bookYear":null,"nameKR":"언더바키","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":33823,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5573070007124,"longitude":127.012324092809,"bookYear":null,"nameKR":"호박식당(약수본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":34239,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wooyukmi/","foodTypes":"한식(육류)","latitude":37.5652171404003,"longitude":127.012988112353,"bookYear":null,"nameKR":"우육미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":34302,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakery_ten/","foodTypes":"디저트/차/베이커리","latitude":37.5634363491457,"longitude":127.011867154127,"bookYear":null,"nameKR":"베이커리텐","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":35746,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mimyuso/","foodTypes":"아시아식","latitude":37.565024633949,"longitude":127.017578751549,"bookYear":null,"nameKR":"미면소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":1233,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.56317,"longitude":127.015085,"bookYear":null,"nameKR":"원조마복림할머니","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":6933,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.56998,"longitude":126.9944,"bookYear":null,"nameKR":"부산횟집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7838,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.569922,"longitude":126.989979,"bookYear":null,"nameKR":"함경도왕순대","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7970,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.571793,"longitude":126.98719,"bookYear":null,"nameKR":"지유명차(인사동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8593,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.57248,"longitude":126.986764,"bookYear":null,"nameKR":"바람부는섬","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8635,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.570724,"longitude":126.991926,"bookYear":null,"nameKR":"카페피디","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":15313,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5575135727876,"longitude":127.013395681773,"bookYear":null,"nameKR":"만다리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":22732,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5648566641944,"longitude":127.015838905623,"bookYear":null,"nameKR":"진성한우곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":25872,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.561656,"longitude":127.017024,"bookYear":null,"nameKR":"바오쯔","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"15. 장충동/약수동/신당동/황학동","status":"ACTIVE"},
        {"id":524,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.576564,"longitude":126.989335,"bookYear":null,"nameKR":"송죽헌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":736,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.573012,"longitude":126.985957,"bookYear":null,"nameKR":"작은인디아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":1406,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57567,"longitude":126.99,"bookYear":null,"nameKR":"목포집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":3934,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.574863,"longitude":126.991024,"bookYear":null,"nameKR":"샤또쇼콜라(인사점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":6045,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.573817,"longitude":126.991374,"bookYear":null,"nameKR":"돈화문칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":28636,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.574024,"longitude":126.990278,"bookYear":null,"nameKR":"익선동121","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":28637,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/turtle_supermarket/","foodTypes":"바/주점","latitude":37.57341,"longitude":126.989444,"bookYear":null,"nameKR":"거북이슈퍼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":28638,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/craftroo/","foodTypes":"바/주점","latitude":37.573029,"longitude":126.98944,"bookYear":null,"nameKR":"크래프트루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":29081,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jongrosteak/","foodTypes":"기타","latitude":37.573824,"longitude":126.99018,"bookYear":null,"nameKR":"종로스테이크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":29109,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57250034620758,"longitude":126.99222548176529,"bookYear":null,"nameKR":"맛나분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":30372,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.57329304128908,"longitude":126.98984819183319,"bookYear":null,"nameKR":"카페온화","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":32120,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yangbandeck","foodTypes":"한식(일반한식)","latitude":37.5722603642929,"longitude":126.985887506167,"bookYear":null,"nameKR":"양반댁","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":32768,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/songam_official","foodTypes":"일식","latitude":37.5740007894615,"longitude":126.99004450024,"bookYear":null,"nameKR":"송암여관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":32956,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/s__bangkok/","foodTypes":"아시아식","latitude":37.5738000366714,"longitude":126.989913213659,"bookYear":null,"nameKR":"살라댕방콕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":33647,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.572004302821,"longitude":126.985898647928,"bookYear":null,"nameKR":"종가집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":35062,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/langman_gungdduk/","foodTypes":"한식(일반한식)","latitude":37.5742147690792,"longitude":126.98996602294,"bookYear":null,"nameKR":"랑만궁떡","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":29931,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.57211447239555,"longitude":126.98909218533315,"bookYear":null,"nameKR":"종로진낙지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":30235,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mil_toasthouse/","foodTypes":"디저트/차/베이커리","latitude":37.573109283063836,"longitude":126.99038252115574,"bookYear":null,"nameKR":"밀토스트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":30237,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.57415453581091,"longitude":126.99168194182116,"bookYear":null,"nameKR":"살롱순라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":30277,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bangida_ikseon","foodTypes":"한식(일반한식)","latitude":37.57364618083848,"longitude":126.98927761139656,"bookYear":null,"nameKR":"반기다","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":34451,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5764815009796,"longitude":126.991218220958,"bookYear":null,"nameKR":"대성성","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":35105,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5739649854418,"longitude":126.987760327654,"bookYear":null,"nameKR":"라쿠엔","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":36164,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/moowee.nakwon","foodTypes":"바/주점","latitude":37.5734548144674,"longitude":126.989332201524,"bookYear":null,"nameKR":"무위낙원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":509,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5724935312538,"longitude":126.988259663514,"bookYear":null,"nameKR":"소문난집추어탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":3821,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.572823,"longitude":126.990812,"bookYear":null,"nameKR":"종로할머니칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":3969,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.572967,"longitude":126.987318,"bookYear":null,"nameKR":"하누소(인사점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7914,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.57346,"longitude":126.987984,"bookYear":null,"nameKR":"옛날집낙원아구찜","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8600,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5736420239623,"longitude":126.988204014785,"bookYear":null,"nameKR":"솔밭갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8637,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.572818,"longitude":126.9908,"bookYear":null,"nameKR":"할머니손칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":28962,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.572963,"longitude":126.988407,"bookYear":null,"nameKR":"천년동안도","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":29761,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.57315955071821,"longitude":126.98829963181602,"bookYear":null,"nameKR":"여진곱","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":658,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5740294853002,"longitude":126.986034334242,"bookYear":null,"nameKR":"오수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":724,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.574472,"longitude":126.984615,"bookYear":null,"nameKR":"인사동그집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":926,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.573861,"longitude":126.986243,"bookYear":null,"nameKR":"풍류사랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":3970,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.57326,"longitude":126.9861,"bookYear":null,"nameKR":"반짝반짝빛나는","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":4088,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5747289368132,"longitude":126.984657547638,"bookYear":null,"nameKR":"인사동사람들","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":6486,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57357,"longitude":126.98631,"bookYear":null,"nameKR":"두부마을","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":6494,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.57431,"longitude":126.985709,"bookYear":null,"nameKR":"한과채","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7053,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.57386,"longitude":126.985848,"bookYear":null,"nameKR":"사동면옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7115,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.574196,"longitude":126.984297,"bookYear":null,"nameKR":"오설록(인사동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":7580,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.574822,"longitude":126.98482,"bookYear":null,"nameKR":"여자만","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8561,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.574774,"longitude":126.985051,"bookYear":null,"nameKR":"흐린세상건너기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8590,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5738704518405,"longitude":126.985967914817,"bookYear":null,"nameKR":"뜰아래채","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8616,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.573722,"longitude":126.985194,"bookYear":null,"nameKR":"인사면옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8633,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.573205,"longitude":126.985714,"bookYear":null,"nameKR":"풍경이있는전통찻집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":9163,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.574158,"longitude":126.984417,"bookYear":null,"nameKR":"도채비도반한찻집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":12916,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5736388447904,"longitude":126.984138858434,"bookYear":null,"nameKR":"안동국시소람","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":24577,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5743481763834,"longitude":126.985366722799,"bookYear":null,"nameKR":"늘마중","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":31204,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hanok.cafe_insadong/","foodTypes":"디저트/차/베이커리","latitude":37.5746006533897,"longitude":126.984798397663,"bookYear":null,"nameKR":"한옥찻집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":32134,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eumeum_vin","foodTypes":"바/주점","latitude":37.5741386598447,"longitude":126.985816061097,"bookYear":null,"nameKR":"음음","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":30429,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.573584560758455,"longitude":126.98592234853693,"bookYear":null,"nameKR":"태극당(인사점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":31507,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gwanhunmansion_2/","foodTypes":"기타","latitude":37.5737207927767,"longitude":126.985935793813,"bookYear":null,"nameKR":"관훈맨션","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":36035,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5689537143861,"longitude":126.98591190076,"bookYear":null,"nameKR":"에도마에텐동하마다(종로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":36083,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/okagura109/","foodTypes":"일식","latitude":37.5687081516714,"longitude":126.987982028258,"bookYear":null,"nameKR":"오카구라라멘&이자카야","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":211,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.56888,"longitude":126.9864,"bookYear":null,"nameKR":"된장예술과술","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":1165,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57481,"longitude":126.9844,"bookYear":null,"nameKR":"선천","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8591,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.575128790108,"longitude":126.984595090675,"bookYear":null,"nameKR":"로댕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8877,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.569534,"longitude":126.988473,"bookYear":null,"nameKR":"뚝배기집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":12411,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.569029,"longitude":126.986171,"bookYear":null,"nameKR":"반쥴","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":12921,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5687081516714,"longitude":126.987982028258,"bookYear":null,"nameKR":"청진식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":16251,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.569349,"longitude":126.983609,"bookYear":null,"nameKR":"백세주마을(종각점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":16404,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5693133701884,"longitude":126.986497953285,"bookYear":null,"nameKR":"종로양꼬치","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":20433,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5687441175576,"longitude":126.988141851664,"bookYear":null,"nameKR":"미당순두부","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":29059,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/doma_insa/","foodTypes":"한식(일반한식)","latitude":37.573795,"longitude":126.985876,"bookYear":null,"nameKR":"도마(인사동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":4962,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.56833,"longitude":126.9889,"bookYear":null,"nameKR":"대련집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":4993,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5695862591941,"longitude":126.988873295811,"bookYear":null,"nameKR":"소문난경북집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":4994,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.569837,"longitude":126.990966,"bookYear":null,"nameKR":"삼해집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":8874,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.569429,"longitude":126.989288,"bookYear":null,"nameKR":"서호장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":13327,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.574842120935,"longitude":126.986277228687,"bookYear":null,"nameKR":"간판없는김치찌개집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":20431,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5697571674595,"longitude":126.98876166011,"bookYear":null,"nameKR":"수정식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":30174,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5687877822001,"longitude":126.989703802396,"bookYear":null,"nameKR":"국일영양족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":31793,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5686313893591,"longitude":126.987150409394,"bookYear":null,"nameKR":"31금룡","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":32083,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistro_la_comma","foodTypes":"기타","latitude":37.5686313893591,"longitude":126.987150409394,"bookYear":null,"nameKR":"비스트로라꼼마","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":32419,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tiger_hamburg/","foodTypes":"기타","latitude":37.5714966636936,"longitude":126.983659015157,"bookYear":null,"nameKR":"타이거함바그","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":32855,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5714966636936,"longitude":126.983659015157,"bookYear":null,"nameKR":"어반밀커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"13. 인사동/익선동/종로3가","status":"ACTIVE"},
        {"id":30460,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.561807293985616,"longitude":126.99173311525246,"bookYear":null,"nameKR":"필동분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":1519,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.56238,"longitude":126.9951,"bookYear":null,"nameKR":"서대문곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":7936,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.561354,"longitude":126.996641,"bookYear":null,"nameKR":"홍탁집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":16106,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.560701,"longitude":126.996851,"bookYear":null,"nameKR":"충무칼국수보쌈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":25413,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.560727,"longitude":126.993121,"bookYear":null,"nameKR":"꼬치장인","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":523,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5630423102761,"longitude":126.992028164868,"bookYear":null,"nameKR":"송죽","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":903,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.563374,"longitude":126.991889,"bookYear":null,"nameKR":"충무로파주옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":1011,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5653760401311,"longitude":126.992961493923,"bookYear":null,"nameKR":"동경우동","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":1255,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.5625741602786,"longitude":126.990890374608,"bookYear":null,"nameKR":"장추","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":3962,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5620999674191,"longitude":126.995667451816,"bookYear":null,"nameKR":"충무로청국장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":5120,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.56253,"longitude":126.9932,"bookYear":null,"nameKR":"황소집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":5632,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5640262424426,"longitude":126.992710347252,"bookYear":null,"nameKR":"제일옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":5644,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5635143211848,"longitude":126.991721155332,"bookYear":null,"nameKR":"화로백서","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8526,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.563497,"longitude":126.992009,"bookYear":null,"nameKR":"본가닭한마리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8529,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5625655656109,"longitude":126.99475866951,"bookYear":null,"nameKR":"신가네갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":17579,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5633404921113,"longitude":126.991346531232,"bookYear":null,"nameKR":"우정집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":17874,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.562376666061844,"longitude":126.99094529281653,"bookYear":null,"nameKR":"청록","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":24983,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5628689946975,"longitude":126.990122494488,"bookYear":null,"nameKR":"원더브레드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29511,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baekdugangsan","foodTypes":"디저트/차/베이커리","latitude":37.56310105217163,"longitude":126.9907505270571,"bookYear":null,"nameKR":"백두강산","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29760,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56222196186367,"longitude":126.99565374953539,"bookYear":null,"nameKR":"호남식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":30075,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.565025588413796,"longitude":126.99085442193153,"bookYear":null,"nameKR":"유카네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":30461,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.56241655662647,"longitude":126.99512856493423,"bookYear":null,"nameKR":"충무로구룡포","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":31578,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5628492647678,"longitude":126.992613010031,"bookYear":null,"nameKR":"강릉장칼국수&보쌈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":32024,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5651150345539,"longitude":126.99318314055,"bookYear":null,"nameKR":"농가순대국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":33074,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5631515399823,"longitude":126.991168626249,"bookYear":null,"nameKR":"해든","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":35576,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/luft_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5621114535549,"longitude":126.989281754738,"bookYear":null,"nameKR":"루프트커피명동","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29938,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.56462581159236,"longitude":126.9954227079087,"bookYear":null,"nameKR":"용강식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":30257,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/euljisowal/","foodTypes":"디저트/차/베이커리","latitude":37.56748385823924,"longitude":127.00058859614907,"bookYear":null,"nameKR":"을지소월","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":30709,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5676141055658,"longitude":126.99146624652,"bookYear":null,"nameKR":"전주집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":31300,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/standingbar_denki/","foodTypes":"바/주점","latitude":37.5650851033938,"longitude":126.990341333737,"bookYear":null,"nameKR":"스탠딩바전기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":31356,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/otrec_seoul/","foodTypes":"바/주점","latitude":37.5637714862625,"longitude":126.995406574589,"bookYear":null,"nameKR":"오트렉","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":31485,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sexy_pigk","foodTypes":"바/주점","latitude":37.5634989049525,"longitude":126.990409110289,"bookYear":null,"nameKR":"고씨네고추장찌개","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":32176,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hoikwan_grillbar/","foodTypes":"한식(육류)","latitude":37.56389778073,"longitude":126.990466897272,"bookYear":null,"nameKR":"을지로회관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":35827,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5638703421254,"longitude":126.993798080859,"bookYear":null,"nameKR":"페이퍼파스타by을지로그랑블루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":5621,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5649540028292,"longitude":126.990272872081,"bookYear":null,"nameKR":"우진골뱅이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":6489,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5669486979331,"longitude":126.988088144293,"bookYear":null,"nameKR":"사프란","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8540,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5651986956145,"longitude":126.990059480679,"bookYear":null,"nameKR":"충무칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29510,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.56442724906243,"longitude":126.98989919728942,"bookYear":null,"nameKR":"MWM","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":924,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.565636,"longitude":126.989958,"bookYear":null,"nameKR":"풍남원조골뱅이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":1418,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.566333,"longitude":126.984916,"bookYear":null,"nameKR":"동표골뱅이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":18051,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5655839569164,"longitude":126.99000453297,"bookYear":null,"nameKR":"동경우동","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":22168,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5672448973996,"longitude":126.993155440997,"bookYear":null,"nameKR":"통일집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":22379,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5669500459178,"longitude":126.984906351855,"bookYear":null,"nameKR":"잇츠크리스피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":22380,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5661527189537,"longitude":126.986869332754,"bookYear":null,"nameKR":"그라츠과자점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29136,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.567252918775694,"longitude":126.99178231760422,"bookYear":null,"nameKR":"만선호프","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29352,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bunkasha","foodTypes":"디저트/차/베이커리","latitude":37.565328408438184,"longitude":126.9920315432867,"bookYear":null,"nameKR":"분카샤","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29473,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brasseriedeulji/","foodTypes":"바/주점","latitude":37.56545818415578,"longitude":126.99252503133098,"bookYear":null,"nameKR":"을지식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29512,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeesa_maria","foodTypes":"디저트/차/베이커리","latitude":37.56614301413976,"longitude":126.99378363056337,"bookYear":null,"nameKR":"커피사마리아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29516,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hotel_soosunhwa/","foodTypes":"디저트/차/베이커리","latitude":37.56569959350796,"longitude":126.99166477181946,"bookYear":null,"nameKR":"호텔수선화","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":31108,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5661527189537,"longitude":126.986869332754,"bookYear":null,"nameKR":"블루노트커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":31215,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jangmanok_euljiro/","foodTypes":"중식","latitude":37.5657437884452,"longitude":126.991063733657,"bookYear":null,"nameKR":"장만옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":31386,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5660209440528,"longitude":126.990052466867,"bookYear":null,"nameKR":"청와옥(을지로3가 직영점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":31443,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/le_temple_euljiro/","foodTypes":"바/주점","latitude":37.565903810095,"longitude":126.992419720869,"bookYear":null,"nameKR":"르템플","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":31486,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi_soukai/","foodTypes":"일식","latitude":37.5661717265953,"longitude":127.001736441373,"bookYear":null,"nameKR":"스시소우카이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":33753,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5653805875119,"longitude":126.992202339001,"bookYear":null,"nameKR":"밀란","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":33903,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5656706236898,"longitude":126.991012694237,"bookYear":null,"nameKR":"다케오호르몬데판야끼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":34330,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/backdon_euljiro/","foodTypes":"한식(육류)","latitude":37.5652432645115,"longitude":126.992034721625,"bookYear":null,"nameKR":"빽돈(을지로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":34397,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/torikami_toriniku/","foodTypes":"일식","latitude":37.5653282801432,"longitude":126.991484504222,"bookYear":null,"nameKR":"토리카미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":34653,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ctwon05_euljiro/","foodTypes":"한식(육류)","latitude":37.5658531919427,"longitude":126.98369058268,"bookYear":null,"nameKR":"청기와타운(을지로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":36317,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5671123565063,"longitude":126.997689318825,"bookYear":null,"nameKR":"20어클락모먼트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":36416,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/artmonster.euljiro_","foodTypes":"바/주점","latitude":37.5657709109319,"longitude":126.989973041455,"bookYear":null,"nameKR":"아트몬스터(을지로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":30508,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.564333967380314,"longitude":127.00154839543892,"bookYear":null,"nameKR":"낙원정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":30688,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hyemindang_/","foodTypes":"디저트/차/베이커리","latitude":37.56647779917071,"longitude":126.98861308151253,"bookYear":null,"nameKR":"혜민당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":34156,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5671458986322,"longitude":126.995451191045,"bookYear":null,"nameKR":"악어지로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":34272,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/keum_eun_don/","foodTypes":"한식(육류)","latitude":37.5241921296678,"longitude":126.964919927515,"bookYear":null,"nameKR":"금은돈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":34608,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwayangyongsan/","foodTypes":"한식(육류)","latitude":37.5242647780997,"longitude":126.965013109515,"bookYear":null,"nameKR":"화양연화","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":35438,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/concede_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5269056148241,"longitude":126.966566172294,"bookYear":null,"nameKR":"콘시드서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":1164,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.566835,"longitude":126.994807,"bookYear":null,"nameKR":"석산정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":7361,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.567358,"longitude":126.985136,"bookYear":null,"nameKR":"차이797(청계천점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":7611,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.566921,"longitude":126.983964,"bookYear":null,"nameKR":"한일관(을지로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":12419,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.566921,"longitude":126.983964,"bookYear":null,"nameKR":"몽중헌(페럼타워점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":13565,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.566841,"longitude":126.989428,"bookYear":null,"nameKR":"을지로 전주옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":25881,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/agrafood/ , http://agra.co.kr/","foodTypes":"아시아식","latitude":37.567358,"longitude":126.985136,"bookYear":null,"nameKR":"아그라(센터원점) ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29160,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.56653,"longitude":126.988594,"bookYear":null,"nameKR":"커피한약방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":29194,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dgsangsa/","foodTypes":"디저트/차/베이커리","latitude":37.53937371518631,"longitude":126.96168320287484,"bookYear":null,"nameKR":"다과상사","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":29455,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seendosi/","foodTypes":"바/주점","latitude":37.567811458018426,"longitude":126.99072730622134,"bookYear":null,"nameKR":"신도시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"16. 을지로/충무로","status":"ACTIVE"},
        {"id":8406,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.524613,"longitude":126.965218,"bookYear":null,"nameKR":"만미정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":13735,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.524592,"longitude":126.9623,"bookYear":null,"nameKR":"해정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":23277,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5334,"longitude":126.960695,"bookYear":null,"nameKR":"면사무소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":30698,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lobsterbar_official","foodTypes":"기타","latitude":37.52977360382622,"longitude":126.96474274711633,"bookYear":null,"nameKR":"랍스터바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32984,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thechambercoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5257540957943,"longitude":126.964280698976,"bookYear":null,"nameKR":"더로스팅체임버","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":33557,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/burgerboy_seoul/","foodTypes":"기타","latitude":37.5263254363791,"longitude":126.962869137997,"bookYear":null,"nameKR":"버거보이(신용산점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":33939,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yongsan_maru/","foodTypes":"일식","latitude":37.5254724713354,"longitude":126.962626787947,"bookYear":null,"nameKR":"용산마루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":33951,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/de.fore_station/","foodTypes":"바/주점","latitude":37.5260707831307,"longitude":126.963935165268,"bookYear":null,"nameKR":"드포레와인&비스트로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":34425,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5247746664463,"longitude":126.961848143516,"bookYear":null,"nameKR":"퍼멘츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":35805,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5297718014452,"longitude":126.964741503485,"bookYear":null,"nameKR":"시그니처랩용산아이파크몰점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":35806,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5297718014452,"longitude":126.964741503485,"bookYear":null,"nameKR":"라멘짱(용산아이파크몰점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36070,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hithai_seoul/","foodTypes":"아시아식","latitude":37.5247983262678,"longitude":126.962014315086,"bookYear":null,"nameKR":"하이타이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36285,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5253454026053,"longitude":126.962825390738,"bookYear":null,"nameKR":"삼층로비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":30256,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/alotbylnm","foodTypes":"디저트/차/베이커리","latitude":37.528790818711556,"longitude":126.96867571514015,"bookYear":null,"nameKR":"에이랏","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":30994,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5270404595814,"longitude":126.964892234354,"bookYear":null,"nameKR":"스시우미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":30995,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5330751959489,"longitude":126.971256672775,"bookYear":null,"nameKR":"김용안과자점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31026,"bookStatus":"비활성","websiteInstagram":"https://www.instragram.com/dasti.the.hidden","foodTypes":"이탈리아식","latitude":37.5348873710921,"longitude":126.972743119385,"bookYear":null,"nameKR":"다스티더히든","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31041,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistro_august","foodTypes":"일식","latitude":37.5351455043256,"longitude":126.973121035092,"bookYear":null,"nameKR":"돈카츠팔월","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31912,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gogagil9gongtan","foodTypes":"한식(육류)","latitude":37.5360706883424,"longitude":126.972112928827,"bookYear":null,"nameKR":"고가길구공탄","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32725,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/samsamsam_official/","foodTypes":"이탈리아식","latitude":37.5315767768856,"longitude":126.972158951911,"bookYear":null,"nameKR":"쌤쌤쌤","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32934,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maaddok/","foodTypes":"바/주점","latitude":37.5325680108282,"longitude":126.972339944908,"bookYear":null,"nameKR":"주식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":33193,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wuxinpuer_shop/","foodTypes":"디저트/차/베이커리","latitude":37.5309087103342,"longitude":126.970412715661,"bookYear":null,"nameKR":"무심헌(용산점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":33397,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.530214258854,"longitude":126.971680104428,"bookYear":null,"nameKR":"아이돌런치박스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":34168,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/chimpsburger","foodTypes":"기타","latitude":37.530214258854,"longitude":126.971680104428,"bookYear":null,"nameKR":"침스버거","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":34273,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/elephantk/","foodTypes":"일식","latitude":37.5288173852679,"longitude":126.966653789116,"bookYear":null,"nameKR":"스시이젠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":34390,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dohwa_apt/","foodTypes":"디저트/차/베이커리","latitude":37.5300748352493,"longitude":126.971148083196,"bookYear":null,"nameKR":"도화아파트먼트용산","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36535,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/teddy.beurre.house","foodTypes":"디저트/차/베이커리","latitude":37.5315987586666,"longitude":126.972532410003,"bookYear":null,"nameKR":"테디뵈르하우스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36674,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.5312872220554,"longitude":126.971516442289,"bookYear":null,"nameKR":"용산삼계탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":14133,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hyunsunine_/","foodTypes":"한식(일반한식)","latitude":37.5305,"longitude":126.9685,"bookYear":null,"nameKR":"현선이네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":29572,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/whatasalad/","foodTypes":"기타","latitude":37.5287911785354,"longitude":126.968673565462,"bookYear":null,"nameKR":"왓어샐러드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":29648,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.52977861102602,"longitude":126.96980664767094,"bookYear":null,"nameKR":"자갈치식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":676,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.544406,"longitude":126.968539,"bookYear":null,"nameKR":"와플하우스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":916,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.534847,"longitude":126.973965,"bookYear":null,"nameKR":"평양집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":1232,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.535831,"longitude":126.963189,"bookYear":null,"nameKR":"원복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":6604,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/benares2007/","foodTypes":"아시아식","latitude":37.5446429004926,"longitude":126.968901281857,"bookYear":null,"nameKR":"베나레스(숙대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":7315,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.544449,"longitude":126.96955,"bookYear":null,"nameKR":"동아냉면(숙대2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":7811,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dal_bbokki/","foodTypes":"한식(일반한식)","latitude":37.545155,"longitude":126.965636,"bookYear":null,"nameKR":"달볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":7906,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.534666,"longitude":126.97409,"bookYear":null,"nameKR":"자원원조대구탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":29087,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.53459097285298,"longitude":126.97408146883666,"bookYear":null,"nameKR":"작은수산시장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":29099,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.54553,"longitude":126.970063,"bookYear":null,"nameKR":"쌍대포소금구이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":29874,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":37.53179756935927,"longitude":126.97274741169277,"bookYear":null,"nameKR":"카키바움","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":29884,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/1__hyolim/","foodTypes":"바/주점","latitude":37.5382141856636,"longitude":126.96476140283194,"bookYear":null,"nameKR":"원효림","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":34087,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/h._.245/","foodTypes":"바/주점","latitude":37.5329621717645,"longitude":126.9718442489,"bookYear":null,"nameKR":"h'245","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":35178,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_cafetilde/","foodTypes":"디저트/차/베이커리","latitude":37.5441065383456,"longitude":126.968571314841,"bookYear":null,"nameKR":"카페틸데","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":35262,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/saignee_cucinaebar/","foodTypes":"이탈리아식","latitude":37.5399456477195,"longitude":126.967629617358,"bookYear":null,"nameKR":"쎄니에","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36064,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/riverhead.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5454861959687,"longitude":126.970143522776,"bookYear":null,"nameKR":"리버헤드(용산점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":36595,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.545672267803,"longitude":126.96714802792,"bookYear":null,"nameKR":"청파맨션","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32020,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5384913860279,"longitude":126.968452716825,"bookYear":null,"nameKR":"히비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32063,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/webakelove.seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5395025797618,"longitude":126.964942853654,"bookYear":null,"nameKR":"위베이크러브","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32462,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goodnews_cnm/","foodTypes":"디저트/차/베이커리","latitude":37.5410302302159,"longitude":126.968815882022,"bookYear":null,"nameKR":"굿뉴스카페앤모어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32630,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rucky_official_/","foodTypes":"디저트/차/베이커리","latitude":37.5364151116975,"longitude":126.953669441386,"bookYear":null,"nameKR":"락희","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":33900,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5407385277351,"longitude":126.969317376118,"bookYear":null,"nameKR":"큰집닭한마리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":34580,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/izakaya_yaruki/","foodTypes":"일식","latitude":37.5436251810849,"longitude":126.973540513584,"bookYear":null,"nameKR":"야루키","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":35616,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leda_theriver/","foodTypes":"프랑스식","latitude":37.5080315126454,"longitude":126.984551546966,"bookYear":null,"nameKR":"르다","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":15204,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5362213778415,"longitude":126.960029288589,"bookYear":null,"nameKR":"국빈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":20241,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.5346826858037,"longitude":126.974454013604,"bookYear":null,"nameKR":"솔뫼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":29863,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.53603602296491,"longitude":126.96050821297158,"bookYear":null,"nameKR":"삽다리순대국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":1262,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.565849,"longitude":127.036006,"bookYear":null,"nameKR":"진국설렁탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":4144,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5443189121034,"longitude":126.971441441072,"bookYear":null,"nameKR":"인마이메모리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":9090,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5445485764949,"longitude":126.97297123445,"bookYear":null,"nameKR":"털보스테이크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":18103,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5598426898639,"longitude":127.040736086668,"bookYear":null,"nameKR":"황소곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":24649,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.56,"longitude":127.0409,"bookYear":null,"nameKR":"이가갈비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":25844,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.561883,"longitude":127.031926,"bookYear":null,"nameKR":"토상막회","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":30173,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5417114040155,"longitude":126.972130856033,"bookYear":null,"nameKR":"진대포","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":31438,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sympathyseoul/","foodTypes":"이탈리아식","latitude":37.5611237377333,"longitude":127.04096586437,"bookYear":null,"nameKR":"심파시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":32263,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yangmoon2021/","foodTypes":"한식(육류)","latitude":37.543114294874,"longitude":126.971896103581,"bookYear":null,"nameKR":"양문","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"22. 용산/삼각지/숙대입구","status":"ACTIVE"},
        {"id":32644,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5606485083606,"longitude":127.041091574869,"bookYear":null,"nameKR":"악어떡볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":32693,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/donchelin__/","foodTypes":"한식(육류)","latitude":37.5587341178433,"longitude":127.033208219189,"bookYear":null,"nameKR":"돈슐랭","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":35667,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5623145866882,"longitude":127.030547726751,"bookYear":null,"nameKR":"스멜비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":35788,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nerdyblue.burrow","foodTypes":"디저트/차/베이커리","latitude":37.5597052141074,"longitude":127.035970463493,"bookYear":null,"nameKR":"너디블루버로우","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":31659,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/inthemass_","foodTypes":"디저트/차/베이커리","latitude":37.5655440076255,"longitude":127.039967923778,"bookYear":null,"nameKR":"인더매스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":31869,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/verdone_official","foodTypes":"기타","latitude":37.563316517552,"longitude":127.067096898311,"bookYear":null,"nameKR":"베르도네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":32123,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/konban_lab","foodTypes":"아시아식","latitude":37.5706170137502,"longitude":127.0678881236,"bookYear":null,"nameKR":"콘반","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":32487,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5668988918932,"longitude":127.053284511547,"bookYear":null,"nameKR":"곽만근갈비탕이야기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":32689,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5678250213491,"longitude":127.025053595952,"bookYear":null,"nameKR":"73명품도야지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":32878,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/JJ.PORK","foodTypes":"한식(육류)","latitude":37.5651958543852,"longitude":127.063775155288,"bookYear":null,"nameKR":"압구정제주집(장안점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":32883,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5634862032153,"longitude":127.065278951825,"bookYear":null,"nameKR":"영동교집(장안직영점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":33513,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5670162325977,"longitude":127.056886893804,"bookYear":null,"nameKR":"램반장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":35534,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rillsseoul/","foodTypes":"기타","latitude":37.569533166927,"longitude":127.029562937984,"bookYear":null,"nameKR":"릴즈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":36511,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mangam__official","foodTypes":"일식","latitude":37.5735804223934,"longitude":127.067194742019,"bookYear":null,"nameKR":"만감","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":36664,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5663484302989,"longitude":127.036299983961,"bookYear":null,"nameKR":"왕십리누룽지통닭구이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":1256,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.57125722424951,"longitude":127.0412671985654,"bookYear":null,"nameKR":"전봇대집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":7207,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.57175,"longitude":127.0749,"bookYear":null,"nameKR":"남원추어탕(장안점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":7916,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.56773,"longitude":127.045,"bookYear":null,"nameKR":"목포산꽃게","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":9079,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.570433,"longitude":127.041435,"bookYear":null,"nameKR":"한우리광주집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":9080,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5712588467467,"longitude":127.041265161909,"bookYear":null,"nameKR":"호남집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":13792,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.56798,"longitude":127.0679,"bookYear":null,"nameKR":"유명닭한마리 칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":14307,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.570643,"longitude":127.041261,"bookYear":null,"nameKR":"마장동한우촌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":22781,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.57089,"longitude":127.041813,"bookYear":null,"nameKR":"변산반도고향집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":24177,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5712588467467,"longitude":127.041265161909,"bookYear":null,"nameKR":"경기집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":24911,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5668325399007,"longitude":127.068551041724,"bookYear":null,"nameKR":"북배산흑염소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":29819,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.56663513528839,"longitude":127.06838956470028,"bookYear":null,"nameKR":"진미기사식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":3839,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.565788,"longitude":126.92891,"bookYear":null,"nameKR":"영월","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":4091,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.57189,"longitude":126.934353,"bookYear":null,"nameKR":"수빈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":6570,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5667428750097,"longitude":126.928090227808,"bookYear":null,"nameKR":"사모님돈가스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":26320,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.566014,"longitude":126.925475,"bookYear":null,"nameKR":"스시준","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":26833,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5720054760276,"longitude":126.934232067128,"bookYear":null,"nameKR":"강성은명과","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":27725,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/blacktearoom/","foodTypes":"디저트/차/베이커리","latitude":37.56831,"longitude":126.9326,"bookYear":null,"nameKR":"시간이머무는홍차가게","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":27814,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/noahs_roasting/","foodTypes":"디저트/차/베이커리","latitude":37.567169,"longitude":126.928682,"bookYear":null,"nameKR":"노아스로스팅","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":29055,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.572533,"longitude":126.935079,"bookYear":null,"nameKR":"우동카덴(연희동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":29906,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.566573804098894,"longitude":126.92554802601254,"bookYear":null,"nameKR":"곳간","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":31944,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/olemus.kr","foodTypes":"디저트/차/베이커리","latitude":37.5654091289442,"longitude":126.926045176736,"bookYear":null,"nameKR":"올레무스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":33634,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/nido_roastery","foodTypes":"디저트/차/베이커리","latitude":37.5740520622408,"longitude":126.935826521544,"bookYear":null,"nameKR":"니도로스터리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":33926,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/heybunsik/","foodTypes":"한식(일반한식)","latitude":37.5699078614451,"longitude":127.06331009179,"bookYear":null,"nameKR":"분식창고","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":34210,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sunjoo_yh/","foodTypes":"디저트/차/베이커리","latitude":37.5706171509481,"longitude":126.932666085304,"bookYear":null,"nameKR":"까사드선주","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":35527,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bonnoel2020/","foodTypes":"디저트/차/베이커리","latitude":37.5717848917026,"longitude":127.056216426234,"bookYear":null,"nameKR":"본노엘(답십리점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"29. 왕십리/마장동/장안동","status":"ACTIVE"},
        {"id":35621,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/meno_pastaria/","foodTypes":"이탈리아식","latitude":37.5736874366603,"longitude":126.936171757685,"bookYear":null,"nameKR":"메노","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":35639,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/beau_yeonhee","foodTypes":"디저트/차/베이커리","latitude":37.5670972877285,"longitude":126.928769032864,"bookYear":null,"nameKR":"보연희","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":36114,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5729910326712,"longitude":126.936963392828,"bookYear":null,"nameKR":"유우(연희동본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":31196,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/frisson_by_sweetmoment/","foodTypes":"디저트/차/베이커리","latitude":37.5686817171518,"longitude":126.929870480129,"bookYear":null,"nameKR":"프리쏭바이스위트모먼트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":31582,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chocolatique_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5673852327796,"longitude":126.928896664947,"bookYear":null,"nameKR":"쇼콜라티끄","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":31802,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sway_coffee_station","foodTypes":"디저트/차/베이커리","latitude":37.5689739354649,"longitude":126.928560553892,"bookYear":null,"nameKR":"스웨이커피스테이션","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":36352,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5667428750097,"longitude":126.928090227808,"bookYear":null,"nameKR":"하이파이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":626,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.56815,"longitude":126.9307,"bookYear":null,"nameKR":"연희동칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":1247,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.56753,"longitude":126.9301,"bookYear":null,"nameKR":"이품","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":27336,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.567516349939154,"longitude":126.92863495454128,"bookYear":null,"nameKR":"카페129-11","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":27700,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.56759,"longitude":126.93,"bookYear":null,"nameKR":"독일빵집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":27780,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.5692068116788,"longitude":126.931394373469,"bookYear":null,"nameKR":"백년추어탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":27918,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.56785,"longitude":126.929499,"bookYear":null,"nameKR":"밀스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":28023,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":0,"longitude":0,"bookYear":null,"nameKR":"뱅센느","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":28221,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.56697,"longitude":126.92889,"bookYear":null,"nameKR":"제니스까페","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":28296,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.568103,"longitude":126.929242,"bookYear":null,"nameKR":"히메지(연희점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":29308,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chaegbar/","foodTypes":"바/주점","latitude":37.566823,"longitude":126.930344,"bookYear":null,"nameKR":"책바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":29342,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thursday_stuffing/","foodTypes":"기타","latitude":37.568653,"longitude":126.930776,"bookYear":null,"nameKR":"써스데이스터핑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"05. 연희동","status":"ACTIVE"},
        {"id":520,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.558027,"longitude":126.935277,"bookYear":null,"nameKR":"송아저씨빈대떡","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":677,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.55865,"longitude":126.9394,"bookYear":null,"nameKR":"완차이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":4775,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5584349481818,"longitude":126.934400408304,"bookYear":null,"nameKR":"하나","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":6668,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.558909,"longitude":126.940637,"bookYear":null,"nameKR":"호밀밭","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":7839,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.559245,"longitude":126.93908,"bookYear":null,"nameKR":"초당쌈밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":12867,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.557755,"longitude":126.935543,"bookYear":null,"nameKR":"술익는마을","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":12868,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/choco.blossom/","foodTypes":"디저트/차/베이커리","latitude":37.558529,"longitude":126.939171,"bookYear":null,"nameKR":"초코블로썸","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":12870,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.559015076325,"longitude":126.935172910477,"bookYear":null,"nameKR":"크리스터치킨(본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":23260,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.55883,"longitude":126.936108,"bookYear":null,"nameKR":"엔터엘피바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":25406,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/damotori6/","foodTypes":"바/주점","latitude":37.558183,"longitude":126.93552,"bookYear":null,"nameKR":"다모토리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":30562,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lovepiehole/","foodTypes":"디저트/차/베이커리","latitude":37.55704687358555,"longitude":126.93505521516568,"bookYear":null,"nameKR":"더파이홀","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":33103,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/damsan_shinchon/","foodTypes":"한식(육류)","latitude":37.5562194725128,"longitude":126.935027526438,"bookYear":null,"nameKR":"담산(신촌본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":34226,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.5558770747964,"longitude":126.936316196421,"bookYear":null,"nameKR":"커피크레페","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":34278,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5584520868522,"longitude":126.936079047663,"bookYear":null,"nameKR":"가문의우동","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":35180,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5586079052888,"longitude":126.935647819094,"bookYear":null,"nameKR":"신촌장군집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":35963,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5607064864408,"longitude":126.933018623631,"bookYear":null,"nameKR":"김사부브런치&지엔빙","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":30280,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yadam_sns","foodTypes":"기타","latitude":37.558907049483274,"longitude":126.94303948091421,"bookYear":null,"nameKR":"야담","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":30710,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5576889878101,"longitude":126.937851933962,"bookYear":null,"nameKR":"대구삼겹살","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":33720,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/neo.classique","foodTypes":"기타","latitude":37.5572712555344,"longitude":126.950563654947,"bookYear":null,"nameKR":"네오클래시끄","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":34206,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5594521467279,"longitude":126.944733248427,"bookYear":null,"nameKR":"위샐러듀","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":35250,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hansul_sinchon/","foodTypes":"한식(일반한식)","latitude":37.5578586447476,"longitude":126.938886004379,"bookYear":null,"nameKR":"한술식당(신촌점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":35279,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5633616535717,"longitude":126.9435742857,"bookYear":null,"nameKR":"옥루몽 신촌본점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":13,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.558612,"longitude":126.945947,"bookYear":null,"nameKR":"가미분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":897,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.558453141276,"longitude":126.945944197486,"bookYear":null,"nameKR":"티앙팡오후의홍차","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":1438,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5637750959193,"longitude":126.944090214952,"bookYear":null,"nameKR":"함평면옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":3568,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.557702,"longitude":126.938773,"bookYear":null,"nameKR":"클로리스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":3894,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.563887,"longitude":126.943836,"bookYear":null,"nameKR":"핀스플레이스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":3913,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lovelytono1107/","foodTypes":"한식(일반한식)","latitude":37.563876,"longitude":126.943824,"bookYear":null,"nameKR":"딸기골분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":3915,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.55824766799491,"longitude":126.94334782586938,"bookYear":null,"nameKR":"카페와플잇업","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":4853,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":37.5590661547811,"longitude":126.94414851221212,"bookYear":null,"nameKR":"벨라프라하","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":7349,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5638064365127,"longitude":126.942930940634,"bookYear":null,"nameKR":"티원(연세대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":9132,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5644200917479,"longitude":126.944413900253,"bookYear":null,"nameKR":"로드샌드위치","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":12857,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.557804,"longitude":126.943042,"bookYear":null,"nameKR":"이코노스시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":22367,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.563264,"longitude":126.943665,"bookYear":null,"nameKR":"이화당제과","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":6496,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.589581,"longitude":127.062345,"bookYear":null,"nameKR":"초록뜰","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":7930,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5900525290852,"longitude":127.054700596934,"bookYear":null,"nameKR":"안씨네쭈꾸미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":12468,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6013,"longitude":127.0744,"bookYear":null,"nameKR":"임현숙의이화김치찌개","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":12680,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5932219276401,"longitude":127.052968791173,"bookYear":null,"nameKR":"노바이탈리아노","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":13017,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.55482,"longitude":126.9325,"bookYear":null,"nameKR":"김진환제과점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":13248,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.591306,"longitude":127.049827,"bookYear":null,"nameKR":"봉평메밀","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":19725,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.546497,"longitude":126.941706,"bookYear":null,"nameKR":"토담오리숯불구이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":23486,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.552456,"longitude":126.938145,"bookYear":null,"nameKR":"홍원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":26717,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/busan_jib/","foodTypes":"바/주점","latitude":37.5555208791293,"longitude":126.930969846221,"bookYear":null,"nameKR":"부산집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":27650,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.55481,"longitude":126.93246,"bookYear":null,"nameKR":"오시리야","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":30070,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.555669555286414,"longitude":126.93398352403126,"bookYear":null,"nameKR":"여수집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":30728,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dokkokji/","foodTypes":"한식(일반한식)","latitude":37.5481350579149,"longitude":126.939904935737,"bookYear":null,"nameKR":"도꼭지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":31441,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5931571086503,"longitude":127.052447885261,"bookYear":null,"nameKR":"크로네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":32156,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kaufff.roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5515314711842,"longitude":126.937154617006,"bookYear":null,"nameKR":"카우프 로스터스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":33993,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joy__burger","foodTypes":"기타","latitude":37.5549517099319,"longitude":126.932512687268,"bookYear":null,"nameKR":"조이버거","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"04. 신촌/이대앞","status":"ACTIVE"},
        {"id":34352,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/33.soup_hoegi/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5903959972497,"longitude":127.059877891806,"bookYear":null,"nameKR":"삼삼뼈국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":35913,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_yoon_yangsik/","foodTypes":"기타","latitude":37.5901702628242,"longitude":127.061524468438,"bookYear":null,"nameKR":"윤양식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":36618,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5928009774922,"longitude":127.052174864528,"bookYear":null,"nameKR":"거복이식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":35229,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5765271462328,"longitude":127.025268657846,"bookYear":null,"nameKR":"돈부각","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":35752,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/completecoffee_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5983004756761,"longitude":127.056800748936,"bookYear":null,"nameKR":"컴플리트커피서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":35787,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nerdyblue.house/","foodTypes":"디저트/차/베이커리","latitude":37.5854270100329,"longitude":127.052929075367,"bookYear":null,"nameKR":"너디블루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":35789,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/derny_brown/","foodTypes":"디저트/차/베이커리","latitude":37.5852705591249,"longitude":127.052612179282,"bookYear":null,"nameKR":"더니브라운","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":973,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.562821,"longitude":126.976944,"bookYear":null,"nameKR":"현대칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":978,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.583423,"longitude":127.049036,"bookYear":null,"nameKR":"혜성칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":7029,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.57892,"longitude":127.0401,"bookYear":null,"nameKR":"평양냉면","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":7924,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.577080872015515,"longitude":127.03069696373399,"bookYear":null,"nameKR":"호남식당나정순할매쭈꾸미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":7960,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.571266,"longitude":127.027444,"bookYear":null,"nameKR":"신설홍어횟집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":8785,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5737694403859,"longitude":127.023683024162,"bookYear":null,"nameKR":"남화루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":9010,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5911516609857,"longitude":127.041902177003,"bookYear":null,"nameKR":"수목원맛쌈밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":13007,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.575295905211,"longitude":127.025833013506,"bookYear":null,"nameKR":"홍조보리밥집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":13292,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.58283,"longitude":127.042,"bookYear":null,"nameKR":"정인보리밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":23383,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.582763,"longitude":127.040135,"bookYear":null,"nameKR":"홍릉각","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":23389,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.57809,"longitude":127.027886,"bookYear":null,"nameKR":"와가리피순대","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":24375,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.5796079576696,"longitude":127.042658968115,"bookYear":null,"nameKR":"엄나무골","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":24633,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.579293,"longitude":127.047901,"bookYear":null,"nameKR":"티원(청량리점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":25689,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5820709616218,"longitude":127.044958497105,"bookYear":null,"nameKR":"홍능족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"28. 신설동/청량리/회기역","status":"ACTIVE"},
        {"id":5630,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.56781,"longitude":126.9697,"bookYear":null,"nameKR":"정동국시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":6417,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.568392,"longitude":126.977727,"bookYear":null,"nameKR":"레더라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":7818,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.56234,"longitude":126.9768,"bookYear":null,"nameKR":"처가집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":7877,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.563697,"longitude":126.977491,"bookYear":null,"nameKR":"장안삼계탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":8474,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.565833,"longitude":126.972499,"bookYear":null,"nameKR":"덕수정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":8476,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.564682,"longitude":126.976341,"bookYear":null,"nameKR":"무교동낙지 애 보쌈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":8800,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.563684,"longitude":126.977428,"bookYear":null,"nameKR":"동호민물장어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":9155,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.568369,"longitude":126.97779,"bookYear":null,"nameKR":"붓처스컷(광화문점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":13009,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.567515,"longitude":126.970206,"bookYear":null,"nameKR":"산다미아노","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":29524,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeelusso_official","foodTypes":"디저트/차/베이커리","latitude":37.56716305913046,"longitude":126.97064276138327,"bookYear":null,"nameKR":"커피루소(정동점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":30416,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dlifestyle_kitchen/","foodTypes":"유럽식","latitude":37.56842249719518,"longitude":126.9782578910947,"bookYear":null,"nameKR":"디라이프스타일키친","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":30730,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/indiebeer_factory","foodTypes":"기타","latitude":37.567161887661,"longitude":126.970642082697,"bookYear":null,"nameKR":"독립맥주공장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":31541,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/liege_waffle_/","foodTypes":"디저트/차/베이커리","latitude":37.5647946515193,"longitude":126.976453944505,"bookYear":null,"nameKR":"덕수궁리에제와플","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":33701,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/roundnd.jeongdong/","foodTypes":"디저트/차/베이커리","latitude":37.5660606555167,"longitude":126.972363673049,"bookYear":null,"nameKR":"라운드앤드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":34030,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5619576608193,"longitude":126.974187325073,"bookYear":null,"nameKR":"우림정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":1559,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.56341,"longitude":126.973,"bookYear":null,"nameKR":"청송옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":5596,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.562711,"longitude":126.970075,"bookYear":null,"nameKR":"고려정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":8480,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.563224,"longitude":126.973114,"bookYear":null,"nameKR":"한성","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":9359,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.56499,"longitude":126.981369,"bookYear":null,"nameKR":"피에르바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":9378,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5644841324893,"longitude":126.980184460247,"bookYear":null,"nameKR":"아리아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":29381,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.56472900249868,"longitude":126.9817315539185,"bookYear":null,"nameKR":"위고에빅토르","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":129,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.56285,"longitude":126.9772,"bookYear":null,"nameKR":"남매집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":754,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.56292,"longitude":126.9784,"bookYear":null,"nameKR":"전주유할머니비빔밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":3714,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.562513,"longitude":126.978053,"bookYear":null,"nameKR":"마산집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":9110,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.56276,"longitude":126.977212,"bookYear":null,"nameKR":"삼성집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":9111,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.56328,"longitude":126.9757,"bookYear":null,"nameKR":"삼우정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":14400,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.568787,"longitude":126.978212,"bookYear":null,"nameKR":"산채향","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":15550,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5634452070604,"longitude":126.97589693086,"bookYear":null,"nameKR":"십원집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":20131,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.562012,"longitude":126.974309,"bookYear":null,"nameKR":"해치","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":28419,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.562844,"longitude":126.978307,"bookYear":null,"nameKR":"스시화정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":28950,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5636417632668,"longitude":126.978481690541,"bookYear":null,"nameKR":"송원스키야키&샤브샤브","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":34506,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5627515424989,"longitude":126.978283534325,"bookYear":null,"nameKR":"구정옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":35120,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5619438347626,"longitude":126.973195505619,"bookYear":null,"nameKR":"담원순대","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":35349,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(육류)","latitude":37.5678548680217,"longitude":126.978742292002,"bookYear":null,"nameKR":"한라담(무교점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":36605,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5620867340846,"longitude":126.977996467826,"bookYear":null,"nameKR":"보원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":35564,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5673664868336,"longitude":126.981150237991,"bookYear":null,"nameKR":"한시락","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":50,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.56872,"longitude":126.9796,"bookYear":null,"nameKR":"고바우","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":521,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.561523,"longitude":126.97736,"bookYear":null,"nameKR":"송옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":3450,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5676024455899,"longitude":126.979982379299,"bookYear":null,"nameKR":"세림","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":4799,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.564021,"longitude":126.981909,"bookYear":null,"nameKR":"타니넥스트도어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":8694,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5679913247653,"longitude":126.981133780839,"bookYear":null,"nameKR":"향설주랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":8700,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.567563,"longitude":126.979969,"bookYear":null,"nameKR":"낙동강","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":14809,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.568715,"longitude":126.979573,"bookYear":null,"nameKR":"남산복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":18915,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.567195,"longitude":126.980656,"bookYear":null,"nameKR":"동아리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"17. 시청/소공동/정동","status":"ACTIVE"},
        {"id":34154,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5980007403291,"longitude":127.029322469894,"bookYear":null,"nameKR":"커인스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":30494,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.583851494721486,"longitude":127.01964077137126,"bookYear":null,"nameKR":"소담","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":33223,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jhallenge/","foodTypes":"한식(일반한식)","latitude":37.5855551086711,"longitude":127.029515833691,"bookYear":null,"nameKR":"한술식당(고대점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":33516,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/o.cdol/","foodTypes":"이탈리아식","latitude":37.593018808454,"longitude":127.014807683421,"bookYear":null,"nameKR":"옥돌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":34010,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5946335001247,"longitude":127.017884874883,"bookYear":null,"nameKR":"파세로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":35251,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maremohe/","foodTypes":"디저트/차/베이커리","latitude":37.593528169387,"longitude":127.013815201384,"bookYear":null,"nameKR":"마레모헤","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":35716,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.596770778787,"longitude":127.014312340358,"bookYear":null,"nameKR":"파니야(돈암점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":36056,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.5903305708161,"longitude":127.021496891835,"bookYear":null,"nameKR":"아피네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":1243,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5830233714194,"longitude":127.022291485108,"bookYear":null,"nameKR":"이상조전통만두국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":1428,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.58995,"longitude":127.0196,"bookYear":null,"nameKR":"신신식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":7044,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.5849113418313,"longitude":127.029545016176,"bookYear":null,"nameKR":"베나레스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":8291,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/curry.since_2004/","foodTypes":"일식","latitude":37.591529,"longitude":127.020064,"bookYear":null,"nameKR":"인도이웃","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":9066,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5859682055417,"longitude":127.030610150496,"bookYear":null,"nameKR":"나그네파전","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":15415,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5869920871665,"longitude":127.029576068606,"bookYear":null,"nameKR":"모심","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":23076,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pikacoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5856047568646,"longitude":127.031590931049,"bookYear":null,"nameKR":"피카커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":25857,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5886566081438,"longitude":127.018346302817,"bookYear":null,"nameKR":"일흥콩나물해장국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":29019,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.58732386670929,"longitude":127.028708469539,"bookYear":null,"nameKR":"디어브레드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":7949,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.585323,"longitude":127.034595,"bookYear":null,"nameKR":"현고대닭발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":26168,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5909956815258,"longitude":127.017099000396,"bookYear":null,"nameKR":"성신돌곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":26718,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5853888911437,"longitude":127.034446214636,"bookYear":null,"nameKR":"제기집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":29022,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/humbolt.cafe/","foodTypes":"디저트/차/베이커리","latitude":37.540122,"longitude":127.055588,"bookYear":null,"nameKR":"훔볼트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29322,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/graystone.flowercafe/","foodTypes":"디저트/차/베이커리","latitude":37.541573487535906,"longitude":127.05343960627587,"bookYear":null,"nameKR":"그레이스톤","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29450,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.55055112303603,"longitude":127.0673699524811,"bookYear":null,"nameKR":"송정국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29801,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5879471697374,"longitude":127.035361778797,"bookYear":null,"nameKR":"칠기마라샹궈마라탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":29961,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_kemang/","foodTypes":"디저트/차/베이커리","latitude":37.588262623822686,"longitude":127.03560637653517,"bookYear":null,"nameKR":"카페끄망","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":30652,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dis_rough","foodTypes":"디저트/차/베이커리","latitude":37.59040332090601,"longitude":127.02077022658116,"bookYear":null,"nameKR":"디스러프","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":33070,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/affaircoffee_/","foodTypes":"디저트/차/베이커리","latitude":37.5366959969402,"longitude":127.055045839393,"bookYear":null,"nameKR":"어페어커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33117,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seongsu_19/","foodTypes":"바/주점","latitude":37.5376562211422,"longitude":127.05517858714,"bookYear":null,"nameKR":"성수일구","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33175,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lowkey_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5494355524687,"longitude":127.065202305693,"bookYear":null,"nameKR":"로우키(송정점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34153,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aiqingmala/","foodTypes":"중식","latitude":37.592173257712,"longitude":127.017239900166,"bookYear":null,"nameKR":"애정마라샹궈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":34339,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kyemoim_seongsu/","foodTypes":"한식(가금류)","latitude":37.5377584931458,"longitude":127.055160445896,"bookYear":null,"nameKR":"계모임","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34435,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gwihan_jokbal/","foodTypes":"한식(육류)","latitude":37.5916656952153,"longitude":127.016849145865,"bookYear":null,"nameKR":"귀한족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":35145,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5936274573744,"longitude":127.019008453295,"bookYear":null,"nameKR":"스시진 1호점 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"26. 성신여대/고려대","status":"ACTIVE"},
        {"id":35641,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moeum_cuisine/","foodTypes":"이탈리아식","latitude":37.5563012473364,"longitude":127.04297558257,"bookYear":null,"nameKR":"모음","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32931,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/le_freak_burger/","foodTypes":"기타","latitude":37.5444391065296,"longitude":127.052659059956,"bookYear":null,"nameKR":"르프리크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33524,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maisonseongsudon/","foodTypes":"한식(육류)","latitude":37.5431880076824,"longitude":127.05496834862,"bookYear":null,"nameKR":"메종성수돈 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33561,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5433028629461,"longitude":127.054040335758,"bookYear":null,"nameKR":"도도한면","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33615,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_mela/","foodTypes":"기타","latitude":37.5435086824985,"longitude":127.053971912211,"bookYear":null,"nameKR":"멜라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33821,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eustress_official/","foodTypes":"디저트/차/베이커리","latitude":37.5430329741345,"longitude":127.055485464366,"bookYear":null,"nameKR":"유스트레스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34053,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/matdaum_seongsu/","foodTypes":"바/주점","latitude":37.5411759472701,"longitude":127.056008546643,"bookYear":null,"nameKR":"맞닿음(성수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34070,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oldnutty_coffeeroasters","foodTypes":"디저트/차/베이커리","latitude":37.5489303715487,"longitude":127.054325722724,"bookYear":null,"nameKR":"올드너티커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35590,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5414076146252,"longitude":127.056908279292,"bookYear":null,"nameKR":"메이플탑 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35907,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maat_coffeebrewers/","foodTypes":"디저트/차/베이커리","latitude":37.5493856237865,"longitude":127.05378399671,"bookYear":null,"nameKR":"마아트커피브루어스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36018,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/upperroom_coffee_/","foodTypes":"디저트/차/베이커리","latitude":37.5483729026106,"longitude":127.053583197486,"bookYear":null,"nameKR":"어퍼룸","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36181,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5429076278416,"longitude":127.056094142075,"bookYear":null,"nameKR":"뚝다이닝","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":28629,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daelim_changgo","foodTypes":"디저트/차/베이커리","latitude":37.541826,"longitude":127.056293,"bookYear":null,"nameKR":"대림창고","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29119,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mia.nonnaa/","foodTypes":"기타","latitude":37.5444391065296,"longitude":127.052659059956,"bookYear":null,"nameKR":"미아논나","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29273,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.54647,"longitude":127.051394,"bookYear":null,"nameKR":"밀본","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29023,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ezercoffee/","foodTypes":"디저트/차/베이커리","latitude":37.546197,"longitude":127.057357,"bookYear":null,"nameKR":"에젤커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":30804,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5481744730279,"longitude":127.0532504713,"bookYear":null,"nameKR":"고기다","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":31383,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yongssalong_official","foodTypes":"바/주점","latitude":37.5410995494079,"longitude":127.057862931953,"bookYear":null,"nameKR":"용싸롱","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":31407,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistrio_seoul/","foodTypes":"바/주점","latitude":37.5461662926896,"longitude":127.0548991319,"bookYear":null,"nameKR":"비스트리오","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32139,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/atmospheresungsoo/","foodTypes":"프랑스식","latitude":37.5434610065988,"longitude":127.063758220361,"bookYear":null,"nameKR":"앳모스피어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32207,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/atable.cafe/","foodTypes":"디저트/차/베이커리","latitude":37.5468421617821,"longitude":127.062283905071,"bookYear":null,"nameKR":"에이테이블","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32504,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/up.side_/","foodTypes":"디저트/차/베이커리","latitude":37.5420003368969,"longitude":127.057928125315,"bookYear":null,"nameKR":"업사이드커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32648,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sedici_seoul/","foodTypes":"이탈리아식","latitude":37.5439218096116,"longitude":127.061316029179,"bookYear":null,"nameKR":"세디치","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32667,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lcdc.seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5417308163872,"longitude":127.061552421083,"bookYear":null,"nameKR":"LCDC서울이페메라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33454,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bao_seoul_restr/","foodTypes":"중식","latitude":37.5405524452944,"longitude":127.061289960823,"bookYear":null,"nameKR":"바오서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33556,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/burgerboy_seoul/","foodTypes":"기타","latitude":37.5463937599992,"longitude":127.065889477465,"bookYear":null,"nameKR":"버거보이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34424,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5412530634959,"longitude":127.060940216094,"bookYear":null,"nameKR":"프롤라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34442,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chunked_official/","foodTypes":"기타","latitude":37.5471813463024,"longitude":127.05511771094,"bookYear":null,"nameKR":"청키드(성수본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35127,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sosobaking_/","foodTypes":"디저트/차/베이커리","latitude":37.5419685383094,"longitude":127.061651851664,"bookYear":null,"nameKR":"쏘쏘한베이킹","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35256,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oude_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5440616211023,"longitude":127.061879434891,"bookYear":null,"nameKR":"오우드 성수2호점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35257,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oude_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5412390325202,"longitude":127.060542023269,"bookYear":null,"nameKR":"오우드 성수1호점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36100,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.adf","foodTypes":"디저트/차/베이커리","latitude":37.5396089165843,"longitude":127.06146649362,"bookYear":null,"nameKR":"올데이휘낭시에(성수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36106,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gute_leute_/","foodTypes":"디저트/차/베이커리","latitude":37.5425836810163,"longitude":127.058219269012,"bookYear":null,"nameKR":"구테로이테(성수센트럴키친)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":30742,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bimbomcafe","foodTypes":"기타","latitude":37.5469051877232,"longitude":127.040424870132,"bookYear":null,"nameKR":"빙봉","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":30867,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myosa_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5478673398761,"longitude":127.039700361488,"bookYear":null,"nameKR":"묘사서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":31266,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5481104648655,"longitude":127.041688751305,"bookYear":null,"nameKR":"주식회사일구공","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32175,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/greytcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5477009519645,"longitude":127.039621286419,"bookYear":null,"nameKR":"그레이트커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32488,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nanpo_official/","foodTypes":"한식(일반한식)","latitude":37.5470517097135,"longitude":127.042673790996,"bookYear":null,"nameKR":"난포","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32505,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/up.side_/","foodTypes":"디저트/차/베이커리","latitude":37.548771040745,"longitude":127.045915766793,"bookYear":null,"nameKR":"업사이드커피뚝섬점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32614,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/zestysaloon/","foodTypes":"기타","latitude":37.547162905635,"longitude":127.04085479412,"bookYear":null,"nameKR":"제스티살룬","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34848,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/peeps_seoul/","foodTypes":"바/주점","latitude":37.5468859197462,"longitude":127.042440925531,"bookYear":null,"nameKR":"핍스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35117,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/comoyoon/","foodTypes":"디저트/차/베이커리","latitude":37.5479045757678,"longitude":127.042975740616,"bookYear":null,"nameKR":"꼬모윤","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35177,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_seasony/","foodTypes":"디저트/차/베이커리","latitude":37.5475239707245,"longitude":127.043039345121,"bookYear":null,"nameKR":"시즈니","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35542,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lowidecoffee","foodTypes":"디저트/차/베이커리","latitude":37.5469411034152,"longitude":127.041046253779,"bookYear":null,"nameKR":"로와이드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":28252,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hanoi_baguette/","foodTypes":"아시아식","latitude":37.5456773286722,"longitude":127.042914714267,"bookYear":null,"nameKR":"하노이바게트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29049,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_sungsu","foodTypes":"디저트/차/베이커리","latitude":37.547499,"longitude":127.042485,"bookYear":null,"nameKR":"카페성수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29100,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.546489,"longitude":127.041653,"bookYear":null,"nameKR":"센터커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29319,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/toit_bleu/","foodTypes":"디저트/차/베이커리","latitude":37.5479045757678,"longitude":127.042975740616,"bookYear":null,"nameKR":"트와블루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":4286,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.546281654154,"longitude":127.045024127318,"bookYear":null,"nameKR":"비사벌전주콩나물국밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":13322,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5482951715381,"longitude":127.046963925782,"bookYear":null,"nameKR":"성일정육점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":23351,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.544382,"longitude":127.045751,"bookYear":null,"nameKR":"서울숲쭈꾸미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":23352,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.545537,"longitude":127.045861,"bookYear":null,"nameKR":"성수아구찜","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":27758,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5460591752394,"longitude":127.047158627811,"bookYear":null,"nameKR":"쁘띠그랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":29117,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seongsustout/","foodTypes":"바/주점","latitude":37.546998,"longitude":127.044338,"bookYear":null,"nameKR":"성수스타우트","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":30729,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/domix_seoul","foodTypes":"한식(일반한식)","latitude":37.5367248071245,"longitude":127.049818785747,"bookYear":null,"nameKR":"도믹스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":31323,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/33table/","foodTypes":"일식","latitude":37.5464050348413,"longitude":127.042999209074,"bookYear":null,"nameKR":"윤경양식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32515,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/corner.room_seoul/","foodTypes":"바/주점","latitude":37.5417129532735,"longitude":127.047784679124,"bookYear":null,"nameKR":"코너룸","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32761,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/midolim_bar","foodTypes":"기타","latitude":37.5471985115607,"longitude":127.044784777706,"bookYear":null,"nameKR":"미도림","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33263,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soranghogen/","foodTypes":"이탈리아식","latitude":37.5426958782317,"longitude":127.045205280472,"bookYear":null,"nameKR":"소랑호젠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33355,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/creamlabel_","foodTypes":"디저트/차/베이커리","latitude":37.5465528679857,"longitude":127.042308452783,"bookYear":null,"nameKR":"크림라벨(서울숲점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33368,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/finz.seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5424200574134,"longitude":127.048228805891,"bookYear":null,"nameKR":"핀즈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33404,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.551424934552,"longitude":127.047052349337,"bookYear":null,"nameKR":"파티세리아모니","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":33831,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/casadalua_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5378976033997,"longitude":127.050958615007,"bookYear":null,"nameKR":"까사다루아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35175,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5461965391103,"longitude":127.043747525348,"bookYear":null,"nameKR":"이월로스터스(성수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35540,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5465528679857,"longitude":127.042308452783,"bookYear":null,"nameKR":"쎄콩데라이브","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35706,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/metable.official/","foodTypes":"이탈리아식","latitude":37.5465384293714,"longitude":127.045284549478,"bookYear":null,"nameKR":"미테이블(성수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35726,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5470849992423,"longitude":127.045222075925,"bookYear":null,"nameKR":"서경도락(성수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35735,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5457593392903,"longitude":127.045974346278,"bookYear":null,"nameKR":"카린지린가네스낵바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35964,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tacotuesday_seongsu","foodTypes":"중남미식","latitude":37.5467293211713,"longitude":127.045596308057,"bookYear":null,"nameKR":"타코튜즈데이(성수본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36116,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5496933703184,"longitude":127.044563788681,"bookYear":null,"nameKR":"소인수서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36222,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soungsoo_oo","foodTypes":"바/주점","latitude":37.5419681986215,"longitude":127.04780407803,"bookYear":null,"nameKR":"성수오오","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36475,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5483631821961,"longitude":127.047000972634,"bookYear":null,"nameKR":"구펠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":30200,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fresca_2?utm_medium=copy_link","foodTypes":"이탈리아식","latitude":37.5581224854556,"longitude":126.909350227902,"bookYear":null,"nameKR":"프레스카2","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":31403,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tbd_kr/","foodTypes":"기타","latitude":37.5439124617533,"longitude":127.050201169519,"bookYear":null,"nameKR":"TBD","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":31405,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/__mortar/","foodTypes":"바/주점","latitude":37.5460938485307,"longitude":127.049399428262,"bookYear":null,"nameKR":"모루타루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":31700,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/boyer_seoul","foodTypes":"이탈리아식","latitude":37.5436693379689,"longitude":127.050912302225,"bookYear":null,"nameKR":"보이어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":31800,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/momento_brewers_","foodTypes":"디저트/차/베이커리","latitude":37.5490593156727,"longitude":127.049159780791,"bookYear":null,"nameKR":"모멘토브루어스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":32754,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kafe.tone/","foodTypes":"디저트/차/베이커리","latitude":37.5592193910805,"longitude":126.90810958869,"bookYear":null,"nameKR":"카페톤","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":33174,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lowkey_coffee","foodTypes":"디저트/차/베이커리","latitude":37.5444719061481,"longitude":127.047778632224,"bookYear":null,"nameKR":"로우키(헤이그라운드점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":34588,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeeandscoop/","foodTypes":"디저트/차/베이커리","latitude":37.5650651601993,"longitude":126.903940151185,"bookYear":null,"nameKR":"커피앤스쿱","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":35528,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/bonnoel2020","foodTypes":"디저트/차/베이커리","latitude":37.5497472507343,"longitude":127.048009686799,"bookYear":null,"nameKR":"본노엘(성수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":35758,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/roundnd.mangwon","foodTypes":"디저트/차/베이커리","latitude":37.5598278345671,"longitude":126.907878859818,"bookYear":null,"nameKR":"라운드앤드(망원점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":35908,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maat_coffeebrewers/","foodTypes":"디저트/차/베이커리","latitude":37.5492195441892,"longitude":127.050789672956,"bookYear":null,"nameKR":"마아트커피브루어스(2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36120,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/highlinecafe","foodTypes":"디저트/차/베이커리","latitude":37.5390161844299,"longitude":127.049928932762,"bookYear":null,"nameKR":"하이라인","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":36299,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.544503693939,"longitude":127.049138570968,"bookYear":null,"nameKR":"브레디포스트 성수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":9077,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.542849,"longitude":127.0544,"bookYear":null,"nameKR":"소문난성수감자탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":22370,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.570266,"longitude":126.903412,"bookYear":null,"nameKR":"마르메종과자점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":24979,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kidary_bread/","foodTypes":"디저트/차/베이커리","latitude":37.55839,"longitude":126.9107,"bookYear":null,"nameKR":"키다리아저씨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":25896,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.54742,"longitude":127.050212,"bookYear":null,"nameKR":"강남면옥(성수점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":28293,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.56055,"longitude":126.91567,"bookYear":null,"nameKR":"라무진","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":28445,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hungoringobread/","foodTypes":"디저트/차/베이커리","latitude":37.5583951351401,"longitude":126.913197602655,"bookYear":null,"nameKR":"훈고링고브레드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":29321,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/or.er/","foodTypes":"디저트/차/베이커리","latitude":37.543453,"longitude":127.051363,"bookYear":null,"nameKR":"오르에르","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"30. 성수동","status":"ACTIVE"},
        {"id":3453,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.59379,"longitude":126.9963,"bookYear":null,"nameKR":"섭지코지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":5173,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5946944009873,"longitude":126.995145937623,"bookYear":null,"nameKR":"선동보리밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":21818,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.576635,"longitude":126.896047,"bookYear":null,"nameKR":"더브래드팬트리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":29769,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.58112504285025,"longitude":126.89099023854854,"bookYear":null,"nameKR":"보헤미안박이추커피(상암점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":30207,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sbd_ian/","foodTypes":"프랑스식","latitude":37.58895271299347,"longitude":127.00289853621867,"bookYear":null,"nameKR":"성북동이안","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":31348,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5794419093126,"longitude":126.890322604776,"bookYear":null,"nameKR":"크루미상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":31361,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/haerocoffee","foodTypes":"디저트/차/베이커리","latitude":37.589613279054,"longitude":127.004269038102,"bookYear":null,"nameKR":"해로커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":32832,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5791943482019,"longitude":126.892314544858,"bookYear":null,"nameKR":"오로지닭곰탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":33717,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.577619755261,"longitude":126.893988764582,"bookYear":null,"nameKR":"중화백반","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":33935,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.582343255719,"longitude":126.888365859866,"bookYear":null,"nameKR":"모범반점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":35143,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5887733310699,"longitude":127.005322311133,"bookYear":null,"nameKR":"스시산","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":35144,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5887733310699,"longitude":127.005322311133,"bookYear":null,"nameKR":"마구로산 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":35524,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jihoos_kitchen","foodTypes":"일식","latitude":37.5763732585256,"longitude":126.896149350546,"bookYear":null,"nameKR":"지후식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":35887,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wolhwa_official_/","foodTypes":"한식(육류)","latitude":37.5780913852803,"longitude":126.894446586599,"bookYear":null,"nameKR":"월화고기(상암직영1호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":36380,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5820926424763,"longitude":126.888984720113,"bookYear":null,"nameKR":"유타로(상암점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"07. 성산동/상암동","status":"ACTIVE"},
        {"id":32113,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oojoo_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.595219507794,"longitude":126.993463252733,"bookYear":null,"nameKR":"우주제빵소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":32749,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.595893623474,"longitude":126.988630794544,"bookYear":null,"nameKR":"성북동면옥집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":92,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5948315882709,"longitude":126.994385116336,"bookYear":null,"nameKR":"금왕돈까스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":296,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/majeonteo/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.592656,"longitude":126.998656,"bookYear":null,"nameKR":"마전터","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":489,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.592454,"longitude":126.99927,"bookYear":null,"nameKR":"서울왕돈까스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":654,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.592573,"longitude":126.99897,"bookYear":null,"nameKR":"오박사네왕돈까스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":4192,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.593075,"longitude":127.000338,"bookYear":null,"nameKR":"손가네","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":6968,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/conchurros_/","foodTypes":"디저트/차/베이커리","latitude":37.5931967807618,"longitude":127.000584042923,"bookYear":null,"nameKR":"초코라떼꼰츄로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":7564,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.594428,"longitude":126.988605,"bookYear":null,"nameKR":"제이스케익","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":9081,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.594508,"longitude":126.99335,"bookYear":null,"nameKR":"성북설렁탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":13020,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joseph_coffee_tree/","foodTypes":"디저트/차/베이커리","latitude":37.59457,"longitude":126.9886,"bookYear":null,"nameKR":"조셉의커피나무","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":16010,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5926842681942,"longitude":127.002652972437,"bookYear":null,"nameKR":"구포국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":26421,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.59629,"longitude":126.9972,"bookYear":null,"nameKR":"누브티스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":28550,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/millgokkan/","foodTypes":"디저트/차/베이커리","latitude":37.594076,"longitude":126.999315,"bookYear":null,"nameKR":"밀곳간","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":29221,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.595059,"longitude":126.993496,"bookYear":null,"nameKR":"알렉스더커피(성북점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":517,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.58987,"longitude":127.0088,"bookYear":null,"nameKR":"송림원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":4056,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.59304,"longitude":126.9972,"bookYear":null,"nameKR":"일상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":7810,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.580622,"longitude":126.970211,"bookYear":null,"nameKR":"원조할머니떡볶이집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":9290,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5768145412306,"longitude":126.968970434089,"bookYear":null,"nameKR":"마농트로포","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":19395,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.584616,"longitude":127.010666,"bookYear":null,"nameKR":"하리원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":22375,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.580907,"longitude":126.96866,"bookYear":null,"nameKR":"효자베이커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":23092,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/noshi_62/","foodTypes":"디저트/차/베이커리","latitude":37.57993,"longitude":126.9709,"bookYear":null,"nameKR":"놋그릇가지런히","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":25572,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5773603962147,"longitude":126.969094847948,"bookYear":null,"nameKR":"만나분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":29387,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.578732,"longitude":126.972048,"bookYear":null,"nameKR":"곰솔","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":29620,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.57959314023419,"longitude":126.97046580257337,"bookYear":null,"nameKR":"스노브(서촌점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31638,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baker_lee88/","foodTypes":"디저트/차/베이커리","latitude":37.5895770056493,"longitude":127.015606256909,"bookYear":null,"nameKR":"이씨네빵집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":33640,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/noearcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5887540877121,"longitude":127.011213116061,"bookYear":null,"nameKR":"노이어커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"25. 성북동","status":"ACTIVE"},
        {"id":35295,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kjmoksam/","foodTypes":"한식(육류)","latitude":37.5768640715491,"longitude":126.969569045597,"bookYear":null,"nameKR":"김진목삼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31169,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_victoria_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.578997215372,"longitude":126.973071882505,"bookYear":null,"nameKR":"빅토리아베이커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31584,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5778050771461,"longitude":126.97139956245,"bookYear":null,"nameKR":"미라보쇼콜라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31695,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/j.alley_bistro/","foodTypes":"이탈리아식","latitude":37.5778701492147,"longitude":126.971483424158,"bookYear":null,"nameKR":"제이엘리 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":34655,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aslike_/","foodTypes":"기타","latitude":37.5784600983367,"longitude":126.973316832018,"bookYear":null,"nameKR":"애즈라이크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":35296,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kjmoksam/","foodTypes":"한식(육류)","latitude":37.5769262064682,"longitude":126.971980531429,"bookYear":null,"nameKR":"김진목삼(2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":35833,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jebunso/","foodTypes":"바/주점","latitude":37.5777848488713,"longitude":126.970480667711,"bookYear":null,"nameKR":"제분소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":35926,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5799717338378,"longitude":126.97154998287,"bookYear":null,"nameKR":"라파리나","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":8963,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5765901097208,"longitude":126.973030070927,"bookYear":null,"nameKR":"강구미주구리(본관)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":12630,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5779057540419,"longitude":126.972305635476,"bookYear":null,"nameKR":"봉피양(경복궁점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":28454,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/handmade_mandoo/","foodTypes":"중식","latitude":37.578227,"longitude":126.971021,"bookYear":null,"nameKR":"취천루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":29608,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rules_seoul/","foodTypes":"프랑스식","latitude":37.57757142103462,"longitude":126.97091490016597,"bookYear":null,"nameKR":"룰스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":873,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.59261,"longitude":126.966,"bookYear":null,"nameKR":"클럽에스프레소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":3635,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.59538,"longitude":126.9679,"bookYear":null,"nameKR":"산모퉁이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":7001,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.580101,"longitude":126.969004,"bookYear":null,"nameKR":"영화루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":18827,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.580944237255,"longitude":126.966941773733,"bookYear":null,"nameKR":"남도분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":27572,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gomis361","foodTypes":"바/주점","latitude":37.579503,"longitude":126.969344,"bookYear":null,"nameKR":"까예데고미스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":28991,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.576283,"longitude":126.972285,"bookYear":null,"nameKR":"커피투어(경복궁점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":29128,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/scoffbakehouse/","foodTypes":"디저트/차/베이커리","latitude":37.580322,"longitude":126.967464,"bookYear":null,"nameKR":"스코프(서촌점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":30241,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daechungpark_inwangsan/","foodTypes":"바/주점","latitude":37.580093670063775,"longitude":126.96882178622172,"bookYear":null,"nameKR":"인왕산대충유원지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":31193,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/raisondetre_dessert/","foodTypes":"디저트/차/베이커리","latitude":37.5801858609805,"longitude":126.968894768738,"bookYear":null,"nameKR":"레종데트르","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":32048,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5941582875688,"longitude":126.962713396458,"bookYear":null,"nameKR":"몽유도원도이창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":32257,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dochwi_dining","foodTypes":"기타","latitude":37.5808614762467,"longitude":126.967086042174,"bookYear":null,"nameKR":"도취","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":33183,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/patic_seoul/","foodTypes":"바/주점","latitude":37.5807127462798,"longitude":126.965901235183,"bookYear":null,"nameKR":"파틱","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":33692,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/senedong_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5808124709587,"longitude":126.96744256751,"bookYear":null,"nameKR":"세네동커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":34428,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mok.restaurant/","foodTypes":"중식","latitude":37.5932496330015,"longitude":126.965668714904,"bookYear":null,"nameKR":"목식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":35888,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myc.kr","foodTypes":"디저트/차/베이커리","latitude":37.579311654055,"longitude":126.967986400037,"bookYear":null,"nameKR":"엠와이씨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":36004,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5755924608212,"longitude":126.965501922875,"bookYear":null,"nameKR":"인왕산호랭이주막","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":36545,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/grocery_cafe_qyun/","foodTypes":"기타","latitude":37.5847099600923,"longitude":126.972271640465,"bookYear":null,"nameKR":"큔","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"10. 서촌/부암동","status":"ACTIVE"},
        {"id":30662,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.56920785041306,"longitude":126.96260970047763,"bookYear":null,"nameKR":"브루어리304","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":30993,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dongnip_millbang/","foodTypes":"이탈리아식","latitude":37.5727064029257,"longitude":126.960632960408,"bookYear":null,"nameKR":"독립밀방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":31519,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/casa_del_mar__/","foodTypes":"유럽식","latitude":37.574374261303,"longitude":126.958604295236,"bookYear":null,"nameKR":"까사델마르","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":32537,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/draw_espresso_bar","foodTypes":"디저트/차/베이커리","latitude":37.5598464516488,"longitude":126.96796179478,"bookYear":null,"nameKR":"드로우에스프레소바","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":35838,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.6193281173709,"longitude":126.919227198516,"bookYear":null,"nameKR":"아우성황소곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":471,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5642,"longitude":126.967102,"bookYear":null,"nameKR":"삼오쭈꾸미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":5871,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.56023,"longitude":126.9677,"bookYear":null,"nameKR":"중림장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":7952,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.569306,"longitude":126.966649,"bookYear":null,"nameKR":"곰마루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":12996,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5596781093752,"longitude":126.963201979192,"bookYear":null,"nameKR":"세양원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":16438,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5630162425604,"longitude":126.964180178209,"bookYear":null,"nameKR":"오리와메밀푸른초장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":17911,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5673944471343,"longitude":126.968589944032,"bookYear":null,"nameKR":"새봄떡국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":18648,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5608540098383,"longitude":126.964110814472,"bookYear":null,"nameKR":"청정골","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":18955,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5624947715699,"longitude":126.967705317604,"bookYear":null,"nameKR":"막내네회집(서대문점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":29241,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.557775,"longitude":126.955143,"bookYear":null,"nameKR":"북성해장국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":29384,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gosma_trattoria","foodTypes":"이탈리아식","latitude":37.5688537151032,"longitude":126.965114852628,"bookYear":null,"nameKR":"고스마","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":29507,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.560511794028415,"longitude":126.96375943839416,"bookYear":null,"nameKR":"충정각","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":588,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5775582642125,"longitude":126.986323484652,"bookYear":null,"nameKR":"안집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":3980,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thecoffeemill_official/","foodTypes":"디저트/차/베이커리","latitude":37.58028,"longitude":126.9825,"bookYear":null,"nameKR":"커피방앗간","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":7667,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.579532,"longitude":126.983724,"bookYear":null,"nameKR":"몽중헌(안국점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8646,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.581601,"longitude":126.989213,"bookYear":null,"nameKR":"동네커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8657,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5793,"longitude":126.983154,"bookYear":null,"nameKR":"차향기듣는집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8735,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.580888,"longitude":126.981698,"bookYear":null,"nameKR":"풍년쌀농산","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8779,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.583714,"longitude":126.979763,"bookYear":null,"nameKR":"데일리브라운","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":9101,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.566822,"longitude":126.962263,"bookYear":null,"nameKR":"평동양곱창전골불고기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":26316,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.566332,"longitude":126.965076,"bookYear":null,"nameKR":"소연","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"09. 서대문/충정로","status":"ACTIVE"},
        {"id":28920,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/boutique_kyungsung/","foodTypes":"기타","latitude":37.582234,"longitude":126.981162,"bookYear":null,"nameKR":"부띠끄경성","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":29086,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fritzcoffeecompany/","foodTypes":"디저트/차/베이커리","latitude":37.577629,"longitude":126.988555,"bookYear":null,"nameKR":"프릳츠(원서점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":29430,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/txtcoffee","foodTypes":"디저트/차/베이커리","latitude":37.5828255,"longitude":126.98902989999999,"bookYear":null,"nameKR":"txt커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":30134,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dansangkr","foodTypes":"한식(일반한식)","latitude":37.57795624394754,"longitude":126.98641510986873,"bookYear":null,"nameKR":"단상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":30206,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.57776184256481,"longitude":126.98841664712182,"bookYear":null,"nameKR":"합(원서점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":31798,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thanksoat/","foodTypes":"디저트/차/베이커리","latitude":37.5787935854394,"longitude":126.984601467817,"bookYear":null,"nameKR":"땡스오트안국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":32119,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/insadong_chungsik","foodTypes":"한식(어패류)","latitude":37.5791158515675,"longitude":126.98588757086,"bookYear":null,"nameKR":"인사동쭈꾸미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":30262,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nhahang_anguk","foodTypes":"바/주점","latitude":37.5776387780225,"longitude":126.98390197355646,"bookYear":null,"nameKR":"냐항인안국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":30337,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bluebottlecoffee_korea/","foodTypes":"디저트/차/베이커리","latitude":37.58015389983659,"longitude":126.98086285568424,"bookYear":null,"nameKR":"블루보틀(삼청카페)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":30696,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/salt24_artisan/","foodTypes":"디저트/차/베이커리","latitude":37.578979192910225,"longitude":126.98206769973422,"bookYear":null,"nameKR":"솔트24","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":32095,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5781006879066,"longitude":126.98368462866,"bookYear":null,"nameKR":"지유가오카핫초메(삼청점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":33607,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.577086774668,"longitude":126.984853702875,"bookYear":null,"nameKR":"커피브론즈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":34131,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/on6.5_seoul/","foodTypes":"한식(일반한식)","latitude":37.5773675392768,"longitude":126.984277651202,"bookYear":null,"nameKR":"온6.5","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":35408,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5765508752039,"longitude":126.98425665352,"bookYear":null,"nameKR":"동백식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":35988,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/komfortabelcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5768377450185,"longitude":126.984205763754,"bookYear":null,"nameKR":"콤포타블안국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":36108,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5765738126662,"longitude":126.984653093736,"bookYear":null,"nameKR":"삼방매","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":266,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.577848,"longitude":126.984304,"bookYear":null,"nameKR":"로마네꽁띠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":6376,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.57913,"longitude":126.9888,"bookYear":null,"nameKR":"비원손칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8672,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57881,"longitude":126.9831,"bookYear":null,"nameKR":"마나님레시피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8774,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5788204959725,"longitude":126.982455138051,"bookYear":null,"nameKR":"벽오동가","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":22903,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.57856836291924,"longitude":126.98222176160841,"bookYear":null,"nameKR":"조선김밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":26753,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.57913,"longitude":126.9835,"bookYear":null,"nameKR":"티테라피행랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":28929,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kiwataproom/","foodTypes":"바/주점","latitude":37.578607,"longitude":126.981688,"bookYear":null,"nameKR":"기와탭룸","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":158,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.587202,"longitude":126.981423,"bookYear":null,"nameKR":"다락정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":424,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5871910272942,"longitude":126.982672936286,"bookYear":null,"nameKR":"부영도가니탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":528,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.51898,"longitude":127.056541,"bookYear":null,"nameKR":"수와래","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":670,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5866762079327,"longitude":126.981536660244,"bookYear":null,"nameKR":"온마을","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":810,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5835711414005,"longitude":126.981703401668,"bookYear":null,"nameKR":"청수정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":1269,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cha.teul/","foodTypes":"디저트/차/베이커리","latitude":37.58214,"longitude":126.9823,"bookYear":null,"nameKR":"차마시는뜰","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":8674,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.584624512021,"longitude":126.982424112988,"bookYear":null,"nameKR":"삼청칼국수쭈꾸미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":9171,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.57789,"longitude":126.9801,"bookYear":null,"nameKR":"스미스가좋아하는한옥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":28919,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bukchon_bingsu","foodTypes":"디저트/차/베이커리","latitude":37.5829940082177,"longitude":126.981984089794,"bookYear":null,"nameKR":"북촌빙수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":28926,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.58428,"longitude":126.981855,"bookYear":null,"nameKR":"츄로101(삼청점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":28942,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.583702,"longitude":126.981292,"bookYear":null,"nameKR":"베테카텐","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":29275,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.586483,"longitude":126.982013,"bookYear":null,"nameKR":"북막골","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":30717,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5801703743746,"longitude":126.986658110742,"bookYear":null,"nameKR":"계동피자","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":32704,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5773251194773,"longitude":126.986607675513,"bookYear":null,"nameKR":"북촌유기농밀명인단팥빵","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":32977,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joyluck2019/","foodTypes":"중식","latitude":37.5861291243407,"longitude":126.98153215336,"bookYear":null,"nameKR":"길운구락부","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":33143,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/foudethe_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5779832161594,"longitude":126.981370580653,"bookYear":null,"nameKR":"푸드떼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":33790,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sorry_espressobar/","foodTypes":"디저트/차/베이커리","latitude":37.5801703743746,"longitude":126.986658110742,"bookYear":null,"nameKR":"쏘리에스프레소바(창덕궁점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":32064,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi_ima/","foodTypes":"일식","latitude":37.606043152338,"longitude":126.916032289155,"bookYear":null,"nameKR":"스시이마","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":33414,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/1307poepos/","foodTypes":"이탈리아식","latitude":37.5857950488912,"longitude":126.909283734473,"bookYear":null,"nameKR":"밀라노기사식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34189,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.huga/","foodTypes":"기타","latitude":37.6348535294175,"longitude":126.919584718281,"bookYear":null,"nameKR":"휴가","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34528,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lazy_slow_","foodTypes":"한식(가금류)","latitude":37.6093364223052,"longitude":126.916502898582,"bookYear":null,"nameKR":"레이지슬로우","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34630,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/delphic_official/","foodTypes":"디저트/차/베이커리","latitude":37.5812983502505,"longitude":126.986852182069,"bookYear":null,"nameKR":"델픽","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":35344,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5831278147902,"longitude":126.906367377206,"bookYear":null,"nameKR":"초밥연구소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":35411,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kkoalapie_seoul","foodTypes":"기타","latitude":37.6011062357842,"longitude":126.930384978308,"bookYear":null,"nameKR":"꼬알라파이서울본점","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":35535,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/i_sagua_","foodTypes":"디저트/차/베이커리","latitude":37.5817100916307,"longitude":126.985221274943,"bookYear":null,"nameKR":"아이사구아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":159,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.58688,"longitude":126.9108,"bookYear":null,"nameKR":"다리원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":174,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.58717,"longitude":126.9167,"bookYear":null,"nameKR":"태조대림감자국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":546,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.58745,"longitude":126.916798,"bookYear":null,"nameKR":"시골감자국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":1573,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5876658340828,"longitude":126.915298312259,"bookYear":null,"nameKR":"동현생고기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":7858,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.587553,"longitude":126.916948,"bookYear":null,"nameKR":"이화감자국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":14079,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.581188,"longitude":126.986922,"bookYear":null,"nameKR":"물나무다방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":14393,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.601432,"longitude":126.921955,"bookYear":null,"nameKR":"산동성수타짜장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":19982,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.584639,"longitude":126.909253,"bookYear":null,"nameKR":"굴렁쇠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":23156,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.6576881871039,"longitude":126.947077806408,"bookYear":null,"nameKR":"평양초계탕막국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":23182,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/______gaya/","foodTypes":"한식(면류)","latitude":37.655382,"longitude":126.948209,"bookYear":null,"nameKR":"가야밀냉면","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":26806,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.583133,"longitude":126.986558,"bookYear":null,"nameKR":"한경헌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"12. 삼청동/안국동/북촌","status":"ACTIVE"},
        {"id":29698,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi_shobu","foodTypes":"일식","latitude":37.6020325214855,"longitude":126.925052027686,"bookYear":null,"nameKR":"스시쇼부","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":6916,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.577079,"longitude":126.928029,"bookYear":null,"nameKR":"모래내설농탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":7943,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.60991,"longitude":126.9317,"bookYear":null,"nameKR":"중화원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":15235,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.58475,"longitude":126.948715,"bookYear":null,"nameKR":"만리장성","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":27819,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.580492358336755,"longitude":126.92552963997048,"bookYear":null,"nameKR":"순이네고릴라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":28286,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gallhyenondong_offical/","foodTypes":"한식(일반한식)","latitude":37.623443,"longitude":126.918751,"bookYear":null,"nameKR":"갈현동할머니떡볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":29771,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.61054343508183,"longitude":126.91657052256338,"bookYear":null,"nameKR":"코스모스분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":31933,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/illo0907","foodTypes":"바/주점","latitude":37.6191213017613,"longitude":126.917995047098,"bookYear":null,"nameKR":"일로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":32196,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hello_saige/","foodTypes":"바/주점","latitude":37.6179002587646,"longitude":126.917572086532,"bookYear":null,"nameKR":"심야식당세이지 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":32215,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/latouchante_eunpyeong","foodTypes":"디저트/차/베이커리","latitude":37.6208493219098,"longitude":126.917243852172,"bookYear":null,"nameKR":"라뚜셩트(연신내점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":33021,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/arenshop99","foodTypes":"디저트/차/베이커리","latitude":37.5912554087153,"longitude":126.912730886361,"bookYear":null,"nameKR":"아렌샵","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":33764,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5828609253195,"longitude":126.925872246238,"bookYear":null,"nameKR":"온정","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":33983,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/island.4199coffee","foodTypes":"디저트/차/베이커리","latitude":37.603701666465,"longitude":126.918613881096,"bookYear":null,"nameKR":"아일랜드4199커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34106,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5976211875103,"longitude":126.913470817451,"bookYear":null,"nameKR":"타이선","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34192,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5993399378289,"longitude":126.948890685724,"bookYear":null,"nameKR":"포방터쭈꾸미","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34496,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6172684395835,"longitude":126.92079319037,"bookYear":null,"nameKR":"메이플크로와상","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":35406,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ep.platz/","foodTypes":"기타","latitude":37.6170477893286,"longitude":126.91802518798,"bookYear":null,"nameKR":"이피플라츠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":30682,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.569619711397664,"longitude":126.91563818790033,"bookYear":null,"nameKR":"닭내장집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":31979,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.57274,"longitude":126.91473,"bookYear":null,"nameKR":"다레젤라또","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":34400,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hotsy_totsy_dining/","foodTypes":"이탈리아식","latitude":37.5815826263255,"longitude":126.908570283798,"bookYear":null,"nameKR":"하씨타씨다이닝","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":6889,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.580956,"longitude":126.935311,"bookYear":null,"nameKR":"에버그린","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":20054,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.578358,"longitude":126.923633,"bookYear":null,"nameKR":"모래내곱창","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":29006,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.583188,"longitude":126.923413,"bookYear":null,"nameKR":"가타쯔무리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"08. 모래내/구파발/홍은동","status":"ACTIVE"},
        {"id":7897,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.5534029664696,"longitude":126.911601468374,"bookYear":null,"nameKR":"닭날다","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":14028,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.552296,"longitude":126.911894,"bookYear":null,"nameKR":"제주돈사돈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":29122,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5525097664697,"longitude":126.906745880876,"bookYear":null,"nameKR":"과일가게","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":29461,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hapjeong_newyorkapartment/","foodTypes":"기타","latitude":37.55100941078739,"longitude":126.91164854350205,"bookYear":null,"nameKR":"뉴욕아파트먼트오리지날그릴","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":33417,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kukje_yangsik/","foodTypes":"일식","latitude":37.5503867908338,"longitude":126.910531427942,"bookYear":null,"nameKR":"국제양식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":34108,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kobasii.sumibi/","foodTypes":"일식","latitude":37.5498197535318,"longitude":126.910242403799,"bookYear":null,"nameKR":"코바시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":34198,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/greenee_kr_official/","foodTypes":"기타","latitude":37.551719135596,"longitude":126.90708206865,"bookYear":null,"nameKR":"그리니망원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":36184,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/choikangkeum/","foodTypes":"기타","latitude":37.5499174135013,"longitude":126.91094549376,"bookYear":null,"nameKR":"최강금돈까스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":29875,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/westside_pasta/","foodTypes":"이탈리아식","latitude":37.555694455767295,"longitude":126.90231750016329,"bookYear":null,"nameKR":"웨스트사이드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":30371,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/visionstroll_coffeemaker/","foodTypes":"디저트/차/베이커리","latitude":37.55720555617928,"longitude":126.90356951744887,"bookYear":null,"nameKR":"비전스트롤","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":31423,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/uglybakery/","foodTypes":"디저트/차/베이커리","latitude":37.5550352603969,"longitude":126.906060981616,"bookYear":null,"nameKR":"어글리베이커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":31585,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5531203453232,"longitude":126.904904186948,"bookYear":null,"nameKR":"카카오다다","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":31719,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ichizen_ten","foodTypes":"일식","latitude":37.5566803113882,"longitude":126.904501286522,"bookYear":null,"nameKR":"이치젠","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":32613,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/two_thumbsup_/","foodTypes":"디저트/차/베이커리","latitude":37.5543483358359,"longitude":126.90719514485,"bookYear":null,"nameKR":"투떰즈업","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":33642,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brownfrontdoor/","foodTypes":"디저트/차/베이커리","latitude":37.555420276267,"longitude":126.905183858106,"bookYear":null,"nameKR":"브라운프론트도어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":34046,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cannelle_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5612427464308,"longitude":126.901716956462,"bookYear":null,"nameKR":"꺄넬","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":34285,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/regularservice/","foodTypes":"디저트/차/베이커리","latitude":37.5579535116605,"longitude":126.904280437588,"bookYear":null,"nameKR":"레귤러서비스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":34461,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5564409355061,"longitude":126.90598452169,"bookYear":null,"nameKR":"우이락(망원본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":34687,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/weekend__cake/","foodTypes":"디저트/차/베이커리","latitude":37.5541526643254,"longitude":126.902967418629,"bookYear":null,"nameKR":"위켄드케이크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":36356,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeong_gak92/","foodTypes":"한식(육류)","latitude":37.5544803557495,"longitude":126.904068382249,"bookYear":null,"nameKR":"정각","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":27081,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_nream/","foodTypes":"디저트/차/베이커리","latitude":37.5588289921651,"longitude":126.900552012402,"bookYear":null,"nameKR":"카페느림","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":28628,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tokyobingsu","foodTypes":"디저트/차/베이커리","latitude":37.556466,"longitude":126.905163,"bookYear":null,"nameKR":"도쿄빙수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":29120,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5548213225525,"longitude":126.905108225614,"bookYear":null,"nameKR":"망원동티라미수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":7861,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.556808,"longitude":126.908872,"bookYear":null,"nameKR":"합정동원조황소곱창구이전문","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":12629,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.540082,"longitude":126.942878,"bookYear":null,"nameKR":"봉피양(마포점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":19821,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5423544340511,"longitude":126.943758267542,"bookYear":null,"nameKR":"원조양평신내서울해장국(마포직영점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29553,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hyj7508/","foodTypes":"바/주점","latitude":37.54297926704307,"longitude":126.93954381165645,"bookYear":null,"nameKR":"이박사의신동막걸리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29977,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_bam_bi","foodTypes":"디저트/차/베이커리","latitude":37.55548780193347,"longitude":126.90983700931774,"bookYear":null,"nameKR":"카페밤비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":30474,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.539517771459224,"longitude":126.94347514894615,"bookYear":null,"nameKR":"마루심(마포점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":30656,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gourmet_odd/","foodTypes":"이탈리아식","latitude":37.5877639350058,"longitude":126.981850017255,"bookYear":null,"nameKR":"고메오드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":31091,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/your_dining","foodTypes":"이탈리아식","latitude":37.5544353519126,"longitude":126.903792753464,"bookYear":null,"nameKR":"유어다이닝","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":31857,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe__presse","foodTypes":"디저트/차/베이커리","latitude":37.5538585357425,"longitude":126.909846894173,"bookYear":null,"nameKR":"프레쎄","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":33163,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bellatortilla_westvillage/","foodTypes":"중남미식","latitude":37.5534796848406,"longitude":126.905717651467,"bookYear":null,"nameKR":"벨라또띠아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":33238,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leebukbang/","foodTypes":"한식(육류)","latitude":37.5397308784185,"longitude":126.943142441441,"bookYear":null,"nameKR":"리북방","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":33285,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nondry_seoul/","foodTypes":"기타","latitude":37.5538237462899,"longitude":126.905567945655,"bookYear":null,"nameKR":"논드라이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":33363,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5410485366541,"longitude":126.944736764955,"bookYear":null,"nameKR":"우동이요이요","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":34004,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woongjang_bistro/","foodTypes":"바/주점","latitude":37.5535844404578,"longitude":126.905792438371,"bookYear":null,"nameKR":"웅장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":35903,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5399742776869,"longitude":126.943788908605,"bookYear":null,"nameKR":"용강동숯불쭈꾸미구이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":35911,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/ramen_menji/","foodTypes":"일식","latitude":37.5549409780941,"longitude":126.910417677487,"bookYear":null,"nameKR":"멘지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"03. 망원동/합정동","status":"ACTIVE"},
        {"id":31321,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/okmyeon_ga","foodTypes":"한식(면류)","latitude":37.5457124747106,"longitude":126.945914811434,"bookYear":null,"nameKR":"옥면가","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":32109,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bbangzip_da/","foodTypes":"디저트/차/베이커리","latitude":37.552196546223,"longitude":126.955495945044,"bookYear":null,"nameKR":"빵을집다","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":32658,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/melrose_alldaybrunch/","foodTypes":"기타","latitude":37.55099812968,"longitude":126.953625787034,"bookYear":null,"nameKR":"멜로즈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":32694,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5456376714898,"longitude":126.945870507339,"bookYear":null,"nameKR":"달팽이가그린집황소곱창 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":34287,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jinho_raphael_lee/","foodTypes":"디저트/차/베이커리","latitude":37.5491059448178,"longitude":126.94580213806,"bookYear":null,"nameKR":"퍼넬스페셜티커피하우스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36419,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brewery304","foodTypes":"바/주점","latitude":37.5476927069681,"longitude":126.939102522345,"bookYear":null,"nameKR":"브루어리304(경의숲길2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":917,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5480373333338,"longitude":126.945547278501,"bookYear":null,"nameKR":"매일스시횟집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":974,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.53834,"longitude":126.945,"bookYear":null,"nameKR":"현래장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":9069,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.537532,"longitude":126.944528,"bookYear":null,"nameKR":"마포구이구이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":12580,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5372715088061,"longitude":126.94466046347,"bookYear":null,"nameKR":"천지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":28154,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.54341,"longitude":126.95289,"bookYear":null,"nameKR":"정미스시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":28582,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.541179,"longitude":126.941343,"bookYear":null,"nameKR":"서씨네해장국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29091,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/before.it.melts/","foodTypes":"디저트/차/베이커리","latitude":37.5466789101409,"longitude":126.945574746095,"bookYear":null,"nameKR":"녹기전에","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29836,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwanginsang.bread_office/","foodTypes":"디저트/차/베이커리","latitude":37.543500716001624,"longitude":126.95288666172718,"bookYear":null,"nameKR":"황인상브레드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":78,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.542067,"longitude":126.950798,"bookYear":null,"nameKR":"굴다리식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":7320,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5413315500222,"longitude":126.948961312534,"bookYear":null,"nameKR":"마포나루(아크로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":8345,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5412173019758,"longitude":126.954233813363,"bookYear":null,"nameKR":"보난자","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":9068,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5420230834437,"longitude":126.950695930047,"bookYear":null,"nameKR":"고바우","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":9189,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5415400527035,"longitude":126.950684367131,"bookYear":null,"nameKR":"굴다리식당(2호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":13158,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.540014,"longitude":126.948738,"bookYear":null,"nameKR":"다락떡볶이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":16815,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.541624,"longitude":126.95068,"bookYear":null,"nameKR":"장수갈매기","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":18656,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5418737704976,"longitude":126.951965603101,"bookYear":null,"nameKR":"원합천돼지국밥","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":19737,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.53986,"longitude":126.947932,"bookYear":null,"nameKR":"서산꽃게","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29144,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seokyungdorok/","foodTypes":"한식(육류)","latitude":37.538376,"longitude":126.947608,"bookYear":null,"nameKR":"서경도락","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":30897,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5418207872926,"longitude":126.952623733477,"bookYear":null,"nameKR":"문스타파","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":34389,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dohwa_apt/","foodTypes":"디저트/차/베이커리","latitude":37.5395404996998,"longitude":126.949490374862,"bookYear":null,"nameKR":"도화아파트먼트마포","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":35369,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/supreme__cow/","foodTypes":"한식(육류)","latitude":37.5423140022795,"longitude":126.948966526798,"bookYear":null,"nameKR":"우월소곱창(마포직영점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":35500,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/burggernaut/","foodTypes":"기타","latitude":37.5410675293711,"longitude":126.951023454276,"bookYear":null,"nameKR":"버거넛","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":35684,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5417131179302,"longitude":126.952849088404,"bookYear":null,"nameKR":"자쿠자쿠(공덕본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":35866,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.539519266655,"longitude":126.947697765321,"bookYear":null,"nameKR":"고레카레(마포역점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":35885,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gop_official_/","foodTypes":"한식(육류)","latitude":37.5410415983081,"longitude":126.949356635462,"bookYear":null,"nameKR":"곱(마포점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":31789,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5447477438441,"longitude":126.948258201287,"bookYear":null,"nameKR":"아이엠베이글","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":33947,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5447477438441,"longitude":126.948258201287,"bookYear":null,"nameKR":"더플레이스공간","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":34167,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/__mapori__","foodTypes":"이탈리아식","latitude":37.5460845064692,"longitude":126.94176646627,"bookYear":null,"nameKR":"마포리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":36681,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sunmaillard/","foodTypes":"디저트/차/베이커리","latitude":37.5473148398077,"longitude":126.94162326574,"bookYear":null,"nameKR":"썬마이야르","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":1419,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.540687,"longitude":126.948342,"bookYear":null,"nameKR":"라스텔라","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":13104,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.544803180199,"longitude":126.953746742484,"bookYear":null,"nameKR":"마포청학동부침개","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":14024,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.544803180199,"longitude":126.953746742484,"bookYear":null,"nameKR":"마포할머니빈대떡","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":21918,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/topcloudcorp/","foodTypes":"기타","latitude":37.543193,"longitude":126.951604,"bookYear":null,"nameKR":"탑클라우드23","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29230,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jugamirak/","foodTypes":"바/주점","latitude":37.5447477438441,"longitude":126.948258201287,"bookYear":null,"nameKR":"주가미락","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29519,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.544749366441955,"longitude":126.94826001068026,"bookYear":null,"nameKR":"이요이요(파크자이3호점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":515,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.586612,"longitude":127.006676,"bookYear":null,"nameKR":"손칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":7720,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/menyasandaime_hyehwa/","foodTypes":"일식","latitude":37.581765,"longitude":126.999708,"bookYear":null,"nameKR":"멘야산다이메(대학로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":7824,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.582779,"longitude":127.001309,"bookYear":null,"nameKR":"혜화돌쇠아저씨","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":7886,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.544602,"longitude":126.95312,"bookYear":null,"nameKR":"소문난집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":8265,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5447947887537,"longitude":126.953258247848,"bookYear":null,"nameKR":"궁중족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":8302,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.586293,"longitude":127.001535,"bookYear":null,"nameKR":"혜화동콩집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":9097,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.57474,"longitude":126.9977,"bookYear":null,"nameKR":"원남복집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":13282,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.544602,"longitude":126.953119,"bookYear":null,"nameKR":"마포오향족발","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":14488,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.546322,"longitude":126.95455,"bookYear":null,"nameKR":"안면도꽃게간장게장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":29169,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/germany_house/","foodTypes":"바/주점","latitude":37.582914,"longitude":127.000538,"bookYear":null,"nameKR":"독일주택","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":29378,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ki___iro","foodTypes":"디저트/차/베이커리","latitude":37.582067,"longitude":126.999726,"bookYear":null,"nameKR":"카페키이로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":29621,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.58246199006161,"longitude":127.00106874242961,"bookYear":null,"nameKR":"스노브(대학로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":32650,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5452987922319,"longitude":126.952992351329,"bookYear":null,"nameKR":"영광보쌈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":33076,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dep_trai_korea/","foodTypes":"아시아식","latitude":37.582391804911,"longitude":126.999218369618,"bookYear":null,"nameKR":"뎁짜이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":33558,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5742339287421,"longitude":127.001326498494,"bookYear":null,"nameKR":"이에스티디2017","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":33562,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5742339287421,"longitude":127.001326498494,"bookYear":null,"nameKR":"허밍벨라베이커리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":33852,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5459544226874,"longitude":126.955242785247,"bookYear":null,"nameKR":"봉평옹심이메밀칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"06. 마포","status":"ACTIVE"},
        {"id":34110,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/6bunjiburger/","foodTypes":"기타","latitude":37.5740364165469,"longitude":126.997742196401,"bookYear":null,"nameKR":"6번지버거","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":35134,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.582954200073,"longitude":127.001335597187,"bookYear":null,"nameKR":"고기하다(혜화점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":35325,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5757529002483,"longitude":127.002321928934,"bookYear":null,"nameKR":"스트렝스커피(혜화점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":32339,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5810076289824,"longitude":127.004125899517,"bookYear":null,"nameKR":"두두","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":34774,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/shoutadonburi/","foodTypes":"일식","latitude":37.5888470522643,"longitude":126.995141561981,"bookYear":null,"nameKR":"쇼타돈부리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":35659,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5818105714383,"longitude":127.00109839499,"bookYear":null,"nameKR":"카페첫만남","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":738,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5821172418901,"longitude":127.002785963181,"bookYear":null,"nameKR":"장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":1301,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/taschenkr/","foodTypes":"디저트/차/베이커리","latitude":37.5829715425845,"longitude":127.003577707596,"bookYear":null,"nameKR":"타셴","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":1311,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.58487,"longitude":126.9975,"bookYear":null,"nameKR":"페르시안궁전","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":4204,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.583287,"longitude":127.004333,"bookYear":null,"nameKR":"마미청국장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":8296,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.582091,"longitude":127.001101,"bookYear":null,"nameKR":"솔나무길된장예술","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":8303,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.580782,"longitude":127.004352,"bookYear":null,"nameKR":"국수가","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":9099,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.580368431321,"longitude":127.004973588737,"bookYear":null,"nameKR":"담아","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":13016,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.58657,"longitude":126.9968,"bookYear":null,"nameKR":"일송칼국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":18230,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.5881387159957,"longitude":126.99576750387,"bookYear":null,"nameKR":"벌교영양추어탕","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":29037,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.581773,"longitude":127.001084,"bookYear":null,"nameKR":"정돈","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":29799,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.583982857290586,"longitude":126.99815230600863,"bookYear":null,"nameKR":"천향록","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"24. 대학로/혜화동","status":"ACTIVE"},
        {"id":102,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5720555353472,"longitude":126.981709615148,"bookYear":null,"nameKR":"깡장집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":7089,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.568549,"longitude":126.978878,"bookYear":null,"nameKR":"강가(무교점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":7901,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.570701,"longitude":126.979744,"bookYear":null,"nameKR":"피마길실비집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":9409,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.568187,"longitude":126.976513,"bookYear":null,"nameKR":"대상해","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":14531,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.575187,"longitude":126.970161,"bookYear":null,"nameKR":"예조","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":28954,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.568229,"longitude":126.975834,"bookYear":null,"nameKR":"광화문몽로","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":28959,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"미국식","latitude":37.570923,"longitude":126.978916,"bookYear":null,"nameKR":"폴리스피자","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29225,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.571355,"longitude":126.978921,"bookYear":null,"nameKR":"한육감(디타워점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":30055,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cuchara_official","foodTypes":"중남미식","latitude":37.57096024357674,"longitude":126.98144024815856,"bookYear":null,"nameKR":"쿠차라(종로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":33233,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5684232177354,"longitude":126.97825653257,"bookYear":null,"nameKR":"일도씨닭갈비(광화문점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":33445,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/felt_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5709958592808,"longitude":126.978914477333,"bookYear":null,"nameKR":"펠트커피(광화문점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":33495,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5684232177354,"longitude":126.97825653257,"bookYear":null,"nameKR":"이스트빌리지서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":30456,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/deer_ie","foodTypes":"일식","latitude":37.572590308114826,"longitude":126.96927771167068,"bookYear":null,"nameKR":"시카노이에","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":32942,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/negi.company/","foodTypes":"바/주점","latitude":37.5687030064764,"longitude":126.97198395166,"bookYear":null,"nameKR":"네기실비","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":34165,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/terrace222/","foodTypes":"이탈리아식","latitude":37.5727280927581,"longitude":126.969019107414,"bookYear":null,"nameKR":"테라스222","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":35521,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5696996246023,"longitude":126.974907969051,"bookYear":null,"nameKR":"멘소레","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":36420,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pizzeriahkpk","foodTypes":"미국식","latitude":37.5703555223451,"longitude":126.974363389883,"bookYear":null,"nameKR":"피제리아호키포키(광화문점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":256,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"프랑스식","latitude":37.5709578373114,"longitude":126.977928770123,"bookYear":null,"nameKR":"라브리","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":356,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.570844667978704,"longitude":126.9799234417087,"bookYear":null,"nameKR":"미진","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":1354,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.569305,"longitude":126.974738,"bookYear":null,"nameKR":"중화","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":3777,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57167,"longitude":126.9717,"bookYear":null,"nameKR":"안성또순이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":4959,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5708444878121,"longitude":126.979923668151,"bookYear":null,"nameKR":"감촌","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":6436,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.5708444878121,"longitude":126.979923668151,"bookYear":null,"nameKR":"두르가","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":7337,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.570994,"longitude":126.980451,"bookYear":null,"nameKR":"옥토버훼스트(종로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":8491,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5708444878121,"longitude":126.979923668151,"bookYear":null,"nameKR":"막내낙지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":8649,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.570442,"longitude":126.976163,"bookYear":null,"nameKR":"스파게티가있는풍경","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":8852,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.57039,"longitude":126.974,"bookYear":null,"nameKR":"중화요리산동","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":12922,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5708444878121,"longitude":126.979923668151,"bookYear":null,"nameKR":"한마루화로구이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":19085,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.570507,"longitude":126.973911,"bookYear":null,"nameKR":"감나무집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":26104,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.5705081504947,"longitude":126.976078259106,"bookYear":null,"nameKR":"옴레스토랑","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":26800,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.57544,"longitude":126.9798,"bookYear":null,"nameKR":"테라로사(광화문점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29294,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"유럽식","latitude":37.5696425113058,"longitude":126.972139469043,"bookYear":null,"nameKR":"엘꾸비또","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":206,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.571394,"longitude":126.975249,"bookYear":null,"nameKR":"동성각","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":518,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.573625,"longitude":126.974091,"bookYear":null,"nameKR":"송백부대찌개","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":4243,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.56984295696779,"longitude":126.98110999560605,"bookYear":null,"nameKR":"신승관","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":7196,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.571447,"longitude":126.976068,"bookYear":null,"nameKR":"고려삼계탕(세종로점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":8843,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.569626,"longitude":126.979457,"bookYear":null,"nameKR":"신성","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29563,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.57297714574931,"longitude":126.9743534352524,"bookYear":null,"nameKR":"루뽀","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":30040,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.57105631412499,"longitude":126.97477065877098,"bookYear":null,"nameKR":"화로담","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":30644,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.57069965284965,"longitude":126.97540015123504,"bookYear":null,"nameKR":"마루","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":34584,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5725639351805,"longitude":126.981506298154,"bookYear":null,"nameKR":"자후","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":34656,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_horse_gwanghwamun/","foodTypes":"디저트/차/베이커리","latitude":37.5736488068784,"longitude":126.974213175414,"bookYear":null,"nameKR":"카페홀스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":30242,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/naeja_and_co/","foodTypes":"디저트/차/베이커리","latitude":37.57578013990074,"longitude":126.97049447755566,"bookYear":null,"nameKR":"내자상회","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":32282,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5727859643735,"longitude":126.971638861492,"bookYear":null,"nameKR":"신안촌(경희궁점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":32695,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5735669794965,"longitude":126.972154874023,"bookYear":null,"nameKR":"사발주인장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":33555,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5718394925759,"longitude":126.973284555591,"bookYear":null,"nameKR":"세종커피","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":35328,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/addiodosi_official/","foodTypes":"바/주점","latitude":37.5756066648014,"longitude":126.971457005197,"bookYear":null,"nameKR":"아디오도시","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":611,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.57217,"longitude":126.982453,"bookYear":null,"nameKR":"엉클조","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":627,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seoultrainhouse/","foodTypes":"한식(일반한식)","latitude":37.57166,"longitude":126.9828,"bookYear":null,"nameKR":"열차집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":689,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.56932813776144,"longitude":126.98493609488024,"bookYear":null,"nameKR":"우정낙지","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":702,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.573264,"longitude":126.971996,"bookYear":null,"nameKR":"위치스테이블","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":6415,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.57281,"longitude":126.9714,"bookYear":null,"nameKR":"커피투어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":8503,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.57152,"longitude":126.975207,"bookYear":null,"nameKR":"종로빈대떡","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":8734,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.571675,"longitude":126.982837,"bookYear":null,"nameKR":"공평동꼼장어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":15756,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5755529729625,"longitude":126.970751200147,"bookYear":null,"nameKR":"소야원","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":15815,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5753929440769,"longitude":126.971067100597,"bookYear":null,"nameKR":"남도한정식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":20446,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5723482573739,"longitude":126.973969793352,"bookYear":null,"nameKR":"보길도","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29044,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.575218,"longitude":126.971025,"bookYear":null,"nameKR":"텐더바서울","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":29231,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/live.emoi.love/","foodTypes":"아시아식","latitude":37.569802,"longitude":126.984601,"bookYear":null,"nameKR":"에머이","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"11. 광화문/종로2가","status":"ACTIVE"},
        {"id":12632,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.651458,"longitude":127.044784,"bookYear":null,"nameKR":"하누소","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":14419,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.6143227843788,"longitude":127.04166395906,"bookYear":null,"nameKR":"서향&아리산","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":24099,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.6554754454458,"longitude":127.045868861866,"bookYear":null,"nameKR":"더차우","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":35747,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ssangling_","foodTypes":"중식","latitude":37.6469371396782,"longitude":127.036168747029,"bookYear":null,"nameKR":"링링","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":36101,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.6400027347758,"longitude":127.040280155361,"bookYear":null,"nameKR":"창골멸치국수","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":31727,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6413012911597,"longitude":127.0315024438,"bookYear":null,"nameKR":"진호횟집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":32153,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ifure0623/","foodTypes":"이탈리아식","latitude":37.6779396581031,"longitude":127.04355905248,"bookYear":null,"nameKR":"이퓨레","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":32628,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baguette1089","foodTypes":"디저트/차/베이커리","latitude":37.6657563099309,"longitude":127.04363897722,"bookYear":null,"nameKR":"바게트1089","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":32647,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6532092027291,"longitude":127.04679058523,"bookYear":null,"nameKR":"창동포크","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":33176,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gute_leute_/","foodTypes":"디저트/차/베이커리","latitude":37.6369305402999,"longitude":127.02328159303,"bookYear":null,"nameKR":"구테로이테수유에피소드","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":33540,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/camino_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.6620772553912,"longitude":127.034950599693,"bookYear":null,"nameKR":"카미노커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":34578,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6473362861152,"longitude":127.00852394328,"bookYear":null,"nameKR":"밀당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":35254,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tulbo_beef/","foodTypes":"한식(육류)","latitude":37.6422672859788,"longitude":127.0240167946,"bookYear":null,"nameKR":"털보정육식당","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":35568,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.6412372558665,"longitude":127.028199963405,"bookYear":null,"nameKR":"이백돈(수유본점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":7860,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.685548,"longitude":127.041757,"bookYear":null,"nameKR":"영수네감자국","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":14428,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.634396,"longitude":127.018403,"bookYear":null,"nameKR":"쎄쎄","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":15105,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.6719786416586,"longitude":127.006184608093,"bookYear":null,"nameKR":"백운산장","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":19941,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.637604,"longitude":127.023123,"bookYear":null,"nameKR":"풍천장어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":22359,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.662053,"longitude":127.041592,"bookYear":null,"nameKR":"사거리짬뽕집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":26090,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.662945,"longitude":127.019329,"bookYear":null,"nameKR":"우이정풍천장어","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":26373,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.653479,"longitude":127.046465,"bookYear":null,"nameKR":"사누끼","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":29772,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.65244201847206,"longitude":127.03479842692573,"bookYear":null,"nameKR":"호호분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":3396,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.525223,"longitude":127.043602,"bookYear":null,"nameKR":"미엘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":3924,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.524766,"longitude":127.044121,"bookYear":null,"nameKR":"먼데이투선데이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":5029,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5243352600843,"longitude":127.044788076146,"bookYear":null,"nameKR":"몽중헌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":9064,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.6400798197791,"longitude":127.028330615875,"bookYear":null,"nameKR":"황주집","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":13168,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.6125852455048,"longitude":127.028957382492,"bookYear":null,"nameKR":"제일분식","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":15532,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.6245309798144,"longitude":127.02751194401,"bookYear":null,"nameKR":"다래성","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":16387,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.612272,"longitude":127.031945,"bookYear":null,"nameKR":"바다포차","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":30038,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.onion","foodTypes":"디저트/차/베이커리","latitude":37.624030263249594,"longitude":127.02843259023446,"bookYear":null,"nameKR":"어니언(미아점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":30930,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.6351759144779,"longitude":127.024406169955,"bookYear":null,"nameKR":"통영산꼼장어 ","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":31029,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5251927478858,"longitude":127.045718384766,"bookYear":null,"nameKR":"스시카나에","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32047,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5242459413056,"longitude":127.042677653468,"bookYear":null,"nameKR":"보메청담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33232,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6127653965327,"longitude":127.031259465098,"bookYear":null,"nameKR":"일도씨닭갈비(미아점)","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":35390,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/noc_bakeshop","foodTypes":"디저트/차/베이커리","latitude":37.6125852455048,"longitude":127.028957382492,"bookYear":null,"nameKR":"녹베이크샵","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":35519,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baker_ahn","foodTypes":"디저트/차/베이커리","latitude":37.6190655242978,"longitude":127.019738947711,"bookYear":null,"nameKR":"베이커안","ribbonType":"정보없음","zone2_1":"서울 강북","zone2_2":"31. 강북구/도봉구","status":"ACTIVE"},
        {"id":31333,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daily_cheongdam/","foodTypes":"바/주점","latitude":37.5244973932097,"longitude":127.041463043475,"bookYear":null,"nameKR":"데일리청담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":52,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_goshen/","foodTypes":"디저트/차/베이커리","latitude":37.525277,"longitude":127.041478,"bookYear":null,"nameKR":"고센","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":6380,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeelusso_official/","foodTypes":"디저트/차/베이커리","latitude":37.525268,"longitude":127.041252,"bookYear":null,"nameKR":"커피루소","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":26654,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maison_de_la_categorie/","foodTypes":"프랑스식","latitude":37.52554,"longitude":127.0402,"bookYear":null,"nameKR":"메종드라카테고리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28618,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.524195,"longitude":127.040162,"bookYear":null,"nameKR":"스틸","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29260,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cucciologroup/","foodTypes":"이탈리아식","latitude":37.524534,"longitude":127.042293,"bookYear":null,"nameKR":"쿠촐로테라짜","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29565,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pearlshell_oyster/","foodTypes":"기타","latitude":37.5265894435817,"longitude":127.04090771615292,"bookYear":null,"nameKR":"펄쉘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":808,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.52242,"longitude":127.0551,"bookYear":null,"nameKR":"청담복집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":3762,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":37.525883,"longitude":127.041595,"bookYear":null,"nameKR":"퀸스파크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":13335,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5243847204375,"longitude":127.053064320632,"bookYear":null,"nameKR":"장독대김치찌개","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":23719,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.52431,"longitude":127.051839,"bookYear":null,"nameKR":"긴자(청담점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30231,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/horumon.kr/","foodTypes":"일식","latitude":37.524198614517324,"longitude":127.05337291001398,"bookYear":null,"nameKR":"호루몬","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31374,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mitasu_cheongdam/ ","foodTypes":"바/주점","latitude":37.5231915241413,"longitude":127.048886462588,"bookYear":null,"nameKR":"미타스청담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31490,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tridseoul/","foodTypes":"컨템포러리","latitude":37.5267080116008,"longitude":127.041691658114,"bookYear":null,"nameKR":"트리드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33035,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/a5__official/","foodTypes":"한식(육류)","latitude":37.5224089749768,"longitude":127.054313613882,"bookYear":null,"nameKR":"에이오","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33897,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/butterandshelter/","foodTypes":"디저트/차/베이커리","latitude":37.5200198304195,"longitude":127.054216748317,"bookYear":null,"nameKR":"버터앤쉘터","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":35933,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5238129525119,"longitude":127.051622809365,"bookYear":null,"nameKR":"우천식육식단(청담본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30477,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kitchen_nomenu/","foodTypes":"기타","latitude":37.523074791205865,"longitude":127.04300086160363,"bookYear":null,"nameKR":"키친노메뉴","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32069,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"컨템포러리","latitude":37.5230546690263,"longitude":127.042083071774,"bookYear":null,"nameKR":"드레스덴그린","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32620,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5227092358102,"longitude":127.040766794512,"bookYear":null,"nameKR":"야단","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32676,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushisankowoo","foodTypes":"일식","latitude":37.5226041599715,"longitude":127.041851367598,"bookYear":null,"nameKR":"스시산코우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32881,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mukyang2food/","foodTypes":"한식(육류)","latitude":37.5226490357306,"longitude":127.040543116022,"bookYear":null,"nameKR":"영동교집(청담직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":36546,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5187894406336,"longitude":127.048580547572,"bookYear":null,"nameKR":"강정이넘치는집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":214,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.522564,"longitude":127.041838,"bookYear":null,"nameKR":"두루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":16323,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tokyosweets/","foodTypes":"디저트/차/베이커리","latitude":37.518871,"longitude":127.044158,"bookYear":null,"nameKR":"도쿄스위츠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":20856,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5184715536895,"longitude":127.041012895002,"bookYear":null,"nameKR":"원할머니보쌈(강남구청역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":26761,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.518985,"longitude":127.046119,"bookYear":null,"nameKR":"밍글","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":27865,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5233111622682,"longitude":127.044119009562,"bookYear":null,"nameKR":"라메종뒤쇼콜라","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":6855,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.522048568199,"longitude":127.04169144087,"bookYear":null,"nameKR":"마토","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":13146,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.522666,"longitude":127.042621,"bookYear":null,"nameKR":"옛날집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29219,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cattle_dryaging","foodTypes":"한식(육류)","latitude":37.522061,"longitude":127.041867,"bookYear":null,"nameKR":"꿰뚫","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29903,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/shokudokai","foodTypes":"바/주점","latitude":37.522411888875844,"longitude":127.04234889017927,"bookYear":null,"nameKR":"쇼쿠도카이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30734,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lagrandejatte_seoul","foodTypes":"디저트/차/베이커리","latitude":37.5226559286982,"longitude":127.042963516601,"bookYear":null,"nameKR":"라그랑자트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31302,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sigolo.seoul","foodTypes":"기타","latitude":37.5220707009882,"longitude":127.042035573101,"bookYear":null,"nameKR":"시고로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32396,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5222618849225,"longitude":127.041551286792,"bookYear":null,"nameKR":"청담고수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32615,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5225407915642,"longitude":127.043180421695,"bookYear":null,"nameKR":"호시쿠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32641,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/robatalibra/","foodTypes":"일식","latitude":37.522867715501,"longitude":127.0438052815,"bookYear":null,"nameKR":"로바타 라이브라 카운터 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33303,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5222660297875,"longitude":127.04231192957,"bookYear":null,"nameKR":"스시렌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":34164,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moremanymore/","foodTypes":"기타","latitude":37.5223586388498,"longitude":127.044315622673,"bookYear":null,"nameKR":"모어매니모어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":35446,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/gnl_dining_bar","foodTypes":"기타","latitude":37.5227335976367,"longitude":127.044418108471,"bookYear":null,"nameKR":"청담그늘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31298,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/smtown_smthouse/","foodTypes":"중식","latitude":37.5253871907651,"longitude":127.052017353008,"bookYear":null,"nameKR":"차이나룸","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31598,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hyunnewooo/","foodTypes":"디저트/차/베이커리","latitude":37.5232038080323,"longitude":127.046353270853,"bookYear":null,"nameKR":"오묘한과자점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32599,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mwd_cheongdam/","foodTypes":"이탈리아식","latitude":37.5258239738753,"longitude":127.046908317027,"bookYear":null,"nameKR":"명월당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33921,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"컨템포러리","latitude":37.5253737056184,"longitude":127.048780188752,"bookYear":null,"nameKR":"빈트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33959,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5254308344451,"longitude":127.049201853961,"bookYear":null,"nameKR":"첼시스하이볼(청담점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":34092,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/talon_cheongdam/","foodTypes":"바/주점","latitude":37.525763104745,"longitude":127.050527938079,"bookYear":null,"nameKR":"탈롱드청담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":34177,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/j_u_cho/","foodTypes":"바/주점","latitude":37.5259960816311,"longitude":127.05141457281,"bookYear":null,"nameKR":"삼미집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":34200,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5253737056184,"longitude":127.048780188752,"bookYear":null,"nameKR":"코이에텐","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":34311,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5252610329763,"longitude":127.047571006188,"bookYear":null,"nameKR":"피플더테라스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":972,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.521902,"longitude":127.040128,"bookYear":null,"nameKR":"현대정육식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":6644,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.525239,"longitude":127.050265,"bookYear":null,"nameKR":"지유가오카핫초메(청담점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":12628,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.520635,"longitude":127.046502,"bookYear":null,"nameKR":"봉피양(청담점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":22273,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5224162613037,"longitude":127.056828126838,"bookYear":null,"nameKR":"국수전","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29188,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.522596,"longitude":127.044785,"bookYear":null,"nameKR":"가디록","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":359,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.515519,"longitude":127.039995,"bookYear":null,"nameKR":"민들레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":5195,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.522166,"longitude":127.040277,"bookYear":null,"nameKR":"양마니(청담점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":5196,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.515834,"longitude":127.042281,"bookYear":null,"nameKR":"청담면옥함흥냉면","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":7037,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.51549,"longitude":127.042352,"bookYear":null,"nameKR":"지유가오카핫초메(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":26790,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/luka511_seoul","foodTypes":"디저트/차/베이커리","latitude":37.527016821141,"longitude":127.046881347002,"bookYear":null,"nameKR":"루카511","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":28965,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.52535,"longitude":127.047826,"bookYear":null,"nameKR":"페어링룸","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29165,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.527044,"longitude":127.045747,"bookYear":null,"nameKR":"한라산식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29595,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.515156908501694,"longitude":127.04263435823653,"bookYear":null,"nameKR":"김영모과자점(강남구청점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30255,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.522927137371,"longitude":127.039860222522,"bookYear":null,"nameKR":"스시카이세이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30997,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/shorin_/","foodTypes":"바/주점","latitude":37.5255537852377,"longitude":127.047752989057,"bookYear":null,"nameKR":"쇼린","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31001,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5255629847983,"longitude":127.048173718653,"bookYear":null,"nameKR":"호시카츠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31600,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5269009583537,"longitude":127.045716712119,"bookYear":null,"nameKR":"스시전","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":31914,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/levain_rules","foodTypes":"디저트/차/베이커리","latitude":37.5155511161052,"longitude":127.042332457469,"bookYear":null,"nameKR":"르뱅룰즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33132,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafem_chungdam/","foodTypes":"디저트/차/베이커리","latitude":37.5166377998582,"longitude":127.051864456444,"bookYear":null,"nameKR":"카페엠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":35488,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5152392437758,"longitude":127.052517618746,"bookYear":null,"nameKR":"카페싸이공","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30010,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wando1957","foodTypes":"한식(어패류)","latitude":37.51654171724045,"longitude":127.0401601992311,"bookYear":null,"nameKR":"완도1957","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":30574,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.518065499793934,"longitude":127.05131433917428,"bookYear":null,"nameKR":"청담하게","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32859,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jinjunbok_kr/","foodTypes":"한식(가금류)","latitude":37.5158144675278,"longitude":127.039774545019,"bookYear":null,"nameKR":"진전복삼계탕 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33393,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5138516773763,"longitude":127.041982890976,"bookYear":null,"nameKR":"판도숯불구이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":33849,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sochul_sep/","foodTypes":"바/주점","latitude":37.5174807661856,"longitude":127.040619500921,"bookYear":null,"nameKR":"구월의소철","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":34173,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/latespring915/","foodTypes":"기타","latitude":37.5176166251155,"longitude":127.046743685239,"bookYear":null,"nameKR":"만춘915","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":34199,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.516242883757,"longitude":127.050632935983,"bookYear":null,"nameKR":"스시타","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":34224,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5179044983754,"longitude":127.039854496609,"bookYear":null,"nameKR":"기와집능이백숙삼계탕해천탕강남직영점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":34388,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/phoinviet/","foodTypes":"아시아식","latitude":37.5160155758052,"longitude":127.040293617765,"bookYear":null,"nameKR":"포인비에트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":36158,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/doritory_gangnam/","foodTypes":"바/주점","latitude":37.5140814568131,"longitude":127.041662237297,"bookYear":null,"nameKR":"도리토리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":5703,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/parkgo_/","foodTypes":"바/주점","latitude":37.515817,"longitude":127.040751,"bookYear":null,"nameKR":"박고볼래실내포차(논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":29335,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.516467,"longitude":127.040419,"bookYear":null,"nameKR":"스시나미키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"03. 청담동/강남구청역","status":"ACTIVE"},
        {"id":32206,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nakajima_goemong","foodTypes":"일식","latitude":37.5236586861947,"longitude":126.906430209934,"bookYear":null,"nameKR":"나카지마고에몽","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":33650,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sun_u_yongssuk/","foodTypes":"바/주점","latitude":37.5368335680459,"longitude":126.895057595193,"bookYear":null,"nameKR":"선유용숙","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":34732,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/foftcoffee/","foodTypes":"바/주점","latitude":37.51844919471,"longitude":126.914161730212,"bookYear":null,"nameKR":"포프트커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":34754,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5347784618069,"longitude":126.897295483431,"bookYear":null,"nameKR":"서울초밥당산점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":35197,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/avenue_428/","foodTypes":"유럽식","latitude":37.5217236428511,"longitude":126.902251510937,"bookYear":null,"nameKR":"애비뉴428","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":35896,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wolhwa_sikdang_ydp/","foodTypes":"한식(육류)","latitude":37.5182169354733,"longitude":126.909128852123,"bookYear":null,"nameKR":"월화식당(영등포점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":35941,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5351198626312,"longitude":126.89845517932,"bookYear":null,"nameKR":"서울객점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":35950,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5203285573764,"longitude":126.904042327682,"bookYear":null,"nameKR":"스시민(영등포점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36417,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5174134151713,"longitude":126.906923049239,"bookYear":null,"nameKR":"아트몬스터(영등포점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":3355,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.535703,"longitude":126.896197,"bookYear":null,"nameKR":"호연","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":6405,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.51853,"longitude":126.9066,"bookYear":null,"nameKR":"영등포복집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":7612,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5172332566615,"longitude":126.903715993779,"bookYear":null,"nameKR":"한일관(영등포점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":7829,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5188602750991,"longitude":126.90696016472,"bookYear":null,"nameKR":"뽕씨네얼큰수제비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":8169,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.518655,"longitude":126.908174,"bookYear":null,"nameKR":"여로집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":9086,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.518954,"longitude":126.906764,"bookYear":null,"nameKR":"함흥냉면","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":9198,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.51791,"longitude":126.9087,"bookYear":null,"nameKR":"청도양꼬치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":19875,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.518977,"longitude":126.906162,"bookYear":null,"nameKR":"호우양꼬치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":28512,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5196161360604,"longitude":126.907892120104,"bookYear":null,"nameKR":"호박집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":7895,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.51586771012241,"longitude":126.89117572175465,"bookYear":null,"nameKR":"원조마늘통닭","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":7944,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.51627,"longitude":126.8897,"bookYear":null,"nameKR":"영일분식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":8166,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.51602,"longitude":126.8902,"bookYear":null,"nameKR":"대추나무칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":17668,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.524982,"longitude":126.889125,"bookYear":null,"nameKR":"진콩나물국밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":29392,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/beer_gyudam","foodTypes":"아시아식","latitude":37.515315,"longitude":126.894433,"bookYear":null,"nameKR":"규담","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":29944,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/age_de_ble/","foodTypes":"디저트/차/베이커리","latitude":37.52570779149017,"longitude":126.88477193970074,"bookYear":null,"nameKR":"아쥬드블레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":31835,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moollae_korea/","foodTypes":"바/주점","latitude":37.5130667203285,"longitude":126.895521827941,"bookYear":null,"nameKR":"물래","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":32680,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pont_official_/","foodTypes":"디저트/차/베이커리","latitude":37.5122530800671,"longitude":126.892571181168,"bookYear":null,"nameKR":"폰트커피(문래점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":32774,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bossanova_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5199582224639,"longitude":126.892014446008,"bookYear":null,"nameKR":"보사노바커피로스터스(문래점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":35304,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mullae_mokhwawon/","foodTypes":"바/주점","latitude":37.515100669227,"longitude":126.894534349504,"bookYear":null,"nameKR":"목화원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":35655,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bring_me_coffee_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5266211056739,"longitude":126.889068107427,"bookYear":null,"nameKR":"브링미커피 브루어스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":35689,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5263909280031,"longitude":126.88890644698,"bookYear":null,"nameKR":"시즌커피앤베이크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36308,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5130059086545,"longitude":126.892912822975,"bookYear":null,"nameKR":"미츠바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":30788,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5239682020173,"longitude":126.897969261858,"bookYear":null,"nameKR":"베르두레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":33201,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/onpo_kitchen/","foodTypes":"기타","latitude":37.5249432690399,"longitude":126.892289852845,"bookYear":null,"nameKR":"온포식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":33727,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_unclepizza/","foodTypes":"미국식","latitude":37.5143303098898,"longitude":126.894029487208,"bookYear":null,"nameKR":"엉클비스트로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":33958,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_bucketlist/","foodTypes":"디저트/차/베이커리","latitude":37.524668482687,"longitude":126.895929084468,"bookYear":null,"nameKR":"카페버킷리스트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":34001,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/holapoke_dangsan/","foodTypes":"기타","latitude":37.5308112501685,"longitude":126.898319035004,"bookYear":null,"nameKR":"올라포케(당산점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":34293,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leforte_dangsan/","foodTypes":"기타","latitude":37.5353702476966,"longitude":126.899505032826,"bookYear":null,"nameKR":"르포르테","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":35207,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/verde__mullae/","foodTypes":"기타","latitude":37.513869999243,"longitude":126.89364194283,"bookYear":null,"nameKR":"베르데 문래","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36015,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5256680266244,"longitude":126.897338858482,"bookYear":null,"nameKR":"동래정(본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36050,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5256596099082,"longitude":126.893731321805,"bookYear":null,"nameKR":"안남면가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":36451,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5269114271622,"longitude":126.894597707083,"bookYear":null,"nameKR":"만평우동","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":7984,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.52425,"longitude":126.8922,"bookYear":null,"nameKR":"부여집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":19415,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.535585,"longitude":126.90285,"bookYear":null,"nameKR":"기찻길연탄불생고기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":19564,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.53185,"longitude":126.901,"bookYear":null,"nameKR":"욥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":26645,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/strada_146/","foodTypes":"디저트/차/베이커리","latitude":37.5290691628169,"longitude":126.899369128669,"bookYear":null,"nameKR":"스트라다146","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":27534,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/civediamo5688/","foodTypes":"이탈리아식","latitude":37.5132683519043,"longitude":126.894493493819,"bookYear":null,"nameKR":"치베디아모","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":29104,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.513891,"longitude":126.89426,"bookYear":null,"nameKR":"올드문래","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"19. 영등포","status":"ACTIVE"},
        {"id":898,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.519734,"longitude":126.929238,"bookYear":null,"nameKR":"파낙스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":1426,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5192120330535,"longitude":126.928582231249,"bookYear":null,"nameKR":"소도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":4690,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.51849,"longitude":126.9396,"bookYear":null,"nameKR":"하쯔호","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":7940,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.51836,"longitude":126.9339,"bookYear":null,"nameKR":"청수우동메밀냉면","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":8009,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"프랑스식","latitude":37.5198,"longitude":126.9401,"bookYear":null,"nameKR":"터치더스카이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":15639,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5228372415324,"longitude":126.941575147015,"bookYear":null,"nameKR":"파라다이스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":15741,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.519412,"longitude":126.938574,"bookYear":null,"nameKR":"양자강","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":35571,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/topokki112","foodTypes":"한식(일반한식)","latitude":37.5183968410658,"longitude":126.933905858565,"bookYear":null,"nameKR":"즉떡112","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":36180,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5202061193308,"longitude":126.931800002781,"bookYear":null,"nameKR":"여의도 우아동 우동소바왕돈까스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":179,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.520535,"longitude":126.930068,"bookYear":null,"nameKR":"대여","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":968,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5210980098112,"longitude":126.929780312735,"bookYear":null,"nameKR":"향복집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":8172,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.51939,"longitude":126.930271,"bookYear":null,"nameKR":"조원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":9270,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.52072,"longitude":126.9291,"bookYear":null,"nameKR":"상은북어국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":14077,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.519509,"longitude":126.930477,"bookYear":null,"nameKR":"수정쌈밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":16315,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5197432861193,"longitude":126.929217003405,"bookYear":null,"nameKR":"오네스타키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":24377,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5208724804701,"longitude":126.931298156696,"bookYear":null,"nameKR":"여의도떡집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":1272,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.520723,"longitude":126.926923,"bookYear":null,"nameKR":"창고43","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":1433,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5202,"longitude":126.9264,"bookYear":null,"nameKR":"주빈커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":5115,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.521425,"longitude":126.924901,"bookYear":null,"nameKR":"풀향기보쌈칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":7660,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/paul.and.paulina/","foodTypes":"디저트/차/베이커리","latitude":37.5210144173791,"longitude":126.923991634451,"bookYear":null,"nameKR":"폴앤폴리나(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":23863,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5210144173791,"longitude":126.923991634451,"bookYear":null,"nameKR":"하루떡(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":26758,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5210144173791,"longitude":126.923991634451,"bookYear":null,"nameKR":"브리오슈도레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":31892,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jdg0114korea","foodTypes":"일식","latitude":37.5202405439146,"longitude":126.926406742546,"bookYear":null,"nameKR":"스시젠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":33521,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5225634268582,"longitude":126.931203052964,"bookYear":null,"nameKR":"브로트아트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":34246,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5210144173791,"longitude":126.923991634451,"bookYear":null,"nameKR":"59","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":34291,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.5213712853208,"longitude":126.927398276154,"bookYear":null,"nameKR":"너섬칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":36227,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/man.yu_official/","foodTypes":"바/주점","latitude":37.5202405439146,"longitude":126.926406742546,"bookYear":null,"nameKR":"만유","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":31885,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5233667660094,"longitude":126.923451000009,"bookYear":null,"nameKR":"오복수산대구","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":32588,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.5251775245928,"longitude":126.924876706923,"bookYear":null,"nameKR":"더스테이크하우스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":33019,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thunderrolls.official/","foodTypes":"기타","latitude":37.5251775245928,"longitude":126.924876706923,"bookYear":null,"nameKR":"썬더롤스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":33344,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5251913154781,"longitude":126.929112756574,"bookYear":null,"nameKR":"도원스타일(더현대서울점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":33924,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hanutheme/","foodTypes":"한식(육류)","latitude":37.5240806711634,"longitude":126.924494536092,"bookYear":null,"nameKR":"더미(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":35362,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jumboseafoodkorea/","foodTypes":"기타","latitude":37.5251775245928,"longitude":126.924876706923,"bookYear":null,"nameKR":"점보씨푸드(여의도IFC몰점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":1253,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.52291,"longitude":126.9243,"bookYear":null,"nameKR":"황태본가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":6369,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tokyomc786/","foodTypes":"일식","latitude":37.521129,"longitude":126.918632,"bookYear":null,"nameKR":"동경식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":7091,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.5246490570692,"longitude":126.926838629184,"bookYear":null,"nameKR":"강가(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":7392,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.521195,"longitude":126.918592,"bookYear":null,"nameKR":"올라!(여의도파크센터점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":7734,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.5229451843816,"longitude":126.924285608734,"bookYear":null,"nameKR":"두르가(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":7905,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.523069,"longitude":126.925563,"bookYear":null,"nameKR":"동해대구탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":14961,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.525367,"longitude":126.926524,"bookYear":null,"nameKR":"37그릴앤바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":18588,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5251775245928,"longitude":126.924876706923,"bookYear":null,"nameKR":"사리원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":18927,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.52547,"longitude":126.9262,"bookYear":null,"nameKR":"제스트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":28342,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.522134,"longitude":126.920243,"bookYear":null,"nameKR":"사대부집곳간","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":29343,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/piginthegarden/","foodTypes":"기타","latitude":37.524007,"longitude":126.922865,"bookYear":null,"nameKR":"피그인더가든","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":29732,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bk023049293/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.52386555653834,"longitude":126.9241424797356,"bookYear":null,"nameKR":"배꼽집(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":3522,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5253645,"longitude":126.9192027,"bookYear":null,"nameKR":"올라(여의도KBS점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":5172,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.530111,"longitude":126.919702,"bookYear":null,"nameKR":"여의도양지탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":7219,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5260304622171,"longitude":126.918692566602,"bookYear":null,"nameKR":"리샨(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":9404,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.530234,"longitude":126.921678,"bookYear":null,"nameKR":"브로드웨이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":9461,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.530234,"longitude":126.921678,"bookYear":null,"nameKR":"샹하오","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":12399,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nanatama31/","foodTypes":"일식","latitude":37.5281995830714,"longitude":126.922656059858,"bookYear":null,"nameKR":"타마스시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":16250,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5299948883491,"longitude":126.919907178495,"bookYear":null,"nameKR":"속초해물탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":20563,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"창고43(VIP점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":21261,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5298309198,"longitude":126.919265654284,"bookYear":null,"nameKR":"스시나고미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":21910,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.527477,"longitude":126.919652,"bookYear":null,"nameKR":"노조미일식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":29520,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kappo_akii/?igshid=1kkcxn4tm15qa","foodTypes":"일식","latitude":37.5274108867949,"longitude":126.91726505260479,"bookYear":null,"nameKR":"갓포아키(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":30338,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.52751345777717,"longitude":126.91966784418432,"bookYear":null,"nameKR":"남도마루(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":32189,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/surfers_poke","foodTypes":"기타","latitude":37.4804418584288,"longitude":127.042867881345,"bookYear":null,"nameKR":"서퍼스포케","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":32653,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/merci.yangjae/","foodTypes":"유럽식","latitude":37.4730130737773,"longitude":127.049022431408,"bookYear":null,"nameKR":"메르시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":32718,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5301063102401,"longitude":126.926220220484,"bookYear":null,"nameKR":"엘디에스(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":33762,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/simjae_official/","foodTypes":"디저트/차/베이커리","latitude":37.4681493138338,"longitude":127.042799479489,"bookYear":null,"nameKR":"심재(양재점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":33816,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cgdabang2022/","foodTypes":"디저트/차/베이커리","latitude":37.4437644317073,"longitude":127.056065584742,"bookYear":null,"nameKR":"청계다방","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":34676,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5305204835617,"longitude":126.921308706686,"bookYear":null,"nameKR":"청기와타운(여의도점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":34719,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5290821496185,"longitude":126.921888669221,"bookYear":null,"nameKR":"스윗차징","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":35513,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/samsam_cafe","foodTypes":"기타","latitude":37.5290821496185,"longitude":126.921888669221,"bookYear":null,"nameKR":"33카페","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":35514,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/seem.on_","foodTypes":"디저트/차/베이커리","latitude":37.4765971853513,"longitude":127.039972398544,"bookYear":null,"nameKR":"심온","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36088,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafetodah/","foodTypes":"디저트/차/베이커리","latitude":37.4675277936623,"longitude":127.022632215132,"bookYear":null,"nameKR":"카페토다","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":1133,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4434608092954,"longitude":127.055642947532,"bookYear":null,"nameKR":"보현재","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":1186,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.477982,"longitude":127.041349,"bookYear":null,"nameKR":"씨엘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":4288,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daebanggol_official/","foodTypes":"한식(일반한식)","latitude":37.53093101076068,"longitude":126.92108517065249,"bookYear":null,"nameKR":"대방골","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":5656,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.468386,"longitude":127.027809,"bookYear":null,"nameKR":"봉평메밀막국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":7910,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.52904,"longitude":126.921875,"bookYear":null,"nameKR":"뒤푸리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"20. 여의도","status":"ACTIVE"},
        {"id":14236,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.478945248884145,"longitude":127.03796031454142,"bookYear":null,"nameKR":"느린마을양조장술펍","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":23378,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.45766,"longitude":127.047337,"bookYear":null,"nameKR":"도토리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":24270,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.478908,"longitude":127.037949,"bookYear":null,"nameKR":"배상면주가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":26636,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4666630288892,"longitude":127.043231686034,"bookYear":null,"nameKR":"원산생태","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":29017,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soulbread_1/","foodTypes":"디저트/차/베이커리","latitude":37.4712654713833,"longitude":127.024370524719,"bookYear":null,"nameKR":"소울브레드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":3767,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.4850446617942,"longitude":127.039775187068,"bookYear":null,"nameKR":"우미각","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":7380,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4846146544206,"longitude":127.039644480455,"bookYear":null,"nameKR":"오대산산채전문점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":12523,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4748992309526,"longitude":127.042556649323,"bookYear":null,"nameKR":"더벨로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":17091,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4832944262439,"longitude":127.036215443199,"bookYear":null,"nameKR":"강촌원조쭈꾸미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":29859,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/domafnb2018","foodTypes":"디저트/차/베이커리","latitude":37.47707116408042,"longitude":127.03791414456634,"bookYear":null,"nameKR":"도마베이커리앤테스트키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":31543,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4859870116588,"longitude":127.035583446307,"bookYear":null,"nameKR":"커피에스페란토","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":31547,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4861494496599,"longitude":127.036221796355,"bookYear":null,"nameKR":"국미당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":31811,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/romaok_dogok","foodTypes":"이탈리아식","latitude":37.4869990199787,"longitude":127.034068788108,"bookYear":null,"nameKR":"로마옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":32304,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4824780437509,"longitude":127.036729599792,"bookYear":null,"nameKR":"갈비사랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":33470,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_boaz/","foodTypes":"디저트/차/베이커리","latitude":37.4742049028392,"longitude":127.035792090912,"bookYear":null,"nameKR":"카페보아즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":35847,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4855360426104,"longitude":127.030825791222,"bookYear":null,"nameKR":"명갈비곰탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":35996,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yangjae_soondae/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4849344638394,"longitude":127.031416883965,"bookYear":null,"nameKR":"양재순대","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36645,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4840145262632,"longitude":127.035874332915,"bookYear":null,"nameKR":"갈비세상","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36690,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4752155775505,"longitude":127.044983495399,"bookYear":null,"nameKR":"양재박삼겹","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":30019,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.48509899672516,"longitude":127.04359417189954,"bookYear":null,"nameKR":"오가네족발","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":30258,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.48386573787011,"longitude":127.04539797735588,"bookYear":null,"nameKR":"마린레스토랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":31549,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/togarashiseoul/","foodTypes":"디저트/차/베이커리","latitude":37.4838572994625,"longitude":127.045784429364,"bookYear":null,"nameKR":"브라이언스커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":31604,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.491495899154,"longitude":127.039712570874,"bookYear":null,"nameKR":"한재용베이커리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":32486,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/katsu_ou/","foodTypes":"일식","latitude":37.4846456827176,"longitude":127.045079262653,"bookYear":null,"nameKR":"카츠오우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":33173,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leestaurant/","foodTypes":"프랑스식","latitude":37.4850469791971,"longitude":127.043183369009,"bookYear":null,"nameKR":"리스토랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":33695,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4896537373705,"longitude":127.032839070004,"bookYear":null,"nameKR":"뱅뱅막국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":33763,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/simjae_official/","foodTypes":"디저트/차/베이커리","latitude":37.4837491431052,"longitude":127.044937731797,"bookYear":null,"nameKR":"심재(도곡점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":34007,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4839475575914,"longitude":127.046075175616,"bookYear":null,"nameKR":"브라운핸즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":35259,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/project_denki/","foodTypes":"일식","latitude":37.4848939855081,"longitude":127.045118872169,"bookYear":null,"nameKR":"덴키라멘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36066,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4824616064799,"longitude":127.043724471205,"bookYear":null,"nameKR":"클라우즈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":36318,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ordinary_moment_/","foodTypes":"디저트/차/베이커리","latitude":37.4839901801267,"longitude":127.044175249519,"bookYear":null,"nameKR":"올디너리모먼트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":6505,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.48516,"longitude":127.0442,"bookYear":null,"nameKR":"쉐플로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":16447,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4893165456972,"longitude":127.033284658163,"bookYear":null,"nameKR":"한강수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":22485,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4829191428054,"longitude":127.046084380646,"bookYear":null,"nameKR":"비플레이트바이브라운브레드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":969,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.52567,"longitude":127.0249,"bookYear":null,"nameKR":"허형만의압구정커피집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":3948,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leesfnb/","foodTypes":"디저트/차/베이커리","latitude":37.532076,"longitude":127.028456,"bookYear":null,"nameKR":"이승남의꽃과빵","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":4810,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.4777334875266,"longitude":127.048088506213,"bookYear":null,"nameKR":"영동올뱅이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":6581,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.52731,"longitude":127.0179,"bookYear":null,"nameKR":"오엔","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":8008,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/h450_apgujeong/","foodTypes":"기타","latitude":37.528631,"longitude":127.027648,"bookYear":null,"nameKR":"h450","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":12599,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.527327,"longitude":127.027427,"bookYear":null,"nameKR":"르알래스카(압구정현대백화점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":12710,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/crew4209/","foodTypes":"디저트/차/베이커리","latitude":37.53073,"longitude":127.0353,"bookYear":null,"nameKR":"크레마코스타(압구정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":12971,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/apgujeong_haru/","foodTypes":"일식","latitude":37.52674,"longitude":127.0383,"bookYear":null,"nameKR":"하루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":14858,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.52720294297692,"longitude":127.03847347919445,"bookYear":null,"nameKR":"도수향","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":16228,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.5262774174353,"longitude":127.03907202336,"bookYear":null,"nameKR":"얌타이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":17087,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.486392,"longitude":127.044278,"bookYear":null,"nameKR":"갯바위","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":25923,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5273661232786,"longitude":127.027468526838,"bookYear":null,"nameKR":"몽슈슈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":27670,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moyashi_papa/","foodTypes":"바/주점","latitude":37.485806,"longitude":127.045054,"bookYear":null,"nameKR":"모야시키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"11. 양재/매봉","status":"ACTIVE"},
        {"id":29786,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soi_mao","foodTypes":"아시아식","latitude":37.52516292487227,"longitude":127.03892946698619,"bookYear":null,"nameKR":"소이연남마오","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29960,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/elecshoes/","foodTypes":"바/주점","latitude":37.52406381805397,"longitude":127.03857594297772,"bookYear":null,"nameKR":"전자신발","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31834,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woktionary/","foodTypes":"중식","latitude":37.5238807580141,"longitude":127.038503448305,"bookYear":null,"nameKR":"웍셔너리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":34347,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/miraehall/","foodTypes":"한식(육류)","latitude":37.5268778406519,"longitude":127.039085685879,"bookYear":null,"nameKR":"미래회관(압구정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":34472,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5263735381523,"longitude":127.038017367949,"bookYear":null,"nameKR":"모락관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":36562,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/satis.woogam","foodTypes":"한식(육류)","latitude":37.5262774174353,"longitude":127.03907202336,"bookYear":null,"nameKR":"우감만족","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30252,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.525166655035825,"longitude":127.03854483510749,"bookYear":null,"nameKR":"미스터사이몬","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30490,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5274075022415,"longitude":127.038375612826,"bookYear":null,"nameKR":"로얄컵케익","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32268,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/littleneck.official/","foodTypes":"디저트/차/베이커리","latitude":37.5242138261807,"longitude":127.037766715705,"bookYear":null,"nameKR":"리틀넥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32903,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/stewbudae/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5255913428421,"longitude":127.038405227112,"bookYear":null,"nameKR":"스튜부대","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32950,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eomuljeon_chung/","foodTypes":"바/주점","latitude":37.5241017780192,"longitude":127.037652288851,"bookYear":null,"nameKR":"어물전청","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33207,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/senzairyoku/","foodTypes":"일식","latitude":37.5270322552754,"longitude":127.039137353912,"bookYear":null,"nameKR":"센자이료쿠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33282,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/d.cruise.seoul/","foodTypes":"바/주점","latitude":37.5249617604329,"longitude":127.038565318856,"bookYear":null,"nameKR":"디크루즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33419,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5246295912402,"longitude":127.037633322561,"bookYear":null,"nameKR":"루비떡볶이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":34366,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goodson.official/","foodTypes":"아시아식","latitude":37.5279203642126,"longitude":127.037497633767,"bookYear":null,"nameKR":"굿손(압구정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":5223,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.52375,"longitude":127.0375,"bookYear":null,"nameKR":"마리모","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":7234,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bycdol_happy_food/","foodTypes":"한식(육류)","latitude":37.525419,"longitude":127.038191,"bookYear":null,"nameKR":"벽돌해피푸드(압구정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":24275,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.528143,"longitude":127.038057,"bookYear":null,"nameKR":"솟대","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":27605,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"프랑스식","latitude":37.5280407556239,"longitude":127.036869472402,"bookYear":null,"nameKR":"바빌리안테이블","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":27892,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.52378,"longitude":127.0377,"bookYear":null,"nameKR":"로지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":28952,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistrospark/","foodTypes":"이탈리아식","latitude":37.5282720655424,"longitude":127.037654045502,"bookYear":null,"nameKR":"비스트로스파크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29218,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ansic_kihofood/","foodTypes":"한식(일반한식)","latitude":37.52405310612793,"longitude":127.03826823498186,"bookYear":null,"nameKR":"안식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29764,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goldfish_cuisine/","foodTypes":"중식","latitude":37.52607172192224,"longitude":127.03710630039664,"bookYear":null,"nameKR":"골드피쉬딤섬퀴진","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":1003,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/grandciel2005/","foodTypes":"이탈리아식","latitude":37.52337,"longitude":127.0358,"bookYear":null,"nameKR":"그랑씨엘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":1536,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.52309,"longitude":127.0362,"bookYear":null,"nameKR":"세시셀라","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":4933,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.52332,"longitude":127.0357,"bookYear":null,"nameKR":"마이쏭","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":8073,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.522981,"longitude":127.036214,"bookYear":null,"nameKR":"인뉴욕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29858,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dosanbunsik/","foodTypes":"한식(일반한식)","latitude":37.52371444891586,"longitude":127.03789701068867,"bookYear":null,"nameKR":"도산분식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30273,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.52518896519517,"longitude":127.03638184635076,"bookYear":null,"nameKR":"미아전","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30727,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.523845018025,"longitude":127.036712086093,"bookYear":null,"nameKR":"대막","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30860,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeomi_dining","foodTypes":"한식(일반한식)","latitude":37.525599174163,"longitude":127.036438044043,"bookYear":null,"nameKR":"여미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30928,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gardner_official/","foodTypes":"이탈리아식","latitude":37.5239652294737,"longitude":127.036655016479,"bookYear":null,"nameKR":"가드너아드리아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31781,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dd.gelateria","foodTypes":"디저트/차/베이커리","latitude":37.5253977365361,"longitude":127.036932993945,"bookYear":null,"nameKR":"젤라떼리아도도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32124,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/konban_katsu","foodTypes":"일식","latitude":37.5247997088646,"longitude":127.036457565564,"bookYear":null,"nameKR":"카츠바이콘반","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33270,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aelgerizm/","foodTypes":"컨템포러리","latitude":37.5233511349545,"longitude":127.037425209409,"bookYear":null,"nameKR":"알고리즘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":35088,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tkii.seoul/","foodTypes":"디저트/차/베이커리","latitude":37.5230853508609,"longitude":127.035937150738,"bookYear":null,"nameKR":"트키도산","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":35971,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/aru_apgujeong","foodTypes":"바/주점","latitude":37.5252859762923,"longitude":127.036175887785,"bookYear":null,"nameKR":"아루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31780,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/il_riso/","foodTypes":"이탈리아식","latitude":37.5273174758506,"longitude":127.035248177715,"bookYear":null,"nameKR":"일리조","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32015,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5261535649144,"longitude":127.03493335748,"bookYear":null,"nameKR":"스시결","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32624,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bar_crybaby/","foodTypes":"바/주점","latitude":37.527465156665,"longitude":127.034922655997,"bookYear":null,"nameKR":"크라이베이비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32746,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5272539303485,"longitude":127.03622367424,"bookYear":null,"nameKR":"갓포모로미(도산공원점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33121,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/newyorklotsobagels/","foodTypes":"디저트/차/베이커리","latitude":37.5268616028561,"longitude":127.036617177467,"bookYear":null,"nameKR":"뉴욕라츠오베이글스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33180,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sundayburgerclub_seoul/","foodTypes":"기타","latitude":37.5266144205262,"longitude":127.036737879251,"bookYear":null,"nameKR":"선데이버거클럽","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33487,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/felt_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.5256645232426,"longitude":127.03546324887,"bookYear":null,"nameKR":"펠트커피(도산공원점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":36457,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5266090561205,"longitude":127.035421951109,"bookYear":null,"nameKR":"마일드하이클럽","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":7614,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.525436,"longitude":127.036541,"bookYear":null,"nameKR":"후꾸짱","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29488,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gujeondonghwa/","foodTypes":"한식(육류)","latitude":37.52705949531336,"longitude":127.0362198469171,"bookYear":null,"nameKR":"구전동화","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29584,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.52846541831767,"longitude":127.0350258466962,"bookYear":null,"nameKR":"js가든(압구정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":39,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.527668,"longitude":127.034142,"bookYear":null,"nameKR":"경수사","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":1497,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.522548,"longitude":127.035049,"bookYear":null,"nameKR":"카페마당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":1518,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.52793,"longitude":127.0308,"bookYear":null,"nameKR":"신미식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":6716,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.523108,"longitude":127.03438,"bookYear":null,"nameKR":"저스트스테이크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":7225,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.527009,"longitude":127.030578,"bookYear":null,"nameKR":"광화문미진(압구정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":16222,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/assoulinekorea/","foodTypes":"디저트/차/베이커리","latitude":37.523,"longitude":127.0352,"bookYear":null,"nameKR":"애술린라운지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":23580,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.52733,"longitude":127.031,"bookYear":null,"nameKR":"와니스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31495,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi_sakau","foodTypes":"일식","latitude":37.5230970973678,"longitude":127.032395342347,"bookYear":null,"nameKR":"스시사카우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33237,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kiyoi_sushi_/","foodTypes":"일식","latitude":37.5231925184583,"longitude":127.034907881283,"bookYear":null,"nameKR":"스시기요이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":34581,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tampa_sandwichbar/","foodTypes":"기타","latitude":37.5266163122588,"longitude":127.034032607667,"bookYear":null,"nameKR":"탬파","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29909,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_plate_dessert","foodTypes":"디저트/차/베이커리","latitude":37.5253215967063,"longitude":127.028865132152,"bookYear":null,"nameKR":"더플레이트디저트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30409,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.520841305217466,"longitude":127.02759249094373,"bookYear":null,"nameKR":"스시호우시절","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30706,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.521514181532,"longitude":127.030142736691,"bookYear":null,"nameKR":"룽청","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":30724,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5253215967063,"longitude":127.028865132152,"bookYear":null,"nameKR":"네기스키야키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31359,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/miiang_official/","foodTypes":"아시아식","latitude":37.5253215967063,"longitude":127.028865132152,"bookYear":null,"nameKR":"미앙","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31681,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5236843326195,"longitude":127.029443703183,"bookYear":null,"nameKR":"카페바쏘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32475,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wan_gang_jeong/","foodTypes":"한식(가금류)","latitude":37.5266063858882,"longitude":127.02939202001,"bookYear":null,"nameKR":"완강정(압구정본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33595,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5232813641479,"longitude":127.030314718515,"bookYear":null,"nameKR":"스시유키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":34039,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/twgteakr/","foodTypes":"디저트/차/베이커리","latitude":37.5253215967063,"longitude":127.028865132152,"bookYear":null,"nameKR":"TWG티(안다즈점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":34107,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mizuki.seoul/","foodTypes":"일식","latitude":37.5231702951589,"longitude":127.030568751385,"bookYear":null,"nameKR":"미즈키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":35321,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_lambin/","foodTypes":"디저트/차/베이커리","latitude":37.5252025694879,"longitude":127.030671621739,"bookYear":null,"nameKR":"램방","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":817,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.52658,"longitude":127.0301,"bookYear":null,"nameKR":"충무상회","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":889,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.524898,"longitude":127.027807,"bookYear":null,"nameKR":"토모","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":1242,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.523634992536,"longitude":127.030021075239,"bookYear":null,"nameKR":"이마스시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":7010,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.52676,"longitude":127.0294,"bookYear":null,"nameKR":"노아베이커리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":9053,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.526566,"longitude":127.029376,"bookYear":null,"nameKR":"안동국시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":29195,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.525853,"longitude":127.029481,"bookYear":null,"nameKR":"별달식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":1378,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.52511,"longitude":127.0265,"bookYear":null,"nameKR":"피터폴앤메리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":6467,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.523094,"longitude":127.027423,"bookYear":null,"nameKR":"파티오42","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":9267,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.50517,"longitude":127.0464,"bookYear":null,"nameKR":"선릉설렁탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":12682,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5236012907122,"longitude":127.027446444565,"bookYear":null,"nameKR":"압구정공주떡","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":14710,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.506459,"longitude":127.047387,"bookYear":null,"nameKR":"통영집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":27347,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.52009,"longitude":127.027436,"bookYear":null,"nameKR":"모국정서","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":28569,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/madeseoul/","foodTypes":"미국식","latitude":37.5249206756997,"longitude":127.025632593476,"bookYear":null,"nameKR":"메이드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":28978,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.508852,"longitude":127.043841,"bookYear":null,"nameKR":"스시키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":28987,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/saluzzo_kwangchef/","foodTypes":"이탈리아식","latitude":37.511276,"longitude":127.045422,"bookYear":null,"nameKR":"살루쪼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":30210,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.52543663505798,"longitude":127.0266484332339,"bookYear":null,"nameKR":"떼부짱","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31203,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5259418312356,"longitude":127.02818263902,"bookYear":null,"nameKR":"레더라(압구정점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31453,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.525255546219,"longitude":127.027381097133,"bookYear":null,"nameKR":"스시에비스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31529,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eliberico_official/","foodTypes":"유럽식","latitude":37.5249900303012,"longitude":127.026948288055,"bookYear":null,"nameKR":"엘이베리코","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31540,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chamberry_/","foodTypes":"이탈리아식","latitude":37.507438417788,"longitude":127.045393813264,"bookYear":null,"nameKR":"샹베리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":31747,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jun_x_esteban/","foodTypes":"일식","latitude":37.5242029191593,"longitude":127.027320868696,"bookYear":null,"nameKR":"갓포준x에스테반","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32543,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sav.seoul/","foodTypes":"기타","latitude":37.5263670759444,"longitude":127.027922375096,"bookYear":null,"nameKR":"사브서울","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":32660,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/laubenuit_official","foodTypes":"디저트/차/베이커리","latitude":37.5028661452318,"longitude":127.048859862095,"bookYear":null,"nameKR":"로브니","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":33392,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/isokwoohwa/","foodTypes":"한식(육류)","latitude":37.5253452973989,"longitude":127.028099944398,"bookYear":null,"nameKR":"이속우화천공","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":33676,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/breadful.kr/","foodTypes":"디저트/차/베이커리","latitude":37.5131772418812,"longitude":127.046408261406,"bookYear":null,"nameKR":"브레드풀","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":34146,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5244576611004,"longitude":127.027197088046,"bookYear":null,"nameKR":"갈비다움(압구정지점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":34225,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5032355765545,"longitude":127.046582379785,"bookYear":null,"nameKR":"커피나인(역삼점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":34690,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5263096990298,"longitude":127.027465990023,"bookYear":null,"nameKR":"잠바주스(압구정역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":34965,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_the__lotus/","foodTypes":"이탈리아식","latitude":37.5244875087715,"longitude":127.026694816905,"bookYear":null,"nameKR":"더로투스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":35548,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/reed_coffeestand","foodTypes":"디저트/차/베이커리","latitude":37.5116383853809,"longitude":127.04532928417,"bookYear":null,"nameKR":"리드커피스탠드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":35992,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/songofsongs_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5240269072002,"longitude":127.026735264978,"bookYear":null,"nameKR":"송오브송즈로스터즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"02. 압구정동/도산공원","status":"ACTIVE"},
        {"id":31477,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mage_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.4864371159699,"longitude":126.913442051154,"bookYear":null,"nameKR":"메이지커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":31740,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/backerin_seoul","foodTypes":"디저트/차/베이커리","latitude":37.513411013735,"longitude":127.044158990675,"bookYear":null,"nameKR":"베커린","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":33980,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5099566820593,"longitude":127.053065754946,"bookYear":null,"nameKR":"하스초콜릿아뜰리에","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":34188,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5073438196741,"longitude":127.0537495217,"bookYear":null,"nameKR":"르뱅룰즈(선릉점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":34503,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":37.5036666684361,"longitude":127.054468432463,"bookYear":null,"nameKR":"더피자스탠드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":34644,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5033351942461,"longitude":127.051440761262,"bookYear":null,"nameKR":"청기와타운(선릉점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":35187,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5067869422082,"longitude":127.053476778004,"bookYear":null,"nameKR":"수림복국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":35188,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5068999933152,"longitude":127.054906103551,"bookYear":null,"nameKR":"수림복국별관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":36213,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dear.gelato","foodTypes":"디저트/차/베이커리","latitude":37.4995784097446,"longitude":127.053160981438,"bookYear":null,"nameKR":"디어젤라또","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":36441,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5012100064904,"longitude":127.050992026651,"bookYear":null,"nameKR":"더스노우(대치본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":287,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.50342,"longitude":127.0502,"bookYear":null,"nameKR":"마담밍","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":6722,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5107,"longitude":127.0508,"bookYear":null,"nameKR":"트라토리아모로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":7210,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.510142,"longitude":127.054468,"bookYear":null,"nameKR":"대도식당(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":15062,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.509125,"longitude":127.054992,"bookYear":null,"nameKR":"원조광양불고기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":15092,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.4758393792937,"longitude":126.917626954698,"bookYear":null,"nameKR":"거목","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":27548,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushisanwon_official/","foodTypes":"일식","latitude":37.50647,"longitude":127.0509,"bookYear":null,"nameKR":"스시산원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"07. 선릉역/선정릉역","status":"ACTIVE"},
        {"id":29773,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.46707410990305,"longitude":126.93134854848307,"bookYear":null,"nameKR":"미림분식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":4843,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.481187,"longitude":126.947558,"bookYear":null,"nameKR":"진순자김밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":6927,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4725476267928,"longitude":126.935118562106,"bookYear":null,"nameKR":"김영자나주곰탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":7274,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.481858,"longitude":126.949907,"bookYear":null,"nameKR":"외래향(서울대점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":7622,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.4829681335432,"longitude":126.928216949459,"bookYear":null,"nameKR":"성민양꼬치(신림역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":8163,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4858245613578,"longitude":126.937673730794,"bookYear":null,"nameKR":"신풍루곱창구이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":14449,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.486071,"longitude":126.929671,"bookYear":null,"nameKR":"스시락","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":18880,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.48518,"longitude":126.9353,"bookYear":null,"nameKR":"정담은보쌈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":19432,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.481528,"longitude":126.942507,"bookYear":null,"nameKR":"이대감명품막창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":27165,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4898494223418,"longitude":126.927365586997,"bookYear":null,"nameKR":"양평해장국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":29135,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4812788803214,"longitude":126.945115249873,"bookYear":null,"nameKR":"장군집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":30035,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/foa_mal","foodTypes":"디저트/차/베이커리","latitude":37.48579141022412,"longitude":126.92786701588298,"bookYear":null,"nameKR":"폼앤노말","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":30049,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.482912889614035,"longitude":126.94308365406047,"bookYear":null,"nameKR":"후포리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":32643,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4836349466017,"longitude":126.937192875508,"bookYear":null,"nameKR":"루비정","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":34266,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4842950188384,"longitude":126.928167853107,"bookYear":null,"nameKR":"막불감동","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":35678,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.4812495407838,"longitude":126.944415647746,"bookYear":null,"nameKR":"낀알로이알로이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":35768,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gyunguk_lee/","foodTypes":"한식(어패류)","latitude":37.4828315414349,"longitude":126.928963639858,"bookYear":null,"nameKR":"하와이조개(신림본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":35780,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4836130350161,"longitude":126.928745589246,"bookYear":null,"nameKR":"신림춘천집본점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":35822,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.489616444532,"longitude":126.925520785086,"bookYear":null,"nameKR":"카페스내킹","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":36118,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ultimate_beef_mat/","foodTypes":"한식(육류)","latitude":37.4886316016347,"longitude":126.926172268722,"bookYear":null,"nameKR":"궁극의갈비살678 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":30033,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cordon__official/","foodTypes":"이탈리아식","latitude":37.47816880412439,"longitude":126.95741379774799,"bookYear":null,"nameKR":"꼬르동","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":30264,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.47964194957275,"longitude":126.95497088895964,"bookYear":null,"nameKR":"현씨공방","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":31506,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kissa.seoul/","foodTypes":"일식","latitude":37.4792187336193,"longitude":126.953773403418,"bookYear":null,"nameKR":"킷사서울","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":32192,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/talarodfai","foodTypes":"아시아식","latitude":37.4774421701323,"longitude":126.958511527856,"bookYear":null,"nameKR":"딸랏롯빠이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":33488,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.4781420395126,"longitude":126.956904040429,"bookYear":null,"nameKR":"미카엘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":33865,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/ahnmill_","foodTypes":"디저트/차/베이커리","latitude":37.4767321939825,"longitude":126.965276878143,"bookYear":null,"nameKR":"안밀","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":34157,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4793880669445,"longitude":126.9557439095,"bookYear":null,"nameKR":"한우육회마을2호점 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":34289,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/modamoda_curry/","foodTypes":"일식","latitude":37.4756214908878,"longitude":126.965797322177,"bookYear":null,"nameKR":"모다모다(낙성대점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":34334,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jungsooksung/","foodTypes":"한식(육류)","latitude":37.4792187336193,"longitude":126.953773403418,"bookYear":null,"nameKR":"정숙성","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":35711,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.4789596968172,"longitude":126.954478252855,"bookYear":null,"nameKR":"카이센동136","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":35928,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/manfromokinawa_epic/","foodTypes":"바/주점","latitude":37.4779224015344,"longitude":126.954107492012,"bookYear":null,"nameKR":"맨프롬오키나와","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":36260,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gobongsigdang/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4782407660496,"longitude":126.956347518093,"bookYear":null,"nameKR":"고봉식당(서울대입구본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":36517,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4782407660496,"longitude":126.956347518093,"bookYear":null,"nameKR":"닭장맥주(서울대입구점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":6397,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.481346179517516,"longitude":126.95373071232497,"bookYear":null,"nameKR":"삼미옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":7843,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.476864337445,"longitude":126.95846809511,"bookYear":null,"nameKR":"시골집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":9004,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.474313335124,"longitude":126.965557798192,"bookYear":null,"nameKR":"미도식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":15181,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.468034829997,"longitude":126.961769042702,"bookYear":null,"nameKR":"중화각","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":15197,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.4875621618116,"longitude":126.959937748135,"bookYear":null,"nameKR":"동화반점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":26143,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.4744924172774,"longitude":126.96700104032,"bookYear":null,"nameKR":"향도장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":29309,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/salon_book/","foodTypes":"디저트/차/베이커리","latitude":37.480356,"longitude":126.956961,"bookYear":null,"nameKR":"살롱드북","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":29798,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.47880321206303,"longitude":126.95768477612282,"bookYear":null,"nameKR":"두만강샤브샤브","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":231,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.497069,"longitude":126.997768,"bookYear":null,"nameKR":"뚜르뒤뱅","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":1494,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4938,"longitude":126.9926,"bookYear":null,"nameKR":"차이797","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":5155,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.49788,"longitude":126.9981,"bookYear":null,"nameKR":"파리크라상(반포서래점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":7851,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4750032782962,"longitude":126.952431360159,"bookYear":null,"nameKR":"산야로콩나물국밥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":25277,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.4780890897722,"longitude":126.952739857628,"bookYear":null,"nameKR":"모리돈부리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":30223,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.47998062174211,"longitude":126.95469029833632,"bookYear":null,"nameKR":"로향양꼬치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":31232,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rabbitstable_kitchen/","foodTypes":"이탈리아식","latitude":37.4913743581846,"longitude":126.993435902475,"bookYear":null,"nameKR":"토끼네마굿간","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":34011,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/projectseoul/","foodTypes":"디저트/차/베이커리","latitude":37.4795209692591,"longitude":126.955513191285,"bookYear":null,"nameKR":"프로젝트서울","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":34043,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.4796005695589,"longitude":126.941725729212,"bookYear":null,"nameKR":"대왕사각피자(봉천점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":34642,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ctown_sha/","foodTypes":"한식(육류)","latitude":37.480431385536,"longitude":126.953962484327,"bookYear":null,"nameKR":"청기와타운(서울대점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":35844,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/bil_bakery","foodTypes":"디저트/차/베이커리","latitude":37.4790467146506,"longitude":126.947077421667,"bookYear":null,"nameKR":"빌베이커리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"17. 서울대/관악구","status":"ACTIVE"},
        {"id":30795,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/craftbros_seorae/","foodTypes":"바/주점","latitude":37.4987915152569,"longitude":126.997628775033,"bookYear":null,"nameKR":"크래프트브로스탭하우스&바틀샵","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":31275,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.4979849961343,"longitude":126.996490923341,"bookYear":null,"nameKR":"일상의즐거움","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":32731,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/503recipe/","foodTypes":"이탈리아식","latitude":37.4978041604252,"longitude":126.996368006984,"bookYear":null,"nameKR":"503테이블","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":33059,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dining_go/","foodTypes":"일식","latitude":37.498046944776,"longitude":126.999213708341,"bookYear":null,"nameKR":"다이닝고","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":1109,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.499537,"longitude":126.998798,"bookYear":null,"nameKR":"맘마키키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":3856,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.499775,"longitude":126.998298,"bookYear":null,"nameKR":"서래본갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":5749,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.4985244780666,"longitude":126.99919956741,"bookYear":null,"nameKR":"야미도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":7288,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4998977000732,"longitude":126.998989093254,"bookYear":null,"nameKR":"나무그린차돌가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":27862,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.49953,"longitude":126.9989,"bookYear":null,"nameKR":"쿠오코선술","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":28465,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushitano","foodTypes":"일식","latitude":37.497945,"longitude":126.996481,"bookYear":null,"nameKR":"스시타노","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":28580,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yoon_seorae","foodTypes":"프랑스식","latitude":37.499738,"longitude":126.998987,"bookYear":null,"nameKR":"윤","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":28596,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.49717,"longitude":126.999675,"bookYear":null,"nameKR":"라모라","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":3917,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5146428711924,"longitude":127.05864118134,"bookYear":null,"nameKR":"송강","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":5203,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.513578,"longitude":127.064425,"bookYear":null,"nameKR":"이남장(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":6906,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.509595,"longitude":127.059429,"bookYear":null,"nameKR":"경성면옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":9060,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.514593,"longitude":127.063897,"bookYear":null,"nameKR":"삼정복집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":9381,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.509132,"longitude":127.060851,"bookYear":null,"nameKR":"그랜드키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":13479,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.515258,"longitude":127.060392,"bookYear":null,"nameKR":"치폴라로쏘(삼성본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":29288,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cocina_espana_ju/","foodTypes":"유럽식","latitude":37.497784,"longitude":126.997057,"bookYear":null,"nameKR":"꼬시나에스파냐","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"13. 서래마을","status":"ACTIVE"},
        {"id":30319,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.515578223251794,"longitude":127.06003591917053,"bookYear":null,"nameKR":"페스티바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":31392,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5142349548299,"longitude":127.064079356027,"bookYear":null,"nameKR":"언더프레셔","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":33210,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/and_haven/","foodTypes":"기타","latitude":37.518348883992,"longitude":127.055650658453,"bookYear":null,"nameKR":"앤헤이븐","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":33271,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5154929808923,"longitude":127.060755249435,"bookYear":null,"nameKR":"재희키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":34132,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/back_m_front_w/","foodTypes":"바/주점","latitude":37.5135084042352,"longitude":127.061846509329,"bookYear":null,"nameKR":"배산임수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":34606,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dimdimsumkr/","foodTypes":"중식","latitude":37.5091916919873,"longitude":127.06080691987,"bookYear":null,"nameKR":"딤딤섬(파르나스몰점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":35315,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/domyungol/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5126576770499,"longitude":127.065358963527,"bookYear":null,"nameKR":"도명골청국장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":35997,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5126576770499,"longitude":127.065358963527,"bookYear":null,"nameKR":"진양재순대(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":29893,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushiyuri.official","foodTypes":"일식","latitude":37.50978809059148,"longitude":127.05696389092233,"bookYear":null,"nameKR":"스시유리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":32805,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jinmyunmok","foodTypes":"기타","latitude":37.5102268857967,"longitude":127.057137953712,"bookYear":null,"nameKR":"이대성의진면목","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":33200,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/samsungri_/","foodTypes":"바/주점","latitude":37.5115915477012,"longitude":127.056762686679,"bookYear":null,"nameKR":"삼성리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":36074,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/peer_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5118239121138,"longitude":127.059159043842,"bookYear":null,"nameKR":"피어커피(코엑스)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":3920,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.510956,"longitude":127.055378,"bookYear":null,"nameKR":"까발로비안코","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":5708,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5112487718071,"longitude":127.05702008388,"bookYear":null,"nameKR":"도쿄재즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":6866,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5118239121138,"longitude":127.059159043842,"bookYear":null,"nameKR":"토이셔","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":9044,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5129436953442,"longitude":127.057114235894,"bookYear":null,"nameKR":"브래서리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":12979,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중남미식","latitude":37.509595,"longitude":127.059429,"bookYear":null,"nameKR":"온더보더(코엑스도시공항점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":14736,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.51083,"longitude":127.0563,"bookYear":null,"nameKR":"프로간장게장(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":15643,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5109823973192,"longitude":127.058205573267,"bookYear":null,"nameKR":"마쯔가제","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":18534,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5129436953442,"longitude":127.057114235894,"bookYear":null,"nameKR":"재동","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":29066,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/planplan_seoul","foodTypes":"디저트/차/베이커리","latitude":37.5114299720443,"longitude":127.05606164117,"bookYear":null,"nameKR":"플랑플랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":966,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.504888,"longitude":127.065778,"bookYear":null,"nameKR":"해초록(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":3658,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.505181,"longitude":127.065725,"bookYear":null,"nameKR":"등대","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":4899,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.512462,"longitude":127.056252,"bookYear":null,"nameKR":"흑돈가(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":5127,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.508547,"longitude":127.064139,"bookYear":null,"nameKR":"더라운지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":7191,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.50746,"longitude":127.064449,"bookYear":null,"nameKR":"홍영재장수청국장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":16466,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.514941,"longitude":127.060995,"bookYear":null,"nameKR":"삼환","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":19694,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4868843657306,"longitude":126.991971249339,"bookYear":null,"nameKR":"바다해물포차","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":19811,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.515932,"longitude":127.0652,"bookYear":null,"nameKR":"일공","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":21823,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.482323,"longitude":126.993767,"bookYear":null,"nameKR":"어니스크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":30343,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.515395148189796,"longitude":127.06544709033032,"bookYear":null,"nameKR":"백세주마을(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":31033,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/entete___","foodTypes":"디저트/차/베이커리","latitude":37.5038047972699,"longitude":127.066592014355,"bookYear":null,"nameKR":"앙떼띠 커피로스터리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":31468,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/planant__seoul/","foodTypes":"일식","latitude":37.5071617694148,"longitude":127.061424045649,"bookYear":null,"nameKR":"플라넌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":31955,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushichiwoo/","foodTypes":"일식","latitude":37.507001535999,"longitude":127.058656127092,"bookYear":null,"nameKR":"스시치우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":32712,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4817935157778,"longitude":126.992400299734,"bookYear":null,"nameKR":"스윗두","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":33415,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5088304781805,"longitude":127.065688827677,"bookYear":null,"nameKR":"삼성골목집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":34000,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/holapoke_/","foodTypes":"기타","latitude":37.5068334516553,"longitude":127.060885647262,"bookYear":null,"nameKR":"올라포케(삼성점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"06. 삼성역/청담역","status":"ACTIVE"},
        {"id":34837,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daika_thai_food/","foodTypes":"아시아식","latitude":37.4862865355713,"longitude":126.993230562173,"bookYear":null,"nameKR":"다이카","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":35326,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yueliang_s/","foodTypes":"중식","latitude":37.4855116092147,"longitude":126.985548128839,"bookYear":null,"nameKR":"월량","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":35522,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4812515316047,"longitude":126.999425535234,"bookYear":null,"nameKR":"함포해장(방배본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":29873,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.494018904875084,"longitude":126.9853945094364,"bookYear":null,"nameKR":"밤키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":30609,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.48762602855484,"longitude":126.992632295899,"bookYear":null,"nameKR":"큰집가마솥순대국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":31765,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oosa_bakeshop","foodTypes":"디저트/차/베이커리","latitude":37.4963377271232,"longitude":126.984613890668,"bookYear":null,"nameKR":"오오사","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":31950,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nostimo_kr","foodTypes":"유럽식","latitude":37.488634596004,"longitude":126.995922606171,"bookYear":null,"nameKR":"노스티모","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":33329,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4902887138253,"longitude":126.991681639183,"bookYear":null,"nameKR":"서래족발","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":33407,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4815733203302,"longitude":126.99645856386,"bookYear":null,"nameKR":"선화의삼겹살무한리필","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":33713,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/montauk.official/","foodTypes":"디저트/차/베이커리","latitude":37.4883226981858,"longitude":126.99695474448,"bookYear":null,"nameKR":"몬탁","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":34005,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/habe.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.4899167721412,"longitude":126.992981470863,"bookYear":null,"nameKR":"해브커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":34080,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4881841117249,"longitude":126.992613697313,"bookYear":null,"nameKR":"구뜰집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":35566,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_moho_bb/","foodTypes":"디저트/차/베이커리","latitude":37.4831203717565,"longitude":126.997173847878,"bookYear":null,"nameKR":"카페모호(방배점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":35637,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/upset_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.4809515766449,"longitude":126.993287800064,"bookYear":null,"nameKR":"업셋","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":36219,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4903293489337,"longitude":126.993072909968,"bookYear":null,"nameKR":"묘오또","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":36310,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/towa_bangbae/","foodTypes":"일식","latitude":37.4832527286095,"longitude":126.997119006671,"bookYear":null,"nameKR":"토와","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":4928,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4873696351752,"longitude":126.991774793811,"bookYear":null,"nameKR":"명인등심(방배점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":5863,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.493877,"longitude":126.986031,"bookYear":null,"nameKR":"일미칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":21719,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.491631,"longitude":126.989614,"bookYear":null,"nameKR":"구름떡집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":23785,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hangboc_com/","foodTypes":"디저트/차/베이커리","latitude":37.495084,"longitude":126.989302,"bookYear":null,"nameKR":"행복빵집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":29027,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fave_coffeebakery/","foodTypes":"디저트/차/베이커리","latitude":37.489279,"longitude":126.993641,"bookYear":null,"nameKR":"페이브","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":29159,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.492475,"longitude":126.988269,"bookYear":null,"nameKR":"양양메밀막국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":7319,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.498131,"longitude":126.985288,"bookYear":null,"nameKR":"루안(방배점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":7787,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4768739744914,"longitude":126.985370148299,"bookYear":null,"nameKR":"엘빈커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":9139,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4762981155867,"longitude":126.987520658705,"bookYear":null,"nameKR":"티에리스티스탠드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":18180,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4776672183692,"longitude":126.983944131922,"bookYear":null,"nameKR":"유성참치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":21825,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.47738,"longitude":126.986496,"bookYear":null,"nameKR":"뺑드비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":22986,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.484591,"longitude":126.98338,"bookYear":null,"nameKR":"이수통닭","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":28643,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.477002,"longitude":126.984368,"bookYear":null,"nameKR":"성민양꼬치(사당역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":30649,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/donutsyoon_bangbae","foodTypes":"디저트/차/베이커리","latitude":37.4874522054656,"longitude":126.9841791472294,"bookYear":null,"nameKR":"도너츠윤(방배점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":31893,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kiseceu","foodTypes":"일식","latitude":37.4788892638885,"longitude":126.983339689586,"bookYear":null,"nameKR":"키세츠스시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":31960,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/osee_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.4794744849649,"longitude":126.983450244108,"bookYear":null,"nameKR":"오쎄","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":32006,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ortivo_bangbae/","foodTypes":"이탈리아식","latitude":37.4981363159323,"longitude":126.98531839174,"bookYear":null,"nameKR":"오스테리아오티보","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":32090,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lepain__","foodTypes":"디저트/차/베이커리","latitude":37.4966857699647,"longitude":126.988420813084,"bookYear":null,"nameKR":"르팡","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":32522,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4849099516077,"longitude":126.984412148813,"bookYear":null,"nameKR":"지구커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":35338,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seouljung_official/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4780700043146,"longitude":126.982906864823,"bookYear":null,"nameKR":"서울정양평해장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":35355,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/didim_global/","foodTypes":"한식(어패류)","latitude":37.4777007617804,"longitude":126.982236074572,"bookYear":null,"nameKR":"도쿄하나(사당점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":36282,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4790670384593,"longitude":126.984031565487,"bookYear":null,"nameKR":"수미향","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":30048,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.485808292276666,"longitude":126.98096383359375,"bookYear":null,"nameKR":"이수회관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":30050,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.487318319334385,"longitude":126.98057901073706,"bookYear":null,"nameKR":"수연갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":31790,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rrang_rang","foodTypes":"디저트/차/베이커리","latitude":37.490972553287,"longitude":126.981816473101,"bookYear":null,"nameKR":"카페오다가다x랑랑카롱","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":32307,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/river_bell_","foodTypes":"디저트/차/베이커리","latitude":37.4759184430834,"longitude":126.976394579039,"bookYear":null,"nameKR":"리버벨","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":32947,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/muchcoffee_official/","foodTypes":"디저트/차/베이커리","latitude":37.480624292907,"longitude":126.979092981405,"bookYear":null,"nameKR":"머치커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":33844,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_petitours.bakery/","foodTypes":"디저트/차/베이커리","latitude":37.4843790909712,"longitude":126.980281616349,"bookYear":null,"nameKR":"쁘띠우스(이수점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":33856,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4753785551349,"longitude":126.980862131803,"bookYear":null,"nameKR":"두리닭발","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":34549,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4787218686889,"longitude":126.979416177662,"bookYear":null,"nameKR":"장가네토종순대국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":35643,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sconely_/","foodTypes":"디저트/차/베이커리","latitude":37.4852868185085,"longitude":126.979012308858,"bookYear":null,"nameKR":"스코넬리(사당점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":36659,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/penny_hills/","foodTypes":"디저트/차/베이커리","latitude":37.4778936545983,"longitude":126.980992409628,"bookYear":null,"nameKR":"페니힐스커피스테이션","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":3857,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4862850569156,"longitude":126.980662610028,"bookYear":null,"nameKR":"애플하우스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":5661,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.487932,"longitude":126.98193,"bookYear":null,"nameKR":"남성집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":14245,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.48562,"longitude":126.9808,"bookYear":null,"nameKR":"단아한정식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":15211,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.47513,"longitude":126.981112,"bookYear":null,"nameKR":"제주토종흑돼지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":15574,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.477866,"longitude":126.980814,"bookYear":null,"nameKR":"바다회세꼬시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":18310,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.485038,"longitude":126.98027,"bookYear":null,"nameKR":"해미수산","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":19437,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.47534,"longitude":126.98085,"bookYear":null,"nameKR":"만경양육관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":21514,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.484608,"longitude":126.981638,"bookYear":null,"nameKR":"잇나인","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":24024,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.487350868779785,"longitude":126.98128003971489,"bookYear":null,"nameKR":"남강장어촌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":24785,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.474773,"longitude":126.981184,"bookYear":null,"nameKR":"홍태양양꼬치","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":28205,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.48215,"longitude":127.004,"bookYear":null,"nameKR":"민어사랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"15. 방배/사당/이수","status":"ACTIVE"},
        {"id":1544,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.52815,"longitude":126.8758,"bookYear":null,"nameKR":"스시노미찌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":3105,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.535565,"longitude":126.878747,"bookYear":null,"nameKR":"수미가샤브샤브","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":7545,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.51584,"longitude":126.836528,"bookYear":null,"nameKR":"봉피양(신월점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":12559,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.533911,"longitude":126.880945,"bookYear":null,"nameKR":"대합탕수제비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":23430,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.540258,"longitude":126.833506,"bookYear":null,"nameKR":"범생이포차","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":26297,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.536335,"longitude":126.881113,"bookYear":null,"nameKR":"나폴레옹과자점(목동점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":30127,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.54902214402842,"longitude":126.86447977969489,"bookYear":null,"nameKR":"목동찹쌀꽈배기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":30268,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gorae_abang/","foodTypes":"한식(일반한식)","latitude":37.52817822371467,"longitude":126.87577187643134,"bookYear":null,"nameKR":"고래아방","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":31925,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5350091554353,"longitude":126.825843944972,"bookYear":null,"nameKR":"브니엘커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":33231,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5281783139269,"longitude":126.875771989415,"bookYear":null,"nameKR":"일도씨닭갈비(목동점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34094,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gsh_coffee","foodTypes":"디저트/차/베이커리","latitude":37.5200828278632,"longitude":126.860302811899,"bookYear":null,"nameKR":"지소현로스터스커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34194,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mokdon72_mokdong/","foodTypes":"한식(육류)","latitude":37.5262374696334,"longitude":126.862210005882,"bookYear":null,"nameKR":"목돈72","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34430,"bookStatus":"비활성","websiteInstagram":"https://www.instagram/cafe_montroyal ","foodTypes":"디저트/차/베이커리","latitude":37.5212269722475,"longitude":126.874981935845,"bookYear":null,"nameKR":"카페몽뜨로얄","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34456,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/classybean_/","foodTypes":"디저트/차/베이커리","latitude":37.5299956155624,"longitude":126.875071951863,"bookYear":null,"nameKR":"클래시빈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34650,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/c_town_mokdong","foodTypes":"한식(육류)","latitude":37.5281783139269,"longitude":126.875771989415,"bookYear":null,"nameKR":"청기와타운(목동점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":35186,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yours_bake.shop/","foodTypes":"디저트/차/베이커리","latitude":37.5374776528581,"longitude":126.88167161741,"bookYear":null,"nameKR":"유어스베이크샵","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":35791,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5244827678664,"longitude":126.864231748994,"bookYear":null,"nameKR":"김소연육개장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":30400,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.54726447396139,"longitude":126.87526945980275,"bookYear":null,"nameKR":"패밀리만두","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":30464,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.54949498125387,"longitude":126.86510359042522,"bookYear":null,"nameKR":"안동돼지갈비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":30958,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5241947609737,"longitude":126.873775245563,"bookYear":null,"nameKR":"뉴웨이브커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":31837,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ku_chef","foodTypes":"일식","latitude":37.5255909146643,"longitude":126.872731774722,"bookYear":null,"nameKR":"갓포쿠마카세","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":31913,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5257681359469,"longitude":126.872142527023,"bookYear":null,"nameKR":"강릉스낵","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":31983,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5503902688818,"longitude":126.867059742345,"bookYear":null,"nameKR":"다원레스토랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":33269,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/guumblues","foodTypes":"디저트/차/베이커리","latitude":37.5240569505281,"longitude":126.874068586829,"bookYear":null,"nameKR":"구움블루스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":33371,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/focoa_f/","foodTypes":"기타","latitude":37.5453182959329,"longitude":126.878358294151,"bookYear":null,"nameKR":"포코아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":33403,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5255974500992,"longitude":126.872609358932,"bookYear":null,"nameKR":"스시다마","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":33721,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/flux_coffeeroasters/","foodTypes":"디저트/차/베이커리","latitude":37.5486459561113,"longitude":126.868282850569,"bookYear":null,"nameKR":"플럭스커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":33837,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5256730292822,"longitude":126.872762745506,"bookYear":null,"nameKR":"스시다마스토어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34060,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pogon_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.5228954377892,"longitude":126.873533539021,"bookYear":null,"nameKR":"포곤포곤","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34233,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5305705989188,"longitude":126.863325472398,"bookYear":null,"nameKR":"파장군","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34457,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5241786520066,"longitude":126.872201010791,"bookYear":null,"nameKR":"젠틀한식탁","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":34716,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5255764400721,"longitude":126.872844701201,"bookYear":null,"nameKR":"오목교곱창(목동본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":35642,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistro_yum_/","foodTypes":"이탈리아식","latitude":37.546524586703,"longitude":126.872580884237,"bookYear":null,"nameKR":"윰","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":35777,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bbang_si_92","foodTypes":"디저트/차/베이커리","latitude":37.5449297791494,"longitude":126.870473666092,"bookYear":null,"nameKR":"빵의시간","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":6453,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.546729,"longitude":126.875138,"bookYear":null,"nameKR":"옛날빈대떡","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":6926,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.545691,"longitude":126.873657,"bookYear":null,"nameKR":"미시락칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":18220,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.523849,"longitude":126.873797,"bookYear":null,"nameKR":"가진항물회","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":366,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.50378,"longitude":126.921268,"bookYear":null,"nameKR":"바닷가재가리비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":3801,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.55199,"longitude":126.8641,"bookYear":null,"nameKR":"강서복집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":8164,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5103120577401,"longitude":126.898596811165,"bookYear":null,"nameKR":"영신반점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":12880,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/go_ddong","foodTypes":"디저트/차/베이커리","latitude":37.552005,"longitude":126.864627,"bookYear":null,"nameKR":"콩볶는집고양이똥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":13021,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5108620941449,"longitude":126.917034828349,"bookYear":null,"nameKR":"곰집칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":14120,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.4916,"longitude":126.9082,"bookYear":null,"nameKR":"삼우치킨센타","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":22381,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.548069,"longitude":126.835648,"bookYear":null,"nameKR":"공병득쉐프","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":28468,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.492112,"longitude":126.897773,"bookYear":null,"nameKR":"봉선마라탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":28469,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.495261,"longitude":126.898058,"bookYear":null,"nameKR":"화룽마라룽샤","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":30869,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.4899373803571,"longitude":126.898323808367,"bookYear":null,"nameKR":"사계천면관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":32210,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.eastwood","foodTypes":"디저트/차/베이커리","latitude":37.5066628067793,"longitude":126.899648710664,"bookYear":null,"nameKR":"이스트우드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":32451,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5013721744075,"longitude":126.918726889644,"bookYear":null,"nameKR":"춘뽕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":34025,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kyril_patisserie/","foodTypes":"디저트/차/베이커리","latitude":37.5545625286683,"longitude":126.859975916278,"bookYear":null,"nameKR":"키릴","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":35153,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kkotpig5892/","foodTypes":"한식(육류)","latitude":37.553119021754,"longitude":126.863967284969,"bookYear":null,"nameKR":"골목꽃돼지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"22. 목동/화곡","status":"ACTIVE"},
        {"id":31299,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.498570959612,"longitude":126.924165699568,"bookYear":null,"nameKR":"의성식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":1162,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4974611469747,"longitude":126.92093867558,"bookYear":null,"nameKR":"서일순대국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"16. 동작구/대림/대방","status":"ACTIVE"},
        {"id":77,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.504875,"longitude":127.064711,"bookYear":null,"nameKR":"군산횟집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":1302,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.487278,"longitude":127.052821,"bookYear":null,"nameKR":"타워차이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":4647,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.505538,"longitude":127.064244,"bookYear":null,"nameKR":"스시유","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":5860,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.487391,"longitude":127.052765,"bookYear":null,"nameKR":"그안에맛있는이탈리안","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":6771,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4873,"longitude":127.0516,"bookYear":null,"nameKR":"김영모과자점(도곡타워점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":7258,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.4877632466167,"longitude":127.052530150983,"bookYear":null,"nameKR":"야마모토스시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":7467,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.499598,"longitude":127.060818,"bookYear":null,"nameKR":"할매재첩국(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":17172,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.504921988755,"longitude":127.058347780939,"bookYear":null,"nameKR":"정다원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":17922,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.50391,"longitude":127.0591,"bookYear":null,"nameKR":"소호정(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":29840,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/t.f.m_butai","foodTypes":"일식","latitude":37.506171732639025,"longitude":127.06004648139945,"bookYear":null,"nameKR":"부타이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":30554,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/g_honey","foodTypes":"바/주점","latitude":37.505851286107514,"longitude":127.0594309692748,"bookYear":null,"nameKR":"키친바락","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":33970,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5051969938506,"longitude":127.058127907336,"bookYear":null,"nameKR":"오성식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":36040,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ansic_restaurant/","foodTypes":"한식(일반한식)","latitude":37.5025884116209,"longitude":127.065025380615,"bookYear":null,"nameKR":"안식레스토랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":30859,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chilango_kr","foodTypes":"중남미식","latitude":37.5026105921601,"longitude":127.052697638181,"bookYear":null,"nameKR":"칠랑고","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":31532,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mochibang","foodTypes":"디저트/차/베이커리","latitude":37.5025205738702,"longitude":127.054891247787,"bookYear":null,"nameKR":"모찌방","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":34429,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/love_fruits123/","foodTypes":"디저트/차/베이커리","latitude":37.5028362054824,"longitude":127.057337360308,"bookYear":null,"nameKR":"과일이좋아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":34648,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lees_soba/","foodTypes":"일식","latitude":37.4987659975278,"longitude":127.053105331769,"bookYear":null,"nameKR":"리즈소바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":35452,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4981861425089,"longitude":127.054419556037,"bookYear":null,"nameKR":"나따오비까(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":46,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.504361,"longitude":127.054333,"bookYear":null,"nameKR":"고메고매","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":1471,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.500502,"longitude":127.051584,"bookYear":null,"nameKR":"신흥식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":1472,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.50189072171177,"longitude":127.05892117572154,"bookYear":null,"nameKR":"홍운장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":3393,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.50147,"longitude":127.0531,"bookYear":null,"nameKR":"두꺼비정육점식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":3800,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.498391,"longitude":127.053274,"bookYear":null,"nameKR":"웰컴투조개골","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":5183,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.505163,"longitude":127.055327,"bookYear":null,"nameKR":"수하동","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":7367,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/napoleon_bakery1968/","foodTypes":"디저트/차/베이커리","latitude":37.492387,"longitude":127.057935,"bookYear":null,"nameKR":"나폴레옹제과점(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":19388,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.503604,"longitude":127.052833,"bookYear":null,"nameKR":"원조한방삼계탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":29469,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/terarosacoffee/","foodTypes":"디저트/차/베이커리","latitude":37.5058132551014,"longitude":127.05613299051616,"bookYear":null,"nameKR":"테라로사(포스코점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":5227,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5038111062408,"longitude":127.021292917493,"bookYear":null,"nameKR":"금강바비큐","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":7672,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.510768,"longitude":127.019491,"bookYear":null,"nameKR":"진도집(논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":14964,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.497428,"longitude":127.05733,"bookYear":null,"nameKR":"강남면옥(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":18140,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.511764,"longitude":127.01992,"bookYear":null,"nameKR":"향토","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":19086,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.50601549804641,"longitude":127.02231211039732,"bookYear":null,"nameKR":"인동상회포항집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":29101,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.504445,"longitude":127.019985,"bookYear":null,"nameKR":"분노지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":29337,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.509418,"longitude":127.021093,"bookYear":null,"nameKR":"도셰프","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":29594,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.49367642276627,"longitude":127.06035762380232,"bookYear":null,"nameKR":"김영모과자점(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":30410,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/quelquechose_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.49699397604059,"longitude":127.05662396296376,"bookYear":null,"nameKR":"껠끄쇼즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":30467,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.51043071324932,"longitude":127.02071185275763,"bookYear":null,"nameKR":"성천막국수(논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":31580,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5045763454921,"longitude":127.022188859276,"bookYear":null,"nameKR":"명동피자(신논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":31804,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5084546819407,"longitude":127.021920823555,"bookYear":null,"nameKR":"오하라식당교토","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":32450,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yunagi.official/","foodTypes":"일식","latitude":37.5104201016707,"longitude":127.020072465346,"bookYear":null,"nameKR":"유나기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":32755,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dduckhamji/","foodTypes":"디저트/차/베이커리","latitude":37.4936757916501,"longitude":127.060358414855,"bookYear":null,"nameKR":"떡함지(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":32882,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5065179380008,"longitude":127.023045031273,"bookYear":null,"nameKR":"영동교집(강남직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":33236,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4945700144534,"longitude":127.062659097416,"bookYear":null,"nameKR":"일도씨닭갈비(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":33496,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4945700144534,"longitude":127.062659097416,"bookYear":null,"nameKR":"이스트빌리지서울(대치점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":34097,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mui_gangnam/","foodTypes":"바/주점","latitude":37.5063515752252,"longitude":127.022772411063,"bookYear":null,"nameKR":"강남모밀무이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":34475,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5123974704208,"longitude":127.021469882661,"bookYear":null,"nameKR":"강씨가문의족발(강남직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":34781,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.492752188617,"longitude":127.058529415562,"bookYear":null,"nameKR":"구좌리얼크니손칼국수(강남대치직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"09. 대치/도곡","status":"ACTIVE"},
        {"id":35870,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/concerto_2023","foodTypes":"이탈리아식","latitude":37.5113661000642,"longitude":127.01760031787,"bookYear":null,"nameKR":"콘체르토","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":36001,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lesamishonnetes/","foodTypes":"디저트/차/베이커리","latitude":37.5120911295095,"longitude":127.022906038514,"bookYear":null,"nameKR":"레자미오네뜨","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":36316,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/variegata_kr/","foodTypes":"이탈리아식","latitude":37.5056738874165,"longitude":127.022042835067,"bookYear":null,"nameKR":"바리에가타(신논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":29921,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jubangryu/","foodTypes":"중식","latitude":37.50946404561158,"longitude":127.02481499499028,"bookYear":null,"nameKR":"주방류","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":30550,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.509284157818044,"longitude":127.02327672615588,"bookYear":null,"nameKR":"함지곱창전문","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":31019,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gangnam_fish/","foodTypes":"바/주점","latitude":37.5078978596254,"longitude":127.023338614644,"bookYear":null,"nameKR":"강남어시장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":31100,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/shanghairoo/","foodTypes":"중식","latitude":37.5078978596254,"longitude":127.023338614644,"bookYear":null,"nameKR":"상해루(강남점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":32138,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5097082123085,"longitude":127.026095076769,"bookYear":null,"nameKR":"봉골레숲","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":32655,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5079363715371,"longitude":127.02359581965,"bookYear":null,"nameKR":"화다인","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":34211,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/okuda_sushi/","foodTypes":"한식(일반한식)","latitude":37.5096807470291,"longitude":127.025613243222,"bookYear":null,"nameKR":"오쿠다","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":34325,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yuckduck_simyeon/","foodTypes":"한식(육류)","latitude":37.5105152390861,"longitude":127.023536119994,"bookYear":null,"nameKR":"심연한우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":35154,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/real.majju_official/","foodTypes":"기타","latitude":37.5054974051802,"longitude":127.025081045457,"bookYear":null,"nameKR":"마쮸","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":36703,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5071378502264,"longitude":127.024290570182,"bookYear":null,"nameKR":"원할머니보쌈족발(논현직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":6526,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.510323,"longitude":127.02292,"bookYear":null,"nameKR":"언양불고기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":7719,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.506241,"longitude":127.026114,"bookYear":null,"nameKR":"멘야산다이메(논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"05. 논현역/영동시장","status":"ACTIVE"},
        {"id":513,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.52113,"longitude":127.0351,"bookYear":null,"nameKR":"손칼국수감자수제비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":6943,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.520752,"longitude":127.036498,"bookYear":null,"nameKR":"반포등심","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":7152,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.522018,"longitude":127.03756,"bookYear":null,"nameKR":"주주포차","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":9274,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.52198,"longitude":127.0363,"bookYear":null,"nameKR":"js가든","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":18268,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.521669,"longitude":127.036576,"bookYear":null,"nameKR":"현해","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":21094,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jennysboutiquecake/","foodTypes":"디저트/차/베이커리","latitude":37.520608,"longitude":127.037863,"bookYear":null,"nameKR":"제니스케이크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":29589,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.52149467564036,"longitude":127.0369592373709,"bookYear":null,"nameKR":"js가든블랙","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":31253,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5214740403464,"longitude":127.03609271253,"bookYear":null,"nameKR":"150","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":31530,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cettesaison_official/","foodTypes":"디저트/차/베이커리","latitude":37.520669645919,"longitude":127.038035853593,"bookYear":null,"nameKR":"쎄쎄종","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32045,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/61jeom/","foodTypes":"한식(육류)","latitude":37.5201986891312,"longitude":127.035135328462,"bookYear":null,"nameKR":"육일점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32898,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dosan_ddukbaeki/","foodTypes":"한식(육류)","latitude":37.5215513588997,"longitude":127.038617070217,"bookYear":null,"nameKR":"도산뚝배기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":33545,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jingmonglu_mapotoufu/","foodTypes":"중식","latitude":37.5206301397046,"longitude":127.036162554013,"bookYear":null,"nameKR":"경몽루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":34444,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5206301397046,"longitude":127.036162554013,"bookYear":null,"nameKR":"새돌집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":35164,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/osteria_natura/","foodTypes":"이탈리아식","latitude":37.5216232838564,"longitude":127.038815863068,"bookYear":null,"nameKR":"오스테리아나뚜라","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":36027,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/godai30_4/","foodTypes":"한식(육류)","latitude":37.5216144578157,"longitude":127.03769142353,"bookYear":null,"nameKR":"고다이(청담점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":30042,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.51995738531839,"longitude":127.03134774051836,"bookYear":null,"nameKR":"쿠시마사","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":31439,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"컨템포러리","latitude":37.5185161976298,"longitude":127.03078867625,"bookYear":null,"nameKR":"다이닝포레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32010,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.5182213409132,"longitude":127.029925471554,"bookYear":null,"nameKR":"디도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32011,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/evvivaseoul/","foodTypes":"이탈리아식","latitude":37.5180802226563,"longitude":127.030008782353,"bookYear":null,"nameKR":"에비바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":32798,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pizzaiconic","foodTypes":"미국식","latitude":37.5106397134921,"longitude":127.036186123171,"bookYear":null,"nameKR":"피자아이코닉","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":33593,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/BAR_OPNNG/","foodTypes":"기타","latitude":37.518882305163,"longitude":127.031018682668,"bookYear":null,"nameKR":"오프닝","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":33598,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5183265902586,"longitude":127.029877438746,"bookYear":null,"nameKR":"정육공방","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":33718,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/winebar6_9/","foodTypes":"바/주점","latitude":37.517740028377,"longitude":127.025284566568,"bookYear":null,"nameKR":"육다시구","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":34414,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fried_chicken2527/","foodTypes":"한식(가금류)","latitude":37.5093589130056,"longitude":127.035964839491,"bookYear":null,"nameKR":"계열사(논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":1402,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.518819,"longitude":127.029829,"bookYear":null,"nameKR":"목련식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":5093,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.51646903493559,"longitude":127.03006130097077,"bookYear":null,"nameKR":"헤야","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":7295,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.508462,"longitude":127.036926,"bookYear":null,"nameKR":"토담골(논현점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":9088,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5203859293775,"longitude":127.032579574469,"bookYear":null,"nameKR":"봉산집(도산대로점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":9285,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.518498,"longitude":127.027986,"bookYear":null,"nameKR":"와인북카페","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":12480,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.516642,"longitude":127.028657,"bookYear":null,"nameKR":"더플레이트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":13164,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/shintteoksoon/","foodTypes":"한식(일반한식)","latitude":37.5085687138372,"longitude":127.03420548965,"bookYear":null,"nameKR":"신천할매떡볶이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":55,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.516736,"longitude":127.037241,"bookYear":null,"nameKR":"고향집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":7212,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5713698504776,"longitude":126.80296035927,"bookYear":null,"nameKR":"딘타이펑(김포롯데몰점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":16304,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.512409,"longitude":127.034433,"bookYear":null,"nameKR":"진대감","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":22015,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.510889,"longitude":127.032509,"bookYear":null,"nameKR":"세종한우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":30144,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/136s6th_official/","foodTypes":"일식","latitude":37.51737908224573,"longitude":127.03635090661177,"bookYear":null,"nameKR":"136길육미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":33131,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/homesukki_0/","foodTypes":"기타","latitude":37.5162828438668,"longitude":127.035718971503,"bookYear":null,"nameKR":"홈수끼(학동점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":33264,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5207692428371,"longitude":127.039301614503,"bookYear":null,"nameKR":"스시아오타","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":33892,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/___angled___","foodTypes":"디저트/차/베이커리","latitude":37.571005283099,"longitude":126.808641305119,"bookYear":null,"nameKR":"앵글드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":35909,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5112425674198,"longitude":127.033143621098,"bookYear":null,"nameKR":"굿그릴","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":36130,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.512828698397,"longitude":127.028280297405,"bookYear":null,"nameKR":"판교집(학동점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"04. 논현동","status":"ACTIVE"},
        {"id":30320,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.56013407471462,"longitude":126.83153323051546,"bookYear":null,"nameKR":"주엔","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":30335,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/epicure_france_bistro/","foodTypes":"프랑스식","latitude":37.55931399088023,"longitude":126.8352382965275,"bookYear":null,"nameKR":"레피큐르","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":31758,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5598184721733,"longitude":126.83524805093,"bookYear":null,"nameKR":"참치왕양승호","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":32232,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakerylune/","foodTypes":"디저트/차/베이커리","latitude":37.5591532341498,"longitude":126.836520966803,"bookYear":null,"nameKR":"베이커리룬","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":34583,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.561058561996,"longitude":126.838610552611,"bookYear":null,"nameKR":"스시카이시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":35550,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dongumung_official","foodTypes":"한식(육류)","latitude":37.5605537644679,"longitude":126.833890184435,"bookYear":null,"nameKR":"돈구멍(마곡본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":35614,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5590996762805,"longitude":126.827995667592,"bookYear":null,"nameKR":"청춘꼬마김밥(마곡역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":35892,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wolhwa_sikdang_magok/","foodTypes":"한식(육류)","latitude":37.5605448342463,"longitude":126.838427628497,"bookYear":null,"nameKR":"월화식당(마곡점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":35945,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5619100886907,"longitude":126.837098401629,"bookYear":null,"nameKR":"레드플랜트(마곡점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":36516,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.559915259194,"longitude":126.8343608462,"bookYear":null,"nameKR":"닭장맥주(발산점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":8176,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.561962,"longitude":126.807717,"bookYear":null,"nameKR":"도일처","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":14635,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5725815309206,"longitude":126.812546830183,"bookYear":null,"nameKR":"중화루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":18729,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.56054375259591,"longitude":126.83842729128854,"bookYear":null,"nameKR":"리퍼블릭오브커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":29894,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/naoki_1133","foodTypes":"일식","latitude":37.56157674480064,"longitude":126.84008205255321,"bookYear":null,"nameKR":"나오키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":29927,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rouge_pepper/","foodTypes":"바/주점","latitude":37.56902689161126,"longitude":126.82778233674225,"bookYear":null,"nameKR":"루즈페퍼","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":30243,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/manafulll/","foodTypes":"바/주점","latitude":37.56786593186267,"longitude":126.82745448900788,"bookYear":null,"nameKR":"마나식당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":30246,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafewoodjean","foodTypes":"디저트/차/베이커리","latitude":37.5606295118078,"longitude":126.8327227798663,"bookYear":null,"nameKR":"우드진","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":30323,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.55959871799013,"longitude":126.83268433971952,"bookYear":null,"nameKR":"담방담방수제비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":30326,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oksoban_official/","foodTypes":"일식","latitude":37.5606295118078,"longitude":126.8327227798663,"bookYear":null,"nameKR":"옥소반","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":30331,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thaitogo_magok","foodTypes":"아시아식","latitude":37.5606295118078,"longitude":126.8327227798663,"bookYear":null,"nameKR":"타이투고","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":31387,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mano_sapore/","foodTypes":"이탈리아식","latitude":37.5689300485123,"longitude":126.827113698601,"bookYear":null,"nameKR":"마노사포레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":31643,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5589430708389,"longitude":126.837790496795,"bookYear":null,"nameKR":"본대가(발산점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":31902,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ope.neat","foodTypes":"기타","latitude":37.559933531546,"longitude":126.840498784915,"bookYear":null,"nameKR":"오프닛","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":32617,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5600800563689,"longitude":126.832639349443,"bookYear":null,"nameKR":"우니크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":33660,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5673140391214,"longitude":126.827554917518,"bookYear":null,"nameKR":"엘리케이커","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":34002,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seochon_chef_kim/","foodTypes":"이탈리아식","latitude":37.5597527538278,"longitude":126.830889417254,"bookYear":null,"nameKR":"서촌김씨오스테리아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":34681,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5588203160036,"longitude":126.840006908063,"bookYear":null,"nameKR":"청기와타운(마곡점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":35445,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/koreanbeef_top/","foodTypes":"한식(육류)","latitude":37.5690270688312,"longitude":126.827780298847,"bookYear":null,"nameKR":"일편등심(마곡나루점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":35631,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.568638335633,"longitude":126.82578514534,"bookYear":null,"nameKR":"땡순이아구찜본점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":36017,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5604015515818,"longitude":126.829006208171,"bookYear":null,"nameKR":"고향옥얼큰순대국(마곡역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"21. 김포/마곡","status":"ACTIVE"},
        {"id":30567,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.493395737111655,"longitude":127.01164268206712,"bookYear":null,"nameKR":"카루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":31554,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.4952688686683,"longitude":127.013884154638,"bookYear":null,"nameKR":"스시카네","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":32306,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.4959243733637,"longitude":127.014449919529,"bookYear":null,"nameKR":"미슌","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":34103,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/delibag.kr/","foodTypes":"디저트/차/베이커리","latitude":37.4956714063155,"longitude":127.019253188234,"bookYear":null,"nameKR":"델리백(서초점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":35565,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_moho/","foodTypes":"디저트/차/베이커리","latitude":37.4742980742091,"longitude":127.036065605417,"bookYear":null,"nameKR":"카페모호","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":35665,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/garamcoffeeroasters","foodTypes":"디저트/차/베이커리","latitude":37.4543364760474,"longitude":127.053542034923,"bookYear":null,"nameKR":"가람커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":35685,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4777908206157,"longitude":127.036997622854,"bookYear":null,"nameKR":"더호감","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":36319,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mokyeon_beef/","foodTypes":"한식(육류)","latitude":37.4963287751304,"longitude":127.014202908723,"bookYear":null,"nameKR":"목연","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":181,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.493621,"longitude":127.012617,"bookYear":null,"nameKR":"서초원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":6451,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.49369,"longitude":127.016158,"bookYear":null,"nameKR":"디바야누스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":15345,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4935782291686,"longitude":127.009163319685,"bookYear":null,"nameKR":"송학횟집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":18038,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.49295,"longitude":127.0105,"bookYear":null,"nameKR":"북해도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":18830,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.496852,"longitude":127.014809,"bookYear":null,"nameKR":"하레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":28548,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.498158,"longitude":127.013304,"bookYear":null,"nameKR":"라포레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":4801,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.48545,"longitude":127.0173,"bookYear":null,"nameKR":"삼학도","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":5159,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.488922,"longitude":127.013175,"bookYear":null,"nameKR":"항방양육관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":7928,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.492132,"longitude":127.011737,"bookYear":null,"nameKR":"이모네쭈꾸미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":12962,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.48616,"longitude":127.0161,"bookYear":null,"nameKR":"우작설렁탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":13178,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.490972,"longitude":127.012671,"bookYear":null,"nameKR":"주호","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":19734,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4927095526782,"longitude":127.012308156357,"bookYear":null,"nameKR":"속초항뱃머리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":21817,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.493814,"longitude":127.016544,"bookYear":null,"nameKR":"베이커스테일","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":29592,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.49379081747818,"longitude":127.0213902615694,"bookYear":null,"nameKR":"김영모과자점(서초동성당점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":31556,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.4906837898848,"longitude":127.010747041808,"bookYear":null,"nameKR":"스시카이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":32581,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/foodsinfood/","foodTypes":"기타","latitude":37.4910269304049,"longitude":127.013854535029,"bookYear":null,"nameKR":"믿을신","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":32925,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4913955879416,"longitude":127.011674033066,"bookYear":null,"nameKR":"큰집포항물회","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":33116,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mukyang2food/","foodTypes":"한식(육류)","latitude":37.490174728018,"longitude":127.013327222883,"bookYear":null,"nameKR":"영동교집(교대직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":34269,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hozu__official/","foodTypes":"바/주점","latitude":37.486312988348,"longitude":127.014995322006,"bookYear":null,"nameKR":"호주","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":34643,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4913952797163,"longitude":127.012051709016,"bookYear":null,"nameKR":"청기와타운(교대점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":35470,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fig_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.4936435887032,"longitude":127.019323116547,"bookYear":null,"nameKR":"피그커피(교대점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":35779,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.492088163799,"longitude":127.01281837401,"bookYear":null,"nameKR":"교대평상집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":36089,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4853558653878,"longitude":127.01251804697,"bookYear":null,"nameKR":"토다서초","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":36185,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oldshanghai_gyodae","foodTypes":"바/주점","latitude":37.4903893393009,"longitude":127.013404378251,"bookYear":null,"nameKR":"올드상해","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":29983,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chochobakeshop/","foodTypes":"디저트/차/베이커리","latitude":37.4847721516939,"longitude":127.010118674975,"bookYear":null,"nameKR":"초초베이크숍","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":30205,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/salondenoir/","foodTypes":"중남미식","latitude":37.48891983042504,"longitude":127.00806436032765,"bookYear":null,"nameKR":"살롱드누아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":31371,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.4856836954399,"longitude":127.004329601311,"bookYear":null,"nameKR":"디토","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":32568,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffee_dna/","foodTypes":"디저트/차/베이커리","latitude":37.4868783561476,"longitude":127.003931890564,"bookYear":null,"nameKR":"커피디엔에이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":33390,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/slicepizzamarket/","foodTypes":"이탈리아식","latitude":37.4844430966259,"longitude":127.00909379885,"bookYear":null,"nameKR":"슬라이스피자마켓(서초점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":35523,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4896623611196,"longitude":127.009160353694,"bookYear":null,"nameKR":"함포해장(서초점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":35707,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/metable.official/","foodTypes":"이탈리아식","latitude":37.4919549787835,"longitude":127.011204622388,"bookYear":null,"nameKR":"미테이블(교대직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":36023,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wooldolmok_seocho/","foodTypes":"한식(일반한식)","latitude":37.4898397627834,"longitude":127.008013575788,"bookYear":null,"nameKR":"진도울돌목가는길 ","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":582,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.480153,"longitude":127.011373,"bookYear":null,"nameKR":"카나디안랍스터","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":1220,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4890315864475,"longitude":127.00759568441,"bookYear":null,"nameKR":"영변","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":3228,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4831650145129,"longitude":127.007857512586,"bookYear":null,"nameKR":"한우만","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":3570,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.489127,"longitude":127.009677,"bookYear":null,"nameKR":"만리성","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":5032,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.483389,"longitude":127.009634,"bookYear":null,"nameKR":"소들녘","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":12967,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.48473,"longitude":127.0101,"bookYear":null,"nameKR":"낙천정","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":15032,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4898,"longitude":127.008,"bookYear":null,"nameKR":"울돌목가는길","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":24672,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4823,"longitude":127.005451,"bookYear":null,"nameKR":"율고떡방","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":24981,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.48686,"longitude":127.0083,"bookYear":null,"nameKR":"루스티크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":29494,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/songi_19oct","foodTypes":"디저트/차/베이커리","latitude":37.481241140603,"longitude":127.010664624273,"bookYear":null,"nameKR":"10월19일","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":245,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lacallas_","foodTypes":"이탈리아식","latitude":37.48148,"longitude":127.013036,"bookYear":null,"nameKR":"라칼라스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":6525,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.48453,"longitude":127.030019,"bookYear":null,"nameKR":"임병주산동칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":6967,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.484312,"longitude":127.020627,"bookYear":null,"nameKR":"레프레미스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":7052,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.485804,"longitude":127.029617,"bookYear":null,"nameKR":"햇쌀마루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":13334,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.486853,"longitude":127.023648,"bookYear":null,"nameKR":"장꼬방묵은김치찌개전문","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":22369,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.485373,"longitude":127.031334,"bookYear":null,"nameKR":"라크렘드밀트와","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":29082,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fritzcoffeecompany/","foodTypes":"디저트/차/베이커리","latitude":37.485331,"longitude":127.032183,"bookYear":null,"nameKR":"프릳츠(양재점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":32067,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/reflect_coffee","foodTypes":"디저트/차/베이커리","latitude":37.4870103195509,"longitude":127.028483359749,"bookYear":null,"nameKR":"리플렉트커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":33090,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.485005539175,"longitude":127.032139751131,"bookYear":null,"nameKR":"계약소","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":33748,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.4838120876808,"longitude":127.012421231706,"bookYear":null,"nameKR":"몬안베띠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":33973,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seocho1346/","foodTypes":"디저트/차/베이커리","latitude":37.4873981176677,"longitude":127.025297742306,"bookYear":null,"nameKR":"서초커피작업실","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":34715,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4866539924548,"longitude":127.030906756049,"bookYear":null,"nameKR":"리플렉트커피로스팅랩","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":35562,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.481137954971,"longitude":127.013504028268,"bookYear":null,"nameKR":"포호아(예술의전당점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":31229,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/whitelier_official","foodTypes":"디저트/차/베이커리","latitude":37.5075121874388,"longitude":127.008065342049,"bookYear":null,"nameKR":"화이트리에","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":32385,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thecoffeecompany_official/","foodTypes":"디저트/차/베이커리","latitude":37.4989399887639,"longitude":127.010722667041,"bookYear":null,"nameKR":"더커피컴퍼니","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":32756,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dduckhamji/","foodTypes":"디저트/차/베이커리","latitude":37.4893014985012,"longitude":127.024865425793,"bookYear":null,"nameKR":"떡함지(서초점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"14. 교대/남부터미널/서초","status":"ACTIVE"},
        {"id":33281,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/berlinkranz/","foodTypes":"디저트/차/베이커리","latitude":37.5021163351127,"longitude":127.011368089573,"bookYear":null,"nameKR":"베를린크란츠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":34317,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.500597,"longitude":127.005184,"bookYear":null,"nameKR":"플렌티카페","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":36148,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5075121874388,"longitude":127.008065342049,"bookYear":null,"nameKR":"온유월솥뚜껑삼겹살","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":36433,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.50654540385,"longitude":127.004868141536,"bookYear":null,"nameKR":"담은","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":5285,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.504329,"longitude":127.003605,"bookYear":null,"nameKR":"팥꽃나무집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":12412,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.502138,"longitude":126.987717,"bookYear":null,"nameKR":"미소의집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":16503,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5064357442307,"longitude":127.006834366246,"bookYear":null,"nameKR":"육각고기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":19075,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.50413,"longitude":127.0031,"bookYear":null,"nameKR":"호경전(신세계백화점강남점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":28459,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"프랑스식","latitude":37.50413,"longitude":127.003069,"bookYear":null,"nameKR":"꼴라주","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":28464,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.498427,"longitude":127.001969,"bookYear":null,"nameKR":"토박이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":29586,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5128156550848,"longitude":127.010659840374,"bookYear":null,"nameKR":"js가든(잠원점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":7043,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.503049,"longitude":126.995692,"bookYear":null,"nameKR":"김영모과자점(신반포점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":15387,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.550392,"longitude":127.126365,"bookYear":null,"nameKR":"지리산왕재봉흑돼지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":15982,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5424422933213,"longitude":127.124508080133,"bookYear":null,"nameKR":"엄마손만두","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":28256,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5044921229104,"longitude":127.007845270854,"bookYear":null,"nameKR":"모던눌랑","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":30552,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.503934456230404,"longitude":127.00241119724721,"bookYear":null,"nameKR":"순대실록(센트럴시티점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"12. 고속터미널/반포","status":"ACTIVE"},
        {"id":32231,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/119.gram/","foodTypes":"한식(가금류)","latitude":37.5449996605145,"longitude":127.130214565495,"bookYear":null,"nameKR":"119닭발","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":32289,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mindeulre_bakery","foodTypes":"디저트/차/베이커리","latitude":37.5479683170985,"longitude":127.143171795493,"bookYear":null,"nameKR":"민들레제과","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":32296,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soonjungkitchen","foodTypes":"기타","latitude":37.5366048791746,"longitude":127.133115595362,"bookYear":null,"nameKR":"순정키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":35129,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/marynmarycoffee_/","foodTypes":"디저트/차/베이커리","latitude":37.5297804400349,"longitude":127.116298355093,"bookYear":null,"nameKR":"메리앤메리 카페","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":35420,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/upcoffee88/","foodTypes":"디저트/차/베이커리","latitude":37.548105595048,"longitude":127.12537413715,"bookYear":null,"nameKR":"업커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":35771,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5384960736319,"longitude":127.127484882745,"bookYear":null,"nameKR":"연풍민락","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36188,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5350410744671,"longitude":127.119406400772,"bookYear":null,"nameKR":"카페 웨이홈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36468,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi_gonggan","foodTypes":"일식","latitude":37.5392723718664,"longitude":127.126734330403,"bookYear":null,"nameKR":"스시공간","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":31088,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5245591927157,"longitude":127.132979026886,"bookYear":null,"nameKR":"다람","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":31336,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5234991687093,"longitude":127.133613586692,"bookYear":null,"nameKR":"메모러블모먼트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":31978,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5308687506955,"longitude":127.123581487277,"bookYear":null,"nameKR":"토팡가커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":32201,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gim_patisserie","foodTypes":"디저트/차/베이커리","latitude":37.5271668963132,"longitude":127.126644385689,"bookYear":null,"nameKR":"짐빠띠스리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":33464,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/midday_sun_","foodTypes":"디저트/차/베이커리","latitude":37.5262700541204,"longitude":127.131656981562,"bookYear":null,"nameKR":"미드데이썬","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36081,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5274445905025,"longitude":127.130691662924,"bookYear":null,"nameKR":"채스우드커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36082,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chatswoodcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.530460784566,"longitude":127.124176020182,"bookYear":null,"nameKR":"리틀채스우드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":5137,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.524175,"longitude":127.131686,"bookYear":null,"nameKR":"나주관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":6070,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.536988,"longitude":127.126278,"bookYear":null,"nameKR":"독도쭈꾸미","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":7593,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.529839,"longitude":127.121925,"bookYear":null,"nameKR":"비사벌전주콩나물국밥(성내점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":14612,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5518738627213,"longitude":127.132449367669,"bookYear":null,"nameKR":"장홍규중화요리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":18269,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/harunoyuki_hana/","foodTypes":"디저트/차/베이커리","latitude":37.5241699658813,"longitude":127.131687115678,"bookYear":null,"nameKR":"하루노유키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":21621,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5528762968119,"longitude":127.128136105806,"bookYear":null,"nameKR":"청진생등심","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":1447,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.561579,"longitude":127.156413,"bookYear":null,"nameKR":"여수집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":9062,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5516963813123,"longitude":127.142869526386,"bookYear":null,"nameKR":"천호동원조춘천족발집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":14396,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.526884,"longitude":127.148065,"bookYear":null,"nameKR":"산수고원","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":14755,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5512989894637,"longitude":127.142837986406,"bookYear":null,"nameKR":"한성관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":19041,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.5265316642335,"longitude":127.145805086556,"bookYear":null,"nameKR":"동구밖장작구이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":22361,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.549026,"longitude":127.154286,"bookYear":null,"nameKR":"베이커리휴고","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":23563,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.552038955913,"longitude":127.143377173465,"bookYear":null,"nameKR":"숯불쭈꾸미구이","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":32108,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5573671931016,"longitude":127.168412440283,"bookYear":null,"nameKR":"스시미진","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":32165,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5574405229047,"longitude":127.16967893433,"bookYear":null,"nameKR":"모락베이커리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":33680,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jokbalsaja_official/","foodTypes":"한식(육류)","latitude":37.5355771394189,"longitude":127.127952286718,"bookYear":null,"nameKR":"족발사자","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":34654,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5330169698043,"longitude":127.138782705213,"bookYear":null,"nameKR":"청기와타운(길동점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":35032,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_4tential_","foodTypes":"디저트/차/베이커리","latitude":37.5483177946768,"longitude":127.146129739147,"bookYear":null,"nameKR":"로스터리카페포텐셜","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":35124,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kiki_bistro/","foodTypes":"기타","latitude":37.5541115705917,"longitude":127.143186942244,"bookYear":null,"nameKR":"키키","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":35209,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thewhite_inspiredbycesco/","foodTypes":"프랑스식","latitude":37.5501283470147,"longitude":127.176772864631,"bookYear":null,"nameKR":"더화이트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":35931,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/elephant_coffee","foodTypes":"디저트/차/베이커리","latitude":37.5342377344982,"longitude":127.143828349982,"bookYear":null,"nameKR":"엘리펀트커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36401,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.5322886973659,"longitude":127.140454105054,"bookYear":null,"nameKR":"운삐아또","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36589,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5361054063823,"longitude":127.127108116397,"bookYear":null,"nameKR":"내맘대로순대국","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":36648,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wabadak/","foodTypes":"한식(가금류)","latitude":37.5352477607977,"longitude":127.132663780085,"bookYear":null,"nameKR":"와바닭강정","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"26. 강동구","status":"ACTIVE"},
        {"id":30557,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mobeom_gbs","foodTypes":"한식(육류)","latitude":37.5009272542176,"longitude":127.024557940948,"bookYear":null,"nameKR":"모범갈빗살","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":30832,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4911903599155,"longitude":127.035490138885,"bookYear":null,"nameKR":"비놀릭","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":31546,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5032072803982,"longitude":127.023519915144,"bookYear":null,"nameKR":"덕자네방앗간","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":32494,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4956119342423,"longitude":127.027090955824,"bookYear":null,"nameKR":"스시도온","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":36301,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nuvola_gelato","foodTypes":"디저트/차/베이커리","latitude":37.4943931800281,"longitude":127.027705111719,"bookYear":null,"nameKR":"누볼라","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":36616,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4981646510326,"longitude":127.028307900881,"bookYear":null,"nameKR":"브릭샌드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":3684,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5032072803982,"longitude":127.023519915144,"bookYear":null,"nameKR":"아소산","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":3787,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4947989581217,"longitude":127.02860980273,"bookYear":null,"nameKR":"봉피양","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":4922,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.494745,"longitude":127.028586,"bookYear":null,"nameKR":"바바인디아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":5201,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.498488,"longitude":127.025606,"bookYear":null,"nameKR":"딘타이펑(강남역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":8170,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.495572,"longitude":127.027075,"bookYear":null,"nameKR":"강남복칼국수","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":15999,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5032072803982,"longitude":127.023519915144,"bookYear":null,"nameKR":"남서울아바이순대","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":17843,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.49579,"longitude":127.0273,"bookYear":null,"nameKR":"소호정(강남점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":17861,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"호주식","latitude":37.49778,"longitude":127.026193,"bookYear":null,"nameKR":"더플라잉팬레드","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":26775,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.498304,"longitude":127.023783,"bookYear":null,"nameKR":"장꼬방","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":652,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.49612,"longitude":127.0336,"bookYear":null,"nameKR":"오무라안","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":9122,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.497774,"longitude":127.028536,"bookYear":null,"nameKR":"구레스시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":12951,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4918,"longitude":127.033,"bookYear":null,"nameKR":"십원집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":17795,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.494307,"longitude":127.033469,"bookYear":null,"nameKR":"호남마을","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":19960,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"농민백암순대","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":28990,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gooseislandkr/","foodTypes":"바/주점","latitude":37.493454,"longitude":127.032108,"bookYear":null,"nameKR":"구스아일랜드브루하우스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":30535,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.499289043628174,"longitude":127.02857069075819,"bookYear":null,"nameKR":"바와인공간","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":30661,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.49256958969903,"longitude":127.03438036146986,"bookYear":null,"nameKR":"땅코참숯구이(역삼점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":32233,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/boseulboseul/","foodTypes":"한식(일반한식)","latitude":37.4976095062071,"longitude":127.033280422536,"bookYear":null,"nameKR":"보슬보슬","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":32551,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5004786918472,"longitude":127.027603090723,"bookYear":null,"nameKR":"아트몬스터(강남역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":33085,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cultural_citizen_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.4910922161915,"longitude":127.034352093727,"bookYear":null,"nameKR":"문화시민서울","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":34217,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/iele_forest/","foodTypes":"이탈리아식","latitude":37.4924044673041,"longitude":127.032021362216,"bookYear":null,"nameKR":"이엘포레스트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":34680,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4946959681486,"longitude":127.030080389903,"bookYear":null,"nameKR":"청기와타운(강남점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":34788,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.499650436263,"longitude":127.028574560391,"bookYear":null,"nameKR":"칙바이칙(강남역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":35135,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wasshoiken/","foodTypes":"일식","latitude":37.4955481444177,"longitude":127.03006467392,"bookYear":null,"nameKR":"왓쇼이켄","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":35886,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gop_official_/","foodTypes":"한식(육류)","latitude":37.4943642838521,"longitude":127.031552686124,"bookYear":null,"nameKR":"곱(강남점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":36644,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4941525483838,"longitude":127.031883923791,"bookYear":null,"nameKR":"남도애찬","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":30218,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.44468230484522,"longitude":126.90107429025237,"bookYear":null,"nameKR":"만두라","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":30340,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bluebottlecoffee_korea","foodTypes":"디저트/차/베이커리","latitude":37.49993318510482,"longitude":127.03236622035415,"bookYear":null,"nameKR":"블루보틀(역삼점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":30740,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5052805568865,"longitude":127.028919391781,"bookYear":null,"nameKR":"앙뜨레","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":34474,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5016033846107,"longitude":127.027357416481,"bookYear":null,"nameKR":"소보키(강남점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":35317,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kyumidang/","foodTypes":"한식(면류)","latitude":37.4535870951027,"longitude":126.903010796425,"bookYear":null,"nameKR":"규미당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":35611,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5106437319633,"longitude":127.023030125548,"bookYear":null,"nameKR":"탕탕집(논현본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":36274,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.5046036440998,"longitude":127.026160366244,"bookYear":null,"nameKR":"미타우동비스트로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":36333,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4532228279149,"longitude":126.902151304348,"bookYear":null,"nameKR":"인생파곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":2797,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.455131,"longitude":126.89856,"bookYear":null,"nameKR":"동흥관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":5306,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dublin_terrace_/","foodTypes":"바/주점","latitude":37.500631,"longitude":127.029103,"bookYear":null,"nameKR":"더블린테라스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":6501,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5042070691769,"longitude":127.029765274799,"bookYear":null,"nameKR":"동방미인","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":7109,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.4999326440603,"longitude":127.032367803374,"bookYear":null,"nameKR":"마노디셰프(강남점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":9374,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.505376,"longitude":127.028856,"bookYear":null,"nameKR":"더스퀘어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":29701,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.50297967855757,"longitude":127.02809257799764,"bookYear":null,"nameKR":"알베르","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"10. 강남역","status":"ACTIVE"},
        {"id":476,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.479835,"longitude":126.8825,"bookYear":null,"nameKR":"삼팔교자관","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":4967,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5073694628793,"longitude":126.885387464785,"bookYear":null,"nameKR":"한수한식","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":6156,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.496396,"longitude":126.842179,"bookYear":null,"nameKR":"평양면옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":15175,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.482202,"longitude":126.882985,"bookYear":null,"nameKR":"어탐","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":16092,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.4900302831953,"longitude":126.839931665816,"bookYear":null,"nameKR":"일경추어탕","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":25121,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.4935914775711,"longitude":126.889851657698,"bookYear":null,"nameKR":"계림원(구로구청점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":29255,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5047866933726,"longitude":126.890280134842,"bookYear":null,"nameKR":"라도맨션","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":32237,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.506883854568,"longitude":126.884036662859,"bookYear":null,"nameKR":"봉고기","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":33177,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4826735316069,"longitude":126.878293835001,"bookYear":null,"nameKR":"카레업자","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":34042,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pizza.square._.n","foodTypes":"이탈리아식","latitude":37.4902365090737,"longitude":126.89026139227,"bookYear":null,"nameKR":"대왕사각피자","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":34081,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4784047058322,"longitude":126.883358589722,"bookYear":null,"nameKR":"불독맨션","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":34105,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4821528072024,"longitude":126.883994620232,"bookYear":null,"nameKR":"스타에델치킨(본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":34124,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dads_baguette/","foodTypes":"디저트/차/베이커리","latitude":37.48305321277,"longitude":126.838914729764,"bookYear":null,"nameKR":"아빠의바게트","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":34434,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4723439731414,"longitude":126.899326752618,"bookYear":null,"nameKR":"순댕이네얼큰수제비","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":34842,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4804093572171,"longitude":126.879053991982,"bookYear":null,"nameKR":"으뜸명가","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":35373,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nice.caffein.club/","foodTypes":"디저트/차/베이커리","latitude":37.4842856391064,"longitude":126.899282125859,"bookYear":null,"nameKR":"나이스카페인클럽","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":35830,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/embassy_gudi/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4865551001079,"longitude":126.896162097676,"bookYear":null,"nameKR":"부대찌개대사관(구로디지털본점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":35895,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wolhwa_sikdang_sdr/","foodTypes":"한식(육류)","latitude":37.5090492151745,"longitude":126.8869873429,"bookYear":null,"nameKR":"월화식당(신도림점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":36156,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kuyidae/","foodTypes":"일식","latitude":37.5020321024364,"longitude":126.876117278882,"bookYear":null,"nameKR":"쿠이데","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":36217,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4807624025755,"longitude":126.880678781517,"bookYear":null,"nameKR":"오늘돈카츠","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":31888,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5129101703357,"longitude":127.017470491879,"bookYear":null,"nameKR":"머랭의집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":32886,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4862033805248,"longitude":126.856807761647,"bookYear":null,"nameKR":"고기에미친남자","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":33746,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4864184195155,"longitude":126.854276526642,"bookYear":null,"nameKR":"드립하우스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":33747,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/driphouse_seoul","foodTypes":"디저트/차/베이커리","latitude":37.4904765094163,"longitude":126.85637252003,"bookYear":null,"nameKR":"드립하우스직영점","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":34195,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4934362521241,"longitude":126.857360822461,"bookYear":null,"nameKR":"목돈72(개봉직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":34213,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4945222528654,"longitude":126.859102583368,"bookYear":null,"nameKR":"육연사(개봉역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":35699,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soo_mil_boulangerie","foodTypes":"디저트/차/베이커리","latitude":37.5063537038109,"longitude":126.860612503872,"bookYear":null,"nameKR":"수밀블랑제리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":35880,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4840587045063,"longitude":126.899846627555,"bookYear":null,"nameKR":"신림춘천집(구로디지털직영점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":35894,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wolhwa_sikdang_guro/","foodTypes":"한식(육류)","latitude":37.4835332463617,"longitude":126.899752015371,"bookYear":null,"nameKR":"월화식당(구로디지털단지역점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":7802,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.518525,"longitude":127.018067,"bookYear":null,"nameKR":"더가든키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":8178,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.5151898362835,"longitude":127.018989785315,"bookYear":null,"nameKR":"원조마산아구찜","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":15333,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5012425848848,"longitude":126.865836085169,"bookYear":null,"nameKR":"가향","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"18. 가산디지털단지/구로","status":"ACTIVE"},
        {"id":28989,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.517732,"longitude":127.017448,"bookYear":null,"nameKR":"신사동연탄공장","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":4672,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.523581,"longitude":127.023367,"bookYear":null,"nameKR":"할머니현대낙지집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":7646,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.52336,"longitude":127.023329,"bookYear":null,"nameKR":"르알래스카(가로수길점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":9170,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.52053,"longitude":127.024289,"bookYear":null,"nameKR":"레이브릭스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":19934,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.519228,"longitude":127.025002,"bookYear":null,"nameKR":"일도씨곱창","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":27622,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/milestone_coffee","foodTypes":"디저트/차/베이커리","latitude":37.521847450448,"longitude":127.024334591638,"bookYear":null,"nameKR":"마일스톤커피로스터스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":28327,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5165371942425,"longitude":127.017853325292,"bookYear":null,"nameKR":"돼지평야","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":29105,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.521657,"longitude":127.024037,"bookYear":null,"nameKR":"라빠뜨","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30051,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/skillet_garosugil/","foodTypes":"기타","latitude":37.522240505567716,"longitude":127.02370609378924,"bookYear":null,"nameKR":"스킬렛스테이크하우스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30253,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.51938227391972,"longitude":127.02492234849987,"bookYear":null,"nameKR":"모던오뎅","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30475,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soot_official/","foodTypes":"기타","latitude":37.51963793759084,"longitude":127.02594955631984,"bookYear":null,"nameKR":"숯","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":31315,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/koie_official/","foodTypes":"일식","latitude":37.5203730601942,"longitude":127.024716233381,"bookYear":null,"nameKR":"코이에","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":31358,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.5207891545072,"longitude":127.024227574844,"bookYear":null,"nameKR":"ebt","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":31555,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.5188658448158,"longitude":127.025224654498,"bookYear":null,"nameKR":"텔러스9.5","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":34229,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.516257656298,"longitude":127.017563347134,"bookYear":null,"nameKR":"멘야하나비(신사점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":34432,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/merry_go_round_steak/","foodTypes":"기타","latitude":37.5238371322452,"longitude":127.023455120787,"bookYear":null,"nameKR":"메리고라운드스테이크","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":36537,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lowidecoffee/","foodTypes":"기타","latitude":37.5250720249382,"longitude":127.024879442668,"bookYear":null,"nameKR":"로와이드압구정","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30723,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5194829189133,"longitude":127.024038696221,"bookYear":null,"nameKR":"네기다이닝라운지","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":32452,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lb_sinsa/","foodTypes":"기타","latitude":37.5181737013501,"longitude":127.02298153685,"bookYear":null,"nameKR":"레부르주아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":33960,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chelseas_highball","foodTypes":"바/주점","latitude":37.5212605849517,"longitude":127.023201939704,"bookYear":null,"nameKR":"첼시스하이볼(가로수길점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":36221,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/uhuru_hwa","foodTypes":"기타","latitude":37.5201862876777,"longitude":127.024255546816,"bookYear":null,"nameKR":"우후루화","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":3189,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.519444,"longitude":127.023668,"bookYear":null,"nameKR":"헬로우","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":4750,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.524081,"longitude":127.023445,"bookYear":null,"nameKR":"한추","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":4919,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.521777,"longitude":127.023,"bookYear":null,"nameKR":"크레이지호스","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":4932,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.522221,"longitude":127.023281,"bookYear":null,"nameKR":"지구당","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":5034,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sarubia_garosugil/","foodTypes":"이탈리아식","latitude":37.520006,"longitude":127.023602,"bookYear":null,"nameKR":"사루비아","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":5695,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.51916,"longitude":127.023736,"bookYear":null,"nameKR":"마루","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":5696,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.518888,"longitude":127.024212,"bookYear":null,"nameKR":"켄카","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":16808,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/monlit_localbubble/","foodTypes":"기타","latitude":37.519027,"longitude":127.023549,"bookYear":null,"nameKR":"몽리비스트로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":27623,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jooos_korea/","foodTypes":"중식","latitude":37.5195019034754,"longitude":127.024605428725,"bookYear":null,"nameKR":"쮸즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":28463,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mikkellerseoul/","foodTypes":"바/주점","latitude":37.520797,"longitude":127.023932,"bookYear":null,"nameKR":"미켈러바","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":29498,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bpatisserie_seoul/","foodTypes":"디저트/차/베이커리","latitude":37.52200273259967,"longitude":127.02324447745279,"bookYear":null,"nameKR":"비파티세리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":4842,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"유럽식","latitude":37.52186,"longitude":127.0218,"bookYear":null,"nameKR":"스페인클럽","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":5732,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5211,"longitude":127.0223,"bookYear":null,"nameKR":"이코복스커피","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":5849,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lebrunchic/","foodTypes":"기타","latitude":37.52204,"longitude":127.0211,"bookYear":null,"nameKR":"르브런쉭","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":14064,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.522988,"longitude":127.021339,"bookYear":null,"nameKR":"더램키친","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":23259,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.522157,"longitude":127.020866,"bookYear":null,"nameKR":"룰라톤","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":28588,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.519349,"longitude":127.020669,"bookYear":null,"nameKR":"나따오비까","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30152,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/samchang.gyoja/","foodTypes":"중식","latitude":37.51891437898328,"longitude":127.02175035973548,"bookYear":null,"nameKR":"삼창교자","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":33423,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mongle_curry/","foodTypes":"기타","latitude":37.5204529495391,"longitude":127.02261572553,"bookYear":null,"nameKR":"몽글몽글카레집","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":33848,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lu307_official/","foodTypes":"바/주점","latitude":37.5219248457278,"longitude":127.021556323194,"bookYear":null,"nameKR":"Lu307","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":36086,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/AwesomeRose_garosu","foodTypes":"기타","latitude":37.5209250994354,"longitude":127.022009877804,"bookYear":null,"nameKR":"어썸로즈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":30170,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.51876680469064,"longitude":127.02069153302429,"bookYear":null,"nameKR":"해밥상달주막","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":32222,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5192707642924,"longitude":127.020528102547,"bookYear":null,"nameKR":"딸부자네불백","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":34130,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jingguancheng_hotpot/","foodTypes":"중식","latitude":37.5189626537243,"longitude":127.021853424456,"bookYear":null,"nameKR":"징관청훠궈","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":34438,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeon_hwaro/","foodTypes":"한식(육류)","latitude":37.5196332824784,"longitude":127.020269724388,"bookYear":null,"nameKR":"연화로","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":35005,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5196112388253,"longitude":127.020088727689,"bookYear":null,"nameKR":"소각","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":35599,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coorownie/","foodTypes":"디저트/차/베이커리","latitude":37.5190343294693,"longitude":127.022090541398,"bookYear":null,"nameKR":"쿠라우니","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":35845,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gentleman_eel/","foodTypes":"한식(민물어패류)","latitude":37.5173900715817,"longitude":127.020170593709,"bookYear":null,"nameKR":"신사만장어","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":6539,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.518048,"longitude":127.021565,"bookYear":null,"nameKR":"워너커피(가로수길점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":13250,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5180513714224,"longitude":127.020384110098,"bookYear":null,"nameKR":"동인동","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":14063,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.517226,"longitude":127.020225,"bookYear":null,"nameKR":"마산옥","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":23928,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.517762,"longitude":127.020298,"bookYear":null,"nameKR":"두사라","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":25788,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.518711,"longitude":127.019918,"bookYear":null,"nameKR":"퀸실내포차","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":27609,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5186698145159,"longitude":127.022404562041,"bookYear":null,"nameKR":"송옥(가로수길점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":1136,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.51574,"longitude":127.0207,"bookYear":null,"nameKR":"원조부산아구","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":17822,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.51574,"longitude":127.020692,"bookYear":null,"nameKR":"향방","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":29486,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/umm_No1/","foodTypes":"일식","latitude":37.5171730963631,"longitude":127.02452797174082,"bookYear":null,"nameKR":"음세이로무시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":31605,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5154202147944,"longitude":127.022617034548,"bookYear":null,"nameKR":"김록훈베이커리","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":34652,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/c_town_sinsa/","foodTypes":"한식(육류)","latitude":37.5151950134955,"longitude":127.020363312256,"bookYear":null,"nameKR":"청기와타운(신사점)","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":36552,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/reiho_sushi/","foodTypes":"일식","latitude":37.5195101291255,"longitude":127.019106035326,"bookYear":null,"nameKR":"레이호스시","ribbonType":"정보없음","zone2_1":"서울 강남","zone2_2":"01. 가로수길/신사역","status":"ACTIVE"},
        {"id":36652,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3105369330912,"longitude":126.904539382374,"bookYear":null,"nameKR":"바리스타강","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36704,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.0529546023675,"longitude":128.96153609906,"bookYear":null,"nameKR":"리에또","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":36700,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1351792865983,"longitude":129.084108139026,"bookYear":null,"nameKR":"참못골돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":36698,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.8287909695175,"longitude":127.074307989816,"bookYear":null,"nameKR":"늘푸른흑염소가든","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":36693,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tosungvillage/","foodTypes":"디저트/차/베이커리","latitude":36.6812839179569,"longitude":127.460133485167,"bookYear":null,"nameKR":"토성마을","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":34032,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.6424666168557,"longitude":126.660859793016,"bookYear":null,"nameKR":"엄청난해장국","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":35392,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/queenstown_official?igshid=NzZlODBkYWE4Ng==","foodTypes":"기타","latitude":37.2061584057883,"longitude":127.094372707219,"bookYear":null,"nameKR":"퀸스타운","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":4559,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.160999,"longitude":129.184144,"bookYear":null,"nameKR":"속시원한대구탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":36195,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.3741983075828,"longitude":127.322386502141,"bookYear":null,"nameKR":"크라운샤이니스","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":36691,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.0090382697236,"longitude":127.271877148051,"bookYear":null,"nameKR":"빠박이생고기전문점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":36687,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/como_osteria/","foodTypes":"이탈리아식","latitude":37.5926073432533,"longitude":126.712725586683,"bookYear":null,"nameKR":"오스테리아꼬모","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":36684,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.1559884974908,"longitude":127.110051134739,"bookYear":null,"nameKR":"맨션1102","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":36683,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ksdessert.cafe/","foodTypes":"디저트/차/베이커리","latitude":37.7159784842967,"longitude":126.763735001248,"bookYear":null,"nameKR":"경성양과점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":36676,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woo_sireuk2017/","foodTypes":"한식(육류)","latitude":37.3339202392581,"longitude":127.05198332409,"bookYear":null,"nameKR":"우시륵","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":33948,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.2342104940333,"longitude":129.009012144217,"bookYear":null,"nameKR":"툇마루","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":36518,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.3621749058887,"longitude":127.106553698118,"bookYear":null,"nameKR":"드윝","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":36665,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1499463327673,"longitude":129.115305735423,"bookYear":null,"nameKR":"조개대표","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36315,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.1618255745011,"longitude":126.916070353012,"bookYear":null,"nameKR":"계림동잔치집","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":36586,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/u.j.g.meal","foodTypes":"바/주점","latitude":35.151994997292,"longitude":129.062695188102,"bookYear":null,"nameKR":"음주가밀(전포점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":36646,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/wit.haus/","foodTypes":"기타","latitude":35.2420726385959,"longitude":128.6871149249,"bookYear":null,"nameKR":"윗하우스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":36624,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bshw_dj/","foodTypes":"한식(육류)","latitude":36.3507142999812,"longitude":127.331485735544,"bookYear":null,"nameKR":"백송한우(대전유성점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":36619,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistro_hotelambiance/","foodTypes":"기타","latitude":35.2085239735956,"longitude":129.207278983975,"bookYear":null,"nameKR":"비스트로호텔엠비언스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":36374,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.1577055926962,"longitude":128.046378748942,"bookYear":null,"nameKR":"더그릴720","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":36612,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeolmunzip","foodTypes":"한식(육류)","latitude":37.3873395397425,"longitude":127.12246451531,"bookYear":null,"nameKR":"열문집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35167,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.310552244991,"longitude":126.827433877675,"bookYear":null,"nameKR":"포터블","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36354,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":36.3532416894251,"longitude":127.378234436618,"bookYear":null,"nameKR":"몽상","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":36599,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/somun_official","foodTypes":"바/주점","latitude":36.8083621166789,"longitude":127.105071383198,"bookYear":null,"nameKR":"소문","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":36598,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/k_hutieu001","foodTypes":"아시아식","latitude":35.1581458236253,"longitude":129.124859431599,"bookYear":null,"nameKR":"요오면","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36582,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sciencecafekorea/","foodTypes":"디저트/차/베이커리","latitude":36.3916295330684,"longitude":127.349541071161,"bookYear":null,"nameKR":"쿠아","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":36591,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sybj_official/","foodTypes":"기타","latitude":36.9802635996925,"longitude":126.929094033058,"bookYear":null,"nameKR":"서양밥집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":36587,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/syeo_syeo__official","foodTypes":"중식","latitude":35.543463111897,"longitude":129.338078334755,"bookYear":null,"nameKR":"셔셔","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":36579,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3645066353735,"longitude":127.107093767636,"bookYear":null,"nameKR":"미담","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32147,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3176823563285,"longitude":126.838581601634,"bookYear":null,"nameKR":"몰래먹던뒷고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":35491,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.3788949275109,"longitude":127.137658399999,"bookYear":null,"nameKR":"리틀타이랜드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":36578,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.2380730960841,"longitude":128.684478256886,"bookYear":null,"nameKR":"프레클앤코","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":36502,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8803485657831,"longitude":128.599719245445,"bookYear":null,"nameKR":"스무고개(대구점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":36298,"bookStatus":"비활성","websiteInstagram":"","latitude":35.814733260575,"longitude":128.643017639019,"bookYear":null,"nameKR":"치맥킹","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":35126,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.8732498379874,"longitude":128.598398447436,"bookYear":null,"nameKR":"막삼가","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":32873,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.5453350180899,"longitude":129.328762993806,"bookYear":null,"nameKR":"미지","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":36572,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.2361855484076,"longitude":127.206318765285,"bookYear":null,"nameKR":"카츠랩","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":36571,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/500bakery/","foodTypes":"디저트/차/베이커리","latitude":35.6708212630995,"longitude":129.372621094857,"bookYear":null,"nameKR":"500베이커리엔카페","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":36563,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":36.6436916593296,"longitude":127.431060254591,"bookYear":null,"nameKR":"키와마루아지(청주점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36338,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.22694370239,"longitude":128.883418031963,"bookYear":null,"nameKR":"하츠키","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":36532,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi_sirokuma","foodTypes":"일식","latitude":35.3287973721611,"longitude":129.009968198122,"bookYear":null,"nameKR":"시로쿠마","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":36549,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.3682047072201,"longitude":126.728938399307,"bookYear":null,"nameKR":"채우정","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":36560,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bandalhodduk","foodTypes":"디저트/차/베이커리","latitude":35.1658839964079,"longitude":129.157788410038,"bookYear":null,"nameKR":"반달호떡","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":36556,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/starmoondon","foodTypes":"기타","latitude":36.3515514370338,"longitude":127.377153873535,"bookYear":null,"nameKR":"대전둔산동별달돈까스카페","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":36555,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/starmoondon","foodTypes":"기타","latitude":36.3628743560789,"longitude":127.349833601424,"bookYear":null,"nameKR":"별리달리궁동별달돈까스","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":36551,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1302303473776,"longitude":128.913209771601,"bookYear":null,"nameKR":"연향정돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":36529,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gusto__gusto/","foodTypes":"이탈리아식","latitude":37.6663497142599,"longitude":126.77318582578,"bookYear":null,"nameKR":"구스토구스토","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":36465,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yunsicoffeeroasters","foodTypes":"디저트/차/베이커리","latitude":37.6645873234193,"longitude":126.785771664668,"bookYear":null,"nameKR":"윤시커피로스터스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":36513,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bonemeathouse/","foodTypes":"한식(육류)","latitude":35.646607697984,"longitude":129.35679845926,"bookYear":null,"nameKR":"본고깃집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":36507,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/geumsansanjang/","foodTypes":"한식(일반한식)","latitude":34.7515830721386,"longitude":127.980677714889,"bookYear":null,"nameKR":"금산산장","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":36508,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pokkkkkk_mj","foodTypes":"아시아식","latitude":35.0978294987286,"longitude":128.91888769212,"bookYear":null,"nameKR":"폭폭","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":34736,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.1464063497858,"longitude":128.659944813091,"bookYear":null,"nameKR":"미드나잇버거","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":36493,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.9995369673699,"longitude":127.133493335476,"bookYear":null,"nameKR":"코다리백가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":36494,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.9240354431773,"longitude":127.038646401843,"bookYear":null,"nameKR":"백가네진코다리 ","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":36491,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.7703680050526,"longitude":127.120814727298,"bookYear":null,"nameKR":"오리사냥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":36489,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gogwigwan_","foodTypes":"한식(육류)","latitude":35.220625751703,"longitude":128.990677854816,"bookYear":null,"nameKR":"고귀관","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":36119,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.5280986130198,"longitude":126.724990457443,"bookYear":null,"nameKR":"을지돈까스 ","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":36454,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwangga_chueotang/","foodTypes":"한식(민물어패류)","latitude":35.2722871607045,"longitude":129.0853202269,"bookYear":null,"nameKR":"황가추어탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":36473,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1559749093952,"longitude":129.065649590245,"bookYear":null,"nameKR":"백양육식장","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":36109,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bosukhanwu/","foodTypes":"한식(육류)","latitude":37.2327793868862,"longitude":127.187420199813,"bookYear":null,"nameKR":"보석한우","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":36425,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8135514214692,"longitude":128.754355647888,"bookYear":null,"nameKR":"녹림","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":29013,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.043693,"longitude":128.065746,"bookYear":null,"nameKR":"송화관","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":25863,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.986284,"longitude":126.962921,"bookYear":null,"nameKR":"송원숯불갈비","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":21794,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.566808,"longitude":128.161553,"bookYear":null,"nameKR":"복흥반점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"합천군","status":"ACTIVE"},
        {"id":36413,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.811150264788,"longitude":127.106667458406,"bookYear":null,"nameKR":"인정집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":29587,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.86663023255647,"longitude":128.590625620519,"bookYear":null,"nameKR":"js가든(대구점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":29588,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.392793537537784,"longitude":127.11208816175997,"bookYear":null,"nameKR":"js가든(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":36414,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sul_ya_myeok/","foodTypes":"한식(육류)","latitude":35.1579627195169,"longitude":129.062782349144,"bookYear":null,"nameKR":"설야멱(서면직영점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":36410,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/analog_bp","foodTypes":"디저트/차/베이커리","latitude":37.4949764936524,"longitude":126.72319985841,"bookYear":null,"nameKR":"아날로그","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":36406,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4900852504295,"longitude":126.743953822285,"bookYear":null,"nameKR":"카페아루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":36404,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tongken_family","foodTypes":"한식(육류)","latitude":35.3153641971124,"longitude":128.990406923586,"bookYear":null,"nameKR":"통큰삼겹(증산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":36403,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.3142032121229,"longitude":129.024651659459,"bookYear":null,"nameKR":"통큰삼겹","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":35617,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.3133197193014,"longitude":126.818251310399,"bookYear":null,"nameKR":"별의감성","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":23639,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":34.786625,"longitude":126.384373,"bookYear":null,"nameKR":"안골정꼬리곰탕","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":36375,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.1577055926962,"longitude":128.046378748942,"bookYear":null,"nameKR":"마묵라운지&바","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":36395,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.8006354743562,"longitude":126.431698817513,"bookYear":null,"nameKR":"초가네장어구이","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":33277,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/songbin_kamameshi/","foodTypes":"일식","latitude":35.539830774131,"longitude":129.332795323911,"bookYear":null,"nameKR":"가마메시야송빈","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":33910,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/a.nook_/","foodTypes":"디저트/차/베이커리","latitude":35.8322449722906,"longitude":128.575175477855,"bookYear":null,"nameKR":"아눅앞산","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":33911,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/a.nook_/","foodTypes":"디저트/차/베이커리","latitude":35.8562892835419,"longitude":128.62672994825,"bookYear":null,"nameKR":"아눅베이커스","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":33912,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/a.nook_/","foodTypes":"디저트/차/베이커리","latitude":35.8417923315151,"longitude":128.715493253248,"bookYear":null,"nameKR":"아눅사월","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":33915,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.488037633357,"longitude":126.779762473226,"bookYear":null,"nameKR":"피코차이나","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":33917,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/respiro_busan","foodTypes":"이탈리아식","latitude":35.0529546023675,"longitude":128.96153609906,"bookYear":null,"nameKR":"레스피로","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":33919,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/i_scoop_seosan/","foodTypes":"디저트/차/베이커리","latitude":36.7865245841848,"longitude":126.453833662154,"bookYear":null,"nameKR":"아이스쿱","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":33925,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/odon_realmeat/","foodTypes":"한식(육류)","latitude":36.6156225633644,"longitude":127.518130436673,"bookYear":null,"nameKR":"오돈생고기","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33930,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nokeum_official/","foodTypes":"이탈리아식","latitude":35.5796921592416,"longitude":129.32040712194,"bookYear":null,"nameKR":"녹음","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":33941,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sinkiforest/","foodTypes":"디저트/차/베이커리","latitude":35.0868516521463,"longitude":129.060273374294,"bookYear":null,"nameKR":"신기숲","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":33946,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/balancecoffeeroaster/","foodTypes":"디저트/차/베이커리","latitude":36.8173065194698,"longitude":127.108701907543,"bookYear":null,"nameKR":"밸런스커피로스터","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":33967,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/samdolzip_gwangju/","foodTypes":"한식(육류)","latitude":37.4270493061114,"longitude":127.256594072614,"bookYear":null,"nameKR":"삼돌집(2호점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":33969,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mizinenae_/","foodTypes":"한식(육류)","latitude":35.823407342182,"longitude":128.525819831763,"bookYear":null,"nameKR":"미진삼겹살(월성점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":33975,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/littledelight_/","foodTypes":"기타","latitude":36.3622993153377,"longitude":127.354268911404,"bookYear":null,"nameKR":"리틀딜라잇","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":33985,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dotikatsu/","foodTypes":"일식","latitude":37.5048312328334,"longitude":126.72405332229,"bookYear":null,"nameKR":"도티카츠","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":36397,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.6491520430391,"longitude":127.457845736606,"bookYear":null,"nameKR":"육즙","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36400,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jyp_macaron","foodTypes":"디저트/차/베이커리","latitude":37.66147090149,"longitude":126.890083374183,"bookYear":null,"nameKR":"제이와이피마카롱","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":36399,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3860281419418,"longitude":126.838671875494,"bookYear":null,"nameKR":"두부진","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":23073,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.011925,"longitude":129.349748,"bookYear":null,"nameKR":"어림지(상도점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":33998,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.3622435746656,"longitude":127.295126914299,"bookYear":null,"nameKR":"자작나무","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":33905,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.1607540749744,"longitude":128.222199894865,"bookYear":null,"nameKR":"복현","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":33902,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bluff_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.6648027708546,"longitude":126.787377965593,"bookYear":null,"nameKR":"블러프커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":33896,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/doyaji2019/","foodTypes":"한식(육류)","latitude":34.8328959320185,"longitude":128.41249772858,"bookYear":null,"nameKR":"도야지마을","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":33895,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pearl_district_/","foodTypes":"기타","latitude":35.1886150838797,"longitude":128.114887333522,"bookYear":null,"nameKR":"펄디스트릭","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":33891,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4534669713323,"longitude":126.720014314165,"bookYear":null,"nameKR":"푸른바다참치","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":33890,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dodomandu/","foodTypes":"한식(면류)","latitude":37.2313789805281,"longitude":127.108329219126,"bookYear":null,"nameKR":"도도만두","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":33889,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sambon0874/","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.6726643674894,"longitude":127.486423132716,"bookYear":null,"nameKR":"명장삼본식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33883,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwamokjeong_namku/","foodTypes":"한식(일반한식)","latitude":36.0173362705457,"longitude":129.340991276658,"bookYear":null,"nameKR":"화목정(남구점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":33882,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.0897221335138,"longitude":129.388363686022,"bookYear":null,"nameKR":"화목정","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":33878,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/claire_bake_shop/","foodTypes":"디저트/차/베이커리","latitude":35.539543710466,"longitude":129.333120489565,"bookYear":null,"nameKR":"끌레르","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":33877,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_shutter/","foodTypes":"디저트/차/베이커리","latitude":37.166390217906,"longitude":127.095277217267,"bookYear":null,"nameKR":"셔터","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":33871,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soso_uijeongbu/","foodTypes":"한식(일반한식)","latitude":37.7475837340212,"longitude":127.045243725789,"bookYear":null,"nameKR":"소소떡볶이(의정부점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":33866,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.489840648089,"longitude":126.75714960415,"bookYear":null,"nameKR":"남해","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":33867,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4897781886626,"longitude":126.757930242721,"bookYear":null,"nameKR":"남해옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":34019,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/stedcoffee","foodTypes":"디저트/차/베이커리","latitude":35.8684240452905,"longitude":128.592835865748,"bookYear":null,"nameKR":"스테드","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":34029,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bleuchaud_pasta/","foodTypes":"이탈리아식","latitude":37.3073826722409,"longitude":127.085450438456,"bookYear":null,"nameKR":"블루쇼파스타","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":34031,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.1536227020569,"longitude":128.191852525185,"bookYear":null,"nameKR":"뜰이있는집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":34033,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/greek_dang","foodTypes":"디저트/차/베이커리","latitude":37.7393464744631,"longitude":127.037175172426,"bookYear":null,"nameKR":"그릭당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":33858,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.2266595270154,"longitude":128.876086987752,"bookYear":null,"nameKR":"도도플레이트(김해점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":34034,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffee_kojongyard/","foodTypes":"디저트/차/베이커리","latitude":37.6772701473096,"longitude":126.812443700793,"bookYear":null,"nameKR":"고종의뜰","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":33853,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/encorboa_17.06/","foodTypes":"디저트/차/베이커리","latitude":37.2500549923934,"longitude":127.076803878408,"bookYear":null,"nameKR":"앙코르보아","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34044,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tomsdiner_67st/","foodTypes":"기타","latitude":35.1238832599939,"longitude":129.110013053207,"bookYear":null,"nameKR":"톰스다이너","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":33851,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/standardcoffee.lab/","foodTypes":"디저트/차/베이커리","latitude":37.6466197987871,"longitude":127.235989263191,"bookYear":null,"nameKR":"스탠다드커피랩","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":36391,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/badang_doldam","foodTypes":"한식(육류)","latitude":35.0915146073541,"longitude":128.578749179519,"bookYear":null,"nameKR":"바당돌담","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":33847,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ginger_s_house/","foodTypes":"디저트/차/베이커리","latitude":35.1072251818111,"longitude":128.922038340045,"bookYear":null,"nameKR":"진저하우스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":33842,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.153709087558,"longitude":129.059762403649,"bookYear":null,"nameKR":"차선책(서면점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34054,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gugujok_gwangmyeong","foodTypes":"한식(육류)","latitude":37.4798230179535,"longitude":126.852298477517,"bookYear":null,"nameKR":"구구족주막(광명점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":34056,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/cafe_otherside","foodTypes":"디저트/차/베이커리","latitude":37.3996441086743,"longitude":126.970274096871,"bookYear":null,"nameKR":"아더사이드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":34057,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ekinomae_official/","foodTypes":"디저트/차/베이커리","latitude":37.4904472744166,"longitude":126.723486369222,"bookYear":null,"nameKR":"에키노마에","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":34067,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/warak_bistro/","foodTypes":"기타","latitude":37.7342554750934,"longitude":127.041691820502,"bookYear":null,"nameKR":"비스트로와락","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":34071,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/monkey_brown2022","foodTypes":"기타","latitude":35.1612997818826,"longitude":129.064375816041,"bookYear":null,"nameKR":"몽키브라운","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34073,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dominiccoffee_official_/","foodTypes":"디저트/차/베이커리","latitude":37.2952097364216,"longitude":127.054962775099,"bookYear":null,"nameKR":"도미닉커피로스터즈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34074,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daejam_mansion/","foodTypes":"기타","latitude":36.0148539753793,"longitude":129.342870691911,"bookYear":null,"nameKR":"대잠맨션","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":34407,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leesodang0726/","foodTypes":"디저트/차/베이커리","latitude":36.3718466464701,"longitude":127.337990307231,"bookYear":null,"nameKR":"이소당","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":34079,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakers__moment/","foodTypes":"디저트/차/베이커리","latitude":34.8112006950744,"longitude":126.464889552154,"bookYear":null,"nameKR":"베이커스모먼트","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":34082,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3698356601897,"longitude":126.728353838883,"bookYear":null,"nameKR":"탐라담","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":31909,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.1455592395588,"longitude":126.885919353178,"bookYear":null,"nameKR":"테스팅노트","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":34089,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/only.yuk/","foodTypes":"한식(육류)","latitude":35.5373679010438,"longitude":129.335610805246,"bookYear":null,"nameKR":"온리육","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":34095,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/badaroum_cafe/","foodTypes":"디저트/차/베이커리","latitude":35.0940072130407,"longitude":128.450817864742,"bookYear":null,"nameKR":"바다로움","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":34111,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bbok__gojan/","foodTypes":"이탈리아식","latitude":37.310108731276,"longitude":126.829030848646,"bookYear":null,"nameKR":"뽁식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":34112,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bbok__baegot/","foodTypes":"이탈리아식","latitude":37.368371329452,"longitude":126.727341176579,"bookYear":null,"nameKR":"뽁식당(배곧점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":36385,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bs_galbi","foodTypes":"한식(육류)","latitude":35.1212993476265,"longitude":126.916990791225,"bookYear":null,"nameKR":"제주생갈비","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":34113,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bbok__homaesil/","foodTypes":"이탈리아식","latitude":37.2732778742353,"longitude":126.954752231108,"bookYear":null,"nameKR":"뽁식당(호매실점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34114,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bbok_beomgye/","foodTypes":"중남미식","latitude":37.3903595340571,"longitude":126.953064536797,"bookYear":null,"nameKR":"뽁식당(범계점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":34116,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/casade_taco/","foodTypes":"중남미식","latitude":37.3104996531489,"longitude":126.829067753133,"bookYear":null,"nameKR":"카사데타코","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":34115,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/obokdang_adore/","foodTypes":"중식","latitude":37.3103788659256,"longitude":126.828411502352,"bookYear":null,"nameKR":"오복당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":34118,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bbok_guwol/","foodTypes":"이탈리아식","latitude":37.4489871545544,"longitude":126.706899307919,"bookYear":null,"nameKR":"뽁식당(구월점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":34120,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/go_chef_/","foodTypes":"일식","latitude":37.52927676727,"longitude":126.655375506208,"bookYear":null,"nameKR":"고쉐프의신선한초밥","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":34133,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1538657526683,"longitude":129.056885962846,"bookYear":null,"nameKR":"삿뽀로참치","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34144,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.ohmissu","foodTypes":"디저트/차/베이커리","latitude":36.3757315237382,"longitude":127.319853225421,"bookYear":null,"nameKR":"오미쑤","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":34148,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/god_s_table/","foodTypes":"이탈리아식","latitude":37.4865363407348,"longitude":126.780614898827,"bookYear":null,"nameKR":"신의식탁","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":34149,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/helloespresso_gojan/","foodTypes":"디저트/차/베이커리","latitude":37.3112892352202,"longitude":126.830827660184,"bookYear":null,"nameKR":"헬로에스프레소","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36371,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3613993780208,"longitude":127.113165749848,"bookYear":null,"nameKR":"함흥미식관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":34161,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1508946511378,"longitude":128.838986989043,"bookYear":null,"nameKR":"다이츠로스터스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":34163,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/bonoaron","foodTypes":"일식","latitude":35.1674520417221,"longitude":129.03669020563,"bookYear":null,"nameKR":"백쉐프본스시","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":34166,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4239165711972,"longitude":126.644168489552,"bookYear":null,"nameKR":"코코도쿄","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":36295,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mansot237/","foodTypes":"중식","latitude":35.8515728339652,"longitude":128.624410187776,"bookYear":null,"nameKR":"만솥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36294,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.8226846153648,"longitude":128.625822422525,"bookYear":null,"nameKR":"몽짬뽕본점","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36292,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.8620206844484,"longitude":128.622754954414,"bookYear":null,"nameKR":"팔선생","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36291,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8348826140228,"longitude":128.616336654198,"bookYear":null,"nameKR":"금등어","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36290,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/__wabi_/","foodTypes":"바/주점","latitude":35.8568558733184,"longitude":128.614376157718,"bookYear":null,"nameKR":"와비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36283,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8586947027906,"longitude":128.624552190173,"bookYear":null,"nameKR":"최강찜갈비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":34175,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/meatpride_/","foodTypes":"한식(육류)","latitude":35.8378280187356,"longitude":128.62166559651,"bookYear":null,"nameKR":"고기부심","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":34179,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/momory9432/","foodTypes":"디저트/차/베이커리","latitude":37.3301218015495,"longitude":127.070977858367,"bookYear":null,"nameKR":"마모리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":34181,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/graeyo2022/","foodTypes":"디저트/차/베이커리","latitude":36.3591587624837,"longitude":127.364768460644,"bookYear":null,"nameKR":"그래요","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":34184,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jsst0615","foodTypes":"이탈리아식","latitude":37.2988041515366,"longitude":126.972812300148,"bookYear":null,"nameKR":"정성식탁","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":32687,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/krum_coffee","foodTypes":"디저트/차/베이커리","latitude":37.7713279141284,"longitude":127.04814112976,"bookYear":null,"nameKR":"크룸커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":34191,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.2329283221103,"longitude":128.850709238746,"bookYear":null,"nameKR":"남포통닭","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":34585,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffee_probe","foodTypes":"디저트/차/베이커리","latitude":37.5684306753895,"longitude":126.747983467106,"bookYear":null,"nameKR":"커피프로브","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":34542,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.9028065067182,"longitude":127.2156465601,"bookYear":null,"nameKR":"호박꽃","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":34525,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/beautiful_macarons_/","foodTypes":"디저트/차/베이커리","latitude":36.1384948030215,"longitude":128.09439488921,"bookYear":null,"nameKR":"오늘참예쁘다","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"김천시","status":"ACTIVE"},
        {"id":34522,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/u_love_sikdang2020/","foodTypes":"이탈리아식","latitude":37.6464371949623,"longitude":127.124865371949,"bookYear":null,"nameKR":"유럽식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":34504,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"미국식","latitude":35.0695062361511,"longitude":129.079844477197,"bookYear":null,"nameKR":"더피자스탠드(부산점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":34501,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1539580837588,"longitude":129.067752930747,"bookYear":null,"nameKR":"드메르(전포점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34499,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1534253333494,"longitude":129.067123783635,"bookYear":null,"nameKR":"수수하지만굉장해! ","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34491,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.3264770692753,"longitude":127.424967848927,"bookYear":null,"nameKR":"오징어어때 ","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":34489,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.3483352211998,"longitude":127.378411893456,"bookYear":null,"nameKR":"트리플디","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":33165,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.7768476780782,"longitude":127.056455883909,"bookYear":null,"nameKR":"신사우물갈비(배방직영점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":33164,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.8173065194698,"longitude":127.108701907543,"bookYear":null,"nameKR":"신사우물갈비","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":34487,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1729576549741,"longitude":129.13158500122,"bookYear":null,"nameKR":"우적(센텀점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":36264,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1468332032487,"longitude":129.113561434752,"bookYear":null,"nameKR":"베터커피베터라이프","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":34479,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3681634346789,"longitude":127.106311660534,"bookYear":null,"nameKR":"미방","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":34470,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/view66_maneku","foodTypes":"일식","latitude":37.8167435281051,"longitude":127.103687436292,"bookYear":null,"nameKR":"뷰66마네쿠","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":34469,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.8167435281051,"longitude":127.103687436292,"bookYear":null,"nameKR":"뷰66양주스퀘어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":34466,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vodabar/","foodTypes":"바/주점","latitude":35.2586851194388,"longitude":128.636005155675,"bookYear":null,"nameKR":"보다","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":34465,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cream_wave_cake","foodTypes":"디저트/차/베이커리","latitude":35.196622026793,"longitude":129.070228424397,"bookYear":null,"nameKR":"크림웨이브케이크","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":34464,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daecheon_official/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8142673046128,"longitude":128.523797691475,"bookYear":null,"nameKR":"대천국밥본점","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":34463,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coup_de_foudre_2021/","foodTypes":"디저트/차/베이커리","latitude":37.4486372652499,"longitude":126.694402466325,"bookYear":null,"nameKR":"쿠드푸드흐","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":34454,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.3012460499123,"longitude":126.838978795517,"bookYear":null,"nameKR":"언니네화덕핏짜","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36353,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mamangouter/","foodTypes":"디저트/차/베이커리","latitude":35.1832960418093,"longitude":128.095464011603,"bookYear":null,"nameKR":"마망구떼","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":34590,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.at.tune","foodTypes":"디저트/차/베이커리","latitude":37.8617315545763,"longitude":126.773407493881,"bookYear":null,"nameKR":"앳튠","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":34593,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.2018771076575,"longitude":126.828005103936,"bookYear":null,"nameKR":"여운","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":36351,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.8765790259628,"longitude":127.753312936019,"bookYear":null,"nameKR":"감자바위한우","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"춘천시","status":"ACTIVE"},
        {"id":34599,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.6119259654161,"longitude":126.455918966496,"bookYear":null,"nameKR":"맛을담은강된장","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":34600,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.9338216630819,"longitude":127.552069856596,"bookYear":null,"nameKR":"언베일커피스탠드","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":34603,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wearegege/","foodTypes":"중식","latitude":37.3621749058887,"longitude":127.106553698118,"bookYear":null,"nameKR":"꺼거2","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":34605,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5219394048998,"longitude":126.805129571423,"bookYear":null,"nameKR":"맛있는이야기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":36329,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sansungbutcher_seomyeon/","foodTypes":"한식(육류)","latitude":35.1555811081799,"longitude":129.066658119087,"bookYear":null,"nameKR":"산성식육점(전포직영점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":36328,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2067682086648,"longitude":129.081860320786,"bookYear":null,"nameKR":"산성식육점(동래본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":36341,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6545441479581,"longitude":127.303680796223,"bookYear":null,"nameKR":"화로구이육걸(마석점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":36340,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwaloguiyuggeol/","foodTypes":"한식(육류)","latitude":37.6545796814528,"longitude":127.246568826065,"bookYear":null,"nameKR":"화로구이육걸","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":36339,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/onjeok","foodTypes":"디저트/차/베이커리","latitude":37.428944709035,"longitude":127.104589265174,"bookYear":null,"nameKR":"온적공간","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":36278,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/anjooga88/","foodTypes":"바/주점","latitude":37.3197236803391,"longitude":126.847366387629,"bookYear":null,"nameKR":"안주가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":34612,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wangchang_jokbal/","foodTypes":"한식(육류)","latitude":37.379964955328,"longitude":127.116832474045,"bookYear":null,"nameKR":"왕창족발","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":34614,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5369106514642,"longitude":126.643301998629,"bookYear":null,"nameKR":"로이스바이앤티진","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":34617,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wynyard.asiancuisine/","foodTypes":"중식","latitude":37.6130708407415,"longitude":127.168796214064,"bookYear":null,"nameKR":"윈야드다산","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":34622,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lee_mil_gong_","foodTypes":"기타","latitude":35.2108969296613,"longitude":129.035387065593,"bookYear":null,"nameKR":"이상한나라의 밀가루공장","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":34623,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1742209532835,"longitude":129.173539469735,"bookYear":null,"nameKR":"우주옥 숯불한우소갈비전문점","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":34627,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gorangsigdang/","foodTypes":"한식(육류)","latitude":35.2097716819925,"longitude":129.008402903694,"bookYear":null,"nameKR":"고랑식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":34633,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1510553661348,"longitude":126.849937321267,"bookYear":null,"nameKR":"갓포이에","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":34634,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1457073145851,"longitude":126.842472227221,"bookYear":null,"nameKR":"명품상무팥죽","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":34635,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/geumseok_ori","foodTypes":"한식(가금류)","latitude":37.6026026047579,"longitude":126.891178148989,"bookYear":null,"nameKR":"금석오리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":34639,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/farfalle_pasta_/","foodTypes":"이탈리아식","latitude":37.741992404941,"longitude":127.050792896572,"bookYear":null,"nameKR":"파르팔레","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":34640,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1485744348206,"longitude":129.114251938216,"bookYear":null,"nameKR":"청기와타운(광안리점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":34664,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dragonchai_","foodTypes":"중식","latitude":37.4004090859667,"longitude":126.921478953293,"bookYear":null,"nameKR":"드래곤차이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":34677,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fullbretzel/","foodTypes":"디저트/차/베이커리","latitude":37.1738698181571,"longitude":127.113366608595,"bookYear":null,"nameKR":"풀브렛첼","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":34689,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.7503341201926,"longitude":127.681951897687,"bookYear":null,"nameKR":"노다웃오리지널리티","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":34691,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.4250479546821,"longitude":127.392714657572,"bookYear":null,"nameKR":"청기와타운(관평점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":34693,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.8716721382706,"longitude":128.723251255813,"bookYear":null,"nameKR":"넘버원까스","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":34697,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.7317199786222,"longitude":127.450475493533,"bookYear":null,"nameKR":"우미솥(청주오창본점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":34699,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.0474701609914,"longitude":127.04546231786,"bookYear":null,"nameKR":"청기와타운(고덕점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":34700,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.8131257581917,"longitude":127.161923404838,"bookYear":null,"nameKR":"은항아리정육식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":34718,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/galmaegidaedo_namsan/","foodTypes":"한식(육류)","latitude":35.2299046066211,"longitude":128.888375401942,"bookYear":null,"nameKR":"갈매기대도(남산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":34720,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2066952528693,"longitude":128.075706078028,"bookYear":null,"nameKR":"미지인","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":36327,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chulma_official/","foodTypes":"한식(육류)","latitude":37.3399595979967,"longitude":127.172675927466,"bookYear":null,"nameKR":"철마숙성한우(용인본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":36325,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kitchen187_/","foodTypes":"바/주점","latitude":35.1606287270853,"longitude":129.173519031911,"bookYear":null,"nameKR":"키친187다이닝바","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":34745,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1496756332474,"longitude":129.002446676086,"bookYear":null,"nameKR":"장원육전밀면","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":35586,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dear.sweet.lab/","foodTypes":"디저트/차/베이커리","latitude":37.2942412282805,"longitude":127.055313270113,"bookYear":null,"nameKR":"디어스윗랩","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35162,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2943709532527,"longitude":127.024747664733,"bookYear":null,"nameKR":"화홍문갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":36276,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.7465670232431,"longitude":126.729934259203,"bookYear":null,"nameKR":"드디어커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":34748,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4476635839714,"longitude":126.697747013684,"bookYear":null,"nameKR":"돈불식당","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":34749,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hozakdo/","foodTypes":"디저트/차/베이커리","latitude":37.2863223437013,"longitude":127.016440376537,"bookYear":null,"nameKR":"호작도","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34750,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/becomehambak_gwanggyo/","foodTypes":"기타","latitude":37.2915676707273,"longitude":127.050470929016,"bookYear":null,"nameKR":"그렇게함박이된다(광교아브뉴프랑점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34757,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.0364672773472,"longitude":129.333619241123,"bookYear":null,"nameKR":"고려의숲 품격한우 한정식","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":34760,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wyd_coffee/","foodTypes":"디저트/차/베이커리","latitude":36.3203078048346,"longitude":127.343489810093,"bookYear":null,"nameKR":"와이드","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":34763,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chakhwa_/","foodTypes":"한식(육류)","latitude":37.4038504387942,"longitude":126.953054090224,"bookYear":null,"nameKR":"착화","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":34764,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_jeil_official/","foodTypes":"바/주점","latitude":37.264697463315,"longitude":127.030873990619,"bookYear":null,"nameKR":"제일회관(인계직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34765,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.2689790980757,"longitude":127.006465484095,"bookYear":null,"nameKR":"제일회관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34782,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3424292769683,"longitude":127.33199763792,"bookYear":null,"nameKR":"구좌리얼크니손칼국수(화담숲직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":34784,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.4128926910695,"longitude":127.290194891265,"bookYear":null,"nameKR":"명동칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":34840,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3773424502175,"longitude":126.727496126419,"bookYear":null,"nameKR":"슐츠커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":34841,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dozo.coffee/","foodTypes":"디저트/차/베이커리","latitude":37.3767491227915,"longitude":127.112355461382,"bookYear":null,"nameKR":"도조커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":34844,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kingpork_offcial/","foodTypes":"한식(육류)","latitude":37.5023422984096,"longitude":126.773163900307,"bookYear":null,"nameKR":"임금돼지(부천본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":34853,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oilyburger/","foodTypes":"기타","latitude":35.858548406885,"longitude":128.623055335599,"bookYear":null,"nameKR":"오일리버거(범어점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":34947,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_noeunro/","foodTypes":"디저트/차/베이커리","latitude":36.3630413559029,"longitude":127.31524858962,"bookYear":null,"nameKR":"더노은로","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":36303,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/___beurre/","foodTypes":"디저트/차/베이커리","latitude":37.2026971610624,"longitude":127.125400712242,"bookYear":null,"nameKR":"뵈르","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":35093,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3378795564947,"longitude":126.934427165917,"bookYear":null,"nameKR":"라우드커피플레이스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":35100,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.2792703438517,"longitude":127.153453166634,"bookYear":null,"nameKR":"르뺑데쟈코벵","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":35104,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.8531016604845,"longitude":126.997237129625,"bookYear":null,"nameKR":"어랑 추어탕전문점","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":35115,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mong_yktr/","foodTypes":"바/주점","latitude":35.8548334121064,"longitude":128.55285001534,"bookYear":null,"nameKR":"이자카야몽","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":35118,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/comoyoon/","foodTypes":"디저트/차/베이커리","latitude":37.6248726304674,"longitude":127.152422448068,"bookYear":null,"nameKR":"꼬모윤(다산신도시점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":35119,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.3600614429073,"longitude":127.337867142798,"bookYear":null,"nameKR":"용빠주방","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":35123,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.8665966614083,"longitude":128.602222889847,"bookYear":null,"nameKR":"다이닝바 고우니","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":36280,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ravencoffeeroastery/","foodTypes":"디저트/차/베이커리","latitude":35.1770271936885,"longitude":129.07316183223,"bookYear":null,"nameKR":"레이븐커피로스터리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":36277,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2565473491513,"longitude":128.60730897919,"bookYear":null,"nameKR":"명동갈비탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":36202,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/danahan_dessert?utm_source=qr&igshid=NGExMmI2YTkyZg%3D%3D","foodTypes":"디저트/차/베이커리","latitude":36.8337520617939,"longitude":127.127778110283,"bookYear":null,"nameKR":"단아한과자점","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":36267,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1342294576999,"longitude":129.111216765972,"bookYear":null,"nameKR":"나막집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":36265,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ji_ssert/","foodTypes":"디저트/차/베이커리","latitude":34.8250809094299,"longitude":128.415623137618,"bookYear":null,"nameKR":"지저트","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":36262,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/its.katsu_/","foodTypes":"일식","latitude":35.2379113352801,"longitude":128.583809611684,"bookYear":null,"nameKR":"이츠카츠","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":36259,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/__tacoman/","foodTypes":"중남미식","latitude":36.6476990866991,"longitude":127.475550659305,"bookYear":null,"nameKR":"타코맨","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36256,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3705044199436,"longitude":126.727970634048,"bookYear":null,"nameKR":"윌로우우드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":35862,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.35240103765,"longitude":127.165527050222,"bookYear":null,"nameKR":"광주칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":36240,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.0959668103765,"longitude":128.900832278638,"bookYear":null,"nameKR":"오리마루(명지본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":35125,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/forkner_beomgye/","foodTypes":"이탈리아식","latitude":37.3910154417126,"longitude":126.953656310193,"bookYear":null,"nameKR":"포크너(범계점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35130,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pretbakery/","foodTypes":"디저트/차/베이커리","latitude":37.383699233336,"longitude":127.149706930739,"bookYear":null,"nameKR":"프렛베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35133,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.myminino/","foodTypes":"디저트/차/베이커리","latitude":37.817674016142,"longitude":127.09650175832,"bookYear":null,"nameKR":"마이미니노","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":35136,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/suran_chinese/","foodTypes":"중식","latitude":37.5924827318967,"longitude":126.671904974353,"bookYear":null,"nameKR":"수란","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":35137,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3954648629951,"longitude":127.112113596734,"bookYear":null,"nameKR":"킵샐러드(판교본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":35141,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1551337662558,"longitude":129.066373253328,"bookYear":null,"nameKR":"토오루","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":35142,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nobadvibes.official/","foodTypes":"디저트/차/베이커리","latitude":37.5078881923517,"longitude":126.732053818663,"bookYear":null,"nameKR":"노배드바이브스","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":35949,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.4059981630337,"longitude":126.683622060373,"bookYear":null,"nameKR":"스시민(스퀘어원점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":35948,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.4712068214003,"longitude":126.626034278196,"bookYear":null,"nameKR":"스시민신관","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":35947,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.4710478670874,"longitude":126.626199777189,"bookYear":null,"nameKR":"스시민(본점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":35151,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8339525630715,"longitude":127.136999347638,"bookYear":null,"nameKR":"육화미(두정점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":35156,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1362909579584,"longitude":129.070460615253,"bookYear":null,"nameKR":"윤우재 문현샤브칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":35169,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_repas/","foodTypes":"디저트/차/베이커리","latitude":35.1215389135739,"longitude":126.915401900809,"bookYear":null,"nameKR":"레파스","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":35959,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4435637107737,"longitude":126.766342877613,"bookYear":null,"nameKR":"산향한정식(대공원점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":36241,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.7579279584336,"longitude":127.12816660663,"bookYear":null,"nameKR":"채육시간쭈꾸미","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":35423,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1632000941408,"longitude":129.164903819836,"bookYear":null,"nameKR":"할매집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":36223,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_booboo_pangyo/","foodTypes":"디저트/차/베이커리","latitude":37.3905006967421,"longitude":127.093321714485,"bookYear":null,"nameKR":"카페부부(서판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":36232,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2810297051829,"longitude":126.969382862232,"bookYear":null,"nameKR":"박네집송탄부대찌개&무공닭갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35173,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeonpoyeokwan/","foodTypes":"바/주점","latitude":35.1547322271411,"longitude":129.060411116548,"bookYear":null,"nameKR":"전포여관","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":36226,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/slowalkcoffeestand","foodTypes":"디저트/차/베이커리","latitude":36.352972105044,"longitude":127.376907609094,"bookYear":null,"nameKR":"슬로워크커피스탠드","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":36224,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sione_bake.shop","foodTypes":"디저트/차/베이커리","latitude":36.3451961388443,"longitude":127.394742193009,"bookYear":null,"nameKR":"시오네베이크샵","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":35184,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pakuya_yb/","foodTypes":"바/주점","latitude":35.1791264391703,"longitude":126.893305546278,"bookYear":null,"nameKR":"파쿠야(용봉점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":35185,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/roun.cafe/","foodTypes":"바/주점","latitude":35.8442843227694,"longitude":128.621894554769,"bookYear":null,"nameKR":"로운","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":35189,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2944664882956,"longitude":127.185738968163,"bookYear":null,"nameKR":"글렌로스골프클럽","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":36215,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/maison.jenny","foodTypes":"디저트/차/베이커리","latitude":37.5321533302342,"longitude":126.657432915581,"bookYear":null,"nameKR":"메종드제니","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":36178,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/koreakong_/","foodTypes":"한식(일반한식)","latitude":35.9392388752586,"longitude":128.55832897065,"bookYear":null,"nameKR":"대한콩국(칠곡본점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":36211,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.646417892181,"longitude":127.235807326789,"bookYear":null,"nameKR":"딩딩","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":35977,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.1427920745114,"longitude":128.707665589725,"bookYear":null,"nameKR":"토리이에","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":33463,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1656534685838,"longitude":129.036916844679,"bookYear":null,"nameKR":"삼수집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":36162,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/caffeluigi_official/","foodTypes":"디저트/차/베이커리","latitude":35.8579876709676,"longitude":128.636078150541,"bookYear":null,"nameKR":"카페루이지","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36163,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/modo_waffle","foodTypes":"디저트/차/베이커리","latitude":35.2075181611476,"longitude":128.580738086427,"bookYear":null,"nameKR":"모도(본점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":35192,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aupres_bake/","foodTypes":"디저트/차/베이커리","latitude":37.3129721977202,"longitude":126.832609989701,"bookYear":null,"nameKR":"오프레베이크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":31671,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.7397148658476,"longitude":127.036936310554,"bookYear":null,"nameKR":"날라리식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":35953,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mattoita7","foodTypes":"기타","latitude":37.4763126069551,"longitude":126.870100850823,"bookYear":null,"nameKR":"마또이따레스토랑루프탑앤바","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":35203,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/meetssteak_seohyeon","foodTypes":"기타","latitude":37.3840671535876,"longitude":127.121552935695,"bookYear":null,"nameKR":"미츠스테이크 서현","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35205,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kailinfeng_/","foodTypes":"중식","latitude":37.5302535716189,"longitude":126.647782386384,"bookYear":null,"nameKR":"카이린펑","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":29911,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fastars_segundo/","foodTypes":"이탈리아식","latitude":36.50271838381485,"longitude":127.25002440986644,"bookYear":null,"nameKR":"빠스타스","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":35210,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1556967961111,"longitude":128.704665730862,"bookYear":null,"nameKR":"겉바속촉압력솥통닭","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":35212,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.2027407382353,"longitude":128.628104978748,"bookYear":null,"nameKR":"겉바속촉압력솥통닭(신촌점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":35211,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1738634691526,"longitude":128.060177677554,"bookYear":null,"nameKR":"겉바속촉압력솥통닭(평거점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":35213,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.2210522086887,"longitude":128.571088461879,"bookYear":null,"nameKR":"겉바속촉압력솥통닭(마산회원점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":35242,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gelateria_maman/","foodTypes":"디저트/차/베이커리","latitude":37.3637209133898,"longitude":127.106324583275,"bookYear":null,"nameKR":"마망젤라또","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35244,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8603112806246,"longitude":128.64670673687,"bookYear":null,"nameKR":"양고집부대찌개&철판볶음","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36205,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/finepalette._/","foodTypes":"기타","latitude":37.6736971410655,"longitude":126.798093080634,"bookYear":null,"nameKR":"파인팔레트","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35260,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kk2___bake/","foodTypes":"디저트/차/베이커리","latitude":37.5333635711451,"longitude":126.650734729401,"bookYear":null,"nameKR":"베이크샵 켜켜이","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":35265,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7916595704615,"longitude":127.164997671396,"bookYear":null,"nameKR":"알천비빔국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":35283,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maylee_yeon/","foodTypes":"중식","latitude":35.1802811883235,"longitude":129.072850889603,"bookYear":null,"nameKR":"메이리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":35284,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maylee_yeon/","foodTypes":"중식","latitude":35.1825172703947,"longitude":129.073343117786,"bookYear":null,"nameKR":"메이리연","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":1937,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/killing_icynoodles/","foodTypes":"한식(일반한식)","latitude":37.568267,"longitude":127.322006,"bookYear":null,"nameKR":"죽여주는동치미국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":35289,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/public_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":36.1405206031271,"longitude":128.441937909219,"bookYear":null,"nameKR":"퍼블릭 커피 로스터즈","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":35299,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/meatandveggie/","foodTypes":"기타","latitude":35.330113779007,"longitude":129.00860183447,"bookYear":null,"nameKR":"밋앤베지","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":35300,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/midtown_bowl_/","foodTypes":"미국식","latitude":35.1564394587299,"longitude":129.121685190903,"bookYear":null,"nameKR":"미드타운볼","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36182,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4775399169207,"longitude":126.505730950744,"bookYear":null,"nameKR":"카페 투영즈","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":35305,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/patisserie_sosin/","foodTypes":"디저트/차/베이커리","latitude":36.3616385121887,"longitude":127.352675511715,"bookYear":null,"nameKR":"파티세리소신","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":35309,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wonkats_official/","foodTypes":"일식","latitude":35.2049184336864,"longitude":129.084984574728,"bookYear":null,"nameKR":"원카츠","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":35313,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1871098746253,"longitude":129.048434194562,"bookYear":null,"nameKR":"나탄약선요리 ","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":35467,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.1865642334557,"longitude":129.085365274189,"bookYear":null,"nameKR":"랑돼지","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":35320,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mentene_official/","foodTypes":"일식","latitude":35.2085957616265,"longitude":129.008304541422,"bookYear":null,"nameKR":"멘테네","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":35323,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/arterie_kunsthaus/","foodTypes":"기타","latitude":35.8261171273303,"longitude":128.616614640888,"bookYear":null,"nameKR":"아르테리","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":35324,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.429865663465,"longitude":127.071685539119,"bookYear":null,"nameKR":"행복한소풍 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":36175,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.6172499902732,"longitude":126.748913507681,"bookYear":null,"nameKR":"문화반점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":35327,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/hyyh_coffee","foodTypes":"디저트/차/베이커리","latitude":37.5035104155183,"longitude":126.764264791509,"bookYear":null,"nameKR":"화양연화","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":36103,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/handongotgan/","foodTypes":"한식(육류)","latitude":37.2059172256117,"longitude":127.113349866127,"bookYear":null,"nameKR":"한돈곳간(동탄본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":35666,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.2731945359489,"longitude":127.07272014116,"bookYear":null,"nameKR":"라퐁텐","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":30495,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":36.0080964116588,"longitude":129.330458752437,"bookYear":null,"nameKR":"가정초밥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":34284,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.7949790359265,"longitude":126.430236487625,"bookYear":null,"nameKR":"와플랑(목포하당점)","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":6225,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.197965,"longitude":127.075546,"bookYear":null,"nameKR":"커피볶는곰다방","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":35751,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soobook_restaurant/","foodTypes":"일식","latitude":37.6990531063405,"longitude":126.753575820691,"bookYear":null,"nameKR":"식당수북","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35336,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/namhae00/","foodTypes":"바/주점","latitude":37.3909063697575,"longitude":126.953304727246,"bookYear":null,"nameKR":"남해물고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":36102,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/handonkokgan/","foodTypes":"한식(육류)","latitude":37.3625100862929,"longitude":127.106555413744,"bookYear":null,"nameKR":"한돈곳간(분당정자점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35337,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/billyscoffeehouse/","foodTypes":"디저트/차/베이커리","latitude":37.6388338805425,"longitude":126.627564536337,"bookYear":null,"nameKR":"빌리스커피하우스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":35339,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/b_rownery/","foodTypes":"디저트/차/베이커리","latitude":37.357267846086,"longitude":127.124175023365,"bookYear":null,"nameKR":"브라우너리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":33735,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kushi_kowa/","foodTypes":"일식","latitude":37.5625864791901,"longitude":127.189964124756,"bookYear":null,"nameKR":"쿠시코와","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":33835,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe__ombre ","foodTypes":"디저트/차/베이커리","latitude":37.7391119910723,"longitude":127.048255948641,"bookYear":null,"nameKR":"옴브레","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":33834,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/cafe.eungy.o","foodTypes":"디저트/차/베이커리","latitude":35.2285032259617,"longitude":128.876321973794,"bookYear":null,"nameKR":"은교","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":33833,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.0764412514408,"longitude":129.016922719061,"bookYear":null,"nameKR":"스시로운","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":35374,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/till_bagel/","foodTypes":"디저트/차/베이커리","latitude":35.8629091443456,"longitude":128.605923781682,"bookYear":null,"nameKR":"카페틸","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":36165,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.2328255096288,"longitude":129.08741940485,"bookYear":null,"nameKR":"황금칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":33829,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jianyong_hanam/","foodTypes":"중식","latitude":37.5620314431748,"longitude":127.192812893671,"bookYear":null,"nameKR":"찌엔용","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":33815,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/agacheetah_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.2422642022191,"longitude":127.069660384057,"bookYear":null,"nameKR":"아가치타","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":33814,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.2525105751105,"longitude":127.48994489114,"bookYear":null,"nameKR":"커피나인(이천SK하이닉스점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":33806,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.8194236586263,"longitude":127.092133550562,"bookYear":null,"nameKR":"스시다린","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":33805,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yy0ungjae/","foodTypes":"바/주점","latitude":37.4026684991805,"longitude":126.722150000586,"bookYear":null,"nameKR":"영재","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":33794,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/patisserie_ousia/","foodTypes":"디저트/차/베이커리","latitude":36.3296155104512,"longitude":127.425574950363,"bookYear":null,"nameKR":"우시아","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":33792,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hoho__house/","foodTypes":"디저트/차/베이커리","latitude":35.2584865517854,"longitude":128.634747272343,"bookYear":null,"nameKR":"호호하우스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":33789,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seongpo_gogi/","foodTypes":"한식(육류)","latitude":37.3205746334363,"longitude":126.846058548307,"bookYear":null,"nameKR":"성포정육식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":36131,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lafolia_official","foodTypes":"이탈리아식","latitude":37.5339020590277,"longitude":126.652034349285,"bookYear":null,"nameKR":"라폴리아","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":33787,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seoul_pastry_suwon/","foodTypes":"디저트/차/베이커리","latitude":37.2858952247334,"longitude":127.015294330857,"bookYear":null,"nameKR":"서울페이스트리(행궁점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33783,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/labellemaman_/","foodTypes":"디저트/차/베이커리","latitude":37.3630091848763,"longitude":127.106533316945,"bookYear":null,"nameKR":"라벨르마망","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":34443,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.3944039022277,"longitude":127.108352326702,"bookYear":null,"nameKR":"마마문스판교본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":33778,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6389804882922,"longitude":127.123567504293,"bookYear":null,"nameKR":"범표원두(갈매점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":33773,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/byeollimdang/","foodTypes":"디저트/차/베이커리","latitude":37.4323910895284,"longitude":127.134052116834,"bookYear":null,"nameKR":"별림당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":31894,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8352997198826,"longitude":129.210053765294,"bookYear":null,"nameKR":"동양백반 ","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":33504,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_fullmoon_official/","foodTypes":"디저트/차/베이커리","latitude":35.1877059586015,"longitude":129.212396915489,"bookYear":null,"nameKR":"대보름","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":33770,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/palette___coffee/","foodTypes":"디저트/차/베이커리","latitude":37.3186915641078,"longitude":126.836518384424,"bookYear":null,"nameKR":"팔레트커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":31854,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.8826881473949,"longitude":128.618780396258,"bookYear":null,"nameKR":"고인돌","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":35312,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.2668313941414,"longitude":127.054212038916,"bookYear":null,"nameKR":"666버거","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35378,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kokaja_busan/","foodTypes":"디저트/차/베이커리","latitude":35.1531561760044,"longitude":129.066921993782,"bookYear":null,"nameKR":"꼬까자","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33771,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/villa.in.apt/","foodTypes":"이탈리아식","latitude":37.4492548262897,"longitude":126.698474968573,"bookYear":null,"nameKR":"빌라인아파트","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":34218,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nanabangkok_/","foodTypes":"아시아식","latitude":35.1513197020217,"longitude":126.853892942225,"bookYear":null,"nameKR":"나나방콕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":35596,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.3965285100571,"longitude":126.930301561895,"bookYear":null,"nameKR":"전기구이나는통닭본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35764,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myung_baeck_house","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2485039341448,"longitude":127.05493863973,"bookYear":null,"nameKR":"명백집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35769,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/paldangnengmyun/","foodTypes":"한식(면류)","latitude":37.3044128876658,"longitude":127.075043334924,"bookYear":null,"nameKR":"팔당냉면(수지광교본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":35693,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/okonomi_story/","foodTypes":"일식","latitude":37.3996697728322,"longitude":126.922671114549,"bookYear":null,"nameKR":"오코노미스토리(안양점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35793,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daily.oasis/","foodTypes":"디저트/차/베이커리","latitude":35.8678803225468,"longitude":128.608373061415,"bookYear":null,"nameKR":"데일리오아시스대구본점","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":35834,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sen_innovation/","foodTypes":"일식","latitude":35.1784842727639,"longitude":128.137849572254,"bookYear":null,"nameKR":"센라멘","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":35831,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goraebudaejjigae_ochang/","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.7121635887373,"longitude":127.431252172675,"bookYear":null,"nameKR":"수제햄고래부대찌개(오창본점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35993,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kuromatsu_busan/","foodTypes":"일식","latitude":35.1750130888429,"longitude":129.196506300492,"bookYear":null,"nameKR":"흑송","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":33759,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.2363861889432,"longitude":128.685507979376,"bookYear":null,"nameKR":"브레드웜(창원점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":33758,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bread_warm/","foodTypes":"디저트/차/베이커리","latitude":35.3125136428044,"longitude":128.733092387776,"bookYear":null,"nameKR":"브레드웜(진영점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":33757,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.3305778698348,"longitude":129.000223766199,"bookYear":null,"nameKR":"박만석돼지곰탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":33749,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4667243057134,"longitude":127.137331514615,"bookYear":null,"nameKR":"영동교집(위례직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":33745,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1554903014708,"longitude":129.064400163466,"bookYear":null,"nameKR":"낙화","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33744,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1536599469326,"longitude":129.060712258131,"bookYear":null,"nameKR":"수라상","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":35383,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.5333419696599,"longitude":126.645601017085,"bookYear":null,"nameKR":"백월스시","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":33743,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.153719117011,"longitude":129.063498344453,"bookYear":null,"nameKR":"3파운드","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33741,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_afternoontea/","foodTypes":"디저트/차/베이커리","latitude":35.1641035234731,"longitude":129.128255094692,"bookYear":null,"nameKR":"오후의홍차","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":33739,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeo_jeol_ro/","foodTypes":"한식(육류)","latitude":35.8704055699127,"longitude":128.592774671117,"bookYear":null,"nameKR":"저절로막창(종로점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":33738,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/busanbaminatanadda/","foodTypes":"바/주점","latitude":37.4318946564142,"longitude":127.132831310893,"bookYear":null,"nameKR":"부산의밤","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":35388,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/___autumnvibes/","foodTypes":"기타","latitude":37.2342952152708,"longitude":127.204100581556,"bookYear":null,"nameKR":"어텀바이브","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":35389,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lollakr/","foodTypes":"기타","latitude":36.3736462432526,"longitude":127.504145167071,"bookYear":null,"nameKR":"롤라","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":35573,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bunsik_baekhwajeom/","foodTypes":"한식(일반한식)","latitude":37.2108651707078,"longitude":127.089445559923,"bookYear":null,"nameKR":"분식백화점(동탄영천직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":35391,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1521270885189,"longitude":129.1170095548,"bookYear":null,"nameKR":"석화연","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35394,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju_siheung/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3446369818996,"longitude":126.735441702881,"bookYear":null,"nameKR":"제주은희네해장국(시흥정왕점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":35836,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.527738705047,"longitude":126.725356626683,"bookYear":null,"nameKR":"별미뼈다귀","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":35399,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1562755406682,"longitude":129.065913517387,"bookYear":null,"nameKR":"지즈","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":35401,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mandugoeul/","foodTypes":"한식(면류)","latitude":35.8748678176593,"longitude":128.667537163278,"bookYear":null,"nameKR":"만두고을(방촌점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":32300,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.2451294220493,"longitude":127.061898704684,"bookYear":null,"nameKR":"미스트","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35402,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mandugoeul/","foodTypes":"한식(면류)","latitude":35.8309170822856,"longitude":128.704980945012,"bookYear":null,"nameKR":"만두고을(시지점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":35859,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/izakaya_maneku","foodTypes":"바/주점","latitude":37.7083303528939,"longitude":127.04787943597,"bookYear":null,"nameKR":"마네쿠","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":33725,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3508873918775,"longitude":127.109492449514,"bookYear":null,"nameKR":"분당두부","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35403,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.8529734111099,"longitude":128.502741302529,"bookYear":null,"nameKR":"만두고을(성서점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":35723,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4518582955875,"longitude":126.65629224927,"bookYear":null,"nameKR":"고수찜닭","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":33723,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/teacher__heo","foodTypes":"바/주점","latitude":37.7388576944996,"longitude":127.049446689053,"bookYear":null,"nameKR":"허선생","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":35405,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dolcee_cafe_/","foodTypes":"디저트/차/베이커리","latitude":37.386016889663,"longitude":127.111002414842,"bookYear":null,"nameKR":"돌체베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":35407,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hoohoocurry/","foodTypes":"일식","latitude":35.8601580471113,"longitude":128.637448282332,"bookYear":null,"nameKR":"후후카레","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":35416,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/parangseya/","foodTypes":"일식","latitude":37.3899740797681,"longitude":126.953529121118,"bookYear":null,"nameKR":"파랑새야!","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35417,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/sazagalbi","foodTypes":"한식(육류)","latitude":37.3903294088302,"longitude":126.954133500425,"bookYear":null,"nameKR":"사자갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35418,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pimpinella_eatinghouse/","foodTypes":"기타","latitude":36.6120233393892,"longitude":127.465585106399,"bookYear":null,"nameKR":"핌피넬라","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33712,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_macaso/","foodTypes":"디저트/차/베이커리","latitude":36.3416262779049,"longitude":127.387427507262,"bookYear":null,"nameKR":"마카소","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":33706,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vitoscone_","foodTypes":"디저트/차/베이커리","latitude":37.6452671816497,"longitude":126.626294177736,"bookYear":null,"nameKR":"비토스콘(구래점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":36154,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/just_bucheon/","foodTypes":"이탈리아식","latitude":37.4897755700098,"longitude":126.75595665864,"bookYear":null,"nameKR":"그냥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":33699,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.2633931057919,"longitude":127.035712151263,"bookYear":null,"nameKR":"포크너(인계점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33697,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1726983453411,"longitude":128.983766139519,"bookYear":null,"nameKR":"해물왕창칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":36135,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/j_cheongdam_2019/","foodTypes":"중식","latitude":37.8311056892846,"longitude":127.069005077274,"bookYear":null,"nameKR":"제이청담반점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":36134,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/petitrong","foodTypes":"디저트/차/베이커리","latitude":35.0988959981025,"longitude":128.957990708597,"bookYear":null,"nameKR":"쁘띠롱","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":36132,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jin1926pungmu/","foodTypes":"일식","latitude":37.6073967175777,"longitude":126.72618836119,"bookYear":null,"nameKR":"진1926(풍무점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":33688,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3726101726552,"longitude":127.114052580893,"bookYear":null,"nameKR":"1987,봄","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":33687,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wookineyo/","foodTypes":"바/주점","latitude":35.5522416534438,"longitude":129.295027542907,"bookYear":null,"nameKR":"우기네","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":33686,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1816848742819,"longitude":129.045743395738,"bookYear":null,"nameKR":"순두부마을","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":33685,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/flower._.ricesweet/","foodTypes":"디저트/차/베이커리","latitude":36.6166363294552,"longitude":127.518193782998,"bookYear":null,"nameKR":"라이스윗마카롱","ribbonType":"정보없음","zone2_1":"충북","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33684,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/aubestorage_ilsan/","foodTypes":"디저트/차/베이커리","latitude":37.6577673770129,"longitude":126.771382818559,"bookYear":null,"nameKR":"오브스토리지(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":33683,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.6252049735869,"longitude":127.154936446325,"bookYear":null,"nameKR":"참치한상","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":33682,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.2491043772112,"longitude":127.042485240859,"bookYear":null,"nameKR":"오월의꽃수저 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33671,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_ordinary_house/","foodTypes":"디저트/차/베이커리","latitude":35.8671814148723,"longitude":128.595955059163,"bookYear":null,"nameKR":"오디너리하우스","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":33670,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ord.diner/","foodTypes":"기타","latitude":35.866501757046,"longitude":128.597786291316,"bookYear":null,"nameKR":"오드다이너","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":33677,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.87787760604,"longitude":128.612711757809,"bookYear":null,"nameKR":"삼봉대감","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":33669,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hanoi_mood/","foodTypes":"아시아식","latitude":35.8663210184841,"longitude":128.595940350073,"bookYear":null,"nameKR":"하노이무드","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":33661,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.4115425587772,"longitude":127.131141344937,"bookYear":null,"nameKR":"비스트로올","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":33657,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/slowjcoffee/","foodTypes":"디저트/차/베이커리","latitude":37.2121476048137,"longitude":127.169766050854,"bookYear":null,"nameKR":"슬로우제이커피공장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":33656,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gold_jjock/","foodTypes":"한식(육류)","latitude":37.7387209459209,"longitude":127.049186906982,"bookYear":null,"nameKR":"금쪽갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":36090,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/10593_bagel_coffee_house/","foodTypes":"디저트/차/베이커리","latitude":37.2547014075791,"longitude":127.021871207888,"bookYear":null,"nameKR":"1059-3베이글커피하우스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33645,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.1632342927233,"longitude":129.060905765166,"bookYear":null,"nameKR":"명란김밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33638,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.0008226013027,"longitude":127.080693425658,"bookYear":null,"nameKR":"참참식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":33637,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":34.968270565322,"longitude":127.52494991193,"bookYear":null,"nameKR":"다락카레호수공원점","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":35610,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/huchoo.daegu","foodTypes":"바/주점","latitude":35.8598179694922,"longitude":128.638055447015,"bookYear":null,"nameKR":"후추 (한식당)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":33631,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moonies_official/","foodTypes":"디저트/차/베이커리","latitude":35.8111945111979,"longitude":128.518615628493,"bookYear":null,"nameKR":"무니스","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":33630,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.1471773416003,"longitude":127.074190489299,"bookYear":null,"nameKR":"도란치킨","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":33629,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.3549668394963,"longitude":127.379985459058,"bookYear":null,"nameKR":"오복집","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":33627,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wanderlust.coffee/","foodTypes":"디저트/차/베이커리","latitude":35.2275569744396,"longitude":129.088499050849,"bookYear":null,"nameKR":"원더러스트","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":33624,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/orrd_busan/","foodTypes":"디저트/차/베이커리","latitude":35.1397434729986,"longitude":128.979127979692,"bookYear":null,"nameKR":"오르디","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":33622,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/whitehaven_coffee","foodTypes":"디저트/차/베이커리","latitude":37.5700176084467,"longitude":127.181080303494,"bookYear":null,"nameKR":"화이트헤이븐커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":33614,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2507391462565,"longitude":127.074847823295,"bookYear":null,"nameKR":"환이네뭉티기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33611,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.4667677914813,"longitude":127.142159396264,"bookYear":null,"nameKR":"한성돈까스(위례점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":36052,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_bavarder/","foodTypes":"디저트/차/베이커리","latitude":36.7371344801519,"longitude":127.453069996022,"bookYear":null,"nameKR":"바바르디","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":36063,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dolsamdak_cheolsan/","foodTypes":"한식(육류)","latitude":37.4749531611589,"longitude":126.867903781074,"bookYear":null,"nameKR":"돌삼닭(철산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":33604,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/2waywork/","foodTypes":"기타","latitude":35.5385371821553,"longitude":129.336365142047,"bookYear":null,"nameKR":"이중생업","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":33601,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.3550643001118,"longitude":126.589356109362,"bookYear":null,"nameKR":"산도155","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":33599,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.7500161057265,"longitude":127.118580219773,"bookYear":null,"nameKR":"차이나퀴진","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":33594,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ohjooyeun0119/","foodTypes":"한식(육류)","latitude":37.4717741361398,"longitude":127.31192487122,"bookYear":null,"nameKR":"퇴촌중앙갈비 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":33590,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woo.zic/","foodTypes":"디저트/차/베이커리","latitude":35.5392148765063,"longitude":129.322293049947,"bookYear":null,"nameKR":"우직베이커리","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":33589,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakery.gool/","foodTypes":"디저트/차/베이커리","latitude":35.1570763332398,"longitude":129.064723348674,"bookYear":null,"nameKR":"베이커리굴","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33579,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mabusi_3436/","foodTypes":"일식","latitude":37.3943527597949,"longitude":126.961778602993,"bookYear":null,"nameKR":"마부시","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":33575,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakery_wondong/","foodTypes":"디저트/차/베이커리","latitude":37.6624430215494,"longitude":126.890526556169,"bookYear":null,"nameKR":"원동","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":33564,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jointpizza/","foodTypes":"이탈리아식","latitude":36.8094797554539,"longitude":127.104979783722,"bookYear":null,"nameKR":"조인트피자","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":33560,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1548172363353,"longitude":129.061574383506,"bookYear":null,"nameKR":"샤브테이(부산점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33559,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.2189600447893,"longitude":128.682963755032,"bookYear":null,"nameKR":"샤브테이","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":33548,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafedegree/","foodTypes":"디저트/차/베이커리","latitude":37.3087905401283,"longitude":126.82892125654,"bookYear":null,"nameKR":"디그리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":35419,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wunder_coffeebar/","foodTypes":"디저트/차/베이커리","latitude":37.4880315249104,"longitude":126.813823325157,"bookYear":null,"nameKR":"분더커피바","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":33542,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mentawai_kr/","foodTypes":"아시아식","latitude":35.864400494449,"longitude":128.60994550382,"bookYear":null,"nameKR":"멘타와이 ","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":33535,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3644920476942,"longitude":127.106784891112,"bookYear":null,"nameKR":"고기의즐거움(분당직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":33536,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2073672433305,"longitude":127.073684310644,"bookYear":null,"nameKR":"고기의즐거움","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":35424,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wonang_gwangreung/","foodTypes":"기타","latitude":37.7786872035547,"longitude":127.142827266186,"bookYear":null,"nameKR":"원앙칼국수광릉","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":35426,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sea_otters_pie/","foodTypes":"디저트/차/베이커리","latitude":35.1570139569364,"longitude":129.115925907723,"bookYear":null,"nameKR":"씨오터스파이","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":34427,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/naricomo_main/","foodTypes":"디저트/차/베이커리","latitude":35.85472366849,"longitude":128.655087883589,"bookYear":null,"nameKR":"나리꼬모","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":34420,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/meat._.pancake/","foodTypes":"한식(육류)","latitude":35.6284521469011,"longitude":129.353464155834,"bookYear":null,"nameKR":"육회의전당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":34419,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/saintcoffee1980","foodTypes":"디저트/차/베이커리","latitude":37.4146074544424,"longitude":126.658146669251,"bookYear":null,"nameKR":"세인트커피","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":34418,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hanwolgwan.official/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1544039279275,"longitude":129.118277940394,"bookYear":null,"nameKR":"한월관","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":33534,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/onsil.cake/","foodTypes":"디저트/차/베이커리","latitude":37.3489404443443,"longitude":127.110466899341,"bookYear":null,"nameKR":"온실과자점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35431,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafegoodweather/","foodTypes":"디저트/차/베이커리","latitude":37.3866161898751,"longitude":127.124286529502,"bookYear":null,"nameKR":"카페굿웨더","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35433,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_creative_mongsim/","foodTypes":"디저트/차/베이커리","latitude":36.3518554713359,"longitude":127.424954452047,"bookYear":null,"nameKR":"몽심","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":35434,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mongsim_doan/","foodTypes":"디저트/차/베이커리","latitude":36.3260223264967,"longitude":127.345131186618,"bookYear":null,"nameKR":"몽심(도안점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":33530,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nathan_pasta_seocheon/","foodTypes":"이탈리아식","latitude":37.2370233123127,"longitude":127.077839400503,"bookYear":null,"nameKR":"나단이태리식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":35435,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.7873589773594,"longitude":128.89370502304,"bookYear":null,"nameKR":"쉼터민물매운탕","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"봉화군","status":"ACTIVE"},
        {"id":35436,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8974235730361,"longitude":128.616737913717,"bookYear":null,"nameKR":"프롭베이커리","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":35437,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wooyeonga/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.3188683344118,"longitude":128.998184430895,"bookYear":null,"nameKR":"우연가","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":36111,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4993464944471,"longitude":126.775398429638,"bookYear":null,"nameKR":"미쓰발랑코","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":36110,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.6414650423993,"longitude":126.786742695439,"bookYear":null,"nameKR":"정갈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":33527,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/saichou_sekai/","foodTypes":"바/주점","latitude":37.3677103184992,"longitude":126.728468295924,"bookYear":null,"nameKR":"사이쵸세카이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":35439,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2948073574866,"longitude":127.02707212631,"bookYear":null,"nameKR":"사또통족발","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35441,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fourseason_74/","foodTypes":"바/주점","latitude":35.868001849727,"longitude":128.599400693689,"bookYear":null,"nameKR":"포시즌74","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":35447,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ramen.ondo/","foodTypes":"일식","latitude":37.5632526660464,"longitude":127.194725811456,"bookYear":null,"nameKR":"라멘온도","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":36105,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8257862594915,"longitude":128.712809264265,"bookYear":null,"nameKR":"뜨거운형제동태탕찜","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":35451,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.8115326287356,"longitude":126.990985133547,"bookYear":null,"nameKR":"양주흑염소","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":35456,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bum__whale/","foodTypes":"바/주점","latitude":37.402284993562,"longitude":126.919047149259,"bookYear":null,"nameKR":"범고래","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35457,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2330097656282,"longitude":128.903218470215,"bookYear":null,"nameKR":"가얏골감자탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":35464,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thaimarsil_official/","foodTypes":"아시아식","latitude":37.2112139670853,"longitude":127.041528031745,"bookYear":null,"nameKR":"타이마실(동탄병점점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":33526,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/saichouking/","foodTypes":"바/주점","latitude":37.3092932367297,"longitude":126.831313125306,"bookYear":null,"nameKR":"사이쵸쇼우지","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":33525,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/saichouking/","foodTypes":"바/주점","latitude":37.2945556992494,"longitude":126.862736667891,"bookYear":null,"nameKR":"사이쵸","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":35935,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.2374416773284,"longitude":129.085556700558,"bookYear":null,"nameKR":"버들로스터리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":34411,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yukdwae_seomyeon/","foodTypes":"한식(육류)","latitude":35.1570634209043,"longitude":129.06172565989,"bookYear":null,"nameKR":"육돼","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34409,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seagulltown_geoje/","foodTypes":"한식(육류)","latitude":34.8676032174099,"longitude":128.635802895177,"bookYear":null,"nameKR":"갈매기타운(거제점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":34408,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seagulltown_gwangalli/","foodTypes":"한식(육류)","latitude":35.155479457306,"longitude":129.131108503355,"bookYear":null,"nameKR":"갈매기타운(광안본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":34396,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.1471396465415,"longitude":128.707859570163,"bookYear":null,"nameKR":"석이네꼬막짬뽕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":34395,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.6312056169285,"longitude":127.506284932117,"bookYear":null,"nameKR":"고향축산물불고기","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35472,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5442101932411,"longitude":127.323164528344,"bookYear":null,"nameKR":"명물숯불닭갈비(양수점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":34394,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/obootada/","foodTypes":"이탈리아식","latitude":37.6472318780324,"longitude":126.676948369746,"bookYear":null,"nameKR":"오붓하다","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":34393,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wonderful_cheetah_/","foodTypes":"디저트/차/베이커리","latitude":37.2740918013599,"longitude":127.015851637801,"bookYear":null,"nameKR":"원더풀치타","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34391,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dining_chei/","foodTypes":"이탈리아식","latitude":37.6620866553614,"longitude":126.888858448259,"bookYear":null,"nameKR":"다이닝채이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":35587,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.3181875246317,"longitude":126.951937173477,"bookYear":null,"nameKR":"도깨비반점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":35476,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tttt.co.kr","foodTypes":"이탈리아식","latitude":35.1541324117909,"longitude":126.922476423543,"bookYear":null,"nameKR":"틸트틸트","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":33508,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wild_brother_/","foodTypes":"한식(어패류)","latitude":36.3657099490368,"longitude":127.426386043193,"bookYear":null,"nameKR":"형제횟집","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":34379,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/alze_roasters.official/","foodTypes":"디저트/차/베이커리","latitude":35.1427828718894,"longitude":129.110181650757,"bookYear":null,"nameKR":"알제로스터즈","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35480,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/acordar_choco/","foodTypes":"디저트/차/베이커리","latitude":35.1463660297692,"longitude":126.874416602342,"bookYear":null,"nameKR":"아코다초코","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":34378,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wokster_3.12/","foodTypes":"중식","latitude":36.6385435549108,"longitude":127.487254581916,"bookYear":null,"nameKR":"웍스터","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35481,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeungpo_no.1","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.290308950549,"longitude":127.451335410884,"bookYear":null,"nameKR":"증포설렁탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":34377,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jonggodhouse/","foodTypes":"한식(육류)","latitude":35.1841157562023,"longitude":129.07326331985,"bookYear":null,"nameKR":"종갓집양곱창","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":35482,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5662871417766,"longitude":127.189231111369,"bookYear":null,"nameKR":"미려돈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":33506,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.7255254171252,"longitude":126.796369303,"bookYear":null,"nameKR":"만두요법","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35483,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sole_gelato_official/","foodTypes":"디저트/차/베이커리","latitude":35.1579124752114,"longitude":129.063552264986,"bookYear":null,"nameKR":"쏠레젤라또","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33503,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/relic_coffeebrewers/","foodTypes":"디저트/차/베이커리","latitude":35.1569914276608,"longitude":128.704145377982,"bookYear":null,"nameKR":"레릭커피브루어스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":35486,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yoshi.ramen/","foodTypes":"일식","latitude":35.1699443801953,"longitude":129.162419269148,"bookYear":null,"nameKR":"요시라멘","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":35489,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/alohaoe_yangpyeong/","foodTypes":"이탈리아식","latitude":37.5480385921797,"longitude":127.322314916075,"bookYear":null,"nameKR":"알로하오에","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":33492,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":36.4269612003763,"longitude":127.389085518562,"bookYear":null,"nameKR":"인디(테크노밸리점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":33490,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/san_geuneul/","foodTypes":"한식(가금류)","latitude":35.5035038109951,"longitude":128.802325299381,"bookYear":null,"nameKR":"산그늘","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":33484,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.6462966453661,"longitude":126.790206732514,"bookYear":null,"nameKR":"호우04","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":34373,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woojeok_yulha/","foodTypes":"한식(육류)","latitude":35.1735723665939,"longitude":128.826377387132,"bookYear":null,"nameKR":"우적(율하점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":34372,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woojeok_mj/","foodTypes":"한식(육류)","latitude":35.0951390804414,"longitude":128.900532029694,"bookYear":null,"nameKR":"우적(명지점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":34371,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woo_jeok2021/","foodTypes":"한식(육류)","latitude":35.106884201263,"longitude":128.96474619181,"bookYear":null,"nameKR":"우적","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":34364,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/collecting_gamsam/","foodTypes":"디저트/차/베이커리","latitude":35.8552414674597,"longitude":128.549121810868,"bookYear":null,"nameKR":"컬렉팅더모먼츠(감삼점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":34362,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gallerycafebom_official/","foodTypes":"이탈리아식","latitude":37.4159172946519,"longitude":126.982713712294,"bookYear":null,"nameKR":"갤러리카페봄","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":34359,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/shokumi_21/","foodTypes":"일식","latitude":37.5079019703412,"longitude":126.724143810209,"bookYear":null,"nameKR":"쇼쿠미","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":34356,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nuru102/","foodTypes":"한식(일반한식)","latitude":37.3838443552957,"longitude":126.959221107035,"bookYear":null,"nameKR":"누루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":34355,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_okki/","foodTypes":"디저트/차/베이커리","latitude":37.3921937000392,"longitude":126.948619359043,"bookYear":null,"nameKR":"오키","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35494,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffee_tt_roasters/","foodTypes":"디저트/차/베이커리","latitude":36.289212547739,"longitude":127.241707410904,"bookYear":null,"nameKR":"커피토트로스터즈","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"계룡시","status":"ACTIVE"},
        {"id":34351,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sheepcoffee_official/","foodTypes":"디저트/차/베이커리","latitude":35.1615970175374,"longitude":128.98552154247,"bookYear":null,"nameKR":"쉽커피(사상점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":34350,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sheepcoffee_official/","foodTypes":"디저트/차/베이커리","latitude":35.1601966007822,"longitude":129.064670657225,"bookYear":null,"nameKR":"쉽커피","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34343,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/fruits_forest_jangyoo/","foodTypes":"디저트/차/베이커리","latitude":35.1753785558561,"longitude":128.801682767999,"bookYear":null,"nameKR":"과일의숲","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":36055,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":34.7939126301661,"longitude":126.379299638067,"bookYear":null,"nameKR":"비스트로로지","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":34333,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dol_ha_ru_bang/","foodTypes":"한식(육류)","latitude":37.8169010511842,"longitude":127.096652824038,"bookYear":null,"nameKR":"돌하르방식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":35502,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.3940333895461,"longitude":126.961353808735,"bookYear":null,"nameKR":"해조","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35503,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yangweoldaeg/","foodTypes":"디저트/차/베이커리","latitude":35.8356615654026,"longitude":129.210314104992,"bookYear":null,"nameKR":"양월댁","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":34329,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/andong_gh_galbi/","foodTypes":"한식(육류)","latitude":36.5622178868405,"longitude":128.70288656839,"bookYear":null,"nameKR":"고향갈비","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":35505,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/now_here_official/","foodTypes":"디저트/차/베이커리","latitude":37.486086132523,"longitude":126.537082119708,"bookYear":null,"nameKR":"베이커리카페지금이곳","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":35506,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.231711764724,"longitude":128.853120254208,"bookYear":null,"nameKR":"가야왕족본점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":34319,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jeju.upig/","foodTypes":"한식(육류)","latitude":37.5326373299899,"longitude":126.646603136462,"bookYear":null,"nameKR":"시골한우시골돼지(청라점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":34313,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/halo_roasters/","foodTypes":"디저트/차/베이커리","latitude":35.0234718926304,"longitude":126.787307042824,"bookYear":null,"nameKR":"헤일로로스터스2nd","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":34290,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goldhands_cake/","foodTypes":"디저트/차/베이커리","latitude":37.682877149161,"longitude":126.790877250722,"bookYear":null,"nameKR":"골드핸즈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":33479,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moonstone_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.4622668863001,"longitude":126.721014620957,"bookYear":null,"nameKR":"문스톤","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":33478,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.5597496668865,"longitude":128.700704474046,"bookYear":null,"nameKR":"노룰즈커피","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":33477,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.2013530057444,"longitude":129.004689179562,"bookYear":null,"nameKR":"웁스차이나","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":33448,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1545054848614,"longitude":129.144499481748,"bookYear":null,"nameKR":"못난이식당(해운대점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":33442,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/p/CKyBw-IHhfz/","foodTypes":"한식(민물어패류)","latitude":36.013224087573,"longitude":129.371620940254,"bookYear":null,"nameKR":"장룡민물장어나라","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":33440,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sinsungdang_/","foodTypes":"한식(일반한식)","latitude":36.7382656562513,"longitude":127.444826891732,"bookYear":null,"nameKR":"신성당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33439,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.4061178572279,"longitude":126.972137220461,"bookYear":null,"nameKR":"아티스츠커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":34286,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/a_money.amd/","foodTypes":"한식(육류)","latitude":36.5178854976616,"longitude":126.34603813865,"bookYear":null,"nameKR":"아부지돈굴러가유","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":33427,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/handnoodle_official/","foodTypes":"한식(면류)","latitude":37.4955174649556,"longitude":126.727115040356,"bookYear":null,"nameKR":"홍두깨칼국수2900 ","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":36077,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/getbaepizza","foodTypes":"미국식","latitude":38.2020785505077,"longitude":128.591222953079,"bookYear":null,"nameKR":"갯배피자","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"속초시","status":"ACTIVE"},
        {"id":34277,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.0823245644287,"longitude":126.709356622869,"bookYear":null,"nameKR":"국가대표해물칼국수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서천군","status":"ACTIVE"},
        {"id":33409,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1140544595347,"longitude":129.110668253174,"bookYear":null,"nameKR":"홍이집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":34267,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.6509965297031,"longitude":126.648703264453,"bookYear":null,"nameKR":"콩심팥심","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":34261,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/janggunjib_/","foodTypes":"한식(가금류)","latitude":36.3069033766047,"longitude":127.348898896056,"bookYear":null,"nameKR":"장군집","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":34260,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/obliviate_vino/","foodTypes":"바/주점","latitude":35.5345852764145,"longitude":129.323650923206,"bookYear":null,"nameKR":"오블리비아테","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":34256,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.688972306808,"longitude":126.902389094203,"bookYear":null,"nameKR":"장어명가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":34252,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0959339898496,"longitude":128.923324015572,"bookYear":null,"nameKR":"쭈꾸미가알고싶다","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":34250,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/unnamed__coffee/","foodTypes":"디저트/차/베이커리","latitude":35.1533282853577,"longitude":129.0663576484,"bookYear":null,"nameKR":"언네임드커피","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34247,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yeonhodu/","foodTypes":"디저트/차/베이커리","latitude":37.7301780020126,"longitude":127.059432374304,"bookYear":null,"nameKR":"연호두","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":34245,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2825327322374,"longitude":127.033276256704,"bookYear":null,"nameKR":"육콩","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34244,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cheongsong_samgyetang/","foodTypes":"한식(가금류)","latitude":35.869509433559,"longitude":128.555546181012,"bookYear":null,"nameKR":"청송약수삼계탕","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":34235,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eggbreak.incheon/","foodTypes":"기타","latitude":37.4949047816518,"longitude":126.722166896576,"bookYear":null,"nameKR":"에그브레이크","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":34227,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.5683341786043,"longitude":127.510054109201,"bookYear":null,"nameKR":"행복모음카페","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":34222,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/webscoffee/","foodTypes":"디저트/차/베이커리","latitude":37.3132144190534,"longitude":126.83211771781,"bookYear":null,"nameKR":"웹스커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":34221,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_leaping/","foodTypes":"기타","latitude":37.2487062491326,"longitude":127.042615088112,"bookYear":null,"nameKR":"카페리핑","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":34216,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yummy_katsu/","foodTypes":"일식","latitude":37.4138277453544,"longitude":127.099316624097,"bookYear":null,"nameKR":"야미카츠(판교본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":34215,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/osteriamills/","foodTypes":"이탈리아식","latitude":35.8364582732606,"longitude":129.211669941415,"bookYear":null,"nameKR":"오스테리아밀즈","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":34197,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/stateless_jeonpo/","foodTypes":"바/주점","latitude":35.15487220509,"longitude":129.063759762022,"bookYear":null,"nameKR":"무국적신사","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33396,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chwihyang_patisserie/","foodTypes":"디저트/차/베이커리","latitude":37.2537110951157,"longitude":127.111930477077,"bookYear":null,"nameKR":"양과자점취향","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":34193,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/duriwon/","foodTypes":"한식(육류)","latitude":34.8890419418867,"longitude":128.596708805174,"bookYear":null,"nameKR":"두리원","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":33395,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":35.2284331858253,"longitude":129.087818745151,"bookYear":null,"nameKR":"포맨티코","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":36069,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.4503698674608,"longitude":126.800361068951,"bookYear":null,"nameKR":"청양우거디","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"청양군","status":"ACTIVE"},
        {"id":33376,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mannyeondong_joseon/","foodTypes":"바/주점","latitude":36.3656452165655,"longitude":127.376337272922,"bookYear":null,"nameKR":"조선","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":33356,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/misa_kodari/","foodTypes":"한식(일반한식)","latitude":37.5654096848705,"longitude":127.191397352591,"bookYear":null,"nameKR":"코다리와능이버섯미사","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":33350,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/zanzan_suwon/","foodTypes":"바/주점","latitude":37.2691552965662,"longitude":127.005583168849,"bookYear":null,"nameKR":"잔잔(수원역점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33348,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/imagine_donuts_","foodTypes":"디저트/차/베이커리","latitude":37.3954205092827,"longitude":127.113554546191,"bookYear":null,"nameKR":"이매진도넛","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":33346,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.0584934849315,"longitude":127.073881552337,"bookYear":null,"nameKR":"김가네전주콩나물국밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":33345,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.0584934849315,"longitude":127.073881552337,"bookYear":null,"nameKR":"삼백반점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":36062,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chefhyeok/","foodTypes":"기타","latitude":35.2549042841246,"longitude":128.621709769456,"bookYear":null,"nameKR":"셰프혁","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":33343,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.8574907308422,"longitude":128.624149258772,"bookYear":null,"nameKR":"오호리준(범어본사직영점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":36058,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eternity_rin_cake/","foodTypes":"디저트/차/베이커리","latitude":37.4154506290774,"longitude":127.250370389911,"bookYear":null,"nameKR":"이터너티 린 케이크제작소","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":36057,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.3958260426713,"longitude":127.407218769091,"bookYear":null,"nameKR":"앤디팬디","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":35525,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/patisserie_motie/","foodTypes":"디저트/차/베이커리","latitude":35.1548261795711,"longitude":129.14324343864,"bookYear":null,"nameKR":"모티에과자점","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":33340,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1997184541055,"longitude":129.060258589211,"bookYear":null,"nameKR":"소우데스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":33339,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thefoxhotdog/","foodTypes":"기타","latitude":35.8711424769591,"longitude":129.219245091527,"bookYear":null,"nameKR":"이쁜여우핫도그","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":33333,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.2232045727679,"longitude":128.685873612955,"bookYear":null,"nameKR":"영선","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":33326,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/blue_.hill/","foodTypes":"이탈리아식","latitude":37.4561645369252,"longitude":127.224691974981,"bookYear":null,"nameKR":"블루힐","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":33318,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.1771686784346,"longitude":128.813403243237,"bookYear":null,"nameKR":"원투원","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":33316,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sohyang_mong/","foodTypes":"한식(일반한식)","latitude":35.8378369662636,"longitude":129.209368492299,"bookYear":null,"nameKR":"소향몽","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":33314,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3202646161296,"longitude":127.109862171061,"bookYear":null,"nameKR":"젤라떼리아 칠렉싱","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":35537,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.2653980945439,"longitude":127.035745453786,"bookYear":null,"nameKR":"체리커피(수원점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33286,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.9757684908071,"longitude":126.386475809274,"bookYear":null,"nameKR":"갤러리휴","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":33267,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.477835516504,"longitude":127.1860224934,"bookYear":null,"nameKR":"여기로와","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":33261,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_honestofficial/","foodTypes":"디저트/차/베이커리","latitude":37.2149315940082,"longitude":127.100158159356,"bookYear":null,"nameKR":"어니스트 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":35549,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwangnam_dukkeobi/","foodTypes":"한식(일반한식)","latitude":35.8344918316348,"longitude":129.210916126673,"bookYear":null,"nameKR":"황남두꺼비식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":33255,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hanaki8488","foodTypes":"바/주점","latitude":37.6613188072903,"longitude":126.767301436388,"bookYear":null,"nameKR":"하나기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35552,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/muyangdowon","foodTypes":"한식(육류)","latitude":36.357466870495,"longitude":127.344056885719,"bookYear":null,"nameKR":"무양도원","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":35556,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6469771187284,"longitude":126.89477806511,"bookYear":null,"nameKR":"비와별닭갈비(스타필드고양점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":33252,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sesang_sera/","foodTypes":"디저트/차/베이커리","latitude":36.969670276507,"longitude":127.936073323493,"bookYear":null,"nameKR":"세상상회","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":33244,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/opo_jinddukdak/","foodTypes":"한식(가금류)","latitude":37.3880868044223,"longitude":127.255413206995,"bookYear":null,"nameKR":"오포진뚝닭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":33243,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tjhamburger","foodTypes":"기타","latitude":35.8678259613333,"longitude":128.636744395586,"bookYear":null,"nameKR":"티제이버거","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":33242,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/saekongcoffee/","foodTypes":"디저트/차/베이커리","latitude":35.1592115000331,"longitude":128.696100793788,"bookYear":null,"nameKR":"새콩커피","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":33239,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafeoddo","foodTypes":"디저트/차/베이커리","latitude":37.4520149469249,"longitude":126.64506286268,"bookYear":null,"nameKR":"카페오디디오","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":33235,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.394244787236,"longitude":127.112210932491,"bookYear":null,"nameKR":"일도씨닭갈비(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":36029,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dongshim_udon","foodTypes":"일식","latitude":37.500794309197,"longitude":126.774636725313,"bookYear":null,"nameKR":"동심","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":36046,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.6374460638662,"longitude":127.488554564349,"bookYear":null,"nameKR":"아베크케이크","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33226,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.8515798984946,"longitude":129.262977206576,"bookYear":null,"nameKR":"낙지마실","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":33225,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.8405681021727,"longitude":129.232032319765,"bookYear":null,"nameKR":"기와메밀막국수","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":33218,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/parks_table30/","foodTypes":"이탈리아식","latitude":37.5008379979602,"longitude":126.774348325924,"bookYear":null,"nameKR":"팍스테이블","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":36049,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hanbab1006/","foodTypes":"한식(육류)","latitude":35.2080349814329,"longitude":129.072575910838,"bookYear":null,"nameKR":"한우야밥묵자","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":33216,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daom2016","foodTypes":"한식(면류)","latitude":35.153006221653,"longitude":129.059606320437,"bookYear":null,"nameKR":"다옴(서면심정타워점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33215,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daom2016","foodTypes":"한식(면류)","latitude":35.1473379314718,"longitude":129.110916206941,"bookYear":null,"nameKR":"다옴","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":36039,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/loving_julia_","foodTypes":"유럽식","latitude":36.1256348615021,"longitude":128.334772854999,"bookYear":null,"nameKR":"러빙줄리아","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":35572,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/threeoclock__/","foodTypes":"디저트/차/베이커리","latitude":37.6542678348328,"longitude":126.68481909007,"bookYear":null,"nameKR":"쓰리어클락","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":35575,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mipojip/","foodTypes":"한식(일반한식)","latitude":35.1727110253227,"longitude":129.197664960772,"bookYear":null,"nameKR":"미포집(송정직영점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":36036,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.383526936654,"longitude":127.121780037862,"bookYear":null,"nameKR":"에도마에텐동하마다(서현점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":33196,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.3579918085345,"longitude":127.346736709729,"bookYear":null,"nameKR":"금강삼겹살","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":33195,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.9089034693516,"longitude":127.056316619282,"bookYear":null,"nameKR":"일미담","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":33192,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/b.o.n.g.b.o.n.g/","foodTypes":"디저트/차/베이커리","latitude":37.3721599902148,"longitude":127.112863090015,"bookYear":null,"nameKR":"봉봉","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35588,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hannamdongsuji/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1562599943634,"longitude":129.059720837606,"bookYear":null,"nameKR":"한남동스지","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33178,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rollingbrewing_company/","foodTypes":"디저트/차/베이커리","latitude":35.392436586163,"longitude":129.055690438304,"bookYear":null,"nameKR":"롤링브루잉컴퍼니","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":33172,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/flower.garam7/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1585567897461,"longitude":129.060795964543,"bookYear":null,"nameKR":"꽃가람(서면직영점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":33159,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/elder_franc/","foodTypes":"디저트/차/베이커리","latitude":35.1996302065622,"longitude":128.114004489697,"bookYear":null,"nameKR":"엘더프랑 ","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":33157,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dottgan/","foodTypes":"한식(육류)","latitude":35.1048967171145,"longitude":128.924539994878,"bookYear":null,"nameKR":"돗간","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":33153,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/acinejokbal/","foodTypes":"한식(육류)","latitude":37.6470060438559,"longitude":127.123181600391,"bookYear":null,"nameKR":"아씨네족발","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":35592,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.440485839406,"longitude":127.173565779809,"bookYear":null,"nameKR":"성호커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":33150,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.1073011858641,"longitude":128.419566731527,"bookYear":null,"nameKR":"꽃돼지식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":33148,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/byeokdollzip","foodTypes":"디저트/차/베이커리","latitude":35.8611794272534,"longitude":128.604714887354,"bookYear":null,"nameKR":"벽돌집","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":35597,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nangki__gogi/","foodTypes":"한식(육류)","latitude":35.3286109446205,"longitude":129.01063874887,"bookYear":null,"nameKR":"낭기뒷고기(양산본점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":33127,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/63_jung/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1751629116698,"longitude":129.124506074509,"bookYear":null,"nameKR":"육삼정","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":33120,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/crescendocoffee/","foodTypes":"디저트/차/베이커리","latitude":37.4051254914238,"longitude":127.126936671291,"bookYear":null,"nameKR":"크레센도커피로스터스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":29726,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jam_8_bread","foodTypes":"디저트/차/베이커리","latitude":37.39109195476756,"longitude":127.09170026863768,"bookYear":null,"nameKR":"잼앤브레드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":35496,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5004465088003,"longitude":126.721598669953,"bookYear":null,"nameKR":"리워크커피","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":35600,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.5551254714423,"longitude":128.754818517738,"bookYear":null,"nameKR":"한뚝배기","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":35605,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lemans_roasters/","foodTypes":"디저트/차/베이커리","latitude":35.1679291641216,"longitude":128.059942914915,"bookYear":null,"nameKR":"르망로스터스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":33106,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/leecoffee_paragon","foodTypes":"디저트/차/베이커리","latitude":37.2039744437243,"longitude":127.072192727906,"bookYear":null,"nameKR":"리커피(동탄파라곤점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":33101,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/27.leanonme","foodTypes":"디저트/차/베이커리","latitude":37.5610945158436,"longitude":127.190693867516,"bookYear":null,"nameKR":"내게기대","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":35613,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/teddy_heyday/","foodTypes":"미국식","latitude":36.6361919793691,"longitude":127.490196114249,"bookYear":null,"nameKR":"테디헤이데이","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":33094,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/100se_/","foodTypes":"한식(가금류)","latitude":37.3180439518529,"longitude":126.988926840812,"bookYear":null,"nameKR":"백세삼계탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33079,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bojungdang/","foodTypes":"디저트/차/베이커리","latitude":35.8719510097076,"longitude":128.712651026751,"bookYear":null,"nameKR":"보정당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":33078,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/datetreehouse/","foodTypes":"한식(일반한식)","latitude":37.6621170851788,"longitude":126.768038454559,"bookYear":null,"nameKR":"일영대추나무집(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35615,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafeopenhaus_official/","foodTypes":"디저트/차/베이커리","latitude":35.2051178935207,"longitude":128.11486963741,"bookYear":null,"nameKR":"카페오픈하우스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":33077,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/camellia_dabang/","foodTypes":"디저트/차/베이커리","latitude":37.2763945486179,"longitude":127.152646661699,"bookYear":null,"nameKR":"동백다방","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":35618,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/khansteakhouse_/","foodTypes":"기타","latitude":36.3440089812486,"longitude":127.341006173134,"bookYear":null,"nameKR":"칸스테이크하우스(도안본점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":33072,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/samchon1982/","foodTypes":"한식(면류)","latitude":35.863951683171,"longitude":128.628877147817,"bookYear":null,"nameKR":"삼촌1982","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":35622,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chefkwinebistro/","foodTypes":"이탈리아식","latitude":37.817349451935,"longitude":127.096988559576,"bookYear":null,"nameKR":"쉐프케이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":33071,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.2193735399891,"longitude":128.691592809094,"bookYear":null,"nameKR":"성원낙지","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":35624,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/andsensecoffee/","foodTypes":"디저트/차/베이커리","latitude":37.4993009160948,"longitude":126.785191225614,"bookYear":null,"nameKR":"앤센스 에스프레소 커피바 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":35633,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.7427088820062,"longitude":127.048153675545,"bookYear":null,"nameKR":"뇌프","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":36014,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.1237104491106,"longitude":126.907462351856,"bookYear":null,"nameKR":"정초밥","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":33053,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mongs_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.3643948114297,"longitude":127.10769641858,"bookYear":null,"nameKR":"몽스베이커리(정자역점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":33052,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mongs_bakery/","foodTypes":"디저트/차/베이커리","latitude":37.3587618141043,"longitude":127.124113257481,"bookYear":null,"nameKR":"몽스베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35634,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.6212810351291,"longitude":127.148816998809,"bookYear":null,"nameKR":"도도한면(다산직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":36031,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yoi_kuma/","foodTypes":"일식","latitude":35.1579690940204,"longitude":129.126595463726,"bookYear":null,"nameKR":"요이쿠마(본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":33050,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4566195502577,"longitude":126.635852164069,"bookYear":null,"nameKR":"조선갈비실록(인천점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":35925,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.4719532023158,"longitude":126.631450359002,"bookYear":null,"nameKR":"화양식","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":35640,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dongrami__cookie/","foodTypes":"디저트/차/베이커리","latitude":35.8722016651612,"longitude":128.596125839953,"bookYear":null,"nameKR":"동그라미쿠키","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":35646,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_etalee","foodTypes":"디저트/차/베이커리","latitude":35.1956148781754,"longitude":129.077782130354,"bookYear":null,"nameKR":"에타리(교대본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":35647,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_etalee/","foodTypes":"디저트/차/베이커리","latitude":35.228990181781,"longitude":129.087427214933,"bookYear":null,"nameKR":"에타리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":33038,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.7998232068315,"longitude":127.107563513517,"bookYear":null,"nameKR":"현대옥(불당동카페거리점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":33032,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.3492100132403,"longitude":126.557474839946,"bookYear":null,"nameKR":"장벌집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":33033,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/owa_cake_byeollae/","foodTypes":"디저트/차/베이커리","latitude":37.6573799092556,"longitude":127.127130193073,"bookYear":null,"nameKR":"오와케이크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":33027,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ever_knock/","foodTypes":"디저트/차/베이커리","latitude":37.8964703985722,"longitude":127.056772947544,"bookYear":null,"nameKR":"에버노크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"동두천시","status":"ACTIVE"},
        {"id":33024,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/curryhyun/","foodTypes":"일식","latitude":37.7437567584744,"longitude":127.099659951417,"bookYear":null,"nameKR":"카레현","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":33014,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dainsgrandpa/","foodTypes":"디저트/차/베이커리","latitude":35.1780213104314,"longitude":128.089905044613,"bookYear":null,"nameKR":"다인스그랜파","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":36008,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.5686269351169,"longitude":127.183581448395,"bookYear":null,"nameKR":"로스트피그(하남점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":33004,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ondonne.2021","foodTypes":"한식(육류)","latitude":37.7449816431985,"longitude":127.097374440948,"bookYear":null,"nameKR":"온돈네","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":32998,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ityristorante/","foodTypes":"이탈리아식","latitude":35.1767994994123,"longitude":128.812063857584,"bookYear":null,"nameKR":"이태리식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":36013,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.755685249519,"longitude":126.908733197295,"bookYear":null,"nameKR":"효자관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":36012,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/pho2nam","foodTypes":"아시아식","latitude":37.7514469136246,"longitude":127.118059854934,"bookYear":null,"nameKR":"포이남","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":32983,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/deep_cafe_","foodTypes":"디저트/차/베이커리","latitude":36.3888365787229,"longitude":127.347921782475,"bookYear":null,"nameKR":"디프카페","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":32969,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/forkner_bundang/","foodTypes":"이탈리아식","latitude":37.3517203372396,"longitude":127.105689710757,"bookYear":null,"nameKR":"포크너(분당점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":36006,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/momendor_/","foodTypes":"디저트/차/베이커리","latitude":37.3953102739007,"longitude":127.871200929547,"bookYear":null,"nameKR":"모멘도르","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"원주시","status":"ACTIVE"},
        {"id":32968,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.demure/","foodTypes":"디저트/차/베이커리","latitude":37.4800263276287,"longitude":126.853272423386,"bookYear":null,"nameKR":"데뮤어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":32961,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/agingminam/","foodTypes":"한식(육류)","latitude":35.1765866865981,"longitude":129.126489620138,"bookYear":null,"nameKR":"우미남본인미트","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":32959,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seokkeut/","foodTypes":"바/주점","latitude":35.0485885803432,"longitude":128.965360439629,"bookYear":null,"nameKR":"서끝","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":32952,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3970245375177,"longitude":126.974376581417,"bookYear":null,"nameKR":"강남면옥(평촌점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35874,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/101719_/","foodTypes":"바/주점","latitude":35.138704405758,"longitude":129.105553169167,"bookYear":null,"nameKR":"단독","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":35785,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/merclmercl","foodTypes":"디저트/차/베이커리","latitude":35.2169496308696,"longitude":128.682430261332,"bookYear":null,"nameKR":"메르시메르시","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":35998,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7348575779275,"longitude":127.418058058644,"bookYear":null,"nameKR":"양가네쪽갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":32944,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/themoog_bakerycafe/","foodTypes":"디저트/차/베이커리","latitude":35.6846339164762,"longitude":127.910900203818,"bookYear":null,"nameKR":"더무그","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거창군","status":"ACTIVE"},
        {"id":35990,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/cafe_sobi","foodTypes":"디저트/차/베이커리","latitude":37.6697214344469,"longitude":126.785607856724,"bookYear":null,"nameKR":"카페소비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":32923,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ko__jjang","foodTypes":"바/주점","latitude":37.6891391428715,"longitude":126.762622697116,"bookYear":null,"nameKR":"마츠노키","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35987,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.5896552837641,"longitude":126.51981761668,"bookYear":null,"nameKR":"꽃새담","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":32920,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gimpook/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6540272635513,"longitude":126.592670212742,"bookYear":null,"nameKR":"김포옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":35978,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.1595056027066,"longitude":129.126715793768,"bookYear":null,"nameKR":"토리이에(광안점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35976,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.2083954095462,"longitude":127.061223186784,"bookYear":null,"nameKR":"호무라텐동","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":35983,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/korn_mj/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.9706049024036,"longitude":129.409279179232,"bookYear":null,"nameKR":"부산본가돼지국밥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":35979,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gukbap_busan/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.095694521066,"longitude":128.962482014543,"bookYear":null,"nameKR":"정짓간돼지국밥&막국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":35974,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ms_blacknote/","foodTypes":"디저트/차/베이커리","latitude":36.3905673337644,"longitude":127.348674499696,"bookYear":null,"nameKR":"블랙노트커피로스터스","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":35973,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/toietmoi__cafe/","foodTypes":"디저트/차/베이커리","latitude":35.1521633925329,"longitude":129.067791553404,"bookYear":null,"nameKR":"트와엣모아","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":32909,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/blaec10/","foodTypes":"디저트/차/베이커리","latitude":37.6458151519928,"longitude":126.700891222041,"bookYear":null,"nameKR":"블랙텐","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":32908,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oh_kunsan/","foodTypes":"한식(어패류)","latitude":37.3435885895842,"longitude":127.127667689603,"bookYear":null,"nameKR":"오창은군산집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32905,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwajeong_dabang/","foodTypes":"디저트/차/베이커리","latitude":37.3576725293271,"longitude":126.822801776688,"bookYear":null,"nameKR":"화정다방","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":32852,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dokkeb_coffee/","foodTypes":"디저트/차/베이커리","latitude":35.697960188162,"longitude":128.48824930654,"bookYear":null,"nameKR":"도케비커피","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":32901,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/anc_dessert/","foodTypes":"디저트/차/베이커리","latitude":36.3591447687972,"longitude":127.376885115414,"bookYear":null,"nameKR":"앤크.","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":32902,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bubu_dang/","foodTypes":"디저트/차/베이커리","latitude":35.9674167033537,"longitude":129.404895673531,"bookYear":null,"nameKR":"부부당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":32897,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/italyguksi/","foodTypes":"이탈리아식","latitude":36.3531408752558,"longitude":127.377256571064,"bookYear":null,"nameKR":"이태리국시","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":32896,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"프랑스식","latitude":36.3437553500898,"longitude":127.341051741505,"bookYear":null,"nameKR":"블루리본","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":32893,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.8060618183517,"longitude":126.768080829294,"bookYear":null,"nameKR":"대들보","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":32892,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4169076436569,"longitude":127.274334505797,"bookYear":null,"nameKR":"마이스터심슨부대찌개","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":32890,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/deia_bakery","foodTypes":"디저트/차/베이커리","latitude":37.5029580733058,"longitude":126.772850689993,"bookYear":null,"nameKR":"데이아","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":32888,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/250_do/","foodTypes":"한식(육류)","latitude":35.1716678672686,"longitude":128.060509646553,"bookYear":null,"nameKR":"고기가가장맛있는온도250도(평거점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":32887,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/250_do/","foodTypes":"한식(육류)","latitude":35.1933357616303,"longitude":128.122258798495,"bookYear":null,"nameKR":"고기가가장맛있는온도250도","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":35962,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/butasense_01/","foodTypes":"일식","latitude":37.248267907971,"longitude":127.079464216528,"bookYear":null,"nameKR":"부타센세","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":32867,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.88393457899,"longitude":129.230682163622,"bookYear":null,"nameKR":"경주여행밀면본점여행라멘","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":32857,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rodam_/","foodTypes":"한식(육류)","latitude":35.287903490462,"longitude":128.99496424137,"bookYear":null,"nameKR":"로담","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":35921,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/joseon_hgbbq","foodTypes":"기타","latitude":37.7409325652165,"longitude":127.199072523072,"bookYear":null,"nameKR":"조선헛간텍사스바베큐","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":35906,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yoohyungsang710321","foodTypes":"기타","latitude":36.6273891070819,"longitude":127.121577777532,"bookYear":null,"nameKR":"향수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":35902,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.6662065620536,"longitude":126.747684861405,"bookYear":null,"nameKR":"현대모터스튜디오고양키친바이해비치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35728,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1897379119281,"longitude":129.077277985473,"bookYear":null,"nameKR":"수매풍생선구이식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":35884,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hongsi_pet_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.6492093553475,"longitude":127.652234653488,"bookYear":null,"nameKR":"홍시","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":35825,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":34.9434980125917,"longitude":127.516202148449,"bookYear":null,"nameKR":"순천갈대장어(연향점)","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":35704,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.9001241093768,"longitude":126.631047131011,"bookYear":null,"nameKR":"더뿌리","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":35784,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.4691653602235,"longitude":126.819544091752,"bookYear":null,"nameKR":"보꼬네","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":35877,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.5033178949467,"longitude":126.760958856665,"bookYear":null,"nameKR":"신림춘천집(부천중동현대점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":35878,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.2691795981586,"longitude":127.006119829681,"bookYear":null,"nameKR":"신림춘천집(수원역직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35879,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.2638843015003,"longitude":127.03310232868,"bookYear":null,"nameKR":"신림춘천집(나혜석거리점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35873,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/5nadam/","foodTypes":"한식(육류)","latitude":37.2873183115697,"longitude":127.451696904823,"bookYear":null,"nameKR":"온아담","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":35863,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.4557984081987,"longitude":127.284614468576,"bookYear":null,"nameKR":"발산커피","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":35871,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":36.4790541217474,"longitude":127.284606092541,"bookYear":null,"nameKR":"진웨이성&진향","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":35868,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lounge__b","foodTypes":"디저트/차/베이커리","latitude":35.0957525907141,"longitude":129.067550597483,"bookYear":null,"nameKR":"라운지비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":35850,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.453291550103,"longitude":126.63569425028,"bookYear":null,"nameKR":"동이집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":35860,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mi.mi.dessert/","foodTypes":"디저트/차/베이커리","latitude":35.3261866205377,"longitude":129.017288049877,"bookYear":null,"nameKR":"미미작업실","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":35837,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/izakaya_peacock","foodTypes":"바/주점","latitude":37.6970703898358,"longitude":127.900379632699,"bookYear":null,"nameKR":"이자카야공작","ribbonType":"정보없음","zone2_1":"강원","zone2_2":"홍천군","status":"ACTIVE"},
        {"id":35835,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/table_de_pasta","foodTypes":"이탈리아식","latitude":37.5595579847945,"longitude":127.201526539898,"bookYear":null,"nameKR":"테이블드파스타","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":35828,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.4814871764797,"longitude":127.119952204133,"bookYear":null,"nameKR":"공주쌍신집칼국수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":35826,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":34.9331430159096,"longitude":127.554425214913,"bookYear":null,"nameKR":"순천갈대장어(신대점)","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":35466,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/palddaksan/","foodTypes":"바/주점","latitude":37.2867564857414,"longitude":127.012590512988,"bookYear":null,"nameKR":"팔딱산","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":32042,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.347556670052,"longitude":127.11178229293,"bookYear":null,"nameKR":"베이키베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":25812,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lee_heung_yong/","foodTypes":"디저트/차/베이커리","latitude":35.137134,"longitude":129.072477,"bookYear":null,"nameKR":"이흥용과자점(문현점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":31479,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.2302824273626,"longitude":129.083929819482,"bookYear":null,"nameKR":"이흥용과자점(부산대점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":24032,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lee_heung_yong/","foodTypes":"디저트/차/베이커리","latitude":35.192647,"longitude":129.067487,"bookYear":null,"nameKR":"이흥용과자점(사직점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":35712,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.8572329809436,"longitude":128.601627706415,"bookYear":null,"nameKR":"본다이닝","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":35810,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/multin_kitchen/","foodTypes":"이탈리아식","latitude":35.1620624914999,"longitude":129.117466466889,"bookYear":null,"nameKR":"멀틴","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35697,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.2638843015003,"longitude":127.03310232868,"bookYear":null,"nameKR":"미가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35398,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.5250521514583,"longitude":129.241678635505,"bookYear":null,"nameKR":"크래프츠맨십","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":35380,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"호주식","latitude":36.3084109597206,"longitude":127.350041208974,"bookYear":null,"nameKR":"뉴타운","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":35804,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nh07_01/","foodTypes":"일식","latitude":37.2750009452322,"longitude":126.954079196941,"bookYear":null,"nameKR":"생또오","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35796,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.2855785564655,"longitude":126.958318596276,"bookYear":null,"nameKR":"기와집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35778,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.3910074884474,"longitude":126.954993129278,"bookYear":null,"nameKR":"오루카","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35772,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":36.6351321346299,"longitude":127.459360227231,"bookYear":null,"nameKR":"카페정다운샌드위치","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35781,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.6113272973776,"longitude":127.465679174481,"bookYear":null,"nameKR":"한우짝갈비살","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35775,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":34.9342694303436,"longitude":127.495258697487,"bookYear":null,"nameKR":"족발이땡기는날(수정점)","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":35687,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4942098765959,"longitude":126.539148047497,"bookYear":null,"nameKR":"인사동불고기","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":35731,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/uglyrice.namhae/","foodTypes":"한식(일반한식)","latitude":34.7130372187776,"longitude":128.047578598014,"bookYear":null,"nameKR":"어글리라이스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":35759,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/makperjb/","foodTypes":"한식(어패류)","latitude":37.314278607386,"longitude":126.828769160875,"bookYear":null,"nameKR":"막퍼전복본점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":35756,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.3001292256975,"longitude":127.631757034284,"bookYear":null,"nameKR":"리버52","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":35652,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/principle_coffee","foodTypes":"기타","latitude":37.6524852733616,"longitude":127.112735937018,"bookYear":null,"nameKR":"프린시플커피컴퍼니","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":32846,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":35.077857525133,"longitude":129.064652742881,"bookYear":null,"nameKR":"에르마나","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":32844,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cooksi_ham/","foodTypes":"한식(면류)","latitude":37.5913895943183,"longitude":127.141604867323,"bookYear":null,"nameKR":"국시함","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":35663,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/swirlinglounge","foodTypes":"바/주점","latitude":37.3810935982072,"longitude":126.959160721434,"bookYear":null,"nameKR":"스월링라운지","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":35744,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/elrato_gyeongju","foodTypes":"중남미식","latitude":35.8342780894155,"longitude":129.209320343355,"bookYear":null,"nameKR":"엘라토경주","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":35742,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.4914865412848,"longitude":129.429824529693,"bookYear":null,"nameKR":"코모레비 ","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":35739,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/ruver.coffee","foodTypes":"디저트/차/베이커리","latitude":35.1551382672248,"longitude":129.065686808293,"bookYear":null,"nameKR":"러버커피","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":32841,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_steelzone/","foodTypes":"디저트/차/베이커리","latitude":37.3427920200245,"longitude":127.227618028329,"bookYear":null,"nameKR":"스틸존","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":32840,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":36.5928967467303,"longitude":126.678346432173,"bookYear":null,"nameKR":"내포해물촌","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":32834,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1854404722479,"longitude":126.920704531796,"bookYear":null,"nameKR":"족발집배원 ","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":32831,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4587142536596,"longitude":127.165251340559,"bookYear":null,"nameKR":"산성쌈밥 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":32826,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.3126352042072,"longitude":127.006461437071,"bookYear":null,"nameKR":"덕수궁","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":32823,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/with_marigold","foodTypes":"디저트/차/베이커리","latitude":35.8253809455678,"longitude":128.549960001179,"bookYear":null,"nameKR":"메리골드 ","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":32822,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi_kajitsu","foodTypes":"일식","latitude":37.5030683739057,"longitude":126.771411913047,"bookYear":null,"nameKR":"스시카지츠","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":32819,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6575928102258,"longitude":126.772944560294,"bookYear":null,"nameKR":"피오커피 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":32818,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soma_dining/","foodTypes":"기타","latitude":37.4453221313777,"longitude":126.704915644257,"bookYear":null,"nameKR":"소마다이닝","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":32817,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8765938153168,"longitude":128.6302869419,"bookYear":null,"nameKR":"헤이차일드","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":32813,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_ymmd","foodTypes":"디저트/차/베이커리","latitude":37.2939120040548,"longitude":127.055281450564,"bookYear":null,"nameKR":"와이엠엠디","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35738,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.5379934199104,"longitude":127.230724283606,"bookYear":null,"nameKR":"도도브로스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":32812,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_manmeathouse","foodTypes":"한식(육류)","latitude":35.2630116413114,"longitude":128.870561515927,"bookYear":null,"nameKR":"그남자고깃집(김해점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":35736,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1892332005049,"longitude":129.087241217944,"bookYear":null,"nameKR":"그린애플","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":32811,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_manmeathouse","foodTypes":"한식(육류)","latitude":35.3105255727651,"longitude":129.008573861977,"bookYear":null,"nameKR":"그남자고깃집(증산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":32809,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.6016085061602,"longitude":127.172628053391,"bookYear":null,"nameKR":"유니코알","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":32808,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2551786589583,"longitude":128.61949991377,"bookYear":null,"nameKR":"돈담","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":32806,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bucheonjib","foodTypes":"한식(어패류)","latitude":37.5074757706979,"longitude":126.753039186319,"bookYear":null,"nameKR":"부천집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":35734,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.3088762137566,"longitude":127.051742953769,"bookYear":null,"nameKR":"윤세영식당(광교직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35724,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.5690502743514,"longitude":128.780341012658,"bookYear":null,"nameKR":"커피명가(안동점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":32792,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wolmi_doremi_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.475242573204,"longitude":126.597876411669,"bookYear":null,"nameKR":"월미도레미","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":32791,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":35.825886534886,"longitude":128.62233745299,"bookYear":null,"nameKR":"푸나왈라","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":32784,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/396coffeecompany/","foodTypes":"디저트/차/베이커리","latitude":36.5669631035058,"longitude":128.728989225175,"bookYear":null,"nameKR":"396커피스토어","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":32781,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/windcoffee_official","foodTypes":"디저트/차/베이커리","latitude":37.2758569444677,"longitude":127.151277088807,"bookYear":null,"nameKR":"윈드커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":32778,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bossanova_coffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":35.1960289048182,"longitude":129.212971319364,"bookYear":null,"nameKR":"보사노바커피로스터스(부산롯데월드점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":32771,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/corner546_official/","foodTypes":"이탈리아식","latitude":37.3461036326146,"longitude":127.11009324513,"bookYear":null,"nameKR":"코너546","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35730,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.3131462961154,"longitude":126.828656776725,"bookYear":null,"nameKR":"사계절참맛 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":32752,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.3318733205256,"longitude":127.119721962535,"bookYear":null,"nameKR":"한성옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":32741,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oldhome2013","foodTypes":"한식(일반한식)","latitude":36.3355326565434,"longitude":127.385574668494,"bookYear":null,"nameKR":"전&막걸리전문점옛집","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":35557,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.4801356884837,"longitude":127.148404124838,"bookYear":null,"nameKR":"비와별닭갈비(스타필드위례점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":35559,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":36.9946334108693,"longitude":127.147249620616,"bookYear":null,"nameKR":"비와별닭갈비(스타필드안성점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":35733,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.1752588677792,"longitude":129.104610671364,"bookYear":null,"nameKR":"정움","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":32672,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.7116052375612,"longitude":127.04804020421,"bookYear":null,"nameKR":"랑차이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":32671,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8592707649964,"longitude":128.618025399878,"bookYear":null,"nameKR":"대정옥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":32656,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bros_macaron/","foodTypes":"디저트/차/베이커리","latitude":35.1556582045371,"longitude":129.058140841025,"bookYear":null,"nameKR":"브로쓰마카롱","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":32497,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":37.398144681613,"longitude":126.922636767753,"bookYear":null,"nameKR":"로렌스308","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":32649,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dotom_dotom_dining/","foodTypes":"이탈리아식","latitude":36.3270468592302,"longitude":127.423451186083,"bookYear":null,"nameKR":"도톰도톰","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":35729,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/1show2kitchen/?utm_medium=copy_link","foodTypes":"일식","latitude":37.757914861638,"longitude":126.766909772034,"bookYear":null,"nameKR":"잇쇼니키친(파주점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32642,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.8193302097389,"longitude":127.15784651678,"bookYear":null,"nameKR":"능수정(신세계백화점천안아산점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":32632,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wootender/","foodTypes":"한식(육류)","latitude":37.3680299802465,"longitude":127.105520669444,"bookYear":null,"nameKR":"우텐더(정자점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32631,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/rhomb__","foodTypes":"디저트/차/베이커리","latitude":37.6564583502911,"longitude":127.12724625461,"bookYear":null,"nameKR":"롬브","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":32627,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/IT_WAS_G.O.A.T","foodTypes":"기타","latitude":35.861359299375,"longitude":128.624857536002,"bookYear":null,"nameKR":"잇워즈고트","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":32618,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yoonsicakes","foodTypes":"디저트/차/베이커리","latitude":37.3859226587334,"longitude":127.137021404177,"bookYear":null,"nameKR":"윤시케이크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35714,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.0820103122282,"longitude":129.395923776767,"bookYear":null,"nameKR":"쿠킹빌리지양식당(양덕점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":32609,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3913627249287,"longitude":126.954661026766,"bookYear":null,"nameKR":"삼중대광","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":32580,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.315166111556,"longitude":126.95842500497,"bookYear":null,"nameKR":"정가네숯불갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":32579,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4106453576926,"longitude":127.130214114002,"bookYear":null,"nameKR":"금정황소곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32573,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/apron_project/","foodTypes":"호주식","latitude":37.2938533121895,"longitude":127.054968216746,"bookYear":null,"nameKR":"에이프런","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":32557,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwahwa_ilsan","foodTypes":"일식","latitude":37.6660625103382,"longitude":126.756435827812,"bookYear":null,"nameKR":"화화","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":32556,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.5533195427102,"longitude":127.237863428351,"bookYear":null,"nameKR":"팔당반점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":32523,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hapoomsbake/","foodTypes":"디저트/차/베이커리","latitude":37.2941778402883,"longitude":127.069574239621,"bookYear":null,"nameKR":"하품스베이크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":32470,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/forkner_official/","foodTypes":"이탈리아식","latitude":37.3112892352202,"longitude":126.830827660184,"bookYear":null,"nameKR":"포크너(신도시점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":32461,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jinsot_official/","foodTypes":"한식(육류)","latitude":37.4451276469492,"longitude":126.804319702239,"bookYear":null,"nameKR":"진솥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":35692,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.2073380885598,"longitude":126.861351415748,"bookYear":null,"nameKR":"써지라멘","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":32460,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/comma_annyeong/","foodTypes":"디저트/차/베이커리","latitude":37.370385443751154,"longitude":127.12847432563105,"bookYear":null,"nameKR":",안녕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32405,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1964987924371,"longitude":129.069672543438,"bookYear":null,"nameKR":"이튼","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":35722,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/paninicoffee","foodTypes":"기타","latitude":35.0709472509659,"longitude":129.018354647374,"bookYear":null,"nameKR":"파니니브런치(송도점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":35721,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe686_official/","foodTypes":"기타","latitude":35.3358448313731,"longitude":129.169712340303,"bookYear":null,"nameKR":"카페686","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":32384,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/argylecoffeeclub","foodTypes":"디저트/차/베이커리","latitude":37.4110391243249,"longitude":127.256440165731,"bookYear":null,"nameKR":"아가일커피클럽","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":32369,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.6494781076792,"longitude":129.359302398767,"bookYear":null,"nameKR":"삼정한우축산","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":32361,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bientoi_official/","foodTypes":"기타","latitude":37.3973207561987,"longitude":127.11356226927,"bookYear":null,"nameKR":"비앙또아","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":32360,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/6block_official/","foodTypes":"한식(육류)","latitude":37.3697497006664,"longitude":127.105596233151,"bookYear":null,"nameKR":"육블럭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32347,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/horang2_sunsaeng/","foodTypes":"바/주점","latitude":37.3906208865489,"longitude":126.953893695784,"bookYear":null,"nameKR":"호랑이선생","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":32345,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wb.burgers_kitchen","foodTypes":"기타","latitude":37.267195051521,"longitude":127.060715623857,"bookYear":null,"nameKR":"원천동수제버거 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":32320,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baekkwan_kr/","foodTypes":"한식(육류)","latitude":37.0029666267098,"longitude":127.11314785212,"bookYear":null,"nameKR":"백관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":32308,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.3637209133898,"longitude":127.106324583275,"bookYear":null,"nameKR":"코이라멘","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32305,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bbangsik77/","foodTypes":"디저트/차/베이커리","latitude":37.6339388115497,"longitude":127.206804428445,"bookYear":null,"nameKR":"제빵집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":35669,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.2516333911864,"longitude":126.992001464428,"bookYear":null,"nameKR":"힘찬숯불유황오리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":35694,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.0953268493727,"longitude":128.924085193906,"bookYear":null,"nameKR":"명지광복경양식","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":35696,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3708524725944,"longitude":126.939967610513,"bookYear":null,"nameKR":"우브베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":32283,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/massburger_","foodTypes":"기타","latitude":37.2500936813202,"longitude":127.073774250494,"bookYear":null,"nameKR":"매스버거","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":32276,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_jigum","foodTypes":"디저트/차/베이커리","latitude":37.3920848997729,"longitude":126.978148566676,"bookYear":null,"nameKR":"카페지금 커피로스터스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":32275,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/palette_dessert_/","foodTypes":"디저트/차/베이커리","latitude":37.5429307225008,"longitude":126.727504017459,"bookYear":null,"nameKR":"팔레트디저트","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":32272,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/strawberrymoon___","foodTypes":"디저트/차/베이커리","latitude":35.8329846232308,"longitude":128.723481857307,"bookYear":null,"nameKR":"스트로베리문 ","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":32271,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1562465529703,"longitude":129.034874574564,"bookYear":null,"nameKR":"샐픽","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":32264,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1146427363445,"longitude":129.015612675625,"bookYear":null,"nameKR":"해신해물찜","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":32254,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.3907090094879,"longitude":126.952759646646,"bookYear":null,"nameKR":"우마이식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":32245,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/la_four_t","foodTypes":"프랑스식","latitude":37.3220776942346,"longitude":127.109452218692,"bookYear":null,"nameKR":"라포티","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":35698,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/yangjibarum","foodTypes":"한식(육류)","latitude":35.2088927163383,"longitude":129.008998327616,"bookYear":null,"nameKR":"양지바름","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":32085,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nob_coffee/","foodTypes":"디저트/차/베이커리","latitude":35.5393289993576,"longitude":129.331969848479,"bookYear":null,"nameKR":"노브커피","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":35688,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"미국식","latitude":36.3153810907905,"longitude":126.510620444925,"bookYear":null,"nameKR":"버거네버거","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":32230,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4593778986067,"longitude":126.772246988837,"bookYear":null,"nameKR":"심원간장게장","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":32223,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.3096712042852,"longitude":126.82736550173,"bookYear":null,"nameKR":"버드케이지","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":32202,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1791874324657,"longitude":128.062733141318,"bookYear":null,"nameKR":"브라운하우스 ","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":32183,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.8848145754833,"longitude":128.610052274526,"bookYear":null,"nameKR":"짬마담","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":32179,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.5037023948135,"longitude":126.73580984429,"bookYear":null,"nameKR":"스윗유 ","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":35690,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lecafe_official","foodTypes":"디저트/차/베이커리","latitude":36.3861433282027,"longitude":127.484151402712,"bookYear":null,"nameKR":"르카페","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":35686,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bistro_izakaya_/","foodTypes":"바/주점","latitude":36.6409335060438,"longitude":127.428731436415,"bookYear":null,"nameKR":"명분","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35681,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hippo_monkfish","latitude":35.1871649205541,"longitude":126.829128967475,"bookYear":null,"nameKR":"하마네아구찜(수완점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":35682,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1554682000792,"longitude":126.853511713535,"bookYear":null,"nameKR":"하마네아구찜(상무점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":35680,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kopfkino_coffee/","foodTypes":"디저트/차/베이커리","latitude":36.6172920800039,"longitude":127.414875395192,"bookYear":null,"nameKR":"콥프키노","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":35677,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mukri459_official/","foodTypes":"디저트/차/베이커리","latitude":35.6557963627687,"longitude":129.194130062768,"bookYear":null,"nameKR":"묵리459(울산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":35676,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeebreeze_official/","foodTypes":"디저트/차/베이커리","latitude":36.6732857071493,"longitude":126.858814557469,"bookYear":null,"nameKR":"커피브리즈","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":35675,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bean__dooboo/","foodTypes":"한식(일반한식)","latitude":37.4617103131809,"longitude":126.784135484544,"bookYear":null,"nameKR":"콩이랑두부랑","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":32140,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/Aging_Doma/","foodTypes":"한식(육류)","latitude":35.5362535765657,"longitude":129.333893506393,"bookYear":null,"nameKR":"숙성도마","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":32117,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oneulcoffeeroasters","foodTypes":"디저트/차/베이커리","latitude":37.7600790476439,"longitude":126.778244362479,"bookYear":null,"nameKR":"오,늘카페","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32114,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daldaloven_","foodTypes":"디저트/차/베이커리","latitude":36.1558642007045,"longitude":128.431742659311,"bookYear":null,"nameKR":"달달한오븐 ","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":32102,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myeong828/","foodTypes":"한식(어패류)","latitude":37.5316221209745,"longitude":126.733546179187,"bookYear":null,"nameKR":"이춘명숙성회","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":32101,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sashim_i","foodTypes":"바/주점","latitude":37.5700505992522,"longitude":127.181423025552,"bookYear":null,"nameKR":"사심","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":32086,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.7748285469813,"longitude":126.447865207857,"bookYear":null,"nameKR":"고래다방","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":32065,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.6692182532115,"longitude":126.774126479422,"bookYear":null,"nameKR":"반야관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":32052,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_kafe.miro/","foodTypes":"디저트/차/베이커리","latitude":36.3105724033172,"longitude":127.353698820351,"bookYear":null,"nameKR":"카페미로","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":32051,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/incept_daejeon","foodTypes":"디저트/차/베이커리","latitude":36.3537086858797,"longitude":127.371481230469,"bookYear":null,"nameKR":"인셉트","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":32043,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gwangalli_namme?utm_medium=copy_link","foodTypes":"이탈리아식","latitude":35.1467159304824,"longitude":129.114074496346,"bookYear":null,"nameKR":"광안리남매","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":32001,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wooriroasters/","foodTypes":"디저트/차/베이커리","latitude":35.2158748616954,"longitude":128.709199498938,"bookYear":null,"nameKR":"우리로스터스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":31806,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.3651040266648,"longitude":126.920978754573,"bookYear":null,"nameKR":"궁안골","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":35670,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.2296956283051,"longitude":127.186232872965,"bookYear":null,"nameKR":"프로그","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":31977,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/butter_king_paju/","foodTypes":"디저트/차/베이커리","latitude":37.7265713729536,"longitude":126.699044355864,"bookYear":null,"nameKR":"버터킹빵공장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":31971,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chois.steak/","foodTypes":"이탈리아식","latitude":37.667913763694,"longitude":126.782536114972,"bookYear":null,"nameKR":"초이스스테이크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":31953,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2067897933696,"longitude":127.073485484181,"bookYear":null,"nameKR":"설램","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":31942,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.283467772943,"longitude":127.019892613893,"bookYear":null,"nameKR":"커피가이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":31923,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.095816126424,"longitude":128.924447353214,"bookYear":null,"nameKR":"명지첫집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":31919,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kitsune__soba/","foodTypes":"일식","latitude":35.8608555668724,"longitude":128.63379245239,"bookYear":null,"nameKR":"키츠네소바","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":31901,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.8669218946144,"longitude":128.593762333396,"bookYear":null,"nameKR":"동양백반(동성로점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":31878,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.2455261998395,"longitude":128.87231766226,"bookYear":null,"nameKR":"하오방구산점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":35664,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/liberte_0403/","foodTypes":"이탈리아식","latitude":35.146310444186,"longitude":126.885261956906,"bookYear":null,"nameKR":"리베르띠","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":31858,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeebanjeom","foodTypes":"바/주점","latitude":37.2730876685805,"longitude":127.061262679777,"bookYear":null,"nameKR":"커피반점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":31844,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/forkner_official","foodTypes":"이탈리아식","latitude":37.282003350733,"longitude":126.828353595632,"bookYear":null,"nameKR":"포크너(그랑시티자이점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":31826,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/razes_coffee/,https://www.instagram.com/hyunrista/","foodTypes":"디저트/차/베이커리","latitude":35.1206855720846,"longitude":126.865836549352,"bookYear":null,"nameKR":"라제스","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":31809,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6536923263122,"longitude":126.631278002088,"bookYear":null,"nameKR":"태백산","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":31791,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daldalhanyunmissi/","foodTypes":"디저트/차/베이커리","latitude":35.3296671495095,"longitude":129.008386138939,"bookYear":null,"nameKR":"달달한윤미씨","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":31787,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/illpyungseafood_official","foodTypes":"한식(어패류)","latitude":34.931679732748,"longitude":128.055484277999,"bookYear":null,"nameKR":"일평수산","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"사천시","status":"ACTIVE"},
        {"id":31763,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.4776183217459,"longitude":127.350651977246,"bookYear":null,"nameKR":"퇴촌갈비밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":31730,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/odett_she","foodTypes":"이탈리아식","latitude":35.1597295871407,"longitude":129.150704866293,"bookYear":null,"nameKR":"오데뜨(부산점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":31709,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yoi_kuma/","foodTypes":"일식","latitude":35.1579690940204,"longitude":129.126595463726,"bookYear":null,"nameKR":"요이쿠마","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":31705,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4159039929422,"longitude":126.679628966109,"bookYear":null,"nameKR":"백면옥","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":31702,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":36.3609091744226,"longitude":127.337485744064,"bookYear":null,"nameKR":"마시내본점 ","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":31697,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4747878011587,"longitude":126.597707237023,"bookYear":null,"nameKR":"달쭈꾸미","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":31693,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lockecoffee_official","foodTypes":"디저트/차/베이커리","latitude":36.3268777833461,"longitude":127.429291176231,"bookYear":null,"nameKR":"로크커피","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":31691,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/groundzero_coffee","foodTypes":"디저트/차/베이커리","latitude":37.292063,"longitude":127.047766,"bookYear":null,"nameKR":"그라운드제로","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":31682,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.8469828701419,"longitude":128.604075912155,"bookYear":null,"nameKR":"현짬뽕","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":31677,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6388093825183,"longitude":126.787584963691,"bookYear":null,"nameKR":"너랑나랑한우곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":31654,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3963332498728,"longitude":126.930746701312,"bookYear":null,"nameKR":"거인감자탕 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":31640,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.8186009847215,"longitude":128.539565078139,"bookYear":null,"nameKR":"풍천관","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":31620,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.1790988682899,"longitude":128.108903012438,"bookYear":null,"nameKR":"현대장어","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":35661,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4269477626074,"longitude":126.995970128953,"bookYear":null,"nameKR":"줄을스시유","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":31454,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":34.7966098289916,"longitude":126.430733818903,"bookYear":null,"nameKR":"카페인평광","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":31384,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.3176823563285,"longitude":126.838581601634,"bookYear":null,"nameKR":"구르메산장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":30842,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.3580645039291,"longitude":127.424105578569,"bookYear":null,"nameKR":"커피동행","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":35650,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/upstairkitchen_ilsan/","foodTypes":"이탈리아식","latitude":37.6683373033227,"longitude":126.774941828188,"bookYear":null,"nameKR":"업스테어키친(밤리단길점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35649,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/upstairkitchen_official/","foodTypes":"이탈리아식","latitude":37.7290578711581,"longitude":126.736129112889,"bookYear":null,"nameKR":"업스테어키친(파주운정본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":35648,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_etalee/","foodTypes":"디저트/차/베이커리","latitude":35.1567777014,"longitude":129.125218749092,"bookYear":null,"nameKR":"에타리(광안리점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35632,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/allsunday_gwangan/","foodTypes":"디저트/차/베이커리","latitude":35.1549195164882,"longitude":129.120500183729,"bookYear":null,"nameKR":"올선데이(광안점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35630,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.1507012511204,"longitude":129.016369074792,"bookYear":null,"nameKR":"삼보아구찜","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":35623,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seuchiulttae/","foodTypes":"바/주점","latitude":35.1781858086984,"longitude":126.913324037917,"bookYear":null,"nameKR":"스치울때","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":35609,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/di.geut/","foodTypes":"일식","latitude":35.8657523596583,"longitude":128.595854992652,"bookYear":null,"nameKR":"디귿","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":35579,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/somakgol/","foodTypes":"한식(육류)","latitude":35.1714840302377,"longitude":129.176548590157,"bookYear":null,"nameKR":"소막골본점","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":35570,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/years__official/","foodTypes":"디저트/차/베이커리","latitude":35.8711670404542,"longitude":128.600407285103,"bookYear":null,"nameKR":"이얼즈","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":31417,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dim_tao_/","foodTypes":"중식","latitude":35.1639381934054,"longitude":129.156456150697,"bookYear":null,"nameKR":"딤타오","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":31699,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eun.ssom","foodTypes":"디저트/차/베이커리","latitude":35.1683818379794,"longitude":129.155791929248,"bookYear":null,"nameKR":"은쏨","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":2296,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.15597,"longitude":129.153993,"bookYear":null,"nameKR":"오킴스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":7093,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":35.159693,"longitude":129.159594,"bookYear":null,"nameKR":"강가(해운대점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":7205,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":35.160217,"longitude":129.160626,"bookYear":null,"nameKR":"나마스테(해운대점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":7493,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.162901,"longitude":129.16596,"bookYear":null,"nameKR":"초원복국(해운대점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":7726,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1608598597668,"longitude":129.155440494412,"bookYear":null,"nameKR":"속씨원한대구탕(리베라점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":11186,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.160238,"longitude":129.159412,"bookYear":null,"nameKR":"일품한우","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":11200,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.162628,"longitude":129.165008,"bookYear":null,"nameKR":"해운대소문난삼계탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":11409,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.16202,"longitude":129.1637,"bookYear":null,"nameKR":"부성회관","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":11417,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.160995,"longitude":129.162039,"bookYear":null,"nameKR":"일번지자갈치산곰장어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":14546,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":0,"longitude":0,"bookYear":null,"nameKR":"옵스(해운대점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":20340,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"면옥향천","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":22559,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.16395,"longitude":129.163466,"bookYear":null,"nameKR":"시골쌈밥집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":23251,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":35.160204,"longitude":129.166597,"bookYear":null,"nameKR":"스페인클럽","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":25362,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.160134,"longitude":129.164688,"bookYear":null,"nameKR":"크리스탈가든","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":25697,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.163648,"longitude":129.162356,"bookYear":null,"nameKR":"소문난원조쌈밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":26214,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.162798,"longitude":129.169739,"bookYear":null,"nameKR":"원산면옥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":26501,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.1664869604816,"longitude":129.157747389738,"bookYear":null,"nameKR":"중식궁","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":26519,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.162886,"longitude":129.16282,"bookYear":null,"nameKR":"사북칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(해운대/해리단길)","status":"ACTIVE"},
        {"id":25646,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.16904,"longitude":129.170258,"bookYear":null,"nameKR":"복미아구찜(본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":32856,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chef_eleven/","foodTypes":"이탈리아식","latitude":35.1631650904754,"longitude":129.184575033896,"bookYear":null,"nameKR":"고동운식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":34511,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/horang2__gelatteok/","foodTypes":"디저트/차/베이커리","latitude":35.158500786398,"longitude":129.171182572303,"bookYear":null,"nameKR":"호랑이젤라떡","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":2288,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.160588,"longitude":129.190993,"bookYear":null,"nameKR":"수민이네","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":4172,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.157444,"longitude":129.175554,"bookYear":null,"nameKR":"몽","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":13270,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.160908,"longitude":129.171518,"bookYear":null,"nameKR":"원조할매복국","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":2297,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.159002,"longitude":129.175454,"bookYear":null,"nameKR":"오페라하우스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":19591,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.158139,"longitude":129.175574,"bookYear":null,"nameKR":"솔마루","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":21169,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/diartcoffee/","foodTypes":"디저트/차/베이커리","latitude":35.1607289239226,"longitude":129.191867554605,"bookYear":null,"nameKR":"카페디아트","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":6843,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1579862593401,"longitude":129.172080732723,"bookYear":null,"nameKR":"미포이씨할매횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":7434,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.160337,"longitude":129.170824,"bookYear":null,"nameKR":"할매집원조복국(해운대 2호점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":11447,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1701854177399,"longitude":129.197247985138,"bookYear":null,"nameKR":"자연횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":14841,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":35.175553,"longitude":129.19593,"bookYear":null,"nameKR":"테이블4","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":22249,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.178859,"longitude":129.198226,"bookYear":null,"nameKR":"카페벨라비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":22319,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.208309,"longitude":129.123651,"bookYear":null,"nameKR":"시골집추어탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":25653,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.182786,"longitude":129.200969,"bookYear":null,"nameKR":"엄마손대구탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":26506,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.174051,"longitude":129.196243,"bookYear":null,"nameKR":"길조","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":29539,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.176170856997025,"longitude":129.196337819108,"bookYear":null,"nameKR":"송정집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(달맞이길/청사포/송정)","status":"ACTIVE"},
        {"id":35217,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hi_buon_giorno/","foodTypes":"이탈리아식","latitude":36.3378941222521,"longitude":127.343714708598,"bookYear":null,"nameKR":"본조르노","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":35543,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.1994902280642,"longitude":129.117121494335,"bookYear":null,"nameKR":"온들랑샤브샤브","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":24533,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.378783,"longitude":127.16926,"bookYear":null,"nameKR":"강천장어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":35529,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafeyoon_631/","foodTypes":"디저트/차/베이커리","latitude":35.1819077121661,"longitude":129.21135273795,"bookYear":null,"nameKR":"카페윤","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":22851,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.163052,"longitude":129.115352,"bookYear":null,"nameKR":"창녕돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":2314,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.156397,"longitude":129.124704,"bookYear":null,"nameKR":"해초록","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":5483,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.168509,"longitude":129.115284,"bookYear":null,"nameKR":"둔내막국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":11139,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.153183,"longitude":129.124689,"bookYear":null,"nameKR":"부산횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":25634,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.156909,"longitude":129.126251,"bookYear":null,"nameKR":"명송회오리탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":26513,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.168916,"longitude":129.118907,"bookYear":null,"nameKR":"대만만두","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":33086,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vispore/","foodTypes":"이탈리아식","latitude":35.1636454218449,"longitude":129.128673102943,"bookYear":null,"nameKR":"비스포레","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":33194,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1552453748854,"longitude":129.124562006162,"bookYear":null,"nameKR":"신라횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":35274,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.14789874379,"longitude":129.114371050111,"bookYear":null,"nameKR":"로옹","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":4069,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.144219,"longitude":129.108687,"bookYear":null,"nameKR":"동화반점(남천동점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":21667,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.138888,"longitude":129.107411,"bookYear":null,"nameKR":"덩굴아나고","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":22099,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.146675,"longitude":129.112887,"bookYear":null,"nameKR":"다케다야","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":22306,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.139128,"longitude":129.109057,"bookYear":null,"nameKR":"서울김밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":22313,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.140688,"longitude":129.108663,"bookYear":null,"nameKR":"보성녹차","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":22321,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.144893,"longitude":129.107271,"bookYear":null,"nameKR":"할매손","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":23396,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.159441,"longitude":129.122,"bookYear":null,"nameKR":"청도추어탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":23730,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.157186,"longitude":129.126644,"bookYear":null,"nameKR":"하모횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":25578,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/galmegibrewing_gwangan/","foodTypes":"바/주점","latitude":35.1475298728401,"longitude":129.112699539488,"bookYear":null,"nameKR":"갈매기브루잉","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":29084,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.17675979522431,"longitude":129.1154916984382,"bookYear":null,"nameKR":"테라로사(수영점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":7683,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1405020867206,"longitude":129.110195058347,"bookYear":null,"nameKR":"신창국밥(남천점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":12455,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1402231925496,"longitude":129.109837348817,"bookYear":null,"nameKR":"연합횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":12459,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.1671398225212,"longitude":129.11734386091,"bookYear":null,"nameKR":"진주한우양곱창","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":13211,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.1575138371245,"longitude":129.112058634956,"bookYear":null,"nameKR":"장가계","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":20813,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.152664,"longitude":129.11747,"bookYear":null,"nameKR":"진미횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":22194,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.1610115886099,"longitude":129.108737696449,"bookYear":null,"nameKR":"세잔네작은커피가게","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":22566,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.146295,"longitude":129.110551,"bookYear":null,"nameKR":"옛날쌈밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":22877,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.1604365509478,"longitude":129.111996172436,"bookYear":null,"nameKR":"시장할매칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":25638,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.14872,"longitude":129.114296,"bookYear":null,"nameKR":"언양불고기부산집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":26491,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.166384,"longitude":129.11619,"bookYear":null,"nameKR":"수복식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":32604,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.160547978705,"longitude":129.112634186893,"bookYear":null,"nameKR":"정국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":32727,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bread_sokuri/","foodTypes":"디저트/차/베이커리","latitude":35.1574319393608,"longitude":129.113586571518,"bookYear":null,"nameKR":"빵소쿠리","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":34328,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bollywoodbusan/","foodTypes":"아시아식","latitude":35.1530535306512,"longitude":129.117253666063,"bookYear":null,"nameKR":"발리우드","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":8199,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.167189,"longitude":129.114112,"bookYear":null,"nameKR":"우성삼계탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"수영구/광안리","status":"ACTIVE"},
        {"id":22963,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lecielbluebs/","foodTypes":"이탈리아식","latitude":35.143371,"longitude":126.8428,"bookYear":null,"nameKR":"르시엘블루","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":24939,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.13898,"longitude":126.858194,"bookYear":null,"nameKR":"빛고을찐빵만두","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":6065,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.132556,"longitude":126.851016,"bookYear":null,"nameKR":"두레박","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":11426,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.184966,"longitude":129.126381,"bookYear":null,"nameKR":"양산왕돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":13253,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.14827,"longitude":126.849609,"bookYear":null,"nameKR":"소망생고기","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":34467,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goodson.official/","foodTypes":"아시아식","latitude":35.1526154731084,"longitude":129.06755589952,"bookYear":null,"nameKR":"굿손(전포점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34513,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ducobi_diner_x_stadium/","foodTypes":"디저트/차/베이커리","latitude":35.1543321420614,"longitude":129.064711263602,"bookYear":null,"nameKR":"듀코비다이너","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34520,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/umill_dessert_cafe/","foodTypes":"디저트/차/베이커리","latitude":35.1542766932637,"longitude":129.067116578471,"bookYear":null,"nameKR":"유밀","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":35241,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1515755452811,"longitude":126.91207943298,"bookYear":null,"nameKR":"온다방","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":13538,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.1536456739813,"longitude":126.925929251001,"bookYear":null,"nameKR":"산수원","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":21716,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.1484823097929,"longitude":126.92459859181182,"bookYear":null,"nameKR":"토라네코","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":21995,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.155875,"longitude":126.847808,"bookYear":null,"nameKR":"송학한정식","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":23309,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.1545946620995,"longitude":126.934940512829,"bookYear":null,"nameKR":"미니밀","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":23642,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1451173098309,"longitude":126.929166876095,"bookYear":null,"nameKR":"이조감자탕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":23647,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.165085,"longitude":126.858741,"bookYear":null,"nameKR":"철주네식육식당","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":23795,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.148858,"longitude":126.927678,"bookYear":null,"nameKR":"창억떡집(광주점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":23925,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.153527,"longitude":126.847666,"bookYear":null,"nameKR":"창억예다손떡(상무점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":24950,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.145775,"longitude":126.924315,"bookYear":null,"nameKR":"삼미관","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":24959,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.12677,"longitude":126.93155,"bookYear":null,"nameKR":"덕산오징어보쌈","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":2773,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.152021,"longitude":126.916555,"bookYear":null,"nameKR":"화랑궁회관","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":4041,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.149644,"longitude":126.918467,"bookYear":null,"nameKR":"참뫼","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":4532,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.154226,"longitude":126.911792,"bookYear":null,"nameKR":"금탑소머리국밥","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":8886,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.162089,"longitude":126.918731,"bookYear":null,"nameKR":"아리랑하우스","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":13288,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.1499809456109,"longitude":126.919061183117,"bookYear":null,"nameKR":"구수향","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":18244,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.14541,"longitude":126.918839,"bookYear":null,"nameKR":"청해복집","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":22550,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.144201,"longitude":126.917757,"bookYear":null,"nameKR":"정열식당","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":23658,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.150322,"longitude":126.916245,"bookYear":null,"nameKR":"장씨복집","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":32371,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.1628257920355,"longitude":126.915651465191,"bookYear":null,"nameKR":"홍궁","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":33254,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_chu_rup/","foodTypes":"디저트/차/베이커리","latitude":35.1455562038771,"longitude":126.918717256072,"bookYear":null,"nameKR":"츄룹","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"동구/금남로/충장로","status":"ACTIVE"},
        {"id":5263,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.610974,"longitude":126.52056,"bookYear":null,"nameKR":"장어마을","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":11528,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.5072416424436,"longitude":126.750700252373,"bookYear":null,"nameKR":"송도숯불장어(상동점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":23421,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.497523,"longitude":127.281609,"bookYear":null,"nameKR":"호수집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":10428,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.476093,"longitude":126.598111,"bookYear":null,"nameKR":"해뜨는회집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":11360,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.0663132749525,"longitude":129.013444604395,"bookYear":null,"nameKR":"애경이네집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":11768,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.349273,"longitude":126.822346,"bookYear":null,"nameKR":"장수촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":23649,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.161412,"longitude":126.905206,"bookYear":null,"nameKR":"영양오리탕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":24193,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.810506,"longitude":127.177131,"bookYear":null,"nameKR":"본가신토장수오리","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":24316,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.217239,"longitude":128.687502,"bookYear":null,"nameKR":"솔밭오리마을","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":24385,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.465942,"longitude":126.755579,"bookYear":null,"nameKR":"박씨농원","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":25629,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.178313,"longitude":129.05231,"bookYear":null,"nameKR":"미가정","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":32555,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.9272547207754,"longitude":127.68845641036,"bookYear":null,"nameKR":"장수촌","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"음성군","status":"ACTIVE"},
        {"id":33585,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/JUU.NAM/","foodTypes":"한식(가금류)","latitude":37.2651362607792,"longitude":127.602273145067,"bookYear":null,"nameKR":"포뜰오리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":9495,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.743774,"longitude":126.583891,"bookYear":null,"nameKR":"농원깊은골","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9527,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.757906,"longitude":126.604776,"bookYear":null,"nameKR":"청솔가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9636,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.817606,"longitude":126.364833,"bookYear":null,"nameKR":"팔봉산가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9953,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":34.724229,"longitude":127.088827,"bookYear":null,"nameKR":"청광도예원","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":10165,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":34.733551,"longitude":126.718867,"bookYear":null,"nameKR":"석정가든","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":15593,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.446358,"longitude":126.880005,"bookYear":null,"nameKR":"장수촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":22488,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.941596,"longitude":127.410129,"bookYear":null,"nameKR":"할머니집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"진천군","status":"ACTIVE"},
        {"id":22677,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.2767681113279,"longitude":129.074037527178,"bookYear":null,"nameKR":"유씨집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":22889,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.187576,"longitude":129.048702,"bookYear":null,"nameKR":"기와집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":23749,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.355542,"longitude":127.042203,"bookYear":null,"nameKR":"고기리농원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":23955,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":38.075221,"longitude":127.41641,"bookYear":null,"nameKR":"나드리쉼터","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":24108,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.454012,"longitude":126.853009,"bookYear":null,"nameKR":"광명토성","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":24281,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.425027,"longitude":127.066456,"bookYear":null,"nameKR":"청계냇가집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":26110,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.9708285989703,"longitude":128.785609898127,"bookYear":null,"nameKR":"명당폭포식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":26124,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.669884,"longitude":127.028055,"bookYear":null,"nameKR":"나그네가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":26238,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.9630585482915,"longitude":127.681713030754,"bookYear":null,"nameKR":"봉학골가든","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"음성군","status":"ACTIVE"},
        {"id":2295,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.187331,"longitude":129.078949,"bookYear":null,"nameKR":"오소리순대","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":2035,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.824682,"longitude":128.415284,"bookYear":null,"nameKR":"용화찜","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":11109,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gimceolhwa8/","foodTypes":"한식(육류)","latitude":35.244537,"longitude":129.055322,"bookYear":null,"nameKR":"산성창녕집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":13697,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.577853,"longitude":128.577573,"bookYear":null,"nameKR":"풍산대구식육식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":22591,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.941276,"longitude":128.645723,"bookYear":null,"nameKR":"팔공참한우마실","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":25112,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":38.02419,"longitude":127.368955,"bookYear":null,"nameKR":"우목정","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":25227,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.6099124673614,"longitude":126.656663138095,"bookYear":null,"nameKR":"일미옥불고기","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":34360,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.6637046899131,"longitude":126.766434892031,"bookYear":null,"nameKR":"만선애꿈(라페스타점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":9524,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.770216,"longitude":126.453722,"bookYear":null,"nameKR":"왕산낙지마을","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":12472,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.059685,"longitude":129.379185,"bookYear":null,"nameKR":"옛날한계령조개구이","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":22354,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.677718,"longitude":126.754888,"bookYear":null,"nameKR":"을지로골뱅이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":7092,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.388335,"longitude":127.123778,"bookYear":null,"nameKR":"강가(분당점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":17403,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":36.3628757863546,"longitude":127.352321760398,"bookYear":null,"nameKR":"뉴타지마할","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":29634,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":36.96602333966586,"longitude":126.6873400683378,"bookYear":null,"nameKR":"카페루이루카","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":35181,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1572268200733,"longitude":126.878604130638,"bookYear":null,"nameKR":"파쿠야","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":30936,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hn_house","foodTypes":"바/주점","latitude":35.8348087925437,"longitude":129.214840375848,"bookYear":null,"nameKR":"황남주택","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":21527,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.852967,"longitude":128.504443,"bookYear":null,"nameKR":"하지메","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":22010,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/misari_river/","foodTypes":"디저트/차/베이커리","latitude":37.575467,"longitude":127.205216,"bookYear":null,"nameKR":"리버","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":22334,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.244698,"longitude":129.087062,"bookYear":null,"nameKR":"라뽀아르","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":11758,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.36152,"longitude":126.936507,"bookYear":null,"nameKR":"두꺼비부대찌개(산본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":22185,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1528463499554,"longitude":129.025536940208,"bookYear":null,"nameKR":"밀양돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":26496,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.085652,"longitude":128.911214,"bookYear":null,"nameKR":"영진돼지국밥(명지점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":34595,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/momo_sangjeom/","foodTypes":"디저트/차/베이커리","latitude":35.8670711110477,"longitude":128.611189222583,"bookYear":null,"nameKR":"모모상점","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":2915,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.995039,"longitude":128.681045,"bookYear":null,"nameKR":"자미가","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":9991,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.906544,"longitude":127.340839,"bookYear":null,"nameKR":"향토음식점","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":11592,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.23107,"longitude":127.19801,"bookYear":null,"nameKR":"순두부마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":18807,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4994459301056,"longitude":126.719308046662,"bookYear":null,"nameKR":"화미소금구이(부평점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":18846,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.508717,"longitude":127.768627,"bookYear":null,"nameKR":"할매맛집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":20459,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.8614543048429,"longitude":128.57076195969,"bookYear":null,"nameKR":"정민막창","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":33056,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2285996021639,"longitude":127.298720151559,"bookYear":null,"nameKR":"대흥갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":35468,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.2093397485975,"longitude":129.008419493443,"bookYear":null,"nameKR":"랑돼지(덕천점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":32096,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.156789983664,"longitude":129.056416218143,"bookYear":null,"nameKR":"차이797플러스(롯데부산본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34514,"bookStatus":"비활성","websiteInstagram":"http://www.instagram.com/okcheonsa_setac","foodTypes":"이탈리아식","latitude":35.1569956657522,"longitude":129.0660187458,"bookYear":null,"nameKR":"옥천사세탁","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":34852,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oilyburger/","foodTypes":"기타","latitude":35.8715359343601,"longitude":128.59888072891,"bookYear":null,"nameKR":"오일리버거","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":34535,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/stonemill80","foodTypes":"디저트/차/베이커리","latitude":36.3505290438877,"longitude":126.614987178586,"bookYear":null,"nameKR":"스톤밀","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":34553,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.1103828694975,"longitude":129.01915319516,"bookYear":null,"nameKR":"미미떡집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":34555,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/townbakery2023/","foodTypes":"기타","latitude":35.3136206992708,"longitude":128.737410232455,"bookYear":null,"nameKR":"읍내빵집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":34127,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mugungmugin_","foodTypes":"이탈리아식","latitude":35.8356186533889,"longitude":129.209301425449,"bookYear":null,"nameKR":"무궁무진","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":33457,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.195859614237,"longitude":127.09808740717,"bookYear":null,"nameKR":"화담정","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":33465,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kitchen205/","foodTypes":"디저트/차/베이커리","latitude":35.0612145004039,"longitude":126.524439748166,"bookYear":null,"nameKR":"키친205","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":12543,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.835545,"longitude":128.600646,"bookYear":null,"nameKR":"정길채호두파이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":10173,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":34.743084,"longitude":126.713882,"bookYear":null,"nameKR":"월오황토가","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":31413,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/patissier_koo/","foodTypes":"디저트/차/베이커리","latitude":37.6919107497693,"longitude":126.75697444563,"bookYear":null,"nameKR":"레미니스케이크팩토리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":30896,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafekumi0123/","foodTypes":"디저트/차/베이커리","latitude":36.1235910491375,"longitude":128.323822140937,"bookYear":null,"nameKR":"최권수베이커리 ","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":31657,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/craftondo","foodTypes":"디저트/차/베이커리","latitude":37.6398715132164,"longitude":126.833898086673,"bookYear":null,"nameKR":"크라프트온도","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":33673,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/na_vi_cafe/","foodTypes":"기타","latitude":35.2048999529876,"longitude":129.079317128029,"bookYear":null,"nameKR":"하트&나비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":34014,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.4019366386166,"longitude":126.974387558474,"bookYear":null,"nameKR":"인덕숯불구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":8995,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.842047,"longitude":129.217518,"bookYear":null,"nameKR":"부산찐빵","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":11441,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.0935748115891,"longitude":128.905970751219,"bookYear":null,"nameKR":"원할머니보쌈(부산명지점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":12008,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.2797743315611,"longitude":127.444988813933,"bookYear":null,"nameKR":"원할머니보쌈(이천점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":26147,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_kipoom/","foodTypes":"디저트/차/베이커리","latitude":36.400597,"longitude":127.397184,"bookYear":null,"nameKR":"기품","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":10826,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.673451,"longitude":128.957484,"bookYear":null,"nameKR":"하얀집","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":11124,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.136304,"longitude":129.0914,"bookYear":null,"nameKR":"김유순대구뽈찜전문집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":16062,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":0,"longitude":0,"bookYear":null,"nameKR":"옵스(롯데울산점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":24075,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.3130868135667,"longitude":126.822473179511,"bookYear":null,"nameKR":"한섬떡케익","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":34020,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/somidang_/","foodTypes":"디저트/차/베이커리","latitude":37.3734164390131,"longitude":127.138036422726,"bookYear":null,"nameKR":"소미당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":35103,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_cakie","foodTypes":"디저트/차/베이커리","latitude":34.7850261763025,"longitude":127.692354040876,"bookYear":null,"nameKR":"케이키","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":35460,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1726499080991,"longitude":128.815396896981,"bookYear":null,"nameKR":"유타우동","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":35427,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pauncoffee/","foodTypes":"디저트/차/베이커리","latitude":37.7155399709846,"longitude":126.740339774136,"bookYear":null,"nameKR":"파운커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":35430,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1547801394963,"longitude":129.056553034277,"bookYear":null,"nameKR":"청기와타운(서면점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":35453,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7349074159176,"longitude":127.108757342098,"bookYear":null,"nameKR":"외양간한우(의정부본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":6326,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.662957365165,"longitude":126.7513775829,"bookYear":null,"nameKR":"터치아프리카","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":35421,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/baru_bakeshop","foodTypes":"디저트/차/베이커리","latitude":35.920553583794,"longitude":128.546985515352,"bookYear":null,"nameKR":"바루베이크샵","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":35297,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.4703744383961,"longitude":126.812606337594,"bookYear":null,"nameKR":"예당어죽","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":35413,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.4459493526086,"longitude":129.076075882572,"bookYear":null,"nameKR":"천성산가는길","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":35400,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mandugoeul/","foodTypes":"한식(면류)","latitude":35.8575405927458,"longitude":128.63061904673,"bookYear":null,"nameKR":"만두고을","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":16061,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":0,"longitude":0,"bookYear":null,"nameKR":"옵스(구서골드점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":16063,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":0,"longitude":0,"bookYear":null,"nameKR":"옵스(LG메트로시티점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":23899,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.203844,"longitude":127.072166,"bookYear":null,"nameKR":"라본테이블","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":26446,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":35.206846,"longitude":129.075365,"bookYear":null,"nameKR":"아빠와돈까스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":15354,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":0,"longitude":0,"bookYear":null,"nameKR":"옵스(마린시티점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":22720,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.390037,"longitude":127.092029,"bookYear":null,"nameKR":"브로테나인","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":2123,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.804931,"longitude":129.502588,"bookYear":null,"nameKR":"해운대회식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2150,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.161174,"longitude":126.906303,"bookYear":null,"nameKR":"춘하추동오리탕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":2498,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.988339,"longitude":126.47802,"bookYear":null,"nameKR":"굴비랑낙지랑","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":4021,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hp_jeonjusigdang/","foodTypes":"한식(일반한식)","latitude":35.066139,"longitude":126.519059,"bookYear":null,"nameKR":"전주식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":4143,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.462573,"longitude":126.635135,"bookYear":null,"nameKR":"신성복집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":4330,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.222646,"longitude":127.369977,"bookYear":null,"nameKR":"나고야","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":5450,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.79369159810244,"longitude":126.6864690333184,"bookYear":null,"nameKR":"옛날시골밥상","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":6013,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.856088,"longitude":128.588873,"bookYear":null,"nameKR":"할매칼국수","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":6166,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":36.957371,"longitude":126.84837,"bookYear":null,"nameKR":"몬테비안코","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":8127,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.471133,"longitude":126.627463,"bookYear":null,"nameKR":"할매왕족발순대","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":9594,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.606427,"longitude":126.411824,"bookYear":null,"nameKR":"전망좋은횟집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":10440,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.4747188252032,"longitude":126.597666343202,"bookYear":null,"nameKR":"부산태종대횟집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":10608,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.148773,"longitude":128.697809,"bookYear":null,"nameKR":"석청","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":9484,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.785693,"longitude":126.447371,"bookYear":null,"nameKR":"삼정곱창","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":12252,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7807569867787,"longitude":126.954910990003,"bookYear":null,"nameKR":"흑과백","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":14050,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":36.348019,"longitude":127.38985,"bookYear":null,"nameKR":"동방명주","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":16164,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":34.571752662609,"longitude":127.34038707139408,"bookYear":null,"nameKR":"포두식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"고흥군","status":"ACTIVE"},
        {"id":17624,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.731888,"longitude":127.73792,"bookYear":null,"nameKR":"문화횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":19677,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":35.861214,"longitude":128.604609,"bookYear":null,"nameKR":"로타리식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":21743,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hoyasushi1/","foodTypes":"일식","latitude":35.1963895550612,"longitude":129.07898662985,"bookYear":null,"nameKR":"호야스시","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":26711,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.7806849680841,"longitude":126.71797322512,"bookYear":null,"nameKR":"손가주방","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":29727,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.372662879865,"longitude":127.114985989481,"bookYear":null,"nameKR":"윤밀원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":30664,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.29848728576788,"longitude":127.31849362901131,"bookYear":null,"nameKR":"한마음냉면","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":30954,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/kdk_bakery/","foodTypes":"디저트/차/베이커리","latitude":35.2303608818843,"longitude":128.894020019824,"bookYear":null,"nameKR":"김덕규베이커리","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":31412,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.8822470034941,"longitude":129.221055885975,"bookYear":null,"nameKR":"황가정","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31650,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.8673658990035,"longitude":128.582105956817,"bookYear":null,"nameKR":"만원족발","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":31776,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brownhands_incheon","foodTypes":"디저트/차/베이커리","latitude":37.4719495245054,"longitude":126.6307917668,"bookYear":null,"nameKR":"브라운핸즈개항로","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":31777,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brownhands_masan/","foodTypes":"디저트/차/베이커리","latitude":35.1736693841696,"longitude":128.575069661583,"bookYear":null,"nameKR":"브라운핸즈마산점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":31935,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8375182780452,"longitude":129.210675470482,"bookYear":null,"nameKR":"빛꾸리","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31939,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.0971326235742,"longitude":129.024278335463,"bookYear":null,"nameKR":"선어마을","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":32338,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.1225586870286,"longitude":129.110160951087,"bookYear":null,"nameKR":"소문난칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":32350,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mireukmireuk/","foodTypes":"바/주점","latitude":34.8430711227039,"longitude":128.42998059427,"bookYear":null,"nameKR":"미륵미륵","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":35341,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.736219884883,"longitude":126.751485045811,"bookYear":null,"nameKR":"이태리식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32329,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/momos_coffee/","foodTypes":"디저트/차/베이커리","latitude":35.0960261533655,"longitude":129.042578733,"bookYear":null,"nameKR":"모모스로스터리&커피바","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":29933,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.271141948528836,"longitude":127.01136934913156,"bookYear":null,"nameKR":"두꺼비집부대찌개","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":1892,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.378043,"longitude":127.113267,"bookYear":null,"nameKR":"연경(수내점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":2113,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.359206,"longitude":129.384169,"bookYear":null,"nameKR":"청송식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":3620,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sorella_.p/","foodTypes":"이탈리아식","latitude":37.366107,"longitude":127.105352,"bookYear":null,"nameKR":"쏘렐라파스타","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":4178,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.230142,"longitude":129.084436,"bookYear":null,"nameKR":"우마이도","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":5279,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.365491,"longitude":127.380012,"bookYear":null,"nameKR":"한스랍스터","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":6234,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.378802,"longitude":127.115295,"bookYear":null,"nameKR":"그집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":27248,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.321064,"longitude":127.111361,"bookYear":null,"nameKR":"브라우니70","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":28557,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.72779850137021,"longitude":127.41405543047509,"bookYear":null,"nameKR":"명월집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":32592,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.1772672586694,"longitude":126.913355531911,"bookYear":null,"nameKR":"호미도","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":32622,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.1392549681881,"longitude":128.208377157723,"bookYear":null,"nameKR":"덩실분식","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":32640,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.3082410215043,"longitude":126.517772430203,"bookYear":null,"nameKR":"어다랑","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":32669,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mi_sung_gak","foodTypes":"중식","latitude":37.6368298737794,"longitude":126.67701357845,"bookYear":null,"nameKR":"미성각","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":32686,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.3214486498888,"longitude":127.112655887536,"bookYear":null,"nameKR":"안안","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":32697,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6394961284511,"longitude":126.673732077256,"bookYear":null,"nameKR":"이촌옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":32705,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wondangbread/","foodTypes":"디저트/차/베이커리","latitude":37.6577429834323,"longitude":126.838793389639,"bookYear":null,"nameKR":"원당식빵","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":32713,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pizaok/","foodTypes":"이탈리아식","latitude":35.8364240244567,"longitude":129.21081384745,"bookYear":null,"nameKR":"피자옥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":32714,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8613398204943,"longitude":129.227963537752,"bookYear":null,"nameKR":"부둑골식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":32715,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.3908461902374,"longitude":127.099139043262,"bookYear":null,"nameKR":"샘밭막국수(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":32729,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/teacafe_chasen","foodTypes":"디저트/차/베이커리","latitude":37.1332747426375,"longitude":128.195892977728,"bookYear":null,"nameKR":"티카페차센","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":32770,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushi_shokuzen.official","foodTypes":"일식","latitude":37.2874808899042,"longitude":127.060178440172,"bookYear":null,"nameKR":"스시쇼쿠젠","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":32904,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gardenus_/","foodTypes":"디저트/차/베이커리","latitude":37.7896491321523,"longitude":126.69611350376,"bookYear":null,"nameKR":"가드너스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32936,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_pezkitchen/","foodTypes":"기타","latitude":35.5407116453574,"longitude":129.332953798365,"bookYear":null,"nameKR":"페즈키친","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":32990,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/downthere__/","foodTypes":"디저트/차/베이커리","latitude":37.7887475873361,"longitude":126.696200638754,"bookYear":null,"nameKR":"다운데어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":33007,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.6603392424096,"longitude":126.767748529362,"bookYear":null,"nameKR":"딤섬점방","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":33098,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe.becoming.mood/","foodTypes":"디저트/차/베이커리","latitude":37.2078744822897,"longitude":127.111455956223,"bookYear":null,"nameKR":"비커밍무드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":33130,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/american_quality_dining/","foodTypes":"미국식","latitude":37.3630091848763,"longitude":127.106533316945,"bookYear":null,"nameKR":"리츠다이너","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":33166,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_inga/","foodTypes":"디저트/차/베이커리","latitude":37.2237348877316,"longitude":127.106605726342,"bookYear":null,"nameKR":"카페인가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":33185,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/haydn_park/","foodTypes":"이탈리아식","latitude":35.1705175269381,"longitude":128.822455530131,"bookYear":null,"nameKR":"하이든파크","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":33189,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/onesummernight.cafe/","foodTypes":"디저트/차/베이커리","latitude":37.2341027622987,"longitude":127.112571318938,"bookYear":null,"nameKR":"원썸머나잇","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":33258,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/patisserie_melikey/","foodTypes":"디저트/차/베이커리","latitude":37.6748869732937,"longitude":126.779960645724,"bookYear":null,"nameKR":"미라이키","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":33274,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/makeseacoffee/","foodTypes":"디저트/차/베이커리","latitude":35.3083457177993,"longitude":129.259332212758,"bookYear":null,"nameKR":"메이크씨","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":33275,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafecoralani/","foodTypes":"디저트/차/베이커리","latitude":35.1823746936464,"longitude":129.208957923809,"bookYear":null,"nameKR":"코랄라니","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":33276,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gonggeuk_sand/","foodTypes":"디저트/차/베이커리","latitude":35.1844862961937,"longitude":129.213098144996,"bookYear":null,"nameKR":"공극샌드커피","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":33279,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.696081042253,"longitude":126.662798114549,"bookYear":null,"nameKR":"대복갈비","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":33280,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.543138503365,"longitude":126.7254859443,"bookYear":null,"nameKR":"블랑제리쉐종","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":33283,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/nakta_nakta/","foodTypes":"디저트/차/베이커리","latitude":37.7071198673734,"longitude":127.038341755583,"bookYear":null,"nameKR":"나크타","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":33289,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.6584656796268,"longitude":127.479447395296,"bookYear":null,"nameKR":"LX22","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":33297,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/abogoga_official/","foodTypes":"디저트/차/베이커리","latitude":37.703396324029,"longitude":126.661018160328,"bookYear":null,"nameKR":"아보고가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":33312,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.3307357974542,"longitude":127.302508777014,"bookYear":null,"nameKR":"우드페커","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":33313,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woodybrook_cafe/","foodTypes":"디저트/차/베이커리","latitude":35.134256539049,"longitude":128.998516737722,"bookYear":null,"nameKR":"우디브룩","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":33319,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_mowol/","foodTypes":"디저트/차/베이커리","latitude":36.7248053123507,"longitude":126.422625042874,"bookYear":null,"nameKR":"카페모월","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":33486,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/felt_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.3952969470752,"longitude":127.110449292622,"bookYear":null,"nameKR":"펠트커피(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":33571,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.1761561548515,"longitude":129.12584611422,"bookYear":null,"nameKR":"현우동(부산점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":33596,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushisuiren/","foodTypes":"일식","latitude":37.2951019637936,"longitude":127.058185334001,"bookYear":null,"nameKR":"스시스이렌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":33618,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.6538207046493,"longitude":126.844334154671,"bookYear":null,"nameKR":"평양옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":33736,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwalyu/","foodTypes":"바/주점","latitude":37.6575839055984,"longitude":126.77085561273,"bookYear":null,"nameKR":"화류","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":34090,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":34.8854161024961,"longitude":128.626035382188,"bookYear":null,"nameKR":"구천식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":34271,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.7086426990873,"longitude":127.123866341858,"bookYear":null,"nameKR":"흙심","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":34303,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.2294092184426,"longitude":129.01284424083,"bookYear":null,"nameKR":"아하스시","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":34304,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.0899019673685,"longitude":129.026369121927,"bookYear":null,"nameKR":"구내식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":34324,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.0874974889359,"longitude":129.044152952941,"bookYear":null,"nameKR":"가마솥돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":34405,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/6kcoffee/","foodTypes":"디저트/차/베이커리","latitude":35.1387720347839,"longitude":126.913666053121,"bookYear":null,"nameKR":"육각커피","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":34481,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/huseang_diningroom/","foodTypes":"한식(육류)","latitude":35.8381522034722,"longitude":129.20901295246,"bookYear":null,"nameKR":"후생식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":34485,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/cafe_jinmok","foodTypes":"디저트/차/베이커리","latitude":35.1058911701386,"longitude":128.931293538889,"bookYear":null,"nameKR":"진목카페","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":34512,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/overlap12/","foodTypes":"이탈리아식","latitude":35.2001640994822,"longitude":129.228896295351,"bookYear":null,"nameKR":"오버랩","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":34531,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.9916214402833,"longitude":127.578278086215,"bookYear":null,"nameKR":"우분투","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"음성군","status":"ACTIVE"},
        {"id":34533,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.3269759274905,"longitude":127.423631778599,"bookYear":null,"nameKR":"형네낙지","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":34536,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":36.6153845531817,"longitude":127.246120774618,"bookYear":null,"nameKR":"그대있음에","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":34538,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/woohwahansigdang/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.3723025409824,"longitude":128.824494462169,"bookYear":null,"nameKR":"우화한식당 생림본점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":34682,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/steamhouse_official/","foodTypes":"중식","latitude":37.4016340578617,"longitude":126.973893880652,"bookYear":null,"nameKR":"스팀하우스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":29035,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"이탈리아식","latitude":37.714445,"longitude":126.695763,"bookYear":null,"nameKR":"515더오리지널","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":5413,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":36.3999970930142,"longitude":127.087118746685,"bookYear":null,"nameKR":"주봉마을우렁촌","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":33950,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/theroad101_gwisan/","foodTypes":"디저트/차/베이커리","latitude":35.1544143524543,"longitude":128.610115590143,"bookYear":null,"nameKR":"더로드101귀산","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":11132,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.202485,"longitude":129.083027,"bookYear":null,"nameKR":"함경면옥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":32337,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.7303740090292,"longitude":126.704533350838,"bookYear":null,"nameKR":"말똥도넛디저트타운","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32321,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.3973207561987,"longitude":127.11356226927,"bookYear":null,"nameKR":"마루심(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":34695,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.315638963335,"longitude":128.738793262442,"bookYear":null,"nameKR":"카페데코로","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":33576,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hmmcoffee_roasters/","foodTypes":"디저트/차/베이커리","latitude":37.5201225877069,"longitude":127.185148710842,"bookYear":null,"nameKR":"흠커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":33652,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/breezeweek","foodTypes":"기타","latitude":36.5003617968612,"longitude":127.256130174325,"bookYear":null,"nameKR":"브리즈윅","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":34015,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sushidada22/","foodTypes":"일식","latitude":37.4758566197709,"longitude":126.869949357449,"bookYear":null,"nameKR":"스시다다","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":34171,"bookStatus":"비활성","websiteInstagram":"http://instagram.com/tunahouse_baegot","foodTypes":"일식","latitude":37.3671302469022,"longitude":126.727374793218,"bookYear":null,"nameKR":"참치집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":33096,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/patisserie_sera/","foodTypes":"디저트/차/베이커리","latitude":37.314278607386,"longitude":126.828769160875,"bookYear":null,"nameKR":"세라제과","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":33996,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/butter.meal/","foodTypes":"디저트/차/베이커리","latitude":35.8152548633972,"longitude":128.537786772561,"bookYear":null,"nameKR":"버터밀","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":34410,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/avorio_bakehouse/","foodTypes":"디저트/차/베이커리","latitude":37.6461753129275,"longitude":126.681626660766,"bookYear":null,"nameKR":"아보리오","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":30673,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.380581431049585,"longitude":127.40629139105363,"bookYear":null,"nameKR":"서원골유황오리","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":32677,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/vegeang_vegan","foodTypes":"기타","latitude":37.7199582365293,"longitude":126.714390447605,"bookYear":null,"nameKR":"베지앙","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32930,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/baebaecake_official/","foodTypes":"디저트/차/베이커리","latitude":35.855525009454,"longitude":128.586193227007,"bookYear":null,"nameKR":"베베케이크","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":33984,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/more_greeeen/","foodTypes":"디저트/차/베이커리","latitude":37.7353716743388,"longitude":126.951449135371,"bookYear":null,"nameKR":"모어그린","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":34345,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/120marystreet/","foodTypes":"디저트/차/베이커리","latitude":34.8898507060524,"longitude":128.690854244357,"bookYear":null,"nameKR":"120메리스트릿","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":33100,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ggomaghansang/","foodTypes":"한식(어패류)","latitude":37.6081353097346,"longitude":126.516939560821,"bookYear":null,"nameKR":"꼬막한상","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":33625,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yangwang_._/","foodTypes":"한식(육류)","latitude":35.1222644408419,"longitude":126.86875336167,"bookYear":null,"nameKR":"양왕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":33037,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/lao_chinese/","foodTypes":"중식","latitude":35.600667700408,"longitude":129.361832668018,"bookYear":null,"nameKR":"라오","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":32838,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3471154865701,"longitude":127.110481718964,"bookYear":null,"nameKR":"박가네순대","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32918,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tuffenuffroaster/","foodTypes":"디저트/차/베이커리","latitude":36.9828843479767,"longitude":126.879859223739,"bookYear":null,"nameKR":"터프이너프로스터스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":33987,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.1628870263607,"longitude":128.115515534335,"bookYear":null,"nameKR":"해송돌판닭찜","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":34205,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":37.7403449137734,"longitude":127.048606301549,"bookYear":null,"nameKR":"비밥바","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":34249,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.5074389663119,"longitude":127.605542358903,"bookYear":null,"nameKR":"광이원농가맛집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":17051,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"선운산풍천장어","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":9210,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.36081,"longitude":127.106525,"bookYear":null,"nameKR":"호접몽","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":13081,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.474557,"longitude":126.618591,"bookYear":null,"nameKR":"대창반점","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":33125,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gooddaytorelax/","foodTypes":"한식(일반한식)","latitude":35.2301571899844,"longitude":127.644351870784,"bookYear":null,"nameKR":"쉬어가기좋은날식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":33332,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hongsung_sapdari_gopchang/","foodTypes":"한식(육류)","latitude":36.6052890742253,"longitude":126.662620249308,"bookYear":null,"nameKR":"삽다리곱창","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":10599,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.100192,"longitude":128.822007,"bookYear":null,"nameKR":"송원왕갈비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":25618,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"기타","latitude":36.003647,"longitude":128.413468,"bookYear":null,"nameKR":"한미식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":10579,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.6829766769536,"longitude":127.921856003055,"bookYear":null,"nameKR":"왕우렁쌈밥약닭약오리탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거창군","status":"ACTIVE"},
        {"id":21489,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":35.5703290129379,"longitude":128.162028436841,"bookYear":null,"nameKR":"순할머니손칼국수","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"합천군","status":"ACTIVE"},
        {"id":22280,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.840748,"longitude":128.603708,"bookYear":null,"nameKR":"우미가","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":26425,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.6544727613923,"longitude":126.623521838836,"bookYear":null,"nameKR":"빅토리통닭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":25635,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1728720214496,"longitude":129.174192987288,"bookYear":null,"nameKR":"기장곰장어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":35201,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":35.153006221653,"longitude":129.059606320437,"bookYear":null,"nameKR":"솥이랑(서면점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":35198,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1571773819704,"longitude":126.879463244108,"bookYear":null,"nameKR":"심경희 라라랜드","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":35182,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"바/주점","latitude":35.1319136248552,"longitude":126.910275168626,"bookYear":null,"nameKR":"파쿠야(봉선점)","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":11178,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.184352,"longitude":129.107454,"bookYear":null,"nameKR":"경주박가국밥(토곡점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":2022,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.0938082843877,"longitude":128.819647778903,"bookYear":null,"nameKR":"동원횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":21860,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.6619258028574,"longitude":126.750807663885,"bookYear":null,"nameKR":"죽림소노캄","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":11624,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(민물어패류)","latitude":37.241441,"longitude":127.171301,"bookYear":null,"nameKR":"우렁각시","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":22767,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.181709,"longitude":129.072816,"bookYear":null,"nameKR":"소문난가야밀면","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":34688,"bookStatus":"비활성","websiteInstagram":"https://instagram.com/atwaterstation_kr?igshid=NTE5MzUyOTU=","foodTypes":"이탈리아식","latitude":37.4979794489989,"longitude":126.723545900855,"bookYear":null,"nameKR":"엣워터스테이션","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":31465,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.6981289889361,"longitude":127.151980347314,"bookYear":null,"nameKR":"능소비빔국수(소정점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":12650,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.360779,"longitude":127.290724,"bookYear":null,"nameKR":"숯골원냉면(갑동점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":26709,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":37.437625,"longitude":126.787341,"bookYear":null,"nameKR":"원조닭탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":20750,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.334471,"longitude":127.064461,"bookYear":null,"nameKR":"산뜨락","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":23857,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.385254,"longitude":127.129512,"bookYear":null,"nameKR":"정가네능이백숙","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":6047,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4562946156215,"longitude":127.009186798781,"bookYear":null,"nameKR":"한성칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":7763,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.367546,"longitude":127.380016,"bookYear":null,"nameKR":"신촌설렁탕","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":11092,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.172467,"longitude":128.914957,"bookYear":null,"nameKR":"황금오리알","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":32534,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_soom/","foodTypes":"디저트/차/베이커리","latitude":37.787586514539,"longitude":127.163829851607,"bookYear":null,"nameKR":"카페숨","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":32960,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/we.are.nongdo","foodTypes":"한식(일반한식)","latitude":35.5587130790602,"longitude":129.083313851217,"bookYear":null,"nameKR":"농도","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":34299,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":34.6083569397953,"longitude":127.286600492596,"bookYear":null,"nameKR":"팥죽이야기","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"고흥군","status":"ACTIVE"},
        {"id":34638,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.8429634183545,"longitude":128.487710004883,"bookYear":null,"nameKR":"박기태명장의 장수당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":34604,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/espresso_planet_coffee/","foodTypes":"디저트/차/베이커리","latitude":37.3514020956173,"longitude":126.74185573009,"bookYear":null,"nameKR":"에스프레소플래닛","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":32436,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_m_cliff/","foodTypes":"디저트/차/베이커리","latitude":37.4258423040434,"longitude":126.419928442491,"bookYear":null,"nameKR":"엠클리프","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(영종도)","status":"ACTIVE"},
        {"id":2084,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.0697647324058,"longitude":129.401045625438,"bookYear":null,"nameKR":"하봉석회대게타운","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":2342,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.475343,"longitude":126.619587,"bookYear":null,"nameKR":"공화춘","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":34421,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bakery_space_1st/","foodTypes":"디저트/차/베이커리","latitude":37.8170379818173,"longitude":127.096999736355,"bookYear":null,"nameKR":"베이커리공간","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":19922,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_pat/","foodTypes":"디저트/차/베이커리","latitude":35.131001,"longitude":129.092302,"bookYear":null,"nameKR":"엄마손팥집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":26787,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.144812,"longitude":127.367214,"bookYear":null,"nameKR":"알렉스더커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":5473,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.488088027878376,"longitude":127.72523203541648,"bookYear":null,"nameKR":"이원식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":34381,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":35.8924233730857,"longitude":128.607177353928,"bookYear":null,"nameKR":"마사","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":34338,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.4003793746832,"longitude":127.10208724198,"bookYear":null,"nameKR":"혼키라멘","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":34234,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/minam__on/","latitude":35.9691926167106,"longitude":129.409955567742,"bookYear":null,"nameKR":"미남장어문덕점","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":34297,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/monanpetit/","foodTypes":"아시아식","latitude":37.3698018839388,"longitude":127.106485232427,"bookYear":null,"nameKR":"몬안베띠(정자점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":9725,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.770455,"longitude":126.43596,"bookYear":null,"nameKR":"송가네한우탕","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":22588,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.864688,"longitude":128.594055,"bookYear":null,"nameKR":"명성회초밥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":25376,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.232702,"longitude":129.086058,"bookYear":null,"nameKR":"김해원조뒷고기","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":32422,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bunkerhill_brunchcafe/","foodTypes":"디저트/차/베이커리","latitude":37.7789605747622,"longitude":126.690633276103,"bookYear":null,"nameKR":"벙커힐","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":5311,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/terrace.kitchen_sungsimdang/","foodTypes":"이탈리아식","latitude":36.327718,"longitude":127.427252,"bookYear":null,"nameKR":"테라스키친","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":3009,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":36.359292,"longitude":127.632226,"bookYear":null,"nameKR":"뿌리깊은나무","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":21223,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.6851563203725,"longitude":126.317510490357,"bookYear":null,"nameKR":"춘하추동","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":24315,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.18289,"longitude":129.210284,"bookYear":null,"nameKR":"제이엠커피로스터스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":30905,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/foretm556/","foodTypes":"디저트/차/베이커리","latitude":37.7774304661431,"longitude":126.950463382901,"bookYear":null,"nameKR":"포레엠","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":30943,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.4505353403177,"longitude":127.470951995252,"bookYear":null,"nameKR":"카페담","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":11700,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.35235,"longitude":126.824359,"bookYear":null,"nameKR":"길손집시골밥상","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":34075,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_oneray/","foodTypes":"디저트/차/베이커리","latitude":37.2560400190044,"longitude":127.040052495581,"bookYear":null,"nameKR":"원레이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":2931,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.960365810841516,"longitude":128.92814620007087,"bookYear":null,"nameKR":"편대장영화식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영천시","status":"ACTIVE"},
        {"id":31831,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/churchanddancehall","foodTypes":"디저트/차/베이커리","latitude":36.3257332233983,"longitude":127.426717191761,"bookYear":null,"nameKR":"처치앤댄스홀","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":30018,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":36.92508007357132,"longitude":127.42864601252425,"bookYear":null,"nameKR":"진천막국수","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"진천군","status":"ACTIVE"},
        {"id":16712,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.265597,"longitude":127.034846,"bookYear":null,"nameKR":"조대감부대찌개","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":14839,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":34.952008,"longitude":127.487301,"bookYear":null,"nameKR":"순광식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":24045,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":37.449275,"longitude":126.994446,"bookYear":null,"nameKR":"봉평메밀막국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":31766,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/osteria.laluna","foodTypes":"이탈리아식","latitude":35.2279434541836,"longitude":128.585803740235,"bookYear":null,"nameKR":"라루나","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":30672,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":36.31913758804701,"longitude":127.43048904167281,"bookYear":null,"nameKR":"동소예","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":12005,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.2115152867532,"longitude":127.452443755485,"bookYear":null,"nameKR":"풍년골","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":33977,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ggagga.zip/","foodTypes":"디저트/차/베이커리","latitude":37.3796873474197,"longitude":126.960152483699,"bookYear":null,"nameKR":"까까집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":18873,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":35.1532278388789,"longitude":129.061263035783,"bookYear":null,"nameKR":"라마앤바바나(서면점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":22565,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중남미식","latitude":35.167187,"longitude":129.171778,"bookYear":null,"nameKR":"아티스타","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":7015,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.166932,"longitude":129.1333,"bookYear":null,"nameKR":"밍주(센텀점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":11420,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(가금류)","latitude":35.168651,"longitude":129.141469,"bookYear":null,"nameKR":"수비가든","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":34065,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":34.5264364731259,"longitude":127.135342371401,"bookYear":null,"nameKR":"소신2020","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"고흥군","status":"ACTIVE"},
        {"id":32171,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/playcoffee_cake","foodTypes":"디저트/차/베이커리","latitude":35.1602820721041,"longitude":126.888356383387,"bookYear":null,"nameKR":"플레이커피","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":33838,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ollirolli_/","foodTypes":"디저트/차/베이커리","latitude":37.4028382808729,"longitude":126.96580038804,"bookYear":null,"nameKR":"올리롤리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":33922,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.1243802794733,"longitude":129.112038580419,"bookYear":null,"nameKR":"한끼뚝딱","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":7494,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":35.137809,"longitude":129.105921,"bookYear":null,"nameKR":"초원복국(영도동점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":33628,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cc.pork/","foodTypes":"한식(육류)","latitude":35.8225160251182,"longitude":128.547796977605,"bookYear":null,"nameKR":"월촌역청춘삼겹살","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":24346,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":36.504155,"longitude":127.271809,"bookYear":null,"nameKR":"봉피양(세종시점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":1952,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.668517,"longitude":126.762979,"bookYear":null,"nameKR":"취옹","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":4028,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.570598,"longitude":126.598972,"bookYear":null,"nameKR":"시장식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"해남군","status":"ACTIVE"},
        {"id":1770,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.493788,"longitude":127.473637,"bookYear":null,"nameKR":"길조","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":4755,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":34.947062,"longitude":127.52554,"bookYear":null,"nameKR":"일품매우(순천점)","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":2381,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.760248,"longitude":126.466274,"bookYear":null,"nameKR":"푸른언덕","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":2400,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.449111,"longitude":126.82165,"bookYear":null,"nameKR":"남도일번지횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":2903,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":34.841122,"longitude":128.422274,"bookYear":null,"nameKR":"항남우짜","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":4145,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.659408,"longitude":126.770371,"bookYear":null,"nameKR":"베스코","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":4227,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.392254,"longitude":126.956535,"bookYear":null,"nameKR":"산타루치아","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":4851,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.369713,"longitude":127.106064,"bookYear":null,"nameKR":"JZ카페","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":6276,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.656669,"longitude":126.773139,"bookYear":null,"nameKR":"신의돈부리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":3209,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.21765,"longitude":129.081747,"bookYear":null,"nameKR":"동신참치","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":4358,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.454377,"longitude":126.607349,"bookYear":null,"nameKR":"민영바다식당","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":2633,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.818007,"longitude":127.133714,"bookYear":null,"nameKR":"강릉집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":4046,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.122646,"longitude":126.884987,"bookYear":null,"nameKR":"정가네옹기맛","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":4397,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.225942,"longitude":127.370344,"bookYear":null,"nameKR":"맛내음터","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":4040,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.121293,"longitude":126.917533,"bookYear":null,"nameKR":"데이지","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":4187,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.517339,"longitude":129.306036,"bookYear":null,"nameKR":"밤나무집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":4447,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.808146,"longitude":127.147482,"bookYear":null,"nameKR":"태극당","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":6012,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":35.893522,"longitude":128.609594,"bookYear":null,"nameKR":"팀스쿠치나","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":6221,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.194985,"longitude":127.080638,"bookYear":null,"nameKR":"드시계","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":6272,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.66337,"longitude":126.765972,"bookYear":null,"nameKR":"이승철24시동태집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":6293,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.263401,"longitude":127.029977,"bookYear":null,"nameKR":"명동보리밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6342,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.265503,"longitude":127.031733,"bookYear":null,"nameKR":"첨","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6636,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.788209,"longitude":126.702864,"bookYear":null,"nameKR":"원투커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":7123,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.439858,"longitude":127.009726,"bookYear":null,"nameKR":"봉덕칼국수(과천점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":7395,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.674009,"longitude":126.849154,"bookYear":null,"nameKR":"일산교자","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":7416,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.388057,"longitude":127.352337,"bookYear":null,"nameKR":"숯골원냉면갈비(국립현충원점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":7602,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.830363,"longitude":128.61767,"bookYear":null,"nameKR":"녹양구이(두산동점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":7608,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.310682,"longitude":127.372491,"bookYear":null,"nameKR":"띠울석갈비(도마점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":7566,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.846044,"longitude":126.789156,"bookYear":null,"nameKR":"오두산막국수(문산통일로점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":7567,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.779151,"longitude":126.686711,"bookYear":null,"nameKR":"오두산막국수(통일동산직영점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":7581,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.369559,"longitude":127.113481,"bookYear":null,"nameKR":"제일콩집(분당점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":7299,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.32755,"longitude":127.097018,"bookYear":null,"nameKR":"함지박(수지점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":7489,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.600777,"longitude":126.652276,"bookYear":null,"nameKR":"소복갈비(홍성점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":7496,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.19405,"longitude":128.801538,"bookYear":null,"nameKR":"초원복국(장유점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":7554,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.98561,"longitude":126.8681,"bookYear":null,"nameKR":"최네집(안중점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":7430,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.988836,"longitude":127.927191,"bookYear":null,"nameKR":"오막집(충주점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":7462,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.613282,"longitude":127.469436,"bookYear":null,"nameKR":"소영칼국수(산남점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":7473,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.992416,"longitude":128.398113,"bookYear":null,"nameKR":"남산식육식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":7477,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.85663,"longitude":128.62522,"bookYear":null,"nameKR":"편대장영화식당(대구범어점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":7483,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.263623,"longitude":129.086537,"bookYear":null,"nameKR":"옥미아구찜(남산점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":7699,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.783258,"longitude":126.988994,"bookYear":null,"nameKR":"밀터(방축점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":7735,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.739093,"longitude":127.048728,"bookYear":null,"nameKR":"두르가(의정부점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":9333,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.844664,"longitude":129.285081,"bookYear":null,"nameKR":"아사카와","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":9344,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.844664,"longitude":129.285081,"bookYear":null,"nameKR":"아리랑","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":9345,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.787592,"longitude":129.321733,"bookYear":null,"nameKR":"계림","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":9349,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.221843,"longitude":128.677174,"bookYear":null,"nameKR":"가야당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":9388,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.840503,"longitude":129.285494,"bookYear":null,"nameKR":"레이크사이드","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":9392,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":36.666548,"longitude":127.494375,"bookYear":null,"nameKR":"카페그랜드","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":9393,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.538372,"longitude":129.338244,"bookYear":null,"nameKR":"페닌슐라(롯데울산)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":9407,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.907032,"longitude":128.61132,"bookYear":null,"nameKR":"예그리나","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":9453,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.840503,"longitude":129.285494,"bookYear":null,"nameKR":"실크로드","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":9698,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.776855,"longitude":127.574483,"bookYear":null,"nameKR":"황부자","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9717,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.786304,"longitude":127.583632,"bookYear":null,"nameKR":"원갈비","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9723,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.778976,"longitude":126.46121,"bookYear":null,"nameKR":"등촌샤브칼국수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9949,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.669867,"longitude":127.085975,"bookYear":null,"nameKR":"해돋이횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9956,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.829108,"longitude":127.152407,"bookYear":null,"nameKR":"백야식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9964,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.947048,"longitude":127.526552,"bookYear":null,"nameKR":"제주갈치고등어전문점","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":9965,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.948513,"longitude":127.513146,"bookYear":null,"nameKR":"용전갈치","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":9967,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.948371,"longitude":127.48974,"bookYear":null,"nameKR":"정락회관","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":9968,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.068846,"longitude":127.503296,"bookYear":null,"nameKR":"산수정","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":9975,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":34.949318,"longitude":127.490076,"bookYear":null,"nameKR":"효동회관","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":9980,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.951258,"longitude":127.486487,"bookYear":null,"nameKR":"호남식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10716,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.097512,"longitude":128.820036,"bookYear":null,"nameKR":"미락정복집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":10722,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.40453,"longitude":129.375251,"bookYear":null,"nameKR":"남일가든","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":10740,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.185578,"longitude":128.093002,"bookYear":null,"nameKR":"본토비빔밥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":10982,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.086687,"longitude":127.72158,"bookYear":null,"nameKR":"만지횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":10999,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.50722,"longitude":129.448011,"bookYear":null,"nameKR":"등대회식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":11007,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.508317,"longitude":129.449633,"bookYear":null,"nameKR":"일출회타운","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":11492,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":35.219814,"longitude":129.082072,"bookYear":null,"nameKR":"리스또란떼","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":9657,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.782582,"longitude":127.579095,"bookYear":null,"nameKR":"본가","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9665,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.78698,"longitude":126.447239,"bookYear":null,"nameKR":"만리장성","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9675,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.782479,"longitude":127.583257,"bookYear":null,"nameKR":"영춘관","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9683,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.765052,"longitude":126.60211,"bookYear":null,"nameKR":"고향가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9690,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.78542,"longitude":127.584304,"bookYear":null,"nameKR":"선비촌족발보쌈","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9692,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.779402,"longitude":127.578324,"bookYear":null,"nameKR":"태양식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9913,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":34.77264,"longitude":127.080917,"bookYear":null,"nameKR":"삼나무숲길따라","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9923,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.837095,"longitude":127.329739,"bookYear":null,"nameKR":"다성촌","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9933,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.836369,"longitude":127.411744,"bookYear":null,"nameKR":"두무포산장","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9943,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.670417,"longitude":127.086763,"bookYear":null,"nameKR":"만리회관","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9948,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.650027,"longitude":127.058777,"bookYear":null,"nameKR":"푸른바다횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":10176,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.449075,"longitude":126.820862,"bookYear":null,"nameKR":"장수회타운","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":10177,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.449077,"longitude":126.823369,"bookYear":null,"nameKR":"정든횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":10181,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.589705,"longitude":126.769794,"bookYear":null,"nameKR":"다산정","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":10425,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.47369,"longitude":126.597134,"bookYear":null,"nameKR":"원조부산활어회","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":10435,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.475934,"longitude":126.598228,"bookYear":null,"nameKR":"해저회집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":10709,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.41032,"longitude":129.367745,"bookYear":null,"nameKR":"수석분식","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":10948,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.563668,"longitude":128.732317,"bookYear":null,"nameKR":"안동한우갈비","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":11207,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.275753,"longitude":129.088286,"bookYear":null,"nameKR":"풍년오리박사","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":9616,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.778821,"longitude":126.464619,"bookYear":null,"nameKR":"서령산아구","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9630,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.755993,"longitude":126.477723,"bookYear":null,"nameKR":"아담농원가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9637,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.769872,"longitude":126.60212,"bookYear":null,"nameKR":"예원가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9640,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.78655,"longitude":126.447365,"bookYear":null,"nameKR":"늘푸른쌈밥","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9643,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.781579,"longitude":127.573025,"bookYear":null,"nameKR":"가미본가","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9890,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.597947,"longitude":126.661212,"bookYear":null,"nameKR":"대봉한정식","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":10134,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.27637,"longitude":126.500219,"bookYear":null,"nameKR":"향미림","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":10138,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.276794,"longitude":126.511222,"bookYear":null,"nameKR":"여수회관","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":10144,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.24207,"longitude":126.501832,"bookYear":null,"nameKR":"고목나무","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":10148,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.394746,"longitude":126.444728,"bookYear":null,"nameKR":"복화정","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":10642,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.109561,"longitude":127.809828,"bookYear":null,"nameKR":"청하식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":10904,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.086532,"longitude":127.721935,"bookYear":null,"nameKR":"재첩잡는선장집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":10910,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.086765,"longitude":127.722244,"bookYear":null,"nameKR":"청옥산장","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":10919,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.18728,"longitude":128.090734,"bookYear":null,"nameKR":"남가람청국장마을","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":11163,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.1934,"longitude":128.985859,"bookYear":null,"nameKR":"하동재첩국","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":11170,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.192711,"longitude":129.099425,"bookYear":null,"nameKR":"새미골해물아구찜","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":11175,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.185178,"longitude":129.079872,"bookYear":null,"nameKR":"가야할매밀면","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":11177,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.20467,"longitude":129.0821,"bookYear":null,"nameKR":"청담","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":9590,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.606173,"longitude":126.414801,"bookYear":null,"nameKR":"서산횟집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9601,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.827481,"longitude":126.327349,"bookYear":null,"nameKR":"황해횟집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9602,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.827754,"longitude":126.327777,"bookYear":null,"nameKR":"구도횟집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9606,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daedogwan/","foodTypes":"한식(일반한식)","latitude":36.784904,"longitude":126.446058,"bookYear":null,"nameKR":"대도관","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9614,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.757282,"longitude":126.604463,"bookYear":null,"nameKR":"푸른산장가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":10084,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.139231,"longitude":126.390964,"bookYear":null,"nameKR":"안악칠산횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":10086,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.064881,"longitude":126.517273,"bookYear":null,"nameKR":"남매식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":10596,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.13997,"longitude":128.703,"bookYear":null,"nameKR":"이가숯불갈비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":10610,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.148555,"longitude":128.660876,"bookYear":null,"nameKR":"백우숯불갈비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":10850,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.187582,"longitude":128.091108,"bookYear":null,"nameKR":"영빈가든","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":10868,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.418164,"longitude":127.876313,"bookYear":null,"nameKR":"자연촌","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":10881,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.29541,"longitude":127.9588,"bookYear":null,"nameKR":"성화식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":10882,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.301456,"longitude":127.970089,"bookYear":null,"nameKR":"암소참나무숯불갈비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":10890,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.08754,"longitude":127.719701,"bookYear":null,"nameKR":"이화가든","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":11130,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.071005,"longitude":129.068879,"bookYear":null,"nameKR":"제주복국","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":11145,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.16864,"longitude":129.036525,"bookYear":null,"nameKR":"바다횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":9535,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.746285,"longitude":126.585141,"bookYear":null,"nameKR":"고목나무가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":10045,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":34.951224,"longitude":127.521453,"bookYear":null,"nameKR":"장군초밥","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10050,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.949282,"longitude":127.515058,"bookYear":null,"nameKR":"국일설렁탕","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10054,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.948229,"longitude":127.52357,"bookYear":null,"nameKR":"녹동해물탕","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10069,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.946217,"longitude":127.525116,"bookYear":null,"nameKR":"석암정돌솥밥","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10811,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.141554,"longitude":128.710502,"bookYear":null,"nameKR":"한우마을식육식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":10825,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.672938,"longitude":128.957192,"bookYear":null,"nameKR":"울산아지매집","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":11102,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.102439,"longitude":129.000453,"bookYear":null,"nameKR":"대티물꽁","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":11104,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.26048,"longitude":129.0894,"bookYear":null,"nameKR":"함안매운탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":11345,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.086285,"longitude":129.024132,"bookYear":null,"nameKR":"금호복요리전문점","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":9511,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.773097,"longitude":127.593818,"bookYear":null,"nameKR":"산촌묵밥","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9762,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.286952,"longitude":127.236732,"bookYear":null,"nameKR":"용인토종순대국","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"계룡시","status":"ACTIVE"},
        {"id":10006,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":34.892562,"longitude":127.509585,"bookYear":null,"nameKR":"순천만가든","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10016,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.004978,"longitude":127.265405,"bookYear":null,"nameKR":"광신식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10036,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":34.940085,"longitude":127.503614,"bookYear":null,"nameKR":"향토추어탕","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10039,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":34.95517,"longitude":127.485037,"bookYear":null,"nameKR":"도미초밥","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10777,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.09399,"longitude":128.820069,"bookYear":null,"nameKR":"청해횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":10799,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.948504,"longitude":127.860039,"bookYear":null,"nameKR":"금오횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":10805,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.94104,"longitude":127.876283,"bookYear":null,"nameKR":"용궁횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":10807,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.940769,"longitude":127.873779,"bookYear":null,"nameKR":"곰바우횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":11031,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.301389,"longitude":127.968807,"bookYear":null,"nameKR":"백조횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":11041,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.970594,"longitude":128.943522,"bookYear":null,"nameKR":"대림매운탕","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영천시","status":"ACTIVE"},
        {"id":11043,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.965368,"longitude":128.918567,"bookYear":null,"nameKR":"대한식육식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영천시","status":"ACTIVE"},
        {"id":11060,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.971667,"longitude":128.937634,"bookYear":null,"nameKR":"제주회초밥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영천시","status":"ACTIVE"},
        {"id":11061,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.9718,"longitude":128.938766,"bookYear":null,"nameKR":"초원숯불가든","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영천시","status":"ACTIVE"},
        {"id":11064,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.986193,"longitude":129.098659,"bookYear":null,"nameKR":"홍진한정식","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영천시","status":"ACTIVE"},
        {"id":9478,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.778785,"longitude":126.465234,"bookYear":null,"nameKR":"예당숯불갈비","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9984,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":34.948378,"longitude":127.517411,"bookYear":null,"nameKR":"명가촌","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":9990,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":34.906515,"longitude":127.340782,"bookYear":null,"nameKR":"민속잔치집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":9993,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":34.909552,"longitude":127.339043,"bookYear":null,"nameKR":"녹수식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":9994,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.906261,"longitude":127.341851,"bookYear":null,"nameKR":"동문고향집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10757,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.112935,"longitude":128.835114,"bookYear":null,"nameKR":"경주골가든","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":11018,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.351672,"longitude":128.697667,"bookYear":null,"nameKR":"대풍회","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"의성군","status":"ACTIVE"},
        {"id":11704,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.314673,"longitude":126.861072,"bookYear":null,"nameKR":"시골홍두깨칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11705,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.309391,"longitude":126.869472,"bookYear":null,"nameKR":"일동토종순대감자탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11710,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.282482,"longitude":126.847046,"bookYear":null,"nameKR":"이천영양돌솥밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11740,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.335994,"longitude":126.908386,"bookYear":null,"nameKR":"정선달가마솥밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11745,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.386676,"longitude":126.933668,"bookYear":null,"nameKR":"한촌설렁탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":11774,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.319123,"longitude":126.829873,"bookYear":null,"nameKR":"천하일미","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11990,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.656576,"longitude":127.274683,"bookYear":null,"nameKR":"도봉산갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":12004,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.311371,"longitude":127.401073,"bookYear":null,"nameKR":"이천돌솥밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":12218,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.895835,"longitude":126.975855,"bookYear":null,"nameKR":"만인식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12230,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.868728,"longitude":127.009698,"bookYear":null,"nameKR":"양주골초계탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12242,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.777344,"longitude":126.936713,"bookYear":null,"nameKR":"뫼골","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12249,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.777078,"longitude":126.945041,"bookYear":null,"nameKR":"기산약수촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12277,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.38106,"longitude":127.569754,"bookYear":null,"nameKR":"두메촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":11623,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.229275,"longitude":127.301132,"bookYear":null,"nameKR":"우리소","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":11626,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.23061,"longitude":127.288227,"bookYear":null,"nameKR":"산마루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":11628,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.194658,"longitude":127.326165,"bookYear":null,"nameKR":"신촌댁","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":11630,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.290619,"longitude":127.23552,"bookYear":null,"nameKR":"포곡올갱이해장국","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":11635,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.372141,"longitude":126.935243,"bookYear":null,"nameKR":"수리산두부마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11640,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.357884,"longitude":126.93388,"bookYear":null,"nameKR":"완산정","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11644,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.35897,"longitude":126.932602,"bookYear":null,"nameKR":"송수사","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11653,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.297591,"longitude":126.863403,"bookYear":null,"nameKR":"김명자낙지마당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11654,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.358098,"longitude":126.948193,"bookYear":null,"nameKR":"촛불조방낙지","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11657,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.359153,"longitude":126.932944,"bookYear":null,"nameKR":"부산복해물칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11662,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.358528,"longitude":126.931771,"bookYear":null,"nameKR":"이조숯불갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11666,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.365237,"longitude":126.930003,"bookYear":null,"nameKR":"춘하추동","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11697,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.359335,"longitude":126.922619,"bookYear":null,"nameKR":"이명옥시골손두부","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11868,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.682913,"longitude":127.108937,"bookYear":null,"nameKR":"수인씨의마당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11883,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.539868,"longitude":127.255213,"bookYear":null,"nameKR":"팔당강변회관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11914,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.785773,"longitude":127.463737,"bookYear":null,"nameKR":"빗고개청국장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":11915,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.815393,"longitude":127.501619,"bookYear":null,"nameKR":"무교동미락낙지","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":11919,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.799064,"longitude":127.48234,"bookYear":null,"nameKR":"시골밥상닭갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":11943,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.742632,"longitude":127.352573,"bookYear":null,"nameKR":"아침고요식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":11953,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.64605,"longitude":127.36404,"bookYear":null,"nameKR":"전주시골밥상","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":12126,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.78496,"longitude":126.994,"bookYear":null,"nameKR":"황우촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12142,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.823813,"longitude":126.982244,"bookYear":null,"nameKR":"청진도가니탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12153,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.785546,"longitude":127.02396,"bookYear":null,"nameKR":"송추부일기사식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12157,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.784536,"longitude":127.029363,"bookYear":null,"nameKR":"양주골전통순대국","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12175,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.793364,"longitude":127.096694,"bookYear":null,"nameKR":"동방두부마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12177,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.7447,"longitude":126.951005,"bookYear":null,"nameKR":"초가마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12184,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.744229,"longitude":126.950952,"bookYear":null,"nameKR":"신선산장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12185,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.722526,"longitude":126.946987,"bookYear":null,"nameKR":"피자성효인방","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":11547,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.290508,"longitude":127.011538,"bookYear":null,"nameKR":"리틀본수원갈비집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":11555,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.287462,"longitude":127.016884,"bookYear":null,"nameKR":"연포갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":11571,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.294563,"longitude":127.118752,"bookYear":null,"nameKR":"춘향골할매추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":11587,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.233746,"longitude":127.200545,"bookYear":null,"nameKR":"춘향골남원추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":11604,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.489819,"longitude":126.755302,"bookYear":null,"nameKR":"산채시골집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":11812,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.746977,"longitude":127.18756,"bookYear":null,"nameKR":"큰대문집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11818,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.66778,"longitude":127.119928,"bookYear":null,"nameKR":"바위와소나무","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11820,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.680523,"longitude":127.209459,"bookYear":null,"nameKR":"천안문손짜장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11823,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.7328,"longitude":127.1949,"bookYear":null,"nameKR":"광릉정육식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11833,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.586147,"longitude":127.1733,"bookYear":null,"nameKR":"두레박","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11835,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.585757,"longitude":127.173023,"bookYear":null,"nameKR":"강마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11837,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.593002,"longitude":127.173961,"bookYear":null,"nameKR":"고향가는길","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11862,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.640851,"longitude":127.163198,"bookYear":null,"nameKR":"물맑은집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":12069,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.111343,"longitude":127.549916,"bookYear":null,"nameKR":"이천쌀밥송학(설성점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":12070,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.114655,"longitude":127.612999,"bookYear":null,"nameKR":"장수촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":12071,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.482536,"longitude":126.780348,"bookYear":null,"nameKR":"고구려삼계탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":12080,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.496132,"longitude":126.765601,"bookYear":null,"nameKR":"원조마포소금구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":12090,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.060159,"longitude":127.190306,"bookYear":null,"nameKR":"약수터식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":12093,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.008643,"longitude":127.436839,"bookYear":null,"nameKR":"시골집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":12100,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.035442,"longitude":127.262529,"bookYear":null,"nameKR":"빛고을가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":12103,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.013291,"longitude":127.251732,"bookYear":null,"nameKR":"정성회관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":12112,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.51978,"longitude":126.790026,"bookYear":null,"nameKR":"조박사아구복","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":12325,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.785959,"longitude":126.910685,"bookYear":null,"nameKR":"해든터","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":12330,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.910574,"longitude":126.733535,"bookYear":null,"nameKR":"장단콩두부마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":12332,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.886018,"longitude":126.794277,"bookYear":null,"nameKR":"장단가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":12343,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.996801,"longitude":126.987076,"bookYear":null,"nameKR":"남포촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":12354,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.621883,"longitude":126.722614,"bookYear":null,"nameKR":"풀잎속의생명","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":11533,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/seoksanjeong/","foodTypes":"한식(육류)","latitude":37.244387,"longitude":127.014855,"bookYear":null,"nameKR":"석산정","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":11779,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.332352,"longitude":126.758696,"bookYear":null,"nameKR":"신길장어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11783,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.326892,"longitude":126.752788,"bookYear":null,"nameKR":"만해두꺼비부대찌개","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11785,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.333135,"longitude":126.777665,"bookYear":null,"nameKR":"진삼초가원누룽닭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11786,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.332555,"longitude":126.793848,"bookYear":null,"nameKR":"원조게장정식","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11788,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.353796,"longitude":126.809258,"bookYear":null,"nameKR":"우산리산장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11792,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.326972,"longitude":126.918892,"bookYear":null,"nameKR":"봉평메밀막국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11794,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.6686,"longitude":127.4839,"bookYear":null,"nameKR":"황금어장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":12055,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.253378,"longitude":127.489177,"bookYear":null,"nameKR":"중화요리산수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":12058,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.269055,"longitude":127.434864,"bookYear":null,"nameKR":"옛집염소탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":12290,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.20979,"longitude":127.664586,"bookYear":null,"nameKR":"화림가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":12297,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.298308,"longitude":127.654385,"bookYear":null,"nameKR":"명성회관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":12300,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.297721,"longitude":127.639603,"bookYear":null,"nameKR":"여주쌀밥집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":12304,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.270603,"longitude":127.637426,"bookYear":null,"nameKR":"교리여주쌀밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":12444,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.674395,"longitude":126.642697,"bookYear":null,"nameKR":"신토불이묵집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":12451,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.255537,"longitude":127.073348,"bookYear":null,"nameKR":"예랑","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":12461,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.388982,"longitude":127.122541,"bookYear":null,"nameKR":"바순","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":12499,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.853238,"longitude":128.62679,"bookYear":null,"nameKR":"르배(범어점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":12524,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.851532,"longitude":128.515978,"bookYear":null,"nameKR":"프랑세즈베이커리","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":12525,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chezlegout/","foodTypes":"디저트/차/베이커리","latitude":35.854653,"longitude":128.589581,"bookYear":null,"nameKR":"르구","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":12526,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.859178,"longitude":128.519204,"bookYear":null,"nameKR":"레이몬드베이커리","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":12528,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/slow.bread0425/","foodTypes":"디저트/차/베이커리","latitude":36.399359,"longitude":127.401952,"bookYear":null,"nameKR":"슬로우브레드","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":12534,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/provence.bakery/","foodTypes":"디저트/차/베이커리","latitude":35.899765,"longitude":128.616492,"bookYear":null,"nameKR":"프로방스베이커리","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":12588,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.259812,"longitude":127.141514,"bookYear":null,"nameKR":"쌍령해장국(용인점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":12603,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.479338,"longitude":127.869945,"bookYear":null,"nameKR":"백운산장","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":12660,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.972588,"longitude":128.435634,"bookYear":null,"nameKR":"장독대","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":12787,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.878624,"longitude":127.184672,"bookYear":null,"nameKR":"지장산막국수(어룡점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":12792,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.145969,"longitude":128.658774,"bookYear":null,"nameKR":"백어횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":12826,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.004489,"longitude":127.265806,"bookYear":null,"nameKR":"송광사식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":13040,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.65092,"longitude":126.7607,"bookYear":null,"nameKR":"만가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":13070,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.79351,"longitude":127.0967,"bookYear":null,"nameKR":"회령손만두국","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":13116,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.453516,"longitude":126.733271,"bookYear":null,"nameKR":"초심","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":13124,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.707913,"longitude":128.437137,"bookYear":null,"nameKR":"비룡손짜장","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":13125,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.06477,"longitude":129.387188,"bookYear":null,"nameKR":"마라도회식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":13157,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.868604,"longitude":128.608334,"bookYear":null,"nameKR":"황떡(본점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":13200,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eubnae_daegu/","foodTypes":"한식(일반한식)","latitude":35.869605,"longitude":128.599859,"bookYear":null,"nameKR":"읍내밥집","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":13225,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.552622,"longitude":127.211378,"bookYear":null,"nameKR":"디딤돌숨두부집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":13237,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.665009,"longitude":126.848008,"bookYear":null,"nameKR":"올터두부고을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":13247,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.474761,"longitude":127.239304,"bookYear":null,"nameKR":"메밀꽃","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":13266,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.782258,"longitude":126.999011,"bookYear":null,"nameKR":"온양청국장집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":13269,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.857694,"longitude":128.423374,"bookYear":null,"nameKR":"새풍화식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":13277,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.129013,"longitude":126.909301,"bookYear":null,"nameKR":"즉석왕족발","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":13285,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.442391,"longitude":127.155144,"bookYear":null,"nameKR":"중동왕족발","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":13319,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.804286,"longitude":126.396835,"bookYear":null,"nameKR":"용당골","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":13342,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.220504,"longitude":126.879439,"bookYear":null,"nameKR":"원조해남장수촌닭","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":13387,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.659661,"longitude":126.767813,"bookYear":null,"nameKR":"울랄라고릴라","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":13460,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.214969,"longitude":126.876441,"bookYear":null,"nameKR":"나주곰탕하얀집","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":13553,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.64884,"longitude":126.7861,"bookYear":null,"nameKR":"향원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":13566,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.331931,"longitude":127.121692,"bookYear":null,"nameKR":"히카리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":13606,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.025692,"longitude":128.554285,"bookYear":null,"nameKR":"무릉도원","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":13744,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.505405,"longitude":126.749167,"bookYear":null,"nameKR":"연자방아","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":14535,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.612392,"longitude":127.46765,"bookYear":null,"nameKR":"오감만족","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":14834,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.344129,"longitude":127.127817,"bookYear":null,"nameKR":"하인선생","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":14509,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.539351,"longitude":126.472846,"bookYear":null,"nameKR":"어부횟집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":14375,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.497605,"longitude":126.734245,"bookYear":null,"nameKR":"북경반점","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":14395,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.844968,"longitude":129.258055,"bookYear":null,"nameKR":"산성숯불갈비","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":14663,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.816574,"longitude":127.156852,"bookYear":null,"nameKR":"천진","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":14667,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.367572,"longitude":127.31942,"bookYear":null,"nameKR":"청송한우타운","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":14850,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.413038,"longitude":127.131498,"bookYear":null,"nameKR":"만강장어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":14921,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.37042,"longitude":126.9411,"bookYear":null,"nameKR":"가야성","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":15081,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":37.487696,"longitude":126.782593,"bookYear":null,"nameKR":"기라성","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":15303,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.203925,"longitude":129.082348,"bookYear":null,"nameKR":"예원한정식","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":15340,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.755747,"longitude":126.780831,"bookYear":null,"nameKR":"장강","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":15517,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.218183,"longitude":127.077874,"bookYear":null,"nameKR":"동해복집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":15542,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.51583,"longitude":127.3014,"bookYear":null,"nameKR":"옛나루터","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":15848,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.369185,"longitude":127.111467,"bookYear":null,"nameKR":"이남장(정자점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":15253,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.737702,"longitude":127.041587,"bookYear":null,"nameKR":"영의정","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":15454,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.356537,"longitude":127.34443,"bookYear":null,"nameKR":"황우마을","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":15499,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.228152,"longitude":128.677955,"bookYear":null,"nameKR":"신생원","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":16069,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.857481,"longitude":128.430416,"bookYear":null,"nameKR":"야간열차","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":16070,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.857481,"longitude":128.430416,"bookYear":null,"nameKR":"삼수갑산","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":16080,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.59758,"longitude":127.1369,"bookYear":null,"nameKR":"큰집돌솥설렁탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":16260,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.86464,"longitude":128.558149,"bookYear":null,"nameKR":"함지박","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":16405,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.237934,"longitude":128.584965,"bookYear":null,"nameKR":"금옥정","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":16709,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.267097,"longitude":127.030061,"bookYear":null,"nameKR":"양평해장국","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":16726,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.627899,"longitude":126.8887,"bookYear":null,"nameKR":"통일동산두부마을(서오능점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":17081,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.399839,"longitude":126.970109,"bookYear":null,"nameKR":"우경","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":17123,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.300733,"longitude":127.008377,"bookYear":null,"nameKR":"북경오리구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":17398,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.622981,"longitude":127.485499,"bookYear":null,"nameKR":"산호횟집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":17402,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.395043,"longitude":126.964832,"bookYear":null,"nameKR":"빈체로","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":17541,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.390231,"longitude":127.093635,"bookYear":null,"nameKR":"방유당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":17605,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.324363,"longitude":127.127045,"bookYear":null,"nameKR":"다린","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":17620,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.612244,"longitude":127.48627,"bookYear":null,"nameKR":"해담","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":17622,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.22976,"longitude":128.680135,"bookYear":null,"nameKR":"반달집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":17914,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.475564,"longitude":127.240312,"bookYear":null,"nameKR":"한우장군","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":18160,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":36.35469,"longitude":127.342437,"bookYear":null,"nameKR":"어가원","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":17995,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.250168,"longitude":127.080379,"bookYear":null,"nameKR":"영통복요리전문점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":18020,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.846814,"longitude":127.34365,"bookYear":null,"nameKR":"원조수라상꼬막정식","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":18032,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.148079,"longitude":127.075453,"bookYear":null,"nameKR":"문경새재","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":18053,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.439769,"longitude":128.586805,"bookYear":null,"nameKR":"공원숯불갈비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창녕군","status":"ACTIVE"},
        {"id":18347,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.50518,"longitude":126.7524,"bookYear":null,"nameKR":"명인참치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":18657,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.361179,"longitude":127.113149,"bookYear":null,"nameKR":"푸른바다볼테기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":18668,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.165585,"longitude":128.013802,"bookYear":null,"nameKR":"선유정장작구이","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"김천시","status":"ACTIVE"},
        {"id":18616,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.247137,"longitude":129.320124,"bookYear":null,"nameKR":"신라식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":18708,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.475666,"longitude":126.618353,"bookYear":null,"nameKR":"중화원","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":18817,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3689,"longitude":127.106475,"bookYear":null,"nameKR":"봄날에청국장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":18856,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.002735,"longitude":126.452161,"bookYear":null,"nameKR":"바다식당","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":19130,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.663969,"longitude":126.765365,"bookYear":null,"nameKR":"백두한우곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":19157,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.104163,"longitude":128.814664,"bookYear":null,"nameKR":"널마루","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":19383,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.905874,"longitude":127.343284,"bookYear":null,"nameKR":"선비촌","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":19438,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.438619,"longitude":127.126138,"bookYear":null,"nameKR":"평창메밀전","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":19626,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.384063,"longitude":127.112443,"bookYear":null,"nameKR":"타이엔","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":19640,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/williamsburger12/","foodTypes":"기타","latitude":37.384952,"longitude":127.112149,"bookYear":null,"nameKR":"윌리엄스버거","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":19644,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/the_old_stand/","foodTypes":"바/주점","latitude":37.385983,"longitude":127.111411,"bookYear":null,"nameKR":"올드스탠드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":19752,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.203269,"longitude":129.084003,"bookYear":null,"nameKR":"여의도족발","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":19829,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.865684,"longitude":128.619244,"bookYear":null,"nameKR":"영덕횟집","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":20119,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.191926,"longitude":128.081842,"bookYear":null,"nameKR":"장성","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":20135,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":35.194677,"longitude":129.066081,"bookYear":null,"nameKR":"더포","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":20184,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.409962,"longitude":127.130961,"bookYear":null,"nameKR":"군산아구찜","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":20196,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.161764,"longitude":128.983009,"bookYear":null,"nameKR":"미조리초밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":19979,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.128122,"longitude":128.969456,"bookYear":null,"nameKR":"새들원","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":19987,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.93269,"longitude":128.563523,"bookYear":null,"nameKR":"참우양곱창","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":20000,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.989912,"longitude":127.923825,"bookYear":null,"nameKR":"숲속장수촌","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":20639,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jangsoo.gd/","foodTypes":"한식(가금류)","latitude":37.463952,"longitude":126.750561,"bookYear":null,"nameKR":"장수가든","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":20828,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.137517,"longitude":128.702326,"bookYear":null,"nameKR":"정미식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":20854,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.371703,"longitude":126.942539,"bookYear":null,"nameKR":"털보네떡볶이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":20943,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.461857,"longitude":126.784433,"bookYear":null,"nameKR":"예원한정식","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":21361,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.036755,"longitude":129.367959,"bookYear":null,"nameKR":"할매소머리곰탕","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":21447,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafedecosta/","foodTypes":"디저트/차/베이커리","latitude":37.330334,"longitude":127.339162,"bookYear":null,"nameKR":"카페드코스타","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":21455,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.222732,"longitude":127.48258,"bookYear":null,"nameKR":"머들령","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"금산군","status":"ACTIVE"},
        {"id":21477,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.715041,"longitude":126.555961,"bookYear":null,"nameKR":"연호정칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":21484,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":34.977843,"longitude":127.466107,"bookYear":null,"nameKR":"어우림","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":21502,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.566048,"longitude":128.723117,"bookYear":null,"nameKR":"신선식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":21559,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.369256,"longitude":127.10757,"bookYear":null,"nameKR":"행복치킨","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":21560,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.803097,"longitude":127.525359,"bookYear":null,"nameKR":"남이가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":21561,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.322181,"longitude":127.110614,"bookYear":null,"nameKR":"황도바지락손칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":21595,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.32544,"longitude":127.125312,"bookYear":null,"nameKR":"신정면","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":21599,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.337795,"longitude":127.115664,"bookYear":null,"nameKR":"손칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":21612,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.279804,"longitude":127.232666,"bookYear":null,"nameKR":"세인식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":21662,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.420253,"longitude":129.063644,"bookYear":null,"nameKR":"우정식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":21518,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.282426,"longitude":127.017562,"bookYear":null,"nameKR":"대왕칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":21522,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.858038,"longitude":128.529253,"bookYear":null,"nameKR":"녹향구이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":21524,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.846872,"longitude":128.578679,"bookYear":null,"nameKR":"청송숯불갈비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":21528,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.115039,"longitude":128.350117,"bookYear":null,"nameKR":"만리궁","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":21534,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.855932,"longitude":128.588018,"bookYear":null,"nameKR":"새만동식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":21546,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.870529,"longitude":128.570792,"bookYear":null,"nameKR":"시골돼지국밥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":21558,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.433557,"longitude":127.086756,"bookYear":null,"nameKR":"한소반쭈꾸미","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":21685,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.298784,"longitude":129.25784,"bookYear":null,"nameKR":"구룡가식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":21693,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.343804,"longitude":127.110231,"bookYear":null,"nameKR":"금향","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":21715,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.83417,"longitude":127.129963,"bookYear":null,"nameKR":"개성옹고집족발","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":21720,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.332514,"longitude":126.787378,"bookYear":null,"nameKR":"횡성생고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":21724,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.284718,"longitude":127.763488,"bookYear":null,"nameKR":"우천정","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":21742,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.218333,"longitude":128.921797,"bookYear":null,"nameKR":"할매추어탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":21754,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.191687,"longitude":128.085638,"bookYear":null,"nameKR":"중앙집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":21756,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.851616,"longitude":128.624451,"bookYear":null,"nameKR":"신촌약수신촌닭불백","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":21759,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.418451,"longitude":129.276911,"bookYear":null,"nameKR":"할매국밥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":21872,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/Oncheoncheonmelbourne/","foodTypes":"디저트/차/베이커리","latitude":35.191892,"longitude":129.101216,"bookYear":null,"nameKR":"멜버른","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":21873,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.101768,"longitude":128.977029,"bookYear":null,"nameKR":"커피팜","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":21883,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.478152,"longitude":127.18436,"bookYear":null,"nameKR":"산성민속집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":21760,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.184923,"longitude":128.12807,"bookYear":null,"nameKR":"대화식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":21761,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.41664,"longitude":129.2823,"bookYear":null,"nameKR":"장터국밥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":21772,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.791162,"longitude":127.149438,"bookYear":null,"nameKR":"유명통닭","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":21774,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.270773,"longitude":127.71449,"bookYear":null,"nameKR":"할머니손두부","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":21779,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.23308,"longitude":129.008466,"bookYear":null,"nameKR":"장수꼬리곰탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":21782,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.149679,"longitude":129.004206,"bookYear":null,"nameKR":"주례칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":21808,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.882667,"longitude":128.665752,"bookYear":null,"nameKR":"자인식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":21837,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.150735,"longitude":126.896256,"bookYear":null,"nameKR":"허내윤과자점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":21839,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.255832,"longitude":128.636913,"bookYear":null,"nameKR":"그린하우스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":21841,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.298039,"longitude":127.341002,"bookYear":null,"nameKR":"정성을다하는베이커리","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":21844,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.138397,"longitude":128.418167,"bookYear":null,"nameKR":"새로나제과점","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":21866,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/maison_de_owls/","foodTypes":"디저트/차/베이커리","latitude":35.233335,"longitude":129.086192,"bookYear":null,"nameKR":"메종드아올스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":21952,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.359117,"longitude":129.38559,"bookYear":null,"nameKR":"사계절대게직판장","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":21963,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.836301,"longitude":129.28483,"bookYear":null,"nameKR":"조가네떡갈비쌈밥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":21975,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.504454,"longitude":126.804535,"bookYear":null,"nameKR":"솔사랑","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":21980,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.68209,"longitude":126.753532,"bookYear":null,"nameKR":"남궁","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":21923,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.264567,"longitude":127.033427,"bookYear":null,"nameKR":"레시피나인","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":22045,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.3674,"longitude":127.322909,"bookYear":null,"nameKR":"동신수산","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":22060,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":36.800989,"longitude":127.137903,"bookYear":null,"nameKR":"쭈꾸미네","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":22109,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":34.741088,"longitude":127.735305,"bookYear":null,"nameKR":"통만두집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":22203,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.154618,"longitude":129.06594,"bookYear":null,"nameKR":"삼돌식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":22239,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.568183,"longitude":129.2418,"bookYear":null,"nameKR":"화덕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":22240,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/genroku_busan/","foodTypes":"일식","latitude":35.137033,"longitude":129.103279,"bookYear":null,"nameKR":"겐로쿠우동(부산점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":22244,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":34.793564,"longitude":126.680465,"bookYear":null,"nameKR":"대양회관","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영암군","status":"ACTIVE"},
        {"id":22254,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":34.886991,"longitude":127.507672,"bookYear":null,"nameKR":"우리밀해물칼국수","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":22267,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.918583,"longitude":128.295769,"bookYear":null,"nameKR":"이진우참한우","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"성주군","status":"ACTIVE"},
        {"id":22445,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.199076,"longitude":129.111927,"bookYear":null,"nameKR":"미미참족발","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":22463,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.508278,"longitude":126.734275,"bookYear":null,"nameKR":"배두둑마을","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":22135,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/anne_coffee_/","foodTypes":"디저트/차/베이커리","latitude":36.637393,"longitude":127.487292,"bookYear":null,"nameKR":"앤","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":22149,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":36.350698,"longitude":127.380521,"bookYear":null,"nameKR":"쇼1186","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":22152,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.556939,"longitude":127.01918,"bookYear":null,"nameKR":"바람처럼구름처럼","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":22155,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.611895,"longitude":127.465994,"bookYear":null,"nameKR":"시골애","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":22337,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.413998,"longitude":127.263425,"bookYear":null,"nameKR":"공리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":22340,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.411474,"longitude":127.259697,"bookYear":null,"nameKR":"강마을유황오리주물럭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":22343,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.8637,"longitude":128.087993,"bookYear":null,"nameKR":"여주박상궁맛집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":22349,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.281425,"longitude":127.443484,"bookYear":null,"nameKR":"제일갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":22357,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.180994,"longitude":126.928553,"bookYear":null,"nameKR":"완도금일회타운","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":22358,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.8103,"longitude":126.455894,"bookYear":null,"nameKR":"동경일식","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":22406,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.685163,"longitude":125.44375,"bookYear":null,"nameKR":"젬마수산","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"신안군","status":"ACTIVE"},
        {"id":22408,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.84318,"longitude":127.346269,"bookYear":null,"nameKR":"경운횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":22410,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.988109,"longitude":126.477834,"bookYear":null,"nameKR":"해제수산","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":22420,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.661989,"longitude":126.769402,"bookYear":null,"nameKR":"명계옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":22431,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":38.023656,"longitude":127.368979,"bookYear":null,"nameKR":"포천이동정원갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":22287,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.892464,"longitude":128.590044,"bookYear":null,"nameKR":"수야커피","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":22292,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.85502,"longitude":128.55534,"bookYear":null,"nameKR":"달고떡볶이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":22300,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.172085,"longitude":129.175763,"bookYear":null,"nameKR":"콩마을","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":22303,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.173435,"longitude":129.175386,"bookYear":null,"nameKR":"외양간","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":22311,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.132084,"longitude":129.089488,"bookYear":null,"nameKR":"서포선지국","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":22312,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.115347,"longitude":129.093964,"bookYear":null,"nameKR":"영분식","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":22329,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.133119,"longitude":129.089601,"bookYear":null,"nameKR":"옛날전통추어탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":22333,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.195657,"longitude":129.223142,"bookYear":null,"nameKR":"지수농원","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":22607,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.820297,"longitude":128.737813,"bookYear":null,"nameKR":"대보식육식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":22619,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.840549,"longitude":128.620871,"bookYear":null,"nameKR":"금강회초밥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":22620,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.863899,"longitude":128.582115,"bookYear":null,"nameKR":"지지분식","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":22661,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.274264,"longitude":127.122496,"bookYear":null,"nameKR":"일품향","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":22680,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.170817,"longitude":129.051357,"bookYear":null,"nameKR":"교통부돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":22529,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.51061,"longitude":127.604848,"bookYear":null,"nameKR":"시루항아리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":22544,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.329376,"longitude":127.419961,"bookYear":null,"nameKR":"고향콩나물집","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":22555,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.18748,"longitude":129.098009,"bookYear":null,"nameKR":"연산숯불갈비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":22563,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.232018,"longitude":128.852334,"bookYear":null,"nameKR":"참숯닭발닭날개","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":22567,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.233427,"longitude":128.855434,"bookYear":null,"nameKR":"장독수제비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":22569,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.176772,"longitude":129.172976,"bookYear":null,"nameKR":"다빈분식","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":22583,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.861779,"longitude":128.629215,"bookYear":null,"nameKR":"바비네카페","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":22703,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.390483,"longitude":127.090022,"bookYear":null,"nameKR":"청계산수타짬뽕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":22733,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.762642,"longitude":127.393638,"bookYear":null,"nameKR":"사미가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":22760,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.869713,"longitude":128.591205,"bookYear":null,"nameKR":"달팽이식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":22776,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.653304,"longitude":126.700644,"bookYear":null,"nameKR":"올갱이와청국장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":22890,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.842776,"longitude":128.529553,"bookYear":null,"nameKR":"소백산양대창구이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":22893,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.876237,"longitude":128.603381,"bookYear":null,"nameKR":"한우정곰탕","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":22894,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.86973,"longitude":128.595212,"bookYear":null,"nameKR":"삼대할매국밥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":22796,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.154903,"longitude":129.058298,"bookYear":null,"nameKR":"정성식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":22812,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.154018,"longitude":129.057151,"bookYear":null,"nameKR":"예담골","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":22830,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.188087,"longitude":129.105372,"bookYear":null,"nameKR":"다미국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":22852,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.188034,"longitude":129.095097,"bookYear":null,"nameKR":"가덕도병어집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":22880,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.077299,"longitude":129.070363,"bookYear":null,"nameKR":"신흥반점","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":22885,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.224429,"longitude":129.085043,"bookYear":null,"nameKR":"천서리이가네막국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":22908,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.318864,"longitude":127.428291,"bookYear":null,"nameKR":"홍두깨칼국수","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":22909,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.877786,"longitude":128.419268,"bookYear":null,"nameKR":"동곡윤송연근손칼국수","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":22915,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.887763,"longitude":127.508683,"bookYear":null,"nameKR":"순천만갈대밭식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":22961,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.237116,"longitude":127.201819,"bookYear":null,"nameKR":"엄마손칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":22984,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.419715,"longitude":126.924445,"bookYear":null,"nameKR":"쿠커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":22992,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.868848,"longitude":128.599411,"bookYear":null,"nameKR":"칼디커피&류(공평점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":22993,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.868101,"longitude":128.554884,"bookYear":null,"nameKR":"사리원","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":23000,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.872332,"longitude":128.59311,"bookYear":null,"nameKR":"군위식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":23003,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.536475,"longitude":129.332841,"bookYear":null,"nameKR":"함양집(달동점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":23005,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.729622,"longitude":128.270614,"bookYear":null,"nameKR":"축산사랑","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"고령군","status":"ACTIVE"},
        {"id":23030,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.870797,"longitude":128.570811,"bookYear":null,"nameKR":"부산대구뽈찜","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":23033,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.843323,"longitude":128.623444,"bookYear":null,"nameKR":"제일숯불막창","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":23036,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.914677,"longitude":128.64017,"bookYear":null,"nameKR":"가람한식","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":23048,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.848077,"longitude":128.624085,"bookYear":null,"nameKR":"삼합일","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":23064,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.722766,"longitude":127.308718,"bookYear":null,"nameKR":"옛날밥상","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":23111,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.9876,"longitude":127.919142,"bookYear":null,"nameKR":"북어마당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":23112,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.150981,"longitude":128.053895,"bookYear":null,"nameKR":"성지원","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":23126,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.790836,"longitude":126.685625,"bookYear":null,"nameKR":"베로키오커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":23150,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.795011,"longitude":127.130862,"bookYear":null,"nameKR":"와촌국수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":23157,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":34.977165,"longitude":128.322456,"bookYear":null,"nameKR":"고성할매곰탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"고성군","status":"ACTIVE"},
        {"id":23165,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.186721,"longitude":127.102679,"bookYear":null,"nameKR":"유정콩나물국밥","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":23167,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.774112,"longitude":127.128911,"bookYear":null,"nameKR":"거재손두부","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":23172,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.527071,"longitude":127.081905,"bookYear":null,"nameKR":"한천밤수타마을","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":23175,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ummegol/","foodTypes":"한식(탕/국/찌개/전골)","latitude":36.622552,"longitude":127.506585,"bookYear":null,"nameKR":"음메골","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":23184,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.817419,"longitude":127.156744,"bookYear":null,"nameKR":"웰빙마떡","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":23186,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":36.627861,"longitude":127.501673,"bookYear":null,"nameKR":"솔모루생맥주","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":23187,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.832889,"longitude":127.127698,"bookYear":null,"nameKR":"옛날양푼국수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":23191,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.646795,"longitude":126.874982,"bookYear":null,"nameKR":"남원추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":23199,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.784568,"longitude":127.235927,"bookYear":null,"nameKR":"만나식당","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":23206,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":36.655727,"longitude":127.475904,"bookYear":null,"nameKR":"마포이모네","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":23214,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/p/B6HhnIeDhFg/","foodTypes":"한식(면류)","latitude":36.9143,"longitude":127.128433,"bookYear":null,"nameKR":"한일칼국수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":23218,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.801868,"longitude":127.148541,"bookYear":null,"nameKR":"본가서산순대","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":23315,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.062546,"longitude":129.081013,"bookYear":null,"nameKR":"전주식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":23364,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.28357,"longitude":126.926214,"bookYear":null,"nameKR":"진원반점","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"부여군","status":"ACTIVE"},
        {"id":23392,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.841366,"longitude":128.563466,"bookYear":null,"nameKR":"동산식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":23394,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.842365,"longitude":128.590224,"bookYear":null,"nameKR":"어부","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":23397,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.166945,"longitude":129.132987,"bookYear":null,"nameKR":"비학산보리밥생칼국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":23535,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.317596,"longitude":126.839532,"bookYear":null,"nameKR":"영동곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":23651,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.150286,"longitude":126.89239,"bookYear":null,"nameKR":"시골농장","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":23591,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.876615,"longitude":127.528778,"bookYear":null,"nameKR":"자연다슬기해장국","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":23602,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.188111,"longitude":129.086015,"bookYear":null,"nameKR":"도화","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":23621,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.257905,"longitude":129.049494,"bookYear":null,"nameKR":"시골농장","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":23645,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.126187,"longitude":126.859363,"bookYear":null,"nameKR":"시골농장","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":23965,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ilsanlikelike/","foodTypes":"기타","latitude":37.660958,"longitude":126.766797,"bookYear":null,"nameKR":"라이크라이크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":23967,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.333154,"longitude":127.048632,"bookYear":null,"nameKR":"꽃담","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":23971,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.4107,"longitude":127.134482,"bookYear":null,"nameKR":"야탑골한방삼계탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":23993,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.374844,"longitude":127.118407,"bookYear":null,"nameKR":"베이커리차차","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":24028,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.318607,"longitude":127.4,"bookYear":null,"nameKR":"풍전삼계탕","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":24223,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.457262,"longitude":126.773721,"bookYear":null,"nameKR":"미궁장어구이","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":24240,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.359531,"longitude":127.044156,"bookYear":null,"nameKR":"남수원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":24269,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.383156,"longitude":126.965103,"bookYear":null,"nameKR":"숲","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":24277,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.428153,"longitude":126.722105,"bookYear":null,"nameKR":"청송","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":24471,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.38581,"longitude":127.125049,"bookYear":null,"nameKR":"왕푸징양꼬치","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":24745,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.410624,"longitude":127.131112,"bookYear":null,"nameKR":"춘천황가닭갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":24775,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.734098,"longitude":129.334728,"bookYear":null,"nameKR":"계곡폭포가든","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":23885,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.418609,"longitude":126.932526,"bookYear":null,"nameKR":"촌골오리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":23887,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.12026,"longitude":128.359184,"bookYear":null,"nameKR":"금산할매삼계탕","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":24128,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.66951,"longitude":126.81599,"bookYear":null,"nameKR":"삼교리동치미막국수(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":24186,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.624779,"longitude":126.695915,"bookYear":null,"nameKR":"장원삼계탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":24187,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.643022,"longitude":127.401032,"bookYear":null,"nameKR":"까망염소","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":24194,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.517415,"longitude":126.721865,"bookYear":null,"nameKR":"떡사랑향기","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":24204,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.227253,"longitude":127.210133,"bookYear":null,"nameKR":"설봄","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":24205,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.553971,"longitude":129.312695,"bookYear":null,"nameKR":"허준","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":24395,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.768904,"longitude":126.938473,"bookYear":null,"nameKR":"숲속산장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":24401,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.285374,"longitude":126.888298,"bookYear":null,"nameKR":"한재골하얀집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":24408,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.707173,"longitude":127.019823,"bookYear":null,"nameKR":"강당골 토담","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":24451,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.235731,"longitude":127.201213,"bookYear":null,"nameKR":"산골정육점식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":24693,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.421819,"longitude":126.910339,"bookYear":null,"nameKR":"봉평메밀","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":24710,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.241646,"longitude":128.866126,"bookYear":null,"nameKR":"하늘담","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":24723,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.83338,"longitude":128.709211,"bookYear":null,"nameKR":"옛날통닭","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":23861,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.343545,"longitude":127.318047,"bookYear":null,"nameKR":"도안동감나무집(유성본점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":23868,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.749966,"longitude":127.079252,"bookYear":null,"nameKR":"락궁","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":24072,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.535143,"longitude":126.73778,"bookYear":null,"nameKR":"약선삼계탕","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":24085,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.443028,"longitude":127.134468,"bookYear":null,"nameKR":"은행나무집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":24087,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.402687,"longitude":126.723101,"bookYear":null,"nameKR":"일품통닭","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":24626,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.535607,"longitude":126.737219,"bookYear":null,"nameKR":"옛맛불고기","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":24632,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.348309,"longitude":127.111309,"bookYear":null,"nameKR":"기장산꼼장어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":25003,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.837525,"longitude":127.145397,"bookYear":null,"nameKR":"자연정","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":25009,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/honamjung_/","foodTypes":"한식(일반한식)","latitude":35.832079,"longitude":128.617179,"bookYear":null,"nameKR":"호남정","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":25015,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.825675,"longitude":128.611352,"bookYear":null,"nameKR":"금계정","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":25016,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.86719,"longitude":128.638489,"bookYear":null,"nameKR":"대구손칼국수","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":25024,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.876201,"longitude":128.622707,"bookYear":null,"nameKR":"신라숯불가든","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":25047,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.876255,"longitude":128.640439,"bookYear":null,"nameKR":"용화반점","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":24860,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.477651,"longitude":126.795257,"bookYear":null,"nameKR":"마포주먹고기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":24880,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.26859,"longitude":126.906493,"bookYear":null,"nameKR":"연꽃이야기","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"부여군","status":"ACTIVE"},
        {"id":24926,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.15553,"longitude":126.908796,"bookYear":null,"nameKR":"골목길","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":24949,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.009605,"longitude":126.516808,"bookYear":null,"nameKR":"선미식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":24970,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.123916,"longitude":126.880084,"bookYear":null,"nameKR":"장안동왕족발","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":24988,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.386413,"longitude":127.111455,"bookYear":null,"nameKR":"블레도르","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":24989,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.392132,"longitude":127.126939,"bookYear":null,"nameKR":"블랑제리 드 르방","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":24991,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.351684,"longitude":127.105679,"bookYear":null,"nameKR":"라뜰리에","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":24994,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.386569,"longitude":127.122116,"bookYear":null,"nameKR":"듀팜","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":24995,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.368103,"longitude":127.107242,"bookYear":null,"nameKR":"안데르센과자(백궁파라곤점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":24998,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.812565,"longitude":127.175854,"bookYear":null,"nameKR":"유량골손두부","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":25178,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.320996,"longitude":127.112362,"bookYear":null,"nameKR":"무지개돌솥밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":25193,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.797684,"longitude":126.686209,"bookYear":null,"nameKR":"오리촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":25195,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.734866,"longitude":127.108735,"bookYear":null,"nameKR":"외양간한우","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":25204,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.602128,"longitude":126.814421,"bookYear":null,"nameKR":"행복한집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":25219,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.600518,"longitude":126.820863,"bookYear":null,"nameKR":"성화오리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":25236,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.626252,"longitude":127.814352,"bookYear":null,"nameKR":"옛날할매순대","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"함양군","status":"ACTIVE"},
        {"id":25239,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.365834,"longitude":128.152661,"bookYear":null,"nameKR":"새지천식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"상주시","status":"ACTIVE"},
        {"id":25097,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.841457,"longitude":128.556218,"bookYear":null,"nameKR":"어탕국수삼계","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":25098,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.512479,"longitude":126.707847,"bookYear":null,"nameKR":"황제능이버섯백숙","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":25128,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.043581,"longitude":129.404606,"bookYear":null,"nameKR":"동치골허브야생화마을","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"울진군","status":"ACTIVE"},
        {"id":25165,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.630345,"longitude":126.552402,"bookYear":null,"nameKR":"황촌집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":25268,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.535738,"longitude":126.736291,"bookYear":null,"nameKR":"토모","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"계양구","status":"ACTIVE"},
        {"id":25321,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.870232,"longitude":128.732816,"bookYear":null,"nameKR":"미송정","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":25343,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.277969,"longitude":127.019505,"bookYear":null,"nameKR":"방자족발","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":25357,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.137072,"longitude":129.102076,"bookYear":null,"nameKR":"형제돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":25367,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.594591,"longitude":127.287802,"bookYear":null,"nameKR":"세종축산","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":".세종","status":"ACTIVE"},
        {"id":25518,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.853526,"longitude":128.548634,"bookYear":null,"nameKR":"금강산돼지갈비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":25527,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.322671,"longitude":127.123846,"bookYear":null,"nameKR":"퍼스트네팔","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":25533,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.423354,"longitude":126.656896,"bookYear":null,"nameKR":"어머니순두부","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":25546,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.791156,"longitude":127.14893,"bookYear":null,"nameKR":"토성","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":25568,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.269087,"longitude":127.237931,"bookYear":null,"nameKR":"어량","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":25378,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.78364,"longitude":126.38503,"bookYear":null,"nameKR":"유달아구탕찜","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":25391,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.599732,"longitude":127.141086,"bookYear":null,"nameKR":"이모네곱창","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":25420,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mojo_the_lounge/","foodTypes":"디저트/차/베이커리","latitude":37.413445,"longitude":127.1255,"bookYear":null,"nameKR":"모조더라운지","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":25421,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.192085,"longitude":128.07973,"bookYear":null,"nameKR":"테이블9","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":25497,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.554681,"longitude":129.457572,"bookYear":null,"nameKR":"별장횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":25503,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.367129,"longitude":126.624926,"bookYear":null,"nameKR":"일월굴칼국수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":25624,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.037098,"longitude":129.369313,"bookYear":null,"nameKR":"진미고래","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":25627,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.565574,"longitude":128.72736,"bookYear":null,"nameKR":"신선찜닭","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":25628,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.713868,"longitude":129.198809,"bookYear":null,"nameKR":"봉계황가숯불고기","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":25691,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.827939,"longitude":127.512073,"bookYear":null,"nameKR":"가평칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":25729,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.471279,"longitude":126.624589,"bookYear":null,"nameKR":"통큰조개찜","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":25730,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.119855,"longitude":128.120146,"bookYear":null,"nameKR":"대도식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"김천시","status":"ACTIVE"},
        {"id":25750,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.629077,"longitude":127.490636,"bookYear":null,"nameKR":"신송보리밥집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":25822,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.832793,"longitude":128.622925,"bookYear":null,"nameKR":"보카치오 브로이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":25825,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.917929,"longitude":128.549894,"bookYear":null,"nameKR":"빵굽는남자(태전점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":25826,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.32054,"longitude":127.398747,"bookYear":null,"nameKR":"이봉구케익과자점","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":25827,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.876121,"longitude":128.497618,"bookYear":null,"nameKR":"이용재베이커리","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":25829,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.85487,"longitude":128.494576,"bookYear":null,"nameKR":"가또오프레즈","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":25954,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.986155,"longitude":128.637427,"bookYear":null,"nameKR":"동림식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":25976,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.165807,"longitude":129.038791,"bookYear":null,"nameKR":"단골식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":26070,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.381347,"longitude":127.097924,"bookYear":null,"nameKR":"백마강참숯민물장어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":26150,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.289058,"longitude":127.100311,"bookYear":null,"nameKR":"만미정","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":26191,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.292554,"longitude":127.958958,"bookYear":null,"nameKR":"영실한우프라자","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":26204,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.474839,"longitude":127.230475,"bookYear":null,"nameKR":"벽수장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":26240,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.220877,"longitude":128.682477,"bookYear":null,"nameKR":"서소문","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":26248,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.765611,"longitude":127.482236,"bookYear":null,"nameKR":"장수촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":26295,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.258831,"longitude":128.619837,"bookYear":null,"nameKR":"금산삼계탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":26370,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.390516,"longitude":126.954805,"bookYear":null,"nameKR":"번창순대타운","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":26372,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.766483,"longitude":127.166863,"bookYear":null,"nameKR":"빈어스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":26574,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.856531,"longitude":127.137408,"bookYear":null,"nameKR":"서울추어탕","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":26576,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.732417,"longitude":126.606258,"bookYear":null,"nameKR":"말무리농장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":26436,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.235446,"longitude":128.5856,"bookYear":null,"nameKR":"해금강횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":26447,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.209676,"longitude":129.037003,"bookYear":null,"nameKR":"녹차밀면갈비마을","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":26448,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.205191,"longitude":129.082767,"bookYear":null,"nameKR":"언양민물매운탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":26453,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.210266,"longitude":129.076366,"bookYear":null,"nameKR":"미성버섯수제비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":26455,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.12889,"longitude":129.008361,"bookYear":null,"nameKR":"할매집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":26469,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.28661,"longitude":127.033462,"bookYear":null,"nameKR":"홍화루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":26474,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.777388,"longitude":126.464157,"bookYear":null,"nameKR":"향토","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":26476,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.432091,"longitude":127.132362,"bookYear":null,"nameKR":"복래반점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":26507,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.820259,"longitude":128.738354,"bookYear":null,"nameKR":"포항통닭","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":26516,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.161071,"longitude":128.898908,"bookYear":null,"nameKR":"금강가든","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":26521,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.944376,"longitude":128.83621,"bookYear":null,"nameKR":"평양왕순대국밥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":26529,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.223191,"longitude":128.872486,"bookYear":null,"nameKR":"산골버섯","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":26634,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.859004,"longitude":128.523389,"bookYear":null,"nameKR":"성주돼지국밥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":26713,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.887396,"longitude":127.508464,"bookYear":null,"nameKR":"순천만일번가","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":26715,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.941721,"longitude":127.412125,"bookYear":null,"nameKR":"염병옥오리탕","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"진천군","status":"ACTIVE"},
        {"id":28586,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.394411,"longitude":127.109192,"bookYear":null,"nameKR":"자산어보(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":1788,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.453562,"longitude":127.209951,"bookYear":null,"nameKR":"두메산골","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":1910,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.434904,"longitude":127.226516,"bookYear":null,"nameKR":"완도집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":2100,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.797785,"longitude":129.205471,"bookYear":null,"nameKR":"옛집칼국수","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2193,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.868741,"longitude":128.571306,"bookYear":null,"nameKR":"최영경할매빈대떡","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":2211,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.378278,"longitude":127.421731,"bookYear":null,"nameKR":"뉴서울식당","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":2227,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.33745797360376,"longitude":127.413801891044,"bookYear":null,"nameKR":"서울북어","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":2245,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.3649284293421,"longitude":127.42565986265,"bookYear":null,"nameKR":"푸른바다횟집","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":2265,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.1049809422743,"longitude":129.020964627734,"bookYear":null,"nameKR":"대궁삼계탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":2469,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.057127,"longitude":126.974468,"bookYear":null,"nameKR":"떡보브라더스","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":2638,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.600842937005,"longitude":126.554683680898,"bookYear":null,"nameKR":"김가네볼태기","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":2900,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.8417232362922,"longitude":128.423687048889,"bookYear":null,"nameKR":"통영비빔밥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":2911,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.8832324958426,"longitude":128.816237451588,"bookYear":null,"nameKR":"청봉숯불구이전문점","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"봉화군","status":"ACTIVE"},
        {"id":3559,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":34.754986,"longitude":127.702432,"bookYear":null,"nameKR":"작은어촌","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":2885,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.829313,"longitude":128.001629,"bookYear":null,"nameKR":"여원","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":3045,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":38.21388076137681,"longitude":127.13916475165549,"bookYear":null,"nameKR":"약수오리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"연천군","status":"ACTIVE"},
        {"id":3176,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":34.8026092747602,"longitude":126.394360666178,"bookYear":null,"nameKR":"평양냉면","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":3197,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.19638249009421,"longitude":129.09834334825413,"bookYear":null,"nameKR":"청기와","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":3218,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.5525873401449,"longitude":129.285613941309,"bookYear":null,"nameKR":"본가굴국밥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":3252,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.415443,"longitude":127.204206,"bookYear":null,"nameKR":"하루방가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":3275,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.550847,"longitude":129.298137,"bookYear":null,"nameKR":"대가복집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":3362,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.11086,"longitude":129.017202,"bookYear":null,"nameKR":"휴고","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":3823,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.9199561370339,"longitude":128.283676256788,"bookYear":null,"nameKR":"얼음골맛집","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":4032,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.027734,"longitude":127.033457,"bookYear":null,"nameKR":"우리국수우리팥죽","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"화순군","status":"ACTIVE"},
        {"id":4058,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.384603,"longitude":127.134648,"bookYear":null,"nameKR":"취홍","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":4191,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.532947459802024,"longitude":129.31882913528185,"bookYear":null,"nameKR":"홍어마을","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":4269,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.326085,"longitude":127.425175,"bookYear":null,"nameKR":"쌍리","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":4270,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.317541,"longitude":127.3973,"bookYear":null,"nameKR":"연아식당","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":4335,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.553854,"longitude":127.708904,"bookYear":null,"nameKR":"하누야","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":4348,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":38.071584,"longitude":127.290453,"bookYear":null,"nameKR":"산정수로생고기집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":4364,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.822221158159536,"longitude":127.33768493675373,"bookYear":null,"nameKR":"황소고집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":4373,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.019119325009264,"longitude":127.2395160273921,"bookYear":null,"nameKR":"서울종로해장국","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":4376,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.003982,"longitude":127.290252,"bookYear":null,"nameKR":"옹기골추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":4428,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.119506,"longitude":126.846493,"bookYear":null,"nameKR":"매월농원","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":4434,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":34.991014,"longitude":127.349282,"bookYear":null,"nameKR":"정문회관","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":4521,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.712207,"longitude":129.198041,"bookYear":null,"nameKR":"일류숯불고기","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":4527,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.687648,"longitude":129.340672,"bookYear":null,"nameKR":"초원암소숯불","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":4552,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.875416,"longitude":128.812668,"bookYear":null,"nameKR":"일미식육식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":4553,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.874395513831196,"longitude":128.58673291853444,"bookYear":null,"nameKR":"태능집","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":4582,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.466167418078165,"longitude":129.20976220796265,"bookYear":null,"nameKR":"웅촌식육식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":4608,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.516188,"longitude":127.146489,"bookYear":null,"nameKR":"팔복도가니탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":4635,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.160536,"longitude":128.894626,"bookYear":null,"nameKR":"민물가든","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":4741,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.73845373424064,"longitude":127.92439539771699,"bookYear":null,"nameKR":"대전식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거창군","status":"ACTIVE"},
        {"id":5018,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":36.5633516273504,"longitude":128.298133286977,"bookYear":null,"nameKR":"삼강주막","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"예천군","status":"ACTIVE"},
        {"id":5090,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.12471,"longitude":127.600878,"bookYear":null,"nameKR":"선희식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"영동군","status":"ACTIVE"},
        {"id":5241,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.085764,"longitude":129.02454,"bookYear":null,"nameKR":"남포식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":5357,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.25536686647611,"longitude":127.11333610075971,"bookYear":null,"nameKR":"이창노녹두삼계탕메기매운탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":5447,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.728904,"longitude":126.702177,"bookYear":null,"nameKR":"타샤의정원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":5474,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.783019,"longitude":127.578441,"bookYear":null,"nameKR":"원조부원정육왕소금구이","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":5882,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.09854,"longitude":127.590399,"bookYear":null,"nameKR":"마달피가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"금산군","status":"ACTIVE"},
        {"id":5885,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.39361,"longitude":126.858789,"bookYear":null,"nameKR":"까치내흥부가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"청양군","status":"ACTIVE"},
        {"id":6016,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.863559,"longitude":128.571128,"bookYear":null,"nameKR":"똘똘이식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":6095,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":35.830225,"longitude":128.619209,"bookYear":null,"nameKR":"하롱베이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":6151,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.3188851168937,"longitude":127.45201101380594,"bookYear":null,"nameKR":"판암면옥","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":6186,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.232419,"longitude":127.487902,"bookYear":null,"nameKR":"송이식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":6288,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.662088,"longitude":126.768039,"bookYear":null,"nameKR":"미술관옆커피가게","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":6350,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.289777,"longitude":126.999441,"bookYear":null,"nameKR":"수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6734,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":37.268349,"longitude":126.999957,"bookYear":null,"nameKR":"카삼","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6737,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.865058,"longitude":127.347848,"bookYear":null,"nameKR":"황토가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":6852,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.792108,"longitude":126.404435,"bookYear":null,"nameKR":"쉼터식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":6880,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.386104,"longitude":127.111406,"bookYear":null,"nameKR":"코티지가든 온더스테어스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":6913,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.872831,"longitude":128.592755,"bookYear":null,"nameKR":"마산설렁탕전문","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":8243,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.8673957973901,"longitude":128.594272237479,"bookYear":null,"nameKR":"미진분식","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":8906,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.5304551858195,"longitude":127.46573963535,"bookYear":null,"nameKR":"콩리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":9227,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.826028,"longitude":128.63813,"bookYear":null,"nameKR":"화청궁","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":9475,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.4754927713474,"longitude":126.619725986248,"bookYear":null,"nameKR":"청관","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":9658,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.775865,"longitude":127.571083,"bookYear":null,"nameKR":"조선설렁탕숯불갈비","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9969,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.9203818936164,"longitude":127.481880865362,"bookYear":null,"nameKR":"벽오동","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":9972,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":34.979208,"longitude":127.385683,"bookYear":null,"nameKR":"수로산장","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10174,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.547073,"longitude":126.728204,"bookYear":null,"nameKR":"우리식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":10700,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.152243,"longitude":128.710832,"bookYear":null,"nameKR":"시루봉가든","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":9517,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.77651015108241,"longitude":126.60342644389385,"bookYear":null,"nameKR":"산수가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9912,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.767895,"longitude":127.083093,"bookYear":null,"nameKR":"윤해명식당   ","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":10025,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.991842545165014,"longitude":127.34413678607898,"bookYear":null,"nameKR":"선암식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10027,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.992268,"longitude":127.34345,"bookYear":null,"nameKR":"초원식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10038,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":34.951268,"longitude":127.486756,"bookYear":null,"nameKR":"남흥회관","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10106,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.143773,"longitude":126.431612,"bookYear":null,"nameKR":"한옥정","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":10153,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/golmok4270","foodTypes":"한식(어패류)","latitude":35.361618,"longitude":126.444939,"bookYear":null,"nameKR":"골목식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":10372,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.989217,"longitude":128.696038,"bookYear":null,"nameKR":"성공식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":10613,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.137339,"longitude":128.705275,"bookYear":null,"nameKR":"초심","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":10625,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1535951201913,"longitude":128.699086657037,"bookYear":null,"nameKR":"두레박식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":10655,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.099851,"longitude":128.822125,"bookYear":null,"nameKR":"무교동낙지","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":10658,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.149374,"longitude":128.697111,"bookYear":null,"nameKR":"동심","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":10681,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.596695,"longitude":128.784145,"bookYear":null,"nameKR":"동악골금재가든","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":10769,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.094069,"longitude":128.82019,"bookYear":null,"nameKR":"송원횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":10865,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.3916596071839,"longitude":127.903518823349,"bookYear":null,"nameKR":"청산가든","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":11099,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.161656,"longitude":128.983876,"bookYear":null,"nameKR":"대궐안집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":12082,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.48365,"longitude":126.772797,"bookYear":null,"nameKR":"춘천호반막국수(본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":12155,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.769597,"longitude":127.054326,"bookYear":null,"nameKR":"막줄래국시","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12395,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.861636,"longitude":128.556023,"bookYear":null,"nameKR":"배박사늑대갈비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":12494,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.835464,"longitude":128.577024,"bookYear":null,"nameKR":"마리아쥬커피","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":12502,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.179681,"longitude":128.114981,"bookYear":null,"nameKR":"정동근과자점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":12823,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.94745994741267,"longitude":127.87386098293811,"bookYear":null,"nameKR":"용궁성횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":13119,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.355787,"longitude":127.361531,"bookYear":null,"nameKR":"화로야","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":13197,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":34.84292,"longitude":128.420898,"bookYear":null,"nameKR":"민속촌","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":13199,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.383648,"longitude":127.136471,"bookYear":null,"nameKR":"안집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":13231,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.956531,"longitude":128.692022,"bookYear":null,"nameKR":"산골기사식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":16916,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yam2_cake","foodTypes":"디저트/차/베이커리","latitude":37.2942651973422,"longitude":127.052945790851,"bookYear":null,"nameKR":"얌이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":18227,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.230872,"longitude":127.173793,"bookYear":null,"nameKR":"학마루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":18252,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.190928,"longitude":128.230659,"bookYear":null,"nameKR":"배신식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"김천시","status":"ACTIVE"},
        {"id":18363,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.786131,"longitude":126.384379,"bookYear":null,"nameKR":"중앙횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"목포시","status":"ACTIVE"},
        {"id":18685,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.369571,"longitude":127.106174,"bookYear":null,"nameKR":"카페드로잉","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":19635,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.384186,"longitude":127.11172,"bookYear":null,"nameKR":"커피스톤","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":20474,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.873021643887476,"longitude":128.58130686984495,"bookYear":null,"nameKR":"한우곰탕","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":20491,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.788684,"longitude":126.697725,"bookYear":null,"nameKR":"호메오","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":21028,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.791922,"longitude":126.69785,"bookYear":null,"nameKR":"나무와베이커리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":21076,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.0748206929608,"longitude":129.012463060658,"bookYear":null,"nameKR":"카페해인","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":21351,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wood_side_bar","foodTypes":"바/주점","latitude":35.152774,"longitude":129.057612,"bookYear":null,"nameKR":"우드사이드","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":21652,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.134983,"longitude":129.100166,"bookYear":null,"nameKR":"하코네","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":22916,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":34.958849,"longitude":127.485187,"bookYear":null,"nameKR":"괴목국밥","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":23063,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.8026720598594,"longitude":127.34842462702,"bookYear":null,"nameKR":"소양강가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":23071,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.101575,"longitude":128.976846,"bookYear":null,"nameKR":"통영숯불장어구이","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":23771,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.377111,"longitude":127.113849,"bookYear":null,"nameKR":"지오쿠치나","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":25875,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.03216,"longitude":126.71707,"bookYear":null,"nameKR":"나주곰탕한옥집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"나주시","status":"ACTIVE"},
        {"id":25979,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.15214,"longitude":129.024886,"bookYear":null,"nameKR":"영희네분식","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":26039,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.069851,"longitude":127.615022,"bookYear":null,"nameKR":"푸른산장","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":26533,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.169157,"longitude":128.12015,"bookYear":null,"nameKR":"홍제원","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":26538,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.79224577174461,"longitude":128.08854611938668,"bookYear":null,"nameKR":"전주가든식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"합천군","status":"ACTIVE"},
        {"id":26783,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.944829,"longitude":127.501153,"bookYear":null,"nameKR":"알선식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":26933,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.202619524285,"longitude":129.084282422449,"bookYear":null,"nameKR":"밥도둑1번지","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":26971,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.294912,"longitude":127.022755,"bookYear":null,"nameKR":"홍남매칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":27134,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.46588,"longitude":126.634752,"bookYear":null,"nameKR":"신생갈비","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":27542,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.599313,"longitude":126.874469,"bookYear":null,"nameKR":"청기와추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":27899,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ihyeonjeong2494/","foodTypes":"한식(일반한식)","latitude":35.855799,"longitude":128.627277,"bookYear":null,"nameKR":"청아람","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":27947,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bookcafenoon","foodTypes":"디저트/차/베이커리","latitude":37.707447,"longitude":126.687216,"bookYear":null,"nameKR":"북카페눈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":27948,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.707215,"longitude":126.687314,"bookYear":null,"nameKR":"행간과여백","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":28071,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.384057,"longitude":127.111935,"bookYear":null,"nameKR":"플랩잭팬트리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":28118,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.85982,"longitude":128.634413,"bookYear":null,"nameKR":"라우스터프","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":28173,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mammoseu22ho/","foodTypes":"한식(어패류)","latitude":37.45484,"longitude":126.604282,"bookYear":null,"nameKR":"맘모스회센타","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":29183,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.3454019887845,"longitude":127.792227367077,"bookYear":null,"nameKR":"청양식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"옥천군","status":"ACTIVE"},
        {"id":29454,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/clubespresso_1990/","foodTypes":"디저트/차/베이커리","latitude":37.677334525201644,"longitude":127.48382513053349,"bookYear":null,"nameKR":"클럽에스프레소(가평점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":29751,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.80709680255005,"longitude":127.08271895304046,"bookYear":null,"nameKR":"우일면옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":29862,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.15564451468398,"longitude":129.06026569580553,"bookYear":null,"nameKR":"화전국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":30924,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/climb_tower/","foodTypes":"디저트/차/베이커리","latitude":35.2828330033557,"longitude":129.258078522752,"bookYear":null,"nameKR":"클라임타워","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":30935,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.8353398511229,"longitude":129.212489480037,"bookYear":null,"nameKR":"아르떼","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30945,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/eero_coffee/","foodTypes":"디저트/차/베이커리","latitude":35.8351582288586,"longitude":129.212708065492,"bookYear":null,"nameKR":"이이로 커피","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30983,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.3639860033755,"longitude":127.106315700721,"bookYear":null,"nameKR":"양우정","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":31002,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.4724286593279,"longitude":126.622967318561,"bookYear":null,"nameKR":"중화방","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":31011,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffeemimi_official/","foodTypes":"디저트/차/베이커리","latitude":35.0889084559411,"longitude":129.039236365364,"bookYear":null,"nameKR":"커피미미","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":31054,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.2015130376273,"longitude":129.088441693798,"bookYear":null,"nameKR":"희망통닭","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":31062,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.2350874816562,"longitude":128.880564678851,"bookYear":null,"nameKR":"남광식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":31072,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.2775261461681,"longitude":127.05048027003,"bookYear":null,"nameKR":"금메달식당(수원점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":31078,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.1734026818461,"longitude":129.174269348837,"bookYear":null,"nameKR":"하가원","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":31092,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dessertcafeamai/","foodTypes":"디저트/차/베이커리","latitude":36.789176120916,"longitude":127.125141784232,"bookYear":null,"nameKR":"디저트카페아마이","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":31255,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.3376957415072,"longitude":127.110983963924,"bookYear":null,"nameKR":"빠띠쓰리애나스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":31282,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.8455591700372,"longitude":129.210001630401,"bookYear":null,"nameKR":"삼영복식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31293,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/_peacepiece/","foodTypes":"디저트/차/베이커리","latitude":37.6705821133655,"longitude":126.783276650795,"bookYear":null,"nameKR":"피스피스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":31329,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4033014273917,"longitude":127.223369240608,"bookYear":null,"nameKR":"나인블럭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":31409,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/montleve_/","foodTypes":"디저트/차/베이커리","latitude":37.3607500776078,"longitude":127.034557072631,"bookYear":null,"nameKR":"몽르베","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":31411,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":37.3828326928486,"longitude":127.151566922942,"bookYear":null,"nameKR":"타임포타이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":31461,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.1674650511447,"longitude":126.895690527634,"bookYear":null,"nameKR":"소담비빔국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":31488,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.751601477633,"longitude":129.205934011338,"bookYear":null,"nameKR":"용산회식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31533,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.2745028855003,"longitude":127.609947878959,"bookYear":null,"nameKR":"장복골","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":31548,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":34.9403270612399,"longitude":127.505936168451,"bookYear":null,"nameKR":"통뼈연가","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":31563,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dirty_trunk_korea/","foodTypes":"디저트/차/베이커리","latitude":37.7283824389851,"longitude":126.702999446561,"bookYear":null,"nameKR":"더티트렁크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":2143,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.204922,"longitude":126.90234,"bookYear":null,"nameKR":"원조용전식육회관","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":2192,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.872304,"longitude":128.593041,"bookYear":null,"nameKR":"유경식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":2226,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.365302,"longitude":127.481853,"bookYear":null,"nameKR":"샘골농장","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":2857,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.322092,"longitude":126.990857,"bookYear":null,"nameKR":"만상","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":3292,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.870687,"longitude":128.604909,"bookYear":null,"nameKR":"실비찜갈비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":4104,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.85037316438562,"longitude":128.53609043970724,"bookYear":null,"nameKR":"대중한우영화식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":4321,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.333408690471124,"longitude":127.17209808961809,"bookYear":null,"nameKR":"풍미정","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":4446,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.808606,"longitude":127.147644,"bookYear":null,"nameKR":"천안당","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":4641,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.777141,"longitude":127.12629,"bookYear":null,"nameKR":"삐노꼴레","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":5026,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.678135,"longitude":126.909437,"bookYear":null,"nameKR":"신라복집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"장흥군","status":"ACTIVE"},
        {"id":5542,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.57068,"longitude":129.117969,"bookYear":null,"nameKR":"금화불고기","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":5958,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":36.635473,"longitude":127.485176,"bookYear":null,"nameKR":"서문우동","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":6163,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.026887,"longitude":127.122905,"bookYear":null,"nameKR":"태성추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":6240,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.355302,"longitude":127.105195,"bookYear":null,"nameKR":"스토브온","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":7019,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.807168,"longitude":127.114159,"bookYear":null,"nameKR":"뚜쥬루과자점(거북이점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":7792,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.820179,"longitude":128.640632,"bookYear":null,"nameKR":"진주손칼국수손수제비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":8231,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.870958,"longitude":128.592363,"bookYear":null,"nameKR":"대구전통따로","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":10118,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.018709,"longitude":126.537328,"bookYear":null,"nameKR":"진미복집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":10797,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.83728,"longitude":127.895084,"bookYear":null,"nameKR":"만복초밥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":13188,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.887964778318,"longitude":126.79627483657,"bookYear":null,"nameKR":"임진대가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":23002,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.844712,"longitude":128.603823,"bookYear":null,"nameKR":"청도돼지국밥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":24930,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.161431,"longitude":126.906308,"bookYear":null,"nameKR":"장성오리탕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":26981,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"중식","latitude":35.2019473450077,"longitude":127.527391175149,"bookYear":null,"nameKR":"옥산식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":27122,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.812303,"longitude":126.867253,"bookYear":null,"nameKR":"로스팅하우스드발롱","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":28388,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.719136,"longitude":126.70502,"bookYear":null,"nameKR":"장원막국수(파주문발점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":28502,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1559923424503,"longitude":129.058480967209,"bookYear":null,"nameKR":"경주박가국밥(서면점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":2072,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.840792,"longitude":129.216504,"bookYear":null,"nameKR":"대구해장국","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":2157,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.873622,"longitude":128.596347,"bookYear":null,"nameKR":"강산면옥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":2355,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.415507,"longitude":126.652924,"bookYear":null,"nameKR":"성진아구탕","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"연수구/송도","status":"ACTIVE"},
        {"id":2364,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.45764281200073,"longitude":126.67710380069512,"bookYear":null,"nameKR":"변가네옹진냉면","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":2733,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.5311911376515,"longitude":127.82244798529683,"bookYear":null,"nameKR":"팔도식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":2854,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.460472,"longitude":127.166883,"bookYear":null,"nameKR":"전주해장국","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":3109,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.875086,"longitude":128.539898,"bookYear":null,"nameKR":"정도너츠","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":4225,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.471558,"longitude":126.628169,"bookYear":null,"nameKR":"신포닭강정","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":4411,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.169417,"longitude":126.903911,"bookYear":null,"nameKR":"초가왕족발","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":4519,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.71376,"longitude":129.199713,"bookYear":null,"nameKR":"정원암소숯불구이","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":4551,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.8183857543959,"longitude":128.821993599595,"bookYear":null,"nameKR":"자인식육식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":5240,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.221348,"longitude":128.983753,"bookYear":null,"nameKR":"대저할매국수","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":5248,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.545444223124875,"longitude":127.58373091996063,"bookYear":null,"nameKR":"은행나무식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":5396,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.282862,"longitude":126.914759,"bookYear":null,"nameKR":"백제의집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"부여군","status":"ACTIVE"},
        {"id":5436,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.841006,"longitude":128.005813,"bookYear":null,"nameKR":"언덕넘어","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"충주시","status":"ACTIVE"},
        {"id":5560,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":35.237071,"longitude":128.683412,"bookYear":null,"nameKR":"알베또","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":9605,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.991943,"longitude":127.343764,"bookYear":null,"nameKR":"길상식당(선암사점)","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":16157,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.704891,"longitude":126.55982,"bookYear":null,"nameKR":"지혜추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":21330,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":35.867712,"longitude":128.588891,"bookYear":null,"nameKR":"소울키친","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":25425,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":36.798633,"longitude":127.120338,"bookYear":null,"nameKR":"멘야마쯔리","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":2792,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.1883584504825,"longitude":129.086849240648,"bookYear":null,"nameKR":"칠보락","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":2969,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.01397,"longitude":126.395761,"bookYear":null,"nameKR":"곰솔가든","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"무안군","status":"ACTIVE"},
        {"id":3016,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.621664,"longitude":127.633641,"bookYear":null,"nameKR":"대왕산","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":4328,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.004927,"longitude":127.265895,"bookYear":null,"nameKR":"약산골","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":16116,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.391688,"longitude":127.060562,"bookYear":null,"nameKR":"청계산손두부집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":28424,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.2777133354599,"longitude":127.449014561126,"bookYear":null,"nameKR":"홍이원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":2149,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.221116,"longitude":126.878457,"bookYear":null,"nameKR":"초유지","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":2200,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.868051,"longitude":128.595529,"bookYear":null,"nameKR":"전원돈까스","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":4140,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.588691,"longitude":127.160693,"bookYear":null,"nameKR":"구리타워G레스토랑","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":3621,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.365389,"longitude":127.106284,"bookYear":null,"nameKR":"화덕피자와맥주","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":6161,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.386803,"longitude":127.125472,"bookYear":null,"nameKR":"오끼참치(분당본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":9220,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.0586435465493,"longitude":129.424997898173,"bookYear":null,"nameKR":"금성식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"울진군","status":"ACTIVE"},
        {"id":1856,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.421451,"longitude":127.115518,"bookYear":null,"nameKR":"소호정(분당점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시","status":"ACTIVE"},
        {"id":1979,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.7911683080677,"longitude":126.685598577902,"bookYear":null,"nameKR":"프로방스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":2062,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.8387938430439,"longitude":128.416264668993,"bookYear":null,"nameKR":"희락회맛집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":3810,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.4595644375017,"longitude":127.329764822486,"bookYear":null,"nameKR":"털보셀프바베큐","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":4114,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":37.656918,"longitude":126.77119,"bookYear":null,"nameKR":"창고43(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":4318,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.344941,"longitude":127.101253,"bookYear":null,"nameKR":"연탄삼겹고추장구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":4617,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(면류)","latitude":38.02384,"longitude":127.109229,"bookYear":null,"nameKR":"망향비빔국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"연천군","status":"ACTIVE"},
        {"id":4620,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.558322,"longitude":127.235706,"bookYear":null,"nameKR":"온누리장작구이(팔당본점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":8988,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.039792,"longitude":129.365869,"bookYear":null,"nameKR":"초원통닭삼계탕","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":18984,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.0560285197546,"longitude":128.970949063791,"bookYear":null,"nameKR":"한우애가","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":19641,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.349621,"longitude":127.109549,"bookYear":null,"nameKR":"미정국수0410(미금역점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":4135,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":36.004754,"longitude":126.731303,"bookYear":null,"nameKR":"화이트뮤즈","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서천군","status":"ACTIVE"},
        {"id":7189,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.852048,"longitude":129.262218,"bookYear":null,"nameKR":"함양집(경주점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":7993,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.840731,"longitude":128.421188,"bookYear":null,"nameKR":"소문난 3대할매김밥 1호점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":3705,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.406522,"longitude":127.400883,"bookYear":null,"nameKR":"동해회관동해막국수","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":5276,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.3447853477087,"longitude":127.433177225278,"bookYear":null,"nameKR":"경동오징어국수","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":3333,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.657574,"longitude":126.868815,"bookYear":null,"nameKR":"너른마당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":564,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.740853,"longitude":127.049156,"bookYear":null,"nameKR":"꼬시나","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":2189,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.887263084725,"longitude":128.494669358327,"bookYear":null,"nameKR":"옹심이칼국수","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달성군","status":"ACTIVE"},
        {"id":17497,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.3563138443314,"longitude":126.917889814269,"bookYear":null,"nameKR":"카페프롬나드","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":27682,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.321036545355,"longitude":127.112815703666,"bookYear":null,"nameKR":"세꼬시마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":29532,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/osteria_moon","foodTypes":"이탈리아식","latitude":36.639932124990516,"longitude":127.48799763939685,"bookYear":null,"nameKR":"오스테리아문","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":29536,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mettere_official/","foodTypes":"이탈리아식","latitude":36.343893765769515,"longitude":127.38869203588068,"bookYear":null,"nameKR":"메떼레","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":29537,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":35.864401120659394,"longitude":128.60994584861564,"bookYear":null,"nameKR":"멘타와이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":29629,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/hwajeon2018/","foodTypes":"일식","latitude":35.84948728044,"longitude":128.62346666259577,"bookYear":null,"nameKR":"화전","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":29678,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.273474723160525,"longitude":127.02782216509014,"bookYear":null,"nameKR":"동호간장게장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":29708,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":34.797264545002626,"longitude":128.04353696787325,"bookYear":null,"nameKR":"완벽한인생","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":29723,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"일식","latitude":37.36372100411027,"longitude":127.10632390609481,"bookYear":null,"nameKR":"고쿠텐","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":29728,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/junyeoung_ramen","foodTypes":"일식","latitude":37.402100812018936,"longitude":127.1104666192067,"bookYear":null,"nameKR":"클준빛날영","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":29748,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.670799672613114,"longitude":126.74811306869525,"bookYear":null,"nameKR":"뒤집기한판","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":29762,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gomany_noodle/","foodTypes":"한식(면류)","latitude":37.639074381614606,"longitude":126.6731795465624,"bookYear":null,"nameKR":"고마니면가","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":29838,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brot_backen/","foodTypes":"이탈리아식","latitude":37.727169065329264,"longitude":126.7369690996821,"bookYear":null,"nameKR":"브롯","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":29920,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/buja_wang/","foodTypes":"한식(탕/국/찌개/전골)","latitude":35.32358645554809,"longitude":128.99452621956544,"bookYear":null,"nameKR":"부자왕돼지국밥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":30095,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.993200649577226,"longitude":127.08799859148445,"bookYear":null,"nameKR":"민족의부대찌개 ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":30148,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.56854572640441,"longitude":129.11020357450923,"bookYear":null,"nameKR":"한마당한우촌","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":30154,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/de.marigny/","foodTypes":"프랑스식","latitude":35.1575616591512,"longitude":129.1504892230035,"bookYear":null,"nameKR":"드마히니(마린시티점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":30157,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/common_kitchen/","foodTypes":"바/주점","latitude":37.40209529907863,"longitude":127.10863694633468,"bookYear":null,"nameKR":"커먼키친","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":30158,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.39426202230159,"longitude":127.11036477013562,"bookYear":null,"nameKR":"광화문국밥(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":30161,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/mezclar_comfort_cuisine/","foodTypes":"이탈리아식","latitude":37.39017983753411,"longitude":127.08228577860396,"bookYear":null,"nameKR":"메즈클라","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":30182,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/theyeasthouse/","foodTypes":"미국식","latitude":37.39146447364994,"longitude":127.0900113444063,"bookYear":null,"nameKR":"더이스트하우스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":30233,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gyeongju_gohyang/","foodTypes":"한식(면류)","latitude":35.841545929710975,"longitude":129.21444311395862,"bookYear":null,"nameKR":"고향밀면","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30370,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.30895301356848,"longitude":126.83176137379068,"bookYear":null,"nameKR":"둥이상회","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":30380,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":35.3182570478619,"longitude":129.00161230910112,"bookYear":null,"nameKR":"오딘레스토랑","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":30498,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.20290757152488,"longitude":127.07238557352164,"bookYear":null,"nameKR":"더힐링","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":30542,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moonspub","foodTypes":"바/주점","latitude":37.32116076099238,"longitude":127.10940034511819,"bookYear":null,"nameKR":"문스펍","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":30568,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.84079332617382,"longitude":128.42381271306738,"bookYear":null,"nameKR":"회운정","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":30692,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.074945606109104,"longitude":129.38921819008218,"bookYear":null,"nameKR":"안동소문난국밥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":31247,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.4041854916915,"longitude":127.155909978037,"bookYear":null,"nameKR":"향기나는터","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":31622,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.6934760608726,"longitude":126.753956195613,"bookYear":null,"nameKR":"더기스스테이크하우스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":31689,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/yico_brew","foodTypes":"디저트/차/베이커리","latitude":37.624978796861,"longitude":126.556987539795,"bookYear":null,"nameKR":"이코브루커피숍","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":31696,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/salon_de_n.ti","foodTypes":"기타","latitude":36.338150690987,"longitude":127.386208660664,"bookYear":null,"nameKR":"살롱드느티","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":31736,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/iwannago_coffee","foodTypes":"디저트/차/베이커리","latitude":35.2277903838408,"longitude":128.58574496535,"bookYear":null,"nameKR":"가고파커피로스터스","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":31767,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ambergris_coffee","foodTypes":"디저트/차/베이커리","latitude":35.2569384530326,"longitude":128.635685915728,"bookYear":null,"nameKR":"앰버그리스커피","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":31768,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/classicground","foodTypes":"이탈리아식","latitude":35.2279819129618,"longitude":128.585413477633,"bookYear":null,"nameKR":"클래식그라운드","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":31769,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.2281112158485,"longitude":128.585690470569,"bookYear":null,"nameKR":"스시쇼우진","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":31843,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/dagwa__wa/","foodTypes":"디저트/차/베이커리","latitude":35.8402526316959,"longitude":129.229524885426,"bookYear":null,"nameKR":"다과와","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":31846,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bedford.coffee","foodTypes":"디저트/차/베이커리","latitude":37.2643001257722,"longitude":127.035308532952,"bookYear":null,"nameKR":"베드포드커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":31860,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.4453374346622,"longitude":126.705126681044,"bookYear":null,"nameKR":"라이프에이드커피","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":31873,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_koharu","foodTypes":"디저트/차/베이커리","latitude":36.339508861744,"longitude":127.34336669788,"bookYear":null,"nameKR":"카페코하루 ","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":31875,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jubangjang_brewery","foodTypes":"바/주점","latitude":36.3679741713264,"longitude":127.316862477975,"bookYear":null,"nameKR":"주방장양조장","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":31911,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/etcroi/","foodTypes":"디저트/차/베이커리","latitude":35.1740006805248,"longitude":129.128422232875,"bookYear":null,"nameKR":"에끄로와","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":31938,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pezcoffee","foodTypes":"디저트/차/베이커리","latitude":35.5400522718564,"longitude":129.333027187473,"bookYear":null,"nameKR":"페즈","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":32039,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gijangsonkal.official/","foodTypes":"한식(면류)","latitude":35.2436967938759,"longitude":129.214868508134,"bookYear":null,"nameKR":"원조기장손칼국수(기장시장본점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":32061,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/le_montauk","foodTypes":"이탈리아식","latitude":36.3796574940844,"longitude":127.326148028347,"bookYear":null,"nameKR":"르몽탁","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":32161,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.8267664867005,"longitude":128.623332881346,"bookYear":null,"nameKR":"대장금안동생고기","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":32194,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sindo_kalguksu","foodTypes":"한식(면류)","latitude":36.3190976764496,"longitude":127.430335926565,"bookYear":null,"nameKR":"신도칼국수(대사점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":32531,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/doobal_caffe/","foodTypes":"디저트/차/베이커리","latitude":36.3109041782029,"longitude":126.51381134422,"bookYear":null,"nameKR":"두발카페&욕장과자점","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":32589,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/myeong828/","foodTypes":"한식(어패류)","latitude":37.6401757575893,"longitude":126.789211482973,"bookYear":null,"nameKR":"이춘명숙성회(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":5852,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.521511,"longitude":127.146345,"bookYear":null,"nameKR":"하나비원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":6223,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.197633,"longitude":127.077139,"bookYear":null,"nameKR":"아그집쭈꾸미(동탄점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":6274,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/chung_hyun_/","foodTypes":"일식","latitude":37.657549,"longitude":126.772949,"bookYear":null,"nameKR":"타마시초밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":6290,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.2705028422942,"longitude":127.026142404305,"bookYear":null,"nameKR":"옥동이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":6330,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.3902386744947,"longitude":126.952756892814,"bookYear":null,"nameKR":"즉석감고을김밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":6335,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.3838596641878,"longitude":126.961341980465,"bookYear":null,"nameKR":"동천홍","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":6341,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/ilsansodong/","foodTypes":"디저트/차/베이커리","latitude":37.6569,"longitude":126.769944,"bookYear":null,"nameKR":"소리가있는동네","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":7064,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.418111,"longitude":127.376796,"bookYear":null,"nameKR":"산밑할머니묵집(덕진동점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":7077,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.194791,"longitude":128.116154,"bookYear":null,"nameKR":"하연옥(하대동점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":7130,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.349374,"longitude":127.344384,"bookYear":null,"nameKR":"청국장과보리밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":7170,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.402269,"longitude":126.956462,"bookYear":null,"nameKR":"소담비빔국수(안양점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":7400,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":34.8309304122605,"longitude":128.416491579543,"bookYear":null,"nameKR":"오미사꿀빵(도남점)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":7408,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.8813553858715,"longitude":128.652180072578,"bookYear":null,"nameKR":"마루(동촌점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":7358,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/butterfingerpancakes/","foodTypes":"기타","latitude":37.498831,"longitude":127.025642,"bookYear":null,"nameKR":"버터핑거팬케익스(분당점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":7378,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.3834969160154,"longitude":127.139035785641,"bookYear":null,"nameKR":"홍천화로구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":7388,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.213759,"longitude":127.032975,"bookYear":null,"nameKR":"본수원갈비(병점점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":7587,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.65773,"longitude":126.771376,"bookYear":null,"nameKR":"청목(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":7590,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.3256999927981,"longitude":127.433373487446,"bookYear":null,"nameKR":"청주해장국(원동점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":7600,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.8190197234717,"longitude":127.159104309164,"bookYear":null,"nameKR":"원조할머니학화호두과자(천안터미널)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":7606,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.824046,"longitude":127.16466,"bookYear":null,"nameKR":"팔복도가니탕(천안점)","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":7302,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.384244,"longitude":127.131147,"bookYear":null,"nameKR":"강남면옥(분당점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":7503,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.271979,"longitude":129.159406,"bookYear":null,"nameKR":"보림한우전문점","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":7508,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.45965406171772,"longitude":126.67982591819967,"bookYear":null,"nameKR":"청실홍실(주안직영점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":7530,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.323217,"longitude":127.109301,"bookYear":null,"nameKR":"쏘렐라에프라텔로","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":7762,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.571691,"longitude":128.698058,"bookYear":null,"nameKR":"맘모스제과(송현동점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"안동시","status":"ACTIVE"},
        {"id":7461,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.6563344555232,"longitude":127.479962483422,"bookYear":null,"nameKR":"소영칼국수(내덕점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":7476,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.872097,"longitude":128.596393,"bookYear":null,"nameKR":"미성당(동아백화점점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":7478,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.742119,"longitude":128.082531,"bookYear":null,"nameKR":"문경약돌한우타운","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":7482,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.3532,"longitude":127.360713,"bookYear":null,"nameKR":"신도칼국수(월평점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":7484,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1052463265321,"longitude":128.970599875807,"bookYear":null,"nameKR":"옥미아구찜(하단점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":7487,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.355654,"longitude":127.442025,"bookYear":null,"nameKR":"소복갈비","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"대덕구","status":"ACTIVE"},
        {"id":7425,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.158112,"longitude":129.065158,"bookYear":null,"nameKR":"서울깍두기 전포동점","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":7684,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.168864,"longitude":129.177797,"bookYear":null,"nameKR":"신창국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":7814,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.0858102646962,"longitude":129.042942251709,"bookYear":null,"nameKR":"도날드","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":7883,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.54188,"longitude":126.788352,"bookYear":null,"nameKR":"팽오리농장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":8119,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.445088,"longitude":126.698557,"bookYear":null,"nameKR":"송원식당(구월동점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":9368,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":36.3563034823053,"longitude":127.338779743928,"bookYear":null,"nameKR":"가드니아","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":9389,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.9069985378003,"longitude":128.611285546387,"bookYear":null,"nameKR":"예그리나","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":9394,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.239122,"longitude":128.654941,"bookYear":null,"nameKR":"블루핀","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":9466,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.3563034823053,"longitude":127.338779743928,"bookYear":null,"nameKR":"구룡","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":9292,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.219814,"longitude":129.082072,"bookYear":null,"nameKR":"허심청브로이","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":9295,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.151981,"longitude":126.850553,"bookYear":null,"nameKR":"파라뷰","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":10994,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.367292,"longitude":129.394414,"bookYear":null,"nameKR":"귀빈대게회타운","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":11252,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.11194,"longitude":129.015696,"bookYear":null,"nameKR":"동경초밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":9935,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.842957969281,"longitude":127.352786456594,"bookYear":null,"nameKR":"향우식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9937,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.8465205146098,"longitude":127.347389391656,"bookYear":null,"nameKR":"홍도회관","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9950,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.6703284585923,"longitude":127.086242318716,"bookYear":null,"nameKR":"흑산도횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9955,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":34.799943,"longitude":127.087353,"bookYear":null,"nameKR":"미력양탕집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":10967,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.3504915847435,"longitude":128.697112833777,"bookYear":null,"nameKR":"서원한정식","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"의성군","status":"ACTIVE"},
        {"id":11218,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.0742550552019,"longitude":129.015683152394,"bookYear":null,"nameKR":"동명횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":11483,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.156853,"longitude":129.056647,"bookYear":null,"nameKR":"라세느(롯데호텔부산)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":9664,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.7826653754228,"longitude":126.451120461643,"bookYear":null,"nameKR":"동명반점","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9666,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.7318885389801,"longitude":127.614631622492,"bookYear":null,"nameKR":"토지","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9667,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.81054,"longitude":127.583766,"bookYear":null,"nameKR":"조선시대","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9910,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":34.7688302643993,"longitude":127.080360002879,"bookYear":null,"nameKR":"녹황우","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9916,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.7691802797339,"longitude":127.081990053668,"bookYear":null,"nameKR":"중앙식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":9931,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":34.8373214393593,"longitude":127.341919289162,"bookYear":null,"nameKR":"여자만장어구이","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"보성군","status":"ACTIVE"},
        {"id":10431,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4767277860233,"longitude":126.597690507019,"bookYear":null,"nameKR":"장터활어횟집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":10438,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4755902779577,"longitude":126.598143386573,"bookYear":null,"nameKR":"소양강횟집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":10677,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.8330118268138,"longitude":129.213379287372,"bookYear":null,"nameKR":"황남맷돌순두부","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":10678,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.170952,"longitude":128.11883,"bookYear":null,"nameKR":"가야숯불가든","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":10688,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.188328,"longitude":128.074368,"bookYear":null,"nameKR":"풍국민물장어","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":10697,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.6926297706317,"longitude":127.878980472386,"bookYear":null,"nameKR":"건계정식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거창군","status":"ACTIVE"},
        {"id":9628,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.776606124201,"longitude":127.571170282192,"bookYear":null,"nameKR":"동궁오리요리전문점","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9893,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.642629573985,"longitude":126.659323635743,"bookYear":null,"nameKR":"돌산가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":10154,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.3559426035771,"longitude":126.448662689668,"bookYear":null,"nameKR":"국제식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":10157,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.4498376279672,"longitude":126.817479321111,"bookYear":null,"nameKR":"거북횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":10159,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":34.6398689979381,"longitude":126.770127875533,"bookYear":null,"nameKR":"금강산식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":10412,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.475701,"longitude":126.599321,"bookYear":null,"nameKR":"군산광주회집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":10414,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4743414020834,"longitude":126.597351147495,"bookYear":null,"nameKR":"이경숙아줌마횟집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(월미도)","status":"ACTIVE"},
        {"id":10672,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.0703279217614,"longitude":127.749162668245,"bookYear":null,"nameKR":"해동옥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":10675,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.0291482800388,"longitude":127.902615951142,"bookYear":null,"nameKR":"장수숯불갈비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":10907,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.81431,"longitude":127.770726,"bookYear":null,"nameKR":"점터전통찻집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거창군","status":"ACTIVE"},
        {"id":10916,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.0717896831116,"longitude":127.756906887369,"bookYear":null,"nameKR":"모정식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":10924,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.1891485863631,"longitude":127.622137509594,"bookYear":null,"nameKR":"태봉식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":11424,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.169843,"longitude":129.131102,"bookYear":null,"nameKR":"얌차이나","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":11429,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.19389,"longitude":129.1306,"bookYear":null,"nameKR":"언양봉계숯불갈비","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":9595,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.6058199428081,"longitude":126.41450780777,"bookYear":null,"nameKR":"간월도바다횟집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9608,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.7114398329022,"longitude":126.546730145343,"bookYear":null,"nameKR":"읍성뚝배기","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9617,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.7830103534587,"longitude":126.445107145167,"bookYear":null,"nameKR":"부영아구","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9862,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":36.3624460366741,"longitude":127.377656020425,"bookYear":null,"nameKR":"바이젠하우스(월평점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":10126,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.28246,"longitude":126.500954,"bookYear":null,"nameKR":"태능갈비","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":10136,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.2761475679764,"longitude":126.503423032322,"bookYear":null,"nameKR":"영광버섯매운탕","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":10375,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.875252,"longitude":128.616893,"bookYear":null,"nameKR":"고향집칼국수","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":10395,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.991123,"longitude":128.636745,"bookYear":null,"nameKR":"블루문레스토랑","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":10622,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":34.9871787634491,"longitude":127.836768214353,"bookYear":null,"nameKR":"현영식육식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":10638,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.0987653629084,"longitude":128.803769934161,"bookYear":null,"nameKR":"용원꼬리곰탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":10870,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.4934426776808,"longitude":127.835518294651,"bookYear":null,"nameKR":"초원한방식육가든","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"산청군","status":"ACTIVE"},
        {"id":10901,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.00287,"longitude":127.786518,"bookYear":null,"nameKR":"섬진강식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":11133,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.1602431446287,"longitude":129.057684442905,"bookYear":null,"nameKR":"일신초밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":11143,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/CAFE_JUKJEON/","foodTypes":"한식(민물어패류)","latitude":35.1608275351473,"longitude":128.899116010823,"bookYear":null,"nameKR":"죽전가","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":11149,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1603646330636,"longitude":129.056564378619,"bookYear":null,"nameKR":"활력은장수국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":11152,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.15813,"longitude":129.0575,"bookYear":null,"nameKR":"아리한정식","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":11413,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1725673796059,"longitude":129.1767138913,"bookYear":null,"nameKR":"선미아구찜","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":9570,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.0069390632113,"longitude":126.442239620271,"bookYear":null,"nameKR":"삼길포막횟집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9574,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.006888,"longitude":126.442573,"bookYear":null,"nameKR":"홍일수산","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9578,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.960082,"longitude":126.336329,"bookYear":null,"nameKR":"명성회센터","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":10095,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.1816720973093,"longitude":126.604888289722,"bookYear":null,"nameKR":"해월축산회관","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"함평군","status":"ACTIVE"},
        {"id":10354,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.86908,"longitude":128.589173,"bookYear":null,"nameKR":"도심속의산골","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":10591,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.688473421591,"longitude":127.90769940181,"bookYear":null,"nameKR":"외양간구시","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거창군","status":"ACTIVE"},
        {"id":11366,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.076886,"longitude":129.023868,"bookYear":null,"nameKR":"해적해물나라","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":11370,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.1167830406222,"longitude":129.017350253755,"bookYear":null,"nameKR":"홍복","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":10061,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.956434,"longitude":127.520548,"bookYear":null,"nameKR":"몽돌해물탕","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10830,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.6841011584163,"longitude":128.718184478287,"bookYear":null,"nameKR":"프로방스","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":11342,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1150726941653,"longitude":129.01590480137,"bookYear":null,"nameKR":"조방낙지(대신직영점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":11352,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.071614,"longitude":129.01719,"bookYear":null,"nameKR":"박서방한정식","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":11356,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.1020989565766,"longitude":129.019565857061,"bookYear":null,"nameKR":"송원재첩국","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":11357,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.075193,"longitude":129.015969,"bookYear":null,"nameKR":"송일횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":9515,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.537715,"longitude":129.336153,"bookYear":null,"nameKR":"트레비브로이","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":10022,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.98538595397,"longitude":127.305672181687,"bookYear":null,"nameKR":"조계산보리밥집원조집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":10787,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.941559,"longitude":127.875081,"bookYear":null,"nameKR":"대구횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":10789,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.918001,"longitude":128.031009,"bookYear":null,"nameKR":"보물선횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":10798,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.001959,"longitude":127.789412,"bookYear":null,"nameKR":"물방골가든","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":10803,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.71331,"longitude":128.044817,"bookYear":null,"nameKR":"오륙도회센터","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":11068,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.218058,"longitude":129.227817,"bookYear":null,"nameKR":"만포장횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":9486,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.7760354523476,"longitude":127.567442651231,"bookYear":null,"nameKR":"굴천지","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":9492,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.77982,"longitude":126.461698,"bookYear":null,"nameKR":"설악칡냉면","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서산시","status":"ACTIVE"},
        {"id":9738,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.276327,"longitude":127.251483,"bookYear":null,"nameKR":"콩밭가인","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"계룡시","status":"ACTIVE"},
        {"id":10764,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.089034518122,"longitude":127.71619867811,"bookYear":null,"nameKR":"섬진강횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":10774,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.0945697388962,"longitude":128.819958674148,"bookYear":null,"nameKR":"충무횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":11015,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.627311419176,"longitude":129.413839035833,"bookYear":null,"nameKR":"별미회식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영덕군","status":"ACTIVE"},
        {"id":11699,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.3182193953606,"longitude":126.841868830993,"bookYear":null,"nameKR":"안산주물럭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11728,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(육류)","latitude":35.0558425721153,"longitude":128.369129130932,"bookYear":null,"nameKR":"늘푸른숯불갈비","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"고성군","status":"ACTIVE"},
        {"id":11734,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.324299,"longitude":126.88804,"bookYear":null,"nameKR":"태을봉","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11769,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.334831,"longitude":126.857817,"bookYear":null,"nameKR":"진장어탕구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":11954,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.661851,"longitude":127.293051,"bookYear":null,"nameKR":"논골추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":12018,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.335956,"longitude":127.486976,"bookYear":null,"nameKR":"옥계촌누룽지백숙","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":12215,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.9076410226338,"longitude":127.006575432632,"bookYear":null,"nameKR":"봉암식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12243,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.7748492469007,"longitude":126.937817592415,"bookYear":null,"nameKR":"산막골식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":12278,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.2562141998578,"longitude":127.645166011477,"bookYear":null,"nameKR":"웅골손두부","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"여주시","status":"ACTIVE"},
        {"id":11638,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.346876,"longitude":126.943255,"bookYear":null,"nameKR":"청기와집뼈감자탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11661,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.374082,"longitude":126.929494,"bookYear":null,"nameKR":"수리산영양돌솥쌈밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11663,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.3597321221002,"longitude":126.930478396117,"bookYear":null,"nameKR":"삼정복집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11686,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.3593263028997,"longitude":126.922509345266,"bookYear":null,"nameKR":"본향칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":11898,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.594219,"longitude":127.186344,"bookYear":null,"nameKR":"우렁찬명품한우정육식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11921,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.595457,"longitude":127.49034,"bookYear":null,"nameKR":"유명산종점가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":11929,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.741855,"longitude":127.352686,"bookYear":null,"nameKR":"들꽃향기식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":11935,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.8471629834303,"longitude":127.355504525143,"bookYear":null,"nameKR":"썬일가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":11938,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.8228765970744,"longitude":127.340066401627,"bookYear":null,"nameKR":"일가가마솥설렁탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":11941,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.715642,"longitude":127.502256,"bookYear":null,"nameKR":"검단집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":11944,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.8661795861439,"longitude":127.349320312752,"bookYear":null,"nameKR":"산이좋은사람들","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":11946,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.677418,"longitude":127.480667,"bookYear":null,"nameKR":"설악면옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":11948,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.6318067354765,"longitude":127.350963153008,"bookYear":null,"nameKR":"금남멧돼지","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11843,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.643676,"longitude":127.23103,"bookYear":null,"nameKR":"민속두부마을과돌솥밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11858,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.703221,"longitude":127.163305,"bookYear":null,"nameKR":"삼정숯불갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11864,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.7318376879041,"longitude":127.194842456027,"bookYear":null,"nameKR":"장우가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11875,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.698614,"longitude":127.214373,"bookYear":null,"nameKR":"수사골송어횟집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11882,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.592485,"longitude":127.337479,"bookYear":null,"nameKR":"종갓집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":12101,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.519719,"longitude":126.770446,"bookYear":null,"nameKR":"두미만두","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":12107,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.0027670772241,"longitude":127.317616484034,"bookYear":null,"nameKR":"쌍동이네","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":12115,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.503812,"longitude":126.804623,"bookYear":null,"nameKR":"산골마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":12119,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.505356,"longitude":127.439573,"bookYear":null,"nameKR":"나루께","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":11544,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.2817982275741,"longitude":127.019949796016,"bookYear":null,"nameKR":"본가갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":11813,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.7474106150027,"longitude":127.187985633382,"bookYear":null,"nameKR":"사랑방식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11816,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.6874826263311,"longitude":127.21091768464,"bookYear":null,"nameKR":"닭이봉춘천닭갈비(오남점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11830,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.6066466653893,"longitude":127.16009468271,"bookYear":null,"nameKR":"최고집칼국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":11831,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.586331,"longitude":127.172965,"bookYear":null,"nameKR":"안채","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":12083,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.4981132034292,"longitude":126.78632933846,"bookYear":null,"nameKR":"조마루감자탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":12087,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.4827361568574,"longitude":126.777766298553,"bookYear":null,"nameKR":"사랑방손칼국수(부천점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":12333,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.9106389800343,"longitude":126.733603103531,"bookYear":null,"nameKR":"통일촌장단콩마을","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":12338,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.85058,"longitude":126.8879,"bookYear":null,"nameKR":"승잠원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":12341,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.9555368536587,"longitude":126.918621476496,"bookYear":null,"nameKR":"황소가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":11778,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.3144320175943,"longitude":126.797268630687,"bookYear":null,"nameKR":"복청","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":12424,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.505356,"longitude":127.439573,"bookYear":null,"nameKR":"젤코바","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":12517,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/madeleine_bakery_daejeon/","foodTypes":"디저트/차/베이커리","latitude":36.374164,"longitude":127.320189,"bookYear":null,"nameKR":"마들렌과자점","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":12519,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.85632880292,"longitude":128.634506508151,"bookYear":null,"nameKR":"마들렌베이커리(범어점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":12529,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.4048756980504,"longitude":127.402953002668,"bookYear":null,"nameKR":"베이커리별","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":12538,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.6058852563104,"longitude":127.155773257318,"bookYear":null,"nameKR":"구루몽","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":12583,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.2865287285921,"longitude":127.00677078598,"bookYear":null,"nameKR":"옛날서문순대국밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":12590,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.2657388823651,"longitude":127.031577113974,"bookYear":null,"nameKR":"피피케이키친","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":12606,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.5282126575391,"longitude":127.819985712511,"bookYear":null,"nameKR":"길손식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":12693,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.870439954623,"longitude":129.203617656555,"bookYear":null,"nameKR":"랑꽁뜨레(금장점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":12718,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.3982086428734,"longitude":126.976011498662,"bookYear":null,"nameKR":"남부정육점","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":12876,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.2910635416226,"longitude":126.852108005447,"bookYear":null,"nameKR":"원조원촌칡냉면","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":13030,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.046661,"longitude":129.368095,"bookYear":null,"nameKR":"오대양물회","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":13042,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6851718739014,"longitude":126.770449023659,"bookYear":null,"nameKR":"중앙식당","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":13048,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/jayeonsam_cafe/","foodTypes":"한식(육류)","latitude":37.1470445575554,"longitude":126.919772494595,"bookYear":null,"nameKR":"자연샘","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":13115,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.7400229905642,"longitude":127.036958019869,"bookYear":null,"nameKR":"이순례손만두","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":13121,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.349944,"longitude":127.388269,"bookYear":null,"nameKR":"미진","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":13129,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.798516,"longitude":129.31271,"bookYear":null,"nameKR":"토함산식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":13162,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.2300539638419,"longitude":129.088890571496,"bookYear":null,"nameKR":"부대꼬마김밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":13177,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.6514029948687,"longitude":126.779120233334,"bookYear":null,"nameKR":"삼합촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":13198,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.238036,"longitude":127.487709,"bookYear":null,"nameKR":"그옛날산채식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":13244,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.83403,"longitude":127.509654,"bookYear":null,"nameKR":"동이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":13283,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.646661,"longitude":127.234833,"bookYear":null,"nameKR":"명품족발","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":13367,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.741936,"longitude":127.04742,"bookYear":null,"nameKR":"남원순대국","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":13410,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.175004,"longitude":127.054776,"bookYear":null,"nameKR":"물향기쌈밥정식","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"오산시","status":"ACTIVE"},
        {"id":13417,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.724746,"longitude":126.56708,"bookYear":null,"nameKR":"제일낙지마당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영암군","status":"ACTIVE"},
        {"id":13489,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4867685977141,"longitude":126.813717912591,"bookYear":null,"nameKR":"환상의섬","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":13519,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.377327,"longitude":127.139151,"bookYear":null,"nameKR":"대포항","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":13531,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.5185072399652,"longitude":129.303597835876,"bookYear":null,"nameKR":"신라해장국","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":13536,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":0,"longitude":0,"bookYear":null,"nameKR":"웅이네오돌뼈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":13548,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.001058,"longitude":129.255203,"bookYear":null,"nameKR":"초원식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":13607,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.0290828990396,"longitude":127.446685381961,"bookYear":null,"nameKR":"황우마을","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":13609,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.7727070788959,"longitude":129.307197891587,"bookYear":null,"nameKR":"비룡","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":13646,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.268616,"longitude":126.569224,"bookYear":null,"nameKR":"청미","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":13659,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.1542808560581,"longitude":126.688937434177,"bookYear":null,"nameKR":"진미식당","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"서천군","status":"ACTIVE"},
        {"id":13661,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.312469,"longitude":126.981998,"bookYear":null,"nameKR":"김순옥찹쌀도너츠","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"담양군","status":"ACTIVE"},
        {"id":13780,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.0768683678018,"longitude":128.321761430162,"bookYear":null,"nameKR":"여담","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":13977,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"아시아식","latitude":35.2224402263631,"longitude":128.676637085354,"bookYear":null,"nameKR":"펀자브","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":13992,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.5574106726233,"longitude":129.357225248316,"bookYear":null,"nameKR":"더안","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":14011,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.3906171162014,"longitude":127.306643739189,"bookYear":null,"nameKR":"굴이야기","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":14022,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/casa338.love/","foodTypes":"이탈리아식","latitude":37.801162,"longitude":127.103732,"bookYear":null,"nameKR":"까사338","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":14053,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.8688077925828,"longitude":128.590269032262,"bookYear":null,"nameKR":"약전삼계탕","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":14055,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.58377,"longitude":126.9796,"bookYear":null,"nameKR":"한옥마을박씨네추어탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":14111,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.489858,"longitude":126.752836,"bookYear":null,"nameKR":"경성집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":14122,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.6823004751203,"longitude":126.89698212825,"bookYear":null,"nameKR":"전주장작불곰탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":14129,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.2648893968716,"longitude":127.026753753594,"bookYear":null,"nameKR":"남수통닭","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":14250,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.2057216291227,"longitude":126.644934819053,"bookYear":null,"nameKR":"대부도 원조 11호 횟집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":14254,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"대하회집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":14288,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"등대횟집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":14309,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.413073,"longitude":127.129732,"bookYear":null,"nameKR":"만강홍","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":14557,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(어패류)","latitude":37.3126893506096,"longitude":126.999315816824,"bookYear":null,"nameKR":"우전옥간장게장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":14751,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.441264707619695,"longitude":126.67009130711399,"bookYear":null,"nameKR":"학운정(학익점)","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"미추홀구","status":"ACTIVE"},
        {"id":14447,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.504761,"longitude":126.350904,"bookYear":null,"nameKR":"숲속가든","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":14633,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"중앙횟집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":14651,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.31986501536,"longitude":127.109659868853,"bookYear":null,"nameKR":"차이란","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":14653,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.6704579481819,"longitude":126.773535921791,"bookYear":null,"nameKR":"차이홍","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":14863,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.732382,"longitude":128.111561,"bookYear":null,"nameKR":"채가네들깨국수","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":14958,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.383804,"longitude":127.134969,"bookYear":null,"nameKR":"조선면옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":15571,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.638496,"longitude":126.773459,"bookYear":null,"nameKR":"예향","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":15846,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.8552853638991,"longitude":127.549070466662,"bookYear":null,"nameKR":"동기간","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":15215,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.5012630856663,"longitude":126.774675576889,"bookYear":null,"nameKR":"희래등","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":15478,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.135675,"longitude":129.092485,"bookYear":null,"nameKR":"동보현","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":15731,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.187,"longitude":126.653681,"bookYear":null,"nameKR":"전곡종합수산시장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":15740,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.83697,"longitude":127.130983,"bookYear":null,"nameKR":"청해","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":15803,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.6172042504425,"longitude":127.474146968846,"bookYear":null,"nameKR":"동흥반점","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":15805,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.5997,"longitude":126.8177,"bookYear":null,"nameKR":"강나루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":16096,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":35.267934,"longitude":129.084632,"bookYear":null,"nameKR":"일디오보","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":16122,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.4562465522046,"longitude":127.123192605945,"bookYear":null,"nameKR":"초가집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":16181,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.2050438279253,"longitude":128.579812023496,"bookYear":null,"nameKR":"덕성복집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":16182,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.2049326434827,"longitude":128.579602670234,"bookYear":null,"nameKR":"광포복집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(마산)","status":"ACTIVE"},
        {"id":16186,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.4405947519814,"longitude":128.145591935735,"bookYear":null,"nameKR":"황태촌","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"합천군","status":"ACTIVE"},
        {"id":16215,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.160078,"longitude":127.338009,"bookYear":null,"nameKR":"들꽃향기","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"금산군","status":"ACTIVE"},
        {"id":16564,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.956126,"longitude":126.916756,"bookYear":null,"nameKR":"임진강한우마을(원풍점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":16603,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.88254,"longitude":128.66664,"bookYear":null,"nameKR":"우미정","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":16632,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.7550661920895,"longitude":126.774599707575,"bookYear":null,"nameKR":"금촌능이버섯백숙","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":16406,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.5572689409738,"longitude":129.066198768079,"bookYear":null,"nameKR":"간월산장","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":16408,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.832554,"longitude":128.421895,"bookYear":null,"nameKR":"장방식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":16691,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.5421419100303,"longitude":127.240200957273,"bookYear":null,"nameKR":"털보집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"하남시","status":"ACTIVE"},
        {"id":17132,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.0142723656379,"longitude":127.268151887047,"bookYear":null,"nameKR":"서해수산","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":17075,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.531482,"longitude":128.943977,"bookYear":null,"nameKR":"약산가든","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"밀양시","status":"ACTIVE"},
        {"id":17085,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.509511,"longitude":126.335835,"bookYear":null,"nameKR":"안면도안흥꽃게장","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":17161,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.5583648084689,"longitude":129.356022065502,"bookYear":null,"nameKR":"다향오리농원","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":17170,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"한식(일반한식)","latitude":37.59478,"longitude":127.1943,"bookYear":null,"nameKR":"초원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":17355,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.6848917540755,"longitude":126.775831991538,"bookYear":null,"nameKR":"빵공방아키","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":17434,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.2523268448601,"longitude":127.076726041705,"bookYear":null,"nameKR":"덕담오리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":17521,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.870046,"longitude":128.626615,"bookYear":null,"nameKR":"청정한우식육식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":17569,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.8790409435241,"longitude":128.501341163605,"bookYear":null,"nameKR":"소백산풍기인삼순대","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영주시","status":"ACTIVE"},
        {"id":17574,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.51939,"longitude":126.7699,"bookYear":null,"nameKR":"대부","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":17677,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.1168667623011,"longitude":128.348010315285,"bookYear":null,"nameKR":"선산고을","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":17740,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5057070612746,"longitude":127.438220028667,"bookYear":null,"nameKR":"리버사이드테라스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":17870,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.5397335691739,"longitude":126.470599022758,"bookYear":null,"nameKR":"남당리수산","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":17888,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.2514587479802,"longitude":127.075935916587,"bookYear":null,"nameKR":"원조회세꼬시","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":17951,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.3751442943993,"longitude":126.942811124243,"bookYear":null,"nameKR":"한우만","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":18194,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.252995,"longitude":127.362522,"bookYear":null,"nameKR":"오일레스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"이천시","status":"ACTIVE"},
        {"id":18215,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4530135017078,"longitude":126.864602782845,"bookYear":null,"nameKR":"신농한우","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":18282,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.0138727440644,"longitude":128.604676665935,"bookYear":null,"nameKR":"부촌가든","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":18336,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.6074765865114,"longitude":128.282490692463,"bookYear":null,"nameKR":"용궁순대","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"예천군","status":"ACTIVE"},
        {"id":18381,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/promessa_official_/","foodTypes":"기타","latitude":37.62165,"longitude":126.8466,"bookYear":null,"nameKR":"프로메사","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":18614,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"맛나분식","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":18812,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.402671,"longitude":126.94999,"bookYear":null,"nameKR":"고려약선삼계탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안양시","status":"ACTIVE"},
        {"id":18914,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gj_chosunok/","foodTypes":"한식(어패류)","latitude":35.0964896803868,"longitude":126.90729735327,"bookYear":null,"nameKR":"조선옥","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":18936,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.7004730480689,"longitude":126.409140131321,"bookYear":null,"nameKR":"이나경강화약쑥떡","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":19306,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.3521286990058,"longitude":127.389495988741,"bookYear":null,"nameKR":"흑돼지","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":19322,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.61697,"longitude":127.138289,"bookYear":null,"nameKR":"옹기꽃게장(구리점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":19367,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.2101515324277,"longitude":127.470080529505,"bookYear":null,"nameKR":"백련산방","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"구례군","status":"ACTIVE"},
        {"id":19207,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.5611586732723,"longitude":129.122544906957,"bookYear":null,"nameKR":"언양향토불고기","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":19217,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.3064422466582,"longitude":126.848336010907,"bookYear":null,"nameKR":"명장한우","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":19252,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":36.6100586115481,"longitude":127.492853220563,"bookYear":null,"nameKR":"하나비초밥","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":19496,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":0,"longitude":0,"bookYear":null,"nameKR":"남해해물","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":19566,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.9837637245841,"longitude":128.625413591001,"bookYear":null,"nameKR":"부산짚불꼼장어구이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":19599,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"유럽식","latitude":35.8721085688113,"longitude":128.595604265255,"bookYear":null,"nameKR":"사마리칸트","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":19651,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe737/","foodTypes":"디저트/차/베이커리","latitude":35.837534,"longitude":129.218603,"bookYear":null,"nameKR":"카페737","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":19674,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.3779304784601,"longitude":127.102302829056,"bookYear":null,"nameKR":"큰집쭈꾸미","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":19825,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.8655614437094,"longitude":128.614436275603,"bookYear":null,"nameKR":"석순이네민물매운탕","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":19864,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.6153441214611,"longitude":129.449346480171,"bookYear":null,"nameKR":"유정호선장집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":19715,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.418274,"longitude":127.258516,"bookYear":null,"nameKR":"농가보쌈족발","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":19724,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4105742693921,"longitude":127.265631402901,"bookYear":null,"nameKR":"윤씨보쌈","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":19754,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.2181545982471,"longitude":129.103626956,"bookYear":null,"nameKR":"정가네합천돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":19757,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.179286,"longitude":129.071592,"bookYear":null,"nameKR":"봉평면옥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":20145,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.2615905625052,"longitude":128.872949490457,"bookYear":null,"nameKR":"백정한우","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"김해시","status":"ACTIVE"},
        {"id":20361,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":37.396592,"longitude":127.113675,"bookYear":null,"nameKR":"붓처스컷(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":20293,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.871509,"longitude":128.570859,"bookYear":null,"nameKR":"서대포소금구이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"서구","status":"ACTIVE"},
        {"id":20336,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":0,"longitude":0,"bookYear":null,"nameKR":"항도반점","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":20456,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.83397,"longitude":128.537753,"bookYear":null,"nameKR":"우리가락손칼국수","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":20486,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.453331525576594,"longitude":126.86425930921743,"bookYear":null,"nameKR":"청평매운탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":20500,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.3841334693848,"longitude":127.007004140966,"bookYear":null,"nameKR":"청계누룽지백숙","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":20618,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.1522713413809,"longitude":128.662196140913,"bookYear":null,"nameKR":"금강산면옥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":20665,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.8686354092993,"longitude":128.61603145075,"bookYear":null,"nameKR":"메리포핀스","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":20846,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.1715734089227,"longitude":126.904972406077,"bookYear":null,"nameKR":"깻잎통닭","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":20847,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.205348,"longitude":127.074331,"bookYear":null,"nameKR":"노조미","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":21224,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.7585762946424,"longitude":127.296415217146,"bookYear":null,"nameKR":"아우내 엄나무순대","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":20992,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.406283,"longitude":127.116422,"bookYear":null,"nameKR":"세시셀라(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":21023,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.837268,"longitude":128.414271,"bookYear":null,"nameKR":"어촌싱싱회해물탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":21173,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.908907,"longitude":128.198201,"bookYear":null,"nameKR":"성주가나안농장","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"성주군","status":"ACTIVE"},
        {"id":21210,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.6289641312359,"longitude":127.81274424202,"bookYear":null,"nameKR":"삼일식육식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"함양군","status":"ACTIVE"},
        {"id":21332,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.8170178833613,"longitude":128.533739426911,"bookYear":null,"nameKR":"다복식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":21337,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.8424106812598,"longitude":128.578840550328,"bookYear":null,"nameKR":"정우초밥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"남구","status":"ACTIVE"},
        {"id":21398,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.620448,"longitude":126.714817,"bookYear":null,"nameKR":"천명","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":21402,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.74405,"longitude":127.049439,"bookYear":null,"nameKR":"오뎅식당별관","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":21427,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.406283,"longitude":127.116422,"bookYear":null,"nameKR":"하꼬야(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":21457,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":36.223105,"longitude":127.483138,"bookYear":null,"nameKR":"채담","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"금산군","status":"ACTIVE"},
        {"id":21517,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.275279971024,"longitude":127.014707109869,"bookYear":null,"nameKR":"춘천막국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":21526,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.869630448297,"longitude":128.592370549189,"bookYear":null,"nameKR":"통나무집","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":21551,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.8588829529434,"longitude":128.504812694454,"bookYear":null,"nameKR":"일출","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":21568,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.338348,"longitude":127.110084,"bookYear":null,"nameKR":"금강숯불바베큐치킨","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":21671,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.089986,"longitude":129.037914,"bookYear":null,"nameKR":"영도해장국","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":21750,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.202225,"longitude":129.079137,"bookYear":null,"nameKR":"동보성","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"동래구","status":"ACTIVE"},
        {"id":21758,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.3308919601116,"longitude":129.004018165795,"bookYear":null,"nameKR":"석천","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"양산시","status":"ACTIVE"},
        {"id":21684,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.238455,"longitude":129.245021,"bookYear":null,"nameKR":"송미횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":21686,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":34.8441335194249,"longitude":128.427485168134,"bookYear":null,"nameKR":"거북당꿀빵","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":21698,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.4393095170527,"longitude":126.520510071383,"bookYear":null,"nameKR":"수산물센터8호점","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"보령시","status":"ACTIVE"},
        {"id":21726,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.941165,"longitude":128.898293,"bookYear":null,"nameKR":"전원숯불가든","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"영천시","status":"ACTIVE"},
        {"id":21738,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":35.151455,"longitude":129.061126,"bookYear":null,"nameKR":"더포(서면일번가점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":21741,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.296742264117,"longitude":129.081966749462,"bookYear":null,"nameKR":"자두나무집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":21852,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.6315862697947,"longitude":127.030666847018,"bookYear":null,"nameKR":"섬이보인다","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"장흥군","status":"ACTIVE"},
        {"id":21869,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.8486152288499,"longitude":128.635850254093,"bookYear":null,"nameKR":"르배(황금점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":21870,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.232889,"longitude":129.085395,"bookYear":null,"nameKR":"카카오두","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":21882,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.341346,"longitude":126.686487,"bookYear":null,"nameKR":"동키커피","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"시흥시","status":"ACTIVE"},
        {"id":21846,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.351647,"longitude":127.392687,"bookYear":null,"nameKR":"경성베이커리","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":21971,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.8241493483084,"longitude":128.618411520946,"bookYear":null,"nameKR":"수성못뉴욕바닷가재(본점)","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":21985,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.650062,"longitude":126.793183,"bookYear":null,"nameKR":"홍성원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":22026,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.6873702206942,"longitude":126.929481221997,"bookYear":null,"nameKR":"장자원가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":22051,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.6856832138069,"longitude":127.134573215138,"bookYear":null,"nameKR":"태능맥갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":22061,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.25996,"longitude":127.065051,"bookYear":null,"nameKR":"삼대째손두부진갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":22084,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.841364,"longitude":128.422769,"bookYear":null,"nameKR":"멍게가","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"통영시","status":"ACTIVE"},
        {"id":22095,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.8111081818843,"longitude":128.036195308201,"bookYear":null,"nameKR":"청해삼소리정","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":22096,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.2151049415157,"longitude":127.440844260968,"bookYear":null,"nameKR":"옛터민속박물관","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"동구/대전역","status":"ACTIVE"},
        {"id":22243,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.360499607465,"longitude":126.444557439835,"bookYear":null,"nameKR":"법성토우","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"영광군","status":"ACTIVE"},
        {"id":22245,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1858997797471,"longitude":129.086904684877,"bookYear":null,"nameKR":"수복돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":22269,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.853831,"longitude":129.269348,"bookYear":null,"nameKR":"화산운수대통한우","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":22174,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4565683327186,"longitude":126.603583401783,"bookYear":null,"nameKR":"연백식당","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구","status":"ACTIVE"},
        {"id":22175,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.1554507616782,"longitude":129.058501115477,"bookYear":null,"nameKR":"하연정순두부","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":22196,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"아시아식","latitude":35.156853,"longitude":129.056647,"bookYear":null,"nameKR":"포이안","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":22209,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.333648,"longitude":127.053407,"bookYear":null,"nameKR":"세컨플로어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(수지)","status":"ACTIVE"},
        {"id":22215,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.350946,"longitude":127.110457,"bookYear":null,"nameKR":"제주도토종흑돼지","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":22218,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.3615106011754,"longitude":127.15808356676,"bookYear":null,"nameKR":"고향생각","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":22419,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.504275,"longitude":126.336058,"bookYear":null,"nameKR":"방포수산","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":22422,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.990776,"longitude":129.554465,"bookYear":null,"nameKR":"백설분식","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":22425,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.717541,"longitude":127.727649,"bookYear":null,"nameKR":"경도풍경횟집","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":22464,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.524134,"longitude":126.330966,"bookYear":null,"nameKR":"노을횟집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":22473,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":34.9527165060153,"longitude":127.523634529977,"bookYear":null,"nameKR":"오대감","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":22145,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":36.61142,"longitude":127.46817,"bookYear":null,"nameKR":"쿠라이","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":22154,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.6367693305557,"longitude":127.488353387025,"bookYear":null,"nameKR":"요기(본점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":22341,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.4188113618474,"longitude":127.246928398078,"bookYear":null,"nameKR":"삼해수산","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광주시","status":"ACTIVE"},
        {"id":22344,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.8633876846304,"longitude":128.087913755886,"bookYear":null,"nameKR":"덕주골산장","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"제천시","status":"ACTIVE"},
        {"id":22345,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.7745332240694,"longitude":127.057078445944,"bookYear":null,"nameKR":"구구초밥&배방참치","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"아산시","status":"ACTIVE"},
        {"id":22352,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.679181,"longitude":126.754247,"bookYear":null,"nameKR":"새벽수산","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":22396,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.0701228144578,"longitude":127.750147934939,"bookYear":null,"nameKR":"한다사섬진강재첩","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"하동군","status":"ACTIVE"},
        {"id":22402,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.8778626500593,"longitude":129.516613980366,"bookYear":null,"nameKR":"양포삼거리생아구탕","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":22409,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.4627714269509,"longitude":126.672759437695,"bookYear":null,"nameKR":"북일기사식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"해남군","status":"ACTIVE"},
        {"id":22281,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8582241871899,"longitude":128.63335908298,"bookYear":null,"nameKR":"고운곰탕","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":22285,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.87191,"longitude":128.594646,"bookYear":null,"nameKR":"황소식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":22295,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.2603506043331,"longitude":129.086402671427,"bookYear":null,"nameKR":"테디네","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":22309,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.1364170999216,"longitude":129.068573437793,"bookYear":null,"nameKR":"산마루","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":22332,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.103681,"longitude":129.113049,"bookYear":null,"nameKR":"자연이주는밥상","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":22507,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.7110854425121,"longitude":126.788277139035,"bookYear":null,"nameKR":"만바우촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":22508,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.6014558223711,"longitude":127.123300331007,"bookYear":null,"nameKR":"서옹메밀막국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"구리시","status":"ACTIVE"},
        {"id":22520,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.532663,"longitude":126.350493,"bookYear":null,"nameKR":"오복꽃게집","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":22560,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.173829,"longitude":129.176549,"bookYear":null,"nameKR":"모이세식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":22561,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.173565,"longitude":129.130192,"bookYear":null,"nameKR":"콩나라(센텀1호점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":22573,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.120109,"longitude":129.110505,"bookYear":null,"nameKR":"영진돼지국밥(경성대점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":22576,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.187775749644,"longitude":129.129330707678,"bookYear":null,"nameKR":"보리밥분식","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":22579,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.0993848271812,"longitude":129.022633096674,"bookYear":null,"nameKR":"하동집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":22590,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"기타","latitude":35.866325,"longitude":128.593804,"bookYear":null,"nameKR":"풀하우스","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":22593,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":35.865708,"longitude":128.594973,"bookYear":null,"nameKR":"행복식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":22603,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.847888,"longitude":128.623559,"bookYear":null,"nameKR":"자매국수육개장","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":22608,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.9964978286788,"longitude":128.398917422247,"bookYear":null,"nameKR":"서울식육식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"칠곡군","status":"ACTIVE"},
        {"id":22616,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.844254,"longitude":128.612144,"bookYear":null,"nameKR":"가덕식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":22617,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.916738,"longitude":128.821232,"bookYear":null,"nameKR":"개미분식","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":22621,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.867484,"longitude":128.589936,"bookYear":null,"nameKR":"행복제면소","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(종로)","status":"ACTIVE"},
        {"id":22543,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.7628258880686,"longitude":127.112018786251,"bookYear":null,"nameKR":"민락동할매의토종보리밥집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의정부시","status":"ACTIVE"},
        {"id":22558,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.184714,"longitude":129.122114,"bookYear":null,"nameKR":"오복돼지국밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":22698,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/thetablebrewing/","foodTypes":"바/주점","latitude":37.6626500864282,"longitude":126.804202787854,"bookYear":null,"nameKR":"브루하우스더테이블","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":22717,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":37.391163,"longitude":127.091226,"bookYear":null,"nameKR":"베이비기네스(판교점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":22731,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.6168542670017,"longitude":127.480039712033,"bookYear":null,"nameKR":"괴산식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":22734,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gangmaru_maeuntang/","foodTypes":"한식(민물어패류)","latitude":37.684801,"longitude":127.386229,"bookYear":null,"nameKR":"강마루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"가평군","status":"ACTIVE"},
        {"id":22740,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.2921043980789,"longitude":127.01643024407,"bookYear":null,"nameKR":"북문족발","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":22744,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.7362385027312,"longitude":126.753142001113,"bookYear":null,"nameKR":"루미케이크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":22758,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.620794,"longitude":127.512581,"bookYear":null,"nameKR":"소순주한우마당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":22769,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.9591527991836,"longitude":127.529722447788,"bookYear":null,"nameKR":"원조군산아구찜","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":22815,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.1587542907844,"longitude":129.048778637771,"bookYear":null,"nameKR":"시골장터","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":22840,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.1538,"longitude":129.055654,"bookYear":null,"nameKR":"요시노스시","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":22855,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.606145,"longitude":127.483559,"bookYear":null,"nameKR":"어화코다리냉면(본점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":22859,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.619499,"longitude":127.512553,"bookYear":null,"nameKR":"투핸즈","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":22860,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/girincoffee/","foodTypes":"디저트/차/베이커리","latitude":36.6125631516791,"longitude":127.467372011604,"bookYear":null,"nameKR":"기린커피","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":22865,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.1964,"longitude":126.981223,"bookYear":null,"nameKR":"장어집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":22876,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.1683485565665,"longitude":129.142766503431,"bookYear":null,"nameKR":"하와이김밥","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":22883,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.0452479468215,"longitude":128.969238036101,"bookYear":null,"nameKR":"할매집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사하구","status":"ACTIVE"},
        {"id":23001,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.8380243376073,"longitude":128.617309658253,"bookYear":null,"nameKR":"충무로양곱창","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":23008,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.870731,"longitude":128.602198,"bookYear":null,"nameKR":"누렁소","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동인동)","status":"ACTIVE"},
        {"id":23023,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.8912777337332,"longitude":128.62398978169,"bookYear":null,"nameKR":"등대회","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":23031,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.8406798315743,"longitude":128.619028984506,"bookYear":null,"nameKR":"장원부대찌개철판구이","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":23038,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.878124,"longitude":128.594728,"bookYear":null,"nameKR":"팔산가든","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":22935,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.344561,"longitude":126.987045,"bookYear":null,"nameKR":"고향메밀촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":22981,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.595978,"longitude":128.200096,"bookYear":null,"nameKR":"솔베이지","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"문경시","status":"ACTIVE"},
        {"id":22982,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.438403,"longitude":126.718325,"bookYear":null,"nameKR":"오대산쭈꾸미볶음","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":22990,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.8680849956928,"longitude":128.578463548189,"bookYear":null,"nameKR":"에덴김밥","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":22991,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.824606,"longitude":128.554271,"bookYear":null,"nameKR":"삼천포세꼬시횟집","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":22994,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.541157,"longitude":128.491538,"bookYear":null,"nameKR":"대중분식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창녕군","status":"ACTIVE"},
        {"id":23040,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.8649890306227,"longitude":128.591145579639,"bookYear":null,"nameKR":"와촌손칼국시","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":23042,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.82542,"longitude":128.623288,"bookYear":null,"nameKR":"싱싱회뽈락","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":23047,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.8584329511265,"longitude":128.62403686349,"bookYear":null,"nameKR":"계림","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":23060,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.9970047316885,"longitude":127.084427292842,"bookYear":null,"nameKR":"기운네김밥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":23069,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.033588,"longitude":129.365215,"bookYear":null,"nameKR":"개미산곰장어","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":23107,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.269938,"longitude":127.109831,"bookYear":null,"nameKR":"하이드파크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":23197,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.2079122120462,"longitude":127.087219513222,"bookYear":null,"nameKR":"중앙손칼국수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"논산시","status":"ACTIVE"},
        {"id":23202,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":36.623059,"longitude":127.43384,"bookYear":null,"nameKR":"나박실","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":23203,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.7819828902874,"longitude":127.583448707891,"bookYear":null,"nameKR":"일미분식","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"증평군","status":"ACTIVE"},
        {"id":23219,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.0764114859029,"longitude":129.016480100029,"bookYear":null,"nameKR":"남촌설렁탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"서구","status":"ACTIVE"},
        {"id":23114,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.841572,"longitude":128.559958,"bookYear":null,"nameKR":"베이스캠프","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":23121,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.853458,"longitude":129.224652,"bookYear":null,"nameKR":"어림지해장국","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":23160,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/sabiagu/?utm_source=ig_profile_share&igshid=qcspjfqlz9z","foodTypes":"한식(어패류)","latitude":36.2778484984828,"longitude":126.907253117018,"bookYear":null,"nameKR":"사비아구","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"부여군","status":"ACTIVE"},
        {"id":23161,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.600221,"longitude":126.814612,"bookYear":null,"nameKR":"행주가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시","status":"ACTIVE"},
        {"id":23164,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.280908,"longitude":126.973746,"bookYear":null,"nameKR":"장터설렁탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":23170,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.814637,"longitude":127.17229,"bookYear":null,"nameKR":"유량진소머리국밥","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":23180,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.8844998264958,"longitude":127.203709012775,"bookYear":null,"nameKR":"성거산시골막국수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":23181,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":36.7951343710711,"longitude":127.123244695966,"bookYear":null,"nameKR":"방아다리","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":23185,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.8014416414255,"longitude":127.128834420719,"bookYear":null,"nameKR":"미라분식","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":23192,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.701214,"longitude":126.932022,"bookYear":null,"nameKR":"미소복원조양평해장국","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":23195,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.6956244649679,"longitude":126.666003016857,"bookYear":null,"nameKR":"홍북식당","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"홍성군","status":"ACTIVE"},
        {"id":23377,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/pizzeria_da_moon/","foodTypes":"이탈리아식","latitude":37.321678,"longitude":127.109678,"bookYear":null,"nameKR":"피제리아다문","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":23380,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.3531803830129,"longitude":127.102389099294,"bookYear":null,"nameKR":"삼교리동치미막국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":23388,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.6411812523794,"longitude":128.746369725737,"bookYear":null,"nameKR":"영빈원","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":23393,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.8506981844089,"longitude":128.535225369715,"bookYear":null,"nameKR":"미가식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":23313,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.358468,"longitude":126.93097,"bookYear":null,"nameKR":"카페더치즈케이크","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"군포시","status":"ACTIVE"},
        {"id":23343,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.982472,"longitude":128.369893,"bookYear":null,"nameKR":"오성통닭(단양점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":23484,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.4518673116951,"longitude":126.863886625616,"bookYear":null,"nameKR":"청담본갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"광명시","status":"ACTIVE"},
        {"id":23546,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/city_traveller/","foodTypes":"디저트/차/베이커리","latitude":36.326994353896,"longitude":127.427693989679,"bookYear":null,"nameKR":"도시여행자","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":23547,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.3313993574135,"longitude":127.423299989442,"bookYear":null,"nameKR":"안도르","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":23622,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":35.181709,"longitude":129.072816,"bookYear":null,"nameKR":"진주통닭(1호점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":23641,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gwangju_ddoksuni/","foodTypes":"한식(어패류)","latitude":35.162305,"longitude":126.934409,"bookYear":null,"nameKR":"똑순이아구찜","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":23646,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":34.5297018853635,"longitude":127.136485034194,"bookYear":null,"nameKR":"명당만두","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"고흥군","status":"ACTIVE"},
        {"id":23660,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.1029506236995,"longitude":126.899414634596,"bookYear":null,"nameKR":"제주흑돈하루","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":23609,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.071165,"longitude":129.069387,"bookYear":null,"nameKR":"어리목도새기촌","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":23776,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.3614778568981,"longitude":127.335936125617,"bookYear":null,"nameKR":"창억떡집(대전점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":23778,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.3329821796855,"longitude":127.172258363066,"bookYear":null,"nameKR":"황소고집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":23791,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.4601014546231,"longitude":126.770361161574,"bookYear":null,"nameKR":"은행나무집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"남동구","status":"ACTIVE"},
        {"id":24033,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.159242,"longitude":126.858708,"bookYear":null,"nameKR":"미래연","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":24063,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.1895223388832,"longitude":129.085800608477,"bookYear":null,"nameKR":"자연담은떡","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":24313,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.5085787137158,"longitude":126.808547880068,"bookYear":null,"nameKR":"마레도르이은","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":24538,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.1729119075557,"longitude":128.06610861473,"bookYear":null,"nameKR":"동경일식","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"진주시","status":"ACTIVE"},
        {"id":24550,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.3976156431246,"longitude":126.987425524173,"bookYear":null,"nameKR":"함흥면옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"의왕시","status":"ACTIVE"},
        {"id":24817,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.559965100276,"longitude":129.357697714481,"bookYear":null,"nameKR":"명촌순두부보쌈","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":23693,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.432786,"longitude":126.99306,"bookYear":null,"nameKR":"행복찹쌀떡","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"과천시","status":"ACTIVE"},
        {"id":23694,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.3113,"longitude":126.830813,"bookYear":null,"nameKR":"아미가일식","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":23752,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/trattoria_sikgu_suwon/","foodTypes":"이탈리아식","latitude":37.267164,"longitude":127.001913,"bookYear":null,"nameKR":"트라토리아식구(수원역점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":24217,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.798355,"longitude":129.312172,"bookYear":null,"nameKR":"품안","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":24224,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.894921,"longitude":126.628853,"bookYear":null,"nameKR":"고래면옥","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"당진시","status":"ACTIVE"},
        {"id":24470,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.670849,"longitude":126.790303,"bookYear":null,"nameKR":"목우촌웰빙마을(애니골점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":24513,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.293291,"longitude":126.863554,"bookYear":null,"nameKR":"화평동왕냉면(본오점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":23671,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wine_tunnel/","foodTypes":"바/주점","latitude":35.713344,"longitude":128.720333,"bookYear":null,"nameKR":"와인터널","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":23878,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.269993,"longitude":127.003249,"bookYear":null,"nameKR":"강원도막국수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":23894,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.263159,"longitude":127.194222,"bookYear":null,"nameKR":"소금오리황토진흙구이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":23933,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.318740647592,"longitude":127.401262307147,"bookYear":null,"nameKR":"도담한정식","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":24156,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.8325635932608,"longitude":127.145718428358,"bookYear":null,"nameKR":"문차이나","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":24179,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.539602,"longitude":126.626097,"bookYear":null,"nameKR":"사리원냉면","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":24388,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.2528313903591,"longitude":127.014838571739,"bookYear":null,"nameKR":"가보삼계탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":24407,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":38.0695599130178,"longitude":127.405310292681,"bookYear":null,"nameKR":"전주솔밭갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":24680,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":35.173223,"longitude":129.174554,"bookYear":null,"nameKR":"종로떡집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구","status":"ACTIVE"},
        {"id":24681,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.719838,"longitude":127.428333,"bookYear":null,"nameKR":"부민세숫대야냉면","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"청주시","status":"ACTIVE"},
        {"id":24709,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.252732,"longitude":127.070889,"bookYear":null,"nameKR":"영통가야밀면","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":24107,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.1832544378224,"longitude":127.077515038046,"bookYear":null,"nameKR":"산삼궁","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":24372,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.164718,"longitude":129.399066,"bookYear":null,"nameKR":"청풍정횟집펜션","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":24374,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.358276,"longitude":127.349394,"bookYear":null,"nameKR":"화평동왕냉면(봉명점)","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":24608,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.244435,"longitude":127.279666,"bookYear":null,"nameKR":"태봉산 한터오리골","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":24620,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.182501,"longitude":126.897054,"bookYear":null,"nameKR":"다연정식","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":24623,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":37.658106,"longitude":126.77271,"bookYear":null,"nameKR":"봉평메밀막국수(일산점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":24634,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.4834320518818,"longitude":126.757451539375,"bookYear":null,"nameKR":"라이차이","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"부천시","status":"ACTIVE"},
        {"id":24838,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.468411,"longitude":126.70216,"bookYear":null,"nameKR":"속초코다리냉면","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"부평구","status":"ACTIVE"},
        {"id":24940,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.161996,"longitude":126.88466,"bookYear":null,"nameKR":"금호국밥감자탕","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"서구/상무지구","status":"ACTIVE"},
        {"id":24997,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":36.863283,"longitude":127.14923,"bookYear":null,"nameKR":"덕향","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":25001,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.8216822276254,"longitude":127.156320062191,"bookYear":null,"nameKR":"장칼국수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":25014,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1671509103775,"longitude":129.133464374305,"bookYear":null,"nameKR":"금수복국(센텀시티점)","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":25021,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.0122884112569,"longitude":128.828254616878,"bookYear":null,"nameKR":"소희네집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"강서구","status":"ACTIVE"},
        {"id":25041,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.8820071570324,"longitude":128.642742456849,"bookYear":null,"nameKR":"동서활어회","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":25092,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.870075,"longitude":128.58079,"bookYear":null,"nameKR":"삼미갈비찜","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구","status":"ACTIVE"},
        {"id":25164,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.5369066210012,"longitude":126.641673072724,"bookYear":null,"nameKR":"행복한손님","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":25187,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.70335,"longitude":126.371663,"bookYear":null,"nameKR":"은성횟집","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":25206,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.0433107297601,"longitude":127.093334090592,"bookYear":null,"nameKR":"마당집촌돼지두루치기","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"평택시","status":"ACTIVE"},
        {"id":25331,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.001569,"longitude":127.33902,"bookYear":null,"nameKR":"감나무골가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":25279,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.5369473571611,"longitude":127.591590857471,"bookYear":null,"nameKR":"메밀촌","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":25285,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.6863946102468,"longitude":126.318602573338,"bookYear":null,"nameKR":"솔밭식당","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":25344,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.077441,"longitude":127.211585,"bookYear":null,"nameKR":"거목산장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안성시","status":"ACTIVE"},
        {"id":25346,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.377726,"longitude":127.132875,"bookYear":null,"nameKR":"도깨비족발포차","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":25500,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.540786,"longitude":129.42609,"bookYear":null,"nameKR":"부강숯불갈비·쌈밥","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산","status":"ACTIVE"},
        {"id":25505,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.834072,"longitude":127.1423,"bookYear":null,"nameKR":"광명만두","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":25384,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":36.772117,"longitude":127.130274,"bookYear":null,"nameKR":"들마루","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":25389,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.163327,"longitude":129.060504,"bookYear":null,"nameKR":"바다산곰장어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":25631,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1131779004878,"longitude":129.094569438411,"bookYear":null,"nameKR":"오륙도횟집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":25642,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.1186467851811,"longitude":129.079769378798,"bookYear":null,"nameKR":"쌍희반점","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":25650,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.114192,"longitude":129.113038,"bookYear":null,"nameKR":"수구리보리밥집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"남구","status":"ACTIVE"},
        {"id":25654,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.1654193355136,"longitude":129.050990978031,"bookYear":null,"nameKR":"삼부식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":25605,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.831304,"longitude":127.127745,"bookYear":null,"nameKR":"신화수산활어회센타","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"},
        {"id":25752,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":36.4863189946234,"longitude":127.720801589639,"bookYear":null,"nameKR":"화성가든","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"보은군","status":"ACTIVE"},
        {"id":25794,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":35.8662180472872,"longitude":128.596080546213,"bookYear":null,"nameKR":"도쿄다이닝","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":25824,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.856328,"longitude":128.492542,"bookYear":null,"nameKR":"와룡반점","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":25884,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gimjeongae79/","foodTypes":"아시아식","latitude":36.019807,"longitude":129.341097,"bookYear":null,"nameKR":"란나타이(포항점)","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":25973,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1643757682533,"longitude":128.984098931318,"bookYear":null,"nameKR":"이웃집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":25977,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.22398,"longitude":129.082639,"bookYear":null,"nameKR":"장수돌곱창","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":25983,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.150632,"longitude":129.011836,"bookYear":null,"nameKR":"황가산오징어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":25988,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.152906,"longitude":128.660869,"bookYear":null,"nameKR":"신생원(별관)","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시(진해)","status":"ACTIVE"},
        {"id":25992,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.165053,"longitude":129.037476,"bookYear":null,"nameKR":"다래산곰장어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구","status":"ACTIVE"},
        {"id":25891,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.2061856213598,"longitude":126.645280167877,"bookYear":null,"nameKR":"8호단골횟집","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":25929,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.362819,"longitude":127.377887,"bookYear":null,"nameKR":"부산기장꼼장어","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":25943,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.8604901781052,"longitude":128.621634677262,"bookYear":null,"nameKR":"정원한우갈비","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":25961,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.914859,"longitude":128.640304,"bookYear":null,"nameKR":"호박돌식당","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"동구","status":"ACTIVE"},
        {"id":25964,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.858834,"longitude":128.622814,"bookYear":null,"nameKR":"기장물회산곰장어","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":25965,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.8883428467244,"longitude":128.582000645973,"bookYear":null,"nameKR":"이방인횟집","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"북구","status":"ACTIVE"},
        {"id":25969,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.1657134599431,"longitude":128.980918058141,"bookYear":null,"nameKR":"사상꼬리집","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"사상구","status":"ACTIVE"},
        {"id":26033,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":37.624729,"longitude":127.355285,"bookYear":null,"nameKR":"테라로사(서종점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양평군","status":"ACTIVE"},
        {"id":26052,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.06477,"longitude":129.387188,"bookYear":null,"nameKR":"마라도회식당","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"포항시","status":"ACTIVE"},
        {"id":26111,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.786504,"longitude":126.689373,"bookYear":null,"nameKR":"장수누룽지백숙명품장어","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":26247,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.62887369696,"longitude":127.812629624048,"bookYear":null,"nameKR":"원조할매갈비식당","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"함양군","status":"ACTIVE"},
        {"id":26339,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/coffee.myungga/","foodTypes":"디저트/차/베이커리","latitude":36.986768,"longitude":128.371117,"bookYear":null,"nameKR":"커피명가(단양점)","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":26132,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":34.836013,"longitude":127.893592,"bookYear":null,"nameKR":"남해한방삼계탕","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"남해군","status":"ACTIVE"},
        {"id":26133,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.3029674266167,"longitude":126.970697269185,"bookYear":null,"nameKR":"다모","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"수원시","status":"ACTIVE"},
        {"id":26142,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.298287,"longitude":127.10774,"bookYear":null,"nameKR":"하이난","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":26200,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.7095159655142,"longitude":126.384700980197,"bookYear":null,"nameKR":"로이카페","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":26202,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.1839140408479,"longitude":127.23448567284,"bookYear":null,"nameKR":"산골가든","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":26203,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.375553,"longitude":127.317078,"bookYear":null,"nameKR":"미시루","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"유성구","status":"ACTIVE"},
        {"id":26400,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_bada/","foodTypes":"디저트/차/베이커리","latitude":36.526507,"longitude":126.334263,"bookYear":null,"nameKR":"카페바다","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":26401,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"일식","latitude":37.317466,"longitude":126.835283,"bookYear":null,"nameKR":"키무스시","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"안산시","status":"ACTIVE"},
        {"id":26564,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.8670476626298,"longitude":128.689477643446,"bookYear":null,"nameKR":"맷돌순두부","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":26449,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":34.7348902110457,"longitude":126.714809461923,"bookYear":null,"nameKR":"월남흑염소","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"강진군","status":"ACTIVE"},
        {"id":26454,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.210484261435,"longitude":129.009058191169,"bookYear":null,"nameKR":"구포추어탕","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"북구","status":"ACTIVE"},
        {"id":26468,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.9208891805709,"longitude":128.524022046155,"bookYear":null,"nameKR":"평화횟집","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"거제시","status":"ACTIVE"},
        {"id":26489,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.1929019088738,"longitude":129.122871259466,"bookYear":null,"nameKR":"한빛기사식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"해운대구(센텀/마린시티)","status":"ACTIVE"},
        {"id":26524,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.0910729819122,"longitude":129.039509880537,"bookYear":null,"nameKR":"포항물회일번지","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"영도구","status":"ACTIVE"},
        {"id":26528,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":35.81664,"longitude":128.822136,"bookYear":null,"nameKR":"고향칼국수","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경산시","status":"ACTIVE"},
        {"id":26708,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":34.9586457824918,"longitude":127.485034059992,"bookYear":null,"nameKR":"순천제일식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":29559,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":37.412041968860976,"longitude":127.1272892461136,"bookYear":null,"nameKR":"고산수","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":29619,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(가금류)","latitude":37.28266890189128,"longitude":127.1128177216003,"bookYear":null,"nameKR":"전통춘천닭갈비","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시(기흥)","status":"ACTIVE"},
        {"id":29649,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.274429577734125,"longitude":129.149403895503,"bookYear":null,"nameKR":"정원식당","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"기장군","status":"ACTIVE"},
        {"id":29721,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/bk023049293/","foodTypes":"한식(탕/국/찌개/전골)","latitude":37.644069857500874,"longitude":126.79280116489467,"bookYear":null,"nameKR":"배꼽집(일산벨라시타점)","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"고양시(일산)","status":"ACTIVE"},
        {"id":29790,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/daruzi.yeonmi","foodTypes":"디저트/차/베이커리","latitude":37.63853880098696,"longitude":126.46848332539868,"bookYear":null,"nameKR":"카페다루지","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"강화/옹진","status":"ACTIVE"},
        {"id":30028,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":35.8646758635426,"longitude":129.21960744200814,"bookYear":null,"nameKR":"당신을위한밥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":30052,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"바/주점","latitude":36.32808874043307,"longitude":127.4181181130304,"bookYear":null,"nameKR":"막장집","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":30114,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":37.723020776587894,"longitude":126.69638580491139,"bookYear":null,"nameKR":"민바리고추장매운탕","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":30278,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/soonsubori/","foodTypes":"한식(일반한식)","latitude":36.15332013594371,"longitude":128.3163543874042,"bookYear":null,"nameKR":"순수보리밥","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"구미시","status":"ACTIVE"},
        {"id":30444,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(민물어패류)","latitude":35.16238342912025,"longitude":126.93449054959319,"bookYear":null,"nameKR":"두암골풍천장어","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"북구","status":"ACTIVE"},
        {"id":30471,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(탕/국/찌개/전골)","latitude":35.28282220889431,"longitude":127.29947316698578,"bookYear":null,"nameKR":"삼기국밥","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"곡성군","status":"ACTIVE"},
        {"id":30510,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.475750760417654,"longitude":126.61942791645147,"bookYear":null,"nameKR":"대만제과","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"중구(차이나타운)","status":"ACTIVE"},
        {"id":30608,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.124687220429095,"longitude":126.91004666953646,"bookYear":null,"nameKR":"동경화로라운지","ribbonType":"정보없음","zone2_1":"광주","zone2_2":"남구","status":"ACTIVE"},
        {"id":30621,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.745829006716,"longitude":126.7036923778233,"bookYear":null,"nameKR":"복생원","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"예산군","status":"ACTIVE"},
        {"id":30665,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.31449065899941,"longitude":127.39946746164229,"bookYear":null,"nameKR":"평산면옥","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"중구","status":"ACTIVE"},
        {"id":31003,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":34.7632378280894,"longitude":127.66671260982,"bookYear":null,"nameKR":"여진식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"여수시","status":"ACTIVE"},
        {"id":31027,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/guillaume_seoul/?igshid=1wyqia5da7r8c","foodTypes":"디저트/차/베이커리","latitude":37.3973207561987,"longitude":127.11356226927,"bookYear":null,"nameKR":"기욤 판교점  ","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":31057,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":35.1862024480944,"longitude":129.107350496523,"bookYear":null,"nameKR":"왔다산곰장어","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"연제구","status":"ACTIVE"},
        {"id":31516,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/brewda_coffee","foodTypes":"디저트/차/베이커리","latitude":37.7786894344324,"longitude":126.956775899574,"bookYear":null,"nameKR":"브루다","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"양주시","status":"ACTIVE"},
        {"id":31574,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":37.8544272155531,"longitude":126.786383748121,"bookYear":null,"nameKR":"터줏대감회직판장","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":31743,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/oven_temperature_","foodTypes":"디저트/차/베이커리","latitude":35.1559460199003,"longitude":129.064563982545,"bookYear":null,"nameKR":"오븐의온도","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"부산진구(서면)","status":"ACTIVE"},
        {"id":31796,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":37.4959182181851,"longitude":126.682907825667,"bookYear":null,"nameKR":"박칼국수","ribbonType":"정보없음","zone2_1":"인천","zone2_2":"서구","status":"ACTIVE"},
        {"id":31815,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.5865971908392,"longitude":126.374311635117,"bookYear":null,"nameKR":"나문재카페","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"태안군","status":"ACTIVE"},
        {"id":31890,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.3944039022277,"longitude":127.108352326702,"bookYear":null,"nameKR":"쉐누하누","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":31903,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(일반한식)","latitude":34.9716709803729,"longitude":127.588053403597,"bookYear":null,"nameKR":"두메산골식당","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"광양시","status":"ACTIVE"},
        {"id":31908,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(면류)","latitude":36.4416348622627,"longitude":127.128254474295,"bookYear":null,"nameKR":"유가네칼국수","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"공주시","status":"ACTIVE"},
        {"id":31924,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(어패류)","latitude":36.2242112560808,"longitude":126.990095739853,"bookYear":null,"nameKR":"삼오식당","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"부여군","status":"ACTIVE"},
        {"id":31927,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":37.0298513869857,"longitude":128.303764823653,"bookYear":null,"nameKR":"향미식당","ribbonType":"정보없음","zone2_1":"충북/세종","zone2_2":"단양군","status":"ACTIVE"},
        {"id":31929,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.6908624588602,"longitude":128.892846560752,"bookYear":null,"nameKR":"강남반점","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"청도군","status":"ACTIVE"},
        {"id":31936,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gabong___","foodTypes":"디저트/차/베이커리","latitude":35.8336012629424,"longitude":129.20880861952,"bookYear":null,"nameKR":"가봉반과","ribbonType":"정보없음","zone2_1":"경북","zone2_2":"경주시","status":"ACTIVE"},
        {"id":32348,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/glenlunar/","foodTypes":"바/주점","latitude":37.6450383303262,"longitude":126.625043944901,"bookYear":null,"nameKR":"글렌루나","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":32367,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/monotonebakehouse/","foodTypes":"디저트/차/베이커리","latitude":37.368833323201,"longitude":127.110948870482,"bookYear":null,"nameKR":"모노톤베이크하우스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32375,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.7917373693017,"longitude":126.697336891381,"bookYear":null,"nameKR":"컴프에비뉴","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32413,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/a_loaf_slice_piece/","foodTypes":"디저트/차/베이커리","latitude":37.2627549696841,"longitude":127.191007800435,"bookYear":null,"nameKR":"어로프슬라이스피스","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"용인시","status":"ACTIVE"},
        {"id":32424,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/moonbali_cafe/","foodTypes":"기타","latitude":37.7245294084281,"longitude":126.693896556541,"bookYear":null,"nameKR":"문발리","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"파주시","status":"ACTIVE"},
        {"id":32435,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/gleenpark_cafe/","foodTypes":"디저트/차/베이커리","latitude":37.6499782916684,"longitude":126.649234187517,"bookYear":null,"nameKR":"글린공원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":32439,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":34.9420130418347,"longitude":127.494100811381,"bookYear":null,"nameKR":"황금성","ribbonType":"정보없음","zone2_1":"전남","zone2_2":"순천시","status":"ACTIVE"},
        {"id":32445,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/wootable_/","foodTypes":"한식(육류)","latitude":37.3973207561987,"longitude":127.11356226927,"bookYear":null,"nameKR":"우테이블","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":32478,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"이탈리아식","latitude":37.3618480979877,"longitude":127.106559783202,"bookYear":null,"nameKR":"그래니살룬","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32479,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.394244787236,"longitude":127.112210932491,"bookYear":null,"nameKR":"하누마루","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(판교)","status":"ACTIVE"},
        {"id":32576,"bookStatus":"비활성","websiteInstagram":"","foodTypes":"디저트/차/베이커리","latitude":35.5652108640634,"longitude":129.122731652895,"bookYear":null,"nameKR":"소월당언양점","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":".울산(언양/울주군)","status":"ACTIVE"},
        {"id":32994,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com=makacine_espressobar_","foodTypes":"디저트/차/베이커리","latitude":37.2082103699662,"longitude":127.111672630685,"bookYear":null,"nameKR":"마카시네에스프레소바","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"화성시","status":"ACTIVE"},
        {"id":32035,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/tangs_coffee_house/","foodTypes":"디저트/차/베이커리","latitude":35.2302722053997,"longitude":129.081911479919,"bookYear":null,"nameKR":"탱쓰커피하우스","ribbonType":"정보없음","zone2_1":"부산","zone2_2":"금정구","status":"ACTIVE"},
        {"id":32426,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/susanpark.cafe/","foodTypes":"기타","latitude":37.6428312965761,"longitude":126.546076105164,"bookYear":null,"nameKR":"수산공원","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"김포시","status":"ACTIVE"},
        {"id":33170,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/simpledelishop/","foodTypes":"기타","latitude":35.2531034457618,"longitude":128.604753124173,"bookYear":null,"nameKR":"심플델리","ribbonType":"정보없음","zone2_1":"경남/울산","zone2_2":"창원시","status":"ACTIVE"},
        {"id":32530,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/goodtaste_sapidite/","foodTypes":"디저트/차/베이커리","latitude":35.8443424558174,"longitude":128.638608792101,"bookYear":null,"nameKR":"사피디뜨by커피맛을조금아는남자","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"수성구","status":"ACTIVE"},
        {"id":32533,"bookStatus":"비활성","websiteInstagram":"https://www.instagram.com/cafe_pooak/","foodTypes":"디저트/차/베이커리","latitude":37.7750159263388,"longitude":127.165893590439,"bookYear":null,"nameKR":"포옥","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"포천시","status":"ACTIVE"},
        {"id":32356,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":37.6139542126811,"longitude":127.339974421456,"bookYear":null,"nameKR":"리베르떼","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"남양주시","status":"ACTIVE"},
        {"id":32443,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":37.3843299362055,"longitude":127.133621666234,"bookYear":null,"nameKR":"설화한우","ribbonType":"정보없음","zone2_1":"경기","zone2_2":"성남시(분당)","status":"ACTIVE"},
        {"id":32520,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"한식(육류)","latitude":35.8171739219952,"longitude":128.546631761006,"bookYear":null,"nameKR":"손이삼겹","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"달서구","status":"ACTIVE"},
        {"id":27879,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":35.870638,"longitude":128.598839,"bookYear":null,"nameKR":"신짬뽕","ribbonType":"정보없음","zone2_1":"대구","zone2_2":"중구(동성로)","status":"ACTIVE"},
        {"id":2214,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"중식","latitude":36.36103100626869,"longitude":127.37728774109982,"bookYear":null,"nameKR":"동천홍","ribbonType":"정보없음","zone2_1":"대전","zone2_2":"서구/둔산","status":"ACTIVE"},
        {"id":32403,"bookStatus":"비활성","websiteInstagram":null,"foodTypes":"디저트/차/베이커리","latitude":36.8450260077018,"longitude":127.135801727227,"bookYear":null,"nameKR":"위커피","ribbonType":"정보없음","zone2_1":"충남","zone2_2":"천안시","status":"ACTIVE"}
    ];


    const data_array_distance = data_array.map((e)=>{
        const yy = Math.abs(y - e.latitude) * 111200;
        const xx = Math.abs(x - e.longitude) * 91090;
        const xxyy = Math.pow(xx,2)+Math.pow(yy,2);
        return { ...e, distance : Math.round(Math.sqrt(xxyy))}

    });
    const data_array_distance_filter = data_array_distance.filter((e)=>{
         return e.distance < 5000;
    });
    return (

        <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={data_array_distance_filter}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                checkboxSelection
                disableRowSelectionOnClick
            />
        </Box>
    );
}
export default BLUE