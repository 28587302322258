import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from '@mui/material/Container';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

function C4() {
    return (
        <Box    sx={(theme) => ({
            width: '100%',
            backgroundImage: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
            // backgroundImage: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(30, 100%, 70%), transparent)',
            backgroundRepeat: 'no-repeat',

        })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    pt: { xs: 5, sm: 5 },
                    pb: { xs: 5, sm: 5 },
                }}
            >
                <Typography component="h2" variant="h4" color="warning.light">
                    2
                </Typography>
                <div>
                    <Accordion>
                        <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header">
                           2
                        </AccordionSummary>
                        <AccordionDetails>
                           2

                        </AccordionDetails>
                    </Accordion>

                </div>
            </Container>
        </Box>
    );
}

export default C4