import {Section, SectionsContainer} from 'react-fullpage';
import Alert from '@mui/material/Alert'
import C1 from "./C1";
import C2 from "./C2";
import C3 from "./C3";
import C4 from "./C4";

let options = {
    anchors: ['one','two','three','four'],
    delay : 200
}

function C() {
    return (
        <SectionsContainer {...options}>
            <Section>
                <C1/>
                <Alert  severity="success">위로 스크롤 가능</Alert>
            </Section>
            <Section>
                <C2/>
                <Alert  severity="success">위로 스크롤 가능</Alert>
            </Section>
            <Section>
                <C3/>
                <Alert  severity="success">위로 스크롤 가능</Alert>
            </Section>
            <Section>
                <C4/>
            </Section>
        </SectionsContainer>
    )
}


export default C