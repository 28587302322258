import {Route, Routes} from 'react-router-dom';
import Letter from './Pages/Letter';
import B from "./Pages/B";
import C from "./Pages/C";
import BLUE from "./Pages/BLUE";
import Empty from "./Pages/Empty";

function Router() {
    return (
     <Routes>
         <Route path="/" element={<Empty/>} />
         <Route path="/letter" element={<Letter/>} />
         <Route path="/B" element={<B/>} />
         <Route path="/C" element={<C/>}/>
         <Route path="/BLUE" element={<BLUE/>}/>

     </Routes>
    );
}
export default Router