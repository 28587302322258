import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function C2() {
    return (
        <Box
            sx={(theme) => ({
                width: '100%',
                backgroundImage: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center',
                    pt: { xs: 1, sm: 1 },
                    pb: { xs: 1, sm: 1 },
                }}
            >
                <Typography component="h2" variant="h4" color="warning.light">
                    1
                </Typography>
                <Typography>
                   1
                </Typography>

            </Container>
        </Box>
    );
}

export default C2